import React from "react";
import { useResource } from "../../../../../../store/resources/useResource";
import { getResource, deleteResource } from "../../../../../../store/resources/actions";
import { getData } from "../../../../../../store/dataGrid/actions";
import { modalOpen } from "../../../../../../store/modal/actions";
import { store } from "store/configureStore";

export const useBudgetLineAttributesDataSource = ({ utilityNumber, contractNumber, budgetLineNumber }) => {
    return useResource({
        resourceName: "utilitiesContractsBudgetLinesAttributes",
        key: budgetLineNumber,
        path: {
            utilityNumber,
            contractNumber,
            budgetLineNumber,
        },
        transform: (data) => {
            return (data && data.budgetLineAttributeList) || [];
        },
    });
};

export const useBudgetLineAttributeResource = ({ utilityNumber, contractNumber, budgetLineNumber, resourceId }) => {
    return useResource({
        resourceName: "utilitiesContractsBudgetLinesAttributes",
        resourceId,
        path: {
            utilityNumber,
            contractNumber,
            budgetLineNumber,
        },
    });
};

export const useBudgetLineCategories = ({ utilityNumber, contractNumber }) => {
    return useResource({
        resourceName: "utilitiesContractsBudgetLineCategories",
        key: contractNumber,
        path: {
            utilityNumber,
            contractNumber,
        },
        transform: (data) => {
            return (data && data.budgetLineCategoryList) || [];
        },
    });
};

export const onRefreshBudgetLinesGrid = ({ contractNumber }) => {
    const dataGridId = getBudgetLinesGridId({ contractNumber });
    store.dispatch(getData({ dataGridId }));
};

export const onRefreshAssignmentsList = ({ utilityNumber, contractNumber, budgetLineNumber, dispatch }) => {
    dispatch(
        getResource({
            resourceName: "utilitiesContractsBudgetLinesPrograms",
            key: budgetLineNumber,
            path: {
                utilityNumber,
                contractNumber,
                budgetLineNumber,
            },
        })
    );
};

export const onDeleteBudgetLine = ({ utilityNumber, contractNumber, dataItem, onRefresh, dispatch }) => {
    const message = (
        <>
            <p>This action will permanently delete this budget item and all child items associated to it.</p>
            <p>Once completed, this action cannot be undone.</p>
            <p>Are you sure you want to perform this action?</p>
        </>
    );

    dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: "Delete Budget Line Item",
                overlayClassName: "modal-styled",
                className: "delete-budget-line-item-modal modal-sm",
                modalIcon: "delete-trash-empty",
                footerContentCenter: true,
                content: message,
                onConfirm: () => {
                    dispatch(
                        deleteResource({
                            resourceName: "utilitiesContractsBudgetLines",
                            resourceId: dataItem.budgetLineNumber,
                            path: {
                                utilityNumber,
                                contractNumber,
                            },
                            onSuccess: onRefresh,
                        })
                    );
                },
            },
        })
    );
};

export const contractBudgetLinesGridColumns = [
    {
        id: "DA58394D-9E47-4CD4-8DD3-91F8B843E8B7",
        key: "budgetName",
        name: "BUDGET NAME",
    },
    {
        id: "A9D0CC7F-084A-4BBD-969B-2802E417BAB4",
        key: "budgetLineID",
        name: "BUDGET ID",
        active: false,
    },
    {
        id: "DF2F4399-FA75-4901-8D16-9AA3253DE8D9",
        key: "budgetLineNumber",
        name: "BUDGET NUMBER",
        active: false,
    },
    {
        id: "92E01FAD-D2AC-4F16-90C9-AB5AE4BD6D23",
        key: "budgetLineCategoryName",
        name: "BUDGET CATEGORY",
    },
    {
        id: "CE53D315-6523-4808-8A17-C722312EB03D",
        key: "total",
        name: "TOTAL",
    },
    {
        id: "1152B7B6-250A-4736-B112-66FF20485F9B",
        key: "remaining",
        name: "REMAINING",
    },
    {
        id: "DDAA596E-AB89-4220-9EFA-76657BA5D4A3",
        key: "budgetLineCategoryId",
        name: "BUDGET CATEGORY ID",
        active: false,
    },
    {
        id: "A171AF48-A50F-4EBB-B8DD-9900FD7B122A",
        key: "parentBudgetLineNumber",
        name: "PARENT ID",
        active: false,
    },
    {
        id: "539D3A40-9973-4BDA-93C8-60643432E4F6",
        key: "sortOrder",
        name: "ORDER",
        active: false,
    },
    {
        id: "BA94881D-AFCC-4EF7-919B-4A927E45DA79",
        key: "note",
        name: "NOTE",
        active: false,
    },
];

export const budgetLineFormSchema = (categories) => ({
    type: "object",
    required: ["budgetName", "budgetLineCategoryId", "sortOrder"],
    properties: {
        budgetName: {
            type: "string",
            title: "Budget Name",
        },
        budgetLineCategoryId: {
            type: "integer",
            title: "Budget Category",
            anyOf: categories.map((c) => ({
                title: c.budgetLineCategoryName,
                enum: [c.budgetLineCategoryId],
            })),
        },
        sortOrder: {
            type: "integer",
            title: "Sort Order",
            default: 0,
        },
        note: {
            type: "string",
            title: "Note",
        },
    },
});

export const getBudgetLinesGridId = ({ contractNumber }) => {
    return `${contractNumber}-budget-lines-grid`;
};

export const getBudgetLineAttributesGridId = ({ budgetLineNumber }) => {
    return `${budgetLineNumber}-attributes-grid`;
};

export const transformFormErrors = (errors) => {
    return errors.map((error) => {
        if (error.name === "pattern") {
            error.message = "Invalid field value";
        }

        return error;
    });
};
