import React, { memo, useContext, useCallback } from "react";

import { isApplicationLocked } from "../../../../../views/ProjectView/utils";
import { appIsLockedText } from "../utils";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";

import IconWithLabel from "../../../../Icons/IconWithLabel";
import ContactsPanelItem from "./ContactsPanelItem";
import ContentHeader from "../../../../Title/ContentHeader";
import NothingFoundBlock from "../../../../NothingFoundBlock";
import { ContactsPanelContext } from ".";
import { ContactEditSidePanel } from "./ContactEditSidePanel";
import { systemUserRights } from "components/utils/user";
import { useProgramRights } from "store/resources/useResource";

const OtherContacts = memo(({ listRef, otherContacts }) => {
    const {
        utilityNumber,
        programNumber,
        applicationNumber,
        premiseContact,
        primaryContact,
        contractorContact,
        onDelete,
        onExpand,
        assignedContacts,
    } = useContext(ContactsPanelContext);

    const [programRights = []] = useProgramRights({ programNumber });

    const isAppLocked = isApplicationLocked({ applicationNumber });
    const isAddContactDisabled = isAppLocked || !programRights.includes(systemUserRights.VISIONDSM_ADD_APPLICATION);

    // Use sidebar handlers
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
    });

    const onCloseAddContact = useCallback(() => handleCloseSidePanel(), [handleCloseSidePanel]);

    const onAddContact = useCallback(() => {
        handleOpenSidePanel(
            <ContactEditSidePanel
                isNew
                applicationNumber={applicationNumber}
                programNumber={programNumber}
                utilityNumber={utilityNumber}
                premiseContact={premiseContact}
                primaryContact={primaryContact}
                contractorContact={contractorContact}
                title={"Add New Contact"}
                icon="plus"
                onClose={onCloseAddContact}
            />
        );
    }, [
        applicationNumber,
        programNumber,
        utilityNumber,
        premiseContact,
        primaryContact,
        contractorContact,
        onCloseAddContact,
        handleOpenSidePanel,
    ]);

    return (
        <div ref={listRef} className="other-contacts">
            <div className="flex-row align-center justify-space-between">
                <ContentHeader>Other Contacts</ContentHeader>
                {otherContacts.length > 0 && (
                    <div className="contacts-action-panel">
                        <IconWithLabel
                            iconWithLabelRight
                            className="add-contact"
                            icon="plus"
                            disabled={isAddContactDisabled}
                            title={isAppLocked ? appIsLockedText : undefined}
                            onClick={onAddContact}
                        >
                            Add New Contact
                        </IconWithLabel>
                    </div>
                )}
            </div>
            {otherContacts.length > 0 ? (
                <div className="contacts-panel-items">
                    {otherContacts.map((contact) => (
                        <ContactsPanelItem
                            utilityNumber={utilityNumber}
                            contact={contact}
                            key={contact.contactnumber}
                            applicationNumber={applicationNumber}
                            isLocked={isAppLocked}
                            onDelete={onDelete}
                            onOpenDashboardPanel={onExpand}
                            assignedContacts={assignedContacts}
                            isOtherContact={true}
                        />
                    ))}
                </div>
            ) : (
                <NothingFoundBlock nothingFoundBlockMargin icon="contacts" title="No Other Contacts">
                    <IconWithLabel
                        iconWithLabelRight
                        className="add-contact"
                        icon="plus"
                        disabled={isAddContactDisabled}
                        title={isAppLocked ? appIsLockedText : undefined}
                        onClick={onAddContact}
                    >
                        Add New Contact
                    </IconWithLabel>
                </NothingFoundBlock>
            )}
        </div>
    );
});

export default OtherContacts;
