import React, { memo } from "react";

import { utilitiesCalculationsGridColumnKeys } from "../../../../../views/configureGrids";
import { mapGridRowToObject } from "../../../../../utils/datagrid";
import { calculationsGridCustomRowActions } from "../utils";

import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid";
import ActionLabel from "../../../../Label/ActionLabel";
import UpdateCalculationPanel from "../UpdateCalculationPanel";
import CalculationDetailsForm from "../CalculationDetailsForm";
import CalculationRevisionsPanel from "../CalculationRevisionsPanel";
import CalculationAssociations from "../CalculationAssociations";
import { isChildProgram } from "components/views/ProgramView/utils";

const Grid = memo(({ gridId, dataGridConfig, utilityNumber, onOpenPanel, onClosePanel, programNumber }) => {
    const disabled = isChildProgram({ programNumber });

    const onRowAction = (action, onExpand) => {
        const { name, dataItem } = action;

        const rowData = mapGridRowToObject(utilitiesCalculationsGridColumnKeys, dataItem);

        switch (name) {
            case "details":
                onOpenPanel(
                    <CalculationDetailsForm disabled={disabled} utilityNumber={utilityNumber} rowData={rowData} onClose={onClosePanel} />
                );
                break;

            case "update":
                onOpenPanel(
                    <UpdateCalculationPanel disabled={disabled} utilityNumber={utilityNumber} rowData={rowData} onClose={onClosePanel} />
                );
                break;
            case "revisions":
                onOpenPanel(
                    <CalculationRevisionsPanel
                        disabled={disabled}
                        programNumber={programNumber}
                        utilityNumber={utilityNumber}
                        rowData={rowData}
                        onClose={onClosePanel}
                    />
                );
                break;
            case "associations":
                onOpenPanel(
                    <CalculationAssociations disabled={disabled} utilityNumber={utilityNumber} rowData={[rowData]} onClose={onClosePanel} />
                );
                break;
            default:
                break;
        }
    };

    return (
        <DataGrid
            name={gridId}
            config={dataGridConfig}
            onRowAction={onRowAction}
            customRowActions={calculationsGridCustomRowActions}
            columnCellContent={{
                [utilitiesCalculationsGridColumnKeys.calculationName]: (column, row, onExpandRow, onRowAction) => {
                    return (
                        <>
                            {disabled ? (
                                row[column.key]
                            ) : (
                                <ActionLabel
                                    onClick={() =>
                                        onRowAction({
                                            name: "details",
                                            dataItem: row,
                                        })
                                    }
                                >
                                    {row[column.key]}
                                </ActionLabel>
                            )}
                        </>
                    );
                },
            }}
        />
    );
});

export default withDataGrid("utilitiesCalculations", Grid);
