import React, { memo } from "react";
import cn from "classnames";
import { isNil } from "lodash";
import { useSelector } from "react-redux";

const SideNavContainer = memo(({ className, sideNavIds, children }) => {
    const isActive = useSelector((state) => {
        return (sideNavIds ?? []).some((id) => !isNil(state.sideNav[id]));
    });

    return (
        <div
            className={cn("sidenav-container flex-row fill-width", className, {
                "sidenav-container--active": isActive,
            })}
        >
            {children}
        </div>
    );
});

export default SideNavContainer;
