import React, { memo, useContext } from "react";

import IconWithLabel from "../../Icons/IconWithLabel";
import { WorkflowContext } from "../context/WorkflowContext";

export const Controls = memo(({ onCreateWorkflowStatus }) => {
    const { isLocked } = useContext(WorkflowContext);

    return (
        <div className="wf__statuses-controls">
            <IconWithLabel
                icon="plus"
                iconWithLabelRight
                disabled={isLocked}
                onClick={onCreateWorkflowStatus}
                title={isLocked ? undefined : "Add Workflow Status"}
            >
                Add Workflow Status
            </IconWithLabel>
        </div>
    );
});
