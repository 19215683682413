// Reference types available from system API /api/v1/reference

export const referenceTypes = {
    actionPromoted: "actionPromoted",
    applicationFlagWorkflow: "applicationflagworkflow",
    autocompleteRule: "autocompleteRule",
    calcDestObjects: "CalcDestObjects",
    calcQueues: "CalcQueues",
    checkResolutionStatus: "checkResolutionStatus",
    cisLookupMethod: "cisLookupMethod",
    constraintRule: "constraintRule",
    contentType: "contentType",
    correspondenceType: "correspondenceType",
    customerClass: "customerClass",
    customerTargeted: "customerTargeted",
    customerType: "customerType",
    equipmentValidation: "equipmentValidation",
    fielddescriptor: "fielddescriptor",
    financing: "financing",
    formfieldvalidation: "formfieldvalidation",
    fuel: "fuel",
    goalType: "goal",
    incentiveOffered: "incentiveOffered",
    invoiceFlagWorkflow: "invoiceflagworkflow",
    marketingChannelsUsed: "marketingChannelsUsed",
    marketingLead: "marketingLead",
    measureType: "measureType",
    programAttribute: "programAttribute",
    programClassification: "programClassification",
    programPurpose: "programPurpose",
    programType: "programType",
    qcType: "qctype",
    qcStatus: "qcstatus",
    rebateControl: "rebateControl",
    rebateExport: "rebateExport",
    rebateFulfillmentChannel: "rebateFulfillmentChannel",
    rebatePayee: "rebatePayee",
    rebateState: "rebatestate",
    rebateStatus: "rebatestatus",
    rebateType: "rebatetype",
    resourceTargeted: "resourceTargeted",
    sectorTargeted: "sectorTargeted",
    storedProcedure: "ICF_WF_SPs",
    targetAgeOfFacility: "facilityTargetAge",
    targetGeography: "targetGeography",
    trueFalse: "truefalse",
    userCompanyName: "userCompanyName",
    userContact: "usercontact",
    utilityAttribute: "utilityAttribute",
    webDeliveryClass: "webDelivery",
    WFProcessMode: "WFProcessMode",
    whoSellsMeasures: "whoSellsMeasures",
    workflowItem: "workflowitem",
    workflowStatusGroup: "workflowStatusGroup",
    groupType: "groupType",
    genericStatus: "genericstatus",
};
