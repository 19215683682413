import React from "react";
import ClassNames from "classnames";
import useFocusClasses from "../../utils/useFocusClasses";
import useEnterKey from "../../utils/useEnterKey";

import "./style.scss";

const TriggerButton = (props) => {
    const isTabable = !props.disabled && Boolean(props.onClick);
    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled: !isTabable,
    });
    const [onEnter] = useEnterKey({
        disabled: !isTabable,
        onClick: props.onClick,
    });

    const className = ClassNames("btn btn-rounded btn-trigger", props.className, {
        active: props.active,
        disabled: props.disabled,
    });

    return (
        <div
            className={className}
            onClick={props.onClick}
            tabIndex={isTabable ? "0" : "-1"}
            onFocus={onFocusClassesFocus}
            onBlur={onFocusClassesBlur}
            onKeyDown={onEnter}
        >
            {props.children}
        </div>
    );
};

export default TriggerButton;
