import React, { memo, useState } from "react";
import { get, isObject, isString } from "lodash";
import { useProgramForm, useProgramFormPages, useProgramFormPage } from "store/resources/useResource";
import WaitIcon from "../WaitIcon";
import DropDownInput from "../Input/DropDownInput";
import Separator from "../Separator";
import ButtonGroup from "../Button/ButtonGroup";
import Button from "../Button";
import { copyFieldToPage } from "./utils";
import { useDispatch, useStore } from "react-redux";
import { savePage, setActivePage } from "store/pages/actions";
import { openConfirmModal, openErrorModal, openWaitingModal } from "../Modal/utils";
import { removeElement } from "store/formBuilder/actions";

const MoveFieldToOtherPageForm = memo(({ instanceId, programNumber, formBuilder, onCancel }) => {
    const dispatch = useDispatch();
    const store = useStore();

    const [state, setState] = useState({
        pageNumber: undefined,
        sectionKey: undefined,
        columnKey: undefined,
    });

    const sourcePageNumber = formBuilder.uiSchema["af:pageNumber"];

    const [pages, isLoadingPages] = useFormPages({ programNumber });
    const [page, isLoadingPage] = useProgramFormPage({
        programNumber,
        pageNumber: state.pageNumber,
    });

    const onPageChange = (event) => {
        setState((prevState) => ({
            ...prevState,
            pageNumber: event.target.value,
            sectionKey: undefined,
            columnKey: undefined,
        }));
    };

    const onSectionChange = (event) => {
        setState((prevState) => ({
            ...prevState,
            sectionKey: event.target.value,
            columnKey: undefined,
        }));
    };

    const onColumnChange = (event) => {
        setState((prevState) => ({
            ...prevState,
            columnKey: event.target.value,
        }));
    };

    const onMove = async () => {
        openWaitingModal({
            title: "Moving field...",
        });

        const onError = (message) => {
            openErrorModal({ text: isString(message) ? message : "Failed to move field." });
        };

        try {
            // copy Field to target form
            const targetPage = copyFieldToPage({
                formBuilder,
                targetPage: page,
                targetSectionKey: state.sectionKey,
                targetColumnKey: state.columnKey,
            });

            // Save target form
            dispatch(
                savePage({
                    programNumber,
                    schema: targetPage.configuration.schema,
                    uiSchema: targetPage.configuration.uiSchema,
                    rules: targetPage.configuration.rules,
                    onSuccess: (savedTargetPage) => {
                        // Remove field from source form
                        dispatch(
                            removeElement({
                                instanceId: formBuilder.uiSchema["af:pageNumber"],
                                elementId: formBuilder.selectedElementId,
                            })
                        );

                        const updatedPage = store.getState().formBuilder[instanceId];

                        if (!updatedPage) {
                            return;
                        }

                        // Save source form
                        dispatch(
                            savePage({
                                programNumber,
                                schema: updatedPage.schema,
                                uiSchema: updatedPage.uiSchema,
                                rules: updatedPage.rules,
                                onSuccess: (savedSourcePage) => {
                                    // Ask if want to open target page
                                    openConfirmModal({
                                        title: "Move field to other form page",
                                        message: "Do you want to open target page?",
                                        onConfirm: () => {
                                            dispatch(
                                                setActivePage({
                                                    id: programNumber,
                                                    page: savedTargetPage,
                                                })
                                            );
                                        },
                                    });
                                },
                                onError,
                            })
                        );
                    },
                    onError,
                })
            );
        } catch (error) {
            onError(error?.message);
        }
    };

    if (isLoadingPages) {
        return <WaitIcon />;
    }

    const pagesList = (pages ?? []).map((i) => ({
        label: i.name,
        value: i.number,
        disabled: i.number === sourcePageNumber,
    }));

    const sectionsList = getFormSections(page?.configuration);
    const columnList = getFormSectionColumns(page?.configuration, state.sectionKey);

    const canMove =
        state.pageNumber &&
        !isLoadingPage &&
        (state.sectionKey || sectionsList.length === 0) &&
        (state.columnKey || columnList.length === 0);

    return (
        <div className="move-field-to-other-page-form">
            <DropDownInput label="Form Page" placeholder="-- SELECT --" data={pagesList} value={state.pageNumber} onChange={onPageChange} />
            {isLoadingPage && <WaitIcon />}
            <Separator />
            {sectionsList.length > 0 && (
                <>
                    <DropDownInput
                        label="Section"
                        placeholder="-- SELECT --"
                        data={sectionsList}
                        value={state.sectionKey}
                        onChange={onSectionChange}
                    />
                    <Separator />
                </>
            )}
            {columnList.length > 0 && (
                <>
                    <DropDownInput
                        label="Column"
                        placeholder="-- SELECT --"
                        data={columnList}
                        value={state.columnKey}
                        onChange={onColumnChange}
                    />
                    <Separator />
                </>
            )}
            <ButtonGroup transparent flex>
                <span className="flex-one" />
                <Button primary disabled={!canMove} onClick={onMove}>
                    Move
                </Button>
                <Button onClick={onCancel}>Cancel</Button>
            </ButtonGroup>
        </div>
    );
});

const useFormPages = ({ programNumber }) => {
    const [form, isLoadingProgramForm] = useProgramForm({
        programNumber,
        forced: false,
    });

    const formNumber = form?.formNumber;
    const [pages, isLoadingPages] = useProgramFormPages({
        programNumber,
        formNumber,
    });

    const isLoading = isLoadingProgramForm || isLoadingPages;

    return [pages, isLoading];
};

const getFormSections = (configuration = {}) => {
    if (!isObject(configuration?.schema)) {
        return [];
    }

    return Object.keys(configuration.schema.properties).map((sectionKey) => {
        return {
            label: configuration.schema.properties[sectionKey].title,
            value: sectionKey,
        };
    });
};

const getFormSectionColumns = (configuration = {}, sectionKey) => {
    const columns = get(configuration, `schema.properties[${sectionKey}].properties`);

    if (!isObject(columns)) {
        return [];
    }

    return Object.keys(columns).map((columnKey, index) => ({
        label: configuration.schema.properties[sectionKey].properties[columnKey].title || `column ${index + 1}`,
        value: columnKey,
    }));
};

export default MoveFieldToOtherPageForm;
