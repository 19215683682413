import React, { memo, useMemo, useCallback, useEffect, useId } from "react";
import { useSelector } from "react-redux";
import ClassNames from "classnames";

import { dispatchEvent } from "../../utils/dom";

import TabHome from "./WindowTab/TabHome";
import WindowTab from "./WindowTab";
import ScrollControls from "../ScrollControls";

import "./WindowTabs.scss";
import IconWrap from "../Icons";

const WindowTabs = memo(({ containerName, layoutType, isSplitView, onWindowActivate, onWindowClose, onWindowSync, onAllWindowsClose }) => {
    const scrollId = useId();

    const userSettings = useSelector((state) => state.user?.settings);

    const isDesktop = useSelector((state) => state.window?.isDesktop);

    const views = useSelector((state) => state.window[containerName])?.views || [];
    const tabs = views.filter((i) => i.showTab !== false);

    const activeTabIndex = useMemo(
        () =>
            tabs.reduce((activeIndex, tab, index) => {
                if (tab.active) {
                    activeIndex = index;
                }

                // Increment by one because of static Home tab.
                return activeIndex + 1;
            }, undefined),
        [tabs]
    );

    const onClose = useCallback(
        (props) => {
            onWindowClose(props);
            const element = document.getElementById(scrollId);
            if (element) {
                dispatchEvent(element, "scroll");
            }
        },
        [onWindowClose, scrollId]
    );

    const windowTabsClass = ClassNames("window-tabs", {
        "layout-top": layoutType === "top-right",
        "layout-left": layoutType === "left-right" && !isSplitView && isDesktop,
        "split-view": isSplitView,
    });

    useEffect(() => {
        var tab = document.getElementsByClassName("window-tab active")[0];
        tab &&
            tab.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
    }, [tabs]);

    if (!tabs || tabs.length === 0) {
        return;
    }

    return (
        <div className={windowTabsClass}>
            <div className="main-grid-wrap responsive">
                <div className="window-tabs-container">
                    <TabHome />
                    <div id={scrollId} className="window-tabs-wrapper">
                        {tabs.map((view, index) => (
                            <WindowTab
                                key={view.name}
                                index={index}
                                containerName={containerName}
                                viewName={view.name}
                                scrollId={scrollId}
                                onClick={onWindowActivate}
                                onClose={onClose}
                                onSync={onWindowSync}
                            />
                        ))}
                    </div>
                    <ScrollControls key={tabs.length} targetId={scrollId} activeItemIndex={activeTabIndex} />
                    {tabs.length > 3 && (
                        <div className="close-all-tabs-button-wrapper flex-row justify-end align-center">
                            <IconWrap
                                className="close-all-tabs-button"
                                iconWrapRoundedSquare
                                iconWrapWhiteInverse={userSettings?.themeMode === "Normal"}
                                iconWrapWhite
                                iconWrapBig
                                icon="remove"
                                title="Close All Tabs"
                                onClick={onAllWindowsClose}
                            />
                            <span className="close-text">
                                Close <br /> all <br /> tabs
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});

export default WindowTabs;
