import { isNil } from "lodash";
import { Dispatch } from "redux";
import { updateResource } from "store/resources/actions";
import {
    EntityRolesState,
    ClientProgramsUtility,
    RoleState,
    RolesState,
    UserRole,
    EntityRolesStateItem,
    UserRolesEntityType,
    UserRolesUpdateItem,
} from "./types";

export const getInitialEntityRolesState = (roles: UserRole[], clientPrograms: ClientProgramsUtility[]) => {
    const rolesState: EntityRolesState = {};

    clientPrograms.forEach((utility) => {
        const utilityRolesState = new UtilityRolesState(utility, roles);
        utility.programList.forEach(
            (program) => (rolesState[program.programNumber] = utilityRolesState.getProgramRolesState(program.programNumber))
        );
        rolesState[utility.utilityNumber] = utilityRolesState.getUtilityRolesState();
    });

    return rolesState;
};

// Update entity role. If entity is utility the update all utility programs that have the same role.
export const updateEntityRolesState = (
    roleID: number,
    entityNumber: string,
    entityRolesState: EntityRolesState,
    utility: ClientProgramsUtility
): EntityRolesState => {
    const newEntityRolesState: EntityRolesState = { ...entityRolesState };
    const entityRolesStateItem = entityRolesState[entityNumber];

    // Change all programs that have the same role as for utility. Change only filtered programs.
    if (entityRolesStateItem.entityType === UserRolesEntityType.Utility) {
        const activeUtilityRoleID = getActiveRoleID(entityRolesStateItem.rolesState);
        utility.filteredProgramList.forEach((program) => {
            if (newEntityRolesState[program.programNumber].rolesState[activeUtilityRoleID] === RoleState.On) {
                newEntityRolesState[program.programNumber] = {
                    ...newEntityRolesState[program.programNumber],
                    rolesState: changeRolesStateRole(newEntityRolesState[program.programNumber].rolesState, roleID, RoleState.On),
                };
            }
        });
    }

    // Change clicked utility/program role.
    newEntityRolesState[entityNumber] = {
        ...entityRolesStateItem,
        rolesState: changeRolesStateRole(entityRolesStateItem.rolesState, roleID, RoleState.On),
    };

    // Check "indeterminate" state for unfiltered utility.
    newEntityRolesState[utility.utilityNumber] = checkIndeterminateRoleState(newEntityRolesState, utility);

    return newEntityRolesState;
};

// If all program roles are "on" then change utility role state to "on", otherwise change to "indeterminate".
const checkIndeterminateRoleState = (entityRolesState: EntityRolesState, utility: ClientProgramsUtility): EntityRolesStateItem => {
    const utilityRolesState = entityRolesState[utility.utilityNumber].rolesState;
    const activeUtilityRoleID = getActiveRoleID(utilityRolesState);
    const utilityRoleState = utility.programList.every(
        (program) => entityRolesState[program.programNumber].rolesState[activeUtilityRoleID] === RoleState.On
    )
        ? RoleState.On
        : RoleState.Indeterminate;

    return {
        entityType: UserRolesEntityType.Utility,
        rolesState: { ...utilityRolesState, [activeUtilityRoleID]: utilityRoleState },
        parentEntityNumber: null,
    };
};

// Change role to new client role for all utilities that have active previous client role
export const updateClientRole = (
    oldClientRoleId: number | undefined,
    newClientRoleId: number,
    entityRolesState: EntityRolesState,
    clientPrograms: ClientProgramsUtility[]
) => {
    const newEntityRolesState: EntityRolesState = { ...entityRolesState };

    Object.keys(newEntityRolesState).forEach((entityNumber) => {
        const entityRolesStateItem = newEntityRolesState[entityNumber];

        // Start changing roles from utility level
        if (entityRolesStateItem.entityType === UserRolesEntityType.Utility) {
            if (
                !isNil(oldClientRoleId) &&
                [RoleState.On, RoleState.Indeterminate].includes(entityRolesStateItem.rolesState[oldClientRoleId])
            ) {
                newEntityRolesState[entityNumber] = {
                    entityType: UserRolesEntityType.Utility,
                    rolesState: changeRolesStateRole(
                        entityRolesStateItem.rolesState,
                        newClientRoleId,
                        entityRolesStateItem.rolesState[oldClientRoleId]
                    ),
                    parentEntityNumber: null,
                };

                // Update utility programs that have old client role
                clientPrograms
                    .filter((utility) => utility.utilityNumber === entityNumber)
                    .flatMap((utility) => utility.programList)
                    .forEach((program) => {
                        if (newEntityRolesState[program.programNumber].rolesState[oldClientRoleId] === RoleState.On) {
                            newEntityRolesState[program.programNumber] = {
                                entityType: UserRolesEntityType.Program,
                                rolesState: changeRolesStateRole(
                                    newEntityRolesState[program.programNumber].rolesState,
                                    newClientRoleId,
                                    newEntityRolesState[program.programNumber].rolesState[oldClientRoleId]
                                ),
                                parentEntityNumber: entityNumber,
                            };
                        }
                    });
            } else if (isNil(oldClientRoleId)) {
                // Set utility role
                newEntityRolesState[entityNumber] = {
                    entityType: UserRolesEntityType.Utility,
                    rolesState: changeRolesStateRole(entityRolesStateItem.rolesState, newClientRoleId, RoleState.On),
                    parentEntityNumber: null,
                };

                // Set role for utility programs
                clientPrograms
                    .filter((utility) => utility.utilityNumber === entityNumber)
                    .flatMap((utility) => utility.programList)
                    .forEach((program) => {
                        newEntityRolesState[program.programNumber] = {
                            entityType: UserRolesEntityType.Program,
                            rolesState: changeRolesStateRole(
                                newEntityRolesState[program.programNumber].rolesState,
                                newClientRoleId,
                                RoleState.On
                            ),
                            parentEntityNumber: entityNumber,
                        };
                    });
            }
        }
    });

    return newEntityRolesState;
};

// Set role for all utilities and programs. Change only for filtered entities.
export const setRoleForAllEntities = (roleID: number, entityRolesState: EntityRolesState, clientPrograms: ClientProgramsUtility[]) => {
    const filteredEntityList = clientPrograms.flatMap((utility) =>
        [utility.utilityNumber].concat(utility.filteredProgramList.map((program) => program.programNumber))
    );

    const newEntityRolesState: EntityRolesState = { ...entityRolesState };
    Object.keys(newEntityRolesState)
        .filter((entityNumber) => filteredEntityList.includes(entityNumber))
        .forEach((entityNumber) => {
            const entityRolesStateItem = newEntityRolesState[entityNumber];
            newEntityRolesState[entityNumber] = {
                ...entityRolesStateItem,
                rolesState: changeRolesStateRole(entityRolesStateItem.rolesState, roleID, RoleState.On),
            };
        });

    // Check "indeterminate" state for all utilities.
    clientPrograms.forEach((utility) => {
        newEntityRolesState[utility.utilityNumber] = checkIndeterminateRoleState(newEntityRolesState, utility);
    });

    return newEntityRolesState;
};

// Unselect existing role and set client role for all entities. Change only for filtered entities.
export const unselectRoleForAllEntities = (
    roleID: number,
    clientRoleId: number,
    entityRolesState: EntityRolesState,
    clientPrograms: ClientProgramsUtility[]
) => {
    const filteredEntityList = clientPrograms.flatMap((utility) =>
        [utility.utilityNumber].concat(utility.filteredProgramList.map((program) => program.programNumber))
    );

    const newEntityRolesState: EntityRolesState = { ...entityRolesState };
    Object.keys(newEntityRolesState)
        .filter((entityNumber) => filteredEntityList.includes(entityNumber))
        .forEach((entityNumber) => {
            const entityRolesStateItem = newEntityRolesState[entityNumber];

            if ([RoleState.On, RoleState.Indeterminate].includes(entityRolesStateItem.rolesState[roleID])) {
                newEntityRolesState[entityNumber] = {
                    ...entityRolesStateItem,
                    rolesState: changeRolesStateRole(
                        entityRolesStateItem.rolesState,
                        clientRoleId,
                        entityRolesStateItem.rolesState[roleID]
                    ),
                };
            }
        });

    // Check "indeterminate" state for all utilities.
    clientPrograms.forEach((utility) => {
        newEntityRolesState[utility.utilityNumber] = checkIndeterminateRoleState(newEntityRolesState, utility);
    });

    return newEntityRolesState;
};

export const filterClientPrograms = (clientPrograms: ClientProgramsUtility[], searchTerm: string) => {
    const result: ClientProgramsUtility[] = [];

    clientPrograms.forEach((utility) => {
        const filteredUtility = { ...utility };
        filteredUtility.filteredProgramList = utility.programList.filter((program) =>
            program.programName.toLowerCase().includes(searchTerm.toLowerCase())
        );

        // Add utility if it has programs that match the search term
        if (filteredUtility.filteredProgramList.length > 0) {
            result.push(filteredUtility);
            // Add utility if it has no programs that match the search term but the utility name matches the search term
        } else if (utility.utilityName.toLowerCase().includes(searchTerm.toLowerCase())) {
            result.push(filteredUtility);
        }
    });

    return result;
};

export const saveRolesState = async (
    userNumber: string,
    clientRoleId: number,
    clientNumber: string,
    entityRolesState: EntityRolesState,
    dispatch: Dispatch<any>
) => {
    /**
     * create client role
     * create utility roles that differ from client
     * create program roles that differ from utility
     */

    // Client role
    const clientRole: UserRolesUpdateItem = {
        clientRoleId,
        entityNumber: clientNumber,
        entityTypeId: UserRolesEntityType.Client,
    };

    const requestBody: UserRolesUpdateItem[] = [clientRole];

    Object.keys(entityRolesState).forEach((entityNumber) => {
        const rolesState = entityRolesState[entityNumber].rolesState;
        const entityTypeId = entityRolesState[entityNumber].entityType;

        // Utility role
        if (entityTypeId === UserRolesEntityType.Utility) {
            const activeRoleID = Object.keys(rolesState).find((key) =>
                [RoleState.On, RoleState.Indeterminate].includes(rolesState[Number(key)])
            );

            if (!isNil(activeRoleID) && Number(activeRoleID) !== clientRoleId) {
                const utilityRole: UserRolesUpdateItem = {
                    clientRoleId: Number(activeRoleID),
                    entityNumber,
                    entityTypeId: UserRolesEntityType.Utility,
                };

                requestBody.push(utilityRole);
            }
        }
        // Program role
        else {
            const activeRoleID = Object.keys(rolesState).find((key) => [RoleState.On].includes(rolesState[Number(key)]));
            const utilityNumber = entityRolesState[entityNumber].parentEntityNumber;
            const utilityRolesState = entityRolesState[utilityNumber!].rolesState;
            const utilityRoleID = Object.keys(utilityRolesState).find((key) =>
                [RoleState.On, RoleState.Indeterminate].includes(utilityRolesState[Number(key)])
            );

            if (!isNil(activeRoleID) && activeRoleID !== utilityRoleID) {
                const programRole: UserRolesUpdateItem = {
                    clientRoleId: Number(activeRoleID),
                    entityNumber,
                    entityTypeId: UserRolesEntityType.Program,
                };

                requestBody.push(programRole);
            }
        }
    });

    return new Promise((resolve, reject) => {
        dispatch(
            updateResource({
                resourceName: "userRoles",
                path: {
                    userNumber,
                },
                body: requestBody,
                onSuccess: resolve,
                onError: reject,
                onComplete: undefined,
                resourceId: undefined,
                errorMessage: undefined,
                successMessage: "User roles updated successfully",
                optimisticUpdate: undefined,
                skipErrorReport: undefined,
            })
        );
    });
};

export const changeRolesStateRole = (rolesState: RolesState, roleID: number, newState: RoleState) => {
    const newRolesState = Object.keys(rolesState).reduce((acc, key: string) => {
        if (Number(key) === roleID) {
            return { ...acc, [Number(key)]: newState };
        }
        return { ...acc, [Number(key)]: RoleState.Off };
    }, {});

    return newRolesState;
};

export const getActiveRoleID = (rolesState: RolesState) => {
    const activeRoleID = Object.keys(rolesState).find((key) => [RoleState.On, RoleState.Indeterminate].includes(rolesState[Number(key)]));
    return Number(activeRoleID);
};

class UtilityRolesState {
    utility: ClientProgramsUtility;
    roles: UserRole[];

    constructor(utility: ClientProgramsUtility, roles: UserRole[]) {
        this.utility = utility;
        this.roles = roles;
    }

    getProgramRolesState(programNumber: string): EntityRolesStateItem {
        const rolesState = this.roles.reduce(
            (result, role) => (result = { ...result, [role.roleID]: this.getProgramRoleState(programNumber, role) }),
            {}
        );

        return {
            entityType: UserRolesEntityType.Program,
            rolesState,
            parentEntityNumber: this.utility.utilityNumber,
        };
    }

    getUtilityRolesState(): EntityRolesStateItem {
        const rolesState = this.roles.reduce((result, role) => (result = { ...result, [role.roleID]: this.getUtilityRoleState(role) }), {});

        return {
            entityType: UserRolesEntityType.Utility,
            rolesState,
            parentEntityNumber: null,
        };
    }

    protected getProgramRoleState(programNumber: string, role: UserRole): RoleState {
        const isAssigned = !isNil(role.programs.find((p) => p.entityNumber === programNumber));

        return isAssigned ? RoleState.On : RoleState.Off;
    }

    protected getUtilityRoleState(role: UserRole): RoleState {
        const isAssigned = !isNil(role.utilities.find((u) => u.entityNumber === this.utility.utilityNumber));
        const isAllProgramsAssigned = this.utility.programList.every(
            (p) => this.getProgramRoleState(p.programNumber, role) === RoleState.On
        );

        return isAssigned ? (isAllProgramsAssigned ? RoleState.On : RoleState.Indeterminate) : RoleState.Off;
    }
}
