import React, { memo } from "react";

import { mapGridRowToObject, SortType } from "../../../../../utils/datagrid";
import { availableGrids } from "../../../../../views/configureGrids";
import { getBudgetLinesInvoicesResourceParams } from "store/configureResources";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { systemUserRights } from "components/utils/user";
import { useUtilityRights } from "../../../../../../store/resources/useResource";

import WaitIcon from "../../../../WaitIcon";
import DataGrid from "../../../../DataGrid";
import Form from "./Form";
import { useDataGrid } from "components/utils/useDataGrid";

const budgetLineInvoicesGridColumnKeys = {
    refId: "c_0f234bb9-8ee1-4835-93b7-19025a891039",
    itemName: "c_822f792b-6628-4d76-a06e-0ac7e503f730",
    billingDate: "c_a5a8db45-a3a1-4ccb-84b9-87768b6e08d9",
    dueDate: "c_ab07a064-2421-4f32-aaee-de247b5d64c9",
    invoiceStartDate: "c_9294214d-daf2-4274-adcb-7803ca1b35ea",
    invoiceEndDate: "c_ba1ab9e5-083e-4365-8385-b2071672b21e",
    amountDue: "c_c24c76b6-5672-4648-9c10-07fd67a9262b",
    amountRequested: "c_46e8bfe3-0d06-4462-bea4-096f76cf6b33",
    amountAuthorized: "c_79fa85c7-604b-49e8-a879-e22083c4fc63",
    invoiceNumber: "c_665ac4c1-c3ec-42f3-87cb-299756257f7f",
    budgetLineNumber: "c_5c1b6d0d-d6f0-4e4d-803e-b25b3d0f602b",
    islocked: "c_ca0d536c-cd2e-4fe0-8253-9f30ac6d090c",
    dateEntered: "c_fccdd1d3-d709-4fa9-afb5-9fbdea33ac58",
    dateEdited: "c_4b43714f-95c7-4ab5-83ec-c428c28998e3",
    itemDescription: "c_9bf2a5a6-fa82-46e4-af3a-f966e1705c2a",
    itemNumber: "c_ab528c7e-abce-47fb-aa3d-684198e26cef",
    itemCode: "c_398e6a1b-b14c-400c-85a7-ad097be9081d",
};

const Grid = memo(({ dataGridId, utilityNumber, contractNumber, budgetLineNumber, onRefresh }) => {
    const [utilityRights, isLoadingRights] = useUtilityRights({ utilityNumber });

    const canEdit = utilityRights.includes(systemUserRights.VISIONDSM_ADD_INVOICE);

    const rowActions = [
        {
            name: "edit",
            icon: "edit-empty",
            title: "Edit Invoice Document",
            footerExclude: true,
            disabled: !canEdit,
        },
    ];

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "budget-invoices-sidenav-panel",
    });

    const [gridConfig, isLoading] = useDataGrid({
        dataGridId: availableGrids.budgetLineInvoices,
        dataGridInstanceId: dataGridId,
        sorting: SortType.CLIENT,
        getDataAction: {
            type: "resourceGetDataAction",
            props: {
                dataGridId,
                columnKeysMap: budgetLineInvoicesGridColumnKeys,
                resourceParams: getBudgetLinesInvoicesResourceParams({
                    utilityNumber,
                    contractNumber,
                    budgetLineNumber,
                }),
            },
        },
    });

    const onRowAction = (action) => {
        const { name, dataItem } = action;

        const data = mapGridRowToObject(budgetLineInvoicesGridColumnKeys, dataItem);

        switch (name) {
            case "edit":
                handleOpenSidePanel(
                    <Form
                        dataItem={data}
                        utilityNumber={utilityNumber}
                        contractNumber={contractNumber}
                        budgetLineNumber={budgetLineNumber}
                        gridRefresh={onRefresh}
                        onClose={handleCloseSidePanel}
                    />
                );
                break;

            default:
                break;
        }
    };

    if (isLoading || isLoadingRights) {
        return <WaitIcon />;
    }

    return <DataGrid name={dataGridId} config={gridConfig} onRowAction={onRowAction} customRowActions={rowActions} />;
});

export default Grid;
