import React, { useState, memo, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { RESET_PASSWORD_ERROR, RESET_PASSWORD_ERROR_CLEAR } from "../../../store/actionTypes";

import Form from "../../ui/Form";
import TextInput from "../../ui/Input/TextInput";
import Button from "../../ui/Button";
import Captcha from "../../ui/Captcha";
import LoginFormWrap from "../../ui/LoginFormWrap";
import { ErrorMessage } from "../../ui/Message";

const ResetPasswordForm = memo(({ title, passwordHelpText, twoFactorRequired, twoFactorQrCodeExists, onSubmit, redirectToLogin }) => {
    const dispatch = useDispatch();

    const captchaRef = useRef();

    const isLoading = useSelector((state) => state.resetPassword.isLoading);
    const message = useSelector((state) => state.resetPassword.message);

    const isError = (message || "").length > 0;

    const [authCode, setAuthCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const [recaptcha, setRecaptcha] = useState("");

    const isValid = useMemo(() => {
        let valid = newPassword?.length > 0 && newPassword2?.length > 0 && recaptcha?.length > 0 && newPassword === newPassword2;

        if (twoFactorRequired && twoFactorQrCodeExists) {
            valid = valid && (authCode || "").trim().length > 0;
        }

        return valid;
    }, [newPassword, newPassword2, recaptcha, authCode, twoFactorRequired, twoFactorQrCodeExists]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isValid) {
            onSubmit({
                authCode,
                newPassword,
                recaptcha,
            });

            captchaRef.current && captchaRef.current.reset();
        } else {
            // show error if passwords do not match
            if (newPassword !== newPassword2) {
                dispatch({
                    type: RESET_PASSWORD_ERROR,
                    message: "Passwords do not match",
                });
            }
        }
    };

    const clearError = () => {
        dispatch({
            type: RESET_PASSWORD_ERROR_CLEAR,
        });
    };

    return (
        <LoginFormWrap>
            <Form onSubmit={handleSubmit}>
                {isError && <ErrorMessage msgSquare>{message}</ErrorMessage>}
                <h3>{title}</h3>
                <TextInput
                    required
                    label="New password"
                    name="newPassword"
                    type="password"
                    placeholder="Enter your new password"
                    error={isError}
                    msgText={passwordHelpText}
                    onChange={(e) => {
                        clearError();
                        setNewPassword(e.target.value);
                    }}
                />
                <TextInput
                    required
                    label="Confirm password"
                    name="newPassword2"
                    type="password"
                    placeholder="Confirm your new password"
                    error={isError}
                    onChange={(e) => {
                        clearError();
                        setNewPassword2(e.target.value);
                    }}
                />
                {twoFactorRequired && twoFactorQrCodeExists && (
                    <TextInput
                        required
                        label="Authentication Code"
                        name="authCode"
                        type="password"
                        placeholder="Enter authentication code"
                        error={isError}
                        onChange={(e) => {
                            clearError();
                            setAuthCode(e.target.value);
                        }}
                    />
                )}
                <Captcha captchaRef={captchaRef} label="recaptcha" name="recaptcha" onChange={setRecaptcha} />
                <div className="form-btn-wrap password">
                    <span className="login-form-link" onClick={redirectToLogin}>
                        Return to login page
                    </span>
                    <span className="flex-one"></span>
                    <Button type="submit" primary disabled={!isValid || isLoading}>
                        Save new password
                    </Button>
                </div>
            </Form>
        </LoginFormWrap>
    );
});

export default ResetPasswordForm;
