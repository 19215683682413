import React, { memo, useState, useRef } from "react";

import useOnKey from "../../utils/useOnKey";
import useOnClickOutside from "../../utils/useOnClickOutside";

import FieldGroup from "../FieldGroup";
import FieldWrap from "../FieldWrap";
import DropdownList from "../DropdownList";
import DropdownListItem from "../DropdownList/DropdownListItem";
import cn from "classnames";

import IconWithLabel from "../Icons//IconWithLabel";

import "./ActionLabelWithDropdown.scss";
import DropdownListWrapper from "../DropdownList/DropdownListWrapper";
import usePopper from "components/utils/usePopper";

const ActionLabelWithDropdown = memo((props) => {
    const triggerRef = useRef();
    const popupRef = useRef();
    const popperRef = useRef();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const close = (event) => {
        if (isDropdownOpen && (!event || event.target !== triggerRef.current)) {
            setIsDropdownOpen(false);
        }
    };

    const onLabelClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const onItemClick = (item) => {
        props.onClick(item);
        close();
    };

    useOnKey("Escape", close);
    useOnClickOutside(popupRef, close);
    usePopper({
        triggerRef,
        popperRef,
        popupRef,
        placement: "bottom-start",
        enabled: props.popperEnabled,
        modifiers: [],
    });

    return (
        <FieldGroup className="label-with-dropdown" inline>
            <FieldWrap iconDropdown>
                <IconWithLabel
                    active={isDropdownOpen}
                    iconWithLabel={props.iconWithLabel}
                    iconWithLabelRight={props.iconWithLabelRight}
                    elementRef={triggerRef}
                    onClick={onLabelClick}
                >
                    {props.label}
                </IconWithLabel>
                <DropdownListWrapper
                    triggerRef={triggerRef}
                    popupRef={popupRef}
                    popperRef={popperRef}
                    enabled={props.popperEnabled}
                    visible={isDropdownOpen}
                    zIndexOverride={props.zIndexOverride}
                >
                    <DropdownList
                        alone
                        mobileHeader={props.mobileHeader}
                        dropdownFixedWidth={props.dropdownFixedWidth}
                        elementRef={popupRef}
                        withTitle={props.withTitle}
                        visible={isDropdownOpen}
                        dropdownRight={props.dropdownRight}
                        onClose={close}
                    >
                        {props.items.map((item, i) => (
                            <DropdownListItem
                                key={i}
                                className={cn({
                                    selected: item.status === props.label,
                                })}
                                selected={item.selected}
                                icon={item.icon}
                                onClick={() => onItemClick(item)}
                            >
                                {item.status}
                            </DropdownListItem>
                        ))}
                    </DropdownList>
                </DropdownListWrapper>
            </FieldWrap>
        </FieldGroup>
    );
});

export default ActionLabelWithDropdown;
