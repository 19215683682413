import { isEmpty } from "lodash";

/// expecting data as per the cisCustomerDetails
export const parseCisDetails = (data) => {
    const rawData = data?.custRawData;

    if (!rawData) {
        return {};
    } else {
        const contact = rawData.reduce((result, item) => {
            const key = getInfoListItemKey(item);
            const value = getInfoListItemValue(item);

            result[key] = value;

            return result;
        }, {});

        const hasMailingAddress = !!contact["MAILINGADDRESS"]?.trim();

        return {
            accountNumber: contact["ACCT_NUMBER"],
            acct_number: contact["ACCT_NUMBER"],
            company: contact["PREMISECOMPANY"],
            firstname: contact["FIRSTNAME"],
            lastname: contact["LASTNAME"],
            address: contact["PREMISEADDRESS"],
            city: contact["PREMISECITY"],
            state: contact["PREMISESTATE"],
            zip: contact["PREMISEZIP"],
            phone: contact["PREMISEPHONE"],
            email: contact["PREMISEEMAIL"],
            premiseid: contact["PREMISEID"],
            meterid: contact["METERID"],
            mailingAddress: hasMailingAddress ? contact["MAILINGADDRESS"] : "",
            mailingCity: hasMailingAddress ? contact["MAILINGCITY"] : "",
            mailingState: hasMailingAddress ? contact["MAILINGSTATE"] : "",
            mailingZip: hasMailingAddress ? contact["MAILINGZIP"] : "",
            mailingPhone: hasMailingAddress ? contact["MAILINGPHONE"] : "",
        };
    }
};

export const getCisGridId = ({ utilityNumber }) => {
    return `${utilityNumber}-cis-lookup`;
};

export const getInfoListItemKey = (item) => item.Key ?? item.key;

export const getInfoListItemValue = (item) => {
    const value = item.Value ?? item.value;
    return isEmpty(value) ? undefined : value;
};
