import { isEmpty } from "lodash";
import ViewPlaceholder from "components/ui/ViewPlaceholder";
import { ErrorMessage } from "components/ui/Message";
import { useTRMulatorSsoUrl } from "components/utils/window";
import WaitIcon from "components/ui/WaitIcon";

const TRMulatorView = () => {
    const [url, isLoading] = useTRMulatorSsoUrl();

    if (isLoading) {
        return (
            <ViewPlaceholder>
                <WaitIcon />
            </ViewPlaceholder>
        );
    }

    if (isEmpty(url)) {
        return (
            <ViewPlaceholder>
                <ErrorMessage>TRMulator login failed</ErrorMessage>
            </ViewPlaceholder>
        );
    }

    return (
        <div className="no-scroll fill-height">
            <iframe className="fill-width fill-height border-0" title="TRMulator" src={url}></iframe>
        </div>
    );
};

export default TRMulatorView;
