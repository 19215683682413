import { createId } from "components/utils/string";
import { ElementTypes, submitByRefPromise } from "components/utils/form";
import { clearValidationError, registerPropertiesForm, setValidationError, unregisterPropertiesForm } from "./actions";
import { useEffect } from "react";
import { YesNo } from "components/utils/constants";

export const DEFAULT_PAGE_PROP_DEFAULTPAGE = 329;
export const DEFAULT_PAGE_PROP_STATUS = 213;

export const FIELD_DESCRIPTOR_TEXT = "67";
export const FIELD_DESCRIPTOR_DB = "68";

export const createPage = () => {
    const pageNumber = createId();

    const schema = {
        title: "Form page",
        type: "object",
        properties: {},
    };

    const uiSchema = {
        classNames: ElementTypes.PAGE,
        "ui:elementType": ElementTypes.PAGE,
        "af:pageNumber": pageNumber,
        "af:runAppEntryStatusInWizard": YesNo.No,
        "af:runAppReceivedStatusInWizard": YesNo.No,
        "af:showFormPageOnApp": YesNo.No,
        "af:defaultPage": DEFAULT_PAGE_PROP_DEFAULTPAGE,
        "af:status": DEFAULT_PAGE_PROP_STATUS,
        "af:denyLimitedAccess": YesNo.No,
        "af:allowEditAppForm": YesNo.No,
        "af:disqualificationPage": YesNo.No,
    };

    const rules = [];
    const initialValues = {};

    return {
        name: "Form page",
        number: pageNumber,
        configuration: {
            schema,
            uiSchema,
            rules,
            initialValues,
        },
    };
};

/**
 * Trigger properties form validation and update form element validation error.
 *
 * @param {object} params
 * @param {string} params.instanceId Form builder instance id
 * @param {object} params.formRef The reference to element properties form
 * @param {string} params.elementId The id of element to validate
 * @param {function} params.dispatch Store dispatch function
 * @returns {Promise<boolean>} true if element properties are valid, otherwise false
 */
export const validatePropertiesForm = async ({ instanceId, formRef, elementId, dispatch }) => {
    try {
        await submitByRefPromise(formRef);
        await dispatch(clearValidationError({ instanceId, elementId }));
        return true;
    } catch (error) {
        console.log("original error", error); //keep
        await dispatch(
            setValidationError({
                instanceId,
                elementId,
                message: "Invalid properties",
            })
        );
        return false;
    }
};

export const usePropertiesFormRegistration = ({ instanceId, selectedElementId, propertiesFormRef, dispatch }) => {
    // Preserve active properties form to be accessible from store actions
    useEffect(() => {
        if (propertiesFormRef.current) {
            dispatch(
                registerPropertiesForm({
                    instanceId,
                    formRef: propertiesFormRef,
                })
            );
        }

        return () => {
            dispatch(unregisterPropertiesForm({ instanceId }));
        };
    }, [instanceId, selectedElementId, propertiesFormRef, dispatch]);
};
