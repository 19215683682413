import React, { useCallback } from "react";

import Input from "../Input";
import Label from "../Label";
import FieldWrap from "../FieldWrap";
import useFocusClasses from "../../utils/useFocusClasses";
import useEnterKey from "../../utils/useEnterKey";

import "./style.scss";

const FieldGroupRadio = (props) => {
    const isTabable = !props.disabled;

    const onClick = useCallback(
        (event) => {
            event.preventDefault();
            const element = document.getElementById(props.id);
            if (element) {
                element.click();
            }
        },
        [props.id]
    );

    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled: !isTabable,
    });
    const [onEnter] = useEnterKey({ disabled: !isTabable, onClick });
    return (
        <FieldWrap radio>
            <Input
                type="radio"
                id={props.id}
                name={props.name}
                disabled={props.disabled}
                checked={props.checked}
                onChange={props.onChange}
                value={props.value}
            />
            {props.label && (
                <Label
                    labelIcon={props.labelIcon}
                    labelRequired={props.required}
                    htmlFor={props.id}
                    tabIndex={isTabable ? "0" : "-1"}
                    onFocus={onFocusClassesFocus}
                    onBlur={onFocusClassesBlur}
                    onKeyDown={onEnter}
                    onClick={onClick}
                >
                    {props.label}
                </Label>
            )}
        </FieldWrap>
    );
};

export default FieldGroupRadio;
