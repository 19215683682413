import React, { memo, useCallback, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setWorkqueueDataSourceFilterExpanded } from "store/workcenter/actions";
import { windowContainerTypes } from "../../utils/window";

import WorkqueueGridTitle from "./WorkqueueGridTitle";
import WorkqueueGridControls from "./WorkqueueGridControls";
import WorkqueueGrid from "./WorkqueueGrid";
import ViewPlaceholder from "../../ui/ViewPlaceholder";
import { assignmentType } from "./ApplicationsFilterTab";
import { WorkCenterContext } from ".";

const WorkqueueFilterResults = memo(({ isFilterPanelOpen, expandFilterPanel }) => {
    const { instanceId } = useContext(WorkCenterContext);
    const dispatch = useDispatch();
    const workqueueGrid = useSelector((state) => state.workcenter[instanceId].workqueueGrid);
    const activeDataSource = useSelector((state) => state.workcenter[instanceId].activeDataSource);
    const isMobile = useSelector((state) => state.window?.isMobile);
    const isTablet = useSelector((state) => state.window?.isTablet);
    const isTabletLarge = useSelector((state) => state.window?.isTabletLarge);

    const isSplitView = useSelector((state) => state.window[windowContainerTypes.Root].views.find((view) => view.active)?.isSplitView);
    const showAssignments =
        useSelector((state) => state.workcenter[instanceId]?.applicationsFilter?.assignment) !== assignmentType.countsByStatus;

    const isFiltersListEmpty = useSelector((state) => {
        const userNumber = state.user.userNumber;
        return state.resources.workcenter.itemsById[`${userNumber}-workcenter`]?.length === 0;
    });

    const placeholderText = isFiltersListEmpty
        ? "Filters list is empty"
        : 'To get results please select a filter and click the "Show Results" button.';

    const expandAddFilterPanel = useCallback(() => {
        if ((isMobile || isTablet || isTabletLarge || isSplitView) && !isFilterPanelOpen) {
            expandFilterPanel();
        }

        dispatch(
            setWorkqueueDataSourceFilterExpanded({
                instanceId,
                dataSourceFilterExpanded: true,
            })
        );
    }, [instanceId, isMobile, isTablet, isTabletLarge, isSplitView, isFilterPanelOpen, expandFilterPanel, dispatch]);

    return (
        <div className="workqueue-grid no-scroll">
            {workqueueGrid ? (
                <div className="flex-column fill-height">
                    <WorkqueueGridTitle />
                    <WorkqueueGridControls showAssignments={showAssignments} />
                    <div className="flex-column fill-height no-scroll">
                        <WorkqueueGrid showAssignments={showAssignments} />
                    </div>
                </div>
            ) : (
                <div className="fill-height">
                    {activeDataSource && (
                        <ViewPlaceholder
                            viewPlaceholderSmall
                            clickableText={isFiltersListEmpty ? "Add New Filter" : ""}
                            clickableTextIcon={isFiltersListEmpty ? "plus" : ""}
                            onClick={isFiltersListEmpty && expandAddFilterPanel}
                        >
                            {placeholderText}
                            {!isFiltersListEmpty && (isMobile || isTablet || isTabletLarge || isSplitView) && !isFilterPanelOpen && (
                                <span onClick={() => expandFilterPanel()}>Show Filter</span>
                            )}
                        </ViewPlaceholder>
                    )}
                </div>
            )}
        </div>
    );
});

export default WorkqueueFilterResults;
