import React, { memo, useState, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { isNil } from "lodash";
import { programCatalogAttributeLookupsGridColumnKeys, availableGrids } from "../../../../../views/configureGrids";
import { useDataGridData } from "../../../../../../store/resources/useResource";
import { removeAttributeLookup, getAttributeLookupValues, addNewAttributeLookup, downloadValues, uploadValues } from "./utils";
import WaitIcon from "../../../../WaitIcon";
import { ErrorMessage } from "../../../../Message";
import Controls from "../../../../Dashboard/Panel/Controls";
import DataGrid from "../../../../DataGrid";
import TextInput from "../../../../Input/TextInput";
import Separator from "../../../../Separator";
import ButtonGroup from "../../../../Button/ButtonGroup";
import Button from "../../../../Button";
import TextLabel from "components/ui/Label/TextLabel";
import { optimisticUpdateRow } from "store/dataGrid/actions";
import { ATTRIBUTE_MAX_LENGTH } from "components/utils/attributes";

const lookupValueKey = programCatalogAttributeLookupsGridColumnKeys.lookupValue;
const timesUsedKey = programCatalogAttributeLookupsGridColumnKeys.timesUsed;
const catalogAttrNumberKey = programCatalogAttributeLookupsGridColumnKeys.catalogAttrNumber;

const rowActions = [
    {
        name: "delete",
        icon: "delete-trash-empty",
        title: "Delete Value",
    },
];

const AttributeLookupAssignmentModal = memo(({ entityKey, dataGridId, attribute, isCatalog, onClose, onSelect }) => {
    const dispatch = useDispatch();

    const attrNumber = attribute && attribute.attrNumber;
    const attrLookups = useMemo(() => (attribute && attribute.lookupValues) || [], [attribute]);
    const notFound = isNil(attribute);

    const attributeMaxLength = isCatalog ? ATTRIBUTE_MAX_LENGTH : undefined;

    const [isUploading, setIsUploading] = useState(false);
    const [uploadError, setUploadError] = useState();

    const [gridData = [], isLoadingGridData] = useDataGridData({
        dataGridId: availableGrids.programCatalogAttributeLookups,
        filter: `${catalogAttrNumberKey}=${attrNumber}`,
    });

    const rows = useMemo(
        () =>
            attrLookups.map((item) => ({
                value: item.lookup,
                timesUsed: (gridData.find((r) => r[lookupValueKey] === item.lookup) ?? {})[timesUsedKey],
            })),
        [attrLookups, gridData]
    );

    const gridConfig = useMemo(() => getGridConfig(dataGridId, rows), [dataGridId, rows]);

    const onRowAction = (action) => {
        const { name, dataIndex } = action;

        switch (name) {
            case "delete":
                removeAttributeLookup({ dataGridId, dataIndex });
                break;
            default:
                break;
        }
    };

    const onSubmit = () => {
        const selectedValues = getAttributeLookupValues({ dataGridId });

        onSelect(selectedValues);
        onClose();
    };

    const onAdd = () => {
        addNewAttributeLookup({ dataGridId });
    };

    const onChange = (event, row, index) => {
        dispatch(
            optimisticUpdateRow({
                gridId: dataGridId,
                row: {
                    value: event.target.value,
                },
                rowSelector: (row, rowIndex) => rowIndex === index,
            })
        );
    };

    const onUpload = () => {
        uploadValues({
            entityKey,
            attributeMaxLength,
            onUploaded: (error) => {
                setIsUploading(false);
                setUploadError(error);
            },
        });

        setIsUploading(true);
    };

    const controls = [
        {
            position: "left",
            title: "add value",
            icon: "plus",
            onClick: onAdd,
        },
        {
            position: "right",
            title: "Import from CSV",
            icon: "upload",
            onClick: onUpload,
        },
        {
            position: "right",
            title: "Download in CSV",
            withHandMadeIcon: true,
            onClick: () => downloadValues({ entityKey }),
        },
    ];

    if (isLoadingGridData) {
        return <WaitIcon />;
    }

    if (isUploading) {
        return (
            <>
                <TextLabel>Importing Attribute Lookup Values...</TextLabel>
                <WaitIcon />
            </>
        );
    }

    if (uploadError) {
        return (
            <>
                <TextLabel>Attribute Lookup Values import failed</TextLabel>
                <Separator />
                <ErrorMessage>{uploadError}</ErrorMessage>
                <Separator />
                <ButtonGroup transparent className="flex-row justify-center">
                    <Button primary onClick={() => setUploadError(undefined)}>
                        Ok
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    return (
        <div className="select-lookups-grid flex-column no-scroll">
            {notFound ? (
                <ErrorMessage>Attribute not found</ErrorMessage>
            ) : (
                <>
                    <Controls items={controls} />
                    <DataGrid
                        name={dataGridId}
                        config={gridConfig}
                        customRowActions={rowActions}
                        onRowAction={onRowAction}
                        columnCellContent={{
                            value: (column, row, onExpandRow, onRowAction, index) => (
                                <TextInput
                                    value={row[column.key]}
                                    maxLength={attributeMaxLength}
                                    onChange={(event) => onChange(event, row, index)}
                                />
                            ),
                        }}
                    />
                    <Separator />
                    <ButtonGroup transparent className="flex-row justify-center">
                        <Button primary onClick={onSubmit}>
                            Apply
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ButtonGroup>
                </>
            )}
        </div>
    );
});

const getGridConfig = (dataGridId, rows) => ({
    type: "client",
    serverSidePaging: false,
    isServerSideFiltering: false,
    dataGridId: availableGrids.attributeLookups,
    name: dataGridId,
    gridactions: [
        {
            sorting: [
                {
                    enabled: false,
                },
            ],
            paging: [
                {
                    enabled: false,
                },
            ],
            columnmanagement: [
                {
                    enabled: false,
                },
            ],
            filter: [
                {
                    enabled: "true",
                    search: "false",
                    columns: "true",
                },
            ],
        },
    ],
    columns: [
        {
            key: "value",
            type: "text",
            name: "value",
            order: 0,
            sort: "",
            filter: true,
            active: true,
            hidecolumn: "false",
            datatype: "varchar",
            rowactions: "",
            width: "",
        },
        {
            key: "timesUsed",
            type: "text",
            name: "times used",
            order: 1,
            sort: "",
            filter: true,
            active: true,
            hidecolumn: "false",
            datatype: "varchar",
            rowactions: "",
            width: "",
        },
    ],
    sourceRows: rows,
});

function mapStateToProps(state, ownProps) {
    const { attributes } = state;
    const { entityKey, attributeId } = ownProps;

    const attribute = attributes[entityKey].filter((i) => i._id === attributeId)[0];

    return {
        attribute,
    };
}

export default connect(mapStateToProps)(AttributeLookupAssignmentModal);
