import React, { memo } from "react";

import { CONTACT_TYPE } from "components/utils/constants";
import { useApplicationAssignedContacts, useProgramRights } from "store/resources/useResource";

import WaitIcon from "../../../../WaitIcon";
import ContactItem from "./ContactItem";
import SidebarPanel from "../../../../SidebarPanel";
import NothingFoundBlock from "../../../../NothingFoundBlock";
import { SidebarIconGroup } from "../../../../SidebarPanel/SidebarParts/SidebarIconGroup";
import { systemUserRights } from "components/utils/user";

import "./ContactsPanelSmall.scss";

const ContactsPanelSmall = memo(({ panel, onSettings, onUnpin, onExpand }) => {
    const { utilityNumber, programNumber, applicationNumber } = panel?.data;

    const [premiseContact, primaryContact, contractorContact, isLoading] = useApplicationAssignedContacts({
        applicationNumber,
    });
    const noContacts = [premiseContact, primaryContact, contractorContact].filter((c) => c).length === 0;
    const [programRights = []] = useProgramRights({ programNumber });
    const sidebarHeaderActions = <SidebarIconGroup panel={panel} onSettings={onSettings} onUnpin={onUnpin} onExpand={onExpand} />;

    const canSearchCIS = programRights.includes(systemUserRights.VISIONDSM_CIS_SEARCH);

    return (
        <SidebarPanel sidebarHeaderTitle={panel.name} sidebarHeaderActions={sidebarHeaderActions} noPadding noFooter noData={noContacts}>
            {isLoading ? (
                <WaitIcon />
            ) : (
                <>
                    {primaryContact && (
                        <ContactItem
                            contactType={CONTACT_TYPE.PRIMARY}
                            applicationNumber={applicationNumber}
                            programNumber={programNumber}
                            utilityNumber={utilityNumber}
                            contact={primaryContact}
                            canSearchCIS={canSearchCIS}
                            premiseContact={premiseContact}
                            primaryContact={primaryContact}
                            contractorContact={contractorContact}
                        />
                    )}
                    {premiseContact && (
                        <ContactItem
                            contactType={CONTACT_TYPE.PREMISE}
                            applicationNumber={applicationNumber}
                            programNumber={programNumber}
                            utilityNumber={utilityNumber}
                            contact={premiseContact}
                            canSearchCIS={canSearchCIS}
                            premiseContact={premiseContact}
                            primaryContact={primaryContact}
                            contractorContact={contractorContact}
                        />
                    )}
                    {contractorContact && (
                        <ContactItem
                            contactType={CONTACT_TYPE.CONTRACTOR}
                            applicationNumber={applicationNumber}
                            programNumber={programNumber}
                            utilityNumber={utilityNumber}
                            contact={contractorContact}
                            canSearchCIS={canSearchCIS}
                            premiseContact={premiseContact}
                            primaryContact={primaryContact}
                            contractorContact={contractorContact}
                        />
                    )}
                    {noContacts && (
                        <div className="empty-sidebar-content no-data">
                            <NothingFoundBlock nothingFoundBlockSmall icon="contacts" title="No contacts assigned to application" />
                        </div>
                    )}
                </>
            )}
        </SidebarPanel>
    );
});

export default ContactsPanelSmall;
