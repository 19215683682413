import React, { memo } from "react";
import { isNil, isFunction } from "lodash";
import cn from "classnames";

import useFocusClasses from "../../utils/useFocusClasses";

import "./ExpandableList.scss";

const ExpandableList = memo(
    ({
        items,
        expandedItem,
        ItemListDetails,
        ItemExpandedDetails,
        onToggle,
        className,
        itemClassName,
        expandedClassName,
        itemKey = "id",
        showOnlyDetails = false,
    }) => {
        let expandedItemIndex = -1;
        let topList = [];
        let bottomList = [];

        const onKeyDown = (item) => (event) => {
            if (event.key === "Enter") {
                toggleItem(item);
            }
        };

        const isTabable = !isNil(ItemListDetails) && !isNil(onToggle);
        const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
            disabled: !isTabable,
        });

        items.forEach((item, index) => {
            if (item === expandedItem || (expandedItem && item[itemKey] === expandedItem[itemKey])) {
                expandedItemIndex = index;
            } else {
                if (expandedItemIndex === -1) {
                    topList.push(item);
                } else {
                    bottomList.push(item);
                }
            }
        });

        const toggleItem = (item) => {
            onToggle && onToggle(item === expandedItem ? null : item);
        };

        const List = ({ items, expandedItem }) => (
            <div
                className={cn("expandable-list", className, {
                    expanded: expandedItem,
                    [isFunction(expandedClassName) ? expandedClassName(expandedItem) : expandedClassName]: expandedItem,
                })}
            >
                {items.map((item) => (
                    <div
                        key={item[itemKey]}
                        className={cn("item flex-row", isFunction(itemClassName) ? itemClassName(item) : itemClassName, {
                            expanded: expandedItem,
                        })}
                        tabIndex={isTabable ? "0" : "-1"}
                        onFocus={onFocusClassesFocus}
                        onBlur={onFocusClassesBlur}
                        onKeyDown={onKeyDown(item)}
                        onClick={() => toggleItem(item)}
                    >
                        <ItemListDetails item={item} />
                    </div>
                ))}
                {expandedItem && (
                    <div className="expanded-details">
                        <ItemExpandedDetails item={expandedItem} />
                    </div>
                )}
            </div>
        );

        return (
            <>
                {topList.length > 0 && <List items={topList} />}
                {expandedItem && <List items={showOnlyDetails ? [] : [expandedItem]} expandedItem={expandedItem} />}
                {bottomList.length > 0 && <List items={bottomList} />}
            </>
        );
    }
);

export default ExpandableList;
