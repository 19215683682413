import { combineReducers } from "redux";
import { availableResources } from "../configureResources";
import { resourceReducer } from "./resourceReducer";

const initialState = {
    itemsById: {},
    items: [],
    isReadingList: false,
    isReading: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    isError: false,
    message: null,
};

const createResourceReducer = ({ resourceName, resourceUrl, listName }) => {
    return (state = initialState, action) => {
        const { passThroughData } = action;
        const isInitializationCall = state === undefined;

        if (!passThroughData || (passThroughData && passThroughData.resourceName !== resourceName && !isInitializationCall)) {
            return state;
        }

        return resourceReducer(state, action, { resourceUrl, listName });
    };
};

const reducers = availableResources.reduce((result, item) => {
    return {
        ...result,
        [item.resourceName]: createResourceReducer(item),
    };
}, {});

export const reducer = combineReducers(reducers);
