import { isNil } from "lodash";

/**
 *
 * @param {array} list
 * @param {number} fromIndex
 * @param {number} toIndex
 * @description Mutable array reordering.
 * @returns {void}
 */

export const reorder = (list, fromIndex, toIndex) => {
    if (!isNil(fromIndex) && !isNil(toIndex)) {
        const [removed] = list.splice(fromIndex, 1);

        list.splice(toIndex, 0, removed);
    }
};

export const toArray = (array) => (Array.isArray(array) ? array : array ? [array] : []);

/**
 * Filter expression to get unique items from array.
 *
 * @param {*} value
 * @param {*} index
 * @param {*} array
 */
export const distinct = (value, index, array) => array.indexOf(value) === index;
