import React, { memo, useState, useCallback, useContext, useMemo, useEffect } from "react";
import cn from "classnames";

import { isAutomated, isSkippedTask, isWorkflowTaskDependent } from "../utils";

import { Header } from "./Header";
import ExpandableList from "../../List/ExpandableList";
import NothingFoundBlock from "../../NothingFoundBlock";
import { isTaskCompleted } from "../../../utils/workflow";

import { TaskWorkflowContext } from "../context/TaskWorkflowContext";
import { sideNavSize } from "../../SideNav/SideNavRoot";
import useSidePanelHandlers from "../../../utils/useSidePanelHandlers";

import "./Status.scss";

const Status = memo(
    ({
        className,
        status,
        processMode,
        tasks,
        allTasks,
        isCompleted,
        listItemComponent,
        expandedListItemComponent,
        onSelectTask,
        AdHocTaskForm,
        expandedWorkflowTaskId,
        statusDate,
        userName,
        isExpandTaskDisabled,
    }) => {
        const { workflowGetResourceOptions } = useContext(TaskWorkflowContext);
        const [selectedTaskNumber, setSelectedTaskNumber] = useState(null);

        const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
            size: sideNavSize.staticMedium,
            className: "add-ad-hoc-task-sidenav-panel",
        });

        const ExpandedTask = expandedListItemComponent;

        const selectedTask = useMemo(
            () => tasks.find(({ wfTaskNumber }) => [selectedTaskNumber, expandedWorkflowTaskId].includes(wfTaskNumber)),
            [tasks, selectedTaskNumber, expandedWorkflowTaskId]
        );

        const showTakeAllTasks = useMemo(() => allTasks.filter((task) => task.allowAccess && !isAutomated(task)).length > 0, [allTasks]);

        const onToggleTask = useCallback(
            (task) => {
                if (onSelectTask) {
                    !isExpandTaskDisabled && onSelectTask(task);
                } else {
                    let taskNumber = null;

                    if (task) {
                        taskNumber = task.wfTaskNumber;
                    }

                    !isExpandTaskDisabled && setSelectedTaskNumber(taskNumber);
                }
            },
            [onSelectTask, isExpandTaskDisabled]
        );

        const onCloseTask = useCallback(() => {
            setSelectedTaskNumber(undefined);
        }, []);

        const closeAdhocTaskForm = useCallback(
            ({ success = false } = {}) => {
                handleCloseSidePanel({ success });
            },
            [handleCloseSidePanel]
        );

        const openAdHocTaskForm = useCallback(() => {
            handleOpenSidePanel(<AdHocTaskForm workflowGetResourceOptions={workflowGetResourceOptions} onClose={closeAdhocTaskForm} />);
        }, [closeAdhocTaskForm, handleOpenSidePanel, workflowGetResourceOptions]);

        useEffect(() => {
            if (expandedWorkflowTaskId) {
                const element = document.getElementById(expandedWorkflowTaskId);
                if (element) {
                    element.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }
            }
        }, [expandedWorkflowTaskId]);

        return (
            <div className={cn("workflow-tasks-container", className)}>
                {isCompleted && <div className="top-right-corner-style" />}
                <Header
                    status={status}
                    processMode={processMode}
                    statusDate={statusDate}
                    userName={userName}
                    tasks={allTasks}
                    isCompleted={isCompleted}
                    showTakeAllTasks={showTakeAllTasks}
                    onOpenAdHocTaskForm={openAdHocTaskForm}
                />
                <div className="workflow-tasks">
                    {allTasks.length === 0 && (
                        <NothingFoundBlock nothingFoundBlockSmall icon="workflow" title="No Tasks available to work on" />
                    )}
                    {allTasks.length > 0 && tasks.length === 0 && (
                        <NothingFoundBlock nothingFoundBlockSmall icon="workflow" title="Switch ON tasks to see the list" />
                    )}
                    <ExpandableList
                        itemKey="wfTaskNumber"
                        items={tasks}
                        expandedClassName={(item) =>
                            cn({
                                "expanded-workflow-task-completed": item && (isCompleted || isTaskCompleted(item)),
                                "expanded-workflow-task-dependent": item && isWorkflowTaskDependent(item, isCompleted),
                                "expanded-workflow-task-skipped": item && isSkippedTask(item),
                            })
                        }
                        itemClassName={(item) =>
                            cn({
                                automated: isAutomated(item),
                                manual: !isAutomated(item),
                                "workflow-task-completed": isCompleted || isTaskCompleted(item),
                                "workflow-task-dependent": isWorkflowTaskDependent(item, isCompleted),
                                "workflow-task-skipped": isSkippedTask(item),
                                "workflow-task-read-only": isExpandTaskDisabled,
                            })
                        }
                        ItemListDetails={listItemComponent}
                        ItemExpandedDetails={(props) => <ExpandedTask {...props} onCloseTask={onCloseTask} />}
                        expandedItem={selectedTask}
                        onToggle={onToggleTask}
                    />
                </div>
            </div>
        );
    }
);

export default Status;
