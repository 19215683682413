import React, { useState, useEffect, useRef, useCallback, useMemo, memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getResource } from "../../../../../../store/resources/actions";
import { useResource } from "../../../../../../store/resources/useResource";
import { useReference } from "../../../../Reference/useReference";
import { referenceTypes } from "../../../../Reference/referenceTypes";

import WaitIcon from "../../../../WaitIcon";
import DropDownInput from "../../../../Input/DropDownInput";
import DropDownMultiSelect from "../../../../Input/DropDownMultiSelect";

import "./RebateApprovalsFilters.scss";

const programsMapper = (programs) => {
    if (programs && programs.hasOwnProperty("programList")) {
        return programs.programList.map((i) => ({
            value: i.programNumber,
            label: i.programName,
        }));
    }

    return [{ value: null, label: "All" }];
};

const datesMapper = (dates) => {
    if (dates && dates.hasOwnProperty("dateList")) {
        return dates.dateList.map((i) => ({
            value: i.dateVal,
            label: i.display,
        }));
    }

    return [{ value: null, label: "All" }];
};

const receivedDatesMapper = (receivedDates) => {
    if (receivedDates && receivedDates.hasOwnProperty("receivedDateList")) {
        return receivedDates.receivedDateList.map((i) => ({
            value: i.dateVal,
            label: i.display,
        }));
    }

    return [{ value: null, label: "All" }];
};

const statesMapper = (states) => {
    if (states && states.hasOwnProperty("stateList")) {
        return states.stateList.map((i) => ({
            value: i.stateVal,
            label: i.display,
        }));
    }

    return [{ value: null, label: "All" }];
};

const approvalStatusesMapper = (approvalStatuses) => {
    if (approvalStatuses) {
        return approvalStatuses.map((i) => ({
            value: i.val,
            label: i.display,
        }));
    }

    return [{ value: null, label: "All" }];
};

export const RebateApprovalsFilters = memo(({ utilityNumber, isFilterVisible, onSubmit }) => {
    const dispatch = useDispatch();
    const didCancel = useRef(false);

    const [programList, setProgramList] = useState();
    const [dateList, setDateList] = useState();
    const [receivedDateList, setReceivedDateList] = useState();
    const [stateList, setStateList] = useState();
    const [approvalStatus, setApprovalStatus] = useState("Pending");
    const [isStatesLoading, setIsStatesLoading] = useState(false);
    const [isDatesLoading, setIsDatesLoading] = useState(false);
    const [isReceivedDatesLoading, setIsReceivedDatesLoading] = useState(false);

    const states = useSelector((state) => state.resources.utilitiesRebateApprovalsStates.itemsById[utilityNumber]);
    const dates = useSelector((state) => state.resources.utilitiesRebateApprovalsDates.itemsById[utilityNumber]);
    const receivedDates = useSelector((state) => state.resources.utilitiesRebateApprovalsReceivedDates.itemsById[utilityNumber]);

    const [programs, isProgramsLoading] = useResource({
        resourceName: "utilitiesRebateApprovalsPrograms",
        key: utilityNumber,
        path: {
            utilityNumber,
        },
    });

    const [approvalStatuses, isApprovalStatusesLoading] = useReference(referenceTypes.rebateState, "string");

    const programsData = useMemo(() => programsMapper(programs), [programs]);
    const statesData = useMemo(() => statesMapper(states), [states]);
    const datesData = useMemo(() => datesMapper(dates), [dates]);
    const receivedDatesData = useMemo(() => receivedDatesMapper(receivedDates), [receivedDates]);
    const approvalStatusesData = useMemo(() => approvalStatusesMapper(approvalStatuses), [approvalStatuses]);

    useEffect(() => {
        setIsDatesLoading(true);

        dispatch(
            getResource({
                resourceName: "utilitiesRebateApprovalsDates",
                key: utilityNumber,
                path: {
                    utilityNumber,
                },
                query: {
                    programList,
                },
                forced: true,
                onComplete: () => {
                    if (!didCancel.current) {
                        setIsDatesLoading(false);
                    }
                },
            })
        );
    }, [utilityNumber, programList, dispatch]);

    useEffect(() => {
        setIsReceivedDatesLoading(true);

        dispatch(
            getResource({
                resourceName: "utilitiesRebateApprovalsReceivedDates",
                key: utilityNumber,
                path: {
                    utilityNumber,
                },
                query: {
                    programList,
                },
                forced: true,
                onComplete: () => {
                    if (!didCancel.current) {
                        setIsReceivedDatesLoading(false);
                    }
                },
            })
        );
    }, [utilityNumber, programList, dispatch]);

    useEffect(() => {
        setIsStatesLoading(true);

        dispatch(
            getResource({
                resourceName: "utilitiesRebateApprovalsStates",
                key: utilityNumber,
                path: {
                    utilityNumber,
                },
                query: {
                    programList,
                },
                forced: true,
                onComplete: () => {
                    if (!didCancel.current) {
                        setIsStatesLoading(false);
                    }
                },
            })
        );
    }, [utilityNumber, programList, dispatch]);

    const handleChangeProgramFilter = useCallback(
        (e) => {
            setDateList();
            setProgramList(e.target.value);

            onSubmit({
                filter: {
                    programList: e.target.value,
                    dateList,
                    receivedDateList,
                    stateList,
                    approvalStatus,
                },
            });
        },
        [dateList, receivedDateList, stateList, approvalStatus, onSubmit]
    );

    const handleChangeDateFilter = useCallback(
        (e) => {
            setDateList(e.target.value);

            onSubmit({
                filter: {
                    programList,
                    dateList: e.target.value,
                    receivedDateList,
                    stateList,
                    approvalStatus,
                },
            });
        },
        [programList, receivedDateList, stateList, approvalStatus, onSubmit]
    );

    const handleChangeReceivedDateFilter = useCallback(
        (e) => {
            setReceivedDateList(e.target.value);

            onSubmit({
                filter: {
                    programList,
                    dateList,
                    receivedDateList: e.target.value,
                    stateList,
                    approvalStatus,
                },
            });
        },
        [programList, dateList, stateList, approvalStatus, onSubmit]
    );

    const handleChangeStateFilter = useCallback(
        (e) => {
            setStateList(e.target.value);

            onSubmit({
                filter: {
                    programList,
                    dateList,
                    receivedDateList,
                    stateList: e.target.value,
                    approvalStatus,
                },
            });
        },
        [programList, dateList, receivedDateList, approvalStatus, onSubmit]
    );

    const handleChangeApprovalStatusFilter = useCallback(
        (e) => {
            setApprovalStatus(e.target.value);

            onSubmit({
                filter: {
                    programList,
                    dateList,
                    receivedDateList,
                    stateList,
                    approvalStatus: e.target.value,
                },
            });
        },
        [programList, dateList, receivedDateList, stateList, onSubmit]
    );

    if (isProgramsLoading) {
        return <WaitIcon />;
    }

    const hasProgramsSelected = programList && programList.length !== 0;

    return (
        <div className={"rebate-approvals__filters flex-row align-center flex-wrap" + (!isFilterVisible ? " hidden" : "")}>
            <div className="rebate-approvals__filters-item rebate-approvals__filters-item--programs">
                <DropDownMultiSelect
                    value={programList}
                    onChange={handleChangeProgramFilter}
                    data={programsData}
                    disabled={isProgramsLoading}
                    placeholder={isProgramsLoading ? "Loading..." : "Programs"}
                    label="Programs"
                    mobileHeader="Select Programs"
                    selectAllItem
                />
            </div>
            <div className="rebate-approvals__filters-item rebate-approvals__filters-item--date">
                <DropDownInput
                    value={dateList}
                    onChange={handleChangeDateFilter}
                    data={datesData}
                    disabled={isDatesLoading || !hasProgramsSelected}
                    placeholder={isDatesLoading ? "Loading..." : "All"}
                    label="Request Date"
                    mobileHeader="Select Request Date"
                />
            </div>
            <div className="rebate-approvals__filters-item rebate-approvals__filters-item--received-date">
                <DropDownInput
                    value={receivedDateList}
                    onChange={handleChangeReceivedDateFilter}
                    data={receivedDatesData}
                    disabled={isReceivedDatesLoading || !hasProgramsSelected}
                    placeholder={isReceivedDatesLoading ? "Loading..." : "All"}
                    label="Received Date"
                    mobileHeader="Select Received Date"
                />
            </div>
            <div className="rebate-approvals__filters-item rebate-approvals__filters-item--states">
                <DropDownMultiSelect
                    value={stateList}
                    onChange={handleChangeStateFilter}
                    data={statesData}
                    disabled={isStatesLoading || !hasProgramsSelected}
                    placeholder={isStatesLoading ? "Loading..." : "All"}
                    label="States"
                    mobileHeader="Select States"
                />
            </div>
            <div className="rebate-approvals__filters-item rebate-approvals__filters-item--status">
                <DropDownInput
                    value={approvalStatus}
                    onChange={handleChangeApprovalStatusFilter}
                    data={approvalStatusesData}
                    disabled={isApprovalStatusesLoading || !hasProgramsSelected}
                    placeholder={isApprovalStatusesLoading ? "Loading..." : "All"}
                    label="Request Status"
                    mobileHeader="Select Request Status"
                />
            </div>
        </div>
    );
});
