import React, { memo } from "react";
import { useDispatch } from "react-redux";

import { modalOpen } from "../../../../../../store/modal/actions";
import { deleteResource } from "../../../../../../store/resources/actions";
import { getData } from "../../../../../../store/dataGrid/actions";
import { lookupTableColumnKeys } from "../../../../../views/configureGrids";

import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid";
import LookupTableItemDetailsForm from "./LookupTableItemDetailsForm";
import { isChildProgram } from "components/views/ProgramView/utils";

const customRowActions = [
    {
        name: "update",
        icon: "edit-empty",
        title: "Edit Calculation Lookup",
    },
    {
        name: "delete",
        icon: "delete-trash-empty",
        title: "Delete Calculation Lookup",
    },
];

const LookupTableItems = memo(({ gridId, dataGridConfig, programNumber }) => {
    const dispatch = useDispatch();

    if (isChildProgram({ programNumber })) {
        customRowActions[0].title = "View Calculation Lookup";
        customRowActions[0].icon = "eye-visibility-empty";
        customRowActions[1].disabled = true;
        customRowActions[1].title = undefined;
    }

    const onRowAction = (action, onExpand) => {
        const { name, dataItem } = action;

        switch (name) {
            case "update":
                onExpand((props) => <LookupTableItemDetailsForm programNumber={programNumber} {...props} />);
                break;
            case "delete":
                const dialogMessage = <p>Are you sure you want to delete calculation Lookup?</p>;

                dispatch(
                    modalOpen({
                        modalType: "CONFIRM",
                        modalProps: {
                            title: "Delete Calculation Lookup",
                            overlayClassName: "modal-styled",
                            className: "calculations-modal delete-calculation-lookup-confirmation-modal modal-sm",
                            modalIcon: "delete-trash-empty",
                            content: dialogMessage,
                            onConfirm: () => {
                                dispatch(
                                    deleteResource({
                                        resourceName: "calculationLookupTableItems",
                                        resourceId: dataItem[lookupTableColumnKeys.attrLookupValueNumber],
                                        onSuccess: () => {
                                            dispatch(
                                                getData({
                                                    dataGridId: gridId,
                                                })
                                            );
                                        },
                                    })
                                );
                            },
                        },
                    })
                );
                break;
            default:
                break;
        }
    };

    return <DataGrid name={gridId} config={dataGridConfig} customRowActions={customRowActions} onRowAction={onRowAction} />;
});

export default withDataGrid("lookupTable", LookupTableItems);
