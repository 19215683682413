import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../ui/Button";
import { tokenExpired } from "../../utils/user";
import { refresh } from "../../../store/login/actions";
import Separator from "../../ui/Separator";
import { copyToClipboard } from "components/utils/string";
import { toast } from "react-toastify";
import Checkbox from "components/ui/Input/Checkbox";

export const UserInfo = ({ onWindowOpen }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const isLoading = useSelector((state) => state.login.isLoading);
    const isLatestVersion = useSelector((state) => state.system.isLatestVersion);

    const [, setRerender] = useState(0);

    const isReduxLoggerEnabled = localStorage.getItem("redux-logger-enabled") === "true";
    const eventsHostname = localStorage.getItem("portal-analytics-events-hostname");
    const showConfigEditorInPortalBuilder = localStorage.getItem("show-config-editor-in-portal-builder") === "true";
    const isPortalBuilderConfigOverlayDisabled = localStorage.getItem("portal-builder-config-overlay-disabled") === "true";

    const onToggleReduxLogger = () => {
        if (window.confirm("This will reload the page. Do you want to continue?")) {
            localStorage.setItem("redux-logger-enabled", isReduxLoggerEnabled ? "false" : "true");
            window.location.reload();
        }
    };

    const onToggleEventsHostname = () => {
        if (!eventsHostname) {
            // show input modal to set hostname
            const hostname = window.prompt("Enter hostname for events", "customerapplication.com");
            if (hostname) {
                localStorage.setItem("portal-analytics-events-hostname", hostname);
            }
        } else {
            // remove hostname
            localStorage.removeItem("portal-analytics-events-hostname");
        }

        setRerender((prev) => prev + 1);
    };

    const copyToken = () => {
        copyToClipboard(user.accessToken, () => {
            toast.success("Copied to clipboard");
        });
    };

    if (!user) {
        return null;
    }

    return (
        <>
            <div className="tile flex-row no-shrink flex-padding">
                <div className="flex-column fill-width">
                    <div className="header">{`${user.firstName} ${user.lastName}`}</div>
                    <div className="flex-row">
                        <span className="text text-small">{`Access Token: ${tokenExpired(user) ? "invalid" : "valid"}`}</span>
                    </div>
                    <div className="flex-row">
                        <span className="text text-small">{`Expires: ${new Date(user.tokenExpires).toLocaleString()}`}</span>
                    </div>
                    <div className="flex-row">
                        <span className="text text-small">{`Is App Latest Version: ${isLatestVersion ? "true" : "false"}`}</span>
                    </div>
                    <Separator />
                    <div className="flex-row">
                        <Button small onClick={copyToken}>
                            Copy token
                        </Button>
                        <Button small loading={isLoading} onClick={() => dispatch(refresh())}>
                            Refresh token
                        </Button>
                        <Button
                            small
                            onClick={() =>
                                onWindowOpen({
                                    name: "User profile",
                                    component: "UserProfile",
                                })
                            }
                        >
                            User profile
                        </Button>
                    </div>
                </div>
            </div>
            <div className="tile flex-row no-shrink flex-padding">
                <Checkbox
                    label="Show Config Editor in Portal Builder"
                    checked={showConfigEditorInPortalBuilder}
                    onChange={() => {
                        localStorage.setItem("show-config-editor-in-portal-builder", showConfigEditorInPortalBuilder ? "false" : "true");
                        setRerender((prev) => prev + 1);
                    }}
                />
            </div>
            <div className="tile flex-row no-shrink flex-padding">
                <Checkbox
                    label="Disable Portal Builder configuration overlay"
                    checked={isPortalBuilderConfigOverlayDisabled}
                    onChange={() => {
                        localStorage.setItem(
                            "portal-builder-config-overlay-disabled",
                            isPortalBuilderConfigOverlayDisabled ? "false" : "true"
                        );
                        setRerender((prev) => prev + 1);
                    }}
                />
            </div>
            <div className="tile flex-row no-shrink flex-padding">
                <Checkbox label="Use redux logger" checked={isReduxLoggerEnabled} onChange={onToggleReduxLogger} />
            </div>
            <div className="tile flex-row no-shrink flex-padding">
                <Checkbox
                    label={"Use custom events hostname" + (eventsHostname ? ` (${eventsHostname})` : "")}
                    checked={!!eventsHostname}
                    onChange={onToggleEventsHostname}
                />
            </div>
        </>
    );
};
