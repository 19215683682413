import { log } from "./logger";

export const getListRootWithChildren = ({ tree, rootId, idKey, parentIdKey }) => {
    let list = [rootId];
    const elements = tree?.filter((i) => i[parentIdKey] === rootId);

    elements?.forEach((i) => {
        list = list.concat(getListRootWithChildren({ tree, rootId: i[idKey], idKey, parentIdKey }));
    });

    return list;
};

export const hasChildren = ({ item, list, idKey, parentIdKey }) => {
    return list.some((r) => r[parentIdKey] === item[idKey]);
};

export const getParents = ({ item, list, idKey, parentIdKey }) => {
    let parents = [];
    let isBroken = false;

    const getParent = (row) => {
        return list.filter((r) => r[idKey] !== row[idKey] && r[idKey] === row[parentIdKey])[0];
    };

    let parent = getParent(item);

    while (parent) {
        const parentId = parent[idKey];

        if (parents.find((i) => i[idKey] === parentId)) {
            // protect against broken tree
            log("Tree: broken parentness for ", parent);
            isBroken = true;
            break;
        } else {
            parents.unshift(parent);
            parent = getParent(parent);
        }
    }

    return { isBroken, parents: isBroken ? [] : parents };
};

/**
 * Get tree item parents in tree sorted list
 *
 * @param {object} params
 * @param {integer} params.index - index of row to search parents for
 * @param {object[]} params.list - list of grid rows sorted for tree
 * @returns {object[]} list of parent rows
 */
export const getParentsInTreeList = ({ index, list }) => {
    // Set row index and depth for parent search
    let currentIndex = index;
    let currentDepth = list[index]._treeDepth;
    let parents = [];

    if (currentDepth > 0) {
        // Start search from previous row and parent depth
        currentIndex--;
        currentDepth--;

        // Search up to first row and get parents
        while (currentIndex > -1 && currentDepth > -1) {
            const rowDepth = list[currentIndex]?._treeDepth ?? 0;
            if (rowDepth === currentDepth) {
                parents.push(list[currentIndex]);
                currentDepth--;
            }

            currentIndex--;
        }
    }

    return parents;
};
