import React, { memo, useEffect, useRef } from "react";

import { openUtilityTab } from "../../utils/window";
import { openProgramTab } from "store/window/openTabActions";

import ToolList from "../../ui/List/ToolList";
import SubHeader from "../../ui/Headers/SubHeader";
import WindowBodyHeader from "../../ui/WindowBody/WindowBodyHeader";
import { useIsMobile } from "components/utils/useIsMobile";
import { useDispatch, useSelector } from "react-redux";
import { getFormBuilder } from "store/formBuilder/selectors";
import { useProgram, useUtilityRights } from "store/resources/useResource";
import { systemUserRights } from "components/utils/user";
import { isNil } from "lodash";
import WaitIcon from "components/ui/WaitIcon";

const Header = memo(
    ({ programNumber, utilityNumber, pages, activeTool, loading, onToolClick, onToolDragEnd, formName, hideDisabledPages }) => {
        const isMobile = useIsMobile();
        const dispatch = useDispatch();

        const [program = {}, isLoadingProgramDetails] = useProgram({ programNumber });
        const [utilityRights = []] = useUtilityRights({ utilityNumber });
        const canViewUtility = utilityRights.includes(systemUserRights.VISIONDSM_VIEW_UTILITY);
        const headerItems = [
            isMobile && {
                name: "Application Form Page Name",
                value: formName,
            },
            {
                name: "Utility",
                value: isLoadingProgramDetails ? <WaitIcon /> : program?.utility,
                onClick: canViewUtility ? () => openUtilityTab({ utilityNumber }) : undefined,
            },
            {
                name: "Program",
                value: isLoadingProgramDetails ? <WaitIcon /> : program?.program,
                onClick: () => dispatch(openProgramTab({ programNumber })),
            },
        ];
        const gridRef = useRef();
        const activePageId = activeTool?.id;
        const activePage = useSelector((state) => getFormBuilder({ instanceId: activePageId, state: state.formBuilder }));
        const newPageNumbers = useSelector((state) => state.pages[`new-${programNumber}`]);
        const formBuilderState = useSelector((state) => state.formBuilder);
        const newPages = Object.values(formBuilderState)
            .filter(
                (p) =>
                    !isNil(p.uiSchema["af:pageNumber"]) &&
                    (newPageNumbers ?? []).some((pageNumber) => pageNumber === p.uiSchema["af:pageNumber"])
            )
            .map((p) => ({
                allowEdit: p.uiSchema["af:allowEditAppForm"],
                configuration: {
                    rules: p.rules,
                    schema: p.schema,
                    uiSchema: p.uiSchema,
                },
                name: p.schema.title,
                number: p.uiSchema["af:pageNumber"],
                status: p.uiSchema["af:status"] === 212 ? "Active" : "Disabled",
                statusId: p.uiSchema["af:status"],
                isNewPage: true,
            }));

        const pageList = [...pages, ...newPages]
            .map((page) => {
                return {
                    id: page.number,
                    type: "page",
                    title: page.number === activePageId ? activePage?.schema["title"] : page.name,
                    icon: "document-text",
                    disabled:
                        page.number === activePageId
                            ? activePage?.uiSchema["af:status"] === 213
                            : page.status === "Disabled"
                            ? true
                            : false,
                    isDragDisabled: page.isNewPage,
                    data: page,
                };
            })
            .concat([
                {
                    id: "new-page",
                    type: "new-page",
                    title: "New Page",
                    icon: "plus",
                    isDragDisabled: true,
                    isSticky: true,
                },
            ])
            .filter((page) => (hideDisabledPages ? !page.disabled : page));
        const lastPageListLength = useRef(pageList.length);

        useEffect(() => {
            if (lastPageListLength.current !== pageList.length) {
                const element = gridRef.current.querySelector(".tools");
                if (element) {
                    setTimeout(() => {
                        element.scrollTo({ left: element.scrollWidth, behavior: "smooth" });
                    }, 0);
                }
                lastPageListLength.current = pageList.length;
            }
        }, [pageList.length]);

        return (
            <div className="app-form-pages-header">
                <WindowBodyHeader windowBodyHeaderName="Application Form Pages">
                    <SubHeader items={headerItems} className="fill-width" />
                </WindowBodyHeader>
                <div className="dashboard-tools">
                    <div ref={gridRef} className="main-grid-wrap responsive">
                        <ToolList
                            items={pageList}
                            activeTool={activeTool}
                            loading={loading}
                            onClick={onToolClick}
                            onDragEnd={onToolDragEnd}
                            disabledItemTooltip="Disabled Form"
                        />
                    </div>
                </div>
            </div>
        );
    }
);

export default Header;
