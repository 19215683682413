import React, { useState, memo } from "react";
import { useDispatch } from "react-redux";

import { getData } from "../../../../../../store/dataGrid/actions";
import { uploadFile } from "../../../../../../store/files/actions";
import { exportDatagridToCSV } from "../../../../../utils/CSV";

import { utilitiesFilesGridColumnKeys } from "../../../../../views/configureGrids";
import IconWithLabel from "../../../../Icons/IconWithLabel";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import { useResource } from "store/resources/useResource";

import FilesGrid from "./FilesGrid";
import FileUpload from "./FileUpload";

import "../../FilesPanel.scss";

const FilesPanel = memo(({ panel }) => {
    const dispatch = useDispatch();
    const utilityNumber = panel && panel.data && panel.data.utilityNumber;
    const gridId = `${utilityNumber}-files-grid`;
    const panelTitle = panel && panel.name;
    const entityTypeId = 70;
    const [showUploadFile, setShowUploadFile] = useState(false);
    const [files] = useResource({
        resourceName: "fileUploadReqs",
        key: entityTypeId,
        path: {
            entityTypeId: 70,
        },
    });

    const toggleUploadEvent = () => {
        setShowUploadFile(!showUploadFile);
    };

    const onSuccess = () => {
        setShowUploadFile(!showUploadFile);
        dispatch(
            getData({
                dataGridId: gridId,
            })
        );
    };

    const onFileUpload = (formData) => {
        dispatch(
            uploadFile({
                fileData: {
                    ...formData,
                    entityTypeId: 361,
                    entityId: utilityNumber,
                    fileTypeId: 27,
                },
                onUploadSuccess: onSuccess,
            })
        );
    };

    return (
        <div className="panel files-panel sticky-grid-list-panel">
            <PanelHeaderLarge title={panelTitle} />
            <div className="data-grid-controls flex-row justify-end">
                <IconWithLabel icon={showUploadFile ? "shevron-circle-down-filled" : "upload"} onClick={toggleUploadEvent}>
                    Upload New File
                </IconWithLabel>
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: gridId,
                            fileName: "utility_files",
                            fileNamePostfix: utilityNumber,
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            {showUploadFile && <FileUpload entityId={utilityNumber} onCancel={toggleUploadEvent} files={files} onSubmit={onFileUpload} />}
            <FilesGrid
                utilityNumber={utilityNumber}
                gridId={gridId}
                filter={`${utilitiesFilesGridColumnKeys.utilityNumber}=${utilityNumber}`}
            />
        </div>
    );
});

export default FilesPanel;
