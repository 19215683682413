import React from "react";
import cn from "classnames";
import { isEmpty } from "lodash";
import InfoList from "components/ui/List/InfoList";
import { AccessibilityValidationMessage, ValidationMessageImpact } from "components/ui/PortalBuilder/types";
import { toPascalCase } from "components/utils/string";
import ExpandablePanel from "components/ui/ExpandablePanel";
import ActionLabel from "components/ui/Label/ActionLabel";
import { openUrl } from "components/utils/window";
import TextLabel from "components/ui/Label/TextLabel";
import { highlightPageElement } from "../../utils/PortalMessenger";
import { useErrorContext } from "../../PortalBuilderErrorContextProvider";
import IconWrap from "components/ui/Icons";

import "./AccessibilityMessage.scss";

export const AccessibilityMessage = ({ message }: { message: AccessibilityValidationMessage }) => {
    const panelTitle = (
        <div className="accessibility-message-title flex-row align-center" title={`${toPascalCase(message.impact)}: ${message.id}`}>
            <IconWrap
                icon={cn({
                    "circle-exclamation-filled icon-wrap-error": message.impact === ValidationMessageImpact.Error,
                    "warning-report-problem-filled icon-wrap-warning": message.impact === ValidationMessageImpact.Warning,
                    "info-filled icon-wrap-theme": message.impact === ValidationMessageImpact.Notice,
                })}
            />{" "}
            {message.id}
        </div>
    );

    return (
        // @ts-ignore
        <ExpandablePanel className="accessibility-message flex-row align-center" title={panelTitle}>
            <AccessibilityMessageDetails message={message} />
        </ExpandablePanel>
    );
};

const AccessibilityMessageDetails = ({ message }: { message: AccessibilityValidationMessage }) => {
    const { originalMessage } = message;

    const portalRef = useErrorContext()?.portalRef;

    const onNodeClick = (selector: string | undefined, description: string | undefined) => {
        if (portalRef && selector) {
            highlightPageElement(portalRef, selector, description, message.impact, message.url);
        }
    };

    let listItems = [
        {
            label: "Message",
            value: message.message,
        },
    ];

    if (!isEmpty(message.description)) {
        listItems.push({
            label: "Description",
            value: message.description as string,
        });
    }

    if (!isEmpty(originalMessage?.help)) {
        listItems.push({
            label: "Help",
            value: originalMessage.help,
        });
    }

    if (!isEmpty(originalMessage?.helpUrl)) {
        listItems.push({
            label: "Help Url",
            //@ts-ignore
            value: <ActionLabel onClick={() => openUrl(originalMessage.helpUrl)}>{originalMessage.helpUrl}</ActionLabel>,
        });
    }

    if (message.validator === "axe-core" && message.id === "color-contrast") {
        listItems.push({
            label: "Elements",
            // @ts-ignore
            value: (
                <div className="accessibility-message-nodes-list flex-column">
                    {(originalMessage.nodes ?? []).map((n: any, index: number) => {
                        const innerNode = n.any[0] ?? {};
                        const selector = n.target[0];

                        return <NodesListItem key={index} selector={selector} onClick={() => onNodeClick(selector, innerNode.message)} />;
                    })}
                </div>
            ),
        });
    } else {
        listItems.push({
            label: "Elements",
            //@ts-ignore
            value: (
                <div className="accessibility-message-nodes-list flex-column">
                    {message.nodes.map((n, index) => (
                        <NodesListItem key={index} selector={n.selector} onClick={() => onNodeClick(n.selector, message.message)} />
                    ))}
                </div>
            ),
        });
    }

    if (!isEmpty(originalMessage?.tags)) {
        listItems.push({
            label: "Tags",
            value: originalMessage.tags.join(", "),
        });
    }

    return (
        <div className="accessibility-message-details flex-column">
            {/* @ts-ignore */}
            <InfoList items={listItems} />
        </div>
    );
};

const NodesListItem = ({ selector, onClick }: NodesListItemProps) => {
    return (
        <div className="nodes-list-item flex-row align-center">
            <TextLabel className="flex-one-in-row">{selector}</TextLabel>
            {selector && <IconWrap icon="target" title="Highlight Element" onClick={onClick} />}
        </div>
    );
};

interface NodesListItemProps {
    selector?: string;
    onClick: () => void;
}
