import React, { useCallback, memo, forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import { uploadFile } from "../../../store/files/actions";
import { getResourcePromise, useFolders, useResource } from "../../../store/resources/useResource";
import { entityType } from "../../utils/entityType";
import { listToAnyOf, submitByRefPromise } from "../../utils/form";
import { renderToString } from "react-dom/server";
import WaitIcon from "../WaitIcon";
import InlinePanelHeader from "../Dashboard/Panel/InlinePanelHeader";
import JsonSchemaForm from "../Form/JsonSchema/JsonSchemaFormV2";
import { DEFAULT_FOLDER } from "components/utils/files";
import { isNil } from "lodash";
import StatusMsg from "../StatusMsg";

const FileUpload = memo(
    forwardRef(
        (
            {
                className,
                title,
                fileTypeId,
                entityTypeId,
                entityId,
                programNumber, // need this to get folderlist
                applicationNumber,
                utilityNumber,
                internalRefId,
                disabled,
                defaultFolder,
                tags,
                files,
                onCancel,
                onUploadSuccess,
                onFileUploadSubmit,
                withoutHeaderPanel,
                withinSideNav,
                otherActions,
                noActions,
                useApplicationFileTags, // Allow usage of list of file tags
                multiple = true, // Allow to upload multiple files by default.
                noDocumentSecurity,
                useEquipmentTags = false,
            },
            ref
        ) => {
            const formRef = useRef();

            useImperativeHandle(ref, () => ({
                submitForm() {
                    return submitByRefPromise(formRef);
                },
                getFormRef() {
                    return formRef;
                },
            }));

            const dispatch = useDispatch();
            const isUploading = useSelector((state) => state.files.isUploading);
            const [fileTags, setFileTags] = useState(tags);
            const [fileAuthorizationList = [], isLoadingFileAuthorizationList] = useResource({
                resourceName: "fileAuthorizationList",
                resourceId: utilityNumber,
                forced: true,
                transform: (data) => data?.fileAuthorizationList ?? [],
            });

            const [folders = [], isLoadingFolders] = useFolders({
                entityTypeId,
                entityId: programNumber,
            });

            const schema = getSchema({
                folders,
                fileTags,
                fileTypeId,
                entityTypeId,
                defaultFolder,
                fileAuthorizationList,
                noDocumentSecurity,
                files,
            });

            const uiSchema = getUiSchema({
                folders,
                fileTags,
                fileTypeId,
                entityTypeId,
                withinSideNav,
                multiple,
                noDocumentSecurity,
                useApplicationFileTags,
            });

            useEffect(() => {
                const getAppFileTags = async () => {
                    const fileTagData = await getResourcePromise({
                        resourceName: "fileUploadTags",
                        key: applicationNumber,
                        path: {
                            appId: applicationNumber,
                        },
                    });

                    if (!isNil(fileTagData)) {
                        setFileTags(fileTagData);
                    }
                };
                useApplicationFileTags && getAppFileTags();
            }, [useApplicationFileTags, applicationNumber]);

            useEffect(() => {
                const getEquipmentCalculatorTags = async () => {
                    const programAttributes = await getResourcePromise({
                        resourceName: "programAttributes",
                        key: programNumber,
                        path: {
                            programNumber: programNumber,
                            attributeType: 855,
                        },
                        transform: (data) => data?.programAttributeList,
                    });
                    if (programAttributes && programAttributes.length > 0) {
                        setFileTags(programAttributes);
                    }
                };
                useEquipmentTags && getEquipmentCalculatorTags();
            }, [programNumber, useEquipmentTags]);

            const onFileUpload = useCallback(
                (formData) => {
                    const fileTypeId = entityTypeId === entityType.invoice ? 27 : formData.fileTypeId;
                    const folder = entityTypeId === entityType.invoice ? DEFAULT_FOLDER : formData.folder;
                    const FileAuthorizationGroupNumber =
                        formData.FileAuthorizationGroupNumber === "_NULL_" ? null : formData.FileAuthorizationGroupNumber;

                    const fileUploadData = {
                        entityTypeId,
                        entityId,
                        fileTypeId,
                        itemFilterId: formData.itemFilterId,
                        tag: formData.tag,
                        folder,
                        internalRefId,
                        file: formData.file,
                        FileAuthorizationGroupNumber,
                    };

                    if (onFileUploadSubmit) {
                        onFileUploadSubmit(fileUploadData);
                    } else {
                        dispatch(
                            uploadFile({
                                fileData: fileUploadData,
                                onUploadSuccess,
                            })
                        );
                    }
                },
                [entityTypeId, entityId, internalRefId, onUploadSuccess, onFileUploadSubmit, dispatch]
            );

            return (
                <div className={cn("file-upload", className)}>
                    {!withoutHeaderPanel && <InlinePanelHeader title={title ?? "Upload Files"} onClose={onCancel} />}
                    {isLoadingFolders || isLoadingFileAuthorizationList ? (
                        <WaitIcon />
                    ) : (
                        <JsonSchemaForm
                            formRef={formRef}
                            schema={schema}
                            uiSchema={uiSchema}
                            onSubmit={onFileUpload}
                            noActions={noActions}
                            onCancel={withinSideNav ? undefined : onCancel}
                            submitText={isUploading ? "Uploading..." : "Upload"}
                            noReset
                            disabled={isUploading || disabled}
                            noSubmit={withinSideNav}
                            otherActions={otherActions}
                            otherActionsClass={true}
                        />
                    )}
                </div>
            );
        }
    )
);

const getSchema = ({ folders, fileTags, fileTypeId, entityTypeId, defaultFolder, fileAuthorizationList, noDocumentSecurity, files }) => {
    const foldersList =
        folders.length === 0
            ? [
                  {
                      value: DEFAULT_FOLDER,
                      display: DEFAULT_FOLDER,
                  },
              ]
            : folders;

    let folderDefault = undefined;

    if (folders.length === 0) {
        folderDefault = DEFAULT_FOLDER;
    } else if (folders.length === 1) {
        folderDefault = folders[0].value;
    } else if (defaultFolder && folders.find((i) => i.value === defaultFolder)) {
        folderDefault = defaultFolder;
    }
    const fileTypes = files ? files.supportedFileTypes : "";
    const fileLimitText = files ? (
        <p className="info-text">
            <b>Supported formats:</b> {fileTypes} <br />
            <b>Maximum upload file size:</b> {files.fileSizeLimit}
        </p>
    ) : (
        ""
    );
    if (entityTypeId === entityType.calculator) {
        return {
            type: "object",
            required: ["file"],
            description: renderToString(<StatusMsg msgInfo msgText={fileLimitText} />),
            properties: {
                file: {
                    type: "array",
                    title: "Files",
                    items: {
                        type: "string",
                        format: "data-url",
                    },
                },
                ...(fileTags
                    ? {
                          tag: {
                              type: "string",
                              title: "Tag",
                              anyOf: listToAnyOf({
                                  list: fileTags,
                                  map: (i) => ({ title: i, enum: [i] }),
                              }),
                          },
                      }
                    : {}),
            },
        };
    }

    if (entityTypeId === entityType.invoice) {
        return {
            type: "object",
            required: ["file", "itemFilterId"],
            description: renderToString(<StatusMsg msgInfo msgText={fileLimitText} />),
            properties: {
                file: {
                    type: "array",
                    title: "Files",
                    items: {
                        type: "string",
                        format: "data-url",
                    },
                },
                tag: {
                    type: "string",
                    title: "Tag",
                },
                itemFilterId: {
                    type: "integer",
                    title: "File Access",
                    anyOf: [
                        {
                            type: "integer",
                            title: "Public",
                            enum: [149],
                        },
                        {
                            type: "integer",
                            title: "Private",
                            enum: [150],
                        },
                    ],
                },
            },
        };
    }
    return {
        type: "object",
        required: noDocumentSecurity ? ["field3"] : ["field3", "field9"],
        properties: {
            field3: {
                type: "array",
                title: "Files",
                items: {
                    type: "string",
                    format: "data-url",
                },
            },
            column: {
                type: "object",
                required: ["field5", "field6", "field7"],
                properties: {
                    field5: {
                        type: "string",
                        title: "Folder",
                        default: folderDefault,
                        anyOf: listToAnyOf({
                            list: foldersList,
                            map: (i) => ({
                                title: i.display,
                                enum: [i.value],
                            }),
                        }),
                    },
                    field6: {
                        type: "integer",
                        title: "File Type",
                        default: fileTypeId ? fileTypeId : undefined,
                        anyOf: [
                            {
                                type: "integer",
                                title: "Supporting Document",
                                enum: [27],
                            },
                            {
                                type: "integer",
                                title: "QC Document",
                                enum: [28],
                            },
                            {
                                type: "integer",
                                title: "Application",
                                enum: [29],
                            },
                            {
                                type: "integer",
                                title: "Customer Letter",
                                enum: [154],
                            },
                        ],
                    },
                    field8: {
                        type: "string",
                        title: "Tags",
                    },
                    field7: {
                        type: "integer",
                        title: "File Access",
                        anyOf: [
                            {
                                type: "integer",
                                title: "Public",
                                enum: [149],
                            },
                            {
                                type: "integer",
                                title: "Private",
                                enum: [150],
                            },
                        ],
                    },
                },
            },

            ...(!noDocumentSecurity && {
                field9: {
                    type: "string",
                    title: "File Security",
                    default: fileAuthorizationList[0]?.fileAuthorizationGroupNumber ?? "_NULL_",
                    anyOf: listToAnyOf({
                        list: fileAuthorizationList,
                        map: (i) => ({
                            title: i.fileAuthorizationGroupName,
                            enum: [i.fileAuthorizationGroupNumber ?? "_NULL_"],
                        }),
                    }),
                },
            }),
        },
        description: renderToString(<StatusMsg msgInfo msgText={fileLimitText} />),
    };
};

const getUiSchema = ({
    folders,
    fileTags,
    fileTypeId,
    entityTypeId,
    withinSideNav,
    multiple,
    noDocumentSecurity,
    useApplicationFileTags,
}) => {
    if (entityTypeId === entityType.calculator) {
        return {
            file: {
                "ui:widget": "DropZoneWidget",
                "ui:multiple": multiple,
                "ui:title": "File",
            },
            tag: {
                "ui:options": {
                    emptyItem: true,
                },
            },
        };
    }

    if (entityTypeId === entityType.invoice) {
        return {
            classNames: withinSideNav ? undefined : "grid-columns-3",
            file: {
                "ui:widget": "DropZoneWidget",
                "ui:multiple": multiple,
                "ui:title": multiple ? "Files" : "File",
            },
        };
    }

    return {
        classNames: withinSideNav ? undefined : "grid-columns-3 qc-grid",
        field3: {
            "ui:elementType": "field",
            classNames: "fill-width",
            "ui:widget": "DropZoneWidget",
            "ui:key": "file",
            "ui:multiple": multiple,
            "ui:title": multiple ? "Files" : "File",
        },
        column: {
            field5: {
                "ui:elementType": "field",
                "ui:key": "folder",
            },
            field6: {
                "ui:elementType": "field",
                "ui:disabled": Boolean(fileTypeId),
                "ui:key": "fileTypeId",
            },
            field7: {
                "ui:elementType": "field",
                "ui:key": "itemFilterId",
            },
            field8: {
                "ui:elementType": "field",
                "ui:key": "tag",
                ...(useApplicationFileTags
                    ? {
                          "ui:widget": "SelectWidget",
                          "ui:extraInput": !fileTags?.forceFileTags,
                          "ui:enumItems": fileTags?.fileTagList
                              ? fileTags.fileTagList.map((ft) => ({
                                    label: ft.fileTag,
                                    value: ft.fileTag,
                                }))
                              : [],
                      }
                    : {}),
            },
        },
        ...(!noDocumentSecurity && {
            field9: {
                "ui:elementType": "field",
                "ui:key": "FileAuthorizationGroupNumber",
            },
        }),
    };
};

export default FileUpload;
