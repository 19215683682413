import React, { useEffect, useCallback, memo, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    windowContainerAdd,
    windowAdd,
    windowRemove,
    windowActivate,
    windowRemoveSplit,
    windowSplit,
    windowClearSplit,
    windowSync,
    windowPersist,
} from "../../../store/window/actions";

import SideNavContainer from "../SideNav/SideNavContainer";
import SideNavRoot, { sideNavKey } from "../SideNav/SideNavRoot";
import WindowsContainer from "./WindowsContainer";
import { openConfirmModal } from "../Modal/utils";
import { getConfirmCloseAllTabs, setSettingsConfirmCloseAllTabs } from "components/utils/settings";
import { modalOpen } from "store/modal/actions";
import { updateResource } from "store/resources/actions";

const Windows = memo(({ containerName, persist, initialViews, sidebars }) => {
    const dispatch = useDispatch();

    const viewsContainer = useSelector((state) => state.window[containerName]);
    const views = useMemo(() => viewsContainer?.views || [], [viewsContainer]);
    const showSidebars = sidebars ?? true;

    useEffect(() => {
        let isInitialViewActive = false;

        dispatch(windowContainerAdd(containerName));

        if (initialViews) {
            initialViews.forEach((view) => {
                if (!views.find((i) => i.name === view.name)) {
                    dispatch(windowAdd(view));
                }
            });

            isInitialViewActive = views.filter((i) => i.active && i.name === initialViews[0].name).length > 0;
        }

        if ((!views || !views.length) && !isInitialViewActive && initialViews.length > 0) {
            dispatch(
                windowActivate({
                    containerName: containerName,
                    name: initialViews[0].name,
                })
            );
        }

        if (persist) {
            dispatch(windowPersist());
        }
    }, [containerName, persist, initialViews, views, dispatch]);

    const onWindowOpen = useCallback(
        (params) => {
            dispatch(
                windowAdd({
                    containerName: containerName,
                    name: params.name,
                    component: params.component,
                    showTab: params.showTab !== undefined ? params.showTab : true,
                    showHeader: params.showHeader !== undefined ? params.showHeader : true,
                    header: params.header,
                    close: params.close !== undefined ? params.close : true,
                    props: params.props,
                    activate: true,
                })
            );

            if (persist) {
                dispatch(windowPersist());
            }
        },
        [containerName, persist, dispatch]
    );

    const closeWindow = useCallback(
        (name) => {
            const view = views.filter((view) => view.name === name)[0];

            if (view.isSplitView) {
                if (!view.leftViewOnClose && !view.rightViewOnClose) {
                    dispatch(
                        windowRemove({
                            containerName: containerName,
                            name: name,
                        })
                    );
                } else {
                    // Control view individual onClose - if rigth side exists, call that side onclose and after that call left sides
                    // onClose ( if that side exists ) using callback functions
                    if (view.rightViewOnClose) {
                        view.rightViewOnClose(() => {
                            if (view.leftViewOnClose) {
                                view.leftViewOnClose();
                            }
                        });
                    } else if (view.leftViewOnClose) {
                        view.leftViewOnClose();
                    }
                }
            } else {
                dispatch(
                    windowRemove({
                        containerName: containerName,
                        name: name,
                    })
                );
            }
        },
        [containerName, dispatch, views]
    );

    const confirmAllTabsClose = useCallback(() => {
        views.forEach((view) => {
            const name = view.name;
            closeWindow(name);

            if (persist) {
                dispatch(windowPersist());
            }
        });
    }, [closeWindow, dispatch, persist, views]);

    const settingsFromStore = useSelector((state) => state.user?.settings);
    const settings = useMemo(() => settingsFromStore ?? {}, [settingsFromStore]);

    const onConfirmCloseAllTabsChange = useCallback(
        (confirmCloseAllTabs) => {
            setSettingsConfirmCloseAllTabs(confirmCloseAllTabs);

            dispatch(
                updateResource({
                    resourceName: "userSettings",
                    body: {
                        ...settings,
                        confirmCloseAllTabs,
                    },
                    showSuccessNotification: false,
                })
            );
        },
        [settings, dispatch]
    );

    const onAllWindowsClose = useCallback(() => {
        if (!getConfirmCloseAllTabs()) {
            confirmAllTabsClose();
        } else {
            dispatch(
                modalOpen({
                    modalType: "CONFIRM",
                    modalProps: {
                        title: "Close All Tabs",
                        overlayClassName: "modal-styled",
                        className: "modal-sm",
                        modalIcon: "remove",
                        footerContentCenter: true,
                        showDontAskOption: true,
                        content: <span>Are you sure you want to close all opened tabs at once?</span>,
                        onConfirm: (dontAskAgain) => {
                            onConfirmCloseAllTabsChange(!dontAskAgain);
                            confirmAllTabsClose();
                        },
                    },
                })
            );
        }
    }, [confirmAllTabsClose, dispatch, onConfirmCloseAllTabsChange]);

    const onWindowClose = useCallback(
        (name) => {
            closeWindow(name);
            if (persist) {
                dispatch(windowPersist());
            }
        },
        [closeWindow, persist, dispatch]
    );

    const onWindowActivate = useCallback(
        (name) => {
            dispatch(
                windowActivate({
                    containerName: containerName,
                    name: name,
                })
            );

            if (persist) {
                dispatch(windowPersist());
            }
        },
        [containerName, persist, dispatch]
    );

    const onWindowSplit = useCallback(
        (name) => {
            dispatch(
                windowSplit({
                    containerName,
                    name,
                })
            );

            if (persist) {
                dispatch(windowPersist());
            }
        },
        [containerName, persist, dispatch]
    );

    const onWindowRemoveSplit = useCallback(
        (name) => {
            dispatch(
                windowRemoveSplit({
                    containerName,
                    name,
                })
            );

            if (persist) {
                dispatch(windowPersist());
            }
        },
        [containerName, persist, dispatch]
    );

    const onWindowClearSplit = useCallback(
        (name, side) => {
            dispatch(
                windowClearSplit({
                    containerName,
                    name,
                    side,
                })
            );

            if (persist) {
                dispatch(windowPersist());
            }
        },
        [containerName, persist, dispatch]
    );

    const onWindowSync = useCallback(
        (name) => {
            dispatch(
                windowSync({
                    containerName,
                    name,
                })
            );

            if (persist) {
                dispatch(windowPersist());
            }
        },
        [containerName, persist, dispatch]
    );

    const content = (
        <WindowsContainer
            containerName={containerName}
            onWindowOpen={onWindowOpen}
            onWindowClose={onWindowClose}
            onWindowActivate={onWindowActivate}
            onWindowSplit={onWindowSplit}
            onWindowRemoveSplit={onWindowRemoveSplit}
            onWindowClearSplit={onWindowClearSplit}
            onWindowSync={onWindowSync}
            onAllWindowsClose={onAllWindowsClose}
        />
    );

    if (showSidebars) {
        return (
            <SideNavContainer sideNavIds={[sideNavKey.globalLeft, sideNavKey.globalRight]}>
                <SideNavRoot id={sideNavKey.globalLeft} onConfirmClose={openConfirmModal} />
                {content}
                <SideNavRoot id={sideNavKey.globalRight} onConfirmClose={openConfirmModal} />
            </SideNavContainer>
        );
    }

    return content;
});

export default Windows;
