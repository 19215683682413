import { useMemo } from "react";
import { useProgramCategories } from "store/resources/useResource";
import { hasChildren, getParents } from "./tree";

export const useProgramCategoriesForDropdown = ({ programNumber, resourceName, initialItem }) => {
    const [categories = [], isLoadingCategories] = useProgramCategories({
        resourceName,
        programNumber,
    });

    const categoriesList = useMemo(() => {
        let items = categories
            .filter(
                (item) =>
                    !hasChildren({
                        item,
                        list: categories,
                        idKey: "categoryNumber",
                        parentIdKey: "parentCategoryNumber",
                    })
            )
            .map((item) => {
                const { parents } = getParents({
                    item,
                    list: categories,
                    idKey: "categoryNumber",
                    parentIdKey: "parentCategoryNumber",
                });

                const categoryName = parents
                    .map((i) => i.category)
                    .concat(item.category)
                    .join(" > ");
                const categoryNumber = item.categoryNumber;

                return {
                    categoryName,
                    categoryNumber,
                };
            });

        // Add existing category to list if it is not present in available list
        if (initialItem.categoryNumber && !items.some((i) => i.categoryNumber === initialItem.categoryNumber)) {
            items = [initialItem].concat(items);
        }

        return items;
    }, [categories, initialItem]);

    return [categoriesList, isLoadingCategories];
};
