import React, { memo } from "react";
import SearchArea from "../../ui/GlobalSearch/SearchArea";

const GlobalSearchHeader = memo(({ instanceId }) => {
    return (
        <div className="global-search__header">
            <SearchArea instanceId={instanceId} />
        </div>
    );
});

export default GlobalSearchHeader;
