import { isEmpty } from "lodash";
import * as actionTypes from "../actionTypes";
import { store } from "../configureStore";
import { logError } from "../../components/utils/logger";
import { saveServerTimezone } from "components/utils/date";

let timer = null;

export const BASE_URL = process.env.REACT_APP_SERVICES_API_BASE_URL;

export const heartbeat = () => (dispatch, getState) => {
    dispatch({
        type: actionTypes.API_GET,
        url: BASE_URL + "heartbeat",
        actionTypes: {
            pending: actionTypes.SYSTEM_STATUS_REQUEST,
            response: actionTypes.SYSTEM_STATUS_RECEIVE,
            error: actionTypes.SYSTEM_STATUS_ERROR,
        },
    });

    // Do not check for new versions if initial version is not set up
    // For example when developing locally
    const { currentVersion } = getState().system;
    if (currentVersion !== "GIT_VERSION") {
        checkAppVersion(currentVersion);
    }

    const intervalMs = getState().system.heartbeatInterval * 1000;

    if (intervalMs > 0) {
        clearInterval(timer);

        timer = setInterval(() => {
            // Skip this beat if request is in progress
            if (!getState().system.pending) {
                dispatch(heartbeat());
            }
        }, intervalMs);
    }
};

export const stopHeartbeat = () => {
    clearInterval(timer);

    return { type: actionTypes.HEARTBEAT_STOP };
};

export const setServerTimezoneOffset =
    ({ serverTimezoneOffset }) =>
    (dispatch, getState) => {
        saveServerTimezone(serverTimezoneOffset);

        dispatch({
            type: actionTypes.SET_SERVER_TIMEZONE_OFFSET,
            serverTimezoneOffset,
        });
    };

const checkAppVersion = async (currentVersion) => {
    try {
        const response = await fetch("/version.json", {
            method: "GET",
            mode: "cors",
            cache: "no-store",
            headers: {
                Accept: "application/json",
            },
        });

        const jsonResponse = await response.json();
        const newVersion = jsonResponse.version;

        if (!isEmpty(newVersion) && newVersion !== currentVersion) {
            store.dispatch({ type: actionTypes.APP_VERSION_CHANGED });
        }
    } catch (error) {
        logError(error);
    }
};
