import React, { memo, useRef, useState, useEffect, useCallback } from "react";
import cn from "classnames";

import { sideNavSize } from "./SideNavRoot";
import SideNavBackdrop from "./SideNavBackdrop";
import { SideNavContext } from "./SideNavContext";

import "./style.scss";

const SideNav = memo(({ id, className, position, mode, size = sideNavSize.small, backdrop, disableClose, children }: SideNavProps) => {
    const sideNavClassName = cn("sidenav", `sidenav--${position}`, `sidenav--${mode}`, `sidenav--${size}`, className);
    const onCloseCallback = useRef<() => void | null>(null);

    const registerOnClose = useCallback((onCloseCb: () => void) => {
        (onCloseCallback as React.MutableRefObject<() => void | null>).current = onCloseCb;
    }, []);

    const [contextState] = useState({
        registerOnClose,
    });

    useEffect(() => {
        const callback = onCloseCallback.current;

        return () => {
            if (callback) {
                callback();
            }
        };
    }, []);

    return (
        <SideNavContext.Provider value={contextState}>
            <SideNavBackdrop id={id} show={backdrop} disableClose={disableClose} />
            <div id={`${id}-sidenav`} className={sideNavClassName}>
                {children}
            </div>
        </SideNavContext.Provider>
    );
});

export interface SideNavProps {
    id?: string;
    className?: string;
    position?: string;
    mode?: string;
    size?: string;
    backdrop?: boolean;
    disableClose?: boolean;
    children?: React.ReactNode;
}

export default SideNav;
