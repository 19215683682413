import React, { memo } from "react";

import NothingFoundBlock from "../../../../NothingFoundBlock";
import IconWithLabel from "../../../../Icons/IconWithLabel";
import ContactViewForm from "./ContactViewForm";
import { hasAnyOfPermissions, systemUserRights } from "components/utils/user";

const ContactList = memo(({ utilityNumber, customerNumber, items, onContactAdd }) => {
    const noContacts = !Array.isArray(items) || items.length === 0;
    const addNewContactDisabled = !hasAnyOfPermissions([systemUserRights.VISIONDSM_ADD_APPLICATION]);

    return (
        <>
            {noContacts ? (
                <NothingFoundBlock nothingFoundBlockSmall icon="contacts" title="No contacts available">
                    <IconWithLabel
                        iconWithLabelRight
                        className="add-contact"
                        icon="plus"
                        onClick={onContactAdd}
                        disabled={addNewContactDisabled}
                    >
                        Add New Contact
                    </IconWithLabel>
                </NothingFoundBlock>
            ) : (
                <div className="customer-contact-list flex-row flex-wrap flex-one">
                    {(items || []).map((contact, index) => (
                        <ContactViewForm
                            key={index}
                            utilityNumber={utilityNumber}
                            customerNumber={customerNumber}
                            contactNumber={contact.contactNumber}
                        />
                    ))}
                </div>
            )}
        </>
    );
});

export default ContactList;
