import React, { memo } from "react";
import { get } from "lodash";
import { useInvoiceDocument } from "../../../../store/resources/useResource";

const TabContentForInvoice = memo(({ view }) => {
    const documentNumber = get(view, `props.documentNumber`);

    const [invoiceDocument = {}] = useInvoiceDocument({ documentNumber });

    let contentItems = [
        {
            label: "Utility",
            value: invoiceDocument.utility,
        },
        {
            label: "Contract",
            value: invoiceDocument.contractDescription,
        },
        {
            label: "Invoice",
            value: invoiceDocument.vendor || <>&nbsp;</>,
        },
    ];

    return (
        <div className="tab-content">
            {contentItems.map((item) => (
                <div key={item.label} className="tab-content-item">
                    <div className="title">{item.label}</div>
                    <div className="value">{item.value}</div>
                </div>
            ))}
        </div>
    );
});

export default TabContentForInvoice;
