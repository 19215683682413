import { getResource } from "../../../../../../store/resources/actions";
import { store } from "../../../../../../store/configureStore";
import { refreshInvoiceDocumentsGrid, refreshContractDetailsInvoiceDocumentsGrid } from "../../../../../../store/dataGrid/refreshGrid";
import { getInvoiceDocumentResourceParams } from "store/configureResources";

export const getInvoiceDocumentsGridId = ({ contractNumber }) => {
    return `${contractNumber}-invoices-grid`;
};

export const getContractDetailsInvoiceDocumentsGridId = ({ contractNumber }) => {
    return `${contractNumber}-details-invoices-grid`;
};

export const invoiceDocumentRefresh = ({ utilityNumber, contractNumber, documentNumber }) => {
    refreshInvoiceDocumentsGrid({ contractNumber });
    refreshContractDetailsInvoiceDocumentsGrid({ contractNumber });

    store.dispatch(
        getResource({
            resourceName: "utilitiesContractsAttributes",
            key: contractNumber,
            path: {
                utilityNumber,
                contractNumber,
            },
        })
    );

    if (documentNumber) {
        store.dispatch(
            getResource({
                resourceName: "utilitiesContractsInvoiceDocumentsAttributes",
                key: documentNumber,
                path: {
                    utilityNumber,
                    contractNumber,
                    documentNumber,
                },
            })
        );

        store.dispatch(
            getResource({
                ...getInvoiceDocumentResourceParams(),
                resourceId: documentNumber,
            })
        );
    }
};

export const contractInvoiceDocumentsGridColumns = [
    {
        id: "CE2F0766-DCEF-4352-A83C-EC8C183BB806",
        key: "invId",
        name: "INV #",
        active: false,
    },
    {
        id: "7A225C5A-33A9-4CA3-9C73-29DCDA95F3BF",
        key: "vendor",
        name: "VENDOR",
    },
    {
        id: "5606F098-1246-4312-9C11-EF5C3FB9C071",
        key: "invoiceDate",
        name: "INVOICE DATE",
        datatype: "date",
    },
    {
        id: "4D4F2D94-88FC-401F-A301-20C7681A62E1",
        key: "dateEdited",
        name: "EDIT DATE",
        datatype: "datetime",
    },
    {
        id: "E6E39293-2F95-44FA-8935-0B6FD496970A",
        key: "total",
        name: "TOTAL",
    },
    {
        id: "47088629-2A5B-4C90-AA2A-6D91EF90DB38",
        key: "status",
        name: "STATUS",
    },
    {
        id: "32F7BFBF-382B-4C5D-A1CC-969A5858023C",
        key: "invoiceDocumentID",
        name: "ID",
        active: false,
    },
    {
        id: "DA0FDEDC-158F-4F7A-95B1-19DB8032CC2F",
        key: "invoiceDocumentNumber",
        name: "NUMBER",
        active: false,
    },
    {
        id: "7FC0FE2B-1BD6-41C6-91B1-A1E0AB1D20EB",
        key: "refId",
        name: "REF #",
        active: false,
    },
    {
        id: "F46EC515-A8FA-416F-95CB-61D1E2E2C905",
        key: "invoiceNumber",
        name: "INVOICE NUMBER",
        active: false,
    },
    {
        id: "5E32E3AC-B076-487E-940A-AADC31A0E840",
        key: "invoiceDescription",
        name: "INVOICE DESCRIPTION",
        active: false,
    },
    {
        id: "DC5C89F9-AD74-4B71-89FB-F6F7C7B98E81",
        key: "paymentText",
        name: "PAYMENT TEXT",
        active: false,
    },
    {
        id: "3A4D86A8-FAA3-4BB1-8A11-51C37A9F851B",
        key: "islocked",
        name: "LOCKED",
        active: false,
    },
    {
        id: "257712DB-31C6-4B46-AC0E-8E6C6F3D6900",
        key: "dateEntered",
        name: "ENTERED",
        active: false,
        datatype: "datetime",
    },
];
