import React, { useState } from "react";
import { connect } from "react-redux";

import TabList from "../../ui/List/TabList";
import LogsSecurityUsers from "./LogsSecurityUsers";
import LogsSecurityErrors from "./LogsSecurityErrors";
import LogsSecurityCustomers from "./LogsSecurityCustomers";

import "./style.scss";

const tabs = [
    {
        id: "errors",
        title: "Log",
    },
    {
        id: "users",
        title: "Users Logged In",
    },
    {
        id: "customers",
        title: "Customers Logged In",
    },
];

const LogsSecurity = () => {
    const [activeTab, setActiveTab] = useState(tabs[0]);

    return (
        <div className="logs-security flex-column fill-height">
            <div className="main-grid-wrap responsive flex-column fill-height">
                <TabList items={tabs} activeItem={activeTab} onClick={(tab) => setActiveTab(tab)} />
                {activeTab.id === "errors" && <LogsSecurityErrors />}
                {activeTab.id === "customers" && <LogsSecurityCustomers />}
                {activeTab.id === "users" && <LogsSecurityUsers />}
            </div>
        </div>
    );
};

export default connect()(LogsSecurity);
