import { Localize } from "components/utils/text";

export const userDataSchema = {
    type: "object",
    required: ["firstName", "lastName", "email"],
    properties: {
        userName: {
            type: "string",
            title: "User Name",
            readOnly: true,
        },
        firstName: {
            type: "string",
            title: "First Name",
        },
        lastName: {
            type: "string",
            title: "Last Name",
        },
    },
};

export const passwordChangeSchema = {
    type: "object",
    properties: {
        oldPassword: {
            type: "string",
            title: "Old password",
        },
        newPassword: {
            type: "string",
            title: "New password",
        },
        repeatPassword: {
            type: "string",
            title: "Repeat password",
        },
    },
};

export const optionalPhoneSchema = {
    type: "object",
    required: [],
    properties: {
        phone: {
            type: "string",
            title: "Phone",
        },
        cell: {
            type: "string",
            title: "Cell",
        },
        pager: {
            type: "string",
            title: "Pager",
        },
    },
};

export const optionalAddressSchema = {
    type: "object",
    required: [],
    properties: {
        address1: {
            type: "string",
            title: "Address1",
        },
        address2: {
            type: "string",
            title: "Address2",
        },
        city: {
            type: "string",
            title: "City",
        },
        state: {
            type: ["string", "null"],
            title: Localize.STATE,
        },
        zip: {
            type: "string",
            title: "Postal Code",
        },
    },
};

export const optioanlOtherSchema = {
    type: "object",
    required: [],
    properties: {
        company: {
            type: "string",
            title: "Company",
        },
    },
};

export const userDataUiSchema = {
    userName: {
        "ui:readonly": true,
    },
};

export const optPhoneUiSchema = {};

export const optAddressUiSchema = {
    state: {
        classNames: "field-state-code",
        "ui:widget": "state",
    },
    zip: {
        classNames: "field-zip-code",
    },
};

export const optOtherUiSchema = {};
