import React, { memo } from "react";
import { useDispatch } from "react-redux";

import { deleteResource } from "../../../../../../store/resources/actions";
import { programsContentGridColumnKeys } from "../../../../../views/configureGrids";
import { modalOpen } from "../../../../../../store/modal/actions";
import { getData } from "../../../../../../store/dataGrid/actions";

import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid";
import ContentForm from "./ContentForm";
import ContentRevisionsPanel from "./ContentRevisionsPanel.js";

import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import { isChildProgram } from "components/views/ProgramView/utils";

const ContentGrid = memo((props) => {
    const dispatch = useDispatch();

    const { gridId, dataGridConfig, programNumber, formNumber } = props;
    const isLocked = isChildProgram({ programNumber });

    const customRowActions = [
        {
            name: "edit",
            title: isLocked ? "View Content" : "Edit Content",
            icon: isLocked ? "eye-visibility-empty" : "edit-empty",
        },
        {
            name: "revisions",
            title: "Manage Content Revisions",
            icon: "assignment",
        },
        {
            name: "delete",
            title: "Delete Content",
            icon: "delete-trash-empty",
            disabled: isLocked,
        },
    ];

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.medium,
    });

    const onRowAction = (action) => {
        const { name, dataItem } = action;
        const contentTitle = dataItem[programsContentGridColumnKeys.title];
        const contentNumber = dataItem[programsContentGridColumnKeys.contentNumber];

        switch (name) {
            case "edit":
                handleOpenSidePanel(
                    <ContentForm
                        dataItem={dataItem}
                        gridId={gridId}
                        programNumber={programNumber}
                        formNumber={formNumber}
                        onClose={handleCloseSidePanel}
                    />,
                    { className: "content-edit-sidenav-panel" }
                );
                break;

            case "revisions":
                handleOpenSidePanel(
                    <ContentRevisionsPanel
                        programNumber={programNumber}
                        formNumber={formNumber}
                        contentNumber={contentNumber}
                        contentTitle={contentTitle}
                        onClose={handleCloseSidePanel}
                        contentGridId={gridId}
                    />,
                    { size: sideNavSize.staticLarge }
                );
                break;

            case "delete":
                const dialogMessage = (
                    <p>
                        Are you sure you want to delete the content <b> {contentTitle} </b> ?
                    </p>
                );

                dispatch(
                    modalOpen({
                        modalType: "CONFIRM",
                        modalProps: {
                            title: "Delete Content",
                            overlayClassName: "modal-styled",
                            className: "delete-content-confirmation-modal modal-sm",
                            modalIcon: "delete-trash-empty",
                            content: dialogMessage,
                            footerContentCenter: true,
                            onConfirm: () => {
                                dispatch(
                                    deleteResource({
                                        resourceName: "programContent",
                                        resourceId: contentNumber,
                                        path: {
                                            programNumber: programNumber,
                                        },
                                        optimisticUpdate: {
                                            clearItem: true,
                                        },
                                        onSuccess,
                                    })
                                );
                            },
                        },
                    })
                );
                break;

            default:
                break;
        }
    };

    const onSuccess = () => {
        dispatch(
            getData({
                dataGridId: gridId,
            })
        );
    };

    return <DataGrid name={gridId} config={dataGridConfig} onRowAction={onRowAction} customRowActions={customRowActions} />;
});

export default withDataGrid("programsContent", ContentGrid);
