import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import { get, isString } from "lodash";

import { useSearchHistory, useSearchOptions } from "../../../store/resources/useResource";
import { toTimeAgo } from "../../utils/date";
import { openProjectTab } from "../../utils/window";
import { toArray } from "../../utils/array";
import { clickSearchHistory } from "../../../store/globalSearch/actions";
import { getRecentSearchCriteriaList } from "./utils";

import WaitIcon from "../WaitIcon";
import IconWrap from "../Icons";

import "./SearchHistory.scss";

const SearchHistory = memo(({ instanceId, hidden }) => {
    const dispatch = useDispatch();

    const criteriaList = useSelector((state) => state.globalSearch.criteriaList[instanceId]) ?? [];
    const searchOptions = useSelector((state) => state.resources.searchOptions.itemsById["searchOptions"]);
    const utilitiesList = useSelector((state) => state.resources.utilities.itemsById.utilities) ?? [];

    const [, isLoadingOptions] = useSearchOptions();
    const [searchHistory, isLoadingHistory] = useSearchHistory({ forced: true });

    const onSetCriteria = ({ criteria }) => {
        dispatch(clickSearchHistory({ instanceId, criteria }));
    };

    const onOpenApplication = (applicationNumber) => openProjectTab({ applicationNumber });

    const criteriaHistory = toArray(get(searchHistory, "RETURN.SEARCH_HISTORY.HISTORY", []));
    const applicationHistory = toArray(get(searchHistory, "RETURN.APP_HISTORY.APP", []));

    const noHistory = !isLoadingHistory && criteriaHistory.length === 0 && applicationHistory.length === 0;

    const criteriaHistoryItem = (item, index) => {
        const criteria = isString(item.SEARCH_CRITERIA) ? JSON.parse(item.SEARCH_CRITERIA) : item.SEARCH_CRITERIA.SEARCH_FILTER;
        const searchCriteriaList = getRecentSearchCriteriaList({
            criteria,
            searchOptions,
            utilitiesList,
        });

        if (searchCriteriaList.length === 0) {
            return null;
        }

        return (
            <div key={index} className="search-history__item criteria" onClick={() => onSetCriteria({ criteria })}>
                <IconWrap icon="search" />
                <div className="search-history__item__value">
                    {searchCriteriaList.map((i, index) => {
                        return (
                            <div key={index}>
                                <span className="search-history__item__value--main-criteria-title">
                                    {i.mainCriteriaTitle === "" ? "Everywhere:" : ""}
                                </span>
                                {i.mainCriteriaTitle !== "" && (
                                    <span className="search-history__item__value--subcriteria-title">
                                        {i.subCriteriaTitle === "" ? "Everywhere in " + i.mainCriteriaTitle : i.subCriteriaTitle}:
                                    </span>
                                )}
                                <span className="link">{i.value}</span>
                            </div>
                        );
                    })}
                </div>
                <div className="search-history__item__details criteria">
                    <span className="search-history__item__details__time criteria">{toTimeAgo(item.SEARCH_DATE)}</span>
                </div>
            </div>
        );
    };

    const applicationHistoryFields = [
        {
            key: "UTILITY",
            title: "UTILITY",
        },
        {
            key: "PROGRAM",
            title: "PROGRAM",
        },
        {
            key: "CONTACT_NAME",
            title: "CONTACT NAME",
        },
        {
            key: "PROJECT_NUMBER",
            title: "PROJECT NUMBER",
        },
        {
            key: "APP_STATUS",
            title: "STATUS",
        },
    ];

    const applicationHistoryItem = (item, index) => {
        return (
            <div key={index} className="search-history__item application" onClick={() => onOpenApplication(item.APPID)}>
                <div className="search-history__tile flex-row fill-width">
                    {applicationHistoryFields.map((i) => SearchResultField(index, i.title, item[i.key] ?? "-"))}
                </div>
            </div>
        );
    };

    const SearchResultField = (index, title, value) => {
        return (
            <div key={index + title} className="search-history__field flex-column">
                <span className="search-history__field__title">{title}:</span>
                <span className="search-history__field__value">{value}</span>
            </div>
        );
    };

    if (criteriaList.length > 0) {
        return null;
    }

    if (isLoadingOptions) {
        return null;
    }

    if (isLoadingHistory) {
        return <WaitIcon />;
    }

    if (noHistory) {
        return null;
    }

    return (
        <div
            className={cn("search-history flex-column no-scroll", {
                "search-history--hidden": hidden,
            })}
        >
            {criteriaHistory.length > 0 && (
                <div className="search-history__criteria-wrapper flex-column">
                    <div className="search-history__section-title">Recent Searches</div>
                    <div className="search-history__criteria-list">{criteriaHistory.map(criteriaHistoryItem)}</div>
                </div>
            )}
            {applicationHistory.length > 0 && (
                <div className="search-history__applications-wrapper flex-column flex-one no-scroll">
                    <div className="search-history__section-title">Recent Applications</div>
                    <div className="search-history__applications-list flex-one with-scroll">
                        {applicationHistory.map(applicationHistoryItem)}
                    </div>
                </div>
            )}
        </div>
    );
});

export default SearchHistory;
