import React, { useCallback, useMemo, memo, useState, useEffect } from "react";
import { isEmpty } from "lodash";

import { useResource } from "../../../../../../../../store/resources/useResource";
import { documentType } from "../../../../../../../utils/constants";
import { STEP_TYPES_KEYS, STEP_TYPES_ATTRIBUTES } from "../../../../../constants/step-types";

import WaitIcon from "../../../../../../WaitIcon";
import JsonSchemaForm from "../../../../../../Form/JsonSchema/JsonSchemaForm";

export const CorrespondenceForm = memo(
    ({
        modal,
        attributes,
        content,
        stepAttributeTypesGetResourceOptions,
        existingCorrespondenceGetResourceOptions,
        existingCorrespondenceDataGetResourceOptions,
        fastTagsGetResourceOptions,
        onChange,
        onCloseModal,
    }) => {
        const [formData, setFormData] = useState({
            corID: undefined,
            emailSubject: undefined,
            content: undefined,
        });

        const [workflowStepAttributeTypes = [], isLoadingWorkflowStepAttributeTypes] = useResource(stepAttributeTypesGetResourceOptions);

        const getAttribute = useCallback(
            ({ number = null, code = null }) => {
                if (number) {
                    return workflowStepAttributeTypes.find((i) => i.number === number);
                } else if (code) {
                    return workflowStepAttributeTypes.find((i) => i.code === code);
                }
            },
            [workflowStepAttributeTypes]
        );

        const isEmail = useMemo(() => modal === STEP_TYPES_KEYS.SEND_EMAIL, [modal]);

        const correspondenceOptions = useMemo(
            () => existingCorrespondenceGetResourceOptions || {},
            [existingCorrespondenceGetResourceOptions]
        );

        const correspondenceDataOptions = useMemo(
            () => existingCorrespondenceDataGetResourceOptions || {},
            [existingCorrespondenceDataGetResourceOptions]
        );

        const [correspondence = [], isCorrespondenceLoading] = useResource({
            ...correspondenceOptions,
            key: isEmpty(correspondenceOptions)
                ? undefined
                : `${correspondenceOptions.key}-${isEmail ? documentType.email : documentType.letter}`,
            query: {
                itemType: isEmail ? documentType.email : documentType.letter,
            },
        });

        const [correspondenceData] = useResource({
            ...correspondenceDataOptions,
            resourceId: formData.corID,
            forced: true,
        });

        const [fastTags, isLoadingFastTags] = useResource({
            ...(fastTagsGetResourceOptions || {}),
            transform: (data) => {
                return ((data && data.friendlyNames) || []).map((fastTag) => fastTag.friendlyName);
            },
        });

        const attributeValues = useMemo(() => {
            return attributes.reduce(
                (acc, { typeNumber, value }) => {
                    const attribute = getAttribute({ number: typeNumber });

                    if (attribute.code === STEP_TYPES_ATTRIBUTES.CORRESPONDENCE_ID) {
                        if (!isCorrespondenceLoading && value) {
                            acc.corID = value;
                        }
                    } else if (attribute.code === STEP_TYPES_ATTRIBUTES.EMAIL_SUBJECT) {
                        if (value) {
                            acc.emailSubject = value;
                        }
                    }

                    return acc;
                },
                { corID: null, emailSubject: "" }
            );
        }, [attributes, isCorrespondenceLoading, getAttribute]);

        const schema = useMemo(
            () => ({
                type: "object",
                properties: {
                    ...(existingCorrespondenceGetResourceOptions
                        ? {
                              corID: {
                                  type: "string",
                                  title: "Existing Correspondence",
                                  anyOf: correspondence.map((item) => ({
                                      title: item.name,
                                      enum: [item.documentNumber],
                                  })),
                              },
                          }
                        : {}),
                    ...(isEmail
                        ? {
                              emailSubject: {
                                  type: "string",
                                  title: getAttribute({
                                      code: STEP_TYPES_ATTRIBUTES.EMAIL_SUBJECT,
                                  }).name,
                              },
                          }
                        : {}),
                    content: {
                        title: "Content",
                        type: "string",
                    },
                },
            }),
            [correspondence, isEmail, existingCorrespondenceGetResourceOptions, getAttribute]
        );

        const uiSchema = useMemo(
            () => ({
                classNames: "inline-form columns-2",
                ...(existingCorrespondenceGetResourceOptions
                    ? {
                          corID: {
                              "ui:placeholder": isCorrespondenceLoading
                                  ? "Loading..."
                                  : correspondence.filter((c) => c.documentNumber === attributeValues.corID).map((c) => c.name)[0] ||
                                    "Existing Correspondence",
                              "ui:disabled": isCorrespondenceLoading || !correspondence.length,
                          },
                      }
                    : {}),
                emailSubject: {
                    ...(isEmpty(fastTagsGetResourceOptions)
                        ? {}
                        : {
                              "ui:widget": "InputWithFastTags",
                              "ui:options": {
                                  fastTags,
                              },
                          }),
                },
                content: {
                    classNames: "fill-width",
                    "ui:widget": "HtmlEditorWidget",
                    ...(isEmpty(fastTagsGetResourceOptions)
                        ? {}
                        : {
                              "ui:options": {
                                  fastTags,
                              },
                          }),
                },
            }),
            [
                existingCorrespondenceGetResourceOptions,
                correspondence,
                isCorrespondenceLoading,
                fastTags,
                attributeValues,
                fastTagsGetResourceOptions,
            ]
        );

        const initialValues = useMemo(() => {
            const emailSubject = attributeValues.emailSubject;

            return {
                ...(isEmail ? { emailSubject } : {}),
                content,
            };
        }, [isEmail, attributeValues, content]);

        useEffect(() => {
            setFormData(initialValues);
        }, [initialValues]);

        useEffect(() => {
            if (correspondenceData) {
                setFormData((prevValue) => {
                    return {
                        ...prevValue,
                        content: correspondenceData.content,
                    };
                });
            }
        }, [initialValues, correspondenceData]);

        const onFormChange = useCallback(async (form) => {
            setFormData(form.formData);
        }, []);

        const handleClickSave = useCallback(
            (formData) => {
                const { content, corID, emailSubject } = formData;
                const attribute = getAttribute({
                    code: STEP_TYPES_ATTRIBUTES.CORRESPONDENCE_ID,
                });

                const attributes = [
                    {
                        typeNumber: attribute.number,
                        name: attribute.name,
                        value: corID || attributeValues.corID || "",
                    },
                ];

                if (isEmail) {
                    const attributeEmail = getAttribute({
                        code: STEP_TYPES_ATTRIBUTES.EMAIL_SUBJECT,
                    });

                    attributes.push({
                        typeNumber: attributeEmail.number,
                        name: attributeEmail.name,
                        value: emailSubject,
                    });
                }

                onChange({
                    content,
                    attributes,
                });

                onCloseModal();
            },
            [isEmail, attributeValues, getAttribute, onChange, onCloseModal]
        );

        if (isLoadingWorkflowStepAttributeTypes || isLoadingFastTags) {
            return <WaitIcon />;
        }

        return (
            <JsonSchemaForm
                className="workflow-step-types-widget__form"
                schema={schema}
                uiSchema={uiSchema}
                initialValues={formData}
                onChange={onFormChange}
                onSubmit={handleClickSave}
                onCancel={onCloseModal}
                submitText="Save"
                noValidate
                noReset
                centeredFooter
            />
        );
    }
);
