import React, { memo } from "react";
import { isEmpty } from "lodash";

import { useApplication } from "../../../../store/resources/useResource";
import { formatFullName } from "components/utils/user";

const TabContentForProject = memo(({ view }) => {
    const applicationNumber = view?.props?.applicationNumber;

    const [application] = useApplication({
        applicationNumber,
    });

    const projectNumber = application?.projectNumber || "";
    const customerName = formatFullName(application?.firstName, application?.lastName);
    let contentItems = [
        {
            label: "Application",
            value: projectNumber,
        },
    ];

    if (application) {
        contentItems = [
            {
                label: "Program",
                value: application.program,
            },
            ...(isEmpty(customerName)
                ? []
                : [
                      {
                          label: "Customer",
                          value: customerName,
                      },
                  ]),
        ].concat(contentItems);
    }

    return (
        <div className="tab-content">
            {contentItems.map((item) => (
                <div key={item.label} className="tab-content-item">
                    <div className="title">{item.label}</div>
                    <div className="value">{item.value}</div>
                </div>
            ))}
        </div>
    );
});

export default TabContentForProject;
