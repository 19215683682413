import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Checkbox from "../../../Input/Checkbox";
import DescriptionField from "../fields/DescriptionField";
import { clearExtraErrors } from "components/utils/form";

function CheckboxWidget(props) {
    const { schema, id, value, required, disabled, readonly, autofocus, onChange, formContext } = props;

    const label = (
        <>
            <span dangerouslySetInnerHTML={{ __html: props.label }} />
            {props.required && <span className="required" />}
        </>
    );

    useEffect(() => {
        if (required && value === false) {
            if (formContext?.extraErrors.filter((e) => e.id === id).length === 0) {
                setTimeout(() => {
                    if (formContext.setExtraError) {
                        formContext.setExtraError(id, "is a required property");
                    }
                }, 0);
            }
        } else {
            clearExtraErrors(id, formContext);
        }
    }, [formContext, id, required, value]);

    return (
        <div className={`checkbox ${disabled || readonly ? "disabled" : ""}`}>
            {schema.description && <DescriptionField description={schema.description} />}
            <Checkbox
                id={id}
                checked={typeof value === "undefined" ? false : value}
                required={required}
                disabled={disabled || readonly}
                autoFocus={autofocus}
                onChange={(event) => onChange(event.target.checked)}
                label={label}
            />
        </div>
    );
}

CheckboxWidget.defaultProps = {
    autofocus: false,
};

if (process.env.NODE_ENV !== "production") {
    CheckboxWidget.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        value: PropTypes.bool,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
    };
}

export default CheckboxWidget;
