import { toArray } from "components/utils/array";
import { useResource } from "store/resources/useResource";
import {
    AnalyticsFilter,
    PortalAppInsightsResponse,
    PortalCampaignInsightsResponse,
    PortalStatisticsResponse,
    PortalUserInsightsResponse,
    PortalZipCodeCount,
} from "./types";
import { DateRangeOption, getDateRange } from "./DateSelector";
import { useMemo } from "react";
import { UserPath } from "./sections/UserPaths/types";

export const getAnalyticsEventsGridId = (hostname: string) => {
    return "analytics-events-" + (hostname ?? "").split(".").join("-");
};

export const useAnalyticsFilterParams = (hostname: string, dateRange: DateRangeOption) => {
    return useMemo(() => {
        const { dateFrom, dateTo } = getDateRange(dateRange);

        return {
            hostname,
            dateFrom,
            dateTo,
        };
    }, [dateRange, hostname]);
};

export const useUserPaths = (filterParams: AnalyticsFilter) => {
    const resourceKey = JSON.stringify(filterParams).split(".").join("-");

    return useResource({
        resourceName: "analyticsUserPaths",
        key: resourceKey,
        query: filterParams,
        forced: true,
    }) as [userPaths: UserPath[] | undefined, isLoading: boolean];
};

export const useAppInsights = (filterParams: AnalyticsFilter) => {
    const resourceKey = JSON.stringify(filterParams).split(".").join("-");

    return useResource({
        resourceName: "analyticsAppInsights",
        key: resourceKey,
        query: filterParams,
        forced: true,
    }) as [appInsights: PortalAppInsightsResponse | undefined, isLoading: boolean];
};

export const useCampaignInsights = (filterParams: AnalyticsFilter, programNumber: string) => {
    const resourceKey = JSON.stringify(filterParams).split(".").join("-");

    const query = useMemo(() => {
        return {
            ...filterParams,
            programNumber,
        };
    }, [filterParams, programNumber]);

    return useResource({
        resourceName: "analyticsCampaigns",
        key: resourceKey,
        query,
        forced: true,
    }) as [campaignInsights: PortalCampaignInsightsResponse, isLoading: boolean];
};

export const useAppZipCodes = (filterParams: AnalyticsFilter, programNumber: string) => {
    const resourceKey = JSON.stringify(filterParams).split(".").join("-");

    return useResource({
        resourceName: "portalZipCodeCount",
        key: resourceKey,
        query: {
            startDate: filterParams.dateFrom,
            endDate: filterParams.dateTo,
        },
        path: {
            programNumber,
        },
        forced: true,
        transform: (data: PortalZipCodeCount[]) => {
            return toArray(data).map((z) => {
                const latLng = z.geoCode?.split(",");
                if (latLng) {
                    const lat = latLng[0];
                    const lng = latLng[1].trim();
                    return { ...z, lat: Number(lat), lng: Number(lng) };
                } else {
                    return z;
                }
            });
        },
    }) as [stats: PortalZipCodeCount[], isLoading: boolean];
};

export const useUserInsights = (filterParams: AnalyticsFilter) => {
    const resourceKey = JSON.stringify(filterParams).split(".").join("-");

    return useResource({
        resourceName: "analyticsUserInsights",
        key: resourceKey,
        query: filterParams,
        forced: true,
    }) as [stats: PortalUserInsightsResponse | undefined, isLoading: boolean];
};

export const usePortalStatistics = (filterParams: AnalyticsFilter, programNumber: string) => {
    const resourceKey = JSON.stringify(filterParams).split(".").join("-");

    return useResource({
        resourceName: "portalStatistics",
        key: resourceKey,
        query: {
            startDate: filterParams.dateFrom,
            endDate: filterParams.dateTo,
        },
        path: {
            programNumber,
        },
        forced: true,
    }) as [stats: PortalStatisticsResponse | undefined, isLoading: boolean];
};

export const getPercent = (count: number, total: number) => {
    const percent = total > 0 ? Math.round((count / total) * 100) : 0;

    return percent > 100 ? 100 : percent;
};

export const secondsToTimeString = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsLeft = seconds % 60;

    const hoursString = hours > 0 ? `${hours}h ` : "";
    const minutesString = minutes > 0 ? `${minutes}m ` : "";
    const secondsString = secondsLeft > 0 ? `${secondsLeft}s` : "";

    return `${hoursString}${minutesString}${secondsString}`;
};

export const MAP_STYLES = [
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [
            {
                color: "#e9e9e9",
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [
            {
                color: "#f5f5f5",
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#ffffff",
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#ffffff",
            },
            {
                lightness: 29,
            },
            {
                weight: 0.2,
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
            {
                color: "#ffffff",
            },
            {
                lightness: 18,
            },
        ],
    },
    {
        featureType: "road.local",
        elementType: "geometry",
        stylers: [
            {
                color: "#ffffff",
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
            {
                color: "#f5f5f5",
            },
            {
                lightness: 21,
            },
        ],
    },
    {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
            {
                color: "#dedede",
            },
            {
                lightness: 21,
            },
        ],
    },
    {
        elementType: "labels.text.stroke",
        stylers: [
            {
                visibility: "on",
            },
            {
                color: "#ffffff",
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        elementType: "labels.text.fill",
        stylers: [
            {
                saturation: 36,
            },
            {
                color: "#333333",
            },
            {
                lightness: 40,
            },
        ],
    },
    {
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "transit",
        elementType: "geometry",
        stylers: [
            {
                color: "#f2f2f2",
            },
            {
                lightness: 19,
            },
        ],
    },
    {
        featureType: "administrative",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#fefefe",
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#fefefe",
            },
            {
                lightness: 17,
            },
            {
                weight: 1.2,
            },
        ],
    },
];
