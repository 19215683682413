import { activityType } from "../../components/utils/window";
import { updateResource } from "../resources/actions";
import { getApplicationActivityResourceParams } from "../configureResources";

export const onActivity =
    ({ view, activity }) =>
    (dispatch, getState) => {
        switch (activity) {
            case activityType.OPEN:
                // do something on tab open
                break;

            case activityType.CLOSE:
                if (view && view.component && view.component === "ProjectView") {
                    const { applicationNumber } = view.props;

                    dispatch(
                        updateResource(
                            getApplicationActivityResourceParams({
                                applicationNumber,
                            })
                        )
                    );
                }

                break;

            default:
                break;
        }
    };
