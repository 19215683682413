import React from "react";

import "./style.scss";

const StickySidePanel = ({ title, children, bodyRef }) => {
    return (
        <div className="sticky-sidepanel no-scroll">
            <div className="sticky-sidepanel__container">
                {title && (
                    <div className="sticky-sidepanel__header">
                        <div className="sticky-sidepanel__header__title">{title}</div>
                    </div>
                )}
                <div ref={bodyRef} className="sticky-sidepanel__body">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default StickySidePanel;
