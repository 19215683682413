import React, { useState, useCallback, memo } from "react";

import { openUtilityTab, openScanQueueTab, closeNewApplicationFromScanTab } from "../../utils/window";
import { openProgramTab } from "store/window/openTabActions";
import { useProgram, useResource, useUtilityRights } from "store/resources/useResource";
import { useDispatch } from "react-redux";

import WindowBodyHeader from "../../ui/WindowBody/WindowBodyHeader";
import SubHeader from "../../ui/Headers/SubHeader";

import Application from "../NewApplication/Application";
import IconWithLabel from "../../ui/Icons/IconWithLabel";
import WaitIcon from "components/ui/WaitIcon";
import { systemUserRights } from "components/utils/user";
import FileView from "../FileView";

import "./NewApplicationFromScan.scss";

const NewApplicationFromScan = memo(({ applicationNumber, utilityNumber, programNumber, formPageNumber, fileNumber }) => {
    const [fileData, isLoading] = useResource({
        resourceName: "files",
        resourceId: fileNumber,
    });

    const [utilityRights = []] = useUtilityRights({ utilityNumber });
    const canViewUtility = utilityRights.includes(systemUserRights.VISIONDSM_VIEW_UTILITY);
    const [program, isLoadingProgramDetails] = useProgram({ programNumber, forced: false });
    const dispatch = useDispatch();

    const headerItems = [
        {
            name: "Utility",
            value: isLoadingProgramDetails ? <WaitIcon /> : program?.utility,
            onClick: canViewUtility
                ? () =>
                      openUtilityTab({
                          utilityNumber,
                      })
                : undefined,
        },
        {
            name: "Program",
            value: isLoadingProgramDetails ? <WaitIcon /> : program?.program,
            onClick: () =>
                dispatch(
                    openProgramTab({
                        programNumber,
                    })
                ),
        },
    ];

    const [isVisibleScannedDoc, setIsVisibleScannedDoc] = useState(false);

    // onWindowRemove - is a callback function for splitview close
    const handleClose = useCallback(
        (onWindowRemove, shouldWindowRemain) => {
            closeNewApplicationFromScanTab({
                applicationNumber,
                onWindowRemove,
                shouldWindowRemain,
            });
            openScanQueueTab();
        },
        [applicationNumber]
    );

    return (
        <div className="create-application-from-scan flex-column fill-height no-scroll">
            <WindowBodyHeader withShadow noPopupDetailsOnMobile>
                <SubHeader items={headerItems} className="fill-width" />
            </WindowBodyHeader>
            <div className="flex-one-in-column no-scroll">
                <div className="main-grid-wrap responsive fill-height">
                    <div className="flex-row">
                        <span className="flex-one"></span>
                        <IconWithLabel
                            className="view-switcher"
                            iconWithLabelRight={isVisibleScannedDoc}
                            icon={isVisibleScannedDoc ? "arrow-in-circle-right-empty" : "arrow-in-circle-left-empty"}
                            onClick={() => setIsVisibleScannedDoc(!isVisibleScannedDoc)}
                        >
                            {isVisibleScannedDoc ? "Hide Scanned Document" : "Show Scanned Document"}
                        </IconWithLabel>
                    </div>
                    <div className="create-application-from-scan__content flex-row fill-height">
                        <div className={"scan-application popup-boundary with-scroll" + (isVisibleScannedDoc ? " hidden" : "")}>
                            <Application
                                fromScan
                                applicationNumber={applicationNumber}
                                utilityNumber={utilityNumber}
                                programNumber={programNumber}
                                formPageNumber={formPageNumber}
                                onClose={handleClose}
                            />
                        </div>
                        <div className={"pdf-viewer" + (isVisibleScannedDoc ? "" : " hidden")}>
                            <FileView fileData={fileData} isLoading={isLoading} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default NewApplicationFromScan;
