import React, { useCallback, useState } from "react";
import ClassNames from "classnames";
import useEnterKey from "../../utils/useEnterKey";
import useFocusClasses from "../../utils/useFocusClasses";

import "./style.scss";
import TextInput from "../Input/TextInput";
import SelectBox from "../SelectBox";

const DropdownField = (props) => {
    const isTabable = !props.disabled;
    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled: !isTabable,
    });
    const [onEnter] = useEnterKey({
        disabled: !isTabable,
        onClick: props.onClick,
    });

    const [addNew, setAddNew] = useState(false);

    const onKeyDown = useCallback(
        (event) => {
            onEnter(event);

            if (event.key === "Tab" && document.activeElement === event.target && props.active) {
                props.onClick();
            } else if (props.visible) {
                props.onListKeyDown(event);
            }
        },
        [onEnter, props]
    );

    const className = ClassNames("dropdown-field", props.dropdownFieldIcon, props.className, {
        empty: props.empty || false,
        "dropdown-ghost": props.ghost,
        filled: props.filled,
        active: props.active,
        error: props.error,
        "light-up-error": props.lightUpError,
        disabled: props.disabled,
        "with-icon": props.dropdownFieldIcon,
        "dropdown-multiple-choice": props.dropdownMultipleChoise,
        "dropdown-read-only": props.readOnly,
        "with-extra-input": props.extraInput,
    });

    return (
        <>
            {props.extraInput && (
                <div className="dropdown-select-boxes flex-row align-center justify-space-between">
                    <SelectBox onClick={() => setAddNew(true)} selectBoxSelected={addNew}>
                        Add New
                    </SelectBox>
                    <SelectBox onClick={() => setAddNew(false)} selectBoxSelected={!addNew}>
                        Select from existing
                    </SelectBox>
                </div>
            )}
            {props.extraInput && addNew && (
                <TextInput
                    className="with-extra-input"
                    value={props.dropdownFieldText}
                    onChange={(e) => {
                        props.onChange(e.target.value);
                    }}
                />
            )}
            {!addNew && (
                <div
                    id={props.id}
                    ref={props.elementRef}
                    className={className}
                    onClick={props.onClick}
                    tabIndex={isTabable ? "0" : "-1"}
                    onKeyDown={onKeyDown}
                    onFocus={onFocusClassesFocus}
                    onBlur={onFocusClassesBlur}
                >
                    {props.dropdownFieldText && !addNew && <span className="dropdown-field-text">{props.dropdownFieldText}</span>}
                    {props.children}
                </div>
            )}
        </>
    );
};

export default DropdownField;
