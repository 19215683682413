import React, { useCallback, useContext } from "react";
import { SectionHeader } from "../../SectionHeader";
import { PortalBuilderContext, PortalBuilderPropertiesContext } from "components/ui/PortalBuilder/contexts";
import { Colors } from "../../Colors";
import { isNil, isString } from "lodash";
import { SelectPanelSubTitle } from "../../SelectPanelSubTitle";

export const SelectColors = (props) => {
    const { id, value, onChange, onClose, row, selectionTitle, title, selectPanelSubTitle } = props;
    const { sectionTitle, sectionIcon, selectedColumn, subTitle } = useContext(PortalBuilderPropertiesContext);
    const { onConfigChange, colors } = useContext(PortalBuilderContext);
    const additionalTitle = isString(selectionTitle) ? !selectionTitle.includes("Common") : false;

    const fullSubTitle = selectPanelSubTitle ? (
        <SelectPanelSubTitle content={selectPanelSubTitle} />
    ) : (
        <span>
            <span className="black-text">
                {isNil(row?.title) ? (additionalTitle ? subTitle.concat(" ", title) : subTitle ?? "Main") : row.title}
                {isNil(selectedColumn) ? "" : <span className="non-transformed-text"> section-{selectedColumn + 1}</span>}
            </span>{" "}
            {isNil(selectionTitle) ? "background" : selectionTitle} color selection
        </span>
    );

    const onSelect = useCallback(
        (color) => {
            onChange(id, color);
            onClose();
        },
        [id, onChange, onClose]
    );

    return (
        <div className="portal-builder-properties-section flex-column fill-width fill-height">
            <SectionHeader Icon={sectionIcon} title={sectionTitle} subtitle={fullSubTitle} underline onGoBack={onClose} />
            <Colors noRemoval onChange={onConfigChange} onSelect={onSelect} selectedColor={value} colors={colors} />
        </div>
    );
};

export default SelectColors;
