import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from "react";

import useUnmounted from "../../utils/useUnmounted";
import { scrollEnd } from "../../utils/scroll";

import IconWrap from "../Icons";
import { debounce, isNil } from "lodash";

import "./style.scss";

// Show controls only if scroll longer than content + treshold;
const defaultTreshold = 3;
const CHILD_VERTICAL_SCROLL_TRESHOLD = 10;

const ScrollControls = ({
    activeItemIndex,
    targetId,
    noWrap,
    iconSmall,
    updateInterval,
    horizontalScroll = true,
    onChange,
}: ScrollControlsProps) => {
    const unmounted = useUnmounted();

    const [scrollArrows, setScrollArrows] = useState({
        left: false,
        right: false,
    });

    const updateIntervalTimer = useRef();
    const lastScrollLeft = useRef<number>();

    const updateScrollable = useCallback(() => {
        const node = document.getElementById(targetId);

        if (!unmounted.current && node) {
            setScrollArrows((prevValue) => {
                const left = node.scrollLeft > 0;
                const treshold = left ? 0 : defaultTreshold;
                const right = Math.ceil(node.scrollLeft + node.offsetWidth + treshold) < node.scrollWidth;

                if (prevValue.left !== left || prevValue.right !== right) {
                    const newValue = { left, right };

                    if (lastScrollLeft.current !== node.scrollLeft) {
                        lastScrollLeft.current = node.scrollLeft;
                        onChange && onChange(newValue);
                    }

                    return newValue;
                }

                return prevValue;
            });
        }
    }, [onChange, targetId, unmounted]);

    useEffect(() => {
        const targetNode = document.getElementById(targetId);
        const observer = new MutationObserver(() => {
            updateScrollable();
        });

        if (targetNode) {
            observer.observe(targetNode, {
                childList: true,
            });
        }

        return () => {
            observer.disconnect();
        };
    }, [targetId, updateScrollable]);

    useEffect(() => {
        if (!unmounted.current) {
            let timer: ReturnType<typeof setInterval> = updateIntervalTimer.current!;
            if (!timer && updateInterval && updateInterval > 0) {
                timer = setInterval(() => {
                    updateScrollable();
                }, updateInterval);
            } else {
                clearInterval(timer);
            }

            return () => {
                clearInterval(timer);
            };
        }
    }, [updateInterval, updateScrollable, unmounted]);

    useEffect(() => {
        if (!unmounted.current) {
            const node = document.getElementById(targetId);
            let isScrolling: ReturnType<typeof setInterval>;

            const onScroll = () => {
                clearTimeout(isScrolling);
                isScrolling = setTimeout(() => {
                    updateScrollable();
                }, 50);
            };

            const onWheel = (event: any) => {
                if (node && !event.shiftKey && node.scrollWidth > node.clientWidth && horizontalScroll) {
                    // check if we are scrolling child element
                    if (event.path?.length > 0) {
                        for (let i = 0; i < event.path.length; i++) {
                            const child = event.path[i];

                            // End search if there are no other children with scroll
                            if (child === node) {
                                break;
                            }

                            // Use default behavior if child has vertical scrollbar
                            if (child.scrollHeight > child.clientHeight + CHILD_VERTICAL_SCROLL_TRESHOLD) {
                                return;
                            }
                        }
                    }

                    node.scrollBy({
                        top: 0,
                        left: event.deltaY * 0.5,
                    });
                    if (horizontalScroll) {
                        event.preventDefault();
                    }
                }
            };

            if (node) {
                node.addEventListener("scroll", onScroll);
                node.addEventListener("wheel", onWheel, { passive: !horizontalScroll });
            }

            return () => {
                if (node) {
                    node.removeEventListener("scroll", onScroll);
                    node.removeEventListener("wheel", onWheel, { passive: !horizontalScroll } as EventListenerOptions);
                }
            };
        }
    }, [targetId, horizontalScroll, updateScrollable, unmounted]);

    useEffect(() => {
        if (!unmounted.current) {
            const node = document.getElementById(targetId);

            if (activeItemIndex && node && node.children[activeItemIndex]) {
                node.children[activeItemIndex].scrollIntoView({
                    behavior: "smooth",
                });

                scrollEnd(node, () => {
                    updateScrollable();
                });
            } else if (node && !isNil(activeItemIndex) && node.children[activeItemIndex]) {
                node.children[activeItemIndex].scrollIntoView({
                    behavior: "smooth",
                });
            } else {
                updateScrollable();
            }
        }
    }, [activeItemIndex, targetId, updateScrollable, updateInterval, unmounted]);

    const scroll = useCallback(
        (direction: "left" | "right") => {
            if (!unmounted.current) {
                const node = document.getElementById(targetId);

                if (node) {
                    if (direction === "left") {
                        node.scrollLeft = node.scrollLeft - node.clientWidth;
                    }

                    if (direction === "right") {
                        node.scrollLeft = node.scrollLeft + node.clientWidth;
                    }

                    scrollEnd(node, () => {
                        updateScrollable();
                    });
                }
            }
        },
        [targetId, updateScrollable, unmounted]
    );

    // Listen for resize
    useLayoutEffect(() => {
        const onResize = debounce(() => {
            updateScrollable();
        }, 200);

        window.addEventListener("resize", onResize);

        return () => window.removeEventListener("resize", onResize);
    }, [updateScrollable]);

    const controls = (
        <>
            {scrollArrows.left && (
                <div className="scroll-control left">
                    {/* @ts-ignore */}
                    <IconWrap
                        iconWrapClickable
                        iconWrapTransparent
                        iconWrapBig={!iconSmall}
                        iconWrap="shevron-left-keyboard-arrow-left"
                        title="Scroll Left"
                        onClick={() => scroll("left")}
                    ></IconWrap>
                </div>
            )}
            {scrollArrows.right && (
                <div className="scroll-control right">
                    {/* @ts-ignore */}
                    <IconWrap
                        iconWrapClickable
                        iconWrapTransparent
                        iconWrapBig={!iconSmall}
                        iconWrapRight
                        iconWrap="shevron-right-keyboard-arrow-right"
                        title="Scroll Right"
                        onClick={() => scroll("right")}
                    ></IconWrap>
                </div>
            )}
        </>
    );

    return <div className="scroll-controls">{noWrap ? controls : <div className="main-grid-wrap">{controls}</div>}</div>;
};

export default ScrollControls;

interface ScrollControlsProps {
    activeItemIndex?: number;
    targetId: string;
    noWrap?: boolean;
    iconSmall?: boolean;
    updateInterval?: number;
    horizontalScroll?: boolean;
    onChange?: (v: { left: boolean; right: boolean }) => {};
}
