import React from "react";

import "./style.scss";

const ActivityIcon = () => {
    return (
        <div className="activity-icon la-ball-grid-pulse la-sm">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default ActivityIcon;
