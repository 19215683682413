export const STEP_TYPES_ATTRIBUTES = {
    FORM_PAGE_NUMBER: "formpgnum",
    APP_FIELD_ID: "appFieldID",
    FORM_SECTION_NUMBER: "formsecnum",
    CORRESPONDENCE_ID: "corid",
    EMAIL_SUBJECT: "emailSubject",
    STORED_PROCEDURE: "proc",
    APPLICATION_FLAG_CODE: "appCd",
    QUALITY_CONTROL_THRESHOLD_PERCENTAGE: "qcpercent",
    QUALITY_CONTROL_TYPE: "qctype",
    FILE_FOLDER_NAME: "foldername",
};

export const STEP_TYPES_KEYS = {
    APPROVAL: "approval",
    ACTIVE_FORM_PAGE: "activeFormPage",
    APPLICATION_FORM_ENTRY_TASK: "applicationFormEntryTask",
    APPLICATION_FORM_FIELD_ENTRY_TASK: "applicationFormFieldEntryTask",
    APPLICATION_FORM_SECTION_ENTRY_TASK: "applicationFormSectionEntryTask",
    AUTOMATED_STATUS_CHANGE: "automatedStatusChange",
    CLEAR_FLAG: "clearFlag",
    CREATE_NEW_CONTACT: "createNewContact",
    CREATE_NEW_MEASURE: "createNewMeasure",
    DOCUMENT_UPLOAD: "documentUpload",
    FLAG_RECORD: "flagRecord",
    GENERAL_PROCEDURE: "generalProcedure",
    PERFORM_TASK: "performTask",
    QC_TASK: "qcTask",
    SEND_EMAIL: "sendEmail",
    SEND_LETTER: "sendLetter",
    TEST_FOR_QC_WITH_STATUS_CHANGE: "testForQcWithStatusChange",
};

export const STEP_TYPES = {
    ACTF: {
        key: STEP_TYPES_KEYS.ACTIVE_FORM_PAGE,
        title: "Active Form Page",
        withFields: true,
        attributes: [STEP_TYPES_ATTRIBUTES.FORM_PAGE_NUMBER],
    },
    AFET: {
        key: STEP_TYPES_KEYS.APPLICATION_FORM_ENTRY_TASK,
        title: "Application Form Entry Task",
        withModal: true,
        modalTitle: "Manage Application Form Entry Task",
        modalSize: "modal-lg",
        attributes: [STEP_TYPES_ATTRIBUTES.FORM_PAGE_NUMBER],
    },
    AFFET: {
        key: STEP_TYPES_KEYS.APPLICATION_FORM_FIELD_ENTRY_TASK,
        title: "Application Form Field Entry Task",
        withModal: true,
        modalTitle: "Manage Application Form Field Entry Task",
        modalSize: "modal-lg",
        attributes: [STEP_TYPES_ATTRIBUTES.FORM_PAGE_NUMBER, STEP_TYPES_ATTRIBUTES.APP_FIELD_ID],
    },
    AFSET: {
        key: STEP_TYPES_KEYS.APPLICATION_FORM_SECTION_ENTRY_TASK,
        title: "Application Form Section Entry Task",
        withModal: true,
        modalTitle: "Manage Application Form Section Entry Task",
        modalSize: "modal-lg",
        attributes: [STEP_TYPES_ATTRIBUTES.FORM_PAGE_NUMBER, STEP_TYPES_ATTRIBUTES.FORM_SECTION_NUMBER],
    },
    APPR: {
        key: STEP_TYPES_KEYS.APPROVAL,
        title: "Approval",
        withModal: true,
        modalTitle: "Manage Approval",
        modalSize: "modal-md",
        attributes: [],
    },
    STCH: {
        key: STEP_TYPES_KEYS.AUTOMATED_STATUS_CHANGE,
        title: "Automated Status Change",
        withFields: true,
        attributes: [],
    },
    ACF: {
        key: STEP_TYPES_KEYS.CLEAR_FLAG,
        title: "Clear Flag",
        withFields: true,
        attributes: [STEP_TYPES_ATTRIBUTES.APPLICATION_FLAG_CODE],
    },
    CNC: {
        key: STEP_TYPES_KEYS.CREATE_NEW_CONTACT,
        title: "Create New Contact",
        attributes: [],
    },
    CNM: {
        key: STEP_TYPES_KEYS.CREATE_NEW_MEASURE,
        title: "Create New Measure",
        attributes: [],
    },
    ADUL: {
        key: STEP_TYPES_KEYS.DOCUMENT_UPLOAD,
        title: "Document Upload",
        withFields: true,
        attributes: [STEP_TYPES_ATTRIBUTES.FILE_FOLDER_NAME],
    },
    AFR: {
        key: STEP_TYPES_KEYS.FLAG_RECORD,
        title: "Flag Record",
        withFields: true,
        attributes: [STEP_TYPES_ATTRIBUTES.APPLICATION_FLAG_CODE],
    },
    GSP: {
        key: STEP_TYPES_KEYS.GENERAL_PROCEDURE,
        title: "General Procedure",
        withModal: true,
        modalTitle: "Manage General Procedure",
        modalSize: "modal-lg",
        attributes: [STEP_TYPES_ATTRIBUTES.STORED_PROCEDURE, STEP_TYPES_ATTRIBUTES.EMAIL_SUBJECT],
    },
    APT: {
        key: STEP_TYPES_KEYS.PERFORM_TASK,
        title: "Perform Task",
        withModal: true,
        modalTitle: "Manage Perform Task",
        modalSize: "modal-md",
        attributes: [],
    },
    AQC: {
        key: STEP_TYPES_KEYS.QC_TASK,
        title: "QC Task",
        withModal: true,
        modalTitle: "Manage QC Task",
        modalSize: "modal-lg",
        attributes: [STEP_TYPES_ATTRIBUTES.QUALITY_CONTROL_TYPE, STEP_TYPES_ATTRIBUTES.QUALITY_CONTROL_THRESHOLD_PERCENTAGE],
    },
    ASEM: {
        key: STEP_TYPES_KEYS.SEND_EMAIL,
        title: "Send Email",
        withModal: true,
        modalTitle: "Manage Send Email",
        modalSize: "modal-lg",
        attributes: [STEP_TYPES_ATTRIBUTES.CORRESPONDENCE_ID, STEP_TYPES_ATTRIBUTES.EMAIL_SUBJECT],
    },
    ASL: {
        key: STEP_TYPES_KEYS.SEND_LETTER,
        title: "Send Letter",
        withModal: true,
        modalTitle: "Manage Send Letter",
        modalSize: "modal-lg",
        attributes: [STEP_TYPES_ATTRIBUTES.CORRESPONDENCE_ID],
    },
    QCT: {
        key: STEP_TYPES_KEYS.TEST_FOR_QC_WITH_STATUS_CHANGE,
        title: "Test For QC With Status Change",
        withModal: true,
        modalTitle: "Manage Test For QC With Status Change",
        modalSize: "modal-lg",
        attributes: [STEP_TYPES_ATTRIBUTES.QUALITY_CONTROL_TYPE, STEP_TYPES_ATTRIBUTES.QUALITY_CONTROL_THRESHOLD_PERCENTAGE],
    },
};

export const ALLOW_CUSTOMER_APPLICATION_EDIT_FLAG = "202";
