import React from "react";
import getPath from "lodash/get";

import withDataGrid from "../../../../DataGrid/withDataGrid";

import IconWrap from "../../../../Icons";
import { ViewOnlyDataGrid } from "../../../../DataGrid";

import { getAuditTypeIcon, getAuditUserIcon } from "../AuditPanel/AuditGrid";
import { auditGridColumnKeys } from "../../../../../views/configureGrids";

import { formatJsonDate, formatJsonTime } from "../../../../../utils/date";

import "./AuditGrid.scss";

const showedColumnsKeys = [auditGridColumnKeys.user, auditGridColumnKeys.action];

const AuditGrid = ({ gridId, dataGridConfig, renderFooter }) => {
    if (dataGridConfig) {
        if (getPath(dataGridConfig, "rows.length", 0) === 0 && !dataGridConfig.isConstructing && !dataGridConfig.isReading) {
            return <div className="not-found-msg">There are no audits for this project</div>;
        }
    }

    return (
        <ViewOnlyDataGrid
            limit={6}
            name={gridId}
            config={dataGridConfig}
            showedColumnsKeys={showedColumnsKeys}
            className="audit-side-panel-grid"
            columnWidth={{
                [auditGridColumnKeys.user]: "50%",
                [auditGridColumnKeys.action]: "50%",
            }}
            columnHeaderContent={{
                [auditGridColumnKeys.user]: (column) => <div>USER / DATE</div>,
            }}
            columnCellContent={{
                [auditGridColumnKeys.user]: (column, row, onExpandRow) => {
                    const date = formatJsonDate(row[auditGridColumnKeys.date]);
                    const time = formatJsonTime(row[auditGridColumnKeys.date]);

                    return (
                        <>
                            <div className="flex-row flex-one align-center">
                                <IconWrap icon={getAuditUserIcon(row)} title={row[auditGridColumnKeys.user]} />
                                <div title={time}>{date}</div>
                            </div>
                            <IconWrap className="audittype" icon={getAuditTypeIcon(row)} />
                        </>
                    );
                },
                [auditGridColumnKeys.action]: (column, row, onExpandRow) => {
                    const oldValue = row[auditGridColumnKeys.oldValue] || "";
                    const newValue = row[auditGridColumnKeys.oldValue] || "";
                    const values = `Old value: ${oldValue}\nNew value: ${newValue}`;

                    return (
                        <div className="user-action-column" title={values}>
                            {row[auditGridColumnKeys.action]}
                        </div>
                    );
                },
            }}
            renderFooter={renderFooter}
        />
    );
};

export default withDataGrid("audit", AuditGrid);
