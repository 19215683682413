import React, { useCallback, memo, useRef, useEffect, useState, useMemo, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import { DragDropContext } from "react-beautiful-dnd";
import FieldGroupDropdownIcon from "../FieldGroupDropdownIcon";
import { setColumns, changeSettingsOpen } from "../../../store/dataGrid/actions";
import { reorder } from "../../utils/array";
import IconWrap from "../Icons";
import IconWithLabel from "../Icons/IconWithLabel";
import { clearStoredColumnWidths, getStoredColumnWidths } from "../List/GridList/utils";

import "./GridSettingsDropdown.scss";
import { WindowContext } from "../Windows/Window";

const GridSettingsDropdown = memo(({ dataGridId, columns }) => {
    const dispatch = useDispatch();
    const windowContext = useContext(WindowContext) ?? {};
    const isSettingsOpen = useSelector((state) => state.dataGrid[dataGridId]?.isSettingsOpen[windowContext.containerName]);
    const treeColumnKey = useSelector((state) => state.dataGrid[dataGridId]?.tree?.treeColumn);

    const elementRef = useRef();

    const [popupBoundary, setPopupBoundary] = useState();
    const [isListOnTop, setIsListOnTop] = useState(false);

    const popupModifiers = useMemo(
        () => [
            {
                name: "offset",
                options: {
                    offset: ({ placement, reference, popper }) => {
                        const flipped = placement === "left-end";

                        const popupOffestX = flipped ? 5 : 5;
                        const popupOffsetY = flipped ? -7 : 12;

                        return [-popupOffsetY, -reference.width - popupOffestX];
                    },
                },
            },
            {
                name: "onFlip",
                phase: "afterWrite",
                enabled: true,
                fn({ state }) {
                    setIsListOnTop(state.placement === "left-end");
                },
            },
        ],
        []
    );

    useEffect(() => {
        if (elementRef.current) {
            setPopupBoundary((prevValue) => {
                if (!prevValue) {
                    return elementRef.current?.closest(".popup-boundary");
                }

                return prevValue;
            });
        }
    }, []);

    const onSelect = useCallback(
        (column) => {
            const updatedColumns = columns.slice().map((c) => ({
                ...c,
                active: c.key === column.draggableId ? !column.checked : c.active,
            }));

            dispatch(setColumns({ dataGridId, columns: updatedColumns }));
        },
        [dataGridId, columns, dispatch]
    );

    const moveItemUp = useCallback(
        (index) => {
            if (index !== null && index > 0) {
                const updatedColumns = columns.slice();

                reorder(updatedColumns, index, index - 1);
                dispatch(setColumns({ dataGridId, columns: updatedColumns }));
            }
        },
        [dataGridId, columns, dispatch]
    );

    const moveItemDown = useCallback(
        (index) => {
            const lastIndex = columns.length - 1;

            if (index !== null && index < lastIndex) {
                const updatedColumns = columns.slice();

                reorder(updatedColumns, index, index + 1);
                dispatch(setColumns({ dataGridId, columns: updatedColumns }));
            }
        },
        [dataGridId, columns, dispatch]
    );

    const onDragEnd = useCallback(
        (result) => {
            if (!result.destination) {
                return;
            }

            if (result.destination.index === result.source.index) {
                return;
            }

            const updatedColumns = columns.slice();
            const sourceIndex = columns.findIndex((c) => c.order === result.source.index);

            let treeColumnIndex = columns.findIndex((c) => c.key === treeColumnKey) ?? -1;

            if (treeColumnIndex > 0) {
                reorder(updatedColumns, treeColumnIndex, 0);
                treeColumnIndex = 0;
            }

            if (result.destination.index > treeColumnIndex) {
                reorder(updatedColumns, sourceIndex, result.destination.index);
                dispatch(setColumns({ dataGridId, columns: updatedColumns }));
            }
        },
        [dataGridId, columns, dispatch, treeColumnKey]
    );

    const onKeyDown = useCallback(
        (event) => {
            let index = null;

            if (event.currentTarget.hasChildNodes()) {
                const children = event.currentTarget.childNodes;

                for (var i = 0; i < children.length; i++) {
                    if (i > 0 && children[i] === event.target) {
                        index = i - 1;
                        break;
                    }
                }
            }

            switch (event.key.toLowerCase()) {
                case "arrowup":
                case "arrowleft":
                    moveItemUp(index);
                    break;
                case "arrowdown":
                case "arrowright":
                    moveItemDown(index);
                    break;
                default:
                    break;
            }
        },
        [moveItemUp, moveItemDown]
    );

    const onToggle = useCallback(() => {
        dispatch(changeSettingsOpen({ dataGridId, isOpen: !isSettingsOpen, containerName: windowContext.containerName }));
    }, [dispatch, dataGridId, isSettingsOpen, windowContext.containerName]);

    const onClose = useCallback(() => {
        dispatch(changeSettingsOpen({ dataGridId, isOpen: false }));
    }, [dataGridId, dispatch]);
    const isDisabled = columns.filter((c) => c?.active).length <= 1;

    const items = columns.map((c) => ({
        draggableId: c.key,
        draggableIndex: c.order,
        icon: c.active ? "fiber-smart-record-filled" : "fiber-smart-record-empty",
        title: isDisabled && c.active ? "There should be atleast one selected column" : c.active ? "hide column" : "show column",
        checked: c.active,
        label: c.name,
        dragDisabled: c.key === treeColumnKey ? true : false,
        disabled: isDisabled && c.active,
    }));

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <FieldGroupDropdownIcon
                elementRef={elementRef}
                title={isSettingsOpen ? "Close" : "Configure Columns"}
                onKeyDown={onKeyDown}
                className={cn("grid-settings-dropdown", {
                    "dropdown-list-up": isListOnTop,
                })}
                draggable
                dropdownRight
                dropdownOptions
                iconWrapDropdown
                withTitle={
                    <>
                        <span>Configure Columns</span>
                        <IconWrap icon="settings-filled" onClick={onClose} />
                        <GridActions dataGridId={dataGridId} />
                    </>
                }
                mobileHeader="Configure Columns Visibility/Sorting"
                visible={isSettingsOpen}
                iconWrapActive={isSettingsOpen}
                iconWrap={isSettingsOpen ? "settings-filled" : "settings-empty"}
                onClick={onToggle}
                onSelect={onSelect}
                items={items}
                withPopper={isSettingsOpen}
                popupBoundary={popupBoundary}
                popupModifiers={popupModifiers}
                zIndexOffset={5} // To set popup in front of inline grid details panel
            />
        </DragDropContext>
    );
});

const GridActions = (props) => {
    const [showActions, setShowActions] = useState(false);
    // Using data grid Backend key. Not the instance key.
    const dataGridId = useSelector((state) => state.dataGrid[props.dataGridId])?.dataGridId;

    useEffect(() => {
        const storedColumnWidths = getStoredColumnWidths(dataGridId);
        if (storedColumnWidths) {
            setShowActions(true);
        }
    }, [dataGridId]);

    const onReset = () => {
        clearStoredColumnWidths(dataGridId);
        setShowActions(false);
    };

    if (!showActions) {
        return null;
    }

    return (
        <div className="grid-settings-actions">
            <IconWithLabel icon="backup-restore-empty" onClick={onReset}>
                Reset Column Widths
            </IconWithLabel>
        </div>
    );
};

export default GridSettingsDropdown;
