import React from "react";
import { useDispatch } from "react-redux";
import BaseInput from "../BaseInput";
import VendorSearchForm from "./VendorSearchForm";
import { modalOpen, modalClose } from "../../../../../../store/modal/actions";
import Button from "../../../../Button";

const ContractVendorWidget = (props) => {
    const { onChange, utilityNumber, contractNumber, readonly } = props;

    const dispatch = useDispatch();

    const onSelect = (vendorContactId) => {
        onChange(vendorContactId);
        dispatch(modalClose());
    };

    const onSearchVendor = () => {
        dispatch(
            modalOpen({
                modalType: "MODAL",
                modalProps: {
                    title: "Vendor Search",
                    overlayClassName: "modal-styled",
                    className: "vendor-search-modal modal-with-grid modal-lg",
                    modalIcon: "search",
                    children: (
                        <VendorSearchForm
                            utilityNumber={utilityNumber}
                            contractNumber={contractNumber}
                            onSelect={onSelect}
                            onCancel={() => dispatch(modalClose())}
                        />
                    ),
                    withScroll: true,
                },
            })
        );
    };

    return (
        <div className="field-with-button-right">
            <BaseInput {...props} />
            <Button onClick={() => onSearchVendor()} disabled={readonly}>
                Search
            </Button>
        </div>
    );
};

export default ContractVendorWidget;
