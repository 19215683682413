import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../ui/Button";
import { saveUser } from "../../utils/user";
import TextLabel from "components/ui/Label/TextLabel";
import { isEmpty, pickBy } from "lodash";
import TextField from "components/ui/TextField";
import ButtonGroup from "components/ui/Button/ButtonGroup";
import Checkbox from "components/ui/Input/Checkbox";
import { optimisticUpdateItem } from "store/resources/actions";
import { convertToArray } from "components/utils/string";

export const UserRights = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const userRights = useSelector((state) => pickBy(state.resources.userRights.itemsById, (value, key) => !key.endsWith("is-loading")));

    const clientLevelRights = (user?.rights ?? []).join(", ");
    const clientLevelRightsRef = useRef();

    const utilityLevelRightsRef = useRef();
    const [overrideUtilityLevelRights, setOverrideUtilityLevelRights] = useState(false);

    const programLevelRightsRef = useRef();
    const [overrideProgramLevelRights, setOverrideProgramLevelRights] = useState(false);

    // Init overridden utility and program rights
    useEffect(() => {
        const overrideUtilityLevelRights = sessionStorage.getItem("overrideUtilityLevelRights");
        if (overrideUtilityLevelRights) {
            setOverrideUtilityLevelRights(true);
            setTimeout(() => {
                if (utilityLevelRightsRef.current) {
                    utilityLevelRightsRef.current.value = overrideUtilityLevelRights;
                }
            });
        }

        const overrideProgramLevelRights = sessionStorage.getItem("overrideProgramLevelRights");
        if (overrideProgramLevelRights) {
            setOverrideProgramLevelRights(true);
            setTimeout(() => {
                if (programLevelRightsRef.current) {
                    programLevelRightsRef.current.value = overrideProgramLevelRights;
                }
            });
        }
    }, []);

    useEffect(() => {
        if (clientLevelRightsRef.current) {
            clientLevelRightsRef.current.value = clientLevelRights;
        }
    }, [clientLevelRights]);

    const onUpdateClientLevelRights = () => {
        const inputValue = clientLevelRightsRef.current?.value ?? "";
        const rights = convertToArray(inputValue);

        const updatedUser = {
            ...user,
            rights,
        };

        saveUser(updatedUser);

        dispatch({
            type: "USER_SAVE",
            user: updatedUser,
        });

        // Reload the browser tab.
        // Some already opened tabs does not update user rights on the fly.
        window.location.reload();
    };

    const onUpdateUtilityLevelRights = () => {
        const inputValue = utilityLevelRightsRef.current?.value ?? "";
        const updatedRights = convertToArray(inputValue);
        const resourceValue = updatedRights.length > 0 ? { rights: updatedRights } : undefined;

        if (isEmpty(inputValue)) {
            sessionStorage.removeItem("overrideUtilityLevelRights");
        } else {
            sessionStorage.setItem("overrideUtilityLevelRights", inputValue);
        }

        const resources = pickBy(userRights, (_, key) => key.startsWith("utility-"));

        Object.keys(resources).forEach((key) => {
            dispatch(
                optimisticUpdateItem({
                    resourceName: "userRights",
                    resourceId: key,
                    value: resourceValue,
                })
            );
        });
    };

    const onUpdateProgramLevelRights = () => {
        const inputValue = programLevelRightsRef.current?.value ?? "";
        const updatedRights = convertToArray(inputValue);
        const resourceValue = updatedRights.length > 0 ? { rights: updatedRights } : undefined;

        if (isEmpty(inputValue)) {
            sessionStorage.removeItem("overrideProgramLevelRights");
        } else {
            sessionStorage.setItem("overrideProgramLevelRights", inputValue);
        }

        const resources = pickBy(userRights, (_, key) => key.startsWith("program-"));

        Object.keys(resources).forEach((key) => {
            dispatch(
                optimisticUpdateItem({
                    resourceName: "userRights",
                    resourceId: key,
                    value: resourceValue,
                })
            );
        });
    };

    const onToggleUtilityLevelRights = () => {
        // Reset rights if switching off
        if (overrideUtilityLevelRights) {
            utilityLevelRightsRef.current.value = "";
            onUpdateUtilityLevelRights();
        }

        setOverrideUtilityLevelRights((prev) => !prev);
    };

    const onToggleProgramLevelRights = () => {
        // Reset rights if switching off
        if (overrideProgramLevelRights) {
            programLevelRightsRef.current.value = "";
            onUpdateProgramLevelRights();
        }

        setOverrideProgramLevelRights((prev) => !prev);
    };

    if (!user) {
        return null;
    }

    return (
        <>
            <div className="tile user-rights-section flex-column no-scroll no-shrink flex-padding">
                <TextLabel>Client level rights</TextLabel>
                <div className="override-rights-controls flex-column fill-width">
                    <TextField className="flex-one" rows={5} inputRef={clientLevelRightsRef} />
                    <ButtonGroup transparent>
                        <Button onClick={onUpdateClientLevelRights}>Update</Button>
                    </ButtonGroup>
                </div>
            </div>

            <div className="tile user-rights-section flex-column no-scroll no-shrink flex-padding">
                <Checkbox
                    label="Override utility level rights"
                    checked={overrideUtilityLevelRights}
                    onChange={onToggleUtilityLevelRights}
                />
                {overrideUtilityLevelRights && (
                    <div className="override-rights-controls flex-column fill-width">
                        <TextField className="flex-one" rows={5} inputRef={utilityLevelRightsRef} onChange={onUpdateUtilityLevelRights} />
                    </div>
                )}
            </div>

            <div className="tile user-rights-section flex-column no-scroll no-shrink flex-padding">
                <Checkbox
                    label="Override program level rights"
                    checked={overrideProgramLevelRights}
                    onChange={onToggleProgramLevelRights}
                />
                {overrideProgramLevelRights && (
                    <div className="override-rights-controls flex-column fill-width">
                        <TextField className="flex-one" rows={5} inputRef={programLevelRightsRef} onChange={onUpdateProgramLevelRights} />
                    </div>
                )}
            </div>
        </>
    );
};
