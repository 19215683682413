import React, { memo, useContext, useMemo } from "react";
import cn from "classnames";

import GridListActions from "./GridListActions";
import GridListFilterCell from "./GridListFilterCell";
import GridListSelectAllCell from "./GridListSelectAllCell";
import { GridListContext } from ".";
import ScrollableColumns from "./ScrollableColumns";

import "./GridListFilterRow.scss";

const GridListFilterRow = memo(({ columns, show, hasColumns, noData }) => {
    const { showActions } = useContext(GridListContext);

    const columnList = useMemo(() => columns.map((column) => <GridListFilterCell key={column.key} column={column} />), [columns]);

    if (!show) {
        return null;
    }

    return (
        <div
            className={cn("grid-list-row filter", {
                "no-actions": !showActions,
                "no-columns": !hasColumns,
            })}
        >
            {!noData && hasColumns && <GridListSelectAllCell />}
            <ScrollableColumns>{columnList}</ScrollableColumns>
            <GridListActions />
        </div>
    );
});

export default GridListFilterRow;
