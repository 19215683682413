export enum HeadingTypes {
    Individual = "individual",
    Common = "common",
}

export enum HeadingCustomPropertyName {
    HeadingColorType = "headingColorType",
    HeadingColorCommon = "headingColorCommon",
    HeadingColorIndividual = "headingColorIndividual",
}

export enum HeadingNames {
    H1 = "H1",
    H2 = "H2",
    H3 = "H3",
    H4 = "H4",
    H5 = "H5",
    H6 = "H6",
}
