import { getResource } from "store/resources/actions";
import { store } from "../../../../../../store/configureStore";

export const getGridId = ({ applicationNumber, type }) => {
    return `${applicationNumber}-${type}`;
};

export const getSidebarFilesGridId = ({ applicationNumber }) => {
    return `${applicationNumber}-files-sidebar`;
};

export const refreshSidebarFilesGrid = ({ applicationNumber }) => {
    store.dispatch(
        getResource({
            resourceName: "applicationFiles",
            key: applicationNumber,
            path: {
                appId: applicationNumber,
            },
        })
    );
};
