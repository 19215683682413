import React, { useState, useEffect, useCallback, useRef } from "react";
import { range } from "lodash";
import { getResource } from "../../../../../../store/resources/actions";
import { useResource, useFastTags } from "../../../../../../store/resources/useResource";
import { programsCorrespondenceWorkflowGridColumnKeys } from "../../../../../views/configureGrids";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import { getData } from "../../../../../../store/dataGrid/actions";
import { documentType } from "../../../../../utils/constants";
import { listToAnyOf, submitResource, submitByRef } from "../../../../../utils/form";
import WaitIcon from "../../../../WaitIcon";
import SideNavBody from "../../../../../ui/SideNav/SideNavBody";
import SideNavFooter from "../../../../../ui/SideNav/SideNavFooter";
import SideNavHeader from "../../../../../ui/SideNav/SideNavHeader";
import SideNavContent from "../../../../../ui/SideNav/SideNavContent";
import Button from "../../../../../ui/Button";
import CorrespondenceWorkflowPanel from "./CorrespondenceWorkflowPanel";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";

const CorrespondenceWorkflowItemEditForm = (props) => {
    const { onClose, dispatch, dataItem, programNumber, gridId, documentName } = props;

    const documentNumber = dataItem[programsCorrespondenceWorkflowGridColumnKeys.documentNumber];
    const wfNumber = dataItem[programsCorrespondenceWorkflowGridColumnKeys.wfNumber];
    const stepNumber = dataItem[programsCorrespondenceWorkflowGridColumnKeys.stepNumber];
    const isEmail = dataItem[programsCorrespondenceWorkflowGridColumnKeys.stepType] === "Send Email";

    const [initialValues, setInitialValues] = useState();
    const [isSubmitting, setSubmitting] = useState(false);
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.medium,
    });

    const formRef = useRef();

    const [correspondenceList] = useResource({
        resourceName: "programAvailableDocuments",
        key: programNumber,
        path: {
            programNumber,
        },
        query: {
            itemType: isEmail ? documentType.email : documentType.letter,
        },
        transform: (data) => {
            return listToAnyOf({
                list: data || [],
                map: (item) => ({
                    title: item.name,
                    enum: [item.documentNumber],
                }),
            });
        },
    });

    const [stepData, isLoading] = useResource({
        resourceName: "workflowSteps",
        resourceId: stepNumber,
        path: {
            wfNumber,
        },
        forced: true,
        transform: (data) => {
            return data && data.task && data.task[0];
        },
    });

    const [fastTags, isLoadingFastTags] = useFastTags({ programNumber });

    useEffect(() => {
        if (stepData) {
            let formDetails = {
                wF_Step_TypeID: Number(stepData.wf_step_typeid),
                step: stepData.step,
                term: Number(stepData.term),
                constraintRule: stepData.constraintrule,
                autoCompleteRule: stepData.autocompleterule,
                content: stepData.content,
                sort_Order: Number(stepData.sort_order),
                customStep: stepData.customstep || "N",
                workflowstatus: Number(stepData.workflowstatus),
            };

            if (stepData.attributes) {
                const emailSubjectAttr = stepData.attributes.find((attr) => attr.attributename.toLowerCase() === "email subject");
                if (emailSubjectAttr) {
                    formDetails = {
                        ...formDetails,
                        emailSubject: emailSubjectAttr.attributevalue,
                    };
                }

                const corIdAttr = stepData.attributes.find((attr) => attr.attributename.toLowerCase() === "correspondence id");
                if (corIdAttr) {
                    formDetails = {
                        ...formDetails,
                        corID: corIdAttr.attributevalue,
                    };
                }
            }

            setInitialValues({ ...formDetails });
        }
    }, [stepData, documentNumber]);

    const schema = {
        type: "object",
        required: ["wF_Step_TypeID", "step", "term", "corID", "content"],
        properties: {
            wF_Step_TypeID: {
                type: "integer",
                title: "Step Type",
                anyOf: [
                    {
                        title: "Send Email",
                        enum: [1],
                    },
                    {
                        title: "Send Letter",
                        enum: [3],
                    },
                ],
            },
            step: {
                type: "string",
                title: "Item Step",
            },
            term: {
                type: "integer",
                title: "Term",
                enum: range(-1, 366),
            },
            constraintRule: {
                type: "string",
                title: "Constraint Rule",
            },
            autoCompleteRule: {
                type: "string",
                title: "Auto Status Change Rule",
            },
        },
        dependencies: {
            wF_Step_TypeID: {
                oneOf: [
                    {
                        properties: {
                            wF_Step_TypeID: {
                                enum: [1],
                            },
                            emailSubject: {
                                type: "string",
                                title: "Email Subject Line",
                            },
                            corID: {
                                type: "string",
                                title: "Existing Correspondence",
                                anyOf: correspondenceList,
                            },
                            content: {
                                type: "string",
                                title: "Content",
                            },
                        },
                    },
                    {
                        properties: {
                            wF_Step_TypeID: {
                                enum: [3],
                            },
                            corID: {
                                type: "string",
                                title: "Existing Correspondence",
                                anyOf: correspondenceList,
                            },
                            content: {
                                type: "string",
                                title: "Content",
                            },
                        },
                    },
                ],
            },
        },
    };

    const uiSchema = {
        wF_Step_TypeID: {
            classNames: "program-correspondence-workflow-dropdown",
            "ui:options": {
                placeholder: "-- SELECT --",
            },
        },
        corID: {
            classNames: "program-correspondence-workflow-dropdown",
            "ui:options": {
                placeholder: "-- SELECT --",
            },
        },
        term: {
            classNames: "program-correspondence-workflow-dropdown",
            "ui:options": {
                placeholder: "-- SELECT --",
            },
        },
        content: {
            "ui:widget": "HtmlEditorWidget",
            "ui:options": {
                fastTags,
                isLoadingFastTags,
            },
        },
    };

    const handleSubmit = useCallback(() => {
        submitByRef(formRef);
    }, []);

    const onBack = () => {
        handleOpenSidePanel(
            <CorrespondenceWorkflowPanel
                programNumber={programNumber}
                documentNumber={documentNumber}
                documentName={documentName}
                onClose={handleCloseSidePanel}
            />,
            { size: sideNavSize.staticLarge }
        );
    };

    const onSubmit = (formData) => {
        const resourceParams = {
            resourceName: "workflowSteps",
            path: {
                wfNumber,
            },
        };

        const body = {
            ...formData,
        };

        submitResource({
            resourceParams,
            resourceId: stepNumber,
            body,
            onRefresh: () => {
                dispatch(
                    getData({
                        dataGridId: gridId,
                    })
                );
            },
            onSuccess: onClose,
            setSubmitting,
        });
    };

    const getContentFromCorrespondence = useCallback(
        (formData) =>
            getResource({
                resourceName: "programDocuments",
                key: formData.corID,
                resourceId: formData.corID,
                path: {
                    programNumber,
                },
                onSuccess: (action) => {
                    setInitialValues({
                        ...formData,
                        content: action.data.content,
                    });
                },
            }),
        [programNumber]
    );

    const onChange = (e) => {
        if (e && e.formData && initialValues && e.formData.corID !== initialValues.corID) {
            dispatch(getContentFromCorrespondence(e.formData));
        }
    };

    const getSubmitText = isSubmitting ? "Saving..." : "Save";

    if (isLoading) {
        return <WaitIcon />;
    }

    return (
        <SideNavContent>
            <SideNavHeader
                title={"Edit Workflow Item "}
                nameInBold={documentName}
                leadBlockIcon="edit-empty"
                smallHeader
                onClose={onClose}
            />
            <SideNavBody className="flex-one-in-column">
                <JsonSchemaForm
                    formRef={formRef}
                    schema={schema}
                    uiSchema={uiSchema}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    onChange={onChange}
                    onCancel={onClose}
                    submitText={getSubmitText}
                    disabled={isSubmitting}
                    noReset
                    noActions
                />
            </SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button icon="shevron-small-left" onClick={onBack}>
                    Back
                </Button>
                <div className="flex-row flex-one-in-row justify-end">
                    <Button primary disabled={isSubmitting} onClick={handleSubmit}>
                        {getSubmitText}
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
            </SideNavFooter>
        </SideNavContent>
    );
};

export default CorrespondenceWorkflowItemEditForm;
