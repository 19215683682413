import React, { memo, useCallback, useContext } from "react";
import { useDispatch } from "react-redux";

import { mapGridRowToObject, SortType } from "../../../../../utils/datagrid";
import { availableGrids, getFriendlyNamesGridGridId } from "../../../../../views/configureGrids";
import { ElementTypes } from "../../../../../utils/form";
import { getPage } from "../../../../../../store/pages/actions";
import { destroy, initPage } from "../../../../../../store/formBuilder/actions";
import { openApplicationFormPages, isViewActive } from "../../../../../utils/window";
import { sideNavMode, getViewSideNavKey, sideNavPosition } from "../../../../SideNav/SideNavRoot";
import { sideNavOpen, sideNavClose } from "../../../../../../store/sideNav/actions";
import { getFormBuilder, getFormPage } from "../../../../../../store/formBuilder/selectors";
import { getActiveFormPage } from "../../../../../../store/pages/selectors";
import { WindowContext } from "../../../../Windows/Window";
import { useProgramFormFriendlyNames } from "../../../../../../store/resources/useResource";

import WaitIcon from "../../../../WaitIcon";
import DataGrid from "../../../../DataGrid";
import FriendlyNamesSidePanel from "./FriendlyNamesSidePanel";
import ActionLabel from "../../../../Label/ActionLabel";
import { isChildProgram } from "components/views/ProgramView/utils";
import Label from "components/ui/Label";
import { useDataGrid } from "components/utils/useDataGrid";

const friendlyNamesGridColumnKeys = {
    id: "c_1cbb11d2-c530-4274-af07-a3f43b58d09c",
    fieldId: "c_919b3ba2-ae03-489c-b5fe-55859eae76ef",
    sectionId: "c_4ac79e0a-cc63-429b-9991-be48299a5774",
    pageId: "c_2b6ba309-c044-4ef0-8cd2-8dee02158f95",
    fieldNumber: "c_aefcb419-eeb5-46fa-8fc9-9b742daa439a",
    pageNumber: "c_0db7c017-c74b-4a5b-9713-db5f16d063e8",
    pageName: "c_030e5222-c4e7-4ed5-9423-9747dfaed4f9",
    sectionName: "c_901b2e2a-eb37-4b92-b898-5ff00b9c1e71",
    fieldName: "c_2c8045c9-c4ca-4572-a427-a26589963107",
    fieldType: "c_d3263530-f4cc-4de1-a1e1-99ed1cb909db",
    fieldGroup: "c_313a65da-9395-463b-aa32-8184a89c36ab",
    friendlyName: "c_9d7fa566-dede-4f0f-9e05-6cefd662330d",
    status: "c_481bb842-94f2-4301-80a3-26be7c46802a",
    validationRequired: "c_28e60aa5-fd6e-46cd-8732-bb342e4c6bf7",
    validationType: "c_c063a921-dd0f-4598-bf88-85ca996e177c",
};

const rowActions = [
    {
        name: "edit-field",
        icon: "edit-empty",
        title: "Edit Field Properties",
    },
    {
        name: "open-page",
        icon: "open-new-window",
        title: "Manage Form Page",
    },
];

const FriendlyNamesGrid = memo(({ utilityNumber, programNumber, formNumber, formName, onClose }) => {
    const { viewName } = useContext(WindowContext);
    const sideNavId = getViewSideNavKey({
        viewName,
        position: sideNavPosition.right,
    });

    const dispatch = useDispatch();
    const dataGridId = getFriendlyNamesGridGridId({ formNumber });

    const [gridConfig, isLoadingConfig] = useDataGrid({
        dataGridId: availableGrids.friendlyNames,
        dataGridInstanceId: dataGridId,
        sorting: SortType.CLIENT,
        getDataAction: {
            type: "friendlyNamesGridGetDataAction",
            props: {
                columnKeysMap: friendlyNamesGridColumnKeys,
                programNumber,
                formNumber,
            },
        },
    });

    // Preload friendly names to be available when sidepanel opens
    const [, isLoadingFriendlyNames] = useProgramFormFriendlyNames({
        programNumber,
    });

    const onRowAction = (action) => {
        const { name, dataItem } = action;

        const data = mapGridRowToObject(friendlyNamesGridColumnKeys, dataItem);

        const { pageNumber, pageId, sectionId, fieldId } = data;

        let elementType = null;
        let selectedElementId = null;
        const instanceId = `friendly-names-${pageNumber}`;

        dispatch(destroy({ instanceId }));

        switch (name) {
            case "open-page":
                openApplicationFormPages({
                    utilityNumber,
                    programNumber,
                    formNumber,
                    formName,
                    pageNumber,
                    selectedElementId: (fieldId ?? "").replace("root", pageNumber),
                });
                break;
            case "edit-page":
                elementType = ElementTypes.PAGE;
                selectedElementId = pageId;
                break;
            case "edit-section":
                elementType = ElementTypes.SECTION;
                selectedElementId = sectionId;
                break;
            case "edit-field":
                elementType = ElementTypes.FIELD;
                selectedElementId = fieldId;
                break;
            default:
                break;
        }

        if (elementType && selectedElementId) {
            const activePage = getActiveFormPage({ programNumber });

            if (isViewActive({ name: `formName ${programNumber}` }) && activePage?.number !== pageNumber) {
                const elementId = selectedElementId.replace("root", pageNumber);
                openApplicationFormPages({
                    utilityNumber,
                    programNumber,
                    formNumber,
                    formName,
                    pageNumber,
                    selectedElementId: elementId,
                });
            }

            dispatch(
                sideNavOpen({
                    id: sideNavId,
                    props: {
                        mode: sideNavMode.over,
                        backdrop: true,
                        className: "all-form-fields-form-sidenav-panel",
                        children: <FriendlyNamesSidePanel programNumber={programNumber} loading={true} />,
                    },
                })
            );

            dispatch(
                getPage({
                    pageNumber,
                    programNumber,
                    formNumber,
                    onSuccess: (page) => {
                        dispatch(
                            initPage({
                                instanceId,
                                ...page.configuration,
                                selectedElementId,
                            })
                        );

                        dispatch(
                            sideNavOpen({
                                id: sideNavId,
                                props: {
                                    mode: sideNavMode.over,
                                    backdrop: true,
                                    className: "all-form-fields-form-sidenav-panel",
                                    children: (
                                        <FriendlyNamesSidePanel
                                            loading={false}
                                            utilityNumber={utilityNumber}
                                            programNumber={programNumber}
                                            formNumber={formNumber}
                                            formName={formName}
                                            elementType={elementType}
                                            elementData={page}
                                            onClose={onCloseSideNav}
                                            onCancel={() => {
                                                onCloseSideNav();

                                                // Reset related instance
                                                const formBuilder = getFormBuilder({
                                                    instanceId: page.number,
                                                });
                                                const formPage = getFormPage({
                                                    pageNumber: page.number,
                                                });

                                                if (formBuilder && formPage) {
                                                    dispatch(
                                                        initPage({
                                                            instanceId: page.number,
                                                            ...formPage.configuration,
                                                            selectedElementId: selectedElementId.replace("root", pageNumber),
                                                        })
                                                    );
                                                }
                                            }}
                                        />
                                    ),
                                },
                            })
                        );
                    },
                })
            );
        }
    };

    const onCloseSideNav = useCallback(() => {
        dispatch(sideNavClose({ id: sideNavId }));
    }, [sideNavId, dispatch]);

    if (isLoadingConfig || isLoadingFriendlyNames) {
        return <WaitIcon />;
    }

    const isLocked = isChildProgram({ programNumber });

    if (isLocked) {
        rowActions[0].title = "View Field Properties";
        rowActions[0].icon = "eye-visibility-empty";
        rowActions[1].disabled = true;
        rowActions[1].title = undefined;
    }

    return (
        <div className="forms-friendly-names-tab flex-one-in-column no-scroll">
            <DataGrid
                name={dataGridId}
                config={gridConfig}
                customRowActions={rowActions}
                onRowAction={onRowAction}
                columnCellContent={{
                    [friendlyNamesGridColumnKeys.pageName]: (column, row, onExpandRow, onRowAction) => (
                        <>
                            {isLocked ? (
                                <Label>{row[column.key]}</Label>
                            ) : (
                                <ActionLabel
                                    onClick={() =>
                                        onRowAction({
                                            name: "edit-page",
                                            dataItem: row,
                                        })
                                    }
                                    title="Edit Page Properties"
                                >
                                    {row[column.key]}
                                </ActionLabel>
                            )}
                        </>
                    ),
                    [friendlyNamesGridColumnKeys.sectionName]: (column, row, onExpandRow, onRowAction) => (
                        <>
                            {isLocked ? (
                                <Label>{row[column.key]}</Label>
                            ) : (
                                <ActionLabel
                                    onClick={() =>
                                        onRowAction({
                                            name: "edit-section",
                                            dataItem: row,
                                        })
                                    }
                                    title="Edit Section Properties"
                                >
                                    {row[column.key]}
                                </ActionLabel>
                            )}
                        </>
                    ),
                    [friendlyNamesGridColumnKeys.fieldName]: (column, row, onExpandRow, onRowAction) => (
                        <>
                            {isLocked ? (
                                <Label>{row[column.key]}</Label>
                            ) : (
                                <ActionLabel
                                    onClick={() =>
                                        onRowAction({
                                            name: "edit-field",
                                            dataItem: row,
                                        })
                                    }
                                    title="Edit Field Properties"
                                >
                                    {row[column.key]}
                                </ActionLabel>
                            )}
                        </>
                    ),
                    [friendlyNamesGridColumnKeys.friendlyName]: (column, row, onExpandRow, onRowAction) => (
                        <>
                            {isLocked ? (
                                <Label>{row[column.key]}</Label>
                            ) : (
                                <ActionLabel
                                    onClick={() =>
                                        onRowAction({
                                            name: "edit-field",
                                            dataItem: row,
                                        })
                                    }
                                    title="Edit Field Properties"
                                >
                                    {row[column.key]}
                                </ActionLabel>
                            )}
                        </>
                    ),
                }}
            />
        </div>
    );
});

export default FriendlyNamesGrid;
