import React, { useCallback, memo } from "react";

import { SortType, mapGridRowToObject } from "../../../../../utils/datagrid";
import { availableGrids } from "../../../../../views/configureGrids";
import { getCalculationLookupsResourceParams } from "store/configureResources";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";

import WaitIcon from "../../../../WaitIcon";
import LookupTableForm from "./LookupTableForm";
import DataGrid from "../../../../DataGrid";
import { isChildProgram } from "components/views/ProgramView/utils";
import { useDataGrid } from "components/utils/useDataGrid";

const calculationLookupsGridColumnKeys = {
    lookupTableNumber: "c_476cc54b-2200-4eee-852c-ce55810f600c",
    lookupTableName: "c_2ad08ad8-37ce-472a-9aa6-9345e5b4945e",
    constraints: "c_49da0859-1122-4c5b-8db0-8ffb8a8f068f",
    dateCreated: "c_b2d478e6-0024-4e4a-8069-947e232d4ef8",
    dateEdited: "c_e25c0763-369a-4007-b233-e1fdff08e01a",
    userEdited: "c_57ef7ee1-101f-4569-8c78-b6bb6aef106a",
};

const customRowActions = [
    {
        name: "update",
        icon: "edit-empty",
        title: "Edit Calculation Lookup",
    },
    {
        name: "download",
        icon: "download",
        title: "Download Calculation Lookup File",
    },
    {
        name: "upload",
        icon: "upload",
        title: "Upload Calculation Lookup File",
    },
    {
        name: "list",
        icon: "view-module-empty",
        title: "Manage Calculation Lookup Values",
    },
];

const Grid = memo(({ programNumber, utilityNumber, onShowLookupList, onUploadLookupItems, onDownloadLookupItems }) => {
    const isChild = isChildProgram({ programNumber });

    if (isChild) {
        customRowActions[0].title = "View Calculation Lookup";
        customRowActions[0].icon = "eye-visibility-empty";
        customRowActions[2].disabled = true;
        customRowActions[2].title = undefined;
        customRowActions[3].title = "View Calculation Lookup Values";
    }

    const dataGridId = `${utilityNumber}-lookups-grid`;
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "calculation-sidenav-panel",
    });

    const [gridConfig, isLoading] = useDataGrid({
        dataGridId: availableGrids.calculationLookups,
        dataGridInstanceId: dataGridId,
        sorting: SortType.CLIENT,
        getDataAction: {
            type: "resourceGetDataAction",
            props: {
                columnKeysMap: calculationLookupsGridColumnKeys,
                dataGridId,
                resourceParams: getCalculationLookupsResourceParams({
                    utilityNumber,
                }),
            },
        },
    });

    const onRowAction = useCallback(
        (action) => {
            const { name, dataItem } = action;

            const data = mapGridRowToObject(calculationLookupsGridColumnKeys, dataItem);

            switch (name) {
                case "update":
                    handleOpenSidePanel(
                        <LookupTableForm
                            programNumber={programNumber}
                            dataItem={data}
                            onClose={handleCloseSidePanel}
                            utilityNumber={utilityNumber}
                        />
                    );
                    break;

                case "download":
                    onDownloadLookupItems(data);
                    break;

                case "upload":
                    onUploadLookupItems(data);
                    break;

                case "list":
                    onShowLookupList(data);
                    break;

                default:
                    break;
            }
        },
        [
            utilityNumber,
            onShowLookupList,
            onUploadLookupItems,
            onDownloadLookupItems,
            handleOpenSidePanel,
            handleCloseSidePanel,
            programNumber,
        ]
    );

    if (isLoading) {
        return <WaitIcon />;
    }

    return <DataGrid name={dataGridId} config={gridConfig} onRowAction={onRowAction} customRowActions={customRowActions} />;
});

export default Grid;
