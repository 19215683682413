import { batch } from "react-redux";
import { isEqual, isNil, orderBy } from "lodash";
import uuid from "uuid/v4";
import * as actionTypes from "../actionTypes";
import { reorder } from "../../components/utils/array";
import { isValidationTypeNumeric } from "components/utils/attributes";

export const initializeAttributes =
    ({ key, isNew, resource }) =>
    (dispatch, getState) => {
        if (isNew) {
            dispatch(
                setAttributes({
                    attributes: [],
                    key,
                })
            );
        } else {
            const attributes = ((resource && resource.attributes) || []).map((a) => ({
                ...a,
                lowerLimit: !isNil(a.lowerLimit)
                    ? isValidationTypeNumeric(a.validationType)
                        ? Number(a.lowerLimit)
                        : String(a.lowerLimit)
                    : undefined,
                upperLimit: !isNil(a.upperLimit)
                    ? isValidationTypeNumeric(a.validationType)
                        ? Number(a.upperLimit)
                        : String(a.upperLimit)
                    : undefined,
                _existing: true,
                _id: uuid(),
            }));

            dispatch(
                setAttributes({
                    attributes,
                    key,
                })
            );
        }
    };

export const addAttribute =
    ({ key, onSuccess }) =>
    (dispatch, getState) => {
        const attributes = getState().attributes[key];

        const updatedList = (attributes || []).slice();

        const itemOrder = updatedList.length;

        const attribute = {
            _id: uuid(),
            validationReq: 329,
            showAll: "Y",
            editAll: "Y",
            itemOrder,
        };

        updatedList.push(attribute);

        batch(() => {
            dispatch(
                setAttributes({
                    key,
                    attributes: updatedList,
                })
            );

            dispatch(
                selectAttribute({
                    key,
                    index: itemOrder,
                })
            );
        });

        if (onSuccess) {
            // Wait a little bit to allow rerender before onSuccess
            setTimeout(() => {
                onSuccess(attribute);
            }, 100);
        }
    };

export const removeAttribute =
    ({ key, attributeId }) =>
    (dispatch, getState) => {
        const attributes = getState().attributes[key];
        const updatedList = (attributes || []).filter((a) => a._id !== attributeId);

        dispatch(
            setAttributes({
                attributes: updatedList,
                key,
            })
        );
    };

export const setAttributes =
    ({ key, attributes }) =>
    (dispatch, getState) => {
        if (isNil(attributes)) {
            dispatch({
                type: actionTypes.PROGRAM_SET_ATTRIBUTES,
                key,
                attributes,
            });
        } else {
            const previousSelectedAttribute = getState().attributes[key]?.find((a) => a._selected) ?? {};

            // Use flag to prevent selection of more than one attribute.
            let attrSelected = false;

            const setSelected = (attribute) => {
                if (!attrSelected && previousSelectedAttribute.attrNumber === attribute.attrNumber) {
                    attrSelected = true;
                    return true;
                }

                return false;
            };

            const orderedAttributes = orderBy(attributes, ["itemOrder"]).map((a, index) => ({
                ...a,
                itemOrder: index,
                _selected: setSelected(a),
            }));

            dispatch({
                type: actionTypes.PROGRAM_SET_ATTRIBUTES,
                key,
                attributes: orderedAttributes,
            });
        }
    };

export const setAttribute =
    ({ key, attribute }) =>
    (dispatch, getState) => {
        const attributes = getState().attributes[key]?.slice();

        if (attributes?.length > 0) {
            const index = attributes.findIndex((a) => a._id === attribute._id);

            // Update if attribute found and is changed
            if (index > -1 && !isEqual(attribute, attributes[index])) {
                const attributeNewOrder = attribute.itemOrder;

                attributes[index] = {
                    ...attribute,
                };

                dispatch({
                    type: actionTypes.PROGRAM_SET_ATTRIBUTES,
                    key,
                    attributes,
                });

                if (index !== attributeNewOrder) {
                    dispatch(
                        reorderAttributes({
                            key,
                            sourceIndex: index,
                            destinationIndex: attributeNewOrder,
                        })
                    );
                }
            }
        }
    };

export const setAttributeProperty =
    ({ key, itemId, propertyName, propertyValue }) =>
    (dispatch, getState) => {
        const attributes = getState().attributes[key];

        const index = (attributes ?? []).findIndex((a) => a._id === itemId);

        if (index > -1) {
            attributes[index] = {
                ...attributes[index],
                [propertyName]: propertyValue,
            };

            dispatch({
                type: actionTypes.PROGRAM_SET_ATTRIBUTES,
                key,
                attributes,
            });
        }
    };

export const reorderAttributes =
    ({ key, sourceIndex, destinationIndex }) =>
    (dispatch, getState) => {
        const attributes = getState().attributes[key];

        if (attributes && attributes.length > sourceIndex && attributes.length > destinationIndex) {
            const updatedList = (attributes || []).slice();

            reorder(updatedList, sourceIndex, destinationIndex);

            updatedList.forEach((a, index) => {
                a.itemOrder = index;
            });

            dispatch({
                type: actionTypes.PROGRAM_SET_ATTRIBUTES,
                key,
                attributes: updatedList,
            });
        }
    };

export const selectAttribute =
    ({ key, index }) =>
    (dispatch, getState) => {
        const attributes = getState().attributes[key];

        if (attributes && attributes.length > index) {
            attributes.forEach((a, i) => {
                if (a._selected) {
                    attributes[i] = {
                        ...a,
                        _selected: false,
                    };
                }

                if (i === index) {
                    attributes[i] = {
                        ...a,
                        _selected: true,
                    };
                }
            });

            dispatch({
                type: actionTypes.PROGRAM_SET_ATTRIBUTES,
                key,
                attributes,
            });
        }
    };
