import React, { useState } from "react";
import { useDispatch } from "react-redux";

import TextInput from "../../../../Input/TextInput";
import FieldGroupTextarea from "../../../../../ui/FieldGroupTextarea";
import Button from "../../../../Button";
import { createResource, updateResource } from "../../../../../../store/resources/actions";
import { getData } from "../../../../../../store/dataGrid/actions";
import Separator from "../../../../../../components/ui/Separator";

import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";
import { isChildProgram } from "components/views/ProgramView/utils";

const LookupTableForm = ({ utilityNumber, dataItem, onClose, programNumber }) => {
    const isNew = !Boolean(dataItem);
    const dispatch = useDispatch();

    const [lookupTableName, setLookupTableName] = useState(isNew ? "" : dataItem.lookupTableName ?? "");
    const [constraints, setConstraints] = useState(isNew ? "" : dataItem.constraints ?? "");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const buttonText = isSubmitting ? (isNew ? "Adding.." : "Saving..") : isNew ? "Add" : "Save";

    const canSubmit = lookupTableName !== "";

    const onFormSubmit = () => {
        if (canSubmit) {
            setIsSubmitting(true);

            const onComplete = () => {
                const dataGridId = `${utilityNumber}-lookups-grid`;

                dispatch(getData({ dataGridId }));
                onClose();
            };

            if (isNew) {
                dispatch(
                    createResource({
                        resourceName: "calculationLookuptables",
                        query: {
                            utilityNumber,
                            lookupName: lookupTableName,
                            constraints,
                        },
                        onComplete,
                    })
                );
            } else {
                dispatch(
                    updateResource({
                        resourceName: "calculationLookuptables",
                        resourceId: dataItem.lookupTableNumber,
                        query: {
                            lookupName: lookupTableName,
                            constraints,
                        },
                        onComplete,
                    })
                );
            }
        }
    };
    const isLocked = isChildProgram({ programNumber });
    return (
        <SideNavContent>
            <SideNavHeader
                title={isNew ? "Add Calculation Lookup" : isLocked ? "View Calculation Lookup" : "Edit Calculation Lookup"}
                leadBlockIcon={isNew ? "plus" : isLocked ? "eye-visibility-empty" : "edit-empty"}
                smallHeader
                onClose={onClose}
            />
            <SideNavBody className="flex-one-in-column">
                <TextInput
                    label="Lookup Name"
                    value={lookupTableName}
                    required
                    onChange={(e) => setLookupTableName(e.target.value)}
                    disabled={isLocked || isSubmitting}
                />
                <Separator />
                <FieldGroupTextarea
                    label="Constraint"
                    onChange={(e) => setConstraints(e.target.value)}
                    value={constraints}
                    disabled={isLocked || isSubmitting}
                />
            </SideNavBody>
            <SideNavFooter setPrimaryButton justifyEnd={isLocked}>
                {!isLocked && (
                    <Button primary onClick={onFormSubmit} disabled={!canSubmit || isSubmitting}>
                        {buttonText}
                    </Button>
                )}
                <Button onClick={onClose}>{isLocked ? "Close" : "Cancel"}</Button>
            </SideNavFooter>
        </SideNavContent>
    );
};

export default LookupTableForm;
