import React, { useCallback, useContext } from "react";
import { setAsDefaultDataSource, useFilterList } from "./utils";
import { useSelector } from "react-redux";

import IconWrap from "../../ui/Icons";
import { WorkCenterContext } from ".";

const ProgramFiltersGrid = ({ programFilters, isShared, onEdit, onShare, onDelete }) => {
    const { instanceId } = useContext(WorkCenterContext);
    const activeDataSource = useSelector((state) => state.workcenter[instanceId].activeDataSource);
    const filterList = useFilterList({ programFilters });

    const onSetAsDefaultDataSource = useCallback(() => {
        setAsDefaultDataSource({ instanceId, dataSource: activeDataSource });
    }, [instanceId, activeDataSource]);

    if (!activeDataSource) {
        return null;
    }

    const actions = (
        <>
            {!isShared && (
                <>
                    <IconWrap iconWrapWhite icon="edit-empty" onClick={onEdit} title="Edit Filter"></IconWrap>
                    <IconWrap iconWrapWhite icon="share-empty" onClick={onShare} title="Share Filter"></IconWrap>
                    <IconWrap iconWrapWhite icon="delete-trash-empty" onClick={onDelete} title="Delete Filter"></IconWrap>
                </>
            )}
            <IconWrap
                iconWrapWhite
                icon={activeDataSource.isDefault || activeDataSource.isSharedDefault ? "stars-filled" : "stars-empty"}
                title={activeDataSource.isDefault ? "Un-set as starting page for Work Center" : "Set as starting page for Work Center"}
                onClick={onSetAsDefaultDataSource}
            />
        </>
    );

    return (
        <div className="filters-grid flex-column no-scroll">
            <div className="filters-grid__lead-block icons">{actions}</div>
            <div className="filters-grid__filters-list icons">
                {filterList.map((item, index) => (
                    <div key={index} className="filters-grid__block">
                        <div className="filters-grid__column filters-grid">
                            <span>Utility</span>
                            {item.utility ?? "Not Found"}
                        </div>
                        <div className="filters-grid__column filters-grid">
                            <span>Program</span>
                            {item.program ?? "Not Found"}
                        </div>
                        <div className="filters-grid__column">
                            <span>Project Statuses</span>
                            {item.projectStatuses ?? "Not Found"}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProgramFiltersGrid;
