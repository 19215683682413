import * as actionTypes from "../actionTypes";

const initialState = {};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PROGRAM_SET_CATALOG_ASSOCIATIONS:
            state = {
                ...state,
                [action.key]: action.associations,
            };
            break;
        default:
            break;
    }

    return state;
};
