import React, { useCallback, useContext, memo } from "react";

import IconWithLabel from "../../../../../ui/Icons/IconWithLabel";
import { BatchGridContext } from "../../../context/BatchGridContext";
import { EntryRow } from "../../../shared/Grid/EntryRow";
import { Document } from "./Document";
import { formatJsonDateTime } from "../../../../../utils/date";

import "./Batch.scss";

export const Batch = memo(
    ({
        documentBatchNumber,
        batchDate,
        user,
        expandedBatches,
        loadingBatchNumbers,
        onClickBatchRow,
        onSelectBatch,
        isSelectionDisabled,
    }) => {
        const { batchDocuments, selectedBatchNumbers, selectedBatchDocumentNumbers, isBatchProcessing, archiveBatch } =
            useContext(BatchGridContext);

        const handleClickArchiveBatch = useCallback(
            (e) => {
                e.stopPropagation();
                archiveBatch(documentBatchNumber);
            },
            [documentBatchNumber, archiveBatch]
        );

        return (
            <EntryRow
                documentComponent={Document}
                entryNumber={documentBatchNumber}
                selectedEntryNumbers={selectedBatchNumbers}
                loadingEntryNumbers={loadingBatchNumbers}
                expandedEntries={expandedBatches}
                documents={batchDocuments}
                selectedEntryDocumentsNumbers={selectedBatchDocumentNumbers}
                isSelectionDisabled={isSelectionDisabled}
                isBatchProcessing={isBatchProcessing}
                onClick={onClickBatchRow}
                onSelect={onSelectBatch}
            >
                <div className="application-document-queue__batch-grid-item flex-row align-center">
                    <div className="application-document-queue__batch-grid-item-field application-document-queue__batch-grid-item-field">
                        Batch
                    </div>
                    <div className="application-document-queue__batch-grid-item-field application-document-queue__batch-grid-item-field--user flex-one">
                        <span>Printed by:</span>
                        {user}, {formatJsonDateTime(batchDate)}
                    </div>
                    <IconWithLabel
                        className="application-document-queue__batch-grid-item-field application-document-queue__batch-grid-item-field--archive"
                        icon="archives-empty--before"
                        onClick={handleClickArchiveBatch}
                    >
                        Archive batch
                    </IconWithLabel>
                </div>
            </EntryRow>
        );
    }
);
