import React, { useCallback } from "react";

import withDataGrid from "../../../ui/DataGrid/withDataGrid.js";
import DataGrid from "../../../ui/DataGrid";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers.js";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot.js";
import Form from "./Form";
import { useDispatch } from "react-redux";
import { modalClose } from "store/modal/actions.js";
import { mapGridRowToObject } from "components/utils/datagrid.js";
import { ftpScheduleColumnKeys } from "components/views/configureGrids.js";
import { deleteResource } from "store/resources/actions.js";
import { refreshFTPSchedulesGrid } from "store/dataGrid/refreshGrid.js";
import { openConfirmModal } from "components/ui/Modal/utils.js";

const FTPSchedulesGrid = (props) => {
    const { gridId, dataGridConfig } = props;
    const dispatch = useDispatch();

    const customRowActions = [
        {
            name: "edit",
            icon: "edit-empty",
            title: "Edit",
        },
        {
            name: "delete",
            icon: "delete-trash-empty",
            title: "Delete",
        },
    ];

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "ftp-schedules-sidenav-panel",
    });

    const onRowAction = useCallback(
        (action) => {
            const { name, dataItem } = action;
            const ftpSchedule = mapGridRowToObject(ftpScheduleColumnKeys, dataItem);

            switch (name) {
                case "edit":
                    handleOpenSidePanel(<Form onClose={handleCloseSidePanel} dataItem={dataItem} />);
                    break;

                case "delete":
                    openConfirmModal({
                        title: "Delete FTP Schedule",
                        modalIcon: "delete-trash-empty",
                        message: (
                            <p>
                                Do you want to delete FTP schedule <b>{ftpSchedule.clientDownloadConfigScheduleNumber}</b>?
                            </p>
                        ),
                        onConfirm: () => {
                            dispatch(
                                deleteResource({
                                    resourceName: "ftpSchedules",
                                    resourceId: ftpSchedule.clientDownloadConfigScheduleNumber,
                                    onSuccess: () => {
                                        refreshFTPSchedulesGrid();
                                        dispatch(modalClose());
                                    },
                                })
                            );
                        },
                    });
                    break;

                default:
                    break;
            }
        },
        [dispatch, handleCloseSidePanel, handleOpenSidePanel]
    );

    return <DataGrid onRowAction={onRowAction} name={gridId} config={dataGridConfig} customRowActions={customRowActions} />;
};

export default withDataGrid("fileTransferProtocolSchedules", FTPSchedulesGrid);
