import * as actionTypes from "../actionTypes";

const initialState = {
    isGettingDocument: false,
    isSavingDocument: false,
    document: null,
    isError: false,
    message: null,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DOCUMENTS_GET_ONE_REQUEST:
            state = {
                ...state,
                isGettingDocument: true,
                isError: false,
                message: null,
            };
            break;
        case actionTypes.DOCUMENTS_GET_ONE_SUCCESS:
            state = {
                ...state,
                isGettingDocument: false,
                document: {
                    ...action.data,
                },
            };
            break;
        case actionTypes.DOCUMENTS_GET_ONE_ERROR:
            state = {
                ...state,
                isGettingDocument: false,
                isError: true,
                message: action.message,
            };
            break;
        case actionTypes.DOCUMENTS_CREATE_ONE_REQUEST:
            state = {
                ...state,
                isSavingDocument: true,
                isError: false,
                message: null,
            };
            break;
        case actionTypes.DOCUMENTS_CREATE_ONE_SUCCESS:
            state = {
                ...state,
                isSavingDocument: false,
                message: action.data.responseMessage,
            };
            break;
        case actionTypes.DOCUMENTS_CREATE_ONE_ERROR:
            state = {
                ...state,
                isSavingDocument: false,
                isError: true,
                message: action.message,
            };
            break;
        case actionTypes.DOCUMENTS_CLEAR_DOCUMENT:
            state = {
                ...state,
                document: null,
            };
            break;
        default:
            break;
    }

    return state;
};
