import React, { memo, useMemo } from "react";
import cn from "classnames";
import TextLabel from "components/ui/Label/TextLabel";
import { TaskIndicator } from "./TaskIndicator";
import {
    isToDoTask,
    isTodoAdHocTask,
    isTodoDependentTask,
    isTodoAutomatedTask,
    isCompletedTask,
    isCompletedAdHocTask,
    isCompletedAutomatedTask,
    isSkippedTask,
} from "../utils";

import "./TaskCounter.scss";

export const TaskCounter = memo(({ tasks }) => {
    const taskGroups = useMemo(() => {
        const toDoAllTaskCount = tasks.filter(
            (t) => isToDoTask(t) || isTodoAdHocTask(t) || isTodoDependentTask(t) || isTodoAutomatedTask(t)
        ).length;
        const toDoTaskCount = tasks.filter(
            (t) => isToDoTask(t) && !isTodoAdHocTask(t) && !isTodoDependentTask(t) && !isTodoAutomatedTask(t)
        ).length;
        const toDoAdHocTaskCount = tasks.filter((t) => isTodoAdHocTask(t)).length;
        const toDoAutomatedTaskCount = tasks.filter((t) => isTodoAutomatedTask(t)).length;
        const toDoDependentTaskCount = tasks.filter((t) => isTodoDependentTask(t)).length;

        const completedAllTaskCount = tasks.filter(
            (t) => (isCompletedTask(t) || isCompletedAdHocTask(t) || isCompletedAutomatedTask(t)) && !isSkippedTask(t)
        ).length;
        const completedTaskCount = tasks.filter(
            (t) => isCompletedTask(t) && !isCompletedAdHocTask(t) && !isCompletedAutomatedTask(t) && !isSkippedTask(t)
        ).length;
        const completedAdHocTaskCount = tasks.filter((t) => isCompletedAdHocTask(t) && !isSkippedTask(t)).length;
        const completedAutomatedTaskCount = tasks.filter((t) => isCompletedAutomatedTask(t) && !isSkippedTask(t)).length;

        const skippedAllTaskCount = tasks.filter((t) => isSkippedTask(t)).length;
        const skippedTaskCount = tasks.filter((t) => isSkippedTask(t) && !isCompletedAdHocTask(t) && !isCompletedAutomatedTask(t)).length;
        const skippedAdHocTaskCount = tasks.filter((t) => isSkippedTask(t) && isCompletedAdHocTask(t)).length;
        const skippedAutomatedTaskCount = tasks.filter((t) => isSkippedTask(t) && isCompletedAutomatedTask(t)).length;

        return (
            [
                {
                    title: "To Do",
                    total: toDoAllTaskCount,
                    details: [
                        {
                            title: "Task",
                            count: toDoTaskCount,
                        },
                        {
                            title: "Ad-hoc",
                            count: toDoAdHocTaskCount,
                        },
                        {
                            title: "Automated",
                            count: toDoAutomatedTaskCount,
                        },
                        {
                            title: "Dependent",
                            count: toDoDependentTaskCount,
                        },
                    ],
                },
                {
                    title: "Completed",
                    total: completedAllTaskCount,
                    details: [
                        {
                            title: "Task",
                            count: completedTaskCount,
                        },
                        {
                            title: "Ad-hoc",
                            count: completedAdHocTaskCount,
                        },
                        {
                            title: "Automated",
                            count: completedAutomatedTaskCount,
                        },
                    ],
                },
                {
                    title: "Skipped",
                    total: skippedAllTaskCount,
                    details: [
                        {
                            title: "Task",
                            count: skippedTaskCount,
                        },
                        {
                            title: "Ad-hoc",
                            count: skippedAdHocTaskCount,
                        },
                        {
                            title: "Automated",
                            count: skippedAutomatedTaskCount,
                        },
                    ],
                },
            ]
                // Filter out groups with 0 tasks
                .filter((g) => g.total > 0)
                // Filter out group counters with 0 tasks
                .map((g) => ({
                    ...g,
                    details: g.details.filter((i) => i.count > 0),
                }))
        );
    }, [tasks]);

    return (
        <div className="workflow-tasks-counter flex-row flex-wrap">
            {taskGroups.map((group, index) => (
                <div
                    key={index}
                    className={cn("workflow-tasks-counter-group flex-row", {
                        "workflow-tasks-counter-group--todo": group.title === "To Do",
                        "workflow-tasks-counter-group--completed": group.title === "Completed",
                        "workflow-tasks-counter-group--skipped": group.title === "Skipped",
                    })}
                >
                    <div className="workflow-tasks-counter-total flex-row align-center justify-center">{group.total}</div>
                    <div className="flex-column">
                        <TextLabel className="workflow-tasks-counter-title flex-one-in-column">
                            TASKS <strong>{group.title}</strong>
                        </TextLabel>
                        <ul className="workflow-tasks-counter-details flex-row flex-one-in-column">
                            {group.details.map((item, index) => (
                                <li key={index} className="workflow-tasks-counter-details-item">
                                    <TaskIndicator
                                        className={item.title.toLowerCase()}
                                        skippedStatus={group.title === "Skipped"}
                                        completedStatus={group.title === "Completed"}
                                    />
                                    <span className="workflow-tasks-counter-details-item-title">{item.title}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </div>
    );
});
