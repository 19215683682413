import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import QRCode from "qrcode.react";

import * as loginActions from "../../../store/login/actions";

import { createResource, deleteResource } from "../../../store/resources/actions";

import WaitIcon from "../../ui/WaitIcon";
import Form from "../../ui/Form";
import Button from "../../ui/Button";
import LoginFormWrap from "../../ui/LoginFormWrap";
import IconWrap from "../../ui/Icons";
import FieldGroupInput from "../../ui/FieldGroupInput";

import "./QrCodeForm.scss";

const QrCodeForm = memo(() => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);

    const [authCode, setAuthCode] = useState("");
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (user) {
            setIsLoading(false);
        }
    }, [user]);

    const onSubmit = (e) => {
        e.preventDefault();

        dispatch(
            createResource({
                resourceName: "usersAuthCode",
                body: {
                    authCode,
                },
                showErrorNotification: false,
                onSuccess: () => {
                    dispatch(loginActions.loginClearQrCode());
                },
                onError: () => {
                    setIsError(true);
                },
            })
        );
    };

    const onCancel = (e) => {
        e.preventDefault();

        dispatch(
            deleteResource({
                resourceName: "usersQrCode",
                path: {
                    userNumber: user.userNumber,
                },
                showErrorNotification: false,
                showSuccessNotification: false,
                onComplete: () => dispatch(loginActions.logout()),
            })
        );
    };

    const getQrCode = () => {
        return "otpauth://totp/VisionDSM:" + user.name + "?secret=" + user.qrCode + "&issuer=AEG";
    };

    if (isLoading) {
        return (
            <LoginFormWrap>
                <WaitIcon />
            </LoginFormWrap>
        );
    }

    return (
        <LoginFormWrap expandedWidth>
            <Form onSubmit={onSubmit}>
                <h3>Set Up Authenticator</h3>
                <div className="two-fa-setup-steps">
                    <ul>
                        <li>
                            Get the <b>Google Authenticator</b> or <b>Microsoft Authenticator</b> app from your application store
                        </li>
                        <li>
                            Once the app is installed, select <b>Set Up Account</b> in the app
                        </li>
                        <li>
                            Choose
                            <br />
                            <b>Scan barcode</b>
                        </li>
                        <li>
                            Scan the barcode
                            <IconWrap icon="arrow-thin-right-empty"></IconWrap>
                            <br />
                            to configure the authentication code you will use to log into the VisionDSM system
                        </li>
                    </ul>
                    <div className="qr-code-wrap">
                        <QRCode className="qr-code" value={getQrCode()} size={210} />
                    </div>
                </div>
                <div className="form-btn-wrap qr-continue">
                    <FieldGroupInput
                        autoFocus
                        label={"Authentication Code"}
                        name={"authCode"}
                        type={"text"}
                        autoComplete={"off"}
                        placeholder={"Enter Authentication Code"}
                        onChange={(e) => {
                            setIsError(false);
                            setAuthCode(e.target.value);
                        }}
                        value={authCode}
                        error={isError}
                        msgText={isError ? "Invalid Authentication Code" : ""}
                    ></FieldGroupInput>
                    <span className="flex-one flex-row align-center justify-end">
                        <span className="login-form-link" onClick={onCancel}>
                            Return to login page
                        </span>
                    </span>
                    <Button type="submit" disabled={isEmpty(authCode)} primary onClick={onSubmit}>
                        {"Continue"}
                    </Button>
                </div>
            </Form>
        </LoginFormWrap>
    );
});

export default QrCodeForm;
