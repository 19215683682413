import SelectBox from "components/ui/SelectBox";
import React from "react";
import { BorderCornerName } from "./types";

export const CornerBox = ({ side, size, isSelected, onClick }: CornerBoxProps) => {
    return (
        <SelectBox
            className={`corner-box corner-box--${side} corner-box--${size} flex-one-in-row`}
            selectBoxSelected={isSelected}
            onClick={isSelected && side === "all-sides" ? undefined : onClick}
        >
            <div className="corner-box-border"></div>
        </SelectBox>
    );
};

interface CornerBoxProps {
    side: BorderCornerName;
    size?: string;
    isSelected: boolean;
    onClick: () => void;
}
