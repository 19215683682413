import React, { memo } from "react";
import cn from "classnames";

import Button from "../../../Button";

const FooterActions = memo(({ isDetails, isSubmitting, onSubmit, onEdit, onCancel, isNew, programNumber, disabled }) => {
    return (
        <div
            className={cn("calculation-actions flex-row", {
                "justify-center": !disabled,
                "justify-end": disabled,
            })}
        >
            {!disabled && !isDetails && !isNew && (
                <Button primary onClick={onSubmit} disabled={isSubmitting}>
                    {isSubmitting ? "Saving..." : "Save"}
                </Button>
            )}
            {!disabled && !isDetails && isNew && (
                <Button primary icon="plus" onClick={onSubmit} disabled={isSubmitting}>
                    {isSubmitting ? "Adding..." : "Add New Calculation"}
                </Button>
            )}
            {!disabled && isDetails && (
                <Button icon="edit-empty" primary onClick={onEdit}>
                    Edit
                </Button>
            )}
            <Button onClick={onCancel} disabled={isSubmitting}>
                {isDetails || disabled ? "Close" : "Cancel"}
            </Button>
        </div>
    );
});

export default FooterActions;
