import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { isNil } from "lodash";
import classNames from "classnames";

import { createResource } from "store/resources/actions";
import { hasAnyOfPermissions, systemUserRights } from "components/utils/user";

import DashboardPanel from "../DashboardPanel";
import JsonSchemaForm from "components/ui/Form/JsonSchema/JsonSchemaFormV2";
import GreenCheck from "assets/img/green-check.png";
import LoadingIndicator from "assets/img/vision-loading.gif";
import IconWithLabel from "components/ui/Icons/IconWithLabel";
import StatusMsg from "components/ui/StatusMsg";

import RedX from "assets/img/red-x.png";
import "./EligibilityVerification.scss";

const EligibilityVerification = ({ panel }) => {
    const noPermission = !hasAnyOfPermissions([systemUserRights.VISIONDSM_ALLOW_CLIENT_ACCESS]);
    const [active, setActive] = useState(null);
    const [verifying, setVerifying] = useState(null);
    const [formData, setFormData] = useState({});
    const [utility, setUtility] = useState(null);
    const formRef = useRef();
    const dispatch = useDispatch();

    // need to preload the loading spinner
    const preloadedSpinner = useRef();
    useEffect(() => {
        preloadedSpinner.current = new Image();
        preloadedSpinner.current.src = LoadingIndicator;
    }, []);

    const schema = {
        type: "object",
        required: ["accountNumber", "addressNumber"],
        properties: {
            accountNumber: {
                type: "string",
                title: "Customer Account Number",
                maxLength: 100,
            },
            addressNumber: {
                type: "string",
                title: "Address Number",
                maxLength: 20,
            },
        },
    };

    const onSubmit = (formData) => {
        const { accountNumber, addressNumber } = formData;
        setVerifying(true);
        dispatch(
            createResource({
                resourceName: "cisEligibilityVerification",
                body: {
                    accountNumber: accountNumber,
                    addressNumber: addressNumber,
                },
                onSuccess: (response) => {
                    setActive(response.data.swcEligibility);
                    setUtility(response.data.utility);
                    setVerifying(false);
                },
                onError: () => {
                    setVerifying(false);
                },
            })
        );
    };

    const otherActions = (
        <div className="flex-column fill-width">
            <StatusMsg className="form-info-message" msgInfo>
                Please enter the Address Number exactly as shown on the Customer’s Utility Bill up to the first space of the full address
            </StatusMsg>
        </div>
    );

    const resultBlockClasses = classNames("info-block fill-with fill-height flex-column align-center", {
        error: !active,
        success: active,
    });

    const resultTextClasses = classNames("result-text", {
        "not-active": !active,
        active: active,
    });

    const updateData = (event) => {
        const { accountNumber, addressNumber } = event.formData;
        setFormData({ accountNumber, addressNumber });
    };

    const onBack = () => {
        setActive(null);
        setUtility(null);
    };

    return (
        <DashboardPanel title={panel.name} className="eligibility-verification-panel">
            {!noPermission && isNil(active) && !verifying && (
                <div className="rjsf-extra-container flex-row align-center justify-center">
                    <JsonSchemaForm
                        onChange={updateData}
                        otherActions={otherActions}
                        formRef={formRef}
                        submitText="Verify"
                        schema={schema}
                        onSubmit={onSubmit}
                        submitIcon="search-person-empty"
                        resetText="Clear"
                        resetDisabled={!formData.accountNumber && !formData.addressNumber}
                    ></JsonSchemaForm>
                </div>
            )}
            {verifying && (
                <div className="flex-column full-width justify-center align-center">
                    <div className="info-block loading fill-height flex-row align-center justify-center">
                        <img src={LoadingIndicator} alt="loading" height="65" width="65" />
                        <div className="flex-column info-text-block">
                            <span className="please-wait">Please wait...</span>
                            <span className="info-description">
                                We are verifying the eligibility for your entered Customer Account and Address Number
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {noPermission && (
                <div className="flex-column full-width justify-center align-center">
                    <div className="info-block loading fill-height flex-row align-center justify-center">
                        <div className="flex-column info-text-block">
                            <span className="info-description">
                                <StatusMsg msgInfo>You are not permitted to use this functionality</StatusMsg>
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {!isNil(active) && (
                <div className="flex-column full-width justify-center align-center">
                    <div className={resultBlockClasses}>
                        <div className="flex-column flex-one">
                            <div className="flex-column align-center margin-auto form-property-result-container">
                                <div className="flex-column align-center">
                                    <span className="form-property-label">Customer Account Number:</span>
                                    <span className="form-property-result">{formData.accountNumber}</span>
                                </div>

                                <div className="flex-column  align-center address-number-container">
                                    <span className="form-property-label">Address Number:</span>
                                    <span className="form-property-result">{formData.addressNumber}</span>
                                </div>

                                {utility && (
                                    <div className="flex-column align-center utility-container">
                                        <span className="form-property-label">Utility:</span>
                                        <span className="form-property-result">{utility}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex-one flex-column align-center">
                            <div className="margin-auto flex-column align-center form-result-container">
                                <img src={active ? GreenCheck : RedX} alt="" height="80" width="80" />
                                <span className={resultTextClasses}>{active ? "Active" : "Not Active or Information Incorrect"}</span>
                                {!active && (
                                    <span className="error-hint">
                                        Please re-verify or contact the utility program <br /> administrator if you believe this is an
                                        error.
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="flex-one flex-column back-button-container">
                            <IconWithLabel onClick={onBack} className="margin-auto back-button" icon="shevron-small-left">
                                Do another Verification
                            </IconWithLabel>
                        </div>
                    </div>
                </div>
            )}
        </DashboardPanel>
    );
};

export default React.memo(EligibilityVerification);
