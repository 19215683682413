import React, { memo, useState, useCallback, useEffect, useRef } from "react";
import cn from "classnames";
import { useAutoSize } from "components/utils/autosize";
import useUnmounted from "components/utils/useUnmounted";
import Checkbox from "components/ui/Input/Checkbox";
import WaitIcon from "components/ui/WaitIcon";
import { RoleButton } from "./RoleButton";
import { RolesListCell } from "./RolesListCell";
import { RolesListRow } from "./RolesListRow";
import { RolesListScrollableCells } from "./RolesListScrollableCells";

export const RolesListItemRow = memo(
    ({
        index,
        style,
        data,
        isScrolling,
        isSelected,
        roles,
        roleButtonTitle,
        roleBadgeTitle,
        onSelect,
        onRoleClick,
        onRoleBadgeClick,
        onGetListItemInfo,
    }) => {
        const { items, itemInfo } = data;
        const item = items[index];
        const itemId = item.itemId;
        const itemName = item.itemName;

        const [itemData, setItemData] = useState(itemInfo[item.itemId]);
        const itemDataReqested = useRef(false);
        const unmounted = useUnmounted();

        const isItemDataLoading = itemData === undefined;

        // Content container for minimum size calculation
        const contentRef = React.useRef();

        // Content size updater
        useAutoSize(contentRef, index, 0, true);

        const getItemData = useCallback(
            async ({ refresh = false } = {}) => {
                if (!unmounted.current) {
                    const response = await onGetListItemInfo(itemId, refresh);
                    !unmounted.current && setItemData(response);
                }
            },
            [itemId, unmounted, onGetListItemInfo]
        );

        // Effect to get initial list item data
        useEffect(() => {
            let timeoutHandler = null;

            if (onGetListItemInfo && !isScrolling && !itemDataReqested.current) {
                itemDataReqested.current = true;

                // Add little debounce as there can be unmounts while scrolling.
                clearTimeout(timeoutHandler);
                timeoutHandler = setTimeout(() => getItemData(), 10);
            }

            return () => {
                clearTimeout(timeoutHandler);
            };
        }, [index, itemId, isScrolling, getItemData, onGetListItemInfo]);

        const isHighlighted = (role) => {
            return itemData?.defaultRoleId === role.roleId;
        };

        const getRoleAssignments = (role) => {
            return itemData?.roleAssignments?.[role.roleId]?.length;
        };

        const onRoleBtnClick = useCallback(
            (role) => {
                onRoleClick({
                    itemId,
                    roleId: role.roleId,
                    onSuccess: (props) => {
                        // Clear item data to show loading while refresh
                        setItemData();

                        // Refresh item data
                        getItemData({ refresh: true });
                    },
                });
            },
            [itemId, getItemData, onRoleClick]
        );

        const onRoleBtnBadgeClick = useCallback(
            (event, role) => {
                event.stopPropagation();

                if (onRoleBadgeClick) {
                    onRoleBadgeClick({
                        itemId,
                        roleId: role.roleId,
                        itemData,
                    });
                }
            },
            [itemId, itemData, onRoleBadgeClick]
        );

        return (
            <RolesListRow rowRef={contentRef} style={style}>
                <RolesListCell
                    className={cn("roles-list-cell--static", {
                        "roles-list-cell--clickable": onSelect,
                    })}
                    onClick={(e) => (onSelect ? onSelect(e, item) : {})}
                >
                    <div className="flex-row align-center justify-space-between fill-height">
                        {onSelect ? <Checkbox labelIconBig checked={isSelected} onChange={(e) => onSelect(e, item)} /> : <span />}
                        {itemName}
                    </div>
                </RolesListCell>
                <RolesListScrollableCells hideScroll>
                    {isItemDataLoading && <WaitIcon />}
                    {!isItemDataLoading &&
                        roles.map((role, index) => (
                            <RolesListCell key={index}>
                                <RoleButton
                                    roleName={role.roleName}
                                    highlight={isHighlighted(role)}
                                    roleAssignments={getRoleAssignments(role)}
                                    isLoading={isItemDataLoading}
                                    roleButtonTitle={roleButtonTitle}
                                    roleBadgeTitle={roleBadgeTitle}
                                    onClick={onRoleClick ? () => onRoleBtnClick(role) : undefined}
                                    onBadgeClick={onRoleClick ? (event) => onRoleBtnBadgeClick(event, role) : undefined}
                                />
                            </RolesListCell>
                        ))}
                </RolesListScrollableCells>
            </RolesListRow>
        );
    }
);
