import React from "react";
import cn from "classnames";

const SideNavFooter = ({
    className,
    noPadding,
    justifyEnd,
    justifyCenter,
    justifySpaceBetween,
    setPrimaryButton,
    children,
    hidden,
}: SideNavFooterProps) => {
    return (
        <div
            hidden={hidden}
            className={cn("sidenav-footer", className, {
                "sidenav-footer--no-padding": noPadding,
                "flex-row justify-end": justifyEnd,
                "flex-row justify-center": justifyCenter,
                "flex-row justify-space-between": justifySpaceBetween,
                "flex-row set-primary-button": setPrimaryButton,
            })}
        >
            {children}
        </div>
    );
};

export default SideNavFooter;

interface SideNavFooterProps {
    className?: string;
    noPadding?: boolean;
    justifyEnd?: boolean;
    justifyCenter?: boolean;
    justifySpaceBetween?: boolean;
    setPrimaryButton?: boolean;
    children?: React.ReactNode;
    hidden?: boolean;
}
