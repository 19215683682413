import React, { memo, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";

import { modalOpen } from "../../../../store/modal/actions";
import { formatJsonDate, formatJsonDateTime } from "../../../utils/date";
import { useResource } from "../../../../store/resources/useResource";
import { updateResource } from "../../../../store/resources/actions";
import { reapplySelectedStatusText } from "../../Dashboard/Panel/Project/utils";
import { refreshInvoiceDocumentTab } from "store/resources/refreshResource";

import RawHtml from "../../RawHtml";
import CopyToClipboard from "../../Label/CopyToClipboard";
import WaitIcon from "../../WaitIcon";
import IconWrap from "../../Icons";
import ActionLabelWithDropdown from "../../Label/ActionLabelWithDropdown";
import { invoiceIsLockedText } from "../../../views/UtilityInvoiceView/utils";

import "./WindowHeaderContent.scss";
import "./WindowHeaderForProject.scss";

const WindowHeaderForUtilityInvoice = memo(({ view }) => {
    const dispatch = useDispatch();
    const documentNumber = get(view, `props.documentNumber`);

    const invoiceFromStore = useSelector((state) => get(state, `resources.invoiceDocument.itemsById[${documentNumber}]`));
    const invoice = useMemo(() => invoiceFromStore || {}, [invoiceFromStore]);

    const isLocked = useSelector((state) => get(state, `resources.invoiceDocument.itemsById[${documentNumber}].islocked`)) === true;
    const { utilityNumber, contractNumber } = invoice;

    const [statuses = [], isLoadingStatuses] = useResource({
        resourceName: "utilitiesContractsWorkflowStatuses",
        key: contractNumber,
        path: {
            utilityNumber,
            contractNumber,
        },
    });

    const canChangeStatus = !isLocked && statuses.length > 1;
    const canReapplyStatus = !isLocked;

    const onChangeStatus = useCallback(
        (status) => {
            const text =
                `<p>This action will ` +
                (invoice.status === status.status ? "re-apply invoice status" : "change invoice status to") +
                ` <strong>${status.status}</strong>. Are you sure you want to do this?</p>`;

            dispatch(
                modalOpen({
                    modalType: "CONFIRM",
                    modalProps: {
                        title: invoice.status === status.status ? "Re-apply selected Invoice Status" : "Change Invoice Status",
                        overlayClassName: "modal-styled",
                        className: "change-status-confirmation-modal modal-sm",
                        modalIcon: invoice.status === status.status ? "repeat" : "",
                        footerContentCenter: true,
                        content: <RawHtml>{text}</RawHtml>,
                        onConfirm: () => {
                            dispatch(
                                updateResource({
                                    resourceName: "utilitiesContractsInvoiceDocumentsStatus",
                                    path: {
                                        utilityNumber,
                                        contractNumber,
                                        documentNumber,
                                    },
                                    query: {
                                        wfNumber: status.workflowNumber,
                                    },
                                    onSuccess: () => {
                                        refreshInvoiceDocumentTab({
                                            documentNumber,
                                        });
                                    },
                                })
                            );
                        },
                    },
                })
            );
        },
        [contractNumber, dispatch, documentNumber, invoice.status, utilityNumber]
    );

    const handleReapplyChangeStatus = useCallback(() => {
        if (invoice && invoice.status && canReapplyStatus) {
            const status = {
                status: invoice.status,
                workflowNumber: invoice.wfNumber,
            };
            onChangeStatus(status);
        }
    }, [invoice, canReapplyStatus, onChangeStatus]);

    if (!invoice) {
        return null;
    }

    return (
        <div className={"window-header-content" + (isLocked ? " app-is-locked" : "")}>
            {isLocked && (
                <div className="app-is-locked-mark">
                    <IconWrap icon="lock-encryption-empty" title={invoiceIsLockedText}></IconWrap>
                </div>
            )}
            <div className="project-mark">
                <span>Invoice</span>
            </div>
            <div className="project-number">
                #
                <CopyToClipboard title="Copy Invoice Number to clipboard" successMessage="Invoice Number copied to clipboard">
                    {invoice.invoiceNumber}
                </CopyToClipboard>
            </div>
            {invoice.isFavorite && <IconWrap iconWrap="bookmark-filled" />}
            <div className="project-info">
                <div className="status">
                    <span>Status:</span>
                    {isLoadingStatuses && <WaitIcon />}
                    {!isLoadingStatuses && !canChangeStatus && invoice.status}
                    {!isLoadingStatuses && canChangeStatus && (
                        <ActionLabelWithDropdown
                            iconWithLabel="shevron-small-down-expand-more"
                            mobileHeader="Change Invoice Status to"
                            label={invoice.status}
                            items={statuses}
                            onClick={(status) => onChangeStatus(status)}
                        />
                    )}
                    {!isLoadingStatuses && canReapplyStatus && (
                        <IconWrap
                            iconWrapClickable
                            iconWrapTransparent
                            iconWrap="repeat"
                            title={reapplySelectedStatusText}
                            onClick={handleReapplyChangeStatus}
                        ></IconWrap>
                    )}
                </div>
            </div>
            <div className="sub-info">
                <div className="main-grid-wrap responsive">
                    <div className="sub-info-wrapper">
                        <div className="sub-info-items">
                            <div className="sub-info-item status-not-desktop">
                                <span>Status:</span>
                                {isLoadingStatuses && <WaitIcon />}
                                {!isLoadingStatuses && !canChangeStatus && invoice.status}
                                {!isLoadingStatuses && canChangeStatus && (
                                    <ActionLabelWithDropdown
                                        iconWithLabel="shevron-small-down-expand-more"
                                        mobileHeader="Change Invoice Status to"
                                        label={invoice.status}
                                        items={statuses}
                                        onClick={(status) => onChangeStatus(status)}
                                        popperEnabled
                                    />
                                )}
                                {!isLoadingStatuses && canReapplyStatus && (
                                    <IconWrap
                                        iconWrapClickable
                                        iconWrap="repeat"
                                        title={reapplySelectedStatusText}
                                        onClick={handleReapplyChangeStatus}
                                    ></IconWrap>
                                )}
                            </div>
                            <div className="sub-info-item">
                                <span>Invoice Doc Date:</span>
                                {`${formatJsonDate(invoice.invoiceDate, "-")}`}
                            </div>
                            <div className="sub-info-item">
                                <span>Entered:</span>
                                {`${formatJsonDateTime(invoice.dateEntered, "-")}`}
                            </div>
                            <div className="sub-info-item">
                                <span>Edited:</span>
                                {`${formatJsonDateTime(invoice.dateEdited, "-")}`}
                            </div>
                            <div className="sub-info-item">
                                <span>Ref ID:</span>
                                {invoice.refId}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default WindowHeaderForUtilityInvoice;
