import React from "react";
import ClassNames from "classnames";

import "./style.scss";

const ButtonGroup = (props) => {
    const className = ClassNames("btn-group", props.className, {
        "side-by-side": props.sideBySide,
        "not-equal-btn-width": props.notEqualBtnWidth,
        transparent: props.transparent,
        "flex-row": props.flex,
    });

    return <div className={className}>{props.children}</div>;
};

export default ButtonGroup;
