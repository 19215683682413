import * as actionTypes from "../actionTypes";
import { selectRows, construct, destroy, getData, setFilter } from "../dataGrid/actions";
import { searchAttrToFilter } from "../../components/utils/datagrid";
import { batch } from "react-redux";
import { getDefaultApplicationsFilter, getDefaultTasksFilter } from "components/views/ApplicationProcessingWorkCenter/utils";

export const initWorkcenter =
    ({ instanceId }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.INIT_WORKCENTER,
            instanceId,
        });
    };

export const setWorkqueueActiveDataSource =
    ({ instanceId, activeDataSource }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_WORKQUEUE_ACTIVE_DATASOURCE,
            instanceId,
            activeDataSource,
        });
    };

export const setWorkqueueDataSourceFilterExpanded =
    ({ instanceId, dataSourceFilterExpanded }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_WORKQUEUE_DATASOURCE_FILTER_EXPANDED,
            instanceId,
            dataSourceFilterExpanded,
        });
    };

export const setWorkqueueActiveFilterTab =
    ({ instanceId, activeFilterTab }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_WORKQUEUE_ACTIVE_FILTER_TAB,
            instanceId,
            activeFilterTab,
        });
    };

export const setWorkqueueApplicationsFilter =
    ({ instanceId, applicationsFilter }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_WORKQUEUE_APPLICATIONS_FILTER,
            instanceId,
            applicationsFilter,
        });
    };

export const setWorkqueueTasksFilter =
    ({ instanceId, tasksFilter }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_WORKQUEUE_TASKS_FILTER,
            instanceId,
            tasksFilter,
        });
        const element = document.getElementsByClassName("active-filter");
        setTimeout(() => {
            if (element && element[0]) {
                element[0].scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }, 100);
    };

export const setWorkqueueGrid =
    ({ instanceId, workqueueGrid, onSuccess }) =>
    (dispatch, getState) => {
        const state = getState();
        const currentWorkqueueGrid = state.workcenter[instanceId].workqueueGrid;

        if (currentWorkqueueGrid !== workqueueGrid) {
            const dataGridInstanceId = state.workcenter[instanceId].workqueueGridId;

            if (currentWorkqueueGrid) {
                dispatch(
                    destroy({
                        name: dataGridInstanceId,
                    })
                );
            }

            dispatch({
                type: actionTypes.SET_WORKQUEUE_GRID,
                instanceId,
                workqueueGrid,
            });

            if (workqueueGrid) {
                dispatch(
                    construct({
                        dataGridId: workqueueGrid,
                        dataGridInstanceId: dataGridInstanceId,
                        onSuccess,
                    })
                );
            } else if (onSuccess) {
                // no grid to construct
                onSuccess();
            }
        }
        // Grid did not change
        else if (onSuccess) {
            onSuccess();
        }
    };

export const setWorkqueueGridFilter =
    ({ instanceId, workqueueGridFilter }) =>
    (dispatch, getState) => {
        const state = getState();
        const currentWorkqueueGridFilter = state.workcenter[instanceId].workqueueGridFilter;
        const dataGridInstanceId = state.workcenter[instanceId].workqueueGridId;

        batch(() => {
            if (currentWorkqueueGridFilter !== workqueueGridFilter) {
                dispatch({
                    type: actionTypes.SET_WORKQUEUE_GRID_FILTER,
                    instanceId,
                    workqueueGridFilter,
                });

                dispatch(
                    setFilter({
                        dataGridId: dataGridInstanceId,
                        filter: searchAttrToFilter(workqueueGridFilter),
                        setAsDefault: true,
                    })
                );
            }

            dispatch(
                getData({
                    dataGridId: dataGridInstanceId,
                })
            );
        });
    };

export const setWorkqueueGridSelectedRows =
    ({ instanceId, workqueueGridSelectedRows }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_WORKQUEUE_GRID_SELECTED_ROWS,
            instanceId,
            workqueueGridSelectedRows,
        });

        if (workqueueGridSelectedRows.length === 0) {
            const dataGridId = getState().workcenter[instanceId].workqueueGridId;
            dispatch(selectRows({ dataGridId, selectRows: [] }));
        }
    };

export const dataSourceChanged =
    ({ instanceId, dataSource }) =>
    (dispatch, getState) => {
        const state = getState();

        const filterExpanded = state.workcenter[instanceId].dataSourceFilterExpanded ?? false;
        const dataSourceFilterExpanded = !dataSource ? false : dataSource.datasourceNumber === "0" ? true : filterExpanded;

        batch(() => {
            dispatch(
                setWorkqueueActiveDataSource({
                    instanceId,
                    activeDataSource: dataSource,
                })
            );
            dispatch(
                setWorkqueueDataSourceFilterExpanded({
                    instanceId,
                    dataSourceFilterExpanded,
                })
            );
            dispatch(setWorkqueueGrid({ instanceId, workqueueGrid: null }));
            dispatch(
                setWorkqueueGridFilter({
                    instanceId,
                    workqueueGridFilter: null,
                })
            );
            dispatch(
                setWorkqueueApplicationsFilter({
                    instanceId,
                    applicationsFilter: getDefaultApplicationsFilter(),
                })
            );
            dispatch(
                setWorkqueueTasksFilter({
                    instanceId,
                    tasksFilter: getDefaultTasksFilter(),
                })
            );
        });
    };
