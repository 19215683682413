import React from "react";

import StatusMark from "../../../ui/StatusMark";
import StatusTag from "../../../ui/StatusTag";
import IconWrap from "../../../ui/Icons";
import StatusMsg from "../../../ui/StatusMsg";
import CounterBox from "../../../ui/CounterBox";

class StatusesAndMarks extends React.Component {
    render() {
        return (
            <div>
                <div className="flexbox-row">
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Status Tags</div>
                        <div className="row">
                            <StatusTag statusTagSuccess>Done</StatusTag>
                            <StatusTag statusTagError>Check this</StatusTag>
                        </div>
                        <div className="row">
                            <StatusTag statusTagSuccess statusTagIcon>
                                Status
                            </StatusTag>
                            <StatusTag statusTagError statusTagIcon>
                                Status
                            </StatusTag>
                        </div>
                    </div>
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Status Marks</div>
                        <div className="row">
                            <div className="flexbox-row">
                                <div className="flexbox-col">
                                    <div className="row">
                                        <StatusMark statusMarkSuccessEmpty>Success</StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkSuccessFilled>Success</StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkError>Error</StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkWarning>Warning</StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkNeutral>Neutral</StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkTheme>Theme</StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkThemeBase>Theme Base</StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkThemeDark>Theme Dark</StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkGhost>Ghost</StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkDisabled>Disabled</StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkChildIndicator>Child</StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkParentIndicator>Parent</StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkWithIcon="visibility-off-empty" statusMarkNeutral>
                                            with icon
                                        </StatusMark>
                                    </div>
                                </div>
                                <div className="flexbox-col">
                                    <div className="row">
                                        <StatusMark statusMarkSuccessEmpty statusMarkSmall>
                                            Success
                                        </StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkSuccessFilled statusMarkSmall>
                                            Success
                                        </StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkError statusMarkSmall>
                                            Error
                                        </StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkWarning statusMarkSmall>
                                            Warning
                                        </StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkNeutral statusMarkSmall>
                                            Neutral
                                        </StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkTheme statusMarkSmall>
                                            Theme
                                        </StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkThemeBase statusMarkSmall>
                                            Theme Base
                                        </StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkThemeDark statusMarkSmall>
                                            Theme Dark
                                        </StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkGhost statusMarkSmall>
                                            Ghost
                                        </StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkDisabled statusMarkSmall>
                                            Disabled
                                        </StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkChildIndicator statusMarkSmall>
                                            Child
                                        </StatusMark>
                                    </div>
                                    <div className="row">
                                        <StatusMark statusMarkParentIndicator statusMarkSmall>
                                            Parent
                                        </StatusMark>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Status Message</div>
                        <div className="row">
                            <StatusMsg msgVisible msgFieldStatus msgError msgText="Please enter valid email address"></StatusMsg>
                        </div>
                        <div className="row">
                            <StatusMsg msgVisible msgFieldStatus msgWarning msgText="Please enter email address"></StatusMsg>
                        </div>
                        <div className="row">
                            <StatusMsg
                                msgFieldStatus
                                msgSuccess
                                msgText="Password must contain at least Numbers, Symbols and Caps"
                            ></StatusMsg>
                        </div>
                        <div className="row">
                            <StatusMsg msgFieldStatus msgInfo msgText="Use Numbers, Symbols and Caps"></StatusMsg>
                        </div>
                    </div>
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Status Message Aligned Left / Right</div>
                        <div className="row">
                            <div className="flexbox-row">
                                <div className="flexbox-col">
                                    <div className="row">
                                        <StatusMsg msgVisible msgError msgText="Not Completed"></StatusMsg>
                                    </div>
                                    <div className="row">
                                        <StatusMsg msgSuccess msgText="Completed"></StatusMsg>
                                    </div>
                                </div>
                                <div className="flexbox-col">
                                    <div className="row">
                                        <StatusMsg msgVisible msgError msgIconRight msgText="Not Completed"></StatusMsg>
                                    </div>
                                    <div className="row">
                                        <StatusMsg msgSuccess msgIconRight msgText="Completed"></StatusMsg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flexbox-row">
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Icon Status</div>
                        <div className="row">
                            <IconWrap iconWrapSuccess title="Success" iconWrap="rebates"></IconWrap>
                            <IconWrap iconWrapError title="Error" iconWrap="rebates"></IconWrap>
                            <IconWrap iconWrapWarning title="Warning" iconWrap="rebates"></IconWrap>
                            <IconWrap iconWrapNeutral title="Msg" iconWrap="rebates"></IconWrap>
                            <IconWrap iconWrapTheme title="Msg" iconWrap="rebates"></IconWrap>
                        </div>
                        <div className="row">
                            <IconWrap iconWrapBig iconWrapTheme title="Msg" iconWrap="rebates"></IconWrap>
                        </div>
                    </div>
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Counter Box</div>
                        <div className="row">
                            <div className="flex-row align-center">
                                <CounterBox>25</CounterBox> items
                            </div>
                        </div>
                    </div>
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Status Message Square</div>
                        <div className="row">
                            <StatusMsg msgSquare msgVisible msgFieldStatus msgError msgText="Please enter valid email address"></StatusMsg>
                        </div>
                        <div className="row">
                            <StatusMsg msgSquare msgVisible msgFieldStatus msgWarning msgText="Please enter email address"></StatusMsg>
                        </div>
                        <div className="row">
                            <StatusMsg
                                msgSquare
                                msgFieldStatus
                                msgSuccess
                                msgText="Password must contain at least Numbers, Symbols and Caps"
                            ></StatusMsg>
                        </div>
                    </div>
                    <div className="flexbox-col"></div>
                </div>
            </div>
        );
    }
}

export default StatusesAndMarks;
