import React from "react";
import cn from "classnames";
import { isNumber } from "lodash";

import "./style.scss";

const Separator = ({ vertical, line, marginBottom, marginTop }: SeparatorProps) => (
    <div
        className={cn("separator", {
            "separator--vertical": vertical,
            "separator--line": line,
            [`separator--margin-top-${marginTop}`]: isNumber(marginTop),
            [`separator--margin-bottom-${marginBottom}`]: isNumber(marginBottom),
        })}
    />
);

type SeparatorProps = {
    vertical?: boolean;
    line?: boolean;
    marginBottom?: number;
    marginTop?: number;
};

export default Separator;
