import React, { useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import IconWrap from "../../../../Icons";
import StatusMsg from "../../../../StatusMsg";
import StickyBottomPanel from "../../../../StickyBottomPanel";
import { ApplicationFormsSelector } from "./ApplicationFormsSelector";
import ApplicationForm from "./ApplicationForm";
import { setActiveApplicationForm } from "../../../../../../store/projects/actions";
import { clearFormData } from "store/forms/actions";
import { useApplicationForms, useApplicationFormDetails, useProgramRights } from "../../../../../../store/resources/useResource";
import { resetApplicationFormDetails } from "store/resources/resetResource";
import Button from "../../../../Button";
import { isApplicationLocked } from "../../../../../views/ProjectView/utils";
import { ErrorMessage } from "components/ui/Message";
import { systemUserRights } from "components/utils/user";

import "./style.scss";

const ApplicationsPanel = memo(({ panel, onToggleTempPanel }) => {
    const { programNumber, applicationNumber } = panel?.data;

    const dispatch = useDispatch();
    const activeApplicationFormId = useSelector((state) => state.projects.activeApplicationFormId[applicationNumber]);

    const isAppLocked = isApplicationLocked({ applicationNumber });

    const [forms = [], loadingForms] = useApplicationForms({
        applicationNumber,
    });
    const formsNotLoaded = !loadingForms && forms.length === 0;

    const [activeApplicationFormDetails, isLoadingFormDetails] = useApplicationFormDetails({
        applicationNumber,
        applicationFormId: activeApplicationFormId,
        forced: true,
    });

    const [programRights = []] = useProgramRights({ programNumber });

    const formId = `app-${applicationNumber}-${activeApplicationFormId}`;
    const formTitle = activeApplicationFormId ? (forms.filter((f) => f.pageNumber === activeApplicationFormId)[0] || {}).pageName : "";

    const allowEditAppForm = activeApplicationFormDetails?.formConfiguration?.uiSchema?.["af:allowEditAppForm"] ?? "N";

    const canEdit = activeApplicationFormDetails
        ? allowEditAppForm !== "N" && activeApplicationFormId !== "8558B8298C3C41AE94E09B2639F41443"
        : false;

    const isEditDisabled = isAppLocked || !programRights.includes(systemUserRights.VISIONDSM_ADD_APPLICATION);

    useEffect(() => {
        if (forms.length > 0 && !activeApplicationFormId) {
            dispatch(setActiveApplicationForm(applicationNumber, forms[0].pageNumber));
        }
    }, [applicationNumber, forms, activeApplicationFormId, dispatch]);

    const onFormSelect = (form) => {
        if (activeApplicationFormId !== form.pageNumber) {
            dispatch(clearFormData());
            resetApplicationFormDetails({
                applicationNumber,
                applicationFormId: form.pageNumber,
            });
            dispatch(setActiveApplicationForm(applicationNumber, form.pageNumber));
        }
    };

    const onFormEdit = () => {
        const onClose = () => {
            dispatch(clearFormData());
            resetApplicationFormDetails({
                applicationNumber,
                applicationFormId: activeApplicationFormId,
            });
            onToggleTempPanel();
        };

        onToggleTempPanel(
            "application-edit-panel",
            {
                formId,
                programNumber,
                applicationNumber,
                formTitle,
                applicationFormId: activeApplicationFormId,
                onClose,
                onSubmitSuccess: onClose,
            },
            true
        );
    };

    if (formsNotLoaded) {
        return <ErrorMessage spaceAround>Forms not loaded</ErrorMessage>;
    }

    return (
        <div className="panel applications">
            <PanelHeaderLarge title="Forms" />
            <ApplicationFormsSelector loading={loadingForms} forms={forms} onSelect={onFormSelect} activeFormId={activeApplicationFormId} />
            <div className="application-form-wrap">
                <div className="application-form-scrollable-part">
                    {!loadingForms && !isLoadingFormDetails && (
                        <div className="application-form-info">
                            <StatusMsg msgInfo>
                                Highlighted fields marked with{" "}
                                <IconWrap iconWrapCombined icon="administrative-field-icon eye-visibility-empty" /> are administrative and
                                are not shown to all users.
                            </StatusMsg>
                        </div>
                    )}
                    {!loadingForms && (
                        <ApplicationForm
                            formId={formId}
                            programNumber={programNumber}
                            applicationNumber={applicationNumber}
                            applicationFormId={activeApplicationFormId}
                            loading={isLoadingFormDetails}
                            hasStickyBottomPanel={canEdit && !loadingForms && !isLoadingFormDetails}
                        />
                    )}
                </div>
            </div>
            {canEdit && !loadingForms && !isLoadingFormDetails && (
                <StickyBottomPanel visible appView>
                    <div className="form-name flex-row flex-wrap">
                        <span>Form:</span>
                        <b>{formTitle}</b>
                    </div>
                    <Button primary icon="edit" disabled={isEditDisabled} onClick={onFormEdit}>
                        Edit Form
                    </Button>
                </StickyBottomPanel>
            )}
        </div>
    );
});

export default ApplicationsPanel;
