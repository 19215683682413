import React, { useState, memo } from "react";

import Grid from "./Grid";
import IconWithLabel from "../../../ui/Icons/IconWithLabel";
import Form from "../Form";

const UserGroups = memo(({ groups, isLoading, onRefresh, visibility }) => {
    const [showAddForm, setShowAddForm] = useState(false);

    const toggleShowAddForm = () => {
        setShowAddForm(!showAddForm);
    };

    return (
        <div className={"scan-queue-management__user-groups flex-column" + (visibility ? "" : " visible")}>
            <div className="top-block flex-row">
                <div className="top-block__title">User Groups</div>
                <IconWithLabel
                    iconWithLabelRight
                    iconWithLabel={showAddForm ? "shevron-circle-down-filled" : "plus"}
                    onClick={toggleShowAddForm}
                >
                    Add User Group
                </IconWithLabel>
                {showAddForm && <Form onRefresh={onRefresh} onClose={() => setShowAddForm(false)} />}
            </div>
            <div className="main-block flex-column flex-one-in-column no-scroll">
                <Grid rows={groups} isLoading={isLoading} onRefresh={onRefresh} />
            </div>
        </div>
    );
});

export default UserGroups;
