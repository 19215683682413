import React from "react";
import { openUrl } from "components/utils/window";
import IconWithLabel from "components/ui/Icons/IconWithLabel";
import { PropertyListItem } from "../Property/PropertyListItem";

export function GoogleAnalyticsLink() {
    const onClick = () => {
        const link = "https://analytics.google.com/";
        openUrl(link);
    };

    return (
        <PropertyListItem className="property-list-item--link" borderBottom={false} innerContent={true}>
            <IconWithLabel iconWithLabelRight icon="arrow-right-up" title="Open Google Analytics" onClick={onClick}>
                Google Analytics
            </IconWithLabel>
        </PropertyListItem>
    );
}
