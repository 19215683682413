import * as actionTypes from "../actionTypes";

const initialState = {
    activeApplicationFormId: {},
    expandedWorkflowTaskId: {},
    equipmentUploadInProgress: {},
    calculationsInProgress: {},
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PROJECTS_SET_ACTIVE_APPLICATION_FORM:
            state = {
                ...state,
                activeApplicationFormId: {
                    ...state.activeApplicationFormId,
                    [action.entityNumber]: action.applicationFormId,
                },
            };
            break;
        case actionTypes.PROJECTS_SET_EXPANDED_WORKFLOW_TASK:
            state = {
                ...state,
                expandedWorkflowTaskId: {
                    ...state.expandedWorkflowTaskId,
                    [action.entityNumber]: action.workflowTaskId,
                },
            };
            break;
        case actionTypes.PROJECTS_SET_EQUIPMENT_UPLOAD_IN_PROGRESS:
            state = {
                ...state,
                equipmentUploadInProgress: {
                    ...state.equipmentUploadInProgress,
                    [action.applicationNumber]: action.uploadInProgress,
                },
            };
            break;
        case actionTypes.PROJECTS_SET_CALCULATIONS_IN_PROGRESS:
            state = {
                ...state,
                calculationsInProgress: {
                    ...state.calculationsInProgress,
                    [action.applicationNumber]: action.calculationsInProgress,
                },
            };
            break;
        default:
            break;
    }

    return state;
};
