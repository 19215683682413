import React from "react";
import cn from "classnames";

import "./ContentSubtitle.scss";

const ContentSubtitle = ({ className, center, children }) => {
    return (
        <h5
            className={cn(className, {
                "content-subtitle": !center,
                "content-subtitle--center": center,
            })}
        >
            {children}
        </h5>
    );
};

export default ContentSubtitle;
