import React, { useContext, useState } from "react";
import cn from "classnames";
import { PropertyList } from "../PropertyList";
import { PORTAL_WIDGET_PROPERTIES } from "components/ui/PortalBuilder/utils";
import { PropertyListItemTitle } from "../PropertyList/PropertyListItemTitle";
import { WidgetType } from "components/ui/PortalBuilder/types";
import { PropertyListItem } from "../Property/PropertyListItem";
import { PageContext, PortalBuilderPropertiesContext, RowContext } from "../../contexts";
import { getRowErrors } from "../../utils/validation";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";

export const AccordionItemProperty = (props) => {
    const { index, value = {}, onChange, onRemove, onChangeOrderUp, onChangeOrderDown, listLength, errors } = props;
    const [isExpanded, setIsExpanded] = useState(false);
    const isHidden = value.hidden === true;
    const type = WidgetType.ACCORDION_ITEM;
    const { sectionTitle, selectedColumn } = useContext(PortalBuilderPropertiesContext);
    const { row } = useContext(RowContext);
    const { page } = useContext(PageContext);
    const [activeError] = usePortalBuilderState((state) => state.activeError);
    const title = value?.headerText ?? "Accordion Item #" + (index + 1);
    const expandTitle = isExpanded ? "Hide Item Properties" : "Show Item Properties";

    let itemErrors;

    const containsActiveError =
        activeError?.pageIndex === page?.index &&
        activeError?.rowIndex === index &&
        activeError?.columnIndex === selectedColumn &&
        activeError?.id?.includes(`accordion[${index}]`);

    if (page) {
        itemErrors = getRowErrors(
            errors,
            `content.pages[${page.index}].components[${row.index}].components[${selectedColumn}].props.accordion[${index}].props.`
        );
    } else if (sectionTitle === "Header") {
        itemErrors = getRowErrors(errors, `header.components[${row.index}].components[${selectedColumn}].props.accordion[${index}].props.`);
    } else if (sectionTitle === "Footer") {
        itemErrors = getRowErrors(errors, `footer.components[${row.index}].components[${selectedColumn}].props.accordion[${index}].props.`);
    }

    if (containsActiveError && !isExpanded) {
        setIsExpanded(true);
    }

    return (
        <PropertyListItem
            className={cn("property-list-item", {
                "property-list-item": isExpanded,
            })}
        >
            <PropertyListItemTitle
                title={title}
                isExpanded={isExpanded}
                expandTitle={expandTitle}
                onRemove={onRemove}
                removeActionTitle="Delete Item"
                onExpand={() => setIsExpanded((prev) => !prev)}
                onChangeOrderDown={onChangeOrderDown}
                onChangeOrderUp={onChangeOrderUp}
                index={index}
                listLength={listLength}
                toggleValue={isHidden}
                type={type}
            />
            <PropertyList
                errors={itemErrors}
                parentTitle={title}
                items={PORTAL_WIDGET_PROPERTIES[type]}
                nestingLevel={2}
                config={value}
                onChange={onChange}
                isExpanded={isExpanded}
            />
        </PropertyListItem>
    );
};
