import React, { useCallback, memo } from "react";
import { openCalculationAssignmentModal, getEventKey } from "../utils";
import AssociationsList from "../../../../../AssociationsList";
import { store } from "../../../../../../../store/configureStore";
import { setCalculationAssociations } from "../../../../../../../store/associations/actions";
import { isChildProgram } from "components/views/ProgramView/utils";
import { systemUserRights } from "components/utils/user";
import { useProgramRights } from "store/resources/useResource";

const Associations = memo(({ utilityNumber, programNumber, eventNumber, calculationAssociations, onSelectCalculation }) => {
    const [programRights = []] = useProgramRights({ programNumber });
    const canEditCalculations = programRights.includes(systemUserRights.VISIONDSM_ADD_CALCULATION);

    const entityKey = getEventKey({ programNumber, eventNumber });
    const isLocked = isChildProgram({ programNumber }) || !canEditCalculations;

    const calculationAssociationsSorted = calculationAssociations.sort((a, b) => {
        return a.calculationName.localeCompare(b.calculationName);
    });

    const onEdit = useCallback(() => {
        openCalculationAssignmentModal({
            utilityNumber,
            programNumber,
            eventNumber,
            selectedItems: calculationAssociations,
            withSelect: true,
        });
    }, [utilityNumber, programNumber, eventNumber, calculationAssociations]);

    const onRemoveCalculation = useCallback(
        (item) => {
            const newAssociations = calculationAssociations.filter((a) => a.calculationNumber !== item.calculationNumber);
            store.dispatch(
                setCalculationAssociations({
                    key: entityKey,
                    calculationAssociations: newAssociations,
                })
            );
        },
        [entityKey, calculationAssociations]
    );

    return (
        <div className={"program-catalog__associations"}>
            <AssociationsList
                disabled={isLocked}
                headerText="Event Calculation Assignment"
                headerIcon="assignment"
                onEdit={onEdit}
                onRemove={onRemoveCalculation}
                onItemClick={onSelectCalculation}
                list={calculationAssociationsSorted}
                displayProperty="calculationName"
            />
        </div>
    );
});

export default Associations;
