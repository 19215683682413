import React from "react";
import { useUtility } from "../../../store/resources/useResource";
import WaitIcon from "../WaitIcon";

const UtilityName = ({ utilityNumber }) => {
    const [utility, isLoading] = useUtility({ utilityNumber, forced: false });

    if (isLoading) {
        return <WaitIcon />;
    }

    return utility ? utility.utility : null;
};

export default UtilityName;
