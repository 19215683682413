import * as actionType from "../actionTypes";
import { setFilter, setSort, getData, setPage } from "./actions";
import { downloadCSV } from "../../components/utils/CSV";
import { getServerTimezoneOffset, getUserTimezoneOffset } from "../../components/utils/date";
import { getClientRowsPage, getClientRows, sortRows, filterRows, getDataGridDataForExport } from "components/utils/datagrid";
import { isAdjustTimeForTimezoneEnabled } from "components/utils/settings";

export function dataGridMiddleware({ dispatch, getState }) {
    return (next) => (action) => {
        switch (action.type) {
            case actionType.DATA_GRID_CONSTRUCT:
                onContruct(action);
                break;
            case actionType.DATA_GRID_GET_DATA:
                onGetData(action);
                break;
            case actionType.DATA_GRID_CLIENT_GET_DATA_SUCCESS:
            case actionType.DATA_GRID_CLIENT_PAGINATE:
            case actionType.DATA_GRID_CLIENT_FILTER:
            case actionType.DATA_GRID_TREE_EXPAND_CHANGE:
                addTimezoneOffset(action);
                break;
            default:
                break;
        }

        next(action);

        switch (action.type) {
            case actionType.DATA_GRID_CONSTRUCT_SUCCESS:
                onContructSuccess(action);
                break;
            case actionType.DATA_GRID_GET_DATA_SUCCESS:
                onGetDataSuccess(action);
                break;
            case actionType.DATA_GRID_GET_DATA_ERROR:
                onGetDataError(action);
                break;
            case actionType.DATA_GRID_GET_DATA_EXPORT:
            case actionType.DATA_GRID_CLIENT_GET_DATA_EXPORT:
                onGetDataExportSuccess(action);
                break;
            default:
                break;
        }
    };

    async function onContruct(action) {
        const { dataGridId } = action.passThroughData;
        const grid = getState().dataGrid[dataGridId];

        switch (grid.type) {
            case "client":
                action.type = actionType.DATA_GRID_CONSTRUCT_SUCCESS;
                action.data = { grid };
                break;
            default:
                action.type = actionType.API_GET_AUTHORIZED;
                break;
        }
    }

    async function onGetData(action) {
        const { dataGridId } = action.passThroughData;
        const grid = getState().dataGrid[dataGridId];

        switch (grid.type) {
            case "client":
                const filteredRows = getClientRows(grid);
                const pageOfRecords = getClientRowsPage(grid, filteredRows);

                action.type = action.actionTypes.response;
                action.data = {
                    grid: {
                        rows: pageOfRecords,
                    },
                };
                break;
            default:
                action.type = actionType.API_GET_AUTHORIZED;
                break;
        }
    }

    async function onContructSuccess(action) {
        const { dataGridId, filter, sort, page, data, onSuccess, onError, filterRequired, initialFilter } = action.passThroughData;
        if (filter) {
            dispatch(setFilter({ dataGridId, filter, setAsDefault: !initialFilter }));
        }

        if (sort) {
            dispatch(setSort({ dataGridId, sort }));
        }

        if (page) {
            dispatch(setPage({ dataGridId, page }));
        }
        if (data && (!filterRequired || filter)) {
            dispatch(getData({ dataGridId, onSuccess, onError }));
        } else {
            onSuccess && onSuccess();
        }
    }

    async function onGetDataSuccess(action) {
        const { onSuccess } = action.passThroughData || {};

        onSuccess && onSuccess(action);
    }

    async function onGetDataError(action) {
        const { onError } = action.passThroughData || {};

        onError && onError(action);
    }

    async function onGetDataExportSuccess(action) {
        const { data } = action;
        const { dataGridId, fileName, fileNamePostfix, columnsKeys } = action.passThroughData;
        const grid = getState().dataGrid[dataGridId];

        if (grid) {
            let columns = grid.columns.filter((i) => i.active === true && i.hidecolumn !== "true");

            if (columnsKeys) {
                columns = columns.filter((i) => columnsKeys.includes(i.key));
            }

            let rows = data?.grid?.rows ?? [];

            if (action.type === actionType.DATA_GRID_CLIENT_GET_DATA_EXPORT) {
                const filteredSourceRows = filterRows({
                    filter: grid.filter,
                    rows,
                    columns: grid.columns,
                });
                rows = sortRows({ sort: grid.sort, rows: filteredSourceRows });
                rows = rows.slice(0, 1000);
            }

            downloadCSV({
                data: getDataGridDataForExport(rows, columns),
                fileName,
                fileNamePostfix,
            });
        }
    }

    // Add timezone info to be used inside reducer.
    function addTimezoneOffset(action) {
        action.timezoneOffset = {
            isEnabled: isAdjustTimeForTimezoneEnabled(),
            local: getUserTimezoneOffset(),
            server: getServerTimezoneOffset(),
        };
    }
}
