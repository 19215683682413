import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { onRowGroupExpandChange } from "store/dataGrid/actions";

import GridListGroupRow from "./GridListGroupRow";
import GridListRow from "./GridListRow";

import "./style.scss";
const GridListGroup = (props) => {
    const { groupName, rows, columns, groupRender, groupRowsCount, dataGridId } = props;
    const expandedByDefault = (groupRowsCount === 1 || groupRowsCount === 0) ?? false;
    const isExpanded = useSelector((state) => state.dataGrid[dataGridId]?.rowGroups?.[groupName]) ?? expandedByDefault;
    const dispatch = useDispatch();

    const onGroupExpandChange = () => {
        dispatch(
            onRowGroupExpandChange({
                dataGridId,
                groupName,
                expanded: !isExpanded,
            })
        );
    };

    return (
        <React.Fragment key={groupName}>
            <GridListGroupRow
                groupName={groupName}
                rows={rows}
                expanded={isExpanded}
                groupRender={groupRender}
                onClick={onGroupExpandChange}
                dataGridId={dataGridId}
            />
            {isExpanded &&
                rows.map((row, index) => <GridListRow key={index} className="group-row" row={row} index={index} columns={columns} />)}
        </React.Fragment>
    );
};

export default GridListGroup;
