import React, { useCallback, useContext, useMemo, memo } from "react";
import { Draggable } from "react-beautiful-dnd";
import cn from "classnames";
import { isEmpty } from "lodash";

import { useId } from "../../../utils/hooks/useId";
import { WorkflowContext } from "../../../context/WorkflowContext";
import { isValidStrings } from "../../../utils/validation";
import { WORKFLOW } from "../../../constants/workflow";
import { STEP_TYPES, STEP_TYPES_KEYS } from "../../../constants/step-types";
import { useResource } from "../../../../../../store/resources/useResource";
import { STEP_TYPES_ATTRIBUTES } from "../../../constants/step-types";
import { pad } from "components/utils/string";

import IconWrap from "../../../../Icons";
import StatusMark from "../../../../StatusMark";
import DragHandle from "../../../../DragHandle";
import { ErrorMessage } from "../../../../Message";

import "./Step.scss";

const StepCells = memo(({ isConfirmed, id, name, typeName, term, isActive, isSelected, assignments }) => {
    if (isConfirmed) {
        const validatedName = isValidStrings(name) ? name : <ErrorMessage>Please enter valid step title</ErrorMessage>;

        const validatedTerm = isValidStrings(term) ? term : <ErrorMessage>Term is required</ErrorMessage>;

        const groups = assignments && assignments.length !== 0 ? assignments.map((i) => i.groupName).join(", ") : "-";

        return (
            <>
                {[isActive ? "Active" : "Disabled", validatedName, id, validatedTerm, typeName, groups].map((cell, index) => (
                    <div className="wf__step-main-cell" key={index}>
                        {cell}
                    </div>
                ))}
            </>
        );
    }

    if (!isSelected) {
        return <ErrorMessage>Step creation is not completed yet</ErrorMessage>;
    }

    if (!isConfirmed) {
        return <div className="wf__step-main--step-placeholder flex-one">new step placeholder</div>;
    }

    return null;
});

export const Step = memo(
    ({
        id,
        isConfirmed = true,
        number,
        editStatus,
        statusNumber,
        newStatus,
        index,
        name,
        typeNumber,
        typeName,
        term,
        statusID,
        constraintRule,
        autoCompleteRule,
        assignments,
        attributes,
        onSetActiveItem,
    }) => {
        const { activeItem, onDeleteWorkflowStep, stepTypesGetResourceOptions, stepAttributeTypesGetResourceOptions } =
            useContext(WorkflowContext);

        const [workflowStepTypes = [], isLoadingWorkflowStepTypes] = useResource(stepTypesGetResourceOptions);
        const [workflowStepAttributeTypes = [], isLoadingWorkflowStepAttributeTypes] = useResource(stepAttributeTypesGetResourceOptions);

        const stepTypeCode = useMemo(() => {
            if (!isLoadingWorkflowStepTypes) {
                return workflowStepTypes.find((i) => i.number === typeNumber)?.code;
            }

            return null;
        }, [typeNumber, workflowStepTypes, isLoadingWorkflowStepTypes]);

        const isGeneralProcedure = useMemo(() => {
            if (stepTypeCode && STEP_TYPES.hasOwnProperty(stepTypeCode)) {
                return STEP_TYPES[stepTypeCode].key === STEP_TYPES_KEYS.GENERAL_PROCEDURE;
            }

            return false;
        }, [stepTypeCode]);

        const generalProcedureName = useMemo(() => {
            if (isGeneralProcedure) {
                if (!isLoadingWorkflowStepAttributeTypes) {
                    const attribute = workflowStepAttributeTypes.find((i) => i.code === STEP_TYPES_ATTRIBUTES.STORED_PROCEDURE);

                    if (attribute) {
                        return attributes.find((i) => i.typeNumber === attribute.number)?.value;
                    }
                }
            }
        }, [isGeneralProcedure, attributes, workflowStepAttributeTypes, isLoadingWorkflowStepAttributeTypes]);

        const isSelected = useMemo(
            () =>
                activeItem &&
                activeItem.statusNumber === statusNumber &&
                activeItem.stepNumber === number &&
                activeItem.type === WORKFLOW.STEP,
            [activeItem, statusNumber, number]
        );

        const isActive = useMemo(() => statusID === WORKFLOW.ACTIVE_STEP_ID, [statusID]);

        const isWithConstraintRule = useMemo(() => isValidStrings(constraintRule), [constraintRule]);

        const isWithAutoCompleteRule = useMemo(() => {
            return !isEmpty(newStatus) && isValidStrings(autoCompleteRule);
        }, [newStatus, autoCompleteRule]);

        const draggableId = useId(number);

        const handleClickDelete = useCallback(
            (e) => onDeleteWorkflowStep(e, statusNumber, number),
            [onDeleteWorkflowStep, statusNumber, number]
        );

        const isError = (!isConfirmed && !isSelected) || newStatus === statusNumber;

        return (
            <Draggable draggableId={draggableId} index={index}>
                {(dragProvided) => (
                    <div
                        className={cn("wf__step", {
                            "wf__step--selected": isSelected,
                            "wf__step--disabled": !isActive,
                            "wf__step--error": isError,
                            "flex-column justify-center": !isConfirmed,
                            "wf__step--has-rules": isWithConstraintRule || isWithAutoCompleteRule,
                        })}
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                    >
                        {isConfirmed && (
                            <div className="wf__step-head flex-row">
                                <div className="wf__step-number"></div>
                                <div className="flex-one flex-row">
                                    <div className="wf__step-head-cell"></div>
                                    <div className="wf__step-head-cell">Name</div>
                                    <div className="wf__step-head-cell">Step ID</div>
                                    <div className="wf__step-head-cell">Term</div>
                                    <div className="wf__step-head-cell">Type</div>
                                    <div className="wf__step-head-cell">Assigned Groups</div>
                                </div>
                            </div>
                        )}
                        {isConfirmed && (
                            <IconWrap
                                iconWrapSuccess={isActive}
                                iconWrapNeutral={!isActive}
                                icon={isActive ? "radio-button-checked" : "do-not-disturb-on-remove-circle-empty"}
                            />
                        )}
                        {
                            <div
                                className={cn("view-headline-list-text", "wf__step-main flex-row align-center", {
                                    "wf__step-main--not-confirmed flex-one": !isConfirmed,
                                    "wf__step-main--selected": isSelected,
                                })}
                                onClick={(e) => onSetActiveItem(e, number, WORKFLOW.STEP, statusNumber)}
                            >
                                <div
                                    className={cn("wf__step-number flex-column justify-center", {
                                        "not-confirmed": !isConfirmed,
                                        error: isError,
                                        selected: isSelected,
                                        disabled: !isActive,
                                    })}
                                >
                                    Step
                                    <span>{pad(index + 1, 2)}</span>
                                </div>
                                <div className="flex-one flex-row">
                                    <StepCells
                                        id={id}
                                        name={name}
                                        typeName={
                                            isGeneralProcedure ? (
                                                <div className="general-procedure flex-column">
                                                    {typeName}{" "}
                                                    <StatusMark statusMarkWithLineBreak statusMarkNeutral>
                                                        {generalProcedureName}
                                                    </StatusMark>
                                                </div>
                                            ) : (
                                                typeName
                                            )
                                        }
                                        term={term}
                                        isActive={isActive}
                                        isConfirmed={isConfirmed}
                                        isSelected={isSelected}
                                        assignments={assignments}
                                    />
                                </div>
                                <div className={"wf__step-actions flex-row" + (!isConfirmed ? " not-confirmed" : "")}>
                                    <IconWrap
                                        iconWrapTheme={isSelected}
                                        icon={isSelected ? "shevron-in-circle-left-filled" : "shevron-in-circle-right-empty"}
                                        title={isSelected ? "Hide Step Properties" : "Show Step Properties"}
                                        onClick={(e) => onSetActiveItem(e, number, WORKFLOW.STEP, statusNumber)}
                                    />
                                    {editStatus === WORKFLOW.ITEM_EDIT_STATUSES.IS_CREATED && isConfirmed && (
                                        <IconWrap icon="delete-trash-empty" title="Delete Step" onClick={handleClickDelete} />
                                    )}
                                    <DragHandle dragVertical {...dragProvided.dragHandleProps} />
                                </div>
                            </div>
                        }
                        {isSelected && (isWithConstraintRule || isWithAutoCompleteRule) && (
                            <div className={"wf__step-rules flex-row" + (isWithConstraintRule && isWithAutoCompleteRule ? " two-col" : "")}>
                                {isWithConstraintRule && (
                                    <div className="flex-column flex-one align-center">
                                        <span>Constraint Rule:</span>
                                        <span>{constraintRule}</span>
                                    </div>
                                )}
                                {isWithAutoCompleteRule && (
                                    <div className="flex-column flex-one align-center">
                                        <span>Status Change Rule:</span>
                                        <span>{autoCompleteRule}</span>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </Draggable>
        );
    }
);
