import React, { useCallback } from "react";
import { PropertyListItemTitle } from "../PropertyList/PropertyListItemTitle";
import { PropertyList } from "../PropertyList";
import { PropertyListItem } from "../Property/PropertyListItem";

import "./NotificationTypeProperty.scss";

export const NotificationProperty = (props) => {
    const { title, titleSwitch, propertiesGroup = [], value = [], nestingLevel, onChange } = props;

    const enableNotification = props.titleSwitchValue ?? titleSwitch.defaultValue;

    const onClick = useCallback(() => {
        const nextState = !enableNotification;
        onChange(titleSwitch?.id, nextState);
    }, [titleSwitch?.id, onChange, enableNotification]);

    return (
        <PropertyListItem className="property-list-item--prop-group property-list-item--notification">
            <PropertyListItemTitle
                title={title}
                toggleTooltip={!enableNotification ? "Switch ON" : "Switch OFF"}
                onToggle={onClick}
                toggleValue={!enableNotification}
            />
            <PropertyList
                items={propertiesGroup}
                nestingLevel={nestingLevel + 1}
                config={value}
                onChange={onChange}
                isExpanded={enableNotification}
            />
        </PropertyListItem>
    );
};
