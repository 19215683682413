import React, { useState, useEffect, useCallback, useMemo, memo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isNil } from "lodash";

import { createResource } from "../../../../../store/resources/actions";
import { getData } from "../../../../../store/dataGrid/actions";
import { getResourcePromise } from "../../../../../store/resources/useResource";
import { listToAnyOf, submitByRef } from "../../../../utils/form";
import { hasPermission, systemUserRights } from "../../../../utils/user";

import JsonSchemaForm from "../../JsonSchema/JsonSchemaForm";
import WaitIcon from "../../../WaitIcon";
import Button from "../../../Button";
import StatusMsg from "../../../StatusMsg";

import SideNavBody from "../../../SideNav/SideNavBody";
import SideNavFooter from "../../../SideNav/SideNavFooter";
import SideNavHeader from "../../../SideNav/SideNavHeader";
import SideNavContent from "../../../SideNav/SideNavContent";

const AuthenticatedProgramCopyForm = memo((props) => {
    const user = useSelector((state) => state.user);

    if (!hasPermission(user, systemUserRights.VISIONDSM_COPY_PROGRAM)) {
        return;
    }

    return <ProgramCopyForm {...props} />;
});

const ProgramCopyForm = memo(({ utilityNumber, gridId, onClose, sidePanel }) => {
    const formRef = useRef();

    const handleSave = useCallback(() => {
        submitByRef(formRef);
    }, []);

    const dispatch = useDispatch();
    const utilities = useSelector((state) => state.resources.utilities.itemsById["utilities"]) || [];

    const [programs, setPrograms] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoadingPrograms, setIsLoadingPrograms] = useState(false);
    const [isSameUtility, setIsSameUtility] = useState(false);

    const loadAllPrograms = useCallback(async () => {
        if (!programs || programs.length === 0) {
            setIsLoadingPrograms(true);

            const utilityPromise = await getResourcePromise({
                resourceName: "utilities",
                key: "utilities",
            });

            const programPromises = utilityPromise
                .filter((u) => u.utilityNumber === utilityNumber || isNil(utilityNumber))
                .map(async (util) => {
                    let utilNumber = util.utilityNumber;

                    const utilPrograms = await getResourcePromise({
                        resourceName: "programs",
                        key: utilNumber,
                        query: {
                            utilityNumber: utilNumber,
                        },
                    });

                    let utilProgramList =
                        (utilPrograms &&
                            utilPrograms.programList &&
                            utilPrograms.programList.map((i) => ({
                                title: util.utility + ": " + i.program,
                                utilityNumber: util.utilityNumber,
                                programNumber: i.programNumber,
                                enum: [i.programNumber],
                            }))) ||
                        [];

                    return utilProgramList;
                });

            Promise.all(programPromises).then((res) => {
                setPrograms(res.reduce((acc, next) => (acc = acc.concat(next)), []));
                setIsLoadingPrograms(false);
            });
        }
    }, [utilityNumber, programs]);

    useEffect(() => {
        loadAllPrograms();
    }, [loadAllPrograms]);

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    const propertiesToCopy = useMemo(
        () => ({
            budget: "Budgets",
            goals: "Goals",
            workflow: "Workflow",
            catalog: "Catalog",
            forms: "Forms",
            content: "Content",
            docs: "Documents",
            files: "Files",
            users: "User Assignments",
            events: "Events",
            calculations: "Calculations",
        }),
        []
    );

    const getPropertiesList = useMemo(() => {
        return Object.keys(propertiesToCopy).map((i) => ({
            title: propertiesToCopy[i],
            enum: [i],
        }));
    }, [propertiesToCopy]);

    const required = ["program", "targetUtilityNumber", "name"];

    const schema = {
        type: "object",
        required,
        properties: {
            program: {
                type: "string",
                title: "Select Existing Program",
            },
            targetUtilityNumber: {
                type: "string",
                title: "Select Target Utility",
                anyOf: listToAnyOf({
                    list: utilities,
                    map: (i) => ({
                        title: i.utility,
                        enum: [i.utilityNumber],
                    }),
                }),
            },
            name: {
                type: "string",
                title: "New Program Name",
            },
            properties: {
                type: "array",
                title: "Information to copy",
                uniqueItems: true,
                items: {
                    type: "string",
                    anyOf: getPropertiesList,
                },
            },
        },
    };

    const uiSchema = {
        program: {
            "ui:options": {
                placeholder: isLoadingPrograms ? "Loading..." : "-- SELECT --",
            },
            "ui:disabled": isLoadingPrograms,
            "ui:widget": "select",
            "ui:enumItems": programs.map((i) => ({
                label: i.title,
                value: i.enum[0],
            })),
        },
        targetUtilityNumber: {
            "ui:options": {
                placeholder: "-- SELECT --",
            },
        },
        name: {
            classNames: "program-name",
        },
        properties: {
            "ui:widget": "checkboxes",
            "ui:enumDisabled": Object.keys(propertiesToCopy)
                .filter((i) => isSameUtility && i === "calculations")
                .map((i) => ({
                    value: i,
                    tooltip: "Cacluations cannot be copied to the same utility",
                })),
        },
    };

    const onSuccess = useCallback(() => {
        sidePanel.close();

        dispatch(
            getData({
                dataGridId: gridId,
            })
        );
    }, [gridId, dispatch, sidePanel]);

    const handleSubmit = useCallback(
        (formData) => {
            setIsSubmitting(true);

            const programNumber = formData.program;

            var selectedProperties = {};

            if (formData.properties) {
                Object.keys(formData.properties).forEach((key) => {
                    if (formData.properties[key] !== "calculations" || (formData.properties[key] === "calculations" && !isSameUtility)) {
                        selectedProperties[formData.properties[key]] = true;
                    }
                });
            }

            dispatch(
                createResource({
                    resourceName: "programCopy",
                    path: {
                        programNumber,
                    },
                    body: {
                        ...formData,
                        ...selectedProperties,
                    },
                    onSuccess,
                })
            );
        },
        [isSameUtility, onSuccess, dispatch]
    );

    const handleChange = useCallback(
        ({ formData }) => {
            if (formData.program && formData.targetUtilityNumber) {
                setIsSameUtility(
                    programs.find((i) => i.programNumber === formData.program)?.utilityNumber === formData.targetUtilityNumber
                );
            }
        },
        [programs]
    );

    return (
        <SideNavContent>
            <SideNavHeader title="Copy Existing Program" leadBlockIcon="layers-empty" smallHeader onClose={onClose} />
            <SideNavBody className="flex-one-in-column">
                {isSubmitting ? (
                    <>
                        <WaitIcon />
                        <div className="flex-row flex-one justify-center">
                            <StatusMsg msgInfo msgText={"Program is being created. It may take some time to copy all the data..."} />
                        </div>
                    </>
                ) : (
                    <JsonSchemaForm
                        formRef={formRef}
                        schema={schema}
                        uiSchema={uiSchema}
                        onSubmit={handleSubmit}
                        onChange={handleChange}
                        disabled={isSubmitting}
                        noActions
                    />
                )}
            </SideNavBody>
            {!isSubmitting && (
                <SideNavFooter setPrimaryButton>
                    <Button primary onClick={handleSave} disabled={isSubmitting}>
                        Copy
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </SideNavFooter>
            )}
        </SideNavContent>
    );
});

export default AuthenticatedProgramCopyForm;
