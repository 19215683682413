import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { pickBy } from "lodash";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import WaitIcon from "../../../../WaitIcon";
import GridDetailsFooterActions from "../../../../DataGrid/GridDetailsFooterActions";
import { budgetLineFormSchema, useBudgetLineCategories } from "./utils";
import { useBudgetLine } from "../../../../../../store/resources/useResource";
import { submitResource, submitByRef } from "../../../../../utils/form";
import Button from "../../../../Button";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";

const Form = (props) => {
    const formRef = useRef();

    const { utilityNumber, contractNumber, dataItem, addChild, onClose, gridRefresh, sidePanel } = props;

    const isNew = dataItem === undefined;
    const isCreateForm = isNew || addChild;

    const resourceId = isCreateForm ? null : dataItem.budgetLineNumber;

    const parentId = isNew ? null : addChild ? dataItem.budgetLineNumber : dataItem.parentBudgetLineNumber;

    const title = isNew ? "Add Budget Line Item" : addChild ? "Add Child Budget Line Item" : "Edit Budget Line Item";

    const titleIcon = isNew ? "plus" : addChild ? "plus" : "edit-empty";

    const [isSubmitting, setSubmitting] = useState(false);

    const [resource, isLoadingResource] = useBudgetLine({
        utilityNumber,
        contractNumber,
        budgetLineNumber: resourceId,
    });
    const [categories = [], isLoadingCategories] = useBudgetLineCategories({
        utilityNumber,
        contractNumber,
    });

    const schema = budgetLineFormSchema(categories);

    const uiSchema = {
        note: {
            classNames: "fill-width",
            "ui:widget": "textarea",
        },
    };

    const initialValues = isCreateForm ? {} : pickBy(resource);

    const submitText = isSubmitting ? "Saving..." : "Save";

    const onSubmit = (formData) => {
        const resourceParams = {
            resourceName: "utilitiesContractsBudgetLines",
            path: {
                utilityNumber,
                contractNumber,
            },
        };

        const body = {
            ...formData,
            parentBudgetLineNumber: parentId,
        };

        submitResource({
            resourceParams,
            resourceId,
            body,
            onRefresh: gridRefresh,
            onSuccess: sidePanel.close,
            setSubmitting,
        });
    };

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    if (isLoadingResource || isLoadingCategories) {
        return <WaitIcon />;
    }

    const otherActions = isCreateForm ? null : (
        <>
            <span className="flex-one" />
            <GridDetailsFooterActions {...props} />
        </>
    );

    return (
        <SideNavContent>
            <SideNavHeader title={title} leadBlockIcon={titleIcon} smallHeader onClose={onClose} />
            <SideNavBody className="flex-one-in-column">
                <JsonSchemaForm
                    formRef={formRef}
                    schema={schema}
                    uiSchema={uiSchema}
                    initialValues={initialValues}
                    disabled={isSubmitting}
                    onSubmit={onSubmit}
                    onCancel={onClose}
                    submitText={submitText}
                    otherActions={otherActions}
                    noReset
                    noActions
                />
            </SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button primary onClick={() => submitByRef(formRef)} disabled={isSubmitting}>
                    {submitText}
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
};

export default connect()(Form);
