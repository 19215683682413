import React from "react";

import Buttons from "./GroupsOfComponents/Buttons";
import TextFields from "./GroupsOfComponents/TextFields";
import Controls from "./GroupsOfComponents/Controls";
import Inputs from "./GroupsOfComponents/Inputs";
import Dropdowns from "./GroupsOfComponents/Dropdowns";
import Typography from "./GroupsOfComponents/Typography";
import Additional from "./GroupsOfComponents/Additional";
import StatusesAndMarks from "./GroupsOfComponents/StatusesAndMarks";

import "./style.scss";

class UILibrary extends React.Component {
    render() {
        return (
            <div className="main-grid-wrap ui-library responsive">
                <div className="ui-library-group">
                    <div className="ui-library-title">Inputs</div>
                    <Inputs></Inputs>
                </div>
                <div className="ui-library-group">
                    <div className="ui-library-title">Textfield</div>
                    <TextFields></TextFields>
                </div>
                <div className="ui-library-group">
                    <div className="ui-library-title">Controls</div>
                    <Controls></Controls>
                </div>
                <div className="ui-library-group">
                    <div className="ui-library-title">Buttons</div>
                    <Buttons></Buttons>
                </div>
                <div className="ui-library-group">
                    <div className="ui-library-title">Dropdowns</div>
                    <Dropdowns></Dropdowns>
                </div>
                <div className="ui-library-group">
                    <div className="ui-library-title">Typography</div>
                    <Typography></Typography>
                </div>
                <div className="ui-library-group">
                    <div className="ui-library-title">Statuses and Marks</div>
                    <StatusesAndMarks></StatusesAndMarks>
                </div>
                <div className="ui-library-group">
                    <div className="ui-library-title">Additional</div>
                    <Additional></Additional>
                </div>
            </div>
        );
    }
}

export default UILibrary;
