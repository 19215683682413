import React, { memo } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const GridListRowContainer = memo(({ draggableRows, children, onDragEnd }) => {
    if (draggableRows) {
        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div className="grid-list-row-container" ref={provided.innerRef} {...provided.droppableProps}>
                            {children}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }

    return <div className="grid-list-row-container">{children}</div>;
});

export default GridListRowContainer;
