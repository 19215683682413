import React, { memo, useMemo, useContext, useEffect, useCallback, useRef } from "react";

import WaitIcon from "../../../../WaitIcon";
import { getAttributeValue } from "../../../../../utils/workflow";
import { TaskWorkflowContext } from "../../../context/TaskWorkflowContext";
import { useApplicationFormDetails } from "store/resources/useResource";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import { ApplicationFormSidePanel } from "./ApplicationFormSidePanel";

export const ApplicationFormEntryTask = memo(({ applicationNumber, wfTaskNumber, task, onSubmitSuccess, onCancel, editMode, content }) => {
    const isPanelOpened = useRef(false);
    const { programNumber } = useContext(TaskWorkflowContext);
    const formRef = useRef();

    const applicationFormId = useMemo(() => getAttributeValue("Form Page Number", task), [task]);
    const formId = `task-workflow-${wfTaskNumber}-${applicationNumber}`;

    const [, isLoadingFormDetails] = useApplicationFormDetails({
        applicationNumber,
        applicationFormId,
        forced: true,
    });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.staticMedium,
        className: "application-form-side-panel",
    });

    const handleApplicationFormEntryTaskCancel = useCallback(() => {
        handleCloseSidePanel();
        onCancel();
    }, [handleCloseSidePanel, onCancel]);

    useEffect(() => {
        if (!isLoadingFormDetails && !isPanelOpened.current) {
            isPanelOpened.current = true;
            handleOpenSidePanel(
                <ApplicationFormSidePanel
                    formRef={formRef}
                    formId={formId}
                    programNumber={programNumber}
                    applicationNumber={applicationNumber}
                    content={content}
                    applicationFormId={applicationFormId}
                    loading={isLoadingFormDetails}
                    editMode={editMode}
                    taskNumber={wfTaskNumber}
                    onSubmitSuccess={() => {
                        handleCloseSidePanel();
                        onSubmitSuccess();
                    }}
                    onCancel={handleApplicationFormEntryTaskCancel}
                    noActions
                />,
                {
                    onClose: onCancel,
                }
            );
        }
    }, [
        formId,
        programNumber,
        applicationNumber,
        content,
        applicationFormId,
        isLoadingFormDetails,
        editMode,
        wfTaskNumber,
        onSubmitSuccess,
        onCancel,
        handleApplicationFormEntryTaskCancel,
        handleOpenSidePanel,
        handleCloseSidePanel,
    ]);

    if (isLoadingFormDetails) {
        return <WaitIcon />;
    }

    return null;
});
