import React, { useCallback, memo } from "react";
import cn from "classnames";

import { formatJsonDateTime, formatJsonDate } from "../../../../../utils/date";
import { formatMoney } from "../../../../../utils/money";
import { Localize } from "components/utils/text";

import "./RebatesListItem.scss";

const RebateDetails = memo(({ item }) => {
    const renderItem = useCallback((label, value, className) => {
        return (
            <div className={cn("item-field", className)}>
                <span className="item-label">{label}</span>
                <div className="item-value">{value}</div>
            </div>
        );
    }, []);

    return (
        <div className="rebate-sidebar-list-item flex-row fill-width">
            <div>{renderItem(`${Localize.CHECK} Date:`, formatJsonDate(item.checkDate, "-"))}</div>
            <div>{renderItem(`${Localize.CHECK} #:`, item.checkNumber ?? "-")}</div>
            <div>{renderItem(`${Localize.CHECK} Amount:`, formatMoney(item.checkAmount, "-"))}</div>
            <div>{renderItem("Invoice Date:", formatJsonDateTime(item.invoiceDate, "-"))}</div>
            <div>{renderItem("Invoice #:", item.invoiceNumber ?? "-")}</div>
            <div className={item.statusName}>{renderItem("Status:", item.statusName)}</div>
        </div>
    );
});

export default RebateDetails;
