import React, { useRef, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuidv1 from "uuid/v1";

import { createResource } from "../../../../../../store/resources/actions";
import { getData } from "../../../../../../store/dataGrid/actions";
import { submitByRef } from "../../../../../utils/form";

import Button from "../../../../Button";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavContent from "../../../../SideNav/SideNavContent";
import EquipmentDetails from "./EquipmentDetails";

const CloneEquipmentPanel = memo(({ equipmentId, applicationNumber, programNumber, onClose, sidePanel, onRowSelectClear }) => {
    const dispatch = useDispatch();

    const isMobile = useSelector((state) => state.window?.isMobile);

    const formRef = useRef();

    const title = "Clone Equipment";
    const leadBlockIcon = "opacity-filled";

    const onSubmit = (data) => {
        dispatch(
            createResource({
                resourceName: "equipment",
                path: {
                    appId: applicationNumber,
                },
                body: {
                    ...data,
                    equipID: uuidv1().toUpperCase(),
                },
                onSuccess: () => {
                    dispatch(
                        getData({
                            dataGridId: `${applicationNumber}-equipment-grid`,
                        })
                    );
                    sidePanel.close();
                    onRowSelectClear();
                },
            })
        );
    };

    const handleSubmit = () => {
        submitByRef(formRef);
    };

    const renderButtons = () => {
        return (
            <>
                <Button primary icon="plus" onClick={handleSubmit}>
                    Add Equipment
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </>
        );
    };

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    return (
        <SideNavContent className="clone-equipment-panel">
            {isMobile && <SideNavHeader title={title} leadBlockIcon={leadBlockIcon} smallHeader onClose={onClose} />}
            <SideNavBody noPadding>
                <EquipmentDetails
                    equipmentId={equipmentId}
                    applicationNumber={applicationNumber}
                    programNumber={programNumber}
                    hideButtons
                    headerLeftAlignedTitle={title}
                    headerLeftAlignedIcon={leadBlockIcon}
                    hasHeaderLeftAligned
                    formRef={formRef}
                    onSubmit={onSubmit}
                    onClose={onClose}
                />
            </SideNavBody>
            <SideNavFooter justifyCenter={!isMobile} setPrimaryButton={isMobile}>
                {renderButtons()}
            </SideNavFooter>
        </SideNavContent>
    );
});

export default CloneEquipmentPanel;
