import React, { useCallback, memo } from "react";

import { openLookupAssignmentModal, openCalculationAssignmentModal, getCatalogKey, displayedProperty, useAttributes } from "../utils";
import { setCalculationAssociations, setApprovedMeasureAssociations } from "../../../../../../../store/associations/actions";
import { store } from "../../../../../../../store/configureStore";

import AssociationsList from "../../../../../AssociationsList";
import { isChildProgram } from "components/views/ProgramView/utils";

const Associations = memo(
    ({ utilityNumber, programNumber, catalogNumber, approvedMeasures, calculationAssociations, onSelectCalculation }) => {
        const entityKey = getCatalogKey({ programNumber, catalogNumber });
        const isLocked = isChildProgram({ programNumber });

        const attributes = useAttributes({ entityKey });

        const getSortedList = (list, key) => list.sort((a, b) => a[key].localeCompare(b[key]));

        const calculationAssociationsSorted = getSortedList(calculationAssociations, displayedProperty.calculation);
        const approvedMeasuresSorted = getSortedList(approvedMeasures, displayedProperty.industryMeasure);

        const onEditMeasures = useCallback(() => {
            openLookupAssignmentModal({
                programNumber,
                catalogNumber,
                selectedItems: approvedMeasures,
            });
        }, [programNumber, catalogNumber, approvedMeasures]);

        const onEditCalculations = useCallback(() => {
            openCalculationAssignmentModal({
                utilityNumber,
                programNumber,
                catalogNumber,
                selectedItems: calculationAssociations,
                attributes,
                hasCalcAssignment: calculationAssociations.length > 0,
                withSelect: true,
            });
        }, [utilityNumber, programNumber, catalogNumber, calculationAssociations, attributes]);

        const onRemoveCalculation = useCallback(
            (item) => {
                const newAssociations = calculationAssociations.filter((a) => a.calculationNumber !== item.calculationNumber);
                store.dispatch(
                    setCalculationAssociations({
                        key: entityKey,
                        calculationAssociations: newAssociations,
                    })
                );
            },
            [entityKey, calculationAssociations]
        );

        const onRemoveMeasure = useCallback(
            (item) => {
                const newAssociations = approvedMeasures.filter((a) => a.industryMeasureNumber !== item.industryMeasureNumber);
                store.dispatch(
                    setApprovedMeasureAssociations({
                        key: entityKey,
                        approvedMeasures: newAssociations,
                    })
                );
            },
            [entityKey, approvedMeasures]
        );

        return (
            <div className="program-catalog__associations">
                <AssociationsList
                    disabled={isLocked}
                    headerText="Approved Measure Lookup Assignment"
                    onEdit={onEditMeasures}
                    onRemove={onRemoveMeasure}
                    list={approvedMeasuresSorted}
                    displayProperty={displayedProperty.industryMeasure}
                />
                <AssociationsList
                    headerText="Catalog Calculation Assignment"
                    onEdit={onEditCalculations}
                    onRemove={onRemoveCalculation}
                    onItemClick={onSelectCalculation}
                    list={calculationAssociationsSorted}
                    displayProperty={displayedProperty.calculation}
                    readOnly={isLocked}
                />
            </div>
        );
    }
);

export default Associations;
