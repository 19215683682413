import React, { memo } from "react";
import { useDispatch } from "react-redux";

import { onRefreshAssignmentsList, onDeleteAssignement } from "../utils";
import { openProgramTab } from "store/window/openTabActions";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import List from "./List";

import "./style.scss";

const AssignmentsPanel = memo(({ panel }) => {
    const dispatch = useDispatch();
    const utilityNumber = panel.data.utilityNumber;
    const contractNumber = panel.data.contractNumber;
    const budgetLineNumber = panel.data.budgetLineNumber;

    const onRefresh = () => {
        onRefreshAssignmentsList({
            utilityNumber,
            contractNumber,
            budgetLineNumber,
            dispatch,
        });
    };

    const onOpen = (item) => {
        dispatch(
            openProgramTab({
                programNumber: item.programNumber,
            })
        );
    };

    const onDelete = (dataItem, assignedPrograms) => {
        onDeleteAssignement({
            utilityNumber,
            contractNumber,
            budgetLineNumber,
            dataItem,
            assignedPrograms,
            onRefresh,
            dispatch,
        });
    };

    return (
        <div className="panel budget-program-assignments flex-column fill-height">
            <PanelHeaderLarge centered title={panel.name} />
            <List
                utilityNumber={utilityNumber}
                contractNumber={contractNumber}
                budgetLineNumber={budgetLineNumber}
                onOpen={onOpen}
                onDelete={onDelete}
            />
        </div>
    );
});

export default AssignmentsPanel;
