import { useCallback, useEffect } from "react";
import { store } from "../../../store/configureStore";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import { cisSearchType } from "../../utils/constants";
import { modalOpen } from "../../../store/modal/actions";
import { updateResource, deleteResource } from "../../../store/resources/actions";
import { refreshApplication } from "../../../store/resources/refreshResource";
import { refreshContactLists } from "components/ui/Dashboard/Panel/Project/ContactsPanel/ContactsUtils";
import { CALCULATIONS_IN_PROGRESS_CHECK_TIMEOUT, checkCalculationStatus } from "store/projects/actions";
import { getResourcePromise } from "store/resources/useResource";
import { PROJECTS_SET_CALCULATIONS_IN_PROGRESS } from "store/actionTypes";
import { refreshWorkcenterGrid } from "../../../store/dataGrid/refreshGrid";
import { openAssignmentModal } from "../../ui/AssignmentSelector/utils";
import IconWithLabel from "../../ui/Icons/IconWithLabel";
import Label from "../../ui/Label";

export const FLAG_TEST_APPLICATION = 889;
export const FLAG_FREEZE_CALCULATIONS = 836;
export const FLAG_FREEZE_CORRESPONDENCE = 837;

export const getApplicationLabel = (application) => {
    return [application.projectName, application.projectNumber, application.customerName].filter((i) => !isEmpty(i)).join(" ");
};

export const getApplicationCisSearchType = ({ applicationNumber }) => {
    return store.getState().resources.applicationDetails?.itemsById[applicationNumber]?.cisLookupMethod ?? cisSearchType.AccountNumber;
};

export const isApplicationLocked = ({ applicationNumber }) => {
    return store.getState().resources.applicationDetails?.itemsById[applicationNumber]?.isLocked ?? false;
};

export const isExtendedRebateApproval = ({ applicationNumber }) => {
    return store.getState().resources.applicationDetails?.itemsById[applicationNumber]?.useExtendedRebate ?? false;
};

export const rebatesUseVendorProgram = ({ applicationNumber }) => {
    return store.getState().resources.applicationDetails?.itemsById[applicationNumber]?.rebatesUseVendorProgram ?? false;
};

export const isApplicationTestApplication = ({ applicationNumber }) => {
    const flags = store.getState().resources.applicationFlags?.itemsById[applicationNumber];

    return flags && flags.find((i) => i.id === FLAG_TEST_APPLICATION);
};

export const isApplicationFrozenCalculations = ({ applicationNumber }) => {
    const flags = store.getState().resources.applicationFlags?.itemsById[applicationNumber];

    return flags && flags.find((i) => i.id === FLAG_FREEZE_CALCULATIONS);
};

export const isApplicationFrozenCorrespondence = ({ applicationNumber }) => {
    const flags = store.getState().resources.applicationFlags?.itemsById[applicationNumber];

    return flags && flags.find((i) => i.id === FLAG_FREEZE_CORRESPONDENCE);
};

export const takeContractorsFromList = ({ applicationNumber }) => {
    return store.getState().resources.applicationDetails?.itemsById[applicationNumber]?.useContractorList ?? false;
};

export const useCanRejectRebate = ({ applicationNumber }) => {
    return useSelector((state) => state.resources.applicationDetails?.itemsById[applicationNumber]?.rejectRebate);
};

export const useRequireContractor = ({ applicationNumber }) => {
    return useSelector((state) => state.resources.applicationDetails?.itemsById[applicationNumber]?.beRequireContractor);
};

export const useLimitRebateTypeList = ({ applicationNumber }) => {
    return (useSelector((state) => state.resources.applicationDetails?.itemsById[applicationNumber]?.limitRebateType) || "")
        .split(",")
        .filter((i) => !isEmpty(i))
        .map((i) => i.trim().toLowerCase());
};

export const useEquipmentCalculator = ({ applicationNumber }) => {
    return useSelector((state) => state.resources.applicationDetails?.itemsById[applicationNumber]?.useEquipmentCalculator) ?? false;
};

export const confirmApplicationStatusChange = ({ application, status, applicationNumber }) => {
    const text = (
        <p>
            This action will {application.status === status.status ? "re-apply application status" : "change application status to"}{" "}
            <strong>{status.status}</strong>. Are you sure you want to do this?
        </p>
    );

    store.dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: application.status === status.status ? "Re-apply selected Application Status" : "Change Application Status",
                overlayClassName: "modal-styled",
                className: "change-status-confirmation-modal modal-sm",
                modalIcon: application.status === status.status ? "repeat" : "",
                footerContentCenter: true,
                content: text,
                onConfirm: () => {
                    store.dispatch(
                        updateResource({
                            resourceName: "applicationStatus",
                            path: {
                                applicationNumber,
                            },
                            query: {
                                wfNumber: status.workflowNumber,
                            },
                            onSuccess: () => {
                                refreshApplication({ applicationNumber });
                                refreshContactLists({ applicationNumber });
                            },
                        })
                    );
                },
            },
        })
    );
};

export const openUserAssignmentModal = ({ applicationNumber, modalIcon, onClose }) => {
    const Header = ({ items = [], selectedItems = [], onAdd }) => {
        const myUserNumber = store.getState().user.userNumber;
        const onClick = () => {
            items.forEach(function (user) {
                let myUser = {};

                if (user.userNumber === myUserNumber) {
                    myUser = user;
                    onAdd(myUser);
                }
            });
        };

        const isDisabled =
            selectedItems.some((user) => user.userNumber === myUserNumber) || !items.some((user) => user.userNumber === myUserNumber);

        const availableListLabelText = "Choose Users to Assign";
        const selectedListLabelText = "Assigned Users";

        return (
            <>
                <div className="user-assignments-header">
                    <div className="flex-row">
                        <div className="flex-row flex-one">
                            <Label>{availableListLabelText}</Label>
                            <IconWithLabel icon="location-user-empty" disabled={isDisabled} onClick={onClick}>
                                Assign to Self
                            </IconWithLabel>
                        </div>
                        <div className="flex-row justify-end">
                            <Label>
                                {selectedListLabelText} ({selectedItems.length})
                            </Label>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const resourceDataPath = "data";
    const title = "Application User Assignments";
    const submitButtonText = "Apply";
    const idKey = "userNumber";
    const nameKey = "name";

    const resourceGetParams = {
        transform: (data) =>
            (data ?? []).map((item) => ({
                ...item,
                assigned: item.isAssigned,
            })),
        resourceName: "applicationToolsAssignments",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
    };

    const onRefresh = () => {
        refreshWorkcenterGrid();
        onClose && onClose();
    };

    const onSelect = (selectedItems, removedItems = []) => {
        const targetUserNumbers = selectedItems.filter((a) => !a.isAssigned).map((u) => u.userNumber);

        if (removedItems.length > 0) {
            const removedUserNumbers = removedItems.map((u) => u.userNumber);

            store.dispatch(
                deleteResource({
                    resourceName: "applicationToolsAssignmentsList",
                    path: {
                        appId: applicationNumber,
                    },
                    body: removedUserNumbers,
                })
            );
        }

        if (targetUserNumbers.length > 0) {
            store.dispatch(
                updateResource({
                    resourceName: "applicationToolsAssignmentsList",
                    path: {
                        appId: applicationNumber,
                    },
                    body: targetUserNumbers,
                })
            );
        }
    };

    openAssignmentModal({
        onRefresh,
        resourceGetParams,
        resourceDataPath,
        title,
        submitButtonText,
        modalIcon,
        idKey,
        nameKey,
        onSelect,
        components: {
            Header,
        },
    });
};

export const useCalculationStatus = ({ applicationNumber }) => {
    const dispatch = useDispatch();
    const isCalculationsInProgress = useSelector((state) => state.projects.calculationsInProgress[applicationNumber]) ?? false;

    useEffect(() => {
        dispatch(checkCalculationStatus({ applicationNumber }));
    }, [applicationNumber, dispatch]);

    return [isCalculationsInProgress];
};

export const useCalculationStatusCheck = ({ applicationNumber }) => {
    const dispatch = useDispatch();

    return useCallback(async () => {
        const checkCalcs = () =>
            getResourcePromise({
                resourceName: "applicationCalculationStatus",
                key: applicationNumber,
                path: {
                    appId: applicationNumber,
                },
            });

        const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        const isCalcsRunning = (response) => response?.isRunning ?? false;

        try {
            await wait(2000);
            const response = await checkCalcs();

            if (isCalcsRunning(response)) {
                // show calcs in progress notification
                dispatch({
                    type: PROJECTS_SET_CALCULATIONS_IN_PROGRESS,
                    applicationNumber,
                    calculationsInProgress: true,
                });

                await wait(1000);
                const response = await checkCalcs();

                if (isCalcsRunning(response)) {
                    // After the timeout start to poll calc status check
                    setTimeout(dispatch(checkCalculationStatus({ applicationNumber })), CALCULATIONS_IN_PROGRESS_CHECK_TIMEOUT);
                    return true;
                } else {
                    // hide calcs in progress notification
                    dispatch({
                        type: PROJECTS_SET_CALCULATIONS_IN_PROGRESS,
                        applicationNumber,
                        calculationsInProgress: false,
                    });
                    return false;
                }
            } else {
                return false;
            }
        } catch {}
    }, [applicationNumber, dispatch]);
};
