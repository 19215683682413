import React from "react";
import WaitIcon from "../WaitIcon";
import { useReference } from "./useReference";

const Reference = ({ type, outputType = "id", id }) => {
    const [referenceValue, loading] = useReference(type, outputType);

    if (loading) {
        return <WaitIcon />;
    }

    if (referenceValue && id) {
        return (referenceValue.filter((i) => i.val === `${id}`)[0] || {}).display || id;
    }

    return null;
};

export default Reference;
