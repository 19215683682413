import React, { useRef, useState, useEffect, useCallback, memo } from "react";
import { useSelector } from "react-redux";
import ClassNames from "classnames";

import IconWrap from "../../Icons";
import TabDetails from "./TabDetails";
import useUnmounted from "../../../utils/useUnmounted";
import { isHover, dispatchEvent } from "../../../utils/dom";

import "./style.scss";

const WindowTab = memo(({ containerName, viewName, index, scrollId, onClick, onClose, onSync }) => {
    const tabRef = useRef();
    const unmounted = useUnmounted();

    const [left, setLeft] = useState(0);
    const [hover, setHover] = useState(false);

    const views = useSelector((state) => state.window[containerName])?.views || [];
    const view = views.find((i) => i.name === viewName) || {};

    const { name, active, close = true, isSplitView } = view;

    const getStyleLeft = (tabRef, node) => {
        let result = 0;

        if (tabRef.current && node) {
            result = tabRef.current.offsetLeft - node.scrollLeft;
        }

        return result;
    };

    useEffect(() => {
        const node = document.getElementById(scrollId);
        let isScrolling;

        const onScroll = () => {
            clearTimeout(isScrolling);
            isScrolling = setTimeout(() => {
                !unmounted.current && setLeft(getStyleLeft(tabRef, node));
            }, 50);
        };

        if (node) {
            node.addEventListener("scroll", onScroll);

            // Set initial offset by triggering scroll.
            dispatchEvent(node, "scroll");
        }

        return () => {
            if (node) {
                node.removeEventListener("scroll", onScroll);
            }
        };
    }, [scrollId, unmounted]);

    const onMouseEnter = useCallback(() => {
        const node = document.getElementById(scrollId);

        if (!node) {
            return;
        }

        const tabWidth = tabRef.current.offsetWidth;
        const tabOffsetLeft = tabRef.current.offsetLeft;
        const areaWidth = node.offsetWidth;
        const scrollOffsetLeft = node.offsetLeft;
        const scrollLeft = node.scrollLeft;
        const left = getStyleLeft(tabRef, node);

        // Ignore tabs not fully visible on right side
        if (left + tabWidth - scrollOffsetLeft > areaWidth) {
            return;
        }

        // Ignore tabs not fully visible on left side
        if (tabOffsetLeft < scrollLeft + scrollOffsetLeft) {
            return;
        }

        setHover(true);
        dispatchEvent(node, "scroll");
    }, [scrollId]);

    const onMouseLeave = useCallback(() => {
        setHover(false);
    }, []);

    useEffect(() => {
        if (!hover) {
            const node = tabRef && tabRef.current;
            setTimeout(() => {
                // Hover not detected if small timeout is not used.
                if (node && isHover(node)) {
                    !unmounted.current && onMouseEnter();
                }
            }, 10);
        }
    }, [index, hover, onMouseEnter, unmounted]);

    const tabClass = ClassNames("window-tab", {
        active: active,
        hover: hover,
        "split-view": isSplitView,
        locked: !close,
    });

    if (isSplitView) {
        const { isSplitViewSync, leftViewClassName, rightViewClassName } = view;
        const hasBothLockedViews =
            (leftViewClassName || "").includes("entity-locked") && (rightViewClassName || "").includes("entity-locked");
        const hasLockedView = (leftViewClassName || "").includes("entity-locked") || (rightViewClassName || "").includes("entity-locked");

        const syncControlsClass = ClassNames("split-view-sync-controls", {
            "entity-locked": hasLockedView,
            "both-entity-locked": hasBothLockedViews,
        });

        return (
            <div ref={tabRef} className={tabClass} key={name} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <div className="split-view-details" style={{ left }}>
                    <TabDetails containerName={containerName} viewName={viewName} onClick={() => onClick(name)} side="left" />
                    <div className={syncControlsClass}>
                        <IconWrap
                            iconWrapTransparent
                            title={isSplitViewSync ? "unsynchronize" : ""}
                            iconWrap={isSplitViewSync ? "locked cut-scissors-filled" : "copy-link"}
                            onClick={() => onSync(name)}
                        />
                        <IconWrap
                            iconWrapTransparent
                            title={isSplitViewSync ? "" : "synchronize"}
                            iconWrap={isSplitViewSync ? "locked copy-link" : "copy-link"}
                            onClick={() => onSync(name)}
                        />
                    </div>
                    <TabDetails
                        containerName={containerName}
                        viewName={viewName}
                        onClick={() => onClick(name)}
                        side="right"
                        onClose={close ? onClose : undefined}
                    />
                </div>
            </div>
        );
    }

    return (
        <div ref={tabRef} className={tabClass} key={name} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <TabDetails
                containerName={containerName}
                viewName={viewName}
                onClick={onClick}
                onClose={close ? onClose : undefined}
                style={{ left }}
            />
        </div>
    );
});

export default WindowTab;
