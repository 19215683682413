import React, { useCallback, useContext, memo } from "react";
import { isEmpty, cloneDeep } from "lodash";

import { WorkflowContext } from "../../../../../context/WorkflowContext";
import { STEP_TYPES_KEYS } from "../../../../../constants/step-types";

import { StepPropertiesSettingsContext } from "../../../../../context/StepPropertiesSettingsContext";

import Input from "../../../../../../Input/TextInput";
import { ApplicationFlagField } from "./ApplicationFlagField";
import { TargetStatusField } from "./TargetStatusField";
import { ActivateFormPageField } from "./ActivateFormPageField";
import { DocumentUploadField } from "./DocumentUploadField";

import "../style.scss";

export const AttributeFields = memo(({ stepContent }) => {
    const { onChangeWorkflowStepProperties, isLocked } = useContext(WorkflowContext);
    const { attributes } = useContext(StepPropertiesSettingsContext);
    const { key, attributes: stepContentAttributes } = stepContent;

    const handleChangeAttribute = useCallback(
        (e, typeNumber, name) => {
            const newAttributes = cloneDeep(attributes);
            const currentAttribute = newAttributes.find((attribute) => attribute.typeNumber === typeNumber);
            const { value } = e.target;

            if (currentAttribute) {
                currentAttribute.value = value;
            } else {
                newAttributes.push({ typeNumber, name, value });
            }

            onChangeWorkflowStepProperties({ attributes: newAttributes });
        },
        [attributes, onChangeWorkflowStepProperties]
    );

    if (key === STEP_TYPES_KEYS.ACTIVE_FORM_PAGE) {
        return <ActivateFormPageField />;
    }

    if (key === STEP_TYPES_KEYS.AUTOMATED_STATUS_CHANGE) {
        return <TargetStatusField />;
    }

    if (key === STEP_TYPES_KEYS.DOCUMENT_UPLOAD) {
        return <DocumentUploadField onChangeAttribute={handleChangeAttribute} />;
    }

    if (!isEmpty(stepContentAttributes)) {
        switch (key) {
            case STEP_TYPES_KEYS.FLAG_RECORD:
            case STEP_TYPES_KEYS.CLEAR_FLAG:
                return <ApplicationFlagField onChangeAttribute={handleChangeAttribute} />;
            default:
                return (
                    <div className="workflow-step-types-widget">
                        {stepContentAttributes.map(({ typeNumber, name }, index) => {
                            const currentAttribute = attributes
                                ? attributes.find((attribute) => attribute.typeNumber === typeNumber)
                                : null;
                            const value = currentAttribute && currentAttribute.hasOwnProperty("value") ? currentAttribute.value : "";

                            return (
                                <Input
                                    className="workflow-step-types-widget__attribute-field"
                                    key={index}
                                    value={value || ""}
                                    label={name}
                                    readOnly={isLocked}
                                    onChange={(e) => handleChangeAttribute(e, typeNumber, name)}
                                />
                            );
                        })}
                    </div>
                );
        }
    }

    return null;
});
