import { useCallback, useRef, memo, useEffect } from "react";
import { cloneDeep } from "lodash";
import { addWidget, setWidgetProperties } from "components/ui/PortalBuilder/utils";
import { PropertyListItemAdd } from "../PropertyList/PropertyListItemAdd";
import { WidgetType } from "components/ui/PortalBuilder/types";
import { useErrorContext } from "../../PortalBuilderErrorContextProvider";
import { CarouselItemProperty } from "./CarouselItemProperty";

import "./CarouselProperty.scss";
import { openConfirmModal } from "components/ui/Modal/utils";

export const CarouselProperty = memo((props) => {
    const { id, value = [], onChange } = props;
    const carouselItems = value;

    const carouselItemsRef = useRef();
    useEffect(() => {
        carouselItemsRef.current = carouselItems;
    }, [carouselItems]);

    const onPropertyChange = useCallback(
        (index) =>
            (propertyId, propertyValue, additionalValues = []) => {
                const updatedValue = cloneDeep(carouselItemsRef.current);
                updatedValue[index] = setWidgetProperties(updatedValue[index], [
                    { id: propertyId, value: propertyValue },
                    ...additionalValues,
                ]);

                onChange(id, updatedValue);
            },
        [id, onChange]
    );

    const { errors } = useErrorContext();

    const addedItemRef = useRef({});

    const onAdd = useCallback(() => {
        const index = carouselItems?.length ?? 0;
        const updatedValue = addWidget(carouselItems, WidgetType.CAROUSEL_ITEM, index, `Carousel Item #${index + 1}`);
        addedItemRef.current[index] = updatedValue[index];
        onChange(id, updatedValue);
    }, [id, onChange, carouselItems]);

    useEffect(() => {
        if (carouselItemsRef.current.length < 1) {
            onAdd();
        }
    }, [carouselItems, onAdd]);

    const onConfirm = useCallback(
        (index) => {
            const updatedValue = cloneDeep(carouselItems);
            updatedValue.splice(index, 1);
            onChange(id, updatedValue);
        },
        [id, onChange, carouselItems]
    );

    const onRemove = useCallback(
        (index) => () => {
            openConfirmModal({
                title: "Delete Slide",
                modalIcon: "delete-trash-empty",
                message: "Are you sure you want to delete the this slide?",
                onConfirm: () => onConfirm(index),
            });
        },
        [onConfirm]
    );

    const onChangeOrderUp = useCallback(
        (index) => {
            const list = cloneDeep(carouselItems);
            const temp = list[index];
            list[index] = list[index - 1];
            list[index - 1] = temp;
            onChange(id, list);
        },
        [carouselItems, id, onChange]
    );

    const onChangeOrderDown = useCallback(
        (index) => {
            const list = cloneDeep(carouselItems);
            const temp = list[index];
            list[index] = list[index + 1];
            list[index + 1] = temp;
            onChange(id, list);
        },
        [carouselItems, id, onChange]
    );

    if (props.hidden) {
        return null;
    }

    const addItemLabel = <PropertyListItemAdd title="Add Item" onAdd={onAdd} disabled={carouselItems.length > 4} />;
    if (carouselItems.length === 0) {
        return addItemLabel;
    }

    return (
        <div className="carousel-property">
            {carouselItems.map((item, index) => {
                const hideItem = item.type !== "CarouselItem";
                if (hideItem) {
                    return null;
                } else {
                    return (
                        <CarouselItemProperty
                            errors={errors}
                            index={index}
                            key={index}
                            value={item.props}
                            onRemove={onRemove(index)}
                            onChange={onPropertyChange(index)}
                            onChangeOrderUp={onChangeOrderUp}
                            onChangeOrderDown={onChangeOrderDown}
                            listLength={carouselItems.length}
                        />
                    );
                }
            })}
            {addItemLabel}
        </div>
    );
});
