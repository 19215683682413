import React from "react";
import { ErrorMessage } from "components/ui/Message";

const PropertyErrors = ({ errors }: PropertyErrorsProps) => {
    return <>{errors.map((error, index) => error && <ErrorMessage key={index}>{error}</ErrorMessage>)}</>;
};

type PropertyErrorsProps = {
    errors: string[];
};

export default PropertyErrors;
