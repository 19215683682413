import { HeadingPropertyContext } from "components/ui/PortalBuilder/contexts";
import { PropertyName } from "components/ui/PortalBuilder/types";
import React, { useContext } from "react";
import {} from "../../Property/BorderProperty/types";
import { ColorSelectIcon } from "../../Property/ColorSelect/ColorSelectIcon";
import ColorSelectPanel from "../../Property/ColorSelect/ColorSelectPanel";
import { PropertyListItem } from "../../Property/PropertyListItem";
import { SelectPanelProperty } from "../../Property/SelectPanelProperty";
import { HeadingCustomPropertyName, HeadingNames, HeadingTypes } from "./types";

export const ColorIndividualProperty = (props) => {
    const { value } = props;
    const { customPropertiesValue } = useContext(HeadingPropertyContext);
    if (customPropertiesValue[HeadingCustomPropertyName.HeadingColorType] !== HeadingTypes.Individual) {
        return null;
    }

    return (
        <PropertyListItem className="property-list-item--heading-color" borderBottom={false}>
            <SelectPanelProperty
                {...props}
                id={PropertyName.HeadingH1Color}
                value={value[HeadingNames.H1]}
                title={HeadingNames.H1}
                borderTop={true}
                selectComponent={ColorSelectIcon}
                panelComponent={ColorSelectPanel}
                selectionTitle={"Text"}
            />
            <SelectPanelProperty
                {...props}
                id={PropertyName.HeadingH2Color}
                value={value[HeadingNames.H2]}
                title={HeadingNames.H2}
                selectComponent={ColorSelectIcon}
                panelComponent={ColorSelectPanel}
                selectionTitle={"Text"}
            />
            <SelectPanelProperty
                {...props}
                id={PropertyName.HeadingH3Color}
                value={value[HeadingNames.H3]}
                title={HeadingNames.H3}
                selectComponent={ColorSelectIcon}
                panelComponent={ColorSelectPanel}
                selectionTitle={"Text"}
            />
            <SelectPanelProperty
                {...props}
                id={PropertyName.HeadingH4Color}
                value={value[HeadingNames.H4]}
                title={HeadingNames.H4}
                selectComponent={ColorSelectIcon}
                panelComponent={ColorSelectPanel}
                selectionTitle={"Text"}
            />
            <SelectPanelProperty
                {...props}
                id={PropertyName.HeadingH5Color}
                value={value[HeadingNames.H5]}
                title={HeadingNames.H5}
                selectComponent={ColorSelectIcon}
                panelComponent={ColorSelectPanel}
                selectionTitle={"Text"}
            />
            <SelectPanelProperty
                {...props}
                id={PropertyName.HeadingH6Color}
                value={value[HeadingNames.H6]}
                title={HeadingNames.H6}
                borderBottom={false}
                selectComponent={ColorSelectIcon}
                panelComponent={ColorSelectPanel}
                selectionTitle={"Text"}
            />
        </PropertyListItem>
    );
};
