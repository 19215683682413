import React, { useContext, memo } from "react";

import { takeContractorsFromList } from "../../../../../views/ProjectView/utils";
import { onContractorLookup } from "../../../../Contractor/actions";
import { formatFullName, formatFullAddress, systemUserRights } from "../../../../../utils/user";
import { getContactType } from "./ContactsUtils";
import { ContactsPanelContext } from ".";

import ActionLabel from "../../../../Label/ActionLabel";
import EmailLink from "../../../../EmailLink";
import IconWithLabel from "../../../../Icons/IconWithLabel";
import IconWrap from "../../../../Icons";
import { InfoMessage } from "../../../../Message";
import { useProgramRights } from "store/resources/useResource";
import { openProjectTab } from "components/utils/window";

export const ContactBodySection = memo(
    ({
        applicationNumber,
        contact,
        isPremise,
        isPrimary,
        isContractor,
        isOtherContact,
        onAddressClick,
        onSelectContractor,
        onScrollToOtherContactsList,
        onOpenCreateForm,
        onCisStored,
        onCisVerify,
        canSearchCIS,
        programNumber,
    }) => {
        if (!contact) {
            return (
                <ContactBodyNoAssignment
                    applicationNumber={applicationNumber}
                    isPrimary={isPrimary}
                    isPremise={isPremise}
                    isContractor={isContractor}
                    onSelect={onSelectContractor}
                    onScrollToOtherContactsList={onScrollToOtherContactsList}
                    onOpenCreateForm={onOpenCreateForm}
                    programNumber={programNumber}
                />
            );
        }

        const {
            contacttitle,
            phone,
            fax,
            email,
            firstname,
            lastname,
            company,
            address,
            city,
            state,
            zip,
            premiseid,
            acct_number,
            address_cont,
            workflowgroup,
        } = contact ?? {};
        const fullName = formatFullName(firstname, lastname, company);
        const contactType = getContactType({
            contact,
            isPremise,
            isPrimary,
            isContractor,
        });
        const fullAddressText = formatFullAddress(address, address_cont, city, state, zip);
        const fullAddress = isPremise ? (
            <ActionLabel className="link" onClick={onAddressClick}>
                {fullAddressText}
            </ActionLabel>
        ) : (
            fullAddressText
        );
        const { hascis, cmpAppId } = contact ?? {};

        return (
            <div className="flex-row align-center body-row content">
                <div className="flex-column content-lead">
                    <div className="contact-row-item type">{contactType}</div>
                    <div className="contact-row-item full-name">{fullName}</div>
                    <div className="contact-row-item company">
                        <span className="label">Company:</span>
                        <span className="title">{company ? company : "-"}</span>
                    </div>
                    {contact && isPremise && (
                        <div className="contact-row-item cis-verification flex-column">
                            {hascis && (
                                <IconWithLabel icon="library-empty" disabled={!canSearchCIS} onClick={onCisStored}>
                                    Stored CIS
                                </IconWithLabel>
                            )}
                            <IconWithLabel icon="library-empty" disabled={!canSearchCIS} onClick={onCisVerify}>
                                Verify CIS
                            </IconWithLabel>
                        </div>
                    )}
                    {contact && cmpAppId && cmpAppId !== applicationNumber && (
                        <div className="contact-row-item view-contractor flex-column">
                            <IconWithLabel
                                title="View CMP Application"
                                icon="eye-visibility-empty"
                                onClick={() => openProjectTab({ applicationNumber: cmpAppId })}
                            >
                                View
                            </IconWithLabel>
                        </div>
                    )}
                </div>
                <div className="flex-one flex-row content-body">
                    <div className="contact-row-item company">
                        <span className="label">
                            <span>Company:</span>
                        </span>
                        <span className="title">
                            <span>{company ? company : "-"}</span>
                        </span>
                    </div>
                    <div className="contact-row-item name">
                        <span className="label">
                            <span>Contact Title:</span>
                        </span>
                        <span className="title">
                            <span>{contacttitle ? contacttitle : "-"}</span>
                        </span>
                    </div>
                    <div className="contact-row-item address">
                        <span className="label">
                            <span>Address:</span>
                        </span>
                        <span className="title">
                            <span>{fullAddress ? fullAddress : "-"}</span>
                        </span>
                    </div>
                    <div className="contact-row-item flex-row premise-and-account">
                        <div className="flex-column">
                            <Identifier label="Account #:" value={acct_number ? acct_number : "-"} />
                        </div>
                        <div className="flex-column">
                            <Identifier label="Premise #:" value={premiseid ? premiseid : "-"} />
                        </div>
                        <div className="flex-column">
                            <Identifier label="Workflow Group:" value={workflowgroup ? workflowgroup : "-"} />
                        </div>
                    </div>
                    <div className="contact-row-item email-container">
                        <div>
                            <div className="flex-row align-center phone">
                                <IconWrap iconWrapSmall icon="phone-empty" />
                                {phone ? phone : "-"}
                            </div>
                            {isOtherContact && (
                                <div className="flex-row align-center phone mt-4">
                                    {fax ? (
                                        <>
                                            <span className="label">Fax:</span>
                                            <span className="ml-2">{fax}</span>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            )}
                        </div>

                        <div className="flex-row email">
                            <IconWrap iconWrapSmall icon="email-empty" />
                            {email ? <EmailLink email={email} /> : "-"}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export const Identifier = memo(({ label, value }) => {
    if (!value) {
        return null;
    }

    return (
        <>
            <div className="identifier">{label}</div>
            <div>{value}</div>
        </>
    );
});

const ContactBodyNoAssignment = memo(
    ({ applicationNumber, isPrimary, isPremise, isContractor, onSelect, onScrollToOtherContactsList, onOpenCreateForm, programNumber }) => {
        const [programRights = []] = useProgramRights({ programNumber });
        if (isPrimary || isPremise) {
            return (
                <ContactBodyAssignContact
                    onScrollToOtherContactsList={onScrollToOtherContactsList}
                    onOpenCreateForm={onOpenCreateForm}
                    programNumber={programNumber}
                />
            );
        }

        const isSearchContractorDisabled = !programRights.includes(systemUserRights.VISIONDSM_ADD_APPLICATION);

        if (isContractor) {
            if (takeContractorsFromList({ applicationNumber })) {
                return (
                    <div className="flex-column content">
                        <InfoMessage>No contact assigned.</InfoMessage>
                        <IconWithLabel
                            icon="search"
                            onClick={() =>
                                onContractorLookup({
                                    applicationNumber,
                                    onSelect,
                                })
                            }
                            disabled={isSearchContractorDisabled}
                        >
                            Search for a Contractor
                        </IconWithLabel>
                    </div>
                );
            }

            return (
                <ContactBodyAssignContact
                    onScrollToOtherContactsList={onScrollToOtherContactsList}
                    onOpenCreateForm={onOpenCreateForm}
                    programNumber={programNumber}
                />
            );
        }

        return null;
    }
);

const ContactBodyAssignContact = memo(({ onOpenCreateForm, programNumber }) => {
    const { onScrollToOtherContactsList } = useContext(ContactsPanelContext);
    const [programRights = []] = useProgramRights({ programNumber });

    const isAddNewContactDisabled = !programRights.includes(systemUserRights.VISIONDSM_ADD_APPLICATION);
    return (
        <div className="flex-column content">
            <InfoMessage>No contact assigned.</InfoMessage>
            {!isAddNewContactDisabled && (
                <p>
                    Assign contact from the <ActionLabel onClick={onScrollToOtherContactsList}>Other Contacts</ActionLabel> list or{" "}
                    <ActionLabel onClick={onOpenCreateForm}>Create new</ActionLabel>.
                </p>
            )}
        </div>
    );
});
