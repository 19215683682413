import React from "react";

import InlinePanelHeader from "../../InlinePanelHeader";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import { InfoMessage } from "../../../../Message";

const FileUpload = (props) => {
    const { onSubmit, onCancel, files } = props;
    const fileTypes = files ? files.supportedFileTypes : "";
    const fileLimitText = files ? (
        <p className="info-text">
            <b>Supported formats:</b> {fileTypes} <br />
            <b>Maximum upload file size:</b> {files.fileSizeLimit}
        </p>
    ) : (
        ""
    );

    const schema = {
        type: "object",
        required: ["file"],
        properties: {
            file: {
                type: "array",
                title: "Upload Files",
                items: {
                    type: "string",
                    format: "data-url",
                },
            },
            column9: {
                type: "object",
                required: ["fileSecurity", "fileTag"],
                properties: {
                    fileSecurity: {
                        type: "integer",
                        title: "File Access",
                        anyOf: [
                            {
                                type: "integer",
                                title: "Public",
                                enum: [149],
                            },
                            {
                                type: "integer",
                                title: "Private",
                                enum: [150],
                            },
                        ],
                    },
                    fileTag: {
                        type: "string",
                        title: "File Tag",
                    },
                },
            },
        },
    };

    const uiSchema = {
        classNames: "inline-form",
        file: {
            "ui:elementType": "field",
            "ui:widget": "DropZoneWidget",
            "ui:key": "file",
        },
        column9: {
            classNames: "file-column-wrapper",
            fileSecurity: {
                "ui:key": "itemFilterId",
                classNames: "file-column",
            },
            fileTag: {
                "ui:key": "tag",
                classNames: "file-column",
            },
        },
    };

    const uploadMessage = (
        <InfoMessage spaceAround>
            <div>{fileLimitText}</div>
        </InfoMessage>
    );

    return (
        <div className="file-upload-form">
            <InlinePanelHeader title="File Upload" onClose={onCancel} />
            <div className="file-form">
                <JsonSchemaForm
                    schema={schema}
                    uiSchema={uiSchema}
                    initialValues={{}}
                    onSubmit={onSubmit}
                    submitText={"Upload"}
                    onCancel={onCancel}
                    withCancel
                    noReset
                    infoMessage={uploadMessage}
                />
            </div>
        </div>
    );
};

export default FileUpload;
