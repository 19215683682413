import React from "react";
import queryString from "query-string";
import { decodeHtml } from "../../utils/string";
import { getResourcePromise } from "../../../store/resources/useResource";
import { availableGrids, programsGridColumnKeys, utilitiesGridColumnKeys } from "../../views/configureGrids";
import { openUtilityTab } from "../../utils/window";
import { openProgramTab } from "store/window/openTabActions";
import get from "lodash/get";
import { store } from "../../../store/configureStore";
import { modalOpen, modalClose } from "../../../store/modal/actions";

export const processHyperlink = ({ url }) => {
    const query = (url || "").split("###")[1] || "";
    const params = queryString.parse(decodeHtml(query));

    if (params) {
        switch (params.show) {
            case "programid":
                openProgramById({ programId: params.programid });
                break;
            case "utilityid":
                openUtilityById({ utilityId: params.utilityid });
                break;
            default:
                break;
        }
    }
};

const openProgramById = ({ programId }) => {
    store.dispatch(
        modalOpen({
            modalType: "WAITING_MODAL",
            modalProps: {
                title: "Opening Program...",
            },
        })
    );

    getResourcePromise({
        resourceName: "grid",
        resourceId: availableGrids.programs,
        query: {
            searchAttr: `${programsGridColumnKeys.programId}=${programId}`,
            pageNum: 1,
            recsPerPage: 1,
        },
    })
        .then((response) => {
            const dataItem = get(response, "grid.rows");

            if (dataItem) {
                store.dispatch(
                    openProgramTab({
                        programNumber: dataItem[programsGridColumnKeys.programNumber],
                    })
                );

                store.dispatch(modalClose());
            } else {
                const text = <strong>Program not found</strong>;

                store.dispatch(
                    modalOpen({
                        modalType: "SIMPLE_DIALOG",
                        modalProps: {
                            text: text,
                        },
                    })
                );
            }
        })
        .catch(() => {
            store.dispatch(modalClose());
        });
};

const openUtilityById = ({ utilityId }) => {
    store.dispatch(
        modalOpen({
            modalType: "WAITING_MODAL",
            modalProps: {
                title: "Opening Utility...",
            },
        })
    );

    getResourcePromise({
        resourceName: "grid",
        resourceId: availableGrids.utilities,
        query: {
            searchAttr: `${utilitiesGridColumnKeys.utilityId}=${utilityId}`,
            pageNum: 1,
            recsPerPage: 1,
        },
    })
        .then((response) => {
            const dataItem = get(response, "grid.rows");

            if (dataItem) {
                openUtilityTab({
                    utilityNumber: dataItem[utilitiesGridColumnKeys.utilityNumber],
                });

                store.dispatch(modalClose());
            } else {
                const text = <strong>Utility not found</strong>;

                store.dispatch(
                    modalOpen({
                        modalType: "SIMPLE_DIALOG",
                        modalProps: {
                            text: text,
                        },
                    })
                );
            }
        })
        .catch(() => {
            store.dispatch(modalClose());
        });
};
