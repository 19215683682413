import React, { memo } from "react";
import { toArray } from "components/utils/array";
import { get, isEmpty } from "lodash";

import withResource from "../EventsPanel/HOCs/withResource";

import CustomList, { renderCustomFooter } from "../../../../List/CustomList";

const headers = {
    event_attr_name: "Attribute",
    event_Attr_Value_Str: "Value",
};

const EventDetails = memo((props) => {
    const attributes = toArray(get(props, "event.event.attributes.attributes", [])).map((attr) => ({
        ...attr,
        event_Attr_Value_Str: isEmpty(attr.event_Attr_Value_Str) ? "-" : attr.event_Attr_Value_Str,
    }));

    return (
        <div className="events-list events-details flex-column">
            <div className="sidebar-list-body flex-column">
                <CustomList limit={6} items={attributes} headers={headers} renderFooter={attributes.length > 6 && renderCustomFooter} />
            </div>
        </div>
    );
});

export default withResource(EventDetails);
