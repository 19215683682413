import React from "react";
import { formatJsonDateTime } from "../../../../../utils/date";
import IconWrap from "../../../../Icons";
import NotePreview from "../NotesPanel/NotePreview";

const NoteListItem = ({ note, isEditDisabled, onEdit }) => {
    return (
        <div className="note">
            <div className="note-header flex-row justify-space-between align-center">
                <div className="note-author">
                    by <b>{note.userName}</b>
                </div>
                <div className="note-actions">
                    <IconWrap icon="edit-empty" title="Edit" onClick={() => onEdit(note)} disabled={isEditDisabled} />
                </div>
            </div>
            <div className="note-body">
                <NotePreview>{note.note}</NotePreview>
            </div>
            <div className="note-footer flex-row align-center justify-space-between">
                <div className="note-date">{formatJsonDateTime(note.dateEntered)}</div>
                <div className="note-info">
                    {note.noteType.toLowerCase() === "public" ? (
                        <div className="flex-row align-center">
                            <IconWrap icon="add-group-filled" />
                            Public
                        </div>
                    ) : (
                        <div className="flex-row align-center">
                            <IconWrap icon="user-identity-person-filled" />
                            Private
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NoteListItem;
