import React from "react";

import IconWithLabel from "../../../../Icons/IconWithLabel";
import GridSelectedItemsLabel from "../../../../DataGrid/GridSelectedItemsLabel";

const EventItemGridControls = ({ selectedRowCount, onRowSelectClear, onUpload }) => (
    <div className="grid-controls flex-row align-center">
        <GridSelectedItemsLabel
            text={selectedRowCount > 0 ? `Event ${"Item" + (selectedRowCount > 1 ? "s" : "")} Selected` : "Select Event Items to Add"}
            count={selectedRowCount}
            onClear={onRowSelectClear}
        />
        <span className="flex-one" />
        <IconWithLabel withHandMadeIcon onClick={onUpload} title="Upload csv with columns ID, NAME, PROGRAM">
            Upload from CSV
        </IconWithLabel>
    </div>
);

export default EventItemGridControls;
