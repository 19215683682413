import React, { memo } from "react";

import { formatMoney } from "../../../../../utils/money";
import { useRebatesAvailable, useApplicationPayments } from "../../../../../../store/resources/useResource";
import { getTotals } from "../RebatesPanel/utils";

import WaitIcon from "../../../../WaitIcon";
import SidebarPanel from "../../../../SidebarPanel";
import { SidebarIconGroup } from "../../../../SidebarPanel/SidebarParts/SidebarIconGroup";
import RebatesList from "./RebatesList";

import "./RebatesPanelSmall.scss";

const RebatesPanelSmall = memo(({ panel, onSettings, onUnpin, onExpand }) => {
    const applicationNumber = panel && panel.data && panel.data.applicationNumber;

    const [rebates = [], isLoading] = useApplicationPayments({
        applicationNumber,
    });
    const [rebatesAvailable = []] = useRebatesAvailable({ applicationNumber });

    const { total, remaining } = getTotals(rebatesAvailable);

    const rebateTotals = [
        {
            label: "Total Payment Available",
            value: formatMoney(total),
        },
        {
            label: "Total Payment Remaining",
            value: formatMoney(remaining),
        },
    ];

    const sidebarHeaderActions = <SidebarIconGroup panel={panel} onSettings={onSettings} onUnpin={onUnpin} onExpand={onExpand} />;

    return (
        <SidebarPanel
            className="rebates-sidebar-panel"
            sidebarHeaderTitle={panel.name}
            sidebarHeaderActions={sidebarHeaderActions}
            noPadding
            noFooter
            noData={rebates.length === 0}
        >
            {isLoading && <WaitIcon />}
            {!isLoading && (
                <div className="rebates-sidebar-body">
                    <div className="rebate-totals">
                        {rebateTotals.map((total, index) => (
                            <div key={index} className="rebate-total-item">
                                <div className="item-value">{total.value}</div>
                                <div className="item-label">{total.label}</div>
                            </div>
                        ))}
                    </div>
                    <RebatesList rebates={rebates} />
                </div>
            )}
        </SidebarPanel>
    );
});

export default RebatesPanelSmall;
