import { store } from "../../../store/configureStore";
import { get, isNil } from "lodash";
import { useProgram, useProgramForm } from "../../../store/resources/useResource";
import { stripHtml } from "../../utils/string";

export const isLockedText = undefined;

export const useIsProgramLocked = ({ programNumber }) => {
    const [program] = useProgram({ programNumber, forced: false });

    // Consider program as not locked while it is not available.
    if (isNil(program)) {
        return false;
    }

    return (program?.status ?? "").toLowerCase() !== "active";
};

export const isChildProgram = ({ programNumber }) => {
    const state = store.getState();
    return get(state, `resources.programs.itemsById[${programNumber}].isChild`, false);
};

export const useBackEndRequirePrimaryContact = ({ programNumber }) => {
    const [form] = useProgramForm({ programNumber, forced: false });
    return form?.beRequireContact?.toLowerCase() ?? null;
};

export const useBackEndRequirePremiseContact = ({ programNumber }) => {
    const [form] = useProgramForm({ programNumber, forced: false });
    return form?.beRequirePremise?.toLowerCase() ?? null;
};

export const useBackEndRequireContractor = ({ programNumber }) => {
    const [form] = useProgramForm({ programNumber, forced: false });

    return form?.beRequireContractor?.toLowerCase() ?? null;
};

export const usePrimaryContactDescription = ({ programNumber }) => {
    const [form] = useProgramForm({ programNumber, forced: false });
    return stripHtml(form?.primaryDescription ?? "");
};

export const usePremiseContactDescription = ({ programNumber }) => {
    const [form] = useProgramForm({ programNumber, forced: false });
    return stripHtml(form?.premiseDescription ?? "");
};

export const useContractorDescription = ({ programNumber }) => {
    const [form] = useProgramForm({ programNumber, forced: false });
    return stripHtml(form?.contractorDescription ?? "");
};
