import { get } from "lodash";

import * as actionType from "../actionTypes";
import { availableResources, getResourceCallbackKey } from "../configureResources";
import { toArray } from "../../components/utils/array";
import { normalizeFormConfiguration } from "../../components/utils/form";
import { logError } from "../../components/utils/logger";
import { toast } from "react-toastify";
import { isDevEnvironment } from "components/utils/constants";
import { convertToArray } from "components/utils/string";

const CALLBACK_TYPE = {
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
    COMPLETE: "COMPLETE",
};

/**
 * Save resource callbacks in arrays accessible with resource key.
 * Callbacks will be cleared on request response.
 * */
const resourceCallbacks = {
    // [key]: [{ onSuccess, onError, onComplete }, ...]
};

export function resourcesMiddleware({ dispatch, getState }) {
    return (next) => (action) => {
        switch (action.type) {
            case actionType.API_CRUD_READ_LIST_SUCCESS:
                onReadListSuccess(action);
                break;
            case actionType.API_CRUD_READ_SUCCESS:
                onReadSuccess(action);
                onReadComplete(action);
                break;
            case actionType.API_CRUD_READ_ERROR:
                onReadComplete(action);
                break;
            case actionType.API_CRUD_REGISTER_CALLBACKS:
                resourceCallbacks[action.key] = [].concat(resourceCallbacks[action.key] ?? [], action.callbacks);
                break;
            default:
                break;
        }

        next(action);

        onResourceChanged(action);

        return;
    };

    // Merge path params in list objects for later filtering and get rid of listName in returned data.
    async function onReadListSuccess(action) {
        const { resourceName, path, appendData } = action.passThroughData;
        const pathKeys = Object.keys(path || {});

        const listName = availableResources.filter((item) => item.resourceName === resourceName)[0].listName;
        action.data = listName ? action.data[listName] : action.data;

        if (pathKeys.length) {
            action.data = action.data.map((item) => {
                return {
                    ...item,
                    ...path,
                };
            });
        }

        if (appendData) {
            action.data = action.data.map((item) => {
                return {
                    ...item,
                    ...appendData,
                };
            });
        }
    }

    async function onReadSuccess(action) {
        const { resourceName, query, transform } = action.passThroughData;

        if (resourceName === "workcenter" && !Array.isArray(action.data)) {
            action.data = {
                ...JSON.parse(action.data === "" ? '{"workCenter":{"workCenterItem":[]}}' : action.data),
            };
        }

        if (
            resourceName === "approvedEquipmentSearch" &&
            Array.isArray(action.data) &&
            action.data.length > 0 &&
            action.data[0].gridOutput
        ) {
            action.data = [...JSON.parse(action.data[0].gridOutput)];
        }

        if (resourceName === "reference" && action.data && action.data.referenceResults) {
            action.data = action.data.referenceResults;
        }

        if (resourceName === "scanGroups" && action.data) {
            const groups = get(action, "data.groups.group", []);

            action.data = toArray(groups).map((item) => ({
                groupNumber: item.groupnumber,
                groupName: item.groupname,
                status: item.status,
                totalUtilities: item.totalutilities,
                totalPrograms: item.totalprograms,
                totalScans: item.totalscans,
                totalUsers: item.totalusers,
                userList: toArray(get(item, "userlist.user", [])).map((i) => ({
                    userNumber: i.userNumber,
                    userName: i.username,
                })),
                programList: toArray(get(item, "programlist.program", [])).map((i) => ({
                    programNumber: i.progid,
                    programName: i.program,
                })),
            }));
        }

        if (resourceName === "scanGroupProgramOrder") {
            const programs = get(action, "data.programs.program", []);

            action.data = toArray(programs).map((item) => ({
                programNumber: item.progid,
                utilityName: item.utility,
                programName: item.program,
                queue: Number(item.total),
                itemOrder: Number(item.itemOrder),
                groupList: toArray(get(item, "grouplist.group", [])).map((i) => ({
                    groupNumber: i.groupnumber,
                    groupName: i.groupname,
                })),
            }));
        }

        if (resourceName === "scanGroupPrograms" && action.data && action.data.scanGroupProgramList) {
            action.data = {
                scanGroupProgramList: action.data.scanGroupProgramList.map((item) => ({
                    programNumber: item.progId,
                    programName: item.programName,
                    assigned: item.assigned,
                })),
            };
        }

        if (resourceName === "programFormPages" && action.data && action.data.configuration) {
            let configuration = null;

            try {
                configuration = JSON.parse(action.data.configuration);
                configuration = normalizeFormConfiguration({ configuration });
            } catch {
                toast.error(`Page "${action.data.name}" configuration parse error`);
                logError(`Page "${action.data.name}" configuration parse error:\n${action.data.configuration}`);
                configuration = null;
            }

            action.data = {
                ...action.data,
                configuration,
            };
        }

        // Application form data in "New Application" and "Application Forms" section
        if (["applicationFormDetails", "applicationFormPageDetails"].includes(resourceName) && action.data) {
            let configuration = action.data.formConfiguration;

            try {
                configuration = normalizeFormConfiguration({ configuration });
            } catch {
                logError(`Application Form Configuration error:\n${action.data.formConfiguration}`);
                configuration = null;
            }

            action.data = {
                ...action.data,
                formConfiguration: configuration,
            };
        }

        if (resourceName === "eventsInCategory" && action.data) {
            action.data = toArray(action.data?.event?.eventItem);
        }

        // Override responses
        if (isDevEnvironment) {
            if (resourceName === "userRights" && action.data) {
                switch (query.entityType) {
                    case "2":
                        const overrideUtilityLevelRights = sessionStorage.getItem("overrideUtilityLevelRights");
                        if (overrideUtilityLevelRights) {
                            action.data = {
                                rights: convertToArray(overrideUtilityLevelRights),
                            };
                        }
                        break;
                    case "3":
                        const overrideProgramLevelRights = sessionStorage.getItem("overrideProgramLevelRights");
                        if (overrideProgramLevelRights) {
                            action.data = {
                                rights: convertToArray(overrideProgramLevelRights),
                            };
                        }
                        break;
                    default:
                        break;
                }
            }
        }

        if (transform) {
            action.data = transform(action.data);
        }
    }

    async function onReadComplete(action) {}

    async function onResourceChanged(action) {
        if (action.passThroughData) {
            const resourceCallbackKey = getResourceCallbackKey(action.passThroughData);

            if (
                [
                    actionType.API_CRUD_READ_LIST_SUCCESS,
                    actionType.API_CRUD_READ_SUCCESS,
                    actionType.API_CRUD_CREATE_SUCCESS,
                    actionType.API_CRUD_UPDATE_SUCCESS,
                    actionType.API_CRUD_DELETE_SUCCESS,
                ].includes(action.type)
            ) {
                if (get(action, "data.responseStatus") === "failure") {
                    callResourceCallbacks(action, CALLBACK_TYPE.ERROR);
                } else {
                    callResourceCallbacks(action, CALLBACK_TYPE.SUCCESS);
                }

                callResourceCallbacks(action, CALLBACK_TYPE.COMPLETE);
            }

            if (
                [
                    actionType.API_CRUD_READ_LIST_ERROR,
                    actionType.API_CRUD_READ_ERROR,
                    actionType.API_CRUD_CREATE_ERROR,
                    actionType.API_CRUD_UPDATE_ERROR,
                    actionType.API_CRUD_DELETE_ERROR,
                ].includes(action.type)
            ) {
                callResourceCallbacks(action, CALLBACK_TYPE.ERROR);
                callResourceCallbacks(action, CALLBACK_TYPE.COMPLETE);
            }

            delete resourceCallbacks[resourceCallbackKey];
        }
    }
}

const callResourceCallbacks = (action, callbackType) => {
    const { resourceName, resourceId, key, onSuccess, onError, onComplete } = action.passThroughData;
    const resourceCallbackKey = getResourceCallbackKey({ resourceName, resourceId, key });

    switch (callbackType) {
        case CALLBACK_TYPE.SUCCESS:
            onSuccess?.(action);
            (resourceCallbacks[resourceCallbackKey] ?? []).forEach((callbacks) => callbacks.onSuccess?.(action));
            break;
        case CALLBACK_TYPE.ERROR:
            onError?.(action);
            (resourceCallbacks[resourceCallbackKey] ?? []).forEach((callbacks) => callbacks.onError?.(action));
            break;
        case CALLBACK_TYPE.COMPLETE:
            onComplete?.(action);
            (resourceCallbacks[resourceCallbackKey] ?? []).forEach((callbacks) => callbacks.onComplete?.(action));
            break;
        default:
            break;
    }
};
