import React from "react";

const Tile = ({ title, body, windowTitle, windowComponent, props, onClick }) => (
    <div
        className="tile flex-row fill-width no-shrink flex-padding"
        onClick={() =>
            onClick({
                name: windowTitle,
                component: windowComponent,
                props: props,
            })
        }
    >
        <div className="flex-column">
            <div className="header">{title}</div>
            <small>{body}</small>
        </div>
        <div className="flex-one" />
    </div>
);

export default Tile;
