import React, { useState, useEffect, memo } from "react";
import { useSelector } from "react-redux";
import ClassNames from "classnames";
import cn from "classnames";

import { windowContainerTypes } from "../../../utils/window";

import IconWithLabel from "../../Icons/IconWithLabel";
import IconWrap from "../../Icons";

import "./style.scss";

const WindowBodyHeader = memo((props) => {
    const { activeDashboardId, withShadow, noPopupDetailsOnMobile, flagsAndTagsAndAttributesSpecificLayout, showSpecificOptionalText } =
        props;

    const isActiveConfiguration = useSelector((state) => state.dashboards.activeWidgetConfiguration[activeDashboardId]) || false;

    const [expandSubHeader, setExpandSubHeader] = useState(false);

    const isMobile = useSelector((state) => state.window?.isMobile);
    const isTablet = useSelector((state) => state.window?.isTablet);
    const isSplitView = useSelector((state) => state.window[windowContainerTypes.Root].views.find((view) => view.active)?.isSplitView);

    useEffect(() => {
        if (isActiveConfiguration) {
            setExpandSubHeader(true);
        }
    }, [isActiveConfiguration]);

    const showOptionalText = () => {
        return expandSubHeader ? "Hide details" : "Show details";
    };

    const windowBodyHeaderClass = ClassNames("window-body-header", {
        "with-shadow": withShadow,
        "no-popup-details-on-mobile": noPopupDetailsOnMobile,
    });

    return (
        <div className={windowBodyHeaderClass}>
            <div className={flagsAndTagsAndAttributesSpecificLayout ? "" : "main-grid-wrap responsive"}>
                {(isMobile || isTablet || isSplitView) && (
                    <div
                        className={"sub-header-expand" + (expandSubHeader ? " active" : "")}
                        onClick={() => setExpandSubHeader(!expandSubHeader)}
                    >
                        <IconWithLabel
                            iconWithLabelRight
                            iconWithLabel={expandSubHeader ? "shevron-up-keyboard-arrow-up" : "shevron-down-keyboard-arrow-down"}
                            active={expandSubHeader}
                        >
                            {showSpecificOptionalText || showOptionalText()}
                        </IconWithLabel>
                    </div>
                )}
                <div
                    className={cn("header-wrap", {
                        "no-padding": flagsAndTagsAndAttributesSpecificLayout,
                    })}
                >
                    {isMobile && !noPopupDetailsOnMobile && (
                        <div className="popup-header-details">
                            {props.windowBodyHeaderName} Details
                            <IconWrap icon="clear-close" onClick={() => setExpandSubHeader(!expandSubHeader)} />
                        </div>
                    )}
                    {props.children}
                </div>
            </div>
        </div>
    );
});

export default WindowBodyHeader;
