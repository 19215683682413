import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMainSearchCriteria, DEFAULT_MAIN_CRITERIA, isCisSearchCriteria } from "../../../store/globalSearch/utils";
import WaitIcon from "../WaitIcon";
import { useSearchOptions } from "../../../store/resources/useResource";
import { clickMainCriteria } from "../../../store/globalSearch/actions";
import { CriteriaButton } from "./CriteriaButton";

import "./MainCriteriaSection.scss";

const MainCriteriaSection = memo(({ instanceId }) => {
    const dispatch = useDispatch();
    const selectedCriteria = useSelector((state) => state.globalSearch.selectedMainCriteria[instanceId]);
    const [searchOptions, isLoadingOptions] = useSearchOptions();

    const sortedCriteriaList = useMemo(() => {
        const searchCriteriaList = getMainSearchCriteria({ searchOptions });
        const programCriteria = searchCriteriaList.find((criteria) => criteria.title.toLowerCase() === "program");
        const utilityCriteria = searchCriteriaList.find((criteria) => criteria.title.toLowerCase() === "utility");

        let list = searchCriteriaList.filter((i) => i.key !== programCriteria?.key && i.key !== utilityCriteria?.key);

        if (!isCisSearchCriteria({ criteria: selectedCriteria })) {
            utilityCriteria && list.push(utilityCriteria);
            programCriteria && list.push(programCriteria);
        }

        return list;
    }, [searchOptions, selectedCriteria]);

    useEffect(() => {
        if (!selectedCriteria) {
            dispatch(
                clickMainCriteria({
                    instanceId,
                    criteria: DEFAULT_MAIN_CRITERIA,
                })
            );
        }
    }, [instanceId, selectedCriteria, dispatch]);

    const onSelect = useCallback(
        (criteria) => {
            dispatch(clickMainCriteria({ instanceId, criteria }));
        },
        [instanceId, dispatch]
    );

    if (isLoadingOptions) {
        return <WaitIcon />;
    }

    return (
        <div className="global-search__main-criteria flex-column fill-width no-shrink">
            <div className="global-search__section-title">Where to search?</div>
            <div className="global-search__main-criteria-list flex-row flex-wrap align-center">
                {sortedCriteriaList.map((criteria) => (
                    <CriteriaButton
                        instanceId={instanceId}
                        key={criteria.key}
                        criteria={criteria}
                        active={criteria.key === selectedCriteria?.key}
                        onClick={() => onSelect(criteria)}
                        mainCriteria
                    />
                ))}
            </div>
        </div>
    );
});

export default MainCriteriaSection;
