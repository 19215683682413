import React, { useReducer, useCallback, useState, useMemo, useEffect, useRef, memo } from "react";
import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { cloneDeep, isEmpty, isNumber, isObject } from "lodash";

import { openProjectTab } from "../../../../../utils/window";
import { getResource, updateResource } from "../../../../../../store/resources/actions";
import { modalOpen, modalClose } from "../../../../../../store/modal/actions";
import { useResource } from "../../../../../../store/resources/useResource";

import WaitIcon from "../../../../WaitIcon";
import Checkbox from "../../../../Input/Checkbox";
import Button from "../../../../Button";
import ButtonGroup from "../../../../Button/ButtonGroup";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import ViewPlaceholder from "../../../../ViewPlaceholder";
import NothingFoundBlock from "../../../../NothingFoundBlock";
import IconWithLabel from "../../../../Icons/IconWithLabel";

import { RebateApprovalsItem } from "./RebateApprovalsItem";
import { RebateApprovalsFilters } from "./RebateApprovalsFilters";
import StickyBottomPanel from "components/ui/StickyBottomPanel";
import { BulkApproveRebateTotal } from "./BulkApproveRebateTotal";

import "./style.scss";

/**
 * @function getApprovedAndPendingLevels
 * @param {array} approvals
 * @description Splits approvals of rebateApprovalItem by 'approved' property.
 * @returns {*}
 */

const getApprovedAndPendingLevels = (approvals) =>
    approvals.reduce(
        (acc, item) => {
            if (item.hasOwnProperty("approved")) {
                item.approved ? acc.approvedLevels.push(item) : acc.pendingLevels.push(item);
            }

            return acc;
        },
        { approvedLevels: [], pendingLevels: [] }
    );

/**
 * @function getAllRebateApprovalsLevelsWithPending
 * @param rebateApprovalsItems
 * @description Takes all rebate approval levels groupRebateIds with 'enabled' and 'allowed' flags from
 * rebateApprovalItems to use it in bulk operations.
 * @returns {array}
 */

const getAllRebateApprovalsLevelsWithPending = (rebateApprovalsItems) =>
    rebateApprovalsItems.reduce((acc, { pendingLevels }) => {
        if (pendingLevels[0]) {
            const { groupRebateId, enabled, allowed } = pendingLevels[0];

            if (enabled && allowed) {
                acc.push({
                    groupRebateId,
                    loading: false,
                });
            }
        }

        return acc;
    }, []);

const getApplicationsTotalRebateApprovalsLevelsWithPending = (rebateApprovalsItems) => {
    return rebateApprovalsItems.reduce((acc, { pendingLevels, projectList }) => {
        if (pendingLevels[0]) {
            const { enabled, allowed } = pendingLevels[0];

            if (enabled && allowed) {
                projectList.map((i) => {
                    if (!acc.includes(i.appId)) {
                        acc.push(i.appId);
                    }

                    return null;
                });
            }
        }

        return acc;
    }, []).length;
};

/**
 * @function activeRebateApprovalsLevelsReducer
 * @param {array} state
 * @param {object} action
 * @description Provides single source of truth for activeRebateApprovalsLevels. It is necessary for working with
 * async 'loading' states of active rebate approval levels.
 * @returns {array}
 */

const activeRebateApprovalsLevelsReducer = (state, action) => {
    switch (action.type) {
        case "set":
            return action.payload;

        case "updateLoading":
            const { list, loading } = action.payload;

            return state.map((activeRebateApprovalsLevel) => {
                if (list.some((groupRebateId) => activeRebateApprovalsLevel.groupRebateId === groupRebateId)) {
                    return {
                        ...activeRebateApprovalsLevel,
                        loading,
                    };
                }

                return activeRebateApprovalsLevel;
            });

        case "delete":
            return [];

        default:
            throw new Error();
    }
};

const RejectModal = memo(({ utilityNumber, groupRebateId, refreshRebateApprovalList }) => {
    const dispatch = useDispatch();

    const [isApprovalRejecting, setApprovalRejecting] = useState(false);
    const didCancel = useRef(false);

    useEffect(() => {
        return () => {
            didCancel.current = true;
        };
    }, []);

    const handleClickConfirm = useCallback(() => {
        if (!isApprovalRejecting) {
            setApprovalRejecting(true);

            dispatch(
                updateResource({
                    resourceName: "utilitiesRebateRejections",
                    key: utilityNumber,
                    path: {
                        utilityNumber,
                    },
                    body: {
                        groupRebateId,
                    },
                    onSuccess: () => refreshRebateApprovalList(() => dispatch(modalClose())),
                    onError: () => {
                        if (!didCancel.current) {
                            setApprovalRejecting(false);
                        }
                    },
                })
            );
        }
    }, [isApprovalRejecting, utilityNumber, groupRebateId, refreshRebateApprovalList, dispatch]);

    return (
        <div className="rebate-approvals__reject-modal">
            <p>Are you sure you want to reject this payment?</p>
            <ButtonGroup transparent>
                <Button onClick={handleClickConfirm} disabled={isApprovalRejecting} primary>
                    {isApprovalRejecting ? "Processing..." : "Confirm"}
                </Button>
                <Button onClick={() => dispatch(modalClose())} disabled={isApprovalRejecting}>
                    Cancel
                </Button>
            </ButtonGroup>
        </div>
    );
});

const RebateApprovalsPanel = memo(({ panel }) => {
    const dispatch = useDispatch();
    const didCancel = useRef(false);

    const utilityNumber = panel.data.utilityNumber;
    const rebateApprovalList = useSelector(
        (state) => state.resources.utilitiesRebateApprovals.itemsById[utilityNumber]?.rebateApprovalList
    );

    const [filter, setFilter] = useState({});
    const [isRebateApprovalsLoading, setRebateApprovalsLoading] = useState(false);
    const [rebateApprovalsItems, setRebateApprovalsItems] = useState([]);
    const [areAllRebateApprovalsLevelsActive, setAllRebateApprovalsLevelsActive] = useState(false);
    const [activeRebateApprovalsLevels, activeRebateApprovalsLevelsDispatch] = useReducer(activeRebateApprovalsLevelsReducer, []);
    const [hasShowButtonTouched, setHasShowButtonTouched] = useState(false);
    const [isVisibleApprovePanel, setIsVisibleApprovePanel] = useState(false);
    const [isManualCheck, setIsManualCheck] = useState(false);
    const [isFilterVisible, setIsFilterVisible] = useState(true);
    const [isTotalsFilterVisible, setIsTotalsFilterVisible] = useState(true);

    const bulkApproveRebateTotal = useMemo(
        () =>
            activeRebateApprovalsLevels
                // find approval items by checked approvals
                .map((i) => rebateApprovalsItems.find((r) => r.pendingLevels.some((l) => l.groupRebateId === i.groupRebateId)))
                // take valid items
                .filter(isObject)
                // sum all totals
                .reduce(
                    (result, item) => {
                        result.rebateTotal += isNumber(item.rebateTotal) ? item.rebateTotal : 0;

                        (item.projectList ?? [])
                            .flatMap((p) => p.measuresList ?? [])
                            .forEach((measure) => {
                                result.kwImpact += isNumber(measure.kwImpact) ? measure.kwImpact : 0;
                                result.kwhImpact += isNumber(measure.kwhImpact) ? measure.kwhImpact : 0;
                                result.thermImpact += isNumber(measure.thermImpact) ? measure.thermImpact : 0;
                                result.peakThermImpact += isNumber(measure.peakThermImpact) ? measure.peakThermImpact : 0;
                            });

                        return result;
                    },
                    {
                        rebateTotal: 0,
                        kwImpact: 0,
                        kwhImpact: 0,
                        thermImpact: 0,
                        peakThermImpact: 0,
                    }
                ),
        [activeRebateApprovalsLevels, rebateApprovalsItems]
    );

    const [programs, isLoadingPrograms] = useResource({
        resourceName: "utilitiesRebateApprovalsPrograms",
        key: utilityNumber,
        path: {
            utilityNumber,
        },
    });

    useEffect(() => {
        setRebateApprovalsItems([]);

        return () => {
            didCancel.current = true;
        };
    }, []);

    useEffect(() => {
        if (rebateApprovalList && hasShowButtonTouched) {
            const rebateApprovalsItems = rebateApprovalList.map((item) => ({
                ...item,
                ...getApprovedAndPendingLevels(item.approvalList),
            }));

            setRebateApprovalsItems(rebateApprovalsItems);
            activeRebateApprovalsLevelsDispatch({ type: "delete" });
        }
    }, [rebateApprovalList, hasShowButtonTouched]);

    /**
     * @description Compares arrays of active rebate approvals and all rebate approvals with 'pending' states. If
     * they are equal then checkbox 'Check all' will be active.
     */

    useEffect(() => {
        const rebateApprovalsWithPendingLevel = getAllRebateApprovalsLevelsWithPending(rebateApprovalsItems);

        if (!isEmpty(rebateApprovalsWithPendingLevel)) {
            let flag = true;

            for (const rebateApprovalWithPendingLevel of rebateApprovalsWithPendingLevel) {
                const rebateApprovalsLevel = activeRebateApprovalsLevels.find(
                    ({ groupRebateId }) => rebateApprovalWithPendingLevel.groupRebateId === groupRebateId
                );

                if (!rebateApprovalsLevel) {
                    flag = false;
                    break;
                }
            }

            setAllRebateApprovalsLevelsActive(flag);
        }
    }, [rebateApprovalsItems, activeRebateApprovalsLevels]);

    const getRebateApprovalList = useCallback(
        ({ filter }) => {
            setFilter(filter);

            const { programList, dateList, receivedDateList, stateList, approvalStatus } = filter;

            if (!isEmpty(programList)) {
                setRebateApprovalsLoading(true);

                dispatch(
                    getResource({
                        resourceName: "utilitiesRebateApprovals",
                        key: utilityNumber,
                        path: {
                            utilityNumber,
                        },
                        query: {
                            programList,
                            dateList,
                            receivedDateList,
                            stateList,
                            approvalStatus,
                        },
                        onComplete: () => {
                            if (!didCancel.current) {
                                if (!hasShowButtonTouched) {
                                    setHasShowButtonTouched(true);
                                }

                                setRebateApprovalsLoading(false);
                            }
                        },
                    })
                );
            }
        },
        [utilityNumber, hasShowButtonTouched, dispatch]
    );

    const refreshRebateApprovalList = useCallback(
        (cb) => {
            const { programList, dateList, receivedDateList, stateList, approvalStatus } = filter;

            dispatch(
                getResource({
                    resourceName: "utilitiesRebateApprovals",
                    key: utilityNumber,
                    path: {
                        utilityNumber,
                    },
                    query: {
                        programList,
                        dateList,
                        receivedDateList,
                        stateList,
                        approvalStatus,
                    },
                    onComplete: () => {
                        if (!didCancel.current) {
                            if (cb) {
                                cb();
                            }
                        }
                    },
                })
            );
        },
        [utilityNumber, filter, dispatch]
    );

    const handleSetActiveRebateApprovalLevel = useCallback(
        (e, groupRebateId, id) => {
            if (e.target.checked) {
                activeRebateApprovalsLevelsDispatch({
                    type: "set",
                    payload: [...cloneDeep(activeRebateApprovalsLevels), { groupRebateId, id, loading: false }],
                });

                setIsManualCheck(true);
                setIsVisibleApprovePanel(true);
            } else {
                activeRebateApprovalsLevelsDispatch({
                    type: "set",
                    payload: activeRebateApprovalsLevels.filter((item) => item.id !== id),
                });

                setIsManualCheck(activeRebateApprovalsLevels.length !== 1);
                setIsVisibleApprovePanel(activeRebateApprovalsLevels.length !== 1);
            }
        },
        [activeRebateApprovalsLevels]
    );

    const handleBulkSetActiveApprovalLevels = useCallback(
        (e) => {
            setIsManualCheck(false);

            if (!e.target.checked) {
                return activeRebateApprovalsLevelsDispatch({ type: "delete" });
            }

            const activeRebateApprovalLevels = getAllRebateApprovalsLevelsWithPending(rebateApprovalsItems);

            activeRebateApprovalsLevelsDispatch({
                type: "set",
                payload: activeRebateApprovalLevels,
            });
        },
        [rebateApprovalsItems]
    );

    const handleSetApproveAll = useCallback(() => {
        activeRebateApprovalsLevelsDispatch({
            type: "set",
            payload: getAllRebateApprovalsLevelsWithPending(rebateApprovalsItems),
        });

        setIsVisibleApprovePanel(true);
        setIsManualCheck(false);
    }, [rebateApprovalsItems]);

    const cancelApprove = useCallback(() => {
        activeRebateApprovalsLevelsDispatch({ type: "delete" });

        setIsVisibleApprovePanel(false);
        setIsManualCheck(false);
    }, []);

    const handleCancelApprove = useCallback(() => {
        if (!isManualCheck) {
            cancelApprove();
        } else {
            const dialogMessage = <p>Would you like to reset payments marked for approval?</p>;
            dispatch(
                modalOpen({
                    modalType: "CONFIRM",
                    modalProps: {
                        title: "Cancel Approval",
                        overlayClassName: "modal-styled",
                        className: "modal-sm",
                        footerContentCenter: true,
                        content: dialogMessage,
                        onConfirm: () => {
                            cancelApprove();
                        },
                    },
                })
            );
        }
    }, [isManualCheck, cancelApprove, dispatch]);

    const handleClickApprove = useCallback(
        (groupRebateId) => {
            if (
                activeRebateApprovalsLevels.some((activeRebateApprovalsLevel) => activeRebateApprovalsLevel.groupRebateId === groupRebateId)
            ) {
                const clearLoadingState = () => {
                    if (!didCancel.current) {
                        activeRebateApprovalsLevelsDispatch({
                            type: "updateLoading",
                            payload: { list: [groupRebateId], loading: false },
                        });
                    }
                };

                activeRebateApprovalsLevelsDispatch({
                    type: "updateLoading",
                    payload: { list: [groupRebateId], loading: true },
                });

                dispatch(
                    updateResource({
                        resourceName: "utilitiesRebateApprovals",
                        key: utilityNumber,
                        path: {
                            utilityNumber,
                        },
                        body: [groupRebateId],
                        onSuccess: () => refreshRebateApprovalList(clearLoadingState),
                        onError: clearLoadingState,
                    })
                );
            }
        },
        [utilityNumber, activeRebateApprovalsLevels, refreshRebateApprovalList, dispatch]
    );

    const isApprovingProcess = useMemo(() => {
        activeRebateApprovalsLevels.some((item) => item.loading);
    }, [activeRebateApprovalsLevels]);

    const handleClickBulkApprove = useCallback(() => {
        setIsManualCheck(false);

        if (!isEmpty(activeRebateApprovalsLevels) && !isApprovingProcess) {
            const list = activeRebateApprovalsLevels.map((level) => level.groupRebateId);

            const clearLoadingStates = () => {
                if (!didCancel.current) {
                    activeRebateApprovalsLevelsDispatch({
                        type: "updateLoading",
                        payload: { list, loading: false },
                    });
                }
            };

            activeRebateApprovalsLevelsDispatch({
                type: "updateLoading",
                payload: { list, loading: true },
            });

            dispatch(
                updateResource({
                    resourceName: "utilitiesRebateApprovals",
                    key: utilityNumber,
                    path: {
                        utilityNumber,
                    },
                    body: list,
                    onSuccess: () => refreshRebateApprovalList(clearLoadingStates),
                    onError: clearLoadingStates,
                })
            );
        }
    }, [utilityNumber, activeRebateApprovalsLevels, refreshRebateApprovalList, isApprovingProcess, dispatch]);

    const handleClickReject = useCallback(
        (groupRebateId) => {
            dispatch(
                modalOpen({
                    modalType: "MODAL",
                    modalProps: {
                        title: "Reject Payment",
                        overlayClassName: "modal-styled",
                        className: "reject-payment-confirmation-modal modal-sm",
                        modalIcon: "thumb-down-empty",
                        children: (
                            <RejectModal
                                utilityNumber={utilityNumber}
                                groupRebateId={groupRebateId}
                                refreshRebateApprovalList={refreshRebateApprovalList}
                            />
                        ),
                        noFooter: true,
                    },
                })
            );
        },
        [utilityNumber, refreshRebateApprovalList, dispatch]
    );

    const handleClickOpenProject = useCallback((applicationNumber) => {
        openProjectTab({ applicationNumber });
    }, []);

    const showNothingFoundBlock = !isLoadingPrograms && isEmpty(programs?.programList);
    const showRebateApprovals = !isLoadingPrograms && !showNothingFoundBlock;
    const showTotals = bulkApproveRebateTotal.rebateTotal > 0 && isTotalsFilterVisible;

    return (
        <div className="rebate-approvals flex-column fill-height">
            <PanelHeaderLarge title={panel.name}>
                {!isEmpty(filter?.programList) && hasShowButtonTouched && !isEmpty(rebateApprovalsItems) && (
                    <>
                        {!isVisibleApprovePanel &&
                            !isEmpty(getAllRebateApprovalsLevelsWithPending(rebateApprovalsItems)) &&
                            getAllRebateApprovalsLevelsWithPending(rebateApprovalsItems).length > 1 && (
                                <IconWithLabel iconWithLabelRight icon="thumb-up-empty" onClick={handleSetApproveAll}>
                                    Approve All Pending Payments ({getAllRebateApprovalsLevelsWithPending(rebateApprovalsItems).length}) in{" "}
                                    {getApplicationsTotalRebateApprovalsLevelsWithPending(rebateApprovalsItems)} project
                                    {getApplicationsTotalRebateApprovalsLevelsWithPending(rebateApprovalsItems) === 1 ? "" : "s"}
                                </IconWithLabel>
                            )}
                        <IconWithLabel
                            iconWithLabelRight
                            icon={isFilterVisible ? "shevron-in-circle-up-filled--before" : "shevron-in-circle-down-drop-down-empty"}
                            onClick={() => setIsFilterVisible(!isFilterVisible)}
                        >
                            {isFilterVisible ? "Hide Filter" : "Show Filter"}
                        </IconWithLabel>
                    </>
                )}
            </PanelHeaderLarge>
            {isLoadingPrograms && <WaitIcon />}
            {showNothingFoundBlock && (
                <div className="flex-column flex-one-in-column">
                    <NothingFoundBlock icon="search-off" title="There are no programs to list approvals for" />
                </div>
            )}
            {showRebateApprovals && (
                <>
                    <RebateApprovalsFilters
                        utilityNumber={utilityNumber}
                        isFilterVisible={isFilterVisible}
                        onSubmit={getRebateApprovalList}
                    />
                    {isRebateApprovalsLoading ? (
                        <WaitIcon />
                    ) : (
                        <div
                            className={cn("rebate-approvals__items flex-column flex-one-in-column no-scroll", {
                                "with-border-top": !isEmpty(filter?.programList) && !isFilterVisible,
                                "with-border-bottom":
                                    !isEmpty(filter?.programList) &&
                                    !isEmpty(rebateApprovalsItems) &&
                                    !isEmpty(getAllRebateApprovalsLevelsWithPending(rebateApprovalsItems)) &&
                                    !isVisibleApprovePanel,
                                "nothing-to-approve": isEmpty(getAllRebateApprovalsLevelsWithPending(rebateApprovalsItems)),
                                "with-totals": showTotals,
                            })}
                        >
                            <div className="flex-column flex-one-in-column with-scroll">
                                {!isEmpty(filter?.programList) &&
                                    rebateApprovalsItems.map((rebateApprovalsItem) => (
                                        <RebateApprovalsItem
                                            key={rebateApprovalsItem.rebateIds}
                                            {...rebateApprovalsItem}
                                            onClickOpenProject={handleClickOpenProject}
                                            onClickApprove={handleClickApprove}
                                            onClickReject={handleClickReject}
                                            onSetActiveRebateApprovalLevel={handleSetActiveRebateApprovalLevel}
                                            activeRebateApprovalsLevels={activeRebateApprovalsLevels}
                                        />
                                    ))}
                                {isEmpty(filter?.programList) && (
                                    <ViewPlaceholder viewPlaceholderSmall>Please select Program to get results.</ViewPlaceholder>
                                )}
                                {!isEmpty(filter?.programList) && hasShowButtonTouched && isEmpty(rebateApprovalsItems) && (
                                    <NothingFoundBlock icon="search-off" title="No payments awaiting approval could be found" />
                                )}
                            </div>
                            <StickyBottomPanel
                                visible={
                                    !isEmpty(filter?.programList) &&
                                    !isEmpty(rebateApprovalsItems) &&
                                    !isEmpty(getAllRebateApprovalsLevelsWithPending(rebateApprovalsItems)) &&
                                    isVisibleApprovePanel
                                }
                            >
                                <div className="main-grid-wrap responsive flex-column fill-height justify-end">
                                    {isTotalsFilterVisible && <BulkApproveRebateTotal totals={bulkApproveRebateTotal} />}
                                    <div className="flex-row align-center">
                                        <div className="flex-one-in-row flex justify-end">
                                            <Checkbox
                                                onChange={handleBulkSetActiveApprovalLevels}
                                                checked={areAllRebateApprovalsLevelsActive}
                                                disabled={isApprovingProcess}
                                                label={
                                                    "Check all pending payments (" +
                                                    getAllRebateApprovalsLevelsWithPending(rebateApprovalsItems).length +
                                                    ")"
                                                }
                                                labelIconBig
                                            />
                                        </div>
                                        <ButtonGroup transparent>
                                            <Button
                                                onClick={handleClickBulkApprove}
                                                disabled={isApprovingProcess || isEmpty(activeRebateApprovalsLevels)}
                                                primary
                                            >
                                                {isApprovingProcess ? "Processing..." : "Approve Checked"}
                                            </Button>
                                            <Button onClick={handleCancelApprove}>Cancel</Button>
                                        </ButtonGroup>
                                        <div className="flex-one-in-row flex justify-end">
                                            <IconWithLabel
                                                iconWithLabelRight
                                                icon={
                                                    isTotalsFilterVisible
                                                        ? "shevron-in-circle-up-filled--before"
                                                        : "shevron-in-circle-down-drop-down-empty"
                                                }
                                                onClick={() => setIsTotalsFilterVisible(!isTotalsFilterVisible)}
                                            >
                                                {isTotalsFilterVisible ? "Hide Totals" : "Show Totals"}
                                            </IconWithLabel>
                                        </div>
                                    </div>
                                </div>
                            </StickyBottomPanel>
                        </div>
                    )}
                </>
            )}
        </div>
    );
});

export default RebateApprovalsPanel;
