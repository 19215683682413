import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { isNil } from "lodash";

import { createResource } from "../../../store/resources/actions";
import useUnmounted from "../../utils/useUnmounted";

import ViewPlaceholder from "../ViewPlaceholder";
import WaitIcon from "../WaitIcon";
import { ErrorMessage } from "../Message";

import "./style.scss";

const BASE_URL = `${process.env.REACT_APP_CRM_BASE_URL}`;

const CRM = (props) => {
    const dispatch = useDispatch();
    const [token, setToken] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const unmounted = useUnmounted();

    // Set new token only if not already set. To not reset CRM opened from app opportunity when switching home screen main menu items
    const shouldPreventTokenChange = !isNil(token) && isNil(props.token);

    useEffect(() => {
        if (!props.token) {
            if (!shouldPreventTokenChange) {
                dispatch(
                    createResource({
                        resourceName: "ssoToken",
                        key: "crmToken",
                        body: {
                            sourceSystem: "Vision Client",
                        },
                        onSuccess: (action) => !unmounted.current && setToken(action.data.token),
                        onComplete: () => !unmounted.current && setIsLoading(false),
                    })
                );
            }
        } else {
            setToken(props.token);
            setIsLoading(false);
        }
    }, [props.token, shouldPreventTokenChange, dispatch, unmounted]);

    if (isLoading) {
        return <WaitIcon />;
    }

    if (isNil(token)) {
        return (
            <ViewPlaceholder>
                <ErrorMessage>VisionCRM login failed</ErrorMessage>
            </ViewPlaceholder>
        );
    }

    return (
        <div className="crm">
            <span className="global-nav-shadow-imitation"></span>
            <iframe title="CRM" src={BASE_URL + token}></iframe>
        </div>
    );
};

export default CRM;
