import React, { memo, useCallback } from "react";

import withDataGrid from "../../../../DataGrid/withDataGrid";
import { programsDocumentsRevisionsGridColumnKeys } from "../../../../../views/configureGrids";

import DataGrid from "../../../../DataGrid";
import RevisionForm from "./RevisionForm";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import { isChildProgram } from "components/views/ProgramView/utils";

const DocumentRevisionsGrid = memo((props) => {
    const { gridId, dataGridConfig, programNumber } = props;
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.medium,
    });
    const isLocked = isChildProgram({ programNumber });

    const customRowActions = [
        {
            name: "edit",
            title: isLocked ? "View Revision" : "Edit Revision",
            icon: isLocked ? "eye-visibility-empty" : "edit-empty",
        },
    ];

    const onRowAction = useCallback(
        (action) => {
            const { name, dataItem } = action;

            switch (name) {
                case "edit":
                    handleOpenSidePanel(
                        <RevisionForm
                            dataItem={dataItem}
                            gridId={gridId}
                            programNumber={programNumber}
                            onClose={handleCloseSidePanel}
                            revisionNumber={dataItem[programsDocumentsRevisionsGridColumnKeys.correspondenceVersionNumber]}
                        />,
                        { className: "documents-revision-edit-sidenav-panel" }
                    );
                    break;
                default:
                    break;
            }
        },
        [gridId, programNumber, handleOpenSidePanel, handleCloseSidePanel]
    );

    return <DataGrid name={gridId} config={dataGridConfig} onRowAction={onRowAction} customRowActions={customRowActions} />;
});

export default withDataGrid("programsDocumentsRevisions", DocumentRevisionsGrid);
