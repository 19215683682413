import { throttle } from "lodash";

const OFFSET = 50; // This is the top/bottom offset to start scrolling in the div.
const PX_DIFF = 6;

// Object with active DragScrollListener instances
const instances = {};

/**
 * Listens for drag events in form-page-editor and starts to scroll
 * if drag is near the top or bottom of the scroll container.
 *
 * @class DragScrollListener
 */
class DragScrollListener {
    constructor(id) {
        this.scrollableElement = document.getElementById(id)?.closest(".form-page-editor");

        if (this.scrollableElement) {
            this.scrollIncrement = 0;
            this.isScrolling = false;

            this.scrollHeightSidebar = this.scrollableElement.scrollHeight;

            const clientRect = this.scrollableElement.getBoundingClientRect();
            this.clientRectTop = clientRect.top;
            this.clientRectBottom = clientRect.bottom;

            this.onDragOver = this.onDragOver.bind(this);

            this.throttleOnDragOver = throttle(this.onDragOver, 150);

            this.initEvents();
        }
    }

    initEvents = () => {
        if (this.scrollableElement) {
            this.removeEvents();
            this.scrollableElement.addEventListener("dragover", this.throttleOnDragOver);
        }
    };

    removeEvents = () => {
        if (this.scrollableElement) {
            this.scrollableElement.removeEventListener("dragover", this.throttleOnDragOver);
        }
    };

    /**
     * Scroll up.
     */
    goUp = () => {
        this.scrollIncrement -= PX_DIFF;
        this.scrollableElement.scrollTop = this.scrollIncrement;

        if (this.isScrolling && this.scrollIncrement >= 0) {
            window.requestAnimationFrame(this.goUp);
        }
    };

    /**
     * Scroll down.
     */
    goDown = () => {
        this.scrollIncrement += PX_DIFF;
        this.scrollableElement.scrollTop = this.scrollIncrement;

        if (this.isScrolling && this.scrollIncrement <= this.scrollHeightSidebar) {
            window.requestAnimationFrame(this.goDown);
        }
    };

    /**
     * "dragover" event handler.
     */
    onDragOver = (event) => {
        const isMouseOnTop = this.scrollIncrement >= 0 && event.clientY > this.clientRectTop && event.clientY < this.clientRectTop + OFFSET;

        const isMouseOnBottom =
            this.scrollIncrement <= this.scrollHeightSidebar &&
            event.clientY > this.clientRectBottom - OFFSET &&
            event.clientY <= this.clientRectBottom;

        if (!this.isScrolling && (isMouseOnTop || isMouseOnBottom)) {
            this.isScrolling = true;
            this.scrollIncrement = this.scrollableElement.scrollTop;

            if (isMouseOnTop) {
                window.requestAnimationFrame(this.goUp);
            } else {
                window.requestAnimationFrame(this.goDown);
            }
        } else if (!isMouseOnTop && !isMouseOnBottom) {
            this.isScrolling = false;
        }
    };
}

export const addDragScrollListener = (id) => {
    if (!instances[id]) {
        instances[id] = new DragScrollListener(id);
    }
};

export const removeDragScrollListener = (id) => {
    if (instances[id]) {
        instances[id].removeEvents();
        delete instances[id];
    }
};
