import React from "react";
import GridList from "../../../../List/GridList";
import ExpandablePanel from "../../../../ExpandablePanel";
import ActionLabel from "../../../../Label/ActionLabel";
import IconWrap from "../../../../Icons";

const columns = [
    {
        key: "calculationName",
        name: "CALCULATION NAME",
    },
    {
        key: "target",
        name: "TARGET",
    },
    {
        key: "revisionDate",
        name: "REVISION DATE",
    },
    {
        key: "userName",
        name: "USER NAME",
    },
    {
        key: "actions",
        name: "",
    },
];

const BulkAssignmentHeader = ({ rowData, onRemoveCalculation, onChangeSelection }) => {
    const title = (
        <>
            <span>{`${rowData.length} Calculations selected`}</span>
            <span className="flex-one" />
            <ActionLabel icon="clear-close" onClick={onChangeSelection}>
                Change Calculation selection
            </ActionLabel>
        </>
    );

    return (
        <div className="calculations-bulk-assignment-header">
            <ExpandablePanel title={title} className="flex-row align-center" panelIcon="shevron-right-keyboard-arrow-right">
                <GridList
                    columns={columns}
                    rows={rowData}
                    showActions
                    columnWidth={{
                        actions: 40,
                    }}
                    columnCellContent={{
                        actions: (column, row, onExpandRow) => {
                            return (
                                <IconWrap icon="clear-close" title="Remove from bulk Management" onClick={() => onRemoveCalculation(row)} />
                            );
                        },
                    }}
                />
            </ExpandablePanel>
        </div>
    );
};

export default BulkAssignmentHeader;
