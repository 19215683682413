import React from "react";
import { SECTION_WIDGETS } from "../../Section";
import { SectionHeader } from "../../SectionHeader";
import { WidgetProps } from "../types";
import { GlobalWidgetWarning } from "./GlobalWidgetWarning";
import { HeaderControls } from "./HeaderControls";
import { PreviewArea } from "./PreviewArea";

import "./WidgetHeader.scss";

export const WidgetHeader = ({ widget, onClose }: WidgetProps) => {
    const headerSubtitle = (
        <span>
            Edit <strong className="widget-title">{widget.title}</strong> widget styles
        </span>
    );

    return (
        <div className="widget-header">
            <HeaderControls onBack={onClose} />
            <GlobalWidgetWarning />
            <SectionHeader Icon={SECTION_WIDGETS.Icon} title={SECTION_WIDGETS.title} subtitle={headerSubtitle} />
            <PreviewArea widget={widget} />
        </div>
    );
};
