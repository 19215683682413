import React from "react";
import ClassNames from "classnames";

import "./style.scss";

const StatusMsg = (props) => {
    const className = ClassNames("status-msg with-icon", props.msgIcon, props.className, {
        "error-msg circle-exclamation-filled": props.msgError,
        "warning-msg warning-report-problem-filled": props.msgWarning,
        "info-msg info-filled": props.msgInfo,
        "success-msg check-circle-filled": props.msgSuccess,
        "msg-visible": props.msgVisible || props.msgInfo || props.msgError || props.msgWarning || props.msgSuccess || props.msgStandard,
        "msg-icon-right": props.msgIconRight,
        "msg-field-status": props.msgFieldStatus,
        "msg-standard": props.msgStandard,
        "msg-centered": props.msgCentered,
        "msg-square": props.msgSquare,
        "msg-has-content": props.children,
    });

    return (
        <div className={className}>
            {props.msgText}
            {props.children}
        </div>
    );
};

export default StatusMsg;
