import React, { memo, useCallback, useEffect } from "react";
import getPath from "lodash/get";
import isNil from "lodash/isNil";

import { referenceTypes } from "../../../Reference/referenceTypes";
import { useReference } from "../../../Reference/useReference";
import { useResource } from "../../../../../store/resources/useResource";
import { calculationTypes } from "./utils";

import JsonSchemaForm from "../../../Form/JsonSchema/JsonSchemaForm";
import IconWithLabel from "../../../Icons/IconWithLabel";

import "./CalculationHeaderForm.scss";

const useCalculationTarget = ({ utilityNumber, programNumber, calculationType, targetAttrId }) => {
    let resourceParams = null;
    let targetListKey = null;
    let targetIdKey = null;
    let targetNameKey = null;
    let targetNameKeyAlternative = null;
    let targetTitle = null;

    if (calculationType === calculationTypes.catalog) {
        resourceParams = {
            resourceName: "calculationAttributes",
            key: utilityNumber,
            query: {
                utilityNumber,
            },
        };

        targetListKey = "calculationAttributesList";
        targetIdKey = "productAttrStandardId";
        targetNameKey = "productAttrDesc";
        targetNameKeyAlternative = "productAttrDesc";
        targetTitle = "Target Attribute";
    } else if (calculationType === calculationTypes.event) {
        resourceParams = {
            resourceName: "calculationEventAttributes",
            key: utilityNumber,
            query: {
                utilityNumber,
            },
        };

        targetListKey = "calculationEventAttributesList";
        targetIdKey = "eventAttrTypeId";
        targetNameKey = "eventAttrName";
        targetNameKeyAlternative = "eventAttrName";
        targetTitle = "Target Attribute";
    } else {
        resourceParams = {
            resourceName: "calculationProgramFormFields",
            key: programNumber,
            query: {
                programNumber,
            },
        };

        targetListKey = "formFieldsList";
        targetIdKey = "fieldId";
        targetNameKey = "friendlyName";
        targetNameKeyAlternative = "fieldName";
        targetTitle = "Target Form Field";
    }

    const [targetList] = useResource(resourceParams);
    const targets = getPath(targetList, targetListKey, [])
        .filter(
            (i) =>
                isNil(i.status) ||
                i.status.toLowerCase() === "active" ||
                i.status.toLowerCase() === "disabled" ||
                i[targetIdKey]?.toString() === targetAttrId?.toString()
        )
        .map((i) => ({
            name: i[targetNameKey] || i[targetNameKeyAlternative] || "",
            value: i[targetIdKey],
        }));

    return [targets, targetTitle];
};

const CalculationHeaderForm = memo(
    ({
        utilityNumber,
        programNumber,
        formRef,
        values,
        initialValues,
        viewOnly,
        onChange,
        isSubmitting,
        isDetails,
        isNew,
        onShowRevisions,
        isProgramCalculation,
        onShowAssociations,
        sidePanel,
        disabled,
    }) => {
        const headerPart = getPath(values, "header", {});
        const valuesPart = getPath(values, "values", {});
        const targetAttrId = valuesPart.targetAttrId;
        const revisionDate = headerPart.revisionDate;
        const calculationType = headerPart.calculationType;

        const [calcQueues = []] = useReference(referenceTypes.calcQueues);
        const [targets, targetTitle] = useCalculationTarget({
            utilityNumber,
            programNumber,
            calculationType,
            targetAttrId,
        });

        const getInitialValues = useCallback(() => {
            if (viewOnly) {
                const { values } = initialValues;
                return {
                    ...initialValues,
                    ...{
                        values,
                        targetAttrId: String(targets.find((i) => i.value === targetAttrId)?.name ?? targetAttrId),
                    },
                };
            }
            return initialValues;
        }, [initialValues, viewOnly, targets, targetAttrId]);

        const schema = {
            type: "object",
            properties: {
                header: {
                    type: "object",
                    properties: {
                        calculationType: {
                            type: "string",
                            title: "Type",
                        },
                        user: {
                            type: "string",
                            title: "User",
                        },
                        revisionDate: {
                            type: "string",
                            title: "Revision Date",
                        },
                    },
                },
                values: {
                    type: "object",
                    required: viewOnly ? [] : ["calculationName", "processQueue", "targetAttrId"],
                    properties: {
                        calculationName: {
                            type: "string",
                            title: "Calculation Name",
                        },
                        processQueue: {
                            type: "string",
                            title: "Process Queue",
                            anyOf: calcQueues.map((i) => ({
                                title: i.display,
                                enum: [i.display],
                            })),
                        },
                        targetAttrId: {
                            type: "string",
                            title: targetTitle,
                        },
                    },
                },
            },
        };

        const uiSchema = {
            "ui:rootFieldId": "calculation",
            header: {
                classNames: "calculation-header flex-column",
                calculationType: {
                    "ui:widget": "ViewOnlyWidget",
                },
                user: {
                    "ui:widget": "ViewOnlyWidget",
                },
                revisionDate: {
                    "ui:widget": Boolean(revisionDate) ? "ViewOnlyWidget" : "hidden",
                },
            },
            values: {
                classNames: "calculation-values flex-column",
                calculationName: {
                    classNames: "calc-name",
                    "ui:widget": viewOnly ? "ViewOnlyWidget" : "textarea",
                },
                processQueue: {
                    "ui:options": {
                        placeholder: "Select Process Queue",
                    },
                    ...(viewOnly ? { "ui:widget": "ViewOnlyWidget" } : {}),
                },
                targetAttrId: {
                    "ui:widget": viewOnly ? "ViewOnlyWidget" : "select",
                    "ui:options": {
                        placeholder: "Select " + targetTitle,
                    },
                    "ui:enumItems": (targets ?? []).map((i) => ({
                        label: i.name,
                        value: i.value.toString(),
                    })),
                },
            },
        };

        useEffect(() => {
            sidePanel.setForm(formRef);
        }, [sidePanel, formRef]);

        return (
            <div className="calculation-header-form flex-column fill-height">
                <div className="flex-one">
                    <JsonSchemaForm
                        selectListsWithPopper
                        formRef={formRef}
                        schema={schema}
                        uiSchema={uiSchema}
                        initialValues={getInitialValues()}
                        onChange={(form) => onChange(form.formData)}
                        disabled={isSubmitting || disabled}
                        noActions
                    />
                </div>
                {(!isNew || isDetails) && (
                    <div>
                        {onShowRevisions && (
                            <IconWithLabel icon="assignment" onClick={onShowRevisions}>
                                See Revisions
                            </IconWithLabel>
                        )}
                        {!isProgramCalculation && onShowAssociations && (
                            <IconWithLabel icon="hub-empty" onClick={onShowAssociations}>
                                See Associations
                            </IconWithLabel>
                        )}
                    </div>
                )}
            </div>
        );
    }
);

export default CalculationHeaderForm;
