import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import TextInput from "../../../Input/TextInput";
import NumericInput from "../../../Input/NumericInput";

function BaseInput(props) {
    if (!props.id) {
        throw new Error(`no id for props ${JSON.stringify(props)}`);
    }

    const { value, readonly, disabled, autofocus, onBlur, onFocus, options, schema, formContext, registry, rawErrors, ...inputProps } =
        props;

    inputProps.type = options.inputType || inputProps.type || "text";
    const _onChange = ({ target: { value } }) => {
        return props.onChange(value === "" ? options.emptyValue : value);
    };

    const isError = !isEmpty(props.rawErrors);

    let component = (
        <TextInput
            id={inputProps.id}
            type={inputProps.type}
            name={inputProps.id}
            label={undefined}
            placeholder={inputProps.placeholder}
            readOnly={readonly}
            disabled={disabled}
            autoFocus={autofocus}
            minLength={schema.minLength}
            maxLength={schema.maxLength}
            value={value === null || value === undefined ? "" : value}
            onChange={_onChange}
            onBlur={onBlur && ((event) => onBlur(inputProps.id, event.target.value))}
            onFocus={onFocus && ((event) => onFocus(inputProps.id, event.target.value))}
            error={isError}
        />
    );

    if (["number", "integer"].indexOf(schema.type) > -1) {
        const min = schema.exclusiveMinimum ? schema.exclusiveMinimum + 1 : schema.minimum;
        const max = schema.exclusiveMaximum ? schema.exclusiveMaximum - 1 : schema.maximum;

        component = (
            <NumericInput
                id={inputProps.id}
                className={schema.type === "number" ? "fill-width" : undefined}
                label={undefined}
                placeholder={inputProps.placeholder}
                readOnly={readonly}
                disabled={disabled}
                autoFocus={autofocus}
                min={min}
                max={max}
                decimals={options.decimals}
                name={inputProps.id}
                value={value === null || value === undefined ? null : value}
                onChange={_onChange}
                onBlur={onBlur && ((event) => onBlur(inputProps.id, event.target.value))}
                onFocus={onFocus && ((event) => onFocus(inputProps.id, event.target.value))}
                error={isError}
            />
        );
    }

    return component;
}

BaseInput.defaultProps = {
    type: "text",
    required: false,
    disabled: false,
    readonly: false,
    autofocus: false,
};

if (process.env.NODE_ENV !== "production") {
    BaseInput.propTypes = {
        id: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        value: PropTypes.any,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    };
}

export default BaseInput;
