export const syncSessionStorage = () => {
    // Source from: https://stackoverflow.com/a/32766809
    // transfers sessionStorage from one tab to another
    const sessionStorageTransfer = function (event) {
        if (!event) {
            event = window.event;
        } // ie suq
        if (!event.newValue) return; // do nothing if no value to work with
        if (event.key === "getSessionStorage") {
            // another tab asked for the sessionStorage -> send it
            triggerSessionStorageSync();
        } else if (event.key === "sessionStorage" && !sessionStorage.length) {
            // another tab sent data <- get it
            const data = JSON.parse(event.newValue);
            for (let key in data) {
                sessionStorage.setItem(key, data[key]);
            }
        } else if (event.key.startsWith("sessionStorage.")) {
            // another tab sent data with key <- get it
            const itemKey = event.key.split(".")[1];
            const data = JSON.parse(event.newValue);

            if (data[itemKey]) {
                sessionStorage.setItem(itemKey, data[itemKey]);
            }
        }
    };

    // listen for changes to localStorage
    if (window.addEventListener) {
        window.addEventListener("storage", sessionStorageTransfer, false);
    } else {
        window.attachEvent("onstorage", sessionStorageTransfer);
    }

    // Ask other tabs for session storage (this is ONLY to trigger event)
    if (!sessionStorage.length) {
        localStorage.setItem("getSessionStorage", true);
        localStorage.removeItem("getSessionStorage");
    }
};

export const triggerSessionStorageSync = (itemKey = undefined) => {
    let key = "sessionStorage";
    if (itemKey) {
        key = [key, itemKey].join(".");
    }

    // send sessionStorage
    localStorage.setItem(key, JSON.stringify(sessionStorage));
    // the other tab should now have it, so we're done with it.
    localStorage.removeItem(key); // <- could do short timeout as well.
};
