import { isNil } from "lodash";
import { Image as BootstrapImage } from "react-bootstrap";
import cn from "classnames";

import "./ImageWidget.scss";

const ImageWidget = (props) => {
    const { options } = props;

    const imageSrc = options?.imageSrc;
    const imageAltText = options?.imagAltText;
    const imageSize = options?.imageSize;
    const customSize = Number(options?.imageSize);

    let customStyle;
    if (!isNaN(customSize)) {
        customStyle = { maxWidth: customSize + "%" };
    }

    if (isNil(imageSrc)) {
        return null;
    }

    return <BootstrapImage className={cn("image-widget-picture", imageSize)} fluid style={customStyle} src={imageSrc} alt={imageAltText} />;
};

export default ImageWidget;
