import React, { useMemo, memo } from "react";
import { formatJsonDate, formatJsonTime } from "../../../../utils/date";
import { getCompletedByLabel, isAutomated, isSkippedTask } from "../../utils";
import IconWrap from "../../../Icons";
import StatusMark from "../../../StatusMark";
import { isAdhocTask } from "../../../../utils/workflow";
import { TaskIndicator } from "../TaskIndicator";
import { TaskDates } from "./TaskDates";
import { useIsMobile } from "components/utils/useIsMobile";

export const CompletedTask = memo(({ item }) => {
    const { stepType, step, dateCompleted, dateRemoved, dateClosed, userCompleted, wfTaskNumber } = item;

    const isMobile = useIsMobile();

    const isAdhoc = isAdhocTask(item);
    const isSkipped = isSkippedTask(item);

    const completedDate = useMemo(() => {
        return dateCompleted || dateRemoved || dateClosed;
    }, [dateCompleted, dateClosed, dateRemoved]);

    const completedDateTitle = useMemo(() => {
        if (dateRemoved) {
            return "Skipped";
        } else if (dateCompleted) {
            return "Completed";
        } else if (dateClosed) {
            return "Closed";
        }
    }, [dateCompleted, dateClosed, dateRemoved]);

    return (
        <div id={wfTaskNumber} className="workflow-task flex-row fill-width">
            {!isAdhoc && !isAutomated(item) && !isSkipped && (
                <StatusMark statusMarkSmall statusMarkSuccessEmpty>
                    Task
                </StatusMark>
            )}
            {isSkipped && !isAdhoc && !isAutomated(item) && (
                <StatusMark statusMarkSmall statusMarkGhost>
                    Task
                </StatusMark>
            )}
            {isAdhoc && (
                <StatusMark statusMarkSmall statusMarkThemeBase={isSkipped} statusMarkSuccessFilled={!isSkipped}>
                    Ad-hoc Task
                </StatusMark>
            )}
            {isAutomated(item) && (
                <StatusMark statusMarkSmall statusMarkThemeDark>
                    Automated
                </StatusMark>
            )}
            <div className="step-title flex-one">
                <div className="step-type">{stepType}</div>
                <div className="task-indicators-wrap flex-row">
                    {!isAdhoc && !isAutomated(item) && (
                        <TaskIndicator completedStatus={!isSkipped} skippedStatus={isSkipped} className="task" title="Task" />
                    )}
                    {isAdhoc && <TaskIndicator completedStatus={!isSkipped} className="ad-hoc" title="Ad-hoc Task" />}
                    {isAutomated(item) && <TaskIndicator completedStatus={!isSkipped} className="automated" title="Automated Task" />}
                </div>
                <div className="step-name" dangerouslySetInnerHTML={{ __html: step }} />
            </div>
            <TaskDates task={item} isCompleted />
            {!isMobile && (
                <div className="step-assignments flex-row align-center justify-space-between">
                    <div className="flex-column">
                        <CompletedBy
                            userCompleted={userCompleted}
                            isAutomatedTask={isAutomated(item)}
                            dateCompleted={dateCompleted}
                            dateRemoved={dateRemoved}
                            dateClosed={dateClosed}
                        />
                        <div className="step-date-completed">
                            <div className="col-label">{completedDateTitle}:</div>
                            <div className="col-content">
                                {formatJsonDate(completedDate, "-")}
                                <div className="col-content-new-line" />
                                {formatJsonTime(completedDate)}
                            </div>
                        </div>
                    </div>
                    <IconWrap
                        icon={isSkipped ? "doc-removed-empty" : "check-circle-filled"}
                        title={isSkipped ? "Skipped Task" : "Completed Task"}
                    />
                </div>
            )}
            <div className="task-indicators-wrap top-right-corner flex-row align-start">
                {!isAdhoc && !isAutomated(item) && (
                    <TaskIndicator completedStatus={!isSkipped} skippedStatus={isSkipped} className="task" title="Task" />
                )}
                {isAdhoc && <TaskIndicator completedStatus={!isSkipped} className="ad-hoc" title="Ad-hoc Task" />}
                {isAutomated(item) && <TaskIndicator completedStatus={!isSkipped} className="automated" title="Automated Task" />}
            </div>
        </div>
    );
});

const CompletedBy = memo(({ userCompleted, isAutomatedTask, dateCompleted, dateRemoved, dateClosed }) => {
    const completedByTitle = useMemo(
        () => getCompletedByLabel({ dateCompleted, dateClosed, dateRemoved }),
        [dateCompleted, dateClosed, dateRemoved]
    );

    if (isAutomatedTask) {
        return (
            <div className="step-assignment step-automated step-completed flex-row align-center">
                <div>
                    Automated
                    <br />
                    Task
                </div>
                <IconWrap iconWrapBig iconWrap="restore-update"></IconWrap>
            </div>
        );
    }

    return (
        <div className="step-assignment">
            <div className="col-label">{completedByTitle}</div>
            <div className="col-content">{userCompleted || "-"}</div>
        </div>
    );
});
