import React from "react";
import { connect } from "react-redux";

import { getData } from "../../../../../../store/dataGrid/actions";
import { updateResource } from "../../../../../../store/resources/actions";

import InlinePanelHeader from "../../InlinePanelHeader";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import { mapGridRowToObject } from "../../../../../utils/datagrid";
import { utilitiesFilesGridColumnKeys } from "../../../../../views/configureGrids";

const FileForm = (props) => {
    const { dataItem, onClose, gridId, dispatch } = props;
    const initialValues = dataItem ? mapGridRowToObject(utilitiesFilesGridColumnKeys, dataItem) : {};

    const schema = {
        type: "object",
        required: ["tag"],
        properties: {
            tag: {
                type: "string",
                title: "File Tag",
            },
        },
    };

    const uiSchema = {
        tag: {
            classNames: "fill-width",
            "ui:widget": "textarea",
        },
    };

    const onUpdate = (formData) => {
        dispatch(
            updateResource({
                resourceName: "fileTags",
                path: {
                    fileId: formData.fileId,
                },
                query: {
                    tags: formData.tag,
                },
                onSuccess,
            })
        );
    };

    const onSuccess = () => {
        onClose();

        dispatch(
            getData({
                dataGridId: gridId,
            })
        );
    };

    return (
        <div className="full-width">
            <InlinePanelHeader title="Edit File Tag" onClose={onClose} />
            <div className="file-form">
                <JsonSchemaForm
                    schema={schema}
                    uiSchema={uiSchema}
                    initialValues={initialValues}
                    onSubmit={onUpdate}
                    onCancel={() => onClose()}
                    withCancel
                    noReset
                />
            </div>
        </div>
    );
};

export default connect()(FileForm);
