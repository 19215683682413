import React from "react";
import cn from "classnames";
import StatusMsg from "../StatusMsg";
import { MessageProps } from ".";

const ErrorMessage = ({ spaceAround, inline, marginTop, marginBottom, children, msgCentered, msgSquare }: MessageProps) => {
    return (
        <StatusMsg
            className={cn("error-message", {
                "error-message--margin": spaceAround,
                "error-message--inline": inline,
                "error-message--margin-top": marginTop,
                "error-message--margin-bottom": marginBottom,
            })}
            msgVisible
            msgFieldStatus
            msgError
            msgText={children}
            msgCentered={msgCentered}
            msgSquare={msgSquare}
        />
    );
};

export default ErrorMessage;
