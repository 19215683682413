import { isNil } from "lodash";
import React, { Dispatch, MutableRefObject, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { PortalBuilderSection } from "./Properties/Section";
import {
    AccessibilityValidationMessage,
    PortalTemplateConfiguration,
    PortalTemplateInfo,
    ValidationError,
    ValidationOptions,
} from "./types";
import {
    debouncedValidateProgramTemplate,
    debouncedValidateUtilityTemplate,
    validateAccessibility as validatePortal,
    validatePageAccessibility as validatePage,
    validateProgramTemplate,
    validateUtilityTemplate,
} from "./utils/validation";

const PortalBuilderErrorContext = React.createContext<PortalBuilderErrorCtx | undefined>(undefined);

export const PortalBuilderErrorContextProvider = ({ children }: { children: React.ReactNode }) => {
    // Portal iframe reference, got from postMessage
    const portalRef = useRef<MessageEventSource | null>(null);

    // All errors found when last validated the config.
    const lastErrors = useRef<ValidationError[]>([]);

    const [isValidating, setIsValidating] = useState(false);
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [accessibilityErrors, setAccessibilityErrors] = useState<AccessibilityValidationMessage[]>([]);
    const [activeSection, setActiveSection] = useState<PortalBuilderSection | undefined>();
    const [sectionPath, setSectionPath] = useState<string | undefined>();

    const activeConfigPath = [activeSection?.path, sectionPath].filter((i) => i).join(".");

    // Using this ref inside onSetErrors function to not get some cached values.
    const activeConfigPathRef = useRef<string>("");
    activeConfigPathRef.current = activeConfigPath;

    // Show all errors when user navigates in UI
    useEffect(() => {
        setErrors(lastErrors.current);
    }, [activeConfigPath]);

    const onValidationComplete = useCallback(
        (newErrors: ValidationError[]) => {
            setErrors((errors) => {
                let errorsToShow = [];

                if (activeConfigPathRef.current === activeSection?.path) {
                    errorsToShow = newErrors;
                } else {
                    errorsToShow = newErrors.filter((e) => {
                        // Show error that is not in the active path.
                        if (!e.id.startsWith(activeConfigPathRef.current)) {
                            return true;
                        }
                        // Show error that is in the active path and was present before navigation to active path.
                        else {
                            if (errors.some((le) => le.id === e.id)) {
                                return true;
                            }
                        }

                        return false;
                    });
                }

                lastErrors.current = newErrors;
                return errorsToShow;
            });
            setIsValidating(false);
        },
        [activeSection?.path]
    );

    const validateProgram = useCallback(
        (programConfig: PortalTemplateConfiguration, utilityConfig: PortalTemplateConfiguration, options?: ValidationOptions) => {
            setIsValidating(true);
            return debouncedValidateProgramTemplate(programConfig, utilityConfig, onValidationComplete, options);
        },
        [onValidationComplete]
    );

    const validateProgramForSave = useCallback(
        (programConfig: PortalTemplateConfiguration, utilityConfig: PortalTemplateConfiguration, options?: ValidationOptions) => {
            const ProgramPageLinkErrors = validateProgramTemplate(programConfig, utilityConfig, options);
            setErrors(ProgramPageLinkErrors);
            return ProgramPageLinkErrors;
        },
        []
    );

    const validateUtility = useCallback(
        (config: PortalTemplateConfiguration, templateInfo: PortalTemplateInfo, options?: ValidationOptions) => {
            setIsValidating(true);
            return debouncedValidateUtilityTemplate(config, templateInfo, onValidationComplete, options);
        },
        [onValidationComplete]
    );

    const validateUtilityForSave = useCallback(
        (config: PortalTemplateConfiguration, templateInfo: PortalTemplateInfo, options?: ValidationOptions) => {
            const utilityPageLinkErrors = validateUtilityTemplate(config, templateInfo, options);
            setErrors(utilityPageLinkErrors);
            return utilityPageLinkErrors;
        },
        []
    );

    const validatePortalAccessibility = useCallback(async (config: PortalTemplateConfiguration) => {
        if (portalRef.current) {
            try {
                setIsValidating(true);
                const errors = await validatePortal(portalRef.current, config);
                setAccessibilityErrors(errors);
            } catch {
                setAccessibilityErrors([]);
            } finally {
                setIsValidating(false);
            }
        }
    }, []);

    const validatePageAccessibility = useCallback(async (pageLink: string | undefined) => {
        if (portalRef.current && pageLink) {
            try {
                setIsValidating(true);
                const result = await validatePage(portalRef.current, pageLink);

                // Replace page errors in errors list
                setAccessibilityErrors((prev) =>
                    prev
                        // Remove old page errors. Compare the path without query params.
                        .filter((e) => !e.url.split("?")[0].endsWith(pageLink))
                        // Add new page errors
                        .concat(result)
                );
            } catch {
            } finally {
                setIsValidating(false);
            }
        }
    }, []);

    const value = useMemo(
        () => ({
            portalRef,
            errors,
            setErrors,
            accessibilityErrors,
            activeSection,
            setActiveSection,
            setSectionPath,
            isValidating,
            validateUtilityTemplate: validateUtility,
            validateUtilityTemplateForSave: validateUtilityForSave,
            validateProgramTemplate: validateProgram,
            validateProgramTemplateForSave: validateProgramForSave,
            validatePortalAccessibility,
            validatePageAccessibility,
        }),
        [
            errors,
            accessibilityErrors,
            activeSection,
            isValidating,
            validateUtility,
            validateUtilityForSave,
            validateProgram,
            validateProgramForSave,
            validatePortalAccessibility,
            validatePageAccessibility,
        ]
    );

    return <PortalBuilderErrorContext.Provider value={value}>{children}</PortalBuilderErrorContext.Provider>;
};

export const useErrorContext = (props: useErrorContextProps | undefined = undefined) => {
    const context = useContext(PortalBuilderErrorContext);

    // Set initial active section
    useEffect(() => {
        if (context?.setActiveSection && props?.initialSection) {
            context.setActiveSection((prev) => {
                if (isNil(prev)) {
                    return props.initialSection;
                }

                return prev;
            });
        }
    }, [context, props?.initialSection]);

    return context;
};

interface useErrorContextProps {
    initialSection?: PortalBuilderSection;
}

export interface PortalBuilderErrorCtx {
    portalRef: MutableRefObject<MessageEventSource | null>;
    errors: ValidationError[];
    accessibilityErrors: AccessibilityValidationMessage[];
    setErrors: Dispatch<SetStateAction<ValidationError[]>>;
    activeSection?: PortalBuilderSection;
    isValidating: boolean;
    setActiveSection: Dispatch<SetStateAction<PortalBuilderSection | undefined>>;
    validateUtilityTemplate: (config: PortalTemplateConfiguration, templateInfo: PortalTemplateInfo, options?: ValidationOptions) => void;
    validateUtilityTemplateForSave: (
        config: PortalTemplateConfiguration,
        templateInfo: PortalTemplateInfo,
        options?: ValidationOptions
    ) => ValidationError[];
    validateProgramTemplate: (
        programConfig: PortalTemplateConfiguration,
        utilityConfig: PortalTemplateConfiguration,
        options?: ValidationOptions
    ) => void;
    validateProgramTemplateForSave: (
        programConfig: PortalTemplateConfiguration,
        utilityConfig: PortalTemplateConfiguration,
        options?: ValidationOptions
    ) => ValidationError[];
    validatePortalAccessibility: (config: PortalTemplateConfiguration) => Promise<void>;
    /**
     * Validate page accessibility
     * @param pageLink Page link
     * @param useValidationState If true, will set isValidating to true and false when validation is complete.
     */
    validatePageAccessibility: (pageLink: string | undefined) => Promise<void>;
}
