/**
 * Do nothing on browser back button click.
 */
const disableBrowserNavigation = () => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
        window.history.go(1);
    };
};

export default disableBrowserNavigation;
