import React, { memo } from "react";
import ClassNames from "classnames";

import Button from "../../Button";
import ButtonGroup from "../../Button/ButtonGroup";

import "./InlinePanelFooter.scss";

const InlinePanelFooter = memo(({ onClose, className, children, btnText, disabled }) => {
    const panelClass = ClassNames("inline-panel-footer", className);

    return (
        <div className={panelClass}>
            <ButtonGroup transparent>
                {children}
                <span className="flex-one"></span>
                <Button standard disabled={disabled} btnText={btnText} onClick={onClose}></Button>
            </ButtonGroup>
        </div>
    );
});

export default InlinePanelFooter;
