import { useMemo } from "react";
import { isNil } from "lodash";
import { getDateWithoutTime } from "components/utils/date";
import { useResource } from "store/resources/useResource";
import { store } from "../../../store/configureStore";
import { updateResource, getResource } from "../../../store/resources/actions";
import { isTaskReadOnly } from "../../utils/workflow";

export const isToDoTask = (task) => {
    return !isCompletedTask(task);
};

export const isTodoAdHocTask = (task) => {
    return isToDoTask(task) && isAdhocTask(task);
};

export const isTodoDependentTask = (task) => {
    return isToDoTask(task) && isWorkflowTaskDependent(task);
};

export const isTodoAutomatedTask = (task) => {
    return isToDoTask(task) && isAutomated(task);
};

export const isCompletedTask = (task) => {
    return !isNil(task.dateCompleted) || !isNil(task.dateRemoved) || !isNil(task.dateClosed);
};

export const isCompletedAdHocTask = (task) => {
    return isCompletedTask(task) && isAdhocTask(task);
};

export const isCompletedAutomatedTask = (task) => {
    return isCompletedTask(task) && isAutomated(task);
};

export const isSkippedTask = (task) => {
    return !isNil(task.dateRemoved);
};

export const isAdhocTask = (task) => {
    return task.customStep?.toUpperCase() === "Y";
};

export const isAutomated = (task) => {
    return task.isAutomatedTask;
};

export const changeTaskOwnership = ({ step, resourceParams, onSuccess, onComplete }) => {
    if ((step && !isTaskReadOnly(step)) || !step) {
        const taskNumber = step ? step.wfTaskNumber : null;

        store.dispatch(
            updateResource({
                ...resourceParams,
                query: {
                    taskNumber,
                },
                onSuccess: (action) => {
                    onSuccess && onSuccess(action);
                },
                onComplete: (action) => {
                    onComplete && onComplete(action);
                },
            })
        );
    }
};

export const refreshWorkflow = ({ resourceParams, onSuccess }) => {
    store.dispatch(
        getResource({
            ...resourceParams,
            onSuccess: (action) => {
                onSuccess && onSuccess(action);
            },
        })
    );
};

export const isWorkflowTaskDependent = (item, isCompleted) => {
    if (isCompletedTask(item) || isCompleted) {
        return false;
    } else if (item.hideFlag) {
        return true;
    } else {
        let isDependent = false;
        let currentDate = getDateWithoutTime(new Date());
        let isDate = false;
        if (item.dateCreated) {
            let dateCreated = new Date(item.dateCreated);
            let datePlusTerm = new Date(dateCreated);
            let dateValue = datePlusTerm.getDate() + item.term;
            datePlusTerm.setDate(dateValue);
            datePlusTerm = getDateWithoutTime(datePlusTerm);
            if (datePlusTerm > currentDate) {
                isDate = true;
            }
        }
        if (item.isAutomated && (!item.dateCreated || !item.dateCompleted || !item.dateRemoved)) {
            isDependent = true;
        } else if (isDate) {
            isDependent = true;
        } else if (!item.allowAccess) {
            isDependent = true;
        }
        return isDependent;
    }
};

export const applyTaskFilter = (task, workflowFilter) => {
    let takeTask = false;

    // Todo Tasks
    if (
        isToDoTask(task) &&
        !isTodoAdHocTask(task) &&
        !isTodoAutomatedTask(task) &&
        !isTodoDependentTask(task) &&
        !isSkippedTask(task) &&
        workflowFilter.showTodoTasks
    ) {
        takeTask = true;
    }

    if (isTodoAdHocTask(task) && !isSkippedTask(task) && workflowFilter.showTodoAdHocTasks) {
        takeTask = true;
    }

    if (isTodoDependentTask(task) && !isSkippedTask(task) && workflowFilter.showTodoDependentTasks) {
        takeTask = true;
    }

    if (isTodoAutomatedTask(task) && !isSkippedTask(task) && workflowFilter.showTodoAutomatedTasks) {
        takeTask = true;
    }

    // Completed Tasks
    if (
        isCompletedTask(task) &&
        !isCompletedAdHocTask(task) &&
        !isCompletedAutomatedTask(task) &&
        !isSkippedTask(task) &&
        workflowFilter.showCompletedTasks
    ) {
        takeTask = true;
    }

    if (isCompletedAdHocTask(task) && !isSkippedTask(task) && workflowFilter.showCompletedAdHocTasks) {
        takeTask = true;
    }

    if (isCompletedAutomatedTask(task) && !isSkippedTask(task) && workflowFilter.showCompletedAutomatedTasks) {
        takeTask = true;
    }

    // Removed Tasks
    if (isSkippedTask(task)) {
        takeTask = workflowFilter.showSkippedTasks;
    }

    return takeTask;
};

export const DEFAULT_WORKFLOW_FILTER = {
    showHistory: false,
    showTodoTasks: true,
    showTodoAdHocTasks: true,
    showTodoDependentTasks: false,
    showTodoAutomatedTasks: false,
    showCompletedTasks: false,
    showCompletedAdHocTasks: false,
    showCompletedAutomatedTasks: false,
    showSkippedTasks: false,
};

export const useWorkflowModel = ({ workflowGetResourceOptions }) => {
    const [workflow, isLoading] = useResource({
        ...workflowGetResourceOptions,
        forced: true,
        transform: (data) => {
            return data?.workflow;
        },
    });

    return useMemo(() => {
        return {
            activeStatus: workflow?.activeStatus?.[0],
            workflowTasks: workflow?.activeStatus?.[0]?.tasks ?? [],
            workflowHistory: workflow?.historyStatuses ?? [],
            isLoading,
        };
    }, [workflow?.activeStatus, workflow?.historyStatuses, isLoading]);
};

export const getCompletedByLabel = (task) => {
    if (task.dateRemoved) {
        return "Skipped by:";
    } else if (task.dateCompleted) {
        return "Completed by:";
    } else if (task.dateClosed) {
        return "Closed by:";
    }

    return "";
};
