import React from "react";
import IconWithLabel from "components/ui/Icons/IconWithLabel";
import { PropertyListItem } from "../Property/PropertyListItem";

export const PropertyListItemAdd = ({ title, onAdd, disabled }) => {
    return (
        <PropertyListItem className="property-list-item--add-item">
            <IconWithLabel disabled={disabled} icon="plus" onClick={onAdd}>
                {title}
            </IconWithLabel>
        </PropertyListItem>
    );
};
