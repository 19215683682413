export const hideRecaptchaBadge = () => {
    let timeout: NodeJS.Timeout;
    // Use MutationObserver to wait for the recaptcha badge to be removed from the DOM.
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.addedNodes.length > 0) {
                const recaptchaBadge = document.querySelector<HTMLElement>(".grecaptcha-badge");
                if (recaptchaBadge) {
                    recaptchaBadge.style.display = "none";
                    const badgeParent = recaptchaBadge.parentElement;
                    if (badgeParent && badgeParent.tagName !== "BODY") {
                        badgeParent.setAttribute("aria-hidden", "true");
                    } else {
                        recaptchaBadge.setAttribute("aria-hidden", "true");
                    }

                    clearTimeout(timeout);
                    observer.disconnect();
                }
            }
        });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    timeout = setTimeout(() => {
        observer.disconnect();
    }, 10000);
};
