import React, { useContext, useCallback, memo } from "react";

import { isApplicationLocked, getApplicationCisSearchType } from "../../../../../views/ProjectView/utils";
import { onCisVerify, onApplicationContactStoredCIS } from "../../../../CIS/actions";
import { ContactsPanelContext } from ".";
import { isSame } from "./ContactsUtils";

import ContentHeader from "../../../../Title/ContentHeader";
import SimilarPremises from "./SimilarPremises";
import ContactsPanelItem from "./ContactsPanelItem";

const AssignedContacts = memo(() => {
    const { utilityNumber, applicationNumber, premiseContact, primaryContact, contractorContact, onDelete, onExpand, assignedContacts } =
        useContext(ContactsPanelContext);

    const isAppLocked = isApplicationLocked({ applicationNumber });

    const handleCisVerify = useCallback(() => {
        onCisVerify({
            utilityNumber,
            contact: premiseContact,
            searchType: getApplicationCisSearchType({ applicationNumber }),
        });
    }, [utilityNumber, applicationNumber, premiseContact]);

    const handleCisStored = useCallback(() => {
        onApplicationContactStoredCIS({
            applicationNumber,
            contactNumber: premiseContact?.contactnumber,
        });
    }, [applicationNumber, premiseContact]);

    return (
        <div className="assigned-contacts">
            <div className="flex-row align-center justify-space-between">
                <ContentHeader>Assigned Contacts</ContentHeader>
                <div className="contacts-action-panel">
                    <SimilarPremises applicationNumber={applicationNumber} onOpenDashboardPanel={onExpand} />
                </div>
            </div>
            <div className="contacts-panel-items flex-column flex-wrap">
                <ContactsPanelItem
                    applicationNumber={applicationNumber}
                    utilityNumber={utilityNumber}
                    contact={primaryContact}
                    isPrimary
                    isAssigned
                    isLocked={isAppLocked}
                    onDelete={onDelete}
                    onOpenDashboardPanel={onExpand}
                    assignedContacts={assignedContacts}
                />
                <ContactsPanelItem
                    applicationNumber={applicationNumber}
                    utilityNumber={utilityNumber}
                    contact={premiseContact}
                    isAssigned
                    isPremise
                    isLocked={isAppLocked}
                    sameAsPrimary={isSame(primaryContact, premiseContact)}
                    onDelete={onDelete}
                    onCisVerify={handleCisVerify}
                    onCisStored={handleCisStored}
                    onOpenDashboardPanel={onExpand}
                    assignedContacts={assignedContacts}
                />
                <ContactsPanelItem
                    applicationNumber={applicationNumber}
                    utilityNumber={utilityNumber}
                    contact={contractorContact}
                    isContractor
                    isAssigned
                    isLocked={isAppLocked}
                    onDelete={onDelete}
                    onOpenDashboardPanel={onExpand}
                    assignedContacts={assignedContacts}
                />
            </div>
        </div>
    );
});

export default AssignedContacts;
