import React, { useState, useCallback, useContext, useMemo, memo } from "react";

import { useResource } from "../../../../../../store/resources/useResource";
import { useDispatch } from "react-redux";
import { isTaskCompleted, isTaskRemoved } from "../../../../../utils/workflow";
import { hasAnyOfPermissions, systemUserRights } from "../../../../../utils/user";
import { isAutomated, isWorkflowTaskDependent } from "./../../../utils";
import { formatJsonDateTime } from "components/utils/date";
import { setExpandedWorkflowTask } from "store/projects/actions";

import WaitIcon from "../../../../WaitIcon";
import Button from "../../../../Button";
import ButtonGroup from "../../../../Button/ButtonGroup";
import Checkbox from "../../../../Input/Checkbox";
import StatusMsg from "../../../../StatusMsg";
import Separator from "../../../../Separator";
import HtmlContentPreview from "components/ui/HtmlContentPreview";
import { TaskWorkflowContext } from "../../../context/TaskWorkflowContext";
import { FileUploadTask } from "./FileUploadTask";
import { FlagRecordTask } from "./FlagRecordTask";
import { ApplicationFormActivateTask } from "./ApplicationFormActivateTask";
import { ApplicationFormEntryTask } from "./ApplicationFormEntryTask";
import { ApplicationFormFieldEntryTask } from "./ApplicationFormFieldEntryTask";
import AddEquipmentTask from "./AddEquipmentTask";
import CreateContactTask from "./CreateContactTask";
import { DependentTask } from "./DependentTask";

import "./TaskDetails.scss";
import { ApplicationFormSectionEntryTask } from "./ApplicationFormSectionEntryTask";

const TaskDetails = memo(({ item, isInActiveStatus, onCloseTask }) => {
    const { wfTaskNumber, stepNumber, allowAccess } = item;

    const dispatch = useDispatch();

    const isAutomatedTask = isAutomated(item);

    const {
        entityId,
        entityTypeId,
        utilityNumber,
        programNumber,
        isLocked,
        workflowTaskDetailsResourceOptions,
        onChangeTask,
        onExpandPanel,
    } = useContext(TaskWorkflowContext);
    const [checked, setChecked] = useState(false);

    const isReadonly = !allowAccess && !hasAnyOfPermissions([systemUserRights.VISIONDSM_MANAGE_APPLICATIONS]);
    const isCompleted = useMemo(() => isTaskCompleted(item) || !isInActiveStatus, [item, isInActiveStatus]);
    const isRemoved = useMemo(() => isTaskRemoved(item), [item]);
    const isDependent = useMemo(() => isWorkflowTaskDependent(item), [item]);
    const isEditable = useMemo(() => !(isLocked || isDependent), [isLocked, isDependent]);

    const [details, isDetailsLoading] = useResource({
        ...workflowTaskDetailsResourceOptions,
        resourceId: stepNumber,
        forced: true,
    });

    const canResetTask = isCompleted && isInActiveStatus && !isReadonly && !isRemoved && !isLocked;
    const canCompleteTask = !isCompleted && !isAutomatedTask && !isReadonly && !isRemoved && !isLocked;

    const isFileUploadTask = useCallback((task) => task.wf_step_typeid === "2" && !isAutomatedTask, [isAutomatedTask]);

    const isAddEquipmentTask = useCallback((task) => task.wf_step_typeid === "16" && !isAutomatedTask, [isAutomatedTask]);

    const isCreateContactTask = useCallback((task) => task.wf_step_typeid === "17" && !isAutomatedTask, [isAutomatedTask]);

    const isFlagRecordTask = useCallback((task) => task.wf_step_typeid === "7" && !isAutomatedTask, [isAutomatedTask]);

    const isApplicationFormActivateTask = useCallback(
        (task) => task.wf_step_typeid === "13" && !isAutomatedTask && !isCompleted,
        [isAutomatedTask, isCompleted]
    );

    const isApplicationFormEntryTask = useCallback(
        (task) => task.wf_step_typeid === "14" && !isAutomatedTask && !isCompleted,
        [isAutomatedTask, isCompleted]
    );

    const isApplicationFormFieldEntryTask = useCallback(
        (task) => task.wf_step_typeid === "15" && !isAutomatedTask && !isCompleted,
        [isAutomatedTask, isCompleted]
    );

    const isApplicationFormSectionEntryTask = useCallback(
        (task) => task.wf_step_typeid === "18" && !isAutomatedTask && !isCompleted,
        [isAutomatedTask, isCompleted]
    );

    const showTaskCompleteAction =
        !isCompleted &&
        !isLocked &&
        !isDependent &&
        !details?.task.every(isFileUploadTask) &&
        !details?.task.every(isCreateContactTask) &&
        !details?.task.every(isAddEquipmentTask) &&
        !details?.task.every(isApplicationFormEntryTask) &&
        !details?.task.every(isApplicationFormFieldEntryTask) &&
        !details?.task.every(isApplicationFormSectionEntryTask);

    const handleCheck = useCallback(() => setChecked(!checked), [checked]);

    const handleClickActionButton = useCallback(() => {
        if (checked) {
            onChangeTask(wfTaskNumber, isCompleted);
            onCloseTask && onCloseTask();
        }
    }, [wfTaskNumber, isCompleted, checked, onChangeTask, onCloseTask]);

    const handleChangeTaskSuccess = useCallback(() => {
        onChangeTask(wfTaskNumber, isCompleted);
        onCloseTask && onCloseTask();
    }, [wfTaskNumber, isCompleted, onChangeTask, onCloseTask]);

    const handleClickCancel = useCallback(() => {
        dispatch(setExpandedWorkflowTask(entityId));
        onCloseTask && onCloseTask();
    }, [entityId, dispatch, onCloseTask]);

    return (
        <div
            hidden={
                isLocked ||
                (details?.task.every(isAddEquipmentTask) && !isDependent) ||
                (details?.task.every(isApplicationFormEntryTask) && !isDependent) ||
                (details?.task.every(isCreateContactTask) && !isDependent)
            }
            className="workflow-task-details fill-width"
        >
            {!details && isDetailsLoading && <WaitIcon />}
            {!details && !isDetailsLoading && <span>No step details</span>}
            {details && (
                <>
                    {details.task.map((task, index) => (
                        <div key={index} className="task">
                            {task.content && task.content !== "" && task.content !== "&nbsp;" && !isDependent && (
                                <div className="task-content">
                                    <HtmlContentPreview applyDefaultFont>{task.content}</HtmlContentPreview>
                                </div>
                            )}
                            {isFileUploadTask(task) && !isCompleted && isEditable && (
                                <FileUploadTask
                                    task={task}
                                    entityId={entityId}
                                    entityTypeId={entityTypeId}
                                    programNumber={programNumber}
                                    utilityNumber={utilityNumber}
                                    disabled={isReadonly || !isEditable}
                                    onUploadSuccess={() => {
                                        onChangeTask(wfTaskNumber, isCompleted);
                                    }}
                                    onCancel={handleClickCancel}
                                />
                            )}
                            {isFlagRecordTask(task) && !isEditable && <FlagRecordTask task={task} />}
                            {isApplicationFormActivateTask(task) && isEditable && (
                                <ApplicationFormActivateTask
                                    editMode={isEditable}
                                    task={task}
                                    applicationNumber={entityId}
                                    onExpandPanel={onExpandPanel}
                                />
                            )}
                            {isApplicationFormEntryTask(task) && isEditable && (
                                <ApplicationFormEntryTask
                                    contentTitle={task.formTitle}
                                    content={task.content}
                                    editMode={isEditable}
                                    task={task}
                                    applicationNumber={entityId}
                                    wfTaskNumber={wfTaskNumber}
                                    onExpandPanel={onExpandPanel}
                                    onSubmitSuccess={handleChangeTaskSuccess}
                                    onCancel={handleClickCancel}
                                />
                            )}
                            {isApplicationFormFieldEntryTask(task) && isEditable && (
                                <ApplicationFormFieldEntryTask
                                    editMode={isEditable}
                                    task={task}
                                    applicationNumber={entityId}
                                    wfTaskNumber={wfTaskNumber}
                                    onExpandPanel={onExpandPanel}
                                    onSubmitSuccess={handleChangeTaskSuccess}
                                    onCancel={handleClickCancel}
                                />
                            )}
                            {isApplicationFormSectionEntryTask(task) && isEditable && (
                                <ApplicationFormSectionEntryTask
                                    editMode={isEditable}
                                    task={task}
                                    applicationNumber={entityId}
                                    wfTaskNumber={wfTaskNumber}
                                    onExpandPanel={onExpandPanel}
                                    onSubmitSuccess={handleChangeTaskSuccess}
                                    onCancel={handleClickCancel}
                                />
                            )}
                            {isAddEquipmentTask(task) && !isCompleted && isEditable && (
                                <AddEquipmentTask
                                    viewOnly={!isEditable}
                                    task={task}
                                    applicationNumber={entityId}
                                    programNumber={programNumber}
                                    onSuccess={handleChangeTaskSuccess}
                                />
                            )}
                            {isCreateContactTask(task) && !isCompleted && isEditable && (
                                <CreateContactTask
                                    disabled={!isEditable}
                                    task={task}
                                    utilityNumber={utilityNumber}
                                    applicationNumber={entityId}
                                    onSuccess={handleChangeTaskSuccess}
                                    onCancel={handleClickCancel}
                                />
                            )}
                            {!isCompleted && isDependent && <DependentTask onCancel={handleClickCancel} />}
                        </div>
                    ))}
                    {isCompleted && (
                        <div className="step-actions">
                            {isRemoved && <RemovedTaskLabel task={item} />}
                            {isAutomatedTask && <AutomatedTaskLabel />}
                            {isReadonly && !isRemoved && !isAutomatedTask && <NoRightsToResetTaskLabel />}
                            <Separator />
                            {canResetTask ? (
                                <>
                                    <Checkbox
                                        label="Please reset this task to be completed again"
                                        checked={checked}
                                        onChange={handleCheck}
                                    />
                                    <ButtonGroup transparent>
                                        <Button disabled={!checked} onClick={handleClickActionButton}>
                                            Submit
                                        </Button>
                                        <Button onClick={handleClickCancel}>Cancel</Button>
                                    </ButtonGroup>
                                </>
                            ) : (
                                <ButtonGroup transparent>
                                    <Button onClick={handleClickCancel}>Cancel</Button>
                                </ButtonGroup>
                            )}
                        </div>
                    )}
                    {showTaskCompleteAction && (
                        <div className="step-actions">
                            {isAutomatedTask && <AutomatedTaskLabel />}
                            {canCompleteTask ? (
                                <>
                                    <Checkbox
                                        label="I certify that this task is completed"
                                        checked={checked}
                                        disabled={!isEditable}
                                        onChange={handleCheck}
                                    />
                                    <ButtonGroup transparent>
                                        <Button disabled={!checked || !isEditable} onClick={handleClickActionButton}>
                                            Submit
                                        </Button>
                                        <Button onClick={handleClickCancel}>Cancel</Button>
                                    </ButtonGroup>
                                </>
                            ) : (
                                <ButtonGroup transparent>
                                    <Button onClick={handleClickCancel}>Cancel</Button>
                                </ButtonGroup>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
});

const AutomatedTaskLabel = () => <StatusMsg msgFieldStatus msgInfo msgText="This is automated task" />;

const RemovedTaskLabel = ({ task }) => (
    <StatusMsg msgFieldStatus msgInfo msgText={"This task has been removed at " + formatJsonDateTime(task.dateRemoved)} />
);

const NoRightsToResetTaskLabel = () => <StatusMsg msgFieldStatus msgInfo msgText="You have insufficient rights to reset this task" />;

export default TaskDetails;
