import React, { useCallback, useState, useEffect } from "react";
import { dataURItoBlob } from "react-jsonschema-form/lib/utils";
import { difference } from "lodash";
import "./DropZoneWidget.scss";
import UploadFile from "../../../UploadFile";
import StatusMsg from "../../../StatusMsg";
import { MAX_FILE_SIZE, MAX_FILE_SIZE_TEXT } from "components/utils/files";

function processDataURL(dataURL, name) {
    const encodedName = encodeURIComponent(name);
    let url = dataURL;

    // [V50-4153] Adjust file type for application/vnd.ms-excel.sheet.macroEnabled.12
    // Form validation fails if file type has upper case chars
    if (url.startsWith("data:application/vnd.ms-excel.sheet.macroEnabled.12;")) {
        url = url.replace("vnd.ms-excel.sheet.macroEnabled.12", "vnd.ms-excel.sheet.macroenabled.12");
    }

    // Allow to submit empty File
    if (url === "data:") {
        return `${url};name=${encodedName};base64,`;
    }

    return url.replace(";base64", `;name=${encodedName};base64`);
}

function processFile(file) {
    const { name, size, type } = file;
    return new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        reader.onerror = reject;
        reader.onload = (event) => {
            resolve({
                dataURL: processDataURL(event.target.result, name),
                name,
                size,
                type,
            });
        };
        reader.readAsDataURL(file);
    });
}

function processFiles(files) {
    return Promise.all([].map.call(files, processFile));
}

function extractFileInfo(dataURLs) {
    return dataURLs
        .filter((dataURL) => typeof dataURL !== "undefined")
        .map((dataURL) => {
            try {
                const { blob, name } = dataURItoBlob(dataURL);

                return { dataURL, blob, name };
            } catch {
                return null;
            }
        })
        .filter((fileData) => fileData !== null)
        .map(({ dataURL, blob, name }) => {
            const decodedName = decodeURIComponent(name);

            return {
                name: decodedName,
                dataURL,
                size: blob.size,
                type: blob.type,
            };
        });
}

const DropZoneWidget = ({ value, onChange, id, readonly, disabled, options }) => {
    const [values, setValues] = useState(Array.isArray(value) ? value : [value]);
    const [rejectedMessage, setRejectedMessage] = useState(null);
    const [rejectedFileList, setRejectedFileList] = useState(null);
    const filesInfo = extractFileInfo(values);
    const multiple = options?.multiple === false ? false : true; // strict comparison to default to true

    useEffect(() => {
        setValues(Array.isArray(value) ? value : [value]);
    }, [value]);

    useEffect(() => {
        onChange(values.length > 0 ? values : undefined);
    }, [values.length, values, multiple, onChange]);

    const renderErrorMessage = (
        <>
            <span>{rejectedMessage}</span>
            {rejectedFileList && (
                <ul className="rejected-file-list">
                    {rejectedFileList.map((file, index) => {
                        return <li key={index}>{file}</li>;
                    })}
                </ul>
            )}
        </>
    );

    const onDrop = useCallback(
        (files, rejectedFiles) => {
            const currentFiles = values || [];
            let rejectedMessage = null;
            let filesToShow = null;
            const allowTypesArray = options?.accept?.split(",").map((a) => a.trim());
            const rejectedFilesByExtension = rejectedFiles.filter((f) => !allowTypesArray?.some((extension) => f.name.endsWith(extension)));

            if (rejectedFiles.length) {
                if (rejectedFiles.length > 1 && !multiple) {
                    rejectedMessage = `Only one file is allowed.`;
                } else if (rejectedFiles.filter((f) => f.size > MAX_FILE_SIZE).length === 1) {
                    rejectedMessage = `File exceeds the allowed ${MAX_FILE_SIZE_TEXT} size limit:`;
                    filesToShow = rejectedFiles.filter((f) => f.size > MAX_FILE_SIZE).map((f) => f.name);
                } else if (rejectedFiles.filter((f) => f.size > MAX_FILE_SIZE).length > 1) {
                    rejectedMessage = `Each of the following files exceeds the allowed ${MAX_FILE_SIZE_TEXT} size limit:`;
                    filesToShow = rejectedFiles.filter((f) => f.size > MAX_FILE_SIZE).map((f) => f.name);
                } else if (rejectedFilesByExtension.length === 1) {
                    rejectedMessage = `Following file has unsupported type: ${rejectedFilesByExtension.map((f) => f.name)}. 
                    Only ${options?.accept} file types are accepted`;
                } else if (rejectedFilesByExtension.length > 1) {
                    rejectedMessage = `Following files have unsupported type: ${rejectedFilesByExtension.map((f) => f.name)}. 
                    Only ${options?.accept} file types are accepted`;
                } else {
                    rejectedMessage = `rejected ${rejectedFiles.map((f) => f.name).join(", ")}`;
                }
            }

            processFiles(files).then((filesInfo) => {
                // remove files that are already uploaded
                const uniqueFiles = filesInfo.filter((fileInfo) => !currentFiles.includes(fileInfo.dataURL));

                // display a message, if there were any duplicates
                const duplicates = difference(filesInfo, uniqueFiles);
                if (duplicates.length > 0) {
                    if (!rejectedMessage) {
                        rejectedMessage = "";
                    }
                    rejectedMessage += "The following files have already been added: ";
                    rejectedMessage += duplicates.map((d) => d.name).join(", ");
                }

                setRejectedFileList(filesToShow);
                setValues(currentFiles.concat(uniqueFiles.map((fileInfo) => fileInfo.dataURL)));
                setRejectedMessage(rejectedMessage);
            });
        },
        [multiple, values, options?.accept]
    );

    const removeFile = useCallback(
        (event, fileInfo) => {
            event.preventDefault();
            const currentFiles = values || [];

            setValues(currentFiles.filter((data) => data !== fileInfo.dataURL));
            setRejectedMessage(null);
        },
        [values]
    );

    let accept = null;
    if (options?.accept) {
        accept = options.accept;
    }

    return (
        <div className="dropzone-widget">
            <UploadFile
                multiple={multiple}
                disabled={readonly || disabled}
                files={filesInfo}
                removeFile={removeFile}
                accept={accept}
                id={id}
                onDrop={onDrop}
            />
            {rejectedMessage && <StatusMsg msgFieldStatus msgError msgText={renderErrorMessage} />}
        </div>
    );
};

export default DropZoneWidget;
