import React, { useRef, useEffect, useCallback } from "react";
import ClassNames from "classnames";
import { Droppable } from "react-beautiful-dnd";

import IconWrap from "../Icons";
import TextInput from "../Input/TextInput";

import "./style.scss";

const DropdownList = (props) => {
    const filterRef = useRef();

    useEffect(() => {
        //Small timeout in order for focus to work
        setTimeout(() => {
            if (props.visible && filterRef.current) {
                filterRef.current.focus();
            }
        }, 100);
    }, [props.visible]);

    const onIconClick = useCallback(() => {
        props.onFilterChange && props.onFilterChange({ target: { value: "" } });
    }, [props]);

    const className = ClassNames("dropdown-list-wrap", props.className, {
        alone: props.alone || false,
        "with-title": props.withTitle,
        "with-parent": props.alone === false || props.alone === undefined,
        visible: props.visible,
        "fixed-width": props.dropdownFixedWidth,
        right: props.dropdownRight,
        "with-options": props.dropdownOptions,
    });

    const listHeader = (
        <div className="dropdown-list__header">
            {props.withTitle && <div className="dropdown-list-title">{props.withTitle}</div>}
            {props.withFilter && (
                <div className="dropdown-list__filter">
                    <TextInput
                        placeholder="Filter List"
                        iconRight
                        icon={props.filterValue.length === 0 ? "search" : "clear-close"}
                        iconTitle={props.filterValue.length === 0 ? "" : "Clear Filter"}
                        onIconClick={props.filterValue.length === 0 ? undefined : onIconClick}
                        value={props.filterValue}
                        onChange={props.onFilterChange}
                        onKeyDown={props.onListKeyDown}
                        inputRef={filterRef}
                    />
                </div>
            )}
        </div>
    );

    let listItems = <ul className="dropdown-list__items">{props.children}</ul>;

    let listFooter = <ul className="dropdown-list__items">{props.footer}</ul>;

    if (props.draggable) {
        listItems = (
            <Droppable droppableId="droppable">
                {(provided) => (
                    <ul className="dropdown-list__items" ref={provided.innerRef} {...provided.droppableProps}>
                        {props.children}
                        {provided.placeholder}
                    </ul>
                )}
            </Droppable>
        );
    }

    return (
        <div ref={props.elementRef} className={className}>
            <div className="dropdpwn-list-mobile-header">
                {props.mobileHeader}
                <IconWrap iconWrapClickable icon="clear-close" onClick={props.onClose} />
            </div>
            <div className="dropdown-list flex-column">
                {listHeader}
                {listItems}
                {listFooter}
            </div>
        </div>
    );
};

export default DropdownList;
