import { getResourcePromise, useResource } from "store/resources/useResource";
import { Role, UserRole } from "./types";

export const useUserRoles = (userNumber: string) => {
    return useResource({
        resourceName: "userRoles",
        resourceId: undefined,
        key: userNumber,
        path: {
            userNumber,
        },
        forced: true,
        onError: undefined,
        showSuccessNotification: false,
        showErrorNotification: true,
        transform: undefined,
    }) as [response: UserRolesResponse | undefined, isLoading: boolean];
};

export const refreshUserRoles = async (userNumber: string) => {
    return getResourcePromise({
        resourceName: "userRoles",
        key: userNumber,
        resourceId: undefined,
        path: {
            userNumber,
        },
    });
};

interface UserRolesResponse {
    clientRole: Role;
    roles: UserRole[];
}
