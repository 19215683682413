import React from "react";
import { exportDatagridToCSV } from "components/utils/CSV";

import IconWithLabel from "components/ui/Icons/IconWithLabel";
import withDataGrid from "components/ui/DataGrid/withDataGrid";
import DataGrid from "components/ui/DataGrid";

const Grid = withDataGrid("userSuccessfulLogins", ({ gridId, dataGridConfig }) => {
    return <DataGrid name={gridId} config={dataGridConfig} />;
});

const SuccessfulLogins = ({ userNumber }) => {
    const gridId = "user-successful-logins";

    return (
        <div className="data-grid-container flex-one-in-column">
            <div className="data-grid-controls">
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: gridId,
                            fileName: gridId,
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <Grid gridId={gridId} filter={`userNumber=${userNumber}`} />
        </div>
    );
};

export default SuccessfulLogins;
