import React, { memo, useId } from "react";
import cn from "classnames";
import { ScrollSyncPane } from "react-scroll-sync";
import ScrollControls from "components/ui/ScrollControls";

export const RolesListScrollableCells = memo(({ className, scrollControls, children }) => {
    const targetId = useId();

    if (scrollControls) {
        return (
            <div
                className={cn("roles-list-scrollable-cells flex-one", className, {
                    "roles-list-scrollable-cells--hide-scrollbar": true,
                })}
            >
                <ScrollSyncPane group="roles-list">
                    <div
                        id={targetId}
                        className={cn("roles-list-scrollable-cells-body flex-row flex-one", className, {
                            "roles-list-scrollable-cells--hide-scrollbar": true,
                        })}
                    >
                        {children}
                    </div>
                </ScrollSyncPane>
                <ScrollControls targetId={targetId} horizontalScroll />
            </div>
        );
    }

    return (
        <ScrollSyncPane group="roles-list">
            <div
                className={cn("roles-list-scrollable-cells flex-row flex-one", className, {
                    "roles-list-scrollable-cells--hide-scrollbar": true,
                })}
            >
                {children}
            </div>
        </ScrollSyncPane>
    );
});
