import React, { useCallback } from "react";
import { PropertyListItem } from "../Property/PropertyListItem";
import { PropertyListItemTitle } from "../PropertyList/PropertyListItemTitle";
import { PropertyList } from "../PropertyList";

export const AnalyticsProperty = (props) => {
    const { title, titleSwitch, propertiesGroup = [], value = [], nestingLevel, onChange } = props;

    const enableAnalytics = props.titleSwitchValue;

    const onClick = useCallback(() => {
        const nextState = !enableAnalytics;
        onChange(titleSwitch?.id, nextState);
    }, [titleSwitch?.id, onChange, enableAnalytics]);

    return (
        <PropertyListItem className="property-list-item--prop-group property-list-item--analytics">
            <PropertyListItemTitle
                title={title}
                toggleTooltip={!enableAnalytics ? "Switch ON" : "Switch OFF"}
                onToggle={onClick}
                toggleValue={!enableAnalytics}
            />
            <PropertyList
                items={propertiesGroup}
                nestingLevel={nestingLevel + 1}
                config={value}
                onChange={onChange}
                isExpanded={enableAnalytics}
            />
        </PropertyListItem>
    );
};
