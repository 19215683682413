import React from "react";
import { downloadFile } from "../../../../../../store/files/actions";

import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid.js";
import { utilitiesFilesGridColumnKeys } from "../../../../../views/configureGrids";

import FileForm from "./FileForm";

const FilesGrid = (props) => {
    const { gridId, dataGridConfig, dispatch } = props;
    const customRowActions = [
        {
            name: "update",
            icon: "edit-empty",
            title: "Edit File Tag",
        },
        {
            name: "download",
            icon: "download",
            title: "Download File",
        },
    ];

    const onRowAction = (action, onExpand) => {
        const { name, dataItem } = action;

        switch (name) {
            case "update":
                onExpand();
                break;
            case "download":
                dispatch(
                    downloadFile({
                        fileId: dataItem[utilitiesFilesGridColumnKeys.fileId],
                    })
                );
                break;
            default:
                break;
        }
    };

    const renderExpandedFastTag = (props) => <FileForm dataItem={props.dataItem} gridId={gridId} onClose={props.onClose} />;

    return (
        <DataGrid
            name={gridId}
            config={dataGridConfig}
            onRowAction={onRowAction}
            customRowActions={customRowActions}
            UpdateForm={renderExpandedFastTag}
        />
    );
};

export default withDataGrid("utilitiesFiles", FilesGrid);
