import React, { memo } from "react";
import { PropertyListItem } from "../Property/PropertyListItem";
import { SelectBoxProperty } from "../Property/SelectBoxProperty";
import { WidgetsListItem } from "../Widgets/WidgetsListItem";

import "./HeadingWidgetProperty.scss";

export const HeadingTypeProperty = memo((props) => {
    const { value, onChange, onRemove, item } = props;
    const propertyValue = value?.level;

    const HeadingTypes = [
        {
            text: "H1",
            value: "h1",
        },
        {
            text: "H2",
            value: "h2",
        },
        {
            text: "H3",
            value: "h3",
        },
        {
            text: "H4",
            value: "h4",
        },
        {
            text: "H5",
            value: "h5",
        },
        {
            text: "H6",
            value: "h6",
        },
    ];

    return (
        <div className="flex-row">
            <WidgetsListItem {...props} onRemove={onRemove} item={item} />
            <PropertyListItem className="property-list-item--heading-type">
                <SelectBoxProperty
                    id={"level"}
                    value={propertyValue}
                    items={HeadingTypes}
                    defaultValue={"h1"}
                    borderBottom={false}
                    onChange={onChange}
                />
            </PropertyListItem>
        </div>
    );
});
