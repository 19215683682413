import React, { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";

import TilesWrap from "../../../../TilesWrap";
import TileItem from "../../../../TilesWrap/TileItem";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import { getData } from "store/dataGrid/actions";
import { useProgram, useProgramRights } from "store/resources/useResource";
import { systemUserRights } from "components/utils/user";
import ProgramForm from "../../../../Form/shared/Program/ProgramForm";
import { openPortalBuilderTab } from "components/utils/window";

import "../ProgramCommonStyles.scss";
import "./style.scss";

const ConfigPanel = memo((props) => {
    const onTileClick = useCallback(
        (component) => {
            const { onToggleTempPanel } = props;

            onToggleTempPanel(component);
        },
        [props]
    );

    const dispatch = useDispatch();

    const utilityNumber = get(props.panel, "data.utilityNumber");
    const programNumber = get(props.panel, "data.programNumber");
    const [programData] = useProgram({ programNumber, forced: false });

    const [programRights = []] = useProgramRights({ programNumber });
    const canViewFastTags =
        programRights.includes(systemUserRights.VISIONDSM_MANAGE_PROGRAM_FILES) ||
        programRights.includes(systemUserRights.VISIONDSM_MANAGE_UTILITY_FILES);
    const canManagePortals = programRights.includes(systemUserRights.VISIONDSM_ADD_CONTENT);
    const canAccessConfig =
        programRights.includes(systemUserRights.VISIONDSM_ADD_PROGRAM) || programRights.includes(systemUserRights.VISIONDSM_MANAGE_PROGRAM);
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "program-config-criteria-sidenav-panel",
    });

    const handleCloseProgramConfigCriteriaForm = useCallback(() => handleCloseSidePanel(), [handleCloseSidePanel]);

    const autoAttributeOpenState = useSelector((state) => {
        return state.dashboards.attributeAutoEditOpen;
    });

    useEffect(() => {
        if (autoAttributeOpenState && autoAttributeOpenState.autoOpen && autoAttributeOpenState.attributeNumber) {
            onTileClick("program-attributes-panel");
        }
    }, [autoAttributeOpenState, onTileClick]);

    const handleCriteriaPanel = useCallback(() => {
        const handleSuccess = () => {
            handleCloseProgramConfigCriteriaForm();
            dispatch(
                getData({
                    dataGridId: `${utilityNumber}-programs-grid`,
                })
            );
        };

        handleOpenSidePanel(
            <ProgramForm
                isProgramConfigCriteria
                utilityNumber={utilityNumber}
                programData={{ ...programData, programNumber }}
                onSuccess={handleSuccess}
                onCancel={handleCloseSidePanel}
            />
        );
    }, [
        utilityNumber,
        programData,
        programNumber,
        handleOpenSidePanel,
        handleCloseSidePanel,
        dispatch,
        handleCloseProgramConfigCriteriaForm,
    ]);

    return (
        <div className="panel program-config-panel">
            <PanelHeaderLarge title="Config" />
            <TilesWrap>
                {canAccessConfig && (
                    <>
                        <TileItem
                            icon="criteria"
                            tileActionIcon="eye-visibility-empty"
                            tileActionIconTooltip="See Details"
                            title="Criteria"
                            onClick={handleCriteriaPanel}
                        ></TileItem>
                        <TileItem
                            icon="view-list-list-bulleted"
                            title="Attributes"
                            onClick={() => onTileClick("program-attributes-panel")}
                        ></TileItem>
                        {canViewFastTags && (
                            <TileItem icon="offer-empty" title="FastTags" onClick={() => onTileClick("program-fast-tags")}></TileItem>
                        )}
                        <TileItem
                            icon="dashboard-view-dashboard-empty"
                            title="Dashboard Criteria"
                            onClick={() => onTileClick("program-dashboard-criteria")}
                        ></TileItem>
                        <TileItem
                            icon="check-all-done-all"
                            title="Approved Measures"
                            onClick={() => onTileClick("program-approved-measures")}
                        ></TileItem>
                        <TileItem
                            icon="catalog"
                            title="Catalog Categories"
                            onClick={() => onTileClick("program-catalog-categories")}
                        ></TileItem>
                        <TileItem icon="events" title="Event Categories" onClick={() => onTileClick("program-event-categories")}></TileItem>
                        <TileItem icon="audit" title="Audits" onClick={() => onTileClick("program-audits-panel")} />
                    </>
                )}
                {canManagePortals && (
                    <TileItem
                        icon="domain-empty"
                        title="Portal Builder"
                        onClick={() =>
                            openPortalBuilderTab({
                                utilityNumber,
                                programNumber,
                                entityType: "Program",
                                entityName: programData?.program,
                            })
                        }
                    />
                )}
            </TilesWrap>
        </div>
    );
});

export default ConfigPanel;
