import React, { memo } from "react";

import CalculationEditForm from "./CalculationEditForm";

const AddCalculationPanel = memo(({ utilityNumber, programNumber, calculationType, user, onClose, sidePanel }) => {
    return (
        <div className="panel calculations fill-height no-scroll">
            <CalculationEditForm
                utilityNumber={utilityNumber}
                programNumber={programNumber}
                calculationType={calculationType}
                user={user}
                onClose={onClose}
                sidePanel={sidePanel}
            />
        </div>
    );
});

export default AddCalculationPanel;
