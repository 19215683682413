import { getUser, deleteUser } from "../../components/utils/user";
import { USER_SAVE, USER_DELETE, APP_RESET } from "../actionTypes";

export const reducer = (state, action) => {
    state = state || getUser();

    switch (action.type) {
        case USER_SAVE:
            state = action.user;
            break;

        case APP_RESET:
        case USER_DELETE:
            deleteUser();
            state = null;
            break;

        default:
            break;
    }

    return state;
};
