import { isNil } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { modalClose, modalOpen } from "store/modal/actions";
import BaseInput from "../BaseInput";
import { FastTagList } from "./FastTagList";
import image from "./fasttags.png";

import "./InputWithFastTags.scss";

const InputWithFastTags = (props: InputWithFastTagsProps) => {
    const { id, value, options } = props;

    const fastTags = options.fastTags || [];

    const dispatch = useDispatch();

    const onAddFastTag = () => {
        // Get text input cursor position by id
        const cursorPosition = (document.getElementById(id) as HTMLInputElement)?.selectionStart;

        const onClick = (fastTag: string) => {
            let newValue = value || "";
            // Insert fast tag at cursor position
            newValue = isNil(cursorPosition)
                ? `${newValue}%%${fastTag}%%`
                : `${newValue.slice(0, cursorPosition)}%%${fastTag}%%${newValue.slice(cursorPosition)}`;

            props.onChange(newValue);
            dispatch(modalClose());
        };

        dispatch(
            modalOpen({
                modalType: "MODAL",
                modalProps: {
                    title: "Correspondence Fast Tags",
                    overlayClassName: "modal-styled",
                    className: "modal-list modal-md",
                    modalIcon: "theaters-empty",
                    children: <FastTagList fastTags={fastTags} onClick={onClick} />,
                },
            })
        );
    };

    return (
        <div className="input-with-fast-tags flex-row align-center">
            {/* @ts-ignore */}
            <BaseInput {...props} />
            <i className="icon-wrap icon-wrap-medium icon-wrap-clickable icon-fast-tags" onClick={onAddFastTag}>
                <img src={image} alt="Fast Tags" />
            </i>
        </div>
    );
};

interface InputWithFastTagsProps {
    id: string;
    value: string;
    options: {
        fastTags: string[];
    };
    onChange: (value: string) => void;
}

export default InputWithFastTags;
