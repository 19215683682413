import React, { memo } from "react";
import JsonSchemaForm from "../../../../../Form/JsonSchema/JsonSchemaForm";
import { pickInitialValues, listToAnyOf } from "../../../../../../utils/form";
import { useProgramCategoriesForDropdown } from "components/utils/program";
import { isChildProgram } from "components/views/ProgramView/utils";

const ItemForm = memo(({ formRef, programNumber, dataItem, isSubmitting, onSubmit, onChange }) => {
    const [categories = [], isLoadingCategories] = useProgramCategoriesForDropdown({
        resourceName: "programEventCategories",
        programNumber,
        initialItem: {
            categoryName: dataItem?.categoryName,
            categoryNumber: dataItem?.categoryNumber,
        },
    });

    const schema = {
        type: "object",
        required: ["name", "categoryNumber", "statusID"],
        properties: {
            name: {
                type: "string",
                title: "Name",
            },
            categoryNumber: {
                type: "string",
                title: "Category",
                anyOf: listToAnyOf({
                    list: categories,
                    map: (item) => ({
                        title: item.categoryName,
                        enum: [item.categoryNumber],
                    }),
                }),
            },
            statusID: {
                type: "integer",
                title: "Status",
                anyOf: [
                    {
                        title: "Active",
                        enum: [212],
                    },
                    {
                        title: "Disabled",
                        enum: [213],
                    },
                ],
            },
        },
    };

    const uiSchema = {
        categoryNumber: {
            "ui:placeholder": isLoadingCategories ? "Loading..." : "Select Category",
        },
        statusID: {
            "ui:widget": "radio",
            "ui:options": {
                inline: true,
            },
        },
    };

    const initialValues = dataItem ? pickInitialValues(dataItem) : {};

    return (
        <JsonSchemaForm
            readOnly={isChildProgram({ programNumber })}
            formRef={formRef}
            schema={schema}
            uiSchema={uiSchema}
            initialValues={initialValues}
            disabled={isSubmitting || isChildProgram({ programNumber })}
            onSubmit={onSubmit}
            onChange={onChange}
            noActions
        />
    );
});

export default ItemForm;
