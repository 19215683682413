import React, { memo } from "react";

import JsonSchemaForm from "../Form/JsonSchema/JsonSchemaForm";
import GridDetailsFooter from "./GridDetailsFooter";
import InlinePanelHeader from "../Dashboard/Panel/InlinePanelHeader";

import "./GridDetailsPanel.scss";

const GridDetailsPanel = memo((props) => {
    const { title, columns, dataItem, onClose } = props;

    const initialValues = {
        ...dataItem,
    };

    const formSchema = {
        type: "object",
        required: [],
        properties: columns.reduce((result, column) => {
            return {
                ...result,
                [column.key]: {
                    type: "string",
                    title: column.name,
                },
            };
        }, {}),
    };

    const formUiSchema = {
        "ui:rootFieldId": "grid-details-form",
        "ui:readonly": true,
        classNames: "inline-form columns-4",
    };

    return (
        <div className="grid-details-panel fill-width">
            <InlinePanelHeader title={title || <>&nbsp;</>} onClose={onClose} />
            <JsonSchemaForm schema={formSchema} uiSchema={formUiSchema} initialValues={initialValues} noActions />
            <GridDetailsFooter {...props} />
        </div>
    );
});

export default GridDetailsPanel;
