import React from "react";
import withDataGrid from "../../../ui/DataGrid/withDataGrid.js";
import { openProgramTab } from "store/window/openTabActions";
import DataGrid from "../../../ui/DataGrid";
import { allProgramAttributesColumnKeys } from "../../../views/configureGrids";
import { useDispatch } from "react-redux";

const AllProgramAttributesGrid = ({ gridId, dataGridConfig, filterRequired }: GridProps) => {
    const programId = allProgramAttributesColumnKeys.programNumber;
    const utilityId = allProgramAttributesColumnKeys.utilityId;
    const attrNumber = allProgramAttributesColumnKeys.attributeNumber;
    const attrType = allProgramAttributesColumnKeys.attributeType;
    const attrValue = allProgramAttributesColumnKeys.attributeValue;
    const dispatch = useDispatch();

    const rowActions = [
        {
            name: "open",
            icon: "open-new-window",
            title: "Open Program Attribute",
        },
    ];

    const onRowAction = (action: any) => {
        const { dataItem } = action;
        const attributeNumber = dataItem[attrNumber];
        const attributeType = dataItem[attrType];
        const attributeValue = dataItem[attrValue];
        const programNumber = dataItem[programId];
        const utilityNumber = dataItem[utilityId];
        const activePanel = "program-config";
        const autoOpen = true;
        dispatch(openProgramTab({ programNumber, activePanel, utilityNumber, attributeNumber, attributeValue, attributeType, autoOpen }));
    };

    return (
        <DataGrid
            name={gridId}
            config={dataGridConfig}
            customRowActions={rowActions}
            onRowAction={onRowAction}
            filterRequired={filterRequired}
        />
    );
};

interface GridProps {
    gridId: string;
    dataGridConfig: object;
    filterRequired: boolean;
}

export default withDataGrid("allProgramAttributes", AllProgramAttributesGrid);
