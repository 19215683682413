import * as actionTypes from "../actionTypes";
import { filterTabs } from "../../components/views/ApplicationProcessingWorkCenter/utils";

const initialState = {
    activeDataSource: null,
    dataSourceFilterExpanded: false,
    activeFilterTab: filterTabs[0],
    applicationsFilter: null,
    tasksFilter: null,
    workqueueGrid: null,
    workqueueGridFilter: null,
    workqueueGridId: "workqueueGrid",
    workqueueGridSelectedRows: [],
};

export const reducer = (state = {}, action) => {
    const { instanceId } = action;

    if (!instanceId) {
        return state;
    }

    let currentInstance = state[instanceId] || initialState;

    switch (action.type) {
        case actionTypes.INIT_WORKCENTER:
            state = {
                ...state,
                [instanceId]: {
                    ...initialState,
                    workqueueGridId: initialState.workqueueGridId + "-" + instanceId,
                },
            };

            break;
        case actionTypes.SET_WORKQUEUE_ACTIVE_DATASOURCE:
            currentInstance = {
                ...currentInstance,
                activeDataSource: action.activeDataSource,
            };

            state = {
                ...state,
                [instanceId]: currentInstance,
            };

            break;
        case actionTypes.SET_WORKQUEUE_ACTIVE_FILTER_TAB:
            currentInstance = {
                ...currentInstance,
                activeFilterTab: action.activeFilterTab,
            };

            state = {
                ...state,
                [instanceId]: currentInstance,
            };

            break;
        case actionTypes.SET_WORKQUEUE_APPLICATIONS_FILTER:
            currentInstance = {
                ...currentInstance,
                applicationsFilter: action.applicationsFilter,
            };

            state = {
                ...state,
                [instanceId]: currentInstance,
            };

            break;
        case actionTypes.SET_WORKQUEUE_TASKS_FILTER:
            currentInstance = {
                ...currentInstance,
                tasksFilter: action.tasksFilter,
            };

            state = {
                ...state,
                [instanceId]: currentInstance,
            };

            break;
        case actionTypes.SET_WORKQUEUE_GRID:
            currentInstance = {
                ...currentInstance,
                workqueueGrid: action.workqueueGrid,
            };

            state = {
                ...state,
                [instanceId]: currentInstance,
            };

            break;
        case actionTypes.SET_WORKQUEUE_GRID_FILTER:
            currentInstance = {
                ...currentInstance,
                workqueueGridFilter: action.workqueueGridFilter,
            };

            state = {
                ...state,
                [instanceId]: currentInstance,
            };

            break;
        case actionTypes.SET_WORKQUEUE_GRID_SELECTED_ROWS:
            currentInstance = {
                ...currentInstance,
                workqueueGridSelectedRows: action.workqueueGridSelectedRows,
            };

            state = {
                ...state,
                [instanceId]: currentInstance,
            };

            break;
        case actionTypes.SET_WORKQUEUE_DATASOURCE_FILTER_EXPANDED:
            currentInstance = {
                ...currentInstance,
                dataSourceFilterExpanded: action.dataSourceFilterExpanded,
            };

            state = {
                ...state,
                [instanceId]: currentInstance,
            };

            break;
        default:
            break;
    }

    return state;
};
