import React from "react";
import { get } from "lodash";
import { useContract } from "../../../../store/resources/useResource";

import IconWrap from "../../Icons";
import CopyToClipboard from "../../Label/CopyToClipboard";

const WindowHeaderForUtilityContract = (props) => {
    const contractNumber = get(props, `view.props.contractNumber`);
    const utilityNumber = get(props, `view.props.utilityNumber`);

    const [contract] = useContract({ utilityNumber, contractNumber });

    if (!contract) {
        return null;
    }

    return (
        <div className="window-header-content">
            <div className="project-mark">
                <span>Contract</span>
            </div>
            <div className="project-number">
                <CopyToClipboard title="Copy Contract Name to clipboard" successMessage="Contract Name copied to clipboard">
                    {contract.contractDesc}
                </CopyToClipboard>
            </div>
            {contract.isFavorite && <IconWrap iconWrap="bookmark-filled" title="Bookmarked" />}
        </div>
    );
};

export default WindowHeaderForUtilityContract;
