import React, { memo } from "react";

import IconWithLabel from "../../../../Icons/IconWithLabel";
import GridSelectedItemsLabel from "../../../../DataGrid/GridSelectedItemsLabel";
import { isDevEnvironment } from "components/utils/constants";

const CatalogItemGridControls = memo(({ selectedRowCount, onRowSelectClear, onUpload }) => {
    return (
        <div className="grid-controls flex-row align-center">
            <GridSelectedItemsLabel
                text={
                    selectedRowCount > 0 ? `Catalog ${"Item" + (selectedRowCount > 1 ? "s" : "")} Selected` : "Select Catalog Items to Add"
                }
                count={selectedRowCount}
                onClear={onRowSelectClear}
            />
            <span className="flex-one" />
            <IconWithLabel
                disabled={!isDevEnvironment}
                withHandMadeIcon
                onClick={onUpload}
                title="Upload csv with columns ID, NAME, PROGRAM"
            >
                Upload from CSV
            </IconWithLabel>
        </div>
    );
});

export default CatalogItemGridControls;
