import React, { memo, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import { logsSystemErrorsGridColumnKeys } from "../../configureGrids";
import { camelToTitle } from "../../../utils/string";
import { exportDatagridToCSV } from "../../../utils/CSV";
import { sideNavChange } from "store/sideNav/actions";

import useSidePanelHandlers from "../../../utils/useSidePanelHandlers";
import { sideNavSize, sideNavKey } from "../../../ui/SideNav/SideNavRoot";

import IconWithLabel from "../../../ui/Icons/IconWithLabel";
import DataGrid from "../../../ui/DataGrid";
import withDataGrid from "../../../ui/DataGrid/withDataGrid";
import InfoList from "../../../ui/List/InfoList";
import Button from "../../../ui/Button";

import SideNavBody from "../../../ui/SideNav/SideNavBody";
import SideNavFooter from "../../../ui/SideNav/SideNavFooter";
import SideNavHeader from "../../../ui/SideNav/SideNavHeader";
import SideNavContent from "../../../ui/SideNav/SideNavContent";

import { WindowContext } from "../../../ui/Windows/Window";
import JsonStateErrors from "./JsonStateErrors";

import "./style.scss";

const DATA_GRID_ID = "logs-system-errors";

const LogsGrid = withDataGrid("logsSystemErrors", ({ gridId, dataGridConfig, filterRequired }) => {
    const ROW_ACTIONS = [
        {
            name: "view",
            icon: "eye-visibility-empty",
            title: "View",
        },
    ];

    const dispatch = useDispatch();

    const { isSplitView } = useContext(WindowContext);

    const key = isSplitView ? undefined : sideNavKey.globalRight;
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        sideNavKey: key,
        className: "view-log-system-error-sidenav-panel",
    });

    const onStateToggle = (isVisible) => {
        dispatch(
            sideNavChange({
                id: key,
                size: isVisible ? sideNavSize.staticMedium : sideNavSize.small,
                className: cn("view-log-system-error-sidenav-panel", {
                    "state-details-opened": isVisible,
                }),
            })
        );
    };

    const onRowAction = (action) => {
        const { dataItem } = action;

        function detailValue(key) {
            switch (key) {
                case "state":
                    return <JsonStateErrors data={dataItem[logsSystemErrorsGridColumnKeys[key]]} onToggleVisible={onStateToggle} />;
                case "url":
                    return dataItem[logsSystemErrorsGridColumnKeys[key]];
                default:
                    return dataItem[logsSystemErrorsGridColumnKeys[key]];
            }
        }

        const details = Object.keys(logsSystemErrorsGridColumnKeys).map((key) => ({
            label: `${camelToTitle(key)}:`,
            value: detailValue(key),
            className: key === "state" ? "json-string" : undefined,
        }));

        switch (action.name) {
            case "view":
                handleOpenSidePanel(<Details items={details} onClose={handleCloseSidePanel} />);
                break;

            default:
                break;
        }
    };

    return (
        <DataGrid
            name={gridId}
            config={dataGridConfig}
            customRowActions={ROW_ACTIONS}
            onRowAction={onRowAction}
            filterRequired={filterRequired}
            columnCellContent={{
                [logsSystemErrorsGridColumnKeys.url]: (column, row) => <>{row[logsSystemErrorsGridColumnKeys.url]}</>,
            }}
        />
    );
});

const LogsSystemErrors = memo(() => {
    const noFilters = useSelector((state) => !state.dataGrid[DATA_GRID_ID]?.filter?.filters?.length);
    return (
        <div className="tab-list-tab-content flex-one-in-column flex-column no-scroll">
            <div className="data-grid-controls">
                <span className="flex-one"></span>
                <IconWithLabel
                    disabled={noFilters}
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: DATA_GRID_ID,
                            fileName: "log_errors",
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <LogsGrid filterRequired={true} gridId={DATA_GRID_ID} />
        </div>
    );
});

const Details = memo((props) => {
    return (
        <SideNavContent>
            <SideNavHeader title="View Page Error" leadBlockIcon="eye-visibility-empty" smallHeader onClose={props.onClose} />
            <SideNavBody className="flex-one-in-column">
                <InfoList {...props} />
            </SideNavBody>
            <SideNavFooter justifyEnd>
                <Button onClick={props.onClose}>Close</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default LogsSystemErrors;
