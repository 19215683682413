import React, { useCallback, useState } from "react";
import cn from "classnames";
import ReactModal from "react-modal";
import { modalClose } from "../../../store/modal/actions";
import { useDispatch } from "react-redux";
import JsonSchemaForm from "../Form/JsonSchema/JsonSchemaForm";
import Separator from "../Separator";
import { FilePreview } from "../PortalBuilder/Properties/Property/FileSelectProperty";
import { getFileExtension } from "components/utils/files";
import { entityType } from "components/utils/entityType";
import { useResource } from "store/resources/useResource";
import { getResource } from "store/resources/actions";
import { uploadFile } from "store/files/actions";
import { fileType } from "components/utils/fileType";
import WaitIcon from "../WaitIcon";

const ImageModal = ({ bodyOpenClassName, overlayClassName, className, utilityNumber, onImageChange, onClose }) => {
    const allowedFileTypes = ["jpeg", "png", "jpg", "gif", "svg"];
    const allowedFileTypeError = "This is not an image. Can't be selected.";
    const [isUploading, setIsUploading] = useState(false);
    const [files, loading] = useResource({
        resourceName: "fileList",
        key: utilityNumber,
        query: { entityTypeId: entityType.portalTemplate, entityId: utilityNumber },
    });

    const dispatch = useDispatch();

    function isSelectable(file) {
        const fileExtension = getFileExtension(file.fileName).substring(0, 4).toLowerCase();
        return allowedFileTypes.includes(fileExtension);
    }

    const onSelectFile = useCallback(
        (file) => {
            onImageChange(file);
            dispatch(modalClose());
            onClose?.();
        },
        [dispatch, onImageChange, onClose]
    );

    const onSuccess = useCallback(() => {
        if (utilityNumber) {
            dispatch(
                getResource({
                    resourceName: "fileList",
                    key: utilityNumber,
                    query: { entityTypeId: entityType.portalTemplate, entityId: utilityNumber },
                })
            );
            setIsUploading(false);
        }
    }, [utilityNumber, dispatch]);

    const onSubmit = useCallback(
        (file) => {
            if (file) {
                setIsUploading(true);
                dispatch(
                    uploadFile({
                        fileData: {
                            file,
                            entityTypeId: entityType.portalTemplate,
                            entityId: utilityNumber,
                            fileTypeId: fileType.supportingDocument,
                            itemFilterId: 149,
                        },
                        onUploadSuccess: onSuccess,
                        onUploadError: () => {
                            setIsUploading(false);
                        },
                    })
                );
            }
        },
        [dispatch, onSuccess, utilityNumber]
    );

    const schema = {
        type: "object",
        required: ["file"],
        properties: {
            file: {
                type: "array",
                items: {
                    type: "string",
                    format: "data-url",
                },
            },
        },
    };

    const uiSchema = {
        file: {
            "ui:widget": "DropZoneWidget",
            "ui:multiple": true,
        },
    };
    return (
        <ReactModal
            isOpen
            shouldCloseOnOverlayClick={false}
            onRequestClose={() => dispatch(modalClose())}
            bodyOpenClassName={cn("", bodyOpenClassName)}
            overlayClassName={cn("", overlayClassName)}
            className={`modal confirm-modal ${className}`}
        >
            {loading ? (
                <div className="flex-row justify-center">
                    <WaitIcon withSpaceAround />
                </div>
            ) : (
                <div className="modal-content image-property-modal">
                    <div className="justify-start file-tiles-container flex-row flex-wrap align-content-start image-widget-file">
                        {files?.fileList.map((f) => {
                            return (
                                <div
                                    title={!isSelectable(f) ? allowedFileTypeError : undefined}
                                    onClick={isSelectable(f) ? () => onSelectFile(f) : undefined}
                                    key={f.publicPath}
                                    className={cn("file-tile flex-column")}
                                >
                                    <FilePreview file={f} />
                                    <span className="file-name">{f.fileName}</span>
                                    <div className="file-select-overlay" onClick={isSelectable(f) ? () => onSelectFile(f) : undefined} />
                                </div>
                            );
                        })}
                    </div>
                    <Separator line marginTop={0} marginBottom={15} />
                    <JsonSchemaForm
                        noSubmit={false}
                        schema={schema}
                        uiSchema={uiSchema}
                        onSubmit={(f) => onSubmit(f.file)}
                        noReset
                        cancelText="Close"
                        onCancel={() => dispatch(modalClose())}
                        submitText={isUploading ? "Uploading..." : "Upload"}
                        disabled={isUploading}
                    />
                </div>
            )}
        </ReactModal>
    );
};
export default ImageModal;
