import React, { memo } from "react";
import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid";
import { invoiceCorrespondenceGridColumnKeys } from "../../../../../views/configureGrids";
import { exportPdf, getValidFileName } from "components/utils/files";
import { HtmlContentAsPdf } from "components/ui/HtmlContentPreview";
import SideNavContent from "components/ui/SideNav/SideNavContent";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import SideNavFooter from "components/ui/SideNav/SideNavFooter";
import Button from "components/ui/Button";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import { stripHtmlForDataGrid } from "components/utils/string";

const rowActions = [
    {
        name: "view",
        icon: "eye-visibility-empty",
        title: "View Correspondence",
    },
    {
        name: "download",
        icon: "download",
        title: "Download",
    },
];

const CorrespondenceGrid = memo(({ gridId, dataGridConfig }) => {
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers();

    const onRowAction = (action) => {
        const { dataItem } = action;
        const fileContent = dataItem[invoiceCorrespondenceGridColumnKeys.content];
        const fileName = getValidFileName({
            fileName: dataItem[invoiceCorrespondenceGridColumnKeys.documentNumber],
            fileExtension: "pdf",
        });

        switch (action.name) {
            case "view":
                handleOpenSidePanel(<ViewHtmlAsPdf fileContent={fileContent} fileName={fileName} onClose={handleCloseSidePanel} />);
                break;
            case "download":
                exportPdf({ fileName, fileContent });
                break;
            default:
                break;
        }
    };

    return (
        <DataGrid
            name={gridId}
            config={dataGridConfig}
            onRowAction={onRowAction}
            customRowActions={rowActions}
            columnCellContent={{
                [invoiceCorrespondenceGridColumnKeys.content]: (column, row) => stripHtmlForDataGrid(row[column.key] ?? ""),
            }}
        />
    );
});

const ViewHtmlAsPdf = memo(({ fileContent, fileName, onClose }) => {
    return (
        <SideNavContent className="sidenav-panel-correspondence">
            <SideNavHeader title="Correspondence" leadBlockIcon="eye-visibility-empty" smallHeader onClose={onClose} />
            <SideNavBody>
                <HtmlContentAsPdf fileContent={fileContent} fileName={fileName} />
            </SideNavBody>
            <SideNavFooter justifyEnd>
                <Button onClick={onClose}>Close</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default withDataGrid("invoiceCorrespondence", CorrespondenceGrid);
