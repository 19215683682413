import React from "react";
import ClassNames from "classnames";
import useFocusClasses from "../../utils/useFocusClasses";
import useEnterKey from "../../utils/useEnterKey";

import "./FieldIconClickable.scss";

const FieldIconClickable = (props) => {
    // Disabled tabbing of numeric input up down arrows
    const isTabable = false; //!props.iconClickableDisabled && Boolean(props.onClick);

    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled: !isTabable,
    });
    const [onEnter] = useEnterKey({
        disabled: !isTabable,
        onClick: props.onClick,
    });

    const className = ClassNames("with-icon field-icon-clickable", props.iconClickable, props.className, {
        disabled: props.iconClickableDisabled,
        "read-only": props.iconClickableReadOnly,
        error: props.iconClickableError,
        password: props.iconClickablePassword,
        arrows: props.arrows,
    });

    return (
        <div
            className={className}
            onClick={props.onClick}
            onFocus={onFocusClassesFocus}
            onBlur={onFocusClassesBlur}
            onKeyDown={onEnter}
            tabIndex={isTabable ? "0" : "-1"}
        />
    );
};

export default FieldIconClickable;
