import React from "react";

import DashboardPanel from "../DashboardPanel";
import { RecentApplicationsDataGrid } from "../../../../views/ApplicationProcessingRecentApplications";

const RecentAppsPanel = ({ panel }) => {
    return (
        <DashboardPanel title={panel.name} className="sticky-grid-list-panel">
            <RecentApplicationsDataGrid isWidget={true} />
        </DashboardPanel>
    );
};

export default React.memo(RecentAppsPanel);
