import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { get } from "lodash";

import { getBudgetLineAttributesGridId } from "../utils";
import { getData } from "store/dataGrid/actions";
import { exportDatagridToCSV } from "../../../../../utils/CSV";
import { systemUserRights } from "components/utils/user";
import { useUtilityRights } from "../../../../../../store/resources/useResource";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../../ui/SideNav/SideNavRoot";

import WaitIcon from "../../../../../ui/WaitIcon";
import Form from "./Form";
import Controls from "../../Controls";
import Grid from "./Grid";
import PanelHeaderLarge from "components/ui/Headers/PanelHeaderLarge";

import "../style.scss";

const AttributesPanel = ({ panel }) => {
    const utilityNumber = get(panel, "data.utilityNumber");
    const contractNumber = get(panel, "data.contractNumber");
    const budgetLineNumber = get(panel, "data.budgetLineNumber");
    const dataGridId = getBudgetLineAttributesGridId({ budgetLineNumber });

    const dispatch = useDispatch();

    const [utilityRights, isLoadingRights] = useUtilityRights({ utilityNumber });

    const canCreate = utilityRights.includes(systemUserRights.VISIONDSM_ADD_CONTRACT);

    const onRefresh = useCallback(() => {
        dispatch(getData({ dataGridId }));
    }, [dataGridId, dispatch]);

    const { handleOpenSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "budget-attributes-sidenav-panel",
    });

    const handleAddAttribute = useCallback(() => {
        handleOpenSidePanel(
            <Form
                utilityNumber={utilityNumber}
                contractNumber={contractNumber}
                budgetLineNumber={budgetLineNumber}
                gridRefresh={onRefresh}
            />
        );
    }, [utilityNumber, contractNumber, budgetLineNumber, onRefresh, handleOpenSidePanel]);

    const controlItems = [
        {
            position: "right",
            title: "Add Attribute",
            icon: "plus",
            onClick: handleAddAttribute,
            disabled: !canCreate,
        },
        {
            position: "right",
            title: "Export CSV",
            icon: "custom-export-csv",
            onClick: () =>
                exportDatagridToCSV({
                    dataGridId: dataGridId,
                    fileName: "budget_line_attributes",
                    fileNamePostfix: budgetLineNumber,
                }),
        },
    ];

    return (
        <div className="panel budget-attributes flex-column fill-height">
            <PanelHeaderLarge title={panel.name} />
            {isLoadingRights ? <WaitIcon /> : <Controls items={controlItems} />}
            <Grid
                dataGridId={dataGridId}
                utilityNumber={utilityNumber}
                contractNumber={contractNumber}
                budgetLineNumber={budgetLineNumber}
                onRefresh={onRefresh}
            />
        </div>
    );
};

export default AttributesPanel;
