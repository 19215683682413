import React, { useEffect, useState, memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { isNil } from "lodash";

import { createResource } from "../../../../../../store/resources/actions";
import useUnmounted from "../../../../../utils/useUnmounted";
import { useApplication } from "../../../../../../store/resources/useResource";
import { openCrm } from "components/utils/window";
import { getOpportunityTokenRequestParameters } from "components/utils/crm";

import ViewPlaceholder from "../../../../ViewPlaceholder";
import { ErrorMessage } from "../../../../Message";
import WaitIcon from "../../../../WaitIcon";
import IconWithLabel from "components/ui/Icons/IconWithLabel";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";

const OpportunityPanel = memo(({ panel }) => {
    const BASE_URL = `${process.env.REACT_APP_CRM_BASE_URL}`;
    const [token, setToken] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const applicationNumber = panel && panel.data && panel.data.applicationNumber;
    const [applicationDetails = {}] = useApplication({ applicationNumber });
    const unmounted = useUnmounted();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            createResource({
                resourceName: "ssoToken",
                key: "crmToken",
                body: getOpportunityTokenRequestParameters({
                    opportunityId: applicationDetails.opportunityId,
                    isPartial: true,
                    isVision: true,
                }),
                onSuccess: (action) => !unmounted.current && setToken(action.data.token),
                onComplete: () => !unmounted.current && setIsLoading(false),
            })
        );
    }, [applicationDetails.opportunityId, dispatch, unmounted]);

    const openInCrm = useCallback(() => {
        dispatch(
            createResource({
                resourceName: "ssoToken",
                key: "crmToken",
                body: getOpportunityTokenRequestParameters({
                    opportunityId: applicationDetails.opportunityId,
                    isPartial: false,
                    isVision: false,
                }),
                onSuccess: (action) => !unmounted.current && openCrm({ token: action.data.token }),
            })
        );
    }, [applicationDetails.opportunityId, unmounted, dispatch]);

    return (
        <div className="panel snapshot flex-column fill-height">
            <PanelHeaderLarge className="flex-row justify-space-between align-end" title="Opportunity Details">
                <IconWithLabel icon="open-new-window" iconWithLabelRight onClick={openInCrm}>
                    Open in CRM
                </IconWithLabel>
            </PanelHeaderLarge>
            {isLoading && <WaitIcon />}
            {!isLoading && isNil(token) && (
                <ViewPlaceholder>
                    <ErrorMessage>VisionCRM login failed</ErrorMessage>
                </ViewPlaceholder>
            )}
            {!isLoading && !isNil(token) && (
                <div className="crm">
                    <span className="global-nav-shadow-imitation"></span>
                    <iframe title="CRM" src={BASE_URL + token}></iframe>
                </div>
            )}
        </div>
    );
});

export default OpportunityPanel;
