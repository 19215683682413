import React, { useCallback, useRef, useState, useEffect, useMemo, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, range } from "lodash";

import { createResource } from "../../../../../../store/resources/actions";
import {
    useResource,
    useFolders,
    useFastTags,
    useApplicationForms,
    useApplicationAvailableDocuments,
} from "../../../../../../store/resources/useResource";
import { useReference } from "../../../../Reference/useReference";
import { referenceTypes } from "../../../../Reference/referenceTypes";
import { referenceToAnyOf, listToAnyOf, getSchemaIds } from "../../../../../utils/form";

import { refreshWorkflow } from "../../../../TaskWorkflow/utils";

import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import UserGroupsSelector from "../../../../UserGroupsSelector";
import { entityType as entityTypeId } from "../../../../../utils/entityType";
import { refreshApplication } from "../../../../../../store/resources/refreshResource";
import { getSelectedElementUiParams } from "../../../../../../store/formBuilder/selectors";
import { getApplicationFormDetailsResourceParams } from "store/configureResources";
import { DEFAULT_FOLDER } from "components/utils/files";

import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import Button from "../../../../Button";
import { submitByRef } from "../../../../../utils/form";
import useFormPageSections from "components/ui/Workflow/utils/hooks/useFormPageSections";

const STEP_TASK_FORM = "STEP_TASK_FORM";
const STEP_ASSIGN_GROUPS = "STEP_ASSIGN_GROUPS";

const AdHocTaskForm = memo(
    ({ programNumber, applicationNumber, workflowGetResourceOptions, onClose, sidePanel, entityType = "program" }) => {
        const dispatch = useDispatch();

        const wfNumber = useSelector((state) => state.resources.applicationDetails.itemsById[applicationNumber].wfNumber);
        const isLocked = useSelector((state) => state.resources.applicationDetails.itemsById[applicationNumber].isLocked);

        const [stepValues, setStepValues] = useState(null);
        const [step, setStep] = useState(STEP_TASK_FORM);
        const [isSubmitting, setSubmitting] = useState(false);
        const [formData, setFormData] = useState({});
        const [isTaskContentWithHtmlEditor, setIsTaskContentWithHtmlEditor] = useState(false);
        const initialValues = {};
        const isMobile = useSelector((state) => state.window?.isMobile);

        const [flagTypes = []] = useReference(referenceTypes.applicationFlagWorkflow);
        const [qcTypes = []] = useReference(referenceTypes.qcType);
        const [procedureList = [], isLoadingProcedureList] = useResource({
            resourceName: "referenceFilter",
            key: `${programNumber}-ICF_WF_SPs`,
            query: {
                type: "ICF_WF_SPs",
                outputType: "string",
                filterType: "program",
                entityNumber: programNumber,
            },
            transform: (data) => {
                return referenceToAnyOf({
                    list: data?.referenceResults ?? [],
                    type: "string",
                });
            },
        });

        const isEmail = useMemo(() => formData.wF_Step_TypeID === 1, [formData]);

        const [availableDocuments = [], isLoadingAvailableDocuments] = useApplicationAvailableDocuments({ applicationNumber, isEmail });

        const [correspondenceData] = useResource({
            resourceName: "programDocuments",
            resourceId: formData.corID,
            path: {
                programNumber,
            },
        });

        const [folders = []] = useFolders({
            entityTypeId: entityTypeId.projectApplication,
            entityId: programNumber,
        });

        const [forms = []] = useApplicationForms({ applicationNumber });

        const [workflowStatuses = []] = useResource({
            resourceName: "applicationWorkflowStatus",
            key: applicationNumber,
            path: {
                applicationNumber,
            },
        });

        const [fastTags, isLoadingFastTags] = useFastTags({ entityNumber: programNumber, entityType });

        const formPageNumber = useMemo(() => formData.formpgnum, [formData]);

        const [sections, isLoadingFormSections] = useFormPageSections({ entityId: programNumber, pageNumber: formPageNumber });
        const [formFields = [], isLoadingFormFields] = useResource({
            ...getApplicationFormDetailsResourceParams({
                applicationNumber,
                applicationFormId: formPageNumber,
            }),
            transform: (data) => {
                if (data?.formConfiguration) {
                    const { schema, uiSchema } = data.formConfiguration;
                    const pageId = "root";

                    return getSchemaIds(schema, true)
                        .map((item) => ({
                            ...item,
                            id: pageId + "_" + item.id.split(".").join("_"),
                        }))
                        .map(({ title, id }) => {
                            const formBuilderState = {
                                schema,
                                uiSchema,
                                selectedElementId: id,
                            };

                            const elementUiParams = getSelectedElementUiParams(formBuilderState);

                            return {
                                fieldNumber: elementUiParams["af:fieldNumber"],
                                friendlyName: elementUiParams["af:friendlyName"],
                            };
                        })
                        .filter((item) => !isEmpty(item.friendlyName));
                }

                return [];
            },
        });

        const getSchema = useCallback(() => {
            const foldersList =
                folders.length === 0
                    ? [
                          {
                              value: DEFAULT_FOLDER,
                              display: DEFAULT_FOLDER,
                          },
                      ]
                    : folders;

            const formEnum = forms.map((form) => {
                return {
                    title: form.pageName,
                    enum: [form.pageNumber],
                };
            });

            const formSectionEnum = sections.map((section) => {
                return {
                    title: section.name,
                    enum: [section.value],
                };
            });

            const formFieldEnum = formFields.map((field) => {
                return {
                    title: field.friendlyName,
                    enum: [field.fieldNumber],
                };
            });

            const stepTypeList = isLocked
                ? [
                      {
                          title: "Clear Flag",
                          enum: [12],
                      },
                  ]
                : [
                      {
                          title: "Send Email",
                          enum: [1],
                      },
                      {
                          title: "Document Upload",
                          enum: [2],
                      },
                      {
                          title: "Send Letter",
                          enum: [3],
                      },
                      {
                          title: "Perform Task",
                          enum: [4],
                      },
                      {
                          title: "QC Task",
                          enum: [6],
                      },
                      {
                          title: "Flag Record",
                          enum: [7],
                      },
                      {
                          title: "General Procedure",
                          enum: [8],
                      },
                      {
                          title: "Approval",
                          enum: [10],
                      },
                      {
                          title: "Test for QC with Status Change",
                          enum: [11],
                      },
                      {
                          title: "Clear Flag",
                          enum: [12],
                      },
                      {
                          title: "Activate Form Page",
                          enum: [13],
                      },
                      {
                          title: "Application Form Entry Task",
                          enum: [14],
                      },
                      {
                          title: "Application Form Field Entry Task",
                          enum: [15],
                      },
                      {
                          title: "Create New Measure",
                          enum: [16],
                      },
                      {
                          title: "Create New Contact",
                          enum: [17],
                      },
                      {
                          title: "Application Form Section Entry Task",
                          enum: [18],
                      },
                  ];

            const flagTypeList = referenceToAnyOf({
                list: isLocked ? flagTypes.filter((item) => item.val === "374") : flagTypes,
            });
            const qcTypeList = referenceToAnyOf({ list: qcTypes });

            const qcPercentList = range(1, 101);

            const workflowStatusList = workflowStatuses.map((item) => {
                return {
                    title: item.status,
                    enum: [item.workflowNumber],
                };
            });

            const availableDocumentList = listToAnyOf({
                list: availableDocuments,
                map: (item) => ({
                    title: item.name,
                    enum: [item.documentNumber],
                }),
                emptyItem: true,
            });

            return {
                type: "object",
                required: ["step", "wF_Step_TypeID", "term"],
                properties: {
                    step: {
                        type: "string",
                        title: "Item Step",
                    },
                    ...(!isMobile && {
                        constraintRule: {
                            type: "string",
                            title: "Constraint Rule",
                            default: "_current_stored_value_",
                            anyOf: [
                                {
                                    type: "string",
                                    title: "Current Stored Value",
                                    enum: ["_current_stored_value_"],
                                },
                                {
                                    type: "string",
                                    title: "completeToHere()",
                                    enum: ["completeToHere()"],
                                },
                            ],
                        },
                    }),
                    wF_Step_TypeID: {
                        type: "integer",
                        title: "Step Type",
                        anyOf: stepTypeList,
                    },
                    ...(!isMobile && {
                        autoCompleteRule: {
                            type: "string",
                            title: "Autocomplete Rule",
                            default: "_current_stored_value_",
                            anyOf: [
                                {
                                    type: "string",
                                    title: "Current Stored Value",
                                    enum: ["_current_stored_value_"],
                                },
                                {
                                    type: "string",
                                    title: "True",
                                    enum: ["true"],
                                },
                            ],
                        },
                    }),
                    term: {
                        type: "integer",
                        title: "Term (days)",
                        default: 0,
                        enum: range(-1, 366),
                    },
                    ...(isMobile && {
                        constraintRule: {
                            type: "string",
                            title: "Constraint Rule",
                            default: "_current_stored_value_",
                            anyOf: [
                                {
                                    type: "string",
                                    title: "Current Stored Value",
                                    enum: ["_current_stored_value_"],
                                },
                                {
                                    type: "string",
                                    title: "completeToHere()",
                                    enum: ["completeToHere()"],
                                },
                            ],
                        },
                        autoCompleteRule: {
                            type: "string",
                            title: "Autocomplete Rule",
                            default: "_current_stored_value_",
                            anyOf: [
                                {
                                    type: "string",
                                    title: "Current Stored Value",
                                    enum: ["_current_stored_value_"],
                                },
                                {
                                    type: "string",
                                    title: "True",
                                    enum: ["true"],
                                },
                            ],
                        },
                    }),
                },
                dependencies: {
                    wF_Step_TypeID: {
                        oneOf: [
                            {
                                properties: {
                                    wF_Step_TypeID: {
                                        enum: [1],
                                    },
                                    emailSubject: {
                                        type: "string",
                                        title: "Email Subject Line",
                                    },
                                    corID: {
                                        type: "string",
                                        title: "Existing Correspondence",
                                        anyOf: availableDocumentList,
                                    },
                                    content: {
                                        type: "string",
                                        title: "Content",
                                    },
                                },
                            },
                            {
                                properties: {
                                    wF_Step_TypeID: {
                                        enum: [2],
                                    },
                                    folderName: {
                                        type: "string",
                                        title: "Target Folder Name",
                                        default: folders.length === 0 ? DEFAULT_FOLDER : undefined,
                                        anyOf: listToAnyOf({
                                            list: foldersList,
                                            map: (i) => ({
                                                title: i.display,
                                                enum: [i.value],
                                            }),
                                        }),
                                    },
                                },
                            },
                            {
                                properties: {
                                    wF_Step_TypeID: {
                                        enum: [3],
                                    },
                                    corID: {
                                        type: "string",
                                        title: "Existing Correspondence",
                                        anyOf: availableDocumentList,
                                    },
                                    content: {
                                        type: "string",
                                        title: "Content",
                                    },
                                },
                            },
                            {
                                properties: {
                                    wF_Step_TypeID: {
                                        enum: [4],
                                    },
                                    content: {
                                        type: "string",
                                        title: "Content",
                                    },
                                },
                            },
                            {
                                properties: {
                                    wF_Step_TypeID: {
                                        enum: [5],
                                    },
                                },
                            },
                            {
                                required: ["qcType", "qcPercent"],
                                properties: {
                                    wF_Step_TypeID: {
                                        enum: [6],
                                    },
                                    qcType: {
                                        type: "integer",
                                        title: "QC Type",
                                        anyOf: qcTypeList,
                                    },
                                    qcPercent: {
                                        type: "integer",
                                        title: "QC Threshold %",
                                        enum: qcPercentList,
                                    },
                                    content: {
                                        type: "string",
                                        title: "Content",
                                    },
                                },
                            },
                            {
                                properties: {
                                    wF_Step_TypeID: {
                                        enum: [7],
                                    },
                                    appCd: {
                                        type: "integer",
                                        title: "Flag Type",
                                        anyOf: flagTypeList,
                                    },
                                },
                                dependencies: {
                                    appCd: {
                                        oneOf: [
                                            {
                                                properties: {
                                                    appCd: {
                                                        enum: [202],
                                                    },
                                                    formpgnum: {
                                                        type: "string",
                                                        title: "Select Form",
                                                        anyOf: formEnum,
                                                    },
                                                },
                                            },
                                        ],
                                    },
                                },
                            },
                            {
                                required: ["proc"],
                                properties: {
                                    wF_Step_TypeID: {
                                        enum: [8],
                                    },
                                    newstatus: {
                                        type: "string",
                                        title: "Target Status",
                                        anyOf: workflowStatusList,
                                    },
                                    proc: {
                                        type: "string",
                                        title: "Procedure Code",
                                        anyOf: procedureList,
                                    },
                                    emailSubject: {
                                        type: "string",
                                        title: "Email Subject Line",
                                    },
                                    content: {
                                        type: "string",
                                        title: "Content",
                                    },
                                },
                            },
                            {
                                properties: {
                                    wF_Step_TypeID: {
                                        enum: [9],
                                    },
                                },
                            },
                            {
                                properties: {
                                    wF_Step_TypeID: {
                                        enum: [10],
                                    },
                                    content: {
                                        type: "string",
                                        title: "Content",
                                    },
                                },
                            },
                            {
                                required: ["qcType", "qcPercent"],
                                properties: {
                                    wF_Step_TypeID: {
                                        enum: [11],
                                    },
                                    qcType: {
                                        type: "integer",
                                        title: "QC Type",
                                        anyOf: qcTypeList,
                                    },
                                    qcPercent: {
                                        type: "integer",
                                        title: "QC Threshold %",
                                        enum: qcPercentList,
                                    },
                                    newstatus: {
                                        type: "string",
                                        title: "Target Status",
                                        anyOf: workflowStatusList,
                                    },
                                    content: {
                                        type: "string",
                                        title: "Content",
                                    },
                                },
                            },
                            {
                                properties: {
                                    wF_Step_TypeID: {
                                        enum: [12],
                                    },
                                    appCd: {
                                        type: "integer",
                                        title: "Flag Type",
                                        anyOf: flagTypeList,
                                    },
                                },
                                dependencies: {
                                    appCd: {
                                        oneOf: [
                                            {
                                                properties: {
                                                    appCd: {
                                                        enum: [202],
                                                    },
                                                    formpgnum: {
                                                        type: "string",
                                                        title: "Select Form",
                                                        anyOf: formEnum,
                                                    },
                                                },
                                            },
                                        ],
                                    },
                                },
                            },
                            {
                                properties: {
                                    wF_Step_TypeID: {
                                        enum: [13],
                                    },
                                    formpgnum: {
                                        type: "string",
                                        title: "Select Form",
                                        anyOf: formEnum,
                                    },
                                },
                            },
                            {
                                properties: {
                                    wF_Step_TypeID: {
                                        enum: [14],
                                    },
                                    formpgnum: {
                                        type: "string",
                                        title: "Select Form",
                                        anyOf: formEnum,
                                    },
                                    content: {
                                        type: "string",
                                        title: "Content",
                                    },
                                },
                            },
                            {
                                properties: {
                                    wF_Step_TypeID: {
                                        enum: [15],
                                    },
                                    formpgnum: {
                                        type: "string",
                                        title: "Select Form",
                                        anyOf: formEnum,
                                    },
                                    ...(formPageNumber
                                        ? {
                                              appFieldID: {
                                                  type: "string",
                                                  title: "Select Field",
                                                  anyOf: formFieldEnum,
                                              },
                                          }
                                        : {}),
                                    content: {
                                        type: "string",
                                        title: "Content",
                                    },
                                },
                            },
                            {
                                properties: {
                                    wF_Step_TypeID: {
                                        enum: [16],
                                    },
                                },
                            },
                            {
                                properties: {
                                    wF_Step_TypeID: {
                                        enum: [17],
                                    },
                                },
                            },
                            {
                                properties: {
                                    wF_Step_TypeID: {
                                        enum: [18],
                                    },
                                    formpgnum: {
                                        type: "string",
                                        title: "Select Form",
                                        anyOf: formEnum,
                                    },
                                    ...(formPageNumber
                                        ? {
                                              formsecnum: {
                                                  type: "string",
                                                  title: "Select Section",
                                                  anyOf: formSectionEnum,
                                              },
                                          }
                                        : {}),
                                    content: {
                                        type: "string",
                                        title: "Content",
                                    },
                                },
                            },
                        ],
                    },
                },
            };
        }, [
            folders,
            forms,
            sections,
            formFields,
            isLocked,
            flagTypes,
            qcTypes,
            workflowStatuses,
            availableDocuments,
            isMobile,
            procedureList,
            formPageNumber,
        ]);

        const uiSchema = {
            "ui:rootFieldId": "adhoc-task",
            classNames: "inline-form columns-2",
            constraintRule: {
                "ui:emptyItem": true,
            },
            autoCompleteRule: {
                classNames: isMobile ? "autocomplete-rule-mobile fill-width" : "",
                "ui:emptyItem": true,
            },
            term: {
                classNames: !isMobile ? "term fill-width" : "",
            },
            corID: {
                "ui:placeholder": isLoadingAvailableDocuments ? "Loading..." : "Choose Correspondence",
                "ui:disabled": isLoadingAvailableDocuments || !availableDocuments.length,
            },
            content: {
                classNames: "fill-width",
                "ui:widget": isTaskContentWithHtmlEditor ? "HtmlEditorWidget" : "textarea",
                "ui:options": {
                    fastTags,
                    isLoadingFastTags,
                },
            },
            appFieldID: {
                "ui:placeholder": isLoadingFormFields ? "Loading..." : "Choose Form Field",
                "ui:disabled": isLoadingFormFields || !formFields.length,
            },
            formsecnum: {
                "ui:placeholder": isLoadingFormSections ? "Loading..." : "Choose Form Section",
                "ui:disabled": isLoadingFormSections || !sections.length,
            },
            newstatus: {
                "ui:placeholder": "-- SELECT --",
            },
            proc: {
                "ui:placeholder": isLoadingProcedureList ? "Loading..." : "-- SELECT --",
            },
        };

        useEffect(() => {
            if (correspondenceData) {
                formRef.current.setFormData({
                    ...formRef.current.state.formData,
                    content: correspondenceData.content,
                });
            }
        }, [correspondenceData]);

        // Remove CK Editor from the DOM when isTaskContentWithHtmlEditor is changed to false
        useEffect(() => {
            if (!isTaskContentWithHtmlEditor) {
                const editorElement = document.querySelector(".sidenav-content .cke");
                if (editorElement) {
                    editorElement.remove();
                    formRef.current.setFormData({
                        ...formRef.current.state.formData,
                        content: "",
                    });
                }
            }
        }, [isTaskContentWithHtmlEditor]);

        const onChange = useCallback(
            (form) => {
                setIsTaskContentWithHtmlEditor([1, 3].includes(form.formData.wF_Step_TypeID));

                const nextValue = form.formData;
                const resetValues = {};

                if (formData.wF_Step_TypeID !== nextValue.wF_Step_TypeID) {
                    resetValues.corID = undefined;
                }

                if (formData.formpgnum !== nextValue.formpgnum) {
                    resetValues.appFieldID = undefined;
                }

                const newFormData = {
                    ...nextValue,
                    ...resetValues,
                };

                if (!isEmpty(resetValues)) {
                    formRef.current.setFormData(newFormData);
                }

                setFormData(newFormData);
            },
            [formData, setFormData]
        );

        const onTaskFormStepSubmitButtonClicked = () => {
            submitByRef(formRef);
        };

        const onStepSubmit = (formData) => {
            const additionalAttributes = [
                "emailSubject",
                "corID",
                "folderName",
                "corID",
                "qcType",
                "qcPercent",
                "appCd",
                "formpgnum",
                "proc",
                "appFieldID",
                "formsecnum",
            ].reduce((result, attrName) => {
                if (formData[attrName]) {
                    result.push(`${attrName}=${formData[attrName]}`);
                }

                return result;
            }, []);

            // Remove placeholder string value to bypass dropdown not selecting empty value
            const updatedFormData = { ...formData };

            if (formData["autoCompleteRule"] === "_current_stored_value_") {
                updatedFormData.autoCompleteRule = "";
            }

            if (formData["constraintRule"] === "_current_stored_value_") {
                updatedFormData.constraintRule = "";
            }

            setStepValues({
                ...updatedFormData,
                targetEntityType: "application",
                targetEntityId: applicationNumber,
                content: formData.content || "",
                customStep: "y",
                workflowstatus: 151,
                additionalAttributes,
            });
            setStep(STEP_ASSIGN_GROUPS);
        };

        const onStepCreated = () => {
            onClose({ success: true });
            refreshWorkflow({
                resourceParams: workflowGetResourceOptions,
            });

            refreshApplication({ applicationNumber });
        };

        const onGroupAssignmentsSubmit = (groups) => {
            setSubmitting(true);

            dispatch(
                createResource({
                    resourceName: "workflowSteps",
                    path: {
                        wfNumber,
                    },
                    body: {
                        groupAssignments: groups.map((g) => g.groupNumber),
                        ...stepValues,
                    },
                    onSuccess: onStepCreated,
                    onError: () => {
                        setSubmitting(false);
                    },
                })
            );
        };

        const onBack = () => {
            const rules = {};
            if (stepValues.autoCompleteRule === "") {
                rules.autoCompleteRule = "_current_stored_value_";
            }

            if (stepValues.constraintRule === "") {
                rules.constraintRule = "_current_stored_value_";
            }

            setStepValues({
                ...stepValues,
                ...rules,
            });
            setStep(STEP_TASK_FORM);
        };
        const title = step !== STEP_ASSIGN_GROUPS ? "Add Ad-hoc Task" : "Add Ad-hoc Task > Assign Groups";
        const assignGroupsStepText = "Assign Groups";
        const formRef = useRef();

        useEffect(() => {
            sidePanel.setForm(formRef);
        }, [sidePanel]);

        return (
            <SideNavContent>
                <SideNavHeader title={title} leadBlockIcon="plus" smallHeader onClose={onClose} />
                <SideNavBody hidden={step !== STEP_TASK_FORM} className="flex-one-in-column">
                    <JsonSchemaForm
                        formRef={formRef}
                        schema={getSchema()}
                        uiSchema={uiSchema}
                        initialValues={initialValues}
                        disabled={isSubmitting}
                        onSubmit={onStepSubmit}
                        onChange={onChange}
                        submitText={assignGroupsStepText}
                        noReset
                        noCancel
                        noSubmit
                    />
                </SideNavBody>
                <SideNavFooter hidden={step !== STEP_TASK_FORM} justifyCenter>
                    <Button primary disabled={isSubmitting} onClick={onTaskFormStepSubmitButtonClicked}>
                        {assignGroupsStepText}
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </SideNavFooter>
                <UserGroupsSelector
                    programNumber={programNumber}
                    groupType="WFUserGroup"
                    onSubmit={onGroupAssignmentsSubmit}
                    submitText={isSubmitting ? "Creating..." : "Create Task"}
                    icon={isSubmitting ? "" : "plus"}
                    disabled={isSubmitting}
                    onBack={onBack}
                    onClose={onClose}
                    hidden={step !== STEP_ASSIGN_GROUPS} // hide it, so selected groups remain when going back
                />
            </SideNavContent>
        );
    }
);

export default AdHocTaskForm;
