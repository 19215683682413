import React, { useEffect, useState, memo } from "react";
import { connect } from "react-redux";
import { isNil } from "lodash";

import { createResource } from "../../../../../../store/resources/actions";

import WaitIcon from "../../../../WaitIcon";
import useUnmounted from "../../../../../utils/useUnmounted";
import ViewPlaceholder from "../../../../ViewPlaceholder";
import { ErrorMessage } from "../../../../Message";

import "./style.scss";

const BASE_URL = `${process.env.REACT_APP_FORECAST_BASE_URL}`;

const ForecastPanel = memo(({ panel, dispatch }) => {
    const utilityNumber = panel && panel.data && panel.data.utilityNumber;

    const [token, setToken] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const unmounted = useUnmounted();

    useEffect(() => {
        dispatch(
            createResource({
                resourceName: "ssoToken",
                key: "forecastToken",
                body: {
                    parameters: "target=/VisionCore/Forecast/Index/" + utilityNumber,
                    sourceSystem: "Vision",
                },
                onSuccess: (action) => !unmounted.current && setToken(action.data.token),
                onComplete: () => !unmounted.current && setIsLoading(false),
            })
        );
    }, [utilityNumber, dispatch, unmounted]);

    if (isLoading) {
        return <WaitIcon />;
    }

    if (isNil(token)) {
        return (
            <ViewPlaceholder>
                <ErrorMessage>Forecast login failed</ErrorMessage>
            </ViewPlaceholder>
        );
    }

    return (
        <div className="forecast">
            <iframe title="Forecast" src={BASE_URL + token}></iframe>
        </div>
    );
});

export default connect()(ForecastPanel);
