import * as actionTypes from "../actionTypes";
import { logError } from "components/utils/logger";
import { checkCalculationStatus } from "./actions";

const actionTypesToMonitor = [
    actionTypes.API_CRUD_CREATE_SUCCESS,
    actionTypes.API_CRUD_UPDATE_SUCCESS,
    actionTypes.API_CRUD_DELETE_SUCCESS,
];

// Trigger calc status check if these resources were called with POST, PUT or DELETE
const resourceNamesForCalcStatusCheck = ["equipment", "event"];

export function projectsMiddleware({ dispatch, getState }) {
    return (next) => (action) => {
        if (actionTypesToMonitor.includes(action.type)) {
            calculationStatus({ action, dispatch });
        }

        return next(action);
    };
}

/**
 * Call calculation status check
 * if application called any of monitored APIs
 *
 * @param {*} { action, dispatch }
 */
const calculationStatus = ({ action, dispatch }) => {
    const { resourceName, path } = action.passThroughData ?? {};

    if (resourceNamesForCalcStatusCheck.includes(resourceName)) {
        const applicationNumber = path?.appId;

        if (applicationNumber) {
            dispatch(checkCalculationStatus({ applicationNumber }));
        } else {
            logError(`projectsMiddleware: resource ${resourceName}, applicationNumber not found`);
        }
    }
};
