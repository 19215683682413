import React, { useCallback, useMemo, useContext, memo } from "react";

import { useResource } from "../../../../../../../../store/resources/useResource";
import { useReference } from "../../../../../../Reference/useReference";
import { STEP_TYPES_ATTRIBUTES, ALLOW_CUSTOMER_APPLICATION_EDIT_FLAG } from "../../../../../constants/step-types";

import WaitIcon from "../../../../../../WaitIcon";
import DropDownInput from "../../../../../../Input/DropDownInput";

import { WorkflowContext } from "../../../../../context/WorkflowContext";
import { StepPropertiesSettingsContext } from "../../../../../context/StepPropertiesSettingsContext";
import useFormPages from "components/ui/Workflow/utils/hooks/useFormPages";

export const ApplicationFlagField = memo(({ onChangeAttribute }) => {
    const { entityId, flagWorkflowReference, stepAttributeTypesGetResourceOptions, onChangeWorkflowStepProperties, isLocked } =
        useContext(WorkflowContext);
    const { attributes } = useContext(StepPropertiesSettingsContext);

    const [applicationFlags = [], isApplicationFlagsLoading] = useReference(flagWorkflowReference);
    const [workflowStepAttributeTypes = [], isLoadingWorkflowStepAttributeTypes] = useResource(stepAttributeTypesGetResourceOptions);
    const [pages, isLoadingFormPages] = useFormPages({ entityId });

    const getAttribute = useCallback(
        ({ number = null, code = null }) => {
            if (number) {
                return workflowStepAttributeTypes.find((i) => i.number === number);
            } else if (code) {
                return workflowStepAttributeTypes.find((i) => i.code === code);
            }
        },
        [workflowStepAttributeTypes]
    );

    const { appCd, formpgnum } = useMemo(() => {
        const { appCd, formpgnum } = attributes.reduce(
            (acc, { typeNumber, value }) => {
                const attribute = getAttribute({ number: typeNumber });

                if (attribute.code === STEP_TYPES_ATTRIBUTES.APPLICATION_FLAG_CODE) {
                    if (!isApplicationFlagsLoading && value) {
                        acc.appCd = value;
                    }
                } else if (attribute.code === STEP_TYPES_ATTRIBUTES.FORM_PAGE_NUMBER) {
                    acc.formpgnum = value;
                }

                return acc;
            },
            { appCd: null, formpgnum: "" }
        );

        return {
            appCd,
            formpgnum,
        };
    }, [attributes, isApplicationFlagsLoading, getAttribute]);

    const handleChange = useCallback(
        (e, key) => {
            if (key === STEP_TYPES_ATTRIBUTES.APPLICATION_FLAG_CODE) {
                const attribute = getAttribute({
                    code: STEP_TYPES_ATTRIBUTES.APPLICATION_FLAG_CODE,
                });

                onChangeWorkflowStepProperties({
                    content: "",
                    attributes: [
                        {
                            typeNumber: attribute.number,
                            name: attribute.name,
                            value: e.target.value || "",
                        },
                    ],
                });
            } else if (key === STEP_TYPES_ATTRIBUTES.FORM_PAGE_NUMBER) {
                const attribute = getAttribute({
                    code: STEP_TYPES_ATTRIBUTES.FORM_PAGE_NUMBER,
                });

                onChangeAttribute(e, attribute.number, attribute.name);
            }
        },
        [onChangeAttribute, onChangeWorkflowStepProperties, getAttribute]
    );

    const formPagesLabel = getAttribute({
        code: STEP_TYPES_ATTRIBUTES.FORM_PAGE_NUMBER,
    }).name;

    if (isLoadingWorkflowStepAttributeTypes) {
        return <WaitIcon />;
    }

    return (
        <>
            <DropDownInput
                className="workflow-step-types-widget__attribute-field"
                value={appCd}
                label={
                    getAttribute({
                        code: STEP_TYPES_ATTRIBUTES.APPLICATION_FLAG_CODE,
                    }).name
                }
                required
                readOnly={isLocked}
                onChange={(e) => handleChange(e, STEP_TYPES_ATTRIBUTES.APPLICATION_FLAG_CODE)}
                data={applicationFlags.map((applicationFlag) => ({
                    value: applicationFlag.val,
                    label: applicationFlag.display,
                }))}
                placeholder={isApplicationFlagsLoading ? "Loading..." : "Flag Code"}
                disabled={isApplicationFlagsLoading}
                mobileHeader="Flag Code"
            />
            {appCd === ALLOW_CUSTOMER_APPLICATION_EDIT_FLAG && (
                <DropDownInput
                    className="workflow-step-types-widget__attribute-field"
                    value={isLoadingFormPages ? undefined : formpgnum}
                    label={formPagesLabel}
                    onChange={(e) => handleChange(e, STEP_TYPES_ATTRIBUTES.FORM_PAGE_NUMBER)}
                    readOnly={isLocked}
                    data={pages.map((item) => ({
                        label: item.name,
                        value: item.number,
                    }))}
                    placeholder={isLoadingFormPages ? "Loading..." : formPagesLabel}
                    disabled={isLoadingFormPages}
                    mobileHeader={formPagesLabel}
                />
            )}
        </>
    );
});
