import { useState, useEffect } from "react";
import useUnmounted from "./useUnmounted";

export const useDelayedShow = ({ delay }) => {
    const [show, setShow] = useState(delay > 0 ? false : true);
    const unmounted = useUnmounted();

    useEffect(() => {
        if (delay > 0) {
            setTimeout(() => {
                !unmounted.current && setShow(true);
            }, delay);
        }
    }, [delay, unmounted]);

    return show;
};
