import React, { memo } from "react";

import DashboardPanel from "../DashboardPanel";
import { AssignedAppsDataGrid } from "../../../../views/ApplicationProcessingAssignedApps";

const AssignedAppsPanel = memo(({ panel }) => {
    return (
        <DashboardPanel title={panel.name} className="sticky-grid-list-panel">
            <AssignedAppsDataGrid isWidget={true} />
        </DashboardPanel>
    );
});

export default AssignedAppsPanel;
