// Debounce timeout form input fields
export const debounceTimeout = 200;

// Compile time flags to identify environment we are in
export const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT === "DEVELOPMENT";
export const isProdEnvironment = process.env.REACT_APP_ENVIRONMENT === "PRODUCTION";

export const STATUS_ACTIVE = "Active";
export const STATUS_DISABLED = "Disabled";

export const MAX_DATE = new Date("9999-12-31");

export const CONTACT_TYPE = {
    PREMISE: "premise",
    PRIMARY: "primary",
    CONTRACTOR: "contractor",
};

export const entityType = {
    Utility: 932,
    Program: 933,
    Contract: 935,
    InvoiceDocument: 936,
};

export const clientUserRolesUpdateEntityType = {
    Utility: 2,
    Program: 3,
};

export const clientHost = {
    sightLine: "sightline",
};

export const clientRoute = {
    applications: "applications",
    invoices: "invoices",
    programs: "programs",
    utilities: "utilities",
    resetPassword: "resetpassword",
    saml: "saml",
};

export const documentType = {
    email: 39,
    letter: 40,
    testMessage: 854,
};

export const cisSearchType = {
    AccountNumber: 428,
    PremiseID: 429,
    AccountAndPremise: 430,
    AccountAndMeter: 1112,
};

export const contactType = {
    CustomerAccountAccess: 976,
};

export const TrueFalse = {
    True: 328,
    False: 329,
};

export const YesNo = {
    Yes: "Y",
    No: "N",
};

export const RunAppStatusInWizard = {
    Entry: "entry",
    Received: "received",
    None: "none",
};
