import React from "react";

import DashboardPanel from "../DashboardPanel";
import { ApplicationBookmarksDataGrid } from "../../../../views/ApplicationProcessingBookmarks";

const BookmarksPanel = ({ panel }) => {
    return (
        <DashboardPanel title={panel.name} className="sticky-grid-list-panel">
            <ApplicationBookmarksDataGrid isWidget={true} />
        </DashboardPanel>
    );
};

export default React.memo(BookmarksPanel);
