import React, { useState, memo, useCallback, useRef } from "react";
import { get } from "lodash";
import { useDispatch } from "react-redux";

import { entityType } from "../../../../../utils/entityType";
import { fileType } from "../../../../../utils/fileType";
import { useSidePanelHandlers } from "../../../../../utils/useSidePanelHandlers";
import { invoiceCorrespondenceGridColumnKeys, invoiceFilesGridColumnKeys } from "../../../../../views/configureGrids";
import { invoiceIsLockedText, useInvoiceDocumentLocked } from "../../../../../views/UtilityInvoiceView/utils";
import { getInvoiceFilesGridId } from "../utils";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import { downloadAllFiles } from "../../../../../../store/files/actions";
import { useResource } from "store/resources/useResource";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import TabList from "../../../../List/TabList";
import IconWithLabel from "../../../../Icons/IconWithLabel";
import FileUpload from "../../../../FileUpload";
import FilesGrid from "./FilesGrid";
import CorrespondenceGrid from "./CorrespondenceGrid";
import ButtonGroup from "../../../../Button/ButtonGroup";
import SideNavFormContainer from "../../../../SideNavFormContainer";
import { refreshInvoiceFilesGrid } from "store/dataGrid/refreshGrid";

const tabs = [
    {
        id: "files",
        title: "Files",
    },
    {
        id: "correspondence",
        title: "Correspondence",
    },
];

const DocumentsPanel = memo(({ panel }) => {
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "invoice-documents-sidenav-panel",
    });
    const documentNumber = get(panel, "data.documentNumber");
    const utilityNumber = get(panel, "data.utilityNumber");

    const fileUploadComponentRef = useRef();

    const [activeTab, setActiveTab] = useState(tabs[0]);
    const isLocked = useInvoiceDocumentLocked({ documentNumber });

    const dispatch = useDispatch();
    const entityTypeId = entityType.invoice;
    const dataGridId = getInvoiceFilesGridId({ documentNumber });
    const onUploadSuccess = useCallback(() => {
        handleCloseSidePanel();
        refreshInvoiceFilesGrid({ documentNumber });
    }, [handleCloseSidePanel, documentNumber]);
    const [files] = useResource({
        resourceName: "fileUploadReqs",
        key: entityTypeId,
        path: {
            entityTypeId: entityTypeId,
        },
    });

    const handleUpload = useCallback(() => {
        handleOpenSidePanel(
            <SideNavFormContainer
                title="File Upload"
                onClose={handleCloseSidePanel}
                forwardedFormComponentRef={fileUploadComponentRef}
                submitText="Upload"
                titleIcon="upload"
                fileUpload
            >
                <FileUpload
                    className="with-panel-borders with-form"
                    ref={fileUploadComponentRef}
                    entityTypeId={entityType.invoice}
                    entityId={documentNumber}
                    fileTypeId={fileType.supportingDocument}
                    onCancel={handleCloseSidePanel}
                    onUploadSuccess={onUploadSuccess}
                    withinSideNav
                    withoutHeaderPanel
                    files={files}
                />
            </SideNavFormContainer>
        );
    }, [documentNumber, handleOpenSidePanel, handleCloseSidePanel, onUploadSuccess, files]);

    return (
        <div className="panel invoice-documents flex-column fill-height">
            <PanelHeaderLarge title={panel.name} />
            <div className="document-toolbar flex-row">
                <TabList items={tabs} activeItem={activeTab} onClick={setActiveTab} />
                <div className="file-controls flex-one flex-row justify-end">
                    {activeTab.id === "files" && (
                        <ButtonGroup className="flex-row no-shrink" transparent>
                            <IconWithLabel
                                iconWithLabelRight
                                icon="upload"
                                disabled={isLocked}
                                title={isLocked ? invoiceIsLockedText : undefined}
                                onClick={handleUpload}
                            >
                                Upload
                            </IconWithLabel>
                            <IconWithLabel
                                iconWithLabelRight
                                iconWithLabel="download"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(
                                        downloadAllFiles({
                                            entityId: documentNumber,
                                            entityTypeId,
                                        })
                                    );
                                }}
                            >
                                Download All
                            </IconWithLabel>
                        </ButtonGroup>
                    )}
                </div>
            </div>
            {activeTab.id === "files" && (
                <div className="flex-one-in-column">
                    <FilesGrid
                        utilityNumber={utilityNumber}
                        entityId={documentNumber}
                        entityTypeId={entityTypeId}
                        gridId={dataGridId}
                        gridColumnKeys={invoiceFilesGridColumnKeys}
                        filter={`${invoiceFilesGridColumnKeys.entityId}=${documentNumber}`}
                        sort={[
                            {
                                field: invoiceFilesGridColumnKeys.date,
                                dir: "desc",
                            },
                        ]}
                        isLocked={isLocked}
                    />
                </div>
            )}
            {activeTab.id === "correspondence" && (
                <CorrespondenceGrid
                    gridId={`${documentNumber}-invoice-correspondence-grid`}
                    filter={`${invoiceCorrespondenceGridColumnKeys.documentNumber}=${documentNumber}|${invoiceCorrespondenceGridColumnKeys.type}=Email-Invoice`}
                />
            )}
        </div>
    );
});

export default DocumentsPanel;
