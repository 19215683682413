import React, { useState, useCallback, memo, useMemo, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { isNil } from "lodash";

import { getUserGroupsGridId, userGroupsGridColumnKeys } from "./utils";
import {
    refreshInvoiceWorkflows,
    refreshContractWorkflowAssignments,
    refreshProgramWorkflowAssignments,
} from "../../../store/resources/refreshResource";
import { getData, selectRows } from "../../../store/dataGrid/actions";
import { exportDatagridToCSV } from "../../utils/CSV";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import { sideNavSize, sideNavKey } from "components/ui/SideNav/SideNavRoot";
import debouncedTextInput from "components/utils/debouncedTextInput";

import Grid from "./Grid";
import Form from "./Form";
import Controls from "../../ui/Dashboard/Panel/Controls";
import BulkEditForm from "./BulkEditForm";
import TextInput from "components/ui/Input/TextInput";
import { WindowContext } from "../../ui/Windows/Window";
import { mapGridRowToObject } from "components/utils/datagrid";

import "./style.scss";

const DebouncedTextInput = debouncedTextInput(TextInput, { timeout: 1000 });

const UserGroups = memo(({ utilityNumber, queryType }) => {
    const { viewName } = useContext(WindowContext);
    const dataGridId = getUserGroupsGridId({ utilityNumber, viewName });
    const dispatch = useDispatch();
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [userName, setUserName] = useState("");

    useEffect(() => {
        dispatch(getData({ dataGridId }));
    }, [userName, dataGridId, dispatch]);

    const { isSplitView } = useContext(WindowContext);
    const key = isSplitView ? undefined : sideNavKey.globalRight;

    // Use global sidepanel if used outside of utility dashboard
    const sidePanelProps = useMemo(
        () => ({
            size: sideNavSize.small,
            sideNavKey: utilityNumber ? undefined : key,
        }),
        [utilityNumber, key]
    );

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers(sidePanelProps);

    const onRefresh = useCallback(() => {
        setSelectedGroups([]);
        dispatch(selectRows({ dataGridId, selectedRows: [] }));
        dispatch(
            getData({
                dataGridId,
            })
        );
        refreshInvoiceWorkflows();
        refreshContractWorkflowAssignments({ utilityNumber });
        refreshProgramWorkflowAssignments({ utilityNumber });
    }, [utilityNumber, dataGridId, dispatch]);

    const onGroupSelect = useCallback((rows) => {
        setSelectedGroups(rows);
    }, []);

    const onIconClick = useCallback(() => {
        setUserName("");
    }, []);

    const controls = useMemo(
        () => [
            {
                position: "left",
                title: "Edit Selected User Groups",
                icon: "edit-empty",
                disabled: selectedGroups.length === 0,
                onClick: () => {
                    const groups = selectedGroups.map((group) => mapGridRowToObject(userGroupsGridColumnKeys, group));

                    handleOpenSidePanel(
                        <BulkEditForm
                            utilityNumber={utilityNumber}
                            userName={userName}
                            selectedGroups={groups}
                            gridRefresh={onRefresh}
                            onClose={handleCloseSidePanel}
                        />,
                        { size: sideNavSize.staticLarge }
                    );
                },
            },
            ...(isNil(utilityNumber)
                ? []
                : [
                      {
                          position: "left",
                          component: (
                              <DebouncedTextInput
                                  inline
                                  labelInside="Filter By User Name"
                                  icon={userName.length === 0 ? "search" : "clear-close"}
                                  iconTitle={userName.length === 0 ? "" : "Clear Filter"}
                                  iconRight
                                  value={userName}
                                  placeholder="Filter By User Name"
                                  onChange={(event) => setUserName(event.target.value)}
                                  onIconClick={userName.length === 0 ? undefined : onIconClick}
                              />
                          ),
                      },
                  ]),
            {
                position: "right",
                title: "Add User Group",
                icon: "plus",
                onClick: () => {
                    handleOpenSidePanel(<Form utilityNumber={utilityNumber} gridRefresh={onRefresh} onClose={handleCloseSidePanel} />);
                },
            },
            {
                position: "right",
                title: "Export CSV",
                withHandMadeIcon: true,
                onClick: () => {
                    exportDatagridToCSV({
                        dataGridId: dataGridId,
                        fileName: "utility_user_groups",
                        fileNamePostfix: utilityNumber,
                    });
                },
            },
        ],
        [dataGridId, utilityNumber, selectedGroups, userName, onRefresh, handleOpenSidePanel, handleCloseSidePanel, onIconClick]
    );

    return (
        <div className="panel system-user-groups-panel flex-column fill-height">
            <Controls items={controls} />
            <div className="flex-column flex-one-in-column no-scroll">
                <Grid
                    utilityNumber={utilityNumber}
                    userName={userName}
                    queryType={queryType}
                    dataGridId={dataGridId}
                    onRowSelect={onGroupSelect}
                    onRefresh={onRefresh}
                />
            </div>
        </div>
    );
});

export default UserGroups;
