import React, { memo } from "react";

import { exportDatagridToCSV } from "../../../utils/CSV";

import IconWithLabel from "../../../ui/Icons/IconWithLabel";
import AllHostnamesGrid from "./AllHostnamesGrid";

const AllHostnamesPanel = memo(() => {
    const gridId = "all-hostnames";

    return (
        <div className="tab-list-tab-content flex-one-in-column flex-column">
            <div className="data-grid-controls">
                <span className="flex-one"></span>
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: gridId,
                            fileName: "hostnames",
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <div className="panel all-hostnames flex-column fill-height no-scroll">
                <AllHostnamesGrid gridId={gridId} />
            </div>
        </div>
    );
});

export default AllHostnamesPanel;
