import React, { memo, useContext } from "react";
import Draggable from "react-draggable";
import { GridListResizeContext } from ".";
import DragHandle from "../../DragHandle";

import "./GridListResizeHandler.scss";

const GridListResizeHandler = memo(({ column }) => {
    const { resizable, onResizeColumn, onAutoResizeColumn } = useContext(GridListResizeContext);

    if (!resizable) {
        return null;
    }

    return (
        <div onDoubleClick={() => onAutoResizeColumn(column)}>
            <Draggable
                axis="x"
                defaultClassName="grid-list-resize-handle"
                defaultClassNameDragging="grid-list-resize-handle-active"
                onDrag={(event, { deltaX }) =>
                    onResizeColumn({
                        column,
                        deltaX,
                    })
                }
                position={{ x: 0 }}
                zIndex={999}
            >
                <DragHandle title="double click to expand the column" />
            </Draggable>
        </div>
    );
});

export default GridListResizeHandler;
