import React, { useCallback, memo, useContext, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import { setExpandedWorkflowTask } from "../../../../store/projects/actions";
import { isTaskCompleted } from "../../../utils/workflow";
import { Task } from "./Task/Task";
import { CompletedTask } from "./Task/CompletedTask";
import TaskDetails from "./Task/Details/TaskDetails";
import Status from "./Status";
import { TaskWorkflowContext } from "../context/TaskWorkflowContext";
import { applyTaskFilter } from "../utils";
import { toArray } from "components/utils/array";

import "./WorkflowStatuses.scss";

export const WorkflowStatuses = memo(({ className, entityId, AdHocTaskForm, workflowFilter, isExpandTaskDisabled }) => {
    const dispatch = useDispatch();
    const expandedWorkflowTaskId = useSelector((state) => state.projects.expandedWorkflowTaskId[entityId]);
    const { workflowTasks, workflowHistory, activeStatus, hasAddTaskRights, isLocked } = useContext(TaskWorkflowContext);
    const historyTitleRef = useRef();

    const onSelectTask = useCallback(
        (task) => {
            let taskNumber = null;

            if (task) {
                taskNumber = task.wfTaskNumber;
            }

            !isExpandTaskDisabled && dispatch(setExpandedWorkflowTask(entityId, taskNumber));
        },
        [entityId, dispatch, isExpandTaskDisabled]
    );

    useEffect(() => {
        if (workflowFilter.showHistory && historyTitleRef.current) {
            historyTitleRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    }, [workflowFilter.showHistory]);

    return (
        <div className={cn("workflow-tasks-statuses", className)}>
            <Status
                className="incompleted-status"
                status={activeStatus?.state}
                processMode={activeStatus?.processMode}
                allTasks={workflowTasks}
                tasks={workflowTasks.filter((task) => applyTaskFilter(task, workflowFilter))}
                listItemComponent={(props) => (isTaskCompleted(props.item) ? <CompletedTask {...props} /> : <Task {...props} />)}
                expandedListItemComponent={(props) => <TaskDetails {...props} isInActiveStatus />}
                onSelectTask={onSelectTask}
                AdHocTaskForm={AdHocTaskForm}
                expandedWorkflowTaskId={expandedWorkflowTaskId}
                isExpandTaskDisabled={isExpandTaskDisabled}
                hasAddTaskRights={hasAddTaskRights}
            />
            {workflowFilter.showHistory && (
                <>
                    <div ref={historyTitleRef} className="workflow-tasks-history-title flex-row align-center">
                        <h3>History</h3>
                        <span className="separator-line flex-one" />
                    </div>
                    {workflowHistory.map((completedStatus, index) => (
                        <Status
                            key={index}
                            className="completed-status"
                            isCompleted
                            status={completedStatus.state}
                            processMode={completedStatus.processMode}
                            allTasks={toArray(completedStatus.tasks)}
                            tasks={toArray(completedStatus.tasks).filter((task) => applyTaskFilter(task, workflowFilter))}
                            statusDate={completedStatus.statusDate}
                            userName={completedStatus.userName}
                            listItemComponent={CompletedTask}
                            expandedListItemComponent={(props) => (
                                <TaskDetails {...props} isInActiveStatus={completedStatus.state === activeStatus?.state} />
                            )}
                            isExpandTaskDisabled={isExpandTaskDisabled}
                            hasAddTaskRights={hasAddTaskRights && !isLocked}
                        />
                    ))}
                </>
            )}
        </div>
    );
});
