import React, { useCallback, useState, memo, useMemo } from "react";
import { useDispatch } from "react-redux";

import { utilitiesCatalogCalculationAssociationsColumnKeys } from "../../../../../views/configureGrids";
import { onRemoveCatalogAssignments, onAddCatalogAssignments } from "./actions";
import { exportCSV } from "../../../../../../store/dataGrid/actions";
import useMultiPageRowSelect from "../../../../../../store/dataGrid/useMultiPageRowSelect";

import Controls from "./Controls";
import BulkAssignmentHeader from "./BulkAssignmentHeader";
import SingleAssignmentHeader from "./SingleAssignmentHeader";
import { CatalogCalculationAssociationsGrid } from "./CatalogCalculationAssociationsGrid";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavContent from "../../../../SideNav/SideNavContent";
import Button from "../../../../Button";
import SideNavHeader from "../../../../SideNav/SideNavHeader";

export const CatalogCalculationAssociations = memo(({ utilityNumber, programNumber, rowData, onClose, sidePanel, disabled }) => {
    const dispatch = useDispatch();
    const [bulkRows, setBulkRows] = useState(Array.isArray(rowData) ? rowData : [rowData]);
    const isBulkAssign = bulkRows.length > 1;
    const calculationNumbers = bulkRows.map((item) => item.calculationNumber);
    const calculationNames = bulkRows.map((item) => item.calculationName);
    const dataGridId = `${utilityNumber}-catalog-calculation-associations-grid`;

    //TODO: Will need to fix when grid will allow to filter column by multiple values
    const dataGridFilter = calculationNumbers.map(
        (calculationNumber) => `${utilitiesCatalogCalculationAssociationsColumnKeys.calculationNumber}=${calculationNumber}`
    )[0];
    //.join('|');

    const [selectedRows, setSelectedRows] = useState([]);

    const selectedCatalogs = useMemo(() => {
        return Object.entries(selectedRows)
            .flatMap((entry) => entry[1])
            .map((i) => ({
                catalogNumber: i[utilitiesCatalogCalculationAssociationsColumnKeys.catalogNumber],
                catalogName: i[utilitiesCatalogCalculationAssociationsColumnKeys.catalogName],
            }));
    }, [selectedRows]);

    const selectedRowCount = selectedCatalogs.length;
    const assignmentDisabled = selectedRowCount === 0;

    const onRowSelect = useCallback((rows) => {
        setSelectedRows(rows);
    }, []);

    const [onRowSelectChange, onPageChanged, onRowSelectClear] = useMultiPageRowSelect({
        dataGridId,
        onRowSelect,
    });

    const onRemove = (catalog) =>
        onRemoveCatalogAssignments({
            dataGridId,
            calculationNumbers,
            catalogs: catalog ? [catalog] : selectedCatalogs,
            onRowSelectClear,
            dispatch,
        });

    const onAdd = () =>
        onAddCatalogAssignments({
            dataGridId,
            utilityNumber,
            calculationNumbers,
            calculationNames,
            dispatch,
        });

    const onExport = useCallback(() => {
        dispatch(exportCSV({ dataGridId }));
    }, [dataGridId, dispatch]);

    const onRemoveBulkCalculation = (row) => {
        setBulkRows(bulkRows.filter((r) => r.calculationNumber !== row.calculationNumber));
    };

    return (
        <SideNavContent className="calculations-form associations">
            <SideNavBody rowLayout noPadding>
                <SideNavHeader
                    title="Catalog Calculation Associations"
                    leadBlockIcon="hub-empty"
                    sidenavHeaderLeftAligned
                    onClose={onClose}
                >
                    {!isBulkAssign && (
                        <SingleAssignmentHeader
                            utilityNumber={utilityNumber}
                            programNumber={programNumber}
                            calculationType={bulkRows[0].calculationType}
                            userName={bulkRows[0].userName}
                            revisionDate={bulkRows[0].revisionDate}
                            calculationNumber={bulkRows[0].calculationNumber}
                            target={bulkRows[0].target}
                            sidePanel={sidePanel}
                        />
                    )}
                </SideNavHeader>
                <div className="panel calculations associations flex-one no-scroll sticky-grid-list-panel">
                    {isBulkAssign && (
                        <BulkAssignmentHeader
                            rowData={bulkRows}
                            onRemoveCalculation={onRemoveBulkCalculation}
                            onChangeSelection={onClose}
                        />
                    )}
                    <Controls
                        disabled={disabled}
                        programNumber={programNumber}
                        assignmentDisabled={assignmentDisabled}
                        selectedRowCount={selectedRowCount}
                        onRowSelectClear={onRowSelectClear}
                        onAdd={onAdd}
                        onRemove={() => onRemove()}
                        onExport={onExport}
                    />
                    <CatalogCalculationAssociationsGrid
                        disabled={disabled}
                        programNumber={programNumber}
                        gridId={dataGridId}
                        filter={dataGridFilter}
                        onRowSelectChange={onRowSelectChange}
                        onPageChanged={onPageChanged}
                        onRemove={onRemove}
                    />
                </div>
            </SideNavBody>
            <SideNavFooter justifyEnd>
                <Button onClick={onClose}>Close</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});
