import React, { useState, useCallback, useContext, memo, useEffect, useMemo, useRef } from "react";
import { renderToString } from "react-dom/server";
import { isEmpty, isNil } from "lodash";

import { onCisLookup } from "../../../../CIS/actions";
import { parseCisDetails } from "../../../../CIS/utils";
import { referenceTypes } from "../../../../Reference/referenceTypes";
import { useReference } from "../../../../Reference/useReference";
import { referenceToAnyOf, isNameOrCompany, isNameOrCompanyErrorText, listToAnyOf } from "../../../../../utils/form";
import { getFormValues, useContactWorkflowTargetGroups } from "./ContactsUtils";
import { contactType } from "components/utils/constants";

import StatusMsg from "../../../../StatusMsg";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import ButtonGroup from "../../../../Button/ButtonGroup";
import Button from "../../../../Button";
import TextInput from "../../../../Input/TextInput";
import TextLabel from "components/ui/Label/TextLabel";
import WaitIcon from "components/ui/WaitIcon";
import { ContactsPanelContext } from ".";
import { onContractorLookup } from "components/ui/Contractor/actions";
import { takeContractorsFromList } from "components/views/ProjectView/utils";

import "./EditContact.scss";

const EditContact = memo((props) => {
    const formRef = useRef();
    const { utilityNumber, programNumber } = useContext(ContactsPanelContext);
    const [contactTypes, isLoadingContactTypes] = useReference(referenceTypes.userContact);
    const [workflowGroups, isLoadingWorkflowGroups] = useContactWorkflowTargetGroups({ utilityNumber, programNumber });

    if (isLoadingContactTypes || isLoadingWorkflowGroups) {
        return <WaitIcon />;
    }

    return (
        <EditContactForm
            programNumber={programNumber}
            formRef={formRef} // Will be overrided if props has formRef
            {...props}
            contactTypes={contactTypes}
            workflowGroups={workflowGroups}
        />
    );
});

const EditContactForm = memo(
    ({
        programNumber,
        applicationNumber,
        formRef,
        isNew,
        isLocked,
        initialValues,
        isSubmitting,
        contactTypes,
        workflowGroups,
        onSubmit,
        onCancel,
    }) => {
        const { utilityNumber, premiseContact, primaryContact, contractorContact, canSearchCIS } = useContext(ContactsPanelContext);

        const [useExisting, setUseExisting] = useState();
        const initialFormValues = useMemo(
            () => getFormValues(initialValues, contactTypes, workflowGroups),
            [initialValues, contactTypes, workflowGroups]
        );
        const takeFromList = takeContractorsFromList({ applicationNumber });

        const existingMainContacts = useMemo(
            () => ({
                Empty: {},
                Premise: premiseContact,
                Primary: primaryContact,
                Contractor: contractorContact,
            }),
            [contractorContact, premiseContact, primaryContact]
        );

        const [isContactTypeCustomerAccountAccess, setIsContactTypeCustomerAccountAccess] = useState(
            initialFormValues.contacttype === contactType.CustomerAccountAccess
        );

        // Update form when existing contact selected
        useEffect(() => {
            if (useExisting && !(useExisting === "Contractor" && takeFromList)) {
                const formData = getFormValues(existingMainContacts[useExisting], contactTypes, workflowGroups);
                // prevent overriding the actual contact
                formData.contactnumber = formRef.current.state.formData.contactnumber;
                formRef.current.setFormData(formData);
            }
        }, [existingMainContacts, contactTypes, workflowGroups, useExisting, formRef, takeFromList]);

        const onUseExisting = useCallback((type) => {
            setUseExisting(type);
        }, []);

        const onSearchContractor = () => {
            setUseExisting("Contractor");
            takeFromList &&
                onContractorLookup({
                    programNumber,
                    onSelect: (c) => {
                        let formData = {
                            firstname: c.firstName,
                            lastname: c.lastName,
                            address: c.address,
                            address_cont: c.addressCont,
                            city: c.city,
                            company: c.company,
                            contacttitle: c.contacttitle,
                            email: c.email,
                            phone: c.phone,
                            state: c.state,
                            zip: c.zip,
                            contacttype: "Contractor",
                            origId: c.origId,
                        };
                        formData = getFormValues(formData, contactTypes, workflowGroups);
                        // prevent overriding the actual premise/primary contact
                        formData.contactnumber = formRef.current.state.formData.contactnumber;
                        formRef.current.setFormData(formData);
                    },
                });
        };

        const onCisSelect = useCallback(
            (data) => {
                const formData = formRef.current?.state?.formData;

                if (formData) {
                    const cis = parseCisDetails(data);

                    formRef.current.setFormData({
                        ...formData,
                        acct_number: cis.acct_number || formData.acct_number,
                        address: cis.address || formData.address,
                        city: cis.city || formData.city,
                        state: cis.state || formData.state,
                        zip: cis.zip || formData.zip,
                        company: cis.company || formData.company,
                        firstname: cis.firstname || formData.firstname,
                        lastname: cis.lastname || formData.lastname,
                        meterid: cis.meterid || formData.meterid,
                        phone: cis.phone || formData.phone,
                        premiseid: cis.premiseid || formData.premiseid,
                        email: formData.email || cis.email,
                    });
                }
            },
            [formRef]
        );

        const requiredFields = useMemo(() => {
            if (isContactTypeCustomerAccountAccess) {
                return ["contacttype", "email"];
            }

            return ["contacttype", "contacttitle", "address", "city", "state", "zip"];
        }, [isContactTypeCustomerAccountAccess]);

        const schema = useMemo(
            () => ({
                type: "object",
                required: requiredFields,
                description: isContactTypeCustomerAccountAccess
                    ? ""
                    : renderToString(<StatusMsg msgInfo msgText={isNameOrCompanyErrorText} />),
                properties: {
                    contacttype: {
                        type: "integer",
                        title: "Contact Type",
                        anyOf: referenceToAnyOf({ list: contactTypes }),
                    },
                    workflowTargetGroupId: {
                        type: "integer",
                        title: "Workflow Group",
                        oneOf: listToAnyOf({
                            list: workflowGroups,
                            map: (i) => ({
                                title: i.groupName,
                                enum: [i.groupID],
                            }),
                        }),
                    },
                    contacttitle: {
                        type: "string",
                        title: "Name This Contact",
                    },
                    firstname: {
                        type: "string",
                        title: "Contact First Name" + (isContactTypeCustomerAccountAccess ? "" : " **"),
                    },
                    lastname: {
                        type: "string",
                        title: "Contact Last Name" + (isContactTypeCustomerAccountAccess ? "" : " **"),
                    },
                    company: {
                        type: "string",
                        title: "Company Name" + (isContactTypeCustomerAccountAccess ? "" : " **"),
                    },
                    acct_number: {
                        type: "string",
                        title: "Account Number",
                    },
                    premiseid: {
                        type: "string",
                        title: "Premise ID",
                    },
                    meterid: {
                        type: "string",
                        title: "Meter ID",
                    },
                    address: {
                        type: "string",
                        title: "Address",
                    },
                    address_cont: {
                        type: "string",
                        title: "Address (continued)",
                    },
                    city: {
                        type: "string",
                        title: "City",
                    },
                    state: {
                        type: "string",
                        title: "State/Province",
                    },
                    zip: {
                        type: "string",
                        title: "Postal Code",
                    },
                    phone: {
                        type: "string",
                        title: "Phone",
                    },
                    cell: {
                        type: "string",
                        title: "Cell",
                    },
                    fax: {
                        type: "string",
                        title: "Fax",
                    },
                    email: {
                        type: "string",
                        title: "Email",
                    },
                    taxid: {
                        type: "string",
                        title: "Tax ID",
                    },
                },
            }),
            [requiredFields, isContactTypeCustomerAccountAccess, contactTypes, workflowGroups]
        );

        const handleCisLookup = useCallback(() => {
            onCisLookup({
                utilityNumber,
                contact: formRef.current.state.formData,
                onSelect: onCisSelect,
            });
        }, [utilityNumber, formRef, onCisSelect]);

        const uiSchema = useMemo(
            () => ({
                contacttype: {
                    "ui:placeholder": "-- SELECT --",
                    "ui:disabled": contactTypes.length === 0,
                },
                workflowTargetGroupId: {
                    "ui:placeholder": "-- SELECT --",
                    "ui:disabled": workflowGroups.length === 0,
                    "ui:emptyItem": true,
                    "ui:enumDisabled": workflowGroups.filter((group) => group.status.toLowerCase() !== "active").map((i) => i.groupID),
                },
                acct_number: {
                    "ui:widget": (props) => <AccountNumberWidget {...props} canSearchCIS={canSearchCIS} onCisLookup={handleCisLookup} />,
                },
                state: {
                    "ui:widget": "state",
                },
            }),
            [contactTypes, workflowGroups, canSearchCIS, handleCisLookup]
        );

        const onValidate = useCallback(
            (formData, errors) => {
                if (
                    !isContactTypeCustomerAccountAccess &&
                    !isNameOrCompany({
                        firstName: formData.firstname,
                        lastName: formData.lastname,
                        company: formData.company,
                    })
                ) {
                    errors.firstname.addError(isNameOrCompanyErrorText);
                }

                return errors;
            },
            [isContactTypeCustomerAccountAccess]
        );

        const onChange = useCallback(({ formData }) => {
            setIsContactTypeCustomerAccountAccess(formData.contacttype === contactType.CustomerAccountAccess);
        }, []);

        return (
            <div className="app-contact-form flex-column">
                <ExistingContactSelector
                    applicationNumber={applicationNumber}
                    isNew={isNew}
                    useExisting={useExisting || initialValues?.contacttype}
                    onClick={onUseExisting}
                    onSearchContractor={onSearchContractor}
                />
                <JsonSchemaForm
                    formRef={formRef}
                    schema={schema}
                    uiSchema={uiSchema}
                    initialValues={initialFormValues}
                    readOnly={isLocked}
                    disabled={isSubmitting}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    onChange={onChange}
                    noReset
                    noActions={isNil(onCancel)} // In create contact task we have form buttons but not in sidepanel
                    submitText={isNew ? "Add New Contact" : "Update"}
                    submitIcon={isNew ? "plus" : ""}
                    validate={onValidate}
                />
            </div>
        );
    }
);

const ExistingContactSelector = memo(({ applicationNumber, useExisting, onClick, onSearchContractor }) => {
    const existingTypes = ["Empty", "Premise", "Primary"];

    return (
        <div className="use-existing">
            <TextLabel>Use existing assigned contact info</TextLabel>
            <ButtonGroup>
                {existingTypes.map((type) => {
                    const isButtonActive = useExisting === type || (!useExisting && type === "Empty");
                    return (
                        <Button key={type} primary={isButtonActive} focus={isButtonActive} onClick={() => onClick(type)}>
                            {type}
                        </Button>
                    );
                })}
                <Button
                    onClick={takeContractorsFromList({ applicationNumber }) ? onSearchContractor : () => onClick("Contractor")}
                    primary={useExisting === "Contractor"}
                    focus={useExisting === "Contractor"}
                >
                    Contractor
                </Button>
            </ButtonGroup>
        </div>
    );
});

const AccountNumberWidget = memo(({ value, required, readonly, canSearchCIS, onChange, onCisLookup }) => {
    return (
        <div className="account-number-widget">
            <TextInput
                value={isEmpty(value) ? "" : value}
                required={required}
                readOnly={readonly}
                onChange={(event) => onChange(event.target.value)}
            />
            <Button onClick={onCisLookup} disabled={isEmpty(value) || readonly || !canSearchCIS}>
                CIS
            </Button>
        </div>
    );
});

export default EditContact;
