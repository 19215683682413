import React, { useState, useCallback, memo } from "react";

import { onCisLookup } from "../../../ui/CIS/actions";
import { parseCisDetails } from "../../../ui/CIS/utils";

import JsonSchemaForm from "../../../ui/Form/JsonSchema/JsonSchemaForm";
import { isEmpty } from "lodash";

const CustomerSearchCIS = memo(({ utilityNumber, onSelect }) => {
    const [formKey, setFormKey] = useState(false);
    const [cancelDisabled, setCancelDisabled] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);

    const schema = {
        type: "object",
        properties: {
            accountNumber: {
                type: "string",
                title: "Account Number",
            },
            lastName: {
                type: "string",
                title: "Full Name",
            },
        },
    };

    const uiSchema = {
        accountNumber: {
            classNames: "string-input",
        },
        lastName: {
            classNames: "string-input",
        },
    };

    const handleSelect = useCallback(
        (data) => {
            onSelect(parseCisDetails(data));
        },
        [onSelect]
    );

    const handleSubmit = useCallback(
        (formData) => {
            let contact = {
                acct_number: formData.accountNumber?.trim() || "",
                lastname: formData.lastName?.trim() || "",
            };

            onCisLookup({ utilityNumber, contact, onSelect: handleSelect, newAppCreation: true });
        },
        [utilityNumber, handleSelect]
    );

    const handleCancel = useCallback(() => {
        setFormKey(!formKey);
    }, [formKey]);

    const handleChange = (formData) => {
        if (isEmpty(formData.accountNumber?.trim()) && isEmpty(formData.lastName?.trim())) {
            setCancelDisabled(true);
            setSubmitDisabled(true);
        } else {
            setCancelDisabled(false);
            setSubmitDisabled(false);
        }
    };
    return (
        <div className="new-app-customer-search-cis">
            <JsonSchemaForm
                key={formKey}
                schema={schema}
                uiSchema={uiSchema}
                noReset
                withCancel
                withSubmit
                submitDisabled={submitDisabled}
                submitText="Search Customer"
                submitIcon="search"
                cancelDisabled={cancelDisabled}
                onChange={(event) => handleChange(event.formData)}
                submitIsPrimary={false}
                cancelText="Clear"
                onSubmit={handleSubmit}
                onCancel={handleCancel}
            />
        </div>
    );
});

export default CustomerSearchCIS;
