import React, { useCallback, memo } from "react";
import { useDispatch } from "react-redux";

import { entityType } from "../../utils/constants";
import { modalOpen } from "../../../store/modal/actions";
import { updateResource } from "../../../store/resources/actions";
import { openUtilityTab } from "../../utils/window";
import { refreshContract } from "../../../store/resources/refreshResource";
import { refreshContractBookmarksGrid } from "../../../store/dataGrid/refreshGrid";
import { useContract } from "../../../store/resources/useResource";
import { sideNavSize } from "../../ui/SideNav/SideNavRoot";
import useSidePanelHandlers from "../../utils/useSidePanelHandlers";

import WindowBodyHeader from "../../ui/WindowBody/WindowBodyHeader";
import IconWrap from "../../ui/Icons";
import SubHeader from "../../ui/Headers/SubHeader";
import HeaderIconGroup from "../../ui/Icons/IconGroup";
import UtilityName from "../../ui/UtilityName";
import WaitIcon from "../../ui/WaitIcon";
import Form from "../../ui/Dashboard/Panel/Utility/FinancialsPanel/Form";
import { useIsMobile } from "components/utils/useIsMobile";

const UtilityContractHeader = memo(({ utilityNumber, contractNumber }) => {
    const dispatch = useDispatch();
    const [contract = {}, isLoading] = useContract({
        utilityNumber,
        contractNumber,
    });
    const isMobile = useIsMobile();
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "contract-sidenav-panel",
    });

    const items = [
        isMobile && {
            name: "Contract Name",
            value: contract.contractDesc,
        },
        {
            name: "Utility",
            value: <UtilityName utilityNumber={utilityNumber} />,
            onClick: () =>
                openUtilityTab({
                    utilityNumber,
                }),
        },
        {
            name: "Note",
            value: contract.note,
        },
    ];

    const handleEdit = useCallback(() => {
        handleOpenSidePanel(
            <Form
                utilityNumber={utilityNumber}
                contractNumber={contractNumber}
                onSaved={() => refreshContract({ utilityNumber, contractNumber })}
                onClose={handleCloseSidePanel}
            />
        );
    }, [utilityNumber, contractNumber, handleOpenSidePanel, handleCloseSidePanel]);

    const handleBookmark = useCallback(() => {
        const text = <p>Would you like {contract.isFavorite ? "remove this contract from" : "add this contract to"} your bookmarks?</p>;

        dispatch(
            modalOpen({
                modalType: "CONFIRM",
                modalProps: {
                    title: contract.isFavorite ? "Remove from Bookmarks" : "Add to Bookmarks",
                    modalIcon: contract.isFavorite ? "bookmark-filled icon-wrap-combined remove" : "bookmark-empty icon-wrap-combined add",
                    overlayClassName: "modal-styled",
                    className: "remove-utility-contract-from-bookmarks-confirmation-modal modal-sm",
                    content: text,
                    footerContentCenter: true,
                    onConfirm: () => {
                        dispatch(
                            updateResource({
                                resourceName: "userFavorites",
                                query: {
                                    entityType: entityType.Contract,
                                    entityId: contractNumber,
                                },
                                onSuccess: () => {
                                    refreshContractBookmarksGrid();
                                    refreshContract({
                                        utilityNumber,
                                        contractNumber,
                                    });
                                },
                            })
                        );
                    },
                },
            })
        );
    }, [utilityNumber, contractNumber, contract, dispatch]);

    if (isLoading) {
        return <WaitIcon />;
    }

    return (
        <WindowBodyHeader windowBodyHeaderName="Contract">
            {isLoading ? (
                <WaitIcon />
            ) : (
                <>
                    <HeaderIconGroup header>
                        <IconWrap iconWrapBig icon="edit-empty" title={"Edit Contract"} onClick={handleEdit} />
                        <IconWrap
                            iconWrapBig
                            icon={contract.isFavorite ? "bookmark-filled" : "bookmark-empty"}
                            title={contract.isFavorite ? "Un-Bookmark Contract" : "Bookmark Contract"}
                            onClick={handleBookmark}
                        ></IconWrap>
                    </HeaderIconGroup>
                    <SubHeader items={items} />
                </>
            )}
        </WindowBodyHeader>
    );
});

export default UtilityContractHeader;
