import React, { memo } from "react";

import DashboardPanel from "../DashboardPanel";
import ApplicationsSummaryStatus from "../../../../views/ApplicationsSummaryStatus";

const ApplicationsSummaryStatusPanel = memo(({ panel }) => {
    return (
        <DashboardPanel title={panel.name} className="applications-summary-status">
            <ApplicationsSummaryStatus panel={panel} />
        </DashboardPanel>
    );
});

export default ApplicationsSummaryStatusPanel;
