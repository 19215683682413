import React, { memo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useExternalApplication } from "../../../../store/resources/useResource";
import { createResource, deleteResource, getResource } from "../../../../store/resources/actions";
import { openConfirmModal } from "components/ui/Modal/utils";
import SideNavContent from "../../../ui/SideNav/SideNavContent";
import SideNavHeader from "../../../ui/SideNav/SideNavHeader";
import SideNavBody from "../../../ui/SideNav/SideNavBody";
import SideNavFooter from "../../../ui/SideNav/SideNavFooter";
import Button from "../../../ui/Button";
import Checkbox from "../../../ui/Input/Checkbox";

import "../../../views/SystemManagementUsers/style.scss";

const AssignExternalAppsForm = memo(({ onClose, targetUserNumber }) => {
    const dispatch = useDispatch();
    const [externalApplication] = useExternalApplication({ targetUserNumber });
    const [externalAppsListState, setExternalAppsListState] = useState([]);
    const title = "Assign External Applications";

    useEffect(() => {
        const initialExternalAppsList = externalApplication?.externalApplicationsResult.map((app) => ({
            label: app.externalApplicationName,
            checked: app.hasAccess,
            id: app.externalApplicationNumber,
        }));
        setExternalAppsListState(initialExternalAppsList);
    }, [externalApplication]);

    const refreshExternalApps = () => {
        dispatch(
            getResource({
                resourceName: "externalApps",
                key: targetUserNumber,
                query: {
                    targetUserNumber,
                },
            })
        );
    };
    const onAddExternalApp = (targetedApp) => {
        openConfirmModal({
            title: "Add External Applicaton Confirm",
            message:
                "This action will add the external application to the user account and integrate the user with the external system. Are you sure you want to do this?",
            onConfirm: () => {
                dispatch(
                    createResource({
                        resourceName: "externalApps",
                        query: {
                            targetUserNumber,
                            externalApplicationNumber: targetedApp.id,
                        },
                        onSuccess: () => {
                            setExternalAppsListState((prevState) => {
                                return prevState.map((app) => (app.id === targetedApp.id ? { ...app, checked: targetedApp.checked } : app));
                            });
                            refreshExternalApps();
                        },
                    })
                );
            },
            onClose: () => {
                return;
            },
        });
    };
    const onRemoveExternalApp = (externalApplicationNumber) => {
        const userObjectNumber = externalApplication.externalApplicationsResult.find(
            (app) => app.externalApplicationNumber === externalApplicationNumber
        )?.userObjectNumber;

        openConfirmModal({
            title: "Remove External Applicaton Confirm",
            message: "This action will remove the external application from the user account. Are you sure you want to do this?",
            onConfirm: () => {
                dispatch(
                    deleteResource({
                        resourceName: "externalApps",
                        query: {
                            targetUserNumber,
                            externalApplicationNumber,
                            userObjectNumber,
                        },
                        onSuccess: () => {
                            refreshExternalApps();
                        },
                    })
                );
            },
            onClose: () => {
                return;
            },
        });
    };
    const onChange = (event) => {
        try {
            const targetedApp = event.target;
            if (targetedApp.checked === true) {
                onAddExternalApp(targetedApp);
            } else if (targetedApp.checked === false) {
                onRemoveExternalApp(targetedApp.id);
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <SideNavContent className="user-external-apps" onClose={onClose}>
            <SideNavHeader title={title} leadBlockIcon="assignment" onClose={onClose} />
            <h4>External Application</h4>
            <SideNavBody>
                {externalAppsListState &&
                    externalAppsListState.length > 0 &&
                    externalAppsListState.map((app) => (
                        <div className="tile flex-row no-shrink flex-padding" key={app.id}>
                            <Checkbox id={app.id} label={app.label} checked={app.checked} onChange={onChange} />
                        </div>
                    ))}
            </SideNavBody>
            <SideNavFooter setPrimaryButton justifyEnd>
                <Button onClick={onClose}>Close</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default AssignExternalAppsForm;
