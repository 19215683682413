import React from "react";
import cn from "classnames";

const ContentHeader = ({ className, children }) => (
    <div className={cn("content-header", className)}>
        <span>{children}</span>
    </div>
);

export default ContentHeader;
