import React, { memo } from "react";
import cn from "classnames";

export const RolesListRow = memo(({ rowRef, className, style, children }) => {
    return (
        <div ref={rowRef} className={cn("roles-list-row flex-row align-center", className)} style={style}>
            {children}
        </div>
    );
});
