import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const RECAPCHA_KEY = process.env.REACT_APP_RECAPCHA_CLIENT_KEY;

const Captcha = (props) => {
    // If window has a flag to disable recaptcha, return simple checkbox. This is used for testing purposes.
    if (window.DISABLE_RECAPTCHA) {
        return (
            <div className="captcha">
                <input type="checkbox" name="recaptcha" className="recaptcha-checkbox" onChange={() => props.onChange("TEST")} />
            </div>
        );
    }

    return (
        <div className="captcha">
            <ReCAPTCHA ref={props.captchaRef} sitekey={RECAPCHA_KEY} onChange={props.input ? props.input.onChange : props.onChange} />
        </div>
    );
};

export default Captcha;
