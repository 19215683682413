import React, { useState, memo, useMemo } from "react";

import { downloadCSV, uploadCSV, lineToObject } from "components/utils/CSV";
import { useListFilter } from "./ListFilter";
import ButtonGroup from "../Button/ButtonGroup";
import Button from "../Button";
import Separator from "../Separator";
import IconWithLabel from "../Icons/IconWithLabel";
import { ErrorMessage } from "../Message";
import AvailableList from "./AvailableList";
import SelectedList from "./SelectedList";

import "./style.scss";

export const ITEM_HEIGHT = 44;
export const OVERSCAN_COUNT = 1;
export const AUTO_SIZE_PADDING = 2;

const AssignmentSelector = memo(
    ({
        items = [],
        idKey,
        nameKey,
        submitButtonText,
        addAllText,
        addAllDisabled,
        availableListLabelText,
        selectedListLabelText,
        removeAllText,
        removeAllDisabled,
        useCSV,
        AvailableListItemComponent,
        HeaderComponent,
        onSelect,
        onClose,
        addAllHandler,
    }) => {
        const initialSelectedItems = items.filter((i) => i.assigned === true || i.assigned === "yes");

        const [selectedItems, setSelectedItems] = useState(initialSelectedItems);
        const availableItems = items.filter((i) => !selectedItems.some((s) => s[idKey] === i[idKey]));
        const isEmptyList = items.length === 0;

        const noChanges = useMemo(() => {
            return (
                initialSelectedItems.length === selectedItems.length &&
                selectedItems.every((i) => initialSelectedItems.some((r) => r[idKey] === i[idKey]))
            );
        }, [initialSelectedItems, selectedItems, idKey]);

        const [filteredAvailableItems, availableItemsFilterValue, onAvailableItemsFilterChange] = useListFilter({
            items: availableItems,
            filterKey: nameKey,
        });

        const onAdd = (item) => {
            setSelectedItems(selectedItems.concat([item]));
        };

        const onRemove = (item) => {
            setSelectedItems(selectedItems.filter((i) => i[idKey] !== item[idKey]));
        };

        const onAddAll = () => {
            if (addAllHandler) {
                addAllHandler({
                    filteredAvailableItems,
                    selectedItems,
                    setSelectedItems,
                });
            } else {
                setSelectedItems(selectedItems.concat(filteredAvailableItems));
            }
        };

        const onRemoveAll = () => {
            setSelectedItems([]);
        };

        const onExport = () => {
            const data = filteredAvailableItems.map((item) => ({
                ID: item[idKey],
                NAME: item[nameKey],
            }));

            downloadCSV({
                data,
                fileName: "AssignmentExport",
            });
        };

        const onImport = () => {
            uploadCSV().then((result) => {
                const uploadedItems = result.lines
                    .map((line) => lineToObject({ header: result.header, line }))
                    .map((line) => items.find((i) => i[idKey] === line.ID)) // Get list items by uploaded identifier
                    .filter((item) => item); // Take only found items

                setSelectedItems(uploadedItems);
            });
        };

        const onSubmit = () => {
            const removedItems = initialSelectedItems.filter((n) => !selectedItems.includes(n));

            onSelect(selectedItems, removedItems);
            onClose && onClose();

            return;
        };

        const showAddAll = filteredAvailableItems.length !== 0 && !addAllDisabled;
        const showRemoveAll = selectedItems.length !== 0 && !removeAllDisabled;

        const selectionLists = (
            <>
                {HeaderComponent && <HeaderComponent onAdd={onAdd} items={items} selectedItems={selectedItems} />}
                <div className="flex-row">
                    <div className="flex-column flex-one">
                        <div className="assignment-selector__list-controls flex-row">
                            {showAddAll && (
                                <IconWithLabel iconWithLabelRight icon="arrow-right" onClick={onAddAll}>
                                    {addAllText || "Add All"}
                                </IconWithLabel>
                            )}
                            <span className="flex-one" />
                            {useCSV && (
                                <IconWithLabel icon="download" title="Export list" onClick={onExport}>
                                    Export
                                </IconWithLabel>
                            )}
                        </div>
                        <AvailableList
                            labelText={availableListLabelText}
                            items={filteredAvailableItems}
                            selectedItems={selectedItems}
                            nameKey={nameKey}
                            filterValue={availableItemsFilterValue}
                            onFilterChange={onAvailableItemsFilterChange}
                            onAdd={onAdd}
                            AvailableListItemComponent={AvailableListItemComponent}
                        />
                    </div>
                    <Separator vertical />
                    <div className="flex-column flex-one">
                        <div className="assignment-selector__list-controls flex-row">
                            {useCSV && (
                                <IconWithLabel icon="upload" title="Import list" onClick={onImport}>
                                    Import
                                </IconWithLabel>
                            )}
                            <span className="flex-one" />
                            {showRemoveAll && (
                                <IconWithLabel icon="arrow-left" onClick={onRemoveAll}>
                                    {removeAllText || "Remove All"}
                                </IconWithLabel>
                            )}
                        </div>
                        <SelectedList labelText={selectedListLabelText} items={selectedItems} nameKey={nameKey} onRemove={onRemove} />
                    </div>
                </div>
            </>
        );

        return (
            <div className="assignment-selector flex-column fill-width">
                {isEmptyList ? <ErrorMessage>Nothing to Select</ErrorMessage> : selectionLists}
                <ButtonGroup transparent>
                    <Button primary onClick={onSubmit} disabled={isEmptyList || noChanges}>
                        {submitButtonText || "Apply"}
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ButtonGroup>
            </div>
        );
    }
);

export default AssignmentSelector;
