import React from "react";

import FieldGroupInput from "../../../ui/FieldGroupInput";
import FieldGroupCheckbox from "../../../ui/FieldGroupCheckbox";
import FieldGroupNumericInput from "../../../ui/FieldGroupNumericInput";
import DatePicker from "components/ui/Input/DatePicker";
import Label from "components/ui/Label";
import FieldGroup from "components/ui/FieldGroup";
import FieldWrap from "components/ui/FieldWrap";

class Inputs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isActive: true,
            isActive2: true,
            isError: true,
            isFocus: true,
            isFocus2: true,
            isFocus3: false,
            isFocus4: false,
            isFocus5: true,
            isChecked: false,
            isChecked2: true,
            checkboxID: "test",
            checkboxID2: "test2",
            date: new Date(),
        };

        this.onInputFocus = this.onInputFocus.bind(this);
        this.onInputFocus2 = this.onInputFocus2.bind(this);
        this.onInputFocus3 = this.onInputFocus3.bind(this);
        this.onInputFocus4 = this.onInputFocus4.bind(this);
        this.onInputFocus5 = this.onInputFocus5.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
    }
    onInputFocus() {
        const newState = !this.state.isFocus;
        this.setState({
            isFocus: newState,
        });
    }
    onInputFocus2() {
        const newState = !this.state.isFocus2;
        this.setState({
            isFocus2: newState,
        });
    }
    onInputFocus3() {
        const newState = !this.state.isFocus3;
        this.setState({
            isFocus3: newState,
        });
    }
    onInputFocus4() {
        const newState = !this.state.isFocus4;
        this.setState({
            isFocus4: newState,
        });
    }
    onInputFocus5() {
        const newState = !this.state.isFocus5;
        this.setState({
            isFocus5: newState,
        });
    }
    onDateChange(data) {
        this.setState({
            date: data.value,
        });
    }
    render() {
        return (
            <div className="flexbox-row">
                <div className="flexbox-col">
                    <div className="ui-library-sub-title">Standard</div>
                    <div className="row">
                        <FieldGroupInput label="Default Label" type="text" placeholder="Text"></FieldGroupInput>
                    </div>
                    <div className="row">
                        <FieldGroupInput label="Input with content" type="text" placeholder="Text" value="Text">
                            <FieldGroupCheckbox
                                label="I agree with Terms & Conditions"
                                id={this.state.checkboxID}
                                checked={this.state.isChecked}
                                onChange={(e) => {
                                    this.setState({
                                        isChecked: e.target.checked,
                                    });
                                }}
                                labelIcon={!this.state.isChecked ? "checkbox-unchecked-empty" : "checkbox-checked-filled"}
                            ></FieldGroupCheckbox>
                        </FieldGroupInput>
                    </div>
                    <div className="row">
                        <FieldGroupInput
                            label="Active/Selected"
                            type="text"
                            placeholder="Text"
                            value="Text"
                            focus={this.state.isFocus}
                            onFocus={this.onInputFocus}
                        >
                            <FieldGroupCheckbox
                                label="I agree with Terms & Conditions"
                                id={this.state.checkboxID2}
                                checked={this.state.isChecked2}
                                onChange={(e) => {
                                    this.setState({
                                        isChecked2: e.target.checked,
                                    });
                                }}
                                labelIcon={!this.state.isChecked2 ? "checkbox-unchecked-empty" : "checkbox-checked-filled"}
                            ></FieldGroupCheckbox>
                        </FieldGroupInput>
                    </div>
                    <div className="row">
                        <FieldGroupInput label="Disabled Input" type="text" placeholder="Text" value="Text" disabled></FieldGroupInput>
                    </div>
                    <div className="row">
                        <FieldGroupInput
                            label="Error"
                            labelRequired
                            type="text"
                            placeholder="Text"
                            value="Text"
                            error={this.state.isError}
                            msgText="Please enter valid email address"
                        ></FieldGroupInput>
                    </div>
                    <div className="row">
                        <FieldGroupInput
                            label="Read-only Input"
                            type="text"
                            placeholder="Text"
                            value="Read Only"
                            readOnly
                        ></FieldGroupInput>
                    </div>
                </div>

                <div className="flexbox-col">
                    <div className="ui-library-sub-title">Input w buttons</div>
                    <div className="row">
                        <FieldGroupInput
                            label="Input with Passive Frozen Zone: Right"
                            type="text"
                            placeholder="Text"
                            value="1,200.00"
                            frozen
                            icon="arrow-right-up"
                            iconRight
                        ></FieldGroupInput>
                    </div>
                    <div className="row">
                        <FieldGroupInput
                            label="Input with Toggle Button: OFF"
                            type={this.state.isActive ? "password" : "text"}
                            value="nnn777NNN_"
                            highlighted={!this.state.isActive}
                            iconClickablePassword
                            iconClickable={this.state.isActive ? "visibility-off" : "eye-visibility-filled"}
                            onClick={() =>
                                this.setState({
                                    isActive: !this.state.isActive,
                                })
                            }
                            msgInfo
                            msgText="Use Numbers, Symbols and Caps"
                        ></FieldGroupInput>
                    </div>
                    <div className="row">
                        <FieldGroupInput
                            label="Input with Toggled Button: ON"
                            type={!this.state.isActive2 ? "password" : "text"}
                            value="nnn777NNN_"
                            highlighted={this.state.isActive2}
                            iconClickablePassword
                            iconClickable={!this.state.isActive2 ? "visibility-off" : "eye-visibility-filled"}
                            onClick={() =>
                                this.setState({
                                    isActive2: !this.state.isActive2,
                                })
                            }
                            msgSuccess
                            msgText="Password must contain at least Numbers, Symbols and Caps"
                        ></FieldGroupInput>
                    </div>
                </div>

                <div className="flexbox-col">
                    <div className="ui-library-sub-title">Input w Autocomplete</div>
                    <div className="row">
                        <FieldGroupInput
                            label="Input with icon: Right"
                            type="text"
                            placeholder="Text"
                            withIcon
                            icon="search"
                            iconRight
                            focus={this.state.isFocus3}
                            onFocus={this.onInputFocus3}
                        ></FieldGroupInput>
                    </div>
                    <div className="row">
                        <FieldGroupInput
                            type="text"
                            label="Input with icon: Right/Active"
                            placeholder="Text"
                            value="Text"
                            withIcon
                            icon="search"
                            iconRight
                            focus={this.state.isFocus2}
                            onFocus={this.onInputFocus2}
                        ></FieldGroupInput>
                    </div>
                    <div className="row">
                        <FieldGroupInput
                            labelInside="With label inside"
                            type="text"
                            placeholder="Text"
                            withIcon
                            icon="search"
                            iconRight
                        ></FieldGroupInput>
                    </div>
                </div>

                <div className="flexbox-col">
                    <div className="ui-library-sub-title">Numerical Input</div>
                    <div className="flexbox-row">
                        <div className="flexbox-col">
                            <FieldGroupNumericInput
                                label="Default"
                                placeholder="0"
                                withIcon
                                iconRight
                                focus={this.state.isFocus4}
                                onFocus={this.onInputFocus4}
                            ></FieldGroupNumericInput>
                        </div>
                        <div className="flexbox-col">
                            <FieldGroupNumericInput
                                label="Active"
                                value="1"
                                min="1"
                                withIcon
                                iconRight
                                focus={this.state.isFocus5}
                                onFocus={this.onInputFocus5}
                            ></FieldGroupNumericInput>
                        </div>
                    </div>
                    <div className="flexbox-row">
                        <div className="flexbox-col">
                            <FieldGroupNumericInput
                                label="Selected"
                                value="1"
                                withIcon
                                iconRight
                                numericInputFilled
                            ></FieldGroupNumericInput>
                        </div>
                        <div className="flexbox-col">
                            <FieldGroupNumericInput
                                label="Disabled"
                                value="1"
                                disabled
                                withIcon
                                iconRight
                                numericInputFilled
                            ></FieldGroupNumericInput>
                        </div>
                    </div>
                    <div className="flexbox-row">
                        <div className="flexbox-col">
                            <FieldGroupNumericInput
                                label="Read-only"
                                value="1"
                                readOnly
                                withIcon
                                iconRight
                                numericInputFilled
                            ></FieldGroupNumericInput>
                        </div>
                        <div className="flexbox-col">
                            <FieldGroupNumericInput
                                label="Error"
                                labelRequired
                                value="1"
                                error
                                withIcon
                                iconRight
                                numericInputFilled
                            ></FieldGroupNumericInput>
                        </div>
                    </div>
                </div>

                <div className="flexbox-col">
                    <div className="ui-library-sub-title">Date Input</div>
                    <div className="flexbox-row">
                        <FieldGroup>
                            <Label>Date only</Label>
                            <FieldWrap>
                                <DatePicker value={this.state.date} onChange={this.onDateChange} />
                            </FieldWrap>
                        </FieldGroup>
                    </div>
                    <div className="flexbox-row">
                        <FieldGroup>
                            <Label>Date and time</Label>
                            <FieldWrap>
                                <DatePicker
                                    value={this.state.date}
                                    onChange={this.onDateChange}
                                    showTime
                                    popperClassName="with-timepicker"
                                />
                            </FieldWrap>
                        </FieldGroup>
                    </div>
                    <div className="flexbox-row">
                        <FieldGroup>
                            <Label>Time only</Label>
                            <FieldWrap>
                                <DatePicker value={this.state.date} onChange={this.onDateChange} showTime showTimeOnly />
                            </FieldWrap>
                        </FieldGroup>
                    </div>
                </div>
            </div>
        );
    }
}

export default Inputs;
