import { isNil } from "lodash";
import { store } from "../../../store/configureStore";
import { getResource, updateResource } from "../../../store/resources/actions";
import { openAssignmentModal } from "../../ui/AssignmentSelector/utils";
import { useResource } from "../../../store/resources/useResource";
import {
    refreshInvoiceWorkflows,
    refreshContractWorkflowAssignments,
    refreshProgramWorkflowAssignments,
} from "../../../store/resources/refreshResource";
import { isNullOrWhitespace } from "components/utils/string";

// Create a unique id for the grid based on view name and utility number. Otherwise, the grid shares the same state between views.
export const getUserGroupsGridId = ({ utilityNumber, viewName }) => {
    return isNil(utilityNumber) ? `system-user-groups-grid-${viewName}` : `${utilityNumber}-user-groups-grid`;
};

export const getGroupList = ({ utilityNumber }) => {
    const state = store.getState();
    const resourceName = getResourceName({ utilityNumber, userName: "" });
    const resourceKey = getResourceKey({ utilityNumber, userName: "" });

    return state.resources[resourceName]?.itemsById[resourceKey]?.groupList ?? [];
};

export const getUserGroupList = ({ utilityNumber, userName = "" }) => {
    const state = store.getState();
    const resourceName = getResourceName({ utilityNumber, userName });
    const resourceKey = getResourceKey({ utilityNumber, userName });

    return (
        state.resources[resourceName]?.itemsById[resourceKey]?.groupList ??
        state.resources[resourceName]?.itemsById[resourceKey]?.groupListByUser ??
        []
    );
};

export const openUserAssignmentModal = ({ utilityNumber, group, onSelect }) => {
    const { groupNumber } = group;

    const resourceDataPath = "data.groupUserList";
    const title = "User Assignment";
    const idKey = "userNumber";
    const nameKey = "userName";

    const resourceGetParams = {
        resourceName: getUserListResourceName({ utilityNumber }),
        key: groupNumber,
        path: {
            ...(isNil(utilityNumber) ? {} : { utilityNumber }),
            groupNumber,
        },
    };

    openAssignmentModal({
        resourceGetParams,
        resourceDataPath,
        title,
        idKey,
        nameKey,
        onSelect,
    });
};

export const openProgramAssignmentModal = ({ utilityNumber, group, onSelect }) => {
    const { groupNumber } = group;

    const resourceDataPath = "data.groupProgramList";
    const title = "Program Assignment";
    const idKey = "progId";
    const nameKey = "programName";

    const resourceGetParams = {
        resourceName: getProgramListResourceName({ utilityNumber }),
        key: groupNumber,
        path: {
            ...(isNil(utilityNumber) ? {} : { utilityNumber }),
            groupNumber,
        },
    };

    openAssignmentModal({
        resourceGetParams,
        resourceDataPath,
        title,
        idKey,
        nameKey,
        onSelect,
    });
};

export const openUtilityAssignmentModal = ({ group, onSelect }) => {
    const { groupNumber } = group;

    const resourceDataPath = "data.groupUtilityList";
    const title = "Utility Assignment";
    const idKey = "utilityNumber";
    const nameKey = "utilityName";

    const resourceGetParams = {
        resourceName: "groupUtilities",
        key: groupNumber,
        path: {
            groupNumber,
        },
    };

    openAssignmentModal({
        resourceGetParams,
        resourceDataPath,
        title,
        idKey,
        nameKey,
        onSelect,
    });
};

export const onUpdateAssociation = ({ resourceName, utilityNumber, groupNumber, list }) => {
    const resourceParams = {
        resourceName,
        key: groupNumber,
        path: {
            ...(isNil(utilityNumber) ? {} : { utilityNumber }),
            groupNumber,
        },
    };

    store.dispatch(
        updateResource({
            ...resourceParams,
            body: list,
            onSuccess: () => {
                store.dispatch(getResource(resourceParams));
                refreshInvoiceWorkflows();
                refreshContractWorkflowAssignments({ utilityNumber });
                refreshProgramWorkflowAssignments({ utilityNumber });
            },
        })
    );
};

export const useUserGroup = ({ utilityNumber, groupNumber }) => {
    return useResource({
        resourceName: getResourceName({ utilityNumber }),
        resourceId: groupNumber,
        path: isNil(utilityNumber)
            ? undefined
            : {
                  utilityNumber,
              },
        forced: true,
    });
};

export const useUserGroupUsers = ({ utilityNumber, groupNumber, forced = true } = {}) => {
    return useResource({
        resourceName: getUserListResourceName({ utilityNumber }),
        key: groupNumber,
        path: {
            ...(isNil(utilityNumber) ? {} : { utilityNumber }),
            groupNumber,
        },
        forced: forced,
        transform: (data) => {
            return (data && data.groupUserList) || [];
        },
    });
};

export const useUserGroupPrograms = ({ utilityNumber, groupNumber, forced = true } = {}) => {
    return useResource({
        resourceName: getProgramListResourceName({ utilityNumber }),
        key: groupNumber,
        path: {
            ...(isNil(utilityNumber) ? {} : { utilityNumber }),
            groupNumber,
        },
        forced: forced,
        transform: (data) => {
            return (data && data.groupProgramList) || [];
        },
    });
};

export const useUserGroupUtilities = ({ groupNumber, forced = true } = {}) => {
    return useResource({
        resourceName: "groupUtilities",
        key: groupNumber,
        path: {
            groupNumber,
        },
        forced: forced,
        transform: (data) => {
            return (data && data.groupUtilityList) || [];
        },
    });
};

export const getResourceName = ({ utilityNumber, userName }) => {
    if (isNil(utilityNumber)) {
        return "groups";
    }

    if (!isNullOrWhitespace(userName)) {
        return "groupsByUser";
    }

    return "utilityGroups";
};

export const getUserListResourceName = ({ utilityNumber }) => {
    return isNil(utilityNumber) ? "groupUsers" : "utilityGroupUsers";
};

export const getProgramListResourceName = ({ utilityNumber }) => {
    return isNil(utilityNumber) ? "groupPrograms" : "utilityGroupPrograms";
};

export const getResourceKey = ({ utilityNumber, userName }) => {
    return isNil(utilityNumber) ? "system-user-groups" : `${utilityNumber}-${userName}-user-groups`;
};
export const userGroupsGridColumnKeys = {
    groupID: "c_a584a74d-d317-422c-821c-0930c3f7a61c",
    groupNumber: "c_67cbf839-6b33-4725-b161-ba5c8a3693df",
    parentGroupNumber: "c_b6b90918-dc06-4db7-a592-49d1cdf98273",
    groupName: "c_f11f7421-bc4d-42a5-a9fe-8a0f7db488c7",
    groupType: "c_3555febc-426b-4735-9e9b-8ec0d7945918",
    status: "c_b614a755-556f-4b05-83ef-b1e034019d59",
    parentGroupName: "c_bb850e68-beac-4ca2-8bc3-5e14bb8e607e",
};
