import React from "react";
import { isEmpty } from "lodash";
import cn from "classnames";
import { BorderPropertyContext } from "components/ui/PortalBuilder/contexts";
import { PropertyList } from "../../PropertyList";
import { PropertyListItemTitle } from "../../PropertyList/PropertyListItemTitle";
import { PropertyType } from "components/ui/PortalBuilder/types";
import { PropertyListItem } from "../../Property/PropertyListItem";
import { ButtonBorderWidthProperty } from "./ButtonBorderWidthProperty";
import { ButtonBorderCustomPropertyName } from "./types";
import { ColorSelectIcon } from "../../Property/ColorSelect/ColorSelectIcon";
import ColorSelectPanel from "../../Property/ColorSelect/ColorSelectPanel";

import "./GlobalButtonProperties.scss";

export const DefaultBorderWidth = "1px";

export const FilledButtonBorderProperty = (props) => {
    const { className, title, value = {}, nestingLevel, borderTop, borderBottom, onChange } = props;

    const isExpanded = Object.values(value).some((v) => !isEmpty(v));
    const customPropertiesValue = {
        [ButtonBorderCustomPropertyName.FilledButtonBorderWidth]: getBorderWidth(
            value[ButtonBorderCustomPropertyName.FilledButtonBorderWidth]
        ),
        [ButtonBorderCustomPropertyName.FilledButtonBorderColorDefault]:
            value[ButtonBorderCustomPropertyName.FilledButtonBorderColorDefault],
        [ButtonBorderCustomPropertyName.FilledButtonBorderColorMouseOver]:
            value[ButtonBorderCustomPropertyName.FilledButtonBorderColorMouseOver],
        [ButtonBorderCustomPropertyName.FilledButtonBorderColorFocusActive]:
            value[ButtonBorderCustomPropertyName.FilledButtonBorderColorFocusActive],
        [ButtonBorderCustomPropertyName.FilledButtonBorderColorDisabled]:
            value[ButtonBorderCustomPropertyName.FilledButtonBorderColorDisabled],
    };

    const onToggle = () => {
        if (isExpanded) {
            const extraProperties = [
                {
                    id: ButtonBorderCustomPropertyName.FilledButtonBorderWidth,
                    value: undefined,
                },
                {
                    id: ButtonBorderCustomPropertyName.FilledButtonBorderColorDefault,
                    value: undefined,
                },
                {
                    id: ButtonBorderCustomPropertyName.FilledButtonBorderColorMouseOver,
                    value: undefined,
                },
                {
                    id: ButtonBorderCustomPropertyName.FilledButtonBorderColorFocusActive,
                    value: undefined,
                },
                {
                    id: ButtonBorderCustomPropertyName.FilledButtonBorderColorDisabled,
                    value: undefined,
                },
            ];
            onChange(ButtonBorderCustomPropertyName.FilledButtonBorderWidth, undefined, extraProperties);
        } else {
            onChange(ButtonBorderCustomPropertyName.FilledButtonBorderWidth, DefaultBorderWidth);
        }
    };

    const buttonBorderPropertyContextValue = {
        customPropertiesValue,
    };

    const customProperties = [
        {
            id: ButtonBorderCustomPropertyName.FilledButtonBorderWidth,
            type: PropertyType.CustomComponent,
            title: <>Border width:</>,
            component: ButtonBorderWidthProperty,
        },
        {
            title: "Border Color",
            type: PropertyType.Group,
            borderBottom: false,
            collapsible: false,
            propertiesGroup: [
                {
                    id: ButtonBorderCustomPropertyName.FilledButtonBorderColorDefault,
                    title: <>Default</>,
                    type: "select-panel",
                    selectComponent: ColorSelectIcon,
                    panelComponent: ColorSelectPanel,
                },
                {
                    id: ButtonBorderCustomPropertyName.FilledButtonBorderColorMouseOver,
                    title: <>Mouse over</>,
                    type: "select-panel",
                    selectComponent: ColorSelectIcon,
                    panelComponent: ColorSelectPanel,
                },
                {
                    id: ButtonBorderCustomPropertyName.FilledButtonBorderColorFocusActive,
                    title: <>Focus/Active</>,
                    type: "select-panel",
                    selectComponent: ColorSelectIcon,
                    panelComponent: ColorSelectPanel,
                },
                {
                    id: ButtonBorderCustomPropertyName.FilledButtonBorderColorDisabled,
                    title: <>Disabled</>,
                    type: "select-panel",
                    borderBottom: false,
                    selectComponent: ColorSelectIcon,
                    panelComponent: ColorSelectPanel,
                    disabled: true,
                },
            ],
        },
    ];

    return (
        <BorderPropertyContext.Provider value={buttonBorderPropertyContextValue}>
            <PropertyListItem
                className={cn("property-list-item--prop-group property-list-item--border", className)}
                borderTop={borderTop}
                borderBottom={borderBottom}
            >
                {/* @ts-ignore */}
                <PropertyListItemTitle
                    title={title}
                    toggleTooltip={isExpanded ? "Switch OFF" : "Switch ON"}
                    onToggle={onToggle}
                    toggleValue={!isExpanded}
                />
                <PropertyList
                    items={customProperties}
                    nestingLevel={nestingLevel + 1}
                    config={customPropertiesValue}
                    onChange={onChange}
                    isExpanded={isExpanded}
                />
            </PropertyListItem>
        </BorderPropertyContext.Provider>
    );
};

const getBorderWidth = (cssValue) => {
    const result = parseInt(cssValue, 10);
    return isNaN(result) ? 1 : result;
};

export const FILLED_BUTTON_BORDER_PROPERTY = {
    title: "Border",
    type: PropertyType.CustomComponent,
    component: FilledButtonBorderProperty,
};
