import { useCallback } from "react";

const useEnterKey = ({ disabled, onClick } = {}) => {
    const onEnter = useCallback(
        (event) => {
            if (event.key === "Enter" && !disabled) {
                event.preventDefault();
                onClick && onClick(event);
            }
        },
        [disabled, onClick]
    );

    return [onEnter];
};

export default useEnterKey;
