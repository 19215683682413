import React, { useCallback, useEffect, useState, useRef, memo, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import { construct } from "../../../store/dataGrid/actions";
import { createResource } from "../../../store/resources/actions";
import { availableGrids, utilitiesGridColumnKeys } from "../configureGrids";
import { openUtilityTab } from "../../utils/window";
import { exportDatagridToCSV } from "../../utils/CSV";
import { hasPermission, systemUserRights } from "../../utils/user";

import { WindowContext } from "../../ui/Windows/Window";

import IconWithLabel from "../../ui/Icons/IconWithLabel";
import WaitIcon from "../../ui/WaitIcon";
import DataGrid from "../../ui/DataGrid";
import ActionLabel from "../../ui/Label/ActionLabel";
import { UtilityForm } from "../../ui/Form/shared/UtilityForm";

import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import { sideNavSize, sideNavKey } from "components/ui/SideNav/SideNavRoot";

import "./style.scss";

const DATA_GRID_ID = "utilities";
const ROW_ACTIONS = [
    {
        name: "edit",
        icon: "open-new-window",
        title: "Manage Utility",
    },
];

const UtilitiesAndProgramManagementAllUtilities = memo(() => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const dataGridConfig = useSelector((state) => state.dataGrid[DATA_GRID_ID]);

    const didCancel = useRef(false);

    const [isAddUtilityFormVisible, setAddUtilityFormVisibility] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const { isSplitView } = useContext(WindowContext);

    const key = isSplitView ? undefined : sideNavKey.globalRight;
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        className: "add-new-utility",
        sideNavKey: key,
        size: sideNavSize.small,
    });

    const canCreateUtility = hasPermission(user, systemUserRights.VISIONDSM_ADD_UTILITY);

    const buildDataGrid = useCallback(
        () =>
            dispatch(
                construct({
                    dataGridId: availableGrids.utilities,
                    dataGridInstanceId: DATA_GRID_ID,
                    data: true,
                })
            ),
        [dispatch]
    );

    useEffect(() => {
        buildDataGrid();

        return () => {
            didCancel.current = true;
        };
    }, [buildDataGrid]);

    const handleCloseAddUtilityForm = useCallback(() => handleCloseSidePanel(), [handleCloseSidePanel]);

    const handleUtilityOpen = useCallback((utilityNumber) => {
        openUtilityTab({ utilityNumber });
    }, []);

    const handleRowAction = useCallback(
        (action) => {
            const { dataItem } = action;
            const utilityNumber = dataItem[utilitiesGridColumnKeys.utilityNumber];

            handleUtilityOpen(utilityNumber);
        },
        [handleUtilityOpen]
    );

    const handleSubmitForm = useCallback(
        (form) => {
            setSubmitting(true);
            dispatch(
                createResource({
                    resourceName: "utilities",
                    body: form,
                    onSuccess: () => {
                        if (!didCancel.current) {
                            handleCloseAddUtilityForm();
                            buildDataGrid();
                        }
                    },
                    onComplete: () => {
                        if (!didCancel.current) {
                            setSubmitting(false);
                        }
                    },
                })
            );
        },
        [buildDataGrid, handleCloseAddUtilityForm, dispatch]
    );

    const handleToggleAddUtilityFormVisibility = useCallback(() => {
        setAddUtilityFormVisibility(!isAddUtilityFormVisible);
        handleOpenSidePanel(<UtilityForm onSubmit={handleSubmitForm} onCancel={handleCloseSidePanel} isSubmitting={isSubmitting} />);
    }, [isAddUtilityFormVisible, isSubmitting, handleSubmitForm, handleCloseSidePanel, handleOpenSidePanel]);

    return (
        <div className="all-utilities fill-height">
            <div className="main-grid-wrap responsive flex-column fill-height">
                <div className="data-grid-controls">
                    {canCreateUtility && (
                        <IconWithLabel icon="plus" onClick={handleToggleAddUtilityFormVisibility}>
                            Add New Utility
                        </IconWithLabel>
                    )}
                    <IconWithLabel
                        withHandMadeIcon
                        onClick={() =>
                            exportDatagridToCSV({
                                dataGridId: DATA_GRID_ID,
                                fileName: "utilities",
                            })
                        }
                    >
                        Export CSV
                    </IconWithLabel>
                </div>
                {dataGridConfig ? (
                    <div className="flex-column flex-one-in-column">
                        <DataGrid
                            name={DATA_GRID_ID}
                            config={dataGridConfig}
                            onRowAction={handleRowAction}
                            customRowActions={ROW_ACTIONS}
                            columnCellContent={{
                                [utilitiesGridColumnKeys.utilityNumber]: (column, row) => (
                                    <ActionLabel onClick={() => handleUtilityOpen(row[column.key])}>{row[column.key]}</ActionLabel>
                                ),
                                [utilitiesGridColumnKeys.utilityName]: (column, row) => {
                                    if (row[utilitiesGridColumnKeys.utilityNumber]) {
                                        return (
                                            <ActionLabel onClick={() => handleUtilityOpen(row[utilitiesGridColumnKeys.utilityNumber])}>
                                                {row[column.key]}
                                            </ActionLabel>
                                        );
                                    }

                                    return row[column.key];
                                },
                            }}
                        />
                    </div>
                ) : (
                    <WaitIcon />
                )}
            </div>
        </div>
    );
});

export default UtilitiesAndProgramManagementAllUtilities;
