import React, { useCallback, useContext } from "react";

import { useDispatch } from "react-redux";
import { WindowContext } from "components/ui/Windows/Window";

import { sideNavOpen, sideNavClose, setSideNavForm } from "store/sideNav/actions";
import { sideNavMode, sideNavSize, getViewSideNavKey, sideNavPosition } from "components/ui/SideNav/SideNavRoot";

/** Returns handlers to use for opening SidePanel */
export const useSidePanelHandlers = ({ className, size, disableClose, sideNavKey } = {}) => {
    // Dispatcher
    const dispatch = useDispatch();

    // Get side nav key
    const { viewName, splitWindowPosition } = useContext(WindowContext);
    const key = sideNavKey ?? getViewSideNavKey({ viewName, position: sideNavPosition.right, splitWindowPosition });

    const setForm = useCallback(
        (form) => {
            dispatch(setSideNavForm({ id: key, form }));
        },
        [key, dispatch]
    );

    // Handle Close Panel
    const handleCloseSidePanel = useCallback(() => {
        dispatch(sideNavClose({ id: key }));
    }, [key, dispatch]);

    // Handle Open Panel
    const handleOpenSidePanel = useCallback(
        (component, params = {}) => {
            if (component) {
                dispatch(
                    sideNavOpen({
                        id: key,
                        props: {
                            mode: sideNavMode.over,
                            backdrop: true,
                            className: params.className ?? className,
                            disableClose: params.disableClose ?? disableClose ?? false,
                            size: params.size ?? size ?? sideNavSize.staticLarge,
                            confirmMessage: params.confirmMessage,
                            confirmModalCheck: params.confirmModalCheck,
                            onClose: params.onClose,
                            children: React.cloneElement(component, {
                                sidePanel: {
                                    key,
                                    close: handleCloseSidePanel,
                                    setForm,
                                },
                            }),
                        },
                    })
                );
            }
        },
        [key, className, size, disableClose, dispatch, handleCloseSidePanel, setForm]
    );

    // Return handlers
    return {
        handleOpenSidePanel,
        handleCloseSidePanel,
    };
};

export default useSidePanelHandlers;
