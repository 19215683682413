import React, { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import IconWrap from "../../../../Icons";
import NothingFoundBlock from "../../../../NothingFoundBlock";
import { availableGrids, documentsGridColumnKeys } from "../../../../../views/configureGrids";
import { construct } from "../../../../../../store/dataGrid/actions";
import { searchAttrToFilter } from "../../../../../utils/datagrid";
import WaitIcon from "../../../../WaitIcon";
import { formatJsonDateTime } from "../../../../../utils/date";
import ActionLabel from "../../../../Label/ActionLabel";
import { exportPdf, getValidFileName } from "components/utils/files";
import CustomList, { renderCustomFooter } from "components/ui/List/CustomList";

const CorrespondenceTab = memo(({ applicationNumber }) => {
    const dataGridId = `${applicationNumber}-documents-sidebar`;

    const dispatch = useDispatch();
    const dataGridConfig = useSelector((state) => state.dataGrid[dataGridId]);

    useEffect(() => {
        dispatch(
            construct({
                dataGridId: availableGrids.documents,
                dataGridInstanceId: dataGridId,
                filter: searchAttrToFilter(`${documentsGridColumnKeys.appId}=${applicationNumber}`),
                sort: [
                    {
                        field: documentsGridColumnKeys.date,
                        dir: "desc",
                    },
                ],
                data: true,
            })
        );
    }, [applicationNumber, dataGridId, dispatch]);

    const onDownload = useCallback((item) => {
        const fileName = getValidFileName({
            fileName: item[documentsGridColumnKeys.item],
            fileExtension: "pdf",
        });
        const fileContent = item[documentsGridColumnKeys.content];

        exportPdf({ fileName, fileContent });
    }, []);

    if (!dataGridConfig) {
        return null;
    }

    const { rows = [], isReading, isConstructing } = dataGridConfig;

    if (isReading || isConstructing) {
        return <WaitIcon />;
    }

    const renderItem = (item) => {
        return (
            <div key={item[documentsGridColumnKeys.documentNumber]} className="item">
                {item[documentsGridColumnKeys.type] === "Email" && <IconWrap iconWrapBig iconWrap="email-empty"></IconWrap>}
                {item[documentsGridColumnKeys.type] === "Letter" && <IconWrap iconWrapBig iconWrap="mailbox-empty"></IconWrap>}
                {item[documentsGridColumnKeys.type] === "Text Message" && <IconWrap iconWrapBig iconWrap="text-wrap"></IconWrap>}
                <div className="item-header">
                    {item[documentsGridColumnKeys.type]}
                    <div className="item-date">{formatJsonDateTime(item[documentsGridColumnKeys.date])}</div>
                </div>
                <ActionLabel className="item-name" onClick={() => onDownload(item)}>
                    {item[documentsGridColumnKeys.item]}
                </ActionLabel>
            </div>
        );
    };

    return (
        <div className="sidebar-doc-tab correspondence">
            <CustomList limit={6} items={rows} renderItem={renderItem} renderFooter={renderCustomFooter} />
            {rows.length === 0 && <NothingFoundBlock nothingFoundBlockSmall icon="files" title="No correspondence" />}
        </div>
    );
});

export default CorrespondenceTab;
