import React from "react";
import { openConfirmModal } from "components/ui/Modal/utils";
import { clientUserRolesUpdateEntityType } from "components/utils/constants";
import { getResource, updateResource } from "store/resources/actions";
import { getClientUsersResourceParams } from "store/configureResources";
import { downloadCSV } from "components/utils/CSV";
import { RoleChangeModalMessage } from "./RoleChangeModalMessage";

export const getUserListInfo = ({ utilityNumber, programs, users }) => {
    const result = users.reduce((userInfo, user) => {
        const userRoles = user.roles ?? [];

        const defaultRoleId =
            userRoles.find((role) => role.utilities.some((utility) => utility.entityNumber === utilityNumber))?.roleId ??
            user.clientRole?.roleId;

        let roleAssignments = userRoles.reduce(
            (result, role) => ({
                ...result,

                [role.roleId]: role.programs
                    // Take programs from current utility
                    .filter((program) => program.parentEntityNumber === utilityNumber)
                    // Take programs if exist in program list
                    .filter((program) => programs.some((p) => p.programNumber === program.entityNumber)),
            }),
            {}
        );

        // Collect assigned programs from all roles
        const assignedPrograms = [].concat(Object.keys(roleAssignments).flatMap((key) => roleAssignments[key]));

        // Get list of not assigned programs to add to default role
        const notAssignedPrograms = programs
            .filter((i) => !assignedPrograms.some((j) => j.entityNumber === i.programNumber))
            .map((i) => ({
                assigned: false,
                entityNumber: i.programNumber,
                entityName: i.program,
                parentEntityNumber: utilityNumber,
                parentEntityName: "",
            }));

        roleAssignments[defaultRoleId] = (roleAssignments[defaultRoleId] ?? []).concat(notAssignedPrograms);

        return {
            ...userInfo,
            [user.userNumber]: {
                defaultRoleId,
                roleAssignments,
            },
        };
    }, {});

    return result;
};

export const getProgramListInfo = ({ utilityNumber, roles, programs, users }) => {
    const result = programs.reduce((itemInfo, program) => {
        // Count of assigned users in current utility
        const roleAssignments = roles.reduce((result, role) => {
            let assignedUsers = [];

            users.forEach((user) => {
                const userRoles = user.roles ?? [];

                const userRole = userRoles.find((r) => r.roleId === role.roleId) ?? {
                    programs: [],
                    utilities: [],
                };

                if (userRole.programs?.some((p) => p.entityNumber === program.programNumber)) {
                    assignedUsers.push(user);
                } else {
                    // If no other role has this program then can look at inherited roles
                    if (!userRoles.some((r) => r.programs?.some((p) => p.entityNumber === program.programNumber))) {
                        if (userRole.utilities?.some((p) => p.entityNumber === utilityNumber)) {
                            assignedUsers.push(user);
                        } else {
                            if (user.clientRole?.roleId === role.roleId) {
                                assignedUsers.push(user);
                            }
                        }
                    }
                }
            });

            return {
                ...result,
                [role.roleId]: assignedUsers,
            };
        }, {});

        return {
            ...itemInfo,
            [program.programNumber]: {
                defaultRoleId: null,
                roleAssignments,
            },
        };
    }, {});

    return result;
};

export const setProgramLevelRoleForSelection = ({
    clientNumber,
    utilityNumber,
    roleId,
    roleName,
    userNumbers,
    programNumbers,
    message,
    dispatch,
}) => {
    openConfirmModal({
        title: "Change role",
        modalIcon: "theaters-empty",
        message: message ?? (
            <p>
                Set <strong>{roleName}</strong> role for selection?
            </p>
        ),
        onConfirm: () => {
            const body = {
                entityType: clientUserRolesUpdateEntityType.Program,
                userList: userNumbers,
                entityList: programNumbers,
            };

            dispatch(
                updateResource({
                    resourceName: "clientRoles",
                    resourceId: roleId,
                    path: {
                        clientNumber,
                    },
                    body,
                    onSuccess: () => {
                        refreshClientUsers({
                            clientNumber,
                            utilityNumber,
                            dispatch,
                        });
                    },
                })
            );
        },
    });
};

export const setUtilityLevelRoleForSelection = ({ clientNumber, utilityNumber, roleId, userNumbers, message, dispatch }) => {
    openConfirmModal({
        title: "Change role",
        modalIcon: "theaters-empty",
        message,
        onConfirm: () => {
            const body = {
                entityType: clientUserRolesUpdateEntityType.Utility,
                userList: userNumbers,
                entityList: [utilityNumber],
            };

            dispatch(
                updateResource({
                    resourceName: "clientRoles",
                    resourceId: roleId,
                    path: {
                        clientNumber,
                    },
                    body,
                    onSuccess: () => {
                        refreshClientUsers({
                            clientNumber,
                            utilityNumber,
                            dispatch,
                        });
                    },
                })
            );
        },
    });
};

export const exportProgramList = ({ programs }) => {
    openConfirmModal({
        title: "Export programs",
        modalIcon: "theaters-empty",
        message: <RoleChangeModalMessage title="Export programs" programList={programs.map((p) => ({ program: p.entityName }))} />,
        onConfirm: () => {
            const data = (programs ?? []).map((item) => ({
                ID: item.entityNumber,
                NAME: item.entityName,
            }));

            downloadCSV({
                data,
                fileName: "AssignmentExport",
            });
        },
    });
};

export const exportUserList = ({ users }) => {
    openConfirmModal({
        title: "Export users",
        modalIcon: "theaters-empty",
        message: <RoleChangeModalMessage title="Export users" userList={users} />,
        onConfirm: () => {
            const data = (users ?? []).map((item) => ({
                ID: item.userNumber,
                NAME: item.userName,
            }));

            downloadCSV({
                data,
                fileName: "AssignmentExport",
            });
        },
    });
};

export const refreshClientUsers = ({ clientNumber, utilityNumber, dispatch }) => {
    dispatch(getResource(getClientUsersResourceParams({ clientNumber, utilityNumber })));
};
