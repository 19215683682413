import React, { useState, useEffect, memo, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";

import { optimisticUpdateItem } from "../../../../../../store/resources/actions";
import { programsDocumentsGridColumnKeys, programsDocumentsRevisionsGridColumnKeys } from "../../../../../views/configureGrids";
import { useReference } from "../../../../Reference/useReference";
import { referenceTypes } from "../../../../Reference/referenceTypes";
import { getData } from "../../../../../../store/dataGrid/actions";

import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import { useFastTags, useResource } from "../../../../../../store/resources/useResource";
import {
    refreshProgramAvailableDocuments,
    refreshApplicationAvailabelDocumentsInOpenTabs,
} from "../../../../../../store/resources/refreshResource";
import { submitResource, submitByRef } from "../../../../../utils/form";
import Button from "../../../../Button";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import DocumentRevisionsPanel from "./DocumentRevisionsPanel";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";
import { isChildProgram } from "components/views/ProgramView/utils";
import WaitIcon from "components/ui/WaitIcon";

const PreloadRevisionForm = memo((props) => {
    const { dataItem, programNumber, revisionNumber } = props;

    const isNew = dataItem == null;

    const resourceName = revisionNumber ? "programDocumentRevision" : "programDocuments";

    const resourceNumber = isNew
        ? undefined
        : revisionNumber
        ? dataItem[programsDocumentsRevisionsGridColumnKeys.correspondenceVersionNumber]
        : dataItem[programsDocumentsGridColumnKeys.documentNumber];

    const documentNumber = isNew
        ? undefined
        : revisionNumber
        ? dataItem[programsDocumentsRevisionsGridColumnKeys.documentNumber]
        : dataItem[programsDocumentsGridColumnKeys.documentNumber];

    const [correspondenceTypes = [], isLoadingCorrespondenceTypes] = useReference(referenceTypes.correspondenceType);
    const [fastTags, isLoadingFastTags] = useFastTags({ entityNumber: programNumber, entityType: "program" });
    const [resource, isLoadingResource] = useResource({
        resourceName,
        resourceId: resourceNumber,
        path: {
            programNumber,
            documentNumber,
        },
    });

    const isLoading = isLoadingCorrespondenceTypes || isLoadingFastTags || isLoadingResource;

    if (isLoading) {
        return <WaitIcon />;
    }

    return <RevisionForm {...props} resource={resource} correspondenceTypes={correspondenceTypes} fastTags={fastTags} />;
});

const RevisionForm = memo(
    ({ dataItem, programNumber, resource, correspondenceTypes, fastTags, revisionNumber, gridId, onClose, sidePanel }) => {
        const dispatch = useDispatch();

        const isNew = dataItem == null;

        const isLocked = isChildProgram({ programNumber });

        const documentNumber = isNew
            ? undefined
            : revisionNumber
            ? dataItem[programsDocumentsRevisionsGridColumnKeys.documentNumber]
            : dataItem[programsDocumentsGridColumnKeys.documentNumber];

        const documentName = dataItem[programsDocumentsRevisionsGridColumnKeys.name];

        const correspondenceTypeList =
            (correspondenceTypes &&
                correspondenceTypes.map((i) => ({
                    title: i.display,
                    enum: [+i.val],
                }))) ||
            [];

        const [isSubmitting, setSubmitting] = useState(false);
        const initialValues = resource ?? {};
        const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({ size: sideNavSize.medium });

        const formRef = useRef();

        const handleSave = useCallback(() => {
            submitByRef(formRef);
        }, []);

        const schema = {
            type: "object",
            required: ["item_Type", "name", "content"],
            properties: {
                item_Type: {
                    type: "integer",
                    title: "Type",
                    anyOf: correspondenceTypeList,
                },
                name: {
                    type: "string",
                    title: "Name",
                },
                content: {
                    type: "string",
                    title: "Content",
                },
            },
        };

        const uiSchema = {
            classNames: "inline-form columns-2",
            item_Type: {
                classNames: "program-correspondence-dropdown",
                "ui:options": {
                    placeholder: "-- SELECT --",
                },
            },
            content: {
                classNames: "fill-width",
                "ui:widget": "HtmlEditorWidget",
                "ui:options": {
                    fastTags,
                },
            },
        };

        const onSubmit = (formData) => {
            const resourceParams = {
                resourceName: "programDocuments",
                path: {
                    programNumber,
                },
            };

            const body = {
                ...formData,
            };

            submitResource({
                resourceParams,
                resourceId: documentNumber,
                body,
                onRefresh: () => {
                    // Refresh available document in store
                    dispatch(
                        optimisticUpdateItem({
                            resourceName: resourceParams.resourceName,
                            resourceId: documentNumber,
                            value: body,
                        })
                    );

                    // Refresh available documents list
                    refreshProgramAvailableDocuments({
                        programNumber,
                        itemType: formData.item_Type,
                    });

                    // Refresh document lists in open applications
                    refreshApplicationAvailabelDocumentsInOpenTabs({
                        programNumber,
                        isEmail: formData.item_Type === 39,
                    });

                    // refresh grid
                    dispatch(getData({ dataGridId: gridId }));
                },
                onSuccess: sidePanel ? sidePanel.close : onClose,
                setSubmitting,
            });
        };
        const onBack = () => {
            handleOpenSidePanel(
                <DocumentRevisionsPanel
                    programNumber={programNumber}
                    documentNumber={documentNumber}
                    documentName={documentName}
                    onClose={handleCloseSidePanel}
                />,
                { size: sideNavSize.staticLarge }
            );
        };

        const getSubmitText = isSubmitting ? "Saving..." : "Save";

        useEffect(() => {
            if (sidePanel) {
                sidePanel.setForm(formRef);
            }
        }, [sidePanel]);

        return (
            <SideNavContent>
                <SideNavHeader
                    title={isNew ? "Add Revision" : isLocked ? "View Revision " : "Edit Revision "}
                    nameInBold={documentName}
                    leadBlockIcon={isNew ? "plus" : isLocked ? "eye-visibility-empty" : "edit-empty"}
                    smallHeader
                    onClose={onClose}
                />
                <SideNavBody className="flex-one-in-column">
                    <JsonSchemaForm
                        formRef={formRef}
                        schema={schema}
                        uiSchema={uiSchema}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onCancel={onClose}
                        submitText={getSubmitText}
                        disabled={isSubmitting || isLocked}
                        noActions
                    />
                </SideNavBody>
                <SideNavFooter setPrimaryButton={!isLocked} justifyEnd={isLocked}>
                    <Button icon="shevron-small-left" onClick={onBack}>
                        Back
                    </Button>
                    <div className="flex-row flex-one-in-row justify-end">
                        {!isLocked && (
                            <Button primary onClick={handleSave} disabled={isSubmitting}>
                                {getSubmitText}
                            </Button>
                        )}
                        <Button onClick={onClose}>{isLocked ? "Close" : "Cancel"}</Button>
                    </div>
                </SideNavFooter>
            </SideNavContent>
        );
    }
);

export default PreloadRevisionForm;
