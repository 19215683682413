import React, { memo, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import Grid from "./Grid";
import Form from "./Form";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import { exportDatagridToCSV } from "components/utils/CSV";
import { getData } from "store/dataGrid/actions";
import Controls from "../../Controls";

import "./style.scss";

const BudgetLookups = memo(({ panel }) => {
    const dispatch = useDispatch();

    const utilityNumber = panel?.data?.utilityNumber;
    const contractNumber = panel?.data?.contractNumber;
    const dataGridId = `${contractNumber}-budget-lookups-grid`;

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "contract-budget-lookups-sidenav-panel",
    });

    const onRefresh = useCallback(() => {
        dispatch(getData({ dataGridId }));
    }, [dataGridId, dispatch]);

    const controls = useMemo(
        () => [
            {
                position: "right",
                title: "Add Parent Lookup",
                icon: "plus",
                onClick: () => {
                    handleOpenSidePanel(
                        <Form
                            utilityNumber={utilityNumber}
                            contractNumber={contractNumber}
                            gridRefresh={onRefresh}
                            onClose={handleCloseSidePanel}
                        />
                    );
                },
            },
            {
                position: "right",
                title: "Export CSV",
                withHandMadeIcon: true,
                onClick: () => {
                    exportDatagridToCSV({
                        dataGridId: dataGridId,
                        fileName: "contract_budget_lookups",
                        fileNamePostfix: contractNumber,
                    });
                },
            },
        ],
        [dataGridId, utilityNumber, contractNumber, onRefresh, handleOpenSidePanel, handleCloseSidePanel]
    );

    return (
        <div className="panel contract-budget-lookups flex-column fill-height">
            <PanelHeaderLarge title={panel.name} />
            <Controls items={controls} />
            <div className="flex-column flex-one-in-column no-scroll">
                <Grid dataGridId={dataGridId} utilityNumber={utilityNumber} contractNumber={contractNumber} onRefresh={onRefresh} />
            </div>
        </div>
    );
});

export default BudgetLookups;
