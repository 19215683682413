import React from "react";
import StatusMsg from "components/ui/StatusMsg";

export const NoPermissionMessage = () => {
    return (
        <div className="flex-column full-width fill-height justify-center align-center">
            <StatusMsg msgInfo>You are not permitted to use this functionality.</StatusMsg>
        </div>
    );
};
