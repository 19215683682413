import React, { useRef, useState, useCallback, memo, useEffect } from "react";

import { useBudgetLineInvoice } from "../../../../../../store/resources/useResource";
import { getInvoiceItemFormSchema, getInvoiceItemFormUiSchema } from "../../Invoice/utils";
import { submitResource, pickInitialValues } from "../../../../../utils/form";
import { submitByRef } from "../../../../../utils/form";

import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import WaitIcon from "../../../../WaitIcon";
import { ErrorMessage } from "../../../../Message";
import Button from "../../../../Button";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";

const Form = memo((props) => {
    const { utilityNumber, contractNumber, budgetLineNumber, dataItem, gridRefresh, onClose, sidePanel } = props;

    const formRef = useRef();

    const isNew = dataItem === undefined;
    const resourceId = isNew ? null : dataItem.invoiceNumber;

    const [isSubmitting, setSubmitting] = useState(false);

    const [resource, isLoading] = useBudgetLineInvoice({
        utilityNumber,
        contractNumber,
        budgetLineNumber,
        resourceId,
    });

    const titleText = isNew ? "Add Budget Invoice" : "Edit Budget Invoice";
    const titleIcon = isNew ? "plus" : "edit-empty";

    const initialValues = isNew ? {} : pickInitialValues(resource);

    const submitText = isSubmitting ? "Saving..." : "Save";

    const schema = getInvoiceItemFormSchema({ withCostCode: false });
    const uiSchema = getInvoiceItemFormUiSchema({
        withCostCode: false,
        utilityNumber,
        contractNumber,
    });

    const onSubmit = useCallback(
        (formData) => {
            const resourceParams = {
                resourceName: "utilitiesContractsBudgetLinesInvoices",
                path: {
                    utilityNumber,
                    contractNumber,
                    budgetLineNumber,
                },
            };

            const body = {
                ...formData,
            };

            submitResource({
                resourceParams,
                resourceId,
                body,
                onRefresh: gridRefresh,
                onSuccess: sidePanel.close,
                setSubmitting,
            });
        },
        [utilityNumber, contractNumber, budgetLineNumber, resourceId, gridRefresh, sidePanel]
    );

    const handleSubmit = useCallback(() => {
        submitByRef(formRef);
    }, []);

    useEffect(() => {
        sidePanel.setForm([formRef]);
    }, [sidePanel]);

    if (isLoading) {
        return <WaitIcon />;
    }

    const showError = !isNew && !resource;

    return (
        <SideNavContent className="invoice-edit-form">
            <SideNavHeader title={titleText} leadBlockIcon={titleIcon} smallHeader onClose={onClose}></SideNavHeader>
            <SideNavBody className="flex-one-in-column">
                {showError ? (
                    <ErrorMessage spaceAround>Invoice not Found</ErrorMessage>
                ) : (
                    <JsonSchemaForm
                        formRef={formRef}
                        schema={schema}
                        uiSchema={uiSchema}
                        initialValues={initialValues}
                        disabled={isSubmitting}
                        onSubmit={onSubmit}
                        submitText={submitText}
                        noReset
                        noActions
                    />
                )}
            </SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button primary disabled={isSubmitting} onClick={handleSubmit}>
                    {submitText}
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default Form;
