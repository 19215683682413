import React, { useCallback, memo } from "react";

import withDataGrid from "../../../../../DataGrid/withDataGrid";
import { programsCatalogGridColumnKeys } from "../../../../../../views/configureGrids";
import { isYes } from "../../../../../../utils/string";
import { openCalculationAssignmentModal } from "../utils";

import DataGrid from "../../../../../DataGrid";
import { isChildProgram } from "components/views/ProgramView/utils";

const Grid = memo(({ gridId, dataGridConfig, onOpenItem, programNumber }) => {
    const isLocked = isChildProgram({ programNumber });

    const rowActions = [
        {
            name: "edit",
            icon: isLocked ? "eye-visibility-empty" : "edit-empty",
            title: isLocked ? "View" : "Edit",
        },
        {
            name: "calc-assignment",
            icon: (row) => (isYes(row[programsCatalogGridColumnKeys.calculations]) ? "hub-filled" : "hub-empty"),
            hide: isLocked,
            title: "Edit Catalog Calculation Assignment",
        },
    ];

    const onRowAction = useCallback(
        (action) => {
            const { name, dataItem } = action;

            switch (name) {
                case "edit":
                    onOpenItem({ dataItem });
                    break;

                case "calc-assignment":
                    openCalculationAssignmentModal({
                        utilityNumber: dataItem[programsCatalogGridColumnKeys.utilityNumber],
                        programNumber: dataItem[programsCatalogGridColumnKeys.programNumber],
                        catalogNumber: dataItem[programsCatalogGridColumnKeys.catalogNumber],
                        hasCalcAssignment: isYes(dataItem[programsCatalogGridColumnKeys.calculations]),
                    });
                    break;

                default:
                    break;
            }
        },
        [onOpenItem]
    );

    return <DataGrid name={gridId} config={dataGridConfig} customRowActions={rowActions} onRowAction={onRowAction} />;
});

export default withDataGrid("programsCatalog", Grid);
