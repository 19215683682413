import React from "react";

import LocationMap from "../../../../Location/Map";
import StreetView from "../../../../Location/StreetView";
import NothingFoundBlock from "../../../../NothingFoundBlock";

import "./LocationView.scss";

const LocationView = ({ location, applicationNumber }) => {
    if (!location.address) {
        return (
            <div className="location-tab location-block no-info">
                <div className="title">Location details</div>
                <NothingFoundBlock nothingFoundBlockSmall nothingFoundBlockMargin icon="location" title="No Data Available" />
            </div>
        );
    }

    return (
        <div className="location-tab location-view">
            <div className="flex-column fill-width map">
                <LocationMap showMarker applicationNumber={applicationNumber} location={location} height="323" />
                <div className="address">{location.address + ", " + location.city + ", " + location.state + ", " + location.zip}</div>
            </div>
            <div className="view">
                <StreetView location={location} width="323" height="323" />
            </div>
        </div>
    );
};

export default LocationView;
