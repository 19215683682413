import React from "react";
import Button from "components/ui/Button";

export const RolesManagementFooter = (props: RolesManagementFooterProps) => {
    const { disableSave, onClose, onRevert, onSave } = props;

    return (
        <div className="roles-management-footer flex-row">
            <Button icon="shevron-small-left" onClick={onClose}>
                Back to user list
            </Button>
            <span className="flex-one" />
            <Button primary disabled={disableSave} onClick={onSave}>
                Apply Roles
            </Button>
            <Button icon="undo" disabled={disableSave} onClick={onRevert}>
                Revert
            </Button>
        </div>
    );
};

interface RolesManagementFooterProps {
    disableSave: boolean;
    onClose: () => void;
    onRevert: () => void;
    onSave: () => void;
}
