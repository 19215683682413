import React, { memo, useRef, useCallback, useState, useEffect } from "react";
import { orderBy } from "lodash";
import { useResource } from "store/resources/useResource";
import { WarningMessage } from "components/ui/Message";
import DropDownInput from "components/ui/Input/DropDownInput";
import Separator from "components/ui/Separator";
import "./ApplicationBulkUpload.scss";
import JsonSchemaForm from "components/ui/Form/JsonSchema/JsonSchemaFormV2";
import DashboardPanel from "../DashboardPanel";
import { useDispatch } from "react-redux";
import { uploadFile } from "store/files/actions";
import StatusMsg from "components/ui/StatusMsg";
import GreenCheck from "assets/img/green-check.png";
import IconWithLabel from "../../../Icons/IconWithLabel";
import { hasAnyOfPermissions, systemUserRights } from "components/utils/user";
import { isEmpty } from "lodash";
import BulkUploadProgramsDropDown from "./BulkUploadProgramsDropDown";
import Label from "components/ui/Label";

const BulkUpload = memo(({ panel }) => {
    const [isUploading, setIsUploading] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [utilityNumber, setUtilityNumber] = useState(null);
    const [programNumber, setProgramNumber] = useState(null);
    const [noFile, setNoFile] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    const isStatusUpload = panel.type === "bulk-status-change-grouper";
    const directory = isStatusUpload ? "Bulk Application Status Upload Directory" : "Bulk Application Upload Directory";
    const resourceKey = isStatusUpload ? "utilitiesBulkStatusUpload" : "utilitiesBulkUpload";

    const [utilities, isLoadingUtilities] = useResource({
        resourceName: "utilityWithAttribute",
        key: resourceKey,
        path: {
            attribute: directory,
        },
        transform: (data) => {
            return orderBy(data?.utilityList || [], [(item) => item.utility], ["asc"]).map((i) => ({
                label: i.utility,
                value: i.utilityNumber,
            }));
        },
    });

    useEffect(() => {
        if (utilities?.length === 1) {
            setUtilityNumber(utilities[0].value);
        }
    }, [utilities]);

    const renderEmptyUtility = () => {
        return (
            <div className="application-form-warning-msg field-group">
                <Label labelRequired>Select Utility</Label>
                <WarningMessage>No utilities available for application {isStatusUpload ? "status" : ""} bulk upload.</WarningMessage>
            </div>
        );
    };

    const renderEmptyProgram = () => {
        return (
            <div className="application-form-warning-msg field-group">
                <Label labelRequired>Select Program</Label>
                <WarningMessage>
                    There are no programs configured for bulk upload regarding selected utility. Please choose another utility.
                </WarningMessage>
            </div>
        );
    };

    const formRef = useRef();

    const schema = {
        type: "object",
        required: ["file"],
        properties: {
            file: {
                title: "Select File",
                type: "array",
                items: {
                    type: "string",
                    format: "data-url",
                },
            },
        },
    };

    const uiSchema = {
        file: {
            "ui:widget": "DropZoneWidget",
            "ui:multiple": false,
            "ui:accept": ".xlsx, .xls",
        },
    };

    const dispatch = useDispatch();

    const onSuccess = useCallback(() => {
        setIsUploading(false);
        formRef.current.setFormData({ file: [] });
        setIsUploaded(true);
        setUtilityNumber(null);
        setProgramNumber(null);
    }, []);

    const onError = useCallback((message) => {
        setErrorMessage(message);
        setIsUploading(false);
    }, []);

    const hasPermission = hasAnyOfPermissions([systemUserRights.VISIONDSM_ADD_APPLICATION]);

    const onFormChange = (e) => {
        setNoFile(isEmpty(e.formData.file));
        setErrorMessage(null);
    };

    const onSubmit = useCallback(
        (file) => {
            if (file) {
                setIsUploading(true);
                dispatch(
                    uploadFile({
                        fileData: {
                            utilityNumber,
                            programNumber,
                            file,
                        },
                        resourceName: isStatusUpload ? "bulkApplicationStatusUpload" : "bulkApplicationUpload",
                        onUploadSuccess: onSuccess,
                        onUploadError: onError,
                    })
                );
            }
        },
        [dispatch, isStatusUpload, onError, onSuccess, programNumber, utilityNumber]
    );

    const onBack = () => {
        setIsUploaded(false);
    };
    const otherActions = (
        <div className="flex-column fill-width">
            <StatusMsg className="form-info-message" msgInfo>
                Please select an .xls or .xlsx file which doesn't exceed 30MB in size
            </StatusMsg>
            {errorMessage && (
                <StatusMsg className="form-error-message" msgFieldStatus msgError>
                    {errorMessage}
                </StatusMsg>
            )}
        </div>
    );

    return (
        <DashboardPanel title={panel.name} className="bulk-upload">
            {isUploaded && (
                <div className="fill-width fill-height flex-column justify-center align-center success-block">
                    <div className="flex-column align-center">
                        <img src={GreenCheck} alt="" height="80" width="80" />
                        <span className="result-text">Thank you. Your file has been queued for processing.</span>
                    </div>
                    <IconWithLabel onClick={onBack} className="margin-auto back-button" icon="shevron-small-left">
                        Do another Bulk {isStatusUpload ? "Status" : "Application"} Upload
                    </IconWithLabel>
                </div>
            )}
            {hasPermission && !isUploaded && (
                <div className="panel">
                    <div className="flex-row">
                        <div className="flex-column dropdown-wrapper">
                            {utilities.length || isLoadingUtilities ? (
                                <DropDownInput
                                    inline
                                    label="Select Utility"
                                    mobileHeader="Select Utility"
                                    placeholder={isLoadingUtilities ? "Loading..." : "Select Utility"}
                                    disabled={isLoadingUtilities}
                                    data={utilities}
                                    value={isLoadingUtilities ? undefined : utilityNumber}
                                    onChange={(a) => {
                                        setUtilityNumber(a.target.value);
                                        setProgramNumber(null);
                                    }}
                                    withPopper
                                    required
                                />
                            ) : (
                                renderEmptyUtility()
                            )}
                        </div>
                        <Separator vertical />
                        <div className="flex-column dropdown-wrapper">
                            {utilityNumber && (
                                <BulkUploadProgramsDropDown
                                    inline
                                    label={"Select Program"}
                                    mobileHeader={"Select Program"}
                                    placeholder={"Select Program"}
                                    utilityNumber={utilityNumber}
                                    program={programNumber}
                                    onChange={setProgramNumber}
                                    renderWhenEmpty={renderEmptyProgram()}
                                    directory={directory}
                                    resourceKey={resourceKey}
                                    withPopper
                                    required
                                />
                            )}
                        </div>
                    </div>
                    <Separator />
                    <div className="flex flex-wrap">
                        {programNumber && (
                            <JsonSchemaForm
                                key={programNumber}
                                formRef={formRef}
                                schema={schema}
                                uiSchema={uiSchema}
                                otherActions={otherActions}
                                onSubmit={(f) => onSubmit(f.file)}
                                onChange={onFormChange}
                                submitText={isUploading ? "Uploading..." : "Upload File"}
                                noReset
                                disabled={isUploading}
                                submitDisabled={noFile}
                            />
                        )}
                        <div className="flex-one spacer-helper"></div>
                    </div>
                </div>
            )}
            {!hasPermission && (
                <div className="flex-column full-width fill-height justify-center align-center">
                    <StatusMsg msgInfo>You are not permitted to use this functionality.</StatusMsg>
                </div>
            )}
        </DashboardPanel>
    );
});

export default BulkUpload;
