import { HeadingPropertyContext } from "components/ui/PortalBuilder/contexts";
import { PropertyName } from "components/ui/PortalBuilder/types";
import React, { useContext } from "react";
import { ColorSelectIcon } from "../../Property/ColorSelect/ColorSelectIcon";
import ColorSelectPanel from "../../Property/ColorSelect/ColorSelectPanel";
import { PropertyListItem } from "../../Property/PropertyListItem";
import { SelectPanelProperty } from "../../Property/SelectPanelProperty";
import { HeadingCustomPropertyName, HeadingTypes } from "./types";

export const ColorCommonProperty = (props) => {
    const { customPropertiesValue } = useContext(HeadingPropertyContext);

    if (customPropertiesValue[HeadingCustomPropertyName.HeadingColorType] !== HeadingTypes.Common) {
        return null;
    }

    return (
        <PropertyListItem borderBottom={false}>
            <SelectPanelProperty
                {...props}
                className="property-list-item--heading-color property-list-item--heading-color-common"
                id={PropertyName.HeadingColor}
                title={"H1, H2, H3, H4, H5, H6"}
                selectComponent={ColorSelectIcon}
                panelComponent={ColorSelectPanel}
                selectionTitle={"Common Text"}
            />
        </PropertyListItem>
    );
};
