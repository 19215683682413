import React, { memo, useState, useContext } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";

import { useAutoMaxHeight } from "components/utils/dom";
import { WindowContext } from "../Windows/Window";
import Panel from "./Panel/PanelRoot";
import TagButton from "../Button/TagButton";
import ViewPlaceholder from "../../ui/ViewPlaceholder";

import "./DashboardPanels.scss";

const DashboardPanels = memo(
    ({
        mainPanel,
        sidePanels,
        sidebarExpanded,
        useSidebar,
        tempPanel,
        onUnpin,
        onExpand,
        onToggleSidebar,
        onToggleTempPanel,
        onToggleSettings,
    }) => {
        const [sidebarRef, setSidebarRef] = useState();
        const { isSplitView } = useContext(WindowContext);
        const isDesktop = useSelector((state) => state.window?.isDesktop);

        sidePanels.sort(function (a, b) {
            return a.order - b.order;
        });

        const containerClass = cn("dashboard-panels flex-column fill-height", {
            "with-sidebar": useSidebar,
            "sidebar-expanded": useSidebar && sidebarExpanded,
        });

        const sidebarClass = cn("small-area panel-column", {
            hidden: !sidebarExpanded,
        });

        useAutoMaxHeight({
            elementRef: sidebarRef,
            offsetHeight: 60,
            enabled: useSidebar && isDesktop && !isSplitView,
        });

        return (
            <div className={containerClass}>
                {useSidebar && (
                    <div className="panel-controls">
                        {sidePanels.length > 0 && (
                            <TagButton
                                edit
                                notRemovable
                                className={sidebarExpanded ? "rotate" : ""}
                                text={sidebarExpanded ? "Hide Sidebar" : "Show Sidebar"}
                                onFullItemClick={onToggleSidebar}
                            />
                        )}
                    </div>
                )}
                <div className="panel-areas fill-height">
                    <div
                        className={
                            "main-area panel-column fill-height" + (sidebarExpanded && sidePanels.length > 0 ? " main-area-hidden" : "")
                        }
                    >
                        {tempPanel}
                        {!tempPanel && mainPanel && (
                            <Panel panel={mainPanel} onUnpin={onUnpin} onExpand={onExpand} onToggleTempPanel={onToggleTempPanel} />
                        )}
                        {!mainPanel && (
                            <ViewPlaceholder
                                clickableText="click here to configure widgets"
                                clickableTextIcon="settings-empty"
                                onClick={onToggleSettings}
                            >
                                Please select some widgets
                            </ViewPlaceholder>
                        )}
                    </div>
                    {useSidebar && (
                        <div ref={setSidebarRef} className={sidebarClass} hidden={!sidebarExpanded}>
                            {sidePanels.map((panel) => (
                                <Panel
                                    key={panel.id}
                                    panel={panel}
                                    onUnpin={onUnpin}
                                    onExpand={onExpand}
                                    onToggleSidebar={onToggleSidebar}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

export default DashboardPanels;
