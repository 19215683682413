import React, { useCallback, useContext, memo, useState, useMemo } from "react";

import { stripHtml } from "../../../../utils/string";
import { isAutomated, isWorkflowTaskDependent } from "../../utils";
import { isTaskReadOnly, isAdhocTask } from "../../../../utils/workflow";

import IconWrap from "../../../Icons";
import StatusMark from "../../../StatusMark";
import { TaskWorkflowContext } from "../../context/TaskWorkflowContext";
import { TaskIndicator } from "../TaskIndicator";
import WaitIcon from "../../../WaitIcon";
import { TaskDates } from "./TaskDates";

import "./Task.scss";

export const Task = memo(({ item }) => {
    const { isLocked, onChangeTaskOwnership, hasAddTaskRights } = useContext(TaskWorkflowContext);
    const { stepType, step, userOwner, wfTaskNumber } = item;
    const isTakeTaskDisabled = isLocked || !hasAddTaskRights;

    const [isUpdatingTask, setIsUpdatingTask] = useState(false);

    const handleChangeTaskOwnership = useCallback(
        (e) => {
            e.stopPropagation();

            setIsUpdatingTask(true);
            onChangeTaskOwnership(item, () => {
                setIsUpdatingTask(false);
            });
        },
        [item, onChangeTaskOwnership]
    );

    const flagTitle = useMemo(() => {
        if (isLocked) {
            return "";
        }

        if (isWorkflowTaskDependent(item)) {
            return "Dependent Task";
        }

        return userOwner ? "Un-take Task" : "Take Task";
    }, [isLocked, item, userOwner]);

    const isReadonly = isTaskReadOnly(item);
    const isAdhoc = isAdhocTask(item);

    return (
        <div id={wfTaskNumber} className="workflow-task flex-row fill-width">
            {!isAdhoc && !isAutomated(item) && !isWorkflowTaskDependent(item) && (
                <StatusMark statusMarkSmall statusMarkTheme>
                    Task
                </StatusMark>
            )}
            {isAdhoc && (
                <StatusMark statusMarkSmall statusMarkThemeBase>
                    Ad-hoc Task
                </StatusMark>
            )}
            {isAutomated(item) && (
                <StatusMark statusMarkSmall statusMarkThemeDark>
                    Automated
                </StatusMark>
            )}
            {isWorkflowTaskDependent(item) && (
                <StatusMark statusMarkSmall statusMarkDisabled>
                    Dependent
                </StatusMark>
            )}
            <div className="step-title flex-one">
                <div className="step-type">{stepType}</div>
                <div className="task-indicators-wrap flex-row">
                    {!isAdhoc && !isAutomated(item) && !isWorkflowTaskDependent(item) && <TaskIndicator className="task" title="Task" />}
                    {isAdhoc && <TaskIndicator className="ad-hoc" title="Ad-hoc Task" />}
                    {isAutomated(item) && <TaskIndicator className="automated" title="Automated Task" />}
                    {isWorkflowTaskDependent(item) && <TaskIndicator className="dependent" title="Dependent Task" />}
                </div>
                <div className="step-name">{stripHtml(step)}</div>
            </div>
            <TaskDates task={item} />
            <div className="step-assignments flex-row align-center justify-space-between">
                <Assignment userOwner={userOwner} isAutomatedTask={isAutomated(item)} />
                {!isAutomated(item) && !isReadonly && (
                    <>
                        {isUpdatingTask ? (
                            <WaitIcon />
                        ) : (
                            <div className="flex-row flex-one justify-end">
                                <IconWrap
                                    disabled={isTakeTaskDisabled || isWorkflowTaskDependent(item)}
                                    icon={userOwner ? "flag-filled" : "flag-empty"}
                                    title={flagTitle}
                                    onClick={handleChangeTaskOwnership}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className="task-indicators-wrap top-right-corner flex-row align-start">
                {!isAdhoc && !isAutomated(item) && !isWorkflowTaskDependent(item) && <TaskIndicator className="task" title="Task" />}
                {isAdhoc && <TaskIndicator className="ad-hoc" title="Ad-hoc Task" />}
                {isAutomated(item) && <TaskIndicator className="automated" title="Automated Task" />}
                {isWorkflowTaskDependent(item) && <TaskIndicator className="dependent" title="Dependent Task" />}
            </div>
        </div>
    );
});

const Assignment = memo(({ userOwner, isAutomatedTask }) => {
    if (isAutomatedTask) {
        return (
            <div className="step-assignment step-automated flex-row align-center">
                <div>
                    Automated
                    <br />
                    Task
                </div>
                <IconWrap iconWrapBig iconWrap="restore-update"></IconWrap>
            </div>
        );
    }

    if (userOwner) {
        return (
            <div className="step-assignment">
                <div className="col-label">Owned By:</div>
                <div className="col-content">{userOwner}</div>
            </div>
        );
    }
});
