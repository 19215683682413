import BaseInput from "./BaseInput";
import TextareaWidget from "./TextareaWidget";
import CheckboxWidget from "./CheckboxWidget";
import CheckboxesWidget from "./CheckboxesWidget";
import RadioWidget from "./RadioWidget";
import SelectWidget from "./SelectWidget";
import DatePickerWidget from "./DatePickerWidget";
import DropZoneWidget from "./DropZoneWidget";
import HtmlEditorWidget from "./HtmlEditorWidget";
import ViewOnlyWidget from "./ViewOnlyWidget";
import ContentHeaderWidget from "./ContentHeaderWidget";
import StatesWidget from "./StatesWidget";
import AdditionalContactsWidget from "./AdditionalContactsWidget";
import ApplicationContactsWidget from "./ApplicationContactsWidget";
import WorkflowWidget from "./WorkflowWidget";
import { RebateTotalGridWidget } from "./RebateTotalGridWidget";
import EqipmentRecommsWidget from "./EqipmentRecommsWidget";
import ContractorLookupListWidget from "./ContractorLookupListWidget";
import ApplicationNameWidget from "./ApplicationNameWidget";
import EquipmentBlockWidget from "./EquipmentBlockWidget";
import AuditEquipmentBlockWidget from "./AuditEquipmentBlockWidget";
import AuditResultWidget from "./AuditResultWidget";
import AuditWorkorderWidget from "./AuditWorkorderWidget";
import GeneralProcedureWidget from "./GeneralProcedureWidget";
import DictionaryWidget from "./DictionaryWidget";
import DateRangeValidationWidget from "./DateRangeValidationWidget";
import FormWizardTargetWidget from "./FormWizardTargetWidget";
import DefaultDateWidget from "./DefaultDateWidget";
import FieldFormatWidget from "./FieldFormatWidget";
import InputWithFastTags from "./InputWithFastTags";
import ConditionalValidationRuleWidget from "./ConditionalValidationRuleWidget";
import ImageWidget from "./ImageWidget.js";
import ImagePropertiesWidget from "./ImagePropertiesWidget";
import SignatureBlockWidget from "./SignatureBlockWidget";

export const widgets = {
    BaseInput,
    TextareaWidget,
    CheckboxWidget,
    CheckboxesWidget,
    RadioWidget,
    SelectWidget,
    DropZoneWidget,
    HtmlEditorWidget,
    ViewOnlyWidget,
    ContentHeaderWidget,
    date: DatePickerWidget,
    file: DropZoneWidget,
    html: HtmlEditorWidget,
    state: StatesWidget,
    readonlyvalue: ViewOnlyWidget,
    rawhtml: ViewOnlyWidget,
    statement: ViewOnlyWidget,
    nontextspacer: ViewOnlyWidget,
    defaultdate: DefaultDateWidget,
    spacer: ViewOnlyWidget,
    dictionary: DictionaryWidget,
    largecheckbox: CheckboxWidget,
    largecheckboxes: CheckboxesWidget,
    daterangevalidation: DateRangeValidationWidget,
    fieldformat: FieldFormatWidget,
    formwizardtarget: FormWizardTargetWidget,
    InputWithFastTags,
    ConditionalValidationRuleWidget,

    // custom widgets
    additionalcontacts: AdditionalContactsWidget,
    applicationcontacts: ApplicationContactsWidget,
    applicationname: ApplicationNameWidget,
    equipmentblock: EquipmentBlockWidget,
    auditequipmentblock: AuditEquipmentBlockWidget,
    auditresult: AuditResultWidget,
    auditresults: AuditResultWidget,
    auditworkorder: AuditWorkorderWidget,
    contractorlookuplist: ContractorLookupListWidget,
    eqipmentrecomms: EqipmentRecommsWidget,
    rebatetotalgrid: RebateTotalGridWidget,
    workflow: WorkflowWidget,
    generalprocedure: GeneralProcedureWidget,
    image: ImageWidget,
    imageProperties: ImagePropertiesWidget,
    signature: SignatureBlockWidget,
};
