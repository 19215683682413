import React, { memo } from "react";
import { PropertyListItem } from "../PropertyListItem";
import { TextProperty } from "../TextProperty";
import { ValidationRule } from "components/ui/PortalBuilder/types";

export const ImageAltTextProperty = memo((props) => {
    const { title, value, onChange, listContainsActiveError, errors = {} } = props;

    if (!value.src) {
        return null;
    }

    return (
        <PropertyListItem className="property-list-item--image-alt-text">
            <TextProperty
                title={title}
                errors={errors.altText}
                id="altText"
                value={value.altText}
                fullWidth={true}
                defaultValue=""
                borderBottom={false}
                onChange={onChange}
                listContainsActiveError={listContainsActiveError}
                validationRules={[ValidationRule.Required]}
                infoIconText="Alt text is the written copy that appears in place of an image on a webpage if the image fails to load on a user's screen"
            />
        </PropertyListItem>
    );
});
