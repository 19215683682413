import React, { memo } from "react";

import { exportDatagridToCSV } from "../../utils/CSV";

import Grid from "./Grid";
import IconWithLabel from "../../ui/Icons/IconWithLabel";

import "./style.scss";

const UtilitiesAndProgramManagementCatalog = memo(() => {
    const gridId = "global-catalog";

    return (
        <div className="global-catalog panel sticky-grid-list-panel">
            <div className="main-grid-wrap responsive flex-column fill-height">
                <div className="data-grid-controls flex-row justify-end">
                    <IconWithLabel
                        withHandMadeIcon
                        onClick={() =>
                            exportDatagridToCSV({
                                dataGridId: gridId,
                                fileName: "catalog",
                            })
                        }
                    >
                        Export CSV
                    </IconWithLabel>
                </div>
                <Grid gridId={gridId} />
            </div>
        </div>
    );
});

export default UtilitiesAndProgramManagementCatalog;
