import React, { useCallback, memo, useMemo, useContext } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import { toArray } from "../../utils/array";
import { openNewApplication } from "../../utils/window";
import { openSearchResult } from "../../../store/globalSearch/actions";
import { entityType, getCisUtilityNumber, createApplication, getUtilityNumber, isCisSearch } from "../../../store/globalSearch/utils";

import IconWrap from "../Icons";
import Highlighter from "react-highlight-words";
import CisSearchDetails from "../CIS/CisSearchDetails";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import { sideNavSize, sideNavKey } from "components/ui/SideNav/SideNavRoot";
import { WindowContext } from "../Windows/Window";

import "./SearchResultsItem.scss";

const SearchResultsItem = memo(({ table, row, tableIndex, rowIndex, words, criteriaList, isCisSecondarySearch }) => {
    const dispatch = useDispatch();

    const isCis = isCisSearch({ criteriaList }) && !isCisSecondarySearch;

    const showOpenAction = !isCis;
    const entityTypeCustomer = row.ENTITY_TYPE === entityType.customer;
    const showCustomerActions = row.ENTITY_TYPE === entityType.customer;
    const showApplicationStandardBlock = row.ENTITY_TYPE === entityType.application;

    const { isSplitView } = useContext(WindowContext);
    const key = isSplitView ? undefined : sideNavKey.globalRight;

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({ sideNavKey: key, size: sideNavSize.medium });

    const onOpenResult = useCallback(() => {
        dispatch(
            openSearchResult({
                result: row,
            })
        );
    }, [row, dispatch]);

    const onCreateApplication = useCallback(() => {
        if (isCis) {
            const utilityNumber = getCisUtilityNumber({ criteriaList });
            const accountNumber = toArray(row.FIELD)
                .filter((f) => f.TITLE === "ACCOUNT #")
                .map((f) => f.VALUE)[0];
            const customerID = row.ENTITYID;

            createApplication({ utilityNumber, accountNumber, customerID });
        } else {
            const utilityNumber = getUtilityNumber({ searchResult: row });
            const customerNumber = row.ENTITYID;

            openNewApplication({
                utilityNumber,
                customerNumber,
                reset: true,
            });
        }
    }, [row, criteriaList, isCis]);

    const onShowCisDetails = useCallback(() => {
        //        setShowCisDetails(true);
        const utilityNumber = getCisUtilityNumber({ criteriaList });
        const customerID = row.ENTITYID;
        const dataItem = {
            ID: customerID,
        };
        handleOpenSidePanel(
            <CisSearchDetails cisDetailsInSideNavPanel utilityNumber={utilityNumber} dataItem={dataItem} onClose={handleCloseSidePanel} />
        );
    }, [criteriaList, handleCloseSidePanel, handleOpenSidePanel, row.ENTITYID]);

    const fields = useMemo(() => {
        const excludedItems = ["Utility Number"];

        return toArray(row.FIELD).filter((f) => !excludedItems.includes(f.TITLE));
    }, [row]);

    const fieldsTitle = ["Utility", "Program", "Project #", "Application Status", "Contact Name"];

    const rowTitle = fields
        .filter((i) => fieldsTitle.includes(i.TITLE))
        .sort((a, b) => fieldsTitle.findIndex((i) => i === a.TITLE) - fieldsTitle.findIndex((i) => i === b.TITLE));
    const rowOther = showApplicationStandardBlock ? fields.filter((i) => !fieldsTitle.includes(i.TITLE)) : fields;

    return (
        <div className="search-results__item flex-row" tabIndex={0}>
            <div className="search-results__table flex-column justify-center">
                <span>{table.TITLE}</span>
            </div>
            <div className="search-results__item-wrap flex-one">
                <div className="flex-column">
                    {showApplicationStandardBlock && (
                        <div className="search-results__header flex-row flex-wrap">
                            {toArray(rowTitle).map((field, fieldIndex) => (
                                <SearchResultField
                                    key={`${tableIndex}-${rowIndex}-${fieldIndex}`}
                                    field={field}
                                    table={table}
                                    words={words}
                                />
                            ))}
                        </div>
                    )}
                    <div className="search-results__data flex-one">
                        <div className={"search-results__fields flex-row flex-wrap" + (entityTypeCustomer ? " cis-search" : "")}>
                            {toArray(rowOther).map((field, fieldIndex) => (
                                <SearchResultField
                                    key={`${tableIndex}-${rowIndex}-${fieldIndex}`}
                                    field={field}
                                    table={table}
                                    words={words}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="search-results__item-actions flex-column justify-space-between">
                    <div className="flex-column">
                        {showOpenAction && <IconWrap icon="open-new-window" title="Open" onClick={onOpenResult} />}
                        {showCustomerActions && (
                            <div className="search-results__item-actions--additional flex-column">
                                {isCis && (
                                    <IconWrap icon="eye-visibility-empty" onClick={onShowCisDetails} title="View Customer Information" />
                                )}
                                <IconWrap icon="plus" onClick={onCreateApplication} title="Create Application" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});

const SearchResultField = ({ field, table, words }) => {
    return (
        <div className="search-results__field flex-column">
            <span className="search-results__field__title">{field.TITLE}:</span>
            <Highlighter
                className={cn("search-results__field__value", {
                    "file-name": field.TITLE === "File Name",
                    "customer-email": field.TITLE === "Customer Email",
                })}
                searchWords={words}
                textToHighlight={field.VALUE || ""}
                autoEscape
            />
        </div>
    );
};

export default SearchResultsItem;
