import React from "react";
import cn from "classnames";

import useFocusClasses from "../../../utils/useFocusClasses";
import useEnterKey from "../../../utils/useEnterKey";

import IconWrap from "../../Icons";
import { Tab } from ".";

const TabListItem = ({ className, item, active, onClick, equalItemsWidth, hasData, disabledItemTooltip, disabled }: TabListItemProps) => {
    const isTabable = true;
    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled: !isTabable,
    });
    const [onEnter] = useEnterKey({
        disabled: !isTabable,
        onClick: () => onClick && onClick(item),
    });

    return (
        <div
            className={cn("tab", className, {
                "equal-width": equalItemsWidth,
                active: active,
                "form-with-data": hasData,
                disabled: disabled,
            })}
            tabIndex={0}
            onClick={() => onClick && onClick(item)}
            onFocus={onFocusClassesFocus}
            onBlur={onFocusClassesBlur}
            onKeyDown={onEnter}
            title={hasData ? "Form has data" : ""}
        >
            {item.title}
            {item.disabled && <IconWrap iconWrapSmall icon="block-not-interested-empty" title={disabledItemTooltip} />}
        </div>
    );
};

interface TabListItemProps {
    className?: string;
    item: Tab;
    active: boolean;
    onClick?: (tab: Tab) => void;
    equalItemsWidth?: boolean;
    hasData?: boolean;
    disabled?: boolean;
    disabledItemTooltip?: string;
}

export default TabListItem;
