export enum ButtonSize {
    Small = "btn-sm",
    Normal = "btn-m",
    Large = "btn-lg",
}

export enum ButtonCornerRadius {
    Radius1 = "0.25rem",
    Radius2 = "0.5rem",
    Radius3 = "0.75rem",
    Radius4 = "1rem",
    Radius5 = "2rem",
}

export enum ButtonCustomPropertyName {
    ButtonSize = "buttonSize",
    ButtonCornerRadius = "buttonCornerRadius",
}

export enum GhostButtonTextCustomPropertyName {
    GhostButtonTextColorDefault = "ghostButtonTextColorDefault",
    GhostButtonTextColorMouseOver = "ghostButtonTextColorMouseOver",
    GhostButtonTextColorFocusActive = "ghostButtonTextColorFocusActive",
    GhostButtonTextColorDisabled = "ghostButtonTextColorDisabled",
}

export enum ButtonBackgroundCustomPropertyName {
    PrimaryButtonBackgroundColorDefault = "primaryButtonBackgroundColorDefault",
    PrimaryButtonBackgroundColorMouseOver = "primaryButtonBackgroundColorMouseOver",
    PrimaryButtonBackgroundColorFocusActive = "primaryButtonBackgroundColorFocusActive",
    PrimaryButtonBackgroundColorDisabled = "primaryButtonBackgroundColorDisabled",

    GhostButtonBackgroundColorMouseOver = "ghostButtonBackgroundColorMouseOver",
    GhostButtonBackgroundColorFocusActive = "ghostButtonBackgroundColorFocusActive",

    FilledButtonBackgroundColorDefault = "filledButtonBackgroundColorDefault",
    FilledButtonBackgroundColorMouseOver = "filledButtonBackgroundColorMouseOver",
    FilledButtonBackgroundColorFocusActive = "filledButtonBackgroundColorFocusActive",
    FilledButtonBackgroundColorDisabled = "filledButtonBackgroundColorDisabled",
}

export enum ButtonBorderCustomPropertyName {
    PrimaryButtonBorderWidth = "primaryButtonBorderWidth",
    PrimaryButtonBorderColor = "primaryButtonBorderColor",
    PrimaryButtonBorderColorOpacity = "primaryButtonBorderColorOpacity",

    GhostButtonBorderWidth = "ghostButtonBorderWidth",
    GhostButtonBorderColorDefault = "ghostButtonBorderColorDefault",
    GhostButtonBorderColorMouseOver = "ghostButtonBorderColorMouseOver",
    GhostButtonBorderColorFocusActive = "ghostButtonBorderColorFocusActive",
    GhostButtonBorderColorDisabled = "ghostButtonBorderColorDisabled",

    FilledButtonBorderWidth = "filledButtonBorderWidth",
    FilledButtonBorderColorDefault = "filledButtonBorderColorDefault",
    FilledButtonBorderColorMouseOver = "filledButtonBorderColorMouseOver",
    FilledButtonBorderColorFocusActive = "filledButtonBorderColorFocusActive",
    FilledButtonBorderColorDisabled = "filledButtonBorderColorDisabled",
}
