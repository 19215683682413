import { get } from "lodash";
import React from "react";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";

import "./SelectPanelSubTitle.scss";

export const SelectPanelSubTitle = ({ content }: SelectPanelSubTitleProps) => {
    const [editingPageIndex] = usePortalBuilderState((state) => state.editingPageIndex);
    const [updatedConfig] = usePortalBuilderState((state) => state.updatedConfig);
    const page = get(updatedConfig, `content.pages[${editingPageIndex}]`);

    let pageLabel = undefined;

    if (page) {
        pageLabel = (
            <>
                <span>
                    EDIT <strong className="page-title">{page.title}</strong> PAGE
                </span>
                <br />
            </>
        );
    }

    return (
        <div className="select-panel-subtitle">
            {pageLabel}
            {content}
        </div>
    );
};

interface SelectPanelSubTitleProps {
    content: React.ReactNode | string;
}
