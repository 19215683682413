import React, { useState, useCallback, memo, useRef, useEffect } from "react";

import { useResource, useBudgetLines } from "../../../../../../store/resources/useResource";
import { listToAnyOf, submitResource, regexPatterns, submitByRef, pickInitialValues } from "../../../../../utils/form";
import { transformFormErrors, getBudgetLineFullName } from "./utils";

import WaitIcon from "../../../../WaitIcon";
import JsonSchemaFormWithConditionals from "./../../../../Form/JsonSchema/JsonSchemaFormWithConditionals";
import SideNavContent from "components/ui/SideNav/SideNavContent";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import SideNavFooter from "components/ui/SideNav/SideNavFooter";
import Button from "components/ui/Button";

const Form = memo((props) => {
    const formRef = useRef();

    const { utilityNumber, contractNumber, dataItem, addChild, onClose, gridRefresh, sidePanel } = props;

    const isNew = dataItem === undefined;
    const isCreateForm = isNew || addChild;

    const resourceId = isCreateForm ? null : dataItem.lookupNumber;

    const [isSubmitting, setSubmitting] = useState(false);

    const [resource, isLoading] = useResource({
        resourceName: "utilitiesContractsBudgetLookups",
        resourceId,
        path: {
            utilityNumber,
            contractNumber,
        },
        forced: true,
    });

    const initialValues = isCreateForm ? {} : pickInitialValues(resource);

    const [budgetLines = [], isLoadingBudgetLines] = useBudgetLines({
        utilityNumber,
        contractNumber,
        forced: true,
    });

    const parentLookupNumber = isNew ? null : addChild ? dataItem.lookupNumber : dataItem.parentLookupNumber;

    const title = isNew ? "Add Lookup" : addChild ? "Add Child Lookup" : "Edit Lookup";

    const titleIcon = isNew || addChild ? "plus" : "edit-empty";
    const submitText = isSubmitting ? "Saving..." : "Save";

    const schema = {
        type: "object",
        required: ["displayValue", "displayLabel", "storedValue"],
        properties: {
            displayValue: {
                type: "string",
                title: "Display Value",
                pattern: regexPatterns.nonEmptyString,
            },
            displayLabel: {
                type: "string",
                title: "Display Label",
                pattern: regexPatterns.nonEmptyString,
            },
            storedValue: {
                type: "string",
                title: "Stored Value",
                pattern: regexPatterns.nonEmptyString,
            },
            budgetLineNumber: {
                type: "string",
                title: "Budget Line",
                anyOf: listToAnyOf({
                    list: budgetLines,
                    map: (i) => ({
                        title: getBudgetLineFullName({
                            budgetLines,
                            budgetLineNumber: i.budgetLineNumber,
                        }),
                        enum: [i.budgetLineNumber],
                    }),
                }),
            },
        },
    };

    const uiSchema = {
        budgetLineNumber: {
            "ui:disabled": budgetLines.length === 0,
            "ui:placeholder": "No Budget Line",
            "ui:emptyItem": "No Budget Line",
        },
    };

    const onSubmit = useCallback(
        (formData) => {
            const resourceParams = {
                resourceName: "utilitiesContractsBudgetLookups",
                path: {
                    utilityNumber,
                    contractNumber,
                },
            };

            const body = {
                ...formData,
                parentLookupNumber,
            };

            submitResource({
                resourceParams,
                resourceId,
                body,
                onRefresh: gridRefresh,
                onSuccess: sidePanel.close,
                setSubmitting,
            });
        },
        [utilityNumber, contractNumber, parentLookupNumber, resourceId, gridRefresh, sidePanel]
    );

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    if (isLoading || isLoadingBudgetLines) {
        return <WaitIcon />;
    }

    return (
        <SideNavContent>
            <SideNavHeader title={title} leadBlockIcon={titleIcon} smallHeader onClose={onClose} />
            <SideNavBody className="flex-one-in-column">
                <JsonSchemaFormWithConditionals
                    formRef={formRef}
                    schema={schema}
                    uiSchema={uiSchema}
                    initialValues={initialValues}
                    disabled={isSubmitting}
                    onSubmit={onSubmit}
                    transformErrors={transformFormErrors}
                    noSubmit
                    noCancel
                    noReset
                    noActions
                />
            </SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button primary onClick={() => submitByRef(formRef)} disabled={isSubmitting}>
                    {submitText}
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default Form;
