import React from "react";
import ClassNames from "classnames";

import "./style.scss";

const TextField = (props) => {
    const classes = ClassNames(props.className, {
        error: props.error,
        focus: props.focus,
        "textfield-read-only": props.readOnly,
        "light-up-error": props.lightUpError,
    });

    const { className, error, focus, inputRef, lightUpError, ...rest } = props;

    return <textarea ref={inputRef} className={classes} {...rest} onFocus={props.onFocus}></textarea>;
};

export default TextField;
