import React, { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { isEqual } from "lodash";
import { useCalculationStatus } from "components/views/ProjectView/utils";
import ActivityIcon from "components/ui/ActivityIcon";
import { windowSetNotificationVisible } from "store/window/actions";

import "./WindowHeaderNotification.scss";

const WindowHeaderNotification = memo(({ view }) => {
    const { component } = view;
    let notification = null;

    switch (component) {
        case "ProjectView":
            notification = <WindowHeaderNotificationForProject view={view} />;
            break;

        default:
            notification = null;
            break;
    }

    return notification;
}, isEqual);

const WindowHeaderNotificationForProject = memo(({ view }) => {
    const applicationNumber = view.props.applicationNumber;
    const [isCalculationsInProgress] = useCalculationStatus({
        applicationNumber,
    });

    useNotificationVisible({
        viewName: view.name,
        isVisible: isCalculationsInProgress,
    });

    if (isCalculationsInProgress) {
        return (
            <NotificationContainer>
                <div className="window-header-notification--activity flex-row align-center">
                    <ActivityIcon />
                    <p>Calculations are currently queued for this application. Please wait…</p>
                </div>
            </NotificationContainer>
        );
    }

    return null;
});

const NotificationContainer = ({ children }) => {
    return (
        <div className="window-header-notification">
            <div className="main-grid-wrap responsive">{children}</div>
        </div>
    );
};

const useNotificationVisible = ({ viewName, isVisible }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            windowSetNotificationVisible({
                viewName,
                isNotificationVisible: isVisible,
            })
        );
    }, [viewName, isVisible, dispatch]);
};

export default WindowHeaderNotification;
