import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import Checkbox from "../../../Input/Checkbox";
import { stripHtml } from "../../../../utils/string";
import TextInput from "components/ui/Input/TextInput";

import "./CheckboxesWidget.scss";

function selectValue(value, selected, all) {
    const at = all.indexOf(value);
    const updated = selected.slice(0, at).concat(value, selected.slice(at));
    // As inserting values at predefined index positions doesn't work with empty
    // arrays, we need to reorder the updated selection to match the initial order
    return updated.sort((a, b) => all.indexOf(a) > all.indexOf(b));
}

function deselectValue(value, selected) {
    const result = selected.filter((v) => v !== value);
    // Do not return empty array if nothing is selected. This breaks "required" validation.
    return result.length === 0 ? undefined : result;
}

function CheckboxesWidget(props) {
    const { id, disabled, options, value, autofocus, readonly, onChange } = props;
    const { enumOptions, enumDisabled = [], inline, shouldDisplayFilter, enableSelectAll } = options;
    const [filterValue, setFilterValue] = useState("");

    const onFilterChange = (e) => {
        setFilterValue(e.target.value);
    };

    const onIconClick = () => {
        setFilterValue("");
    };

    const filteredOptions = enumOptions.filter((o) => o.label.toLowerCase().includes(filterValue.toLowerCase()));

    const selectAllState = useMemo(() => {
        const itemCount = filteredOptions?.length ?? 0;
        const selectedItemCount = value?.length ?? 0;

        let state = false;
        if (selectedItemCount > 0) {
            state = true;

            if (selectedItemCount !== itemCount) {
                state = null;
            }
        }

        return state;
    }, [filteredOptions, value]);

    const onSelectAllClick = useCallback(
        (event) => {
            event && event.stopPropagation();

            let values = null;

            // deselect all
            if (selectAllState) {
                values = [];
            }
            // select all
            else {
                values = filteredOptions.map((i) => i.value);
            }

            if (onChange) {
                onChange(values);
            }
        },
        [filteredOptions, selectAllState, onChange]
    );
    return (
        <>
            {shouldDisplayFilter && enumOptions.length >= 5 && (
                <div className="checkbox-filter">
                    <TextInput
                        placeholder="Filter List"
                        iconRight
                        disabled={disabled}
                        icon={filterValue.length === 0 ? "search" : "clear-close"}
                        iconTitle={filterValue.length === 0 ? "" : "Clear Filter"}
                        value={filterValue}
                        onChange={onFilterChange}
                        onIconClick={filterValue.length === 0 ? undefined : onIconClick}
                    />
                </div>
            )}
            <div className="checkboxes" id={id}>
                {enableSelectAll && enumOptions.length > 3 && (
                    <div className="checkboxes--select-all">
                        <Checkbox label="Select All" checked={selectAllState} onChange={onSelectAllClick} />
                    </div>
                )}
                {filteredOptions.map((option, index) => {
                    const isDisabled = enumDisabled.find((i) => i.value === option.value) ? true : false;
                    const tooltip = enumDisabled.find((i) => i.value === option.value)?.tooltip;
                    const checked = value && value.indexOf(option.value) !== -1;
                    const disabledCls = isDisabled || disabled || readonly ? "disabled" : "";
                    const parsedLabel = stripHtml(option.label);
                    const checkbox = (
                        <Checkbox
                            id={`${id}_${index}`}
                            title={tooltip}
                            checked={checked && !isDisabled}
                            disabled={isDisabled || disabled || readonly}
                            autoFocus={autofocus && index === 0}
                            onChange={(event) => {
                                const all = enumOptions.map(({ value }) => value);
                                if (event.target.checked) {
                                    onChange(selectValue(option.value, value, all));
                                } else {
                                    onChange(deselectValue(option.value, value));
                                }
                            }}
                            label={parsedLabel}
                        />
                    );

                    return inline ? (
                        <div key={index} className={`checkbox-inline ${disabledCls}`}>
                            {checkbox}
                        </div>
                    ) : (
                        <div key={index} className={`checkbox ${disabledCls}`}>
                            {checkbox}
                        </div>
                    );
                })}
            </div>
        </>
    );
}

CheckboxesWidget.defaultProps = {
    autofocus: false,
    options: {
        inline: false,
    },
};

if (process.env.NODE_ENV !== "production") {
    CheckboxesWidget.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        options: PropTypes.shape({
            enumOptions: PropTypes.array,
            inline: PropTypes.bool,
        }).isRequired,
        value: PropTypes.any,
        required: PropTypes.bool,
        readonly: PropTypes.bool,
        disabled: PropTypes.bool,
        multiple: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
    };
}

export default CheckboxesWidget;
