import React, { useState } from "react";

import { createResource } from "../../../../store/resources/actions";
import JsonSchemaForm from "../../../ui/Form/JsonSchema/JsonSchemaForm";
import InlinePanelHeader from "../../../ui/Dashboard/Panel/InlinePanelHeader";
import { getData } from "../../../../store/dataGrid/actions";

const ProductAttributesForm = (props) => {
    const { onClose, dispatch, gridId } = props;

    const schema = {
        type: "object",
        required: ["description", "shortName"],
        properties: {
            description: {
                type: "string",
                title: "Attribute",
            },
            shortName: {
                type: "string",
                title: "Short Name",
            },
        },
    };

    const uiSchema = {
        classNames: "inline-form",
    };

    const [isSubmitting, setSubmitting] = useState(false);

    const onSubmit = (formData) => {
        setSubmitting(true);

        dispatch(
            createResource({
                resourceName: "productAttributes",
                body: {
                    ...formData,
                },
                onSuccess,
                onComplete: () => setSubmitting(false),
            })
        );
    };

    const onSuccess = () => {
        onClose();
        dispatch(
            getData({
                dataGridId: gridId,
            })
        );
    };

    const getSubmitText = (isSubmitting) => {
        return isSubmitting ? "Adding..." : "Add";
    };

    return (
        <>
            <InlinePanelHeader title="Add New Attribute" onClose={onClose} />

            <JsonSchemaForm
                schema={schema}
                uiSchema={uiSchema}
                onSubmit={onSubmit}
                submitText={getSubmitText(isSubmitting)}
                disabled={isSubmitting}
                onCancel={onClose}
                withCancel
                noReset
            />
        </>
    );
};

export default ProductAttributesForm;
