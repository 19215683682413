import React, { useCallback, memo } from "react";

import { useContracts } from "../../../../../../store/resources/useResource";
import { openContractTab } from "../../../../../utils/window";

import TileItem from "../../../../TilesWrap/TileItem";
import CustomList, { renderCustomFooter } from "../../../../List/CustomList";
import IconWrap from "../../../../Icons";
import WaitIcon from "../../../../WaitIcon";
import IconWithLabel from "../../../../Icons/IconWithLabel";
import NothingFoundBlock from "../../../../NothingFoundBlock";
import Form from "./Form";

import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import { refreshContracts } from "../../../../../../store/resources/refreshResource";
import { ListHeader } from "../../../../List/ListHeader";

const ContractList = memo(({ utilityNumber, onEditContract }) => {
    const [contracts, isLoadingContracts] = useContracts({ utilityNumber });

    const handleOpenContract = useCallback(
        (item) => {
            openContractTab({
                utilityNumber,
                contractNumber: item.contractNumber,
                contractDescription: item.contractDesc,
            });
        },
        [utilityNumber]
    );

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "contract-sidenav-panel",
    });

    const handleRefresh = useCallback(() => {
        refreshContracts({ utilityNumber });
    }, [utilityNumber]);

    const handleEditContract = useCallback(
        (contractNumber) => {
            handleOpenSidePanel(
                <Form
                    utilityNumber={utilityNumber}
                    contractNumber={contractNumber}
                    onSaved={handleRefresh}
                    onClose={handleCloseSidePanel}
                />
            );
        },
        [utilityNumber, handleRefresh, handleOpenSidePanel, handleCloseSidePanel]
    );

    const renderItem = useCallback(
        (item, index) => {
            return (
                <TileItem similarTiles title={item.contractDesc} onClick={() => handleOpenContract(item)}>
                    <div className="tile-action-icons flex-column">
                        <IconWrap
                            icon="edit-empty"
                            title="Edit Contract"
                            onClick={(e) => {
                                e.stopPropagation();
                                onEditContract(item.contractNumber);
                            }}
                        />
                        <IconWrap icon="open-new-window" title="Manage Contract" onClick={() => handleOpenContract(item)} />
                    </div>
                </TileItem>
            );
        },
        [onEditContract, handleOpenContract]
    );

    return (
        <div className="utility-financials__list flex-one-in-column no-scroll">
            {isLoadingContracts ? (
                <WaitIcon />
            ) : (
                <div className="utility-financials__contractors-list-wrap flex-column fill-height no-scroll">
                    {contracts.length > 0 ? (
                        <>
                            <ListHeader
                                className="utility-financials__title"
                                count={contracts.length}
                                title="Contracts"
                                onAdd={() => handleEditContract()}
                                addActionTitle="Add Contract"
                            />
                            <CustomList
                                items={contracts}
                                searchFilters={{
                                    contractDesc: "",
                                }}
                                searchPlaceholder="Search contracts"
                                renderItem={renderItem}
                                renderFooter={renderCustomFooter}
                            />
                        </>
                    ) : (
                        <NothingFoundBlock nothingFoundBlockMargin icon="rebates" title="No Contracts">
                            <IconWithLabel icon="plus" onClick={() => handleEditContract()}>
                                Add Contract
                            </IconWithLabel>
                        </NothingFoundBlock>
                    )}
                </div>
            )}
        </div>
    );
});

export default ContractList;
