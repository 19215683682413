import React, { memo } from "react";
import cn from "classnames";

import { useResource } from "../../../../../store/resources/useResource";
import DropDownInput from "../../../Input/DropDownInput";

export const DROP_DOWN_ID = "utility-programs-list";

const UtilityProgramsDropDown = memo(
    ({
        utilityNumber,
        activeOnly = false,
        renderWhenEmpty,
        className,
        program,
        placeholder = "Not Selected",
        showLabel = true,
        onChange,
        disabled,
        withPopper,
        label,
        required,
    }) => {
        const [programs, isLoadingPrograms] = useResource({
            resourceName: "programs",
            key: utilityNumber,
            query: {
                utilityNumber,
            },
            forced: true,
            transform: (data) => {
                return ((data && data.programList && data.programList.filter((i) => (activeOnly ? i.isActive : true))) || []).map((i) => ({
                    label: i.program,
                    value: i.programNumber,
                }));
            },
        });

        if (renderWhenEmpty && !isLoadingPrograms && (!programs || programs.length === 0)) {
            return renderWhenEmpty;
        }

        return (
            <DropDownInput
                className={cn("utility-programs-list", className)}
                id={DROP_DOWN_ID + "_" + utilityNumber}
                label={showLabel ? label || "Program Filter" : ""}
                mobileHeader={showLabel ? label || "Program Filter" : ""}
                placeholder={isLoadingPrograms ? "Loading..." : placeholder}
                disabled={isLoadingPrograms || disabled}
                data={programs}
                value={isLoadingPrograms ? undefined : program}
                onChange={(event) => onChange(event.target.value)}
                withPopper={withPopper}
                required={required}
            />
        );
    }
);

export default UtilityProgramsDropDown;
