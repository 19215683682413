import React, { memo } from "react";

import { locationEquipmentGridColumnKeys } from "../../../../../views/configureGrids";
import { exportDatagridToCSV } from "../../../../../utils/CSV";

import IconWithLabel from "../../../../Icons/IconWithLabel";
import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid";
import NothingFoundBlock from "../../../../NothingFoundBlock";

import "./LocationPanel.scss";

const Equipment = memo(({ applicationNumber, address, zip }) => {
    const gridId = `${applicationNumber}-location-equipment-grid`;
    const filter = `${locationEquipmentGridColumnKeys.address}=${address}|${locationEquipmentGridColumnKeys.zip}=${zip}`;

    return (
        <div className="fill-width">
            <h3>Measure penetration at this location</h3>
            {address && zip ? (
                <Grid applicationNumber={applicationNumber} gridId={gridId} filter={filter} />
            ) : (
                <NothingFoundBlock nothingFoundBlockSmall nothingFoundBlockMargin icon="location" title="No Data Available" />
            )}
        </div>
    );
});

const showedColumnsKeys = [locationEquipmentGridColumnKeys.category, locationEquipmentGridColumnKeys.quantity];

const Grid = withDataGrid(
    "locationEquipment",
    memo(({ applicationNumber, gridId, dataGridConfig }) => {
        return (
            <>
                <div className="data-grid-controls flex-row justify-end">
                    <IconWithLabel
                        withHandMadeIcon
                        onClick={() =>
                            exportDatagridToCSV({
                                dataGridId: gridId,
                                fileName: "application_location_equipment",
                                fileNamePostfix: applicationNumber,
                            })
                        }
                    >
                        Export CSV
                    </IconWithLabel>
                </div>
                <DataGrid limit={5} name={gridId} config={dataGridConfig} showedColumnsKeys={showedColumnsKeys} />
            </>
        );
    })
);

export default Equipment;
