import React from "react";
import ErrorMsg from "./ErrorMessage";
import SuccessMsg from "./SuccessMessage";
import InfoMsg from "./InfoMessage";
import WarningMsg from "./WarningMessage";

import "./style.scss";

export const SuccessMessage = SuccessMsg;
export const ErrorMessage = ErrorMsg;
export const InfoMessage = InfoMsg;
export const WarningMessage = WarningMsg;

export type MessageProps = {
    children: React.ReactNode;
    spaceAround?: boolean;
    marginTop?: boolean;
    marginBottom?: boolean;
    inline?: boolean;
    msgCentered?: boolean;
    msgSquare?: boolean;
    delay?: number;
};
