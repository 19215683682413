import React, { memo } from "react";

import { useUtilityStandardDashboard } from "../../../../../utils/powerBI";

import WaitIcon from "../../../../WaitIcon";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import PowerBI, { reportPages } from "../../../../../views/PowerBI";

import "./style.scss";
import "../../SnapshotPanel.scss";

const SnapshotPanel = memo(({ panel }) => {
    const utilityNumber = panel && panel.data && panel.data.utilityNumber;

    const [reportID, isLoading] = useUtilityStandardDashboard();

    if (isLoading) {
        return <WaitIcon />;
    }

    return (
        <div className="panel snapshot">
            <PanelHeaderLarge title={panel.name} />
            <div className="panel flex-column panel-report">
                <PowerBI
                    reportID={reportID}
                    pageName={reportPages["Utility Standard Dashboard Utility"]}
                    entityTypeID="1"
                    entityNumber={utilityNumber}
                />
            </div>
        </div>
    );
});

export default SnapshotPanel;
