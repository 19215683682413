import React, { memo } from "react";

import FieldGroup from "../FieldGroup";
import FieldWrap from "../FieldWrap";
import StatusMsg from "../StatusMsg";
import Input from "../Input";
import Label from "../Label";
import IconWrap from "../Icons";

import "./style.scss";

const FieldGroupInput = memo((props) => {
    return (
        <FieldGroup className={props.className} inline={props.inline}>
            {props.label && !props.labelAfter && <Label labelRequired={props.labelRequired}>{props.label}</Label>}
            <FieldWrap input twoIcons={props.twoIcons} iconRight={props.iconRight} frozen={props.frozen}>
                <Input
                    id={props.id}
                    inputRef={props.inputRef}
                    autoFocus={props.autoFocus}
                    type={props.type}
                    autoComplete={props.autoComplete}
                    name={props.name}
                    readOnly={props.readOnly}
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    value={props.value ?? undefined}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    onChange={props.onChange}
                    onKeyDown={props.onKeyDown}
                    highlighted={props.highlighted}
                    focus={props.focus}
                    withIcon={props.withIcon}
                    twoIcons={props.twoIcons}
                    iconRight={props.iconRight}
                    error={props.error}
                    frozen={props.frozen}
                    minLength={props.minLength}
                    maxLength={props.maxLength}
                    defaultValue={props.defaultValue}
                    title={props.title}
                    withLabelInside={props.labelInside}
                    onClick={props.onClick}
                />
                {props.labelInside && <Label labelRequired={props.labelRequired}>{props.labelInside}</Label>}
                {props.icon && <IconWrap icon={props.icon} title={props.iconTitle} onClick={props.onIconClick} />}
            </FieldWrap>
            {props.msgText && (
                <StatusMsg
                    msgFieldStatus
                    msgVisible={props.error}
                    msgError={props.error}
                    msgInfo={props.msgInfo}
                    msgSuccess={props.msgSuccess}
                    msgText={props.msgText}
                ></StatusMsg>
            )}
            {props.children}
        </FieldGroup>
    );
});

export default FieldGroupInput;
