import React from "react";
import ClassNames from "classnames";
import { isNil } from "lodash";
import useFocusClasses from "../../../utils/useFocusClasses";
import useEnterKey from "../../../utils/useEnterKey";

import "./style.scss";

const ListItemCategory = (props) => {
    const className = ClassNames("list-item-category", props.withArrow, props.listItemSelected, props.className, {
        "with-icon with-arrow shevron-small-right": props.withArrow,
        "list-item-selected": props.listItemSelected,
    });

    const isTabable = !isNil(props.onClick);
    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled: !isTabable,
    });
    const [onEnter] = useEnterKey({
        disabled: !isTabable,
        onClick: props.onClick,
    });

    return (
        <div
            className={className}
            tabIndex={isTabable ? "0" : "-1"}
            onFocus={onFocusClassesFocus}
            onBlur={onFocusClassesBlur}
            onKeyDown={onEnter}
            onClick={props.onClick}
        >
            <div>{props.children}</div>
        </div>
    );
};

export default ListItemCategory;
