import React from "react";
import { DeviceTypes, PortalTemplateConfiguration, RevisionData, SelectedUtilityTemplate, ValidationError } from "./types";
import createFastContext from "components/utils/useFastContext";
import { DEFAULT_UTILITY_TEMPLATE_CONFIG } from "./utils";
import { PortalBuilderSection } from "./Properties/Section";

const { Provider, useStore, useStoreSetter } = createFastContext<PortalBuilderState>({
    deviceType: DeviceTypes.DESKTOP,
    isProgramPortalBuilder: false,
    isDarkFileListBackground: false,
    isPropertiesExpanded: true,
    isSaving: false,
    revisionData: {
        selectedDate: null,
        skip: 0,
        selectedRevision: null,
        selectedUsername: null,
    },
    config: DEFAULT_UTILITY_TEMPLATE_CONFIG,
    updatedConfig: DEFAULT_UTILITY_TEMPLATE_CONFIG,
    activeSectionErrors: [],
});

export const PortalBuilderContextProvider = ({ children }: PortalBuilderContextProviderProps) => {
    return <Provider>{children}</Provider>;
};

interface PortalBuilderContextProviderProps {
    children: React.ReactNode;
}

export function usePortalBuilderState<SelectorOutput>(selector: (store: PortalBuilderState) => SelectorOutput) {
    return useStore(selector);
}

export function usePortalBuilderStateSetter() {
    return useStoreSetter();
}

export interface PortalBuilderState {
    utilityNumber?: string;
    programNumber?: string;
    deviceType: string;
    isProgramPortalBuilder: boolean;
    isDarkFileListBackground: boolean;
    isPropertiesExpanded: boolean;
    isSaving: boolean;
    revisionData: RevisionData;
    config: PortalTemplateConfiguration;
    updatedConfig: PortalTemplateConfiguration;
    activeError?: ValidationError;
    selectedUtilityTemplate?: SelectedUtilityTemplate;
    /** Preview iframe name */
    previewName?: string;
    /** Currently edited page index */
    editingPageIndex?: number;
    /** index of last opened page for edit */
    lastEditingPageIndex?: number;
    /** Last active section */
    lastActiveSection?: PortalBuilderSection;
    /** Active error index */
    errorIndex?: number;

    activeSectionErrors: ValidationError[];
    activeConfigPanel?: React.ReactNode;

    activePageUrl?: string;
}
