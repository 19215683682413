import React, { Suspense, memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { isNil } from "lodash";
import { getPortalTemplateResourceParams, getPortalTemplatesResourceParams } from "store/configureResources";
import { getResource } from "store/resources/actions";
import { windowChange } from "store/window/actions";
import { windowContainerTypes } from "components/utils/window";
import ModuleLoadError from "components/ui/ModuleLoadError";
import WaitIcon from "components/ui/WaitIcon";

const PortalBuilder = React.lazy(() =>
    import("components/ui/PortalBuilder").catch((error) => ({
        default: () => <ModuleLoadError error={error} />,
    }))
);

const PortalBuilderView = memo((props) => {
    const { utilityNumber, programNumber, entityName, entityType, activeInitialView } = props;
    const dispatch = useDispatch();
    const [portalTemplateNumber, setPortalTemplateNumber] = useState(props.portalTemplateNumber);
    const isProgramPortalBuilder = !isNil(programNumber);

    // Update template number if changed in props
    useEffect(() => {
        setPortalTemplateNumber(props.portalTemplateNumber);
    }, [props.portalTemplateNumber]);

    const onPortalTemplateSaved = useCallback(
        (portalTemplateNumber, onComplete) => {
            // Update template number to load the newly created template.
            setPortalTemplateNumber(portalTemplateNumber);
            dispatch(
                windowChange({
                    containerName: windowContainerTypes.Root,
                    oldViewName: `portal-builder-${utilityNumber}-${programNumber}-undefined`,
                    newViewName: `portal-builder-${utilityNumber}-${programNumber}-${portalTemplateNumber}`,
                    viewProps: { ...props, portalTemplateNumber },
                })
            );

            // Refresh the template
            dispatch(getResource(getPortalTemplateResourceParams({ utilityNumber, portalTemplateNumber, onComplete })));

            // Refresh the templates list
            dispatch(getResource(getPortalTemplatesResourceParams({ utilityNumber })));
        },
        [utilityNumber, dispatch, programNumber, props]
    );

    return (
        <div className="portal-builder-view flex-column fill-height no-scroll">
            <Suspense fallback={<WaitIcon />}>
                <PortalBuilder
                    key={`${utilityNumber}-${programNumber}-${portalTemplateNumber}`}
                    entityType={entityType}
                    entityName={entityName}
                    programNumber={programNumber}
                    utilityNumber={utilityNumber}
                    portalTemplateNumber={portalTemplateNumber}
                    isProgramPortalBuilder={isProgramPortalBuilder}
                    onPortalTemplateSaved={onPortalTemplateSaved}
                    activeInitialView={activeInitialView}
                />
            </Suspense>
        </div>
    );
});

export default PortalBuilderView;
