import React, { useEffect, useState, memo } from "react";
import { useDispatch } from "react-redux";

import withDataGrid from "../../../../DataGrid/withDataGrid";
import { getTotalRecords } from "components/utils/datagrid";
import { getData } from "../../../../../../store/dataGrid/actions";
import { filesGridColumnKeys } from "../../../../../views/configureGrids";
import { downloadFile, deleteFile } from "../../../../../../store/files/actions";
import { isApplicationLocked } from "../../../../../views/ProjectView/utils";
import { exportDatagridToCSV } from "../../../../../utils/CSV";
import { entityType } from "components/utils/entityType";
import { useResource } from "store/resources/useResource";
import { getResource } from "../../../../../../store/resources/actions";
import DataGrid from "../../../../DataGrid";
import IconWrap from "../../../../Icons";
import IconWithLabel from "../../../../Icons/IconWithLabel";
import FileUpload from "../../../../FileUpload";

const DocumentsGrid = withDataGrid(
    "files",
    ({ gridId, dataGridConfig, setFileCount, columnCellContent, customRowActions, onRowAction, sort }) => {
        useEffect(() => {
            setFileCount(getTotalRecords(dataGridConfig));
        }, [dataGridConfig, setFileCount]);

        return (
            <DataGrid
                name={gridId}
                config={dataGridConfig}
                customRowActions={customRowActions}
                columnCellContent={columnCellContent}
                onRowAction={onRowAction}
                sort={sort}
            />
        );
    }
);

const FilesTab = memo(({ programNumber, applicationNumber, resourceId, qcNumber, setFileCount, hidden }) => {
    const dispatch = useDispatch();

    const gridId = `${applicationNumber}-${resourceId}-documents-grid`;
    const [showUploadForm, setShowUploadForm] = useState(false);
    const isAppLocked = isApplicationLocked({ applicationNumber });
    const entityTypeId = 70;
    const [files] = useResource({
        resourceName: "fileUploadReqs",
        key: entityTypeId,
        path: {
            entityTypeId: 70,
        },
    });

    const customRowActions = [
        {
            name: "download",
            icon: "download",
            title: "Download File",
        },
        {
            name: "delete",
            icon: "delete-trash-empty",
            hide: isAppLocked,
            title: "Delete File",
        },
    ];

    const toggleShowUploadForm = () => {
        setShowUploadForm(!showUploadForm);
    };

    const reload = () => {
        dispatch(
            getData({
                dataGridId: `${applicationNumber}-${resourceId}-documents-grid`,
            })
        );
        dispatch(
            getResource({
                resourceName: "qcWidget",
                key: applicationNumber,
                path: {
                    appId: applicationNumber,
                },
            })
        );
    };

    const onUploadSuccess = () => {
        setShowUploadForm(!showUploadForm);
        reload();
    };

    const onRowAction = (action) => {
        const { name, dataItem } = action;
        switch (name) {
            case "download":
                dispatch(
                    downloadFile({
                        fileId: dataItem[filesGridColumnKeys.fileId],
                    })
                );
                break;
            case "delete":
                dispatch(
                    deleteFile({
                        fileId: dataItem[filesGridColumnKeys.fileId],
                        fileName: dataItem[filesGridColumnKeys.fileName],
                        onDeleteSuccess: reload,
                    })
                );
                break;
            default:
                break;
        }
    };

    return (
        <div hidden={hidden}>
            <div className="files-actions">
                <IconWithLabel
                    icon={showUploadForm ? " shevron-circle-down-filled" : "upload"}
                    disabled={isAppLocked}
                    onClick={toggleShowUploadForm}
                >
                    Upload files
                </IconWithLabel>
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: gridId,
                            fileName: "documents",
                            fileNamePostfix: resourceId,
                        })
                    }
                >
                    Download All in CSV
                </IconWithLabel>
            </div>
            {showUploadForm && (
                <FileUpload
                    noDocumentSecurity
                    internalRefId={qcNumber}
                    entityTypeId={entityType.projectApplication}
                    entityId={applicationNumber}
                    programNumber={programNumber}
                    fileTypeId={28}
                    onCancel={toggleShowUploadForm}
                    onUploadSuccess={onUploadSuccess}
                    files={files}
                />
            )}
            <DocumentsGrid
                applicationNumber={applicationNumber}
                gridId={gridId}
                filter={`${filesGridColumnKeys.intrefid}=${qcNumber}|${filesGridColumnKeys.entityId}=${applicationNumber}`}
                setFileCount={setFileCount}
                customRowActions={customRowActions}
                onRowAction={onRowAction}
                columnCellContent={{
                    [filesGridColumnKeys.security]: (column, row) =>
                        row[filesGridColumnKeys.security].toLowerCase() === "public" ? (
                            <IconWrap title="Public" icon="add-group-filled" />
                        ) : (
                            <IconWrap title="Private" icon="user-identity-person-filled" />
                        ),
                }}
                sort={[
                    {
                        field: filesGridColumnKeys.date,
                        dir: "desc",
                    },
                ]}
            />
        </div>
    );
});

export { FilesTab };
