import * as actionTypes from "../actionTypes";
import { get } from "lodash";

export const initializeAssociations =
    ({ key, isNew, resource }) =>
    (dispatch, getState) => {
        if (isNew) {
            dispatch(
                setAssociations({
                    associations: {
                        approvedMeasures: [],
                        calculationAssociations: [],
                    },
                    key,
                })
            );
        } else {
            dispatch(
                setApprovedMeasureAssociations({
                    approvedMeasures: (resource && resource.approvedMeasures) || [],
                    key,
                })
            );

            dispatch(
                setCalculationAssociations({
                    calculationAssociations: (resource && resource.calculationAssociations) || [],
                    key,
                })
            );
        }
    };

export const setAssociations =
    ({ key, associations }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.PROGRAM_SET_CATALOG_ASSOCIATIONS,
            key,
            associations,
        });
    };

export const setCalculationAssociations =
    ({ key, calculationAssociations }) =>
    (dispatch, getState) => {
        const approvedMeasures = get(getState(), `associations[${key}].approvedMeasures`, []);

        dispatch({
            type: actionTypes.PROGRAM_SET_CATALOG_ASSOCIATIONS,
            key,
            associations: {
                approvedMeasures,
                calculationAssociations,
            },
        });
    };

export const setApprovedMeasureAssociations =
    ({ key, approvedMeasures }) =>
    (dispatch, getState) => {
        const calculationAssociations = get(getState(), `associations[${key}].calculationAssociations`, []);

        dispatch({
            type: actionTypes.PROGRAM_SET_CATALOG_ASSOCIATIONS,
            key,
            associations: {
                approvedMeasures,
                calculationAssociations,
            },
        });
    };
