import React, { useContext, memo, useCallback, useMemo } from "react";
import cn from "classnames";
import { Draggable } from "react-beautiful-dnd";
import uuid from "uuid/v4";
import { isEqual, isFunction } from "lodash";

import IconWrap from "../../Icons";
import Checkbox from "../../Input/Checkbox";
import DragHandle from "../../DragHandle";
import { GridListContext } from ".";
import { formatCellContent } from "../../../utils/datagrid";
import GridListCell from "./GridListCell";
import ScrollableColumns from "./ScrollableColumns";
import { MinColumnWidth } from "./utils";
import GridListResizeHandler from "./GridListResizeHandler";
import WaitIcon from "components/ui/WaitIcon";

import "./GridListRow.scss";

const GridListRow = memo(({ className, row, index, columns, style }) => {
    if (row._treeHidden) {
        return null;
    }

    return (
        <>
            <RowContent className={className} style={style} index={index} row={row} columns={columns} />
            {row._expanded && <RowExpandedContent index={index} row={row} />}
        </>
    );
}, isEqual);

const RowContent = memo(({ className, style, index, row, columns }) => {
    const { expandable, showActions, showExpandedRow, draggableRows, onRowSelectChange, onExpandChange, onTreeExpandChange } =
        useContext(GridListContext);

    const commonClassName = cn("grid-list-row", className, {
        expandable: expandable,
        expanded: row._expanded,
        selected: row._selected,
        "show-expanded-row": row._expanded && showExpandedRow,
        "no-actions": !showActions,
        draggable: draggableRows,
        "has-children": row._treeHasChildren,
        "has-children-expanded": row._treeExpanded && row._treeHasChildren,
        "is-children": row._treeDepth,
    });

    const onRowSelectClick = useCallback(() => {
        onRowSelectChange({ dataItem: row, index });
    }, [row, index, onRowSelectChange]);

    const onTreeExpandClick = useCallback(() => {
        onTreeExpandChange({ dataIndex: index, dataItem: row });
    }, [row, index, onTreeExpandChange]);

    const onClick = useCallback(
        (event) => {
            event.stopPropagation();

            if (expandable) {
                onExpandChange({ dataIndex: index, dataItem: row });
            }
        },
        [index, row, expandable, onExpandChange]
    );

    const content = useMemo(
        () => (
            <>
                <RowSelectCell isChecked={row._selected} onClick={onRowSelectClick} />
                <ScrollableColumns>
                    {columns.map((column) => (
                        <RowCell key={column.key} index={index} row={row} column={column} onTreeExpandClick={onTreeExpandClick} />
                    ))}
                </ScrollableColumns>
                <RowActionsCell index={index} row={row} />
            </>
        ),
        [index, row, columns, onRowSelectClick, onTreeExpandClick]
    );

    // Render draggable row
    if (draggableRows) {
        return (
            <Draggable draggableId={uuid()} index={index}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={cn(commonClassName, {
                            "is-dragging-item": snapshot.isDragging,
                        })}
                        onClick={onClick}
                    >
                        <DragHandle dragVertical {...provided.dragHandleProps} />
                        {content}
                    </div>
                )}
            </Draggable>
        );
    }

    // Default Row render
    return (
        <div style={style} className={commonClassName} onClick={onClick}>
            {content}
        </div>
    );
});

const RowSelectCell = memo(({ isChecked, onClick }) => {
    const { canSelectRow } = useContext(GridListContext);

    const onChange = useCallback(
        (event) => {
            event.stopPropagation();
            onClick();
        },
        [onClick]
    );

    if (!canSelectRow) {
        return null;
    }

    return (
        <GridListCell className="row-select" width={MinColumnWidth}>
            <Checkbox labelIconBig iconLabelEmpty checked={Boolean(isChecked)} onChange={onChange} />
        </GridListCell>
    );
});

const RowCell = memo(({ index, row, column, onTreeExpandClick }) => {
    const { columnCellContent, columnWidths, onRowAction, onExpandChange } = useContext(GridListContext);

    const customContent = columnCellContent?.[column.key];
    const width = columnWidths[column.key];

    return (
        <GridListCell key={column.key} width={width}>
            {column.key === row._treeColumn && (
                <RowTreeControls
                    treeDepth={row._treeDepth}
                    treeHasChildren={row._treeHasChildren}
                    treeExpanded={row._treeExpanded}
                    onClick={onTreeExpandClick}
                />
            )}
            {customContent
                ? customContent(column, row, () => onExpandChange({ dataItem: row }), onRowAction, index)
                : formatCellContent(column, row[column.key])}
            <GridListResizeHandler column={column} />
        </GridListCell>
    );
});

const RowActionsCell = memo(({ index, row }) => {
    const { showActions, rowActions } = useContext(GridListContext);

    if (!showActions) {
        return null;
    }

    return (
        <GridListCell className="actions">
            {rowActions.map((action) => (
                <RowAction key={action.name} index={index} row={row} action={action} />
            ))}
        </GridListCell>
    );
});

const RowExpandedContent = ({ index, row }) => {
    const { showExpandedRow, onGridAction, DetailsContent } = useContext(GridListContext);

    const ExpandedContent = row._expandedContent ? row._expandedContent : DetailsContent;

    if (!row._expanded) {
        return null;
    }

    return (
        <div
            className={cn("grid-list-row details", {
                "show-expanded-row": showExpandedRow,
            })}
        >
            <ExpandedContent dataItem={row} dataIndex={index} gridRefresh={() => onGridAction({ name: "refresh" })} />
        </div>
    );
};

const RowAction = ({ index, row, action }) => {
    const { onRowAction } = useContext(GridListContext);

    const hide = isFunction(action.hide) ? action.hide(row) : action.hide;
    const icon = isFunction(action.icon) ? action.icon(row, index) : action.icon;

    const onClick = useCallback(
        (event) => {
            event.stopPropagation();
            onRowAction({ dataIndex: index, dataItem: row, ...action });
        },
        [index, row, action, onRowAction]
    );

    if (icon === "waiting") {
        return (
            <div className="wait-icon-wrap">
                <WaitIcon />
            </div>
        );
    }

    if (hide) {
        return null;
    }

    return (
        <IconWrap
            className="grid-list-row-action"
            icon={isFunction(action.icon) ? action.icon(row, index) : action.icon}
            title={isFunction(action.title) ? action.title(row) : action.title}
            disabled={isFunction(action.disabled) ? action.disabled(row) : action.disabled}
            onClick={onClick}
            iconWrapWhite
            iconWrapRoundedSquare
        />
    );
};

const RowTreeControls = memo(({ treeDepth, treeHasChildren, treeExpanded, onClick }) => {
    const { notExpandableRowIcon } = useContext(GridListContext);

    const onTreeClick = useCallback(
        (event) => {
            event.stopPropagation();
            onClick();
        },
        [onClick]
    );

    if (treeDepth === undefined) {
        return null;
    }

    let depth = <span style={{ marginLeft: 28 * treeDepth + "px" }} />;

    if (treeHasChildren) {
        return (
            <>
                {depth}
                <IconWrap
                    className="grid-list-row-tree"
                    title={treeExpanded ? "Hide Sub Groups" : "Show Sub Groups"}
                    icon={treeExpanded ? "shevron-in-circle-up-filled" : "shevron-in-circle-down-drop-down-empty"}
                    onClick={onTreeClick}
                />
            </>
        );
    } else {
        if (notExpandableRowIcon) {
            return (
                <>
                    {depth}
                    <IconWrap icon={notExpandableRowIcon} />
                </>
            );
        }

        depth = <span style={{ marginLeft: 28 * (treeDepth + 1) + "px" }} />;

        return <>{depth}</>;
    }
});

export default GridListRow;
