import React from "react";
import ClassNames from "classnames";

import "./IconHandMade.scss";

const IconHandMade = (props) => {
    const className = ClassNames("with-hand-made-icon", props.className, {
        hidden: props.iconHandMadeHidden,
        "document-icon": props.iconDocumentHandMade,
        big: props.big,
    });

    return (
        <i className={className}>
            <span>{props.title}</span>
        </i>
    );
};

export default IconHandMade;
