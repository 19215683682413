import React, { memo } from "react";

import Button from "../Button";
import NothingFoundBlock from "../NothingFoundBlock";
import Separator from "../Separator";

const ModuleLoadError = memo(() => {
    const onClick = () => {
        window.location.reload();
    };

    return (
        <NothingFoundBlock title="Failed to load module" nothingFoundBlockError icon="circle-exclamation-empty">
            <Separator />
            <Button primary icon="update-refresh" onClick={onClick}>
                Reload page to try again
            </Button>
        </NothingFoundBlock>
    );
});

export default ModuleLoadError;
