import React, { useCallback, memo } from "react";
import { useDispatch } from "react-redux";

import { updateResource, deleteResource } from "../../../../../../store/resources/actions";
import { programsContentRevisionsGridColumnKeys } from "../../../../../views/configureGrids";
import { modalOpen } from "../../../../../../store/modal/actions";
import { getData } from "../../../../../../store/dataGrid/actions";

import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid";
import ContentRevisionForm from "./ContentRevisionForm";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../../ui/SideNav/SideNavRoot";
import { isChildProgram } from "components/views/ProgramView/utils";

const ContentRevisionsGrid = memo(({ programNumber, formNumber, gridId, dataGridConfig, contentGridId, contentTitle }) => {
    const dispatch = useDispatch();
    const isLocked = isChildProgram({ programNumber });
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({ size: sideNavSize.medium });

    const customRowActions = [
        {
            name: "edit",
            title: isLocked ? "View Revision" : "Edit Revision",
            icon: isLocked ? "eye-visibility-empty" : "edit-empty",
        },
        {
            name: "activate",
            title: isLocked ? undefined : "Activate Revision",
            icon: "check-circle-empty",
            disabled: isLocked,
            hide: (row) => !(row[programsContentRevisionsGridColumnKeys.status] !== "active"),
        },
        {
            name: "disable",
            title: isLocked ? undefined : "Disable Revision",
            icon: "block-not-interested-empty",
            disabled: isLocked,
            hide: (row) => !(row[programsContentRevisionsGridColumnKeys.status] === "pending"),
        },
    ];

    const onSuccess = useCallback(() => {
        dispatch(
            getData({
                dataGridId: gridId,
            })
        );
        dispatch(
            getData({
                dataGridId: contentGridId,
            })
        );
    }, [gridId, contentGridId, dispatch]);

    const onRowAction = useCallback(
        (action, onExpand) => {
            const { name, dataItem } = action;

            const contentNumber = dataItem[programsContentRevisionsGridColumnKeys.contentNumber];
            const revisionNumber = dataItem[programsContentRevisionsGridColumnKeys.itemNumber];

            switch (name) {
                case "edit":
                    handleOpenSidePanel(
                        <ContentRevisionForm
                            contentTitle={contentTitle}
                            dataItem={dataItem}
                            gridId={gridId}
                            dispatch={dispatch}
                            onClose={handleCloseSidePanel}
                            programNumber={programNumber}
                            formNumber={formNumber}
                            revisionNumber={dataItem[programsContentRevisionsGridColumnKeys.itemNumber]}
                        />,
                        { className: "content-revision-edit-sidenav-panel" }
                    );
                    break;

                case "activate":
                    let dialogMessage = <p>Are you sure you want to activate the selected content?</p>;

                    dispatch(
                        modalOpen({
                            modalType: "CONFIRM",
                            modalProps: {
                                title: "Activate Content",
                                overlayClassName: "modal-styled",
                                className: "activate-content-confirmation-modal modal-sm",
                                modalIcon: "check-circle-empty",
                                content: dialogMessage,
                                footerContentCenter: true,
                                onConfirm: () => {
                                    dispatch(
                                        updateResource({
                                            resourceName: "programContentItems",
                                            resourceId: revisionNumber,
                                            path: {
                                                programNumber,
                                                contentNumber,
                                            },
                                            optimisticUpdate: {
                                                clearItem: true,
                                            },
                                            onSuccess,
                                        })
                                    );
                                },
                            },
                        })
                    );
                    break;

                case "disable":
                    dialogMessage = <p>Are you sure you want to disable the selected content?</p>;

                    dispatch(
                        modalOpen({
                            modalType: "CONFIRM",
                            modalProps: {
                                title: "Disable Content",
                                overlayClassName: "modal-styled",
                                className: "disable-revision-confirmation-modal modal-sm",
                                modalIcon: "block-not-interested-empty",
                                content: dialogMessage,
                                footerContentCenter: true,
                                onConfirm: () => {
                                    dispatch(
                                        deleteResource({
                                            resourceName: "programContentItems",
                                            resourceId: revisionNumber,
                                            path: {
                                                programNumber,
                                                contentNumber,
                                            },
                                            optimisticUpdate: {
                                                clearItem: true,
                                            },
                                            onSuccess,
                                        })
                                    );
                                },
                            },
                        })
                    );
                    break;

                default:
                    break;
            }
        },
        [programNumber, formNumber, gridId, onSuccess, dispatch, handleOpenSidePanel, handleCloseSidePanel, contentTitle]
    );

    return <DataGrid name={gridId} config={dataGridConfig} onRowAction={onRowAction} customRowActions={customRowActions} />;
});

export default withDataGrid("programsContentRevisions", ContentRevisionsGrid);
