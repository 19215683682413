import React, { useCallback, useRef, useMemo, memo } from "react";
import cn from "classnames";
import { setConfigSectionValue } from "../utils";
import FilesPage from "./FilesPage";
import JsonEditor from "./JsonEditor";
import { Colors } from "./Colors";
import { SectionHeader } from "./SectionHeader";
import ContentPages from "./ContentPages";
import TemplateInfo from "./TemplateInfo";
import Accessibility from "./Accessibility";
import { PropertyList } from "./PropertyList";
import { Settings } from "./Settings";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { PortalTemplateConfiguration, ValidationError } from "../types";
import IconWrap from "components/ui/Icons";
import { ReactComponent as IconSettings } from "assets/img/portal-builder-sections/section-settings.svg";
import { ReactComponent as IconWidgets } from "assets/img/portal-builder-sections/section-widgets.svg";
import { ReactComponent as IconColors } from "assets/img/portal-builder-sections/section-colors.svg";
import { ReactComponent as IconFiles } from "assets/img/portal-builder-sections/section-files.svg";
import { ReactComponent as IconHeader } from "assets/img/portal-builder-sections/section-header.svg";
import { ReactComponent as IconContent } from "assets/img/portal-builder-sections/section-content.svg";
import { ReactComponent as IconFooter } from "assets/img/portal-builder-sections/section-footer.svg";
import { ReactComponent as IconInfo } from "assets/img/portal-builder-sections/section-info.svg";
import { Widgets } from "./Widgets";
import { ConfigurationOverlay } from "./ConfigurationOverlay";

import "./Section.scss";
import { RevisionsSection } from "./Revisions/RevisionsSection";

const IconAccessibility = () => <IconWrap icon="accessibility-filled" />;
// workaround until an svg icon is provided
const IconRevisions = () => <IconWrap iconWrapStyle={{ height: "28px", lineHeight: "28px" }} iconWrapBig icon="assignment" />;

export const Section = memo((props: SectionProps) => {
    const { section, hidden, containsErrors, onChange } = props;
    const { Component, Icon, showHeader, headerUnderline } = useSection(section);

    return (
        <div className="portal-builder-properties-section flex-column fill-height no-scroll" hidden={hidden}>
            <ConfigurationOverlay section={section} />
            {showHeader && <SectionHeader Icon={Icon} title={section} underline={headerUnderline} containsErrors={containsErrors} />}
            {Component ? <Component onChange={onChange} containsErrors={containsErrors} /> : null}
        </div>
    );
});

export const PropertiesForSection = (props: PropertiesForSectionProps) => {
    const { className, sectionKey, sectionProperties, config, onChange, errors } = props;
    const sectionConfig = config?.[sectionKey];

    const configRef = useRef<PortalTemplateConfiguration>();
    configRef.current = config;

    const onPropertyChange = useCallback(
        (id: string, value: any) => {
            const updatedConfig = setConfigSectionValue(configRef.current, sectionKey, id, value);
            onChange && onChange(updatedConfig);
        },
        [onChange, sectionKey]
    );

    return (
        <div className={cn("portal-builder-properties-section-body flex-one-in-column fill-width with-scroll", className)}>
            <PropertyList items={sectionProperties} errors={errors} config={sectionConfig} onChange={onPropertyChange} isExpanded />
        </div>
    );
};

const useSection = (section: string): UseSectionResult => {
    return useMemo(() => {
        let Component = null;
        let Icon = null;
        let headerUnderline = false;
        let showHeader = true;

        switch (section) {
            case SECTION_COLORS.title:
                Component = Colors;
                Icon = SECTION_COLORS.Icon;
                headerUnderline = true;
                break;
            case SECTION_SETTINGS.title:
                Component = Settings;
                Icon = SECTION_SETTINGS.Icon;
                headerUnderline = true;
                break;
            case SECTION_WIDGETS.title:
                Component = Widgets;
                Icon = SECTION_WIDGETS.Icon;
                showHeader = false;
                break;
            case SECTION_HEADER.title:
                Component = Header;
                Icon = SECTION_HEADER.Icon;
                headerUnderline = true;
                break;
            case SECTION_FOOTER.title:
                Component = Footer;
                Icon = SECTION_FOOTER.Icon;
                headerUnderline = true;
                break;
            case SECTION_EDITOR.title:
                Component = JsonEditor;
                Icon = SECTION_EDITOR.Icon;
                headerUnderline = true;
                break;
            case SECTION_CONTENT.title:
                Component = ContentPages;
                Icon = SECTION_CONTENT.Icon;
                showHeader = false;
                break;
            case SECTION_FILES.title:
                Component = FilesPage;
                Icon = SECTION_FILES.Icon;
                headerUnderline = true;
                break;
            case SECTION_TEMPLATE_INFO.title:
                Component = TemplateInfo;
                Icon = SECTION_TEMPLATE_INFO.Icon;
                headerUnderline = true;
                break;
            case SECTION_ACCESSIBILITY.title:
                Component = Accessibility;
                Icon = SECTION_ACCESSIBILITY.Icon;
                showHeader = false;
                break;
            case SECTION_REVISIONS.title:
                Component = RevisionsSection;
                Icon = SECTION_REVISIONS.Icon;
                headerUnderline = true;
                break;
            default:
                showHeader = false;
                break;
        }

        return {
            Component,
            Icon: Icon,
            headerUnderline,
            showHeader,
        };
    }, [section]);
};

export const SECTION_SETTINGS: PortalBuilderSection = {
    title: "Settings",
    Icon: IconSettings,
    path: "settings",
    separatorBottom: true,
};

export const SECTION_WIDGETS: PortalBuilderSection = {
    title: "Widgets",
    Icon: IconWidgets,
    path: "widgets",
};

export const SECTION_HEADER: PortalBuilderSection = {
    title: "Header",
    Icon: IconHeader,
    path: "header",
    separatorTop: true,
};

export const SECTION_CONTENT: PortalBuilderSection = {
    title: "Page Content",
    shortTitle: "Content",
    Icon: IconContent,
    path: "content",
};

export const SECTION_FOOTER: PortalBuilderSection = {
    title: "Footer",
    Icon: IconFooter,
    path: "footer",
};

export const SECTION_COLORS: PortalBuilderSection = {
    title: "Colors",
    Icon: IconColors,
    path: "colors",
};

export const SECTION_FILES: PortalBuilderSection = {
    title: "Files",
    Icon: IconFiles,
    path: "files",
};

export const SECTION_TEMPLATE_INFO: PortalBuilderSection = {
    title: "Template Info",
    Icon: IconInfo,
    path: "templateInfo",
    spaceTop: true,
};

export const SECTION_EDITOR: PortalBuilderSection = {
    title: "Editor",
    Icon: IconSettings,
    path: "editor",
};

export const SECTION_REVISIONS: PortalBuilderSection = {
    title: "Revisions",
    Icon: IconRevisions,
    path: "revisions",
};

export const SECTION_ACCESSIBILITY: PortalBuilderSection = {
    title: "Accessibility",
    shortTitle: "",
    Icon: IconAccessibility,
    path: "accessibility",
    separatorTop: true,
};

export type PortalBuilderSection = {
    title: string;
    shortTitle?: string;
    Icon: React.ElementType;
    separatorTop?: boolean;
    separatorBottom?: boolean;
    spaceTop?: boolean;
    path?: string;
};

export type SectionProps = {
    section: string;
    hidden: boolean;
    containsErrors: boolean;
    onChange: (config: any) => void;
};

export type UseSectionResult = {
    Component: React.ElementType | null;
    Icon: React.ElementType | null;
    showHeader: boolean;
    headerUnderline: boolean;
};

export type PropertiesForSectionProps = {
    className?: string;
    sectionKey: keyof PortalTemplateConfiguration;
    sectionProperties: any;
    config: any;
    onChange: (config: any) => void;
    errors?: ValidationError;
    section?: PortalBuilderSection;
};

export interface SectionComponentProps {}
