import React, { memo, useCallback } from "react";
import cn from "classnames";
import TextLabel from "components/ui/Label/TextLabel";
import SelectBox from "components/ui/SelectBox";
import { isEmpty } from "lodash";
import { Property } from ".";
import { ValidationRule } from "../../types";
import { PropertyListItem } from "../Property/PropertyListItem";

export const AutoCustomProperty = memo((props) => {
    const {
        id,
        title,
        helperPropName,
        autoValue,
        value,
        customValueTitle,
        customValueType,
        customValueDefault,
        onChange,
        errors,
        validationRules,
    } = props;
    const previousHeight = props[helperPropName];
    const isCustom = String(value).toLowerCase() !== autoValue;
    const toggleAutoProperty = (auto) => {
        if (auto) {
            onChange(id, autoValue);
        } else {
            onChange(id, previousHeight);
        }
    };

    // Custom value change
    const onCustomValueChange = useCallback(
        (id, val) => {
            const stringValue = String(val);
            const transformedValue = isEmpty(stringValue) ? "" : stringValue;
            onChange(id, transformedValue, [{ id: helperPropName, value: transformedValue }]);
        },
        [helperPropName, onChange]
    );

    return (
        <PropertyListItem
            className={cn("property-list-item--auto-custom", {
                "property-list-item--expanded-auto-custom": isCustom,
            })}
        >
            <div className="property-list-item-inner align-center flex-row justify-space-between">
                <TextLabel>{title}:</TextLabel>
                <div className="flex-row">
                    <SelectBox selectBoxText="Auto" selectBoxSelected={!isCustom} onClick={() => toggleAutoProperty(true)} />
                    <SelectBox selectBoxText="Custom" selectBoxSelected={isCustom} onClick={() => toggleAutoProperty(false)} />
                </div>
            </div>
            {isCustom && (
                <Property
                    {...props}
                    defaultValue={customValueDefault}
                    type={customValueType}
                    title={customValueTitle}
                    required={validationRules.includes(ValidationRule.Required)}
                    onChange={onCustomValueChange}
                    errors={errors}
                />
            )}
        </PropertyListItem>
    );
});
