import React from "react";
import IconWrap from "../../../Icons";

export const appIsLockedText = "Application is Locked";

export const reapplySelectedStatusText = "Re-apply selected status";

export const renderCategory = (category, parentCategory) => {
    return (
        <>
            {parentCategory && (
                <div className="category-item">
                    {parentCategory}
                    <IconWrap icon="shevron-small-right" disabled />
                </div>
            )}
            <div className="category-item">{category}</div>
        </>
    );
};

export const getTotalTimesUsed = (total) => {
    return total + (total === 1 ? " time" : " times") + " used";
};
