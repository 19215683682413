import React from "react";
import { isEmpty, isNil } from "lodash";

import { store } from "../../../../../../store/configureStore";
import { modalOpen, modalClose } from "../../../../../../store/modal/actions";
import { updateResource, deleteResource } from "../../../../../../store/resources/actions";
import { refreshApplicationPayment, refreshApplicationPayments } from "../../../../../../store/resources/refreshResource";

import RawHtml from "../../../../RawHtml";
import { refreshSidebarEquipment } from "../EquipmentPanel/utils";
import { systemUserRights } from "components/utils/user";

export const statusTypes = {
    approved: "88",
    canceled: "199",
};

export const rebateTypes = {
    choiceDigital: 1227,
};

export const rebateAction = {
    approve: "approve",
    commit: "commit",
    transfer: "transfer",
};

export const getTotals = (rebatesAvailable) => {
    let total = null;
    let remaining = null;

    if (rebatesAvailable && rebatesAvailable.length > 0) {
        total = rebatesAvailable[0].amountavail ?? 0;
        remaining = total - (rebatesAvailable[0].amountsubt ?? 0);
    }

    return { total, remaining };
};

export const getNotApprovedCancelled = ({ paymentStatusID, paymentApproveDate, isApplicationLocked }) => {
    const isApproved = paymentApproveDate;
    const isCanceled = paymentStatusID?.toString() === statusTypes.canceled;

    return !isApplicationLocked && !(isApproved || isCanceled);
};

export const getCanEditPaymentDetails = (rebate, programRights) => {
    return (
        programRights.includes(systemUserRights.VISIONDSM_ADMIN_REBATE) ||
        (!rebate.transferdate &&
            ((!rebate.approvedate && !rebate.commitdate && programRights.includes(systemUserRights.VISIONDSM_ADD_REBATE)) ||
                programRights.includes(systemUserRights.VISIONDSM_MANAGE_REBATE)))
    );
};

export const getCanDelete = ({ payment, isApplicationLocked, programRights = [] }) => {
    // Do not allow to delete if the payment is an empty object or undefined.
    if (isEmpty(payment)) {
        return false;
    }

    const isApproved = !isNil(payment.approvedate);
    const isTransferred = !isNil(payment.transferdate);
    const isCommitted = !isNil(payment?.commitDate || payment?.commitdate);

    const canAddRebate = programRights.includes(systemUserRights.VISIONDSM_ADD_REBATE);
    const canManageRebate = programRights.includes(systemUserRights.VISIONDSM_MANAGE_REBATE);
    const canAdminRebate = programRights.includes(systemUserRights.VISIONDSM_ADMIN_REBATE);

    // Delete rules:
    // 1. Rebates should not be deletable if the application is locked.
    // If it is unlocked:
    // 2. Rebates can be deleted by someone with VAR until they are approved, transferred or committed.
    // 3. If approved, but not committed or transferred, rebates can be deleted by someone with VMR
    // 4. Rebates can be deleted by someone with VARB

    const rule1 = !isApplicationLocked;
    const rule2 = !isApproved && !isTransferred && !isCommitted && canAddRebate;
    const rule3 = isApproved && !isCommitted && !isTransferred && canManageRebate;
    const rule4 = canAdminRebate;

    return rule1 && (rule2 || rule3 || rule4);
};

export const resetDates = ({ applicationNumber, rebateNumber, rebateAction }) => {
    const dateLabel = getDateLabelByRebateAction(rebateAction);
    const text = `<p>Would you like to reset payment <b>${dateLabel}</b> date?</p>`;

    store.dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: `Reset Payment ${dateLabel} Date`,
                overlayClassName: "modal-styled",
                className: "delete-payment-confirmation-modal modal-sm",
                modalIcon: "restore-undate-empty",
                content: <RawHtml>{text}</RawHtml>,
                footerContentCenter: true,
                onConfirm: () => {
                    store.dispatch(
                        updateResource({
                            resourceName: "applicationRebateResetDate",
                            path: {
                                appId: applicationNumber,
                                rebateNumber,
                            },
                            query: {
                                rebateAction,
                            },
                            onSuccess: () => {
                                refreshApplicationPayment({
                                    applicationNumber,
                                    paymentNumber: rebateNumber,
                                });
                                refreshApplicationPayments({
                                    applicationNumber,
                                });

                                store.dispatch(modalClose());
                            },
                        })
                    );
                },
            },
        })
    );
};

export const deleteRebate = ({ applicationNumber, rebate, onConfirmed, checkCalculationStatus }) => {
    const text = `<p>Would you like to delete payment <b>${rebate.typeName}</b> for <b>$${rebate.amount}</b>?</p>`;

    store.dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: "Delete Payment",
                overlayClassName: "modal-styled",
                className: "delete-payment-confirmation-modal modal-sm",
                modalIcon: "delete-trash-empty",
                content: <RawHtml>{text}</RawHtml>,
                footerContentCenter: true,
                onConfirm: () => {
                    if (onConfirmed) {
                        onConfirmed();
                    }

                    store.dispatch(
                        deleteResource({
                            resourceName: "applicationRebates",
                            resourceId: rebate.number,
                            path: {
                                appId: applicationNumber,
                            },
                            onSuccess: () => {
                                checkCalculationStatus().then(() => {
                                    refreshApplicationPayments({
                                        applicationNumber,
                                    });
                                    refreshSidebarEquipment({
                                        applicationNumber,
                                    });
                                    store.dispatch(modalClose());
                                });
                            },
                        })
                    );
                },
            },
        })
    );
};

const getDateLabelByRebateAction = (action) => {
    let label = "";

    switch (action) {
        case rebateAction.approve:
            label = "Approved";
            break;

        case rebateAction.commit:
            label = "Committed";
            break;

        case rebateAction.transfer:
            label = "Transferred";
            break;

        default:
            break;
    }

    return label;
};
