import React, { useState, memo, useCallback } from "react";

import { takeContractorsFromList, useRequireContractor } from "../../../../../views/ProjectView/utils";
import { onAssign, onUnassign, getContactType } from "./ContactsUtils";
import { onContractorLookup } from "../../../../Contractor/actions";

import WaitIcon from "../../../../WaitIcon";
import IconWrap from "../../../../Icons";
import FieldGroupDropdownIcon from "components/ui/FieldGroupDropdownIcon";
import { systemUserRights } from "components/utils/user";
import { useProgramRights } from "store/resources/useResource";

export const ContactActionsSection = memo(
    ({
        applicationNumber,
        contact,
        isLocked,
        isAssigned,
        isPremise,
        isPrimary,
        isContractor,
        onSelectContractor,
        onDelete,
        onEdit,
        assignedContacts,
        programNumber,
    }) => {
        const requireContractorOption = useRequireContractor({
            applicationNumber,
        });

        const isContractorEditable = isContractor && requireContractorOption !== "r";

        const { isNew, contactnumber } = contact ?? {};
        const [isSubmitting, setIsSubmitting] = useState();
        const contactType = getContactType({
            contact,
            isPremise,
            isPrimary,
            isContractor,
        });
        const [programRights = []] = useProgramRights({ programNumber });
        const isEditContactDisabled = !programRights.includes(systemUserRights.VISIONDSM_ADD_APPLICATION);

        return (
            <div className="actions flex-row">
                {isSubmitting ? (
                    <WaitIcon />
                ) : (
                    <>
                        {isContractor && (
                            <SearchContractorAction
                                applicationNumber={applicationNumber}
                                isLocked={isLocked}
                                contact={contact}
                                onSelect={onSelectContractor}
                                programNumber={programNumber}
                            />
                        )}
                        <AssignAction
                            applicationNumber={applicationNumber}
                            contactNumber={contactnumber}
                            isLocked={isLocked}
                            isNew={isNew}
                            isAssigned={isAssigned}
                            setIsSubmitting={setIsSubmitting}
                            assignedContacts={assignedContacts}
                            disabled={isEditContactDisabled}
                        />
                        <EditAction
                            applicationNumber={applicationNumber}
                            isLocked={isLocked}
                            isNew={isNew}
                            contact={contact}
                            isContractor={isContractor}
                            isContractorEditable={isContractorEditable}
                            onEdit={onEdit}
                            disabled={isEditContactDisabled}
                        />
                        <UnassignAction
                            applicationNumber={applicationNumber}
                            contactType={contactType}
                            contact={contact}
                            isAssigned={isAssigned}
                            isLocked={isLocked}
                            setIsSubmitting={setIsSubmitting}
                            disabled={isEditContactDisabled}
                        />
                        <DeleteAction
                            isLocked={isLocked}
                            isAssigned={isAssigned}
                            isNew={isNew}
                            onDelete={() => onDelete(contact)}
                            disabled={isEditContactDisabled}
                        />
                    </>
                )}
            </div>
        );
    }
);

export const SearchContractorAction = ({ applicationNumber, isLocked, contact, onSelect, programNumber }) => {
    const takeFromList = takeContractorsFromList({ applicationNumber });

    const [programRights = []] = useProgramRights({ programNumber });

    const isSearchContractorDisabled = isLocked || !programRights.includes(systemUserRights.VISIONDSM_ADD_APPLICATION);

    if (!isSearchContractorDisabled && contact && takeFromList) {
        return (
            <IconWrap
                className="contact-action"
                iconWrap="search"
                title="Search for a Contractor"
                onClick={() => onContractorLookup({ applicationNumber, onSelect })}
            />
        );
    }

    return null;
};

const AssignAction = ({ applicationNumber, contactNumber, isLocked, isNew, isAssigned, setIsSubmitting, assignedContacts, disabled }) => {
    const takeFromList = takeContractorsFromList({ applicationNumber });

    const availableTypes = ["Primary", "Premise", "Contractor"].filter((contactType) => !(takeFromList && contactType === "Contractor"));

    const [isActive, setIsActive] = useState(false);

    const listItems = availableTypes.map((contactType) => ({
        icon: "user",
        label: "Assign as " + contactType,
        contactType,
    }));

    const onSelect = useCallback(
        (item) => {
            setIsSubmitting(true);
            onAssign({
                applicationNumber,
                contactNumber,
                assignType: item.contactType,
                onComplete: () => {
                    setIsSubmitting(false);
                },
                assignedContacts,
            });
        },
        [applicationNumber, contactNumber, assignedContacts, setIsSubmitting]
    );

    if (isLocked) {
        return null;
    }
    if (!isAssigned && !isNew) {
        return (
            <FieldGroupDropdownIcon
                title={"Assign to Application as " + availableTypes.join(", ")}
                dropdownOptions
                iconWrapDropdown
                mobileHeader={"Assign to Application as " + availableTypes.join(", ")}
                visible={isActive}
                iconWrapActive={isActive}
                iconWrap="copy-link"
                onClick={() => setIsActive((prev) => !prev)}
                onSelect={onSelect}
                items={listItems}
                withPopper={isActive}
                popupPosition="bottom-end"
                disabled={disabled}
            />
        );
    }

    return null;
};

const UnassignAction = ({ applicationNumber, contactType, contact, isLocked, isAssigned, setIsSubmitting, disabled }) => {
    const onClick = () => {
        setIsSubmitting(true);
        onUnassign({
            applicationNumber,
            assignType: contactType,
            onComplete: () => {
                setIsSubmitting(false);
            },
        });
    };

    if (contact && !isLocked && isAssigned) {
        return <IconWrap iconWrap="cut-scissors-filled" title="Unassign Contact" onClick={onClick} disabled={disabled} />;
    }

    return null;
};

export const EditAction = ({ applicationNumber, isLocked, isNew, contact, isContractor, isContractorEditable, onEdit, disabled }) => {
    const takeFromList = takeContractorsFromList({ applicationNumber });

    if (isContractor && (takeFromList || !isContractorEditable)) {
        return null;
    }

    if (!isNew && contact) {
        return (
            <IconWrap
                className="contact-action"
                icon={isLocked ? "eye-visibility-empty" : "edit-empty"}
                title={isLocked ? "View Contact" : "Edit Contact"}
                onClick={onEdit}
                disabled={disabled}
            />
        );
    }

    return null;
};

const DeleteAction = ({ isLocked, isAssigned, isNew, onDelete, disabled }) => {
    if (isLocked) {
        return null;
    }
    if (!isAssigned && !isNew) {
        return <IconWrap iconWrap="delete-trash-empty" title="Delete Contact" onClick={onDelete} disabled={disabled} />;
    }

    return null;
};
