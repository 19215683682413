import FieldGroupDropdownIcon from "components/ui/FieldGroupDropdownIcon";
import ScrollControls from "components/ui/ScrollControls";
import React, { useId, useState } from "react";
import { ScrollSyncPane } from "react-scroll-sync";
import { Role, RoleActionType, UserRole } from "./types";

export const RolesColumnHeader = ({ roles, clientRoleId, onRoleAction }: RolesColumnHeaderProps) => {
    const targetId = useId();

    return (
        <>
            <ScrollSyncPane group="roles-list">
                <div id={targetId} className="roles-management-roles-header flex-row no-shrink">
                    {roles.map((role: Role) => (
                        <RoleColumnHeader key={role.roleID} clientRoleId={clientRoleId} role={role} onRoleAction={onRoleAction} />
                    ))}
                </div>
            </ScrollSyncPane>
            <ScrollControls
                targetId={targetId}
                horizontalScroll
                activeItemIndex={undefined}
                noWrap={undefined}
                iconSmall={undefined}
                updateInterval={undefined}
                onChange={undefined}
            />
        </>
    );
};
interface RolesColumnHeaderProps {
    clientRoleId?: number;
    roles: UserRole[];
    onRoleAction: (roleID: number, actionType: RoleActionType) => void;
}
const RoleColumnHeader = ({ role, clientRoleId, onRoleAction }: RoleColumnHeaderProps) => {
    const [isActive, setIsActive] = useState(false);

    const onAction = ({ actionType }: { actionType: RoleActionType }) => {
        setIsActive(!isActive);
        onRoleAction(role.roleID, actionType);
    };

    const isClientRole = clientRoleId === role.roleID;

    const actionItems = [
        {
            label: "Select all",
            actionType: RoleActionType.SelectAll,
        },
        {
            label: "Unselect all",
            actionType: RoleActionType.UnselectAll,
            disabled: isClientRole,
            title: isClientRole ? "You can't unselect client role" : undefined,
        },
        {
            label: "Show rights",
            actionType: RoleActionType.ShowRights,
        },
        {
            label: "Show users",
            actionType: RoleActionType.ShowUsers,
        },
    ];

    return (
        <div className="roles-management-column-header roles-management-column-header--role flex-column flex-one">
            <FieldGroupDropdownIcon
                //@ts-ignore
                title={undefined}
                className="role-actions-dropdown"
                dropdownOptions
                iconWrapDropdown
                mobileHeader="Role actions"
                visible={isActive}
                iconWrapActive={isActive}
                iconWrap="more-vertical"
                onClick={() => setIsActive((prev) => !prev)}
                onSelect={onAction}
                items={actionItems}
                withPopper={isActive}
                popupPosition="bottom-start"
            />
            <div className="roles-management-header-column-title">{role.roleName}</div>
        </div>
    );
};
interface RoleColumnHeaderProps {
    clientRoleId?: number;
    role: Role;
    onRoleAction: (roleID: number, actionType: RoleActionType) => void;
}
