import React, { memo, useCallback } from "react";
import { PropertyListItem } from "../Property/PropertyListItem";
import { SelectBoxProperty } from "../Property/SelectBoxProperty";
import { SwitchProperty } from "../Property/SwitchProperty";
import { TextProperty } from "../Property/TextProperty";
import { LinkType, ValidationRule } from "../../types";
import { isEmpty } from "lodash";

export const CookiesLinkProperty = memo((props) => {
    const { value, onChange, errors = {} } = props;
    const containsActiveError = !isEmpty(errors.cookiesBannerLinkUrl);
    const enableCookiesBannerLink = value.enableCookiesBannerLink ?? true;

    const items = [
        {
            text: "Internal",
            value: "internal",
        },
        {
            text: "External",
            value: "external",
        },
    ];

    const onPropertyChange = useCallback(
        (id, value) => {
            const val = id === "cookiesBannerLinkOpenInNewTab" ? "_blank" : true;
            if (!value) {
                onChange(id, false);
            } else {
                onChange(id, val);
            }
        },
        [onChange]
    );

    return (
        <PropertyListItem className="property-list-item--link-url flex-column align-center justify-space-between" innerContent>
            <SwitchProperty
                className="enable-link-switch"
                title='Enable "Read more" link'
                id="enableCookiesBannerLink"
                fullWidth={true}
                value={enableCookiesBannerLink}
                onChange={(id, value) => onChange(id, value)}
            />
            {enableCookiesBannerLink && (
                <>
                    <SelectBoxProperty
                        id="cookiesPageLink"
                        value={value.cookiesPageLink ?? LinkType.INTERNAL}
                        title='"Read more" link url'
                        items={items}
                        borderBottom={false}
                        onChange={onChange}
                        validationRules={[ValidationRule.Required]}
                    />
                    <TextProperty
                        errors={errors.cookiesBannerLinkUrl}
                        id="cookiesBannerLinkUrl"
                        value={value.cookiesBannerLinkUrl ?? "/cookies"}
                        fullWidth={true}
                        borderBottom={false}
                        onChange={onChange}
                        listContainsActiveError={containsActiveError}
                    />
                    {value.cookiesPageLink === LinkType.EXTERNAL && (
                        <SwitchProperty
                            title={"Open in the new Tab"}
                            id={"cookiesBannerLinkOpenInNewTab"}
                            value={value.cookiesBannerLinkOpenInNewTab ?? false}
                            onChange={(id, value) => onPropertyChange(id, value)}
                            borderBottom={false}
                        />
                    )}
                </>
            )}
        </PropertyListItem>
    );
});
