import { listToAnyOf } from "components/utils/form";

export const getFormSectionFields = (form, sectionNumber) => {
    const uiSchema = form?.formConfiguration?.uiSchema;
    const section = Object.keys(uiSchema ?? {})
        .filter((k) => uiSchema[k]["af:sectionNumber"] === sectionNumber)
        .map((k) => uiSchema[k])[0];
    const columns = Object.keys(section ?? {})
        .filter((k) => section[k]["af:columnNumber"])
        .map((k) => section[k]);
    return columns
        .map((column) =>
            Object.keys(column ?? {})
                .filter((k) => column[k]["af:fieldNumber"])
                .map((k) => column[k]["af:fieldNumber"])
        )
        .flat();
};

export const getAttribute = ({ number = null, code = null, workflowStepAttributeTypes }) => {
    if (number) {
        return workflowStepAttributeTypes.find((i) => i.number === number);
    } else if (code) {
        return workflowStepAttributeTypes.find((i) => i.code === code);
    }
};

export const getInitialPageFromAttributes = ({ attributes, workflowStepAttributeTypes }) => {
    const attribute = getAttribute({
        code: "formpgnum",
        workflowStepAttributeTypes,
    });
    return attributes.find((a) => a.typeNumber === attribute?.number)?.value;
};

export const getProperties = ({ fields, pages, pageSections, pageFields, workflowStepAttributeTypes }) => {
    return fields.reduce((properties, attrCode) => {
        const title = getAttribute({
            code: attrCode,
            workflowStepAttributeTypes,
        })?.name;
        const type = "string";
        let anyOf = [];

        if (attrCode === "formpgnum") {
            anyOf = listToAnyOf({
                list: pages,
                map: (item) => ({ title: item.name, enum: [item.number] }),
            });
        }

        if (attrCode === "formsecnum") {
            anyOf = listToAnyOf({
                list: pageSections,
                map: (item) => ({
                    title: item.name,
                    enum: [String(item.value)],
                }),
            });
        }

        if (attrCode === "appFieldID") {
            anyOf = listToAnyOf({
                list: pageFields,
                map: (item) => ({
                    title: item.friendlyName,
                    enum: [String(item.fieldId)],
                }),
            });
        }

        return {
            ...properties,
            [attrCode]: {
                title,
                type,
                anyOf,
            },
        };
    }, {});
};
