import React, { useEffect, useMemo, memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "lodash";

import { dashboardTypes } from "../../utils/dashboard";
import { useResource } from "../../../store/resources/useResource";
import { setActiveSubMenu } from "../../../store/subMenu/actions";
import useSidePanelHandlers from "../../utils/useSidePanelHandlers";
import { sideNavKey, sideNavSize } from "../../ui/SideNav/SideNavRoot";

import WaitIcon from "../../ui/WaitIcon";
import ViewWithSubmenu from "../../ui/ViewWithSubmenu";
import DashboardSettings from "../../ui/Dashboard/DashboardSettings";
import {
    AVAILABLE_DASHBOARDS_KEY,
    createLandingPageSubMenuItem,
    getDashboardFormComponent,
} from "components/ui/Dashboard/DashboardSettings/utils";

import "./style.scss";

const HomeDashboardView = memo(() => {
    const viewName = "Dashboard";

    const dispatch = useDispatch();
    const activeMenuItem = useSelector((state) => state.subMenu.activeSubMenu[viewName]);
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        sideNavKey: sideNavKey.globalRight,
        size: sideNavSize.small,
    });

    const [dashboards = [], isLoadingDashboards] = useResource({
        resourceName: "dashboards",
        key: dashboardTypes.LANDING_PAGE,
        query: {
            dashboardType: dashboardTypes.LANDING_PAGE,
        },
        transform: (data) => {
            const customDashboards = (data || []).filter((d) => !d.isGlobal);
            return customDashboards.length > 0 ? customDashboards : data;
        },
    });

    const [availablePanels, isLoadingAvailablePanels] = useResource({
        resourceName: "dashboardsAvailable",
        key: AVAILABLE_DASHBOARDS_KEY,
        transform: (data) => data?.availableDashboardList ?? [],
    });

    const menuItems = useMemo(
        () => dashboards.filter((d) => d.isGlobal || d.show).map((d) => createLandingPageSubMenuItem(d, availablePanels)),
        [dashboards, availablePanels]
    );

    const onOpenDashboardSettings = useCallback(
        (dashboard) => {
            const component = getDashboardFormComponent({
                dashboardType: dashboardTypes.LANDING_PAGE,
                dashboard,
                setIsLocked: () => {},
                onClose: () => handleCloseSidePanel(),
            });
            handleOpenSidePanel(component);
        },
        [handleOpenSidePanel, handleCloseSidePanel]
    );

    useEffect(() => {
        if (menuItems.length > 0 && !activeMenuItem) {
            const activeItem = getActiveMenuItem({ dashboards, menuItems });

            dispatch(
                setActiveSubMenu({
                    key: viewName,
                    subMenu: activeItem ? activeItem : menuItems[0],
                })
            );
        }
    }, [dashboards, menuItems, activeMenuItem, dispatch]);

    // Update active menu item if active dashboard changed.
    useEffect(() => {
        if (activeMenuItem) {
            const menuItem = menuItems.find((i) => i.id === activeMenuItem.id);
            if (menuItem && !isEqual(activeMenuItem, menuItem)) {
                dispatch(
                    setActiveSubMenu({
                        key: viewName,
                        subMenu: menuItem,
                    })
                );
            }
        }
    }, [menuItems, activeMenuItem, dispatch]);

    const settings = <DashboardSettings dashboardType={dashboardTypes.LANDING_PAGE} />;

    const isLoading = isLoadingDashboards || isLoadingAvailablePanels;

    return (
        <ViewWithSubmenu
            className="home-dashboard-view"
            viewName={viewName}
            menuItems={menuItems}
            active={activeMenuItem}
            menuSettings={settings}
            isLoadingMenu={isLoading}
            viewPlaceholder={isLoading ? <WaitIcon /> : "No Dashboard Selected"}
            onOpenSettings={onOpenDashboardSettings}
        />
    );
});

const getActiveMenuItem = ({ dashboards, menuItems }) => {
    const defaultDashboard = dashboards.filter((d) => !d.isGlobal && d.isDefault && d.show)[0];

    if (defaultDashboard) {
        return menuItems.filter((i) => i.id === defaultDashboard.id)[0];
    }

    const globalDashboard = dashboards.filter((d) => d.isGlobal)[0];

    if (globalDashboard) {
        return menuItems.filter((i) => i.id === globalDashboard.id)[0];
    }

    return null;
};

export default HomeDashboardView;
