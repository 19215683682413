import React, { useEffect, useState, memo } from "react";
import { useDispatch } from "react-redux";

import { createResource } from "../../../store/resources/actions";
import { getCisCustomerDetailsResourceParams } from "../../../store/configureResources";
import { getInfoListItemKey, getInfoListItemValue } from "./utils";

import WaitIcon from "../WaitIcon";
import RawHtml from "../RawHtml";
import InfoList from "../List/InfoList";
import SideNavBody from "../SideNav/SideNavBody";
import SideNavFooter from "../SideNav/SideNavFooter";
import SideNavHeader from "../SideNav/SideNavHeader";
import SideNavContent from "../SideNav/SideNavContent";
import Button from "../Button";
import InlinePanelHeader from "../Dashboard/Panel/InlinePanelHeader";
import GridDetailsFooter from "../DataGrid/GridDetailsFooter";

import "./style.scss";
import "../DataGrid/GridDetailsPanel.scss";

const CisSearchDetails = memo((props) => {
    const dispatch = useDispatch();

    const { utilityNumber, dataItem, onClose, cisDetailsInSideNavPanel } = props;

    const [customerDetails, setCustomerDetails] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        dispatch(
            createResource({
                ...getCisCustomerDetailsResourceParams({
                    utilityNumber,
                    accountNumber: dataItem["ACCOUNT #"],
                    customerID: dataItem["ID"],
                    meterID: dataItem["METERID"],
                }),
                onSuccess: (action) => {
                    setCustomerDetails(action.data);
                },
                onComplete: () => {
                    setIsLoading(false);
                },
            })
        );
    }, [utilityNumber, dataItem, dispatch]);

    return (
        <>
            {!cisDetailsInSideNavPanel ? (
                <div className="grid-details-panel cis-details-inline-panel flex-column fill-width">
                    <InlinePanelHeader title={"CIS Customer Details"} onClose={onClose} />
                    {isLoading && <WaitIcon />}
                    {!isLoading && (
                        <>
                            <div className="cis_details with-scroll">
                                <InfoList
                                    items={customerDetails?.custInfoList?.map((i) => ({
                                        label: getInfoListItemKey(i),
                                        value: <RawHtml>{getInfoListItemValue(i) ?? "-"}</RawHtml>,
                                    }))}
                                />
                            </div>
                            <GridDetailsFooter {...props} dataItem={customerDetails} />
                        </>
                    )}
                </div>
            ) : (
                <SideNavContent>
                    <SideNavHeader title="CIS Customer Details" leadBlockIcon="eye-visibility-empty" smallHeader onClose={onClose} />
                    <SideNavBody noPadding className="flex-one-in-column">
                        {isLoading ? (
                            <WaitIcon />
                        ) : (
                            <div className="cis_details cis-search-item-details">
                                <InfoList
                                    items={customerDetails?.custInfoList?.map((i) => ({
                                        label: getInfoListItemKey(i),
                                        value: <RawHtml>{getInfoListItemValue(i) ?? "-"}</RawHtml>,
                                    }))}
                                />
                            </div>
                        )}
                    </SideNavBody>
                    {!isLoading && (
                        <SideNavFooter justifyEnd>
                            <Button onClick={onClose}>Close</Button>
                        </SideNavFooter>
                    )}
                </SideNavContent>
            )}
        </>
    );
});

export default CisSearchDetails;
