import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";

import { auditGridColumnKeys } from "../../../../../views/configureGrids";
import { exportDatagridToCSV } from "../../../../../utils/CSV";
import { getData, setFilter } from "../../../../../../store/dataGrid/actions";
import { auditType } from "../../../../../utils/auditType";
import { auditPanelTypes } from "./utils";

import IconWithLabel from "../../../../Icons/IconWithLabel";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import AuditTypesSelector from "../../../../AuditTypesSelector";

import AuditGrid from "./AuditGrid";

import "./style.scss";

const AuditPanel = memo(({ panel }) => {
    const dispatch = useDispatch();

    const applicationNumber = get(panel, "data.applicationNumber");
    const gridId = `${applicationNumber}-audit-grid`;

    const filter = useSelector((state) => get(state, `dataGrid.${gridId}.filter`));
    const { filters } = filter ?? {};

    const getSelectedAuditType = (filters = []) => {
        const auditTypeFilter = filters.find((f) => f.field === auditGridColumnKeys.audittype);
        const filterValue = auditTypeFilter?.value?.toLowerCase();
        return [auditType.project, auditType.task].includes(filterValue) ? filterValue : auditType.all;
    };

    const onChangeAuditType = (type) => () => {
        const filterIndex = filters.findIndex((f) => f.field === auditGridColumnKeys.audittype);
        const index = filterIndex > -1 ? filterIndex : filters.length;
        const newType = type === auditType.all ? "" : type;

        const newFilters = [...filters];
        newFilters[index] = {
            field: auditGridColumnKeys.audittype,
            value: newType,
        };
        dispatch(
            setFilter({
                dataGridId: gridId,
                filter: { ...filter, filters: newFilters },
            })
        );
        dispatch(
            getData({
                dataGridId: gridId,
            })
        );
    };

    const selectedAuditType = getSelectedAuditType(filters);

    return (
        <div className="panel audit">
            <PanelHeaderLarge title="History" />
            <div className="grid-controls flex-row align-center">
                <AuditTypesSelector items={auditPanelTypes} type={selectedAuditType} onChange={onChangeAuditType} />
                <span className="flex-one" />
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() => {
                        exportDatagridToCSV({
                            dataGridId: gridId,
                            fileName: "application_history",
                            fileNamePostfix: applicationNumber,
                            formatColumnType: "datetimeWithSeconds",
                            formatColumnKey: auditGridColumnKeys.date,
                        });
                    }}
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <AuditGrid
                filter={`${auditGridColumnKeys.entityNumber}=${applicationNumber}`}
                applicationNumber={applicationNumber}
                gridId={gridId}
            />
        </div>
    );
});

export default AuditPanel;
