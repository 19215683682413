import React, { memo } from "react";
import { notesGridColumnKeys } from "../../../../../views/configureGrids";
import { exportDatagridToCSV } from "../../../../../utils/CSV";
import { getNotesGridId, refreshNotesGrid, refreshSidebarNotes } from "./utils";
import { systemUserRights } from "components/utils/user";
import IconWithLabel from "../../../../Icons/IconWithLabel";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import NotesGrid from "./NotesGrid";
import { useProgramRights } from "store/resources/useResource";
import NotesEditForm from "./NotesEditForm";

import "./NotesPanel.scss";
import "../ProjectCommonStyles.scss";

const notesGridSort = [
    {
        field: notesGridColumnKeys.date,
        dir: "desc",
    },
];

const NotesPanel = memo(({ panel }) => {
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
    });

    const applicationNumber = panel && panel.data && panel.data.applicationNumber;
    const programNumber = panel && panel.data && panel.data.programNumber;
    const [programRights = []] = useProgramRights({ programNumber });
    const gridId = getNotesGridId({ applicationNumber });

    const isAddNoteDisabled = !programRights.includes(systemUserRights.VISIONDSM_ADD_NOTES);

    const onAdd = () => {
        handleOpenSidePanel(<NotesEditForm applicationNumber={applicationNumber} onSaved={onSaved} onClose={handleCloseSidePanel} />);
    };

    const onSaved = () => {
        onRefresh();
        handleCloseSidePanel();
    };

    const onRefresh = () => {
        refreshNotesGrid({ applicationNumber });
        refreshSidebarNotes({ applicationNumber });
    };

    return (
        <div className="panel notes">
            <PanelHeaderLarge title="Notes" />
            <div className="data-grid-controls flex-row align-center">
                <span className="flex-one" />
                <IconWithLabel disabled={isAddNoteDisabled} icon="plus" onClick={onAdd}>
                    Add Note
                </IconWithLabel>
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: gridId,
                            fileName: "application_notes",
                            fileNamePostfix: applicationNumber,
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <div className="panel notes-wrap flex-column">
                <NotesGrid
                    gridId={gridId}
                    filter={`${notesGridColumnKeys.refId}=${applicationNumber}`}
                    sort={notesGridSort}
                    applicationNumber={applicationNumber}
                    onSaved={onRefresh}
                    programNumber={programNumber}
                />
            </div>
        </div>
    );
});

export default NotesPanel;
