import React from "react";
import cn from "classnames";
import { PropertySize } from "../../types";

export const PropertyListItem = React.forwardRef<HTMLDivElement, PropertyListItemProps>(
    (
        {
            className,
            children,
            borderTop = false,
            borderBottom = true,
            innerContent = false,
            size = undefined,
            onMouseOver = undefined,
            onMouseLeave = undefined,
        },
        ref
    ) => (
        <div
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            ref={ref}
            className={cn("property-list-item", className, {
                "property-list-item--border-top": borderTop,
                "property-list-item--no-border-bottom": !borderBottom,
                "property-list-item--inner-content": innerContent,
                "property-list-item--size-md": size === PropertySize.MD,
            })}
        >
            {children}
        </div>
    )
);

interface PropertyListItemProps {
    className?: string;
    children: React.ReactNode;
    borderTop?: boolean;
    borderBottom?: boolean;
    innerContent?: boolean;
    size?: PropertySize;
    onMouseOver?: () => void;
    onMouseLeave?: () => void;
}
