import { isEqual, isObject } from "lodash";
import { useRef } from "react";
import { getPortalConfigurationResourceParams } from "store/configureResources";
import { useResource } from "store/resources/useResource";
import { transformProgramPortalConfig } from "./page";

export const useProgramPortalConfiguration = ({ programNumber, forced = false }: { programNumber?: string; forced: boolean }) => {
    const configRef = useRef(undefined);

    const [data, isLoading] = useResource({
        ...getPortalConfigurationResourceParams({ programNumber }),
        forced,
        showErrorNotification: false,
        transform,
    });

    // Return previous value if configs are equal. This is to prevent unnecessary re-renders.
    const equal = isEqual(data, configRef.current);
    if (!equal) {
        configRef.current = data;
    }

    return [configRef.current, isLoading];
};

const transform = (data: PortalConfigurationResponse) => {
    if (isObject(data)) {
        return transformProgramPortalConfig(data);
    }
    return undefined;
};

export interface PortalConfigurationResponse {
    programTemplateNumber?: string;
    programTemplateConfiguration?: string;
    utilityTemplateNumber?: string;
    utilityTemplateConfiguration?: string;
    utilityTemplateName?: string;
}
