import React, { memo } from "react";

import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid";

const ItemGrid = memo((props) => {
    const { gridId, dataGridConfig } = props;

    return <DataGrid name={gridId} config={dataGridConfig} customRowActions={[]} />;
});

export default withDataGrid("invoiceItemAudit", ItemGrid);
