import React, { useCallback, memo } from "react";

import { allHostnamesGridColumnKeys } from "../../configureGrids.js";
import { openUtilityTab, openUrl } from "../../../utils/window.js";
import { openProgramTab } from "store/window/openTabActions";
import { hasAnyOfPermissions, systemUserRights } from "components/utils/user.js";

import Form from "./Form.js";
import ActionLabel from "../../../ui/Label/ActionLabel.js";
import DataGrid from "../../../ui/DataGrid";
import withDataGrid from "../../../ui/DataGrid/withDataGrid.js";
import { useDispatch } from "react-redux";

const AllHostnamesGrid = memo(({ gridId, dataGridConfig }) => {
    const dispatch = useDispatch();

    const customRowActions = [
        {
            name: "edit",
            icon: "edit-empty",
            title: "Edit",
            hide: () => !hasAnyOfPermissions([systemUserRights.VISIONDSM_MANAGE_SYSTEM]),
        },
        {
            name: "url",
            icon: "arrow-right-up",
            title: (row) => (row[allHostnamesGridColumnKeys.hostname] ? "Website" : "There is no website configured for this program"),
            disabled: (row) => !row[allHostnamesGridColumnKeys.hostname],
        },
    ];

    const onRowAction = useCallback((action, onExpand) => {
        const { name, dataItem } = action;

        switch (name) {
            case "edit":
                onExpand((props) => <Form {...props} />);
                break;

            case "url":
                openUrl(dataItem[allHostnamesGridColumnKeys.hostname]);
                break;

            default:
                break;
        }
    }, []);

    return (
        <DataGrid
            name={gridId}
            config={dataGridConfig}
            customRowActions={customRowActions}
            onRowAction={onRowAction}
            columnCellContent={{
                [allHostnamesGridColumnKeys.program]: (column, row) => (
                    <ActionLabel
                        onClick={() =>
                            dispatch(
                                openProgramTab({
                                    programNumber: row[allHostnamesGridColumnKeys.programNumber],
                                })
                            )
                        }
                    >
                        {row[column.key]}
                    </ActionLabel>
                ),
                [allHostnamesGridColumnKeys.utility]: (column, row) => (
                    <ActionLabel
                        onClick={() =>
                            openUtilityTab({
                                utilityNumber: row[allHostnamesGridColumnKeys.utilityNumber],
                            })
                        }
                    >
                        {row[column.key]}
                    </ActionLabel>
                ),
                [allHostnamesGridColumnKeys.hostname]: (column, row) => {
                    if (row[column.key]) {
                        return <ActionLabel onClick={() => openUrl(row[column.key])}>{row[column.key]}</ActionLabel>;
                    }

                    return row[column.key];
                },
            }}
        />
    );
});

export default withDataGrid("allHostnames", AllHostnamesGrid);
