import React, { useState, useEffect, useCallback, memo, useMemo } from "react";
import { useDispatch } from "react-redux";
import { isNil } from "lodash";
import cn from "classnames";

import useUnmounted from "../../../../../utils/useUnmounted";
import { getResource } from "../../../../../../store/resources/actions";
import { formatJsonDateTime } from "../../../../../utils/date";
import { isApplicationLocked } from "../../../../../views/ProjectView/utils";
import { submitByRef } from "../../../../../utils/form";
import { useProgramRights } from "store/resources/useResource";
import { ProgramRights } from "components/utils/user";
import { isNullOrWhitespace } from "components/utils/string";

import Label from "../../../../Label";
import Button from "../../../../Button";
import WaitIcon from "../../../../WaitIcon";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import NumericInput from "../../../../Input/NumericInput";
import EquipmentAttributesForm from "./EquipmentAttributesForm";
import AddApprovedEquipmentForm from "./AddApprovedEquipmentForm";
import { useIsMobile } from "components/utils/useIsMobile";
import { useEquipment, useEquipmentCatalog, useApprovedEquipment } from "./utils";
import ErrorMessage from "components/ui/Message/ErrorMessage";

const EquipmentDetails = memo(
    ({
        formRef,
        equipmentId,
        catalogNumber,
        applicationNumber,
        programNumber,
        hideButtons,
        onClose,
        onSubmit,
        onChange,
        submitButtonText,
        submitButtonIcon,
        headerLeftAlignedTitle,
        headerLeftAlignedIcon,
        cancelButtonText,
        viewOnly,
        showApprovedEquipmentSearch,
        onShowApprovedEquipmentSearch,

        approvedEquipmentDetails,
        onShowApprovedEquipmentDetails,
    }) => {
        const dispatch = useDispatch();
        const unmounted = useUnmounted();

        // We use initial value null to see that form is never touched and can apply default values
        const [attributesFormData, setAttributesFormData] = useState(null);

        const [quantity, setQuantity] = useState();
        const [quantityError, setQuantityError] = useState();
        const isAppLocked = isApplicationLocked({ applicationNumber });

        const [programRights = [], isLoadingProgramRights] = useProgramRights({ programNumber });

        // equipment and catalog item must be reloaded each time because data is changed outside of component
        // (on application save, on particular equipment save etc i.e.may change on any calculation engine execution)
        const [equipment, isLoadingEquipment] = useEquipment({ equipmentId, applicationNumber, forced: true });
        const [catalogItem, isLoadingEquipmentCatalog] = useEquipmentCatalog({ catalogNumber, applicationNumber, forced: true });
        const [approvedEquipment, isLoadingApprovedEquipment] = useApprovedEquipment({
            catalogNumber: catalogNumber ?? equipment?.catalogNumber,
            applicationNumber: equipment || catalogNumber ? applicationNumber : undefined, // request approved equipment only after equipment is loaded or catalogNumber is available
            forced: true,
        });

        const isLoading = isLoadingEquipment || isLoadingEquipmentCatalog || isLoadingApprovedEquipment || isLoadingProgramRights;

        const isMobile = useIsMobile();

        const hasApprovedEquipment = approvedEquipment && approvedEquipment.length > 0;

        const isNewEquipment = isNil(equipmentId);

        const equipmentItem = useMemo(() => {
            let item = equipmentId ? equipment : catalogItem;

            // Fix attibutes prop for catalogItem to be the same as for equipment
            if (!equipmentId && item) {
                item = {
                    ...item,
                    attributes: catalogItem && catalogItem.attributes && catalogItem.attributes.attributeItem,
                };
            }

            return item;
        }, [catalogItem, equipment, equipmentId]);

        const hasRightsToEdit = programRights.includes(ProgramRights.VISIONDSM_ADD_EQUIPMENT);
        const hasRightsToEditAll = programRights.includes(ProgramRights.VISIONDSM_EDIT_ALL_EQUIPMENT);

        // Perform equipment validation on attribute form submit
        if (formRef?.current) {
            formRef.current.preSubmit = () => {
                setQuantityError(isNullOrWhitespace(quantity) ? "is a required property" : undefined);
            };
        }

        // Set quantity after equipment is loaded
        useEffect(() => {
            if (!unmounted.current && !isNil(equipmentItem?.quantity)) {
                setQuantity(Number(equipmentItem.quantity));
            }
        }, [equipmentItem, unmounted]);

        useEffect(() => {
            // Start to trigger onChange when attributes form is initialized
            if (!isNil(attributesFormData) && onChange) {
                onChange({ ...attributesFormData, quantity });
            }
        }, [attributesFormData, quantity, onChange]);

        const getApprovedEquipment = useCallback((data) => {
            if (data && data.approvedMeasure) {
                return {
                    attributes: (data.approvedMeasure.attributeItem || [])
                        .filter((i) => i.attributeName)
                        .map((attr) => ({
                            attributename: (attr?.attributeName ?? "").toLowerCase(),
                            value: attr.val,
                        })),
                };
            }

            return {};
        }, []);

        const applyApprovedEquipmentAttributes = useCallback(
            (approvedEquipment) => {
                const formData = (approvedEquipment.attributes ?? []).reduce((result, attribute) => {
                    const attributeName = (attribute?.attributename ?? "").toLowerCase();

                    if (attribute && result.hasOwnProperty(attributeName)) {
                        result[attributeName] = attribute.value;
                    }

                    return result;
                }, attributesFormData);

                setAttributesFormData({
                    ...formData,
                });
            },
            [attributesFormData, setAttributesFormData]
        );

        const handleApprovedEquipmentSelect = useCallback(
            (data, onComplete) => {
                const { itemId, industryMeasureNumber } = data;

                dispatch(
                    getResource({
                        resourceName: "approvedEquipmentDetails",
                        resourceId: itemId,
                        path: {
                            industryMeasureNumber,
                        },
                        onSuccess: (action) => {
                            if (action.data) {
                                applyApprovedEquipmentAttributes(getApprovedEquipment(action.data));
                            }
                        },
                        onComplete: () => {
                            if (onComplete) {
                                onComplete();
                            }

                            onShowApprovedEquipmentDetails(null);
                        },
                    })
                );
            },
            [applyApprovedEquipmentAttributes, getApprovedEquipment, onShowApprovedEquipmentDetails, dispatch]
        );

        const handleAttributesFormChange = useCallback((formData) => {
            setAttributesFormData(formData);
        }, []);

        const onSubmitClick = useCallback(() => {
            submitByRef(formRef);
        }, [formRef]);

        const handleSubmit = useCallback(() => {
            if (isNullOrWhitespace(quantity)) {
                return;
            }

            const attributes = Object.keys(attributesFormData).map((key) => ({
                attributename: key,
                attributevalue: String(attributesFormData[key] ?? ""),
            }));

            const data = {
                catalogNumber: equipment ? equipment.catalogNumber : catalogNumber,
                quantity: +quantity,
                attributes,
            };

            onSubmit(data);
        }, [attributesFormData, catalogNumber, equipment, quantity, onSubmit]);

        const infoField = (label, value) => (
            <>
                <Label contentLabel>{label}</Label>
                <div className="equipment-info-value">{value}</div>
            </>
        );

        if (isLoading) {
            return (
                <div className="equipment-details">
                    <WaitIcon />
                </div>
            );
        }

        if (!equipmentItem) {
            return (
                <div className="equipment-details">
                    <ErrorMessage spaceAround>Equipment not found</ErrorMessage>
                </div>
            );
        }

        const renderEquipmentInfo = () => {
            return (
                <>
                    <div className="flex-column">{infoField("Category", equipmentItem.category)}</div>
                    <div className="flex-column">{infoField("Name", equipmentItem.name)}</div>
                    {equipmentItem.refid ? (
                        <div className="flex-column ref-id">{equipmentItem.refid && infoField("Ref.ID", equipmentItem.refid)}</div>
                    ) : (
                        <div className="flex-column blank-column"></div>
                    )}
                    <div className="flex-column quantity-input">
                        <NumericInput
                            label={equipmentItem.quantityFriendlyName ?? "Quantity"}
                            required
                            disabled={isAppLocked}
                            error={quantityError}
                            value={quantity}
                            name="quantity"
                            min={-1000000000}
                            onChange={(event) => setQuantity(event.target.value)}
                        />
                    </div>
                    {equipmentItem.dateentered ? (
                        <div className="flex-column">
                            {equipmentItem.dateentered && infoField("Entered", formatJsonDateTime(equipmentItem.dateentered))}
                        </div>
                    ) : (
                        <div className="flex-column blank-column"></div>
                    )}
                    {equipmentItem.dateedited ? (
                        <div className="flex-column">
                            {equipmentItem.dateedited && infoField("Edited", formatJsonDateTime(equipmentItem.dateedited))}
                        </div>
                    ) : (
                        <div className="flex-column blank-column"></div>
                    )}
                </>
            );
        };

        return (
            <div className={"equipment-details" + (!isMobile ? " flex-one flex-row fill-height no-scroll" : "")}>
                {!approvedEquipmentDetails && (
                    <>
                        {!isMobile ? (
                            <SideNavHeader
                                title={headerLeftAlignedTitle}
                                leadBlockIcon={headerLeftAlignedIcon}
                                sidenavHeaderLeftAligned
                                onClose={onClose}
                            >
                                {renderEquipmentInfo()}
                            </SideNavHeader>
                        ) : (
                            <div className="flex-row equipment-info">{renderEquipmentInfo()}</div>
                        )}
                    </>
                )}

                <div
                    className={cn("equipment-form flex-column with-scroll", {
                        "approved-equipment-list": showApprovedEquipmentSearch && !isAppLocked,
                        "approved-equipment-list-details": approvedEquipmentDetails,
                    })}
                >
                    {showApprovedEquipmentSearch ? (
                        <AddApprovedEquipmentForm
                            isApplyApprovedEquipment
                            title="Apply Approved Equipment"
                            applicationNumber={applicationNumber}
                            catalogNumber={catalogNumber ?? equipment?.catalogNumber}
                            onShowApprovedEquipmentDetails={onShowApprovedEquipmentDetails}
                            approvedEquipmentDetails={approvedEquipmentDetails}
                            onClose={() => onShowApprovedEquipmentSearch(false)}
                            onSelectResult={handleApprovedEquipmentSelect}
                        />
                    ) : (
                        <>
                            {hasApprovedEquipment && !showApprovedEquipmentSearch && !isAppLocked && (
                                <div className="flex-row equipment-inline-actions">
                                    <Button onClick={() => onShowApprovedEquipmentSearch(true)} disabled={viewOnly}>
                                        Apply Approved Equipment
                                    </Button>
                                </div>
                            )}
                            <div className="flex-row equipment-attributes">
                                <EquipmentAttributesForm
                                    isNewEquipment={isNewEquipment}
                                    formRef={formRef}
                                    equipment={equipmentItem}
                                    values={attributesFormData}
                                    hasRightsToEdit={hasRightsToEdit}
                                    hasRightsToEditAll={hasRightsToEditAll}
                                    viewOnly={isAppLocked || viewOnly}
                                    onChange={handleAttributesFormChange}
                                    onSubmit={handleSubmit}
                                />
                            </div>
                        </>
                    )}
                </div>
                {!hideButtons && (
                    <div className="flex-row equipment-actions">
                        {!isAppLocked && (
                            <Button primary icon={submitButtonIcon} onClick={onSubmitClick}>
                                {submitButtonText}
                            </Button>
                        )}
                        <Button onClick={onClose}>{cancelButtonText}</Button>
                    </div>
                )}
            </div>
        );
    }
);

export default EquipmentDetails;
