import React, { useState, useRef, memo, useEffect } from "react";
import { programsAttributesGridColumnKeys } from "../../../../../../views/configureGrids";
import { mapGridRowToObject } from "../../../../../../utils/datagrid";
import JsonSchemaForm from "../../../../../Form/JsonSchema/JsonSchemaForm";

import { submitResource, submitByRef } from "../../../../../../utils/form";
import { onAttributeChanged } from "./utils";

import SideNavBody from "../../../../../SideNav/SideNavBody";
import SideNavHeader from "../../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../../SideNav/SideNavContent";
import SideNavFooter from "../../../../../SideNav/SideNavFooter";
import Button from "../../../../../Button";
import WaitIcon from "components/ui/WaitIcon";
import { useResource } from "store/resources/useResource";
import { referenceTypes } from "components/ui/Reference/referenceTypes";

const AttributesFormPreload = (props) => {
    const [attributeTypes, isLoading] = useResource({
        resourceName: "entityReference",
        key: props.programNumber,
        path: {
            entityNumber: props.programNumber,
        },
        query: {
            type: referenceTypes.programAttribute,
            currentGeneralAttributeNumber: props.attributeNumber,
        },
        transform: (data) => data?.referenceResults ?? [],
        forced: true,
    });
    if (isLoading) {
        return <WaitIcon />;
    }

    return <AttributesForm {...props} attributeTypes={attributeTypes} />;
};

const AttributesForm = memo(({ onClose, dataItem, programNumber, gridId, sidePanel, attributeTypes, attribute }) => {
    const isNew = dataItem == null && attribute == null;

    const initialValues = isNew ? {} : attribute ? attribute : mapGridRowToObject(programsAttributesGridColumnKeys, dataItem);

    const availableAttributeTypes = attributeTypes.map((i) => ({
        title: i.display,
        enum: [+i.val],
    }));

    if (!isNew) {
        const defaultAttrType = availableAttributeTypes.find((attr) => attr.title === initialValues.attributeType);
        if (defaultAttrType) {
            initialValues.dataType = defaultAttrType.enum[0];
        }
    }

    const required = ["dataType", "dataValue"];

    const schema = {
        type: "object",
        required,
        properties: {
            dataType: {
                type: "integer",
                title: "Attribute Type",
                anyOf: availableAttributeTypes,
            },
            dataValue: {
                type: "string",
                title: "Attribute Value",
                default: initialValues != null ? initialValues.attributeValue : "",
            },
        },
    };

    const uiSchema = {
        dataType: {
            "ui:options": {
                placeholder: "Select Attribute Type",
            },
        },
        dataValue: {
            classNames: "fill-width",
            "ui:widget": "textarea",
        },
    };

    const [isSubmitting, setSubmitting] = useState(false);

    const onSubmit = (formData) => {
        const resourceParams = {
            resourceName: "generalAttributes",
        };

        const resourceId = isNew ? undefined : initialValues.attributeNumber;

        const body = {
            ...formData,
            tableName: "program attribute",
            primaryKeys: programNumber,
        };

        submitResource({
            resourceParams,
            resourceId,
            body,
            noResourceRefresh: true,
            onRefresh: () => {
                onAttributeChanged({
                    programNumber,
                    attributeType: formData.dataType,
                    dataGridId: gridId,
                });
            },
            onSuccess: sidePanel.close,
            setSubmitting,
        });
    };

    const formRef = useRef();
    const leadBlockIcon = isNew ? "plus" : "edit-empty";

    const getSubmitText = (isNew, isSubmitting) => {
        if (!isNew) {
            return isSubmitting ? "Saving..." : "Save";
        }

        return isSubmitting ? "Adding..." : "Add";
    };

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    return (
        <>
            <SideNavContent>
                <SideNavHeader
                    title={isNew ? "Add New attribute" : "Edit Attribute"}
                    leadBlockIcon={leadBlockIcon}
                    smallHeader
                    onClose={onClose}
                />
                <SideNavBody className="flex-one-in-column">
                    <JsonSchemaForm
                        formRef={formRef}
                        schema={schema}
                        uiSchema={uiSchema}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        submitText={getSubmitText(isNew, isSubmitting)}
                        disabled={isSubmitting}
                        noCancel
                        noSubmit
                        noReset
                    />
                </SideNavBody>
                <SideNavFooter setPrimaryButton>
                    <Button primary icon={isNew ? "plus" : ""} disabled={isSubmitting} onClick={() => submitByRef(formRef)}>
                        {getSubmitText(isNew, isSubmitting)}
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </SideNavFooter>
            </SideNavContent>
        </>
    );
});

export default AttributesFormPreload;
