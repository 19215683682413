import React, { useMemo } from "react";
import { getAccessibilityErrorsForExport } from "components/ui/PortalBuilder/utils/validation";
import WaitIcon from "components/ui/WaitIcon";
import { AccessibilityControls } from "./AccessibilityControls";
import { AccessibilityProperties } from "./AccessibilityProperties";
import { downloadCSV } from "components/utils/CSV";
import { useErrorContext } from "../../PortalBuilderErrorContextProvider";
import { AccessibilityValidationMessage } from "../../types";
import CustomList from "components/ui/List/CustomList";
import { PageDetails } from "./PageDetails";
import { PagesListItem } from "./PagesListItem";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";

const Accessibility = () => {
    const [activePageUrl, setActivePageUrl] = React.useState<string | undefined>(undefined);
    const [validatePageUrl, setValidatePageUrl] = React.useState<string | undefined>(undefined);

    const errorContext = useErrorContext();
    const accessibilityErrors = errorContext?.accessibilityErrors ?? [];
    const isValidating = errorContext?.isValidating ?? false;
    const validatePortalAccessibility = errorContext?.validatePortalAccessibility ?? (async () => {});
    const validatePageAccessibility = useMemo(
        () => errorContext?.validatePageAccessibility ?? (async () => {}),
        [errorContext?.validatePageAccessibility]
    );

    const [updatedConfig] = usePortalBuilderState((state) => state.updatedConfig);

    const hasErrors = accessibilityErrors.length > 0;

    // Clear page url on validation end
    if (!isValidating && validatePageUrl) {
        setValidatePageUrl(undefined);
    }

    const pages = useMemo(() => {
        // Validate page on page url change
        const validate = async (url: string) => {
            try {
                setValidatePageUrl(url);
                await validatePageAccessibility(url);
            } finally {
                setValidatePageUrl(undefined);
            }
        };

        return (updatedConfig?.content?.pages ?? []).map((p) => {
            const title = p.title ?? "";
            const url = p.link ?? "";
            // Get messages for current page by comparing the path without query params
            const messages = (errorContext?.accessibilityErrors ?? []).filter((e) => e.url.split("?")[0].endsWith(p.link ?? ""));

            return {
                title,
                url,
                messages,
                onClick: () => setActivePageUrl(url),
                onValidate: () => validate(url),
                validationDisabled: validatePageUrl && validatePageUrl !== url,
                isValidating: validatePageUrl === url,
            };
        }) as PageAccessibilityInfo[];
    }, [errorContext?.accessibilityErrors, updatedConfig?.content?.pages, validatePageAccessibility, validatePageUrl]);

    const onValidatePortal = async () => {
        await validatePortalAccessibility(updatedConfig);
    };

    const onExport = async () => {
        if (hasErrors) {
            const data = getAccessibilityErrorsForExport(accessibilityErrors);
            downloadCSV({
                data,
                fileName: "portal-accessibility-report",
            });
        }
    };

    // Show page details
    if (activePageUrl) {
        const page = pages.find((p) => p.url === activePageUrl);

        if (page) {
            return <PageDetails page={page} isValidating={isValidating} onClose={() => setActivePageUrl(undefined)} />;
        }
    }

    // Show global spinner
    if (isValidating && !validatePageUrl) {
        return (
            <AccessibilityProperties>
                <WaitIcon />
            </AccessibilityProperties>
        );
    }

    // Show pages list
    return (
        <AccessibilityProperties>
            <AccessibilityControls onValidate={onValidatePortal} onExport={hasErrors ? onExport : undefined} />
            {/* @ts-ignore */}
            <CustomList className="pages-list flex-column flex-one-in-column with-scroll" items={pages} renderItem={PagesListItem} />
        </AccessibilityProperties>
    );
};

export const AccessibilityMessages = ({ children }: ChildrenProps) => {
    return <div className="accessibility-messages flex-column flex-one-in-column with-scroll">{children}</div>;
};

export interface PageAccessibilityInfo {
    title: string;
    url: string;
    messages: AccessibilityValidationMessage[];
    validationDisabled: boolean;
    isValidating: boolean;
    onClick: () => void;
    onValidate?: (useValidationState?: boolean) => Promise<void>;
}

export type ChildrenProps = { children: JSX.Element | JSX.Element[] };

export default Accessibility;
