import React, { useCallback, useContext, useRef, memo } from "react";
import { omit, range } from "lodash";

import { WorkflowContext } from "../../../context/WorkflowContext";
import { WORKFLOW } from "../../../constants/workflow";

import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import StepTypesWidget from "./StepTypesWidget";
import { log } from "components/utils/logger";

export const MainForm = memo((props) => {
    const { name, typeNumber, typeName, term, statusID } = props;

    log("StepProperties MainForm render", props);

    const formRef = useRef();

    const { onChangeWorkflowStepProperties, isLocked } = useContext(WorkflowContext);

    const initialValues = {
        name,
        stepType: {
            typeNumber,
            typeName,
        },
        term,
        statusID,
    };

    const required = ["name", "stepType", "term"];

    const schema = {
        type: "object",
        required,
        properties: {
            name: {
                type: "string",
                title: "Title",
                maxLength: 300,
            },
            stepType: {
                type: "string",
                title: "Step Type",
            },
            term: {
                type: "integer",
                title: "Term (days)",
                enum: range(-1, 366),
            },
            statusID: {
                type: "integer",
                title: "Status",
                default: 1,
                anyOf: [
                    {
                        title: "Active",
                        enum: [WORKFLOW.ACTIVE_STEP_ID],
                    },
                    {
                        title: "Disabled",
                        enum: [WORKFLOW.DISABLED_STEP_ID],
                    },
                ],
            },
        },
    };

    const uiSchema = {
        name: {
            classNames: "wf__settings-step-form-field",
        },
        stepType: {
            classNames: "wf__settings-step-form-field",
            "ui:widget": (props) => <StepTypesWidget {...props} />,
        },
        term: {
            classNames: "wf__settings-step-form-field",
        },
        statusID: {
            classNames: "wf__settings-step-form-field",
            "ui:widget": "radio",
            "ui:options": {
                inline: true,
            },
        },
    };

    const handleChange = useCallback(
        ({ formData }) => {
            log("StepProperties MainForm handleChange", formData);

            const { stepType } = formData;

            onChangeWorkflowStepProperties({
                ...omit(formData, ["stepType"]),
                ...(stepType.typeNumber !== typeNumber ? { content: "", attributes: [] } : {}),
                typeNumber: stepType.typeNumber,
                typeName: stepType.typeName,
            });
        },
        [typeNumber, onChangeWorkflowStepProperties]
    );

    return (
        <JsonSchemaForm
            formRef={formRef}
            className="wf__settings-step-form"
            schema={schema}
            uiSchema={uiSchema}
            initialValues={initialValues}
            disabled={isLocked}
            onChange={handleChange}
            noSubmit
            noReset
        />
    );
});
