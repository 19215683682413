import { useEffect } from "react";

/**
 * Hook to react to global key presses.
 * @param {string} key key string to compare against `event.key`
 * @param {function} handler
 */
const useOnKey = (key, handler) => {
    useEffect(() => {
        const listener = (event) => {
            if (event.key !== key) {
                return;
            }

            handler(event);
        };

        document.addEventListener("keydown", listener);

        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [key, handler]);
};

export default useOnKey;
