import { isNil } from "lodash";
import React, { useEffect } from "react";
import DefaultArrayField from "react-jsonschema-form/lib/components/fields/ArrayField";

const ArrayField = (props) => {
    // Ensure the form data value is an array.
    let formData = props.formData;
    if (!isNil(formData) && !Array.isArray(formData)) {
        formData = [formData];
    }

    const arrayId = props.idSchema?.$id;

    useEffect(() => {
        // focus the last input field
        const handler = (e) => {
            // Using setTimeout to ensure the focus is set after the component is rendered.
            setTimeout(() => {
                const allInputs = document.querySelectorAll(`#${CSS.escape(arrayId)} > .array-item-list input`);
                const lastInput = allInputs[allInputs.length - 1];
                if (lastInput) {
                    lastInput.focus();
                }
            }, 0);
        };

        // add event listener to add button
        const addButton = document.querySelector(`#${CSS.escape(arrayId)} > .row:not(.array-item-list) .array-item-add .btn-add`);
        if (addButton) {
            addButton.addEventListener("click", handler);
        }

        return () => {
            // remove event listener from add button
            if (addButton) {
                addButton.removeEventListener("click", handler);
            }
        };
    }, [arrayId]);

    return <DefaultArrayField {...props} formData={formData} />;
};

export default ArrayField;
