import React, { memo, useCallback } from "react";
import { modalOpen } from "store/modal/actions";
import PanelHeaderLarge from "components/ui/Headers/PanelHeaderLarge";
import WaitIcon from "components/ui/WaitIcon";
import { useResource } from "store/resources/useResource";
import TemplateList from "./TemplateList";
import { openPortalBuilderTab } from "components/utils/window";
import { openConfirmModal } from "components/ui/Modal/utils";
import { useDispatch } from "react-redux";
import { deleteResource, getResource } from "store/resources/actions";
import { getPortalTemplatesResourceParams } from "store/configureResources";
import CloneTemplateForm from "./CloneTemplateForm";
import { usePortalTemplates } from "components/ui/PortalBuilder/utils/usePortalTemplates";

import "./style.scss";

const PortalsManagementPanel = memo(({ panel, onToggleTempPanel }) => {
    const utilityNumber = panel?.data?.utilityNumber;

    const dispatch = useDispatch();

    const [utility, isLoadingUtility] = useResource({
        resourceName: "utilities",
        resourceId: utilityNumber,
    });

    const [templates = [], isLoadingTemplates] = usePortalTemplates({
        utilityNumber,
        forced: true,
    });

    const isLoading = isLoadingUtility || isLoadingTemplates;
    const entityName = utility?.utility;
    const entityType = "Utility";

    const onOpen = useCallback(
        (template) => (event) => {
            event.stopPropagation();
            openPortalBuilderTab({ utilityNumber, entityType, entityName, portalTemplateNumber: template?.portalTemplateNumber });
        },
        [entityName, utilityNumber]
    );

    const onRemove = useCallback(
        (template) => (event) => {
            event.stopPropagation();

            const message = (
                <>
                    <p>
                        This action will delete the template <strong>{template.name}</strong> and cannot be undone.
                    </p>
                    <p>Are you sure you want to do this?</p>
                </>
            );

            openConfirmModal({
                title: "Delete Template",
                modalIcon: "delete-trash-empty",
                message,
                onConfirm: () => {
                    dispatch(
                        deleteResource({
                            resourceName: "utilityPortalTemplates",
                            resourceId: template.portalTemplateNumber,
                            path: {
                                utilityNumber,
                            },
                            onSuccess: () => {
                                // Refresh the templates list
                                dispatch(getResource(getPortalTemplatesResourceParams({ utilityNumber })));
                            },
                        })
                    );
                },
            });
        },
        [dispatch, utilityNumber]
    );

    const onClone = useCallback(
        (template) => (event) => {
            event.stopPropagation();

            dispatch(
                modalOpen({
                    modalType: "MODAL",
                    modalProps: {
                        title: `Clone ${template.name}`,
                        modalIcon: "layers-empty",
                        overlayClassName: "modal-styled",
                        className: "modal-sm",
                        footerContentCenter: true,
                        children: <CloneTemplateForm template={template} utilityNumber={utilityNumber} />,
                        noFooter: true,
                    },
                })
            );
        },
        [dispatch, utilityNumber]
    );

    return (
        <div className="panel panel-utility-portals-management">
            <PanelHeaderLarge title={panel?.name} childTitle="Portal Management" onClick={() => onToggleTempPanel()} />
            {isLoading && <WaitIcon />}
            <TemplateList
                templates={templates}
                utilityNumber={utilityNumber}
                onOpen={onOpen}
                onRemove={onRemove}
                onClone={onClone}
                hidden={isLoading}
            />
        </div>
    );
});

export default PortalsManagementPanel;
