import React, { memo, useEffect, useCallback, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get, isNil } from "lodash";
import cn from "classnames";

import { defaultDataSource, setActiveDataSource } from "./utils";
import { setWorkqueueDataSourceFilterExpanded, dataSourceChanged } from "../../../store/workcenter/actions";
import { useResource } from "../../../store/resources/useResource";
import AddNewItemPanel from "../../ui/AddNewItemPanel";
import IconWrap from "../../ui/Icons";
import IconWithLabel from "../../ui/Icons/IconWithLabel";
import WaitIcon from "../../ui/WaitIcon";
import StatusMsg from "../../ui/StatusMsg";
import CounterBox from "../../ui/CounterBox";
import { WorkCenterContext } from ".";

const DataSourceFilterList = memo(() => {
    const { instanceId } = useContext(WorkCenterContext);

    const dispatch = useDispatch();
    const userNumber = useSelector((state) => get(state, "user.userNumber"));
    const activeDataSource = useSelector((state) => state.workcenter[instanceId].activeDataSource);
    const dataSourceFilterExpanded = useSelector((state) => state.workcenter[instanceId].dataSourceFilterExpanded);

    const isMobile = useSelector((state) => state.window?.isMobile);
    const isTablet = useSelector((state) => state.window?.isTablet);

    const datasourceNumber = activeDataSource && activeDataSource.datasourceNumber;

    const [dataSources = [], isLoadingDatasources] = useResource({
        resourceName: "workcenter",
        key: `${userNumber}-workcenter`,
    });

    useEffect(() => {
        if (!isLoadingDatasources && !activeDataSource) {
            setActiveDataSource({ instanceId, activeDataSource, dataSources });
        }
    }, [instanceId, isLoadingDatasources, activeDataSource, dataSources]);

    const onDataSourceSelect = useCallback(
        (dataSource) => (event) => {
            if (dataSource.datasourceNumber !== datasourceNumber) {
                dispatch(dataSourceChanged({ instanceId, dataSource }));
            }
        },
        [instanceId, datasourceNumber, dispatch]
    );

    const onAddNewItemClick = useCallback(() => {
        dispatch(dataSourceChanged({ instanceId, dataSource: defaultDataSource }));
    }, [instanceId, dispatch]);

    const onExpand = useCallback(() => {
        dispatch(
            setWorkqueueDataSourceFilterExpanded({
                instanceId,
                dataSourceFilterExpanded: !dataSourceFilterExpanded,
            })
        );
    }, [instanceId, dataSourceFilterExpanded, dispatch]);

    if (isLoadingDatasources || !activeDataSource) {
        return <WaitIcon />;
    }

    return (
        <div
            className={cn("saved-filters flex-column no-scroll", {
                collapsed: !dataSourceFilterExpanded,
            })}
        >
            <div className="saved-filters__title-block flex-row justify-space-between">
                <div className="title flex-row align-center">
                    <CounterBox>{dataSources.length}</CounterBox>
                    <span>Filters</span>
                </div>
                <div className="filter-marks-wrap">
                    <StatusMsg
                        msgIconRight
                        msgIcon="circle-radio-button-unchecked-filled icon-wrap-combined share-filled-after own"
                        msgText="my shared filters"
                    ></StatusMsg>
                    <StatusMsg
                        msgIconRight
                        msgIcon="circle-radio-button-unchecked-filled icon-wrap-combined share-filled-after"
                        msgText="shared filters from others"
                    ></StatusMsg>
                    {(isMobile || isTablet) && (
                        <StatusMsg msgIconRight msgIcon="stars-filled--after" msgText="starting page for Work Center"></StatusMsg>
                    )}
                </div>
            </div>
            <div className="saved-filters-wrap flex-column no-scroll">
                <AddNewItemPanel text="Add New Filter" onClick={onAddNewItemClick}></AddNewItemPanel>
                <div className="saved-filters-list">
                    <div>
                        {dataSources.map((dataSource) => (
                            <IconWithLabel
                                key={dataSource.datasourceNumber}
                                icon={dataSource.isDefault ? "stars-filled" : "stars-empty"}
                                className={cn({
                                    "active-filter": datasourceNumber === dataSource.datasourceNumber,
                                    "shared-filter": dataSource.isShared,
                                    "shared-filter own": dataSource.isSharedTo,
                                })}
                                onClick={onDataSourceSelect(dataSource)}
                                title={dataSource.isShared ? "Filter Shared with me" : dataSource.isSharedTo ? "My Shared Filter" : ""}
                            >
                                <span>{dataSource.datasourceName}</span>
                                {(dataSource.isShared || dataSource.isSharedTo) && (
                                    <IconWrap
                                        className={dataSource.isSharedTo && "own"}
                                        iconWrapCombined
                                        iconWrap="circle-radio-button-unchecked-filled share-filled-after"
                                    ></IconWrap>
                                )}
                                {!isNil(activeDataSource.datasourceNumber) &&
                                    activeDataSource.datasourceNumber !== "0" &&
                                    !dataSourceFilterExpanded && (
                                        <IconWrap title="Show Filter Details" icon="shevron-in-circle-right-empty" onClick={onExpand} />
                                    )}
                            </IconWithLabel>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default DataSourceFilterList;
