import React from "react";
import { useApplicationAssignedContacts, useDataGridData, useResource } from "../../../../../../store/resources/useResource";
import { useMemo } from "react";
import { availableGrids, contactsGridColumnKeys } from "../../../../../views/configureGrids";
import { mapGridDataToObjects } from "../../../../../utils/datagrid";
import { store } from "../../../../../../store/configureStore";
import { createResource, updateResource } from "../../../../../../store/resources/actions";
import { refreshDataGridData, refreshApplicationAssignedContacts } from "../../../../../../store/resources/refreshResource";
import { modalOpen } from "../../../../../../store/modal/actions";
import { toArray } from "components/utils/array";
import { pickInitialValues } from "components/utils/form";
import { getReferenceValueByName } from "components/ui/Reference/utils";
import { isString } from "lodash";

export const isSame = (contact1, contact2) => {
    if (contact1 && contact2) {
        return contact1.city === contact2.city && contact1.address === contact2.address;
    } else {
        return false;
    }
};

export const getFormValues = (initialValues = {}, contactTypes, workflowGroups) => {
    const values = pickInitialValues(initialValues);

    const type = isString(values.contacttype)
        ? Number(
              getReferenceValueByName({
                  reference: contactTypes,
                  displayName: values.contacttype,
              }) ?? 0
          )
        : values.contacttype;

    const mappedValues = {
        ...values,
        contacttype: type === 0 ? undefined : type,
    };

    if (values.workflowgroup) {
        mappedValues.workflowTargetGroupId = workflowGroups.find((g) => g.groupName === values.workflowgroup)?.groupID;
    }

    return mappedValues;
};

export const useApplicationContactsGridData = ({ applicationNumber }) => {
    const [gridData = [], isLoadingGrid] = useDataGridData({
        dataGridId: availableGrids.contacts,
        key: `${applicationNumber}-contacts-grid`,
        filter: `${contactsGridColumnKeys.applicationNumber}=${applicationNumber}`,
    });

    const contacts = useMemo(() => mapGridDataToObjects(contactsGridColumnKeys, gridData), [gridData]);

    return [contacts, isLoadingGrid];
};

export const refreshContactLists = ({ applicationNumber }) => {
    refreshDataGridData({
        dataGridId: availableGrids.contacts,
        key: `${applicationNumber}-contacts-grid`,
        filter: `${contactsGridColumnKeys.applicationNumber}=${applicationNumber}`,
        onSuccess: () => {
            refreshApplicationAssignedContacts({ applicationNumber });
        },
    });
};

export const useApplicationContacts = ({ applicationNumber }) => {
    const [otherContacts, isLoadingOtherContacts] = useApplicationOtherContacts({ applicationNumber });
    const [premiseContact, primaryContact, contractorContact, isLoadingAssignedContacts] = useApplicationAssignedContacts({
        applicationNumber,
    });

    const contacts = useMemo(() => {
        let existingContacts = [];

        if (!(isLoadingOtherContacts || isLoadingAssignedContacts)) {
            if (premiseContact) {
                existingContacts.push(premiseContact);
            }

            if (primaryContact) {
                existingContacts.push(primaryContact);
            }

            if (contractorContact) {
                existingContacts.push(contractorContact);
            }

            if (otherContacts) {
                existingContacts = existingContacts.concat(otherContacts);
            }
        }

        return existingContacts;
    }, [premiseContact, primaryContact, contractorContact, otherContacts, isLoadingOtherContacts, isLoadingAssignedContacts]);

    return [contacts, isLoadingOtherContacts || isLoadingAssignedContacts];
};

export const useApplicationOtherContacts = ({ applicationNumber }) => {
    const [contacts, isLoading] = useApplicationContactsGridData({
        applicationNumber,
    });
    const [premiseContact, primaryContact, contractorContact] = useApplicationAssignedContacts({
        applicationNumber,
    });

    const otherContacts = useMemo(() => {
        const mainContactNumbers = [premiseContact?.contactnumber, primaryContact?.contactnumber, contractorContact?.contactnumber];

        return contacts.filter((contact) => !mainContactNumbers.includes(contact.contactnumber));
    }, [contacts, premiseContact, primaryContact, contractorContact]);

    return [otherContacts, isLoading];
};

export const useContactWorkflowTargetGroups = ({ utilityNumber, programNumber }) => {
    return useResource({
        resourceName: "utilityGroupsByAssignedEntity",
        key: `${utilityNumber}-contact-workflow-target-groups`,
        path: {
            utilityNumber,
            groupType: "Contact Workflow Target Group",
            entityType: "program",
            entityNumber: programNumber,
        },
        transform: (data) => {
            return toArray(data?.groupByEntityAssignmentList);
        },
    });
};

export const onAssign = ({ applicationNumber, contactNumber, assignType, onComplete, assignedContacts }) => {
    const assignedContact =
        assignedContacts !== undefined ? assignedContacts.find((a) => a.ContactType === assignType.toLowerCase()).Contact : undefined;

    if (assignedContact !== undefined) {
        const message = <p>{assignType} contact already exists, would you like to replace it?</p>;

        store.dispatch(
            modalOpen({
                modalType: "CONFIRM",
                modalProps: {
                    title: "Assign " + assignType + " Contact",
                    modalIcon: "copy-link",
                    overlayClassName: "modal-styled",
                    className: "modal-sm",
                    footerContentCenter: true,
                    content: message,
                    onConfirm: () => {
                        assignContact({
                            applicationNumber,
                            contactNumber,
                            assignType,
                            onComplete,
                        });
                    },
                    onClose: onComplete,
                },
            })
        );
    } else
        assignContact({
            applicationNumber,
            contactNumber,
            assignType,
            onComplete,
        });
};

export const onUnassign = ({ applicationNumber, assignType, onComplete }) => {
    const message = <p>Would you like to unassign {assignType} contact?</p>;

    store.dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: "Unassign " + assignType + " Contact",
                modalIcon: "cut-scissors-filled",
                overlayClassName: "modal-styled",
                className: "modal-sm",
                footerContentCenter: true,
                content: message,
                onConfirm: () => {
                    store.dispatch(
                        createResource({
                            resourceName: "applicationContactsUnassign",
                            path: {
                                applicationNumber,
                                assignType,
                            },
                            onSuccess: () => {
                                refreshContactLists({ applicationNumber });
                            },
                            onComplete,
                        })
                    );
                },
                onClose: onComplete,
            },
        })
    );
};

export const assignContact = ({ applicationNumber, contactNumber, assignType, onComplete }) => {
    store.dispatch(
        createResource({
            resourceName: "applicationContactsAssign",
            path: {
                applicationNumber,
                contactNumber,
                assignType,
            },
            onSuccess: () => {
                refreshContactLists({ applicationNumber });
            },
            onComplete,
        })
    );
};

export const getContactType = ({ contact, isPremise, isPrimary, isContractor }) => {
    let contactType = contact?.contacttype;

    if (isPremise) {
        contactType = "Premise";
    }

    if (isPrimary) {
        contactType = "Primary";
    }

    if (isContractor) {
        contactType = "Contractor";
    }

    return contactType;
};

export const onUpdateContact = ({ applicationNumber, contact, onSuccess, onError }) => {
    store.dispatch(
        updateResource({
            resourceName: "applicationContacts",
            resourceId: contact.contactnumber,
            path: {
                appId: applicationNumber,
            },
            body: contact,
            onSuccess: () => {
                refreshContactLists({ applicationNumber });
                onSuccess && onSuccess();
            },
            onError,
        })
    );
};

export const onCreateContact = ({ applicationNumber, contact, refreshContacts = true, onSuccess, onError }) => {
    const body = {
        ...contact,
    };

    store.dispatch(
        createResource({
            resourceName: "applicationContacts",
            path: {
                appId: applicationNumber,
            },
            body,
            onSuccess: (action) => {
                refreshContacts && refreshContactLists({ applicationNumber });
                onSuccess && onSuccess(action);
            },
            onError,
        })
    );
};
