import React, { memo, useId } from "react";
import isNil from "lodash/isNil";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import WaitIcon from "../../WaitIcon";
import ToolListItem from "./ToolListItem";
import ScrollControls from "../../ScrollControls";

import "./ToolList.scss";

const ToolList = memo(({ items, activeTool, onClick, onPin, onDragEnd, loading, disabledItemTooltip }) => {
    const toolsId = useId();
    const activeItemIndex = (items || []).findIndex((i) => i.id === activeTool?.id);
    const isDraggable = !isNil(onDragEnd);

    if (isDraggable) {
        return (
            <div className="tool-list">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="tools-droppable" direction="horizontal">
                        {(provided) => {
                            return (
                                <div
                                    id={toolsId}
                                    ref={(ref) => {
                                        provided.innerRef(ref);
                                    }}
                                    {...provided.droppableProps}
                                    className="tools"
                                >
                                    {items.map((item, index) => (
                                        <ToolListItem
                                            key={item.id}
                                            item={item}
                                            index={index}
                                            activeItem={activeTool}
                                            isDraggable={isDraggable}
                                            onClick={onClick}
                                            onPin={onPin}
                                            disabledItemTooltip={disabledItemTooltip}
                                        />
                                    ))}
                                    {loading && <WaitIcon />}
                                    {provided.placeholder}
                                </div>
                            );
                        }}
                    </Droppable>
                    <ScrollControls targetId={toolsId} activeItemIndex={activeItemIndex} />
                </DragDropContext>
            </div>
        );
    }

    return (
        <div className="tool-list">
            <div id={toolsId} className="tools">
                {items.map((item, index) => (
                    <ToolListItem
                        key={item.id}
                        item={item}
                        index={index}
                        activeItem={activeTool}
                        isDraggable={isDraggable}
                        onClick={onClick}
                        onPin={onPin}
                        disabledItemTooltip={disabledItemTooltip}
                    />
                ))}
                {loading && <WaitIcon />}
            </div>
            <ScrollControls targetId={toolsId} activeItemIndex={activeItemIndex} />
        </div>
    );
});

export default ToolList;
