import React from "react";
import { openPortalBuilderTab } from "components/utils/window";
import { SECTION_CONTENT, SECTION_SETTINGS } from "../Section";
import IconWrap from "components/ui/Icons";
import Button from "components/ui/Button";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";
import { useErrorContext } from "../../PortalBuilderErrorContextProvider";

export const PageEditorInfo = ({ isOverridden, isInactiveStartPage, onPageReset }: PageEditorInfoProps) => {
    const [utilityNumber] = usePortalBuilderState((state) => state.utilityNumber);
    const [selectedUtilityTemplate] = usePortalBuilderState((state) => state.selectedUtilityTemplate);
    const { setActiveSection } = useErrorContext()!;

    const goToUtility = () => {
        openPortalBuilderTab({
            utilityNumber,
            programNumber: undefined,
            entityType: "Utility",
            entityName: selectedUtilityTemplate?.utilityTemplateName,
            portalTemplateNumber: selectedUtilityTemplate?.utilityTemplateNumber,
            activeInitialView: SECTION_CONTENT,
            forceReplaceProps: true,
        });
    };

    const goToSettings = () => {
        setActiveSection(SECTION_SETTINGS);
    };

    if (isInactiveStartPage) {
        return (
            <div className="program-page-info">
                <p>
                    This portal uses an alternate home page. This current page is not visible on the portal. Visit{" "}
                    <span onClick={goToSettings} className="settings-link">
                        settings
                    </span>{" "}
                    to adjust the start page settings.
                </p>
            </div>
        );
    }

    return (
        <div className="program-page-info">
            {isOverridden ? (
                <>
                    <p>
                        This program page is not inheriting all properties from the utility version. If you would like to reset this page to
                        the utility version, click the button below.
                    </p>
                    <Button small onClick={onPageReset}>
                        Reset page
                    </Button>
                </>
            ) : (
                <p>
                    Go to the utility portal template{" "}
                    <span onClick={goToUtility} className="template-link">
                        "{selectedUtilityTemplate?.utilityTemplateName}" content section
                    </span>{" "}
                    to edit this page everywhere. Edits here will override the utility version.
                </p>
            )}
            <IconWrap icon="info-empty" />
        </div>
    );
};

interface PageEditorInfoProps {
    isInactiveStartPage: boolean;
    isOverridden: boolean;
    onPageReset: () => void;
}
