import React, { useState, memo } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import useAppEquipmentListForWidget from "store/resources/useAppEquipmentListForWidget";
import { SidebarIconGroup } from "../../../../SidebarPanel/SidebarParts/SidebarIconGroup";
import SidebarPanel from "../../../../SidebarPanel";
import CategoriesList from "./CategoriesList";
import EquipmentList from "./EquipmentList";
import EquipmentDetails from "./EquipmentDetails";
import Breadcrumb from "../../../../Breadcrumb";
import WaitIcon from "../../../../WaitIcon";
import IconWrap from "../../../../Icons";

import "./EquipmentPanelSmall.scss";

const EquipmentPanelSmall = memo(({ panel, onSettings, onUnpin, onExpand, onToggleSidebar }) => {
    const applicationNumber = get(panel, "data.applicationNumber");
    const programNumber = get(panel, "data.programNumber");

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedEquipment, setSelectedEquipment] = useState(null);

    const isDesktop = useSelector((state) => get(state, "window.isDesktop"));

    const [equipment = [], equipmentLoading] = useAppEquipmentListForWidget(applicationNumber);

    const sidebarHeaderActions = <SidebarIconGroup panel={panel} onSettings={onSettings} onUnpin={onUnpin} onExpand={onExpand} />;

    const categoriesList = equipment
        .map((e) => e.category)
        .filter((category, index, array) => array.indexOf(category) === index)
        .map((name) => {
            const count = equipment.filter((eq) => eq.category === name).length;

            return {
                name,
                count,
            };
        });

    const equipmentList = selectedCategory === null ? [] : equipment.filter((eq) => eq.category === selectedCategory.name);

    const onCategorySelect = (item) => {
        setSelectedCategory(item);
        setSelectedEquipment(null);
    };

    const onEquipmentSelect = (item) => {
        setSelectedEquipment(item);
    };

    const getBreadcrumb = () => {
        if (!selectedCategory) {
            return [];
        }

        let items = [
            {
                name: (
                    <IconWrap
                        icon="home-empty"
                        title="Sidebar Home"
                        onClick={() => {
                            setSelectedCategory(null);
                            setSelectedEquipment(null);
                        }}
                    />
                ),
                onClick: null,
            },
        ];

        if (selectedEquipment) {
            items = items.concat([
                {
                    name: selectedCategory.name,
                    onClick: () => setSelectedEquipment(null),
                },
                {
                    name: selectedEquipment.name,
                    onClick: null,
                },
            ]);
        } else {
            items = items.concat([
                {
                    name: selectedCategory.name,
                    onClick: null,
                },
            ]);
        }

        return items;
    };

    const handleMoreFunctionality = () => {
        onExpand(panel);

        if (!isDesktop) {
            onToggleSidebar();
        }
    };

    return (
        <SidebarPanel
            sidebarHeaderTitle={panel.name}
            sidebarHeaderActions={sidebarHeaderActions}
            noPadding
            noFooter
            noData={equipmentList.length === 0 || categoriesList.length === 0}
        >
            {equipmentLoading && <WaitIcon />}
            {!equipmentLoading && (
                <div className="equipment-catalog flex-column">
                    {selectedCategory === null ? (
                        <CategoriesList items={categoriesList} onSelect={onCategorySelect} onExpand={() => handleMoreFunctionality()} />
                    ) : (
                        <div className="equipment-list">
                            <Breadcrumb items={getBreadcrumb()} />
                            {selectedEquipment === null ? (
                                <EquipmentList
                                    items={equipmentList}
                                    category={selectedCategory}
                                    onSelect={onEquipmentSelect}
                                    onExpand={() => handleMoreFunctionality()}
                                />
                            ) : (
                                <EquipmentDetails
                                    equipmentId={selectedEquipment.equipid}
                                    applicationNumber={applicationNumber}
                                    programNumber={programNumber}
                                    onExpand={() => handleMoreFunctionality()}
                                />
                            )}
                        </div>
                    )}
                </div>
            )}
        </SidebarPanel>
    );
});

export default EquipmentPanelSmall;
