import { windowAdd } from "./actions";

import { getProgramView } from "components/utils/window";
import { setActiveDashboardTool, setCatalogAutoOpen, setAttributeAutoOpen } from "store/dashboards/actions";
import { setApplicationStatus } from "store/programs/actions";

export const openProgramTab =
    ({
        programNumber,
        activePanel,
        applicationStatus,
        catalogNumber,
        attributeNumber,
        attributeType,
        attributeValue,
        utilityNumber,
        autoOpen,
    }: {
        programNumber: string;
        activePanel?: string;
        applicationStatus?: string;
        catalogNumber?: string;
        attributeNumber?: string;
        attributeType?: string;
        attributeValue?: string;
        utilityNumber?: string;
        autoOpen?: boolean;
    }) =>
    (dispatch: any) => {
        const view = getProgramView({ programNumber });

        // @ts-ignore
        dispatch(windowAdd({ ...view, activate: true }));

        if (activePanel) {
            dispatch(
                setActiveDashboardTool({
                    entityNumber: programNumber,
                    tool: activePanel,
                })
            );
            if (autoOpen && activePanel === "program-catalog") {
                dispatch(
                    setCatalogAutoOpen({
                        programNumber,
                        activePanel,
                        catalogNumber,
                        utilityNumber,
                        autoOpen,
                    })
                );
            }
        }

        if (autoOpen && activePanel === "program-config" && attributeNumber)
            dispatch(
                setAttributeAutoOpen({
                    programNumber,
                    activePanel,
                    attributeNumber,
                    attributeType,
                    attributeValue,
                    utilityNumber,
                    autoOpen,
                })
            );

        if (applicationStatus) {
            dispatch(setApplicationStatus({ programNumber, applicationStatus }));
        }
    };
