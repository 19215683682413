import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import getPath from "lodash/get";

import SidebarPanel from "../../../../SidebarPanel";
import GridListPaging from "../../../../List/GridList/GridListPaging";
import { SidebarIconGroup } from "../../../../SidebarPanel/SidebarParts/SidebarIconGroup";
import { auditGridColumnKeys } from "../../../../../views/configureGrids";
import AuditGrid from "./AuditGrid";
import AuditTypesSelector, { useSelector } from "../../../../AuditTypesSelector";
import { auditType } from "../../../../../utils/auditType";
import { auditPanelTypes } from "../AuditPanel/utils";

import "./style.scss";
import { getData } from "../../../../../../store/dataGrid/actions";

const AuditPanelSmall = ({ panel, onUnpin, onExpand, onSettings }) => {
    const [filterAudit, setFilterAudit] = useSelector();

    const dispatch = useDispatch();

    const applicationNumber = getPath(panel, "data.applicationNumber");

    const gridId = `${applicationNumber}-audit-widget-grid`;

    // skip loading data on first render, as grid view takes care of it
    const firstRender = useRef(true);
    useEffect(() => {
        if (!firstRender.current) {
            dispatch(
                getData({
                    dataGridId: gridId,
                })
            );
        }
        firstRender.current = false;
    }, [gridId, dispatch]);

    const sidebarHeader = <SidebarIconGroup panel={panel} onUnpin={onUnpin} onExpand={onExpand} />;

    // Custom pagination
    // Implementation: components/ui/List/GridList/GridListFooter
    const renderFooter = (Pagination, skip, take, total, onSelectPage) => {
        if (total <= take) return null;
        return <GridListPaging skip={skip} take={take} total={total} compact onPageChange={onSelectPage} />;
    };

    let filter = `${auditGridColumnKeys.entityNumber}=${applicationNumber}`;

    if (filterAudit !== auditType.all) {
        filter = `${filter}|${auditGridColumnKeys.audittype}=${filterAudit}`;
    }

    return (
        <SidebarPanel sidebarHeaderTitle={panel.name} sidebarHeaderActions={sidebarHeader} noPadding noFooter>
            <div className="container-body audit-small">
                <AuditTypesSelector items={auditPanelTypes} type={filterAudit} onChange={setFilterAudit} />
                <AuditGrid filter={filter} applicationNumber={applicationNumber} gridId={gridId} renderFooter={renderFooter} />
            </div>
        </SidebarPanel>
    );
};

export default AuditPanelSmall;
