import { useSelector } from "react-redux";
import { get } from "lodash";

export const customerDisabledText = "Customer is disabled";

export const useCustomerDisabled = ({ customerNumber }) => {
    return (
        (useSelector((state) => get(state, `resources.customers.itemsById[${customerNumber}].status`)) || "").toLowerCase() === "disabled"
    );
};
