import React, { useState, useCallback, useMemo, memo, useRef, useEffect } from "react";

import { budgetLineFormSchema, useBudgetLineCategories, onRefreshBudgetLinesGrid } from "./utils";
import { submitResource, pickInitialValues } from "../../../../../utils/form";

import WaitIcon from "../../../../WaitIcon";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";

import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";
import Button from "../../../../../ui/Button";
import { submitByRef } from "../../../../../utils/form";

const BudgetLineDetails = memo(({ utilityNumber, contractNumber, resource, onClose, sidePanel }) => {
    const [isSubmitting, setSubmitting] = useState(false);

    const resourceId = resource.budgetLineNumber;

    const [categories = [], isLoading] = useBudgetLineCategories({
        utilityNumber,
        contractNumber,
    });

    const schema = budgetLineFormSchema(categories);

    const titleText = "Edit Budget";
    const titleIcon = "edit-empty";

    const formRef = useRef();

    const uiSchema = {
        note: {
            classNames: "fill-width",
            "ui:widget": "textarea",
        },
    };

    const initialValues = resource ? pickInitialValues(resource) : {};

    const submitText = useMemo(() => {
        return isSubmitting ? "Saving..." : "Save";
    }, [isSubmitting]);

    const onSubmit = useCallback(
        (formData) => {
            const resourceParams = {
                resourceName: "utilitiesContractsBudgetLines",
                resourceId,
                path: {
                    utilityNumber,
                    contractNumber,
                },
            };

            const body = {
                ...formData,
                parentBudgetLineNumber: resource.parentBudgetLineNumber,
            };

            submitResource({
                resourceParams,
                resourceId,
                body,
                onSuccess: sidePanel.close,
                setSubmitting,
                onRefresh: () => onRefreshBudgetLinesGrid({ contractNumber }),
            });
        },
        [utilityNumber, contractNumber, resource.parentBudgetLineNumber, resourceId, sidePanel]
    );

    const handleSubmit = useCallback(() => {
        submitByRef(formRef);
    }, []);

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    if (isLoading) {
        return <WaitIcon />;
    }

    return (
        <SideNavContent className="budget-edit-form">
            <SideNavHeader title={titleText} leadBlockIcon={titleIcon} smallHeader onClose={onClose}></SideNavHeader>
            <SideNavBody className="flex-one-in-column">
                <JsonSchemaForm
                    formRef={formRef}
                    schema={schema}
                    uiSchema={uiSchema}
                    initialValues={initialValues}
                    disabled={isSubmitting}
                    submitText={submitText}
                    onSubmit={onSubmit}
                    noReset
                    noSubmit
                    noCancel
                    noActions
                />
            </SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button primary disabled={isSubmitting || isLoading} onClick={handleSubmit}>
                    {submitText}
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default BudgetLineDetails;
