import React from "react";
import cn from "classnames";
import TextLabel from "components/ui/Label/TextLabel";
import IconWrap from "components/ui/Icons";
import { PropertyListItem } from "../Property/PropertyListItem";

export const PropertyListItemTitle = ({
    title,
    expandTitle,
    isExpanded,
    onRemove,
    removeActionTitle = "Delete Row",
    onExpand,
    onClone,
    index,
    onChangeOrderDown,
    onChangeOrderUp,
    listLength,
    disabled,
    onToggle,
    toggleTooltip,
    toggleValue,
    className = "",
    onMouseOver,
    onMouseLeave,
}) => {
    const expandIcon = isExpanded ? "shevron-in-circle-up-filled" : "shevron-in-circle-down-drop-down-empty";

    return (
        <PropertyListItem
            className={cn("property-list-item--title property-list-item--no-border-bottom", {
                "property-list-item--expanded-title": isExpanded,
            })}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
        >
            <div className="property-list-item-inner flex-row align-center">
                {listLength > 1 && (
                    <div className="property-list-order-buttons flex-column">
                        <IconWrap
                            icon="shevron-small-up-expand-less"
                            iconWrapBig
                            iconWrap={"arrow-wrap"}
                            isClickable
                            title="Move Up"
                            disabled={index <= 0}
                            onClick={() => onChangeOrderUp(index)}
                        />
                        <IconWrap
                            icon="shevron-small-down-expand-more"
                            iconWrapBig
                            iconWrap={"arrow-wrap"}
                            isClickable
                            title="Move Down"
                            disabled={index >= listLength - 1}
                            onClick={() => onChangeOrderDown(index)}
                        />
                    </div>
                )}
                <TextLabel className={className}>{title}</TextLabel>
                {onClone && <IconWrap className="clone-row-btn" icon="layers-empty" onClick={onClone} title="Clone Row" />}
                <span className="flex-one" />
                {onRemove && listLength > 1 && <IconWrap icon="delete-trash-empty" onClick={onRemove} title={removeActionTitle} />}
                {onToggle && (
                    <IconWrap
                        disabled={disabled}
                        iconWrapBig
                        onClick={onToggle}
                        title={toggleTooltip}
                        icon={!toggleValue ? "fiber-smart-record-filled" : "fiber-smart-record-empty"}
                    />
                )}
                {onExpand && <IconWrap className="property-list-toggle" icon={expandIcon} title={expandTitle} onClick={onExpand} />}
            </div>
        </PropertyListItem>
    );
};
