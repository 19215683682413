import React, { useContext, useCallback, useMemo, memo } from "react";

import { DocumentRow } from "../../../shared/Grid/DocumentRow";
import { DocumentQueueGridContext } from "../../../context/DocumentQueueGridContext";

export const Document = memo(({ index, style, data }) => {
    const { entityId, isBatchFilled, isSelectionDisabled, selectedDocumentsCount } = data;
    const {
        queueDocuments,
        isBatchProcessing,
        selectedProgramDocumentNumbers,
        selectedProgramDocumentNumbersActions,
        selectedProgramNumbers,
        selectedProgramNumbersActions,
    } = useContext(DocumentQueueGridContext);
    const document = useMemo(() => queueDocuments[entityId][index], [entityId, index, queueDocuments]);
    const { documentNumber } = document;
    const checked = useMemo(
        () => selectedProgramDocumentNumbers.some((selectedDocument) => selectedDocument.documentNumber === documentNumber),
        [selectedProgramDocumentNumbers, documentNumber]
    );

    const handleSelectDocument = useCallback(
        (e) => {
            e.stopPropagation();

            if (!checked) {
                if (isBatchFilled || isSelectionDisabled || isBatchProcessing) return;

                if (!selectedProgramNumbers.includes(entityId)) {
                    selectedProgramNumbersActions.add([entityId]);
                }

                selectedProgramDocumentNumbersActions.add([{ documentNumber }]);
            } else {
                selectedProgramDocumentNumbersActions.remove([documentNumber]);

                if (selectedDocumentsCount === 1) {
                    selectedProgramNumbersActions.remove([entityId]);
                }
            }
        },
        [
            entityId,
            checked,
            documentNumber,
            isBatchFilled,
            isSelectionDisabled,
            isBatchProcessing,
            selectedProgramNumbers,
            selectedProgramNumbersActions,
            selectedProgramDocumentNumbersActions,
            selectedDocumentsCount,
        ]
    );

    return (
        <DocumentRow
            {...document}
            index={index}
            style={style}
            checked={checked}
            onSelectDocument={handleSelectDocument}
            isBatchFilled={isBatchFilled}
            isSelectionDisabled={isSelectionDisabled}
            isBatchProcessing={isBatchProcessing}
        />
    );
});
