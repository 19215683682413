import React from "react";
import Button from "components/ui/Button";
import { PagesList } from "./PagesList";
import { UserInfo } from "./UserInfo";
import { UserRights } from "./UserRights";
import { useDispatch } from "react-redux";
import { openTRMulator } from "components/utils/window";

import "./style.scss";

const ComponentsPage = ({ onWindowOpen }) => {
    const dispatch = useDispatch();

    return (
        <div className="components flex-column with-scroll">
            <div className="flex-row with-scroll">
                <div className="components-list">
                    <div className="tile flex-row no-shrink flex-padding">
                        <Button small onClick={() => dispatch(openTRMulator())}>
                            Open TRMulator
                        </Button>
                    </div>
                    <PagesList onWindowOpen={onWindowOpen} />
                </div>
                <div className="components-list">
                    <UserInfo onWindowOpen={onWindowOpen} />
                </div>
                <div className="components-list">
                    <UserRights onWindowOpen={onWindowOpen} />
                </div>
            </div>
        </div>
    );
};

export default ComponentsPage;
