import React from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { useProgramForms } from "../../../../store/resources/useResource";
import ProgramName from "../../ProgramName";

const TabContentForApplicationFormPages = (view) => {
    const formNumber = get(view, `view.props.formNumber`);
    const programNumber = get(view, `view.props.programNumber`);

    const [programForm] = useProgramForms({ formNumber });

    if (!programForm) {
        return null;
    }

    let contentItems = [
        {
            label: "Program",
            value: <ProgramName programNumber={programNumber} />,
        },
        {
            label: "Application Form Pages",
            value: programForm.formName,
        },
    ];

    return (
        <div className="tab-content">
            {contentItems.map((item) => (
                <div key={item.label} className="tab-content-item">
                    <div className="title">{item.label}</div>
                    <div className="value">{item.value}</div>
                </div>
            ))}
        </div>
    );
};

export default connect()(TabContentForApplicationFormPages);
