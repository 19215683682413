import React from "react";
import cn from "classnames";
import { useDrop } from "react-dnd";
import { ElementTypes } from "../../utils/form";

import * as builder from "../../../store/formBuilder/actions";
import { useDispatch } from "react-redux";

const DropTargetField = (props) => {
    const dispatch = useDispatch();

    const { show, order, dropTargetId, instanceId } = props;

    const [{ isOver, canDrop, placeholderProps }, drop] = useDrop(
        () => ({
            accept: ElementTypes.FIELD,
            drop: (item, monitor) => {
                const elementId = item.idSchema.$id;
                // Using timeout to allow dnd lib to do its thing before form is updated. Otherwise, there is a 'drop' error in console.
                setTimeout(() => {
                    dispatch(builder.dropElement({ instanceId, elementId, dropTargetId, order }));
                }, 0);
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                placeholderProps: monitor.getItem(),
            }),
        }),
        [order, dropTargetId, instanceId]
    );

    return (
        <div
            ref={drop}
            className={cn("drop-target", {
                "is-over": isOver,
                "can-drop": canDrop,
                [`drop-${placeholderProps?.elementType}`]: placeholderProps,
            })}
            hidden={!show}
        >
            <div className="drop-target-line" />
            <div className="drop-target-area" />
        </div>
    );
};

export default DropTargetField;
