import React from "react";
import GridList from "../../../../List/GridList";

const columns = [
    {
        key: "NAME",
        name: "NAME",
    },
    {
        key: "PROGRAM",
        name: "PROGRAM",
    },
];

const UploadedItemsGrid = ({ rows, onRowSelect }) => {
    return (
        <GridList
            className="calculation-uploaded-items-grid"
            columns={columns}
            rows={rows}
            noData={rows.length === 0}
            canSelectRow
            onRowSelectChange={onRowSelect}
        />
    );
};

export default UploadedItemsGrid;
