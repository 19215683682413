import React, { useRef, useState, useLayoutEffect, memo, useCallback, useEffect, useMemo } from "react";
import { ScrollSync } from "react-scroll-sync";
import { useResizeDetector } from "react-resize-detector";
import { isNil } from "lodash";
import { FixedSizeList, areEqual } from "react-window";
import memoize from "memoize-one";
import cn from "classnames";

import useUnmounted from "../../../utils/useUnmounted";

import GridListRow from "./GridListRow";
import GridListHeaderRow from "./GridListHeaderRow";
import GridListGroup from "./GridListGroup";
import GridListFilterRow from "./GridListFilterRow";
import GridListRowContainer from "./GridListRowContainer";

import WaitIcon from "../../WaitIcon";
import {
    getGridListWidth,
    getSelectAllRowState,
    getGroupedRows,
    canResize,
    setStoredColumnWidths,
    EVENT_GRID_COLUMN_WIDTHS_RESET,
    calculateColumnWidths,
} from "./utils";
import GridListFooter from "./GridListFooter";
import GridListNoDataRow from "./GridListNoDataRow";
import GridListNoColumnsRow from "./GridListNoColumnsRow";
import { SizeMeasureItem, getVirtualListHeight } from "./SizeMeasureItem";
import { isVisible } from "components/utils/dom";

import "./style.scss";

export const GridListContext = React.createContext();
export const GridListResizeContext = React.createContext({});
export const GridListFilterContext = React.createContext({});

const GridList = memo((props) => {
    const unmounted = useUnmounted();

    const {
        dataGridId, // Data grid instance id.
        dataGridKey, // Data grid key used in backend to store column configuration.
        columns,
        rows = [],
        DetailsContent,
        onExpandChange,
        onTreeExpandChange,
        rowActions = [],
        gridActions = [],
        rowGroups = [],
        onRowAction,
        onGridAction,
        isReading,
        groupRender,
        pageable,
        pagingCompact,
        skip,
        take,
        total,
        onPageChange,
        filterable,
        filter,
        onFilterChange,
        sortable,
        sort,
        onSortChange,
        resizableHeaders,
        showActions,
        onlyItems = false,
        renderItem,
        className,
        columnWidth = {},
        columnHeaderContent,
        columnFilterContent,
        columnCellContent,
        canExpandRow,
        canSelectRow,
        onRowSelectChange,
        noData,
        renderFooter: propsRenderFooter,
        showExpandedRow = false,
        draggableRows = false,
        onDragEnd,
        isWidget,
        notExpandableRowIcon,
        customRowClassName,
        virtualized,
        withResizeDetector = true,
    } = props;

    const expandable = DetailsContent !== undefined && canExpandRow;

    const hasColumns = columns.length > 0;

    const gridListRef = useRef();
    const columnWidthsInitialized = useRef(false);
    const columnMinWidths = useRef();
    const shouldResetColumnWidths = useRef(false);

    const [gridListWidth, setGridListWidth] = useState(0);
    const [scrollable, setScrollable] = useState({});
    const [popupBoundary, setPopupBoundary] = useState();

    // Custom Column Widths, Updated when resizing column
    const [columnWidths, setColumnWidths] = useState({
        ...columnWidth,
    });

    const selectAllRowsState = getSelectAllRowState({ rows });

    const onResizeGrid = useCallback(() => {
        if (!unmounted.current) {
            setGridListWidth((prevValue) => {
                const gridWidth = getGridListWidth({ gridListRef });

                if (prevValue !== gridWidth && gridWidth > 0) {
                    columnWidthsInitialized.current = false;
                    return gridWidth;
                }

                return prevValue;
            });
        }
    }, [unmounted]);

    useLayoutEffect(() => {
        if (!unmounted.current) {
            onResizeGrid();
        }
    }, [onResizeGrid, unmounted]);

    // Listen for stored column width reset
    useEffect(() => {
        // Recalculate column widths if custom widths are reset.
        const onReset = (event) => {
            if (event?.detail?.dataGridId === dataGridKey) {
                // Reset columns only for visible grids. Otherwise, column widths will be incorrect.
                if (!isVisible(gridListRef.current)) {
                    // Set flag to reset columns when grid becomes visible.
                    shouldResetColumnWidths.current = true;
                    return;
                }

                shouldResetColumnWidths.current = false;
                setColumnWidths(
                    calculateColumnWidths(
                        dataGridKey,
                        {}, // Empty object with current column widths to force recalculation
                        columns,
                        columnMinWidths,
                        resizableHeaders,
                        gridListRef,
                        isWidget
                    )
                );
            }
        };

        window.addEventListener(EVENT_GRID_COLUMN_WIDTHS_RESET, onReset, false);

        return () => {
            window.removeEventListener(EVENT_GRID_COLUMN_WIDTHS_RESET, onReset, false);
        };
    }, [columns, dataGridKey, isWidget, onResizeGrid, resizableHeaders, unmounted]);

    // Listen to grid visibility changes to reset column widths if needed.
    useEffect(() => {
        const observer = new IntersectionObserver(
            () => {
                if (isVisible(gridListRef.current) && shouldResetColumnWidths.current) {
                    shouldResetColumnWidths.current = false;
                    setColumnWidths(
                        calculateColumnWidths(
                            dataGridKey,
                            {}, // Empty object with current column widths to force recalculation
                            columns,
                            columnMinWidths,
                            resizableHeaders,
                            gridListRef,
                            isWidget
                        )
                    );
                }
            },
            {
                root: gridListRef.current?.closest(".window"),
                rootMargin: "0px",
                threshold: 0.1,
            }
        );

        observer.observe(gridListRef.current);

        return () => {
            observer.disconnect();
        };
    }, [columns, dataGridKey, isWidget, resizableHeaders]);

    useEffect(() => {
        if (
            !unmounted.current &&
            columnWidthsInitialized.current &&
            columnMinWidths.current &&
            (!columns.every((c) => !isNil(columnMinWidths.current[c.key])) || Object.keys(columnMinWidths.current).length > columns.length)
        ) {
            columnWidthsInitialized.current = false;

            setColumnWidths({
                ...columnWidth,
            });
        }
    }, [columns, columnWidth, unmounted]);

    useEffect(() => {
        if (!unmounted.current && gridListWidth && !columnWidthsInitialized.current) {
            setColumnWidths((prevColumnWidths) =>
                calculateColumnWidths(dataGridKey, prevColumnWidths, columns, columnMinWidths, resizableHeaders, gridListRef, isWidget)
            );

            columnWidthsInitialized.current = true;
        }
    }, [isWidget, columns, gridListWidth, resizableHeaders, unmounted, dataGridKey]);

    // Find closest popup boundary
    useEffect(() => {
        if (gridListRef.current) {
            setPopupBoundary((prevValue) => {
                if (!prevValue) {
                    return gridListRef.current?.closest(".popup-boundary");
                }

                return prevValue;
            });
        }
    }, []);

    const onResizeColumn = useCallback(
        ({ column, deltaX }) => {
            if (!unmounted.current) {
                setColumnWidths((prevState) => {
                    const currentWidth = prevState[column.key];
                    const nextWidth = currentWidth + deltaX;
                    const minWidth = columnMinWidths.current[column.key];

                    if (!canResize(currentWidth, nextWidth, minWidth)) {
                        return prevState;
                    }

                    const updatedColumns = {
                        ...prevState,
                        [column.key]: nextWidth,
                    };

                    setStoredColumnWidths(dataGridKey, updatedColumns);

                    return {
                        ...prevState,
                        [column.key]: nextWidth,
                    };
                });
            }
        },
        [dataGridKey, unmounted]
    );

    //TODO apply font size; header font is different from the row one;
    const onAutoResizeColumn = useCallback(
        (column) => {
            if (!unmounted.current) {
                let size = column.name ? column.name.length : 0;

                rows.forEach((i) => {
                    let valueSize = i[column.key] ? i[column.key].toString().length : 0;

                    if (valueSize > size) {
                        size = valueSize;
                    }
                });

                setColumnWidths((prevState) => {
                    const updatedColumns = {
                        ...prevState,
                        [column.key]: size * 12,
                    };

                    setStoredColumnWidths(dataGridKey, updatedColumns);

                    return updatedColumns;
                });
            }
        },
        [dataGridKey, rows, unmounted]
    );

    const onHorizontalScrollChange = useCallback(
        (state) => {
            if (!unmounted.current) {
                setScrollable(state);
            }
        },
        [unmounted]
    );

    const onGridPageChange = useCallback(
        (event) => {
            // Scroll to first row
            if (gridListRef.current) {
                const rowContainer = gridListRef.current.querySelector(".grid-list-row-container");
                if (rowContainer) {
                    rowContainer.scrollTop = 0;
                }
            }

            onPageChange(event);
        },
        [onPageChange]
    );

    const groupedRows = getGroupedRows({ rowGroups, rows });
    const groupRowsCount = Object.keys(groupedRows).length;
    const grid = onlyItems ? (
        <div
            ref={gridListRef}
            className={cn("grid-list only-items", {
                "grouped-grid": rowGroups.length > 0,
            })}
        >
            <GridListRows
                rows={rows}
                columns={columns}
                renderItem={renderItem}
                customRowClassName={customRowClassName}
                virtualized={virtualized}
            />
        </div>
    ) : (
        <div
            ref={gridListRef}
            className={cn("grid-list flex-row", className, {
                "grouped-grid": rowGroups.length > 0,
                "grid-list-draggable-rows": draggableRows,
                "only-items": onlyItems,
                "grid-list--scrollable": scrollable.left || scrollable.right,
                "grid-list--scrollable-left": scrollable.left && canSelectRow,
                "grid-list--edge": !canSelectRow && (scrollable.left || scrollable.right),
                "grid-list--scrollable-right": scrollable.right,
                "no-data": noData,
                "no-columns": !noData && !hasColumns,
            })}
        >
            <div className="flex-column flex-one grid-list-left-side">
                <GridListHeaderRow columns={columns} show={hasColumns} showActions={!filterable} noData={noData} />
                <GridListFilterRow columns={columns} show={filterable} hasColumns={hasColumns} noData={noData} />
                <GridListRowContainer draggableRows={draggableRows} onDragEnd={onDragEnd}>
                    <GridListNoColumnsRow dataGridId={dataGridId} show={!noData && !hasColumns} />
                    <GridListNoDataRow show={noData} />
                    {hasColumns && (
                        <>
                            {rowGroups.length > 0 &&
                                Object.keys(groupedRows).map((groupName) => (
                                    <GridListGroup
                                        key={groupName}
                                        groupName={groupName}
                                        dataGridId={dataGridId}
                                        groupRender={groupRender}
                                        rows={groupedRows[groupName]}
                                        columns={columns}
                                        groupRowsCount={groupRowsCount}
                                    />
                                ))}
                            {isReading && !showActions && <WaitIcon />}
                            {rowGroups.length === 0 && (
                                <GridListRows
                                    rows={rows}
                                    columns={columns}
                                    renderItem={renderItem}
                                    customRowClassName={customRowClassName}
                                    virtualized={virtualized}
                                />
                            )}
                        </>
                    )}
                </GridListRowContainer>
            </div>
            <div className="grid-list-footer-placeholder">
                <GridListFooter
                    propsRenderFooter={propsRenderFooter}
                    pageable={pageable}
                    skip={skip}
                    take={take}
                    total={total}
                    pagingCompact={pagingCompact}
                    onPageChange={onGridPageChange}
                />
            </div>
        </div>
    );

    const gridContextValue = useMemo(
        () => ({
            // Grid list element ref
            gridListRef,

            // Closest popup boundary
            popupBoundary,

            // Header
            showActions: showActions && (rowActions.length > 0 || gridActions.length > 0),
            gridActions,
            onGridAction,
            selectAllRowsState,

            // Rows
            expandable,
            showExpandedRow,
            DetailsContent,
            draggableRows,
            rowActions: Array.isArray(rowActions) ? rowActions : [],
            onRowAction,
            onRowSelectChange,
            onExpandChange,
            onTreeExpandChange,

            // Columns
            columnWidths,
            columnCellContent,
            onHorizontalScrollChange,

            // Sort
            sortable,
            sort,
            onSortChange,

            isReading,

            columnHeaderContent,
            canSelectRow,

            notExpandableRowIcon,
        }),
        [
            canSelectRow,
            columnHeaderContent,
            columnCellContent,
            columnWidths,
            sortable,
            sort,
            gridActions,
            isReading,
            notExpandableRowIcon,
            popupBoundary,
            expandable,
            showExpandedRow,
            DetailsContent,
            draggableRows,
            rowActions,
            selectAllRowsState,
            showActions,

            onSortChange,
            onRowAction,
            onGridAction,
            onHorizontalScrollChange,
            onRowSelectChange,
            onExpandChange,
            onTreeExpandChange,
        ]
    );

    const gridResizeContextValue = useMemo(
        () => ({
            resizable: resizableHeaders,
            onAutoResizeColumn,
            onResizeColumn,
        }),
        [resizableHeaders, onAutoResizeColumn, onResizeColumn]
    );

    const gridFilterContextValue = useMemo(
        () => ({
            filterable,
            filter,
            onFilterChange,
            columnFilterContent,
        }),
        [filterable, filter, onFilterChange, columnFilterContent]
    );

    useResizeDetector({
        handleHeight: false,
        onResize: onResizeGrid,
        targetRef: withResizeDetector ? gridListRef : null,
    });

    return (
        <GridListContext.Provider value={gridContextValue}>
            <GridListFilterContext.Provider value={gridFilterContextValue}>
                <GridListResizeContext.Provider value={gridResizeContextValue}>
                    <ScrollSync>{grid}</ScrollSync>
                </GridListResizeContext.Provider>
            </GridListFilterContext.Provider>
        </GridListContext.Provider>
    );
});

const GridListRows = memo((props) => {
    if (props.virtualized) {
        return <VirtualizedGridListRows {...props} />;
    }

    const { rows, columns, renderItem, customRowClassName } = props;

    // Visible rows
    const visibleRows = rows.filter((r) => r._treeHidden !== true);

    if (visibleRows.length === 0) {
        return null;
    }

    const itemData = createRowData(visibleRows, rows, columns, renderItem, customRowClassName);

    return visibleRows.map((row, index) => <GridListRowsItem key={index} index={index} data={itemData} />);
});

const VirtualizedGridListRows = memo(({ rows, columns, renderItem, customRowClassName }) => {
    // Visible rows
    const visibleRows = rows.filter((r) => r._treeHidden !== true);

    // Row count
    const itemCount = visibleRows.length;

    // Row height. For now virtualized list supports only rows with static height.
    const itemHeight = 44;

    // List element ref
    const [listRef, setListRef] = useState(null);

    // List height only for rerender
    const [, setListHeight] = useState(0);

    // Callback returns list height changes
    // Need only to trigger rerender
    const onResize = useCallback(setListHeight, [setListHeight]);

    const onSetRowContainerRef = useCallback((ref) => {
        if (ref) {
            setListRef(ref);
        }
    }, []);

    // Render nothing if there are no rows
    if (visibleRows.length === 0) {
        return null;
    }

    const itemData = createRowData(visibleRows, rows, columns, renderItem, customRowClassName);
    const listHeight = getVirtualListHeight({
        listRef,
        itemHeight,
        itemCount,
    });

    return (
        <>
            <FixedSizeList
                outerRef={onSetRowContainerRef}
                height={listHeight}
                width={"100%"}
                itemCount={itemCount}
                itemSize={itemHeight}
                itemData={itemData}
                overscanCount={2}
            >
                {GridListRowsItem}
            </FixedSizeList>
            <SizeMeasureItem listRef={listRef} itemHeight={itemHeight} itemCount={itemCount} onResize={onResize} />
        </>
    );
});

const GridListRowsItem = memo(({ data, index, style }) => {
    const { rows, allRows, columns, renderItem, customRowClassName } = data;

    const row = rows[index];
    const rowClassName = customRowClassName ? customRowClassName(row) : "";

    const fullRowListIndex = useMemo(() => allRows.findIndex((r) => r === row), [allRows, row]);

    if (renderItem) {
        return renderItem(row, index);
    }

    return <GridListRow style={style} row={row} index={fullRowListIndex} columns={columns} className={rowClassName} />;
}, areEqual);

const createRowData = memoize((visibleRows, allRows, columns, renderItem, customRowClassName) => ({
    rows: visibleRows,
    allRows,
    columns,
    renderItem,
    customRowClassName,
}));

export default GridList;
