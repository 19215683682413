import { useContext } from "react";
import { PortalBuilderContext } from "../../contexts";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";
import { PageConfiguration } from "../../types";
import { getProgramLevelPageTitle } from "../../utils/page";

export const usePageTitle = (page: PageConfiguration | undefined) => {
    const { template } = useContext(PortalBuilderContext);
    const [isProgramPortalBuilder] = usePortalBuilderState((state) => state.isProgramPortalBuilder);
    const utilityTemplateConfiguration = template?.utilityTemplateConfiguration;

    if (isProgramPortalBuilder) {
        return getProgramLevelPageTitle(page, utilityTemplateConfiguration);
    }

    return page?.title ?? "";
};
