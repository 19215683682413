import React from "react";
import TextLabel from "components/ui/Label/TextLabel";
import cn from "classnames";
import IconWrap from "components/ui/Icons";
import { PropertyChangeFunc } from "components/ui/PortalBuilder/types";
import { isEmpty } from "lodash";

import "./style.scss";

export const PropertyTitle = ({ title, required, noPostfix, infoIconText }: PropertyTitleProps) =>
    !isEmpty(infoIconText) ? (
        <div className={"flex-row with-info-icon"}>
            <TextLabel
                className={cn({
                    required,
                })}
            >
                {title}
                {!noPostfix && <span className="title-postfix">:</span>}
            </TextLabel>
            <IconWrap icon="info-empty" title={infoIconText} />
        </div>
    ) : (
        <TextLabel
            className={cn({
                required,
            })}
        >
            {title}
            {!noPostfix && <span className="title-postfix">:</span>}
        </TextLabel>
    );

export const PropertyTitleWithSwitch = ({
    title,
    required,
    switchId,
    switchTitle,
    switchValue,
    onChange,
    noPostfix,
}: PropertyTitleWithSwitchProps) => {
    return (
        <div className="flex-row align-center fill-width property-list-item-title-with-switch">
            <PropertyTitle title={title} required={required} noPostfix />
            <span className="flex-one" />
            <div className="flex-row align-center fill-width switch">
                <TextLabel>{switchTitle}:</TextLabel>
                <IconWrap
                    title={!switchValue ? "Switch OFF" : "Switch ON"}
                    icon={switchValue ? "fiber-smart-record-filled" : "fiber-smart-record-empty"}
                    onClick={() => onChange(switchId, !switchValue)}
                />
            </div>
        </div>
    );
};

type PropertyTitleProps = {
    title: string;
    required: boolean;
    noPostfix: boolean;
    infoIconText?: string;
};

type PropertyTitleWithSwitchProps = PropertyTitleProps & {
    switchId: string;
    switchTitle: string;
    switchValue: boolean;
    onChange: PropertyChangeFunc;
    noPostfix: false;
};
