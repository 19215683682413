import React, { useCallback, useRef, useEffect, useState, useLayoutEffect, useMemo } from "react";
import { renderToString } from "react-dom/server";
import loadScript from "load-script";
import { createId } from "../../../../../utils/string";
import debounce from "lodash/debounce";
import { debounceTimeout } from "../../../../../utils/constants";
import { logError } from "../../../../../utils/logger";
import { dispatchEvent } from "../../../../../utils/dom";
import WaitIcon from "components/ui/WaitIcon";
import {
    toolbarBasic,
    toolbarFastTags,
    toolbarLite,
    toolbarMinimum,
    toolbarPortalBuilder,
    toolbarPortalBuilderFullScreen,
    toolbarTexts,
    toolbarLinks,
    toolbarTextsFullScreen,
    ToolbarTypes,
    toolbarList,
} from "./toolbars";

import "./HtmlEditorWidgetCkEditor.scss";
import { elementReady } from "../../../../../utils/dom";

const editorUrl = process.env.PUBLIC_URL + "/ckeditor/ckeditor.js";

function HtmlEditorWidget(props) {
    const {
        id,
        options,
        placeholder,
        value,
        // required,
        disabled,
        readonly,
        onChange,
        onBlur,
        onFocus,
        colors,
    } = props;

    const changeEventAttached = useRef(false);
    const ckEditor = useRef();
    const editorInstanceRef = useRef();
    const editorIdRef = useRef(createId());
    const lastValueRef = useRef();
    const [editorLoaded, setEditorLoaded] = useState(false);

    const editorId = editorIdRef.current;

    const fastTags = options?.fastTags;
    const fastTagsLength = fastTags?.length ?? 0;
    const isLoadingFastTags = options?.isLoadingFastTags ?? false;
    const forcePasteAsPlainText = options?.forcePasteAsPlainText ?? false;
    const fastTagsDialogRef = useRef();
    const hideLinkAdvancedTab = options?.hideLinkAdvancedTab;
    const restrictedTablePlugin = options?.restrictedTablePlugin ?? false;

    const emptyValue = options?.emptyValue;

    // toolbar type to use
    const toolbar = options?.toolbar ?? (fastTags ? ToolbarTypes.FastTags : ToolbarTypes.Basic);

    const onEditorLoaded = useCallback(
        (event) => {
            const editor = event.editor;
            const isMaximized = editor.getCommand("maximize").state;

            if (isMaximized) {
                // Trigger window resize event to fix the editor size in full-screen mode.
                dispatchEvent(window, "resize");
            } else {
                const parentElement = document.getElementById(editorId).parentElement;
                if (parentElement) {
                    editor.resize("100%", parentElement.clientHeight);
                }
            }

            setEditorLoaded(true);
        },
        [editorId]
    );

    const onEditorChange = useMemo(() => {
        return debounce((event) => {
            let currentValue = editorInstanceRef?.current?.ui?.editor?.getData() ?? "";

            if (toolbar === ToolbarTypes.List || toolbar === ToolbarTypes.Texts) {
                var stringToHTML = function (str) {
                    var dom = document.createElement("div");
                    dom.innerHTML = str;
                    return dom.childNodes;
                };
                var cDiv = stringToHTML(currentValue);
                var modifiedHTML = "";

                function findSpanColor(node) {
                    if (node.nodeType === Node.ELEMENT_NODE) {
                        if (node.tagName === "SPAN") {
                            return node?.style?.color;
                        }
                        for (const childNode of node.childNodes) {
                            const color = findSpanColor(childNode);
                            if (color) {
                                return color;
                            }
                        }
                    }
                    return null;
                }

                if (cDiv.length > 0) {
                    cDiv?.forEach((element, index) => {
                        if (element.nodeType === Node.ELEMENT_NODE) {
                            if (element.tagName === "UL" || element.tagName === "OL") {
                                element.querySelectorAll("li").forEach((li) => {
                                    li.style.color = findSpanColor(li);
                                });
                                modifiedHTML += element.outerHTML;
                            } else {
                                modifiedHTML += element.outerHTML;
                            }
                        } else if (element.nodeType === Node.TEXT_NODE) {
                            modifiedHTML += element.textContent;
                        }
                    });
                }

                currentValue = modifiedHTML;
            }
            lastValueRef.current = currentValue;
            return onChange(currentValue === "" ? emptyValue : currentValue);
        }, debounceTimeout);
    }, [emptyValue, toolbar, onChange]);

    const onEditorFocus = useCallback(
        (event) => {
            if (!changeEventAttached.current) {
                editorInstanceRef.current.on("change", onEditorChange);
                changeEventAttached.current = true;
            }
            const currentValue = event.editor.getData();
            return onFocus && onFocus(id, currentValue);
        },
        [id, onEditorChange, onFocus]
    );

    const onEditorBlur = useCallback(
        (event) => {
            const currentValue = event.editor.getData();
            return onBlur && onBlur(id, currentValue);
        },
        [id, onBlur]
    );

    const onEditorModeChange = useCallback(
        (event) => {
            var editable = event.editor.editable();

            // Attach to input change event if editor is in 'source' mode.
            // Default 'change' event is not fired in 'source' mode.
            if (event.editor.mode === "source") {
                editable.attachListener(editable, "input", onEditorChange);
            } else {
                editable.removeListener(editable, "input", onEditorChange);
            }
        },
        [onEditorChange]
    );

    // Initialize "ckEditorReady" callback. The callback is called from the plugin "toolbarswitch" after editor is switched to full screen mode.
    useLayoutEffect(() => {
        window.ckEditorReady = (event) => {
            const editor = event.editor;
            editorInstanceRef.current = editor;
            onEditorLoaded({ editor });
            editor.on("focus", onEditorFocus);
            editor.on("blur", onEditorBlur);
            editor.on("mode", onEditorModeChange);
            editor.on("change", onEditorChange);
        };

        return () => {
            window.ckEditorReady = null;
        };
    }, [onEditorBlur, onEditorChange, onEditorFocus, onEditorLoaded, onEditorModeChange]);

    useLayoutEffect(() => {
        if (!editorInstanceRef.current) {
            createEditor(
                editorId,
                placeholder,
                forcePasteAsPlainText,
                hideLinkAdvancedTab,
                restrictedTablePlugin,
                fastTags,
                isLoadingFastTags,
                toolbar,
                colors,
                ckEditor,
                editorInstanceRef,
                fastTagsDialogRef,
                onEditorLoaded,
                onEditorFocus,
                onEditorBlur,
                onEditorModeChange
            );
        }
    }, [
        fastTags,
        placeholder,
        toolbar,
        colors,
        editorId,
        isLoadingFastTags,
        forcePasteAsPlainText,
        hideLinkAdvancedTab,
        restrictedTablePlugin,
        onEditorLoaded,
        onEditorChange,
        onEditorFocus,
        onEditorBlur,
        onEditorModeChange,
    ]);

    useEffect(() => {
        const editor = editorInstanceRef.current;
        return () => {
            destroyEditor(editor);
        };
    }, [editorId]);

    useEffect(() => {
        const editor = editorInstanceRef.current;

        if (editor && editor.status !== "unloaded") {
            const currentValue = editor.getData();

            if (lastValueRef.current !== value && currentValue !== value) {
                try {
                    editor.setData(value);
                } catch (error) {
                    logError(`HtmlEditorWidget editor.setData: ${error?.message ?? "error"}, ${value}`);
                }
            }
        }
    }, [value, editorLoaded]);

    useEffect(() => {
        const editor = editorInstanceRef.current;

        if (editor && editor.status !== "unloaded") {
            editor && editor.setReadOnly(Boolean(readonly || disabled));
        }
    }, [readonly, disabled, editorLoaded]);

    useEffect(() => {
        if (fastTags && ckEditor.current) {
            ckEditor.current.config.fastTags = fastTags || [];
        }
    }, [fastTags, editorId]);

    // For fasttags dialog to not stuck in loading state.
    useEffect(() => {
        if (ckEditor.current) {
            ckEditor.current.config.isLoadingFastTags = isLoadingFastTags;
        }
    }, [isLoadingFastTags, editorId]);

    // Update fasttags dialog on list change if it is opened
    useEffect(() => {
        if (fastTagsDialogRef.current) {
            ckEditor.current.config.fastTags = fastTags;
            ckEditor.current.config.isLoadingFastTags = isLoadingFastTags;
            destroyDialog(fastTagsDialogRef.current);
            setTimeout(() => editorInstanceRef.current.execCommand("insertFasttag"), 10);
        }
    }, [fastTags, fastTagsLength, isLoadingFastTags]);

    return <div id={editorId} contentEditable="true" />;
}

const createEditor = (
    editorId,
    placeholder,
    forcePasteAsPlainText,
    hideLinkAdvancedTab,
    restrictedTablePlugin,
    fastTags,
    isLoadingFastTags,
    toolbar,
    colors,
    ckEditorRef,
    editorInstanceRef,
    fastTagsDialogRef,
    onEditorLoaded,
    onEditorFocus,
    onEditorBlur,
    onEditorModeChange
) => {
    getEditorNamespace().then((CKEDITOR) => {
        if (!CKEDITOR.instances[editorId]) {
            ckEditorRef.current = CKEDITOR;

            CKEDITOR.disableAutoInline = true;
            CKEDITOR.config.allowedContent = true;
            CKEDITOR.config.enterMode = CKEDITOR.ENTER_BR;
            CKEDITOR.config.shiftEnterMode = CKEDITOR.ENTER_P;
            CKEDITOR.config.placeholder = placeholder;

            CKEDITOR.config.forcePasteAsPlainText = forcePasteAsPlainText;

            if (hideLinkAdvancedTab) {
                CKEDITOR.config.linkShowAdvancedTab = !hideLinkAdvancedTab;
            }

            if (restrictedTablePlugin) {
                CKEDITOR.config.disableObjectResizing = true;
            }

            // Define toolbar types
            CKEDITOR.config.toolbar_lite = toolbarLite;
            CKEDITOR.config.toolbar_basic = toolbarBasic;
            CKEDITOR.config.toolbar_fasttags = toolbarFastTags;
            CKEDITOR.config.toolbar_portalbuilder = toolbarPortalBuilder;
            CKEDITOR.config.toolbar_portalbuilder_fullscreen = toolbarPortalBuilderFullScreen;
            CKEDITOR.config.toolbar_minimum = toolbarMinimum;
            CKEDITOR.config.toolbar_texts = toolbarTexts;
            CKEDITOR.config.toolbar_links = toolbarLinks;
            CKEDITOR.config.toolbar_list = toolbarList;
            CKEDITOR.config.toolbar_texts_fullscreen = toolbarTextsFullScreen;

            CKEDITOR.config.extraPlugins = "confighelper";

            // Enable toolbar switch plugin
            if (toolbar === ToolbarTypes.PortalBuilder) {
                CKEDITOR.config.toolbar = ToolbarTypes.PortalBuilder;
                CKEDITOR.config.smallToolbar = ToolbarTypes.PortalBuilder;
                CKEDITOR.config.maximizedToolbar = ToolbarTypes.PortalBuilderFullScreen;
                CKEDITOR.config.extraPlugins += ",toolbarswitch";
                CKEDITOR.config.format_tags = "p;h1;h2;h3;h4;h5;h6";
            }

            if (toolbar === ToolbarTypes.Texts) {
                CKEDITOR.config.toolbar = ToolbarTypes.Texts;
                CKEDITOR.config.smallToolbar = ToolbarTypes.Texts;
                CKEDITOR.config.maximizedToolbar = ToolbarTypes.TextsFullScreen;
                CKEDITOR.config.extraPlugins += ",toolbarswitch";
                CKEDITOR.config.format_tags = "p;h1;h2;h3;h4;h5;h6";
                CKEDITOR.config.colorButton_enableMore = false;
                CKEDITOR.config.colorButton_colors = colors;
            }

            if (toolbar === ToolbarTypes.Links) {
                CKEDITOR.config.toolbar = ToolbarTypes.Links;
                CKEDITOR.config.smallToolbar = ToolbarTypes.Links;
                CKEDITOR.config.maximizedToolbar = ToolbarTypes.TextsFullScreen;
                CKEDITOR.config.extraPlugins += ",toolbarswitch";
                CKEDITOR.config.format_tags = "p;h1;h2;h3;h4;h5;h6";
                CKEDITOR.config.colorButton_enableMore = false;
                CKEDITOR.config.colorButton_colors = colors;
            }

            if (toolbar === ToolbarTypes.List) {
                CKEDITOR.config.toolbar = ToolbarTypes.List;
                CKEDITOR.config.smallToolbar = ToolbarTypes.List;
                CKEDITOR.config.maximizedToolbar = ToolbarTypes.TextsFullScreen;
                CKEDITOR.config.extraPlugins += ",toolbarswitch";
                CKEDITOR.config.format_tags = "p;h1;h2;h3;h4;h5;h6";
                CKEDITOR.config.colorButton_enableMore = false;
                CKEDITOR.config.colorButton_colors = colors;
            }

            if (fastTags) {
                ckEditorRef.current.config.fastTags = fastTags;
                ckEditorRef.current.config.isLoadingFastTags = isLoadingFastTags;

                if (!CKEDITOR.plugins.registered.fasttags) {
                    CKEDITOR.plugins.add("fasttags", {
                        icons: "fasttags",
                        init: function (editor) {
                            CKEDITOR.skin.loadPart("dialog");

                            CKEDITOR.dialog.add("fasttagsDialog", function (editor) {
                                const dialogTitle = "Correspondence Fast Tags";
                                const contents = [
                                    ckEditorRef.current.config.isLoadingFastTags
                                        ? {
                                              id: "fasttagsLoading",
                                              label: dialogTitle,
                                              elements: [
                                                  {
                                                      type: "html",
                                                      align: "center",
                                                      html: renderToString(<WaitIcon />),
                                                  },
                                              ],
                                          }
                                        : {
                                              id: "fasttaglist",
                                              label: dialogTitle,
                                              elements: (ckEditorRef.current.config.fastTags || []).map((tag, index) => ({
                                                  type: "button",
                                                  id: `item${index}`,
                                                  label: tag,
                                                  title: tag,
                                                  style: "width: 100%;",
                                                  onClick: function () {
                                                      editor.insertHtml(`%%${tag}%%`);
                                                      fastTagsDialogRef.current.hide();
                                                  },
                                              })),
                                          },
                                ];

                                return {
                                    title: dialogTitle,
                                    resizable: false,
                                    contents,
                                    buttons: [],
                                    onHide: (event) => {
                                        const dialog = event.sender;

                                        // Without timeout it throws error
                                        setTimeout(() => {
                                            destroyDialog(dialog);
                                            fastTagsDialogRef.current = null;
                                        }, 0);
                                    },
                                };
                            });

                            editor.addCommand("insertFasttag", {
                                exec: function (editor) {
                                    fastTagsDialogRef.current = new CKEDITOR.dialog(editor, "fasttagsDialog");
                                    fastTagsDialogRef.current.show();
                                },
                                canUndo: false,
                                editorFocus: 1,
                            });

                            editor.ui.addButton("Fasttags", {
                                label: "Insert Fasttag",
                                command: "insertFasttag",
                                toolbar: "insert",
                            });
                        },
                    });
                }

                CKEDITOR.config.extraPlugins += ",fasttags";
            }

            editorInstanceRef.current = CKEDITOR.replace(editorId, {
                toolbar,
            });

            if (editorInstanceRef.current) {
                editorInstanceRef.current.on("instanceReady", onEditorLoaded);
                editorInstanceRef.current.on("focus", onEditorFocus);
                editorInstanceRef.current.on("blur", onEditorBlur);
                editorInstanceRef.current.on("mode", onEditorModeChange);

                if (restrictedTablePlugin) {
                    // Add custom table styles
                    editorInstanceRef.current.addContentsCss(`
                        .cke_editable .table thead {
                            border: 0 solid;
                        }
                        .cke_editable .table thead th {
                            border: none;
                            text-transform: uppercase;
                            text-align: inherit;
                        }
                        .cke_editable .table tbody th {
                            text-align: inherit;
                        }
                    `);
                }

                // Change link dialog on open
                CKEDITOR.on("dialogDefinition", function (ev) {
                    var dialogName = ev.data.name;

                    if (dialogName === "link") {
                        const dialogDefinition = ev.data.definition;
                        const informationTab = dialogDefinition.getContents("target");
                        const targetField = informationTab.get("linkTargetType");

                        const linkInfoTab = dialogDefinition.getContents("info");
                        const urlOptions = linkInfoTab.get("urlOptions");
                        const protocolField = urlOptions.children[0]?.children[0];

                        // Show only "None", "http://" and "https://" protocol options
                        protocolField.items = protocolField.items.filter(
                            (item) => item[1] === "http://" || item[1] === "https://" || item[1] === ""
                        );

                        //Change the label for no protocol option
                        const index = protocolField.items.findIndex((item) => item[1] === "");
                        protocolField.items[index][0] = "None";

                        // Show only <not set> and New Window (_blank)
                        targetField.items = targetField.items.filter((item) => item[1] === "_blank" || item[1] === "notSet");
                    }

                    // Customize the table dialog
                    if (["table", "tableProperties"].includes(dialogName) && restrictedTablePlugin) {
                        const dialogDefinition = ev.data.definition;
                        const infoTab = dialogDefinition.getContents("info");
                        const columnsField = infoTab.get("txtCols");

                        // Set table class in advanced tab
                        const advancedTab = dialogDefinition.getContents("advanced");
                        advancedTab.get("advCSSClasses").default = "";

                        advancedTab.get("advCSSClasses").commit = (type, element) => {
                            element.$.classList.add("table");
                            element.$.classList.add("table-bordered");
                            element.$.classList.add("cke_show_border");
                        };
                        // Hide advanced tab
                        advancedTab.hidden = true;

                        // Restrict column count
                        const maxColumnCount = 4;
                        const baseValidate = columnsField.validate;
                        columnsField.validate = function () {
                            const value = this.getValue();

                            if (value > maxColumnCount) {
                                alert("Maximum column count is " + maxColumnCount);
                                return false;
                            }

                            return baseValidate.apply(this, arguments);
                        };

                        // Remove summary field from table dialog
                        infoTab.remove("txtSummary");

                        // Remove ID field from table dialog
                        infoTab.remove("txtId");

                        // Remove border field from table dialog
                        infoTab.remove("txtBorder");

                        // Hide cell spacing field from table dialog
                        infoTab.get("txtCellSpace").hidden = true;

                        // Hide cell padding field from table dialog
                        infoTab.get("txtCellPad").hidden = true;

                        // Remove alignment field from table dialog
                        infoTab.remove("cmbAlign");

                        // Remove height field from table dialog
                        infoTab.remove("txtHeight");

                        if (!infoTab.get("selHeadersColor")) {
                            // Move Headers select here
                            const headersField = infoTab.get("selHeaders");
                            infoTab.remove("selHeaders");
                            infoTab.add(headersField);

                            infoTab.add({
                                type: "select",
                                id: "selHeadersColor",
                                label: "Header Color",
                                requiredContent: "thead",
                                default: "None",
                                items: [
                                    ["None", "none"],
                                    ["Light", "light"],
                                    ["Dark", "dark"],
                                ],
                                commit: function (type, element) {
                                    const headerColor = this.getValue();
                                    elementReady("thead", element.$).then((el) => {
                                        let thead = el;
                                        if (!thead) {
                                            return;
                                        }

                                        if (headerColor === "light") {
                                            thead.classList.remove("table-dark");
                                            thead.classList.add("table-light");
                                        } else if (headerColor === "dark") {
                                            thead.classList.remove("table-light");
                                            thead.classList.add("table-dark");
                                        } else {
                                            thead.classList.remove("table-light");
                                            thead.classList.remove("table-dark");
                                        }
                                    });
                                },
                                setup: function (element) {
                                    this.setValue("none");
                                    const thead = element?.$.querySelector("thead");

                                    if (!thead) {
                                        return;
                                    }

                                    if (thead.classList.contains("table-light")) {
                                        this.setValue("light");
                                    } else if (thead.classList.contains("table-dark")) {
                                        this.setValue("dark");
                                    }
                                },
                            });
                        }

                        if (!infoTab.get("selCaptions")) {
                            // Move the caption text here.
                            const captionField = infoTab.get("txtCaption");
                            infoTab.remove("txtCaption");
                            infoTab.add(captionField);

                            infoTab.add({
                                type: "select",
                                id: "selCaptions",
                                requiredContent: "table caption",
                                default: "Top",
                                label: "Caption Position",
                                items: [
                                    ["Top", "top"],
                                    ["Bottom", "bottom"],
                                ],
                                commit: function (type, element) {
                                    let captionPosition = this.getValue();

                                    if (captionPosition === "top") {
                                        element.$.classList.remove("caption-bottom");
                                        element.$.classList.add("caption-top");
                                    } else {
                                        element.$.classList.remove("caption-top");
                                        element.$.classList.add("caption-bottom");
                                    }
                                },
                                setup: function (element) {
                                    if (element.$.classList.contains("caption-top")) {
                                        this.setValue("top");
                                    } else {
                                        this.setValue("bottom");
                                    }
                                },
                            });
                        }

                        if (!infoTab.get("tableStripedRows")) {
                            infoTab.add({
                                type: "checkbox",
                                id: "tableStripedRows",
                                label: "Striped Rows",
                                commit: function (type, element) {
                                    if (this.getValue()) {
                                        element.$.classList.add("table-striped");
                                    } else {
                                        element.$.classList.remove("table-striped");
                                    }
                                },
                                setup: function (element) {
                                    if (element.$.classList.contains("table-striped")) {
                                        this.setValue(true);
                                    } else {
                                        this.setValue(false);
                                    }
                                },
                            });
                        }

                        if (!infoTab.get("tableHoverableRows")) {
                            infoTab.add({
                                type: "checkbox",
                                id: "tableHoverableRows",
                                label: "Hoverable Rows",
                                commit: function (type, element) {
                                    if (this.getValue()) {
                                        element.$.classList.add("table-hover");
                                    } else {
                                        element.$.classList.remove("table-hover");
                                    }
                                },
                                setup: function (element) {
                                    if (element.$.classList.contains("table-hover")) {
                                        this.setValue(true);
                                    } else {
                                        this.setValue(false);
                                    }
                                },
                            });
                        }
                    }
                });

                // Customize table plugin behavior
                CKEDITOR.on("instanceReady", function (ev) {
                    if (restrictedTablePlugin) {
                        // Remove Paste from table context menu
                        ev.editor.removeMenuItem("paste");

                        // Remove Cell from table context menu
                        ev.editor.removeMenuItem("tablecell");

                        // Remove Column from table context menu
                        ev.editor.removeMenuItem("tablecolumn");

                        // Disable table toolbar icon if cursor is in table
                        ev.editor.on("selectionChange", function (ev) {
                            const command = ev.editor.getCommand("table");
                            if (command) {
                                const element = ev.editor.getSelection().getStartElement();
                                const table = element.getAscendant("table", true);

                                if (table) {
                                    command.setState(CKEDITOR.TRISTATE_DISABLED);
                                } else {
                                    command.setState(CKEDITOR.TRISTATE_OFF);
                                }
                            }
                        });
                    }
                });
            }
        }
    });
};

const destroyDialog = (dialog) => {
    dialog.destroy();

    const dialogElement = dialog.getElement();
    if (dialogElement) {
        dialogElement.remove();
    }
};

const destroyEditor = (editor, editorId) => {
    if (editor) {
        editor.removeAllListeners();
        editor.destroy();

        // Remove editor from DOM
        const editorElement = document.getElementById("cke_" + editorId);
        if (editorElement) {
            editorElement.remove();
        }
    }
};

const getEditorNamespace = () => {
    return new Promise((scriptResolve, scriptReject) => {
        if ("CKEDITOR" in window) {
            return scriptResolve(window.CKEDITOR);
        } else {
            loadScript(editorUrl, (err) => {
                if (err) {
                    scriptReject(err);
                } else {
                    scriptResolve(window.CKEDITOR);
                }
            });
        }
    });
};

export default HtmlEditorWidget;
