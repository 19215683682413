import React, { memo, useState, useRef } from "react";
import cn from "classnames";
import { isEmpty, isNumber } from "lodash";

import IconWrap from "../../Icons";
import useFocusClasses from "../../../utils/useFocusClasses";
import NumericInput from "../../Input/NumericInput";

import "../PagingCommon.scss";
import "./GridListPaging.scss";

const PAGES_TO_SHOW = 3;

const GridListPaging = memo(({ skip, take, total, hideTotal, compact, pageable, hideGoToPage, onPageChange, pageAmountToTruncate = 5 }) => {
    // Check if we will show any content
    const hideContent = total === 0 && !hideTotal;
    // Store flag for show / hide page list
    const [hidePageList] = useState(false);

    // Ref to paging element
    const elemREF = useRef(null);

    // No content, return nothing
    if (hideContent) {
        return null;
    }

    // Calculate active page and total pages count
    const activePage = skip / take + 1;
    const totalPages = parseInt(total / take, 10) + (total % take > 0 ? 1 : 0);

    // Show controls only if we have more items than allowed on the screen
    const showControls = total > take;

    // Change page
    const changePage = (page) => {
        onPageChange({
            page: {
                skip: take * (page - 1),
                take: take,
            },
        });
    };

    // Compact (for widgets)
    if (compact) {
        // Paging Info
        const paggingInfo = !hideTotal ? (
            <PaggingInfoCompact skip={skip} take={take} total={total} activePage={activePage} totalPages={totalPages} />
        ) : null;

        // Paging ontrols
        let paggingControls = null;
        if (showControls) {
            let pageSwitcher = null;

            // Less than 5 pages, show page list
            if (totalPages < pageAmountToTruncate && !hidePageList) {
                pageSwitcher = <PageList activePage={activePage} totalPages={totalPages} changePage={changePage} />;
            }

            // Show numeric input
            else if (!hideGoToPage) {
                pageSwitcher = (
                    <GoToPageCompact activePage={activePage} totalPages={totalPages} onSubmit={changePage} changePage={changePage} />
                );
            }

            paggingControls = <div>{pageSwitcher}</div>;
        }

        return (
            <div ref={elemREF} className="grid-list-paging flex-row align-center justify-space-between">
                {paggingInfo}
                {paggingControls}
            </div>
        );
    }

    if (pageable) {
        return (
            <div className="grid-list-paging flex-column">
                {showControls && <PageListMobile activePage={activePage} totalPages={totalPages} changePage={changePage} />}
                {!hideTotal ? (
                    <PaggingInfoMobile skip={skip} take={take} total={total} activePage={activePage} />
                ) : (
                    <PaggingInfo skip={skip} take={take} total={total} />
                )}
            </div>
        );
    } else {
        return (
            <div className="grid-list-paging flex-column">
                <div className="paging-info--mobile flex-column">
                    <div className="paging-info">{`${total} ${getItemsPluralSingular(total)}`}</div>
                </div>
            </div>
        );
    }
});

const getItemsPluralSingular = (total) => {
    if (total === 1) {
        return "item";
    } else {
        return "items";
    }
};

const PaggingInfo = ({ skip, take, total }) => {
    const firstRecord = skip + 1;
    let lastRecord = skip + take;
    lastRecord = lastRecord > total ? total : lastRecord;

    return (
        <div className="paging-info">
            <div>
                <b>
                    {firstRecord} - {lastRecord}
                </b>{" "}
                items of <b>{total}</b>
            </div>
        </div>
    );
};

const PaggingInfoCompact = ({ skip, take, total, activePage, totalPages }) => {
    const firstRecord = skip + 1;
    let lastRecord = skip + take;
    lastRecord = lastRecord > total ? total : lastRecord;

    // Show page counter only if we have more than one page
    const pageCounter =
        totalPages > 1 ? (
            <div className="page-counter">
                page <b>{activePage}</b> from <b>{totalPages}</b>
            </div>
        ) : null;

    return (
        <div className={"paging-info" + (totalPages > 1 ? " with-page-counter" : "")}>
            {pageCounter}
            <div>
                <b>
                    {firstRecord} - {lastRecord}
                </b>{" "}
                items of <b>{total}</b>
            </div>
        </div>
    );
};

const PaggingInfoMobile = ({ skip, take, total, activePage }) => {
    const firstRecord = skip + 1;
    let lastRecord = skip + take;
    lastRecord = lastRecord > total ? total : lastRecord;

    return (
        <div className="paging-info--mobile flex-column">
            <div className="paging-info-page flex-row align-center justify-center flex-one">
                <div className="paging-info-page-label">current page</div>
                <div className="paging-info-page-value">{activePage}</div>
            </div>
            <div className="paging-info">{`${firstRecord} - ${lastRecord} of ${total}`}</div>
        </div>
    );
};

const PageList = ({ activePage, totalPages, changePage }) => {
    const showFirstPage = totalPages > PAGES_TO_SHOW && activePage > PAGES_TO_SHOW - 1;
    const showFirstPageSpacer = totalPages > PAGES_TO_SHOW + 1 && activePage > PAGES_TO_SHOW;

    const showLastPage = totalPages > PAGES_TO_SHOW && activePage < totalPages - 1;
    const showLastPageSpacer = totalPages > PAGES_TO_SHOW + 1 && activePage < totalPages - (PAGES_TO_SHOW - 1);

    let pages = [];
    let pageListFirstPage = totalPages === activePage ? activePage - 2 : activePage - 1;

    while (pageListFirstPage < 1) {
        pageListFirstPage = pageListFirstPage + 1;
    }

    pages.push(pageListFirstPage);

    let lastPage = pageListFirstPage + 1;

    while (lastPage <= totalPages && lastPage <= pageListFirstPage + 2) {
        pages.push(lastPage);

        lastPage = lastPage + 1;
    }

    return (
        <div className="paging flex-row align-center flex-one justify-center">
            <IconWrap
                disabled={activePage < 2}
                icon="arrow-in-circle-left-empty"
                title="previous"
                onClick={() => changePage(activePage - 1)}
            />
            {showFirstPage && <SelectablePage pageNumber={1} onSelect={changePage} />}
            {showFirstPageSpacer && <div className={"selectable-page spacer"}>...</div>}
            {pages.map((page) => (
                <SelectablePage key={page} className={page === activePage ? " active" : ""} pageNumber={page} onSelect={changePage} />
            ))}
            {showLastPageSpacer && <div className={"selectable-page spacer"}>...</div>}
            {showLastPage && <SelectablePage pageNumber={totalPages} onSelect={changePage} />}
            <IconWrap
                disabled={activePage >= totalPages}
                icon="arrow-in-circle-right-empty"
                title="next"
                onClick={() => changePage(activePage + 1)}
            />
        </div>
    );
};

const PageListMobile = ({ activePage, totalPages, changePage }) => {
    const pages = Array(totalPages)
        .fill()
        .map((i, index) => index + 1);

    return (
        <div className="paging paging--mobile flex-row fill-width">
            {pages.map((page) => (
                <SelectablePage key={page} className={page === activePage ? " active" : ""} pageNumber={page} onSelect={changePage} />
            ))}
        </div>
    );
};

const SelectablePage = ({ className, pageNumber, onSelect }) => {
    const isTabable = true;
    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled: !isTabable,
    });

    const onClick = () => {
        onSelect(pageNumber);
    };

    const onKeyDown = (page) => (event) => {
        if (event.key === "Enter") {
            onSelect(page);
        }
    };

    return (
        <div
            className={cn("selectable-page", className)}
            onClick={onClick}
            tabIndex={isTabable ? "0" : "-1"}
            onFocus={onFocusClassesFocus}
            onBlur={onFocusClassesBlur}
            onKeyDown={onKeyDown(pageNumber)}
        >
            <span>{pageNumber}</span>
        </div>
    );
};

const GoToPageCompact = ({ activePage, totalPages, onSubmit, changePage }) => {
    const [value, setValue] = useState(activePage);

    const onChange = (event) => {
        let newValue = event.target.value;

        if (!isNumber(newValue) && isEmpty(newValue)) {
            setValue("");
        } else {
            newValue = event.target.value > totalPages ? totalPages : event.target.value < 1 ? 1 : event.target.value;

            setValue(Number(newValue));
        }
    };

    const onGoToPage = () => {
        if (isNumber(value)) {
            onSubmit(value);
        }

        setValue("");
    };

    const onKeyDown = (event) => {
        if (event.key === "Enter") {
            onGoToPage();
        }
    };

    const onGoBack = () => {
        setValue(activePage - 1);
        changePage(activePage - 1);
    };

    const onGoNext = () => {
        setValue(activePage + 1);
        changePage(activePage + 1);
    };

    return (
        <div className="paging-goto-page flex-row align-center">
            <IconWrap disabled={activePage < 2} icon="arrow-in-circle-left-empty" title="previous" onClick={onGoBack} />
            <NumericInput withoutIcon value={value} onChange={onChange} onKeyDown={onKeyDown} />
            <IconWrap disabled={activePage >= totalPages} icon="arrow-in-circle-right-empty" title="next" onClick={onGoNext} />
        </div>
    );
};

export default GridListPaging;
