import React from "react";
import withDataGrid from "../../../../DataGrid/withDataGrid";
import FilesGrid from "../../../../DataGrid/FilesGrid";
import { useUtilityRights } from "store/resources/useResource";
import { systemUserRights } from "components/utils/user";

export default withDataGrid("invoiceFiles", (props) => {
    const { utilityNumber, isLocked } = props;
    const [utilityRights = []] = useUtilityRights({ utilityNumber });
    const canEdit = !isLocked && utilityRights.includes(systemUserRights.VISIONDSM_ADD_FILES);

    return <FilesGrid {...props} canEdit={canEdit} />;
});
