import React from "react";
import DatePickerWidget from "./DatePickerWidget";
import Checkbox from "../../../Input/Checkbox";
import Separator from "../../../Separator";
import { dateToJson } from "../../../../utils/date";
import { getDateWithoutTime } from "components/utils/date";
import { InfoMessage } from "components/ui/Message";

import "./DateRangeValidationWidget.scss";

const DateRangeValidationWidget = (props) => {
    const isToday = props.value === "today";
    const currentDate = dateToJson(getDateWithoutTime(new Date()));
    const dateValue = isToday ? currentDate : props.value;
    const showInfo = props.options.showInfo ?? true;

    const onTodayChange = (event) => {
        props.onChange(event.target.checked ? "today" : undefined);
    };

    return (
        <div>
            <div className="date-range-validation-widget flex-row align-center">
                <DatePickerWidget {...props} disabled={props.disabled || isToday} value={dateValue} />
                <Separator vertical />
                <Checkbox label="Today" checked={isToday} onChange={onTodayChange} />
            </div>
            {showInfo && (
                <InfoMessage marginTop>
                    Datepicker sets static date value, and the checkbox sets dynamic value that is always the current date.
                </InfoMessage>
            )}
        </div>
    );
};

export default DateRangeValidationWidget;
