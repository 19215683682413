export enum PaddingCustomPropertyName {
    PaddingType = "PaddingType",
    PaddingAllSides = "PaddingAllSides",
    PaddingSideBySide = "PaddingSideBySide",
}

export enum PaddingSides {
    SideBySide = "side-by-side",
    AllSides = "all-sides",
}

export enum PaddingSideName {
    Top = "top",
    Right = "right",
    Bottom = "bottom",
    Left = "left",
    AllSides = "all-sides",
}

export interface PaddingSideProps {
    side: PaddingSideName;
    selectedValue: string;
    onSelect: (size: string) => void;
}

export interface PaddingSideBySideValue {
    [PaddingSideName.Top]: string;
    [PaddingSideName.Right]: string;
    [PaddingSideName.Bottom]: string;
    [PaddingSideName.Left]: string;
}

export interface PaddingCustomPropertiesValue {
    [PaddingCustomPropertyName.PaddingType]: PaddingSides;
    [PaddingCustomPropertyName.PaddingAllSides]: string;
    [PaddingCustomPropertyName.PaddingSideBySide]: PaddingSideBySideValue;
}
