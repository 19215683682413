import React, { memo, useCallback, useEffect, useContext } from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import { isNil } from "lodash";

import { filterTabs, getDefaultApplicationsFilter, getDefaultTasksFilter } from "./utils";
import {
    setWorkqueueGrid,
    setWorkqueueActiveFilterTab,
    setWorkqueueGridFilter,
    setWorkqueueApplicationsFilter,
    setWorkqueueTasksFilter,
    setWorkqueueGridSelectedRows,
} from "../../../store/workcenter/actions";
import { availableGrids } from "../configureGrids";

import TabList from "../../ui/List/TabList";
import ApplicationsFilterTab, { assignmentType } from "./ApplicationsFilterTab";
import TasksFilterTab, { ownershipType } from "./TasksFilterTab";
import ButtonGroup from "../../ui/Button/ButtonGroup";
import Button from "../../ui/Button";
import { windowContainerTypes } from "../../utils/window";
import { WorkCenterContext } from ".";

const DataSourceFilterControls = memo(({ closeFilterPanel }) => {
    const { instanceId } = useContext(WorkCenterContext);

    const dispatch = useDispatch();
    const activeDataSource = useSelector((state) => state.workcenter[instanceId].activeDataSource);
    const activeFilterTab = useSelector((state) => state.workcenter[instanceId].activeFilterTab);
    const applicationsFilter = useSelector((state) => state.workcenter[instanceId].applicationsFilter);
    const tasksFilter = useSelector((state) => state.workcenter[instanceId].tasksFilter);
    const user = useSelector((state) => state.user);

    const isMobile = useSelector((state) => state.window?.isMobile);
    const isTablet = useSelector((state) => state.window?.isTablet);
    const isSplitView = useSelector((state) => state.window[windowContainerTypes.Root].views.find((view) => view.active)?.isSplitView);

    useEffect(() => {
        if (!applicationsFilter) {
            dispatch(
                setWorkqueueApplicationsFilter({
                    instanceId,
                    applicationsFilter: getDefaultApplicationsFilter(),
                })
            );
        }

        if (!tasksFilter) {
            dispatch(
                setWorkqueueTasksFilter({
                    instanceId,
                    tasksFilter: getDefaultTasksFilter(),
                })
            );
        }
    }, [instanceId, applicationsFilter, tasksFilter, dispatch]);

    const datasourceNumber = activeDataSource && activeDataSource.datasourceNumber;

    const onActiveFilterTabChange = useCallback(
        (tab) => {
            dispatch(setWorkqueueGrid({ instanceId, workqueueGrid: null }));
            dispatch(
                setWorkqueueGridFilter({
                    instanceId,
                    workqueueGridFilter: null,
                })
            );
            dispatch(
                setWorkqueueActiveFilterTab({
                    instanceId,
                    activeFilterTab: tab,
                })
            );
        },
        [instanceId, dispatch]
    );

    const onApplicationsFilterChange = useCallback(
        (formData) => {
            if (Object.keys(formData).length) {
                batch(() => {
                    dispatch(setWorkqueueGrid({ instanceId, workqueueGrid: null }));
                    dispatch(
                        setWorkqueueGridFilter({
                            instanceId,
                            workqueueGridFilter: null,
                        })
                    );
                    dispatch(
                        setWorkqueueGridSelectedRows({
                            instanceId,
                            workqueueGridSelectedRows: [],
                        })
                    );
                    dispatch(
                        setWorkqueueApplicationsFilter({
                            instanceId,
                            applicationsFilter: formData,
                        })
                    );
                });
            }
        },
        [instanceId, dispatch]
    );

    const onTasksFilterChange = useCallback(
        (formData) => {
            if (Object.keys(formData).length) {
                batch(() => {
                    dispatch(setWorkqueueGrid({ instanceId, workqueueGrid: null }));
                    dispatch(
                        setWorkqueueGridFilter({
                            instanceId,
                            workqueueGridFilter: null,
                        })
                    );
                    dispatch(
                        setWorkqueueGridSelectedRows({
                            instanceId,
                            workqueueGridSelectedRows: [],
                        })
                    );
                    dispatch(
                        setWorkqueueTasksFilter({
                            instanceId,
                            tasksFilter: formData,
                        })
                    );
                });
            }
        },
        [instanceId, dispatch]
    );

    const onFilterShow = useCallback(() => {
        if ((isMobile || isTablet || isSplitView) && closeFilterPanel) {
            closeFilterPanel();
        }

        if (activeFilterTab.id === "applications") {
            let filter = applicationsFilter;

            // Workqueue apps "Any" | "Unassigned"
            if ([assignmentType.any, assignmentType.unassigned].includes(filter.assignment)) {
                let workqueueFilter = `datasourcenumber=${datasourceNumber}`;

                // All Unassigned Applications
                if (filter.assignment === assignmentType.unassigned) {
                    workqueueFilter = workqueueFilter + `|assigned=null`;
                }

                dispatch(
                    setWorkqueueGrid({
                        instanceId,
                        workqueueGrid: availableGrids.workqueueApps,
                        onSuccess: () => {
                            dispatch(
                                setWorkqueueGridFilter({
                                    instanceId,
                                    workqueueGridFilter: workqueueFilter,
                                })
                            );
                            dispatch(
                                setWorkqueueGridSelectedRows({
                                    instanceId,
                                    workqueueGridSelectedRows: [],
                                })
                            );
                        },
                    })
                );
            }

            // Workqueue apps "With Notes"
            if ([assignmentType.withNotes].includes(filter.assignment)) {
                let workqueueFilter = `datasourcenumber=${datasourceNumber}`;

                dispatch(
                    setWorkqueueGrid({
                        instanceId,
                        workqueueGrid: availableGrids.workqueueAppsNotes,
                        onSuccess: () => {
                            dispatch(
                                setWorkqueueGridFilter({
                                    instanceId,
                                    workqueueGridFilter: workqueueFilter,
                                })
                            );
                            dispatch(
                                setWorkqueueGridSelectedRows({
                                    instanceId,
                                    workqueueGridSelectedRows: [],
                                })
                            );
                        },
                    })
                );
            }

            // Workqueue apps "Ready for Status Change"
            if ([assignmentType.readyForStatusChange].includes(filter.assignment)) {
                let workqueueFilter = `datasourcenumber=${datasourceNumber}`;

                dispatch(
                    setWorkqueueGrid({
                        instanceId,
                        workqueueGrid: availableGrids.workqueueAppsStatusChange,
                        onSuccess: () => {
                            dispatch(
                                setWorkqueueGridFilter({
                                    instanceId,
                                    workqueueGridFilter: workqueueFilter,
                                })
                            );
                            dispatch(
                                setWorkqueueGridSelectedRows({
                                    instanceId,
                                    workqueueGridSelectedRows: [],
                                })
                            );
                        },
                    })
                );
            }

            // Workqueue apps "Assigned to"
            if ([assignmentType.assignedTo].includes(filter.assignment)) {
                let workqueueFilter = `datasourcenumber=${datasourceNumber}`;

                // Assigned to user
                if (filter.assignmentUser) {
                    workqueueFilter = workqueueFilter + `|assignedUserNumber=${filter.assignmentUser}`;
                }

                dispatch(
                    setWorkqueueGrid({
                        instanceId,
                        workqueueGrid: availableGrids.workqueueAppsAssigned,
                        onSuccess: () => {
                            dispatch(
                                setWorkqueueGridFilter({
                                    instanceId,
                                    workqueueGridFilter: workqueueFilter,
                                })
                            );
                            dispatch(
                                setWorkqueueGridSelectedRows({
                                    instanceId,
                                    workqueueGridSelectedRows: [],
                                })
                            );
                        },
                    })
                );
            }

            // Workqueue apps "Created by"
            if ([assignmentType.createdBy].includes(filter.assignment)) {
                let workqueueFilter = `datasourcenumber=${datasourceNumber}`;

                // Created by user
                if (filter.createdByUser) {
                    workqueueFilter = workqueueFilter + `|usernumber=${filter.createdByUser}`;
                }

                dispatch(
                    setWorkqueueGrid({
                        instanceId,
                        workqueueGrid: availableGrids.workqueueApps,
                        onSuccess: () => {
                            dispatch(
                                setWorkqueueGridFilter({
                                    instanceId,
                                    workqueueGridFilter: workqueueFilter,
                                })
                            );
                            dispatch(
                                setWorkqueueGridSelectedRows({
                                    instanceId,
                                    workqueueGridSelectedRows: [],
                                })
                            );
                        },
                    })
                );
            }

            // Workqueue apps "With tags"
            if ([assignmentType.withTags].includes(filter.assignment)) {
                let workqueueFilter = `datasourcenumber=${datasourceNumber}`;

                dispatch(
                    setWorkqueueGrid({
                        instanceId,
                        workqueueGrid: availableGrids.workqueueAppsWithTags,
                        onSuccess: () => {
                            dispatch(
                                setWorkqueueGridFilter({
                                    instanceId,
                                    workqueueGridFilter: workqueueFilter,
                                })
                            );
                            dispatch(
                                setWorkqueueGridSelectedRows({
                                    instanceId,
                                    workqueueGridSelectedRows: [],
                                })
                            );
                        },
                    })
                );
            }

            // Workqueue apps "Counts by Status"
            if ([assignmentType.countsByStatus].includes(filter.assignment)) {
                let workqueueFilter = `datasourcenumber=${datasourceNumber}`;

                dispatch(
                    setWorkqueueGrid({
                        instanceId,
                        workqueueGrid: availableGrids.workqueueAppsCountsByStatus,
                        onSuccess: () => {
                            dispatch(
                                setWorkqueueGridFilter({
                                    instanceId,
                                    workqueueGridFilter: workqueueFilter,
                                })
                            );
                            dispatch(
                                setWorkqueueGridSelectedRows({
                                    instanceId,
                                    workqueueGridSelectedRows: [],
                                })
                            );
                        },
                    })
                );
            }
        }

        if (activeFilterTab.id === "tasks") {
            let filter = tasksFilter;

            // Workqueue tasks
            if ([ownershipType.any, ownershipType.withNoOwner, ownershipType.ownedBy].includes(filter.ownership)) {
                let workqueueFilter = `datasourcenumber=${datasourceNumber}`;

                // Tasks with no owner
                if (filter.ownership === ownershipType.withNoOwner) {
                    workqueueFilter = workqueueFilter + `|usernumber=null`;
                }

                // Owned by user
                if (filter.ownership === ownershipType.ownedBy && filter.ownershipUser) {
                    workqueueFilter = workqueueFilter + `|usernumber=${filter.ownershipUser}`;
                }

                dispatch(
                    setWorkqueueGrid({
                        instanceId,
                        workqueueGrid: availableGrids.workqueueTasks,
                        onSuccess: () => {
                            dispatch(
                                setWorkqueueGridFilter({
                                    instanceId,
                                    workqueueGridFilter: workqueueFilter,
                                })
                            );
                            dispatch(
                                setWorkqueueGridSelectedRows({
                                    instanceId,
                                    workqueueGridSelectedRows: [],
                                })
                            );
                        },
                    })
                );
            }

            // Workqueue tasks Assigned Groups
            if ([ownershipType.onAssignedGroups].includes(filter.ownership)) {
                let workqueueFilter = `datasourcenumber=${datasourceNumber}|groupUserNumber=${filter.ownershipUser}`;

                dispatch(
                    setWorkqueueGrid({
                        instanceId,
                        workqueueGrid: availableGrids.workqueueTasksMyAssignedGroups,
                        onSuccess: () => {
                            dispatch(
                                setWorkqueueGridFilter({
                                    instanceId,
                                    workqueueGridFilter: workqueueFilter,
                                })
                            );
                            dispatch(
                                setWorkqueueGridSelectedRows({
                                    instanceId,
                                    workqueueGridSelectedRows: [],
                                })
                            );
                        },
                    })
                );
            }

            // Workqueue Tasks Assigned Apps
            if ([ownershipType.onAssignedApps].includes(filter.ownership)) {
                let workqueueFilter = `datasourcenumber=${datasourceNumber}|usernumber=${user?.userNumber}`;

                dispatch(
                    setWorkqueueGrid({
                        instanceId,
                        workqueueGrid: availableGrids.workqueueTasksAssignedApps,
                        onSuccess: () => {
                            dispatch(
                                setWorkqueueGridFilter({
                                    instanceId,
                                    workqueueGridFilter: workqueueFilter,
                                })
                            );
                            dispatch(
                                setWorkqueueGridSelectedRows({
                                    instanceId,
                                    workqueueGridSelectedRows: [],
                                })
                            );
                        },
                    })
                );
            }
        }
    }, [
        instanceId,
        datasourceNumber,
        activeFilterTab.id,
        applicationsFilter,
        tasksFilter,
        isMobile,
        isTablet,
        isSplitView,
        user?.userNumber,
        closeFilterPanel,
        dispatch,
    ]);

    if (!activeDataSource || !activeFilterTab) {
        return null;
    }

    let showFilterDisabled = true;
    if (applicationsFilter && activeFilterTab.id === "applications") {
        showFilterDisabled = false;
    }

    if (tasksFilter && activeFilterTab.id === "tasks") {
        showFilterDisabled = false;
    }

    return (
        <>
            <div className="filter-second-block no-scroll" key={datasourceNumber}>
                <div
                    className={
                        "tabs-block flex-column fill-height" + (!isNil(datasourceNumber) && datasourceNumber !== "0" ? "" : " disabled")
                    }
                >
                    <TabList tabListGhost items={filterTabs} activeItem={activeFilterTab} onClick={onActiveFilterTabChange} />
                    <div className="filter-tab flex-one-in-column">
                        {activeFilterTab.id === "applications" && (
                            <ApplicationsFilterTab onChange={onApplicationsFilterChange} values={applicationsFilter} />
                        )}
                        {activeFilterTab.id === "tasks" && <TasksFilterTab onChange={onTasksFilterChange} values={tasksFilter} />}
                    </div>
                </div>
            </div>
            <ButtonGroup transparent>
                <Button
                    primary
                    onClick={onFilterShow}
                    disabled={showFilterDisabled || (!isNil(datasourceNumber) && datasourceNumber === "0")}
                >
                    Show Results
                </Button>
            </ButtonGroup>
        </>
    );
});

export default DataSourceFilterControls;
