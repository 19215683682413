import React from "react";
import ColorSelectPanel from "./ColorSelect/ColorSelectPanel";
import { ColorSelectIcon } from "./ColorSelect/ColorSelectIcon";
import sassVariables from "../../../../../assets/sass/_export.module.scss";
import { AlignXPosition, AlignYPosition, PropertyName, PropertySize, PropertyType, ShadowBoxSize } from "../../types";
import { LeadImageProperty } from "./LeadImageProperty";

export const BACKGROUND_COLOR_PROPERTY = {
    id: "backgroundColor",
    title: (
        <>
            Background <br /> color
        </>
    ),
    type: "select-panel",
    defaultValue: sassVariables.colorLightGreyMedium,
    selectComponent: ColorSelectIcon,
    panelComponent: ColorSelectPanel,
};

export const LEAD_IMAGE_PROPERTY = {
    id: "leadImage",
    title: "Lead Image",
    type: "lead-image-parent",
    helperPropName: "_previousLeadImage",
    propertiesGroup: [
        {
            id: "leadImage",
            type: "select-panel",
            selectComponent: LeadImageProperty,
            borderTop: false,
            borderBottom: false,
        },
    ],
};

export const TEXT_COLOR_PROPERTY = {
    id: PropertyName.TextColor,
    title: "Text color",
    type: PropertyType.SelectPanel,
    selectComponent: ColorSelectIcon,
    panelComponent: ColorSelectPanel,
};

export const FONT_SIZE_PROPERTY = {
    id: "fontSize",
    title: "Font size",
    type: "select",
    items: [
        {
            label: "1",
            value: "1",
        },
        {
            label: "2",
            value: "2",
        },
        {
            label: "3",
            value: "3",
        },
        {
            label: "4",
            value: "4",
        },
        {
            label: "5",
            value: "5",
        },
        {
            label: "6",
            value: "6",
        },
    ],
};
export const FONT_WEIGHT_PROPERTY = {
    id: "fontWeight",
    title: "Font weight",
    type: "select",
    items: [
        {
            label: "Bold",
            value: "bold",
        },
        {
            label: "Bolder",
            value: "bolder",
        },
        {
            label: "Normal",
            value: "normal",
        },
        {
            label: "Light",
            value: "light",
        },
        {
            label: "Lighter",
            value: "lighter",
        },
    ],
};

export const BACKGROUND_COLOR_OPACITY_PROPERTY = {
    id: "backgroundColorOpacity",
    title: (
        <>
            Background <br /> opacity
        </>
    ),
    type: "input-range",
    borderBottom: false,
};

export const SHADOW_TYPE = {
    id: "shadowType",
    title: (
        <>
            Shadow <br /> type
        </>
    ),
    type: PropertyType.SelectBox,
    items: [
        {
            text: "Outer",
            value: "",
        },
        {
            text: "Inner",
            value: "inset",
        },
    ],
    borderTop: false,
};

export const SHADOW_SIZE = {
    id: "shadowSize",
    title: (
        <>
            Shadow <br /> size
        </>
    ),
    type: PropertyType.SelectBox,
    items: [
        {
            text: "XS",
            tooltip: "extra small",
            value: ShadowBoxSize.XS,
        },
        {
            text: "SM",
            tooltip: "small",
            value: ShadowBoxSize.SM,
        },
        {
            text: "MD",
            tooltip: "medium",
            value: ShadowBoxSize.MD,
        },
        {
            text: "LG",
            tooltip: "large",
            value: ShadowBoxSize.LG,
        },
        {
            text: "XL",
            tooltip: "extra large",
            value: ShadowBoxSize.XL,
        },
    ],
    className: "shadow-size",
};

export const SHADOW_COLOR_PROPERTY = {
    id: "shadowColor",
    title: (
        <>
            Shadow <br /> color
        </>
    ),
    type: PropertyType.SelectPanel,
    selectComponent: ColorSelectIcon,
    panelComponent: ColorSelectPanel,
    selectionTitle: "Shadow",
};

export const SHADOW_OPACITY_PROPERTY = {
    id: "shadowColorOpacity",
    title: (
        <>
            Shadow <br /> opacity
        </>
    ),
    type: "input-range",
    borderBottom: false,
};

export const ALIGN_X_PROPERTY = {
    id: "alignX",
    title: "Content Horizontal Alignment",
    type: "icon-selector",
    defaultValue: AlignXPosition.Center,
    items: [
        {
            icon: "align-left",
            value: AlignXPosition.Left,
            tooltip: "Aligned left",
        },
        {
            icon: "align-horizontal-center",
            value: AlignXPosition.Center,
            tooltip: "Aligned center",
        },
        {
            icon: "align-right",
            value: AlignXPosition.Right,
            tooltip: "Aligned right",
        },
    ],
};

export const ALIGN_Y_PROPERTY = {
    id: "alignY",
    title: "Content Vertical Alignment",
    type: "icon-selector",
    defaultValue: AlignYPosition.Center,
    items: [
        {
            icon: "align-top",
            value: AlignYPosition.Top,
            tooltip: "Aligned top",
        },
        {
            icon: "align-vertical-center",
            value: AlignYPosition.Center,
            tooltip: "Aligned center",
        },
        {
            icon: "align-bottom",
            value: AlignYPosition.Bottom,
            tooltip: "Aligned bottom",
        },
    ],
};

export const VISIBILITY_PROPERTY = {
    id: PropertyType.GroupVisibility,
    title: "Visibility",
    type: PropertyType.GroupVisibility,
    propertiesGroup: [
        {
            id: PropertyName.ShowOnDesktop,
            title: "Show on Desktop",
            type: PropertyType.Switch,
            defaultValue: true,
        },
        {
            id: PropertyName.ShowOnTablet,
            title: "Show on Tablet",
            type: PropertyType.Switch,
            defaultValue: true,
        },
        {
            id: PropertyName.ShowOnMobile,
            title: "Show on Mobile",
            type: PropertyType.Switch,
            defaultValue: true,
            borderBottom: false,
        },
    ],
};

export const BACKGROUND_PROPERTY = {
    id: "background-property",
    type: PropertyType.Background,
    titleSwitch: {
        id: "backgroundOff",
        defaultValue: true,
    },
    propertiesGroup: [
        {
            id: "colorType",
        },
        {
            id: "backgroundColor",
        },
        {
            id: "backgroundColorOpacity",
        },
        {
            id: "backgroundWidth",
        },
        {
            id: "linearGradient",
        },
        {
            id: "gradientDirection",
        },
        {
            id: "firstGradientColor",
        },
        {
            id: "secondGradientColor",
        },
        {
            id: "firstGradientValue",
        },
        {
            id: "secondGradientValue",
        },
    ],
};

export const BACKGROUND_WIDTH_PROPERTY = {
    id: "backgroundWidth",
    type: PropertyType.SelectBox,
    fullWidth: true,
    defaultValue: "",
    items: [
        {
            text: "Full Width",
            value: "",
        },
        {
            text: "Main Content",
            value: "main-content",
        },
    ],
};

export const SHADOW_PROPERTY = {
    id: "shadow-property",
    title: "Shadow",
    type: "shadow",
    propertiesGroup: [SHADOW_TYPE, SHADOW_SIZE, SHADOW_COLOR_PROPERTY, SHADOW_OPACITY_PROPERTY],
};

export const LINK_TARGET_PROPERTY = {
    id: "target",
    title: "Target",
    type: PropertyType.Select,
    items: [
        {
            label: "Same Tab",
            value: "_self",
        },
        {
            label: "New Tab",
            value: "_blank",
        },
    ],
};

export const WIDGET_LAYOUT_PROPERTY = {
    id: "layout",
    title: "Layout",
    type: PropertyType.Select,
    items: [
        {
            label: "Row",
            value: "row",
        },
        {
            label: "Column",
            value: "column",
        },
    ],
};

export const getLinkPropertiesGroup = (index, includeTarget) => {
    const targetProperty = includeTarget ? [{ ...LINK_TARGET_PROPERTY, id: `link${index}Target` }] : [];

    return {
        id: `link${index}`,
        title: `Link ${index}`,
        type: PropertyType.GroupLink,
        propertiesGroup: [
            {
                id: `link${index}Text`,
                title: "Text",
                type: PropertyType.Text,
                size: PropertySize.MD,
            },
            {
                id: `link${index}AltText`,
                title: "Description",
                type: PropertyType.Text,
                size: PropertySize.MD,
            },
            {
                id: `link${index}Icon`,
                title: "Icon",
                type: PropertyType.Select,
                items: [
                    {
                        label: "Facebook",
                        value: "facebook-f",
                    },
                    {
                        label: "Twitter",
                        value: "twitter",
                    },
                    {
                        label: "LinkedIn",
                        value: "linkedin",
                    },
                    {
                        label: "Youtube",
                        value: "youtube",
                    },
                    {
                        label: "Instagram",
                        value: "instagram",
                    },
                ],
            },
            {
                id: `link${index}Url`,
                title: "Url",
                type: PropertyType.Text,
                size: PropertySize.MD,
            },
            ...targetProperty,
        ],
    };
};
