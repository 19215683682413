import * as actionTypes from "../actionTypes";
import { store } from "store/configureStore";

export const BASE_URL = `${process.env.REACT_APP_SYSTEM_API_BASE_URL}`;

export const getForm =
    ({ formConfigurationNumber, actionVerb }) =>
    (dispatch, getState) => {
        if (getState().forms.isGettingForm) {
            return;
        }

        dispatch({
            type: actionTypes.API_GET_AUTHORIZED,
            url: `${BASE_URL}forms/${formConfigurationNumber}`,
            query: { actionVerb },
            actionTypes: {
                pending: actionTypes.FORM_GET_REQUEST,
                response: actionTypes.FORM_GET_SUCCESS,
                error: actionTypes.FORM_GET_ERROR,
            },
            passThroughData: { formConfigurationNumber, actionVerb },
        });
    };

export const submitForm = (formValues) => (dispatch, getState) => {
    const state = getState().forms;

    if (state.isSubmittingForm) {
        return;
    }

    const { APIConfiguration, actionVerb } = state.form;

    if (APIConfiguration && APIConfiguration[0]) {
        const targetURL = APIConfiguration[0].targetURL;

        const formData = APIConfiguration.map((c) => c.parameters)
            // concatenate all API parameters
            .reduce((result, next) => {
                return result.concat(next);
            }, [])
            // create object for submit
            .reduce(
                (result, next) => ({
                    ...result,
                    [next.columnname]: formValues[next.columnname],
                }),
                {}
            );

        dispatch({
            type: `API_${actionVerb}_AUTHORIZED`,
            url: `${process.env.REACT_APP_API_BASE_URL}${targetURL}`,
            body: JSON.stringify(formData),
            actionTypes: {
                pending: actionTypes.FORM_SUBMIT_REQUEST,
                response: actionTypes.FORM_SUBMIT_SUCCESS,
                error: actionTypes.FORM_SUBMIT_ERROR,
            },
        });
    }
};

export const clearForm = () => (dispatch, getState) => {
    dispatch({
        type: actionTypes.FORM_CLEAR,
    });
};

export const setFormData =
    ({ formId, formData }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.FORM_SET_DATA,
            formId,
            formData,
        });
    };

export const getFormData = ({ formId }) => {
    return store.getState().forms[`formData-${formId}`];
};

export const clearFormData = () => (dispatch, getState) => {
    dispatch({
        type: actionTypes.FORM_CLEAR_DATA,
    });
};
