import React, { useCallback, memo, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getData } from "../../../../../store/dataGrid/actions";
import { exportDatagridToCSV } from "../../../../utils/CSV";
import { getProgramsGridId, getProgramsGridFilter, getProgramsGridExportFileName } from "./utils";
import { sideNavKey, sideNavSize } from "components/ui/SideNav/SideNavRoot";
import useSidePanelHandlers from "../../../../utils/useSidePanelHandlers";
import { hasPermission, systemUserRights } from "../../../../utils/user";
import { WindowContext } from "../../../Windows/Window";

import IconWithLabel from "../../../Icons/IconWithLabel";
import Grid from "./Grid";
import ProgramForm from "./ProgramForm";
import ProgramCopyForm from "./ProgramCopyForm";

import "./Panel.scss";

const Panel = memo(({ utilityNumber }) => {
    const dispatch = useDispatch();

    const { isSplitView } = useContext(WindowContext);

    const user = useSelector((state) => state.user);

    const canCopy = hasPermission(user, systemUserRights.VISIONDSM_COPY_PROGRAM);
    const canCreate = hasPermission(user, systemUserRights.VISIONDSM_ADD_PROGRAM);

    const key = isSplitView ? undefined : sideNavKey.globalRight;
    const sidePanelProps = utilityNumber ? undefined : { sideNavKey: key };

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers(sidePanelProps);

    const DATAGRID_ID = getProgramsGridId({ utilityNumber });
    const FILTER = getProgramsGridFilter({ utilityNumber });
    const FILE_NAME = getProgramsGridExportFileName({ utilityNumber });

    const handleCopy = useCallback(() => {
        handleOpenSidePanel(<ProgramCopyForm utilityNumber={utilityNumber} gridId={DATAGRID_ID} onClose={handleCloseSidePanel} />, {
            size: sideNavSize.small,
            className: "program-copy-form-sidenav-panel",
        });
    }, [utilityNumber, handleOpenSidePanel, handleCloseSidePanel, DATAGRID_ID]);

    const handleCreateSuccess = useCallback(() => {
        handleCloseSidePanel();

        dispatch(
            getData({
                dataGridId: DATAGRID_ID,
            })
        );
    }, [DATAGRID_ID, handleCloseSidePanel, dispatch]);

    const handleCreate = useCallback(() => {
        handleOpenSidePanel(<ProgramForm utilityNumber={utilityNumber} onSuccess={handleCreateSuccess} onCancel={handleCloseSidePanel} />);
    }, [utilityNumber, handleCreateSuccess, handleOpenSidePanel, handleCloseSidePanel]);

    return (
        <>
            <div className="data-grid-controls flex-row justify-end">
                {canCreate && (
                    <IconWithLabel icon={"plus"} onClick={handleCreate}>
                        Create New Program
                    </IconWithLabel>
                )}
                {canCopy && (
                    <IconWithLabel icon="layers-empty" onClick={handleCopy}>
                        Copy Existing Program
                    </IconWithLabel>
                )}
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: DATAGRID_ID,
                            fileName: FILE_NAME,
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <div className="flex-column flex-one-in-column">
                <Grid gridId={DATAGRID_ID} filter={FILTER} />
            </div>
        </>
    );
});

export default Panel;
