import React, { useCallback, useState, memo } from "react";
import { useDispatch } from "react-redux";

import { documentsGridColumnKeys } from "../../../../../views/configureGrids";
import { updateResource } from "../../../../../../store/resources/actions";

import Label from "../../../../Label";
import Switcher from "../../../../Switcher";
import Button from "../../../../Button";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavContent from "../../../../SideNav/SideNavContent";
import { HtmlContentAsPdf } from "components/ui/HtmlContentPreview";
import { FileSecurity } from "components/utils/files";
import { systemUserRights } from "components/utils/user";
import { useProgramRights } from "store/resources/useResource";

const DocumentsEditForm = memo(({ dataItem, isLocked, gridRefresh, onClose, programNumber }) => {
    const dispatch = useDispatch();
    const [programRights = []] = useProgramRights({ programNumber });
    const [isPublic, setIsPublic] = useState(dataItem[documentsGridColumnKeys.status].toLowerCase() === "public");

    const title = "Correspondence";
    const leadBlockIcon = "eye-visibility-empty";

    const isEditDocumentsDisabled = isLocked || !programRights.includes(systemUserRights.VISIONDSM_ADD_CORRESPONDENCE);

    const handleChangeStatus = useCallback(() => {
        dispatch(
            updateResource({
                resourceName: "documentStatus",
                path: {
                    documentNumber: dataItem[documentsGridColumnKeys.documentNumber],
                },
                query: {
                    status: isPublic ? FileSecurity.PRIVATE : FileSecurity.PUBLIC,
                },
                onSuccess: () => {
                    gridRefresh();
                },
            })
        );

        setIsPublic(!isPublic);
    }, [isPublic, dataItem, gridRefresh, dispatch]);

    return (
        <SideNavContent className="sidenav-panel-correspondence">
            <SideNavHeader title={title} leadBlockIcon={leadBlockIcon} smallHeader onClose={onClose} />
            <SideNavBody>
                <HtmlContentAsPdf
                    fileContent={dataItem[documentsGridColumnKeys.content]}
                    fileName={dataItem[documentsGridColumnKeys.item]}
                />
            </SideNavBody>
            <SideNavFooter justifySpaceBetween>
                <div className="flex-row align-center">
                    <Label contentLabel>Public</Label>
                    <Switcher
                        icon={"with-icon " + (isPublic ? "check-done" : "clear-close")}
                        title="Change state"
                        active={isPublic}
                        disabled={isEditDocumentsDisabled}
                        onClick={handleChangeStatus}
                    />
                </div>
                <Button onClick={onClose}>Close</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default DocumentsEditForm;
