import React, { useState, memo } from "react";

import Button from "../../../../Button";
import ListItemCategory from "../../../../List/ListItemCategory";
import HtmlEditorWidget from "../../../../Form/JsonSchema/widgets/HtmlEditorWidget";
import { referenceTypes } from "../../../../Reference/referenceTypes";
import { useReference } from "../../../../Reference/useReference";
import { isNullOrWhitespace, stripHtml } from "components/utils/string";
import WaitIcon from "../../../../WaitIcon";

import SideNavContent from "components/ui/SideNav/SideNavContent";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import SideNavFooter from "components/ui/SideNav/SideNavFooter";

import "./QualityControlAddRequest.scss";

export const enabledToolbarButtons = [
    [{ header: 1 }, { header: 2 }],
    [{ font: [] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
];

const QCRequestTypes = memo(({ selected, onSelect }) => {
    const [qctype = [], isLoadingQctype] = useReference(referenceTypes.qcType);

    if (isLoadingQctype) {
        return <WaitIcon />;
    }

    return (
        <div className="types">
            {qctype.map((item) => (
                <ListItemCategory withArrow key={item.val} onClick={() => onSelect(item.val)} listItemSelected={item.val === selected}>
                    {item.display}
                </ListItemCategory>
            ))}
        </div>
    );
});

const QualityControlAddRequest = memo(({ onAdd, onClose }) => {
    const [description, setDescription] = useState("");
    const [selectedTypeId, setSelectedTypeId] = useState("");

    return (
        <SideNavContent>
            <SideNavHeader smallHeader leadBlockIcon="plus" title="Add QC Request" onClose={onClose} />
            <SideNavBody noPadding>
                <div className="add-qc-request-form">
                    <div className="flex-row form-values">
                        <div className="types-wrapper">
                            <QCRequestTypes selected={selectedTypeId} onSelect={setSelectedTypeId} />
                        </div>
                        <div className="flex-two rich-editor">
                            <HtmlEditorWidget
                                disabled={!selectedTypeId}
                                onChange={setDescription}
                                placeholder="Select Type of Request and type your description here..."
                                enabledToolbarButtons={enabledToolbarButtons}
                            />
                        </div>
                    </div>
                </div>
            </SideNavBody>
            <SideNavFooter justifyCenter>
                <Button
                    icon="plus"
                    type="submit"
                    onClick={onAdd(Number(selectedTypeId), description)}
                    primary
                    disabled={!selectedTypeId || !description || isNullOrWhitespace(stripHtml(description))}
                >
                    Add Request
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default QualityControlAddRequest;
