import React, { memo } from "react";
import cn from "classnames";

import { formatMoney } from "../../../../../utils/money";
import { statusTypes } from "../RebatesPanel/utils";
import { formatJsonDateTime } from "../../../../../utils/date";

import IconWrap from "../../../../Icons";

import "./RebatesListItem.scss";

const RebatesListItem = memo(({ item }) => {
    const isApproved = item.statusID === statusTypes.approved;
    const isCanceled = item.statusID === statusTypes.canceled;
    const emptyValue = <>&nbsp;</>;

    const renderItem = (label, value, className) => (
        <div className={cn("item-field", className)}>
            <span className="item-label">{label}</span>
            <div className="item-value">{value || emptyValue}</div>
        </div>
    );

    return (
        <div className="rebate-sidebar-list-item flex-row fill-width">
            <div className="status-icon">
                {isApproved && <IconWrap iconWrapSuccess title={"Status: " + item.statusName} iconWrap="check-circle-filled"></IconWrap>}
                {isCanceled && (
                    <IconWrap
                        iconWrapError
                        title={"Status: " + item.statusName}
                        iconWrap="cancel-clear-circle-highlight-off-filled"
                    ></IconWrap>
                )}
                {!isApproved && !isCanceled && (
                    <IconWrap iconWrapNeutral title={"Status: " + item.statusName} iconWrap="clock-time-watch-later-filled"></IconWrap>
                )}
            </div>
            <div className="flex-one payee">{renderItem("Payee:", item.payee)}</div>
            {item.enteredDate ? (
                <div className="date-time">{renderItem("Entered:", formatJsonDateTime(item.enteredDate))}</div>
            ) : (
                <div>{renderItem("Entered:", "-")}</div>
            )}
            <div className="flex-one amount">{renderItem("Amount:", formatMoney(item.amount))}</div>
        </div>
    );
});

export default RebatesListItem;
