import { useResource } from "./useResource";

const useAppEquipmentListForWidget = (
    applicationNumber: string | undefined
): [equipmentList: Equipment[] | undefined, isLoading: boolean] => {
    const key = applicationNumber ? `${applicationNumber}-equipment-widget` : undefined;

    return useResource({
        resourceName: "applicationEquipmentWidget",
        key: key,
        path: {
            appId: applicationNumber,
        },
    });
};

export type Equipment = {
    equipid: string;
    refid: string;
    name: string;
    category: string;
    quantity: number;
    incentive: number;
};

export default useAppEquipmentListForWidget;
