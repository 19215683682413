import React, { useState, useCallback, memo, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { updateResource } from "../../../store/resources/actions";
import {
    getUserThemes,
    setSettingsTheme,
    setSettingsThemeMode,
    setSettingsFontSize,
    setSettingsLayout,
    LAYOUT_WITH_BORDERS,
    LAYOUT_FULL_SCREEN,
    DEFAULT_THEME_MODE,
    availableUserThemeModes,
    DEFAULT_FONT_SIZE,
    availableUserFontSizes,
    setSettingsConfirmCloseAllTabs,
    getConfirmCloseAllTabs,
    setSettingsToggleTimezone,
} from "../../utils/settings";

import IconWrap from "../Icons";
import SelectBox from "../SelectBox";
import FieldGroupDropdownIcon from "../FieldGroupDropdownIcon";

import "./Theme.scss";
import "./Settings.scss";
import IconWithLabel from "../Icons/IconWithLabel";
import classNames from "classnames";
import { refreshAllTabsAndGrids } from "store/resources/refreshResource";
import { openConfirmModal } from "../Modal/utils";
import { windowAdd } from "store/window/actions";
import { windowContainerTypes } from "components/utils/window";
import { useResource } from "store/resources/useResource";
import { formatJsonDate } from "components/utils/date";

const Settings = memo(() => {
    const dispatch = useDispatch();
    const [themesOpen, setThemesOpen] = useState(false);
    const settingsFromStore = useSelector((state) => state.user?.settings);
    const settings = useMemo(() => settingsFromStore ?? {}, [settingsFromStore]);
    const isDesktop = useSelector((state) => state.window?.isDesktop);
    const isDesktopSmall = useSelector((state) => state.window?.isDesktopSmall);

    const themes = getUserThemes();
    const confirmCloseAllTabs = getConfirmCloseAllTabs();

    const selectedFontSize = settings.fontSize ?? DEFAULT_FONT_SIZE;
    const selectedThemeMode = settings.themeMode ?? DEFAULT_THEME_MODE;

    const [isActive, setIsActive] = useState(false);

    const [newReleaseNotes, setNewReleaseNotes] = useState(false);

    const currentVersion = localStorage.getItem("versionNumber");
    const [versionNumber, isLoadingVersionNumber] = useResource({
        resourceName: "currentReleaseNumber",
        key: "currentReleaseNumber",
        forced: true,
    });
    const systemVersion = useSelector((state) => state.system.currentVersion);
    const currentVersionDate = useSelector((state) => state.system.currentVersionDate);

    useEffect(() => {
        if (versionNumber) {
            if (currentVersion !== versionNumber) {
                setNewReleaseNotes(true);
            }
        }
    }, [versionNumber, currentVersion]);

    const onLayoutChange = useCallback(
        (layout) => {
            setSettingsLayout(layout);

            dispatch(
                updateResource({
                    resourceName: "userSettings",
                    body: {
                        ...settings,
                        layout,
                    },
                    showSuccessNotification: false,
                })
            );
        },
        [settings, dispatch]
    );

    const onThemeChange = useCallback(
        (theme) => {
            setSettingsTheme(theme);

            dispatch(
                updateResource({
                    resourceName: "userSettings",
                    body: {
                        ...settings,
                        theme,
                    },
                    showSuccessNotification: false,
                })
            );
        },
        [settings, dispatch]
    );

    const onThemeModeChange = useCallback(
        (themeMode) => {
            setSettingsThemeMode(themeMode);

            dispatch(
                updateResource({
                    resourceName: "userSettings",
                    body: {
                        ...settings,
                        themeMode: themeMode.name,
                    },
                    showSuccessNotification: false,
                })
            );
        },
        [settings, dispatch]
    );

    const onFontSizeChange = useCallback(
        (fontSize) => {
            setSettingsFontSize(fontSize);

            dispatch(
                updateResource({
                    resourceName: "userSettings",
                    body: {
                        ...settings,
                        fontSize: fontSize.name,
                    },
                    showSuccessNotification: false,
                })
            );
        },
        [settings, dispatch]
    );

    const handleToggleConfirmation = useCallback(() => {
        setSettingsConfirmCloseAllTabs(!confirmCloseAllTabs);
        dispatch(
            updateResource({
                resourceName: "userSettings",
                body: {
                    ...settings,
                    confirmCloseAllTabs: !confirmCloseAllTabs,
                },
            })
        );
    }, [confirmCloseAllTabs, dispatch, settings]);

    const handleClick = useCallback(() => {
        setIsActive(!isActive);
    }, [isActive]);

    const toggleThemesOpen = useCallback(() => {
        setThemesOpen(!themesOpen);
    }, [themesOpen]);

    const listTitle = (
        <div className="flex-column fill-width">
            <div className="layout flex-row align-center justify-space-between">
                <div className="settings-dropdown__title">Layout</div>
                <div className="flex-row align-center layout-switchers justify-center">
                    <SelectBox
                        icon="view-array-empty"
                        selectBoxText="with borders"
                        selectBoxSelected={(settings.layout ?? LAYOUT_WITH_BORDERS) === LAYOUT_WITH_BORDERS}
                        onClick={() => onLayoutChange(LAYOUT_WITH_BORDERS)}
                    ></SelectBox>
                    <SelectBox
                        icon="crop-7x5-empty"
                        selectBoxText="full screen"
                        selectBoxSelected={(settings.layout ?? LAYOUT_WITH_BORDERS) === LAYOUT_FULL_SCREEN}
                        onClick={() => onLayoutChange(LAYOUT_FULL_SCREEN)}
                    ></SelectBox>
                </div>
            </div>
            {themes && themes.length > 1 && (
                <>
                    <div className="settings-advanced flex-column align-center">
                        <div className="font-size-settings fill-width flex-row align-center justify-space-between">
                            <div className="settings-dropdown__title">Font Size</div>
                            <div className="flex-row font-size-switchers justify-center">
                                <SelectBox
                                    icon="font-empty"
                                    selectBoxTitle="Normal Font"
                                    selectBoxSelected={selectedFontSize === availableUserFontSizes.Normal.name}
                                    onClick={() => onFontSizeChange(availableUserFontSizes.Normal)}
                                ></SelectBox>
                                <SelectBox
                                    icon="font-empty"
                                    selectBoxTitle="Large Font"
                                    selectBoxSelected={selectedFontSize === availableUserFontSizes.Large.name}
                                    onClick={() => onFontSizeChange(availableUserFontSizes.Large)}
                                ></SelectBox>
                            </div>
                        </div>
                        <div className="theme-mode fill-width flex-row align-center justify-space-between">
                            <div className="theme-mode__title">Theme Mode</div>
                            <div className="flex-row theme-mode-switchers justify-center">
                                <div
                                    className={
                                        "theme-mode-switcher-wrap " +
                                        (selectedThemeMode === availableUserThemeModes.Light.name ? "light" : "") +
                                        (selectedThemeMode === availableUserThemeModes.Dark.name ? "dark" : "")
                                    }
                                >
                                    <IconWrap
                                        title="Light Mode"
                                        className={selectedThemeMode === availableUserThemeModes.Light.name && "selected"}
                                        icon="brightness-low-empty"
                                        onClick={() => onThemeModeChange(availableUserThemeModes.Light)}
                                    />
                                    <IconWrap
                                        title="Normal Mode"
                                        className={selectedThemeMode === availableUserThemeModes.Normal.name && "selected"}
                                        icon="cloud-empty"
                                        onClick={() => onThemeModeChange(availableUserThemeModes.Normal)}
                                    />
                                    {/*<IconWrap title="dark mode" className={(selectedThemeMode === availableUserThemeModes.Dark.name && "selected")} icon="star-grade-empty" onClick={() => onThemeModeChange(availableUserThemeModes.Dark)} />*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="theme-settings flex-row align-center justify-space-between">
                        <div className="settings-dropdown__title">Color Themes</div>
                        <IconWithLabel
                            onClick={toggleThemesOpen}
                            iconWithLabelRight
                            title={themesOpen ? "Hide list of color themes" : "Show list of color themes"}
                            className="toggle-themes-open"
                            icon={themesOpen ? "shevron-in-circle-up-filled" : "shevron-in-circle-down-drop-down-empty"}
                        >
                            {settings.theme}
                        </IconWithLabel>
                    </div>
                </>
            )}
        </div>
    );

    const confirmationSettings = (
        <>
            <div className="setting-with-switcher fill-width flex-row align-center justify-space-between">
                <div className="settings-dropdown__title">Ask for confirmation when closing all open tabs</div>
                <IconWrap
                    onClick={handleToggleConfirmation}
                    iconWrapBig
                    title={confirmCloseAllTabs ? "Switch OFF" : "Switch ON"}
                    icon={confirmCloseAllTabs ? "fiber-smart-record-filled" : "fiber-smart-record-empty"}
                ></IconWrap>
            </div>
            <TimeZoneSwitcher setIsActive={setIsActive} />
            <ReleaseNotesItem
                isActive={isActive}
                setIsActive={setIsActive}
                newReleaseNotes={newReleaseNotes}
                versionNumber={!isLoadingVersionNumber && versionNumber}
                setNewReleaseNotes={setNewReleaseNotes}
            />
            <div className="system-version setting-with-switcher fill-width flex-row align-center justify-space-between">
                <div className="settings-dropdown__title">VERSION: {systemVersion}</div>
                <div className="sv-date">{formatJsonDate(currentVersionDate)}</div>
            </div>
        </>
    );

    const renderTheme = useCallback(
        (theme) => {
            return (
                <div
                    className={"color-theme-item " + (theme.theme === settings.theme && "current")}
                    onClick={() => onThemeChange(theme.theme)}
                >
                    <span className="theme-name">{theme.name}</span>
                    <div className={"theme-color-list theme-" + theme.theme}>
                        <IconWrap className="theme-dark-color" iconWrap="chart-bubble-filled"></IconWrap>
                        <IconWrap className="theme-bottom-tabs-background-base" iconWrap="chart-bubble-filled"></IconWrap>
                        <IconWrap className="theme-light-color" iconWrap="chart-bubble-filled"></IconWrap>
                        <IconWrap className="theme-semi-white-color" iconWrap="chart-bubble-filled"></IconWrap>
                        <IconWrap className="theme-white-color" iconWrap="chart-bubble-filled"></IconWrap>
                        <IconWrap className="theme-dirty-color" iconWrap="chart-bubble-filled"></IconWrap>
                        <IconWrap className="theme-lighter-dirty-color" iconWrap="chart-bubble-filled"></IconWrap>
                    </div>
                    {theme.theme === settings.theme && <IconWrap iconWrap="check-circle-filled"></IconWrap>}
                </div>
            );
        },
        [settings.theme, onThemeChange]
    );

    if ((!isDesktop || isDesktopSmall) && (!themes || themes.length < 2)) {
        return;
    }

    const settingsDropdownClasses = classNames("settings-dropdown", {
        "hide-themes": !themesOpen,
    });

    return (
        <div className="header-settings-block">
            {newReleaseNotes && (
                <IconWrap iconWrapSmall onClick={handleClick} icon={"alert-add-empty"} className="new-version-alert"></IconWrap>
            )}
            <FieldGroupDropdownIcon
                title={isActive ? "Close" : "Settings"}
                mobileHeader="Settings"
                className={settingsDropdownClasses}
                dropdownRight
                dropdownOptions
                iconWrapDropdown
                iconWrapWhite
                withTitle={listTitle}
                visible={isActive}
                iconWrapActive={isActive}
                iconWrap={isActive ? "settings-filled" : "settings-empty"}
                onClick={handleClick}
                items={themes}
                footer={confirmationSettings}
                renderItem={renderTheme}
            />
        </div>
    );
});

export const ReleaseNotesItem = ({ setIsActive, isActive, versionNumber = null, setNewReleaseNotes, newReleaseNotes }) => {
    const dispatch = useDispatch();

    // Flashes the alert icon for 10 seconds if there is a new release note
    useEffect(() => {
        if (newReleaseNotes && isActive) {
            setTimeout(() => {
                setNewReleaseNotes(false);
                localStorage.setItem("versionNumber", versionNumber);
            }, 10000);
        }
    }, [newReleaseNotes, setNewReleaseNotes, isActive, versionNumber]);

    const openReleaseNotes = () => {
        dispatch(
            windowAdd({
                containerName: windowContainerTypes.Home,
                name: "Release Notes",
                component: "ReleaseNotes",
                title: "Release Notes",
                hideSearch: true,
                hideSplitView: true,
                hideFullscreen: true,
                activate: true,
                showTab: false,
                persist: false,
            })
        );

        setNewReleaseNotes(false);
        localStorage.setItem("versionNumber", versionNumber);
        setIsActive(false);
    };

    return (
        <div className="setting-with-switcher fill-width flex-row align-center justify-space-between release-notes-button">
            <div className="settings-dropdown__title">Release Notes</div>
            <IconWithLabel onClick={() => openReleaseNotes()} icon={newReleaseNotes && "alert-add-empty"} className="version-number">
                {versionNumber}
            </IconWithLabel>
        </div>
    );
};

export const TimeZoneSwitcher = ({ setIsActive }) => {
    const dispatch = useDispatch();
    const settingsFromStore = useSelector((state) => state.user?.settings);
    const settings = useMemo(() => settingsFromStore ?? {}, [settingsFromStore]);
    const { adjustTimeForTimezone } = settings;

    const handleToggleTimezone = useCallback(() => {
        const message = (
            <>
                {adjustTimeForTimezone ? (
                    <>
                        <p>All dates and time will be shown with server timezone (Eastern Standard Time - EST).</p>
                        <p>Are you sure you want to do this?</p>{" "}
                    </>
                ) : (
                    <>
                        <p>This action will adjust time for timezone in all of system components.</p>
                        <p>Are you sure you want to do this?</p>{" "}
                    </>
                )}
            </>
        );

        openConfirmModal({
            title: "Adjust Time for My Timezone",
            modalIcon: "circle-exclamation-empty",
            message,
            onConfirm: () => {
                setSettingsToggleTimezone(!adjustTimeForTimezone);
                dispatch(
                    updateResource({
                        resourceName: "userSettings",
                        body: {
                            ...settings,
                            adjustTimeForTimezone: !adjustTimeForTimezone,
                        },
                        onSuccess: () => {
                            refreshAllTabsAndGrids();
                            setIsActive(true);
                        },
                    })
                );
            },
        });
    }, [adjustTimeForTimezone, dispatch, settings, setIsActive]);

    return (
        <div className="setting-with-switcher fill-width flex-row align-center justify-space-between">
            <div className="settings-dropdown__title">Adjust time for my timezone</div>
            <IconWrap
                onClick={handleToggleTimezone}
                iconWrapBig
                title={adjustTimeForTimezone ? "Switch OFF" : "Switch ON"}
                icon={adjustTimeForTimezone ? "fiber-smart-record-filled" : "fiber-smart-record-empty"}
            ></IconWrap>
        </div>
    );
};

export default Settings;
