import { omitBy } from "lodash";
import * as actionTypes from "../actionTypes";

const initialState = {
    isGettingForm: false,
    isSubmittingForm: false,
    form: null,
    isError: false,
    message: null,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FORM_GET_REQUEST:
            state = {
                ...state,
                isGettingForm: true,
                isError: false,
                message: null,
            };
            break;
        case actionTypes.FORM_GET_SUCCESS:
            state = {
                ...state,
                isGettingForm: false,
                form: {
                    ...action.data,
                    ...action.passThroughData,
                },
            };
            break;
        case actionTypes.FORM_GET_ERROR:
            state = {
                ...state,
                isGettingForm: false,
                isError: true,
                message: action.message,
            };
            break;
        case actionTypes.FORM_SUBMIT_REQUEST:
            state = {
                ...state,
                isSubmittingForm: true,
                isError: false,
                message: null,
            };
            break;
        case actionTypes.FORM_SUBMIT_SUCCESS:
            state = {
                ...state,
                isSubmittingForm: false,
                message: action.data.responseMessage,
            };
            break;
        case actionTypes.FORM_SUBMIT_ERROR:
            state = {
                ...state,
                isSubmittingForm: false,
                isError: true,
                message: action.message,
            };
            break;
        case actionTypes.FORM_CLEAR:
            state = {
                ...state,
                form: null,
            };
            break;
        case actionTypes.FORM_MESSAGE_CLEAR:
            state = {
                ...state,
                isError: false,
                message: null,
            };
            break;
        case actionTypes.FORM_SET_DATA:
            state = {
                ...state,
                [`formData-${action.formId}`]: action.formData,
            };
            break;
        case actionTypes.FORM_CLEAR_DATA:
            state = omitBy(state, (value, key) => key.startsWith("formData-"));
            break;
        default:
            break;
    }

    return state;
};
