import { createHttpError } from "../../components/utils/error";
import { refresh } from "../login/actions";
import { reportError } from "../error/actions";
import queryString from "query-string";
import { isStatusExpired, tokenExpired } from "../../components/utils/user";
import { clearRequestRetryNotification, REQUEST_RETRY_EXCLUDED_HTTP_STATUS_CODES, retryRequest } from "./requestRetryMiddleware";
import { isEmpty } from "lodash";
import {
    API_GET,
    API_GET_AUTHORIZED,
    API_POST,
    API_POST_AUTHORIZED,
    API_PUT,
    API_PUT_AUTHORIZED,
    API_DELETE,
    API_DELETE_AUTHORIZED,
} from "../actionTypes";

const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
};

const getRequestParams = {
    method: "GET",
    mode: "cors",
    headers: headers,
};

const postRequestParams = {
    method: "POST",
    mode: "cors",
    headers: headers,
};

const putRequestParams = {
    method: "PUT",
    mode: "cors",
    headers: headers,
};

const deleteRequestParams = {
    method: "DELETE",
    mode: "cors",
    headers: headers,
};

export function apiMiddleware({ dispatch, getState }) {
    return (next) => (action) => {
        var actionFunc = next;

        switch (action.type) {
            case API_GET:
                actionFunc = apiGet;
                break;
            case API_GET_AUTHORIZED:
                actionFunc = apiGetAuthorized;
                break;
            case API_POST:
                actionFunc = apiPost;
                break;
            case API_POST_AUTHORIZED:
                actionFunc = apiPostAuthorized;
                break;
            case API_PUT:
                actionFunc = apiPut;
                break;
            case API_PUT_AUTHORIZED:
                actionFunc = apiPutAuthorized;
                break;
            case API_DELETE:
                actionFunc = apiDelete;
                break;
            case API_DELETE_AUTHORIZED:
                actionFunc = apiDeleteAuthorized;
                break;
            default:
                actionFunc = next;
                break;
        }

        return actionFunc(action);
    };

    async function apiGet(action, requestParams = getRequestParams) {
        const { url, query, recaptchaAction, actionTypes, passThroughData } = action;

        if (recaptchaAction) {
            Object.assign(requestParams.headers, { recaptcha: await getRecaptchaToken(recaptchaAction) });
        }

        let queryParams = "";
        if (query) {
            queryParams = getQueryString(query);
        }

        try {
            actionTypes?.pending &&
                dispatch({
                    type: actionTypes.pending,
                    passThroughData: passThroughData,
                });

            const response = await fetch(url + queryParams, requestParams);
            handleHttpResponse(response, action);
        } catch (e) {
            handleNetworkError(e, action);
        }
    }

    async function apiGetAuthorized(action) {
        const requestParams = {
            method: "GET",
            headers: getAuthorizedHeaders(),
        };

        if (needToRefreshToken()) {
            refresh({ action })(dispatch, getState);
            return;
        }

        apiGet(action, requestParams);
    }

    async function apiPost(action, requestParams = postRequestParams) {
        const { url, headers, query, body, recaptchaAction, actionTypes, passThroughData } = action;

        if (headers) {
            requestParams.headers = {
                ...requestParams.headers,
                ...headers,
            };
        }

        if (recaptchaAction) {
            Object.assign(requestParams.headers, { recaptcha: await getRecaptchaToken(recaptchaAction) });
        }

        let queryParams = "";
        if (query) {
            queryParams = getQueryString(query);
        }

        if (body) {
            requestParams.body = body;
            if (body instanceof FormData) {
                const { "Content-Type": _remove, ...result } = requestParams.headers;

                requestParams.headers = result;
            }
        }

        try {
            actionTypes?.pending &&
                dispatch({
                    type: actionTypes.pending,
                    passThroughData: passThroughData,
                });

            const response = await fetch(url + queryParams, requestParams);
            handleHttpResponse(response, action);
        } catch (e) {
            handleNetworkError(e, action);
        }
    }

    async function apiPostAuthorized(action) {
        const headers = getAuthorizedHeaders();

        const requestParams = {
            method: "POST",
            headers,
        };

        if (needToRefreshToken()) {
            refresh({ action })(dispatch, getState);
            return;
        }

        apiPost(action, requestParams);
    }

    async function apiPut(action, requestParams = putRequestParams) {
        const { url, query, body, recaptchaAction, actionTypes, passThroughData } = action;

        if (recaptchaAction) {
            Object.assign(requestParams.headers, { recaptcha: await getRecaptchaToken(recaptchaAction) });
        }

        let queryParams = "";
        if (query) {
            queryParams = getQueryString(query);
        }

        if (body) {
            requestParams.body = body;
        }

        try {
            actionTypes?.pending &&
                dispatch({
                    type: actionTypes.pending,
                    passThroughData: passThroughData,
                });

            const response = await fetch(url + queryParams, requestParams);
            handleHttpResponse(response, action);
        } catch (e) {
            handleNetworkError(e, action);
        }
    }

    async function apiPutAuthorized(action) {
        const requestParams = {
            method: "PUT",
            headers: getAuthorizedHeaders(),
        };

        if (needToRefreshToken()) {
            refresh({ action })(dispatch, getState);
            return;
        }

        apiPut(action, requestParams);
    }

    async function apiDelete(action, requestParams = deleteRequestParams) {
        const { url, query, body, recaptchaAction, actionTypes, passThroughData } = action;

        if (recaptchaAction) {
            Object.assign(requestParams.headers, { recaptcha: await getRecaptchaToken(recaptchaAction) });
        }

        let queryParams = "";
        if (query) {
            queryParams = getQueryString(query);
        }

        if (body) {
            requestParams.body = body;
        }

        try {
            actionTypes?.pending &&
                dispatch({
                    type: actionTypes.pending,
                    passThroughData: passThroughData,
                });

            const response = await fetch(url + queryParams, requestParams);
            handleHttpResponse(response, action);
        } catch (e) {
            handleNetworkError(e, action);
        }
    }

    async function apiDeleteAuthorized(action) {
        const requestParams = {
            method: "DELETE",
            headers: getAuthorizedHeaders(),
        };

        if (needToRefreshToken()) {
            refresh({ action })(dispatch, getState);
            return;
        }

        apiDelete(action, requestParams);
    }

    async function handleHttpResponse(response, action) {
        const { actionTypes, passThroughData, onSuccess, onComplete } = action;

        if (isTokenExpired(response)) {
            if (actionTypes?.error) {
                dispatch({
                    type: actionTypes.error,
                    message: null,
                    passThroughData,
                });
            }

            refresh({ action, dispatchError: true })(dispatch, getState);

            return;
        }

        if (response.ok) {
            let data = null;
            clearRequestRetryNotification(action);

            if (isJson(response)) {
                try {
                    data = await response.json();
                } catch (error) {
                    handleError(error.message, action, response.status);
                    return;
                }
            } else if (isFileDownload(response)) {
                data = {
                    blob: await response.blob(),
                    fileName: getFileName(response),
                };
            }
            // If response is html, then return response text.
            else if (response.headers.get("content-type").includes("text/html")) {
                data = await response.text();
            } else {
                handleHttpError(response, action);
                return;
            }

            if (actionTypes?.response) {
                dispatch({
                    type: actionTypes.response,
                    data: data,
                    passThroughData: passThroughData,
                    actionTypes: actionTypes,
                });
            }

            if (onSuccess) {
                onSuccess({
                    ...action,
                    data,
                });
            }
        } else {
            handleHttpError(response, action);
        }

        if (onComplete) {
            onComplete(action);
        }
    }

    async function handleHttpError(response, action) {
        let message = "";

        if (isJson(response)) {
            try {
                const json = await response.json();
                message = json.responseMessage ?? json.title;
            } catch (error) {
                message = error.message;
            }
        } else {
            const text = await response.text();
            message = text ? text : `${response.status} (${response.statusText})`;
        }

        handleError(message, action, response.status);
    }

    async function handleNetworkError(error, action) {
        const message = error.message;

        handleError(message, action, 0);
    }

    async function handleError(message, action, status) {
        const { url, query, body, actionTypes, skipErrorReport, passThroughData, requestRetriesLeft, onError } = action;
        const state = getState();
        let name = null;

        if (!REQUEST_RETRY_EXCLUDED_HTTP_STATUS_CODES.includes(status) && requestRetriesLeft > 0) {
            retryRequest(action)(dispatch, getState);
            return;
        } else {
            clearRequestRetryNotification(action);
        }

        if (actionTypes) {
            const { error } = actionTypes;
            name = error;

            error &&
                dispatch({
                    type: error,
                    message: message,
                    passThroughData: passThroughData,
                });
        }

        if (!skipErrorReport) {
            // Report error
            var errorData = createHttpError({
                name,
                message,
                body,
                url: url + getQueryString(query, { encode: false }),
                state,
            });

            dispatch(reportError(errorData));
        }

        if (onError) {
            onError({
                ...action,
                message,
            });
        }
    }

    function isJson(response) {
        const header = response.headers.get("content-type");
        return Boolean(header && (header.includes("application/json") || header.includes("application/problem+json")));
    }

    function isTokenExpired(response) {
        let isExpired = false;

        if (response.status === 401) {
            const header = response.headers.get("www-authenticate");
            isExpired = Boolean(header && header.includes("invalid_token"));
        }

        return isExpired;
    }

    function needToRefreshToken(response) {
        const user = getState().user;

        // Added isStatusExpired to not try to refresh token for expired user and redirect to login.
        // Expired user needs to stay in update password form and call backend for additional form info.
        if (isEmpty(user) || isStatusExpired(user)) {
            return false;
        }

        // If window has a flag to skip token refresh, return false. This is needed for tests.
        if (window.SKIP_TOKEN_REFRESH) {
            return false;
        }

        return tokenExpired(user);
    }

    function isFileDownload(response) {
        const header = response.headers.get("content-disposition");
        return Boolean(header && header.includes("attachment;"));
    }

    function getFileName(response) {
        let fileName = null;
        const header = response.headers.get("content-disposition");

        if (header && header.includes("filename=")) {
            fileName = header.split("filename=")[1].split(";")[0];
        }

        return fileName;
    }

    function getAuthorizedHeaders() {
        const user = getState().user;
        let accessToken = null;

        if (user) {
            accessToken = user.accessToken;
        }

        return {
            ...headers,
            Authorization: `Bearer ${accessToken}`,
        };
    }

    function getQueryString(queryParams, options) {
        const keys = Object.keys(queryParams ?? {});

        if (!keys.length) {
            return "";
        }

        return "?" + queryString.stringify(queryParams, options);
    }
}

/**
 * Get recaptcha token.
 *
 * @export
 * @param {string | undefined} action - Any name for the action this token will be used for. Use underscore to separate words.
 * @returns {Promise|null} Promise that resolves to a token or null if recaptcha is disabled.
 */
export async function getRecaptchaToken(action) {
    const grecaptcha = window.grecaptcha;

    return new Promise((resolve, reject) => {
        grecaptcha.ready(function () {
            grecaptcha
                .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action })
                .then(function (token) {
                    resolve(token);
                })
                .catch(function (reason) {
                    reject(reason);
                });
        });
    });
}
