import React, { memo, useCallback, useState, useMemo } from "react";
import cn from "classnames";
import TextInput from "../Input/TextInput";
import { isObject } from "lodash";

const ListFilter = memo(({ className, autoFocus, filterValue, onFilterChange }) => {
    const onIconClick = useCallback(() => {
        onFilterChange && onFilterChange({ target: { value: "" } });
    }, [onFilterChange]);

    return (
        <div className={cn("list-filter-container", className)}>
            <TextInput
                autoFocus={autoFocus}
                placeholder="Filter List"
                iconRight
                icon={filterValue.length === 0 ? "search" : "clear-close"}
                iconTitle={filterValue.length === 0 ? "" : "Clear Filter"}
                onIconClick={filterValue.length === 0 ? undefined : onIconClick}
                value={filterValue}
                onChange={onFilterChange}
            />
        </div>
    );
});

export const useListFilter = ({ items, filterKey, initialFilterValue }) => {
    const [filterValue, setFilterValue] = useState(initialFilterValue || "");

    const getListItemValue = useCallback(
        (item) => {
            return (isObject(item) ? item[filterKey] : item) || "";
        },
        [filterKey]
    );

    const onFilterChange = useCallback((event) => {
        setFilterValue(event.target.value);
    }, []);

    const filteredList = useMemo(() => {
        const filter = (filterValue || "").toLowerCase();

        return (items || []).filter((item) => filter === "" || getListItemValue(item).toLowerCase().includes(filter));
    }, [filterValue, items, getListItemValue]);

    return [filteredList, filterValue, onFilterChange];
};

export default ListFilter;
