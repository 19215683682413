import { store } from "../configureStore";
import { get } from "lodash";
import { getData } from "./actions";
import { getFriendlyNamesGridGridId } from "../../components/views/configureGrids";
import { getInvoiceItemsGridId, getInvoiceNotesGridId, getInvoiceFilesGridId } from "../../components/ui/Dashboard/Panel/Invoice/utils";
import {
    getInvoiceDocumentsGridId,
    getContractDetailsInvoiceDocumentsGridId,
} from "../../components/ui/Dashboard/Panel/Contract/InvoicesPanel/utils";
import { getEventCategoriesGridId } from "../../components/ui/Dashboard/Panel/Program/EventsPanel/utils";
import { getCatalogCategoriesGridId } from "../../components/ui/Dashboard/Panel/Program/CatalogPanel/utils";
import { getProgramCalculationsGridId } from "components/ui/Dashboard/Panel/CalculationsPanel/ProgramCalculationsTab/utils";

export const refreshGrid = ({ dataGridId }) => {
    const state = store.getState();

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshAllGrids = () => {
    const state = store.getState();
    Object.keys(state.dataGrid).forEach((gridId) => refreshGrid({ dataGridId: gridId }));
};

export const refreshRecentApplicationsGrid = () => {
    const state = store.getState();

    const userNumber = get(state, "user.userNumber");
    const viewDataGridId = `${userNumber}-applications-recent-projects-grid`;
    const widgetDataGridId = `${userNumber}-widget-applications-recent-projects-grid`;

    if (state.dataGrid[viewDataGridId]) {
        store.dispatch(
            getData({
                dataGridId: viewDataGridId,
            })
        );
    }

    if (state.dataGrid[widgetDataGridId]) {
        store.dispatch(
            getData({
                dataGridId: widgetDataGridId,
            })
        );
    }
};

export const refreshAllHostnamesGrid = () => {
    const state = store.getState();
    const dataGridId = "all-hostnames";

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshFTPSchedulesGrid = () => {
    const state = store.getState();
    const dataGridId = "ftp-schedules";

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshFTPConfigsGrid = () => {
    const state = store.getState();
    const dataGridId = "ftp-configs";

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshContractBookmarksGrid = () => {
    const state = store.getState();
    const dataGridId = "contract-bookmarks";
    const dataGridIdWidget = "widget-contract-bookmarks";

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId: dataGridId }));
    }

    if (state.dataGrid[dataGridIdWidget]) {
        store.dispatch(getData({ dataGridId: dataGridIdWidget }));
    }
};

export const refreshInvoiceDocumentBookmarksGrid = () => {
    const state = store.getState();
    const dataGridId = "invoice-document-bookmarks";
    const dataGridIdWidget = "widget-invoice-document-bookmarks";

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId: dataGridId }));
    }

    if (state.dataGrid[dataGridIdWidget]) {
        store.dispatch(getData({ dataGridId: dataGridIdWidget }));
    }
};

export const refreshUtilityBookmarksGrid = () => {
    const state = store.getState();
    const { userNumber } = state.user;
    const dataGridId = `${userNumber}-utilities-bookmarks-grid`;

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshUtilityBookmarksWidgetGrid = () => {
    const state = store.getState();
    const { userNumber } = state.user;
    const dataGridId = `${userNumber}-widget-utilities-bookmarks-grid`;

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshProgramBookmarksGrid = () => {
    const state = store.getState();
    const { userNumber } = state.user;
    const dataGridId = `${userNumber}-programs-bookmarks-grid`;

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshProgramBookmarksWidgetGrid = () => {
    const state = store.getState();
    const { userNumber } = state.user;
    const dataGridId = `${userNumber}-widget-programs-bookmarks-grid`;

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshApplicationBookmarksGrid = () => {
    const state = store.getState();
    const { userNumber } = state.user;
    const dataGridId = `${userNumber}-applications-bookmarks-grid`;

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshApplicationBookmarksWidgetGrid = () => {
    const state = store.getState();
    const { userNumber } = state.user;
    const dataGridId = `${userNumber}-widget-applications-bookmarks-grid`;

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshFriendlyNamesGrid = ({ formNumber }) => {
    const state = store.getState();
    const dataGridId = getFriendlyNamesGridGridId({ formNumber });

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshInvoiceItemsGrid = ({ documentNumber }) => {
    const state = store.getState();
    const dataGridId = getInvoiceItemsGridId({ documentNumber });

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshInvoiceDocumentsGrid = ({ contractNumber }) => {
    const state = store.getState();
    const dataGridId = getInvoiceDocumentsGridId({ contractNumber });

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshContractDetailsInvoiceDocumentsGrid = ({ contractNumber }) => {
    const state = store.getState();
    const dataGridId = getContractDetailsInvoiceDocumentsGridId({
        contractNumber,
    });

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshInvoiceNotesGrid = ({ documentNumber }) => {
    const state = store.getState();
    const dataGridId = getInvoiceNotesGridId({ documentNumber });

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshInvoiceFilesGrid = ({ documentNumber }) => {
    const state = store.getState();
    const dataGridId = getInvoiceFilesGridId({ documentNumber });

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshCustomerApplicationsGrid = ({ customerNumber }) => {
    const state = store.getState();
    const dataGridId = `${customerNumber}-applications`;

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshProgramApplicationsGrid = ({ programNumber }) => {
    const state = store.getState();
    const dataGridId = `${programNumber}-applications`;

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshEventCalculationsGrid = ({ utilityNumber }) => {
    const state = store.getState();
    const dataGridId = `${utilityNumber}-events-calculations-grid`;

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshEventsGrid = ({ applicationNumber }) => {
    const state = store.getState();
    const dataGridId = `${applicationNumber}-events-grid`;
    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshEquipmentGrid = ({ applicationNumber }) => {
    const state = store.getState();
    const dataGridId = `${applicationNumber}-equipment-grid`;
    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshCatalogCalculationsGrid = ({ utilityNumber }) => {
    const state = store.getState();
    const dataGridId = `${utilityNumber}-catalog-calculations-grid`;

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshProgramCalculationsGrid = ({ utilityNumber, programNumber }) => {
    const state = store.getState();
    const dataGridId = getProgramCalculationsGridId({
        utilityNumber,
        programNumber,
    });

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshCatalogCategoriesGrid = ({ programNumber }) => {
    const state = store.getState();
    const dataGridId = getCatalogCategoriesGridId({ programNumber });

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

export const refreshEventCategoriesGrid = ({ programNumber }) => {
    const state = store.getState();
    const dataGridId = getEventCategoriesGridId({ programNumber });

    if (state.dataGrid[dataGridId]) {
        store.dispatch(getData({ dataGridId }));
    }
};

// Refresh results grid in all workcenter instances
export const refreshWorkcenterGrid = () => {
    const state = store.getState();
    const workcenterInstanceKeys = Object.keys(state.workcenter ?? {});

    workcenterInstanceKeys.forEach((key) => {
        const instance = state.workcenter[key];

        if (instance.workqueueGrid && instance.workqueueGridId) {
            store.dispatch(getData({ dataGridId: instance.workqueueGridId }));
        }
    });
};
