import React, { memo, useCallback, useState, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { InfoMessage } from "../../../../Message";
import { ElementTypes } from "../../../../../utils/form";
import PageProperties from "../../../../FormBuilder/PageProperties";
import SectionProperties from "../../../../FormBuilder/SectionProperties";
import FieldProperties from "../../../../FormBuilder/FieldProperties";
import WaitIcon from "../../../../WaitIcon";
import Button from "../../../../Button";
import SideNavContent from "../../../../SideNav/SideNavContent";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavBody from "../../../../SideNav/SideNavBody";
import StatusMsg from "components/ui/StatusMsg";
import ActionLabel from "components/ui/Label/ActionLabel";
import { onFieldPropertiesChange, onPagePropertiesChange, onSectionPropertiesChange } from "../../../../FormBuilder/utils";
import { selectElement, setValidationErrors, copyValidationErrors } from "../../../../../../store/formBuilder/actions";
import { getFormBuilder } from "../../../../../../store/formBuilder/selectors";
import { ApplicationFormPagesContext } from "../../../../../views/ApplicationFormPages";
import {
    refreshProgramCalculationsForm,
    refreshApplicationFormsInOpenTabs,
    refreshProgramFormFriendlyNames,
} from "../../../../../../store/resources/refreshResource";
import { refreshFriendlyNamesGrid } from "../../../../../../store/dataGrid/refreshGrid";
import { savePage } from "../../../../../../store/pages/actions";
import { useProgramFormFriendlyNames } from "../../../../../../store/resources/useResource";
import { openApplicationFormPages } from "components/utils/window";
import { usePropertiesFormRegistration, validatePropertiesForm } from "store/formBuilder/utils";
import { isChildProgram } from "components/views/ProgramView/utils";

const FriendlyNamesSidePanel = memo(
    ({ loading, utilityNumber, programNumber, formNumber, formName, elementType, elementData, onClose, onCancel }) => {
        const dispatch = useDispatch();
        const [isSubmitting, setIsSubmitting] = useState(false);
        const propertiesFormRef = useRef();
        const isLocked = isChildProgram({ programNumber });

        const instanceId = elementData && `friendly-names-${elementData.number}`;
        const formBuilder = useSelector((state) => state.formBuilder[instanceId]);
        const [friendlyNames] = useProgramFormFriendlyNames({ programNumber });

        const selectedElementId = formBuilder?.selectedElementId;

        const validationErrors = formBuilder?.validationErrors ?? {};
        const errorCount = Object.keys(validationErrors).length;
        const otherErrorCount = Object.keys(validationErrors).filter((key) => validationErrors[key].elementId !== selectedElementId).length;
        const hasErrors = errorCount > 0;
        const hasOtherErrors = otherErrorCount > 0;

        const pageNumber = formBuilder && formBuilder.uiSchema && formBuilder.uiSchema["af:pageNumber"];

        // Preserve active properties form to be accessible from store actions
        usePropertiesFormRegistration({
            instanceId,
            selectedElementId,
            propertiesFormRef,
            dispatch,
        });

        const onPageChange = useCallback(
            (props) => {
                const relatedBuilder = getFormBuilder({
                    instanceId: pageNumber,
                    defaultValue: null,
                });
                if (relatedBuilder) {
                    const elementId = props.selectedElementId.replace("root", pageNumber);

                    dispatch(selectElement({ instanceId: pageNumber, elementId }));
                    onPagePropertiesChange({
                        ...props,
                        instanceId: pageNumber,
                        selectedElementId: elementId,
                        programNumber,
                        formNumber,
                    });
                }
            },
            [programNumber, formNumber, pageNumber, dispatch]
        );

        const onSectionChange = useCallback(
            (props) => {
                const relatedBuilder = getFormBuilder({
                    instanceId: pageNumber,
                    defaultValue: null,
                });
                if (relatedBuilder) {
                    const elementId = props.selectedElementId.replace("root", pageNumber);

                    dispatch(selectElement({ instanceId: pageNumber, elementId }));
                    onSectionPropertiesChange({
                        ...props,
                        instanceId: pageNumber,
                        selectedElementId: elementId,
                    });
                }
            },
            [pageNumber, dispatch]
        );

        const onFieldChange = useCallback(
            (props) => {
                const relatedBuilder = getFormBuilder({
                    instanceId: pageNumber,
                    defaultValue: null,
                });
                if (relatedBuilder) {
                    const elementId = props.selectedElementId.replace("root", pageNumber);

                    dispatch(selectElement({ instanceId: pageNumber, elementId }));
                    onFieldPropertiesChange({
                        ...props,
                        instanceId: pageNumber,
                        selectedElementId: elementId,
                    });
                }
            },
            [pageNumber, dispatch]
        );

        const onSave = useCallback(async () => {
            let isValid = true;

            if (propertiesFormRef?.current) {
                isValid = await validatePropertiesForm({
                    instanceId,
                    formRef: propertiesFormRef,
                    elementId: selectedElementId,
                    dispatch,
                });
            }

            if (isValid) {
                const { schema, uiSchema, rules, initialValues } = formBuilder;

                setIsSubmitting(true);

                dispatch(
                    savePage({
                        programNumber: programNumber,
                        formNumber,
                        schema,
                        uiSchema,
                        rules,
                        initialValues,
                        onSuccess: () => {
                            refreshProgramCalculationsForm({ programNumber });
                            refreshFriendlyNamesGrid({ formNumber });
                            refreshProgramFormFriendlyNames({ programNumber });
                            refreshApplicationFormsInOpenTabs();
                            onClose();
                        },
                        onError: (action) => {
                            setIsSubmitting(false);
                            dispatch(
                                setValidationErrors({
                                    instanceId,
                                    errorList: action.data?.errorList ?? [],
                                })
                            );
                        },
                    })
                );
            }
        }, [instanceId, selectedElementId, formBuilder, programNumber, formNumber, onClose, dispatch]);

        const onOpenPage = useCallback(() => {
            // fix selected element id to have correct bulder instance prefix.
            const elementId = selectedElementId.replace("root", pageNumber);

            // Open form page
            openApplicationFormPages({
                utilityNumber,
                programNumber,
                formNumber,
                formName,
                pageNumber,
                selectedElementId: elementId,
            });

            // Copy over error list to builder instance in opened view
            dispatch(
                copyValidationErrors({
                    sourceInstanceId: instanceId,
                    targetInstanceId: pageNumber,
                })
            );
        }, [instanceId, utilityNumber, programNumber, formNumber, formName, pageNumber, selectedElementId, dispatch]);

        const [title, content] = useMemo(() => {
            let content = <InfoMessage spaceAround>Select Elements to change properties</InfoMessage>;
            let title = "Properties";

            if (formBuilder) {
                if (elementType === ElementTypes.PAGE) {
                    content = (
                        <PageProperties
                            instanceId={instanceId}
                            formRef={propertiesFormRef}
                            disabled={isSubmitting}
                            onChange={onPageChange}
                        />
                    );
                    title = "Edit Page Properties";
                }

                if (elementType === ElementTypes.SECTION) {
                    content = (
                        <SectionProperties
                            instanceId={instanceId}
                            formRef={propertiesFormRef}
                            disabled={isSubmitting}
                            onChange={onSectionChange}
                        />
                    );
                    title = "Edit Section Properties";
                }

                if (elementType === ElementTypes.FIELD) {
                    content = (
                        <FieldProperties
                            key={elementData.id}
                            formRef={propertiesFormRef}
                            instanceId={instanceId}
                            disabled={isSubmitting}
                            onChange={onFieldChange}
                        />
                    );
                    title = isLocked ? "View Field Properties" : "Edit Field Properties";
                }
            }

            return [title, content];
        }, [instanceId, formBuilder, elementData?.id, elementType, isSubmitting, onPageChange, onSectionChange, onFieldChange, isLocked]);

        const submitText = isSubmitting ? "Saving..." : "Save";

        return (
            <ApplicationFormPagesContext.Provider
                value={{
                    programNumber,
                    formNumber,
                    friendlyNames,
                }}
            >
                <SideNavContent>
                    <SideNavHeader
                        leadBlockIcon={isLocked ? "eye-visibility-empty" : "edit-empty"}
                        smallHeader
                        title={title}
                        errorBackground={hasOtherErrors}
                        onClose={onCancel}
                    >
                        {hasOtherErrors && (
                            <StatusMsg
                                msgError
                                msgFieldStatus
                                msgText={
                                    <>
                                        Form page has more fields with errors.
                                        <br />
                                        <ActionLabel onClick={onOpenPage}>Open page to fix all errors</ActionLabel>
                                    </>
                                }
                            />
                        )}
                    </SideNavHeader>
                    <SideNavBody className="flex-one-in-column">{loading ? <WaitIcon /> : content}</SideNavBody>
                    <SideNavFooter setPrimaryButton={!isLocked} justifyEnd={isLocked}>
                        {!isLocked && (
                            <Button primary disabled={isSubmitting || hasErrors} onClick={onSave}>
                                {submitText}
                            </Button>
                        )}
                        <Button disabled={isSubmitting} onClick={onCancel}>
                            {isLocked ? "Close" : "Cancel"}
                        </Button>
                    </SideNavFooter>
                </SideNavContent>
            </ApplicationFormPagesContext.Provider>
        );
    }
);

export default FriendlyNamesSidePanel;
