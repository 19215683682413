import React, { memo } from "react";
import { connect, useSelector } from "react-redux";

const TabMain = memo(({ view, window }) => {
    let title = view.title || view.name;
    let hasParent = false;
    let titleParent, titleChild;

    const activeParent = (window && window["home-window"] && (window["home-window"].views || []).filter((i) => i.active)[0]) || {
        title: title,
    };
    const parentSubMenu = useSelector((state) => state.subMenu.activeSubMenu[activeParent.name]);

    if (window && window["home-window"] && window["home-window"].views) {
        const parent = window["home-window"].views.filter((i) => i.active)[0] || { title: title };

        titleParent = parent.title;

        if (parentSubMenu) {
            hasParent = true;
            titleChild = parentSubMenu.text;
        } else {
            hasParent = false;
            title = titleParent;
        }
    }

    return (
        <div className="tab-content">
            {hasParent ? (
                <div className="tab-content-item">
                    <div className="title">{titleParent}</div>
                    <div className="value">{titleChild}</div>
                </div>
            ) : (
                <div className="tab-title">{title}</div>
            )}
        </div>
    );
});

export default connect((state) => ({ window: state.window }))(TabMain);
