import React, { useState, useCallback, memo } from "react";

import Button from "../../../../Button";
import IconWithLabel from "../../../../Icons/IconWithLabel";
import { enabledToolbarButtons } from "./QualityControlAddRequest";
import RawHtml from "../../../../RawHtml";
import HtmlEditorWidget from "../../../../Form/JsonSchema/widgets/HtmlEditorWidget";
import WaitIcon from "../../../../WaitIcon";
import { isNullOrWhitespace, stripHtml } from "components/utils/string";

import "./QualityControlDescription.scss";

const QualityControlDescription = memo(({ description, isLocked, onDescriptionSave }) => {
    const [text, setText] = useState(description);
    const [edited, setEdited] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const toggleOpened = useCallback(
        (btn = "") => {
            setEdited(!edited);
            if (btn === "cancel") {
                setText(description);
            }
        },
        [edited, description]
    );

    const handleSave = useCallback(() => {
        setIsLoading(true);
        toggleOpened();

        onDescriptionSave(text, () => {
            setIsLoading(false);
        });
    }, [text, toggleOpened, onDescriptionSave]);

    if (!edited) {
        return (
            <div className="flex-column fill-width description">
                <div className="title">Description</div>
                {isLoading ? <WaitIcon /> : <RawHtml className="content-block">{description}</RawHtml>}
                <IconWithLabel className="edit-description" icon="edit-empty" disabled={isLocked} onClick={toggleOpened}>
                    Edit description
                </IconWithLabel>
            </div>
        );
    }

    return (
        <div className="flex-column fill-width description">
            <div className="title required">Description</div>
            <IconWithLabel className="edit-description" icon="shevron-circle-down-filled" onClick={toggleOpened}>
                Edit description
            </IconWithLabel>
            <div className="content-block">
                <HtmlEditorWidget
                    value={text}
                    onChange={setText}
                    placeholder="Type your description here..."
                    enabledToolbarButtons={enabledToolbarButtons}
                />
                <div className="flex-row actions">
                    <Button disabled={!text || isNullOrWhitespace(stripHtml(text))} type="submit" primary onClick={handleSave}>
                        Save
                    </Button>
                    <Button type="submit" onClick={() => toggleOpened("cancel")}>
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
});

export default QualityControlDescription;
