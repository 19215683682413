import React from "react";
import { get } from "lodash";
import { store } from "../../../store/configureStore";
import { modalClose, modalOpen } from "../../../store/modal/actions";
import { updateResource, getResource } from "../../../store/resources/actions";
import AssignmentSelector from ".";

export const openAssignmentModal = ({
    resourceGetParams,
    resourceDataPath,
    title,
    submitButtonText,
    addAllText,
    addAllDisabled,
    removeAllText,
    removeAllDisabled,
    modalIcon,
    availableListLabelText,
    selectedListLabelText,
    idKey,
    nameKey,
    selectedItems,
    components = {},
    onSelect,
    onRefresh,
    addAllHandler,
}) => {
    const onClose = () => {
        store.dispatch(modalClose());
    };

    const onSelectItems = (selectedItems, removedItems) => {
        if (onSelect) {
            onSelect(selectedItems, removedItems);
            onClose();
            onRefresh && onRefresh();
        } else {
            store.dispatch(
                updateResource({
                    ...resourceGetParams,
                    body: selectedItems.map((i) => i[idKey]),
                    onSuccess: () => {
                        onClose();
                        onRefresh && onRefresh();
                    },
                })
            );
        }
    };

    store.dispatch(
        modalOpen({
            modalType: "WAITING_MODAL",
            modalProps: {
                title,
                modalIcon,
                className: "assignment-selector-modal-loading",
            },
        })
    );

    store.dispatch(
        getResource({
            ...resourceGetParams,
            onSuccess: (action) => {
                let rows = get(action, resourceDataPath, []);

                // Override selected items with provided list.
                // In case if we preassigned items but not saved jet
                if (Array.isArray(selectedItems)) {
                    rows.forEach((row) => {
                        row.assigned = selectedItems.some((i) => i[idKey] === row[idKey]);
                    });
                }

                store.dispatch(
                    modalOpen({
                        modalType: "MODAL",
                        modalProps: {
                            title,
                            modalIcon,
                            overlayClassName: "modal-styled",
                            className: "assignment-selector-modal modal-md",
                            children: (
                                <AssignmentSelector
                                    items={rows}
                                    idKey={idKey}
                                    nameKey={nameKey}
                                    submitButtonText={submitButtonText}
                                    addAllText={addAllText}
                                    addAllDisabled={addAllDisabled}
                                    availableListLabelText={availableListLabelText}
                                    selectedListLabelText={selectedListLabelText}
                                    removeAllText={removeAllText}
                                    removeAllDisabled={removeAllDisabled}
                                    AvailableListItemComponent={components.AvailableListItem}
                                    HeaderComponent={components.Header}
                                    onClose={onClose}
                                    onSelect={onSelectItems}
                                    addAllHandler={addAllHandler}
                                />
                            ),
                            noFooter: true,
                            withScroll: true,
                        },
                    })
                );
            },
            onError: () => store.dispatch(modalClose()),
        })
    );
};
