import React, { useId } from "react";
import cn from "classnames";

import TabListItem from "./TabListItem";
import WaitIcon from "../../WaitIcon";
import ScrollControls from "../../ScrollControls";

import "./TabList.scss";

const TabList = ({
    items = [],
    activeItem,
    tabListGhost,
    tabListWrapped,
    loading,
    onClick,
    equalItemsWidth,
    disabledItemTooltip,
    className,
}: TabListProps) => {
    const targetId = useId();

    return (
        <div
            className={cn("tab-list", className, {
                "tab-list-ghost": tabListGhost,
                "tab-list-wrapped": tabListWrapped,
            })}
        >
            <div id={targetId} className="tabs flex">
                {items.map((item) => (
                    <TabListItem
                        key={item.id}
                        item={item}
                        hasData={item.hasData}
                        active={item.id === activeItem?.id}
                        disabled={item.disabled}
                        onClick={onClick}
                        equalItemsWidth={equalItemsWidth}
                        disabledItemTooltip={disabledItemTooltip}
                    />
                ))}
                {loading && <WaitIcon />}
            </div>
            <ScrollControls targetId={targetId} />
        </div>
    );
};

interface TabListProps {
    items: Tab[];
    activeItem?: Tab;
    tabListGhost?: boolean;
    tabListWrapped?: boolean;
    loading?: boolean;
    onClick?: (tab: Tab) => void;
    equalItemsWidth?: boolean;
    disabledItemTooltip?: string;
    className?: string;
}

export interface Tab {
    id: string;
    title: string;
    hasData?: boolean;
    disabled?: boolean;
}

export default TabList;
