import React from "react";

class RawHtml extends React.Component {
    componentDidMount() {
        const { onLoad } = this.props;

        if (onLoad) {
            onLoad();
        }
    }
    render() {
        const { className, children } = this.props;
        return <div className={"raw-html " + (className ? className : "")} dangerouslySetInnerHTML={{ __html: children }} />;
    }
}

export default RawHtml;
