import React, { useRef } from "react";
import { isEmpty } from "lodash";
import cn from "classnames";
import { MarginPropertyContext } from "components/ui/PortalBuilder/contexts";
import { PropertyList } from "../../PropertyList";
import { PropertyListItemTitle } from "../../PropertyList/PropertyListItemTitle";
import {
    MarginBoxSize,
    PortalBuilderPropertyProps,
    PropertyChangeFunc,
    PropertyName,
    PropertyType,
} from "components/ui/PortalBuilder/types";
import { AllSidesProperty } from "./AllSidesProperty";
import { SideBySideProperty } from "./SideBySideProperty";

import { MarginCustomPropertyName, MarginSideName, MarginSides } from "./types";
import { PropertyListItem } from "../PropertyListItem";

import "./MarginProperty.scss";

export const DefaultMargin = MarginBoxSize.MD;

export const MarginProperty = (props: PortalBuilderPropertyProps) => {
    const { className, title, value = {}, nestingLevel = 0, emptyValue, onChange, onlyTopAndBottom, borderTop, borderBottom } = props;
    const filteredValues = Object.fromEntries(Object.entries(value).filter(([key]) => !key.includes("_")));
    const valueRef = useRef(value);
    const isExpanded = Object.values(filteredValues).some((v) => !isEmpty(v) && v !== emptyValue);
    const marginTypeObj = customProperties[0];

    const customPropertiesValue = onlyTopAndBottom
        ? {
              [MarginCustomPropertyName.MarginType]: MarginSides.SideBySide,
              [MarginCustomPropertyName.MarginSideBySide]: {
                  [MarginSideName.Top]: value[PropertyName.MarginTop],
                  [MarginSideName.Right]: 0,
                  [MarginSideName.Bottom]: value[PropertyName.MarginBottom],
                  [MarginSideName.Left]: 0,
              },
          }
        : {
              [MarginCustomPropertyName.MarginType]: isEmpty(value[PropertyName.Margin]) ? MarginSides.SideBySide : MarginSides.AllSides,
              [MarginCustomPropertyName.MarginAllSides]: value[PropertyName.Margin],
              [MarginCustomPropertyName.MarginSideBySide]: {
                  [MarginSideName.Top]: value[PropertyName.MarginTop],
                  [MarginSideName.Right]: value[PropertyName.MarginRight],
                  [MarginSideName.Bottom]: value[PropertyName.MarginBottom],
                  [MarginSideName.Left]: value[PropertyName.MarginLeft],
              },
          };

    const onPropertyChange = (id: string, propertyValue: string) => {
        switch (id) {
            case MarginCustomPropertyName.MarginType:
                onMarginTypeChange(propertyValue, value, onChange);
                break;
            default:
                // Reset side properties if this is all sides mode
                if (id === PropertyName.Margin) {
                    onChange(id, propertyValue, emptySideBySideProperties);
                    // Reset all sides property if this is side by side mode
                } else {
                    onChange(id, propertyValue, [
                        {
                            id: PropertyName.Margin,
                            value: undefined,
                        },
                    ]);
                }
                break;
        }
    };

    const onToggle = () => {
        if (isExpanded) {
            // Store last value in ref before collapsing the properties.
            valueRef.current = value;
            onChange(PropertyName.Margin, emptyValue, emptySideBySideProperties);
        } else {
            const allSidesValue = onlyTopAndBottom
                ? undefined
                : Object.values(valueRef.current).every((v) => isEmpty(v) || v === emptyValue)
                ? DefaultMargin
                : valueRef.current[PropertyName.Margin];

            const isAllSidesMode = !isEmpty(allSidesValue);

            if (isAllSidesMode) {
                onChange(PropertyName.Margin, allSidesValue, emptySideBySideProperties);
            } else {
                const extraProperties = [
                    {
                        id: PropertyName.MarginTop,
                        value: valueRef.current[PropertyName.MarginTop] ?? DefaultMargin,
                    },
                    {
                        id: PropertyName.MarginBottom,
                        value: valueRef.current[PropertyName.MarginBottom] ?? DefaultMargin,
                    },
                    {
                        id: PropertyName.MarginLeft,
                        value: onlyTopAndBottom ? undefined : valueRef.current[PropertyName.MarginLeft] ?? DefaultMargin,
                    },
                    {
                        id: PropertyName.MarginRight,
                        value: onlyTopAndBottom ? undefined : valueRef.current[PropertyName.MarginRight] ?? DefaultMargin,
                    },
                ];

                onChange(PropertyName.Margin, undefined, extraProperties);
            }
        }
    };

    if (onlyTopAndBottom) {
        marginTypeObj.hidden = true;
    } else {
        marginTypeObj.hidden = false;
    }

    if (props.hidden) {
        return null;
    }

    return (
        <MarginPropertyContext.Provider
            value={{
                customPropertiesValue,
                onlyTopAndBottom,
            }}
        >
            <PropertyListItem
                className={cn("property-list-item--prop-group property-list-item--margin", className)}
                borderTop={borderTop}
                borderBottom={borderBottom}
            >
                {/* @ts-ignore */}
                <PropertyListItemTitle
                    title={title}
                    toggleTooltip={isExpanded ? "Switch OFF" : "Switch ON"}
                    onToggle={onToggle}
                    toggleValue={!isExpanded}
                />
                <PropertyList
                    items={customProperties}
                    nestingLevel={nestingLevel + 1}
                    config={customPropertiesValue}
                    onChange={onPropertyChange}
                    isExpanded={isExpanded}
                />
            </PropertyListItem>
        </MarginPropertyContext.Provider>
    );
};

const onMarginTypeChange = (propertyValue: string, propertyGroupValue: any, onChange: PropertyChangeFunc) => {
    if (propertyValue === MarginSides.SideBySide) {
        const extraProperties = [
            {
                id: PropertyName.MarginTop,
                value: propertyGroupValue[PropertyName.Margin],
            },
            {
                id: PropertyName.MarginRight,
                value: propertyGroupValue[PropertyName.Margin],
            },
            {
                id: PropertyName.MarginBottom,
                value: propertyGroupValue[PropertyName.Margin],
            },
            {
                id: PropertyName.MarginLeft,
                value: propertyGroupValue[PropertyName.Margin],
            },
        ];
        onChange(PropertyName.Margin, undefined, extraProperties);
    } else {
        onChange(PropertyName.Margin, propertyGroupValue[PropertyName.MarginTop] ?? DefaultMargin, emptySideBySideProperties);
    }
};

export const emptySideBySideProperties = [
    {
        id: PropertyName.MarginTop,
        value: undefined,
    },
    {
        id: PropertyName.MarginRight,
        value: undefined,
    },
    {
        id: PropertyName.MarginBottom,
        value: undefined,
    },
    {
        id: PropertyName.MarginLeft,
        value: undefined,
    },
];

export const MARGIN_PROPERTY = {
    id: "margin",
    title: "Margin",
    type: PropertyType.Margin,
    propertiesGroup: [
        {
            id: PropertyName.Margin,
        },
        {
            id: PropertyName.MarginTop,
        },
        {
            id: PropertyName.MarginRight,
        },
        {
            id: PropertyName.MarginBottom,
        },
        {
            id: PropertyName.MarginLeft,
        },
    ],
};

const customProperties = [
    {
        id: MarginCustomPropertyName.MarginType,
        title: (
            <>
                Margin <br /> size
            </>
        ),
        type: PropertyType.SelectBox,
        borderTop: false,
        borderBottom: false,
        items: [
            {
                text: "Side By Side",
                value: MarginSides.SideBySide,
            },
            {
                text: "All Sides",
                value: MarginSides.AllSides,
            },
        ],
        hidden: false,
    },
    {
        id: MarginCustomPropertyName.MarginAllSides,
        type: PropertyType.CustomComponent,
        borderTop: true,
        borderBottom: false,
        component: AllSidesProperty,
    },
    {
        id: MarginCustomPropertyName.MarginSideBySide,
        type: PropertyType.CustomComponent,
        borderBottom: false,
        component: SideBySideProperty,
    },
];
