import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { contractorParticipationGridColumnKeys } from "../../../../../views/configureGrids";
import { openProgramTab } from "store/window/openTabActions";
import { exportDatagridToCSV } from "../../../../../utils/CSV";

import IconWithLabel from "../../../../Icons/IconWithLabel";
import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid";
import ActionLabel from "../../../../Label/ActionLabel";
import NothingFoundBlock from "../../../../NothingFoundBlock";
import { systemUserRights } from "components/utils/user";
import { useProgramRights } from "store/resources/useResource";

import "./ActivityPanel.scss";

const Participation = memo(({ contactNumber, programNumber }) => {
    const gridId = `${contactNumber}-contractor-participation-grid`;
    const filter = `${contractorParticipationGridColumnKeys.contactnumber}=${contactNumber}`;

    return (
        <div className="fill-width">
            <h3>Program Participation for this Trade Ally</h3>
            {contactNumber ? (
                <Grid contactNumber={contactNumber} gridId={gridId} filter={filter} programNumber={programNumber} />
            ) : (
                <NothingFoundBlock nothingFoundBlockSmall nothingFoundBlockMargin icon="info-empty" title="No Data Available" />
            )}
        </div>
    );
});

const showedColumnsKeys = [
    contractorParticipationGridColumnKeys.program,
    contractorParticipationGridColumnKeys["total rebate"],
    contractorParticipationGridColumnKeys["total apps"],
];

const Grid = withDataGrid(
    "contractorParticipation",
    memo(({ contactNumber, gridId, dataGridConfig, programNumber }) => {
        const dispatch = useDispatch();
        const isMobile = useSelector((state) => state.window?.isMobile);
        const [programRights = []] = useProgramRights({ programNumber });
        const canViewProgram = programRights.includes(systemUserRights.VISIONDSM_VIEW_PROGRAM);

        const customRowActions = [
            {
                name: "edit",
                icon: "open-new-window",
                title: "Manage Program",
                disabled: !canViewProgram,
            },
        ];

        const onRowAction = (action) => {
            const { name, dataItem } = action;

            switch (name) {
                case "edit":
                    onProgramOpen(dataItem[contractorParticipationGridColumnKeys.programnumber]);
                    break;

                default:
                    break;
            }
        };

        const onProgramOpen = (programNumber) => {
            dispatch(openProgramTab({ programNumber }));
        };

        return (
            <>
                <div className="data-grid-controls flex-row justify-end">
                    <IconWithLabel
                        withHandMadeIcon
                        onClick={() =>
                            exportDatagridToCSV({
                                dataGridId: gridId,
                                fileName: "contractor_participation",
                                fileNamePostfix: contactNumber,
                            })
                        }
                    >
                        Export CSV
                    </IconWithLabel>
                </div>
                <DataGrid
                    key={isMobile}
                    name={gridId}
                    config={dataGridConfig}
                    showedColumnsKeys={showedColumnsKeys}
                    onRowAction={onRowAction}
                    customRowActions={customRowActions}
                    columnCellContent={{
                        [contractorParticipationGridColumnKeys.program]: (column, row) =>
                            canViewProgram ? (
                                <ActionLabel onClick={() => onProgramOpen(row[column.key])}>{row[column.key]}</ActionLabel>
                            ) : (
                                row[column.key]
                            ),
                        [contractorParticipationGridColumnKeys.program]: (column, row) => {
                            if (row[contractorParticipationGridColumnKeys.program]) {
                                return canViewProgram ? (
                                    <ActionLabel onClick={() => onProgramOpen(row[contractorParticipationGridColumnKeys.programnumber])}>
                                        {row[column.key]}
                                    </ActionLabel>
                                ) : (
                                    row[column.key]
                                );
                            }

                            return row[column.key];
                        },
                    }}
                />
            </>
        );
    })
);

export default Participation;
