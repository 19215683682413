import { isChildProgram } from "components/views/ProgramView/utils";
import React from "react";

import IconWithLabel from "../../../../Icons/IconWithLabel";

const Controls = ({ onAdd, programNumber }) => (
    <div className="grid-controls flex-row align-center">
        <span className="flex-one" />
        <IconWithLabel disabled={isChildProgram({ programNumber })} icon="plus" onClick={onAdd}>
            Add Lookup Table
        </IconWithLabel>
    </div>
);

export default Controls;
