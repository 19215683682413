import React, { useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { isNil } from "lodash";

import { useUtilities, usePrograms, useCisUtilities } from "../../../../store/resources/useResource";
import DropDownInput from "../../Input/DropDownInput";
import { setUtility, setProgram } from "../../../../store/globalSearch/actions";
import Checkbox from "../../Input/Checkbox";
import SelectBox from "../../SelectBox";

import "./CriteriaButton.scss";
import Radio from "components/ui/Input/Radio";

export const CriteriaButton = ({ instanceId, criteria, active, onClick, mainCriteria, disabled, tooltip }) => {
    if (mainCriteria && criteria.title.toLowerCase() === "utility") {
        return <UtilitiesDropdown instanceId={instanceId} criteriaKey={criteria.key} />;
    }

    if (mainCriteria && criteria.title.toLowerCase() === "program") {
        return <ProgramsDropdown instanceId={instanceId} criteriaKey={criteria.key} />;
    }

    if (mainCriteria) {
        return <SelectBox selectBoxText={criteria.title} selectBoxSelected={active} onClick={disabled ? undefined : onClick}></SelectBox>;
    }

    const SelectionComponent = criteria.isGroup ? Radio : Checkbox;

    return (
        <SelectBox selectBoxSelected={active} onClick={disabled ? undefined : onClick}>
            <SelectionComponent
                label={criteria.title}
                title={tooltip}
                checked={active}
                disabled={disabled}
                onChange={disabled ? undefined : onClick}
            />
        </SelectBox>
    );
};

export const UtilitiesDropdown = ({ instanceId, onClick, isCisSearch, criteriaKey }) => {
    const useUtilitiesMethod = isCisSearch ? useCisUtilities : useUtilities;

    const dispatch = useDispatch();
    const { utilityName, utilityNumber } = useSelector((state) => state.globalSearch.selectedUtility[instanceId]) ?? {};
    const [utilities = [], isLoading] = useUtilitiesMethod({ forced: false });

    const placeholder = "Utility";
    const placeholderText = isLoading ? "Loading..." : utilityName ? `${placeholder}: ${utilityName}` : placeholder;
    const isDisabled = isLoading || utilities?.length === 0;
    const title = utilities?.length === 0 ? "No utilities available" : undefined;

    const data = useMemo(() => {
        return (utilities || []).map((i) => ({
            label: i.utility,
            value: i.utilityNumber,
        }));
    }, [utilities]);

    const onChange = useCallback(
        (event) => {
            onClick && onClick();
            dispatch(
                setUtility({
                    instanceId,
                    utilityNumber: event.target.value,
                    utilityName: data.filter((i) => i.value === event.target.value).map((i) => i.label)[0],
                    utilityKey: criteriaKey,
                })
            );
        },
        [instanceId, data, onClick, criteriaKey, dispatch]
    );

    return (
        <DropDownInput
            inline
            className={cn("global-search__criteria-dropdown", {
                "global-search__criteria-dropdown--active": !isNil(utilityNumber),
            })}
            mobileHeader="Select Utility"
            placeholder={placeholderText}
            title={title}
            disabled={isDisabled}
            data={data}
            emptyItem={!isCisSearch}
            emptyItemLabel="Not Selected"
            onChange={onChange}
        />
    );
};

const ProgramsDropdown = ({ instanceId, onClick, criteriaKey }) => {
    const dispatch = useDispatch();
    const { utilityNumber } = useSelector((state) => state.globalSearch.selectedUtility[instanceId]) ?? {};
    const { programName, programNumber } = useSelector((state) => state.globalSearch.selectedProgram[instanceId]) ?? {};
    const [programs = [], isLoading] = usePrograms({ utilityNumber });

    const placeholder = "Program";
    const placeholderText = isLoading ? "Loading..." : programName ? `${placeholder}: ${programName}` : placeholder;
    const isDisabled = isLoading || isNil(utilityNumber) || programs?.length === 0;
    const title = isNil(utilityNumber) ? "First select utility and then program" : undefined;

    const data = useMemo(() => {
        return (programs || [])
            .filter((i) => i.isActive)
            .map((i) => ({
                label: i.program,
                value: i.programNumber,
            }));
    }, [programs]);

    const onChange = useCallback(
        (event) => {
            onClick && onClick();
            dispatch(
                setProgram({
                    instanceId,
                    programNumber: event.target.value,
                    programName: data.filter((i) => i.value === event.target.value).map((i) => i.label)[0],
                    programKey: criteriaKey,
                })
            );
        },
        [instanceId, data, onClick, criteriaKey, dispatch]
    );

    return (
        <DropDownInput
            inline
            className={cn("global-search__criteria-dropdown", {
                "global-search__criteria-dropdown--active": !isNil(programNumber),
            })}
            mobileHeader="Select Program"
            placeholder={placeholderText}
            title={title}
            disabled={isDisabled}
            data={data}
            emptyItem
            emptyItemLabel="Not Selected"
            onChange={onChange}
        />
    );
};
