import cn from "classnames";
import Checkbox from "components/ui/Input/Checkbox";
import { datePartFromJsonDate, dateToJson, formatJsonDate } from "components/utils/date";
import { isEmpty } from "lodash";
import TextInput from "components/ui/Input/TextInput";
import StatusMsg from "components/ui/StatusMsg";
import FieldGroup from "components/ui/FieldGroup";
import { escapePipeSign, unescapePipeSign } from "components/utils/string";

import "./style.scss";

const SignatureBlockWidget: React.FC<{
    id: string;
    value: string;
    onChange: (value: string) => void;
    disabled: boolean;
    readonly: boolean;
    required: boolean;
    options: {
        title?: string;
        confirmCheckboxTitle?: string;
    };
    rawErrors: any;
    schema: {
        minLength?: number;
        maxLength?: number;
    };
}> = (props) => {
    const { id, value, onChange, disabled, options, readonly, required, rawErrors, schema } = props;

    const currentDateTime = datePartFromJsonDate(dateToJson(new Date()));

    const [signature, checked, , signatureDate] = (value || "").split("|");
    const isChecked = checked === "true";
    const displayDate = signatureDate ? formatJsonDate(signatureDate) : formatJsonDate(currentDateTime);

    const errors = parseErrors(rawErrors);
    const signatureError = errors.signature;
    const confirmationError = errors.confirmation;

    const onSignatureChange = (value: string | undefined) => {
        onChange(`${escapePipeSign(value ?? "")}|${isChecked}|${escapePipeSign(options.confirmCheckboxTitle)}|${currentDateTime}`);
    };

    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(
            `${escapePipeSign(signature ?? "")}|${event.target.checked}|${escapePipeSign(options.confirmCheckboxTitle)}|${currentDateTime}`
        );
    };

    return (
        <div className="signature-block-widget flex-column">
            <TextInput
                className={cn({
                    "field-error": !isEmpty(signatureError),
                })}
                value={unescapePipeSign(signature)}
                onChange={(e: ChangeEvent) => onSignatureChange(e.target.value)}
                required={required}
                readOnly={readonly}
                disabled={disabled}
                error={!isEmpty(signatureError)}
                msgText={signatureError}
                minLength={schema.minLength}
                maxLength={schema.maxLength}
            />
            {signature && (
                <div className="flex-column fill-width">
                    <span className="signature-preview">{unescapePipeSign(signature)}</span>
                    <span className="signature-date">Date: {displayDate}</span>
                </div>
            )}
            <FieldGroup
                className={cn({
                    "field-error": !isEmpty(confirmationError),
                })}
            >
                <Checkbox
                    id={`${id}-confirm`}
                    label={
                        <>
                            {options.confirmCheckboxTitle}
                            {required && <span className="required" />}
                        </>
                    }
                    checked={isChecked}
                    onChange={onCheckboxChange}
                    disabled={disabled || readonly}
                />
                {confirmationError && <StatusMsg msgFieldStatus msgVisible msgError msgText={confirmationError} />}
            </FieldGroup>
        </div>
    );
};

const parseErrors = (rawErrors: string[] | undefined) => {
    if (!rawErrors) {
        return {};
    }

    let errors: { [key: string]: string } = {};

    try {
        errors = JSON.parse(rawErrors[0] ?? "{}");
    } catch (e) {
        errors = {};
    }

    return errors;
};

type ChangeEvent = { target: { value: string } };

export default SignatureBlockWidget;
