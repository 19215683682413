import React, { memo } from "react";

import { PinnedInvoiceDocumentsGrid } from "../../ui/Dashboard/Panel/Widgets/PinnedInvoiceDocumentsPanel";

import "./style.scss";

const UtilitiesAndProgramManagementBookmarkedInvoices = memo(() => {
    return (
        <div className="bookmarked-invoices panel sticky-grid-list-panel">
            <div className="main-grid-wrap responsive flex-column fill-height">
                <PinnedInvoiceDocumentsGrid />
            </div>
        </div>
    );
});

export default UtilitiesAndProgramManagementBookmarkedInvoices;
