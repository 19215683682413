import React, { memo } from "react";

import { exportDatagridToCSV } from "../../utils/CSV";

import IconWithLabel from "../../ui/Icons/IconWithLabel";
import DataGrid from "../../ui/DataGrid";
import withDataGrid from "../../ui/DataGrid/withDataGrid";

const DATA_GRID_ID = "logs-security-customers";

const LogsGrid = withDataGrid("logsSecurityCustomersLoggedIn", ({ gridId, dataGridConfig }) => {
    const ROW_ACTIONS = [];

    return <DataGrid name={gridId} config={dataGridConfig} customRowActions={ROW_ACTIONS} />;
});

const LogsSecurityCustomers = memo(() => {
    return (
        <div className="tab-list-tab-content flex-one-in-column flex-column no-scroll">
            <div className="data-grid-controls">
                <span className="flex-one"></span>
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: DATA_GRID_ID,
                            fileName: "log_security_customers",
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <LogsGrid gridId={DATA_GRID_ID} />
        </div>
    );
});

export default LogsSecurityCustomers;
