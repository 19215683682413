import React from "react";

import Input from "../Input";
import IconWrap from "../Icons";

import "./SearchInput.scss";

const SearchInput = ({ value, onChange, onSubmit }) => {
    const onIconClick = () => {
        if (value) {
            onSubmit(value);
        }
    };

    const onInputKeyPress = (event) => {
        if (event.key === "Enter") {
            onSubmit(event.target.value);
        }
    };

    return (
        <div className="search-input">
            <Input type="text" placeholder="search" iconRight withIcon value={value} onChange={onChange} onKeyPress={onInputKeyPress} />
            <IconWrap iconWrap="search" onClick={onIconClick} title="search"></IconWrap>
        </div>
    );
};

export default SearchInput;
