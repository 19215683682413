import {
    USER_PROFILE_READ_REQUEST,
    USER_PROFILE_READ_SUCCESS,
    USER_PROFILE_READ_ERROR,
    USER_PROFILE_UPDATE_REQUEST,
    USER_PROFILE_UPDATE_SUCCESS,
    USER_PROFILE_UPDATE_ERROR,
    USER_EMAIL_UPDATE_REQUEST,
    USER_EMAIL_UPDATE_SUCCESS,
    USER_EMAIL_UPDATE_ERROR,
    USER_SSO_REQUEST,
    USER_SSO_SUCCESS,
    USER_SSO_ERROR,
} from "../actionTypes";

const initalState = {
    isLoading: false,
    isError: false,
    isUpdating: false,
    message: null,
    ssrsMessage: null,
    errorMessage: null,
};

export const reducer = (state = initalState, action) => {
    switch (action.type) {
        case USER_PROFILE_READ_REQUEST:
            state = {
                ...state,
                isLoading: true,
                isError: false,
                isUpdating: false,
                message: null,
            };
            break;
        case USER_PROFILE_READ_SUCCESS:
            state = {
                ...state,
                data: action.data,
                isLoading: false,
                isError: false,
                message: null,
            };
            break;
        case USER_PROFILE_READ_ERROR:
            state = {
                ...state,
                isLoading: false,
                isError: true,
                message: action.message,
            };
            break;
        case USER_PROFILE_UPDATE_REQUEST:
            state = {
                ...state,
                isError: false,
                isUpdating: true,
                message: null,
            };
            break;

        case USER_PROFILE_UPDATE_SUCCESS:
            state = {
                ...state,
                data: action.passThroughData.data,
                isUpdating: false,
                message: action.data.responseMessage,
            };
            break;

        case USER_PROFILE_UPDATE_ERROR:
            state = {
                ...state,
                isUpdating: false,
                isError: true,
                message: action.message,
            };
            break;
        case USER_EMAIL_UPDATE_REQUEST:
            state = {
                ...state,
                isError: false,
                isUpdating: false,
                message: null,
                errorMessage: null,
            };
            break;

        case USER_EMAIL_UPDATE_SUCCESS:
            state = {
                ...state,
                isUpdating: false,
                message: action.data.responseMessage,
                errorMessage: null,
            };
            break;

        case USER_EMAIL_UPDATE_ERROR:
            state = {
                ...state,
                isUpdating: false,
                isError: true,
                message: action.message,
                errorMessage: action.message,
            };
            break;
        case USER_SSO_REQUEST:
            state = {
                ...state,
                isUpdating: true,
                isError: false,
                message: null,
            };
            break;
        case USER_SSO_SUCCESS:
            state = {
                ...state,
                isUpdating: false,
                isError: false,
                message: action.data.responseMessage,
                ssrsMessage: action.data.responseMessage,
            };
            break;
        case USER_SSO_ERROR:
            state = {
                ...state,
                isUpdating: false,
                isError: true,
                message: action.message,
                ssrsMessage: action.data.responseMessage,
            };
            break;
        default:
            break;
    }

    return state;
};
