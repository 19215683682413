import React from "react";
import { formatMoney } from "components/utils/money";

import "./BulkApproveRebateTotal.scss";

/**
 * Renders the bulk approve rebate total component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.totals - The totals object.
 * @param {number} props.totals.rebateTotal - The total rebate amount.
 * @param {number} props.totals.kwImpact - The kilowatt impact.
 * @param {number} props.totals.kwhImpact - The kilowatt-hour impact.
 * @param {number} props.totals.thermImpact - The therm impact.
 * @param {number} props.totals.peakThermImpact - The peak therm impact.
 * @returns {JSX.Element|null} The JSX element representing the bulk approve rebate total component.
 */
export const BulkApproveRebateTotal = ({ totals }) => {
    if (totals.rebateTotal === 0) {
        return null;
    }

    return (
        <div className="bulk-approve-rebate-total">
            <div className="bulk-approve-total-container flex-row align-end">
                <div className="flex-one">
                    <span className="bulk-approve-rebate-total__value">Totals</span>
                </div>
                <div className="bulk-approve-rebate-total__measures flex-row no-shrink align-end">
                    <div className="flex-column flex-one-in-row">
                        <span className="bulk-approve-rebate-total__measures-label">KW Impact</span>
                        <span className="bulk-approve-rebate-total__measures-value">{totals.kwImpact.toLocaleString()}</span>
                    </div>
                    <div className="flex-column flex-one-in-row">
                        <span className="bulk-approve-rebate-total__measures-label">KWH Impact</span>
                        <span className="bulk-approve-rebate-total__measures-value">{totals.kwhImpact.toLocaleString()}</span>
                    </div>
                    <div className="flex-column flex-one-in-row">
                        <span className="bulk-approve-rebate-total__measures-label">Therm Impact</span>
                        <span className="bulk-approve-rebate-total__measures-value">{totals.thermImpact.toLocaleString()}</span>
                    </div>
                    <div className="flex-column flex-one-in-row">
                        <span className="bulk-approve-rebate-total__measures-label">Peak Therm Impact</span>
                        <span className="bulk-approve-rebate-total__measures-value">{totals.peakThermImpact.toLocaleString()}</span>
                    </div>
                </div>
                <div className="bulk-approve-rebate-total__money flex-row align-center justify-end">
                    <span className="bulk-approve-rebate-total__label">Total:</span>
                    <span className="bulk-approve-rebate-total__value">{formatMoney(totals.rebateTotal)}</span>
                </div>
            </div>
        </div>
    );
};
