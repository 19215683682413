import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { get } from "lodash";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import { getBudgetLineAmountsGridId } from "../utils";
import { getData } from "store/dataGrid/actions";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../../ui/SideNav/SideNavRoot";

import Controls from "./Controls";
import Grid from "./Grid";
import Form from "./Form";

import "../style.scss";

const AmountsPanel = ({ panel }) => {
    const dispatch = useDispatch();

    const utilityNumber = get(panel, "data.utilityNumber");
    const contractNumber = get(panel, "data.contractNumber");
    const budgetLineNumber = get(panel, "data.budgetLineNumber");
    const dataGridId = getBudgetLineAmountsGridId({ budgetLineNumber });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "budget-amounts-sidenav-panel",
    });

    const onRefresh = useCallback(() => {
        dispatch(getData({ dataGridId }));
    }, [dataGridId, dispatch]);

    const handleAddAmount = useCallback(() => {
        handleOpenSidePanel(
            <Form
                utilityNumber={utilityNumber}
                contractNumber={contractNumber}
                budgetLineNumber={budgetLineNumber}
                gridRefresh={onRefresh}
                onClose={handleCloseSidePanel}
            />
        );
    }, [utilityNumber, contractNumber, budgetLineNumber, onRefresh, handleOpenSidePanel, handleCloseSidePanel]);

    return (
        <div className="panel budget-amounts flex-column fill-height">
            <PanelHeaderLarge title={panel.name} />
            <Controls utilityNumber={utilityNumber} budgetLineNumber={budgetLineNumber} dataGridId={dataGridId} onAdd={handleAddAmount} />
            <Grid
                dataGridId={dataGridId}
                utilityNumber={utilityNumber}
                contractNumber={contractNumber}
                budgetLineNumber={budgetLineNumber}
                onRefresh={onRefresh}
            />
        </div>
    );
};

export default AmountsPanel;
