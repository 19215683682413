import * as actionTypes from "../actionTypes";

const initialState = {
    isUploading: false,
    isDownloading: false,
    isDeletting: false,
    fileId: null,
    fileName: null,
    isError: false,
    message: null,
    status: null,
    fileReqs: null,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FILES_UPLOAD_REQUEST:
            state = {
                ...state,
                fileId: null,
                isUploading: true,
                isError: false,
                message: null,
            };
            break;
        case actionTypes.FILES_UPLOAD_SUCCESS:
            state = {
                ...state,
                isUploading: false,
                fileId: action.data.fileId,
                message: action.data.responseMessage,
                status: action.data.responseStatus,
            };
            break;
        case actionTypes.FILES_UPLOAD_ERROR:
            state = {
                ...initialState,
                isUploading: false,
                isError: true,
                message: action.message,
            };
            break;
        case actionTypes.FILES_DOWNLOAD_REQUEST:
        case actionTypes.FILES_PREVIEW_REQUEST:
            state = {
                ...state,
                isDownloading: true,
                isError: false,
                message: null,
            };
            break;
        case actionTypes.FILES_DOWNLOAD_SUCCESS:
        case actionTypes.FILES_PREVIEW_SUCCESS:
            state = {
                ...state,
                isDownloading: false,
                fileName: action.data.fileName,
            };
            break;
        case actionTypes.FILES_DOWNLOAD_ERROR:
        case actionTypes.FILES_PREVIEW_ERROR:
            state = {
                ...state,
                isDownloading: false,
                fileName: null,
                blob: null,
                isError: true,
                message: action.message,
            };
            break;
        case actionTypes.FILES_DELETE_REQUEST:
            state = {
                ...state,
                isDeletting: true,
                isError: false,
                message: null,
            };
            break;
        case actionTypes.FILES_DELETE_SUCCESS:
            state = {
                ...state,
                isDeletting: false,
                message: action.data.responseMessage,
            };
            break;
        case actionTypes.FILES_DELETE_ERROR:
            state = {
                ...initialState,
                isDeletting: false,
                isError: true,
                message: action.message,
            };
            break;
        case actionTypes.FILES_REQS_REQUEST:
            state = {
                ...state,
                fileReqs: action.data,
            };
            break;
        case actionTypes.FILES_REQS_SUCCESS:
            state = {
                ...state,
                fileReqs: action.data,
            };
            break;
        case actionTypes.FILES_REQS_ERROR:
            state = {
                ...state,
                fileReqs: action.data,
            };
            break;
        default:
            break;
    }

    return state;
};
