import React, { memo, useRef, useCallback, useState, useContext, useEffect, useMemo } from "react";
import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { toast } from "react-toastify";

import Button from "../Button";
import JsonSchemaFormWithConditionals from "../Form/JsonSchema/JsonSchemaFormWithConditionals";
import PanelWithSidePanel from "../PanelWithSidePanel";
import StickySidePanel from "../StickySidePanel";
import ViewPlaceholder from "../ViewPlaceholder";
import NothingFoundBlock from "../NothingFoundBlock";
import IconWrap from "../Icons";
import IconWithLabel from "../Icons/IconWithLabel";

import * as builder from "../../../store/formBuilder/actions";
import * as selectors from "../../../store/formBuilder/selectors";
import {
    ElementTypes,
    submitByRef,
    downloadColumnFields,
    uploadColumnFields,
    tryUpdateUnknownElement,
    isFormChanged,
    transformApplicationFormErrors,
} from "../../utils/form";
import { usePropertiesFormRegistration, validatePropertiesForm } from "store/formBuilder/utils";
import PageProperties from "./PageProperties";
import SectionProperties from "./SectionProperties";
import ColumnProperties from "./ColumnProperties";
import FieldProperties from "./FieldProperties";
import { ErrorMessage } from "../Message";
import { useAutoMaxHeight } from "../../utils/dom";
import StickyBottomPanel from "../StickyBottomPanel";
import MoveFieldToOtherPageForm from "./MoveFieldToOtherPageForm";
import { ApplicationFormPagesContext } from "components/views/ApplicationFormPages";
import { onRemoveElement } from "./utils";
import { closeModal, openFormModal } from "../Modal/utils";

import "./style.scss";

import arrow from "../../../assets/img/arrows/arrow-6.png";
import { hasAnyOfPermissions, systemUserRights } from "components/utils/user";
const arrowWidth = 150;

const separatorHeight = 20;

const FormBuilder = memo((props) => {
    const { programNumber } = useContext(ApplicationFormPagesContext) || {};
    const { instanceId, isSubmitting } = props;

    const dispatch = useDispatch();
    const formBuilderFromStore = useSelector((state) => selectors.getFormBuilder({ instanceId, state: state.formBuilder }));
    const formBuilder = useMemo(() => formBuilderFromStore ?? {}, [formBuilderFromStore]);
    const { schema, uiSchema, initialSchema, initialUiSchema, rules, initialRules, initialValues } = formBuilder;

    const isChanged = useMemo(() => {
        return isFormChanged({
            schema,
            uiSchema,
            rules,
            initialSchema,
            initialUiSchema,
            initialRules,
        });
    }, [schema, uiSchema, rules, initialSchema, initialUiSchema, initialRules]);

    const pageCount = selectors.getPageCount(schema);
    const selectedElementId = formBuilder.selectedElementId;
    const selectedElementType = selectors.getSelectedElementType(formBuilder);
    const selectedElementUiParams = selectors.getSelectedElementUiParams(formBuilder);
    const elementNumber = selectors.getElementNumber({
        uiSchema: selectedElementUiParams,
    });

    const hasDisabledFields = document.querySelectorAll(`#${CSS.escape(instanceId)} .json-form .field-hidden`).length > 0;

    const validationErrors = formBuilder.validationErrors ?? {};

    const sidePanelRef = useRef();
    const formRef = useRef();
    const propertiesFormRef = useRef();
    const [hideFields, setHideFields] = useState(false);

    const errorCount = Object.keys(validationErrors).length;
    const hasErrors = errorCount > 0;
    const hasPropertyErrors = validationErrors[elementNumber];
    const isSaveDisabled = pageCount === 0 || isSubmitting || hasErrors;

    useAutoMaxHeight({
        elementRef: sidePanelRef.current,
        offsetHeight: separatorHeight * 2 + 2 + 39,
    });

    const showPageProperties = selectedElementType === ElementTypes.PAGE && pageCount > 0;
    const showSectionProperties = selectedElementType === ElementTypes.SECTION;
    const showColumnProperties = selectedElementType === ElementTypes.COLUMN;
    const showRowProperties = selectedElementType === ElementTypes.ROW;
    const showFieldProperties = selectedElementType === ElementTypes.FIELD;

    const pageNumber = selectedElementUiParams["af:pageNumber"] || "";
    const propertiesKey = pageNumber + selectedElementId;

    const showFieldUploadDownloadActions = showRowProperties || showColumnProperties || showFieldProperties;

    // Try to fix unknown elements
    useEffect(() => {
        if (selectedElementType === ElementTypes.UNKNOWN) {
            const result = tryUpdateUnknownElement({ formBuilder });

            if (result) {
                dispatch(
                    builder.updateUnknownElement({
                        instanceId,
                        ...result,
                    })
                );
            }
        }
    }, [instanceId, formBuilder, selectedElementType, dispatch]);

    // Preserve active properties form to be accessible from store actions
    usePropertiesFormRegistration({
        instanceId,
        selectedElementId,
        propertiesFormRef,
        dispatch,
    });

    const addPage = useCallback(() => {
        dispatch(builder.initPage({ instanceId, isNewPage: true }));
    }, [instanceId, dispatch]);

    const savePage = useCallback(async () => {
        let isValid = true;

        if (propertiesFormRef?.current) {
            isValid = await validatePropertiesForm({
                instanceId,
                formRef: propertiesFormRef,
                elementId: selectedElementId,
                dispatch,
            });
        }

        if (isValid && props.onSave) {
            props.onSave({
                schema,
                uiSchema,
                rules,
                initialValues,
            });
        }
    }, [instanceId, selectedElementId, initialValues, props, schema, uiSchema, rules, dispatch]);

    const revertChanges = useCallback(() => {
        props.onRevert && props.onRevert();
    }, [props]);

    const onChange = useCallback(
        (event) => {
            dispatch(builder.setFormData({ instanceId, ...event }));
        },
        [instanceId, dispatch]
    );

    const onRemove = useCallback(
        ({ elementId, elementType, fieldNumber }) => {
            onRemoveElement({
                instanceId,
                elementId,
                elementType,
                fieldNumber,
                programNumber,
            });
        },
        [instanceId, programNumber]
    );

    const onValidate = useCallback(() => {
        submitByRef(formRef, (errors, formData) => {
            if (!errors) {
                toast.success("Form Data is Valid");
            }
        });
    }, []);

    const onMoveFieldToOtherPage = useCallback(() => {
        openFormModal({
            title: "Move field to other form page",
            form: (
                <MoveFieldToOtherPageForm
                    instanceId={instanceId}
                    programNumber={programNumber}
                    formBuilder={formBuilder}
                    onCancel={closeModal}
                />
            ),
        });
    }, [instanceId, programNumber, formBuilder]);

    const onDownloadFields = useCallback(() => {
        downloadColumnFields({ formBuilder });
    }, [formBuilder]);

    const onUploadFields = useCallback(() => {
        uploadColumnFields({ instanceId, selectedElementId, dispatch });
    }, [instanceId, selectedElementId, dispatch]);

    const onGoToPrevError = useCallback(() => {
        dispatch(builder.goToPrevError({ instanceId }));
    }, [instanceId, dispatch]);

    const onGoToNextError = useCallback(() => {
        dispatch(builder.goToNextError({ instanceId }));
    }, [instanceId, dispatch]);

    const transformErrors = useCallback(
        (errors) => {
            return transformApplicationFormErrors(errors, schema, uiSchema, {
                localizeDateValues: true,
            });
        },
        [schema, uiSchema]
    );

    const submitText = isSubmitting ? "Saving..." : "Save";
    const propertiesTitlePrefix = showPageProperties
        ? "Page"
        : showSectionProperties
        ? "Section"
        : showColumnProperties
        ? "Column"
        : showFieldProperties
        ? "Field"
        : "";

    const sidePanel = (
        <StickySidePanel bodyRef={sidePanelRef}>
            {showPageProperties || showSectionProperties || showColumnProperties || showFieldProperties ? (
                <div className="form-elements flex-column no-scroll">
                    {hasErrors && (
                        <div className="form-elements__errors flex-row align-center">
                            <ErrorMessage>{`Fix Properties Errors (${errorCount})`}</ErrorMessage>
                            <span className="flex-one"></span>
                            <IconWrap icon="shevron-up-keyboard-arrow-up" title="Previous Error" onClick={onGoToPrevError} />
                            <IconWrap icon="shevron-down-keyboard-arrow-down" title="Next Error" onClick={onGoToNextError} />
                        </div>
                    )}

                    <div
                        className={cn("selected-part-properties flex-column no-scroll", {
                            "has-errors": hasPropertyErrors,
                        })}
                    >
                        <div className="element-properties__title-properties flex-row align-center">
                            <div className="flex-row">
                                <span>{propertiesTitlePrefix}</span> properties
                            </div>
                            <div className="form-actions flex-one-in-row flex-row align-center justify-space-between">
                                {showPageProperties && (
                                    <div className="flex-row flex-one align-center">
                                        <IconWithLabel
                                            icon="plus"
                                            disabled={isSubmitting}
                                            onClick={() =>
                                                dispatch(
                                                    builder.addSection({
                                                        instanceId,
                                                    })
                                                )
                                            }
                                        >
                                            Add Section
                                        </IconWithLabel>
                                    </div>
                                )}
                                {/* V50-3262 Row functionality disabled */}
                                {/* {showSectionProperties && <Button onClick={() => dispatch(builder.addRow({ instanceId }))}>Add Row</Button>} */}
                                {showSectionProperties && (
                                    <div className="flex-row flex-one align-center">
                                        <IconWithLabel
                                            icon="plus"
                                            disabled={selectors.isAddColumnDisabled(formBuilder) || isSubmitting}
                                            onClick={() =>
                                                dispatch(
                                                    builder.addColumn({
                                                        instanceId,
                                                    })
                                                )
                                            }
                                        >
                                            Add Column
                                        </IconWithLabel>
                                    </div>
                                )}
                                {(showRowProperties || showColumnProperties) && (
                                    <div className="flex-row flex-one align-center">
                                        <IconWithLabel
                                            icon="plus"
                                            disabled={isSubmitting}
                                            onClick={() =>
                                                dispatch(
                                                    builder.addField({
                                                        instanceId,
                                                    })
                                                )
                                            }
                                        >
                                            Add Field
                                        </IconWithLabel>
                                    </div>
                                )}
                                {showFieldProperties && (
                                    <div className="flex-row flex-one-in-row align-center">
                                        <IconWithLabel
                                            icon="plus"
                                            disabled={isSubmitting}
                                            onClick={() =>
                                                dispatch(
                                                    builder.addField({
                                                        instanceId,
                                                    })
                                                )
                                            }
                                        >
                                            Add Field Below
                                        </IconWithLabel>
                                        <IconWrap
                                            icon="move-out"
                                            iconWrapRoundedSquare
                                            iconWrapWhite
                                            title="Move Field to Other Page"
                                            disabled={isSubmitting}
                                            onClick={onMoveFieldToOtherPage}
                                        />
                                    </div>
                                )}
                                {showFieldUploadDownloadActions && (
                                    <div className="flex-row">
                                        <IconWrap
                                            icon="upload"
                                            iconWrapRoundedSquare
                                            iconWrapWhite
                                            title="Upload Fields To Active Column"
                                            onClick={onUploadFields}
                                        />
                                        <IconWrap
                                            icon="download"
                                            iconWrapRoundedSquare
                                            iconWrapWhite
                                            title="Download Fields From Active Column"
                                            onClick={onDownloadFields}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        {showPageProperties && (
                            <PageProperties
                                key={propertiesKey}
                                formRef={propertiesFormRef}
                                instanceId={instanceId}
                                disabled={isSubmitting}
                            />
                        )}
                        {showSectionProperties && (
                            <SectionProperties
                                key={propertiesKey}
                                formRef={propertiesFormRef}
                                instanceId={instanceId}
                                disabled={isSubmitting}
                            />
                        )}
                        {showColumnProperties && (
                            <ColumnProperties
                                key={propertiesKey}
                                formRef={propertiesFormRef}
                                instanceId={instanceId}
                                disabled={isSubmitting}
                            />
                        )}
                        {/* V50-3262 Row functionality disabled */}
                        {/* {showRowProperties && <RowProperties key={propertiesKey} instanceId={instanceId} />} */}
                        {showFieldProperties && (
                            <FieldProperties
                                key={propertiesKey}
                                formRef={propertiesFormRef}
                                instanceId={instanceId}
                                disabled={isSubmitting}
                            />
                        )}
                    </div>
                </div>
            ) : (
                <NothingFoundBlock
                    nothingFoundBlockImgSrc={arrow}
                    nothingFoundBlockImgWidth={arrowWidth}
                    title="Select anything in the form to see properties"
                ></NothingFoundBlock>
            )}
        </StickySidePanel>
    );

    return (
        <PanelWithSidePanel
            className={"form-builder" + (pageCount !== 0 ? " with-sticky-bottom-panel" : " fill-height")}
            sidePanel={pageCount !== 0 && sidePanel}
            showSidePanel
        >
            {pageCount !== 0 ? (
                <DndProvider backend={HTML5Backend} options={{ rootElement: document.getElementById("root") }}>
                    <div id={instanceId} className="form-pages flex-column flex-one fill-height">
                        <JsonSchemaFormWithConditionals
                            key={pageNumber}
                            className={hideFields && "hide-disabled-fields"}
                            formRef={formRef}
                            formId="form-builder"
                            formBuilder={instanceId}
                            idPrefix={instanceId}
                            schema={schema}
                            uiSchema={uiSchema}
                            rules={rules}
                            initialValues={initialValues}
                            noActions
                            onChange={onChange}
                            onRemove={onRemove}
                            transformErrors={transformErrors}
                        />
                    </div>
                </DndProvider>
            ) : (
                <ViewPlaceholder clickableText="Create New Page" clickableTextIcon="plus" onClick={addPage}>
                    Select Page or Create New
                </ViewPlaceholder>
            )}
            <StickyBottomPanel visible={pageCount !== 0}>
                <div className="main-grid-wrap responsive flex-row align-center">
                    <IconWrap
                        iconWrapRoundedSquare
                        iconWrapWhite
                        icon="scheduled-video-empty"
                        title="Validate Form Fields"
                        onClick={onValidate}
                    />
                    {hasAnyOfPermissions([systemUserRights.VISIONDSM_MANAGE_SYSTEM]) && (
                        <IconWrap
                            className="rebuild-configuration-button"
                            iconWrapRoundedSquare
                            iconWrapWhite
                            icon="repeat"
                            title="Rebuild Form Configuration"
                            onClick={props.onRebuildConfiguration}
                        />
                    )}
                    {hasDisabledFields && (
                        <div className="disabled-fields-switcher flex-row align-center">
                            <IconWrap icon="block-not-interested-empty" iconWrapSmall></IconWrap>
                            <div className="switcher-text" onClick={() => setHideFields(!hideFields)}>
                                {hideFields ? "Show disabled fields" : "Hide disabled fields"}
                            </div>
                            <IconWrap
                                icon={hideFields ? "fiber-smart-record-filled" : "fiber-smart-record-empty"}
                                onClick={() => setHideFields(!hideFields)}
                            />
                        </div>
                    )}
                    <div className="disabled-fields-switcher flex-row align-center">
                        <IconWrap icon="block-not-interested-empty" iconWrapSmall></IconWrap>
                        <div className="switcher-text" onClick={() => props.onHideDisabledPages()}>
                            {props.hideDisabledPages ? "Show disabled forms" : "Hide disabled forms"}
                        </div>
                        <IconWrap
                            icon={props.hideDisabledPages ? "fiber-smart-record-filled" : "fiber-smart-record-empty"}
                            onClick={() => props.onHideDisabledPages()}
                        />
                    </div>
                    <div className="flex-one"></div>
                    {isChanged && (
                        <div className="form-builder__main-buttons flex-row">
                            <Button primary disabled={isSaveDisabled} onClick={savePage}>
                                {submitText}
                            </Button>
                            <Button icon="undo" disabled={isSubmitting} onClick={revertChanges}>
                                Revert Changes
                            </Button>
                        </div>
                    )}
                    <div className="flex-one"></div>
                </div>
            </StickyBottomPanel>
        </PanelWithSidePanel>
    );
});

export default FormBuilder;
