import ButtonGroup from "components/ui/Button/ButtonGroup";
import StatusMsg from "components/ui/StatusMsg";
import React, { memo } from "react";
import Button from "../../../../Button";

import "./DependentTask.scss";

export const DependentTask = memo(({ onCancel }) => {
    return (
        <div className="dependent-task">
            <StatusMsg msgFieldStatus msgInfo msgText="This task cannot be completed because it is dependent task" />

            <ButtonGroup transparent>
                <Button onClick={onCancel}>Close</Button>
            </ButtonGroup>
        </div>
    );
});
