import React, { useCallback, useContext, useEffect, useRef } from "react";
import cn from "classnames";
import { isInViewport } from "components/utils/dom";
import { PortalBuilderContext, PortalBuilderPropertiesContext } from "components/ui/PortalBuilder/contexts";
import WaitIcon from "components/ui/WaitIcon";
import { SectionInfo } from "../SectionInfo";
import StatusMsg from "components/ui/StatusMsg";
import { PortalTemplateThumbnail } from "components/ui/PortalBuilder/PortalThumbnail";
import TextLabel from "components/ui/Label/TextLabel";
import ActionLabel from "components/ui/Label/ActionLabel";
import { openUtilityTab } from "components/utils/window";
import Separator from "components/ui/Separator";
import { SectionHeader } from "../SectionHeader";
import { PortalTemplateConfigurationSectionKey, PropertySize, PropertyType, ValidationRule } from "../../types";
import IconWrap from "components/ui/Icons";
import { ToolbarTypes } from "components/ui/Form/JsonSchema/widgets/HtmlEditorWidget/toolbars";
import ViewPlaceholder from "components/ui/ViewPlaceholder";
import { PropertyList } from "../PropertyList";
import { cloneDeep, isEqual } from "lodash";
import { setConfigSectionValue } from "../../utils";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";
import { portalAuthorizedPages } from "../../utils/page";
import { usePortalProgramTemplates } from "../../utils/usePortalProgramTemplates";

import "./ProgramSettings.scss";

export const ProgramSettings = (props) => {
    const { onChange } = props;
    const [config] = usePortalBuilderState((state) => state.updatedConfig);
    const [programNumber] = usePortalBuilderState((state) => state.programNumber);
    const [activeError] = usePortalBuilderState((state) => state.activeError);
    const [selectedUtilityTemplate] = usePortalBuilderState((state) => state.selectedUtilityTemplate);
    const [activeSectionErrors] = usePortalBuilderState((state) => state.activeSectionErrors);
    const containsActiveError = (activeSectionErrors ?? []).some((e) => isEqual(e, activeError));

    const [portalTemplates = [], isLoadingTemplates] = usePortalProgramTemplates({
        programNumber,
        forced: true,
    });

    const activeTemplate = portalTemplates.find((t) => t.portalTemplateNumber === selectedUtilityTemplate?.utilityTemplateNumber);
    const sectionProperties = getProgramSettingsProperties(activeTemplate, isLoadingTemplates, config);

    const configRef = useRef();
    configRef.current = config;

    const onPropertyChange = useCallback(
        (id, value, additionalValues = []) => {
            let updatedConfig = cloneDeep(configRef.current);
            updatedConfig = setConfigSectionValue(updatedConfig, PortalTemplateConfigurationSectionKey.SETTINGS, id, value);

            if (additionalValues.length > 0) {
                const values = additionalValues.reduce((obj, item) => Object.assign(obj, { [item.id]: item.value }), {});

                updatedConfig.settings = {
                    ...updatedConfig.settings,
                    ...values,
                };
            }

            onChange?.(updatedConfig);
        },
        [onChange]
    );

    return (
        <div className="portal-builder-properties-section-body flex-one-in-column fill-width with-scroll program-portal-settings">
            <PropertyList
                items={sectionProperties}
                errors={activeSectionErrors}
                containsActiveError={containsActiveError}
                config={config?.settings}
                onChange={onPropertyChange}
                isExpanded
            />
        </div>
    );
};

const TemplateSelectPanel = (props) => {
    const { onTemplateChange } = useContext(PortalBuilderContext);
    const [utilityNumber] = usePortalBuilderState((state) => state.utilityNumber);
    const [programNumber] = usePortalBuilderState((state) => state.programNumber);
    const [selectedUtilityTemplate] = usePortalBuilderState((state) => state.selectedUtilityTemplate);
    const { id, onChange, onClose, parentTitle } = props;

    const [portalTemplates = [], isLoadingTemplates] = usePortalProgramTemplates({
        programNumber,
        forced: true,
    });

    const { sectionTitle, sectionIcon } = useContext(PortalBuilderPropertiesContext);

    const subTitle = (
        <span>
            Select <strong>{parentTitle}</strong> template
        </span>
    );

    if (isLoadingTemplates) {
        return (
            <ViewPlaceholder>
                <WaitIcon />
            </ViewPlaceholder>
        );
    }

    return (
        <div className="program-portal-settings flex-one-in-column flex-column fill-height no-scroll">
            <SectionHeader Icon={sectionIcon} title={sectionTitle} subtitle={subTitle} underline onGoBack={onClose} />
            <SectionInfo>
                <StatusMsg
                    msgIcon="info-empty"
                    msgText="Choose any from already created Utility Templates to apply for the current program Portal."
                />
                {portalTemplates.length === 0 && (
                    <StatusMsg msgIcon="info-empty">
                        Create new templates from{" "}
                        <ActionLabel onClick={() => openUtilityTab({ utilityNumber, activePanel: "utility-config" })}>
                            utility config
                        </ActionLabel>
                        .
                    </StatusMsg>
                )}
                <Separator line marginBottom={0} marginTop={15} />
            </SectionInfo>
            <TemplateSelectList
                portalTemplates={portalTemplates}
                selectedPortalTemplateNumber={selectedUtilityTemplate?.utilityTemplateNumber}
                onSelect={onTemplateChange}
                id={id}
                onChange={onChange}
                onClose={onClose}
            />
        </div>
    );
};

const TemplateSelectList = ({ portalTemplates, selectedPortalTemplateNumber, onSelect, onClose }) => {
    // Scroll to active template on load.
    useEffect(() => {
        const element = document.getElementById(selectedPortalTemplateNumber);
        if (element && !isInViewport(element)) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }, [selectedPortalTemplateNumber]);

    const onClick = (template) => {
        onSelect(template);
        onClose && onClose();
    };

    return (
        <div className="portal-template-select-list with-scroll">
            {portalTemplates.map((template, index) => (
                <TemplateSelectListItem
                    key={index}
                    template={template}
                    isActive={template.portalTemplateNumber === selectedPortalTemplateNumber}
                    onClick={() => onClick(template)}
                />
            ))}
        </div>
    );
};

const TemplateSelectListItem = ({ template, isActive, onClick }) => {
    const { portalTemplateNumber, name, thumbnail } = template;

    return (
        <div
            id={portalTemplateNumber}
            className={cn("portal-template-select-item flex-column align-center", {
                "portal-template-select-item--active": isActive,
            })}
            onClick={onClick}
        >
            <PortalTemplateThumbnail thumbnail={thumbnail} />
            <TextLabel>{name}</TextLabel>
        </div>
    );
};

const getProgramSettingsProperties = (activeTemplate, isLoadingTemplates, config) => {
    const additionalPropertiesEnabled = activeTemplate || isLoadingTemplates;
    let properties = [
        {
            id: "selectedTemplate",
            title: activeTemplate ? (
                <>
                    Change selected <br /> template
                </>
            ) : (
                "Select Template"
            ),
            type: "select-panel",
            selectIcon: "touch-empty",
            selectTooltip: (isSelected) => (activeTemplate ? "Change Template" : "Select Template"),
            valueComponent: (props) =>
                !activeTemplate && isLoadingTemplates ? (
                    <WaitIcon />
                ) : (
                    activeTemplate && <TemplateSelectListItem template={activeTemplate} />
                ),
            alwaysShow: additionalPropertiesEnabled,
            noPostfix: true,
            selectComponent: IconWrap,
            panelComponent: TemplateSelectPanel,
        },
    ];

    if (activeTemplate) {
        properties.push(
            {
                id: "programStartPage",
                title: "Start page",
                type: PropertyType.Select,
                emptyItem: false,
                defaultValue: "/",
                items: config.content.pages
                    ?.filter((p) => isInternalLink(p.link) && p.pageAccess !== "AUTHENTICATED" && !portalAuthorizedPages.includes(p.link))
                    .map((p) => ({
                        label: p.link,
                        value: p.link,
                    })),
            },
            {
                id: "wizardButtonLabels",
                title: "Wizard Button Labels",
                type: PropertyType.Group,
                propertiesGroup: [
                    {
                        id: `wizardButtonBack`,
                        title: `Back`,
                        type: PropertyType.Group,
                        propertiesGroup: [
                            {
                                id: `wizardButtonBackText`,
                                title: "Button Text",
                                type: PropertyType.Text,
                                size: PropertySize.MD,
                            },
                        ],
                    },
                    {
                        id: `wizardButtonContinue`,
                        title: `Continue`,
                        type: PropertyType.Group,
                        propertiesGroup: [
                            {
                                id: `wizardButtonContinueText`,
                                title: "Button Text",
                                type: PropertyType.Text,
                                size: PropertySize.MD,
                            },
                        ],
                    },
                    {
                        id: `wizardButtonSaveDraft`,
                        title: `Save & Complete Later`,
                        type: PropertyType.Group,
                        propertiesGroup: [
                            {
                                id: `wizardButtonSaveDraftText`,
                                title: "Button Text",
                                type: PropertyType.Text,
                                size: PropertySize.MD,
                            },
                        ],
                    },
                    {
                        id: `wizardButtonCompleteApplication`,
                        title: `Complete Application`,
                        type: PropertyType.Group,
                        propertiesGroup: [
                            {
                                id: `wizardButtonCompleteApplicationText`,
                                title: "Button Text",
                                type: PropertyType.Text,
                                size: PropertySize.MD,
                            },
                        ],
                    },
                ],
            },
            {
                id: "programNotification",
                title: "Notification",
                type: "notification",
                titleSwitch: {
                    id: "enableProgramNotification",
                    defaultValue: false,
                },
                propertiesGroup: [
                    NOTIFICATION_TYPE_PROPERTY,
                    NOTIFICATION_TYPE_ICON_PROPERTY,
                    NOTIFICATION_TYPE_CLOSE_ICON_PROPERTY,
                    NOTIFICATION_TEXT_PROPERTY,
                ],
            }
        );
    }

    return properties;
};

export const isInternalLink = (link) => {
    return String(link ?? "").startsWith("/");
};

const NOTIFICATION_TYPE_PROPERTY = {
    id: "programNotificationType",
    title: "Notification Type:",
    type: "notification-type",
    defaultValue: "info",
    items: [
        {
            icon: "info-type-icon",
            value: "info",
            tooltip: "Info Type",
        },
        {
            icon: "attention-type-icon",
            value: "attention",
            tooltip: "Attention Type",
        },
        {
            icon: "regular-type-icon",
            value: "regular",
            tooltip: "Regular Type",
        },
    ],
};

const NOTIFICATION_TYPE_ICON_PROPERTY = {
    id: "programNotificationIcon",
    title: "Notification with type icon",
    type: "switch",
};

const NOTIFICATION_TYPE_CLOSE_ICON_PROPERTY = {
    id: "programNotificationCloseIcon",
    title: "Notification with close icon",
    type: "switch",
    labelIcon: "info-empty",
    labelTitle: "The user will be able to close a notification panel",
};

const NOTIFICATION_TEXT_PROPERTY = {
    id: "programNotificationText",
    title: "Notification Text",
    type: "html",
    borderBottom: false,
    validationRules: [ValidationRule.Required],
    toolbar: ToolbarTypes.Minimum,
};
