import React, { memo } from "react";

import { programsApprovedMeasuresGridColumnKeys } from "../../../../../views/configureGrids";
import { exportDatagridToCSV } from "../../../../../utils/CSV";

import IconWithLabel from "../../../../../ui/Icons/IconWithLabel";
import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";

const ApprovedMeasuresPanelGrid = withDataGrid("programsApprovedMeasures", ({ gridId, dataGridConfig }) => {
    const ROW_ACTIONS = [];

    return <DataGrid name={gridId} config={dataGridConfig} customRowActions={ROW_ACTIONS} />;
});

const ApprovedMeasuresPanel = memo(({ panel, onToggleTempPanel }) => {
    const programNumber = panel?.data?.programNumber;

    const DATA_GRID_ID = `${programNumber}-approved-measures-grid`;

    return (
        <div className="panel panel-approved-measures sticky-grid-list-panel">
            <PanelHeaderLarge title={panel?.name} childTitle="Approved Measures" onClick={() => onToggleTempPanel()} />
            <div className="data-grid-controls flex-row justify-end">
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: DATA_GRID_ID,
                            fileName: "program_approved_measures",
                            fileNamePostfix: programNumber,
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <ApprovedMeasuresPanelGrid
                gridId={DATA_GRID_ID}
                filter={`${programsApprovedMeasuresGridColumnKeys.programNumber}=${programNumber}`}
            />
        </div>
    );
});

export default ApprovedMeasuresPanel;
