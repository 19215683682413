import React, { memo } from "react";

import { programsDashboardCriteriaGridColumnKeys } from "../../../../../views/configureGrids";
import { exportDatagridToCSV } from "../../../../../utils/CSV";

import IconWithLabel from "../../../../../ui/Icons/IconWithLabel";
import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";

const DashboardCriteriaPanelGrid = withDataGrid("programsDashboardCriteria", ({ gridId, dataGridConfig }) => {
    const ROW_ACTIONS = [];

    return <DataGrid name={gridId} config={dataGridConfig} customRowActions={ROW_ACTIONS} />;
});

const DashboardCriteriaPanel = memo(({ panel, onToggleTempPanel }) => {
    const programNumber = panel?.data?.programNumber;

    const DATA_GRID_ID = `${programNumber}-dashboard-criteria-grid`;

    return (
        <div className="panel panel-dashboard-criteria sticky-grid-list-panel">
            <PanelHeaderLarge title={panel?.name} childTitle="Dashboard Criteria" onClick={() => onToggleTempPanel()} />
            <div className="data-grid-controls flex-row justify-end">
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: DATA_GRID_ID,
                            fileName: "program_dashboard_criteria",
                            fileNamePostfix: programNumber,
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <DashboardCriteriaPanelGrid
                gridId={DATA_GRID_ID}
                filter={`${programsDashboardCriteriaGridColumnKeys.programNumber}=${programNumber}`}
            />
        </div>
    );
});

export default DashboardCriteriaPanel;
