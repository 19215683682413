import React, { useState, memo } from "react";

import { exportDatagridToCSV } from "../../../utils/CSV";

import IconWithLabel from "../../../ui/Icons/IconWithLabel";
import BudgetAttributesGrid from "./BudgetAttributesGrid";
import BudgetAttributesForm from "./BudgetAttributesForm";

const BudgetAttributesPanel = memo((props) => {
    const gridId = "budget-attribute";

    const [showAddAttribute, setShowAddAttribute] = useState(false);
    const addAttributeIcon = showAddAttribute ? "shevron-circle-down-filled" : "plus";

    const toggleAddAttribute = () => {
        setShowAddAttribute(!showAddAttribute);
    };

    return (
        <div className="tab-list-tab-content flex-one-in-column flex-column">
            <div className="data-grid-controls">
                <IconWithLabel icon={addAttributeIcon} onClick={toggleAddAttribute}>
                    Add New Attribute
                </IconWithLabel>
                <span className="flex-one"></span>
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: gridId,
                            fileName: "budget_attributes",
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            {showAddAttribute && (
                <div className="attributes-form">
                    <BudgetAttributesForm onClose={toggleAddAttribute} dispatch={props.dispatch} gridId={gridId} />
                </div>
            )}
            <div className="panel budget-attributes flex-column fill-height no-scroll">
                <BudgetAttributesGrid gridId={gridId} />
            </div>
        </div>
    );
});

export default BudgetAttributesPanel;
