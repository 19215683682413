import React, { memo, useCallback } from "react";
import cn from "classnames";
import TextLabel from "components/ui/Label/TextLabel";
import IconWrap from "components/ui/Icons";
import "./SwitchProperty.scss";
import { SwitchTooltip } from "../../utils";
import { isNil } from "lodash";
import { PropertyListItem } from "../Property/PropertyListItem";
import PropertyDescription from "./PropertyDescription";

export const SwitchProperty = memo((props) => {
    const {
        title,
        id,
        labelIcon,
        labelTitle,
        onChange,
        switchOnText,
        switchOffText,
        className,
        fullWidth,
        description,
        borderTop,
        borderBottom,
        disabled,
    } = props;
    const value = props.value ?? props.defaultValue;

    const onClick = useCallback(() => {
        onChange(id, !value);
    }, [id, onChange, value]);

    if (props.hidden) {
        return null;
    }

    return (
        <PropertyListItem
            className={cn("property-list-item--switch", className, {
                "property-list-item--switch-on": Boolean(value),
            })}
            borderTop={borderTop}
            borderBottom={borderBottom}
        >
            <div
                className={cn("property-list-item-inner align-center flex-row", {
                    "justify-space-between": fullWidth !== false,
                })}
            >
                <div className="flex-row">
                    <TextLabel>{title}</TextLabel>
                    {labelIcon && <IconWrap className="label-icon" iconWrapSmall icon={labelIcon} title={labelTitle} />}
                </div>
                <IconWrap
                    title={value ? switchOffText ?? SwitchTooltip.SWITCH_OFF : switchOnText ?? SwitchTooltip.SWITCH_ON}
                    iconWrapSmall={props.size === "small"}
                    iconWrapMedium={props.size === "medium"}
                    iconWrapBig={props.size === "big" || isNil(props.size)}
                    icon={value ? "fiber-smart-record-filled" : "fiber-smart-record-empty"}
                    onClick={onClick}
                    disabled={disabled}
                    data-test-id={`${id}-switch`}
                />
            </div>
            <PropertyDescription id={id} value={value} description={description} />
        </PropertyListItem>
    );
});
