import React, { useState } from "react";
import cn from "classnames";
import ReactModal from "react-modal";
import Button from "../Button";
import IconWrap from "../Icons";
import IconWithLabel from "../Icons/IconWithLabel";
import { modalClose } from "../../../store/modal/actions";

import "./style.scss";
import Checkbox from "../Input/Checkbox";
import { useDispatch } from "react-redux";

const ConfirmModal = ({
    title,
    modalIcon,
    bodyOpenClassName,
    overlayClassName,
    className,
    content,
    footerContentCenter = false,
    okButtonText = "Yes",
    okButtonIcon,
    okButtonAsLink = false,
    cancelButtonText = "No",
    cancelButtonIcon,
    okButtonAsLinkIconRightAligned,
    showDontAskOption,
    onConfirm = () => {},
    onClose = () => {},
}) => {
    const [dontAskAgain, setDontAskAgain] = useState();

    const footerClassName = cn("modal-footer", {
        center: footerContentCenter && !showDontAskOption,
        "show-dont-ask-option": showDontAskOption,
    });

    const dispatch = useDispatch();

    return (
        <ReactModal
            isOpen
            shouldCloseOnOverlayClick={false}
            onRequestClose={() => dispatch(modalClose())}
            bodyOpenClassName={cn("", bodyOpenClassName)}
            overlayClassName={cn("", overlayClassName)}
            className={`modal confirm-modal ${className}`}
        >
            <div className="modal-content">
                {title && (
                    <div className={cn("modal-header")}>
                        {modalIcon && <IconWrap icon={modalIcon} />}
                        {title}
                        <IconWrap
                            icon="clear-close"
                            title="Close"
                            onClick={() => {
                                dispatch(modalClose());
                                onClose();
                            }}
                        />
                    </div>
                )}
                <div className="modal-body">
                    <div className="flex-column">{content}</div>
                </div>
                <div className={footerClassName}>
                    {showDontAskOption && (
                        <Checkbox
                            checked={dontAskAgain}
                            label={"Don't ask me again"}
                            onChange={(event) => {
                                setDontAskAgain(event.target.checked);
                            }}
                        />
                    )}

                    <div className="flex-row">
                        {okButtonAsLink ? (
                            <IconWithLabel
                                icon={okButtonIcon}
                                iconWithLabelRight={okButtonAsLinkIconRightAligned}
                                onClick={() => {
                                    dispatch(modalClose());
                                    onConfirm(dontAskAgain);
                                }}
                            >
                                {okButtonText}
                            </IconWithLabel>
                        ) : (
                            <Button
                                icon={okButtonIcon}
                                primary
                                className="modal-close"
                                onClick={() => {
                                    dispatch(modalClose());
                                    onConfirm(dontAskAgain);
                                }}
                            >
                                {okButtonText}
                            </Button>
                        )}
                        <Button
                            icon={cancelButtonIcon}
                            className="modal-close"
                            onClick={() => {
                                dispatch(modalClose());
                                onClose();
                            }}
                        >
                            {cancelButtonText}
                        </Button>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
};
export default ConfirmModal;
