import React, { memo } from "react";
import cn from "classnames";

const SearchResultsCounter = memo(({ totalResults, alignCenter }) => {
    return (
        <div
            className={cn("search-results__counter", {
                "search-results__counter--centered": alignCenter,
            })}
        >
            <span className="search-results__counter__value">{totalResults}</span>
            <span className="search-results__counter__text">
                Search{!alignCenter && <br />} Result
                {totalResults > 1 ? "s" : ""}
            </span>
        </div>
    );
});

export default SearchResultsCounter;
