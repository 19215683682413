import { isChildProgram } from "components/views/ProgramView/utils";
import React, { memo } from "react";

import IconWithLabel from "../../../../Icons/IconWithLabel";

const Controls = memo(({ onAddCalculation, onManageAssociations, onExport, programNumber }) => {
    return (
        <div className="grid-controls flex-row align-center">
            <IconWithLabel icon="plus" disabled={isChildProgram({ programNumber })} onClick={onAddCalculation}>
                Add Program Calculation
            </IconWithLabel>
            <IconWithLabel icon="hub-empty" disabled={isChildProgram({ programNumber })} onClick={onManageAssociations}>
                Manage Associations
            </IconWithLabel>
            <IconWithLabel withHandMadeIcon onClick={onExport}>
                Export CSV
            </IconWithLabel>
        </div>
    );
});

export default Controls;
