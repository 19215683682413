import React, { memo } from "react";

import DashboardPanel from "../DashboardPanel";
import PowerBI from "../../../../views/PowerBI";

const ReportPanel = memo(({ panel }) => {
    return (
        <DashboardPanel title={panel.name} className="powerBI">
            <PowerBI
                reportID={panel.reportId}
                reportType={panel.reportType}
                showPrint={true}
                entityTypeID={panel.entityType}
                entityNumber={panel.entityNumber}
            />
        </DashboardPanel>
    );
});

export default ReportPanel;
