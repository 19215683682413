import React, { useMemo, useCallback, memo } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";

import { refreshInvoiceDocumentTab } from "../../../../../../store/resources/refreshResource";
import { entityType } from "components/utils/entityType";
import {
    getUtilitiesInvoiceWorkflowResourceParams,
    getUtilitiesInvoiceWorkflowHistoryResourceParams,
} from "../../../../../../store/configureResources";
import { useUtilityRights } from "../../../../../../store/resources/useResource";
import { systemUserRights } from "components/utils/user";

import AdHocTaskForm from "./AdHocTaskForm";
import TaskWorkflow from "../../../../TaskWorkflow";

const WorkflowPanel = memo(({ panel, onExpand }) => {
    const utilityNumber = get(panel, "data.utilityNumber");
    const contractNumber = get(panel, "data.contractNumber");
    const documentNumber = get(panel, "data.documentNumber");

    const status = useSelector((state) => get(state, `resources.invoiceDocument.itemsById[${documentNumber}].status`));
    const isLocked = useSelector((state) => get(state, `resources.invoiceDocument.itemsById[${documentNumber}].islocked`)) === true;
    const wfNumber = useSelector((state) => get(state, `resources.invoiceDocument.itemsById[${documentNumber}].wfNumber`));
    const documentID = useSelector((state) => get(state, `resources.invoiceDocument.itemsById[${documentNumber}].invoiceDocumentID`));

    const workflowGetResourceOptions = useMemo(() => getUtilitiesInvoiceWorkflowResourceParams({ documentNumber }), [documentNumber]);

    const [utilityRights = []] = useUtilityRights({ utilityNumber });
    const hasAddTaskRights = utilityRights.includes(systemUserRights.VISIONDSM_ADD_CUSTOM_TASK);
    const isExpandTaskDisabled = isLocked;

    const workflowHistoryGetResourceOptions = useMemo(
        () =>
            getUtilitiesInvoiceWorkflowHistoryResourceParams({
                documentNumber,
            }),
        [documentNumber]
    );

    const workflowTaskOwnershipUpdateResourceOptions = useMemo(
        () => ({
            resourceName: "utilitiesInvoiceWorkflowTaskOwnership",
            path: {
                invoiceMasterNumber: documentNumber,
            },
        }),
        [documentNumber]
    );

    const workflowTaskUpdateResourceOptions = useMemo(
        () => ({
            resourceName: "utilitiesInvoiceWorkflowTasks",
            path: {
                invoiceMasterNumber: documentNumber,
            },
            query: {
                taskType: "invoice_document",
            },
        }),
        [documentNumber]
    );

    const workflowTaskDetailsResourceOptions = useMemo(
        () => ({
            resourceName: "utilitiesContractsWorkflowSteps",
            path: {
                utilityNumber,
                contractNumber,
                wfNumber,
            },
        }),
        [utilityNumber, contractNumber, wfNumber]
    );

    const handleChangeTask = useCallback(() => {
        refreshInvoiceDocumentTab({ documentNumber });
    }, [documentNumber]);

    const getAdHocTaskForm = useCallback(
        (props) => {
            return (
                <AdHocTaskForm
                    {...props}
                    documentID={documentID}
                    documentNumber={documentNumber}
                    utilityNumber={utilityNumber}
                    contractNumber={contractNumber}
                    workflowGetResourceOptions={workflowGetResourceOptions}
                    workflowHistoryGetResourceOptions={workflowHistoryGetResourceOptions}
                />
            );
        },
        [documentID, documentNumber, utilityNumber, contractNumber, workflowGetResourceOptions, workflowHistoryGetResourceOptions]
    );

    return (
        <div className="panel invoice-workflow-panel">
            <TaskWorkflow
                noDocumentSecurity
                entityId={documentNumber}
                entityTypeId={entityType.invoice}
                utilityNumber={utilityNumber}
                workflowGetResourceOptions={workflowGetResourceOptions}
                workflowHistoryGetResourceOptions={workflowHistoryGetResourceOptions}
                workflowTaskOwnershipUpdateResourceOptions={workflowTaskOwnershipUpdateResourceOptions}
                workflowTaskUpdateResourceOptions={workflowTaskUpdateResourceOptions}
                workflowTaskDetailsResourceOptions={workflowTaskDetailsResourceOptions}
                isLocked={isLocked}
                hasAddTaskRights={hasAddTaskRights}
                allowUnlock
                isExpandTaskDisabled={isExpandTaskDisabled}
                activeStatusName={status}
                AdHocTaskForm={getAdHocTaskForm}
                onExpandPanel={onExpand}
                onChangeTask={handleChangeTask}
                name={panel.name}
            />
        </div>
    );
});

export default WorkflowPanel;
