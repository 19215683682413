import React, { memo, ReactNode, RefObject } from "react";
import ClassNames from "classnames";

import useFocusClasses from "../../utils/useFocusClasses";
import useEnterKey from "../../utils/useEnterKey";
import IconHandMade from "./IconHandMade";

import "./IconWithLabel.scss";

const IconWithLabel = memo((props: IconWithLabelProps) => {
    const isTabbable = !props.disabled && Boolean(props.onClick);
    const isCustom = props.withHandMadeIcon || (props.icon || "").startsWith("custom-");

    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled: props.disabled,
    });
    const [onEnter] = useEnterKey({
        disabled: !isTabbable,
        onClick: props.onClick,
    });

    const className = ClassNames("icon-with-label with-icon", props.iconWithLabel, props.icon, props.className, {
        "icon-after": props.iconWithLabelRight,
        active: props.active || false,
        disabled: props.disabled,
        "with-hand-made-icon": isCustom,
        "icon-with-label--text-uppercase": props.iconWithLabelTextUppercase,
    });

    return (
        <div
            ref={props.elementRef}
            className={className}
            title={props.title}
            onMouseDown={props.disabled ? undefined : props.onMouseDown}
            onClick={props.disabled ? undefined : props.onClick}
            onKeyDown={onEnter}
            onFocus={onFocusClassesFocus}
            onBlur={onFocusClassesBlur}
            tabIndex={isTabbable ? 0 : -1}
            data-test-id={props["data-test-id"]}
        >
            {isCustom && <IconHandMade iconDocumentHandMade title="csv"></IconHandMade>}
            {props.children}
        </div>
    );
});

interface IconWithLabelProps {
    /** The name of the icon to display. */
    icon?: string;
    /** ? */
    iconWithLabel?: string;
    /** Whether to display the label to the right of the icon. */
    iconWithLabelRight?: boolean;
    /** Whether to display the label text in uppercase. */
    iconWithLabelTextUppercase?: boolean;
    /** Whether the component is currently active. */
    active?: boolean;
    /** Whether the component is currently disabled. */
    disabled?: boolean;
    /** The title of the component. */
    title?: string;
    /** Function to call when the mouse button is pressed down over the component. */
    onMouseDown?: () => void;
    /** Function to call when the component is clicked. */
    onClick?: () => void;
    /** Reference to the component's HTMLDivElement. */
    elementRef?: RefObject<HTMLDivElement>;
    /** The data-test-id attribute to apply to the component. */
    "data-test-id"?: string;
    /** Whether to use a hand-made icon. */
    withHandMadeIcon?: boolean;
    /** The child elements to render inside the component. */
    children?: ReactNode;
    /** The CSS class name to apply to the component. */
    className?: string;
}

export default IconWithLabel;
