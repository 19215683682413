import React, { memo } from "react";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import {
    getApprovedEquipmentAttributesSchema,
    getApprovedEquipmentAttributesUiSchema,
    getApprovedEquipmentAttributesInitialValues,
} from "./utils";

const ApprovedEquipmentAttributesForm = memo(({ equipment }) => {
    const schema = getApprovedEquipmentAttributesSchema(equipment);
    const uiSchema = getApprovedEquipmentAttributesUiSchema(equipment);
    const initialValues = getApprovedEquipmentAttributesInitialValues(equipment);

    return <JsonSchemaForm schema={schema} uiSchema={uiSchema} initialValues={initialValues} readOnly={true} noActions />;
});

export default ApprovedEquipmentAttributesForm;
