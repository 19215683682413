import React, { useContext } from "react";
import { PaddingPropertyContext } from "components/ui/PortalBuilder/contexts";
import { PaddingCustomPropertyName, PaddingSides } from "./types";
import { PaddingBoxSize, PortalBuilderPropertyProps, PropertyName } from "components/ui/PortalBuilder/types";
import { PaddingBox } from "./PaddingBox";

import { PropertyListItem } from "../PropertyListItem";

export const AllSidesProperty = (props: AllSidesPropertyProps) => {
    const { value, borderBottom, borderTop, onChange } = props;
    const { customPropertiesValue } = useContext(PaddingPropertyContext);

    const onSelect = (id: PropertyName, size: string | null) => {
        if (value === size) {
            onChange(id, "0rem");
        } else {
            onChange(id, size);
        }
    };

    if (customPropertiesValue[PaddingCustomPropertyName.PaddingType] !== PaddingSides.AllSides) {
        return null;
    }

    return (
        <PropertyListItem
            className="property-list-item--padding-size property-list-item--padding-size-all-sides flex-row"
            innerContent
            borderBottom={borderBottom}
            borderTop={borderTop}
        >
            {PADDING_SIZES.map((size, index) => (
                <PaddingBox
                    allSides
                    key={index}
                    text={size.text}
                    tooltip={size.tooltip}
                    isSelected={value === size.value}
                    onClick={() => onSelect(PropertyName.Padding, size.value)}
                />
            ))}
        </PropertyListItem>
    );
};

interface AllSidesPropertyProps extends Omit<PortalBuilderPropertyProps, "value"> {
    value?: string;
}

export const PADDING_SIZES = [
    {
        text: "XS",
        tooltip: "extra small",
        value: PaddingBoxSize.XS,
    },
    {
        text: "SM",
        tooltip: "small",
        value: PaddingBoxSize.SM,
    },
    {
        text: "MD",
        tooltip: "medium",
        value: PaddingBoxSize.MD,
    },
    {
        text: "LG",
        tooltip: "large",
        value: PaddingBoxSize.LG,
    },
    {
        text: "XL",
        tooltip: "extra large",
        value: PaddingBoxSize.XL,
    },
    {
        text: "XXL",
        tooltip: "twice extra large",
        value: PaddingBoxSize.XXL,
    },
];
