import React, { useState, useCallback, useMemo, memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { saveDashboard } from "../../../../store/dashboards/actions";
import { getDashboardFormComponent, openDeleteDashboardDialog } from "./utils";
import { resetDashboards, getDashboardNameByType, getSubmitConfiguration } from "../../../utils/dashboard";

import IconWrap from "../../Icons";
import IconWithLabel from "../../Icons/IconWithLabel";
import FieldGroupDropdownIcon from "../../FieldGroupDropdownIcon";

import "./style.scss";

import { DragDropContext } from "react-beautiful-dnd";
import DragHandle from "components/ui/DragHandle";
import { reorder } from "components/utils/array";
import { optimisticUpdateItem } from "store/resources/actions";
import useSidePanelHandlers from "../../../utils/useSidePanelHandlers";
import { sideNavKey, sideNavSize } from "../../SideNav/SideNavRoot";

const DashboardSettings = memo(({ dashboardType }) => {
    const [isActive, setIsActive] = useState(false);
    const [isLocked, setIsLocked] = useState(false);

    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user);
    const dashboardsFromStore = useSelector((state) => state.resources.dashboards.itemsById[dashboardType]);
    const dashboards = useMemo(() => dashboardsFromStore || [], [dashboardsFromStore]);

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        sideNavKey: sideNavKey.globalRight,
        size: sideNavSize.small,
    });

    const dashboardList = useMemo(() => {
        const customDashboards = dashboards.filter((i) => !i.isGlobal);

        return (customDashboards.length > 0 ? customDashboards : dashboards).map((i, order) => ({
            ...i,
            draggableIndex: i.order ?? order,
            draggableId: i.id,
        }));
    }, [dashboards]);

    const canResetDashboards = useMemo(() => {
        const customDashboards = dashboards.filter((d) => !d.isGlobal);
        return customDashboards.length > 0;
    }, [dashboards]);

    const onToggleShow = useCallback(
        (dashboard) => {
            dispatch(
                saveDashboard({
                    dashboard: {
                        ...dashboard,
                        show: !dashboard.show,
                    },
                })
            );
        },
        [dispatch]
    );

    const onToggleDefault = useCallback(
        (dashboard) => {
            dispatch(
                saveDashboard({
                    dashboard: {
                        ...dashboard,
                        isDefault: !dashboard.isDefault,
                    },
                })
            );
        },
        [dispatch]
    );

    const onDashboardAdd = useCallback(() => {
        setIsLocked(true);
        const component = getDashboardFormComponent({
            dashboardType,
            setIsLocked,
            onClose: () => handleCloseSidePanel(),
        });
        handleOpenSidePanel(component);
    }, [dashboardType, handleOpenSidePanel, handleCloseSidePanel]);

    const onDashboardEdit = useCallback(
        (dashboard) => {
            setIsLocked(true);
            const component = getDashboardFormComponent({
                dashboardType,
                dashboard,
                setIsLocked,
                onClose: () => handleCloseSidePanel(),
            });
            handleOpenSidePanel(component);
        },
        [dashboardType, handleOpenSidePanel, handleCloseSidePanel]
    );

    const onDashboardDelete = useCallback((dashboard) => {
        setIsLocked(true);
        openDeleteDashboardDialog(dashboard, setIsLocked);
    }, []);

    const onResetDashboard = useCallback(() => {
        resetDashboards({
            dashboardType: {
                id: dashboardType,
                label: getDashboardNameByType(dashboardType),
            },
            userNumber: currentUser.userNumber,
            currentUser,
        });
    }, [dashboardType, currentUser]);

    const handleSetActive = useCallback(() => {
        setIsActive(!isActive);
    }, [isActive]);

    const onClick = useCallback((event) => {
        event.stopPropagation();
    }, []);

    const onDragEnd = useCallback(
        (result) => {
            if (!result.destination) {
                return;
            }

            if (result.destination.index === result.source.index) {
                return;
            }
            let updatedList = dashboardList.slice();

            const sourceIndex = updatedList.findIndex((d) => d.order === result.source.index);

            reorder(updatedList, sourceIndex, result.destination.index);
            for (var i = 0; i < updatedList.length; i++) {
                updatedList[i].order = i;
            }

            dispatch(
                optimisticUpdateItem({
                    resourceName: "dashboards",
                    resourceId: updatedList[result.destination.index].type,
                    value: updatedList.map((d) => ({
                        ...d,
                        configuration: JSON.stringify(getSubmitConfiguration(d)),
                    })),
                })
            );
            updatedList.forEach((dashboard) => {
                dispatch(
                    saveDashboard({
                        dashboard,
                    })
                );
            });
        },
        [dispatch, dashboardList]
    );

    const renderItem = (dashboard, dragProps) => {
        return (
            <div className={"flex-row fill-width align-center" + (dashboard.show ? " item-active" : "")} onClick={onClick}>
                <DragHandle dragVertical {...dragProps.dragHandleProps} />
                <IconWrap
                    icon={dashboard.show ? "fiber-smart-record-filled" : "fiber-smart-record-empty"}
                    onClick={() => onToggleShow(dashboard)}
                    title={`${dashboard.show ? "Hide" : "Show"} dashboard`}
                />
                <span className="flex-one">
                    <span onClick={() => onToggleShow(dashboard)}>{dashboard.name}</span>
                </span>
                <IconWrap icon="edit-empty" onClick={() => onDashboardEdit(dashboard)} title="Edit Dashboard" />
                <IconWrap icon="delete-trash-empty" onClick={() => onDashboardDelete(dashboard)} title="Delete Dashboard" />
                {!dashboard.isGlobal && (
                    <IconWrap
                        icon={"pinner" + (dashboard.isDefault ? " pinned pin-filled" : " pin-empty")}
                        onClick={() => onToggleDefault(dashboard)}
                        title={dashboard.isDefault ? "Do not use as default dashboard" : "Set as default dashboard"}
                    />
                )}
            </div>
        );
    };

    const listTitle = (
        <div className="flex-column fill-width">
            <div className="dashboard-tools-settings-dropdown__title">Configure Dashboards</div>
            {canResetDashboards && (
                <div className="dashboard-tools-settings-dropdown__reset">
                    <IconWithLabel icon="backup-restore-empty" title="Reset to Default" onClick={onResetDashboard}>
                        Reset to Default
                    </IconWithLabel>
                </div>
            )}
        </div>
    );
    const footer = (
        <div className="dropdown-sub-block">
            <IconWithLabel icon="plus" onClick={onDashboardAdd}>
                Add Dashboard
            </IconWithLabel>
        </div>
    );
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <FieldGroupDropdownIcon
                title={isActive ? "Close" : "Configure Dashboards"}
                className="dashboard-settings-dropdown"
                dropdownRight
                mobileHeader="Configure Dashboards"
                footer={footer}
                dropdownOptions
                iconWrapDropdown
                iconWrapTransparent
                withTitle={listTitle}
                visible={isActive}
                isLocked={isLocked}
                iconWrapActive={isActive}
                iconWrap={isActive ? "settings-filled" : "settings-empty"}
                onClick={handleSetActive}
                items={dashboardList}
                renderItem={renderItem}
                draggable
            ></FieldGroupDropdownIcon>
        </DragDropContext>
    );
});

export default DashboardSettings;
