import { useContext } from "react";
import { SideNavContext } from "components/ui/SideNav/SideNavContext";

export const useSidePanelCallbacks = ({ onClose } = {}) => {
    const sideNavContext = useContext(SideNavContext);
    if (!sideNavContext) {
        return;
    }

    if (onClose) {
        sideNavContext.registerOnClose(onClose);
    }
};
