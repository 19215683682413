import React, { useEffect, useState, useRef, useCallback, memo, useMemo } from "react";
import { useDispatch } from "react-redux";
import { isEmpty, isNil } from "lodash";

import { getApplicationFormSubmitValues, removeDisabledFields, setDefaultDateValues, submitByRefPromise } from "../../utils/form";
import { getResource, updateResource } from "../../../store/resources/actions";
import { modalOpen } from "../../../store/modal/actions";
import { openProjectTab, setOnCloseNewApplicationTab, setOnCloseNewApplicationFromScanTab } from "../../utils/window";
import { useCustomer } from "../../../store/resources/useResource";
import { refreshProgramApplicationsGrid } from "../../../store/dataGrid/refreshGrid";
import { getApplicationFormPageDetailsResourceParams } from "../../../store/configureResources";
import { useBackEndRequirePrimaryContact, useBackEndRequirePremiseContact } from "../ProgramView/utils";
import { getContactFromCisContact } from "./utils";

import WaitIcon from "../../ui/WaitIcon";
import Button from "../../ui/Button";
import NothingFoundBlock from "../../ui/NothingFoundBlock";
import ContentTitle from "../../ui/Title/ContentTitle";
import ButtonGroup from "../../ui/Button/ButtonGroup";
import ContactsPanel from "./ContactsPanel";
import ApplicationForm from "./ApplicationForm";
import ApplicationFiles from "./ApplicationFiles";
import ApplicationEquipment from "./ApplicationEquipment";
import useUnmounted from "components/utils/useUnmounted";

import "./Application.scss";

const Application = memo(
    ({ fromScan, applicationNumber, utilityNumber, programNumber, formPageNumber, customerNumber, customerDetails, onClose }) => {
        const dispatch = useDispatch();

        const customerFormRef = useRef();
        const premiseFormRef = useRef();
        const primaryFormRef = useRef();
        const contractorFormRef = useRef();
        const formPageRef = useRef();

        const unmounted = useUnmounted();

        const [customer, setCustomer] = useState({});
        const [premise, setPremise] = useState({});
        const [primary, setPrimary] = useState({});
        const [contractor, setContractor] = useState({});
        const [formPage, setFormPage] = useState({});
        const [applicationFormPage, setApplicationFormPage] = useState();
        const [applicationError, setApplicationError] = useState(false);
        const [applicationIsCreating, setApplicationIsCreating] = useState(false);

        const [existingCustomer] = useCustomer({
            utilityNumber,
            customerNumber,
        });

        const requirePrimaryContact = useBackEndRequirePrimaryContact({ programNumber }) === "y";
        const requirePremiseContact = useBackEndRequirePremiseContact({ programNumber }) === "y";

        // Process form configuration
        const { schema, uiSchema, rules, initialValues } = useMemo(() => {
            const configuration = (applicationFormPage && applicationFormPage.formConfiguration) || {};

            const formConfig = removeDisabledFields(configuration);
            const initialValues = setDefaultDateValues({
                schema: formConfig.schema,
                uiSchema: formConfig.uiSchema,
            });

            return { ...formConfig, initialValues };
        }, [applicationFormPage]);

        // onWindowRemove - is a callback function for splitview
        const onCancel = useCallback(
            (onWindowRemove, shouldWindowRemain) => {
                const message = <p>Are you sure you would like to cancel New Application creation?</p>;

                dispatch(
                    modalOpen({
                        modalType: "CONFIRM",
                        modalProps: {
                            title: "Cancel New Application creation",
                            overlayClassName: "modal-styled",
                            className: "cancel-new-app-creation-confirmation-modal modal-sm",
                            content: message,
                            footerContentCenter: true,
                            onConfirm: () => {
                                onClose(onWindowRemove, shouldWindowRemain);
                            },
                        },
                    })
                );
            },
            [onClose, dispatch]
        );

        useEffect(() => {
            if (applicationNumber) {
                if (fromScan) {
                    setOnCloseNewApplicationFromScanTab({
                        applicationNumber,
                        onCancel,
                    });
                } else {
                    setOnCloseNewApplicationTab({ applicationNumber, onCancel });
                }
            }
        }, [applicationNumber, fromScan, onCancel, dispatch]);

        useEffect(() => {
            if (applicationNumber && formPageNumber) {
                dispatch(
                    getResource({
                        ...getApplicationFormPageDetailsResourceParams({
                            applicationNumber,
                            formPageNumber,
                        }),
                        onSuccess: (action) => {
                            !unmounted.current && setApplicationFormPage(action.data);
                        },
                    })
                );
            }
        }, [applicationNumber, formPageNumber, unmounted, dispatch]);

        useEffect(() => {
            if (existingCustomer) {
                setCustomer(existingCustomer);
            }
        }, [existingCustomer]);

        useEffect(() => {
            if (customerDetails) {
                const contact = getContactFromCisContact({ cisContact: customerDetails, isMailingAddress: false });

                setPrimary(getContactFromCisContact({ cisContact: customerDetails, isMailingAddress: true }));
                setPremise(contact);
                setCustomer(contact);
            }
        }, [customerDetails]);

        const onSubmit = useCallback(
            (customer, premise, primary, contractor, formPage) => {
                setApplicationIsCreating(true);

                var element = document.getElementsByClassName("application-form-creation-waiting")[0];
                element?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });

                const values = getApplicationFormSubmitValues(formPage, []);

                dispatch(
                    updateResource({
                        resourceName: "applications",
                        resourceId: applicationNumber,
                        body: {
                            utilityNumber: utilityNumber,
                            customer: customer,
                            premise: premise,
                            addPremiseToProfileContacts: premise?.addToProfileContacts ?? false,
                            primary: primary,
                            addPrimaryToProfileContacts: primary?.addToProfileContacts ?? false,
                            contractor: contractor,
                            data: values,
                        },
                        onSuccess: (action) => {
                            setApplicationError(false);
                            refreshProgramApplicationsGrid({ programNumber });

                            const content = (
                                <>
                                    <p>
                                        Application <strong>{applicationNumber}</strong> succesfully created.
                                    </p>
                                    <p>Would you like to open it or create another application?</p>
                                </>
                            );

                            dispatch(
                                modalOpen({
                                    modalType: "CONFIRM",
                                    modalProps: {
                                        title: "Application Created",
                                        overlayClassName: "modal-styled",
                                        className: "app-creation-confirmation-modal modal-sm",
                                        modalIcon: "check-circle-empty",
                                        content,
                                        footerContentCenter: true,
                                        okButtonAsLink: true,
                                        okButtonText: "Open Application",
                                        okButtonIcon: "open-new-window",
                                        okButtonAsLinkIconRightAligned: true,
                                        cancelButtonText: "Create Another New Application",
                                        cancelButtonIcon: "plus",
                                        onConfirm: () => {
                                            onClose();
                                            openProjectTab({
                                                applicationNumber: action.data.number,
                                            });
                                        },
                                        onClose: () => {
                                            onClose();
                                        },
                                    },
                                })
                            );
                        },
                        onError: (action) => {
                            if (action.message !== null) {
                                setApplicationError(true);
                            }
                        },
                        onComplete: () => setApplicationIsCreating(false),
                    })
                );
            },
            [utilityNumber, programNumber, applicationNumber, onClose, dispatch]
        );

        const handleCustomerCopy = useCallback((data) => {
            setPremise({
                firstname: data.firstName,
                lastname: data.lastName,
                acct_number: data.accountNumber,
                email: data.email,
            });
        }, []);

        const handleContactChange = useCallback((contactType, data) => {
            switch (contactType) {
                case "customer":
                    setCustomer(data);
                    break;

                case "premise":
                    setPremise(data);
                    break;

                case "primary":
                    setPrimary(data);
                    break;

                case "contractor":
                    setContractor(data);
                    break;

                default:
                    break;
            }
        }, []);

        const handleFormPageChange = useCallback((data) => {
            setFormPage(data);
        }, []);

        const handleSubmit = async () => {
            try {
                await submitByRefPromise(customerFormRef);

                const premiseFilled = Object.keys(premise).some((key) => !isEmpty(premise[key]));
                if (requirePremiseContact || (premiseFilled && !isNil(premiseFormRef.current))) {
                    await submitByRefPromise(premiseFormRef);
                }

                const primaryFilled = Object.keys(primary).some((key) => !isEmpty(primary[key]));
                if (requirePrimaryContact || (primaryFilled && !isNil(primaryFormRef.current))) {
                    await submitByRefPromise(primaryFormRef);
                }

                if (contractorFormRef.current) {
                    await submitByRefPromise(contractorFormRef);
                }

                await submitByRefPromise(formPageRef);

                onSubmit(customer, premiseFormRef.current?.state?.formData, primaryFormRef.current?.state?.formData, contractor, formPage);
            } catch {
                return;
            }
        };

        if (!applicationFormPage) {
            return <WaitIcon />;
        }

        return (
            <div className={"new-application-form" + (fromScan ? " from-scan" : "")}>
                <div className={fromScan ? "" : "main-grid-wrap responsive"}>
                    {!fromScan && <ContentTitle>Continue with New App creation</ContentTitle>}
                    <div className="new-application-form__content">
                        <ContactsPanel
                            utilityNumber={utilityNumber}
                            programNumber={programNumber}
                            applicationNumber={applicationNumber}
                            customer={customer}
                            customerFormRef={customerFormRef}
                            premise={premise}
                            premiseFormRef={premiseFormRef}
                            primary={primary}
                            primaryFormRef={primaryFormRef}
                            contractor={contractor}
                            contractorFormRef={contractorFormRef}
                            onCustomerCopy={handleCustomerCopy}
                            onChange={handleContactChange}
                            fromScan={fromScan}
                        />

                        <ApplicationForm
                            formRef={formPageRef}
                            programNumber={programNumber}
                            applicationNumber={applicationNumber}
                            schema={schema}
                            uiSchema={uiSchema}
                            rules={rules}
                            initialValues={initialValues}
                            onChange={handleFormPageChange}
                        />

                        <ApplicationEquipment applicationNumber={applicationNumber} programNumber={programNumber} />

                        <ApplicationFiles
                            utilityNumber={utilityNumber}
                            programNumber={programNumber}
                            applicationNumber={applicationNumber}
                        />
                    </div>
                </div>

                {applicationIsCreating && (
                    <div className="application-form-creation-waiting">
                        Application is being created. <br /> It may take some time for the system to process data submitted
                        <strong>Please wait...</strong>
                        <WaitIcon />
                    </div>
                )}

                {applicationError && (
                    <NothingFoundBlock
                        nothingFoundBlockError
                        nothingFoundBlockSmall
                        nothingFoundBlockNoBorder
                        nothingFoundBlockNoWrapper
                        icon="circle-exclamation-empty"
                        title="Application creation failed."
                    >
                        <span>Try again or contact customer support.</span>
                    </NothingFoundBlock>
                )}

                {!applicationIsCreating && (
                    <ButtonGroup transparent>
                        <Button icon="plus" primary onClick={() => handleSubmit()}>
                            Create New Application
                        </Button>
                        <Button onClick={onCancel}>Cancel</Button>
                    </ButtonGroup>
                )}
            </div>
        );
    }
);

export default Application;
