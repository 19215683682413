import React from "react";
import ClassNames from "classnames";

import "./style.scss";

const PageLeadBlock = (props) => {
    const className = ClassNames("page-lead-block", props.icon, props.className, {
        "with-icon": props.withIcon,
    });

    return (
        <div className={className}>
            <div className="background-img"></div>
            {props.children}
        </div>
    );
};

export default PageLeadBlock;
