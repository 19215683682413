import React from "react";
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useWindowResize } from "../../utils/useWindowResize";
import { AutoSizeContext } from "components/utils/autosize";

/** List which aligns and resizes items taking into account their content size */
const AutoSizeList = ({ className, itemCount, itemSize, overscanCount, children }) => {
    // List REF
    const listREF = React.useRef();

    // Map of heights
    const sizeMap = React.useRef({});

    // Listen for window resize
    const [windowWidth] = useWindowResize();

    // Listen for size updates from children
    const setAutoSize = React.useCallback((index, size) => {
        sizeMap.current = { ...sizeMap.current, [index]: size };

        // Update list
        if (listREF.current) listREF.current.resetAfterIndex(0);
    }, []);
    const getSize = React.useCallback(
        (index) => {
            const savedSize = sizeMap.current[index];
            return savedSize ? Math.max(savedSize, itemSize) : itemSize;
        },
        [itemSize]
    );

    return (
        <AutoSizeContext.Provider value={{ setAutoSize, windowWidth }}>
            <AutoSizer>
                {({ height, width }) => (
                    <VariableSizeList
                        className={className}
                        ref={listREF}
                        height={height}
                        width={width}
                        itemCount={itemCount}
                        estimatedItemSize={itemSize}
                        itemSize={getSize}
                        overscanCount={overscanCount}
                    >
                        {children}
                    </VariableSizeList>
                )}
            </AutoSizer>
        </AutoSizeContext.Provider>
    );
};

export default AutoSizeList;
