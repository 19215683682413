import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

import { reducer as loginReducer } from "./login/reducer";
import { reducer as resetPasswordReducer } from "./login/resetPasswordReducer";
import { reducer as changePasswordReducer } from "./login/changePasswordReducer";
import { reducer as userInfoReducer } from "./login/userInfoReducer";
import { reducer as sideNavReducer } from "./sideNav/reducer";
import { reducer as systemReducer } from "./system/reducer";
import { reducer as windowReducer } from "./window/reducer";
import { reducer as modalReducer } from "./modal/reducer";
import { reducer as userProfileReducer } from "./userProfile/reducer";
import { reducer as dataGridReducer } from "./dataGrid/reducer";
import { reducer as filesReducer } from "./files/reducer";
import { reducer as formBuilderReducer } from "./formBuilder/reducer";
import { reducer as pagesReducer } from "./pages/reducer";
import { reducer as documentsReducer } from "./documents/reducer";
import { reducer as formsReducer } from "./forms/reducer";
import { reducer as resourcesReducer } from "./resources/reducer";
import { reducer as projectsReducer } from "./projects/reducer";
import { reducer as programsReducer } from "./programs/reducer";
import { reducer as attributesReducer } from "./attributes/reducer";
import { reducer as associationsReducer } from "./associations/reducer";
import { reducer as dashboardsReducer } from "./dashboards/reducer";
import { reducer as subMenuReducer } from "./subMenu/reducer";
import { reducer as workcenterReducer } from "./workcenter/reducer";
import { reducer as globalSearchReducer } from "./globalSearch/reducer";

const reducers = {
    login: loginReducer,
    user: userInfoReducer,
    userProfile: userProfileReducer,
    sideNav: sideNavReducer,
    system: systemReducer,
    resetPassword: resetPasswordReducer,
    changePassword: changePasswordReducer,
    window: windowReducer,
    modal: modalReducer,
    dataGrid: dataGridReducer,
    files: filesReducer,
    formBuilder: formBuilderReducer,
    pages: pagesReducer,
    documents: documentsReducer,
    forms: formsReducer,
    resources: resourcesReducer,
    projects: projectsReducer,
    programs: programsReducer,
    attributes: attributesReducer,
    associations: associationsReducer,
    dashboards: dashboardsReducer,
    subMenu: subMenuReducer,
    workcenter: workcenterReducer,
    globalSearch: globalSearchReducer,
};

const appReducer = combineReducers({
    ...reducers,
});

const rootReducer = (state, action) => {
    if (action.type === actionTypes.APP_RESET) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
