import React, { memo, useContext, useId } from "react";
import { ScrollSyncPane } from "react-scroll-sync";
import ScrollControls from "../../ScrollControls";
import { GridListContext } from ".";

import "./ScrollableColumns.scss";

const ScrollableColumns = memo(({ children, scrollControls }) => {
    const targetId = useId();
    const { onHorizontalScrollChange } = useContext(GridListContext);

    const content = (
        <ScrollSyncPane group="datagrid">
            <div id={targetId} className="grid-list-scrollable-columns">
                {children}
            </div>
        </ScrollSyncPane>
    );

    if (scrollControls) {
        return (
            <>
                <div className="grid-list-scrollable-columns-container">{content}</div>
                <ScrollControls targetId={targetId} noWrap iconSmall updateInterval={1000} onChange={onHorizontalScrollChange} />
            </>
        );
    }

    return content;
});

export default ScrollableColumns;
