import React, { memo } from "react";
import { useDispatch } from "react-redux";

import { modalOpen } from "../../../../../../../store/modal/actions";
import { deleteResource } from "../../../../../../../store/resources/actions";
import { availableGrids } from "../../../../../../views/configureGrids";

import useSidePanelHandlers from "../../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../../SideNav/SideNavRoot";

import WaitIcon from "../../../../../WaitIcon";
import Form from "./Form";
import DataGrid from "../../../../../DataGrid";
import { isChildProgram } from "components/views/ProgramView/utils";
import { useDataGrid } from "components/utils/useDataGrid";
import { mapGridRowToObject } from "components/utils/datagrid";

const programCatalogCategoriesGridColumnKeys = {
    categoryNumber: "c_fc5cbdcb-df93-47cb-9175-1d4c64f777b6",
    category: "c_62da3ec2-fc5b-4abd-a403-fa228d845935",
    parentCategoryNumber: "c_a38032a7-b230-4c1f-b7d2-502cde9804e6",
    parentCategoryName: "c_38ee3d2f-fdfe-4943-b537-701229e2c41b",
};

const Grid = memo(({ dataGridId, programNumber, onRefresh }) => {
    const dispatch = useDispatch();

    const isLocked = isChildProgram({ programNumber });

    const rowActions = [
        {
            name: "edit",
            icon: isLocked ? "eye-visibility-empty" : "edit-empty",
            title: isLocked ? "View" : "Edit",
            footerExclude: true,
        },
        {
            name: "add",
            icon: "plus",
            title: "Add New Child Category",
            hide: isLocked,
            footerExclude: true,
        },
        {
            name: "delete",
            icon: "delete-trash-empty",
            title: "Remove Category",
            hide: (row) => isLocked || row._treeHasChildren,
            footerExclude: true,
        },
    ];

    const [gridConfig, isLoadingConfig] = useDataGrid({
        dataGridId: availableGrids.programCatalogCategories,
        dataGridInstanceId: dataGridId,
        getDataAction: {
            type: "catalogCategoriesGridGetDataAction",
            props: {
                columnKeysMap: programCatalogCategoriesGridColumnKeys,
                programNumber,
            },
        },
        tree: {
            treeColumn: programCatalogCategoriesGridColumnKeys.category,
            idColumn: programCatalogCategoriesGridColumnKeys.categoryNumber,
            parentIdColumn: programCatalogCategoriesGridColumnKeys.parentCategoryNumber,
            parentNameColumn: programCatalogCategoriesGridColumnKeys.parentCategoryName,
        },
        data: true,
    });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "catalog-category-sidenav-panel",
    });

    const onRowAction = (action) => {
        const { name, dataItem } = action;

        const data = mapGridRowToObject(programCatalogCategoriesGridColumnKeys, dataItem);

        switch (name) {
            case "edit":
                handleOpenSidePanel(
                    <Form dataItem={data} onClose={handleCloseSidePanel} programNumber={programNumber} gridRefresh={onRefresh} />
                );
                break;

            case "add":
                handleOpenSidePanel(
                    <Form dataItem={data} onClose={handleCloseSidePanel} programNumber={programNumber} gridRefresh={onRefresh} addChild />
                );
                break;

            case "delete":
                dispatch(
                    modalOpen({
                        modalType: "CONFIRM",
                        modalProps: {
                            title: "Delete Category",
                            modalIcon: "delete-trash-empty",
                            className: "modal-sm",
                            overlayClassName: "modal-styled",
                            content: (
                                <p>
                                    Would you like to delete category <strong>{data.category}</strong>?
                                </p>
                            ),
                            footerContentCenter: true,
                            onConfirm: () => {
                                dispatch(
                                    deleteResource({
                                        resourceName: "programCatalogCategories",
                                        resourceId: data.categoryNumber,
                                        path: {
                                            programNumber,
                                        },
                                        onSuccess: () => {
                                            onRefresh();
                                        },
                                    })
                                );
                            },
                        },
                    })
                );
                break;

            default:
                break;
        }
    };

    if (isLoadingConfig) {
        return <WaitIcon />;
    }

    return (
        <DataGrid
            name={dataGridId}
            config={gridConfig}
            customRowActions={rowActions}
            onRowAction={onRowAction}
            notExpandableRowIcon="catalog"
        />
    );
});

export default Grid;
