import React, { useCallback, useMemo, memo } from "react";

import { useResource } from "../../../../../../../../store/resources/useResource";

import WaitIcon from "../../../../../../WaitIcon";
import JsonSchemaForm from "../../../../../../Form/JsonSchema/JsonSchemaForm";

export const DefaultForm = memo(({ attributes, content, fields, stepAttributeTypesGetResourceOptions, onChange, onCloseModal }) => {
    const [workflowStepAttributeTypes = [], isLoadingWorkflowStepAttributeTypes] = useResource(stepAttributeTypesGetResourceOptions);

    const getAttribute = useCallback(
        ({ number = null, code = null }) => {
            if (number) {
                return workflowStepAttributeTypes.find((i) => i.number === number);
            } else if (code) {
                return workflowStepAttributeTypes.find((i) => i.code === code);
            }
        },
        [workflowStepAttributeTypes]
    );

    const getProperties = useCallback(
        (fields) => {
            return fields.reduce(
                (i, j) => ({
                    ...i,
                    [j]: {
                        title: getAttribute({ code: j }).name,
                        type: "string",
                    },
                }),
                {}
            );
        },
        [getAttribute]
    );

    const getInitialValues = useCallback(() => {
        const initialFieldsValues = fields.reduce(
            (acc, { shortName }) => {
                acc[shortName] = "";

                return acc;
            },
            { content }
        );

        if (attributes) {
            return attributes.reduce((acc, { typeNumber, value }) => {
                const attribute = getAttribute({ number: typeNumber });

                if (attribute.code in initialFieldsValues && value) {
                    acc[attribute.code] = value;
                }

                return acc;
            }, initialFieldsValues);
        }

        return initialFieldsValues;
    }, [attributes, content, fields, getAttribute]);

    const schema = useMemo(
        () => ({
            type: "object",
            properties: {
                ...getProperties(fields),
                content: {
                    title: "Content",
                    type: "string",
                },
            },
        }),
        [fields, getProperties]
    );

    const uiSchema = {
        classNames: "inline-form columns-2",
        content: {
            classNames: "workflow-step-types-widget__form-field workflow-step-types-widget__form-field-textarea fill-width",
            "ui:widget": "HtmlEditorWidget",
        },
    };

    const initialValues = useMemo(() => {
        return getInitialValues();
    }, [getInitialValues]);

    const handleClickSave = useCallback(
        (formData) => {
            const { content } = formData;

            const data = {
                content,
                attributes: [],
            };

            for (const key in formData) {
                if (formData.hasOwnProperty(key) && key !== "content") {
                    const attribute = getAttribute({ code: key });

                    data.attributes.push({
                        typeNumber: attribute.number,
                        name: attribute.name,
                        value: formData[key],
                    });
                }
            }

            onChange(data);
            onCloseModal();
        },
        [getAttribute, onChange, onCloseModal]
    );

    if (isLoadingWorkflowStepAttributeTypes) {
        return <WaitIcon />;
    }

    return (
        <JsonSchemaForm
            className="workflow-step-types-widget__form"
            schema={schema}
            uiSchema={uiSchema}
            initialValues={initialValues}
            noValidate
            noReset
            withCancel
            centeredFooter
            submitText="Save"
            onCancel={onCloseModal}
            onSubmit={handleClickSave}
        />
    );
});
