import { isEmpty } from "lodash";

export const calculationTypes = {
    catalog: "EQUIPMENT_ATTR",
    event: "EVENT_ATTR",
    program: "APPLICATION_ITEM",
    special: "APPLICATION",
};

export const calculationsGridCustomRowActions = [
    {
        name: "update",
        icon: "edit-empty",
        title: "Edit Calculation",
    },
    {
        name: "revisions",
        icon: "assignment",
        title: "View Calculation Revisions",
    },
    {
        name: "associations",
        icon: "hub-empty",
        title: "View Catalog/Calculation Associations",
    },
];

export const programCalculationsGridCustomRowActions = [
    {
        name: "update",
        icon: "edit-empty",
        title: "Edit Calculation",
    },
    {
        name: "revisions",
        icon: "assignment",
        title: "View Calculation Revisions",
    },
];

export const associationsGridCustomRowActions = [
    {
        name: "delete",
        icon: "delete-trash-empty",
        title: "Remove Association",
    },
];

export const isProgramCalculation = ({ calculationType }) => {
    return ![calculationTypes.catalog, calculationTypes.event].includes(calculationType);
};

export const getTargetTableId = (calculationType, targetAttrId) => {
    let targetTableId = null;

    switch (calculationType) {
        case calculationTypes.catalog:
            targetTableId = 340;
            break;

        case calculationTypes.event:
            targetTableId = 403;
            break;

        case calculationTypes.program:
            targetTableId = [1, 2, 3].includes(Number(targetAttrId)) ? 495 : 341;
            break;

        default:
            break;
    }

    return targetTableId;
};

export const transformTestInputs = (data) => {
    return (data || []).filter((i) => !isEmpty(i.item));
};
