import React, { useCallback, memo } from "react";

import { getInvoiceDocumentsGridId } from "./utils";
import { exportDatagridToCSV } from "../../../../../utils/CSV";
import { refreshInvoiceDocumentsGrid, refreshContractDetailsInvoiceDocumentsGrid } from "../../../../../../store/dataGrid/refreshGrid";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import Controls from "./Controls";
import Grid from "./Grid";
import Form from "./Form";

import "./style.scss";

const InvoicesPanel = memo(({ panel }) => {
    const utilityNumber = panel.data.utilityNumber;
    const contractNumber = panel.data.contractNumber;

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "contract-invoices-sidenav-panel",
    });

    const handleRefresh = useCallback(() => {
        refreshInvoiceDocumentsGrid({ contractNumber });
        refreshContractDetailsInvoiceDocumentsGrid({ contractNumber });
    }, [contractNumber]);

    const handleCreateInvoice = useCallback(() => {
        handleOpenSidePanel(
            <Form
                utilityNumber={utilityNumber}
                contractNumber={contractNumber}
                gridRefresh={handleRefresh}
                onClose={handleCloseSidePanel}
            />
        );
    }, [utilityNumber, contractNumber, handleRefresh, handleOpenSidePanel, handleCloseSidePanel]);

    const handleExportCsv = useCallback(() => {
        exportDatagridToCSV({
            dataGridId: getInvoiceDocumentsGridId({ contractNumber }),
            fileName: "contract_invoices",
            fileNamePostfix: contractNumber,
        });
    }, [contractNumber]);

    return (
        <div className="panel contract-invoices flex-column fill-height">
            <PanelHeaderLarge title={panel.name} />
            <Controls onAdd={handleCreateInvoice} onExportCsv={handleExportCsv} />
            <Grid utilityNumber={utilityNumber} contractNumber={contractNumber} onRefresh={handleRefresh} />
        </div>
    );
});

export default InvoicesPanel;
