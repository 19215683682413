import React, { memo } from "react";

import JsonSchemaForm from "../../../../../Form/JsonSchema/JsonSchemaForm";

const FastTagUploadImage = memo(({ onSubmit, onClose }) => {
    const disabled = false;
    const isUploading = false;

    const schema = {
        type: "object",
        required: ["file"],
        properties: {
            file: {
                type: "array",
                title: "Upload File",
                items: {
                    type: "string",
                    format: "data-url",
                },
            },
        },
    };

    const uiSchema = {
        file: {
            "ui:widget": "DropZoneWidget",
            "ui:options": {
                multiple: false,
            },
        },
    };

    const dialogMessage = (
        <p>
            Browse and select a file to upload. <br />
            This might take a few moments... Please be patient!
        </p>
    );

    return (
        <div>
            {dialogMessage}
            <JsonSchemaForm
                schema={schema}
                uiSchema={uiSchema}
                onSubmit={onSubmit}
                onCancel={() => onClose()}
                submitText={isUploading ? "Uploading..." : "Upload"}
                withCancel
                noReset
                disabled={isUploading || disabled}
                centeredFooter
            />
        </div>
    );
});

export default FastTagUploadImage;
