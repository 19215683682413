import React from "react";
import SchemaField from "./SchemaField";
import ClassNames from "classnames";
import { get } from "lodash";

import { getElementType, isElementFixedWidth } from "../../../../../store/formBuilder/selectors";

import IconWrap from "../../../Icons";

const FormSchemaField = function (props) {
    const elementType = getElementType(props);
    const classNames = props.uiSchema && props.uiSchema.classNames;
    const isDisabled = get(props, "uiSchema.ui:disabled", null);
    const isAdministrative = Number(get(props, "uiSchema.af:fieldGroup", 0)) === 42;
    const isHiddenAttribute = get(props, "uiSchema.ui:hiddenAttribute", false);

    const containerClassNames =
        classNames &&
        classNames
            .split(" ")
            .map((c) => c + "-container")
            .join(" ");

    const className = ClassNames(`schema-container ${elementType}-container`, containerClassNames, {
        "fill-width": !isElementFixedWidth(props.uiSchema),
        "field-hidden": isDisabled,
        "field-administrative": isAdministrative,
    });

    return (
        <div className={className}>
            {isAdministrative && (
                <IconWrap icon="administrative-field-icon eye-visibility-empty" iconWrapCombined title="Administrative Field" />
            )}
            {isHiddenAttribute && <IconWrap icon="visibility-off-empty" title="Hidden Attribute" />}
            <SchemaField {...props} />
        </div>
    );
};

export default FormSchemaField;
