import React, { memo } from "react";

import ActionLabel from "../../../../Label/ActionLabel";
import IconWrap from "../../../../Icons";
import CustomList, { renderCustomFooter } from "../../../../List/CustomList";
import useFocusClasses from "../../../../../utils/useFocusClasses";
import NothingFoundBlock from "../../../../NothingFoundBlock";

const CategoriesList = memo(({ items, onSelect }) => {
    const notFoundInfo = () => {
        if (items.length === 0) {
            return (
                <div className="empty-sidebar-content">
                    <NothingFoundBlock nothingFoundBlockSmall icon="equipment" title="There is no equipment for this project" />
                </div>
            );
        }

        return null;
    };

    const onKeyDown = (item) => (event) => {
        if (event.key === "Enter") {
            onSelect(item);
        }
    };

    const isTabable = true;
    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled: !isTabable,
    });

    const headers = {
        categories: "Category",
        quantity: "Quantity",
    };

    const renderHeader = (headers) => (
        <>
            <div className="header">{headers.categories}</div>
            <div className="header category-count">{headers.quantity}</div>
        </>
    );

    const renderItem = (item, index) => {
        return (
            <div
                key={index}
                className="list-item-row"
                tabIndex={isTabable ? "0" : "-1"}
                onFocus={onFocusClassesFocus}
                onBlur={onFocusClassesBlur}
                onKeyDown={onKeyDown(item)}
                onClick={() => onSelect(item)}
            >
                <div className="item-value">
                    <ActionLabel className="sidebar-list-row-item category-name flex-one">{item.name}</ActionLabel>
                </div>
                <div className="item-value category-count flex-row align-center">
                    <span className="flex-one">{item.count}</span>
                    <IconWrap icon="shevron-small-right" />
                </div>
            </div>
        );
    };

    return (
        <div className={"categories-list sidebar-list flex-column" + (items.length === 0 ? " no-data" : "")}>
            {items.length > 0 && (
                <div className="sidebar-list-body flex-column">
                    <CustomList
                        limit={6}
                        items={items}
                        headers={headers}
                        searchFilters={{
                            name: "",
                        }}
                        searchPlaceholder="Search categories"
                        renderHeaders={renderHeader}
                        renderItem={renderItem}
                        renderFooter={items.length > 6 && renderCustomFooter}
                    />
                </div>
            )}
            {notFoundInfo()}
        </div>
    );
});

export default CategoriesList;
