import React, { useRef, useState, useCallback, memo, useEffect } from "react";
import { omit } from "lodash";

import { useCustomer } from "../../../../../../store/resources/useResource";
import { pickInitialValues, isEmailAddress, submitResource } from "../../../../../utils/form";
import { getCustomerResourceParams } from "../../../../../../store/configureResources";
import { submitByRef } from "../../../../../utils/form";

import WaitIcon from "../../../../WaitIcon";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import Button from "../../../../Button";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";

const Form = memo(({ utilityNumber, customerNumber, onClose, sidePanel }) => {
    const formRef = useRef();

    const [isSubmitting, setSubmitting] = useState(false);

    const [resource, isLoading] = useCustomer({
        utilityNumber,
        customerNumber,
    });

    const titleIcon = "edit-empty";
    const titleText = "Edit Customer Details";
    const submitText = isSubmitting ? "Saving..." : "Save";

    const schema = {
        type: "object",
        required: ["firstName", "lastName", "accountNumber", "status"],
        properties: {
            firstName: {
                type: "string",
                title: "First Name",
            },
            lastName: {
                type: "string",
                title: "Last Name",
            },
            accountNumber: {
                type: "string",
                title: "Account Number",
            },
            status: {
                type: "string",
                title: "Status",
                anyOf: [
                    {
                        title: "Disabled",
                        enum: ["disabled"],
                    },
                    {
                        title: "Active",
                        enum: ["active"],
                    },
                ],
            },
            email: {
                type: "string",
                title: "Email",
            },
        },
    };

    const uiSchema = {
        status: {
            "ui:options": {
                placeholder: "-- SELECT --",
            },
        },
    };

    const initialValues = pickInitialValues(resource);

    const onValidate = (formData, errors) => {
        if (formData.email && !isEmailAddress(formData.email)) {
            errors.email.addError("Email address must be valid email address");
        }

        return errors;
    };

    const handleSubmit = () => {
        submitByRef(formRef);
    };

    const handleSave = useCallback(
        (formData) => {
            const resourceId = customerNumber;
            const resourceParams = getCustomerResourceParams({
                utilityNumber,
                customerNumber,
            });

            const body = {
                ...omit(formData, ["passwordSection"]),
            };

            submitResource({
                resourceParams,
                resourceId,
                body,
                setSubmitting,
                onComplete: () => {
                    sidePanel.close();
                },
            });
        },
        [utilityNumber, customerNumber, sidePanel]
    );

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    if (isLoading) {
        return <WaitIcon />;
    }

    return (
        <SideNavContent className={"edit-customer-details-form"}>
            <SideNavHeader title={titleText} leadBlockIcon={titleIcon} smallHeader onClose={onClose} />
            <SideNavBody>
                {isLoading ? (
                    <WaitIcon />
                ) : (
                    <JsonSchemaForm
                        formRef={formRef}
                        schema={schema}
                        uiSchema={uiSchema}
                        initialValues={initialValues}
                        validate={onValidate}
                        onSubmit={handleSave}
                        disabled={isSubmitting}
                        noReset
                        noCancel
                        noSubmit
                    />
                )}
            </SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button primary disabled={isSubmitting} onClick={handleSubmit}>
                    {submitText}
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default Form;
