import cn from "classnames";

import { store } from "../../store/configureStore";
import { setUserSettings, isAuthenticated, getUser } from "./user";
import * as actionTypes from "../../store/actionTypes";

let isTimeAdjustEnabled = null;

export const LAYOUT_FULL_SCREEN = "layout-full-screen";
export const LAYOUT_WITH_BORDERS = "layout-with-borders";

export const DEFAULT_THEME = "default";
export const DEFAULT_THEME_SIGHTLINE = "sightLine";
export const THEME_SIGHTLINE_HC = "sightLineHighContrast";
export const DEFAULT_LAYOUT = LAYOUT_WITH_BORDERS;

export const availableUserFontSizes = {
    Normal: { name: "Normal", className: "font-size-normal" },
    Large: { name: "Large", className: "font-size-large" },
};

export const DEFAULT_FONT_SIZE = availableUserFontSizes.Normal.name;

export const availableUserThemeModes = {
    Normal: { name: "Normal", className: "theme-mode-normal" },
    Light: { name: "Light", className: "theme-mode-light" },
    Dark: { name: "Dark", className: "theme-mode-dark" },
};

export const DEFAULT_THEME_MODE = availableUserThemeModes.Normal.name;

export const availableUserThemes = [
    {
        theme: "sightLine",
        name: "Sightline",
        className: "theme-sightline theme-mode-light",
        isActive: false,
    },
    {
        theme: "sightLineHighContrast",
        name: "Sightline (HC)",
        className: "theme-sightlineHighContrast theme-mode-light hc-theme",
        isActive: false,
    },
    {
        theme: "default",
        name: "Default",
        className: "theme-default",
        isActive: false,
    },
    {
        theme: "blueSky",
        name: "Blue Sky",
        className: "theme-blueSky",
        isActive: false,
    },
    {
        theme: "darkSky",
        name: "Dark Sky",
        className: "theme-darkSky",
        isActive: false,
    },
    {
        theme: "lightSeaGreen",
        name: "Light Sea Green",
        className: "theme-lightSeaGreen",
        isActive: false,
    },
    {
        theme: "valentine",
        name: "Valentine",
        className: "theme-valentine",
        isActive: false,
    },
    {
        theme: "violets",
        name: "Violets",
        className: "theme-violets",
        isActive: false,
    },
    {
        theme: "olive",
        name: "Olive",
        className: "theme-olive",
        isActive: false,
    },
    {
        theme: "earth",
        name: "Earth",
        className: "theme-earth",
        isActive: false,
    },
    {
        theme: "sunset",
        name: "Sunset",
        className: "theme-sunset",
        isActive: false,
    },
    {
        theme: "nature",
        name: "Nature",
        className: "theme-nature",
        isActive: false,
    },
    { theme: "dusk", name: "Dusk", className: "theme-dusk", isActive: false },
    {
        theme: "northemLights",
        name: "Northem Lights",
        className: "theme-northemLights",
        isActive: false,
    },
    {
        theme: "eveningShades",
        name: "Evening Shades",
        className: "theme-eveningShades",
        isActive: false,
    },
];

export const getUserTheme = ({ isUserAuthenticated }) => {
    const theme = getTheme();

    if (isUserAuthenticated) {
        return theme;
    } else {
        return theme === DEFAULT_THEME_SIGHTLINE || theme === THEME_SIGHTLINE_HC ? DEFAULT_THEME_SIGHTLINE : DEFAULT_THEME;
    }
};

export const getUserThemes = () => {
    const state = store.getState();
    const user = state?.user;

    if (user && user.userNumber) {
        const themes = state?.resources?.userThemes?.itemsById[user.userNumber];

        if (themes) {
            return availableUserThemes.filter((i) => themes.includes(i.theme));
        }
    }

    return availableUserThemes.filter(
        (i) => i.theme === DEFAULT_THEME || i.theme === DEFAULT_THEME_SIGHTLINE || i.theme === THEME_SIGHTLINE_HC
    );
};

const getDefaultTheme = () => {
    let theme;

    const themes = getUserThemes();
    const stored = localStorage.getItem("theme");

    if (stored) {
        theme = themes.find((i) => i.theme === stored);
    }

    if (!theme) {
        theme = themes.find((i) => i.theme === DEFAULT_THEME);
    }

    if (!theme) {
        theme = themes.find((i) => i.theme === DEFAULT_THEME_SIGHTLINE);
    }

    if (!theme) {
        theme = themes.find((i) => i.theme === THEME_SIGHTLINE_HC);
    }

    return theme ? theme.theme : DEFAULT_THEME;
};

const getTheme = (themeName) => {
    let theme = getDefaultTheme();
    let themes = getUserThemes();

    if (themeName) {
        const _theme = themes.find((i) => i.theme === themeName);

        if (_theme) {
            theme = _theme.theme;
        }
    } else {
        const state = store.getState();
        const settings = state?.user?.settings;

        if (settings && settings.theme) {
            theme = getTheme(settings.theme);
        } else {
            const stored = localStorage.getItem("theme");

            if (stored) {
                theme = getTheme(stored);
            }
        }
    }

    return theme;
};

const getDefaultThemeMode = () => {
    let mode;

    const stored = localStorage.getItem("themeMode");

    if (stored) {
        mode = availableUserThemeModes[stored];
    }

    return mode ?? availableUserThemeModes[DEFAULT_THEME_MODE];
};

const getThemeMode = (themeMode) => {
    return availableUserThemeModes[themeMode] ?? getDefaultThemeMode();
};

const getDefaultFontSize = () => {
    let size;

    const stored = localStorage.getItem("fontSize");

    if (stored) {
        size = availableUserFontSizes[stored];
    }

    return size ?? availableUserFontSizes[DEFAULT_FONT_SIZE];
};

const getFontSize = (fontSize) => {
    return availableUserFontSizes[fontSize] ?? getDefaultFontSize();
};

export const getConfirmCloseAllTabs = () => {
    const state = store.getState();
    const confirmCloseAllTabs = state?.user?.settings?.confirmCloseAllTabs;

    return confirmCloseAllTabs ? true : false;
};

export const isAdjustTimeForTimezoneEnabled = () => {
    if (isTimeAdjustEnabled === null) {
        const user = getUser();
        isTimeAdjustEnabled = user?.settings?.adjustTimeForTimezone ?? false;
    }

    return isTimeAdjustEnabled;
};

const getLayout = (layoutName) => {
    let layout = DEFAULT_LAYOUT;

    if (layoutName) {
        layout = layoutName;
    } else {
        const state = store.getState();
        const settings = state?.user?.settings;

        if (settings && settings.layout) {
            layout = getLayout(settings.layout);
        }
    }

    return layout;
};

const getThemeClassName = (themeName) => {
    const themes = getUserThemes();

    const theme = themes.find((i) => i.theme === themeName) || themes[0];

    return theme.className;
};

const getLayoutClassName = (layoutName) => {
    return layoutName;
};

const setClient = ({ theme, themeMode, fontSize, layout, confirmCloseAllTabs, adjustTimeForTimezone }) => {
    const state = store.getState();
    const user = state?.user;

    const settings = user?.settings ?? {};
    confirmCloseAllTabs = confirmCloseAllTabs ?? true ? true : false;
    adjustTimeForTimezone = adjustTimeForTimezone ?? true ? true : false;
    isTimeAdjustEnabled = adjustTimeForTimezone;

    if (
        isAuthenticated(user) &&
        (settings.theme !== theme ||
            settings.themeMode !== themeMode.name ||
            settings.fontSize !== fontSize.name ||
            settings.layout !== layout ||
            settings.confirmCloseAllTabs !== confirmCloseAllTabs ||
            settings.adjustTimeForTimezone !== adjustTimeForTimezone)
    ) {
        setUserSettings({ theme, themeMode: themeMode.name, fontSize: fontSize.name, layout, confirmCloseAllTabs, adjustTimeForTimezone });

        store.dispatch({
            type: actionTypes.USER_SAVE,
            user: {
                ...user,
                settings: {
                    theme,
                    themeMode: themeMode.name,
                    fontSize: fontSize.name,
                    layout,
                    confirmCloseAllTabs,
                    adjustTimeForTimezone,
                },
            },
        });
    }

    // store locally

    localStorage.setItem("theme", theme);
    localStorage.setItem("themeMode", themeMode.name);
    localStorage.setItem("fontSize", fontSize.name);

    // apply UI

    document.body.className = cn(getThemeClassName(theme), themeMode.className, fontSize.className, getLayoutClassName(layout));
};

export const setSettings = (settings) => {
    setClient({
        theme: getTheme(settings?.theme),
        themeMode: getThemeMode(settings?.themeMode),
        fontSize: getFontSize(settings?.fontSize),
        layout: getLayout(settings?.layout),
        confirmCloseAllTabs: settings?.confirmCloseAllTabs,
        adjustTimeForTimezone: settings?.adjustTimeForTimezone,
    });
};

export const setSettingsTheme = (themeName) => {
    setClient({
        theme: getTheme(themeName),
        themeMode: getThemeMode(),
        fontSize: getFontSize(),
        layout: getLayout(),
        confirmCloseAllTabs: getConfirmCloseAllTabs(),
        adjustTimeForTimezone: isAdjustTimeForTimezoneEnabled(),
    });
};

export const setSettingsThemeMode = (themeMode) => {
    setClient({
        theme: getTheme(),
        themeMode: themeMode,
        fontSize: getFontSize(),
        layout: getLayout(),
        confirmCloseAllTabs: getConfirmCloseAllTabs(),
        adjustTimeForTimezone: isAdjustTimeForTimezoneEnabled(),
    });
};

export const setSettingsFontSize = (fontSize) => {
    setClient({
        theme: getTheme(),
        themeMode: getThemeMode(),
        fontSize: fontSize,
        layout: getLayout(),
        confirmCloseAllTabs: getConfirmCloseAllTabs(),
        adjustTimeForTimezone: isAdjustTimeForTimezoneEnabled(),
    });
};

export const setSettingsConfirmCloseAllTabs = (confirmCloseAllTabs) => {
    setClient({
        theme: getTheme(),
        themeMode: getThemeMode(),
        fontSize: getFontSize(),
        layout: getLayout(),
        confirmCloseAllTabs: confirmCloseAllTabs,
        adjustTimeForTimezone: isAdjustTimeForTimezoneEnabled(),
    });
};
export const setSettingsToggleTimezone = (adjustTimeForTimezone) => {
    setClient({
        theme: getTheme(),
        themeMode: getThemeMode(),
        fontSize: getFontSize(),
        layout: getLayout(),
        confirmCloseAllTabs: getConfirmCloseAllTabs(),
        adjustTimeForTimezone: adjustTimeForTimezone,
    });
};

export const setSettingsLayout = (layoutName) => {
    setClient({
        theme: getTheme(),
        themeMode: getThemeMode(),
        fontSize: getFontSize(),
        layout: getLayout(layoutName),
        confirmCloseAllTabs: getConfirmCloseAllTabs(),
        adjustTimeForTimezone: isAdjustTimeForTimezoneEnabled(),
    });
};
