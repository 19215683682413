import React from "react";

import withDataGrid from "../../../ui/DataGrid/withDataGrid.js";
import DataGrid from "../../../ui/DataGrid";

const ProductAttributesGrid = (props) => {
    const { gridId, dataGridConfig } = props;

    return <DataGrid name={gridId} config={dataGridConfig} customRowActions />;
};

export default withDataGrid("productAttributes", ProductAttributesGrid);
