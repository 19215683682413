import React from "react";
import { PropertyListItemTitle } from "../PropertyList/PropertyListItemTitle";
import { PropertyList } from "../PropertyList";
import sassVariables from "assets/sass/_export.module.scss";
import { PropertyName, ShadowBoxSize } from "../../types";
import { isNil } from "lodash";
import { PropertyListItem } from "../Property/PropertyListItem";

export const DefaultShadowType = "";
export const DefaultShadowSize = ShadowBoxSize.SM;
export const DefaultShadowColor = sassVariables.colorBlack;
export const DefaultShadowOpacity = "20";

export const ShadowProperty = (props) => {
    const { title, propertiesGroup = [], value = [], nestingLevel, onChange } = props;
    const isExpanded = Object.values(value).some((v) => !isNil(v));

    const onToogleShadow = () => {
        if (isExpanded) {
            const extraProperties = [
                {
                    id: PropertyName.ShadowType,
                    value: undefined,
                },
                {
                    id: PropertyName.ShadowSize,
                    value: undefined,
                },
                {
                    id: PropertyName.ShadowColor,
                    value: undefined,
                },
                {
                    id: PropertyName.ShadowColorOpacity,
                    value: undefined,
                },
            ];
            onChange(PropertyName.ShadowType, undefined, extraProperties);
        } else {
            const extraProperties = [
                {
                    id: PropertyName.ShadowType,
                    value: DefaultShadowType,
                },
                {
                    id: PropertyName.ShadowSize,
                    value: DefaultShadowSize,
                },
                {
                    id: PropertyName.ShadowColor,
                    value: DefaultShadowColor,
                },
                {
                    id: PropertyName.ShadowColorOpacity,
                    value: DefaultShadowOpacity,
                },
            ];
            onChange(PropertyName.ShadowType, DefaultShadowType, extraProperties);
        }
    };

    if (props.hidden) {
        return null;
    }

    return (
        <PropertyListItem className="property-list-item--prop-group property-list-item--shadow">
            <PropertyListItemTitle
                title={title}
                toggleTooltip={!isExpanded ? "Switch ON" : "Switch OFF"}
                onToggle={onToogleShadow}
                toggleValue={!isExpanded}
            />
            <PropertyList
                items={propertiesGroup}
                nestingLevel={nestingLevel + 1}
                config={value}
                onChange={onChange}
                isExpanded={isExpanded}
            />
        </PropertyListItem>
    );
};
