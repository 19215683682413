import React, { useState, memo, useMemo } from "react";

import { useResource } from "../../../../../store/resources/useResource";

import WaitIcon from "../../../WaitIcon";
import SideNavBody from "../../../SideNav/SideNavBody";
import SideNavContent from "../../../SideNav/SideNavContent";
import CalculationEditForm from "./CalculationEditForm";
import CalculationAssociations from "./CalculationAssociations";
import CalculationRevisionsPanel from "./CalculationRevisionsPanel";

const UpdateCalculationPanel = memo(({ utilityNumber, programNumber, rowData, onClose, sidePanel, disabled }) => {
    const [calculation, calculationIsLoading] = useResource({
        resourceName: "calculations",
        resourceId: rowData.calculationNumber,
        query: {
            calculationRevisionNumber: rowData.calculationRevisionNumber,
        },
        forced: true,
    });

    const [showRevisions, setShowRevisions] = useState(false);
    const [showAssociations, setShowAssociations] = useState(false);

    const nestedSidePanel = useMemo(
        () => ({
            close: onClose,
            success: onClose,
            setForm: () => {},
        }),
        [onClose]
    );

    if (calculationIsLoading) {
        return <WaitIcon />;
    }

    if (showRevisions) {
        return (
            <CalculationRevisionsPanel
                disabled={disabled}
                programNumber={programNumber}
                utilityNumber={utilityNumber}
                rowData={rowData}
                onClose={() => setShowRevisions(false)}
                sidePanel={nestedSidePanel}
            />
        );
    }

    if (showAssociations) {
        return (
            <CalculationAssociations
                disabled={disabled}
                programNumber={programNumber}
                utilityNumber={utilityNumber}
                rowData={rowData}
                onClose={() => setShowAssociations(false)}
                sidePanel={nestedSidePanel}
            />
        );
    }

    return (
        <SideNavContent rowLayout>
            <SideNavBody rowLayout noPadding className="update-calculation-panel">
                {calculation ? (
                    <CalculationEditForm
                        disabled={disabled}
                        utilityNumber={utilityNumber}
                        programNumber={programNumber}
                        calculationType={rowData.calculationType.toUpperCase()}
                        user={rowData.userName}
                        revisionDate={rowData.revisionDate}
                        targetAttrId={calculation.targetAttrId}
                        calculation={{
                            calculationNumber: rowData.calculationNumber,
                            ...calculation,
                        }}
                        onShowRevisions={() => setShowRevisions(true)}
                        onShowAssociations={() => setShowAssociations(true)}
                        onClose={onClose}
                        sidePanel={sidePanel}
                    />
                ) : (
                    <div>Calculation not found</div>
                )}
            </SideNavBody>
        </SideNavContent>
    );
});

export default UpdateCalculationPanel;
