import React, { useState, memo, useContext, useMemo, useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import { isEmpty, uniqBy } from "lodash";

import { updateResource } from "../../../store/resources/actions";
import { getColumnKeys } from "../../views/configureGrids";
import { getData } from "../../../store/dataGrid/actions";
import { useResource } from "../../../store/resources/useResource";
import { WorkCenterContext } from ".";
import { setWorkqueueGridSelectedRows } from "store/workcenter/actions";

import DropDownMultiSelect from "components/ui/Input/DropDownMultiSelect";
import FieldGroup from "../../ui/FieldGroup";
import Button from "../../ui/Button";
import Radio from "../../ui/Input/Radio";
import { WarningMessage } from "components/ui/Message";
import { mapGridRowToObject } from "components/utils/datagrid";

const assignmentActions = {
    add: "ADDASSIGNMENT",
    remove: "REMOVEASSIGNMENT",
};

const ApplicationsFilterControls = memo(({ selectedRows, dataGridId }) => {
    const { instanceId } = useContext(WorkCenterContext);

    const userListsInitialized = useRef();

    const dispatch = useDispatch();

    const labelText =
        selectedRows.length > 0
            ? `${selectedRows.length} Application${selectedRows.length === 1 ? "" : "s"} selected to update`
            : "Select applications to update:";

    const currentUserNumber = useSelector((state) => state.user?.userNumber);
    const dataGridInstanceId = useSelector((state) => state.workcenter[instanceId].workqueueGridId);
    const updatingAssignments = useSelector((state) => state.resources.workcenterItemUsers.isUpdating);

    const [assignment, setAssignment] = useState(null);
    const [addUserNumber, setAddUserNumber] = useState(null);
    const [removeUserNumber, setRemoveUserNumber] = useState(null);

    const [activeUsers = [], isLoadingActiveUsers] = useResource({
        resourceName: "workcenterUserList",
        key: "workcenter-active-user-list",
        query: {
            activeOnly: true,
        },
    });

    const [users = []] = useResource({
        resourceName: "workcenterUserList",
        key: "workcenter-user-list",
    });

    const assignedUserList = useMemo(() => {
        const columnKeys = getColumnKeys(dataGridId);
        return selectedRows.flatMap((r) => (r[columnKeys.assignedUserNumbers] || "").split(";").map((i) => i.trim()));
    }, [dataGridId, selectedRows]);

    const selectedRowsAsObject = useMemo(() => {
        return selectedRows.map((selectedRow) => {
            const selectedRowObject = mapGridRowToObject(getColumnKeys(dataGridId), selectedRow);
            selectedRowObject.assignedUserNumbersArray = selectedRowObject.assignedUserNumbers?.split(";").map((i) => i.trim());
            return selectedRowObject;
        });
    }, [dataGridId, selectedRows]);

    const addUserList = useMemo(
        () =>
            activeUsers
                // Only show user in the list if there is at least one row selected where the user isn't assigned yet
                .filter(
                    (activeUser) =>
                        !!selectedRowsAsObject.some(
                            (selectedRow) =>
                                !selectedRow.assignedUserNumbersArray?.some(
                                    (assignedUserNumber) => activeUser.userNumber === assignedUserNumber
                                )
                        )
                )
                .map((u) => {
                    return {
                        label: u.userName,
                        value: u.userNumber,
                        selected: u.userNumber === addUserNumber,
                    };
                }),
        [activeUsers, addUserNumber, selectedRowsAsObject]
    );

    const removeUserList = useMemo(() => {
        const items = assignedUserList
            // Pick only users available in the users list
            .filter((userNumber) => users.some((u) => u.userNumber === userNumber))
            .map((userNumber) => ({
                label: users.find((u) => u.userNumber === userNumber).userName,
                value: userNumber,
                selected: (removeUserNumber ?? []).some((number) => number === userNumber),
            }));

        return uniqBy(items, "value");
    }, [users, assignedUserList, removeUserNumber]);

    // Add logged in user to assignement dropdowns
    useEffect(() => {
        if (activeUsers.length > 0 && currentUserNumber && !userListsInitialized.current) {
            userListsInitialized.current = true;

            //Add user if it is available in workcenter userlist
            if (activeUsers.find((u) => u.userNumber === currentUserNumber)) {
                setAddUserNumber([currentUserNumber]);
            }
        }
    }, [activeUsers, currentUserNumber]);

    useEffect(() => {
        if (addUserList.length === 0 && !isLoadingActiveUsers) {
            if (assignment === assignmentActions.add) {
                setAssignment(null);
            }

            setAddUserNumber(null);
        }

        if (removeUserList.length === 0) {
            if (assignment === assignmentActions.remove) {
                setAssignment(null);
            }

            setRemoveUserNumber(null);
        }
    }, [assignment, addUserList, removeUserList, isLoadingActiveUsers]);

    const onAssignmentChange = useCallback((event) => {
        setAssignment(event.target.value);
    }, []);

    const onAddUserChange = useCallback((event) => {
        setAddUserNumber(event.target.value);
    }, []);

    const onRemoveUserChange = useCallback((event) => {
        setRemoveUserNumber(event.target.value);
    }, []);

    const onUpdateApps = () => {
        const columnKeys = getColumnKeys(dataGridId);

        const userNumber = assignment === assignmentActions.add ? addUserNumber : removeUserNumber;

        dispatch(
            updateResource({
                resourceName: "workcenterItemUsers",
                body: {
                    tgtUserNumbers: userNumber,
                    action: assignment,
                    items: selectedRows.map((r) => r[columnKeys.appId]),
                },
                onSuccess: () => {
                    dispatch(
                        getData({
                            dataGridId: dataGridInstanceId,
                            onSuccess: () => {
                                dispatch(
                                    setWorkqueueGridSelectedRows({
                                        instanceId,
                                        workqueueGridSelectedRows: [],
                                    })
                                );

                                // Unselect user lists
                                setRemoveUserNumber(null);
                                setAddUserNumber(null);
                            },
                        })
                    );
                },
            })
        );
    };

    const controlsDisabled = selectedRows.length === 0 || updatingAssignments;
    const submitDisabled =
        controlsDisabled ||
        !assignment ||
        (assignment === assignmentActions.add && isEmpty(addUserNumber)) ||
        (assignment === assignmentActions.remove && isEmpty(removeUserNumber));

    const assignmentRadioGroupName = "app-assignment-control";

    const assignedUsersNotAvailable = assignedUserList.length > 0 && removeUserList.length === 0;

    return (
        <>
            <div className="filter-controls-title">
                <span>{labelText}</span>
            </div>
            <FieldGroup
                inline
                className={cn("radio-plus-dropdown", {
                    "radio-disabled": controlsDisabled,
                })}
            >
                <Radio
                    disabled={controlsDisabled || removeUserList.length === 0}
                    label="Clear Assignment"
                    name={assignmentRadioGroupName}
                    checked={assignment === assignmentActions.remove}
                    value={assignmentActions.remove}
                    onChange={onAssignmentChange}
                />
                {assignedUsersNotAvailable ? (
                    <WarningMessage>No Users to Select</WarningMessage>
                ) : (
                    <DropDownMultiSelect
                        inline
                        disabled={controlsDisabled || assignment !== assignmentActions.remove}
                        placeholder="Select user"
                        mobileHeader="Select user"
                        data={removeUserList}
                        value={removeUserNumber}
                        onChange={onRemoveUserChange}
                    />
                )}
            </FieldGroup>
            <div className="flex-row align-center">
                <FieldGroup
                    inline
                    className={cn("radio-plus-dropdown", {
                        "radio-disabled": controlsDisabled,
                    })}
                >
                    <Radio
                        disabled={controlsDisabled || addUserList.length === 0}
                        label="Assign to:"
                        name={assignmentRadioGroupName}
                        checked={assignment === assignmentActions.add}
                        value={assignmentActions.add}
                        onChange={onAssignmentChange}
                    />
                    <DropDownMultiSelect
                        inline
                        disabled={controlsDisabled || assignment !== assignmentActions.add}
                        placeholder="Select user"
                        mobileHeader="Select user"
                        data={addUserList}
                        value={addUserNumber}
                        onChange={onAddUserChange}
                    />
                </FieldGroup>
                <Button disabled={submitDisabled} onClick={onUpdateApps}>
                    {updatingAssignments ? "Updating..." : "Update Apps"}
                </Button>
            </div>
        </>
    );
});

export default ApplicationsFilterControls;
