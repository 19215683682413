import { getData, setFilter } from "store/dataGrid/actions";
import * as actionTypes from "../actionTypes";
import { searchAttrToFilter } from "components/utils/datagrid";
import { programsApplicationsGridColumnKeys } from "components/views/configureGrids";

export const setApplicationStatus =
    ({ programNumber, applicationStatus }) =>
    (dispatch, getState) => {
        if (applicationStatus) {
            const dataGridId = `${programNumber}-applications`;
            const isMounted = getState().dataGrid[dataGridId]?.isMounted ?? false;
            if (isMounted) {
                dispatch(
                    setFilter({
                        dataGridId,
                        filter: searchAttrToFilter(
                            `${programsApplicationsGridColumnKeys.programNumber}=${programNumber}|${programsApplicationsGridColumnKeys.status}=${applicationStatus}`
                        ),
                    })
                );

                dispatch(getData({ dataGridId }));

                return;
            }
        }

        dispatch({
            type: actionTypes.PROGRAMS_SET_APPLICATION_STATUS,
            programNumber,
            applicationStatus,
        });
    };
