import React, { useCallback, useEffect, useContext, useMemo, useRef } from "react";
import { cloneDeep, get, isEqual, isNil, set } from "lodash";
import CustomList from "components/ui/List/CustomList";
import PageEditor from "./PageEditor";
import IconWithLabel from "components/ui/Icons/IconWithLabel";
import { openConfirmModal } from "components/ui/Modal/utils";
import { PageContext, PortalBuilderContext } from "components/ui/PortalBuilder/contexts";
import { PagesListItem } from "./PagesListItem";
import { ContentPagesInfo } from "./ContentPagesInfo";
import { contactUsPageTabs, copyPage, createNewPage, mainPageTab } from "../../utils/page";
import { SECTION_CONTENT } from "../Section";
import { SectionHeader } from "../SectionHeader";
import { PageEditSubtitle } from "./PageEditSubtitle";
import TabList from "components/ui/List/TabList";
import { useErrorContext } from "../../PortalBuilderErrorContextProvider";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";

import "./ContentPages.scss";

const ContentPages = ({ onChange, containsErrors }) => {
    const [config, setPortalBuilderState] = usePortalBuilderState((state) => state.updatedConfig);
    const [isProgramPortalBuilder] = usePortalBuilderState((state) => state.isProgramPortalBuilder);
    const [activeError] = usePortalBuilderState((state) => state.activeError);
    const [editingPageIndex] = usePortalBuilderState((state) => state.editingPageIndex);
    const [lastEditingPageIndex] = usePortalBuilderState((state) => state.lastEditingPageIndex);
    const [activePageUrl] = usePortalBuilderState((state) => state.activePageUrl);
    const listContainer = useRef();

    const contentPages = useMemo(() => {
        return get(config, "content.pages", []);
    }, [config]);

    const { onActivePageChange } = useContext(PortalBuilderContext);

    const addedPagesRef = useRef({});
    const { setSectionPath } = useErrorContext();

    // Update active config path when user navigates in/out of page editor.
    useEffect(() => {
        setSectionPath(isNil(editingPageIndex) ? undefined : `pages[${editingPageIndex}]`);
        return () => {
            setSectionPath(undefined);
        };
    }, [editingPageIndex, setSectionPath]);

    const onBack = useCallback(() => {
        setPortalBuilderState({ activeError: null, editingPageIndex: null });
    }, [setPortalBuilderState]);

    const onTabChange = useCallback(
        (tab) => {
            const updatedConfig = cloneDeep(configRef.current);

            // Take currently editing page index or active error page index if this is error navigation.
            const pageIndex = editingPageIndex ?? activeError?.pageIndex;
            const pageToChange = get(updatedConfig, `content.pages[${pageIndex}]`);
            if (pageToChange && pageToChange["_activeTab"]?.id !== tab?.id) {
                if (tab?.id === "thankYou") {
                    onActivePageChange(config.content.pages[pageIndex].link, { submitted: true });
                } else {
                    onActivePageChange(config.content.pages[pageIndex].link);
                }
                set(updatedConfig, `content.pages[${pageIndex}]`, { ...pageToChange, _activeTab: tab });
                onChange(updatedConfig);
            }
        },
        [activeError?.pageIndex, config.content.pages, editingPageIndex, onActivePageChange, onChange]
    );

    // Navigate to active error page.
    useEffect(() => {
        const activeErrorPage = activeError?.pageIndex;
        if (!isNil(activeErrorPage) && activeErrorPage !== editingPageIndex) {
            setPortalBuilderState({ editingPageIndex: activeErrorPage });
            onActivePageChange(contentPages[activeErrorPage]?.link);
        }

        if (!isNil(activeError)) {
            onTabChange(activeError.activeTab);
        }
    }, [activeError, contentPages, editingPageIndex, onActivePageChange, onTabChange, setPortalBuilderState]);

    // Handle the case when config is reverted while viewing an unsaved page.
    useEffect(() => {
        if (!isNil(editingPageIndex) && isNil(contentPages[editingPageIndex])) {
            onBack();
        }
    }, [contentPages, editingPageIndex, onActivePageChange, onBack]);

    // Switch to last edited page.
    useEffect(() => {
        if (!isNil(lastEditingPageIndex)) {
            setPortalBuilderState({ editingPageIndex: lastEditingPageIndex, lastEditingPageIndex: null });
            const lastActivePage = contentPages[lastEditingPageIndex];
            onActivePageChange(lastActivePage?.link);
        }
    }, [lastEditingPageIndex, contentPages, onActivePageChange, setPortalBuilderState]);

    const onCopyPage = useCallback(
        (item) => {
            let updatedConfig = cloneDeep(config);
            const index = updatedConfig?.content?.pages.length ?? 0;
            const pageConfig = copyPage(isProgramPortalBuilder, updatedConfig?.content?.pages, item);

            set(updatedConfig, `content.pages[${index}]`, pageConfig);
            addedPagesRef.current[index] = pageConfig;

            onChange(updatedConfig);
            listContainer.current.scroll({ top: listContainer.current.scrollHeight, behavior: "smooth" });
        },
        [config, isProgramPortalBuilder, onChange]
    );

    const onEditPage = useCallback(
        (item, index) => {
            setPortalBuilderState({ editingPageIndex: index });
            onActivePageChange(item.link);
        },
        [onActivePageChange, setPortalBuilderState]
    );

    const onPreviewPage = useCallback(
        (item, index) => {
            onActivePageChange(item.link);
        },
        [onActivePageChange]
    );

    const onNewPage = useCallback(() => {
        let updatedConfig = cloneDeep(config);
        const index = updatedConfig?.content?.pages.length ?? 0;
        const pageConfig = createNewPage(isProgramPortalBuilder, updatedConfig?.content?.pages ?? []);

        set(updatedConfig, `content.pages[${index}]`, pageConfig);
        addedPagesRef.current[index] = pageConfig;

        onChange(updatedConfig);
    }, [config, isProgramPortalBuilder, onChange]);

    const onDeletePage = useCallback(
        (item, index) => {
            const message = (
                <>
                    <p>
                        This action will delete the page <strong>{item.title}</strong> and cannot be undone.
                    </p>
                    <p>Are you sure you want to do this?</p>
                </>
            );

            const updatedConfig = cloneDeep(config);

            const addedPage = addedPagesRef.current[index];

            if (!addedPage || !isEqual(addedPage, item)) {
                openConfirmModal({
                    title: "Delete Page",
                    modalIcon: "delete-trash-empty",
                    message,
                    onConfirm: () => {
                        updatedConfig.content.pages.splice(index, 1);
                        onChange(updatedConfig);
                    },
                });
            } else {
                updatedConfig.content.pages.splice(index, 1);
                onChange(updatedConfig);
            }
        },
        [config, onChange]
    );

    const renderItem = (item, index) => {
        return (
            <PagesListItem
                key={index}
                index={index}
                page={item}
                isActive={item.link === activePageUrl}
                isHomePage={item.link === config.settings.programStartPage || (!config.settings.programStartPage && item.link === "/")}
                onPreview={onPreviewPage}
                onCopy={onCopyPage}
                onEdit={onEditPage}
                onDelete={onDeletePage}
            />
        );
    };

    const configRef = useRef(config);
    configRef.current = config;

    const context = useMemo(
        () => ({
            page: { ...contentPages[editingPageIndex], index: editingPageIndex },
        }),
        [contentPages, editingPageIndex]
    );

    const showTabs =
        contentPages[editingPageIndex]?.link === "/contact-us" &&
        (contentPages[editingPageIndex]?.props?.contactUsFormOn || isNil(contentPages[editingPageIndex]?.props?.contactUsFormOn));
    const activeTab = config.content.pages[editingPageIndex]?.["_activeTab"];

    if (!isNil(editingPageIndex)) {
        return (
            <PageContext.Provider value={context}>
                <SectionHeader
                    subtitle={<PageEditSubtitle />}
                    onGoBack={onBack}
                    Icon={SECTION_CONTENT.Icon}
                    title={SECTION_CONTENT.title}
                    containsErrors={containsErrors}
                    underline={!showTabs}
                />
                {showTabs && (
                    <TabList
                        className="content-pages-tabs"
                        items={contactUsPageTabs}
                        tabListGhost
                        activeItem={activeTab ?? mainPageTab}
                        onClick={(tab) => {
                            setPortalBuilderState({ activeError: null });
                            onTabChange(tab);
                        }}
                    />
                )}
                <PageEditor onChange={onChange} />
            </PageContext.Provider>
        );
    }

    return (
        <div className="content-page-list flex-column flex-one-in-column no-scroll">
            <SectionHeader Icon={SECTION_CONTENT.Icon} title={SECTION_CONTENT.title} underline containsErrors={containsErrors} />
            <ContentPagesInfo />
            <div ref={listContainer} className="pages-section flex-one-in-column with-scroll">
                <CustomList items={contentPages} renderItem={renderItem} />
                <IconWithLabel icon="plus" onClick={onNewPage}>
                    Add New Page
                </IconWithLabel>
            </div>
        </div>
    );
};

export default ContentPages;
