import { useCallback, useReducer, useMemo } from "react";

const documentNumbersReducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case "set":
            return payload;
        case "add":
            return [...state, ...payload];
        case "remove":
            return state.filter(({ documentNumber }) => !payload.some((item) => item === documentNumber));
        case "clear":
            return [];
        default:
            throw new Error();
    }
};

export const useDocumentNumbers = () => {
    const [selectedItems, dispatch] = useReducer(documentNumbersReducer, []);
    const set = useCallback((payload) => dispatch({ type: "set", payload }), [dispatch]);
    const add = useCallback((payload) => dispatch({ type: "add", payload }), [dispatch]);
    const remove = useCallback((payload) => dispatch({ type: "remove", payload }), [dispatch]);
    const clear = useCallback(() => dispatch({ type: "clear" }), [dispatch]);
    const actions = useMemo(() => ({ set, add, remove, clear }), [set, add, remove, clear]);

    return [selectedItems, actions];
};
