import React, { memo, useContext } from "react";

import { GridListContext, GridListFilterContext } from ".";
import GridListCell from "./GridListCell";
import Input from "../../Input";
import DatePicker from "../../Input/DatePicker";
import GridListResizeHandler from "./GridListResizeHandler";
import { jsonDateToDate, dateToJson, datePartFromJsonDate } from "../../../../components/utils/date";
import { getDropdownContainer } from "../../../utils/dom";
import Portal from "../../../../components/ui/Portal";

const NUMBER_FILTER_MAX_LENGTH = 9;

const GridListFilterCell = memo(({ column }) => {
    const { onGridAction, columnWidths, popupBoundary } = useContext(GridListContext);
    const { filter, columnFilterContent = {}, onFilterChange } = useContext(GridListFilterContext);

    const filters = filter && filter.filters;
    const columnFilter = (filters || []).filter((item) => item.field === column.key)[0];
    const filterValue = columnFilter ? columnFilter.value : "";

    const onChange = (event, column) => {
        let filters = filter && filter.filters;
        const filterValue = event.target.value;

        if (filterValue) {
            const columnFilter = {
                field: column.key,
                value: filterValue,
                name: column.name,
            };

            let filterChanged = false;
            filters = (filters || []).map((f) => {
                if (f.field === column.key) {
                    filterChanged = true;

                    return columnFilter;
                }

                return f;
            });

            if (!filterChanged) {
                filters = filters.concat([columnFilter]);
            }
        } else {
            filters = (filters || []).filter((f) => f.field !== column.key);
        }

        onFilterChange({
            filter: {
                filters,
                logic: "and",
            },
        });
    };

    const onDateChange = (data, column) => {
        const jsonDate = dateToJson(data.value);
        const value = datePartFromJsonDate(jsonDate);
        onChange({ target: { value } }, column);

        if (data.event.type === "click" || data.event.key === "Enter") {
            setTimeout(() => {
                onGridAction({
                    name: "filter",
                });
            }, 0);
        }
    };

    const onKeyPress = (event, column) => {
        if (event.key === "Enter") {
            onGridAction({
                name: "filter",
            });
        }
    };

    const onEnterChange = (event, column) => {
        onChange(event, column);

        setTimeout(() => {
            onGridAction({
                name: "filter",
            });
        }, 0);
    };

    const onKeyDown = (event, column, value) => {
        if (event.key === "Enter") {
            onDateChange({ value, event }, column);
        }
    };

    let cellContent = null;

    switch (column.datatype) {
        case "date":
        case "datetime":
        case "datetimeWithSeconds":
            cellContent = (
                <DatePicker
                    className="input-table-filter"
                    name={column.name || null}
                    value={jsonDateToDate(filterValue)}
                    onChange={(data) => onDateChange(data, column)}
                    onKeyDown={(event, value) => onKeyDown(event, column, value)}
                    popperPlacement="bottom-end"
                    popperContainer={DatePickerContainer}
                    popperModifiers={[
                        {
                            name: "preventOverflow",
                            options: {
                                boundary: popupBoundary,
                            },
                        },
                    ]}
                />
            );

            break;
        case "number":
            cellContent = (
                <Input
                    maxLength={NUMBER_FILTER_MAX_LENGTH}
                    name={column.name || null}
                    inputTableFilter
                    value={filterValue}
                    onChange={(event) => onChange(event, column)}
                    onKeyPress={(event) => {
                        if (/^(?![*%.])[^*%.\d]+$/.test(event.key)) {
                            onKeyPress(event, column);
                            event.preventDefault();
                        }
                    }}
                    onPaste={(event) => {
                        const str = event.clipboardData.getData("Text");
                        //match all none degit char, but *,%,.
                        const newStr = str.replace(/[^0-9*%.]/g, "").slice(0, NUMBER_FILTER_MAX_LENGTH);
                        onChange({ target: { value: newStr } }, column);
                        event.preventDefault();
                    }}
                />
            );
            break;
        default:
            cellContent = (
                <Input
                    type="text"
                    name={column.name || null}
                    inputTableFilter
                    value={filterValue}
                    onChange={(event) => onChange(event, column)}
                    onKeyPress={(event) => onKeyPress(event, column)}
                />
            );

            break;
    }

    return (
        <GridListCell key={column.key} width={columnWidths[column.key]}>
            {columnFilterContent[column.key]
                ? columnFilterContent[column.key](column, filterValue, onEnterChange, onChange, onKeyPress)
                : cellContent}
            <GridListResizeHandler column={column} />
        </GridListCell>
    );
});

const DatePickerContainer = ({ children }) => {
    const { gridListRef } = useContext(GridListContext);

    return <Portal container={getDropdownContainer({ element: gridListRef.current })}>{children}</Portal>;
};

export default GridListFilterCell;
