import React, { memo } from "react";
import { useSelector } from "react-redux";

import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid";
import { notesGridColumnKeys } from "../../../../../views/configureGrids";
import IconWrap from "../../../../Icons";
import NotesEditForm from "./NotesEditForm";

import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { systemUserRights } from "components/utils/user";
import { useProgramRights } from "store/resources/useResource";
import { mapGridRowToObject } from "components/utils/datagrid";
import NotePreview from "./NotePreview";

import "./NotesPanel.scss";
const NotesGrid = memo(({ gridId, dataGridConfig, applicationNumber, onSaved, programNumber }) => {
    const [programRights = []] = useProgramRights({ programNumber });
    const isEditNotesDisabled = !programRights.includes(systemUserRights.VISIONDSM_ADD_NOTES);

    const user = useSelector((state) => state.user);

    const customRowActions = [
        {
            name: "update",
            icon: "edit-empty",
            title: "Edit",
            // Do not allow editing if note was created by another user
            disabled: (row) => isEditNotesDisabled || row[notesGridColumnKeys.userNumber] !== user.userNumber,
        },
        {
            name: "open-note",
            icon: "eye-visibility-empty",
            title: "Open Note",
            //Hide the eye icon if the user has the ability to edit
            hide: (row) => isEditNotesDisabled || row[notesGridColumnKeys.userNumber] === user.userNumber,
        },
    ];

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({ size: sideNavSize.small });

    const onRowAction = (action) => {
        const { name, dataItem } = action;
        const note = mapGridRowToObject(notesGridColumnKeys, dataItem);
        switch (name) {
            case "update":
                document.activeElement.blur();
                handleOpenSidePanel(
                    <NotesEditForm
                        note={note}
                        applicationNumber={applicationNumber}
                        onSaved={() => {
                            onSaved();
                            handleCloseSidePanel();
                        }}
                        onClose={handleCloseSidePanel}
                    />
                );
                break;

            case "open-note":
                document.activeElement.blur();
                handleOpenSidePanel(
                    <NotesEditForm
                        note={note}
                        applicationNumber={applicationNumber}
                        saveBtnHidden={true}
                        readOnly={true}
                        onClose={handleCloseSidePanel}
                    />
                );
                break;
            default:
                break;
        }
    };

    return (
        <DataGrid
            name={gridId}
            config={dataGridConfig}
            onRowAction={onRowAction}
            customRowActions={customRowActions}
            columnCellContent={{
                [notesGridColumnKeys.security]: (column, row) =>
                    row[notesGridColumnKeys.security].toLowerCase() === "public" ? (
                        <IconWrap title="Public" icon="add-group-filled" />
                    ) : (
                        <IconWrap title="Private" icon="user-identity-person-filled" />
                    ),
                [notesGridColumnKeys.note]: (column, row) => <NotePreview>{row[notesGridColumnKeys.note]}</NotePreview>,
            }}
        />
    );
});

export default withDataGrid("notes", NotesGrid);
