import React, { memo, useEffect, useState, useCallback } from "react";
import cn from "classnames";
import printJS from "print-js";

import { useResource } from "store/resources/useResource";
import WaitIcon from "components/ui/WaitIcon";
import ViewPlaceholder from "components/ui/ViewPlaceholder";
import { ErrorMessage } from "components/ui/Message";
import { useDispatch } from "react-redux";
import { downloadFile } from "store/files/actions";
import TxtViewer from "components/ui/TxtViewer";
import IconWrap from "../../../components/ui/Icons";

import "./style.scss";

const FileView = memo((props) => {
    const { fileNumber, publicPath, hideControls } = props;

    const dispatch = useDispatch();
    const [dataUrl, setDataUrl] = useState();
    const [file, isLoadingFile] = useFile(fileNumber, publicPath);

    const isLoading = props.isLoading ?? isLoadingFile;
    const fileData = props.fileData ?? file;

    const dataType = fileData?.blob.type ?? "";
    const isImage = dataType.startsWith("image");
    const isTxt = dataType === "text/plain";

    // Create object url if fileData available
    useEffect(() => {
        let objUrl = null;
        if (fileData) {
            objUrl = URL.createObjectURL(fileData.blob);
            setDataUrl(objUrl);
        }

        return () => {
            if (objUrl) {
                URL.revokeObjectURL(objUrl);
            }
        };
    }, [fileData]);

    const onDownload = useCallback(() => {
        dispatch(downloadFile({ fileId: fileNumber }));
    }, [fileNumber, dispatch]);

    const onPrint = useCallback(() => {
        printJS({
            printable: dataUrl,
            type: isImage ? "image" : "html",
            base64: true,
        });
    }, [dataUrl, isImage]);

    if (isLoading) {
        return (
            <ViewPlaceholder>
                <WaitIcon />
            </ViewPlaceholder>
        );
    }

    if (!fileData || !dataUrl) {
        return <ErrorMessage spaceAround>Could not load file</ErrorMessage>;
    }

    if (isTxt) {
        return <TxtViewer fileData={fileData} />;
    }

    return (
        <>
            {isImage && !hideControls ? <IconWrap icon="printer-empty" onClick={onPrint} title="Print" /> : null}
            {/* Added key to fix cases when object not updating on file change */}
            <div
                key={dataUrl}
                className={cn("file-viewer-container", {
                    "file-viewer-container--image": isImage,
                })}
            >
                <object className="file-viewer" data={dataUrl} width="100%" height="100%" type={dataType}>
                    <ViewPlaceholder clickableText="Download File" clickableTextIcon="download" onClick={onDownload}>
                        File cannot be displayed.
                    </ViewPlaceholder>
                </object>
            </div>
        </>
    );
});

const useFile = (fileNumber, publicPath) => {
    const [file, setFile] = useState();
    const [isLoadingFile, setIsLoadingFile] = useState(false);

    const [fileByFileNumber, isLoadingFileByFileNumber] = useResource({
        resourceName: "files",
        resourceId: fileNumber,
    });

    useEffect(() => {
        const load = async () => {
            try {
                setIsLoadingFile(true);
                const response = await fetch(publicPath.replace("amerescositelogo", "amerescositelogo123"));

                if (response.status === 200) {
                    const blob = await response.blob();
                    setFile({ blob });
                }
            } catch (error) {
            } finally {
                setIsLoadingFile(false);
            }
        };

        if (publicPath) {
            load();
        }
    }, [publicPath]);

    return fileNumber ? [fileByFileNumber, isLoadingFileByFileNumber] : [file, isLoadingFile];
};

export default FileView;
