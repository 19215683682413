import * as actionTypes from "../actionTypes";
import { setFormData } from "store/forms/actions";
import { batch } from "react-redux";
import { getData } from "store/dataGrid/actions";
import { uploadFile } from "store/files/actions";
import { toast } from "react-toastify";
import { fileType } from "components/utils/fileType";
import { getResourcePromise } from "store/resources/useResource";
import { windowContainerTypes } from "components/utils/window";

// App calculations in progress check interval in ms
export const CALCULATIONS_IN_PROGRESS_CHECK_TIMEOUT = 2000;

// Timeout handlers for setTimeout.
let timeoutHandlers = {};

export const setActiveApplicationForm = (entityNumber, applicationFormId) => (dispatch, getState) => {
    batch(() => {
        dispatch(
            setFormData({
                formId: `app-${entityNumber}`,
                formData: undefined,
            })
        );

        dispatch({
            type: actionTypes.PROJECTS_SET_ACTIVE_APPLICATION_FORM,
            entityNumber,
            applicationFormId,
        });
    });
};

export const setExpandedWorkflowTask = (entityNumber, workflowTaskId) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.PROJECTS_SET_EXPANDED_WORKFLOW_TASK,
        entityNumber,
        workflowTaskId,
    });
};

export const uploadEquipment =
    ({ applicationNumber, fileData, onSuccess }) =>
    (dispatch, getState) => {
        const onUploadSuccess = (action) => {
            const { ResponseStatus, ResponseMessage, ErrorMessage } = action;

            if (ResponseStatus === "success") {
                toast.success(ResponseMessage);
            } else {
                toast.error(ErrorMessage ?? ResponseMessage);
            }

            batch(() => {
                dispatch({
                    type: actionTypes.PROJECTS_SET_EQUIPMENT_UPLOAD_IN_PROGRESS,
                    applicationNumber,
                    uploadInProgress: false,
                });

                dispatch(
                    getData({
                        dataGridId: `${applicationNumber}-equipment-grid`,
                    })
                );
                onSuccess && onSuccess();
            });
        };

        const onUploadError = () => {
            dispatch({
                type: actionTypes.PROJECTS_SET_EQUIPMENT_UPLOAD_IN_PROGRESS,
                applicationNumber,
                uploadInProgress: false,
            });
        };

        dispatch({
            type: actionTypes.PROJECTS_SET_EQUIPMENT_UPLOAD_IN_PROGRESS,
            applicationNumber,
            uploadInProgress: true,
        });

        dispatch(
            uploadFile({
                fileData: {
                    ...fileData,
                    fileTypeId: fileType.supportingDocument,
                    itemFilterId: 149,
                },
                onUploadSuccess,
                onUploadError,
            })
        );
    };
/**
 * Check application calculations status.
 * If status is isRunning, check repeatedly til calculations are done.
 *
 * @param {*} { applicationNumber }
 */
export const checkCalculationStatus =
    ({ applicationNumber }) =>
    async (dispatch, getState) => {
        const state = getState();
        const currentState = state.projects.calculationsInProgress[applicationNumber] ?? false;

        // Do not call backend if application is not in currently active view.
        if (!isAppInActiveView({ state, applicationNumber })) {
            clearTimeout(timeoutHandlers[applicationNumber]);
            timeoutHandlers[applicationNumber] = setTimeout(() => {
                dispatch(checkCalculationStatus({ applicationNumber }));
            }, CALCULATIONS_IN_PROGRESS_CHECK_TIMEOUT);

            return;
        }

        try {
            const result = await getResourcePromise({
                resourceName: "applicationCalculationStatus",
                key: applicationNumber,
                path: {
                    appId: applicationNumber,
                },
            });

            const calculationsInProgress = result?.isRunning ?? false;

            if (currentState !== calculationsInProgress) {
                dispatch({
                    type: actionTypes.PROJECTS_SET_CALCULATIONS_IN_PROGRESS,
                    applicationNumber,
                    calculationsInProgress,
                });
            }

            if (calculationsInProgress) {
                clearTimeout(timeoutHandlers[applicationNumber]);
                timeoutHandlers[applicationNumber] = setTimeout(() => {
                    dispatch(checkCalculationStatus({ applicationNumber }));
                }, CALCULATIONS_IN_PROGRESS_CHECK_TIMEOUT);
            }
        } catch (error) {
            // Stop calling API if there was an error.
            dispatch({
                type: actionTypes.PROJECTS_SET_CALCULATIONS_IN_PROGRESS,
                applicationNumber,
                calculationsInProgress: false,
            });
        }
    };

const isAppInActiveView = ({ state, applicationNumber }) => {
    // Check if active view is project view for this application.
    const activeView = state.window[windowContainerTypes.Root]?.views.find((w) => w.active) ?? {};

    const isAppView = (viewComponent, viewProps) => {
        return viewComponent === "ProjectView" && viewProps?.applicationNumber === applicationNumber;
    };

    return (
        isAppView(activeView.component, activeView.props) ||
        isAppView(activeView.leftViewComponent, activeView.leftViewProps) ||
        isAppView(activeView.rightViewComponent, activeView.rightViewProps)
    );
};
