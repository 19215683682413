import sassVariables from "../../../src/assets/sass/_export.module.scss";

export const isInIframe = () => {
    return Boolean(window.frameElement);
};

export const getFrameDocument = (iframe) => {
    if (!iframe) {
        return null;
    }

    let frameDoc = iframe.document;

    if (iframe.contentWindow) {
        frameDoc = iframe.contentWindow.document;
    }

    return frameDoc;
};

export const resizeHeightToFitContent = (iframe) => {
    const frameDoc = getFrameDocument(iframe);

    if (frameDoc) {
        iframe.height = frameDoc.body.scrollHeight;
    }
};

export const addFontStyle = (iframe) => {
    const frameDoc = getFrameDocument(iframe);

    if (frameDoc) {
        frameDoc.body.style.fontFamily = sassVariables.defaultFontFamily;
        frameDoc.body.style.fontSize = sassVariables.defaultFontSize;
    }
};
