import React from "react";
import { useProgram } from "../../../store/resources/useResource";
import WaitIcon from "../WaitIcon";

const ProgramName = ({ programNumber }) => {
    const [program, isLoading] = useProgram({ programNumber, forced: false });

    if (isLoading) {
        return <WaitIcon />;
    }

    return program ? program.program : null;
};

export default ProgramName;
