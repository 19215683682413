import React, { useState, useCallback, memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { usersGridColumnKeys } from "../../views/configureGrids";
import { exportDatagridToCSV } from "../../utils/CSV";
import IconWithLabel from "../../ui/Icons/IconWithLabel";
import IconWrap from "../../ui/Icons";
import Button from "../../ui/Button";
import Radio from "../../ui/Input/Radio";
import DropDownInput from "../../ui/Input/DropDownInput";
import WindowBodyHeader from "../../ui/WindowBody/WindowBodyHeader";
import UsersGrid from "./UsersGrid";
import { toggleUserManagmentStatusFilter, setUserManagmentClientNumberFilter } from "../../../store/userManagement/actions";
import useMultiPageRowSelect from "store/dataGrid/useMultiPageRowSelect";
import GridSelectedItemsLabel from "components/ui/DataGrid/GridSelectedItemsLabel";
import { hasPermission, systemUserRights } from "components/utils/user";
import { useResource } from "store/resources/useResource";

const ClientsDropDown = (props) => {
    const [clientList, isLoading] = useResource({
        resourceName: "clients",
        key: "clients",
        transform: (data) => data?.clientList ?? [],
    });

    const data = useMemo(
        () => [
            { label: "Any Client", value: "any" },
            ...clientList.map(({ clientNumber, client }) => ({
                label: client,
                value: clientNumber,
            })),
        ],
        [clientList]
    );

    return (
        <DropDownInput
            mobileHeader="Filter by Client"
            data={data}
            value={props.selectedClient}
            onChange={props.onClientFilterChange}
            placeholder={isLoading ? "Loading..." : undefined}
        />
    );
};

const getUserManagmentFilterValue = (filters, columnKey) => {
    if (filters) {
        const filterSpec = filters.find((f) => f.field === columnKey);
        if (filterSpec) {
            return filterSpec.value;
        }
    }

    return null;
};

const DATA_GRID_ID = "users";

const UsersManagement = memo(({ setManagement, hidden }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const user = useSelector((state) => state.user);
    const allowMimic =
        hasPermission(user, systemUserRights.VISIONDSM_ADD_USER) && hasPermission(user, systemUserRights.VISIONDSM_MANAGE_USER_RIGHTS);
    const canAddUser = hasPermission(user, systemUserRights.VISIONDSM_ADD_USER);

    const dispatch = useDispatch();

    const filters = useSelector((state) => get(state, "dataGrid.users.filter.filters", null));
    const grid = useSelector((state) => get(state, "dataGrid.users", null));

    const statusFilterValue = getUserManagmentFilterValue(filters, usersGridColumnKeys.status);
    const clientNumberFilterValue = getUserManagmentFilterValue(filters, usersGridColumnKeys.clientNumber);

    const onClientTypeChange = useCallback(
        (value) => {
            if (grid && !grid.isReading) {
                dispatch(setUserManagmentClientNumberFilter(value));
            }
        },
        [dispatch, grid]
    );
    const toggleStatus = useCallback(
        (status) => () => {
            if (grid && !grid.isReading) {
                dispatch(toggleUserManagmentStatusFilter(status));
            }
        },
        [dispatch, grid]
    );

    const onRowSelect = (selectedRows) => setSelectedRows(selectedRows || []);
    const [onRowSelectChange, onPageChanged, onRowSelectClear] = useMultiPageRowSelect({
        dataGridId: DATA_GRID_ID,
        onRowSelect,
    });
    const showSpecificOptionalText = "Filter";

    const onAdd = () => setManagement({ type: "update", isNew: true, user: {} });
    const bulkAssignment = () =>
        setManagement({
            type: "bulkAssignment",
            users: selectedRows,
            clientNumber: selectedRows[0]?.[usersGridColumnKeys.clientNumber],
        });
    const bulkAssignmentIsAvailable =
        selectedRows.length > 1 &&
        selectedRows.every((user) => user[usersGridColumnKeys.clientNumber] === selectedRows[0][usersGridColumnKeys.clientNumber]);
    const bulkAssignmentIsBlocked = selectedRows.length > 1 && !bulkAssignmentIsAvailable;

    return (
        <div className="users-management flex-column fill-height" hidden={hidden}>
            <WindowBodyHeader withShadow noPopupDetailsOnMobile showSpecificOptionalText={showSpecificOptionalText}>
                <div className="filters flex-row align-center">
                    <div className="block status">
                        <div className="block-label">Filter by Status:</div>
                        <div className="flex-row">
                            <Radio label="Active" checked={statusFilterValue === "active"} onChange={toggleStatus("active")} />
                            <Radio label="Disabled" checked={statusFilterValue === "disabled"} onChange={toggleStatus("disabled")} />
                            <Radio label="Expired" checked={statusFilterValue === "expired"} onChange={toggleStatus("expired")} />
                        </div>
                    </div>
                    <div className="block client">
                        <div className="block-label">Filter by Client:</div>
                        <ClientsDropDown
                            gettingAtAnyTime
                            resourceName="clients"
                            selectedClient={clientNumberFilterValue === null ? "any" : clientNumberFilterValue}
                            onClientFilterChange={(e) => onClientTypeChange(e.target.value === "any" ? null : e.target.value)}
                        />
                    </div>
                </div>
            </WindowBodyHeader>
            <div className="table-block flex-column flex-one no-scroll">
                <div className="main-grid-wrap responsive flex-column fill-height">
                    <div className="data-grid-pre-header flex-row">
                        <div className="bulk-assignment flex-row align-center">
                            {selectedRows.length ? (
                                <GridSelectedItemsLabel
                                    text={
                                        selectedRows.length ? (
                                            <>
                                                {" "}
                                                User
                                                {selectedRows.length > 1 ? "s" : ""} <br /> Selected{" "}
                                            </>
                                        ) : (
                                            ""
                                        )
                                    }
                                    count={selectedRows.length}
                                    onClear={onRowSelectClear}
                                />
                            ) : (
                                <div className="bulk-assignment__label">
                                    Select <br /> Users
                                </div>
                            )}
                            <Button disabled={!bulkAssignmentIsAvailable} primary onClick={bulkAssignment}>
                                Bulk Assignment
                            </Button>
                            <div className={"select-condition" + (bulkAssignmentIsBlocked ? " warning" : "")}>
                                <IconWrap
                                    iconWrapWarning={bulkAssignmentIsBlocked}
                                    iconWrapNeutral={!bulkAssignmentIsBlocked}
                                    iconWrap={bulkAssignmentIsBlocked ? "warning-report-problem-filled" : "info-filled"}
                                />
                                <span>for Bulk Assignment users must be from the same Client</span>
                            </div>
                        </div>
                        <div className="flex-one" />
                        <IconWithLabel
                            withHandMadeIcon
                            onClick={() =>
                                exportDatagridToCSV({
                                    dataGridId: DATA_GRID_ID,
                                    fileName: "users",
                                })
                            }
                        >
                            Export CSV
                        </IconWithLabel>
                        <Button onClick={onAdd} disabled={!canAddUser} icon="add-user-add-identity-add-person-empty" primary>
                            Create New User Account
                        </Button>
                    </div>
                    <div className="flex-one-in-column no-scroll">
                        <UsersGrid
                            gridId={DATA_GRID_ID}
                            onRowSelect={onRowSelectChange}
                            setManagement={setManagement}
                            onPageChanged={onPageChanged}
                            allowMimic={allowMimic}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default UsersManagement;
