import { isNil } from "lodash";

import { store } from "../configureStore";
import * as actionTypes from "../actionTypes";
import { getFriendlyNamesGridGridId } from "../../components/views/configureGrids";
import { getResourcePromise } from "../resources/useResource";
import { getSchemaIds } from "../../components/utils/form";
import { getSelectedElementUiParams, getActiveSectionId, getActiveSection } from "../formBuilder/selectors";
import {
    getScanQueueGridId,
    formatScanQueueGridRows,
    getScanQueueUserGroupsGridId,
} from "../../components/views/ScanQueueManagement/utils";
import { getResource, createResource } from "../resources/actions";
import { getResourceName, getResourceKey } from "../../components/views/UserGroups/utils";
import { getCisGridId } from "../../components/ui/CIS/utils";
import { getContractorsGridId } from "../../components/ui/Contractor/actions";
import { getInvoiceItemsGridId, getInvoiceNotesGridId } from "../../components/ui/Dashboard/Panel/Invoice/utils";
import {
    getInvoiceItemsResourceParams,
    getInvoiceDocumentsResourceParams,
    getInvoiceNotesResourceParams,
    getContractorsResourceParams,
    getProgramFormFriendlyNamesResourceParams,
} from "../configureResources";
import { getEventCategoriesGridId } from "../../components/ui/Dashboard/Panel/Program/EventsPanel/utils";
import { getCatalogCategoriesGridId } from "../../components/ui/Dashboard/Panel/Program/CatalogPanel/utils";
import { filterObjToString, getDataGridConfig, mapDataToGridColumnKeys } from "components/utils/datagrid";
import { isNullOrWhitespace } from "components/utils/string";
import { getCustomerGridId } from "components/ui/Customer/actions";
import { getReference, getReferenceNameByValue } from "components/ui/Reference/utils";
import { referenceTypes } from "components/ui/Reference/referenceTypes";
import { toArray } from "components/utils/array";

export const friendlyNamesGridGetDataAction = async ({
    columnKeysMap,
    programNumber,
    formNumber,
    exportCSV,
    fileName,
    fileNamePostfix,
}) => {
    const dataGridId = getFriendlyNamesGridGridId({ formNumber });
    store.dispatch({
        type: actionTypes.DATA_GRID_GET_DATA_REQUEST,
        name: dataGridId,
    });

    try {
        const fieldsData = await getResourcePromise({
            ...getProgramFormFriendlyNamesResourceParams({ programNumber }),
        });
        const fields = fieldsData?.fieldFriendlyNameList ?? [];

        const pages = await getResourcePromise({
            resourceName: "programFormPages",
            key: `${programNumber}-${formNumber}`,
            path: {
                programNumber,
                formNumber,
            },
        });

        const promises = pages.map((page) => {
            return getResourcePromise({
                resourceName: "programFormPages",
                resourceId: page.number,
                path: {
                    programNumber,
                    formNumber,
                },
            });
        });

        const pageResults = await Promise.all(promises);
        const validationTypes = await getReference({ type: referenceTypes.formfieldvalidation });
        const friendlyNames = pageResults
            .filter((page) => !isNil(page.configuration))
            .flatMap((page) => {
                const { schema, uiSchema } = page.configuration;
                const pageId = "root";

                return getSchemaIds(schema, true)
                    .map((item) => ({
                        ...item,
                        id: pageId + "_" + item.id.split(".").join("_"),
                    }))
                    .map(({ title, id }) => {
                        const formBuilderState = {
                            schema,
                            uiSchema,
                            selectedElementId: id,
                        };

                        const elementUiParams = getSelectedElementUiParams(formBuilderState);
                        const section = getActiveSection(formBuilderState);
                        const sectionId = pageId + "_" + getActiveSectionId(formBuilderState);

                        const field = fields.find((i) => i.fieldNumber === elementUiParams["af:fieldNumber"]);
                        const validationType = getReferenceNameByValue({
                            reference: validationTypes,
                            value: elementUiParams["af:validationType"],
                        });

                        return {
                            id: field?.fieldId ?? elementUiParams["af:fieldId"],
                            fieldId: id,
                            sectionId,
                            pageId,
                            fieldNumber: field?.fieldNumber ?? elementUiParams["af:fieldNumber"],
                            pageNumber: page.number,
                            pageName: page.name,
                            sectionName: section && section.title,
                            fieldName: title,
                            fieldGroup: field?.fieldGroup,
                            fieldType: elementUiParams["ui:widget"] || "text",
                            friendlyName: field?.friendlyName ?? elementUiParams["af:friendlyName"],
                            status: field?.status,
                            validationRequired: elementUiParams["af:validationRequired"] ? "True" : "False",
                            validationType,
                        };
                    });
            });

        const data = mapDataToGridColumnKeys(columnKeysMap, friendlyNames);

        store.dispatch({
            type: exportCSV ? actionTypes.DATA_GRID_CLIENT_GET_DATA_EXPORT : actionTypes.DATA_GRID_CLIENT_GET_DATA_SUCCESS,
            name: dataGridId,
            data: {
                grid: {
                    rows: data,
                },
            },
            passThroughData: {
                dataGridId,
                fileName,
                fileNamePostfix,
            },
        });
    } catch (error) {
        store.dispatch({
            type: actionTypes.DATA_GRID_GET_DATA_ERROR,
            name: dataGridId,
        });
    }
};

export const scanQueueGridGetDataAction = async ({ columnKeysMap, exportCSV, fileName, fileNamePostfix }) => {
    const dataGridId = getScanQueueGridId();

    store.dispatch({
        type: actionTypes.DATA_GRID_GET_DATA_REQUEST,
        name: dataGridId,
    });

    store.dispatch(
        getResource({
            resourceName: "scanGroupProgramOrder",
            key: "global-scans-queue",
            onSuccess: (action) => {
                const data = mapDataToGridColumnKeys(columnKeysMap, formatScanQueueGridRows({ rows: action.data }));

                store.dispatch({
                    type: exportCSV ? actionTypes.DATA_GRID_CLIENT_GET_DATA_EXPORT : actionTypes.DATA_GRID_CLIENT_GET_DATA_SUCCESS,
                    name: dataGridId,
                    data: {
                        grid: {
                            rows: data,
                        },
                    },
                    passThroughData: {
                        dataGridId,
                        fileName,
                        fileNamePostfix,
                    },
                });
            },
            onError: (action) => {
                store.dispatch({
                    type: actionTypes.DATA_GRID_GET_DATA_ERROR,
                    name: dataGridId,
                });
            },
        })
    );
};

export const scanQueueUserGroupsGridGetDataAction = async ({ columnKeysMap, exportCSV, fileName, fileNamePostfix }) => {
    const dataGridId = getScanQueueUserGroupsGridId();

    store.dispatch({
        type: actionTypes.DATA_GRID_GET_DATA_REQUEST,
        name: dataGridId,
    });

    store.dispatch(
        getResource({
            resourceName: "scanGroups",
            key: "scans-queue-groups",
            onSuccess: (action) => {
                const data = mapDataToGridColumnKeys(columnKeysMap, action.data);

                store.dispatch({
                    type: exportCSV ? actionTypes.DATA_GRID_CLIENT_GET_DATA_EXPORT : actionTypes.DATA_GRID_CLIENT_GET_DATA_SUCCESS,
                    name: dataGridId,
                    data: {
                        grid: {
                            rows: data,
                        },
                    },
                    passThroughData: {
                        dataGridId,
                        fileName,
                        fileNamePostfix,
                    },
                });
            },
            onError: (action) => {
                store.dispatch({
                    type: actionTypes.DATA_GRID_GET_DATA_ERROR,
                    name: dataGridId,
                });
            },
        })
    );
};

export const userGroupsGridGetDataAction = async ({
    utilityNumber,
    userName,
    queryType,
    groupType,
    exportCSV,
    fileName,
    fileNamePostfix,
    dataGridId,
    columnKeysMap,
}) => {
    const resourceParams = {
        resourceName: getResourceName({ utilityNumber, userName }),
        key: getResourceKey({ utilityNumber, userName }),
        path: isNil(utilityNumber)
            ? undefined
            : {
                  utilityNumber,
              },
        query: {
            queryType,
            groupType,
            // Add userNumber and utilityNumber in case of userGroups by usernumber
            ...(isNil(utilityNumber) && !isNullOrWhitespace(userName)
                ? {}
                : {
                      utilityNumber,
                      userName,
                  }),
        },
    };

    store.dispatch({
        type: actionTypes.DATA_GRID_GET_DATA_REQUEST,
        name: dataGridId,
    });

    store.dispatch(
        getResource({
            ...resourceParams,
            onSuccess: (action) => {
                const groupList = action.data?.groupList ?? action.data?.groupListByUser ?? [];

                const groups = groupList.map((r) => ({
                    ...r,
                    // Clear parent Group number if parent group is not found in the received list
                    parentGroupNumber: groupList.filter((g) => g.groupNumber === r.parentGroupNumber).map((g) => g.groupNumber)[0],
                    // Clear parent Group name if parent group is not found in the received list
                    parentGroupName: groupList.filter((g) => g.groupNumber === r.parentGroupNumber).map((g) => g.groupName)[0],
                }));

                const rows = mapDataToGridColumnKeys(columnKeysMap, groups);

                store.dispatch({
                    type: exportCSV ? actionTypes.DATA_GRID_CLIENT_GET_DATA_EXPORT : actionTypes.DATA_GRID_CLIENT_GET_DATA_SUCCESS,
                    name: dataGridId,
                    data: {
                        grid: {
                            rows,
                        },
                    },
                    passThroughData: {
                        dataGridId,
                        fileName,
                        fileNamePostfix,
                    },
                });
            },
            onError: (action) => {
                store.dispatch({
                    type: actionTypes.DATA_GRID_GET_DATA_ERROR,
                    name: dataGridId,
                });
            },
        })
    );
};

export const cisGridGetDataAction = async ({ columnKeysMap, utilityNumber, exportCSV, fileName, fileNamePostfix }) => {
    const dataGridId = getCisGridId({ utilityNumber });

    const { filter, paging } = getDataGridConfig(dataGridId);
    const { skip, take } = paging;

    if (filter && filter.filters && filter.filters.length !== 0) {
        store.dispatch({
            type: actionTypes.DATA_GRID_GET_DATA_REQUEST,
            name: dataGridId,
        });

        store.dispatch(
            createResource({
                resourceName: "cisCustomers",
                key: utilityNumber,
                body: {
                    utilityNumber,
                    page: skip / take + 1,
                    rows: 10,
                    mode: "",
                    searchList: (filter.filters || []).map((f) => ({
                        columnName: Object.keys(columnKeysMap).find((key) => columnKeysMap[key] === f.field) ?? f.field,
                        columnValue: f.value ?? "",
                    })),
                },
                onSuccess: (action) => {
                    let result = action.data;
                    let rows = [];

                    if (result && result.resultCount > 0) {
                        if (result.columnNames.includes("PREMISE #") && !result.columnNames.includes("PREMISEID")) {
                            result.columnNames[result.columnNames.indexOf("PREMISE #")] = "PREMISEID";
                        }

                        if (result.columnNames.includes("METER_NUMBER") && !result.columnNames.includes("METERID")) {
                            result.columnNames[result.columnNames.indexOf("METER_NUMBER")] = "METERID";
                        }

                        rows = result.columnValues.map((r) =>
                            result.columnNames.reduce(
                                (result, column, index) => ({
                                    ...result,
                                    [column]: r[index],
                                }),
                                {}
                            )
                        );

                        rows = mapDataToGridColumnKeys(columnKeysMap, rows, {
                            preserveUnknownColumns: true,
                        });

                        rows[0].totRecords = result.resultCount;
                    }

                    store.dispatch({
                        type: exportCSV ? actionTypes.DATA_GRID_GET_DATA_EXPORT : actionTypes.DATA_GRID_GET_DATA_SUCCESS,
                        name: dataGridId,
                        data: {
                            grid: {
                                rows,
                            },
                        },
                        passThroughData: {
                            dataGridId,
                            fileName,
                            fileNamePostfix,
                        },
                    });
                },
                onError: (action) => {
                    store.dispatch({
                        type: actionTypes.DATA_GRID_GET_DATA_ERROR,
                        name: dataGridId,
                    });
                },
            })
        );
    }
};

export const customerGridGetDataAction = async ({ columnKeysMap, utilityNumber, customers }) => {
    const dataGridId = getCustomerGridId({ utilityNumber });

    const rows = mapDataToGridColumnKeys(columnKeysMap, customers);

    store.dispatch({
        type: actionTypes.DATA_GRID_CLIENT_GET_DATA_SUCCESS,
        name: dataGridId,
        data: {
            grid: {
                rows,
            },
        },
    });
};

export const filesGridGetDataAction = async ({ columnKeysMap, fileName, fileNamePostfix, gridId, applicationNumber, exportCSV }) => {
    store.dispatch({
        type: actionTypes.DATA_GRID_GET_DATA_REQUEST,
        name: gridId,
    });

    store.dispatch(
        getResource({
            resourceName: "applicationFiles",
            key: applicationNumber,
            path: {
                appId: applicationNumber,
            },
            onSuccess: (action) => {
                const rows = mapDataToGridColumnKeys(columnKeysMap, action.data || [], {
                    preserveUnknownColumns: true,
                    keyAlias: {
                        date: "fileDate",
                        type: "fileType",
                        security: "fileSecurity",
                    },
                });

                store.dispatch({
                    type: exportCSV ? actionTypes.DATA_GRID_GET_DATA_EXPORT : actionTypes.DATA_GRID_CLIENT_GET_DATA_SUCCESS,
                    name: gridId,
                    data: {
                        grid: {
                            rows,
                        },
                    },
                    passThroughData: {
                        dataGridId: gridId,
                        fileName,
                        fileNamePostfix,
                    },
                });
            },
            onError: (action) => {
                store.dispatch({
                    type: actionTypes.DATA_GRID_GET_DATA_ERROR,
                    name: gridId,
                });
            },
        })
    );
};
export const contractorsGridGetDataAction = async ({
    columnKeysMap,
    programNumber,
    applicationNumber,
    exportCSV,
    fileName,
    fileNamePostfix,
}) => {
    const dataGridId = getContractorsGridId({
        programNumber,
        applicationNumber,
    });
    const dataGrid = store.getState().dataGrid[dataGridId];

    // Filter
    let searchAttr = null;
    if (dataGrid.filter) {
        searchAttr = filterObjToString(dataGrid.filter);
    }

    // Replace the filter string keys with keys from the columnKeysMap
    if (searchAttr) {
        Object.keys(columnKeysMap).forEach((key) => {
            searchAttr = searchAttr.replace(columnKeysMap[key], key);
        });
    }

    // Sort
    let sort = null;
    if (dataGrid.sort && dataGrid.sort.length > 0) {
        sort = `${dataGrid.sort[0].field} ${dataGrid.sort[0].dir}`;

        // Replace the sort string keys with keys from the columnKeysMap
        Object.keys(columnKeysMap).forEach((key) => {
            sort = sort.replace(columnKeysMap[key], key);
        });

        sort = sort.toUpperCase();
    }

    // Paging
    const { skip, take } = dataGrid.paging;
    const pageNum = skip / take + 1;
    const recsPerPage = take;

    store.dispatch({
        type: actionTypes.DATA_GRID_GET_DATA_REQUEST,
        name: dataGridId,
    });

    store.dispatch(
        getResource({
            ...getContractorsResourceParams({
                programNumber,
                applicationNumber,
                searchAttr,
                sort,
                pageNum,
                recsPerPage,
            }),
            onSuccess: (action) => {
                const totalRecords = action.data?.totalRecords ?? 0;
                const remainingRecords = action.data?.remainingRecords ?? 0;
                const rows = mapDataToGridColumnKeys(columnKeysMap, action.data?.items ?? []);

                if (rows.length > 0) {
                    rows[0].totRecords = totalRecords;
                    rows[0].MoreRecords = remainingRecords;
                }

                store.dispatch({
                    type: exportCSV ? actionTypes.DATA_GRID_GET_DATA_EXPORT : actionTypes.DATA_GRID_GET_DATA_SUCCESS,
                    name: dataGridId,
                    data: {
                        grid: {
                            rows,
                        },
                    },
                    passThroughData: {
                        dataGridId,
                        fileName,
                        fileNamePostfix,
                    },
                });
            },
            onError: () => {
                store.dispatch({
                    type: actionTypes.DATA_GRID_GET_DATA_ERROR,
                    name: dataGridId,
                });
            },
        })
    );
};

export const invoiceItemsGridGetDataAction = async ({
    columnKeysMap,
    utilityNumber,
    contractNumber,
    documentNumber,
    exportCSV,
    fileName,
    fileNamePostfix,
}) => {
    const dataGridId = getInvoiceItemsGridId({ documentNumber });

    store.dispatch({
        type: actionTypes.DATA_GRID_GET_DATA_REQUEST,
        name: dataGridId,
    });

    store.dispatch(
        getResource({
            ...getInvoiceItemsResourceParams({
                utilityNumber,
                contractNumber,
                documentNumber,
            }),
            onSuccess: (action) => {
                const data = mapDataToGridColumnKeys(columnKeysMap, action?.data?.invoiceList || []);
                store.dispatch({
                    type: exportCSV ? actionTypes.DATA_GRID_CLIENT_GET_DATA_EXPORT : actionTypes.DATA_GRID_CLIENT_GET_DATA_SUCCESS,
                    name: dataGridId,
                    data: {
                        grid: {
                            rows: data,
                        },
                    },
                    passThroughData: {
                        dataGridId,
                        fileName,
                        fileNamePostfix,
                    },
                });
            },
            onError: (action) => {
                store.dispatch({
                    type: actionTypes.DATA_GRID_GET_DATA_ERROR,
                    name: dataGridId,
                });
            },
        })
    );
};

export const invoiceDocumentsGridGetDataAction = async ({
    columnKeysMap,
    utilityNumber,
    contractNumber,
    dataGridId,
    exportCSV,
    fileName,
    fileNamePostfix,
}) => {
    store.dispatch({
        type: actionTypes.DATA_GRID_GET_DATA_REQUEST,
        name: dataGridId,
    });

    store.dispatch(
        getResource({
            ...getInvoiceDocumentsResourceParams({
                utilityNumber,
                contractNumber,
            }),
            onSuccess: (action) => {
                const data = mapDataToGridColumnKeys(columnKeysMap, action?.data?.invoiceDocumentList || []);

                store.dispatch({
                    type: exportCSV ? actionTypes.DATA_GRID_CLIENT_GET_DATA_EXPORT : actionTypes.DATA_GRID_CLIENT_GET_DATA_SUCCESS,
                    name: dataGridId,
                    data: {
                        grid: {
                            rows: data,
                        },
                    },
                    passThroughData: {
                        dataGridId,
                        fileName,
                        fileNamePostfix,
                    },
                });
            },
            onError: (action) => {
                store.dispatch({
                    type: actionTypes.DATA_GRID_GET_DATA_ERROR,
                    name: dataGridId,
                });
            },
        })
    );
};

export const invoiceNotesGridGetDataAction = async ({
    columnKeysMap,
    utilityNumber,
    contractNumber,
    documentNumber,
    exportCSV,
    fileName,
    fileNamePostfix,
}) => {
    const dataGridId = getInvoiceNotesGridId({ documentNumber });

    store.dispatch({
        type: actionTypes.DATA_GRID_GET_DATA_REQUEST,
        name: dataGridId,
    });

    store.dispatch(
        getResource({
            ...getInvoiceNotesResourceParams({
                utilityNumber,
                contractNumber,
                documentNumber,
            }),
            onSuccess: (action) => {
                const data = mapDataToGridColumnKeys(columnKeysMap, action.data || []);
                store.dispatch({
                    type: exportCSV ? actionTypes.DATA_GRID_CLIENT_GET_DATA_EXPORT : actionTypes.DATA_GRID_CLIENT_GET_DATA_SUCCESS,
                    name: dataGridId,
                    data: {
                        grid: {
                            rows: data,
                        },
                    },
                    passThroughData: {
                        dataGridId,
                        fileName,
                        fileNamePostfix,
                    },
                });
            },
            onError: (action) => {
                store.dispatch({
                    type: actionTypes.DATA_GRID_GET_DATA_ERROR,
                    name: dataGridId,
                });
            },
        })
    );
};

export const catalogCategoriesGridGetDataAction = ({ columnKeysMap, programNumber, exportCSV, fileName, fileNamePostfix }) => {
    const dataGridId = getCatalogCategoriesGridId({ programNumber });

    store.dispatch({
        type: actionTypes.DATA_GRID_GET_DATA_REQUEST,
        name: dataGridId,
    });

    store.dispatch(
        getResource({
            resourceName: "programCatalogCategories",
            key: programNumber,
            path: {
                programNumber,
            },
            onSuccess: (action) => {
                const data = mapDataToGridColumnKeys(columnKeysMap, action.data || []);

                store.dispatch({
                    type: exportCSV ? actionTypes.DATA_GRID_CLIENT_GET_DATA_EXPORT : actionTypes.DATA_GRID_CLIENT_GET_DATA_SUCCESS,
                    name: dataGridId,
                    data: {
                        grid: {
                            rows: data,
                        },
                    },
                    passThroughData: {
                        dataGridId,
                        fileName,
                        fileNamePostfix,
                    },
                });
            },
            onError: (action) => {
                store.dispatch({
                    type: actionTypes.DATA_GRID_GET_DATA_ERROR,
                    name: dataGridId,
                });
            },
        })
    );
};

export const eventCategoriesGridGetDataAction = ({ columnKeysMap, programNumber, exportCSV, fileName, fileNamePostfix }) => {
    const dataGridId = getEventCategoriesGridId({ programNumber });

    store.dispatch({
        type: actionTypes.DATA_GRID_GET_DATA_REQUEST,
        name: dataGridId,
    });

    store.dispatch(
        getResource({
            resourceName: "programEventCategories",
            key: programNumber,
            path: {
                programNumber,
            },
            onSuccess: (action) => {
                const data = mapDataToGridColumnKeys(columnKeysMap, action.data || []);
                store.dispatch({
                    type: exportCSV ? actionTypes.DATA_GRID_CLIENT_GET_DATA_EXPORT : actionTypes.DATA_GRID_CLIENT_GET_DATA_SUCCESS,
                    name: dataGridId,
                    data: {
                        grid: {
                            rows: data,
                        },
                    },
                    passThroughData: {
                        dataGridId,
                        fileName,
                        fileNamePostfix,
                    },
                });
            },
            onError: (action) => {
                store.dispatch({
                    type: actionTypes.DATA_GRID_GET_DATA_ERROR,
                    name: dataGridId,
                });
            },
        })
    );
};

export const analyticsEventsGridGetDataAction = (props) => {
    const { columnKeysMap, dataGridId, resourceParams, onComplete } = props;

    // Get current filter
    const filter = store.getState().dataGrid[dataGridId]?.filter;

    let searchAttr = filterObjToString(filter);

    if (searchAttr) {
        Object.keys(columnKeysMap).forEach((key) => {
            searchAttr = searchAttr.replace(columnKeysMap[key], key);
        });
    }

    return resourceGetDataAction({
        ...props,
        resourceParams: {
            ...resourceParams,
            query: {
                ...(resourceParams.query ?? {}),
                searchAttr,
            },
        },
        onComplete,
    });
};

export const resourceGetDataAction = ({
    dataGridId,
    resourceDataKey,
    resourceParams,
    exportCSV,
    fileName,
    fileNamePostfix,
    columnKeysMap,
    onComplete,
}) => {
    store.dispatch({
        type: actionTypes.DATA_GRID_GET_DATA_REQUEST,
        name: dataGridId,
    });

    store.dispatch(
        getResource({
            ...resourceParams,
            onSuccess: (action) => {
                const data = mapDataToGridColumnKeys(columnKeysMap, resourceDataKey ? action.data[resourceDataKey] : toArray(action.data), {
                    preserveUnknownColumns: true,
                });

                store.dispatch({
                    type: exportCSV ? actionTypes.DATA_GRID_CLIENT_GET_DATA_EXPORT : actionTypes.DATA_GRID_CLIENT_GET_DATA_SUCCESS,
                    name: dataGridId,
                    data: {
                        grid: {
                            rows: data || [],
                        },
                    },
                    passThroughData: {
                        dataGridId,
                        fileName,
                        fileNamePostfix,
                    },
                });
            },
            onError: (action) => {
                store.dispatch({
                    type: actionTypes.DATA_GRID_GET_DATA_ERROR,
                    name: dataGridId,
                });
            },
            onComplete,
        })
    );
};

export const rowsGetDataAction = ({ dataGridId, rows, exportCSV, fileName, fileNamePostfix }) => {
    store.dispatch({
        type: actionTypes.DATA_GRID_GET_DATA_REQUEST,
        name: dataGridId,
    });

    setTimeout(() => {
        store.dispatch({
            type: exportCSV ? actionTypes.DATA_GRID_CLIENT_GET_DATA_EXPORT : actionTypes.DATA_GRID_CLIENT_GET_DATA_SUCCESS,
            name: dataGridId,
            data: {
                grid: {
                    rows: rows || [],
                },
            },
            passThroughData: {
                dataGridId,
                fileName,
                fileNamePostfix,
            },
        });
    }, 0);
};

export const clientGridGetDataActions = {
    friendlyNamesGridGetDataAction: friendlyNamesGridGetDataAction,
    scanQueueGridGetDataAction: scanQueueGridGetDataAction,
    userGroupsGridGetDataAction: userGroupsGridGetDataAction,
    scanQueueUserGroupsGridGetDataAction: scanQueueUserGroupsGridGetDataAction,
    cisGridGetDataAction: cisGridGetDataAction,
    invoiceItemsGridGetDataAction: invoiceItemsGridGetDataAction,
    invoiceDocumentsGridGetDataAction: invoiceDocumentsGridGetDataAction,
    invoiceNotesGridGetDataAction: invoiceNotesGridGetDataAction,
    catalogCategoriesGridGetDataAction: catalogCategoriesGridGetDataAction,
    eventCategoriesGridGetDataAction: eventCategoriesGridGetDataAction,
    contractorsGridGetDataAction: contractorsGridGetDataAction,
    customerGridGetDataAction: customerGridGetDataAction,
    filesGridGetDataAction: filesGridGetDataAction,
    analyticsEventsGridGetDataAction,
    // Generic get data action when getting data from one resource.
    resourceGetDataAction: resourceGetDataAction,
    // Generic get data action when row data is as a param
    rowsGetDataAction: rowsGetDataAction,
};
