import React, { useCallback, memo } from "react";

import { programsGridColumnKeys } from "../../../../views/configureGrids";
import { openProgramTab } from "store/window/openTabActions";
import { openUrl } from "../../../../utils/window";

import ActionLabel from "../../../Label/ActionLabel";
import DataGrid from "../../../DataGrid";
import withDataGrid from "../../../DataGrid/withDataGrid";
import { useDispatch } from "react-redux";

const Grid = memo(({ gridId, dataGridConfig }) => {
    const dispatch = useDispatch();
    const rowActions = [
        {
            name: "url",
            icon: "arrow-right-up",
            title: (row) => (row[programsGridColumnKeys.hostName] ? "Website" : "There is no website configured for this program"),
            disabled: (row) => !row[programsGridColumnKeys.hostName],
        },
        {
            name: "open",
            icon: "open-new-window",
            title: "Manage Program",
        },
    ];

    const handleRowAction = useCallback(
        (action) => {
            const { name, dataItem } = action;

            switch (name) {
                case "url":
                    openUrl(dataItem[programsGridColumnKeys.hostName]);
                    setTimeout(() => {
                        document?.activeElement?.blur();
                    }, 50);

                    break;

                case "open":
                    dispatch(
                        openProgramTab({
                            programNumber: dataItem[programsGridColumnKeys.programNumber],
                        })
                    );
                    break;

                default:
                    break;
            }
        },
        [dispatch]
    );

    return (
        <DataGrid
            name={gridId}
            config={dataGridConfig}
            customRowActions={rowActions}
            onRowAction={handleRowAction}
            columnCellContent={{
                [programsGridColumnKeys.programNumber]: (column, row) => {
                    return (
                        <ActionLabel
                            onClick={() =>
                                dispatch(
                                    openProgramTab({
                                        programNumber: row[column.key],
                                    })
                                )
                            }
                        >
                            {row[column.key]}
                        </ActionLabel>
                    );
                },
                [programsGridColumnKeys.programName]: (column, row) => {
                    if (row[programsGridColumnKeys.programNumber]) {
                        return (
                            <ActionLabel
                                onClick={() =>
                                    dispatch(
                                        openProgramTab({
                                            programNumber: row[programsGridColumnKeys.programNumber],
                                        })
                                    )
                                }
                            >
                                {row[column.key]}
                            </ActionLabel>
                        );
                    }

                    return row[column.key];
                },
                [programsGridColumnKeys.hostName]: (column, row) => {
                    if (row[column.key]) {
                        return <ActionLabel onClick={() => openUrl(row[column.key])}>{row[column.key]}</ActionLabel>;
                    }

                    return row[column.key];
                },
            }}
        />
    );
});

export default withDataGrid("programs", Grid);
