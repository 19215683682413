import React, { useState, memo, useId } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";

import WaitIcon from "../../ui/WaitIcon";
import IconWithLabel from "../../ui/Icons/IconWithLabel";
import IconWrap from "../../ui/Icons";
import CounterBox from "../../ui/CounterBox";
import TagButton from "../../ui/Button/TagButton";
import ButtonGroup from "../../ui/Button/ButtonGroup";
import ScrollControls from "../../ui/ScrollControls";

import "../../ui/Headers/SubHeader.scss";
import { isEmpty } from "lodash";

const SubHeaderInvoice = memo(({ items, className, flagsAndTagsAndAttributesSpecificLayout, isLoading, attributes = [], flags = [] }) => {
    const [showAllFields, setShowAllFields] = useState(false);
    const isDesktop = useSelector((state) => state.window?.isDesktop);

    const headerItemsScrollId = useId();
    const tagsScrollId = useId();

    const onShowAllFields = () => {
        setShowAllFields(!showAllFields);
    };

    const showOptionalText = () => {
        return showAllFields ? "Hide Flags and Attributes" : "Show Flags and Attributes";
    };

    if (isLoading) {
        return <WaitIcon withSpaceAround />;
    }

    return (
        <div
            className={cn(
                "sub-header",
                { "tags-and-attributes-details-expanded": showAllFields && flagsAndTagsAndAttributesSpecificLayout },
                className
            )}
        >
            <div
                className={cn("sub-header-visible-part", {
                    "invoice-details main-grid-wrap responsive": flagsAndTagsAndAttributesSpecificLayout,
                    "tags-and-attributes-details-expanded": showAllFields && flagsAndTagsAndAttributesSpecificLayout,
                })}
            >
                <div className="header-items-list">
                    <div className="header-items-list-wrap" id={headerItemsScrollId}>
                        {items.map(
                            (item, index) =>
                                !isEmpty(item) && (
                                    <div key={index} className={"header-item " + item.name}>
                                        <div className="header-item-title">
                                            <span>{item.name}</span>
                                            {item.actionShow && (
                                                <IconWithLabel
                                                    iconWithLabelRight
                                                    iconWithLabel={item.actionIcon}
                                                    onClick={item.actionOnClick}
                                                >
                                                    {item.actionText}
                                                </IconWithLabel>
                                            )}
                                        </div>
                                        <div className="header-value">
                                            {item.onClick ? (
                                                <span className="clickable" onClick={item.onClick}>
                                                    {item.value}
                                                </span>
                                            ) : (
                                                <span>{item.value}</span>
                                            )}
                                        </div>
                                    </div>
                                )
                        )}
                    </div>
                    {isDesktop && <ScrollControls targetId={headerItemsScrollId} />}
                </div>
                <div
                    className={cn("expander-wrap invoice-flags-and-attributes no-shrink", {
                        expanded: showAllFields,
                    })}
                    onClick={onShowAllFields}
                >
                    <div className="flex-row align-center">
                        <div className="info-hint flex-row">
                            <div className="flags flex-row align-center">
                                <CounterBox>{flags.length}</CounterBox>
                                Flags
                            </div>
                            <div className="attributes flex-row align-center">
                                <CounterBox>{attributes.length}</CounterBox>
                                Attributes
                            </div>
                        </div>
                        {(flags.length !== 0 || attributes.length !== 0) && (
                            <IconWrap
                                title={showOptionalText()}
                                iconWrap={showAllFields ? "shevron-in-circle-up-filled--before" : "shevron-in-circle-down-drop-down-empty"}
                                onClick={onShowAllFields}
                            ></IconWrap>
                        )}
                    </div>
                </div>
            </div>
            {flagsAndTagsAndAttributesSpecificLayout ? (
                <div
                    className={cn("sub-header-hidden-part invoice-flags-and-attributes main-grid-wrap responsive", {
                        visible: showAllFields,
                    })}
                >
                    <div className="flex-row no-scroll" id={tagsScrollId}>
                        {flags && flags.length !== 0 && (
                            <div
                                className={cn("sub-header-flags-items", {
                                    "reset-margin": flagsAndTagsAndAttributesSpecificLayout,
                                })}
                            >
                                <>
                                    <span className="section-title">
                                        Flags <span>({flags.length})</span>
                                    </span>
                                    <ButtonGroup transparent>
                                        {flags.map((item, index) => (
                                            <TagButton key={index} tagWithIcon="flag-empty" readonly>
                                                {item.flag}
                                            </TagButton>
                                        ))}
                                    </ButtonGroup>
                                </>
                            </div>
                        )}
                        <div className={"sub-header-attributes-items" + (flagsAndTagsAndAttributesSpecificLayout ? " no-width" : "")}>
                            <span className="section-title">
                                Attributes <span>({attributes.length})</span>
                            </span>
                            <ButtonGroup transparent>
                                {attributes.map((item, index) => (
                                    <TagButton key={index} readonly>
                                        {item.name}: {item.value}
                                    </TagButton>
                                ))}
                            </ButtonGroup>
                        </div>
                        {isDesktop && <ScrollControls targetId={tagsScrollId} />}
                    </div>
                </div>
            ) : (
                <div className={"sub-header-hidden-part" + (showAllFields ? " visible" : "")}>
                    {flags && flags.length !== 0 && (
                        <div className="sub-header-flags-items">
                            <>
                                <span className="section-title invoice">Flags:</span>
                                <ButtonGroup transparent>
                                    {flags.map((item, index) => (
                                        <TagButton key={index} tagWithIcon="flag-empty" readonly>
                                            {item.flag}
                                        </TagButton>
                                    ))}
                                </ButtonGroup>
                            </>
                        </div>
                    )}
                    {attributes && attributes.length !== 0 && (
                        <div className="sub-header-tags-items">
                            <span className="section-title invoice">Attributes:</span>
                            <ButtonGroup transparent>
                                {attributes.map((item, index) => (
                                    <TagButton key={index} readonly>
                                        {item.name}: {item.value}
                                    </TagButton>
                                ))}
                            </ButtonGroup>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
});

export default SubHeaderInvoice;
