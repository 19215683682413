import React, { useCallback, memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as loginActions from "../../../store/login/actions";

import NavBarView from "./NavBarView";

const NavBar = memo(({ items, onClick }) => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const logOutPending = useSelector((state) => state.login?.logOutPending);

    const userName = `${user?.firstName} ${user?.lastName}`;

    const handleLogout = useCallback(() => {
        dispatch(loginActions.logout());
    }, [dispatch]);

    return <NavBarView items={items} userName={userName} logOutPending={logOutPending} logout={handleLogout} onClick={onClick} />;
});

export default NavBar;
