import React, { memo, useMemo, useContext } from "react";
import WaitIcon from "../../../../WaitIcon";
import { getAttributeValue } from "../../../../../utils/workflow";
import { useApplicationFormDetails, useProgramFormPageFields } from "../../../../../../store/resources/useResource";
import ApplicationForm from "components/ui/Dashboard/Panel/Project/ApplicationsPanel/ApplicationForm";
import { TaskWorkflowContext } from "../../../context/TaskWorkflowContext";

export const ApplicationFormFieldEntryTask = memo(({ applicationNumber, wfTaskNumber, task, onSubmitSuccess, onCancel, editMode }) => {
    const { programNumber } = useContext(TaskWorkflowContext);

    const applicationFormId = useMemo(() => getAttributeValue("Form Page Number", task), [task]);
    const applicationFormFieldId = useMemo(() => getAttributeValue("Application FieldID", task), [task]);
    const formId = `task-workflow-${wfTaskNumber}-${applicationNumber}`;

    const [fields = [], isLoadingFields] = useProgramFormPageFields({
        programNumber,
        formNumber: programNumber,
        pageNumber: applicationFormId,
        forced: true,
    });

    const [, isLoadingFormDetails] = useApplicationFormDetails({
        applicationNumber,
        applicationFormId,
        forced: true,
    });

    const applicationFormFieldNumber = useMemo(
        () => fields.find((f) => [String(f.fieldId), f.fieldNumber].includes(applicationFormFieldId))?.fieldNumber,
        [fields, applicationFormFieldId]
    );

    if (isLoadingFormDetails || isLoadingFields) {
        return <WaitIcon />;
    }

    return (
        <ApplicationForm
            formId={formId}
            programNumber={programNumber}
            applicationNumber={applicationNumber}
            applicationFormId={applicationFormId}
            loading={isLoadingFormDetails}
            visibleFieldIds={[applicationFormFieldNumber]}
            editMode={editMode}
            taskNumber={wfTaskNumber}
            onSubmitSuccess={onSubmitSuccess}
            onCancel={onCancel}
        />
    );
});
