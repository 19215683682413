import React from "react";
import { ITEM_HEIGHT, OVERSCAN_COUNT } from ".";
import SelectedListItem from "./SelectedListItem";
import AutoSizeList from "./AutoSizeList";
import ListFilter, { useListFilter } from "./ListFilter";

const SelectedList = ({ items, nameKey, onRemove }) => {
    const [filteredList, filterValue, onFilterChange] = useListFilter({
        items,
        filterKey: nameKey,
    });

    return (
        <>
            <ListFilter filterValue={filterValue} onFilterChange={onFilterChange} />
            <div className="assignment-selector__list-container">
                <AutoSizeList
                    className="assignment-selector__list assignment-selector__list--selected"
                    itemCount={filteredList.length}
                    itemSize={ITEM_HEIGHT}
                    overscanCount={OVERSCAN_COUNT}
                >
                    {(props) => <SelectedListItem {...props} items={filteredList} nameKey={nameKey} onRemove={onRemove} />}
                </AutoSizeList>
            </div>
        </>
    );
};

export default SelectedList;
