import React, { memo } from "react";
import { UtilityTemplateInfo } from "./UtilityTemplateInfo";
import { ProgramTemplateInfo } from "./ProgramTemplateInfo";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";

import "./TemplateInfo.scss";

const TemplateInfo = memo((props) => {
    const [isProgramPortalBuilder] = usePortalBuilderState((state) => state.isProgramPortalBuilder);

    if (isProgramPortalBuilder) {
        return <ProgramTemplateInfo />;
    }

    return <UtilityTemplateInfo />;
});

export default TemplateInfo;
