import React, { useCallback, useContext, useRef, memo } from "react";
import cn from "classnames";

import Button from "../../../Button";
import { StatusProperties } from "./StatusProperties";
import { StepProperties } from "./StepProperties";
import { WorkflowContext } from "../../context/WorkflowContext";
import { WORKFLOW } from "../../constants/workflow";

import "./style.scss";

const StickyContainer = memo(
    ({ children, title, type, editStatus, isConfirmed = true, settingsContainerRef, isWorkflowUpdating, isWrapperHidden }) => {
        const { activeItem, onDeleteWorkflowStatus, onDeleteWorkflowStep } = useContext(WorkflowContext);

        const handleClickDelete = useCallback(
            (e) => {
                const { statusNumber, stepNumber } = activeItem;

                if (type === WORKFLOW.STATUS) {
                    onDeleteWorkflowStatus(e, statusNumber);
                }

                if (type === WORKFLOW.STEP) {
                    onDeleteWorkflowStep(e, statusNumber, stepNumber);
                }
            },
            [type, activeItem, onDeleteWorkflowStatus, onDeleteWorkflowStep]
        );

        return (
            <div className="wf__settings flex-column no-scroll">
                {Boolean(activeItem) && !isWorkflowUpdating && !isWrapperHidden && (
                    <div className="wf__settings-title">
                        <div className="wf__settings-title-text">{title}</div>
                    </div>
                )}
                <div
                    ref={settingsContainerRef}
                    className={cn("wf__settings-container flex-column flex-one-in-column no-scroll", {
                        "wf__settings-container--no-content": !activeItem || isWorkflowUpdating || isWrapperHidden,
                    })}
                >
                    <div className="flex-one-in-column no-scroll">{children}</div>
                    {editStatus === WORKFLOW.ITEM_EDIT_STATUSES.IS_CREATED && isConfirmed && (
                        <div className="wf__settings-container-buttons flex-row justify-end">
                            <Button icon="delete-trash-empty" onClick={handleClickDelete}>
                                {type === WORKFLOW.STEP ? "Delete Step" : "Delete Workflow Status"}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

export const Settings = memo(() => {
    const { workflowStatuses, activeItem, isWorkflowUpdating, isWorkflowLoading } = useContext(WorkflowContext);
    const settingsContainerRef = useRef(null);

    if (!isWorkflowUpdating && !isWorkflowLoading && activeItem) {
        const { statusNumber, stepNumber, type } = activeItem;
        const wfStatus = workflowStatuses.find((i) => i.number === statusNumber);

        if (wfStatus) {
            if (type === WORKFLOW.STATUS) {
                return (
                    <StickyContainer
                        title={
                            wfStatus.editStatus === WORKFLOW.ITEM_EDIT_STATUSES.IS_CREATED
                                ? "Create Status"
                                : "View / Edit Status Properties"
                        }
                        type={type}
                        isConfirmed={wfStatus.isConfirmed}
                        editStatus={wfStatus.editStatus}
                    >
                        <StatusProperties {...wfStatus} />
                    </StickyContainer>
                );
            }

            if (type === WORKFLOW.STEP) {
                const { steps } = wfStatus;
                const activeStep = steps.find((i) => i.number === stepNumber);

                if (activeStep) {
                    return (
                        <StickyContainer
                            title={
                                activeStep.editStatus === WORKFLOW.ITEM_EDIT_STATUSES.IS_CREATED
                                    ? "Create Step"
                                    : "View / Edit Step Properties"
                            }
                            type={type}
                            settingsContainerRef={settingsContainerRef}
                            isConfirmed={activeStep.isConfirmed}
                            editStatus={activeStep.editStatus}
                        >
                            <StepProperties {...activeStep} settingsContainerRef={settingsContainerRef} />
                        </StickyContainer>
                    );
                }
            }
        }
    }
});
