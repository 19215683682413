import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid";
import { programsCatalogGridColumnKeys, programsEventsGridColumnKeys } from "../../../../../views/configureGrids";
import { selectRows } from "../../../../../../store/dataGrid/actions";
import { getActivePageNumber } from "components/utils/datagrid";

const ItemsGrid = ({ gridId, columnKeys, dataGridConfig, selectedRows, onRowSelect, dispatch }) => {
    useEffect(() => {
        if (isEmpty(selectedRows)) {
            dispatch(
                selectRows({
                    dataGridId: gridId,
                    selectedRows: [],
                })
            );
        }
    }, [selectedRows, gridId, dispatch]);

    const onPageChanged = (event) => {
        const activePage = getActivePageNumber(null, event.page);
        const selectedRowsInPage = selectedRows[activePage];

        dispatch(
            selectRows({
                dataGridId: gridId,
                selectedRows: selectedRowsInPage,
            })
        );
    };

    const onRowSelectChange = (rows) => {
        const activePage = getActivePageNumber(gridId);
        const items = {
            ...selectedRows,
            [activePage]: rows,
        };

        onRowSelect(items);
    };

    return (
        <DataGrid
            name={gridId}
            config={dataGridConfig}
            showedColumnsKeys={columnKeys}
            customRowActions
            canSelectRow
            onRowSelectChange={onRowSelectChange}
            onPageChanged={onPageChanged}
            limit={10}
        />
    );
};

export const CatalogItemsGrid = withDataGrid("programsCatalog", (props) => (
    <ItemsGrid
        {...props}
        columnKeys={[
            programsCatalogGridColumnKeys.catalogID,
            programsCatalogGridColumnKeys.equipmentName,
            programsCatalogGridColumnKeys.programID,
            programsCatalogGridColumnKeys.programName,
        ]}
    />
));

export const EventItemsGrid = withDataGrid("programsEvents", (props) => (
    <ItemsGrid {...props} columnKeys={[programsEventsGridColumnKeys.eventName, programsEventsGridColumnKeys.programName]} />
));
