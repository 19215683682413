import React, { useCallback, useContext, useRef } from "react";
import { PropertyListItem } from "./PropertyListItem";
import { PropertyList } from "../PropertyList";
import { errorObjectToArray } from "../../utils";
import { PageContext } from "../../contexts";
import { cloneDeep } from "lodash";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";

// Used to store a set of properties under an object instead of a flat list

export const NestedProperties = (props) => {
    const { id, value = {}, nestingLevel, propertiesGroup = [], onChange, errors } = props;

    const [activeError] = usePortalBuilderState((state) => state.activeError);

    const errorArray = errorObjectToArray(errors);

    const { page } = useContext(PageContext);

    const containsActiveError = page?.index === activeError?.pageIndex;

    // onChangeSingleProp sometimes has old value if not using this ref.
    // TODO: investigate why onChange somehow preserves old value.
    const valueRef = useRef();
    valueRef.current = value;

    const onChangeSingleProp = useCallback(
        (propertyId, nestedValue, extraProperties) => {
            const newValue = cloneDeep(valueRef.current);
            extraProperties?.forEach((a) => {
                newValue[a.id] = a.value;
            });
            onChange(id, { ...newValue, [propertyId]: nestedValue });
        },
        [onChange, id]
    );

    if (props.hidden) {
        return null;
    }

    return (
        <PropertyListItem className="property-list-item--prop-group property-list-item--nested-props">
            <PropertyList
                items={propertiesGroup}
                nestingLevel={nestingLevel}
                config={value}
                onChange={onChangeSingleProp}
                containsActiveError={containsActiveError}
                errors={errorArray}
            />
        </PropertyListItem>
    );
};
