import React, { memo, useCallback, useState } from "react";

import AddApprovedEquipmentForm from "./AddApprovedEquipmentForm";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavContent from "../../../../SideNav/SideNavContent";
import Button from "../../../../Button";
import { useIsMobile } from "components/utils/useIsMobile";

import "./AddApprovedEquipment.scss";

const AddApprovedEquipment = memo(
    ({
        title,
        applicationNumber,
        catalogNumber,
        onSelectResult,
        onClose,
        handleAddApproved,
        gridConfig,
        dataGridInstanceId,
        activeFilter,
        onRowSelectClear,
    }) => {
        const isMobile = useIsMobile();
        const [approvedEquipmentDetails, setApprovedEquipmentDetails] = useState();

        const handleClickBack = useCallback(() => {
            setApprovedEquipmentDetails(null);
        }, []);

        return (
            <SideNavContent className="add-approved-equipment-panel">
                <SideNavHeader
                    title={title ?? "Add Approved Equipment"}
                    leadBlockIcon={title ? "edit-empty" : "plus"}
                    smallHeader
                    onClose={onClose}
                />
                <SideNavBody noPadding>
                    <AddApprovedEquipmentForm
                        title={title}
                        applicationNumber={applicationNumber}
                        catalogNumber={catalogNumber}
                        onSelectResult={onSelectResult}
                        onClose={onClose}
                        handleAddApproved={handleAddApproved}
                        gridConfig={gridConfig}
                        dataGridInstanceId={dataGridInstanceId}
                        filter={activeFilter}
                        onShowApprovedEquipmentDetails={(data) => setApprovedEquipmentDetails(data)}
                        approvedEquipmentDetails={approvedEquipmentDetails}
                        onRowSelectClear={onRowSelectClear}
                    />
                </SideNavBody>
                {(approvedEquipmentDetails || !isMobile) && (
                    <SideNavFooter setPrimaryButton={isMobile} justifyCenter>
                        {approvedEquipmentDetails && (
                            <Button icon="shevron-small-left" onClick={handleClickBack}>
                                Back
                            </Button>
                        )}

                        <div className="flex-row flex-one-in-row justify-end">
                            {approvedEquipmentDetails && (
                                <Button primary icon="plus" onClick={approvedEquipmentDetails.onAdd}>
                                    Add Approved Equipment
                                </Button>
                            )}
                            {!isMobile && <Button onClick={onClose}>Cancel</Button>}
                        </div>
                    </SideNavFooter>
                )}
            </SideNavContent>
        );
    }
);

export default AddApprovedEquipment;
