import React from "react";
import DropDownInput from "components/ui/Input/DropDownInput";
import TextLabel from "components/ui/Label/TextLabel";
import { formatFullName } from "components/utils/user";
import { User, ClientRole } from "./RolesManagement/types";

import "./SystemUserInfoBlock.scss";

export const SystemUserInfoBlock = ({ user, clientRoleName, clientRoleId, clientRoles, onChangeClientRole }: SystemUserInfoBlockProps) => {
    return (
        <div className="system-user-info-block flex-row no-shrink">
            <HeaderItem title="Client" value={user.client} />
            <HeaderItem title="Client Role" value={clientRoleName}>
                {onChangeClientRole && Array.isArray(clientRoles) && (
                    <DropDownInput
                        // @ts-ignore
                        mobileHeader="Select Client Role"
                        value={clientRoleId}
                        onChange={(e: { target: { value: number } }) => onChangeClientRole(e.target.value)}
                        data={clientRoles.map(({ clientRoleId, roleName }) => ({
                            label: roleName,
                            value: clientRoleId,
                        }))}
                        placeholder="Select Client Role"
                        withPopper
                    />
                )}
            </HeaderItem>
            <HeaderItem title="User Name (ID)" value={`${user.name} (${user.userNumber})`} />
            <HeaderItem title="First & Last Name" value={formatFullName(user.firstName, user.lastName)} />
            <HeaderItem title="Company" value={user.company} />
        </div>
    );
};

interface SystemUserInfoBlockProps {
    user: User;
    clientRoleName?: string;
    clientRoleId?: number;
    clientRoles?: ClientRole[];
    onChangeClientRole?: (clientRoleId: number) => void;
}

const HeaderItem = ({ title, value, children }: HeaderItemProps) => {
    return (
        <div className="system-user-info-block-item">
            <TextLabel>{title}</TextLabel>
            {children ?? <div className="system-user-info-block-value">{value}</div>}
        </div>
    );
};

interface HeaderItemProps {
    title: string;
    value?: string;
    children?: React.ReactNode;
}
