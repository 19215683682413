import React from "react";
import { PortalBuilderPropertiesCtx } from "./Properties";
import { PageCtx, PortalBuilderCtx } from "./types";

export const PortalBuilderContext = React.createContext<PortalBuilderCtx>({} as PortalBuilderCtx);
export const PortalBuilderPropertiesContext = React.createContext<PortalBuilderPropertiesCtx>({});
export const PageContext = React.createContext<PageCtx>({});
export const RowContext = React.createContext<any>({});
export const BorderPropertyContext = React.createContext<any>({});
export const HeadingPropertyContext = React.createContext<any>({});
export const PaddingPropertyContext = React.createContext<any>({});
export const MarginPropertyContext = React.createContext<any>({});
export const BorderRadiusPropertyContext = React.createContext<any>({});
