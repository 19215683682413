import React, { memo, useCallback } from "react";
import cn from "classnames";

import debouncedTextInput from "components/utils/debouncedTextInput";
import TextInput from "components/ui/Input/TextInput";
import Checkbox from "components/ui/Input/Checkbox";
import IconWithLabel from "components/ui/Icons/IconWithLabel";
import { RolesListCell } from "./RolesListCell";
import { RolesListRow } from "./RolesListRow";
import { RolesListScrollableCells } from "./RolesListScrollableCells";
import IconWrap from "components/ui/Icons";
import { RoleButton } from "./RoleButton";

export const DebouncedTextInput = debouncedTextInput(TextInput, {
    timeout: 1000,
});

export const RolesListHeaderRow = memo(
    ({
        manageSelectedItemsTooltip,
        isAnyItemSelected,
        listItemsTitle,
        manageSelectedItemsTitle,
        roleHeaderButtonTitle,
        selectAllState,
        filteredListItemsLength,
        filterPlaceholder,
        showItemSelection,
        roles,
        itemFilter,
        setItemFilter,
        filterByRole,
        setFilterByRole,
        onManageSelectedItems,
        onManageItems,
        onSelectAll,
        onExportList,
        onImportSelection,
        onHeaderRoleClick,
    }) => {
        const onFilterByRoleClick = useCallback(
            (event, role) => {
                const isChecked = event.target.checked;

                setFilterByRole((prev) => ({
                    ...prev,
                    [role.roleId]: isChecked,
                }));
            },
            [setFilterByRole]
        );

        return (
            <RolesListRow className="roles-list-row--header no-shrink">
                <RolesListCell className="roles-list-cell--header roles-list-cell--static flex-column">
                    <div className="roles-list-item-controls flex-row align-center">
                        {onManageSelectedItems ? (
                            <>
                                <IconWithLabel
                                    icon="edit-empty"
                                    title={manageSelectedItemsTooltip}
                                    disabled={!isAnyItemSelected}
                                    onClick={onManageItems}
                                >
                                    {manageSelectedItemsTitle}
                                </IconWithLabel>
                                <span className="flex-one" />
                                <IconWrap icon="download" title="Export list" onClick={onExportList} />
                                <IconWrap icon="upload" title="Import selection" onClick={onImportSelection} />
                            </>
                        ) : (
                            <span className="flex-one" />
                        )}
                        <RolesListHeaderTitle className="flex-row align-center justify-end">
                            {listItemsTitle}
                            <span className="roles-list-item-count">{filteredListItemsLength}</span>
                        </RolesListHeaderTitle>
                    </div>
                    <div className="roles-list-item-controls flex-row align-center">
                        {showItemSelection && (
                            <Checkbox labelIconBig title="Select All" checked={selectAllState} onChange={(e) => onSelectAll(e)} />
                        )}
                        <DebouncedTextInput
                            className="fill-width"
                            inline
                            labelInside={filterPlaceholder}
                            icon="search"
                            iconRight
                            value={itemFilter}
                            placeholder={filterPlaceholder}
                            onChange={(event) => setItemFilter(event.target.value)}
                        />
                    </div>
                </RolesListCell>
                <RolesListScrollableCells scrollControls>
                    {roles.map((role, index) => (
                        <RolesListCell key={index} className="roles-list-cell--header flex-column">
                            <RoleButton
                                roleName={role.roleName}
                                isAnyItemSelected={isAnyItemSelected}
                                roleButtonTitle={roleHeaderButtonTitle}
                                onClick={onHeaderRoleClick ? () => onHeaderRoleClick(role) : undefined}
                            />
                            <Checkbox
                                className="roles-list-role-filter"
                                iconLabelEmpty
                                labelIconBig
                                title={`Filter items by ${role.roleName} role`}
                                checked={filterByRole[role.roleId] ?? false}
                                onChange={(event) => onFilterByRoleClick(event, role)}
                            />
                        </RolesListCell>
                    ))}
                </RolesListScrollableCells>
            </RolesListRow>
        );
    }
);

const RolesListHeaderTitle = ({ className, children }) => {
    return <div className={cn("roles-list-header-title", className)}>{children}</div>;
};
