import React, { useContext, useEffect, useState } from "react";
import { SectionHeader } from "../SectionHeader";
import { PageContext, PortalBuilderPropertiesContext } from "components/ui/PortalBuilder/contexts";
import IconWithLabel from "components/ui/Icons/IconWithLabel";
import { LeadImageView } from "./LeadImageView";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";

import "./LeadImageProperty.scss";

export const LeadImageProperty = (props) => {
    const [isConfigView, setIsConfigView] = useState(false);
    const { sectionIcon, sectionTitle } = useContext(PortalBuilderPropertiesContext);
    const [activeError] = usePortalBuilderState((state) => state.activeError);
    const { page } = useContext(PageContext);

    const { id, onChange, value = {} } = props;

    const containsActiveError = activeError?.id.includes(".leadImage.");

    useEffect(() => {
        if (containsActiveError) {
            setIsConfigView(true);
        } else if (activeError) {
            setIsConfigView(false);
        }
    }, [activeError, containsActiveError]);

    const subtitle = (
        <div className="flex-column align-center">
            <span>
                EDIT <b className="page-title">{page.title}</b> PAGE
            </span>
            <span> {props.viewTitle ? props.viewTitle : "CONFIGURE LEAD IMAGE"} </span>
        </div>
    );

    if (isConfigView) {
        return (
            <div className="lead-image-property portal-builder-properties-overlay-view flex-column fill-width">
                <SectionHeader
                    Icon={sectionIcon}
                    title={sectionTitle}
                    subtitle={subtitle}
                    underline
                    onGoBack={() => setIsConfigView(false)}
                />
                <div className="column-property flex-one-in-column">
                    <LeadImageView {...props} id={id} onChange={onChange} value={value} />
                </div>
            </div>
        );
    }

    return (
        <IconWithLabel
            className="lead-image-property-button flex-row justify-space-between"
            icon="shevron-small-right"
            iconWithLabelRight
            onClick={() => setIsConfigView(true)}
        >
            {props.viewTitle ? props.viewTitle : "CONFIGURE LEAD IMAGE"}
        </IconWithLabel>
    );
};
