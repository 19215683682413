import React, { useState } from "react";
import JsonSchemaForm from "../../JsonSchema/JsonSchemaForm";
import { uploadFile } from "../../../../../store/files/actions";

const TemplateFileUpload = (props) => {
    const { onClose, dispatch, entityId } = props;

    const [isSubmitting, setSubmitting] = useState(false);

    const schema = {
        type: "object",
        required: ["file"],
        properties: {
            file: {
                type: "array",
                title: "Upload File",
                items: {
                    type: "string",
                    format: "data-url",
                },
            },
        },
    };

    const uiSchema = {
        file: {
            "ui:elementType": "field",
            "ui:widget": "DropZoneWidget",
            "ui:options": {
                multiple: false,
            },
        },
    };

    const uploadMessage = (
        <p className="file-upload-message">
            Browse and select a file for upload. <br />
            This might take a few moments... Please be patient!
        </p>
    );

    const onFileUpload = (formData) => {
        setSubmitting(true);

        dispatch(
            uploadFile({
                fileData: {
                    ...formData,
                    entityTypeId: 904,
                    entityId: entityId,
                    fileTypeId: 27,
                    itemFilterId: 149,
                },
                onUploadSuccess: () => {
                    setSubmitting(false);
                    onClose();
                },
                onUploadError: () => {
                    setSubmitting(false);
                },
            })
        );
    };

    const getSubmitText = (isSubmitting) => {
        return isSubmitting ? "Uploading..." : "Upload";
    };

    return (
        <div className="program-template-file-upload-form">
            <div className="program-template-file-form">
                {uploadMessage}
                <JsonSchemaForm
                    schema={schema}
                    uiSchema={uiSchema}
                    onSubmit={onFileUpload}
                    onCancel={onClose}
                    submitText={getSubmitText(isSubmitting)}
                    withCancel
                    noReset
                    centeredFooter
                />
            </div>
        </div>
    );
};

export default TemplateFileUpload;
