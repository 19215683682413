import React from "react";

import "./SidebarHeader.scss";

const SidebarHeader = ({ sidebarHeaderTitle, sidebarHeaderContent, sidebarHeaderActions }) => {
    return (
        <div className="sidebar-header">
            <div className="sidebar-header-title">
                {sidebarHeaderTitle}
                {sidebarHeaderActions}
            </div>
            {sidebarHeaderContent}
        </div>
    );
};

export default SidebarHeader;
