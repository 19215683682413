import React, { useState, useCallback, memo } from "react";

import { onRefreshGrids } from "./utils";

import GlobalScansQueue from "./GlobalScansQueue";
import UserGroups from "./UserGroups";
import PageLeadBlock from "../../ui/PageLeadBlock";

import "./style.scss";

const ScanQueueManagement = memo(() => {
    const onRefresh = useCallback(() => {
        onRefreshGrids();
    }, []);

    const [isVisibleGlobalScanQueue, setIsVisibleGlobalScanQueue] = useState(false);

    return (
        <div className="panel scan-queue-management fill-height no-scroll">
            <PageLeadBlock>
                <div>
                    Manage <span>Scan Queue</span> and <span>User Groups</span>
                </div>
                <div className="view-switcher">
                    {!isVisibleGlobalScanQueue ? (
                        <div>
                            <span className="active">Global Scans Queue</span>
                            <span onClick={() => setIsVisibleGlobalScanQueue(!isVisibleGlobalScanQueue)}>Show User Groups</span>
                        </div>
                    ) : (
                        <div>
                            <span onClick={() => !setIsVisibleGlobalScanQueue(!isVisibleGlobalScanQueue)}>Show Global Scans Queue</span>
                            <span className="active">User Groups</span>
                        </div>
                    )}
                </div>
            </PageLeadBlock>
            <div className="main-grid-wrap responsive flex-row">
                <GlobalScansQueue visibility={isVisibleGlobalScanQueue} onRefresh={onRefresh} />
                <UserGroups visibility={!isVisibleGlobalScanQueue} onRefresh={onRefresh} />
            </div>
        </div>
    );
});

export default ScanQueueManagement;
