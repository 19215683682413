import React from "react";
import ClassNames from "classnames";

import "./style.scss";

const CounterBox = (props) => {
    const className = ClassNames("counter-box flex-row align-center justify-center", props.className, {
        "semi-transparent": props.counterBoxSemiTransparent,
    });

    return <div className={className}>{props.children}</div>;
};

export default CounterBox;
