import React, { useCallback, useContext, useState, memo } from "react";

import { BatchGridContext } from "../../context/BatchGridContext";
import { BatchList } from "./BatchList";
import { Controls } from "./Controls";
import { useItems } from "../../../../utils/useItems";
import { getResourceByPath } from "../../../../../store/resources/selectors";

export const BatchGrid = memo(() => {
    const { batchDocuments, selectedBatchNumbers, selectedBatchNumbersActions, selectedBatchDocumentNumbersActions, getBatchDocuments } =
        useContext(BatchGridContext);
    const [isSelectionDisabled, setSelectionDisabled] = useState(false);
    const [expandedBatches, expandedBatchesActions] = useItems();
    const [loadingBatchNumbers, loadingBatchNumbersActions] = useItems();

    const handleClickBatchRow = useCallback(
        (batchNumber) => {
            if (expandedBatches.includes(batchNumber)) {
                expandedBatchesActions.remove([batchNumber]);
            } else {
                expandedBatchesActions.add([batchNumber]);
            }

            if (!(batchNumber in batchDocuments)) {
                loadingBatchNumbersActions.add([batchNumber]);

                getBatchDocuments(batchNumber, () => loadingBatchNumbersActions.remove(batchNumber));
            }
        },
        [batchDocuments, getBatchDocuments, expandedBatches, loadingBatchNumbersActions, expandedBatchesActions]
    );

    const handleSelectBatch = useCallback(
        (batchNumber) => {
            if (!selectedBatchNumbers.includes(batchNumber)) {
                if (!expandedBatches.includes(batchNumber)) {
                    expandedBatchesActions.add([batchNumber]);
                }

                selectedBatchNumbersActions.add([batchNumber]);

                if (batchNumber in batchDocuments) {
                    const batchDocumentNumbers = batchDocuments[batchNumber].map(({ documentNumber, programNumber }) => ({
                        documentNumber,
                        programNumber,
                    }));

                    selectedBatchDocumentNumbersActions.add(batchDocumentNumbers);
                } else {
                    setSelectionDisabled(true);
                    loadingBatchNumbersActions.add([batchNumber]);

                    getBatchDocuments(batchNumber, () => {
                        const batchDocuments = getResourceByPath("documentQueueBatchEntries.itemsById")[batchNumber];
                        const batchDocumentNumbers = batchDocuments.map(({ documentNumber, programNumber }) => ({
                            documentNumber,
                            programNumber,
                        }));

                        setSelectionDisabled(false);
                        loadingBatchNumbersActions.remove([batchNumber]);
                        selectedBatchDocumentNumbersActions.add(batchDocumentNumbers);
                    });
                }
            } else {
                selectedBatchNumbersActions.remove([batchNumber]);

                if (batchNumber in batchDocuments) {
                    const batchDocumentNumbers = batchDocuments[batchNumber].map(({ documentNumber }) => documentNumber);

                    selectedBatchDocumentNumbersActions.remove(batchDocumentNumbers);
                }
            }
        },
        [
            batchDocuments,
            expandedBatches,
            expandedBatchesActions,
            selectedBatchNumbers,
            loadingBatchNumbersActions,
            selectedBatchNumbersActions,
            selectedBatchDocumentNumbersActions,
            getBatchDocuments,
        ]
    );

    return (
        <div className="application-document-queue__batch-grid flex-column fill-height">
            <div className="application-document-queue__batch-grid-wrapper flex-column fill-height">
                <Controls isSelectionDisabled={isSelectionDisabled} />
                <BatchList
                    expandedBatches={expandedBatches}
                    loadingBatchNumbers={loadingBatchNumbers}
                    onClickBatchRow={handleClickBatchRow}
                    onSelectBatch={handleSelectBatch}
                    isSelectionDisabled={isSelectionDisabled}
                />
            </div>
        </div>
    );
});
