import React, { useContext, useEffect, useState } from "react";
import Separator from "components/ui/Separator";

import { SectionInfo } from "../SectionInfo";
import StatusMsg from "components/ui/StatusMsg";
import cn from "classnames";
import { isNil, isObject } from "lodash";
import { PortalBuilderContext } from "../../contexts";
import { datePartFromJsonDate, dateToJson, formatJsonDateTime } from "components/utils/date";
import "./RevisionsSection.scss";
import IconWithLabel from "components/ui/Icons/IconWithLabel";
import { getPortalProgramTemplateRevisionResourceParams, getPortalTemplateRevisionResourceParams } from "store/configureResources";
import { getResource } from "store/resources/actions";
import { useDispatch } from "react-redux";
import { transformProgramPortalConfig, transformUtilityPortalConfig } from "../../utils/page";
import DatePicker from "components/ui/Input/DatePicker";
import WaitIcon from "components/ui/WaitIcon";
import GridListPaging from "components/ui/List/GridList/GridListPaging";
import { PortalConfigurationResponse } from "../../utils/useProgramPortalConfiguration";
import { Revision, UtilityPortalConfigurationResponse } from "../../types";
import { openConfirmModal } from "components/ui/Modal/utils";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";
import TextInput from "components/ui/Input/TextInput";
import Button from "components/ui/Button";
import { availableGrids, revisionsGridColumnKeys } from "components/views/configureGrids";
import { getResourcePromise } from "store/resources/useResource";
import { mapGridDataToObjects } from "components/utils/datagrid";
import { toArray } from "components/utils/array";

export const RevisionsSection = () => {
    const { onConfigChange, onConfigSave, template, isConfigChanged } = useContext(PortalBuilderContext);
    const [utilityNumber] = usePortalBuilderState((state) => state.utilityNumber);
    const [programNumber] = usePortalBuilderState((state) => state.programNumber);
    const [isProgramPortalBuilder] = usePortalBuilderState((state) => state.isProgramPortalBuilder);
    const [revisionData, setPortalBuilderState] = usePortalBuilderState((state) => state.revisionData);
    const [selectedUtilityTemplate] = usePortalBuilderState((state) => state.selectedUtilityTemplate);

    const [selectedDate, setSelectedDate] = useState(undefined);
    const [selectedUsername, setSelectedUsername] = useState(null);
    const templateNumber: string = template?.portalTemplateNumber ?? selectedUtilityTemplate?.utilityTemplateNumber;

    const [revisions, isLoadingRevisions, totalNumberOfRevisions] = useRevisions(
        revisionData,
        programNumber,
        templateNumber,
        isProgramPortalBuilder,
        isConfigChanged
    );

    const dispatch = useDispatch();

    const transform = (data: PortalConfigurationResponse | UtilityPortalConfigurationResponse, portalTemplateNumber: string) => {
        if (isObject(data)) {
            if (isProgramPortalBuilder) {
                return transformProgramPortalConfig(data as PortalConfigurationResponse);
            } else {
                return transformUtilityPortalConfig(data as UtilityPortalConfigurationResponse, portalTemplateNumber);
            }
        }
        return data;
    };

    const onSelectPage = (e: { page: { skip: number } }) => {
        setPortalBuilderState({ revisionData: { ...revisionData, skip: e.page.skip } });
    };

    const onSuccessProgram = (data: PortalConfigurationResponse, shouldRestore: boolean) => {
        onConfigChange(data.programTemplateConfiguration);
        if (shouldRestore) {
            onConfigSave(data.programTemplateConfiguration);
            setPortalBuilderState({ revisionData: { ...revisionData, selectedRevision: null } });
        }
    };

    const onSuccessUtility = (data: UtilityPortalConfigurationResponse, shouldRestore: boolean) => {
        onConfigChange(data.configuration);
        if (shouldRestore) {
            onConfigSave(data.configuration);
            setPortalBuilderState({ revisionData: { ...revisionData, selectedRevision: null } });
        }
    };

    const onSelectRevision = (r: Revision, shouldRestore: boolean) => {
        const portalTemplateNumber = r.portaltemplatenumber;
        const portalTemplateRevisionNumber = r.portaltemplaterevisionnumber;

        const resourceParams = isProgramPortalBuilder
            ? getPortalProgramTemplateRevisionResourceParams({ programNumber, portalTemplateRevisionNumber })
            : getPortalTemplateRevisionResourceParams({ utilityNumber, portalTemplateNumber, portalTemplateRevisionNumber });
        dispatch(
            //@ts-ignore
            getResource({
                ...resourceParams,
                key: portalTemplateRevisionNumber,
                onSuccess: ({ data }: { data: PortalConfigurationResponse | UtilityPortalConfigurationResponse }) => {
                    setPortalBuilderState({ revisionData: { ...revisionData, selectedRevision: portalTemplateRevisionNumber } });
                    if (isProgramPortalBuilder) {
                        onSuccessProgram(data as PortalConfigurationResponse, shouldRestore);
                    } else {
                        onSuccessUtility(data as UtilityPortalConfigurationResponse, shouldRestore);
                    }
                },
                transform: (data: PortalConfigurationResponse) => transform(data, portalTemplateNumber),
            })
        );
    };

    const onRestoreRevision = (r: Revision) => {
        //@ts-ignore
        openConfirmModal({
            title: "Restore Revision",
            message: "Are you sure you want to restore this revision?",
            onConfirm: () => {
                onSelectRevision(r, true);
            },
        });
    };

    return (
        <div className="revisions-section-portal-builder flex-column fill-height fill-width flex-one-in-column">
            <SectionInfo>
                <StatusMsg msgIcon="info-empty" msgText={"Preview and/or restore the previous versions of this template"} />
                <Separator line marginBottom={0} marginTop={15} />
            </SectionInfo>

            <div className="revisions-section-flex-container flex-column">
                <div className="revisions-filters flex-column">
                    <TextInput
                        //@ts-ignore
                        icon="search"
                        iconRight
                        value={selectedUsername ?? revisionData.selectedUsername ?? undefined}
                        placeholder="Filter By User Name"
                        onChange={(event: any) => setSelectedUsername(event.target.value)}
                    />
                    <DatePicker
                        //@ts-ignore
                        labelInside="Filter By Date"
                        value={selectedDate === undefined ? revisionData.selectedDate : selectedDate}
                        placeholder="Filter By Date"
                        onChange={(event: any) => {
                            setSelectedDate(event.value);
                        }}
                    />

                    <Button
                        disabled={revisionData.selectedDate === selectedDate && revisionData.selectedUsername === selectedUsername}
                        onClick={() =>
                            setPortalBuilderState({ revisionData: { ...revisionData, selectedDate, selectedUsername, skip: 0 } })
                        }
                    >
                        Apply Filters
                    </Button>
                </div>
                {revisions.length === 0 && !isLoadingRevisions && <div className="no-revisions-found">No revisions found.</div>}
                {isLoadingRevisions ? (
                    <div className="flex-row justify-center">
                        <WaitIcon withSpaceAround />
                    </div>
                ) : (
                    <>
                        <div className="revisions-items-container with-scroll">
                            <div className="align-center revisions-container">
                                {revisions.map((r: Revision) => (
                                    <div
                                        key={r.portaltemplaterevisionnumber}
                                        className={cn("revision-item fill-width", {
                                            selected: r.portaltemplaterevisionnumber === revisionData.selectedRevision,
                                        })}
                                    >
                                        <div className="revision-item-data">
                                            <span className="item-label">Revision date:</span>
                                            <div className="item-value">{formatJsonDateTime(r.revisiondate)}</div>
                                        </div>
                                        <div className="revision-item-data">
                                            <span className="item-label">Revision number:</span>
                                            <div className="item-value">{formatJsonDateTime(r.portaltemplaterevisionnumber)}</div>
                                        </div>
                                        <div className="revision-item-data">
                                            <span className="item-label">Username:</span>
                                            <div className="item-value">{r.username}</div>
                                        </div>
                                        <div className="revision-actions">
                                            <IconWithLabel
                                                disabled={r.portaltemplaterevisionnumber === revisionData.selectedRevision}
                                                onClick={() => onSelectRevision(r, false)}
                                            >
                                                Preview
                                            </IconWithLabel>
                                            <IconWithLabel onClick={() => onRestoreRevision(r)}>Restore</IconWithLabel>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <GridListPaging
                            //@ts-ignore
                            skip={revisionData.skip}
                            pageAmountToTruncate={3}
                            take={20}
                            total={totalNumberOfRevisions}
                            compact
                            onPageChange={onSelectPage}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export const useRevisions = (
    revisionData: any,
    programNumber: string | undefined,
    templateNumber: string,
    isProgramPortalBuilder: boolean,
    isConfigChanged: boolean
): [revisions: Revision[], isLoadingRevisions: boolean, totalNumberOfRevisions: number] => {
    const [totalNumberOfRevisions, setTotalNumberOfRevisions] = useState(0);
    const [isLoadingRevisions, setIsLoadingRevisions] = useState(false);
    const [revisions, setRevisions] = useState<Revision[]>([]);

    const pageNum = revisionData.skip / 20 + 1;
    let searchAttr: string;
    if (isProgramPortalBuilder) {
        searchAttr =
            `${revisionsGridColumnKeys.entitytype}=program|${revisionsGridColumnKeys.entityid}=${programNumber}` +
            (revisionData.selectedDate
                ? `|${revisionsGridColumnKeys.revisiondate}=${datePartFromJsonDate(dateToJson(revisionData.selectedDate))}`
                : "") +
            (revisionData.selectedUsername ? `|${revisionsGridColumnKeys.username}=${revisionData.selectedUsername}` : "");
    } else {
        searchAttr =
            `${revisionsGridColumnKeys.portaltemplatenumber}=${templateNumber}|` +
            (revisionData.selectedDate
                ? `|${revisionsGridColumnKeys.revisiondate}=${datePartFromJsonDate(dateToJson(revisionData.selectedDate))}`
                : "") +
            (revisionData.selectedUsername ? `|${revisionsGridColumnKeys.username}=${revisionData.selectedUsername}` : "");
    }

    useEffect(() => {
        setIsLoadingRevisions(true);

        getResourcePromise({
            resourceName: "grid",
            resourceId: availableGrids.revisions,
            query: {
                searchAttr,
                pageNum,
                recsPerPage: 20,
                sortBy: revisionsGridColumnKeys.revisiondate,
                sortAsc: 0,
            },
        })
            .then((response: any) => {
                if (!isNil(response.grid.rows)) {
                    const rows = toArray(response.grid.rows);
                    const newRevisions = mapGridDataToObjects(revisionsGridColumnKeys, rows);
                    setRevisions(newRevisions);
                    setTotalNumberOfRevisions(rows[0]?.totRecords);
                } else {
                    setRevisions([]);
                    setTotalNumberOfRevisions(0);
                }
            })
            .finally(() => {
                setIsLoadingRevisions(false);
            });
    }, [pageNum, searchAttr, isConfigChanged]);

    return [revisions, isLoadingRevisions, totalNumberOfRevisions];
};
