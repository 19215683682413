import React, { useCallback, memo, useContext, useEffect, useRef } from "react";

import WaitIcon from "../../../../WaitIcon";
import { TaskWorkflowContext } from "../../../context/TaskWorkflowContext";
import { ContactEditSidePanel } from "components/ui/Dashboard/Panel/Project/ContactsPanel/ContactEditSidePanel";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import useUnmounted from "components/utils/useUnmounted";
import { useApplicationAssignedContacts } from "store/resources/useResource";

const CreateContactTask = memo(({ utilityNumber, applicationNumber, task, onSuccess, onCancel, disabled }) => {
    const isPanelOpened = useRef(false);
    const { programNumber } = useContext(TaskWorkflowContext);
    const unmounted = useUnmounted();

    const [premiseContact, primaryContact, contractorContact, isLoadingAssignedContacts] = useApplicationAssignedContacts({
        applicationNumber,
    });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({ size: sideNavSize.small });

    const onCloseAddContact = useCallback(() => {
        handleCloseSidePanel();
        onCancel();
    }, [handleCloseSidePanel, onCancel]);

    const onCreate = useCallback(() => {
        handleCloseSidePanel();
        onSuccess();
    }, [handleCloseSidePanel, onSuccess]);

    useEffect(() => {
        if (!isPanelOpened.current) {
            // Workaround to not reopen panel if Parent component is remounting
            setTimeout(() => {
                if (!unmounted.current) {
                    isPanelOpened.current = true;
                    handleOpenSidePanel(
                        <ContactEditSidePanel
                            isNew
                            applicationNumber={applicationNumber}
                            programNumber={programNumber}
                            utilityNumber={utilityNumber}
                            premiseContact={premiseContact}
                            primaryContact={primaryContact}
                            contractorContact={contractorContact}
                            title={"Add New Contact"}
                            icon="plus"
                            onClose={onCloseAddContact}
                            onSuccess={onCreate}
                            isSubmitting={disabled}
                        />,
                        {
                            onClose: onCancel,
                        }
                    );
                }
            }, 0);
        }
    }, [
        applicationNumber,
        disabled,
        onCloseAddContact,
        handleOpenSidePanel,
        onCreate,
        unmounted,
        programNumber,
        utilityNumber,
        premiseContact,
        primaryContact,
        contractorContact,
        onCancel,
    ]);

    if (isLoadingAssignedContacts) {
        return <WaitIcon />;
    }

    return null;
});

export default CreateContactTask;
