import React from "react";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import ApplicationForm from "./ApplicationForm";

const ApplicationEditPanel = ({ formId, programNumber, applicationNumber, applicationFormId, formTitle, loading, onClose }) => {
    return (
        <div className="panel applications">
            <PanelHeaderLarge title="Applications" childTitle={'Edit "' + formTitle + '" Form'} onClick={onClose} />
            <div className="application-form-wrap">
                <ApplicationForm
                    formId={formId}
                    programNumber={programNumber}
                    applicationNumber={applicationNumber}
                    applicationFormId={applicationFormId}
                    loading={loading}
                    editMode
                    onSubmitSuccess={onClose}
                    onCancel={onClose}
                    hasStickyBottomPanel
                    formTitle={formTitle}
                />
            </div>
        </div>
    );
};

export default ApplicationEditPanel;
