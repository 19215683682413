import { programsGridColumnKeys } from "../../../../views/configureGrids";

export const getProgramsGridId = ({ utilityNumber }) => {
    return utilityNumber ? `${utilityNumber}-programs-grid` : "programs";
};

export const getProgramsGridExportFileName = ({ utilityNumber }) => {
    return utilityNumber ? `utility_programs_${utilityNumber}` : "programs";
};

export const getProgramsGridFilter = ({ utilityNumber }) => {
    return utilityNumber ? `${programsGridColumnKeys.utilityNumber}=${utilityNumber}` : "";
};
