import { useLayoutEffect, useCallback, useEffect } from "react";
import { useResizeDetector } from "react-resize-detector";

import { useWindowResize } from "components/utils/useWindowResize";

export const SizeMeasureItem = ({ listRef, itemCount, itemHeight, onResize }) => {
    // Hide container scrollbar showing up while resizing list
    useLayoutEffect(() => {
        const containerRef = listRef?.parentElement;
        if (containerRef) {
            containerRef.style.overflowY = "hidden";
        }

        return () => {
            if (containerRef) {
                containerRef.style.overflowY = undefined;
            }
        };
    }, [listRef]);

    // Listen for window resize
    const size = useWindowResize();

    useEffect(() => {
        updateVirtualListHeight({ listRef, itemHeight, itemCount, onResize });
    }, [listRef, itemHeight, itemCount, onResize, size]);

    // Listen for container resize
    const onContainerResize = useCallback(() => {
        updateVirtualListHeight({ listRef, itemHeight, itemCount, onResize });
    }, [listRef, itemHeight, itemCount, onResize]);

    useResizeDetector({
        handleWidth: false,
        targetRef: listRef,
        onResize: onContainerResize,
    });

    return null;
};

export const updateVirtualListHeight = ({ listRef, itemHeight, itemCount, onResize }) => {
    if (listRef) {
        const containerHeight = getVirtualListHeight({
            listRef,
            itemHeight,
            itemCount,
        });

        // Update only if list is visible ( view active )
        if (listRef.offsetParent !== null) {
            onResize(containerHeight);
        }
    }
};

export const getVirtualListHeight = ({ listRef, itemHeight, itemCount, estimatedListHeight }) => {
    if (listRef) {
        const maxListHeight = estimatedListHeight ?? itemCount * itemHeight;

        const containerRef = listRef.parentElement;
        let listHeight = containerRef?.clientHeight ?? 0;

        // Do nothing if list is not visible ( view not active )
        if (listRef.offsetParent === null) {
            return listHeight;
        }

        // Case when max height is greater than actual height of list
        if (listHeight < maxListHeight) {
            let containerHeight = containerRef.clientHeight ?? 0;
            let containerScrollHeight = containerRef.scrollHeight ?? 0;

            // Allow few pixel difference to count as similar
            // Sometimes there is 1px difference that can break available height calculation
            const threshold = 2;

            // Determine max available height
            if (Math.abs(containerHeight - containerScrollHeight) < threshold) {
                // Increase list height while container scollbar is not showing up
                while (Math.abs(containerHeight - containerScrollHeight) < threshold && listHeight < maxListHeight) {
                    listHeight += itemHeight;
                    listRef.style.height = listHeight + "px";

                    containerHeight = containerRef.clientHeight ?? 0;
                    containerScrollHeight = containerRef.scrollHeight ?? 0;

                    // Break loop if container does not resize
                    if (listHeight > 0 && containerHeight === 0) {
                        break;
                    }
                }
            }

            // Take max container height without scroll as available height
            containerHeight = containerRef.clientHeight ?? 0;
            listRef.style.height = containerHeight + "px";

            return containerHeight;
        }
        // Case when list is shorter than container height
        else if (maxListHeight < listHeight) {
            listRef.style.height = maxListHeight + "px";
            return maxListHeight;
        }

        return listHeight;
    }

    return 0;
};
