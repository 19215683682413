import React, { useRef, useCallback, useState, memo, useEffect } from "react";
import { useDispatch } from "react-redux";

import { submitByRef } from "../../../../../../utils/form";
import { getData } from "../../../../../../../store/dataGrid/actions";
import { createResource, updateResource } from "../../../../../../../store/resources/actions";
import { decodeHtml } from "../../../../../../utils/string";
import { mapGridRowToObject } from "../../../../../../utils/datagrid";
import { programsFastTagsGridColumnKeys } from "../../../../../../views/configureGrids";

import JsonSchemaForm from "../../../../../Form/JsonSchema/JsonSchemaForm";
import Button from "../../../../../Button";
import SideNavHeader from "../../../../../SideNav/SideNavHeader";
import SideNavBody from "../../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../../SideNav/SideNavFooter";
import SideNavContent from "../../../../../SideNav/SideNavContent";

import "./FastTagForm.scss";
import { isChildProgram } from "components/views/ProgramView/utils";

const FastTagForm = memo(({ programNumber, gridId, dataItem, tagForImage, imageTagValue, onClose, sidePanel }) => {
    const dispatch = useDispatch();

    const formRef = useRef();
    const isNew = dataItem == null;

    const [isSubmitting, setIsSubmitting] = useState(false);

    let initialValues = dataItem ? mapGridRowToObject(programsFastTagsGridColumnKeys, dataItem) : tagForImage ? imageTagValue : {};

    if (initialValues && initialValues.tagValue) {
        initialValues.tagValue = decodeHtml(initialValues.tagValue);
    }

    const schema = {
        type: "object",
        required: ["tagName", "tagValue"],
        properties: {
            tagName: {
                type: "string",
                title: "Name",
            },
            tagValue: {
                type: "string",
                title: "Value",
            },
        },
    };

    const uiSchema = {
        tagValue: {
            classNames: "fill-width",
            "ui:widget": "textarea",
        },
    };

    const handleSave = useCallback(() => {
        submitByRef(formRef);
    }, []);

    const onSuccess = useCallback(() => {
        sidePanel.close();

        dispatch(
            getData({
                dataGridId: gridId,
            })
        );
    }, [gridId, dispatch, sidePanel]);

    const handleSubmit = useCallback(
        (formData) => {
            setIsSubmitting(true);

            if (isNew) {
                dispatch(
                    createResource({
                        resourceName: "fasttags",
                        body: {
                            ...formData,
                            entityNumber: programNumber,
                            entityType: "program",
                        },
                        onSuccess,
                        onComplete: () => {
                            setIsSubmitting(false);
                        },
                    })
                );
            } else {
                dispatch(
                    updateResource({
                        resourceName: "fasttags",
                        resourceId: formData.number,
                        path: {
                            fasttagNumber: formData.number,
                        },
                        body: {
                            ...formData,
                        },
                        onSuccess,
                        onComplete: () => {
                            setIsSubmitting(false);
                        },
                    })
                );
            }
        },
        [isNew, programNumber, onSuccess, dispatch]
    );

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    const isLocked = isChildProgram({ programNumber });

    return (
        <SideNavContent className="fast-tag-form-sidenav">
            <SideNavHeader
                title={isNew ? "Add FastTag" : isLocked ? "View FastTag" : "Edit FastTag"}
                leadBlockIcon={isNew ? "plus" : isLocked ? "eye-visibility-empty" : "edit-empty"}
                smallHeader
                onClose={onClose}
            />
            <SideNavBody>
                <JsonSchemaForm
                    formRef={formRef}
                    schema={schema}
                    uiSchema={uiSchema}
                    disabled={isLocked}
                    initialValues={initialValues}
                    isSubmitting={isSubmitting}
                    onSubmit={handleSubmit}
                    noSubmit
                    noReset
                    noClose
                />
            </SideNavBody>
            <SideNavFooter justifyEnd={isLocked} setPrimaryButton={!isLocked}>
                {!isLocked && (
                    <Button primary onClick={handleSave} disabled={isSubmitting}>
                        Save
                    </Button>
                )}
                <Button onClick={onClose}>{isLocked ? "Close" : "Cancel"}</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default FastTagForm;
