import * as actionTypes from "../actionTypes";

const initialState = {
    applicationStatus: {},
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PROGRAMS_SET_APPLICATION_STATUS:
            state = {
                ...state,
                applicationStatus: {
                    ...state.applicationStatus,
                    [action.programNumber]: action.applicationStatus,
                },
            };
            break;
        default:
            break;
    }

    return state;
};
