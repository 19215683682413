import React, { memo } from "react";
import cn from "classnames";
import WaitIcon from "components/ui/WaitIcon";

export const RoleButton = memo(
    ({
        className,
        roleName,
        highlight,
        isAnyItemSelected,
        roleAssignments,
        isLoading,
        roleButtonTitle,
        roleBadgeTitle,
        onClick,
        onBadgeClick,
    }) => {
        const title = roleButtonTitle
            ? roleButtonTitle({
                  isHighlighted: highlight,
                  roleName,
                  isAnyItemSelected,
              })
            : null;
        const badgeTitle = roleBadgeTitle ? roleBadgeTitle({ roleAssignments, roleName }) : null;

        return (
            <div
                className={cn("roles-list-role-btn", className, {
                    "roles-list-role-btn--highlight": highlight,
                    "roles-list-role-btn--loading": isLoading,
                    "roles-list-role-btn--clickable": onClick,
                })}
                onClick={onClick}
                title={title}
            >
                <div className="roles-list-role-btn-title">{roleName}</div>
                {isLoading && <WaitIcon />}
                {roleAssignments > 0 && (
                    <ButtonBadge title={badgeTitle} onClick={onBadgeClick}>
                        {roleAssignments}
                    </ButtonBadge>
                )}
            </div>
        );
    }
);

const ButtonBadge = ({ title, children, onClick }) => {
    return (
        <div className="btn-badge" title={title} onClick={onClick}>
            {children}
        </div>
    );
};
