import React from "react";
import TextInput from "components/ui/Input/TextInput";
import debouncedTextInput from "components/utils/debouncedTextInput";

export const DebouncedTextInput = debouncedTextInput(TextInput, { timeout: 500 });

export const EntitiesColumnHeader = ({ searchTerm, onSearch }: EntitiesColumnHeaderProps) => {
    const icon = searchTerm ? "clear-close" : "search";
    const onIconClick = searchTerm ? () => onSearch("") : undefined;

    return (
        <div className="roles-management-column-header roles-management-column-header--entities flex-column no-shrink">
            <div className="roles-management-header-column-title">Utilities & Programs</div>
            <DebouncedTextInput
                className="fill-width"
                inline
                icon={icon}
                iconRight
                value={searchTerm}
                placeholder="Search for utility or program"
                onChange={(event: { target: { value: string } }) => onSearch(event.target.value)}
                onIconClick={onIconClick}
            />
        </div>
    );
};

interface EntitiesColumnHeaderProps {
    searchTerm: string;
    onSearch: (searchTerm: string) => void;
}
