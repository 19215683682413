import * as actionTypes from "../actionTypes";
import { DEFAULT_SUB_CRITERIA, DEFAULT_MAIN_CRITERIA } from "./utils";

const initialState = {
    selectedMainCriteria: {},
    selectedSubCriteria: {},
    criteriaValues: {},
    criteriaList: {},
    pageNumber: {},
    pageSize: {},
    isSearching: {},
    errorMessage: {},
    isSearchInputExpanded: {},
    selectedUtility: {},
    selectedProgram: {},
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_GLOBAL_SEARCH_MAIN_CRITERA:
            state = {
                ...state,
                selectedMainCriteria: {
                    ...state.selectedMainCriteria,
                    [action.instanceId]: action.criteria,
                },
                selectedSubCriteria: {
                    ...state.selectedSubCriteria,
                    [action.instanceId]: [DEFAULT_SUB_CRITERIA],
                },
                criteriaValues: {
                    ...state.criteriaValues,
                    [action.instanceId]: {},
                },
            };
            break;
        case actionTypes.SET_GLOBAL_SEARCH_SUB_CRITERA:
            state = {
                ...state,
                selectedSubCriteria: {
                    ...state.selectedSubCriteria,
                    [action.instanceId]: action.criteria,
                },
            };
            break;
        case actionTypes.SET_GLOBAL_SEARCH_CRITERIA_VALUE:
            state = {
                ...state,
                criteriaValues: {
                    ...state.criteriaValues,
                    [action.instanceId]: {
                        ...(state.criteriaValues[action.instanceId] || {}),
                        [action.criteriaKey]: action.value,
                        [`${action.criteriaKey}-title`]: action.valueTitle,
                    },
                },
            };
            break;
        case actionTypes.SET_GLOBAL_SEARCH_UTILITY:
            state = {
                ...state,
                selectedUtility: {
                    ...state.selectedUtility,
                    [action.instanceId]: {
                        utilityNumber: action.utilityNumber,
                        utilityName: action.utilityName,
                        utilityKey: action.utilityKey,
                    },
                },
            };
            break;
        case actionTypes.SET_GLOBAL_SEARCH_PROGRAM:
            state = {
                ...state,
                selectedProgram: {
                    ...state.selectedProgram,
                    [action.instanceId]: {
                        programNumber: action.programNumber,
                        programName: action.programName,
                        programKey: action.programKey,
                    },
                },
            };
            break;
        case actionTypes.SET_GLOBAL_SEARCH_CRITERIA_LIST:
            state = {
                ...state,
                criteriaList: {
                    ...state.criteriaList,
                    [action.instanceId]: action.criteriaList,
                },
            };

            if (!state.isSearchInputExpanded[action.instanceId]) {
                state = {
                    ...state,
                    selectedMainCriteria: {
                        ...state.selectedMainCriteria,
                        [action.instanceId]: DEFAULT_MAIN_CRITERIA,
                    },
                    selectedSubCriteria: {
                        ...state.selectedSubCriteria,
                        [action.instanceId]: [DEFAULT_SUB_CRITERIA],
                    },
                    criteriaValues: {
                        ...state.criteriaValues,
                        [action.instanceId]: {},
                    },
                    selectedUtility: {
                        ...state.selectedUtility,
                        [action.instanceId]: {},
                    },
                    selectedProgram: {
                        ...state.selectedProgram,
                        [action.instanceId]: {},
                    },
                };
            }

            break;
        case actionTypes.SET_GLOBAL_SEARCH_PAGE_NUMBER:
            state = {
                ...state,
                pageNumber: {
                    ...state.pageNumber,
                    [action.instanceId]: action.pageNumber,
                },
            };
            break;
        case actionTypes.SET_GLOBAL_SEARCH_PAGE_SIZE:
            state = {
                ...state,
                pageSize: {
                    ...state.pageSize,
                    [action.instanceId]: action.pageSize,
                },
            };
            break;
        case actionTypes.SET_GLOBAL_SEARCH_IS_SEARCHING:
            state = {
                ...state,
                isSearching: {
                    ...state.isSearching,
                    [action.instanceId]: action.isSearching,
                },
            };
            break;
        case actionTypes.SET_GLOBAL_SEARCH_ERROR:
            state = {
                ...state,
                errorMessage: {
                    ...state.errorMessage,
                    [action.instanceId]: action.errorMessage,
                },
            };
            break;
        case actionTypes.SET_GLOBAL_SEARCH_AREA_EXPANDED:
            state = {
                ...state,
                isSearchInputExpanded: {
                    ...state.isSearchInputExpanded,
                    [action.instanceId]: action.isExpanded,
                },
            };
            break;
        default:
            break;
    }

    return state;
};
