import React, { useState } from "react";
import SelectWidget from "../../../../Form/JsonSchema/widgets/SelectWidget";

const getLists = (parents, budgetLookups) => {
    return parents
        .map((p, index) => {
            return budgetLookups
                .filter((i) => i.parentLookupNumber === p || (index === 0 && i.parentLookupNumber === null))
                .map((i) => ({
                    label: `${i.displayLabel}: ${i.displayValue}`,
                    value: i.lookupNumber,
                }));
        })
        .filter((l) => l.length > 0);
};

const getOptions = (list) => ({
    enumOptions: list,
});

const getValue = (ids, index) => {
    return ids[index];
};

const CostCodeValueWidget = (props) => {
    const { budgetLookups } = props;

    const [parents, setParents] = useState([null]);
    const [ids, setIds] = useState([]);

    const lists = getLists(parents, budgetLookups);

    const onChange = (value, index) => {
        let newIds = ids.slice(0, index + 1);
        newIds[index] = value;
        setIds(newIds);

        let newParents = parents.slice(0, index + 1);
        newParents.push(value);
        setParents(newParents);

        const newLists = getLists(newParents, budgetLookups);
        if (newLists.length === lists.length) {
            props.onChange(newIds.join("|"));
        }
    };

    if (budgetLookups.length === 0) {
        return <span>No selections are available</span>;
    }

    return (
        <div className="flex-column cost-code-value-widget">
            {lists.map((list, index) => (
                <SelectWidget
                    key={index}
                    {...props}
                    value={getValue(ids, index)}
                    options={getOptions(list)}
                    onChange={(value) => onChange(value, index)}
                />
            ))}
        </div>
    );
};

export default CostCodeValueWidget;
