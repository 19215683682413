import React, { memo } from "react";
import { get } from "lodash";
import { useContract } from "../../../../store/resources/useResource";
import UtilityName from "../../UtilityName";

const TabContentForContract = memo(({ view }) => {
    const utilityNumber = get(view, `props.utilityNumber`);
    const contractNumber = get(view, `props.contractNumber`);

    const [contract = {}] = useContract({ utilityNumber, contractNumber });

    let contentItems = [
        {
            label: "Utility",
            value: <UtilityName utilityNumber={utilityNumber} />,
        },
        {
            label: "Contract",
            value: contract.contractDesc || <>&nbsp;</>,
        },
    ];

    return (
        <div className="tab-content">
            {contentItems.map((item) => (
                <div key={item.label} className="tab-content-item">
                    <div className="title">{item.label}</div>
                    <div className="value">{item.value}</div>
                </div>
            ))}
        </div>
    );
});

export default TabContentForContract;
