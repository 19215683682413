import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import { PropertyList } from "../PropertyList";
import { getRowLayoutConfig, PORTAL_WIDGET_PROPERTIES } from "components/ui/PortalBuilder/utils";
import { PropertyListItemTitle } from "../PropertyList/PropertyListItemTitle";
import { RowLayoutTypes } from "./RowLayout/RowLayoutTypes";
import { isEqual, isNil } from "lodash";
import { WidgetType } from "components/ui/PortalBuilder/types";
import { PageContext, PortalBuilderPropertiesContext, RowContext } from "components/ui/PortalBuilder/contexts";
import { getRowErrors } from "../../utils/validation";
import { PropertyListItem } from "../Property/PropertyListItem";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";

export const RowProperty = (props) => {
    const [selectedColumn, setSelectedColumn] = useState();
    const { index, value = {}, onChange, onRemove, onClone, onChangeOrderUp, onChangeOrderDown, listLength, errors } = props;
    const { page } = useContext(PageContext);
    const { sectionTitle, lastSelectedColumnRow } = useContext(PortalBuilderPropertiesContext);
    const [lastEditingPageIndex] = usePortalBuilderState((state) => state.lastEditingPageIndex);
    const [activeError] = usePortalBuilderState((state) => state.activeError);
    const type = WidgetType.ROW;
    const title = value.title ?? "ROW-1";
    const [isExpanded, setIsExpanded] = useState(false);
    const isHidden = value.hidden === true;

    const previousLayout = useRef(null);

    const expandTitle = isExpanded ? "Hide Row Properties" : "Show Row Properties";

    let rowErrors;
    if (page) {
        rowErrors = getRowErrors(errors, `content.pages[${page.index}].components[${props.index}].`);
    } else if (sectionTitle === "Header") {
        rowErrors = getRowErrors(errors, `header.components[${props.index}].`);
    } else if (sectionTitle === "Footer") {
        rowErrors = getRowErrors(errors, `footer.components[${props.index}].`);
    }

    const containsActiveError = useMemo(() => {
        return activeError?.pageIndex === page?.index && activeError?.rowIndex === index && !activeError?.columnIndex && !isHidden;
    }, [activeError, page?.index, index, isHidden]);

    useEffect(() => {
        if (containsActiveError) {
            setIsExpanded(true);
        }
    }, [containsActiveError, activeError]);

    useEffect(() => {
        if (!isNil(lastEditingPageIndex)) {
            setIsExpanded(true);
        }
        if (!isNil(lastSelectedColumnRow) && lastSelectedColumnRow === index) {
            setIsExpanded(true);
        }
    }, [lastEditingPageIndex, lastSelectedColumnRow, index]);

    useEffect(() => {
        const layout = value.layout;
        if (previousLayout.current && previousLayout.current !== layout) {
            const selectedLayout = RowLayoutTypes[layout];
            const updatedColumns = getRowLayoutConfig({
                columns: value.components,
                selectedLayout,
            });
            if (!isEqual(value.components, updatedColumns)) {
                onChange("components", updatedColumns);
            }
        }
        previousLayout.current = layout;
    }, [onChange, value.components, value.layout, value]);

    const context = useMemo(
        () => ({
            row: {
                ...(props ?? {}),
                title,
            },
            selectedColumn,
            selectedLayout: RowLayoutTypes[value.layout],
            onColumnSelect: (index) => setSelectedColumn(index),
        }),
        [props, selectedColumn, title, value.layout]
    );

    const onToggleHide = useCallback(() => {
        if (!isHidden) {
            onChange("hidden", true);
        } else {
            onChange("hidden", undefined);
        }
    }, [isHidden, onChange]);

    const toggleHideTooltip = isHidden ? "Switch ON Row" : "Switch OFF Row";

    return (
        <RowContext.Provider value={context}>
            <PropertyListItem
                className={cn("property-list-item--row", {
                    "property-list-item--expanded-row": isExpanded,
                })}
            >
                <PropertyListItemTitle
                    onMouseOver={() => onRowHover(onChange)}
                    onMouseLeave={() => onRowHoverOff(onChange)}
                    title={title}
                    isExpanded={isExpanded}
                    expandTitle={expandTitle}
                    onRemove={onRemove}
                    onClone={onClone}
                    onExpand={() => setIsExpanded((prev) => !prev)}
                    onChangeOrderDown={onChangeOrderDown}
                    onChangeOrderUp={onChangeOrderUp}
                    index={index}
                    listLength={listLength}
                    onToggle={onToggleHide}
                    toggleTooltip={toggleHideTooltip}
                    toggleValue={isHidden}
                    type={type}
                />
                <PropertyList
                    errors={rowErrors}
                    parentTitle={title}
                    items={PORTAL_WIDGET_PROPERTIES[type]}
                    nestingLevel={2}
                    config={value}
                    onChange={onChange}
                    containsActiveError={containsActiveError}
                    isExpanded={isExpanded}
                />
            </PropertyListItem>
        </RowContext.Provider>
    );
};

const onRowHover = (onChange) => {
    onChange("_highlight", true);
};

const onRowHoverOff = (onChange) => {
    onChange("_highlight", undefined);
};
