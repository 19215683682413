import AssignmentSelector from "components/ui/AssignmentSelector";
import AssociationsList from "components/ui/AssociationsList";
import Button from "components/ui/Button";
import ButtonGroup from "components/ui/Button/ButtonGroup";
import DropDownInput from "components/ui/Input/DropDownInput";
import Separator from "components/ui/Separator";
import { isNil } from "lodash";
import React, { memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalClose, modalOpen } from "store/modal/actions";
import { ChangeProgramLevelRoleTitle, RoleChangeModalMessage } from "./RoleChangeModalMessage";
import { setProgramLevelRoleForSelection } from "./utils";

export const RolesBulkActions = memo(({ clientNumber, utilityNumber, roles, usersList, programsList }) => {
    const dispatch = useDispatch();
    const utilityName = useSelector((state) => state.resources.utilities.itemsById[utilityNumber]?.utility);

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedPrograms, setSelectedPrograms] = useState([]);
    const [selectedRole, setSelectedRole] = useState();

    const users = useMemo(() => {
        return (usersList ?? []).map((item) => ({
            itemId: item.userNumber,
            itemName: item.userName,
        }));
    }, [usersList]);

    const programs = useMemo(() => {
        return (programsList ?? []).map((item) => ({
            itemId: item.programNumber,
            itemName: item.program,
        }));
    }, [programsList]);

    const rolesDropdownList = useMemo(() => {
        return roles.map((role) => ({
            label: role.roleName,
            value: role.roleId,
        }));
    }, [roles]);

    const isAnyUserSelected = selectedUsers.length > 0;
    const isAnyProgramSelected = selectedPrograms.length > 0;
    const isRoleSelected = !isNil(selectedRole);
    const areItemsSelected = isAnyUserSelected && isAnyProgramSelected && isRoleSelected;
    const submitButtonTitle = areItemsSelected ? undefined : "Select users, programs and role";

    const onSelectUsers = useCallback(() => {
        const items = users.map((item) => ({
            ...item,
            assigned: selectedUsers.some((i) => i.itemId === item.itemId),
        }));

        dispatch(
            modalOpen({
                modalType: "MODAL",
                modalProps: {
                    title: "Select Users",
                    modalIcon: "theaters-empty",
                    overlayClassName: "modal-styled",
                    className: "assignment-selector-modal modal-md",
                    children: (
                        <AssignmentSelector
                            items={items}
                            idKey="itemId"
                            nameKey="itemName"
                            submitButtonText="Select"
                            addAllText="Select All"
                            useCSV
                            onSelect={setSelectedUsers}
                            onClose={() => dispatch(modalClose())}
                        />
                    ),
                    noFooter: true,
                    withScroll: true,
                },
            })
        );
    }, [users, selectedUsers, dispatch]);

    const onRemoveUser = useCallback((user) => {
        return setSelectedUsers((prevValue) => prevValue.filter((i) => i.itemId !== user.itemId));
    }, []);

    const onSelectPrograms = useCallback(() => {
        const items = programs.map((item) => ({
            ...item,
            assigned: selectedPrograms.some((i) => i.itemId === item.itemId),
        }));

        dispatch(
            modalOpen({
                modalType: "MODAL",
                modalProps: {
                    title: "Select Programs",
                    modalIcon: "theaters-empty",
                    overlayClassName: "modal-styled",
                    className: "assignment-selector-modal modal-md",
                    children: (
                        <AssignmentSelector
                            items={items}
                            idKey="itemId"
                            nameKey="itemName"
                            submitButtonText="Select"
                            addAllText="Select All"
                            useCSV
                            onSelect={setSelectedPrograms}
                            onClose={() => dispatch(modalClose())}
                        />
                    ),
                    noFooter: true,
                    withScroll: true,
                },
            })
        );
    }, [programs, selectedPrograms, dispatch]);

    const onRemoveProgram = useCallback((user) => {
        return setSelectedPrograms((prevValue) => prevValue.filter((i) => i.itemId !== user.itemId));
    }, []);

    const onSelectRole = useCallback((event) => {
        setSelectedRole(event.target.value);
    }, []);

    const onSubmit = useCallback(() => {
        const roleName = roles.find((r) => r.roleId === selectedRole)?.roleName;

        setProgramLevelRoleForSelection({
            clientNumber,
            utilityNumber,
            roleId: selectedRole,
            roleName,
            userNumbers: selectedUsers.map((i) => i.itemId),
            programNumbers: selectedPrograms.map((i) => i.itemId),
            message: (
                <RoleChangeModalMessage
                    title={<ChangeProgramLevelRoleTitle roleName={roleName} />}
                    utilityList={[{ utilityName }]}
                    programList={selectedPrograms.map((i) => ({
                        program: i.itemName,
                    }))}
                    userList={selectedUsers.map((i) => ({
                        userName: i.itemName,
                    }))}
                />
            ),
            dispatch,
        });
    }, [clientNumber, utilityNumber, utilityName, roles, selectedRole, selectedUsers, selectedPrograms, dispatch]);

    return (
        <div className="role-management-bulk-actions">
            <AssociationsList
                headerText="Selected Users"
                placeholder="Select users for role assignment"
                list={selectedUsers}
                displayProperty="itemName"
                onEdit={onSelectUsers}
                onRemove={onRemoveUser}
            />
            <AssociationsList
                headerText="Selected Programs"
                placeholder="Select programs for role assignment"
                list={selectedPrograms}
                displayProperty="itemName"
                onEdit={onSelectPrograms}
                onRemove={onRemoveProgram}
            />
            <div className="flex-row">
                <DropDownInput
                    inline
                    label="Set User Role For Selection"
                    placeholder="-- SELECT --"
                    data={rolesDropdownList}
                    value={selectedRole}
                    onChange={onSelectRole}
                />
            </div>
            <Separator />
            <ButtonGroup transparent>
                <Button disabled={!areItemsSelected} title={submitButtonTitle} onClick={onSubmit}>
                    Apply Role
                </Button>
            </ButtonGroup>
        </div>
    );
});
