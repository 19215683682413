import React, { memo, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";

import { useProgramRights, useResource } from "../../../../../../store/resources/useResource";
import WaitIcon from "../../../../WaitIcon";
import NothingFoundBlock from "../../../../NothingFoundBlock";
import WorkflowStep from "./WorkflowStep";
import SidebarPanel from "../../../../SidebarPanel";
import { SidebarIconGroup } from "../../../../SidebarPanel/SidebarParts/SidebarIconGroup";
import { setExpandedWorkflowTask } from "../../../../../../store/projects/actions";
import CustomList, { renderCustomFooter } from "components/ui/List/CustomList";
import { applyTaskFilter, DEFAULT_WORKFLOW_FILTER, useWorkflowModel } from "components/ui/TaskWorkflow/utils";
import { getApplicationWorkflowResourceParams } from "store/configureResources";

import "./style.scss";
import { isApplicationLocked } from "components/views/ProjectView/utils";
import { systemUserRights } from "components/utils/user";

const WorkflowPanelSmall = memo(({ panel, onSettings, onUnpin, onExpand, onToggleSidebar }) => {
    const dispatch = useDispatch();

    const applicationNumber = panel && panel.data && panel.data.applicationNumber;
    const programNumber = panel && panel.data && panel.data.programNumber;
    const userName = useSelector((state) => get(state, "user.name"));

    const [programRights = []] = useProgramRights({ programNumber });

    const isAppLocked = isApplicationLocked({ applicationNumber });
    const isTakeTaskDisabled = isAppLocked || !programRights.includes(systemUserRights.VISIONDSM_ADD_APPLICATION);
    const workflowGetResourceOptions = useMemo(
        () =>
            getApplicationWorkflowResourceParams({
                applicationNumber,
            }),
        [applicationNumber]
    );

    const [application] = useResource({
        resourceName: "applicationDetails",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
    });

    const { workflowTasks, isLoading } = useWorkflowModel({
        workflowGetResourceOptions,
    });

    const onOpenTask = useCallback(
        (task) => {
            dispatch(setExpandedWorkflowTask(applicationNumber, task.wfTaskNumber));
            onExpand(panel);
        },
        [onExpand, panel, applicationNumber, dispatch]
    );

    if (!application) {
        return null;
    }

    const steps = workflowTasks.filter((task) => applyTaskFilter(task, DEFAULT_WORKFLOW_FILTER));

    const sidebarHeaderActions = <SidebarIconGroup panel={panel} onSettings={onSettings} onUnpin={onUnpin} onExpand={onExpand} />;

    const renderItem = (item) => {
        return (
            <WorkflowStep
                item={item}
                applicationNumber={applicationNumber}
                owned={item.userOwner === userName}
                onOpenTask={onOpenTask}
                isTakeTaskDisabled={isTakeTaskDisabled}
                isAppLocked={isAppLocked}
            />
        );
    };
    return (
        <SidebarPanel
            sidebarHeaderTitle={panel.name}
            sidebarHeaderActions={sidebarHeaderActions}
            noPadding
            noFooter
            noData={!isLoading && steps.length === 0}
            className="workflow-sidebar-panel"
        >
            {isLoading && <WaitIcon />}
            <div className="workflow-sidebar-body">
                {!isLoading && steps.length === 0 && (
                    <div className="empty-sidebar-content no-data">
                        <NothingFoundBlock nothingFoundBlockSmall icon="workflow" title="No Tasks available to work on" />
                    </div>
                )}
                <CustomList limit={3} renderItem={renderItem} renderFooter={renderCustomFooter} items={steps} />
            </div>
        </SidebarPanel>
    );
});

export default WorkflowPanelSmall;
