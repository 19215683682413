import React, { useState, useContext, useMemo, useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";

import { updateResource } from "../../../store/resources/actions";
import { getColumnKeys } from "../../views/configureGrids";
import { getData, selectRows } from "../../../store/dataGrid/actions";
import { useResource } from "../../../store/resources/useResource";
import { WorkCenterContext } from ".";

import DropDownInput from "../../ui/Input/DropDownInput";
import FieldGroup from "../../ui/FieldGroup";
import Button from "../../ui/Button";
import Radio from "../../ui/Input/Radio";
import { setWorkqueueGridSelectedRows } from "store/workcenter/actions";
import { getSelectedRows } from "components/utils/datagrid";

const assignmentActions = {
    add: "ASSIGNOWNER",
    remove: "REMOVEOWNER",
};

const TasksFilterControls = ({ selectedRows, dataGridId }) => {
    const { instanceId } = useContext(WorkCenterContext);

    const dispatch = useDispatch();

    const userListsInitialized = useRef();

    const labelText = selectedRows.length > 0 ? `${selectedRows.length} Tasks selected to update` : "Select tasks to update";

    const columnKeys = getColumnKeys(dataGridId);
    const currentUserNumber = useSelector((state) => state.user?.userNumber);
    const dataGridInstanceId = useSelector((state) => state.workcenter[instanceId].workqueueGridId);
    const updatingAssignments = useSelector((state) => state.resources.workcenterItemUsers.isUpdating);

    const [assignment, setAssignment] = useState(null);
    const [addUserNumber, setAddUserNumber] = useState(null);

    const [activeUsers = []] = useResource({
        resourceName: "workcenterUserList",
        key: "workcenter-active-user-list",
        query: {
            activeOnly: true,
        },
    });

    const addUserList = useMemo(
        () =>
            activeUsers.map((u) => {
                return {
                    label: u.userName,
                    value: u.userNumber,
                    selected: u.userNumber === addUserNumber,
                };
            }),
        [addUserNumber, activeUsers]
    );

    // Add logged in user to assignement dropdowns
    useEffect(() => {
        if (activeUsers.length > 0 && currentUserNumber && !userListsInitialized.current) {
            userListsInitialized.current = true;

            //Add user if it is available in workcenter userlist
            if (activeUsers.find((u) => u.userNumber === currentUserNumber)) {
                setAddUserNumber(currentUserNumber);
            }
        }
    }, [activeUsers, currentUserNumber]);

    useEffect(() => {
        if (addUserList.length === 0) {
            if (assignment === assignmentActions.add) {
                setAssignment(null);
            }

            setAddUserNumber(null);
        }
    }, [assignment, addUserList]);

    const onAssignmentChange = useCallback((event) => {
        setAssignment(event.target.value);
    }, []);

    const onAddUserChange = useCallback((event) => {
        setAddUserNumber(event.target.value);
    }, []);

    const onUpdateApps = () => {
        const userNumber = assignment === assignmentActions.add ? [addUserNumber] : [];

        dispatch(
            updateResource({
                resourceName: "workcenterItemUsers",
                body: {
                    tgtUserNumbers: userNumber,
                    action: assignment,
                    items: selectedRows.map((r) => r[columnKeys.taskNumber]),
                },
                onSuccess: () => {
                    dispatch(
                        getData({
                            dataGridId: dataGridInstanceId,
                            onSuccess: () => {
                                // Restore Row selection
                                dispatch(
                                    selectRows({
                                        dataGridId: dataGridInstanceId,
                                        selectedRows: selectedRows.map((r) => ({
                                            [columnKeys.taskNumber]: r[columnKeys.taskNumber],
                                        })),
                                    })
                                );

                                const workqueueGridSelectedRows = getSelectedRows(dataGridInstanceId);
                                dispatch(
                                    setWorkqueueGridSelectedRows({
                                        instanceId,
                                        workqueueGridSelectedRows,
                                    })
                                );

                                // Unselect user list
                                setAddUserNumber(null);
                            },
                        })
                    );
                },
            })
        );
    };
    const controlsDisabled = selectedRows.length === 0 || updatingAssignments;
    const removeOwnershipDisabled = !selectedRows.some((sr) => sr[columnKeys.assignedTo]) || updatingAssignments;
    const submitDisabled = controlsDisabled || !assignment || (assignment === assignmentActions.add && !addUserNumber);

    const ownershipRadioGroupName = "task-ownership-control";

    return (
        <>
            <div className="filter-controls-title">
                <span>{labelText}</span>
            </div>
            <FieldGroup
                inline
                className={cn({
                    "radio-disabled": removeOwnershipDisabled,
                })}
            >
                <Radio
                    disabled={removeOwnershipDisabled}
                    label="Remove ownership"
                    name={ownershipRadioGroupName}
                    checked={assignment === assignmentActions.remove}
                    value={assignmentActions.remove}
                    onChange={onAssignmentChange}
                />
            </FieldGroup>
            <FieldGroup
                inline
                className={cn("radio-plus-dropdown", {
                    "radio-disabled": controlsDisabled,
                })}
            >
                <Radio
                    disabled={controlsDisabled || addUserList.length === 0}
                    label="Assign owner"
                    name={ownershipRadioGroupName}
                    checked={assignment === assignmentActions.add}
                    value={assignmentActions.add}
                    onChange={onAssignmentChange}
                />
                <DropDownInput
                    inline
                    disabled={controlsDisabled || assignment !== assignmentActions.add}
                    placeholder="Select user"
                    mobileHeader="Select user"
                    data={addUserList}
                    value={addUserNumber}
                    onChange={onAddUserChange}
                />
            </FieldGroup>
            <Button primary disabled={submitDisabled} onClick={onUpdateApps}>
                {updatingAssignments ? "Updating..." : "Update Tasks"}
            </Button>
        </>
    );
};

export default TasksFilterControls;
