import Button from "components/ui/Button";
import ButtonGroup from "components/ui/Button/ButtonGroup";
import React from "react";

import "./AccessibilityControls.scss";

export const AccessibilityControls = ({ onValidate, onExport }: AccessibilityControlsProps) => {
    return (
        <ButtonGroup transparent className="accessibility-controls flex-row">
            <Button className="flex-one" onClick={onValidate}>
                Validate All Pages
            </Button>
            {onExport && (
                <Button className="flex-one" onClick={onExport}>
                    Export to CSV
                </Button>
            )}
        </ButtonGroup>
    );
};

interface AccessibilityControlsProps {
    onValidate: () => void;
    onExport?: () => void;
}
