import React from "react";
import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid";
import CorrespondenceWorkflowItemEditForm from "./CorrespondenceWorkflowItemEditForm";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../../ui/SideNav/SideNavRoot";

const CorrespondenceWorkflowGrid = (props) => {
    const { gridId, dataGridConfig, programNumber, dispatch, documentName } = props;

    const customRowActions = [
        {
            name: "edit",
            title: "Edit Workflow Item",
            icon: "edit-empty",
        },
    ];

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
    });

    const onRowAction = (action, onExpand) => {
        const { name, dataItem } = action;

        switch (name) {
            case "edit":
                handleOpenSidePanel(
                    <CorrespondenceWorkflowItemEditForm
                        documentName={documentName}
                        dataItem={dataItem}
                        dispatch={dispatch}
                        gridId={gridId}
                        programNumber={programNumber}
                        onClose={handleCloseSidePanel}
                    />,
                    {
                        className: "correspondence-workflow-item-edit-sidenav-panel",
                    }
                );
                break;
            default:
                break;
        }
    };

    return <DataGrid name={gridId} config={dataGridConfig} onRowAction={onRowAction} customRowActions={customRowActions} />;
};

export default withDataGrid("programsCorrespondenceWorkflowGrid", CorrespondenceWorkflowGrid);
