import uuidv1 from "uuid/v1";
import { isEmpty, isString } from "lodash";

const ZERO_WIDTH_SPACE_CHAR = "\u200B";

export const stripHtml = (html) => {
    var div = document.createElement("div");
    div.innerHTML = html;

    return div.textContent || div.innerText || "";
};

export const stripHtmlForDataGrid = (html) => {
    if (!isString(html)) {
        return html;
    }

    // Remove style tags from html
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, "");

    // Remove script tags from html
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, "");

    // Replace br tags with new line
    html = html.replace(/<br\s*[/]?>/gi, "\n");

    // Replace nbsp with space
    html = html.replace(/&nbsp;/gi, " ");

    // Replace &lt; with <
    html = html.replace(/&lt;/gi, "<");

    // Strip HTML tags
    html = stripHtml(html);

    return html;
};

export const removeExtraSpaces = (string) => {
    return string.replace(/\s+/g, " ").trim();
};

/**
 * Adds break chars in long words.
 *
 * @param {*} text - text to process
 * @param {*} maxWordLength - max allowed word length
 * @returns
 */
export const breakLongWords = (text, maxWordLength) => {
    if (!isString(text) || text.length <= maxWordLength) {
        return text;
    }

    let textWithBreaks = [...text]
        .map((char) => {
            // Add soft break after common separators
            return ["|", "_"].includes(char) ? char + ZERO_WIDTH_SPACE_CHAR : char;
        })
        .join("");

    const result = textWithBreaks
        .split(" ")
        .map((part) => {
            // Check every word to not exceed length limit
            return part
                .split(ZERO_WIDTH_SPACE_CHAR)
                .map((part) => {
                    if (maxWordLength > 0 && part.length > maxWordLength) {
                        return [...part]
                            .map((char, index) => (index % (maxWordLength - 1) === 0 ? char + ZERO_WIDTH_SPACE_CHAR : char))
                            .join("");
                    }

                    return part;
                })
                .join(ZERO_WIDTH_SPACE_CHAR);
        })
        .join(" ");

    return result;
};

export const hasSpaces = (text) => {
    return [...text].some((char) => [" "].includes(char));
};

export const camelToTitle = (camelCase) =>
    camelCase.replace(/([A-Z])/g, (match) => ` ${match}`).replace(/^./, (match) => match.toUpperCase());

export const pad = (num, size) => {
    let s = String(num);
    while (s.length < size) {
        s = "0" + s;
    }
    return s;
};

export const copyToClipboard = (text, callback) => {
    var textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    callback && callback();
};

export const decodeHtml = (html) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
};

export const encodeHtml = (html) => {
    if (isNullOrWhitespace(html)) {
        return html;
    }

    const p = document.createElement("p");
    p.textContent = html;
    return p.innerHTML;
};

export const createId = () => {
    return uuidv1().toUpperCase().split("-").join("");
};

// https://stackoverflow.com/a/21015393
export const getTextWidth = (text, font) => {
    var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
    var context = canvas.getContext("2d");
    context.font = font;
    var metrics = context.measureText(text);

    return metrics.width;
};

export const isYes = (value) => {
    return value.toLowerCase() === "yes";
};

export const toPascalCase = (word) => word.charAt(0).toUpperCase() + word.substr(1);

export const arrayToText = (list = [], lastItemPrefix = "and") => {
    if (list.length > 1) {
        return list.slice(0, -1).join(", ") + " " + lastItemPrefix + " " + list[list.length - 1];
    }

    return list[0];
};

/**
 * Check if string is null or contains only whitespaces
 *
 * @param {string} value
 * @returns {boolean}
 */
export const isNullOrWhitespace = (value) => {
    return String(value ?? "").trim().length === 0;
};

/**
 * Check whether a string only contains digits.
 * @param {string} value
 * @returns {boolean}
 */
export const isDigits = (value) => {
    const regEx = new RegExp(/^[0-9]*$/g); // numbers only
    return regEx.test(value);
};

/**
 * Compare if strings are equal in lowercase.
 *
 * @param {string} value1
 * @param {string} value2
 * @returns {boolean}
 */
export const isLowerCaseEqual = (value1, value2) => {
    return String(value1 ?? "").toLowerCase() === String(value2 ?? "").toLowerCase();
};

export const removeNonASCIICharacters = (str) => str.replace(/[^\x00-\x7F]/g, ""); // eslint-disable-line no-control-regex

/**
 * Replace scr attributes with http links to https in provided html content string.
 *
 * @param {string} html html content string
 * @returns {string} updated html content string
 */
export const replaceHttpLinksToHttps = (html) => {
    if (!isString(html)) {
        return html;
    }

    return html.replace(/src="http:\/\//gi, 'src="https://').replace(/src='http:\/\//gi, "src='https://");
};

/**
 * Escapes the pipe sign (|) in a string by replacing it with the HTML entity code &#124;.
 *
 * @param {string | undefined} value - The input string to escape.
 * @returns {string} The escaped string with the pipe sign replaced by &#124;.
 */
export const escapePipeSign = (value) => {
    if (!isString(value)) {
        return value;
    }

    return value.split("|").join("&#124;");
};

/**
 * Replaces all occurrences of "&#124;" with "|" in the given string.
 *
 * @param {string | undefined} value - The string to be processed.
 * @returns {string} The processed string with "|" instead of "&#124;".
 */
export const unescapePipeSign = (value) => {
    if (!isString(value)) {
        return value;
    }

    return value.split("&#124;").join("|");
};

/**
 * Replace equal sign '=' with '&#61';
 *
 * @param {string} value
 * @returns {string}
 */
export const escapeEqualSign = (value) => {
    if (!isString(value)) {
        return value;
    }

    return value.split("=").join("&#61;");
};

/**
 * Replace '&#61' with equal sign '=';
 *
 * @param {string} value
 * @returns {string}
 */
export const unescapeEqualSign = (value) => {
    if (!isString(value)) {
        return value;
    }

    return value.split("&#61;").join("=");
};

/**
 * Convert string to object by using JSON.parse()
 * Return null if there was an error while parsing.
 *
 * @param {string} value
 * @returns {object|null} JSON parse result or null if parse failed.
 */
export const convertToObject = (value) => {
    let result = null;

    try {
        result = JSON.parse(value);
    } catch {
        result = null;
    }

    return result;
};

/**
 * Convert string to array.
 * Return empty array if nothing found in the string.
 *
 * @param {string} value String with separated items.
 * @param {string} separator Item separator in the string.
 * @returns {string[]} Array with items.
 */
export const convertToArray = (value, separator = ",") => {
    return (value ?? "")
        .split(separator)
        .map((r) => r.trim())
        .filter((r) => !isEmpty(r));
};
