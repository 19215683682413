import React, { memo } from "react";

import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid";

const HistoryGrid = memo((props) => {
    const { gridId, dataGridConfig } = props;

    return <DataGrid name={gridId} config={dataGridConfig} />;
});

export default withDataGrid("programsFormsHistory", HistoryGrid);
