import React, { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { isEmpty, isNil } from "lodash";
import SelectWidget from "./SelectWidget";
import useUnmounted from "../../../../utils/useUnmounted";
import CheckboxesWidget from "./CheckboxesWidget";
import RadioWidget from "./RadioWidget";
import { createResourcePromise, getResourcePromise } from "store/resources/useResource";
import { getResourceFromStore } from "store/resources/selectors";

const GeneralProcedureWidget = (props) => {
    const { programNumber, applicationNumber } = props.formContext ?? {};
    const { procDisplayType, procId, parameterNamesList = [], parameterValuesList = [] } = props.options || {};

    const [genProc = [], isLoading] = useApplicationFormGenProc({
        programNumber,
        applicationNumber,
        procId,
        parameterNamesList,
        parameterValuesList,
    });

    const options = useMemo(
        () => ({
            ...(props.options || {}),
            enumOptions: genProc.map((item) => ({
                label: item.itemDisplay,
                value: item.itemValue,
            })),
        }),
        [genProc, props.options]
    );

    const value = isLoading ? undefined : props.value;
    const placeholder = isLoading ? "Loading..." : props.placeholder || props?.options?.placeholder;

    let widget = null;

    switch (procDisplayType) {
        case "checkboxes":
            widget = (
                <CheckboxesWidget
                    {...props}
                    placeholder={placeholder}
                    value={isNil(value) ? [] : (value || "").split(",").map((i) => i.trim())}
                    onChange={(val) => {
                        const valueStr = (val ?? []).join(",");
                        // Return undefined if no value selected. Otherwise form change detection will not work as expected.
                        props.onChange(valueStr === "" ? undefined : valueStr);
                    }}
                    options={options}
                />
            );
            break;
        case "radio":
            widget = <RadioWidget {...props} placeholder={placeholder} value={value} options={options} />;
            break;
        default:
            widget = <SelectWidget {...props} placeholder={placeholder} value={value} options={options} />;
            break;
    }

    return widget;
};

const useApplicationFormGenProc = ({ programNumber, applicationNumber, procId, parameterNamesList, parameterValuesList }) => {
    const dispatch = useDispatch();
    const isInitialized = useRef(false);
    const unmounted = useUnmounted();

    const [state, setState] = useState({
        isLoading: true,
        data: [],
    });

    useEffect(() => {
        const loadData = async () => {
            setState((prev) => ({ ...prev, isLoading: true }));

            try {
                let namesList = parameterNamesList;

                if (isEmpty(parameterNamesList)) {
                    namesList = getResourceFromStore({
                        resourceName: "programFormGenProcs",
                        resourceId: procId,
                    });

                    if (isEmpty(namesList) && programNumber) {
                        namesList = await getResourcePromise({
                            resourceName: "programFormGenProcs",
                            resourceId: procId,
                            path: {
                                programNumber,
                            },
                        });
                    }

                    namesList = (namesList ?? []).map((i) => i.parameterName);
                }

                const valuesList = namesList.map((_, index) => parameterValuesList[index] ?? "");

                const data = await createResourcePromise({
                    resourceName: "applicationFormGenProc",
                    path: {
                        programNumber,
                        applicationNumber,
                        procId,
                    },
                    body: {
                        parameterNamesList: namesList,
                        parameterValuesList: valuesList,
                    },
                });

                !unmounted.current &&
                    setState({
                        data,
                        isLoading: false,
                    });
            } catch {
                !unmounted.current && setState((prev) => ({ ...prev, isLoading: false }));
            }
        };

        if (!isInitialized.current) {
            isInitialized.current = true;

            // Do nothing if don`t have needed parameters for API calls. For example in formBuilder.
            if (isNil(programNumber) || isNil(applicationNumber) || isNil(procId)) {
                setState((prev) => ({ ...prev, isLoading: false }));
                return;
            }

            // Delay loading a little and not load data if form is unmounted.
            setTimeout(() => {
                !unmounted.current && loadData();
            }, 100);
        }
    }, [programNumber, applicationNumber, procId, parameterNamesList, parameterValuesList, dispatch, unmounted]);

    return useMemo(() => [state.data, state.isLoading], [state.data, state.isLoading]);
};

export default GeneralProcedureWidget;
