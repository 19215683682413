import React from "react";
import cn from "classnames";
import IconWrap from "components/ui/Icons";
import ActionLabel from "components/ui/Label/ActionLabel";
import { PageConfiguration } from "../../types";
import { isNil } from "lodash";
import { usePageTitle } from "./usePageTitle";
import { editableStandardPages } from "../../utils/page";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";

export const PagesListItem = ({ index, page, isActive, isHomePage, onPreview, onCopy, onEdit, onDelete }: PagesListItemProps) => {
    const [isProgramPortalBuilder] = usePortalBuilderState((state) => state.isProgramPortalBuilder);
    const pageTitle = usePageTitle(page);

    if (isNil(page)) {
        return null;
    }

    const isCookiesPage = page.link === "/cookies";
    const homePageTooltip = "The first page users see when they visit your Portal";
    const homePageOverrideWarning = "This page will not be accessible from the Portal, because a different start page has been set";
    const showLockIcon = isProgramPortalBuilder && page.isShared && !editableStandardPages.includes(page.link ?? "");
    const lockTooltip = isProgramPortalBuilder ? "Limited customization allowed. Comes from Utility Level" : "Locked";
    const canDeleteOrCopy = isProgramPortalBuilder ? !page.isShared && !isHomePage : page.custom && !isCookiesPage;

    return (
        <div
            className={cn("list-item-row", {
                selected: isActive,
            })}
            data-test-id={`${page.link ?? ""}-list-item`}
        >
            <div className="item-value page-name flex-row align-center">
                {/* @ts-ignore */}
                <ActionLabel className="text-label" onClick={() => onPreview(page, index)}>
                    {pageTitle}
                </ActionLabel>
                {showLockIcon && <IconWrap iconWrapSmall icon="lock-filled" title={lockTooltip} disabled />}
                {isHomePage && <IconWrap icon="info-empty" title={homePageTooltip} />}
                {page.link === "/" && !isHomePage && <IconWrap icon="info-empty" title={homePageOverrideWarning} />}
            </div>
            <div className="flex-row align-center">
                <IconWrap icon="edit-empty" title="Edit Page" onClick={() => onEdit(page, index)} />
                <IconWrap disabled={!canDeleteOrCopy} icon="layers-empty" title="Copy Page" onClick={() => onCopy(page, index)} />
                <IconWrap disabled={!canDeleteOrCopy} icon="delete-trash-empty" title="Delete Page" onClick={() => onDelete(page, index)} />
            </div>
        </div>
    );
};

interface PagesListItemProps {
    /** Page index in pages list */
    index: number;
    /** Page config */
    page: PageConfiguration;
    /** Is page selected in the pages list */
    isActive: boolean;
    isHomePage: boolean;
    /** Preview page */
    onPreview: (page: PageConfiguration, index: number) => void;
    /** Copy page */
    onCopy: (page: PageConfiguration, index: number) => void;
    /** Edit page */
    onEdit: (page: PageConfiguration, index: number) => void;
    /** Delete page */
    onDelete: (page: PageConfiguration, index: number) => void;
}
