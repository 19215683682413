import { ToolbarTypes } from "components/ui/Form/JsonSchema/widgets/HtmlEditorWidget/toolbars";
import { cloneDeep, isEmpty, isEqual, isNil, pick, sortBy, uniqBy, omitBy, set } from "lodash";
import { convertToObject } from "components/utils/string";
import {
    addWidget,
    DEFAULT_HOME_PAGE_CONFIG,
    DEFAULT_PROGRAM_TEMPLATE_CONFIG,
    DEFAULT_UTILITY_TEMPLATE_CONFIG,
    stripHelperValues,
} from ".";
import { LinkUrlProperty } from "../Properties/Property/LinkUrlProperty";
import { MARGIN_PROPERTY } from "../Properties/Property/MarginProperty";
import { PADDING_PROPERTY } from "../Properties/Property/PaddingProperty";
import { BACKGROUND_PROPERTY, LEAD_IMAGE_PROPERTY } from "../Properties/Property/propertyConfig";
import {
    PageAccessType,
    HeaderNavigation,
    LinkType,
    PageConfiguration,
    PageTab,
    PortalBuilderProperty,
    PortalTemplateConfiguration,
    ProgramTemplateConfiguration,
    PropertyName,
    PropertyType,
    ValidationRule,
    WidgetType,
    PaddingBoxSize,
    PaddingProperties,
    MarginProperties,
    BackgroundProperties,
    StandardPageEditProperties,
    ContactUsPageProps,
    UtilityPortalConfigurationResponse,
    TextColor,
    PageLink,
} from "../types";
import { PortalConfigurationResponse } from "./useProgramPortalConfiguration";

const PAGES_WITHOUT_SPACING_PROPS = [PageLink.CustomerHome, PageLink.ApplicationView];

export const getProgramLevelPages = (utilityConfig: PortalTemplateConfiguration, programTemplatePages?: PageConfiguration[]) => {
    const utilityPages = utilityConfig?.content?.pages ?? [];

    // Set flags for program pages.
    const programPages = (programTemplatePages ?? DEFAULT_PROGRAM_TEMPLATE_CONFIG.content.pages).map((p) => {
        const page = {
            ...p,
            isShared: utilityPages.some((utilityPage) => utilityPage.link === p.link),
        };

        // Remove padding and margin properties
        if (PAGES_WITHOUT_SPACING_PROPS.includes(page.link as PageLink)) {
            delete page.padding;
            delete page.paddingTop;
            delete page.paddingRight;
            delete page.paddingBottom;
            delete page.paddingLeft;
            delete page.margin;
            delete page.marginTop;
            delete page.marginRight;
            delete page.marginBottom;
            delete page.marginLeft;
        }

        return page;
    });

    const overriddenUtilityPages = utilityPages.map((page) => {
        const overriddenUtilityPage = programPages.find((programPage) => programPage.link === page.link);
        return getProgramLevelPageConfig(overriddenUtilityPage ?? page, utilityConfig);
    });

    const customProgramPages = programPages.filter((p) => !p.isShared && p.custom);

    let pages: PageConfiguration[] = uniqBy([...overriddenUtilityPages, ...customProgramPages], PropertyName.Link).map((p) => ({
        ...p,
        _isHomePage: p.link === "/" ?? undefined,
    }));

    // Ensure Home page is added.
    if (!pages.some((p) => p.link === "/")) {
        pages.push(DEFAULT_HOME_PAGE_CONFIG);
    }

    // Clear order in all pages that are not in header navigation
    pages.filter((p) => !p.headerNavigation || p.headerNavigation === HeaderNavigation.NEVER).forEach((p) => delete p.order);

    return sortBy(pages, [(p) => p.link !== "/", PropertyName.Order]);
};

/**
 * Check if all mandatory properties are set.
 * Add missing standard pages to the returned list.
 *
 * @param pages - List of pages from utility template configuration.
 * @returns Updated pages list.
 */
export const transformUtilityStandardPages = (pages: PageConfiguration[]) => {
    const utilityStandardPages = DEFAULT_UTILITY_TEMPLATE_CONFIG.content?.pages ?? [];

    let updatedPages = cloneDeep(pages);

    // Add missing standard pages not existing in older templates.
    utilityStandardPages.forEach((standardPage) => {
        if (!updatedPages.find((p) => p.link === standardPage.link)) {
            updatedPages.push(standardPage);
        }
    });

    // Set page properties "isShared" and "custom". Set missing properties for standard pages.
    updatedPages.forEach((page: PageConfiguration) => {
        // Mark the page as custom if it is not found in standard pages list
        page.custom = !utilityStandardPages.some((p) => !p.custom && p.link === page.link);
        // All utility pages should have `isShared ` flag.
        page.isShared = true;

        // Set missing default padding for standard pages
        if (
            !page.custom &&
            isNil(page.padding) &&
            isNil(page.paddingTop) &&
            isNil(page.paddingRight) &&
            isNil(page.paddingBottom) &&
            isNil(page.paddingLeft)
        ) {
            page.paddingTop = PaddingBoxSize.LG;
            page.paddingRight = PaddingBoxSize.MD;
            page.paddingBottom = PaddingBoxSize.LG;
            page.paddingLeft = PaddingBoxSize.MD;
        }

        // Remove padding and margin properties
        if (PAGES_WITHOUT_SPACING_PROPS.includes(page.link as PageLink)) {
            delete page.padding;
            delete page.paddingTop;
            delete page.paddingRight;
            delete page.paddingBottom;
            delete page.paddingLeft;
            delete page.margin;
            delete page.marginTop;
            delete page.marginRight;
            delete page.marginBottom;
            delete page.marginLeft;
        }

        // set missing default properties for standard pages
        if (page.link === PageLink.ApplicationView && isNil(page.headerTextColor)) {
            const utilityLevelPage = getPageConfigByLink(page.link, DEFAULT_UTILITY_TEMPLATE_CONFIG);

            page.headerTextColor = utilityLevelPage?.headerTextColor;
        }
    });

    // Move home page to the top, then standard pages and then custom pages.
    updatedPages = sortBy(updatedPages, [(p) => p.link !== "/", "custom"]);

    return updatedPages;
};

export const getPageProperties = (
    page: PageConfiguration,
    allPages: PageConfiguration[],
    isProgramPortalBuilder: boolean = false
): PortalBuilderProperty[] => {
    const showNavigationProperties = ![
        "/customerReset",
        "/forgot-password",
        "*",
        "/application-submitted",
        PageLink.ApplicationView,
        "/extform-submitted",
    ].includes(page.link ?? "");
    const pagesIncludedInHeaderNavigation = allPages.filter((p) => p.headerNavigation && p.headerNavigation !== HeaderNavigation.NEVER);

    const titleProperty = {
        id: "title",
        title: "Page Name",
        type: PropertyType.Text,
        fullWidth: true,
        borderBottom: false,
        validationRules: [ValidationRule.Required],
    };

    const pageAccessProperty = {
        id: "pageAccess",
        title: "PAGE ACCESS",
        type: PropertyType.Select,
        defaultValue: "PUBLIC",
        items: [
            {
                label: "Public",
                value: "PUBLIC",
            },
            {
                label: "Authenticated",
                value: "AUTHENTICATED",
            },
        ],
    };

    const headerNavigationProperty = {
        id: "headerNavigation",
        title: "HEADER NAVIGATION",
        type: PropertyType.Select,
        defaultValue: "NEVER",
        items: [
            {
                label: "Never",
                value: "NEVER",
            },
            {
                label: "Always",
                value: "ALWAYS",
            },
            {
                label: "Anonymous",
                value: "ANONYMOUS",
            },
            {
                label: "Authenticated",
                value: "AUTHENTICATED",
            },
        ],
    };

    const openInNewTabProperty = {
        id: "openInNewTab",
        title: "Open in New Tab",
        type: PropertyType.Switch,
        labelIcon: "open-new-window",
    };

    const orderProperty = {
        id: PropertyName.Order,
        title: "Order in header navigation",
        type: PropertyType.Number,
        minValue: 1,
        maxValue: pagesIncludedInHeaderNavigation.length > 0 ? pagesIncludedInHeaderNavigation.length : 1,
        hidden: !page.headerNavigation || page.headerNavigation === HeaderNavigation.NEVER,
    };

    const linkTypeProperty = {
        id: "linkType",
        title: "Page Link",
        type: PropertyType.SelectBox,
        fullWidth: true,
        borderBottom: false,
        items: !page.custom
            ? []
            : [
                  {
                      text: "Internal",
                      value: LinkType.INTERNAL,
                  },
                  {
                      text: "External",
                      value: LinkType.EXTERNAL,
                  },
              ],
        defaultValue: LinkType.INTERNAL,
        validationRules: [ValidationRule.Required],
        disabled:
            (page.isShared && isProgramPortalBuilder) || // disable link for shared pages at program level
            (!page.custom && !isProgramPortalBuilder) || // disable link for standard pages at utility level
            page.link === "/cookies", //disable cookies link as it always needs to be disabled
    };

    const linkProperty = {
        id: PropertyName.Link,
        type: PropertyType.CustomComponent,
        component: LinkUrlProperty,
        title: "Link text",
        noSelectFromExistingPages: true,
        fullWidth: true,
        borderBottom: showNavigationProperties,
        disabled:
            (page.linkType === LinkType.INTERNAL && isProgramPortalBuilder && page.link === "/" && page._isHomePage) || // disable internal link for Home Page
            (page.isShared && isProgramPortalBuilder) || // disable link for shared pages at program level
            (!page.custom && !isProgramPortalBuilder) || // disable link for standard pages at utility level
            page.link === "/cookies", //disable cookies link as it always needs to be disabled
        validationRules: [ValidationRule.Required, ValidationRule.ExternalInternalPageLink, ValidationRule.PageUrlUnique],
        propertiesGroup: [
            {
                id: PropertyName.Link,
            },
            {
                id: "linkType",
            },
            {
                id: "_attempted_page_url",
            },
        ],
    };

    const backgroundProperty = { ...BACKGROUND_PROPERTY, selectableWidth: true, title: "Page background", withGradient: true };
    const paddingProperty = { ...PADDING_PROPERTY, emptyValue: "0" };
    const marginProperty = { ...MARGIN_PROPERTY, emptyValue: "0", onlyTopAndBottom: true };

    const standardPageRows = {
        id: "components",
        title: "Rows",
        type: PropertyType.StandardPageRows,
    };

    const titleSwitchTopRows = {
        id: "topRowsOff",
        defaultValue: true,
    };

    const titleSwitchBottomRows = {
        id: "bottomRowsOff",
        defaultValue: true,
    };

    // External page properties
    if (page.linkType === LinkType.EXTERNAL) {
        return [titleProperty, linkTypeProperty, linkProperty, headerNavigationProperty, orderProperty, openInNewTabProperty];
    }

    // Properties for standard pages
    if (!page.custom) {
        const editableProperties: PortalBuilderProperty[] = [titleProperty];

        if (showNavigationProperties) {
            editableProperties.push(linkTypeProperty, linkProperty, headerNavigationProperty, orderProperty, openInNewTabProperty);
        } else {
            editableProperties.push({ ...linkProperty, title: "Page Link" });
        }

        const standardPageRowsHidden = !editableStandardPages.includes(page.link ?? "");

        if (![PageLink.ApplicationView, PageLink.CustomerHome].includes(page.link as PageLink)) {
            editableProperties.push(backgroundProperty);
        }

        if (page.link === PageLink.ApplicationView) {
            editableProperties.push({
                id: "headerBackgroundColor",
                type: PropertyType.NestedProperties,
                propertiesGroup: [{ ...BACKGROUND_PROPERTY, withGradient: true, title: "Header Background Color", borderBottom: false }],
            });
            editableProperties.push({
                ...LEAD_IMAGE_PROPERTY,
                title: "Header Background Image",
                hideContent: true,
                hideDescription: true,
                selectImageTitle: "Select Header Background Image",
                viewTitle: "Configure Background Image",
            });
            editableProperties.push({
                id: "headerTextColor",
                title: "Header Content Text Color",
                type: PropertyType.IconSelector,
                items: [
                    {
                        text: "Dark",
                        value: TextColor.Dark,
                        tooltip: "Dark",
                    },
                    {
                        text: "Light",
                        value: TextColor.Light,
                        tooltip: "Light",
                    },
                ],
                defaultValue: TextColor.Dark,
            });
        }

        // Remove padding and margin properties from the submitted application page
        if (![PageLink.ApplicationView, PageLink.CustomerHome].includes(page.link as PageLink)) {
            editableProperties.push(paddingProperty, { ...marginProperty, borderBottom: !standardPageRowsHidden });
        }

        if (!standardPageRowsHidden && page.link !== PageLink.ApplicationView) {
            editableProperties.push(
                { ...standardPageRows, isTop: true, titleSwitch: titleSwitchTopRows, title: "Introduction Content" },
                {
                    ...standardPageRows,
                    titleSwitch: titleSwitchBottomRows,
                    borderBottom: false,
                    title: page.link === "/prescreen" ? "Error Content" : "Additional Content",
                }
            );
        }

        if (page.link === "/contact-us") {
            editableProperties.push({
                id: "props",
                type: PropertyType.NestedProperties,
                propertiesGroup: getContactUsPageProperties(page),
            });
        }

        return editableProperties;
    }

    // Custom pages properties
    const customPageRowsHidden = Boolean(page.isShared) && isProgramPortalBuilder;

    return [
        titleProperty,
        linkTypeProperty,
        linkProperty,
        ...(page?.link === "/" && page._isHomePage ? [LEAD_IMAGE_PROPERTY] : []),
        ...(page?.link !== "/cookies" ? [pageAccessProperty] : []),
        headerNavigationProperty,
        orderProperty,
        openInNewTabProperty,
        backgroundProperty,
        paddingProperty,
        { ...marginProperty, borderBottom: !customPageRowsHidden },
        {
            id: "components",
            title: "Rows",
            type: "rows",
            hidden: customPageRowsHidden,
        },
    ];
};

// Editable standard pages specified in https://appliedenergygroup.atlassian.net/browse/V50-6481?focusedCommentId=41861
export const editableStandardPages = [
    PageLink.CustomerHome,
    "/contact-us",
    "/application-submitted",
    "/extform-submitted",
    "/contractor-search",
    "/prescreen",
    "/forgot-password",
    "/create-account",
    "/customerReset",
    "/sign-in",
    "/application",
    PageLink.ApplicationView,
];

export const portalAuthorizedPages: string[] = [
    PageLink.CustomerHome,
    "/contacts",
    "/account-details",
    "/application",
    PageLink.ApplicationView,
    "/application-submitted",
    "/sign-out",
    "/change-password",
    "/change-email",
    "/prescreen",
];

export const mainPageTab: PageTab = {
    id: "mainPage",
    title: "Main Page",
};

export const thankYouTab: PageTab = {
    id: "thankYou",
    title: "Thank You Page",
};

export const contactUsPageTabs: PageTab[] = [mainPageTab, thankYouTab];

export const contactUsThankYouPageProperties = [
    {
        id: "props",
        type: PropertyType.NestedProperties,
        propertiesGroup: [
            {
                id: "thankYouTitle",
                title: "Page Title",
                type: PropertyType.Text,
                fullWidth: true,
                borderBottom: false,
                validationRules: [ValidationRule.Required],
                tab: thankYouTab,
            },
            {
                id: "thankYouContent",
                title: "Page Content",
                type: "html",
                borderBottom: false,
                validationRules: [ValidationRule.Required],
                toolbar: ToolbarTypes.Texts,
                tab: thankYouTab,
            },
        ],
    },
];

export const getContactUsPageProperties = (page: PageConfiguration) => {
    const notAllowedTooltip = "Not possible to switch OFF. Not allowed to leave a page empty.";

    return [
        {
            id: "contactUsTextOn",
            title: "Contact Us Text",
            disabled: page.props?.contactUsTextOn && page.props?.contactUsFormOn === false,
            type: PropertyType.LabelSwitch,
            ...(!page.props?.contactUsFormOn ? { switchOffText: notAllowedTooltip } : {}),
            requiredIndicator: true,
            titleSwitch: {
                id: "contactUsTextOn",
                defaultValue: false,
            },
            propertiesGroup: [
                ...(page.props?.contactUsTextOn
                    ? [
                          {
                              id: "contactUsText",
                              type: "html",
                              borderBottom: false,
                              validationRules: [ValidationRule.Required],
                              toolbar: ToolbarTypes.Texts,
                          },
                      ]
                    : []),
            ],
        },
        {
            id: "contactUsFormOn",
            ...(!page.props?.contactUsTextOn ? { switchOffText: notAllowedTooltip } : {}),
            disabled: page.props?.contactUsFormOn !== false && !page.props?.contactUsTextOn,
            title: "Contact Us Form",
            type: PropertyType.Switch,
            defaultValue: true,
            borderBottom: false,
        },
    ];
};

const isDuplicateLink = (link: string, existingPages: PageConfiguration[]) => existingPages.some((p) => p.link === link);

export const copyPage = (
    isProgramPortalBuilder: boolean,
    existingPages: PageConfiguration[],
    config: PageConfiguration
): PageConfiguration => {
    let copySuffix = 0;
    let getLink = (suffix: number) => {
        if (suffix === 0) {
            return `${config?.link}-copy`;
        } else {
            return `${config?.link}-copy-${suffix}`;
        }
    };

    while (isDuplicateLink(getLink(copySuffix), existingPages)) {
        copySuffix = copySuffix + 1;
    }

    return {
        ...config,
        title: `${config?.title}-COPY`,
        link: getLink(copySuffix),
        order: undefined,
        isShared: !isProgramPortalBuilder,
        headerNavigation: HeaderNavigation.NEVER,
    };
};

export const createNewPage = (isProgramPortalBuilder: boolean, existingPages: PageConfiguration[] = []): PageConfiguration => {
    let getLink = (index: number) => `/page-${index}`;
    let getTitle = (index: number) => `Page ${index}`;

    let pageNumber = 1;
    while (isDuplicateLink(getLink(pageNumber), existingPages)) {
        pageNumber = pageNumber + 1;
    }

    return {
        title: getTitle(pageNumber),
        link: getLink(pageNumber),
        linkType: LinkType.INTERNAL,
        pageAccess: PageAccessType.PUBLIC,
        headerNavigation: HeaderNavigation.NEVER,
        openInNewTab: false,
        custom: true,
        isShared: !isProgramPortalBuilder,
        order: undefined,
        components: addWidget([], WidgetType.ROW),
    };
};

export const getPageConfigByLink = (link: string | undefined, config: PortalTemplateConfiguration) => {
    return (config?.content?.pages ?? []).find((p) => p.link === link);
};

export const getPageIndexByLink = (link: string | undefined, config: PortalTemplateConfiguration) => {
    return (config?.content?.pages ?? []).findIndex((p) => p.link === link);
};

export const getProgramLevelPageTitle = (
    page: PageConfiguration | undefined,
    utilityTemplateConfiguration: PortalTemplateConfiguration
) => {
    if (!page) {
        return undefined;
    }

    return isEmpty(page.title) ? getPageConfigByLink(page.link, utilityTemplateConfiguration)?.title : page.title;
};

export const getProgramLevelPageHeaderNavigation = (
    page: PageConfiguration | undefined,
    utilityTemplateConfiguration: PortalTemplateConfiguration
) => {
    if (!page) {
        return undefined;
    }

    return isEmpty(page.headerNavigation)
        ? getPageConfigByLink(page.link, utilityTemplateConfiguration)?.headerNavigation
        : page.headerNavigation;
};

export const getProgramLevelPageOpenInNewTab = (
    page: PageConfiguration | undefined,
    utilityTemplateConfiguration: PortalTemplateConfiguration
) => {
    if (!page) {
        return undefined;
    }

    return isNil(page.openInNewTab) ? getPageConfigByLink(page.link, utilityTemplateConfiguration)?.openInNewTab : page.openInNewTab;
};

export const getProgramLevelPageOrder = (
    page: PageConfiguration | undefined,
    utilityTemplateConfiguration: PortalTemplateConfiguration
) => {
    if (!page) {
        return undefined;
    }

    return isNil(page.order) ? getPageConfigByLink(page.link, utilityTemplateConfiguration)?.order : page.order;
};

export const getProgramLevelPageAccess = (
    page: PageConfiguration | undefined,
    utilityTemplateConfiguration: PortalTemplateConfiguration
) => {
    if (!page) {
        return undefined;
    }

    return isEmpty(page.pageAccess) ? getPageConfigByLink(page.link, utilityTemplateConfiguration)?.pageAccess : page.pageAccess;
};

export const getProgramLevelPageComponents = (
    page: PageConfiguration | undefined,
    utilityTemplateConfiguration: PortalTemplateConfiguration
) => {
    if (!page) {
        return undefined;
    }

    return isEmpty(page.components) ? getPageConfigByLink(page.link, utilityTemplateConfiguration)?.components : page.components;
};

export const getProgramLevelBackgroundProperties = (
    page: PageConfiguration | undefined,
    utilityTemplateConfiguration: PortalTemplateConfiguration
): BackgroundProperties => {
    if (!page) {
        return {};
    }

    const utilityLevelPage = getPageConfigByLink(page.link, utilityTemplateConfiguration);
    const isProgramLevelBackgroundSet = BACKGROUND_PROPERTY_KEYS.some((key) => !isNil(page[key]));

    return isProgramLevelBackgroundSet ? pick(page, BACKGROUND_PROPERTY_KEYS) : pick(utilityLevelPage, BACKGROUND_PROPERTY_KEYS);
};

export const getProgramLevelPaddingProperties = (
    page: PageConfiguration | undefined,
    utilityTemplateConfiguration: PortalTemplateConfiguration
): PaddingProperties => {
    if (!page) {
        return {};
    }

    const utilityLevelPage = getPageConfigByLink(page.link, utilityTemplateConfiguration);
    const isProgramLevelPaddingSet = PADDING_PROPERTY_KEYS.some((key) => !isNil(page[key]));

    return isProgramLevelPaddingSet ? pick(page, PADDING_PROPERTY_KEYS) : pick(utilityLevelPage, PADDING_PROPERTY_KEYS);
};

export const getProgramLevelMarginProperties = (
    page: PageConfiguration | undefined,
    utilityTemplateConfiguration: PortalTemplateConfiguration
): MarginProperties => {
    if (!page) {
        return {};
    }

    const utilityLevelPage = getPageConfigByLink(page.link, utilityTemplateConfiguration);
    const isProgramLevelMarginSet = MARGIN_PROPERTY_KEYS.some((key) => !isNil(page[key]));

    return isProgramLevelMarginSet ? pick(page, MARGIN_PROPERTY_KEYS) : pick(utilityLevelPage, MARGIN_PROPERTY_KEYS);
};

export const getProgramLevelStandardPageEditProperties = (
    page: PageConfiguration | undefined,
    utilityTemplateConfiguration: PortalTemplateConfiguration
): StandardPageEditProperties => {
    if (!page) {
        return {};
    }

    const utilityLevelPage = getPageConfigByLink(page.link, utilityTemplateConfiguration);

    const headerTextColor =
        !isNil(utilityLevelPage?.headerTextColor) && isNil(page.headerTextColor)
            ? { headerTextColor: utilityLevelPage?.headerTextColor }
            : {};

    if (!isNil(page.topRowsOff) || !isNil(page.bottomRowsOff)) {
        return {
            ...headerTextColor,
            topRowsOff: page.topRowsOff,
            bottomRowsOff: page.bottomRowsOff,
        };
    }

    return {
        ...headerTextColor,
        topRowsOff: utilityLevelPage?.topRowsOff,
        bottomRowsOff: utilityLevelPage?.bottomRowsOff,
    };
};

// Page specific props. contact-us page has these.
export const getProgramLevelPageProps = (
    page: PageConfiguration | undefined,
    utilityTemplateConfiguration: PortalTemplateConfiguration
): { props?: ContactUsPageProps } => {
    if (!page) {
        return {};
    }

    return isNil(page.props) ? pick(getPageConfigByLink(page.link, utilityTemplateConfiguration), ["props"]) : pick(page, ["props"]);
};

export const getProgramLevelPageConfig = (
    page: PageConfiguration,
    utilityTemplateConfiguration: PortalTemplateConfiguration
): PageConfiguration => {
    const backgroundProperties = getProgramLevelBackgroundProperties(page, utilityTemplateConfiguration);
    const paddingProperties = PAGES_WITHOUT_SPACING_PROPS.includes(page.link as PageLink)
        ? {}
        : getProgramLevelPaddingProperties(page, utilityTemplateConfiguration);
    const marginProperties = PAGES_WITHOUT_SPACING_PROPS.includes(page.link as PageLink)
        ? {}
        : getProgramLevelMarginProperties(page, utilityTemplateConfiguration);
    const standardPageEditProperties = getProgramLevelStandardPageEditProperties(page, utilityTemplateConfiguration);
    const pageProps = getProgramLevelPageProps(page, utilityTemplateConfiguration);

    // Take only the properties that are set.
    return omitBy<PageConfiguration>(
        {
            ...page,
            title: getProgramLevelPageTitle(page, utilityTemplateConfiguration),
            headerNavigation: getProgramLevelPageHeaderNavigation(page, utilityTemplateConfiguration),
            order: getProgramLevelPageOrder(page, utilityTemplateConfiguration),
            openInNewTab: getProgramLevelPageOpenInNewTab(page, utilityTemplateConfiguration),
            pageAccess: getProgramLevelPageAccess(page, utilityTemplateConfiguration),
            components: getProgramLevelPageComponents(page, utilityTemplateConfiguration),

            ...backgroundProperties,
            ...paddingProperties,
            ...marginProperties,
            ...standardPageEditProperties,
            ...pageProps,
        },
        isNil
    );
};

export const transformProgramPortalConfig = (data: PortalConfigurationResponse) => {
    const transformedData = {
        ...data,
        programTemplateConfiguration: convertToObject(data.programTemplateConfiguration ?? "") as ProgramTemplateConfiguration,
        utilityTemplateConfiguration: convertToObject(data.utilityTemplateConfiguration ?? "") as PortalTemplateConfiguration,
    };

    // Set default program level config if it is empty
    if (isEmpty(transformedData.programTemplateConfiguration)) {
        transformedData.programTemplateConfiguration = DEFAULT_PROGRAM_TEMPLATE_CONFIG;
    }

    // Ensure utility pages are correct
    const updatedUtilityPages = transformUtilityStandardPages(transformedData.utilityTemplateConfiguration?.content?.pages ?? []);
    set(transformedData.utilityTemplateConfiguration, "content.pages", updatedUtilityPages);

    const pages = getProgramLevelPages(
        transformedData.utilityTemplateConfiguration,
        transformedData.programTemplateConfiguration?.content?.pages
    );
    set(transformedData.programTemplateConfiguration, "content.pages", pages);

    return transformedData;
};

export const transformUtilityPortalConfig = (data: UtilityPortalConfigurationResponse, portalTemplateNumber: string) => {
    const template = {
        ...data,
        portalTemplateNumber,
        configuration: convertToObject(data.configuration ?? "") as PortalTemplateConfiguration,
    };

    // Ensure utility pages are correct
    const updatedUtilityPages = transformUtilityStandardPages(template.configuration?.content?.pages ?? []);
    set(template.configuration, "content.pages", updatedUtilityPages);

    return template;
};

/**
 * Remove not overridden page properties on program level.
 * Removed properties will be taken from utility level page config.
 */
export const cleanProgramLevelConfiguration = (
    config: ProgramTemplateConfiguration,
    utilityTemplateConfiguration: PortalTemplateConfiguration | undefined
) => {
    let updatedConfig = cloneDeep(config);
    updatedConfig = stripHelperValues(updatedConfig);

    if (updatedConfig?.content?.pages) {
        const programPages = updatedConfig.content.pages;
        const utilityPages = utilityTemplateConfiguration?.content?.pages ?? [];

        utilityPages.forEach((utilityPage) => {
            const programPage = programPages.find((p) => p.link === utilityPage.link);

            if (programPage) {
                if (programPage.title === utilityPage.title) {
                    delete programPage.title;
                }

                if (programPage.headerNavigation === utilityPage.headerNavigation) {
                    delete programPage.headerNavigation;
                }

                if (programPage.openInNewTab === utilityPage.openInNewTab) {
                    delete programPage.openInNewTab;
                }

                if (programPage.order === utilityPage.order) {
                    delete programPage.order;
                }

                if (programPage.pageAccess === utilityPage.pageAccess) {
                    delete programPage.pageAccess;
                }

                if (isEqual(programPage.components, utilityPage.components)) {
                    delete programPage.components;
                }

                if (BACKGROUND_PROPERTY_KEYS.every((key) => programPage[key] === utilityPage[key])) {
                    delete programPage.backgroundOff;
                    delete programPage.colorType;
                    delete programPage.backgroundWidth;
                    delete programPage.backgroundColor;
                    delete programPage.backgroundColorOpacity;
                    delete programPage.firstGradientValue;
                    delete programPage.firstGradientColor;
                    delete programPage.secondGradientValue;
                    delete programPage.secondGradientColor;
                    delete programPage.gradientDirection;
                    delete programPage.backgroundImage;
                    delete programPage.backgroundRepeat;
                    delete programPage.backgroundPosition;
                    delete programPage.backgroundSize;
                }

                if (PADDING_PROPERTY_KEYS.every((key) => programPage[key] === utilityPage[key])) {
                    delete programPage.padding;
                    delete programPage.paddingBottom;
                    delete programPage.paddingLeft;
                    delete programPage.paddingRight;
                    delete programPage.paddingTop;
                }

                if (MARGIN_PROPERTY_KEYS.every((key) => programPage[key] === utilityPage[key])) {
                    delete programPage.margin;
                    delete programPage.marginBottom;
                    delete programPage.marginLeft;
                    delete programPage.marginRight;
                    delete programPage.marginTop;
                }

                if (
                    isEqual(programPage.topRowsOff ?? true, utilityPage.topRowsOff ?? true) &&
                    isEqual(programPage.bottomRowsOff ?? true, utilityPage.bottomRowsOff ?? true)
                ) {
                    delete programPage.topRowsOff;
                    delete programPage.bottomRowsOff;
                }

                if (isEqual(programPage.props, utilityPage.props)) {
                    delete programPage.props;
                }
            }
        });

        updatedConfig.content.pages = programPages;
    }

    return updatedConfig;
};

/**
 * Check if page is overridden on program level.
 * @returns True if page is overridden on program level, false otherwise
 */
export const isPageOverridden = (
    page: PageConfiguration | undefined,
    isProgramPortalBuilder: boolean,
    utilityTemplateConfiguration: PortalTemplateConfiguration | undefined,
    pageProperties: PortalBuilderProperty[]
) => {
    if (page && utilityTemplateConfiguration && isProgramPortalBuilder) {
        const utilityLevelPage = utilityTemplateConfiguration?.content?.pages?.find((p) => p.link === page.link);
        const programLevelPage = getProgramLevelPageConfig(page, utilityTemplateConfiguration);

        if (utilityLevelPage && programLevelPage) {
            const propertyKeys = pageProperties
                .flatMap((p) => {
                    // Check changes for whole props object.
                    if (p.id === "props") {
                        return p.id;
                    }

                    if (p.propertiesGroup) {
                        if (p.titleSwitch) {
                            return [p.titleSwitch.id].concat(p.propertiesGroup.map((pg) => pg.id));
                        }

                        return p.propertiesGroup.map((pg) => pg.id);
                    }

                    if (p.titleSwitch) {
                        return [p.id, p.titleSwitch.id];
                    }

                    return p.id;
                })
                // Filter out helper props
                .filter((p) => !p.startsWith("_"))
                // Filter out props that no need to check
                .filter((p) => !["linkType"].includes(p)) as (keyof PageConfiguration)[];

            return !propertyKeys.every((key) => {
                const programLevelValue = programLevelPage[key];
                const utilityLevelValue = utilityLevelPage[key];

                // Not overridden if value is equal to utility level value
                if (isEqual(programLevelValue, utilityLevelValue)) {
                    return true;
                }

                // Otherwise, value is overridden
                return false;
            });
        }
    }

    return false;
};

export const resetProgramLevelPage = (page: PageConfiguration | undefined, utilityTemplateConfiguration: PortalTemplateConfiguration) => {
    if (page) {
        const utilityLevelPage = utilityTemplateConfiguration?.content?.pages?.find((p) => p.link === page.link);
        if (utilityLevelPage) {
            const restoredPage = cloneDeep(utilityLevelPage);

            if (!isNil(page._isHomePage)) {
                restoredPage._isHomePage = page._isHomePage;
            }

            if (!isNil(page._activeTab)) {
                restoredPage._activeTab = page._activeTab;
            }

            return restoredPage;
        }
    }

    // Return null if resetting did not work
    return null;
};

const BACKGROUND_PROPERTY_KEYS: (keyof BackgroundProperties)[] = [
    "backgroundOff",
    "backgroundWidth",
    "colorType",

    // Solid color props
    "backgroundColor",
    "backgroundColorOpacity",

    // Gradient color props
    "firstGradientColor",
    "firstGradientValue",
    "secondGradientColor",
    "secondGradientValue",
    "gradientDirection",

    // Image props
    "backgroundImage",
    "backgroundRepeat",
    "backgroundPosition",
    "backgroundSize",
];

const PADDING_PROPERTY_KEYS: (keyof PaddingProperties)[] = ["padding", "paddingBottom", "paddingLeft", "paddingRight", "paddingTop"];

const MARGIN_PROPERTY_KEYS: (keyof MarginProperties)[] = ["margin", "marginBottom", "marginLeft", "marginRight", "marginTop"];
