import React, { useRef } from "react";
import { isEmpty } from "lodash";
import cn from "classnames";
import { BorderPropertyContext } from "components/ui/PortalBuilder/contexts";
import { PropertyList } from "../../PropertyList";
import { PropertyListItemTitle } from "../../PropertyList/PropertyListItemTitle";
import { PortalBuilderPropertyProps, PropertyChangeFunc, PropertyName, PropertyType } from "components/ui/PortalBuilder/types";
import { WidthAllSidesProperty } from "./WidthAllSidesProperty";
import { WidthSideBySideProperty } from "./WidthSideBySideProperty";
import { ColorSideBySideProperty } from "./ColorSideBySideProperty";
import { ColorAllSidesProperty } from "./ColorAllSidesProperty";
import { BorderCustomPropertyName, BorderSideName, BorderSides } from "./types";
import sassVariables from "assets/sass/_export.module.scss";
import { PropertyListItem } from "../PropertyListItem";

import "./BorderProperty.scss";

export const DefaultBorderWidth = "1px";
export const DefaultBorderColor = sassVariables.colorBaseGrey;
export const DefaultBorderStyle = "solid";

export const BorderProperty = (props: PortalBuilderPropertyProps) => {
    const { className, title, value = {}, nestingLevel = 0, borderTop, borderBottom, onChange, titleSwitch, defaultValue } = props;

    const valueRef = useRef(value);
    const isExpanded = Object.values(value).some((v) => !isEmpty(v)) ?? titleSwitch?.defaultValue;

    const customPropertiesValue = {
        [BorderCustomPropertyName.BorderWidthType]: isEmpty(value[PropertyName.BorderWidth])
            ? BorderSides.SideBySide
            : BorderSides.AllSides,
        [BorderCustomPropertyName.BorderWidthAllSides]: getBorderWidth(value[PropertyName.BorderWidth]),
        [BorderCustomPropertyName.BorderWidthSideBySide]: {
            [BorderSideName.Top]: getBorderWidth(value[PropertyName.BorderTopWidth]),
            [BorderSideName.Right]: getBorderWidth(value[PropertyName.BorderRightWidth]),
            [BorderSideName.Bottom]: getBorderWidth(value[PropertyName.BorderBottomWidth]),
            [BorderSideName.Left]: getBorderWidth(value[PropertyName.BorderLeftWidth]),
        },
        [BorderCustomPropertyName.BorderColorType]: isEmpty(value[PropertyName.BorderColor])
            ? BorderSides.SideBySide
            : BorderSides.AllSides,
        [BorderCustomPropertyName.BorderColorAllSides]: value[PropertyName.BorderColor],
        [BorderCustomPropertyName.BorderColorSideBySide]: {
            [BorderSideName.Top]: value[PropertyName.BorderTopColor],
            [BorderSideName.Right]: value[PropertyName.BorderRightColor],
            [BorderSideName.Bottom]: value[PropertyName.BorderBottomColor],
            [BorderSideName.Left]: value[PropertyName.BorderLeftColor],
        },
        [PropertyName.BorderColorOpacity]: value[PropertyName.BorderColorOpacity],
    };

    const onPropertyChange = (id: string, propertyValue: string) => {
        switch (id) {
            case BorderCustomPropertyName.BorderWidthType:
                onBorderWidthTypeChange(propertyValue, value, onChange);
                break;
            case BorderCustomPropertyName.BorderColorType:
                onBorderColorTypeChange(propertyValue, value, onChange);
                break;
            default:
                onChange(id, propertyValue);
                break;
        }
    };

    // On Enable/disable the border
    const onToggle = () => {
        if (isExpanded) {
            valueRef.current = value;

            const extraProperties = [
                ...emptyAllSidesWidthProperties,
                ...emptySideBySideWidthProperties,

                ...emptyAllSidesStyleProperties,
                ...emptySideBySideStyleProperties,

                ...emptyAllSidesColorProperties,
                ...emptySideBySideColorProperties,
                {
                    id: PropertyName.BorderColorOpacity,
                    value: undefined,
                },
            ];

            // Clear all border properties
            onChange(PropertyName.BorderStyle, undefined, extraProperties);
        } else {
            const defaultBorderStyle = Object.values(valueRef.current).every(isEmpty)
                ? DefaultBorderStyle
                : valueRef.current[PropertyName.BorderStyle];

            const extraSideBySideWidthProperties = [
                {
                    id: PropertyName.BorderTopWidth,
                    value: valueRef.current[PropertyName.BorderTopWidth],
                },
                {
                    id: PropertyName.BorderRightWidth,
                    value: valueRef.current[PropertyName.BorderRightWidth],
                },
                {
                    id: PropertyName.BorderBottomWidth,
                    value: valueRef.current[PropertyName.BorderBottomWidth],
                },
                {
                    id: PropertyName.BorderLeftWidth,
                    value: valueRef.current[PropertyName.BorderLeftWidth],
                },
            ];

            const extraSideBySideStyleProperties = [
                {
                    id: PropertyName.BorderTopStyle,
                    value: valueRef.current[PropertyName.BorderTopStyle],
                },
                {
                    id: PropertyName.BorderRightStyle,
                    value: valueRef.current[PropertyName.BorderRightStyle],
                },
                {
                    id: PropertyName.BorderBottomStyle,
                    value: valueRef.current[PropertyName.BorderBottomStyle],
                },
                {
                    id: PropertyName.BorderLeftStyle,
                    value: valueRef.current[PropertyName.BorderLeftStyle],
                },
            ];

            const extraSideBySideColorProperties = [
                {
                    id: PropertyName.BorderTopColor,
                    value: valueRef.current[PropertyName.BorderTopColor],
                },
                {
                    id: PropertyName.BorderRightColor,
                    value: valueRef.current[PropertyName.BorderRightColor],
                },
                {
                    id: PropertyName.BorderBottomColor,
                    value: valueRef.current[PropertyName.BorderBottomColor],
                },
                {
                    id: PropertyName.BorderLeftColor,
                    value: valueRef.current[PropertyName.BorderLeftColor],
                },
            ];

            const otherExtraProperties = [
                {
                    id: PropertyName.BorderColorOpacity,
                    value: valueRef.current[PropertyName.BorderColorOpacity],
                },
                {
                    id: PropertyName.BorderWidth,
                    value: Object.values(valueRef.current).every(isEmpty) ? DefaultBorderWidth : valueRef.current[PropertyName.BorderWidth],
                },
                {
                    id: PropertyName.BorderColor,
                    value: Object.values(valueRef.current).every(isEmpty)
                        ? defaultValue?.[PropertyName.BorderColor] ?? DefaultBorderColor
                        : valueRef.current[PropertyName.BorderColor],
                },
            ];

            const extraProperties = [
                ...emptyAllSidesWidthProperties.map((i) => ({
                    ...i,
                    value: valueRef.current[PropertyName.BorderWidth] ?? DefaultBorderWidth,
                })),
                ...extraSideBySideWidthProperties,

                ...emptyAllSidesStyleProperties.map((i) => ({
                    ...i,
                    value: valueRef.current[PropertyName.BorderStyle] ?? DefaultBorderStyle,
                })),
                ...extraSideBySideStyleProperties,

                ...emptyAllSidesColorProperties.map((i) => ({
                    ...i,
                    value: valueRef.current[PropertyName.BorderColor] ?? defaultValue?.[PropertyName.BorderColor] ?? DefaultBorderColor,
                })),
                ...extraSideBySideColorProperties,
                ...otherExtraProperties,
            ];

            // Set default border properties
            onChange(PropertyName.BorderStyle, defaultBorderStyle, extraProperties);
        }
    };

    const borderPropertyContextValue = {
        customPropertiesValue,
    };

    if (props.hidden) {
        return null;
    }

    return (
        <BorderPropertyContext.Provider value={borderPropertyContextValue}>
            <PropertyListItem
                className={cn("property-list-item--prop-group property-list-item--border", className)}
                borderTop={borderTop}
                borderBottom={borderBottom}
            >
                {/* @ts-ignore */}
                <PropertyListItemTitle
                    title={title}
                    toggleTooltip={isExpanded ? "Switch OFF" : "Switch ON"}
                    onToggle={onToggle}
                    toggleValue={!isExpanded}
                />
                <PropertyList
                    items={customProperties}
                    nestingLevel={nestingLevel + 1}
                    config={customPropertiesValue}
                    onChange={onPropertyChange}
                    isExpanded={isExpanded}
                />
            </PropertyListItem>
        </BorderPropertyContext.Provider>
    );
};

const onBorderWidthTypeChange = (propertyValue: string, propertyGroupValue: any, onChange: PropertyChangeFunc) => {
    if (propertyValue === BorderSides.SideBySide) {
        const extraProperties = [
            ...emptyAllSidesWidthProperties,
            ...emptyAllSidesStyleProperties,
            ...emptySideBySideWidthProperties.map((i) => ({ ...i, value: propertyGroupValue[PropertyName.BorderWidth] })),
            ...emptySideBySideStyleProperties.map((i) => ({ ...i, value: DefaultBorderStyle })),
        ];

        onChange(PropertyName.BorderWidth, undefined, extraProperties);
    } else {
        const extraProperties = [
            ...emptyAllSidesWidthProperties.map((i) => ({ ...i, value: propertyGroupValue[PropertyName.BorderTopWidth] })),
            ...emptyAllSidesStyleProperties.map((i) => ({ ...i, value: DefaultBorderStyle })),
            ...emptySideBySideWidthProperties,
            ...emptySideBySideStyleProperties,
        ];

        onChange(PropertyName.BorderWidth, propertyGroupValue[PropertyName.BorderTopWidth], extraProperties);
    }
};

const onBorderColorTypeChange = (propertyValue: string, propertyGroupValue: any, onChange: PropertyChangeFunc) => {
    if (propertyValue === BorderSides.SideBySide) {
        const extraProperties = [
            ...emptyAllSidesColorProperties,
            ...emptySideBySideColorProperties.map((i) => ({ ...i, value: propertyGroupValue[PropertyName.BorderColor] })),
        ];

        onChange(PropertyName.BorderColor, undefined, extraProperties);
    } else {
        const extraProperties = [
            ...emptyAllSidesColorProperties.map((i) => ({ ...i, value: propertyGroupValue[PropertyName.BorderTopColor] })),
            ...emptySideBySideColorProperties,
        ];

        onChange(PropertyName.BorderColor, propertyGroupValue[PropertyName.BorderTopColor], extraProperties);
    }
};

const getBorderWidth = (cssValue: string) => {
    const result = parseInt(cssValue, 10);
    return isNaN(result) ? 1 : result;
};

const emptyAllSidesWidthProperties = [
    {
        id: PropertyName.BorderWidth,
        value: undefined,
    },
];

const emptySideBySideWidthProperties = [
    {
        id: PropertyName.BorderTopWidth,
        value: undefined,
    },
    {
        id: PropertyName.BorderRightWidth,
        value: undefined,
    },
    {
        id: PropertyName.BorderBottomWidth,
        value: undefined,
    },
    {
        id: PropertyName.BorderLeftWidth,
        value: undefined,
    },
];

export const emptyAllSidesStyleProperties = [
    {
        id: PropertyName.BorderStyle,
        value: undefined,
    },
];

export const emptySideBySideStyleProperties = [
    {
        id: PropertyName.BorderTopStyle,
        value: undefined,
    },
    {
        id: PropertyName.BorderRightStyle,
        value: undefined,
    },
    {
        id: PropertyName.BorderBottomStyle,
        value: undefined,
    },
    {
        id: PropertyName.BorderLeftStyle,
        value: undefined,
    },
];

const emptyAllSidesColorProperties = [
    {
        id: PropertyName.BorderColor,
        value: undefined,
    },
];

const emptySideBySideColorProperties = [
    {
        id: PropertyName.BorderTopColor,
        value: undefined,
    },
    {
        id: PropertyName.BorderRightColor,
        value: undefined,
    },
    {
        id: PropertyName.BorderBottomColor,
        value: undefined,
    },
    {
        id: PropertyName.BorderLeftColor,
        value: undefined,
    },
];

/**
 * properties-group only represents the list of properties that goes to config.
 * UI uses custom properties
 */
export const BORDER_PROPERTY = {
    id: "group-border",
    title: "Border",
    type: PropertyType.Border,
    propertiesGroup: [
        {
            id: PropertyName.BorderStyle,
        },
        {
            id: PropertyName.BorderWidth,
        },
        {
            id: PropertyName.BorderColor,
        },
        {
            id: PropertyName.BorderColorOpacity,
        },
        {
            id: PropertyName.BorderTopStyle,
        },
        {
            id: PropertyName.BorderRightStyle,
        },
        {
            id: PropertyName.BorderBottomStyle,
        },
        {
            id: PropertyName.BorderLeftStyle,
        },
        {
            id: PropertyName.BorderTopWidth,
        },
        {
            id: PropertyName.BorderRightWidth,
        },
        {
            id: PropertyName.BorderBottomWidth,
        },
        {
            id: PropertyName.BorderLeftWidth,
        },
        {
            id: PropertyName.BorderTopColor,
        },
        {
            id: PropertyName.BorderRightColor,
        },
        {
            id: PropertyName.BorderBottomColor,
        },
        {
            id: PropertyName.BorderLeftColor,
        },
    ],
};

const customProperties = [
    {
        id: BorderCustomPropertyName.BorderWidthType,
        title: (
            <>
                Border <br /> width
            </>
        ),
        type: PropertyType.SelectBox,
        borderTop: false,
        borderBottom: false,
        items: [
            {
                text: "Side By Side",
                value: BorderSides.SideBySide,
            },
            {
                text: "All Sides",
                value: BorderSides.AllSides,
            },
        ],
    },
    {
        id: BorderCustomPropertyName.BorderWidthAllSides,
        type: PropertyType.CustomComponent,
        borderTop: true,
        borderBottom: false,
        component: WidthAllSidesProperty,
    },
    {
        id: BorderCustomPropertyName.BorderWidthSideBySide,
        type: PropertyType.CustomComponent,
        component: WidthSideBySideProperty,
    },
    {
        id: BorderCustomPropertyName.BorderColorType,
        title: (
            <>
                Border <br /> color
            </>
        ),
        type: PropertyType.SelectBox,
        borderTop: true,
        borderBottom: false,
        items: [
            {
                text: "Side By Side",
                value: BorderSides.SideBySide,
            },
            {
                text: "All Sides",
                value: BorderSides.AllSides,
            },
        ],
    },
    {
        id: BorderCustomPropertyName.BorderColorAllSides,
        type: PropertyType.CustomComponent,
        component: ColorAllSidesProperty,
        borderTop: true,
        borderBottom: false,
    },
    {
        id: BorderCustomPropertyName.BorderColorSideBySide,
        type: PropertyType.CustomComponent,
        component: ColorSideBySideProperty,
    },
    {
        id: PropertyName.BorderColorOpacity,
        title: (
            <>
                Border color <br /> opacity
            </>
        ),
        type: PropertyType.InputRange,
        borderTop: true,
        borderBottom: false,
    },
];
