import SelectBox from "components/ui/SelectBox";
import React from "react";

export const MarginBox = ({ text, tooltip, isSelected, onClick, allSides }: MarginBoxProps) => {
    const notDeselectable = isSelected && allSides;

    return (
        <SelectBox
            className="margin-box-container flex-one-in-row"
            selectBoxTitle={tooltip}
            selectBoxSelected={isSelected}
            onClick={notDeselectable ? undefined : onClick}
        >
            <div className={`margin-box`}>{text}</div>
        </SelectBox>
    );
};
interface MarginBoxProps {
    text: string;
    tooltip?: string;
    isSelected: boolean;
    onClick: () => void;
    allSides?: boolean;
}
