import React from "react";

import FieldGroupTextarea from "../../../ui/FieldGroupTextarea";

class TextFields extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isError: true,
            isFocus: true,
        };

        this.onTextareaFocus = this.onTextareaFocus.bind(this);
    }
    onTextareaFocus() {
        const newState = !this.state.isFocus;
        this.setState({
            isFocus: newState,
        });
    }
    render() {
        return (
            <div>
                <div className="ui-library-sub-title">Standard</div>
                <div className="flexbox-row">
                    <div className="flexbox-col">
                        <FieldGroupTextarea label="Textfield Label" placeholder="Placeholder"></FieldGroupTextarea>
                    </div>
                    <div className="flexbox-col">
                        <FieldGroupTextarea
                            label="Textfield Active"
                            placeholder="Placeholder"
                            value="Placeholder"
                            focus={this.state.isFocus}
                            onFocus={this.onTextareaFocus}
                        ></FieldGroupTextarea>
                    </div>
                    <div className="flexbox-col">
                        <FieldGroupTextarea
                            label="Textfield Disabled"
                            placeholder="Placeholder"
                            value="Placeholder"
                            disabled
                        ></FieldGroupTextarea>
                    </div>
                    <div className="flexbox-col">
                        <FieldGroupTextarea
                            label="Textfield Validation"
                            labelRequired
                            placeholder="Placeholder"
                            value="Placeholder"
                            error={this.state.isError}
                            msgIcon="warning-report-problem-filled"
                            msgText="Please enter valid email address"
                        ></FieldGroupTextarea>
                    </div>
                </div>
                <div className="flexbox-row">
                    <div className="flexbox-col">
                        <FieldGroupTextarea
                            label="Textfield Read Only"
                            placeholder="Placeholder"
                            value="Placeholder"
                            readOnly
                        ></FieldGroupTextarea>
                    </div>
                    <div className="flexbox-col"></div>
                    <div className="flexbox-col"></div>
                    <div className="flexbox-col"></div>
                </div>
            </div>
        );
    }
}

export default TextFields;
