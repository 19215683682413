import React from "react";
import cn from "classnames";

import InfoList from "../List/InfoList";

import "./SideNavHeaderInfoList.scss";

const SideNavHeaderInfoList = ({ className, items }) => {
    return <InfoList className={cn("sidenav-header-info-list", className)} items={items} />;
};

export default SideNavHeaderInfoList;
