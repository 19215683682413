import React from "react";
import { get } from "lodash";
import { useCustomer } from "../../../../store/resources/useResource";
import { formatFullName } from "../../../utils/user";

import IconWrap from "../../Icons";
import CopyToClipboard from "../../Label/CopyToClipboard";
import { useCustomerDisabled, customerDisabledText } from "../../../views/CustomerView/utils";

const WindowHeaderForCustomer = (props) => {
    const customerNumber = get(props, `view.props.customerNumber`);
    const utilityNumber = get(props, `view.props.utilityNumber`);

    const [customer] = useCustomer({ utilityNumber, customerNumber });
    const isLocked = useCustomerDisabled({ customerNumber });

    if (!customer) {
        return null;
    }

    return (
        <div className={"window-header-content" + (isLocked ? " app-is-locked" : "")}>
            {isLocked && (
                <div className="app-is-locked-mark">
                    <IconWrap icon="lock-encryption-empty" title={customerDisabledText}></IconWrap>
                </div>
            )}
            <div className="project-mark">
                <span>Customer</span>
            </div>
            <div className="project-number">
                <CopyToClipboard title="Copy Customer Name to clipboard" successMessage="Customer Name copied to clipboard">
                    {formatFullName(customer.firstName, customer.lastName)}
                </CopyToClipboard>
            </div>
        </div>
    );
};

export default WindowHeaderForCustomer;
