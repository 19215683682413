import React, { useState, memo } from "react";
import { useDispatch } from "react-redux";

import { lookupTableColumnKeys } from "../../../../../views/configureGrids";
import { mapGridRowToObject } from "../../../../../utils/datagrid";
import { createResource, updateResource } from "../../../../../../store/resources/actions";

import InlinePanelHeader from "../../../../Dashboard/Panel/InlinePanelHeader";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import { isChildProgram } from "components/views/ProgramView/utils";

const schema = {
    type: "object",
    required: ["lowerLimit", "value"],
    properties: {
        lowerLimit: {
            type: "string",
            title: "Lower Limit",
        },
        upperLimit: {
            type: ["string", "null"],
            title: "Upper Limit",
        },
        value: {
            type: "string",
            title: "Value",
        },
    },
};

const uiSchema = {
    classNames: "inline-form",
    "ui:rootFieldId": "calculation-lookup-item",
};

const LookupTableItemDetailsForm = memo(({ lookupTableNumber, dataItem, gridRefresh, onClose, programNumber }) => {
    const dispatch = useDispatch();

    const isNew = dataItem === undefined;
    const initialValues = isNew ? {} : mapGridRowToObject(lookupTableColumnKeys, dataItem);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = (formData) => {
        const onComplete = () => {
            gridRefresh();
            onClose();
        };

        setIsSubmitting(true);

        if (isNew) {
            dispatch(
                createResource({
                    resourceName: "calculationLookupTableItems",
                    query: {
                        ...formData,
                        attrLookupTableNumber: lookupTableNumber,
                    },
                    onComplete,
                })
            );
        } else {
            dispatch(
                updateResource({
                    resourceName: "calculationLookupTableItems",
                    resourceId: dataItem[lookupTableColumnKeys.attrLookupValueNumber],
                    query: {
                        ...formData,
                    },
                    onComplete,
                })
            );
        }
    };

    const submitText = isSubmitting ? (isNew ? "Creating..." : "Updating...") : isNew ? "Create" : "Update";

    return (
        <div className="flex-column fill-width">
            <InlinePanelHeader title={isNew ? "Add Calculation Lookup" : "Edit Calculation Lookup"} onClose={onClose} />
            <JsonSchemaForm
                readOnly={isChildProgram({ programNumber })}
                schema={schema}
                uiSchema={uiSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
                onCancel={onClose}
                submitText={submitText}
                disabled={isSubmitting}
                withCancel
                noReset
            />
        </div>
    );
});

export default LookupTableItemDetailsForm;
