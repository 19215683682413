import React from "react";

import * as actionTypes from "../actionTypes";
import { dataURItoBlob } from "react-jsonschema-form/lib/utils";
import { createResource, deleteResource, getResource } from "../resources/actions";
import { modalOpen } from "../../store/modal/actions";

import RawHtml from "../../components/ui/RawHtml";

export const BASE_URL = `${process.env.REACT_APP_SYSTEM_API_BASE_URL}files/`;
export const BATCH_BASE_URL = `${process.env.REACT_APP_SYSTEM_API_BASE_URL}documentqueue/batch/`;

export const uploadFile =
    ({ fileData, resourceName, onUploadSuccess, onUploadError }) =>
    (dispatch, getState) => {
        const query = Object.keys(fileData)
            .filter((k) => k !== "file")
            .reduce((params, key) => {
                if (fileData[key]) {
                    params[key] = fileData[key];
                }

                return params;
            }, {});

        fileData.file.forEach((file) => {
            const body = new FormData();
            const { blob, name } = dataURItoBlob(file);
            const decodedName = decodeURIComponent(name);

            body.append("file", blob, decodedName);

            dispatch({
                type: actionTypes.FILES_UPLOAD_REQUEST,
            });

            dispatch(
                createResource({
                    resourceName: resourceName || "files",
                    query,
                    body,
                    onSuccess: (action) => {
                        dispatch({
                            ...action,
                            type: actionTypes.FILES_UPLOAD_SUCCESS,
                        });

                        onUploadSuccess && onUploadSuccess(action);
                    },
                    onError: (action) => {
                        dispatch({
                            ...action,
                            type: actionTypes.FILES_UPLOAD_ERROR,
                        });
                        onUploadError && onUploadError(action.message);
                    },
                })
            );
        });
    };

export const getFileUploadReqs =
    ({ entityTypeId }) =>
    async (dispatch, getState) => {
        dispatch({
            type: actionTypes.API_GET_AUTHORIZED,
            url: process.env.REACT_APP_SYSTEM_API_BASE_URL + "files/supported/" + entityTypeId + "",
            actionTypes: {
                pending: actionTypes.FILES_REQS_REQUEST,
                response: actionTypes.FILES_REQS_SUCCESS,
                error: actionTypes.FILES_REQS_ERROR,
            },
        });
    };

export const downloadFile =
    ({
        fileId,
        onSuccess,
        onError,
        showSuccessNotification = false,
        showErrorNotification = true,
        successMessage,
        errorMessage,
        onComplete,
    }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.API_GET_AUTHORIZED,
            url: `${BASE_URL}${fileId}`,
            actionTypes: {
                pending: actionTypes.FILES_DOWNLOAD_REQUEST,
                response: actionTypes.FILES_DOWNLOAD_SUCCESS,
                error: actionTypes.FILES_DOWNLOAD_ERROR,
            },
            passThroughData: {
                onSuccess,
                onError,
                showSuccessNotification,
                showErrorNotification,
                successMessage,
                errorMessage,
            },
            onComplete: (action) => {
                onComplete && onComplete(action);
            },
        });
    };

export const downloadAllFiles =
    ({ entityId, entityTypeId, folder, onSuccess, onError, onComplete }) =>
    (dispatch) => {
        dispatch({
            type: actionTypes.FILES_DOWNLOAD_REQUEST,
        });

        dispatch(
            getResource({
                resourceName: "files",
                query: {
                    entityId: entityId,
                    entityTypeId: entityTypeId,
                    folder: folder,
                },
                onSuccess: (action) => {
                    dispatch({
                        ...action,
                        type: actionTypes.FILES_DOWNLOAD_SUCCESS,
                    });

                    onSuccess && onSuccess(action);
                },
                onError: (action) => {
                    dispatch({
                        ...action,
                        type: actionTypes.FILES_DOWNLOAD_ERROR,
                    });

                    onError && onError(action);
                },
                onComplete: () => {
                    onComplete && onComplete();
                },
            })
        );
    };

export const previewBatchFile =
    ({ batchNumber, container, onSuccess, onError, onComplete }) =>
    (dispatch) => {
        dispatch({
            type: actionTypes.FILES_PREVIEW_REQUEST,
        });

        dispatch(
            getResource({
                resourceName: "documentQueueBatch",
                resourceId: batchNumber,
                key: container,
                onSuccess: (action) => {
                    dispatch({
                        ...action,
                        type: actionTypes.FILES_PREVIEW_SUCCESS,
                    });

                    onSuccess && onSuccess(action);
                },
                onError: (action) => {
                    dispatch({
                        ...action,
                        type: actionTypes.FILES_PREVIEW_ERROR,
                    });

                    onError && onError(action);
                },
                onComplete: () => {
                    onComplete && onComplete();
                },
            })
        );
    };

export const deleteFile =
    ({ fileId, fileName, onDeleteSuccess }) =>
    (dispatch, getState) => {
        dispatch(
            modalOpen({
                modalType: "CONFIRM",
                modalProps: {
                    title: "Delete File",
                    modalIcon: "delete-trash-empty",
                    overlayClassName: "modal-styled",
                    className: "delete-file-confirmation-modal modal-sm",
                    content: <RawHtml>{`Are you sure you want to delete the file <b>${fileName}</b>?`}</RawHtml>,
                    footerContentCenter: true,
                    onConfirm: () => {
                        dispatch({
                            type: actionTypes.FILES_DELETE_REQUEST,
                        });

                        dispatch(
                            deleteResource({
                                resourceName: "files",
                                resourceId: fileId,
                                onSuccess: (action) => {
                                    dispatch({
                                        ...action,
                                        type: actionTypes.FILES_DELETE_SUCCESS,
                                    });

                                    onDeleteSuccess && onDeleteSuccess(action);
                                },
                                onError: (action) => {
                                    dispatch({
                                        ...action,
                                        type: actionTypes.FILES_DELETE_ERROR,
                                    });
                                },
                            })
                        );
                    },
                },
            })
        );
    };
