import { useIsMobile } from "components/utils/useIsMobile";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getResourcePromise } from "store/resources/useResource";
import Breadcrumb from "../Breadcrumb";
import IconWrap from "../Icons";
import ListItemCategory from "../List/ListItemCategory";
import WaitIcon from "../WaitIcon";
import { SearchResults } from "./SearchResults";

export const SelectFromCatalogTab = memo(
    ({
        applicationNumber,
        itemType,
        title,
        categoriesResourceName,
        categorySearchResourceName,
        categoryItemsResourceParams,
        categoryItemNumberKey,
        categoryItemNameKey,
        searchTerm,
        onResetSearch,
        onSelect,
        onNoCategories,
    }) => {
        const { breadcrumbItems, categoryLevels, isCatalogSearch, isLoadingSearchResults, searchResults, onSelectCategory } =
            useSelectFromCatalogModel({
                applicationNumber,
                categoriesResourceName,
                categorySearchResourceName,
                categoryItemsResourceParams,
                searchTerm,
                onNoCategories,
                onResetSearch,
            });

        return (
            <>
                <div className="catalog-controls">
                    <Breadcrumb items={breadcrumbItems} />
                </div>
                {!isCatalogSearch && (
                    <div className="catalog-columns all flex-one-in-column no-scroll">
                        {categoryLevels.map((level, index) => (
                            <div key={index} className="category-column">
                                {level.isLoading ? (
                                    <WaitIcon />
                                ) : (
                                    <div>
                                        {level.categories.map((item) => (
                                            <ListItemCategory
                                                key={item.categorynumber}
                                                onClick={() => onSelectCategory(item, index)}
                                                listItemSelected={item.categorynumber === level.selectedCategory?.categorynumber}
                                                withArrow
                                            >
                                                {item.category}
                                            </ListItemCategory>
                                        ))}
                                        {level.categoryItems.map((item) => (
                                            <ListItemCategory key={item[categoryItemNumberKey]} onClick={() => onSelect(item)}>
                                                {item[categoryItemNameKey]}
                                                <IconWrap icon="plus" title={title} onClick={() => onSelect(item)} />
                                            </ListItemCategory>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
                {isCatalogSearch && (
                    <div className="category-column">
                        {isLoadingSearchResults ? (
                            <WaitIcon />
                        ) : (
                            <div>
                                <SearchResults
                                    title={title}
                                    itemType={itemType}
                                    categoryItemNumberKey={categoryItemNumberKey}
                                    searchTerm={searchTerm}
                                    searchResults={searchResults}
                                    onSelectItem={onSelect}
                                    onResetSearch={onResetSearch}
                                />
                            </div>
                        )}
                    </div>
                )}
            </>
        );
    }
);

const useSelectFromCatalogModel = ({
    applicationNumber,
    categoriesResourceName,
    categoryItemsResourceParams,
    categorySearchResourceName,
    searchTerm,
    onNoCategories,
    onResetSearch,
}) => {
    const initialized = useRef(false);

    const [state, setState] = useState({
        categoryLevels: [],
    });

    const isMobile = useIsMobile();
    const searchResults = useSelector((state) => state.resources[categorySearchResourceName].itemsById[applicationNumber]);
    const isLoadingSearchResults = useSelector(
        (state) => state.resources[categorySearchResourceName].itemsById[`${applicationNumber}-is-loading`]
    );

    const loadHomeCategories = useCallback(async () => {
        onResetSearch();

        let homeCategoryLevel = {
            isLoading: false,
            selectedCategory: null,
            categories: [],
            categoryItems: [],
        };

        try {
            setState({
                categoryLevels: [
                    {
                        ...homeCategoryLevel,
                        isLoading: true,
                    },
                ],
            });

            const categories = await getResourcePromise({
                resourceName: categoriesResourceName,
                key: applicationNumber,
                path: {
                    appId: applicationNumber,
                },
            });

            setState({
                categoryLevels: [
                    {
                        ...homeCategoryLevel,
                        categories: categories ?? [],
                    },
                ],
            });

            if ((categories?.length ?? 0) === 0) {
                onNoCategories();
            }
        } catch {
            setState({
                categoryLevels: [homeCategoryLevel],
            });

            onNoCategories();
        }
    }, [applicationNumber, categoriesResourceName, onNoCategories, onResetSearch]);

    // Load initial categories
    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            loadHomeCategories();
        }
    }, [loadHomeCategories]);

    const onSelectCategory = useCallback(
        async (category, levelIndex) => {
            const categoryNumber = category?.categorynumber;

            try {
                setState((prev) => ({
                    ...prev,
                    categoryLevels: prev.categoryLevels
                        .filter((_, index) => index <= levelIndex)
                        .map((level, index) => ({
                            ...level,
                            selectedCategory: index === levelIndex ? category : level.selectedCategory,
                        }))
                        .concat({
                            isLoading: true,
                            selectedCategory: null,
                            categories: [],
                            categoryItems: [],
                        }),
                }));

                if (category.childcount === 0) {
                    const categoryItems = await getResourcePromise({
                        ...categoryItemsResourceParams({
                            applicationNumber,
                            categoryNumber,
                        }),
                    });

                    setState((prev) => ({
                        ...prev,
                        categoryLevels: prev.categoryLevels.map((level, index) => ({
                            ...level,
                            isLoading: index > levelIndex ? false : level.isLoading,
                            categoryItems: index > levelIndex ? categoryItems : level.categoryItems,
                        })),
                    }));
                } else {
                    const categories = await getResourcePromise({
                        resourceName: categoriesResourceName,
                        key: `${applicationNumber}-${categoryNumber}`,
                        path: {
                            appId: applicationNumber,
                        },
                        query: {
                            categoryNumber,
                        },
                    });

                    setState((prev) => ({
                        ...prev,
                        categoryLevels: prev.categoryLevels.map((level, index) => ({
                            ...level,
                            isLoading: index > levelIndex ? false : level.isLoading,
                            categories: index > levelIndex ? categories : level.categories,
                        })),
                    }));
                }
            } catch (error) {
                setState((prev) => ({
                    ...prev,
                    categoryLevels: prev.categoryLevels.map((level) => ({
                        ...level,
                        isLoading: false,
                    })),
                }));
            }
        },
        [applicationNumber, categoriesResourceName, categoryItemsResourceParams]
    );

    const breadcrumbItems = useMemo(() => {
        let items = [
            {
                name: "Home categories",
                onClick: loadHomeCategories,
            },
        ];

        if (searchTerm.length > 0) {
            items.push({
                name: "Search",
            });
        } else {
            items = items.concat(
                state.categoryLevels.map((level, index) => ({
                    name: level.selectedCategory?.category,
                    onClick: () => onSelectCategory(level.selectedCategory, index),
                }))
            );
        }

        return items;
    }, [searchTerm, state.categoryLevels, onSelectCategory, loadHomeCategories]);

    const isCatalogSearch = searchTerm.length > 0;

    return useMemo(() => {
        let categoryLevels = state.categoryLevels;

        if (isMobile) {
            categoryLevels = state.categoryLevels.slice(-1);
        }

        return {
            breadcrumbItems,
            categoryLevels,
            searchResults,
            isCatalogSearch,
            isLoadingSearchResults,
            isMobile,
            onSelectCategory,
        };
    }, [onSelectCategory, state, breadcrumbItems, searchResults, isCatalogSearch, isLoadingSearchResults, isMobile]);
};
