import React from "react";
import NothingFoundBlock from "../NothingFoundBlock";

export const PortalTemplateThumbnail = ({ thumbnail }) => {
    return thumbnail ? (
        <img className="portal-template-thumbnail" src={thumbnail} alt="Portal thumbnail" />
    ) : (
        <NothingFoundBlock nothingFoundBlockNoBorder nothingFoundBlockSmall icon="domain-empty" />
    );
};
