import React, { useContext, useCallback, memo } from "react";
import { connect } from "react-redux";

import Button from "../../../../ui/Button";
import { modalOpen as modalOpenAction } from "../../../../../store/modal/actions";
import { BatchGridContext } from "../../context/BatchGridContext";

import "./Controls.scss";

const ControlsContainer = memo(({ isSelectionDisabled, modalOpen }) => {
    const { selectedBatchDocumentNumbers, selectedBatchNumbers, isBatchProcessing, revertBatch, reprintBatch } =
        useContext(BatchGridContext);

    const handleClickRevertBatch = useCallback(() => {
        const text = <p>Are you sure you want to return selected batches to document queue?</p>;

        if (!isSelectionDisabled && selectedBatchDocumentNumbers.length) {
            modalOpen({
                modalType: "CONFIRM",
                modalProps: {
                    title: "Revert to Queue",
                    overlayClassName: "modal-styled",
                    className: "revert-to-queue-confirmation-modal modal-sm",
                    content: text,
                    footerContentCenter: true,
                    onConfirm: () => {
                        revertBatch();
                    },
                },
            });
        }
    }, [selectedBatchDocumentNumbers, isSelectionDisabled, revertBatch, modalOpen]);

    const handleClickReprintBatch = useCallback(() => {
        const text = <p>Are you sure you want to re-print selected batches?</p>;

        if (!isSelectionDisabled && selectedBatchNumbers.length) {
            modalOpen({
                modalType: "CONFIRM",
                modalProps: {
                    title: "Print Again",
                    overlayClassName: "modal-styled",
                    className: "print-again-confirmation-modal modal-sm",
                    modalIcon: "printer-empty",
                    content: text,
                    footerContentCenter: true,
                    onConfirm: () => {
                        reprintBatch();
                    },
                },
            });
        }
    }, [selectedBatchNumbers, isSelectionDisabled, reprintBatch, modalOpen]);

    return (
        <div
            className={
                "application-document-queue__batch-grid-controls flex-row align-center" + (selectedBatchNumbers.length > 0 ? "" : " hidden")
            }
        >
            <Button
                primary
                icon="arrow-left"
                onClick={handleClickRevertBatch}
                disabled={isBatchProcessing || isSelectionDisabled || !selectedBatchDocumentNumbers.length}
            >
                {isBatchProcessing ? "Processing..." : "Revert to Queue"}
            </Button>
            <div className="application-document-queue__batch-grid-controls-selected-count flex-one">
                {selectedBatchNumbers.length
                    ? `${selectedBatchNumbers.length} ${selectedBatchNumbers.length === 1 ? "Batch" : "Batches"} selected`
                    : "No Batches selected"}
            </div>
            <Button
                className="reprint-batch-button"
                primary
                icon="printer-empty"
                onClick={handleClickReprintBatch}
                disabled={isBatchProcessing || isSelectionDisabled || !selectedBatchNumbers.length}
            >
                {isBatchProcessing ? "Processing..." : "Print Again"}
            </Button>
        </div>
    );
});

export const Controls = connect(null, { modalOpen: modalOpenAction })(ControlsContainer);
