import React, { useCallback, useContext } from "react";
import { PropertyListItem } from "./PropertyListItem";
import { PropertyListItemTitle } from "../PropertyList/PropertyListItemTitle";
import { PropertyList } from "../PropertyList";
import { errorObjectToArray, SwitchTooltip } from "../../utils";
import { PageContext } from "../../contexts";
import cn from "classnames";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";

// generic property for properties with a title switch

export const LabelSwitchProperty = (props) => {
    const {
        title,
        titleSwitch,
        propertiesGroup = [],
        value = [],
        nestingLevel,
        onChange,
        titleSwitchValue,
        disabled,
        switchOffText,
        switchOnText,
        requiredIndicator,
        errors,
    } = props;

    const [activeError] = usePortalBuilderState((state) => state.activeError);

    const errorArray = errorObjectToArray(errors);

    const { page } = useContext(PageContext);

    const containsActiveError = page?.index === activeError?.pageIndex;

    const onClick = useCallback(() => {
        const nextState = !titleSwitchValue;
        onChange(titleSwitch?.id, nextState);
    }, [titleSwitch?.id, onChange, titleSwitchValue]);
    return (
        <PropertyListItem className="property-list-item--prop-group property-list-item--label-toggle-prop">
            <PropertyListItemTitle
                title={title}
                toggleTooltip={titleSwitchValue ? switchOffText ?? SwitchTooltip.SWITCH_OFF : switchOnText ?? SwitchTooltip.SWITCH_ON}
                onToggle={onClick}
                toggleValue={!titleSwitchValue}
                disabled={disabled}
                className={cn({
                    required: requiredIndicator,
                })}
            />
            <PropertyList
                items={propertiesGroup}
                nestingLevel={nestingLevel + 1}
                config={value}
                onChange={onChange}
                isExpanded={titleSwitchValue}
                containsActiveError={containsActiveError}
                errors={errorArray}
            />
        </PropertyListItem>
    );
};
