import React, { memo, useCallback } from "react";
import { toast } from "react-toastify";
import { copyToClipboard } from "components/utils/string";
import IconWrap from "../Icons";

const CopyToClipboard = memo(({ title, children, successMessage }) => {
    const onCopyToClipboard = useCallback(() => {
        copyToClipboard(children, () => {
            toast.success(successMessage);
        });
    }, [children, successMessage]);

    return (
        <>
            {children}
            <IconWrap iconWrapWhite icon="layers-empty" title={title} onClick={onCopyToClipboard} />
        </>
    );
});

export default CopyToClipboard;
