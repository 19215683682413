import React, { memo, useId } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { isNil, isEmpty } from "lodash";
import IconWrap from "../Icons";
import ScrollControls from "../ScrollControls";

import "./SubHeader.scss";

const SubHeader = memo(({ items, className }) => {
    const targetId = useId();
    const isDesktop = useSelector((state) => state.window?.isDesktop);

    return (
        <div className={cn("sub-header", className)}>
            <div className="sub-header-visible-part">
                <div className="header-items-list">
                    <div className="header-items-list-wrap" id={targetId}>
                        {items.map(
                            (item, index) =>
                                !isEmpty(item) && (
                                    <div key={index} className={"header-item " + item.name}>
                                        <div className="header-item-title">
                                            <span>{item.name}</span>
                                        </div>
                                        <div
                                            className={
                                                "header-value" + ((item.onEdit || item.editComponent) && !item.isLocked ? " editable" : "")
                                            }
                                        >
                                            {item.onClick ? (
                                                <span className="clickable" onClick={item.onClick}>
                                                    {item.value}
                                                </span>
                                            ) : (
                                                <span>{isNil(item.value) ? "-" : item.value}</span>
                                            )}
                                            {!item.isLocked && item.editComponent}
                                            {item.onEdit && !item.isLocked && (
                                                <IconWrap icon="edit-empty" title={item.title} onClick={item.onEdit} />
                                            )}
                                        </div>
                                    </div>
                                )
                        )}
                    </div>
                    {isDesktop && <ScrollControls targetId={targetId} />}
                </div>
            </div>
        </div>
    );
});

export default SubHeader;
