import TabList, { Tab } from "components/ui/List/TabList";
import ViewPlaceholder from "components/ui/ViewPlaceholder";
import WaitIcon from "components/ui/WaitIcon";
import { uniq } from "lodash";
import React, { useState } from "react";
import { useResource } from "store/resources/useResource";

import "./styles.scss";

const ReleaseNotes = () => {
    const [releases, isLoading] = useResource({
        resourceName: "allReleaseNotes",
        key: "allReleaseNotes",
        forced: true,
        transform: (data?: string) => JSON.parse(data ?? "{}"),
    }) as [releases: ReleasesResponse | undefined, isLoading: boolean];

    const thisYear = new Date().getFullYear().toString();
    const [activeTab, setActiveTab] = useState({ id: thisYear, title: thisYear });

    const hasReleases = releases && releases?.results?.length > 0;
    let releaseItems: any[] = [];
    const years: Tab[] = [];

    if (!isLoading && hasReleases) {
        const releaseResults = releases?.results;
        let releaseYears: string[] = [];
        releaseItems = releaseResults?.map((result) => {
            const releaseDate = result.title
                .split(" ")
                .filter((v) => v.match("[0-9]{4}([-/ .])[0-9]{2}[-/ .][0-9]{2}"))
                .map((date) => date);

            releaseYears.push(releaseDate[0].split(".")[0]);
            return {
                ...result,
                releaseDate: releaseDate[0],
            };
        });
        uniq(releaseYears).forEach((year) => {
            years.push({
                id: year,
                title: year,
            });
        });
    }

    const onSelectTab = (tab: Tab) => {
        setActiveTab(tab);
    };

    if (isLoading) {
        return (
            <ViewPlaceholder>
                <WaitIcon />
            </ViewPlaceholder>
        );
    }

    if (!hasReleases) {
        return (
            <ViewPlaceholder>
                <p>Release notes not found</p>
            </ViewPlaceholder>
        );
    }

    return (
        <div className="release-notes main-grid-wrap responsive">
            <TabList loading={isLoading} items={years.reverse()} onClick={onSelectTab} activeItem={activeTab}></TabList>
            {releaseItems
                .filter((release) => release.releaseDate.split(".")[0] === activeTab?.id ?? thisYear)
                .sort((a, b) => {
                    const date1: any = new Date(a.releaseDate.replace(/\./g, "-"));
                    const date2: any = new Date(b.releaseDate.replace(/\./g, "-"));
                    return date2 - date1;
                })
                .map((release: ReleaseResponse, index) => (
                    <div key={index} className="release-notes-item" dangerouslySetInnerHTML={{ __html: release.body.view.value }} />
                ))}
        </div>
    );
};

type ReleasesResponse = {
    results: ReleaseResponse[];
    start: number;
    limit: number;
    size: number;
};

type ReleaseResponse = {
    body: {
        view: {
            value: string;
        };
    };
    id: string;
    title: string;
};

export default ReleaseNotes;
