import React from "react";
import IconWithLabel from "../../Icons/IconWithLabel";

import "./Controls.scss";

const Controls = ({ items = [] }) => {
    const renderItem = (item, index) => {
        return <ControlsItem key={index} item={item} />;
    };

    return (
        <div className="grid-controls flex-row align-center">
            {items.filter((i) => i.position === "left" && !i.hide).map(renderItem)}
            <span className="flex-one" />
            {items.filter((i) => i.position === "right" && !i.hide).map(renderItem)}
        </div>
    );
};

const ControlsItem = ({ item }) => {
    if (item.component) {
        return item.component;
    }

    return (
        <IconWithLabel
            icon={item.icon}
            withHandMadeIcon={item.withHandMadeIcon}
            disabled={item.disabled && item.disabled !== undefined}
            title={item.tooltip}
            onClick={item.onClick}
        >
            {item.title}
        </IconWithLabel>
    );
};

export default Controls;
