import React, { useState, memo, useRef, useEffect } from "react";
import { toNumber } from "lodash";
import SideNavContent from "components/ui/SideNav/SideNavContent";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import SideNavFooter from "components/ui/SideNav/SideNavFooter";
import Button from "components/ui/Button";
import JsonSchemaForm from "components/ui/Form/JsonSchema/JsonSchemaFormV2";
import { submitByRef, submitResource } from "components/utils/form";
import { refreshFTPSchedulesGrid } from "store/dataGrid/refreshGrid";
import { mapGridRowToObject } from "components/utils/datagrid";
import { ftpScheduleColumnKeys } from "components/views/configureGrids";

const timesofDay = [
    {
        label: "12:00AM",
        value: 0,
    },
    {
        label: "1:00AM",
        value: 1,
    },
    {
        label: "2:00AM",
        value: 2,
    },
    {
        label: "3:00AM",
        value: 3,
    },
    {
        label: "4:00AM",
        value: 4,
    },
    {
        label: "5:00AM",
        value: 5,
    },
    {
        label: "6:00AM",
        value: 6,
    },
    {
        label: "7:00AM",
        value: 7,
    },
    {
        label: "8:00AM",
        value: 8,
    },
    {
        label: "9:00AM",
        value: 9,
    },
    {
        label: "10:00AM",
        value: 10,
    },
    {
        label: "11:00AM",
        value: 11,
    },
    {
        label: "12:00PM",
        value: 12,
    },
    {
        label: "1:00PM",
        value: 13,
    },
    {
        label: "2:00PM",
        value: 14,
    },
    {
        label: "3:00PM",
        value: 15,
    },
    {
        label: "4:00PM",
        value: 16,
    },
    {
        label: "5:00PM",
        value: 17,
    },
    {
        label: "6:00PM",
        value: 18,
    },
    {
        label: "7:00PM",
        value: 19,
    },
    {
        label: "8:00PM",
        value: 20,
    },
    {
        label: "9:00PM",
        value: 21,
    },
    {
        label: "10:00PM",
        value: 22,
    },
    {
        label: "11:00PM",
        value: 23,
    },
];

const getUiSchema = (isNew) => ({
    dayOfWeek: {
        "ui:widget": "select",
        "ui:enumItems": [
            {
                label: "Monday",
                value: 2,
            },
            {
                label: "Tuesday",
                value: 3,
            },
            {
                label: "Wednesday",
                value: 4,
            },
            {
                label: "Thursday",
                value: 5,
            },
            {
                label: "Friday",
                value: 6,
            },
            {
                label: "Saturday",
                value: 7,
            },
            {
                label: "Sunday",
                value: 1,
            },
        ],
    },
    clientDownloadConfigNumber: {
        "ui:disabled": !isNew,
    },
    timeOfDay: {
        "ui:widget": "select",
        "ui:enumItems": timesofDay,
    },
});

const schema = {
    type: "object",
    required: ["dayOfWeek", "timeOfDay", "clientDownloadConfigNumber"],
    properties: {
        clientDownloadConfigNumber: {
            type: "string",
            title: "Client Download Config Number",
        },
        dayOfWeek: {
            type: "number",
            title: "Day of Week",
        },
        timeOfDay: {
            type: "number",
            title: "Time of Day",
        },
        isActive: {
            type: "boolean",
            title: "Active",
        },
    },
};

const Form = memo(({ onClose, dataItem }) => {
    const formRef = useRef();

    const isNew = dataItem === undefined;

    const [initialValues, setInitialValues] = useState();

    useEffect(() => {
        if (isNew) {
            setInitialValues({});
        } else {
            let initialValues = mapGridRowToObject(ftpScheduleColumnKeys, dataItem);
            initialValues = {
                ...initialValues,
                timeOfDay: timesofDay.find((t) => t.label === initialValues.timeOfDay).value,
                dayOfWeek: toNumber(initialValues.dayOfWeek),
                isActive: initialValues.isActive === "YES",
            };
            setInitialValues(initialValues);
        }
    }, [dataItem, isNew]);

    const title = isNew ? "Add FTP Schedule" : "Edit FTP Schedule";

    const titleIcon = isNew ? "plus" : "edit-empty";

    const [isSubmitting, setSubmitting] = useState(false);

    const resourceId = isNew ? null : initialValues?.clientDownloadConfigScheduleNumber;

    const onSubmit = (formData) => {
        setSubmitting(true);
        submitResource({
            resourceParams: { resourceName: "ftpSchedules" },
            resourceId,
            body: formData,
            onRefresh: refreshFTPSchedulesGrid,
            onSuccess: onClose,
            setSubmitting,
            noResourceRefresh: true,
        });
    };

    const submitText = isSubmitting ? "Saving..." : "Save";

    return (
        <SideNavContent>
            <SideNavHeader title={title} leadBlockIcon={titleIcon} smallHeader onClose={onClose} />
            <SideNavBody className="flex-one-in-column">
                <JsonSchemaForm
                    formRef={formRef}
                    schema={schema}
                    uiSchema={getUiSchema(isNew)}
                    initialValues={initialValues}
                    disabled={isSubmitting}
                    onSubmit={onSubmit}
                    onCancel={onClose}
                    submitText={submitText}
                    noReset
                    noActions
                />
            </SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button primary onClick={() => submitByRef(formRef)} disabled={isSubmitting}>
                    {submitText}
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default Form;
