import React, { memo, useContext, useRef } from "react";

import { getAttributeValue } from "../../../../../utils/workflow";
import { useResource } from "store/resources/useResource";
import FileUpload from "../../../../FileUpload";
import Button from "components/ui/Button";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot";
import "./FileUploadTask.scss";
import SideNavFormContainer from "components/ui/SideNavFormContainer";
import { TaskWorkflowContext } from "components/ui/TaskWorkflow/context/TaskWorkflowContext";
import { entityType } from "components/utils/entityType";
import { getGridId } from "components/ui/Dashboard/Panel/Project/DocumentsPanel/utils";
import { refreshGrid } from "store/dataGrid/refreshGrid";

export const FileUploadTask = memo(
    ({ task, entityId, entityTypeId, programNumber, disabled, onUploadSuccess, onCancel, utilityNumber }) => {
        const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
            size: sideNavSize.small,
            className: "workflow-file-upload-sidenav-panel",
        });
        const fileUploadComponentRef = useRef();
        const { noDocumentSecurity } = useContext(TaskWorkflowContext);
        const [files] = useResource({
            resourceName: "fileUploadReqs",
            key: entityTypeId,
            path: {
                entityTypeId: entityTypeId,
            },
        });

        return handleOpenSidePanel(
            <SideNavFormContainer
                title="File Upload"
                onClose={() => {
                    setTimeout(() => {
                        handleCloseSidePanel();
                        onCancel();
                    }, 0);
                }}
                onCancel={onCancel}
                forwardedFormComponentRef={fileUploadComponentRef}
                submitText="Upload"
                titleIcon="upload"
            >
                <FileUpload
                    noDocumentSecurity={noDocumentSecurity}
                    noActions
                    ref={fileUploadComponentRef}
                    withoutHeaderPanel
                    withSideNav
                    entityTypeId={entityTypeId}
                    entityId={entityId}
                    onCancel={onCancel}
                    programNumber={programNumber}
                    utilityNumber={utilityNumber}
                    files={files}
                    applicationNumber={entityId}
                    fileTypeId={+getAttributeValue("Reference ID", task)}
                    disabled={disabled}
                    defaultFolder={task?.attributes?.find((i) => i.attributename === "File Folder Name")?.attributevalue}
                    onUploadSuccess={() => {
                        onUploadSuccess();
                        handleCloseSidePanel();
                        if (entityTypeId === entityType.projectApplication) {
                            refreshGrid({
                                dataGridId: getGridId({ applicationNumber: entityId, type: "applicationFiles" }),
                            });
                        }
                    }}
                    onClose={handleCloseSidePanel}
                    withinSideNav
                    // Adding separate cancel button because original cancel button is not shown if form is disabled
                    otherActions={<Button onClick={onCancel}>Cancel</Button>}
                    multiple={false}
                    useApplicationFileTags={entityTypeId === entityType.projectApplication}
                />
            </SideNavFormContainer>
        );
    }
);
