import React, { useContext, useMemo } from "react";
import { isFunction } from "lodash";
import { PortalBuilderContext } from "components/ui/PortalBuilder/contexts";
import classNames from "classnames";

const PropertyDescription = (props: PropertyDescriptionProps) => {
    const context = useContext(PortalBuilderContext);
    const description = useMemo(
        () => (isFunction(props.description) ? props.description(props.id, props.value, context) : false),
        [context, props]
    );

    if (!description) {
        return null;
    }

    return <div className={classNames(props.className, "property-list-item-description")}>{description}</div>;
};

type PropertyDescriptionProps = {
    id: string;
    value?: any;
    description?: string | ((id: string, value: any, context: any) => string);
    className?: string;
};

export default PropertyDescription;
