import React, { memo } from "react";

import { openContractTab, openUtilityTab } from "../../../../utils/window";
import { widgetContractBookmarksGridColumnKeys } from "../../../../views/configureGrids";
import { modalOpen } from "../../../../../store/modal/actions";
import { updateResource } from "../../../../../store/resources/actions";
import { entityType } from "../../../../utils/constants";
import { refreshContract } from "../../../../../store/resources/refreshResource";
import { refreshContractBookmarksGrid } from "../../../../../store/dataGrid/refreshGrid";
import withDataGrid from "../../../DataGrid/withDataGrid";
import { exportDatagridToCSV } from "../../../../utils/CSV";

import IconWithLabel from "../../../Icons/IconWithLabel";
import ActionLabel from "../../../Label/ActionLabel";
import DataGrid from "../../../DataGrid";
import DashboardPanel from "../DashboardPanel";

const filter = `${widgetContractBookmarksGridColumnKeys.isFavorite}=${true}`;

const PinnedContractsPanel = memo(({ panel }) => {
    const gridId = "widget-contract-bookmarks";
    return (
        <DashboardPanel title={panel.name} className="sticky-grid-list-panel">
            <Grid gridId={gridId} filter={filter} />
        </DashboardPanel>
    );
});

export const PinnedContractsGrid = memo(() => {
    const gridId = "contract-bookmarks";
    return <Grid gridId={gridId} filter={filter} />;
});

const Grid = withDataGrid(
    "widgetContractBookmarks",
    memo(({ gridId, dataGridConfig, dispatch }) => {
        const rowActions = [
            {
                name: "open",
                icon: "open-new-window",
                title: "Manage Contract",
            },
            {
                name: "delete",
                icon: "bookmark-filled icon-wrap-combined remove",
                title: "Remove from Bookmarks",
            },
        ];

        const onRowAction = (action) => {
            const { name, dataItem } = action;

            const utilityNumber = dataItem[widgetContractBookmarksGridColumnKeys.utilityNumber];
            const contractNumber = dataItem[widgetContractBookmarksGridColumnKeys.contractNumber];
            const contractDescription = dataItem[widgetContractBookmarksGridColumnKeys.contractDescription];

            switch (name) {
                case "open":
                    openContractTab({
                        utilityNumber,
                        contractNumber,
                        contractDescription,
                    });
                    break;

                case "delete":
                    onBookmarkDelete({
                        utilityNumber,
                        contractNumber,
                        contractDescription,
                    });
                    break;

                default:
                    break;
            }
        };

        const onBookmarkDelete = ({ utilityNumber, contractNumber, contractDescription }) => {
            const text = (
                <p>
                    Would you like to remove contract <strong>{contractDescription}</strong> from your Bookmarks?
                </p>
            );

            dispatch(
                modalOpen({
                    modalType: "CONFIRM",
                    modalProps: {
                        title: "Remove from Bookmarks",
                        modalIcon: "bookmark-filled icon-wrap-combined remove",
                        overlayClassName: "modal-styled",
                        className: "remove-from-bookmarks-confirmation-modal modal-sm",
                        footerContentCenter: true,
                        content: text,
                        onConfirm: () => {
                            dispatch(
                                updateResource({
                                    resourceName: "userFavorites",
                                    query: {
                                        entityType: entityType.Contract,
                                        entityId: contractNumber,
                                    },
                                    optimisticUpdate: {
                                        clearItem: true,
                                    },
                                    onSuccess: () => {
                                        refreshContractBookmarksGrid();
                                        refreshContract({
                                            utilityNumber,
                                            contractNumber,
                                        });
                                    },
                                })
                            );
                        },
                    },
                })
            );
        };

        return (
            <>
                <div className="data-grid-controls flex-row justify-end">
                    <IconWithLabel
                        withHandMadeIcon
                        onClick={() =>
                            exportDatagridToCSV({
                                dataGridId: gridId,
                                fileName: "contracts",
                            })
                        }
                    >
                        Export CSV
                    </IconWithLabel>
                </div>
                <DataGrid
                    name={gridId}
                    config={dataGridConfig}
                    customRowActions={rowActions}
                    onRowAction={onRowAction}
                    columnCellContent={{
                        [widgetContractBookmarksGridColumnKeys.utilityNumber]: (column, row) => (
                            <ActionLabel
                                onClick={() =>
                                    openUtilityTab({
                                        utilityNumber: row[column.key],
                                    })
                                }
                            >
                                {row[column.key]}
                            </ActionLabel>
                        ),
                        [widgetContractBookmarksGridColumnKeys.utility]: (column, row) => (
                            <ActionLabel
                                onClick={() =>
                                    openUtilityTab({
                                        utilityNumber: row[widgetContractBookmarksGridColumnKeys.utilityNumber],
                                    })
                                }
                            >
                                {row[column.key]}
                            </ActionLabel>
                        ),
                        [widgetContractBookmarksGridColumnKeys.contractNumber]: (column, row, onExpandRow, onRowAction) => (
                            <ActionLabel
                                onClick={() =>
                                    onRowAction({
                                        name: "open",
                                        dataItem: row,
                                    })
                                }
                            >
                                {row[column.key]}
                            </ActionLabel>
                        ),
                        [widgetContractBookmarksGridColumnKeys.contractDescription]: (column, row, onExpandRow, onRowAction) => (
                            <ActionLabel
                                onClick={() =>
                                    onRowAction({
                                        name: "open",
                                        dataItem: row,
                                    })
                                }
                            >
                                {row[column.key]}
                            </ActionLabel>
                        ),
                    }}
                />
            </>
        );
    })
);

export default PinnedContractsPanel;
