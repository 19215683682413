import React, { lazy, Suspense, useCallback, useEffect, useRef, useState } from "react";
import { debounce, isEmpty, isObject } from "lodash";
import { toast } from "react-toastify";
import ModuleLoadError from "components/ui/ModuleLoadError";
import WaitIcon from "components/ui/WaitIcon";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";

const Editor = lazy(() =>
    import("./Editor").catch((error) => ({
        default: () => <ModuleLoadError error={error} />,
    }))
);

const JsonEditor = (props) => {
    const { onChange } = props;
    const [config] = usePortalBuilderState((state) => state.updatedConfig);
    const editorRef = useRef();
    const [updatedConfig, setUpdatedConfig] = useState();

    // Update config if it changed outside of properties editor
    useEffect(() => {
        const configStr = isObject(config) ? stringifyConfig(config) : config;
        setUpdatedConfig((prev) => {
            // Fold sections if first time loading the config
            if (isEmpty(prev) && editorRef.current) {
                setTimeout(() => {
                    foldAtDepth(editorRef.current.editor, 3);
                });
            }

            return configStr;
        });

        if (editorRef.current) {
            editorRef.current.editor.resize();
        }
    }, [config]);

    const onConfigChange = useCallback(
        (newValue) => {
            debouncedOnChange(newValue, onChange);
        },
        [onChange]
    );

    return (
        <Suspense fallback={<WaitIcon />}>
            <Editor editorRef={editorRef} value={updatedConfig} onChange={onConfigChange} />
        </Suspense>
    );
};

// Debounce form onChange callback
const debouncedOnChange = debounce((newValue, onChange) => {
    try {
        const config = JSON.parse(newValue);
        onChange(config);
    } catch {}
}, 500);

const foldAtDepth = (editor, depth) => {
    let indent, range;
    const session = editor.session;

    indent = depth * session.getTabString().length;

    for (var row = 0; row < session.getLength(); row++) {
        if (session.foldWidgets[row] === undefined) session.foldWidgets[row] = session.getFoldWidget(row);
        if (session.foldWidgets[row] === "start") {
            range = session.getFoldWidgetRange(row);
            if (range !== null && session.getLine(row).search(/\S/) === indent) {
                session.addFold("...", range);

                row = range.end.row;
            }
        }
    }
};

const stringifyConfig = (config) => {
    let value = "";

    try {
        value = JSON.stringify(config, null, 4);
    } catch {
        toast.error("Failed to load portal configuration");
    }

    return value;
};

export default JsonEditor;
