import React, { memo, useState, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";

import { submitByRef } from "../../../../../utils/form";
import { clearFormData } from "store/forms/actions";

import Button from "../../../../Button";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";
import RawHtml from "../../../../RawHtml";
import { useApplicationForms } from "../../../../../../store/resources/useResource";

import ApplicationForm from "components/ui/Dashboard/Panel/Project/ApplicationsPanel/ApplicationForm";

import "./ApplicationFormSidePanel.scss";

export const ApplicationFormSidePanel = memo(
    ({
        formId,
        programNumber,
        applicationNumber,
        isLoadingFormDetails,
        applicationFormId,
        editMode,
        taskNumber,
        onSubmitSuccess,
        onCancel,
        content,
    }) => {
        const formRef = useRef();
        const dispatch = useDispatch();

        const [forms = []] = useApplicationForms({ applicationNumber });

        const [isDisabled, setIsDisabled] = useState(false);
        const [isSubmitting, setIsSubmitting] = useState(false);

        const submitText = isDisabled || !isSubmitting ? "Save" : "Saving...";

        const titleIcon = "edit-empty";
        const titleText = "Edit Form ";
        const formTitle = applicationFormId ? (forms.filter((f) => f.pageNumber === applicationFormId)[0] || {}).pageName : "";

        const handleSubmit = useCallback(() => {
            setIsSubmitting(true);
            submitByRef(formRef);
        }, []);

        const handleSubmitStatusSet = useCallback((status) => {
            setIsSubmitting(status);
        }, []);

        const handleSubmitDisabledSet = useCallback((status) => {
            setIsDisabled(status);
        }, []);

        const handleCancel = useCallback(() => {
            dispatch(clearFormData());

            onCancel && onCancel();
        }, [onCancel, dispatch]);

        return (
            <SideNavContent>
                <SideNavHeader title={titleText} nameInBold={formTitle} leadBlockIcon={titleIcon} smallHeader onClose={onCancel} />
                <SideNavBody className="flex-one-in-column">
                    <div className="flex-row header-values">
                        <div className="task-content">
                            <RawHtml className="content-block">{content}</RawHtml>
                        </div>
                    </div>
                    <div className="flex-column content flex-one">
                        <ApplicationForm
                            formId={formId}
                            formRef={formRef}
                            programNumber={programNumber}
                            applicationNumber={applicationNumber}
                            applicationFormId={applicationFormId}
                            loading={isLoadingFormDetails}
                            editMode={editMode}
                            taskNumber={taskNumber}
                            onSubmitSuccess={onSubmitSuccess}
                            onSubmitStatusSet={handleSubmitStatusSet}
                            onSubmitDisabledSet={handleSubmitDisabledSet}
                            onCancel={onCancel}
                            noActions
                        />
                    </div>
                </SideNavBody>
                <SideNavFooter justifyCenter>
                    <Button primary disabled={isDisabled || isSubmitting || !editMode} onClick={handleSubmit}>
                        {submitText}
                    </Button>
                    <Button onClick={handleCancel}>Cancel</Button>
                </SideNavFooter>
            </SideNavContent>
        );
    }
);
