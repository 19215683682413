import TabList, { Tab } from "components/ui/List/TabList";
import React from "react";
import { SECTION_WIDGETS } from "../Section";
import { SectionHeader } from "../SectionHeader";
import { WidgetListItem } from "./types";
import { WidgetsInfo } from "./WidgetsInfo";
import { WidgetsListItem } from "./WidgetsListItem";

export const WidgetsList = ({ items, tabs, activeTab, onTabChange, onEdit, noWidgetInfo, noSectionHeader, onClick }: WidgetsListProps) => {
    return (
        <>
            {!noSectionHeader && <SectionHeader Icon={SECTION_WIDGETS.Icon} title={SECTION_WIDGETS.title} underline />}
            {!noWidgetInfo && <WidgetsInfo />}
            <TabList items={tabs} tabListGhost activeItem={activeTab} onClick={onTabChange} />
            <div className="widgets-list fill-height fill-width with-scroll">
                {items.map((item, index) => (
                    <WidgetsListItem
                        key={index}
                        item={item}
                        onClick={onClick ? () => onClick(item) : undefined}
                        onEdit={onEdit ? () => onEdit(item) : undefined}
                    />
                ))}
            </div>
        </>
    );
};

interface WidgetsListProps {
    items: WidgetListItem[];
    tabs: Tab[];
    activeTab?: Tab;
    onTabChange: (tab: Tab) => void;
    onEdit?: (widget: WidgetListItem) => void;
    noWidgetInfo?: boolean;
    noSectionHeader?: boolean;
    activeWidget?: any;
    onClick?: (widget: WidgetListItem) => void;
}
