import React, { useState, useCallback, useMemo, memo, useRef, useEffect } from "react";
import { isEqual } from "lodash";

import { pickInitialValues, submitResource, isNonEmptyString, submitByRef } from "../../utils/form";
import { useScanGroups } from "../../../store/resources/useResource";
import { onUpdateProgramList, onUpdateUserList } from "./utils";

import WaitIcon from "../../ui/WaitIcon";
import Button from "../../ui/Button";
import InlinePanelHeader from "../../ui/Dashboard/Panel/InlinePanelHeader";
import JsonSchemaForm from "../../ui/Form/JsonSchema/JsonSchemaForm";
import GridDetailsFooterActions from "../../ui/DataGrid/GridDetailsFooterActions";
import Associations from "./Associations";

import SideNavBody from "../../ui/SideNav/SideNavBody";
import SideNavFooter from "../../ui/SideNav/SideNavFooter";
import SideNavHeader from "../../ui/SideNav/SideNavHeader";
import SideNavContent from "../../ui/SideNav/SideNavContent";

const Form = memo((props) => {
    const { dataItem, onClose, onRefresh, sidePanel, editEnabled } = props;

    const isNew = dataItem === undefined;
    const resourceId = isNew ? null : dataItem.groupNumber;
    const [isSubmitting, setSubmitting] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(!editEnabled);

    const isLoading = false;
    const resource = dataItem;

    const initialValues = isNew ? {} : pickInitialValues(resource);

    const [groups = [], isLoadingGroups] = useScanGroups();

    const group = groups.filter((i) => i.groupNumber === resourceId)[0];
    const users = useMemo(() => (group && group.userList) || [], [group]);
    const programs = useMemo(() => (group && group.programList) || [], [group]);

    const [updatedUsers, setUpdatedUsers] = useState(users);
    const [updatedPrograms, setUpdatedPrograms] = useState(programs);

    const formRef = useRef();

    const handleSave = useCallback(() => {
        submitByRef(formRef);
    }, []);

    const handleChangeUsers = useCallback(
        (list) => {
            if (resourceId && list && !isEqual(users, list)) {
                setUpdatedUsers(list);
            } else {
                setUpdatedUsers([]);
            }
        },
        [resourceId, users]
    );

    const handleChangePrograms = useCallback(
        (list) => {
            if (resourceId && list && !isEqual(programs, list)) {
                setUpdatedPrograms(list);
            } else {
                setUpdatedPrograms([]);
            }
        },
        [resourceId, programs]
    );

    const schema = useMemo(() => {
        return {
            type: "object",
            required: ["groupName"],
            properties: {
                groupName: {
                    type: "string",
                    title: "Group Name",
                },
                ...(isNew
                    ? {}
                    : {
                          associations: {
                              type: "string",
                          },
                      }),
            },
        };
    }, [isNew]);

    const uiSchema = useMemo(() => {
        return {
            classNames: "inline-form",
            groupName: {
                classNames: "fill-width",
            },
            ...(isNew
                ? {}
                : {
                      associations: {
                          "ui:widget": () => (
                              <Associations
                                  readOnly={isReadOnly}
                                  isLoading={isLoadingGroups}
                                  dataItem={dataItem}
                                  users={updatedUsers}
                                  programs={updatedPrograms}
                                  onChangeUsers={handleChangeUsers}
                                  onChangePrograms={handleChangePrograms}
                              />
                          ),
                          "ui:options": {
                              displayLabel: false,
                          },
                      },
                  }),
        };
    }, [isNew, isLoadingGroups, dataItem, updatedUsers, updatedPrograms, handleChangeUsers, handleChangePrograms, isReadOnly]);

    const handleSubmit = useCallback(
        (formData) => {
            const handleSuccess = () => {
                if (resourceId) {
                    if (updatedUsers) {
                        onUpdateUserList({
                            groupNumber: resourceId,
                            list: updatedUsers.map((i) => i.userNumber),
                        });
                    }

                    if (updatedPrograms) {
                        onUpdateProgramList({
                            groupNumber: resourceId,
                            list: updatedPrograms.map((i) => i.programNumber),
                        });
                    }
                }

                sidePanel ? sidePanel.close() : onClose();
            };

            const resourceParams = {
                resourceName: "scanGroups",
            };

            const body = formData.groupName;

            submitResource({
                resourceParams,
                resourceId,
                body,
                setSubmitting,
                onRefresh,
                onSuccess: handleSuccess,
                noResourceRefresh: true,
            });
        },
        [resourceId, updatedUsers, updatedPrograms, onRefresh, onClose, sidePanel]
    );

    const handleValidate = useCallback((formData, errors) => {
        if (!isNonEmptyString(formData.groupName)) {
            errors.groupName.addError("Invalid field value");
        }

        return errors;
    }, []);

    const otherActions = useMemo(() => {
        return isNew ? null : (
            <>
                <span className="flex-one" />
                <GridDetailsFooterActions {...props} />
            </>
        );
    }, [isNew, props]);

    useEffect(() => {
        if (sidePanel) {
            sidePanel.setForm(formRef);
        }
    }, [sidePanel]);

    if (isLoading) {
        return <WaitIcon />;
    }

    return (
        <>
            {isNew ? (
                <div className="with-form with-panel-borders">
                    <InlinePanelHeader title="Add User Group" onClose={onClose} />
                    <JsonSchemaForm
                        key={updatedUsers.length + updatedPrograms.length}
                        schema={schema}
                        uiSchema={uiSchema}
                        initialValues={initialValues}
                        disabled={isSubmitting}
                        onSubmit={handleSubmit}
                        onCancel={onClose}
                        submitText={isSubmitting ? "Saving..." : "Save"}
                        validate={handleValidate}
                        noReset
                        otherActions={otherActions}
                    />
                </div>
            ) : (
                <SideNavContent>
                    <SideNavHeader title="Edit User Group" leadBlockIcon="edit-empty" smallHeader onClose={onClose} />
                    <SideNavBody className="flex-one-in-column">
                        <JsonSchemaForm
                            formRef={formRef}
                            key={updatedUsers.length + updatedPrograms.length}
                            schema={schema}
                            uiSchema={uiSchema}
                            initialValues={initialValues}
                            disabled={isSubmitting}
                            onSubmit={handleSubmit}
                            validate={handleValidate}
                            readOnly={isReadOnly}
                            otherActions={otherActions}
                            noActions
                        />
                    </SideNavBody>
                    <SideNavFooter setPrimaryButton>
                        {isReadOnly ? (
                            <Button primary icon={"edit-empty"} onClick={() => setIsReadOnly(false)}>
                                {"Edit Group"}
                            </Button>
                        ) : (
                            <Button primary onClick={handleSave}>
                                {isSubmitting ? "Saving..." : "Save"}
                            </Button>
                        )}
                        <Button onClick={onClose}>Cancel</Button>
                    </SideNavFooter>
                </SideNavContent>
            )}
        </>
    );
});

export default Form;
