import React, { memo } from "react";

import { openAttributeLookupAssignmentModal, attributeLookupsToArray } from "./utils";

import AssociationsList from "../../../../AssociationsList";

const LookupValuesWidget = memo(({ entityKey, attributeId, value, onChange, readonly, isCatalog }) => {
    const onEdit = () => {
        openAttributeLookupAssignmentModal({
            entityKey,
            attributeId,
            isCatalog,
            onSelect: (lookups = []) => onChange(lookups.join("|")),
        });
    };

    const onRemove = (item, index) => {
        const lookups = attributeLookupsToArray(value);
        lookups.splice(index, 1);
        onChange(lookups.map((o) => o.lookup).join("|"));
    };

    return (
        <AssociationsList
            headerText="Attribute Lookup Values"
            headerIcon="view-list-list-bulleted"
            onEdit={onEdit}
            onRemove={onRemove}
            list={attributeLookupsToArray(value)}
            displayProperty="lookup"
            readOnly={readonly}
        />
    );
});

export default LookupValuesWidget;
