import React, { RefObject, useEffect, useState } from "react";
import { cloneDeep, isEmpty, isNil, isObject, omitBy, transform } from "lodash";
import { LayoutColumnSelector } from "components/ui/PortalBuilder/Properties/Property/RowLayout/ColumnSelector";
import RowLayoutSelectPanel from "components/ui/PortalBuilder/Properties/Property/RowLayout/SelectRowLayout";
import IconWrap from "components/ui/Icons";
import { RowLayoutTypes } from "components/ui/PortalBuilder/Properties/Property/RowLayout/RowLayoutTypes";
import { LeadImageUrl } from "../../../ui/PortalBuilder/Properties/Property/LeadImageUrl";

import {
    ALIGN_X_PROPERTY,
    ALIGN_Y_PROPERTY,
    BACKGROUND_PROPERTY,
    SHADOW_PROPERTY,
    FONT_SIZE_PROPERTY,
    FONT_WEIGHT_PROPERTY,
    getLinkPropertiesGroup,
    TEXT_COLOR_PROPERTY,
    VISIBILITY_PROPERTY,
    WIDGET_LAYOUT_PROPERTY,
} from "components/ui/PortalBuilder/Properties/Property/propertyConfig";
import {
    LayoutType,
    PortalTemplateConfiguration,
    PortalWidgetConfiguration,
    PropertyChangeFunc,
    PropertyType,
    RowLayoutConfiguration,
    WidgetType,
    HeaderNavigation,
    ValidationRule,
    Row,
    PortalBuilderCtx,
    PropertyName,
    ProgramTemplateConfiguration,
    TypographyFontSize,
    TypographyLineHeight,
    LeadImageContentType,
    PortalBuilderProperty,
    AlignYPosition,
    AlignXPosition,
    ValidationError,
    LayoutWidth,
    PageConfiguration,
    LinkType,
    PaddingBoxSize,
    FontFamily,
    PropertySize,
    PageLink,
    TextColor,
} from "components/ui/PortalBuilder/types";
import { PortalMessenger } from "./PortalMessenger";
import { BORDER_PROPERTY } from "components/ui/PortalBuilder/Properties/Property/BorderProperty";
import { PADDING_PROPERTY } from "components/ui/PortalBuilder/Properties/Property/PaddingProperty";
import sassVariables from "assets/sass/_export.module.scss";
import { MARGIN_PROPERTY } from "../Properties/Property/MarginProperty";
import { BORDER_RADIUS_PROPERTY } from "../Properties/Property/BorderRadiusProperty";
import { SECTION_SETTINGS, SECTION_TEMPLATE_INFO } from "../Properties/Section";
import { CONTENT_WRAPPER_PROPERTIES } from "../Properties/Widgets/GlobalContentWrapperProperties";
import { ButtonCornerRadius, ButtonSize } from "../Properties/Widgets/GlobalButtonProperties/types";
import { ColorSelectIcon } from "../Properties/Property/ColorSelect/ColorSelectIcon";
import ColorSelectPanel from "../Properties/Property/ColorSelect/ColorSelectPanel";
import { ToolbarTypes } from "components/ui/Form/JsonSchema/widgets/HtmlEditorWidget/toolbars";
import { TextsStylesProperty } from "../Properties/Property/TextsStyleProperty";
import { ButtonStylesProperty } from "../Properties/ButtonWidgetProperty/ButtonStylesProperty";
import { ButtonTypeProperty } from "../Properties/ButtonWidgetProperty/ButtonTypeProperty";
import { ButtonLinkProperty } from "../Properties/ButtonWidgetProperty/ButtonLinkProperty";
import { HeadingStylesProperty } from "../Properties/HeadingWidgetProperty/HeadingStylesProperty";
import { LinkStylesProperty } from "../Properties/Property/LinkStyleProperty";
import { ImageLinkProperty } from "../Properties/Property/ImageProperty/ImageLinkProperty";
import { ImageSizeProperty } from "../Properties/Property/ImageProperty/ImageSizeProperty";
import { cleanProgramLevelConfiguration } from "./page";
import { ListStylesProperty } from "../Properties/Property/ListStylesProperty";
import { HeaderSignOutProperty } from "../Properties/Property/HeaderSignOutProperty";
import Button from "components/ui/Button";
import { modalOpen } from "store/modal/actions";
import { ImageAltTextProperty } from "../Properties/Property/ImageProperty/ImageAltTextProperty";
import { LinkUnderlineWarning } from "../Properties/LinkUnderlineWarning";
import { usePortalBuilderState } from "../PortalBuilderContextProvider";
import { PowerBiReportSelectProperty } from "../Properties/Property/PowerBiReportSelectProperty";

export const DEFAULT_HOME_PAGE_CONFIG: PageConfiguration = {
    title: "Home",
    link: "/",
    linkType: LinkType.INTERNAL,
    headerNavigation: HeaderNavigation.NEVER,
    openInNewTab: false,
    custom: true,
    isShared: false,
    components: [],
    _isHomePage: true,
};

export const DEFAULT_PROGRAM_TEMPLATE_CONFIG: ProgramTemplateConfiguration = {
    settings: {
        enableProgramNotification: false,
    },
    content: {
        pages: [DEFAULT_HOME_PAGE_CONFIG],
    },
};

export const SwitchTooltip = {
    SWITCH_ON: "Switch ON",
    SWITCH_OFF: "Switch OFF",
};

export const DeviceSizes = {
    DESKTOP: { width: "100%", height: "100%" },
    TABLET: { width: "834px", height: "1112px" },
    MOBILE: { width: "420px", height: "800px" },
};

export const PORTAL_WIDGET_PROPERTIES: { [key: string]: any[] } = {
    [WidgetType.BUTTON]: [
        {
            id: "text",
            title: "Button text",
            defaultValue: "Button",
            type: PropertyType.Text,
            fullWidth: true,
            validationRules: [ValidationRule.Required],
        },
        {
            type: PropertyType.CustomComponent,
            component: ButtonLinkProperty,
            propertiesGroup: [
                {
                    id: "linkType",
                },
                {
                    id: "href",
                    validationRules: [ValidationRule.Required, ValidationRule.ExternalInternalWidgetsLink],
                },
                {
                    id: "target",
                },
                {
                    id: "internalPageSelect",
                },
            ],
        },
        {
            type: PropertyType.CustomComponent,
            component: ButtonStylesProperty,
            propertiesGroup: [
                {
                    id: "buttonStyle",
                },
            ],
        },
        {
            type: PropertyType.CustomComponent,
            component: ButtonTypeProperty,
            propertiesGroup: [
                {
                    id: "variant",
                },
                {
                    id: "secondaryButtonType",
                },
            ],
        },
        {
            id: "buttonWidth",
            title: "Button width",
            type: PropertyType.SelectBox,
            borderBottom: false,
            defaultValue: "auto",
            items: [
                {
                    text: "Auto",
                    value: "auto",
                },
                {
                    text: "Full width",
                    value: "fullWidth",
                },
            ],
        },
    ],
    [WidgetType.HEADER_NAVIGATION_WIDGET]: [
        {
            id: PropertyName.Version,
            defaultValue: 2,
            type: PropertyType.Hidden,
        },
        TEXT_COLOR_PROPERTY,
        { ...TEXT_COLOR_PROPERTY, title: "Mouseover color", id: PropertyName.HoverTextColor },
        FONT_SIZE_PROPERTY,
        FONT_WEIGHT_PROPERTY,
        { ...ALIGN_Y_PROPERTY, defaultValue: AlignYPosition.Bottom },
        { ...ALIGN_X_PROPERTY, defaultValue: AlignXPosition.Left },
        { ...PADDING_PROPERTY, className: "ignore-bold-uppercase" },
        {
            id: PropertyName.Separators,
            title: "Separators",
            type: PropertyType.Switch,
        },
        {
            id: PropertyName.SameWidths,
            title: "Uniform width",
            type: PropertyType.Switch,
        },
        {
            id: PropertyName.Underline,
            title: "Link underline",
            type: PropertyType.Switch,
        },
        {
            type: PropertyType.CustomComponent,
            component: HeaderSignOutProperty,
            propertiesGroup: [
                {
                    id: "showHeaderSignOutLink",
                },
                {
                    id: "headerSignOutLinkText",
                    validationRules: [ValidationRule.Required],
                },
            ],
        },
    ],
    [WidgetType.HEADING]: [
        {
            id: "text",
            title: "Heading Text",
            defaultValue: "Heading",
            type: PropertyType.Text,
            fullWidth: true,
            validationRules: [ValidationRule.Required],
        },
        {
            type: PropertyType.CustomComponent,
            component: HeadingStylesProperty,
            propertiesGroup: [
                {
                    id: "headingStyle",
                },
            ],
        },
    ],
    [WidgetType.LINK]: [
        {
            id: "link",
            title: "Link text",
            type: PropertyType.Html,
            toolbar: ToolbarTypes.Links,
            useTemplateColors: true,
            defaultValue: "<a href='#'>Click me</a>",
            validationRules: [ValidationRule.Required],
        },
        {
            id: PropertyName.Underline,
            title: "Link underline",
            type: PropertyType.Switch,
            defaultValue: true,
            borderBottom: true,
            description: (id: string, value: boolean, context: PortalBuilderCtx) =>
                value === false ? <LinkUnderlineWarning /> : undefined,
        },
        {
            id: "linkStyle",
            title: "Link styles",
            type: PropertyType.CustomComponent,
            component: LinkStylesProperty,
            borderBottom: true,
        },
    ],
    [WidgetType.IMAGE]: [
        {
            id: "src",
            type: PropertyType.FileSelect,
            buttonTitle: "Select image or upload new",
            fileType: "Image",
            selectTooltip: "Select Image",
        },
        {
            type: PropertyType.CustomComponent,
            component: ImageLinkProperty,
            title: "Image url",
            propertiesGroup: [
                {
                    id: "imageLink",
                    validationRules: [ValidationRule.ExternalInternalWidgetsLink],
                },
                {
                    id: "imageLinkType",
                },
                {
                    id: "src",
                },
                {
                    id: "openInNewTab",
                },
                {
                    id: "imageInternalPageSelect",
                },
            ],
        },
        {
            type: PropertyType.CustomComponent,
            component: ImageAltTextProperty,
            title: "Image alt text",
            propertiesGroup: [
                {
                    id: "altText",
                    validationRules: [ValidationRule.Required],
                },
                {
                    id: "src",
                },
            ],
        },
        {
            type: PropertyType.CustomComponent,
            component: ImageSizeProperty,
            title: "Image size:",
            propertiesGroup: [
                {
                    id: "imageSize",
                },
                {
                    id: "customSize",
                },
                {
                    id: "src",
                },
            ],
        },
    ],
    [WidgetType.LIST]: [
        {
            id: "list",
            title: "List text",
            type: PropertyType.Html,
            toolbar: ToolbarTypes.List,
            useTemplateColors: true,
            defaultValue: "<ul><li>list item 1</li><li>list item 2</li><li>list item 3</li></ul>",
            validationRules: [ValidationRule.Required],
        },
        {
            id: "listStyle",
            title: "List styles",
            type: PropertyType.CustomComponent,
            component: ListStylesProperty,
            borderBottom: true,
        },
    ],
    [WidgetType.NAVIGATION_WIDGET]: [
        {
            id: PropertyName.Version,
            defaultValue: 2,
            type: PropertyType.Hidden,
        },
        {
            id: "ariaLabel",
            title: "Aria label",
            type: PropertyType.Text,
            size: PropertySize.MD,
        },
        WIDGET_LAYOUT_PROPERTY,
        TEXT_COLOR_PROPERTY,
        {
            id: PropertyName.Underline,
            title: "Link underline",
            type: PropertyType.Switch,
            defaultValue: true,
            description: (id: string, value: boolean, context: PortalBuilderCtx) =>
                value === false ? <LinkUnderlineWarning /> : undefined,
        },
        getLinkPropertiesGroup(1, true),
        getLinkPropertiesGroup(2, true),
        getLinkPropertiesGroup(3, true),
        getLinkPropertiesGroup(4, true),
        getLinkPropertiesGroup(5, true),
        getLinkPropertiesGroup(6, true),
        getLinkPropertiesGroup(7, true),
        getLinkPropertiesGroup(8, true),
        PADDING_PROPERTY,
        MARGIN_PROPERTY,
    ],
    [WidgetType.PARAGRAPH]: [
        {
            id: "text",
            title: "Text",
            type: PropertyType.Text,
        },
        PADDING_PROPERTY,
    ],
    [WidgetType.SOCIAL_LINKS]: [
        {
            id: PropertyName.Version,
            defaultValue: 2,
            type: PropertyType.Hidden,
        },
        WIDGET_LAYOUT_PROPERTY,
        TEXT_COLOR_PROPERTY,
        {
            id: PropertyName.Underline,
            title: "Link underline",
            type: PropertyType.Switch,
            defaultValue: true,
            description: (id: string, value: boolean, context: PortalBuilderCtx) =>
                value === false ? <LinkUnderlineWarning /> : undefined,
        },
        getLinkPropertiesGroup(1),
        getLinkPropertiesGroup(2),
        getLinkPropertiesGroup(3),
        getLinkPropertiesGroup(4),
        getLinkPropertiesGroup(5),
    ],
    [WidgetType.TEXT_WIDGET]: [
        {
            id: "text",
            title: "Texts widget text",
            type: PropertyType.Html,
            toolbar: ToolbarTypes.Texts,
            useTemplateColors: true,
            validationRules: [ValidationRule.Required],
        },
        {
            id: "textsStyle",
            title: "Texts styles",
            type: PropertyType.CustomComponent,
            component: TextsStylesProperty,
            borderBottom: false,
        },
    ],
    [WidgetType.TITLE_AND_BUTTON]: [
        {
            id: "content",
            title: "Content",
            type: PropertyType.Html,
        },
        {
            id: "text",
            title: "Button text",
            type: PropertyType.Text,
            fullWidth: true,
        },
        {
            type: PropertyType.CustomComponent,
            component: ButtonLinkProperty,
            propertiesGroup: [
                {
                    id: "linkType",
                },
                {
                    id: "href",
                },
                {
                    id: "target",
                },
                {
                    id: "internalPageSelect",
                },
            ],
        },
        {
            type: PropertyType.CustomComponent,
            component: ButtonStylesProperty,
            hideCustomOption: true,
            propertiesGroup: [
                {
                    id: "buttonStyle",
                },
            ],
        },
        {
            type: PropertyType.CustomComponent,
            component: ButtonTypeProperty,
            propertiesGroup: [
                {
                    id: "variant",
                },
                {
                    id: "secondaryButtonType",
                },
            ],
        },
        {
            id: "buttonWidth",
            title: "Button width",
            type: PropertyType.SelectBox,
            borderBottom: false,
            defaultValue: "auto",
            items: [
                {
                    text: "Auto",
                    value: "auto",
                },
                {
                    text: "Full width",
                    value: "fullWidth",
                },
            ],
        },
    ],
    [WidgetType.CARD_CONTENT]: [
        {
            id: "src",
            type: PropertyType.FileSelect,
            buttonTitle: "Select image or upload new",
            fileType: "Image",
            selectTooltip: "Select Image",
        },
        {
            type: PropertyType.CustomComponent,
            component: ImageLinkProperty,
            title: "Image url",
            propertiesGroup: [
                {
                    id: "imageLink",
                    validationRules: [ValidationRule.ExternalInternalWidgetsLink],
                },
                {
                    id: "imageLinkType",
                },
                {
                    id: "src",
                },
                {
                    id: "openInNewTab",
                },
                {
                    id: "imageInternalPageSelect",
                },
            ],
        },
        {
            type: PropertyType.CustomComponent,
            component: ImageAltTextProperty,
            title: "Image alt text",
            propertiesGroup: [
                {
                    id: "altText",
                    validationRules: [ValidationRule.Required],
                },
                {
                    id: "src",
                },
            ],
        },
        {
            type: PropertyType.CustomComponent,
            component: ImageSizeProperty,
            title: "Image size:",
            propertiesGroup: [
                {
                    id: "imageSize",
                },
                {
                    id: "customSize",
                },
                {
                    id: "src",
                },
            ],
        },
        {
            id: "content",
            title: "Text Content",
            type: PropertyType.Html,
        },
        {
            id: "text",
            title: "Button text",
            type: PropertyType.Text,
            fullWidth: true,
        },
        {
            type: PropertyType.CustomComponent,
            component: ButtonLinkProperty,
            propertiesGroup: [
                {
                    id: "linkType",
                },
                {
                    id: "href",
                },
                {
                    id: "target",
                },
                {
                    id: "internalPageSelect",
                },
            ],
        },
        {
            type: PropertyType.CustomComponent,
            component: ButtonStylesProperty,
            hideCustomOption: true,
            propertiesGroup: [
                {
                    id: "buttonStyle",
                },
            ],
        },
        {
            type: PropertyType.CustomComponent,
            component: ButtonTypeProperty,
            propertiesGroup: [
                {
                    id: "variant",
                },
                {
                    id: "secondaryButtonType",
                },
            ],
        },
        {
            id: "buttonWidth",
            title: "Button width",
            type: PropertyType.SelectBox,
            borderBottom: false,
            defaultValue: "auto",
            items: [
                {
                    text: "Auto",
                    value: "auto",
                },
                {
                    text: "Full width",
                    value: "fullWidth",
                },
            ],
        },
        {
            id: "layout",
            title: "Layout",
            type: PropertyType.SelectBox,
            borderBottom: false,
            defaultValue: "vertical",
            items: [
                {
                    text: "Vertical",
                    value: "vertical",
                },
                {
                    text: "Horizontal",
                    value: "horizontal",
                },
            ],
        },
        BORDER_PROPERTY,
        SHADOW_PROPERTY,
        PADDING_PROPERTY,
        MARGIN_PROPERTY,
    ],
    [WidgetType.ACCORDION]: [
        {
            type: PropertyType.SelectBox,
            title: "Variant",
            id: "variant",
            defaultValue: "normal",
            items: [
                {
                    text: "Normal",
                    value: "normal",
                },
                {
                    text: "Flush",
                    value: "flush",
                },
            ],
        },
        {
            type: PropertyType.Accordion,
            title: "Accordion",
            id: "accordion",
        },
    ],
    [WidgetType.ACCORDION_ITEM]: [
        {
            id: "headerText",
            title: "Header text",
            type: PropertyType.Text,
            fullWidth: true,
            validationRules: [ValidationRule.Required],
        },
        {
            id: "headerBackgroundColor",
            title: "Header Background",
            type: "select-panel",
            selectComponent: ColorSelectIcon,
            panelComponent: ColorSelectPanel,
            selectPanelSubTitle: (
                <>
                    <strong>Header</strong> BACKGROUND COLOR SELECTION
                </>
            ),
        },
        {
            type: PropertyType.Select,
            id: "state",
            title: "State",
            defaultValue: "collapsed",
            emptyItem: false,
            items: [
                {
                    label: "Collapsed",
                    value: "collapsed",
                },
                {
                    label: "Open",
                    value: "open",
                },
                {
                    label: "Always Open",
                    value: "alwaysOpen",
                },
            ],
        },
        {
            id: "bodyText",
            title: "Body text",
            type: PropertyType.Html,
        },
    ],
    [WidgetType.CAROUSEL]: [
        {
            id: "autoplayOn",
            title: "Auto Play",
            type: PropertyType.LabelSwitch,
            titleSwitch: {
                id: "autoplayOn",
                defaultValue: false,
            },
            propertiesGroup: [
                {
                    id: "interval",
                    type: PropertyType.Number,
                    minValue: 1,
                    maxValue: 1000,
                    title: "Interval (Seconds)",
                    borderBottom: false,
                    validationRules: [ValidationRule.MinValue, ValidationRule.MaxValue],
                },
            ],
        },
        {
            id: "height",
            type: PropertyType.Number,
            minValue: 360,
            maxValue: 1000,
            title: "Height (Pixels)",
            borderBottom: false,
            defaultValue: 360,
            validationRules: [ValidationRule.MinValue, ValidationRule.MaxValue],
        },
        {
            type: PropertyType.Carousel,
            title: "Carousel",
            id: "carousel",
        },
    ],
    [WidgetType.CAROUSEL_ITEM]: [
        {
            id: "src",
            type: PropertyType.FileSelect,
            buttonTitle: "Select image or upload new",
            fileType: "Image",
            selectTooltip: "Select Image",
        },
        {
            type: PropertyType.CustomComponent,
            component: ImageAltTextProperty,
            title: "Image alt text",
            propertiesGroup: [
                {
                    id: "altText",
                    validationRules: [ValidationRule.Required],
                },
                {
                    id: "src",
                },
            ],
        },
        {
            id: "imageSize",
            title: "Image size in a panel",
            type: PropertyType.IconSelector,
            defaultValue: "auto",
            items: [
                {
                    text: "Auto",
                    value: "auto",
                    tooltip: "Image is displayed in its original size",
                    borderTop: true,
                },
                {
                    text: "Cover",
                    value: "cover",
                    tooltip: "Image is resized to cover the entire panel, even if it has to stretch or cut off edges",
                },
                {
                    text: "Fit",
                    value: "fit",
                    tooltip: "Image size is adjusted to fit the whole image on the screen",
                },
            ],
        },
        { ...ALIGN_Y_PROPERTY, id: "alignBackgroundY", title: "Image vertical alignment" },
        { ...ALIGN_X_PROPERTY, id: "alignBackgroundX", title: "Image horizontal alignment" },
        {
            id: "controlColor",
            title: "Control Color",
            type: PropertyType.IconSelector,
            items: [
                {
                    text: "Light",
                    value: TextColor.Light,
                    tooltip: "Light",
                },
                {
                    text: "Dark",
                    value: TextColor.Dark,
                    tooltip: "Dark",
                },
            ],
            defaultValue: TextColor.Light,
        },
        {
            ...BACKGROUND_PROPERTY,
            title: "Image background overlay",
            borderBottom: false,
            selectPanelSubTitle: (
                <>
                    Configure lead image <br />
                    background overlay color selection
                </>
            ),
        },
        {
            id: "content",
            title: "Text Content",
            type: PropertyType.Html,
        },
        {
            id: "text",
            title: "Button text",
            type: PropertyType.Text,
            fullWidth: true,
        },

        {
            type: PropertyType.CustomComponent,
            component: ButtonLinkProperty,
            borderBottom: false,

            propertiesGroup: [
                {
                    id: "linkType",
                },
                {
                    id: "href",
                    validationRules: [ValidationRule.Required, ValidationRule.ExternalInternalWidgetsLink],
                },
                {
                    id: "target",
                },
                {
                    id: "internalPageSelect",
                },
            ],
        },
        {
            type: PropertyType.CustomComponent,
            component: ButtonTypeProperty,
            borderTop: false,

            propertiesGroup: [
                {
                    id: "variant",
                },
                {
                    id: "secondaryButtonType",
                },
            ],
        },
        {
            id: "buttonWidth",
            title: "Button width",
            type: PropertyType.SelectBox,
            borderBottom: false,
            defaultValue: "auto",
            items: [
                {
                    text: "Auto",
                    value: "auto",
                },
                {
                    text: "Full",
                    value: "fullWidth",
                },
            ],
        },
    ],
    [WidgetType.SECTION_PLACEHOLDER]: [],
    [WidgetType.ROW]: [
        { ...BACKGROUND_PROPERTY, withGradient: true },
        {
            id: "minHeight",
            title: "Height",
            type: PropertyType.AutoCustom,
            autoValue: "auto",
            helperPropName: "_previousHeight",
            defaultValue: "auto",
            customValueType: PropertyType.Number,
            customValueTitle: "Min height (px)",
            customValueDefault: "0",
            validationRules: [ValidationRule.Required],
        },
        BORDER_PROPERTY,
        SHADOW_PROPERTY,
        { ...MARGIN_PROPERTY, onlyTopAndBottom: true },
        PADDING_PROPERTY,
        VISIBILITY_PROPERTY,
        {
            id: "layout",
            title: "Layout",
            type: PropertyType.SelectPanel,
            selectIcon: "touch-empty",
            selectTooltip: (isSelected: boolean) => (isSelected ? "Change Layout" : "Select Layout"),
            panelComponent: RowLayoutSelectPanel,
            selectComponent: IconWrap,
            valueComponent: (props: { row: Row; value: LayoutType; onChange: (id: string, value: string) => void }) => (
                <LayoutColumnSelector {...props} layoutId={props.value} />
            ),
            defaultValue: "layout1",
        },
    ],
    [WidgetType.COL]: [
        {
            ...BACKGROUND_PROPERTY,
            defaultValue: { defaultBackgroundColor: "white" },
            withGradient: true,
        },
        {
            ...BORDER_PROPERTY,
        },
        BORDER_RADIUS_PROPERTY,
        SHADOW_PROPERTY,
        { ...ALIGN_Y_PROPERTY, defaultValue: AlignYPosition.Top },
        { ...ALIGN_X_PROPERTY, defaultValue: AlignXPosition.Left },
        PADDING_PROPERTY,
        MARGIN_PROPERTY,
        {
            id: "components",
            title: "Widgets",
            type: PropertyType.Widget,
        },
    ],
    [WidgetType.HTML]: [
        {
            id: "content",
            title: "Content",
            type: PropertyType.Html,
        },
        PADDING_PROPERTY,
    ],
    [WidgetType.LEAD_IMAGE]: [
        {
            id: "imageSize",
            title: "Image size in a panel",
            type: PropertyType.IconSelector,
            items: [
                {
                    text: "Auto",
                    value: "auto",
                    tooltip: "Image is displayed in its original size",
                    borderTop: true,
                },
                {
                    text: "Cover",
                    value: "cover",
                    tooltip: "Image is resized to cover the entire panel, even if it has to stretch or cut off edges",
                },
                {
                    text: "Fit",
                    value: "fit",
                    tooltip: "Image size is adjusted to fit the whole image on the screen",
                },
            ],
        },
        { ...ALIGN_Y_PROPERTY, id: "alignBackgroundY", title: "Image vertical alignment" },
        { ...ALIGN_X_PROPERTY, id: "alignBackgroundX", title: "Image horizontal alignment" },
        {
            ...BACKGROUND_PROPERTY,
            title: "Image background overlay",
            borderBottom: false,
            selectPanelSubTitle: (
                <>
                    Configure lead image <br />
                    background overlay color selection
                </>
            ),
        },
    ],
    [WidgetType.CONTENT_WRAPPER]: [...CONTENT_WRAPPER_PROPERTIES],
    [WidgetType.POWER_BI_PUBLIC_REPORT]: [
        {
            id: "reportId",
            title: "Report ID",
            type: PropertyType.CustomComponent,
            fullWidth: true,
            validationRules: [ValidationRule.Required],
            component: PowerBiReportSelectProperty,
        },
    ],
};

export const LEAD_IMAGE_FILE_SELECT_PROPERTY = [
    {
        id: "file",
        buttonTitle: "Select Lead Image File",
        type: PropertyType.FileSelect,
        fileType: "Image",
        borderTop: true,
        borderBottom: false,
        validationRules: [ValidationRule.Required],
        customSubtitle: "Select Lead Image",
    },
];

export const HEADER_IMAGE_FILE_SELECT_PROPERTY = [
    {
        id: "file",
        buttonTitle: "Select Header Image File",
        type: PropertyType.FileSelect,
        fileType: "Image",
        borderTop: true,
        borderBottom: false,
        validationRules: [ValidationRule.Required],
        customSubtitle: "Select Header Image",
    },
];

export const LINK_GLOBAL_PROPERTIES = [
    {
        id: "defaultColor",
        title: "Default",
        type: "select-panel",
        selectComponent: ColorSelectIcon,
        panelComponent: ColorSelectPanel,
        selectPanelSubTitle: (
            <>
                <strong>LINK DEFAULT STATE</strong> TEXT COLOR SELECTION
            </>
        ),
    },
    {
        id: "mouseOverColor",
        title: "Mouse Over",
        type: "select-panel",
        selectComponent: ColorSelectIcon,
        panelComponent: ColorSelectPanel,
        selectPanelSubTitle: (
            <>
                <strong>LINK MOUSE OVER STATE</strong> TEXT COLOR SELECTION
            </>
        ),
    },
    {
        id: "focusColor",
        title: "Focus",
        type: "select-panel",
        selectComponent: ColorSelectIcon,
        panelComponent: ColorSelectPanel,
        borderBottom: false,
        selectPanelSubTitle: (
            <>
                <strong>LINK FOCUS STATE</strong> TEXT COLOR SELECTION
            </>
        ),
    },
];

export const LIST_GLOBAL_PROPERTIES = [
    {
        id: "margin",
        title: "Space between items",
        noPostfix: true,
        type: PropertyType.SelectBox,
        borderBottom: false,
        defaultValue: "none",
        items: [
            {
                text: "None",
                value: "none",
            },
            {
                text: "Normal",
                value: "0.25rem",
            },
            {
                text: "Large",
                value: "0.5rem",
            },
        ],
    },
];

export const setConfigSectionValue = (
    config: PortalTemplateConfiguration | undefined,
    sectionKey: keyof PortalTemplateConfiguration,
    id: string,
    value: any
) => {
    const sectionConfig = config?.[sectionKey] ?? {};

    if (id === "rows") {
        return {
            ...(config ?? {}),
            [sectionKey]: {
                ...sectionConfig,
                components: [...(value ?? [])],
            },
        };
    }

    return {
        ...(config ?? {}),
        [sectionKey]: {
            ...sectionConfig,
            [id]: value,
        },
    };
};

export const setWidgetProperties = (widgetConfig: PortalWidgetConfiguration, properties: { id: PropertyName; value: any }[] = []) => {
    // Create a new object with updated component props
    widgetConfig.props = omitBy(
        properties.reduce((result, property) => {
            return {
                ...result,
                [property.id]: property.value,
            };
        }, widgetConfig.props ?? {}),
        isNil
    );

    // Remove props object if it is empty
    if (isEmpty(widgetConfig.props)) {
        delete widgetConfig.props;
    }

    return widgetConfig;
};

/**
 * Add component to portal configuration
 *
 * @param {[object]} componentsList Components list
 * @param {string} type Component type
 * @param {number} index Position in components list. Add at the end of the list if not specified.
 */
export const addWidget = (componentsList: any[] = [], type: string, index?: number, title?: string) => {
    const props = (PORTAL_WIDGET_PROPERTIES[type] ?? []).reduce((result: any, item: any) => {
        if (Array.isArray(item.propertiesGroup)) {
            const value = item.propertiesGroup.reduce(
                (result: any, i: any) => (result = { ...result, [i.id]: i?.defaultValue ?? item?.defaultValue?.[i.id] }),
                {}
            );

            result = {
                ...result,
                ...value,
            };
        } else {
            result = {
                ...result,
                [item.id]: item?.defaultValue,
            };
        }

        return result;
    }, {});

    let widget = {
        type,
        props: omitBy(props, isNil), // Pick only props with values
    };

    // Add default row layout config
    const rowLayout = RowLayoutTypes[props.layout as LayoutType];
    if (type === WidgetType.ROW && !isNil(rowLayout)) {
        const components = getRowLayoutConfig({
            columns: [],
            selectedLayout: rowLayout,
        });

        widget = {
            ...widget,
            props: {
                ...props,
                components,
                title: title ?? "ROW-1",
            },
        };
    }

    if (type === WidgetType.ACCORDION_ITEM) {
        widget = {
            ...widget,
            props: {
                ...props,
                headerText: title,
            },
        };
    }

    const list = cloneDeep(componentsList);
    const listIndex = index ?? (list.length > 0 ? list.length : 0);
    list.splice(listIndex, 0, widget);

    return list;
};

export const getRowName = (index: number, currentRows: Row[] = []): string => {
    const newRowName = `ROW-${index ?? 1}`;
    if (!currentRows?.some((v) => v?.title === newRowName)) {
        return newRowName;
    } else {
        index++;
        return getRowName(index, currentRows);
    }
};

export const getRowLayoutConfig = ({
    columns,
    selectedLayout,
}: {
    columns: PortalWidgetConfiguration[];
    selectedLayout: RowLayoutConfiguration;
}) => {
    const layoutColumnCount = selectedLayout?.columns?.length ?? 0;
    let updatedColumns = [];

    for (let i = 0; i < layoutColumnCount; i++) {
        const widget = addWidget([], WidgetType.COL)[0];
        const existingProps = columns?.[i]?.props ?? {};
        const portalProps = selectedLayout.columns[i]?.portalProps ?? {};

        const padding = existingProps.padding ?? undefined;
        const components = existingProps.components ?? [{}];

        const column = {
            ...widget,
            props: {
                ...widget.props,
                ...existingProps,
                ...portalProps,
                padding,
                components,
            },
        };

        updatedColumns.push(column);
    }

    return updatedColumns;
};

export const useDefaultValue = (
    id: string,
    value: string | undefined,
    defaultValue: string | boolean | undefined,
    onChange: PropertyChangeFunc
) => {
    useEffect(() => {
        if (isNil(value) && !isNil(defaultValue)) {
            setTimeout(() => {
                onChange(id, defaultValue);
            }, 0);
        }
    }, [id, defaultValue, onChange, value]);
};

// Since properties with propertieGroup have errors stored in an object,
// need to transform into an array before using in the <PropertyList/> component below
export const errorObjectToArray = (errors: any) => {
    const newErrors = Object.keys(errors).reduce((result: ValidationError[], item) => {
        errors[item]?.forEach((message: string) => {
            result = [...result, { id: item, message }];
        });
        return result;
    }, []);
    return newErrors;
};

export const useShowError = (elementRef: RefObject<HTMLDivElement>, listContainsActiveError: boolean, id: string) => {
    const [lightUpError, setLightUpError] = useState<boolean | null>(null);
    const [activeError] = usePortalBuilderState((state) => state.activeError);

    useEffect(() => {
        if (listContainsActiveError && activeError?.id?.endsWith(id)) {
            elementRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
            setLightUpError(true);

            setTimeout(() => {
                setLightUpError(false);
            }, 1000);
        }
    }, [activeError, elementRef, id, listContainsActiveError]);

    return lightUpError;
};

export const takePortalScreenshot = async (
    iframe: MessageEventSource
): Promise<{
    dataUrl: string;
    error?: string;
}> => {
    try {
        return await new PortalMessenger(iframe, 30).post<{ dataUrl: string }>("portal-get-screenshot", {
            cacheBust: true,
            backgroundColor: "white",
            canvasHeight: 130,
            canvasWidth: 230,
        });
    } catch (e) {
        return {
            dataUrl: "",
            error: "Failed to create a template thumbnail",
        };
    }
};

export const stripHelperValues = (obj: Object, excludedKeys: string[] = []) => {
    function omitFromObject(obj: Object) {
        // the inner function which will be called recursively
        return transform(obj, function (result: any, value: any, key: string | number) {
            // transform to a new object
            if (String(key)?.startsWith("_") && !excludedKeys.includes(String(key))) {
                // skip helper keys
                return;
            }

            result[key] = isObject(value) ? omitFromObject(value) : value; // if the key is an object run it through the inner function - omitFromObject
        });
    }

    return omitFromObject(obj); // return the inner function result
};

export const getInitialSection = (isProgramPortalBuilder: boolean, utilityTemplateNumber: string | undefined) => {
    if (isProgramPortalBuilder) {
        return isNil(utilityTemplateNumber) ? SECTION_SETTINGS : SECTION_TEMPLATE_INFO;
    }

    return SECTION_TEMPLATE_INFO;
};

export const getLeadImageContentProperties = (contentType: LeadImageContentType, parentValue?: any): PortalBuilderProperty[] => {
    return [
        {
            id: "leadImageContent",
            title: "Lead Image Content",
            type: PropertyType.LeadImageContent,
            titleSwitch: {
                id: "leadImageContentOff",
            },
            borderBottom: true,
            borderTop: true,
            propertiesGroup: [
                {
                    id: "contentType",
                    title: "Content Type",
                    type: PropertyType.TileSelector,
                    items: [
                        {
                            name: "Title Only",
                            value: "title",
                        },
                        {
                            name: "Title & Button",
                            value: "titleButton",
                        },
                        {
                            name: "Title & Text",
                            value: "titleText",
                        },
                        {
                            name: "Title & Text & Button",
                            value: "titleTextButton",
                        },
                    ],
                },
                {
                    id: "contentTitle",
                    title: "Content Title",
                    type: PropertyType.Text,
                    fullWidth: true,
                    validationRules: [ValidationRule.Required],
                    borderBottom: false,
                },
                ...(contentType === LeadImageContentType.TitleText || contentType === LeadImageContentType.TitleTextButton
                    ? [
                          {
                              id: "contentText",
                              title: "Content Text",
                              type: PropertyType.Textarea,
                              maxLength: 115,
                              fullWidth: true,
                              validationRules: [ValidationRule.Required],
                              borderBottom: false,
                          },
                      ]
                    : []),
                ...(contentType === LeadImageContentType.TitleButton || contentType === LeadImageContentType.TitleTextButton
                    ? [
                          {
                              id: "buttonText",
                              title: "Button Text",
                              type: PropertyType.Text,
                              fullWidth: true,
                              validationRules: [ValidationRule.Required],
                              borderBottom: true,
                          },
                          {
                              id: "linkType",
                              title: "Button url",
                              type: PropertyType.SelectBox,
                              items: [
                                  {
                                      text: "Internal",
                                      value: "internal",
                                  },
                                  {
                                      text: "External",
                                      value: "external",
                                  },
                              ],
                              borderBottom: false,
                              defaultValue: LinkType.INTERNAL,
                          },
                          {
                              id: "buttonURL",
                              type: PropertyType.CustomComponent,
                              component: LeadImageUrl,
                              fullWidth: true,
                              validationRules: [ValidationRule.Required, ValidationRule.ExternalInternalLeadImageButton],
                              borderBottom: true,
                              parentValue: parentValue,
                          },
                          {
                              id: "internalPageSelect",
                              hidden: true,
                          },
                          {
                              id: "target",
                              hidden: true,
                          },
                      ]
                    : []),
                {
                    id: "contentTextColor",
                    title: "Content Text Color",
                    type: PropertyType.IconSelector,
                    items: [
                        {
                            text: "Black",
                            value: "black",
                            tooltip: "Black",
                        },
                        {
                            text: "White",
                            value: "white",
                            tooltip: "White",
                        },
                    ],
                },
                ALIGN_Y_PROPERTY,
                ALIGN_X_PROPERTY,
            ],
        },
    ];
};

export const getProgramTemplateConfigurationForSave = (
    programTemplateConfiguration: ProgramTemplateConfiguration | undefined,
    utilityTemplateConfiguration: PortalTemplateConfiguration | undefined
) => {
    if (!isObject(programTemplateConfiguration)) {
        return "{}";
    }

    let config = cloneDeep(programTemplateConfiguration);
    config = cleanProgramLevelConfiguration(config, utilityTemplateConfiguration);

    return JSON.stringify(config);
};

export const openTemplateSaveErrorModal = ({ errors, onFixErrors }: { errors: ValidationError[]; onFixErrors: () => void }) => {
    return modalOpen({
        modalType: "MODAL",
        modalProps: {
            title: "The template contains errors",
            modalIcon: "circle-exclamation-filled",
            overlayClassName: "modal-styled",
            className: "modal-sm error-modal",
            children: (
                <span>
                    The template has <span className="error-count">{errors.length} errors</span>. Publishing with errors is not allowed. You
                    may save the template as a draft or fix the errors.
                </span>
            ),
            actions: (
                <Button onClick={onFixErrors} icon="arrow-thin-right-empty">
                    Fix Errors
                </Button>
            ),
        },
    });
};

export const DEFAULT_UTILITY_TEMPLATE_CONFIG: PortalTemplateConfiguration = {
    settings: {
        enableGoogleAnalytics: false,
        enableGoogleTags: false,
        cookiesBannerPosition: AlignYPosition.Bottom,
        enableCookiesBanner: false,
        enableCookiesBannerLink: false,
        enableNotification: false,
        notificationText: "Notification Text",
        notificationCloseIcon: false,
        enableProgramNotification: false,
        programNotificationText: "",
        programNotificationCloseIcon: false,
        [PropertyName.MaxWidth]: LayoutWidth.XXL,
        [PropertyName.TextColor]: sassVariables.colorDarkGrey,
        [PropertyName.FontSize]: TypographyFontSize.Small,
        [PropertyName.FontFamily]: FontFamily.Roboto,
        [PropertyName.LineHeight]: TypographyLineHeight.Small,
        siteTitleTemplate: "{utilityName}: {programName} - {pageName}",
    },
    colors: {
        primary: "#0d6efd",
        secondary: "#706b85",
        fill: undefined,
        accent: undefined,
        text: undefined,
    },
    header: {
        isEnabled: false,
        components: [],
    },
    footer: {
        isEnabled: false,
        components: [],
    },
    content: {
        pages: [
            {
                title: "Sign in",
                link: "/sign-in",
                linkType: LinkType.INTERNAL,
                headerNavigation: HeaderNavigation.NEVER,
                openInNewTab: false,
                isShared: true,
                custom: false,
                paddingTop: PaddingBoxSize.LG,
                paddingRight: PaddingBoxSize.MD,
                paddingBottom: PaddingBoxSize.LG,
                paddingLeft: PaddingBoxSize.MD,
                components: [
                    {
                        type: "SignInPage",
                    },
                ],
            },
            {
                title: "Create account",
                link: "/create-account",
                linkType: LinkType.INTERNAL,
                headerNavigation: HeaderNavigation.NEVER,
                openInNewTab: false,
                isShared: true,
                custom: false,
                paddingTop: PaddingBoxSize.LG,
                paddingRight: PaddingBoxSize.MD,
                paddingBottom: PaddingBoxSize.LG,
                paddingLeft: PaddingBoxSize.MD,
                components: [
                    {
                        type: "CreateAccountPage",
                    },
                ],
            },
            {
                title: "View Application",
                link: PageLink.ApplicationView,
                linkType: LinkType.INTERNAL,
                headerNavigation: HeaderNavigation.NEVER,
                openInNewTab: false,
                isShared: true,
                custom: false,
                headerTextColor: TextColor.Dark,
                components: [
                    {
                        type: "SubmittedApplicationPage",
                    },
                ],
            },
            {
                title: "Contractor Search",
                link: "/contractor-search",
                linkType: LinkType.INTERNAL,
                headerNavigation: HeaderNavigation.NEVER,
                openInNewTab: false,
                isShared: true,
                custom: false,
                paddingTop: PaddingBoxSize.LG,
                paddingRight: PaddingBoxSize.MD,
                paddingBottom: PaddingBoxSize.LG,
                paddingLeft: PaddingBoxSize.MD,
                components: [
                    {
                        type: "ContractorMapPage",
                    },
                ],
            },
            {
                title: "Reset Password",
                link: "/customerReset",
                linkType: LinkType.INTERNAL,
                headerNavigation: HeaderNavigation.NEVER,
                openInNewTab: false,
                isShared: true,
                custom: false,
                paddingTop: PaddingBoxSize.LG,
                paddingRight: PaddingBoxSize.MD,
                paddingBottom: PaddingBoxSize.LG,
                paddingLeft: PaddingBoxSize.MD,
                components: [
                    {
                        type: "ResetPasswordPage",
                    },
                ],
            },
            {
                title: "Forgot password",
                link: "/forgot-password",
                linkType: LinkType.INTERNAL,
                headerNavigation: HeaderNavigation.NEVER,
                openInNewTab: false,
                isShared: true,
                custom: false,
                paddingTop: PaddingBoxSize.LG,
                paddingRight: PaddingBoxSize.MD,
                paddingBottom: PaddingBoxSize.LG,
                paddingLeft: PaddingBoxSize.MD,
                components: [
                    {
                        type: "ForgotPasswordPage",
                    },
                ],
            },
            {
                title: "Contact us",
                link: "/contact-us",
                linkType: LinkType.INTERNAL,
                headerNavigation: HeaderNavigation.NEVER,
                openInNewTab: false,
                isShared: true,
                custom: false,
                paddingTop: PaddingBoxSize.LG,
                paddingRight: PaddingBoxSize.MD,
                paddingBottom: PaddingBoxSize.LG,
                paddingLeft: PaddingBoxSize.MD,
                props: {
                    contactUsFormOn: true,
                    contactUsTextOn: false,
                    thankYouTitle: "Thank You!",
                    thankYouContent: "Thank you for submitting your message.<br />We will respond to you as soon as possible.",
                },
                components: [
                    {
                        type: "ContactUsPage",
                    },
                ],
            },
            {
                title: "Page not found",
                link: "*",
                linkType: LinkType.INTERNAL,
                headerNavigation: HeaderNavigation.NEVER,
                openInNewTab: false,
                isShared: true,
                custom: false,
                paddingTop: PaddingBoxSize.LG,
                paddingRight: PaddingBoxSize.MD,
                paddingBottom: PaddingBoxSize.LG,
                paddingLeft: PaddingBoxSize.MD,
                components: [
                    {
                        type: "PageNotFoundPage",
                    },
                ],
            },
            {
                title: "Cookies",
                link: "/cookies",
                linkType: LinkType.INTERNAL,
                headerNavigation: HeaderNavigation.NEVER,
                openInNewTab: false,
                isShared: true,
                custom: true,
                paddingTop: PaddingBoxSize.LG,
                paddingRight: PaddingBoxSize.MD,
                paddingBottom: PaddingBoxSize.LG,
                paddingLeft: PaddingBoxSize.MD,
                components: addWidget([], WidgetType.ROW),
            },
            {
                title: "Dashboard",
                link: PageLink.CustomerHome,
                linkType: LinkType.INTERNAL,
                headerNavigation: HeaderNavigation.NEVER,
                openInNewTab: false,
                isShared: true,
                custom: false,
                paddingTop: PaddingBoxSize.LG,
                paddingRight: PaddingBoxSize.MD,
                paddingBottom: PaddingBoxSize.LG,
                paddingLeft: PaddingBoxSize.MD,
                components: [
                    {
                        type: "CustomerDashboardPage",
                    },
                ],
            },
            {
                title: "Prescreen",
                link: "/prescreen",
                linkType: LinkType.INTERNAL,
                headerNavigation: HeaderNavigation.NEVER,
                openInNewTab: false,
                isShared: true,
                custom: false,
                paddingTop: PaddingBoxSize.LG,
                paddingRight: PaddingBoxSize.MD,
                paddingBottom: PaddingBoxSize.LG,
                paddingLeft: PaddingBoxSize.MD,
                components: [
                    {
                        type: "PrescreenPage",
                    },
                ],
            },
            {
                title: "Application Thank You Page",
                link: "/application-submitted",
                linkType: LinkType.INTERNAL,
                headerNavigation: HeaderNavigation.NEVER,
                openInNewTab: false,
                isShared: true,
                custom: false,
                paddingTop: PaddingBoxSize.LG,
                paddingRight: PaddingBoxSize.MD,
                paddingBottom: PaddingBoxSize.LG,
                paddingLeft: PaddingBoxSize.MD,
                components: [
                    {
                        type: "ThankYouPage",
                    },
                ],
            },
            {
                title: "Anonymous Thank You Page",
                link: "/extform-submitted",
                linkType: LinkType.INTERNAL,
                headerNavigation: HeaderNavigation.NEVER,
                openInNewTab: false,
                isShared: true,
                custom: false,
                paddingTop: PaddingBoxSize.LG,
                paddingRight: PaddingBoxSize.MD,
                paddingBottom: PaddingBoxSize.LG,
                paddingLeft: PaddingBoxSize.MD,
                components: [
                    {
                        type: "AnonymousThankYouPage",
                    },
                ],
            },

            {
                title: "Privacy Policy",
                link: "/aeg-privacy-policy",
                linkType: LinkType.INTERNAL,
                headerNavigation: HeaderNavigation.NEVER,
                openInNewTab: false,
                isShared: true,
                custom: false,
                paddingTop: PaddingBoxSize.LG,
                paddingRight: PaddingBoxSize.MD,
                paddingBottom: PaddingBoxSize.LG,
                paddingLeft: PaddingBoxSize.MD,
                components: [
                    {
                        type: "PrivacyPolicyPage",
                    },
                ],
            },
            {
                title: "Privacy Policy CCPA",
                link: "/aeg-privacy-policy-ccpa",
                linkType: LinkType.INTERNAL,
                headerNavigation: HeaderNavigation.NEVER,
                openInNewTab: false,
                isShared: true,
                custom: false,
                paddingTop: PaddingBoxSize.LG,
                paddingRight: PaddingBoxSize.MD,
                paddingBottom: PaddingBoxSize.LG,
                paddingLeft: PaddingBoxSize.MD,
                components: [
                    {
                        type: "PrivacyPolicyCCPAPage",
                    },
                ],
            },
        ],
    },
    widgets: {
        contentWrapper: {
            backgroundColor: "#ffffff",
        },
        button: {
            buttonSize: ButtonSize.Normal,
            buttonCornerRadius: ButtonCornerRadius.Radius1,
            primaryButtonBackgroundColorDefault: "#0d6efd",
            primaryButtonBackgroundColorMouseOver: "#0b5ed7",
            primaryButtonBackgroundColorFocusActive: "#0a58ca",
            primaryButtonBackgroundColorDisabled: "#0d6efd50",

            ghostButtonBackgroundColorDefault: "transparent",
            ghostButtonBackgroundColorMouseOver: "#666e75",
            ghostButtonBackgroundColorFocusActive: "#5c636a",
            ghostButtonBackgroundColorDisabled: "transparent",

            ghostButtonTextColorDefault: "#666e75",
            ghostButtonTextColorMouseOver: "#ffffff",
            ghostButtonTextColorFocusActive: "#ffffff",
            ghostButtonTextColorDisabled: "#666e7550",

            ghostButtonBorderColorDefault: "#666e75",
            ghostButtonBorderColorMouseOver: "#666e75",
            ghostButtonBorderColorFocusActive: "#5c636a",
            ghostButtonBorderColorDisabled: "#666e7550",

            filledButtonBackgroundColorDefault: "#666e75",
            filledButtonBackgroundColorMouseOver: "#5c636a",
            filledButtonBackgroundColorFocusActive: "#5c636a",
            filledButtonBackgroundColorDisabled: "#666e7550",

            filledButtonBorderColorDefault: "#666e75",
            filledButtonBorderColorMouseOver: "#565e64",
            filledButtonBorderColorFocusActive: "#5c636a",
            filledButtonBorderColorDisabled: "#666e7550",

            secondaryButtonType: "filled",
        },
        heading: {
            headingColor: sassVariables.colorDarkGrey,
        },
        link: {
            defaultColor: sassVariables.bsLinkBlue,
            mouseOverColor: sassVariables.bsLinkBlueHover,
            focusColor: sassVariables.bsLinkBlueHover,
        },
    },
};
