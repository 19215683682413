import React, { useCallback, useContext } from "react";
import cn from "classnames";
import { isFunction, isNil } from "lodash";
import IconWithLabel from "components/ui/Icons/IconWithLabel";
import { PortalBuilderPropertiesContext } from "../contexts";
import { SECTION_CONTENT } from "./Section";
import { usePortalBuilderState } from "../PortalBuilderContextProvider";
import { useErrorContext } from "../PortalBuilderErrorContextProvider";

import "./SectionHeader.scss";

export const SectionHeader = (props) => {
    const { Icon, title, subtitle, underline, onGoBack, extraComponent, containsErrors } = props;
    const ExtraComponent = extraComponent;

    const { setActiveSection } = useErrorContext();
    const [lastEditingPageIndex, setPortalBuilderState] = usePortalBuilderState((state) => state.lastEditingPageIndex);
    const [lastActiveSection] = usePortalBuilderState((state) => state.lastActiveSection);
    const { selectedWidget } = useContext(PortalBuilderPropertiesContext);

    const showBackButton = (lastActiveSection && !selectedWidget) || isFunction(onGoBack);

    const onBack = useCallback(() => {
        if (lastActiveSection) {
            if (lastActiveSection === SECTION_CONTENT) {
                setPortalBuilderState({ editingPageIndex: lastEditingPageIndex });
            }
            setActiveSection(lastActiveSection);
        } else if (onGoBack) {
            onGoBack();
        }
    }, [lastActiveSection, onGoBack, setActiveSection, setPortalBuilderState, lastEditingPageIndex]);

    return (
        <div
            className={cn("portal-builder-properties-section-header flex-column no-shrink", {
                "portal-builder-properties-section-header--underline": underline,
                "justify-center": isNil(subtitle),
                "contains-errors": containsErrors,
            })}
        >
            {showBackButton && (
                <div className="portal-builder-properties-section-header-controls flex-row">
                    <IconWithLabel icon="shevron-small-left" onClick={onBack}>
                        Back
                    </IconWithLabel>
                </div>
            )}
            <div className="flex-row fill-width align-center justify-center">
                <Icon className="portal-builder-section-icon" />
                <h2 className="portal-builder-properties-section-header-title">{title}</h2>
            </div>
            {subtitle && (
                <div className="portal-builder-properties-section-header-subtitle flex-row fill-width align-center justify-center">
                    {subtitle}
                </div>
            )}
            {extraComponent && <ExtraComponent />}
        </div>
    );
};
