import React, { memo, useCallback } from "react";
import cn from "classnames";
import TextLabel from "components/ui/Label/TextLabel";
import SelectBox from "components/ui/SelectBox";
import { PortalBuilderPropertyProps } from "../../types";
import { useDefaultValue } from "../../utils";
import { PropertyListItem } from "../Property/PropertyListItem";
import "./IconSelector.scss";

export const IconSelectorProperty = memo((props: IconSelectorPropertyProps) => {
    const { id, title, items, value, defaultValue, onChange, borderTop, borderBottom } = props;

    useDefaultValue(id, value, defaultValue, onChange);

    const onSelect = useCallback(
        (value: any) => {
            onChange(id, value);
        },
        [id, onChange]
    );

    return (
        <PropertyListItem className="property-list-item--icon-selector icon-selector" borderTop={borderTop} borderBottom={borderBottom}>
            <div className="property-list-item-inner align-center flex-row justify-space-between">
                <TextLabel>{title}</TextLabel>
                <div className="flex-row">
                    {items.map((i, index) => {
                        return (
                            <SelectBox
                                key={index}
                                selectBoxTitle={i.tooltip}
                                icon={i.icon}
                                selectBoxSelected={i.value === value}
                                onClick={() => onSelect(i.value)}
                                className={cn({
                                    "text-select-box": !!i.text,
                                })}
                            >
                                {i.text}
                            </SelectBox>
                        );
                    })}
                </div>
            </div>
        </PropertyListItem>
    );
});

interface IconSelectorPropertyProps extends PortalBuilderPropertyProps {
    items: any[];
}
