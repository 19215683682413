import React from "react";
import cn from "classnames";

import IconWrap from "../../Icons";

import "./style.scss";

const DashboardPanel = ({ title, children, className }) => (
    <div className={cn("panel dashboard-panel", className)}>
        <div className="panel-header">
            <div className="panel-title">
                {title ? <span>{title}</span> : <span className="no-title">No title</span>}
                <IconWrap title="Drag and Drop the Panel" icon="move" />
            </div>
        </div>
        <div className="panel-body flex-column flex-one-in-column">{children}</div>
    </div>
);

export default DashboardPanel;
