import React, { memo } from "react";

import { openProjectTab } from "../../../../../utils/window";
import { programsRebatesGridColumnKeys } from "../../../../../views/configureGrids";

import ActionLabel from "../../../../Label/ActionLabel";
import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid.js";
import RawHtml from "components/ui/RawHtml";

import "./style.scss";

const RebatesGrid = memo((props) => {
    const { gridId, dataGridConfig } = props;

    const customRowActions = [
        {
            name: "View Project",
            icon: "open-new-window",
            title: "View Project",
        },
    ];

    const onRowAction = (action) => {
        const { dataItem } = action;
        const applicationNumber = dataItem[programsRebatesGridColumnKeys.applicationNumber];

        openProjectTab({ applicationNumber });
    };

    const onApplicationOpen = (applicationNumber) => {
        openProjectTab({ applicationNumber });
    };

    return (
        <DataGrid
            name={gridId}
            config={dataGridConfig}
            onRowAction={onRowAction}
            customRowActions={customRowActions}
            columnCellContent={{
                [programsRebatesGridColumnKeys.projectNumber]: (column, row) => (
                    <ActionLabel onClick={() => onApplicationOpen(row[programsRebatesGridColumnKeys.applicationNumber])}>
                        {row[column.key]}
                    </ActionLabel>
                ),
                [programsRebatesGridColumnKeys.applicationNumber]: (column, row) => {
                    if (row[column.key]) {
                        return (
                            <ActionLabel onClick={() => onApplicationOpen(row[programsRebatesGridColumnKeys.applicationNumber])}>
                                {row[column.key]}
                            </ActionLabel>
                        );
                    }

                    return row[column.key];
                },
                [programsRebatesGridColumnKeys.checkInfo]: (column, row) => {
                    if (row[column.key]) {
                        return <RawHtml>{row[column.key]}</RawHtml>;
                    }
                },
            }}
        />
    );
});

export default withDataGrid("programsRebates", RebatesGrid);
