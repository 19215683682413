import React, { useCallback, memo } from "react";
import { useDispatch } from "react-redux";

import { windowSetDevice } from "../../../store/window/actions";

import { useResizeDetector } from "react-resize-detector";

import "./style.scss";

const Layout = memo((props) => {
    const dispatch = useDispatch();

    const onResize = useCallback(
        (width) => {
            dispatch(
                windowSetDevice({
                    width,
                })
            );
        },
        [dispatch]
    );

    const { ref } = useResizeDetector({
        handleHeight: false,
        onResize,
    });

    return (
        <div ref={ref} className="app no-scroll">
            {props.children}
        </div>
    );
});

export default Layout;
