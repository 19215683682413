import { isChildProgram } from "components/views/ProgramView/utils";
import React from "react";

import IconWithLabel from "../../../../Icons/IconWithLabel";

const Controls = ({ onAddCalculation, onExport, programNumber }) => (
    <div className="grid-controls flex-row align-center">
        <span className="flex-one" />
        <IconWithLabel disabled={isChildProgram({ programNumber })} icon="plus" onClick={onAddCalculation}>
            Add Event Calculation
        </IconWithLabel>
        <IconWithLabel withHandMadeIcon onClick={onExport}>
            Export CSV
        </IconWithLabel>
    </div>
);

export default Controls;
