import React from "react";
import cn from "classnames";

const SideNavBody = ({ className, noPadding, children, rowLayout, hidden }: SideNavBodyProps) => {
    return (
        <div
            hidden={hidden}
            className={cn("sidenav-body", className, {
                "sidenav-body--no-padding": noPadding,
                "flex-column flex-one": !rowLayout,
                "flex-row flex-one": rowLayout,
            })}
        >
            {children}
        </div>
    );
};

export default SideNavBody;

interface SideNavBodyProps {
    className?: string;
    noPadding?: boolean;
    children?: React.ReactNode;
    rowLayout?: boolean;
    hidden?: boolean;
}
