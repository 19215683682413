import React, { memo } from "react";

import FieldGroup from "../FieldGroup";
import FieldWrap from "../FieldWrap";
import Input from "../Input";
import Label from "../Label";
import FieldIconClickable from "../Icons/FieldIconClickable";
import StatusMsg from "../StatusMsg";

const FieldGroupNumericInput = memo((props) => {
    return (
        <FieldGroup inline className={props.className}>
            {props.label && (
                <Label labelFullWidth labelRequired={props.labelRequired}>
                    {props.label}
                </Label>
            )}
            <FieldWrap numericInput focus={props.focus} iconRight={props.iconRight}>
                <Input
                    id={props.id}
                    inputRef={props.inputRef}
                    type="number"
                    numeric
                    name={props.name}
                    readOnly={props.readOnly}
                    error={props.error}
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    value={props.value}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    onChange={props.onChange}
                    onKeyDown={props.onKeyDown}
                    focus={props.focus}
                    withIcon={props.withIcon}
                    iconRight={props.iconRight}
                    numericInputFilled={props.numericInputFilled}
                    min={props.min}
                    max={props.max}
                    title={props.title}
                    lightUpError={props.lightUpError}
                />
                {!props.withoutIcon && (
                    <>
                        <FieldIconClickable
                            iconClickableReadOnly={props.readOnly}
                            iconClickableError={props.error}
                            iconClickableDisabled={props.value >= props.max || props.disabled}
                            arrows
                            onClick={props.onUp}
                            iconClickable="shevron-small-up-expand-less"
                        ></FieldIconClickable>
                        <FieldIconClickable
                            iconClickableReadOnly={props.readOnly}
                            iconClickableError={props.error}
                            iconClickableDisabled={props.value <= props.min || props.disabled}
                            arrows
                            onClick={props.onDown}
                            iconClickable="shevron-small-down-expand-more"
                        ></FieldIconClickable>
                    </>
                )}
            </FieldWrap>
            {props.msgText && (
                <StatusMsg
                    msgFieldStatus
                    msgError={props.error}
                    msgInfo={props.msgInfo}
                    msgSuccess={props.msgSuccess}
                    msgText={props.msgText}
                ></StatusMsg>
            )}
        </FieldGroup>
    );
});

export default FieldGroupNumericInput;
