import React, { useState } from "react";

import NothingFoundBlock from "../../../NothingFoundBlock";
import Button from "../../../Button";

import "./BudgetLinesSelect.scss";
import TextInput from "components/ui/Input/TextInput";
import { isChildProgram } from "components/views/ProgramView/utils";

const BudgetLinesSelect = (props) => {
    const { onSelect, data, programNumber } = props;
    const [filterValue, setFilterValue] = useState("");

    const filteredItems = data.filter(function (item) {
        return item.title.toLowerCase().indexOf(filterValue.toLocaleLowerCase()) > -1;
    });

    return (
        <div className="budget-lines-select-form flex-column no-scroll">
            {data.length === 0 && (
                <NothingFoundBlock
                    nothingFoundBlockSmall
                    nothingFoundBlockNoBorder
                    icon="files"
                    title="There are no available budget lines."
                />
            )}
            {data.length >= 5 && (
                <div className="budgetline-list__filter">
                    <TextInput
                        disabled={isChildProgram({ programNumber })}
                        autoFocus
                        placeholder="Filter List"
                        iconRight
                        icon={filterValue.length === 0 ? "search" : "clear-close"}
                        iconTitle={filterValue.length === 0 ? "" : "Clear Filter"}
                        onIconClick={filterValue.length === 0 ? undefined : () => setFilterValue("")}
                        value={filterValue}
                        onChange={(event) => setFilterValue(event.target.value)}
                    />
                </div>
            )}
            {filteredItems.length === 0 && (
                <NothingFoundBlock nothingFoundBlockSmall nothingFoundBlockNoBorder icon="search-off" title="No filter matches found..." />
            )}

            <div className="fill-height with-scroll">
                {filteredItems.map((line) => (
                    <div key={line.value} className="budget-line-name" onClick={() => onSelect(line.value)}>
                        <span className="flex-row justify-space-between align-center">
                            {line.title}
                            <Button icon="touch-empty" small primary>
                                select
                            </Button>
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BudgetLinesSelect;
