import React, { memo } from "react";

import IconWrap from "../Icons";
import useFocusClasses from "../../utils/useFocusClasses";
import { isString } from "lodash";

import "./style.scss";

const Breadcrumb = memo(({ items = [] }) => {
    const onKeyDown = (item) => (event) => {
        if (event.key === "Enter") {
            item.onClick();
        }
    };

    const isTabable = true;
    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled: !isTabable,
    });

    const isLastIndex = (index) => {
        return items.length - index === 1;
    };

    return (
        <div className="breadcrumb flex-row align-center">
            {items.map((item, index) =>
                isLastIndex(index) ? (
                    <div key={index} className="breadcrumb-item">
                        {item.name}
                    </div>
                ) : (
                    <div
                        key={index}
                        className="breadcrumb-item flex-row align-center"
                        tabIndex={isTabable ? "0" : "-1"}
                        onFocus={onFocusClassesFocus}
                        onBlur={onFocusClassesBlur}
                        onKeyDown={onKeyDown(item)}
                        onClick={item.onClick}
                    >
                        {isString(item.name) ? <span>{item.name}</span> : item.name}
                        <IconWrap iconWrap="shevron-small-right" />
                    </div>
                )
            )}
        </div>
    );
});

export default Breadcrumb;
