import { get, isEqual, isNil, isNumber, isString, omit, orderBy, cloneDeep, uniqBy, isDate, toNumber } from "lodash";
import { compareDateWithoutTime, formatJsonDate, formatJsonDateTime, jsonDateToDate, serverDateToLocal } from "./date";
import { store } from "store/configureStore";
import { camelToTitle, stripHtmlForDataGrid } from "./string";
import { getParents, hasChildren } from "./tree";
import { toArray } from "./array";
import { formatMoney } from "./money";
import { applicationFilesGridColumnKeys, logsSystemErrorsGridColumnKeys } from "components/views/configureGrids";
import { FileSecurity } from "./files";

export const dataGridTypes = {
    CLIENT: "client",
};

export const SortType = {
    CLIENT: "client",
    SERVER: "server",
};

export const dataGridColumnTypes = {
    string: "varchar",
    dateTime: "datetime",
    number: "number",
    bool: "bit",
    date: "date",
    datetimeWithSeconds: "datetimeWithSeconds",
};

export const getDataGridConfig = (dataGridId) => {
    const state = store.getState();
    return get(state, `dataGrid[${dataGridId}]`);
};

export const getPageSize = (dataGrid) => {
    const defaultPageSize = 5;
    let pageSize = defaultPageSize;

    if (get(dataGrid, "gridactions[0].paging[0].enabled") === "true") {
        pageSize = parseInt(get(dataGrid, "gridactions[0].paging[0].pagesize", 0), 10);

        if (pageSize === 0) {
            pageSize = defaultPageSize;
        }
    } else {
        pageSize = 10000;
    }

    // Do not use paging for tree grid.
    if (dataGrid?.tree) {
        pageSize = 10000;
    }

    return pageSize;
};

export const getTotalRecords = (dataGrid) => {
    let totalRecords = 0;

    // If datagrid has ony one row then 'rows' can be an object, not an array.
    const rows = toArray(dataGrid?.rows);

    if (rows.length > 0) {
        totalRecords = parseInt(rows[0].totRecords, 10);
    }

    if (totalRecords === 0 && dataGrid.sourceRows?.length > 0 && !dataGrid.filter) {
        totalRecords = (dataGrid.sourceRows || []).length;
    }

    return totalRecords;
};

export const getColumnSort = (dataGrid) => {
    let sort = [];

    if (
        dataGrid &&
        dataGrid.gridactions &&
        dataGrid.gridactions[0] &&
        dataGrid.gridactions[0].sorting &&
        dataGrid.gridactions[0].sorting[0].enabled === "true"
    ) {
        if (dataGrid.columns) {
            sort = dataGrid.columns
                .filter((c) => c.sort !== undefined && c.sort.length > 0)
                .map((c) => {
                    return {
                        field: c.key,
                        dir: c.sort,
                    };
                });
        }
    }

    return sort;
};

export const getSortType = (dataGrid) => {
    return dataGrid?.gridactions?.[0]?.sorting?.[0]?.type ?? "";
};

export const getColumns = (dataGrid) => {
    let columns = [];

    if (dataGrid.columns) {
        columns = uniqBy(dataGrid.columns, "key").map((c) => {
            return {
                ...c,
                name: c.name ? c.name : camelToTitle(c.key),
                active: c.active === true || c.active === undefined || c.active === "true",
                filter: c.filter === true || c.filter === undefined || c.filter === "true",
                order: Number(c.order || 0),
                width: c.width ? c.width : "",
                datatype: c.datatype || "varchar",
                serverTimezoneOffset: dataGrid.serverTimezoneOffset,
            };
        });

        columns.sort(function (a, b) {
            return a.order - b.order;
        });

        columns.forEach((element, index) => {
            element.order = index;
        });
    }

    return columns;
};

export const getEnabledGridActions = (dataGridConfig) => {
    let gridActions = [];

    if (dataGridConfig.gridactions && !!dataGridConfig.gridactions.length) {
        gridActions = Object.keys(dataGridConfig.gridactions[0])
            .map((key) => {
                return {
                    ...dataGridConfig.gridactions[0][key][0],
                    name: key,
                    enabled: dataGridConfig.gridactions[0][key][0].enabled === "true",
                    icon: getActionIcon(key),
                    title: getActionTitle(key),
                };
            })
            .filter((action) => action.enabled);
    }

    return gridActions;
};

export const getActionTitle = (action) => {
    if (action.title) {
        return action.title;
    } else {
        switch (action.name || action) {
            case "create":
                return "Create";

            case "edit":
            case "update":
                return "Edit";

            case "delete":
                return "Delete";

            case "columnmanagement":
                return "Manage Grid";

            case "view":
                return "View";

            case "download":
                return "Download";

            default:
                return "";
        }
    }
};

export const getActionIcon = (name) => {
    let icon = null;

    switch (name) {
        case "create":
            icon = "plus";
            break;
        case "edit":
        case "update":
            icon = "edit-empty";
            break;
        case "delete":
            icon = "delete-trash-empty";
            break;
        case "columnmanagement":
            icon = "settings";
            break;
        case "view":
            icon = "view";
            break;
        case "download":
            icon = "download";
            break;
        default:
            icon = name;
            break;
    }

    return icon;
};

export const getSelectedRows = (dataGridId) => {
    const dataGrid = getDataGridConfig(dataGridId);

    return ((dataGrid && dataGrid.rows) || []).filter((r) => r._selected);
};

export const getActivePageNumber = (dataGridId, page = null) => {
    const dataGrid = getDataGridConfig(dataGridId);

    const paging = page || (dataGrid && dataGrid.paging);

    if (paging) {
        return paging.skip / paging.take + 1;
    }

    return 1;
};

export const getClientRows = (dataGridConfig) => {
    const { filter, sourceRows = [], columns } = dataGridConfig;

    // Filter
    const filteredRows = filterRows({ filter, rows: sourceRows, columns });

    // Sort
    const sortedRows = filteredRows;

    return sortedRows;
};

export const getClientRowsPage = (dataGridConfig, filteredRows) => {
    const { skip, take } = dataGridConfig.paging;
    const currentRows = dataGridConfig.rows ?? [];

    return filteredRows.slice(skip, skip + take).map((r) => ({
        ...r,
        // Preserve row selection
        _selected: currentRows.find((row) => row._sortkey === r._sortkey)?._selected,
        // Update total records count
        totRecords: filteredRows.length,
    }));
};

export const searchAttrToFilter = (searchAttr) => {
    if (!isString(searchAttr) || searchAttr.length === 0) {
        return null;
    }
    return {
        filters: searchAttr
            .split("|")
            .map((item) => item.split("="))
            .filter((parts) => parts.length === 2)
            .map((parts) => {
                return {
                    field: parts[0],
                    value: parts[1],
                };
            }),
        logic: "and",
    };
};

export const mapGridDataToObjects = (gridColumnKeys, items) => {
    return items.map((item) =>
        Object.keys(gridColumnKeys).reduce((row, key) => {
            if (item[gridColumnKeys[key]] === undefined) {
                return row;
            }

            return {
                ...row,
                [key]: item[gridColumnKeys[key]],
            };
        }, {})
    );
};

export const mapGridRowToObject = (gridColumnKeys, item) => {
    return Object.keys(gridColumnKeys).reduce((row, key) => {
        if (item[gridColumnKeys[key]] === undefined) {
            return row;
        }

        return {
            ...row,
            [key]: item[gridColumnKeys[key]],
        };
    }, {});
};

/**
 *
 * @param {Object} columnKeysMap - Map of column keys
 * @param {Array} data - Data to map
 * @param {Object} [params] - Params
 * @param {Boolean} params.preserveUnknownColumns - Preserve unknown columns
 * @param {Object} [params.keyAlias] - Key alias (optional)
 * @returns {Array} - Mapped data
 **/
export const mapDataToGridColumnKeys = (columnKeysMap, data, params) => {
    // Get row unknown keys
    const rowUnknownKeys =
        params?.preserveUnknownColumns && data?.length > 0
            ? Object.keys(data[0]).reduce((acc, key) => {
                  if (!Object.keys(columnKeysMap).includes(key) && !params?.keyAlias?.[key]) {
                      acc.push(key);
                  }

                  return acc;
              }, [])
            : [];

    return columnKeysMap
        ? data.map((row) =>
              Object.keys(columnKeysMap).reduce(
                  (acc, key) => {
                      acc[columnKeysMap[key]] = row[key];

                      if (isNil(row[key]) && params?.keyAlias?.[key]) {
                          acc[columnKeysMap[key]] = row[params.keyAlias[key]];
                      }

                      return acc;
                  },
                  // Initialize row object with keys not in key map.
                  rowUnknownKeys.reduce((acc, key) => {
                      acc[key] = row[key];
                      return acc;
                  }, {})
              )
          )
        : data;
};

export const getRowCompareKeys = (row) => {
    const excludedKeys = [
        "totRecords",
        "MoreRecords",
        "_selected",
        "_expanded",
        "_expandedContent",
        "_treeColumn",
        "_treeHasChildren",
        "_treeDepth",
        "_treeExpanded",
        "_treeHidden",
        "_sortkey",
    ];

    return Object.keys(row).filter((key) => !excludedKeys.includes(key));
};

export const isSameRow = (row1, row2) =>
    getRowCompareKeys(row1)
        .map((key) => row1[key] === row2[key])
        .filter((r) => r === false).length === 0;

const getExpandedStateFromSnapshot = (expandedRows, idColumn, row) => {
    return expandedRows?.includes(row[idColumn]) && row._treeHasChildren ? true : null;
};

const getHiddenStateFromSnapshot = (expandedRows, parentIdColumn, row) => {
    return expandedRows?.includes(row[parentIdColumn]) ? false : null;
};

export const getExpandedRowsIds = (grid) => {
    const { idColumn } = grid.tree ?? {};

    if (!idColumn) {
        return [];
    }

    const { sourceRows } = grid;
    return sourceRows?.reduce((prevValue, row) => {
        if (row._treeExpanded) {
            prevValue.push(row[idColumn]);
        }
        return prevValue;
    }, []);
};

export const setTreeParams = ({ rows, treeColumn, idColumn, parentIdColumn, parentNameColumn, expandedRowsSnapshot }) => {
    let treeIsBroken = false;
    const rowLength = rows.length;

    if (rowLength > 0) {
        if (!rows[0].hasOwnProperty(idColumn) || !rows[0].hasOwnProperty(parentIdColumn)) {
            return rows;
        }

        const isHidden = (parents) => {
            return parents.some((p) => !p._treeExpanded);
        };

        rows.forEach((r) => {
            const { isBroken, parents } = getParents({
                item: r,
                list: rows,
                idKey: idColumn,
                parentIdKey: parentIdColumn,
            });

            if (isBroken) {
                treeIsBroken = true;
            }

            r._treeColumn = treeColumn;
            r._treeHasChildren = hasChildren({
                item: r,
                list: rows,
                idKey: idColumn,
                parentIdKey: parentIdColumn,
            });
            r._treeDepth = parents.length;
            r._treeExpanded = getExpandedStateFromSnapshot(expandedRowsSnapshot, idColumn, r) ?? r._treeExpanded ?? false;
            r._treeHidden = getHiddenStateFromSnapshot(expandedRowsSnapshot, parentIdColumn, r) ?? isHidden(parents);
        });

        if (!treeIsBroken) {
            // Check if we have enumeration keys set
            if (!rows[0]["_sortkey"]) {
                let indexCounter = 1;
                for (const row of rows) {
                    row["_sortkey"] = indexCounter;
                    indexCounter += 1;
                }
            }

            // Sort by key before changing order
            rows.sort((row1, row2) => {
                return row1["_sortkey"] - row2["_sortkey"];
            });

            // Create tree parts
            const treeParts = getTreeParts({
                rows,
                treeColumn,
                parentIdColumn,
            });

            // Construct tree
            rows = concatTreeParts({ treeParts, idColumn });
        }
    }

    return rows;
};

export const getTreeParts = ({ rows, treeColumn, parentIdColumn }) => {
    const partKey = (key) => (isNil(key) ? "root" : key);

    // Parts is object with arrays of children with parentId as a key
    const parts = rows.reduce((result, nextRow) => {
        const key = partKey(nextRow[parentIdColumn]);

        if (!result[key]) {
            result[key] = [];
        }

        result[key].push(nextRow);

        return result;
    }, {});

    // Sort items in parts in ascending order
    Object.keys(parts).forEach((key) => {
        parts[key] = orderBy(parts[key], [(item) => (item[treeColumn] ?? "").toLowerCase()]);
    });

    return parts;
};

export const concatTreeParts = ({ treeParts, idColumn }) => {
    const expandPart = (items) => {
        return items?.flatMap((item) => {
            if (treeParts[item[idColumn]]) {
                return [item].concat(expandPart(treeParts[item[idColumn]]));
            }

            return [item];
        });
    };

    return expandPart(treeParts["root"]);
};

export const copyTreeParams = ({ newRows = [], oldRows = [] }) => {
    oldRows.forEach((or) => {
        const newRow = newRows.filter((nr) => isSameRow(nr, or))[0];

        if (newRow && or._treeColumn) {
            newRow._treeColumn = or._treeColumn;
            newRow._treeHasChildren = or._treeHasChildren;
            newRow._treeDepth = or._treeDepth;
            newRow._treeExpanded = or._treeExpanded;
            newRow._treeHidden = or._treeHidden;
        }
    });
};

export const formatCellContent = (column, value) => {
    let result = "";

    switch (column.datatype) {
        case "date":
            result = formatJsonDate(value);
            break;

        case "datetime":
            result = formatJsonDateTime(value);
            break;

        case "datetimeWithSeconds":
            result = formatJsonDateTime(value, "", true, { server: column?.serverTimezoneOffset });
            break;

        case "currency":
            result = formatMoney(value);
            break;

        case "percent":
            result = `${value}%`;
            break;

        default:
            if (value === false) {
                result = "False";
            } else if (value === true) {
                result = "True";
            } else if (Array.isArray(value)) {
                result = value.join(", ");
            } else {
                result = value;
            }

            break;
    }

    return result;
};

/**
 *
 * Converts a filter object to a filter string. Returns empty string by default.
 * @param {{filters: {field: string, value: string}[], logic: string}} filter Search filter
 * @param {{ transform: [{ search: string, replace: string }]}} options Options to transform search values
 * @returns
 */
export const filterObjToString = (filter = undefined, options = undefined) => {
    const transform = options?.transform ?? [];

    let str = "";
    if (filter && filter.filters && filter.filters.length > 0) {
        str = filter.filters
            .map((f) => {
                const value = transform.reduce((result, item) => (result || "").split(item.search).join(item.replace), f.value).trim();

                return `${f.field}=${value}`;
            })
            .join("|");
    }

    return str;
};

/**
 * Compares whether two arrays of column filters contain
 * the same filters. Ignores ordering.
 * @param {*} filterArrA
 * @param {*} filterArrB
 */
export const areFiltersEqual = (filterA, filterB) => {
    // if both of them dont exist, they the same
    if (!filterA && !filterB) {
        return true;
    }

    // if one doesn't exist, then they're different
    if (!filterA || !filterB) {
        return false;
    }

    // using different defaults on get's, so they fail, if both return defaults
    if (
        get(filterA, "filters.length", null) !== get(filterB, "filters.length", undefined) ||
        get(filterA, "logic", null) !== get(filterB, "logic", undefined)
    ) {
        return false;
    }

    // sort filter copies by field names
    const sortedA = filterA.filters.slice().sort((a, b) => a.field.localeCompare(b.field));
    const sortedB = filterB.filters.slice().sort((a, b) => a.field.localeCompare(b.field));

    // find at least one element that doesn't match at same index
    return !sortedA.some((a, i) => {
        return !isEqual(a, sortedB[i]);
    });
};

export const hasCustomFilter = (dataGridId, showedColumnsKeys) => {
    if (!dataGridId) {
        return false;
    }

    const config = getDataGridConfig(dataGridId);
    const filter = getDataGridFilter(dataGridId);
    const columns = getColumns(config);

    const filters = get(filter, "filters", []);

    const isColumnVisible = showedColumnsKeys?.length
        ? (column) => showedColumnsKeys.includes(column.key)
        : (column) => column.hidecolumn !== "true";

    return filters.some((filter) => columns.filter((column) => column.key === filter.field && isColumnVisible(column)).length > 0);
};

/**
 * Check if datagrid filter changed.
 * After data request this flag is reset to false.
 *
 * @param {*} dataGridId - datagrid instance id
 * @returns
 */
export const isFilterChanged = (dataGridId) => {
    if (!dataGridId) {
        return false;
    }

    const config = getDataGridConfig(dataGridId);

    return config?.isFilterChanged ?? false;
};

export const getDefaultFilter = (dataGridId) => {
    const config = getDataGridConfig(dataGridId);
    return (
        config.defaultFilter || {
            filters: [],
        }
    );
};

export const getDataGridFilter = (dataGridId) => {
    const dataGridConfig = getDataGridConfig(dataGridId);

    return dataGridConfig && dataGridConfig.filter;
};

/**
 * Custom grid filter.
 *
 * @param {*} { filter, rows, columns } - filter and unfiltered rows, column and timezone config
 * @returns Filtered rows
 */
export const filterRows = ({ filter, rows, columns = [], timezoneOffset }) => {
    const wildcards = ["*", "%"];
    const filters = (filter && filter.filters) || [];
    const filteredRows = cloneDeep(rows);

    if (filters.length === 0) {
        return filteredRows;
    }

    // Check row against filters
    const matchFilters = (row) => {
        return (
            filters
                .map((f) => {
                    const value = row[f.field];
                    const isDateValue = [
                        dataGridColumnTypes.date,
                        dataGridColumnTypes.dateTime,
                        dataGridColumnTypes.datetimeWithSeconds,
                    ].includes(columns.find((c) => c.key === f.field)?.datatype);

                    if (isDateValue) {
                        const dateValue = jsonDateToDate(value);
                        if (isDate(dateValue)) {
                            const adjustedDate = serverDateToLocal(dateValue, timezoneOffset);
                            const filterValue = jsonDateToDate(f.value);
                            return compareDateWithoutTime(adjustedDate, filterValue) === 0;
                        }
                    }

                    let rowValue = isNil(value) ? "" : value.toString().toLowerCase();
                    let filterValue = (f.value || "").toLowerCase().trim();

                    let filterParts = wildcards.reduce(
                        (result, separator) => {
                            return result.flatMap((part) => part.split(separator));
                        },
                        [filterValue]
                    );

                    // Check exact case
                    if (filterParts.length === 1 && rowValue !== filterParts[0]) {
                        return false;
                    }

                    // Check startsWith case
                    const startsWithPart = filterParts[0];
                    if (filterParts.length > 1 && startsWithPart !== "" && !rowValue.startsWith(startsWithPart)) {
                        return false;
                    }

                    // Check endsWith case
                    const endsWithPart = filterParts[filterParts.length - 1];
                    if (filterParts.length > 1 && endsWithPart !== "" && !rowValue.endsWith(endsWithPart)) {
                        return false;
                    }

                    // Check contains case
                    if (filterParts.length > 2 && filterParts.some((part) => !rowValue.includes(part))) {
                        return false;
                    }

                    // row match
                    return true;
                })
                // Check if all filters match
                .every((match) => match)
        );
    };

    // Mark row with temp property to include in filtered result
    // And expand if it is in tree
    const markRow = (row) => {
        row._match = true;

        if (row._treeColumn) {
            row._treeExpanded = true;
            row._treeHidden = false;
        }
    };

    // Mark filtered row and direct parents in tree
    const markBranch = (index) => {
        // Set filtered row depth
        const baseDepth = filteredRows[index]._treeDepth ?? 0;

        // Set row index and depth for parent search
        let currentIndex = index;
        let currentDepth = baseDepth;

        // Search up to first row and mark parents
        while (currentIndex > -1 && currentDepth > -1) {
            const rowDepth = filteredRows[currentIndex]?._treeDepth ?? 0;
            if (rowDepth === currentDepth) {
                markRow(filteredRows[currentIndex]);
                currentDepth--;
            }

            currentIndex--;
        }

        // Set row index and depth for children search
        currentIndex = index + 1;
        currentDepth = baseDepth + 1;

        // Search for and mark child rows
        while (currentDepth > baseDepth) {
            currentDepth = filteredRows[currentIndex]?._treeDepth ?? 0;
            if (currentDepth > baseDepth) {
                markRow(filteredRows[currentIndex]);
            }

            currentIndex++;
        }
    };

    filteredRows.forEach((row, index) => {
        const canKeep = matchFilters(row);
        if (canKeep) {
            markBranch(index);
        }
    });

    // Return filtered rows
    return filteredRows.filter((r) => r._match).map((r) => omit(r, ["_match"]));
};

/**
 * Custom grid sort.
 *
 * @param {*} { sort, rows } - sort config and grid current rows
 */
export const sortRows = ({ sort = [], rows, columns }) => {
    const isColumnTypeNumber = columns && columns.find((c) => c.key === sort[0]?.field && c.datatype === "number");

    const fields = sort.map(
        (i) => (row) =>
            isColumnTypeNumber ? toNumber(row[i.field]) : isNumber(row[i.field]) ? row[i.field] : (row[i.field] ?? "").toLowerCase()
    );

    const direction = sort.map((i) => i.dir);

    return orderBy(rows, fields, direction);
};

/**
 * Returns an array of objects containing data from the specified rows and columns, formatted for CSV export.
 * @param {Array} rows - Datagrid data.
 * @param {Array} columns - Datagrid columns.
 * @returns {Array} An array of objects containing data from the specified rows and columns, formatted for CSV export.
 */
export const getDataGridDataForExport = (rows, columns) => {
    let result = [];

    if (isNil(rows) || rows.length === 0) {
        let itemDetails = {};

        columns.forEach((i) => {
            itemDetails[i.name] = "";
        });

        result.push(itemDetails);
    } else {
        toArray(rows).forEach((item) => {
            let itemDetails = {};

            columns.forEach((i) => {
                let itemValue;

                switch (i.datatype) {
                    case "date":
                        itemValue = formatJsonDate(item[i.key]);
                        break;

                    case "datetime":
                        itemValue = formatJsonDateTime(item[i.key]);
                        break;

                    case "datetimeWithSeconds":
                        itemValue = formatJsonDateTime(item[i.key], "", true, {
                            server: i?.serverTimezoneOffset,
                        });
                        break;

                    default:
                        itemValue = item[i.key] || "";

                        if (isString(item[i.key])) {
                            itemValue = stripHtmlForDataGrid(item[i.key]);
                        }
                        break;
                }

                if (i.key === applicationFilesGridColumnKeys.security) {
                    itemValue = item[i.key] === FileSecurity.PRIVATE ? "Private" : "Public";
                }

                if (i.key === logsSystemErrorsGridColumnKeys.url) {
                    try {
                        itemValue = item[i.key];
                    } catch (error) {
                        itemValue = item[i.key];
                    }
                }

                itemDetails[i.name] = itemValue;
            });

            result.push(itemDetails);
        });
    }

    return result;
};
