import * as actionType from "../actionTypes";
import { download } from "../../components/utils/files";

export function filesMiddleware({ dispatch, getState }) {
    return (next) => (action) => {
        switch (action.type) {
            case actionType.FILES_DOWNLOAD_SUCCESS:
                downloadFile(action);
                break;

            case actionType.FILES_PREVIEW_SUCCESS:
                previewFile(action);
                break;

            default:
                break;
        }

        return next(action);
    };

    async function downloadFile(action) {
        const { blob, fileName } = action.data;

        download({ blob, fileName });
    }

    async function previewFile(action) {
        const { blob, fileName } = action.data;

        if (fileName && blob) {
            const container = action.passThroughData.key;
            const dataUrl = window.URL.createObjectURL(blob);

            if (container) {
                container.data = dataUrl;
                const link = container.querySelector(".file-viewer__download-file");

                if (link) {
                    link.href = dataUrl;
                    link.download = fileName;
                    link.target = "_blank";
                }
            } else {
                // IE11 support
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // TODO: ? This does not use filename for save and
                    // does not work if adBlocker is enabled
                    window.open(dataUrl);
                }

                setTimeout(function () {
                    window.URL.revokeObjectURL(dataUrl);
                }, 100);
            }
        }
    }
}
