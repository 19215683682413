import React, { memo } from "react";
import { get } from "lodash";
import { useProgram, useProgramRights } from "../../../../store/resources/useResource";
import { systemUserRights } from "components/utils/user";

const TabContentForProgram = memo(({ view }) => {
    const programNumber = get(view, `props.programNumber`);
    const [programRights = []] = useProgramRights({ programNumber });
    const canViewProgram = programRights.includes(systemUserRights.VISIONDSM_VIEW_PROGRAM);

    const [program] = useProgram({ programNumber, forced: false, canViewProgram });

    let contentItems = [
        {
            label: "Utility",
            value: program?.utility,
        },
        {
            label: "Program",
            value: program?.program ?? programNumber,
        },
    ];

    return (
        <div className="tab-content">
            {contentItems.map((item) => (
                <div key={item.label} className="tab-content-item">
                    <div className="title">{item.label}</div>
                    <div className="value">{item.value}</div>
                </div>
            ))}
        </div>
    );
});

export default TabContentForProgram;
