import React, { useCallback, memo, useState, useRef, useEffect } from "react";
import { onUpdateContact, onCreateContact } from "./ContactsUtils";
import { ContactsPanelContext } from ".";
import { submitByRef } from "components/utils/form";

import EditContact from "components/ui/Dashboard/Panel/Project/ContactsPanel/EditContact";
import SideNavContent from "components/ui/SideNav/SideNavContent";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import SideNavFooter from "components/ui/SideNav/SideNavFooter";
import Button from "components/ui/Button";
import { systemUserRights } from "components/utils/user";
import { useProgramRights } from "store/resources/useResource";

export const ContactEditSidePanel = memo(
    ({
        isNew,
        isAssigned,
        applicationNumber,
        programNumber,
        utilityNumber,
        contact,
        title,
        icon,
        premiseContact,
        primaryContact,
        contractorContact,
        onSuccess,
        onClose,
        sidePanel,
    }) => {
        const formRef = useRef();

        const [isSubmitting, setIsSubmitting] = useState(false);
        const [programRights = []] = useProgramRights({ programNumber });
        const submitText = isSubmitting ? "Saving..." : "Save";

        const onSubmit = useCallback(
            (formData) => {
                setIsSubmitting(true);

                if (isNew) {
                    onCreateContact({
                        applicationNumber,
                        contact: formData,
                        refreshContacts: !isAssigned,
                        onSuccess: (action) => {
                            if (onSuccess) {
                                onSuccess(action);
                            } else {
                                sidePanel.close();
                            }
                        },
                        onError: () => setIsSubmitting(false),
                    });
                } else {
                    onUpdateContact({
                        applicationNumber,
                        contact: formData,
                        onSuccess: sidePanel.close(),
                        onError: () => setIsSubmitting(false),
                    });
                }
            },
            [applicationNumber, isNew, isAssigned, onSuccess, sidePanel]
        );

        const onSave = useCallback(() => {
            submitByRef(formRef);
        }, []);

        const onCancel = useCallback(() => {
            onClose();
        }, [onClose]);

        useEffect(() => {
            sidePanel.setForm(formRef);
        }, [sidePanel]);

        const canSearchCIS = programRights.includes(systemUserRights.VISIONDSM_CIS_SEARCH);

        return (
            <ContactsPanelContext.Provider
                value={{
                    utilityNumber,
                    programNumber,
                    premiseContact,
                    primaryContact,
                    contractorContact,
                    canSearchCIS,
                }}
            >
                <SideNavContent>
                    <SideNavHeader title={title} leadBlockIcon={icon} smallHeader onClose={onClose} />
                    <SideNavBody className="flex-one-in-column">
                        <EditContact
                            applicationNumber={applicationNumber}
                            formRef={formRef}
                            isNew={isNew}
                            initialValues={contact}
                            isSubmitting={isSubmitting}
                            onSubmit={onSubmit}
                        />
                    </SideNavBody>
                    <SideNavFooter setPrimaryButton>
                        <Button primary disabled={isSubmitting} onClick={onSave}>
                            {submitText}
                        </Button>
                        {!isSubmitting && <Button onClick={onCancel}>Cancel</Button>}
                    </SideNavFooter>
                </SideNavContent>
            </ContactsPanelContext.Provider>
        );
    }
);
