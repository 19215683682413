import { isChildProgram } from "components/views/ProgramView/utils";
import React, { memo } from "react";
import IconWithLabel from "../../../../Icons/IconWithLabel";

const LookupTableControls = memo(({ onAdd, onDownload, onUpload, showAddForm, programNumber }) => {
    return (
        <div className="grid-controls flex-row">
            <span className="flex-one" />
            <IconWithLabel
                disabled={isChildProgram({ programNumber })}
                icon={showAddForm ? "shevron-circle-down-filled" : "plus"}
                onClick={onAdd}
            >
                Add Item
            </IconWithLabel>
            <IconWithLabel disabled={isChildProgram({ programNumber })} icon="upload" onClick={onUpload}>
                Upload Items
            </IconWithLabel>
            <IconWithLabel withHandMadeIcon onClick={onDownload}>
                Download Items in CSV
            </IconWithLabel>
        </div>
    );
});

export default LookupTableControls;
