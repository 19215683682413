import React from "react";

const EmailLink = ({ email }) => {
    if (!email) {
        return null;
    }

    return (
        <a className="email-link" href={`mailto:${email}`} title={email}>
            {email}
        </a>
    );
};

export default EmailLink;
