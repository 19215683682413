import React from "react";
import IconWrap from "../Icons";
import ActionLabel from "../Label/ActionLabel";

import "./Breadcrumbs.scss";

const Breadcrumbs = ({ title, childTitle, onClick }: BreadcrumbsProps) => {
    return (
        <div className="title-breadcrumbs">
            <ActionLabel onClick={onClick} className="title-breadcrumbs__item link" title={undefined}>
                {title}
            </ActionLabel>
            <IconWrap className="title-breadcrumbs__item" icon="shevron-small-right" />
            <div className="title-breadcrumbs__item">{childTitle}</div>
        </div>
    );
};

interface BreadcrumbsProps {
    title: string;
    childTitle: string;
    onClick: () => void;
}

export default Breadcrumbs;
