import React, { memo } from "react";
import cn from "classnames";
import { Draggable } from "react-beautiful-dnd";
import DragHandle from "components/ui/DragHandle";

import IconWrap from "../../Icons";

import "./ToolList.scss";
import { stripHtml } from "components/utils/string";

const ToolListItem = memo(({ item, index, activeItem, isDraggable, isSticky, disabledItemTooltip, onClick, onPin }) => {
    const onItemClick = (event, tool) => {
        if (event.target.classList.contains("pinner") && !item.iconLeft) {
            onPin(tool);
        } else {
            onClick(tool);
        }
    };

    const onKeyDown = (tool) => (event) => {
        if (event.key === "Enter") {
            onClick(tool);
        }
    };

    const withoutPin = item.pinned === undefined;
    const pinIcon = "pinner " + (item.pinned ? "pinned pin-filled" : "pin-empty");
    const pinTitle = item.pinned ? "Unpin from the Sidebar" : "Pin to the Sidebar";

    const className = cn("tool", {
        active: item.id === (activeItem && activeItem.id),
        "tool-pinned": item.pinned || item.iconLeft,
        "without-pin": withoutPin && !item.iconLeft,
        draggable: isDraggable,
        sticky: item.isSticky,
        "tool-without-text": item.withoutText,
        disabled: item.disabled,
    });
    const stripHTMLfromTitle = stripHtml(item.title);

    const content = (
        <>
            <div className="tool-info">
                {item.icon && <IconWrap className="tool-info-icon" icon={item.icon} title={stripHTMLfromTitle} />}
                {!item.withoutText && <span className="tool-info-text">{stripHTMLfromTitle}</span>}
            </div>
            {item.iconLeft && (
                <IconWrap
                    iconWrap={item.iconLeft}
                    title={item.iconLeftTitle}
                    className={item.iconLeftClassName}
                    onClick={(e) => onItemClick(e, item)}
                />
            )}
            {!withoutPin && <IconWrap iconWrap={pinIcon} title={pinTitle} onClick={(e) => onItemClick(e, item)} />}
            {item.disabled && <IconWrap iconWrapSmall icon="block-not-interested-empty" title={disabledItemTooltip} />}
        </>
    );

    if (isDraggable) {
        return (
            <Draggable draggableId={item.id} index={index} isDragDisabled={item.isDragDisabled}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={cn(className, {
                            "is-dragging-item": snapshot.isDragging,
                        })}
                        onClick={(e) => onItemClick(e, item)}
                        tabIndex="0"
                        onKeyDown={onKeyDown(item)}
                    >
                        {!item.isDragDisabled && <DragHandle {...provided.dragHandleProps} dragHorizontal disabled={item.isDragDisabled} />}
                        {content}
                    </div>
                )}
            </Draggable>
        );
    }

    return (
        <div className={className} onClick={(e) => onItemClick(e, item)} tabIndex="0" onKeyDown={onKeyDown(item)}>
            {content}
        </div>
    );
});

export default ToolListItem;
