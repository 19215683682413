import React from "react";
import { GoogleMap, HeatmapLayer } from "@react-google-maps/api";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { PortalZipCodeCount } from "../../types";
import {} from "lodash";
import { MAP_STYLES } from "../../utils";
import { clusterRenderer, useMapLoader } from "components/utils/map";

const DEFAULT_CENTER = { lat: 39.5, lng: -98.35 };
const DEFAULT_ZOOM = 3;

export const AppLocationsMap: React.FC<LocationsMapProps> = (props) => {
    const { isLoaded } = useMapLoader();

    const { zipCodes } = props;

    const [locations, setLocations] = React.useState<google.maps.visualization.WeightedLocation[]>([]);

    const onLoad = async (map: google.maps.Map) => {
        map.setZoom(DEFAULT_ZOOM);
        map.setCenter(DEFAULT_CENTER);

        const weightedLocations = [] as google.maps.visualization.WeightedLocation[];

        await Promise.all(
            zipCodes.map(async (item) => {
                return new Promise((resolve) => {
                    try {
                        if (item.lat && item.lng) {
                            const weightedLocation = {
                                location: new google.maps.LatLng({ lat: item.lat, lng: item.lng }),
                                weight: item.applicationCount,
                            };
                            weightedLocations.push(weightedLocation);
                        }
                        resolve(1);
                    } catch (err) {
                        resolve(0);
                    }
                });
            })
        );

        const markers = weightedLocations.map((item) => {
            return new window.google.maps.Marker({
                position: item.location,
                label: {
                    color: "rgba(255,255,255,0.9)",
                    fontSize: "12px",
                    fontWeight: "bold",
                    text: item.weight.toString(),
                },
            });
        });

        new MarkerClusterer({
            map,
            markers,
            renderer: {
                render: clusterRenderer,
            },
        });

        setLocations(weightedLocations);

        // find the most weighted location and set center to that
        const mostWeighted = weightedLocations.reduce<google.maps.visualization.WeightedLocation | null>(
            (prev, current) => (prev && prev.weight > current.weight ? prev : current),
            null
        );

        if (mostWeighted?.location) {
            map.setCenter(mostWeighted.location);
            map.setZoom(8);
        }
    };

    if (!isLoaded) return <div style={{ height: "100%" }} />;

    return (
        <GoogleMap onLoad={onLoad} options={{ styles: MAP_STYLES }} mapContainerStyle={{ height: "100%", width: "100%" }}>
            <HeatmapLayer data={locations} options={{ radius: 20 }} />
        </GoogleMap>
    );
};

interface LocationsMapProps {
    zipCodes: PortalZipCodeCount[];
}
