import { store } from "../configureStore";
import { optimisticUpdateItem } from "./actions";
import { getResourceKey, getApplicationFormDetailsResourceParams } from "store/configureResources";

export const resetResource = ({ resourceName, resourceId, key }) => {
    const state = store.getState();
    const resourceKey = getResourceKey({ resourceId, key });

    if (state.resources[resourceName]?.itemsById[resourceKey]) {
        store.dispatch(
            optimisticUpdateItem({
                resourceName,
                resourceId: resourceKey,
                value: undefined,
            })
        );
    }
};

export const resetApplicationFormDetails = ({ applicationNumber, applicationFormId }) => {
    resetResource({
        ...getApplicationFormDetailsResourceParams({
            applicationNumber,
            applicationFormId,
        }),
    });
};
