import React, { memo, useCallback } from "react";
import ToolList from "../List/ToolList";

import "./DashboardTools.scss";

const DashboardTools = memo(({ tools, activeTool, onToolPin, onToolActivate }) => {
    const onToolClick = useCallback(
        (tool) => {
            onToolActivate && onToolActivate(tool);
        },
        [onToolActivate]
    );

    if (tools.length === 0) {
        return null;
    }

    return (
        <div className="dashboard-tools">
            <div className="main-grid-wrap responsive">
                <ToolList items={tools} activeTool={activeTool} onClick={onToolClick} onPin={onToolPin} loading={false} />
            </div>
        </div>
    );
});

export default DashboardTools;
