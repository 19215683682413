import React from "react";

import DashboardPanel from "../DashboardPanel";
import { ScanQueueMainPanel } from "../../../../views/ScanQueue";

import "./ScannedAppsPanel.scss";

const ScannedAppsPanel = ({ panel }) => {
    return (
        <DashboardPanel title={panel.name} className="scanned-apps-panel">
            <ScanQueueMainPanel forDashboard scanControls />
        </DashboardPanel>
    );
};

export default React.memo(ScannedAppsPanel);
