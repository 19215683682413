export enum MarginCustomPropertyName {
    MarginType = "MarginType",
    MarginAllSides = "MarginAllSides",
    MarginSideBySide = "MarginSideBySide",
}

export enum MarginSides {
    SideBySide = "side-by-side",
    AllSides = "all-sides",
}

export enum MarginSideName {
    Top = "top",
    Right = "right",
    Bottom = "bottom",
    Left = "left",
    AllSides = "all-sides",
}

export interface MarginSideProps {
    side: MarginSideName;
    selectedValue: string;
    onSelect: (size: string) => void;
}

export interface MarginSideBySideValue {
    [MarginSideName.Top]: string;
    [MarginSideName.Right]: string;
    [MarginSideName.Bottom]: string;
    [MarginSideName.Left]: string;
}

export interface MarginCustomPropertiesValue {
    [MarginCustomPropertyName.MarginType]: MarginSides;
    [MarginCustomPropertyName.MarginAllSides]: string;
    [MarginCustomPropertyName.MarginSideBySide]: MarginSideBySideValue;
}
