import { useResource } from "../../../store/resources/useResource";

export const useReference = (type, outputType = "id") => {
    return useResource({
        resourceName: "reference",
        key: type,
        query: {
            type,
            outputType,
        },
    });
};
