import React, { useEffect, useState, memo } from "react";
import { useDispatch } from "react-redux";

import { getResource } from "../../../store/resources/actions";
import { getApplicationContactStoredCisDetailsResourceParams } from "../../../store/configureResources";

import WaitIcon from "../WaitIcon";
import RawHtml from "../RawHtml";
import InfoList from "../List/InfoList";

import "./style.scss";
import "../DataGrid/GridDetailsPanel.scss";

const ApplicationContactStoredCisDetails = memo(({ applicationNumber, contactNumber }) => {
    const dispatch = useDispatch();

    const [customerDetails, setCustomerDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        dispatch(
            getResource({
                ...getApplicationContactStoredCisDetailsResourceParams({
                    applicationNumber,
                    contactNumber,
                }),
                onSuccess: (action) => {
                    setCustomerDetails(action.data?.cis || action.data?.CIS || []);
                },
                onComplete: () => {
                    setIsLoading(false);
                },
            })
        );
    }, [applicationNumber, contactNumber, dispatch]);

    if (isLoading) {
        return <WaitIcon />;
    }

    return (
        <div className="cis_details">
            <InfoList
                items={Object.keys(customerDetails).map((i, index) => ({
                    label: i,
                    value: <RawHtml>{customerDetails[i] && customerDetails[i].length !== 0 ? customerDetails[i] : "-"}</RawHtml>,
                }))}
            />
        </div>
    );
});

export default ApplicationContactStoredCisDetails;
