import React, { useCallback, useState, useRef, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { range } from "lodash";
import uuidv1 from "uuid/v1";

import { createResource } from "../../../../../../store/resources/actions";
import { useInvoiceDocument, useResource, useFastTags } from "../../../../../../store/resources/useResource";
import { referenceTypes } from "../../../../Reference/referenceTypes";
import { useReference } from "../../../../Reference/useReference";
import { referenceToAnyOf, listToAnyOf, submitByRef } from "../../../../../utils/form";

import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import Button from "../../../../Button";

import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import UserGroupsSelector from "../../../../UserGroupsSelector";
import { refreshInvoiceDocumentTab } from "../../../../../../store/resources/refreshResource";
import { DEFAULT_FOLDER } from "components/utils/files";
import { useIsMobile } from "components/utils/useIsMobile";
import { refreshGrid } from "store/dataGrid/refreshGrid";
import { getInvoiceDocumentsGridId } from "../../Contract/InvoicesPanel/utils";

const STEP_TASK_FORM = "STEP_TASK_FORM";
const STEP_ASSIGN_GROUPS = "STEP_ASSIGN_GROUPS";

const AdHocTaskForm = memo(({ utilityNumber, documentNumber, contractNumber, onClose, sidePanel, entityType = "invoice" }) => {
    const [stepValues, setStepValues] = useState(null);
    const [step, setStep] = useState(STEP_TASK_FORM);
    const [isSubmitting, setSubmitting] = useState(false);
    const [isTaskContentWithHtmlEditor, setIsTaskContentWithHtmlEditor] = useState(false);
    const isMobile = useIsMobile();
    const isLocked = useSelector((state) => state.resources.invoiceDocument.itemsById[documentNumber].islocked);
    const dataGridId = getInvoiceDocumentsGridId({ contractNumber });

    const dispatch = useDispatch();
    const folders = useSelector((state) => state.resources.fileFolders.items);

    const [invoiceDetails, isLoadingInvoiceDetails] = useInvoiceDocument({
        documentNumber,
    });

    const [targetStatuses = [], isLoadingTargetStatuses] = useResource({
        resourceName: "utilitiesContractsWorkflowStatuses",
        key: contractNumber,
        path: {
            utilityNumber,
            contractNumber,
        },
    });

    const [procedureList = [], isLoadingProcedureList] = useResource({
        resourceName: "referenceFilter",
        key: `${utilityNumber}-ICF_WF_SPs`,
        query: {
            type: "ICF_WF_SPs",
            outputType: "string",
            filterType: "utility",
            entityNumber: utilityNumber,
        },
        transform: (data) => {
            return referenceToAnyOf({
                list: data?.referenceResults ?? [],
                type: "string",
            });
        },
    });

    const [qcTypes = []] = useReference(referenceTypes.qcType);
    const [invoiceFlags = []] = useReference(referenceTypes.invoiceFlagWorkflow);
    const [fastTags, isLoadingFastTags] = useFastTags({ entityNumber: documentNumber, entityType });
    const wfNumber = invoiceDetails ? invoiceDetails.wfNumber : null;

    const getSchema = useCallback(() => {
        let foldersEnum = folders.map((f) => f.value);
        if (folders.length === 0) {
            foldersEnum = [DEFAULT_FOLDER];
        }

        const stepTypeList = isLocked
            ? [
                  {
                      title: "Clear Flag",
                      enum: [12],
                  },
              ]
            : [
                  {
                      title: "Send Email",
                      enum: [1],
                  },
                  {
                      title: "Document Upload",
                      enum: [2],
                  },
                  {
                      title: "Send Letter",
                      enum: [3],
                  },
                  {
                      title: "Perform Task",
                      enum: [4],
                  },
                  {
                      title: "Flag Record",
                      enum: [7],
                  },
                  {
                      title: "General Procedure",
                      enum: [8],
                  },
                  {
                      title: "Approval",
                      enum: [10],
                  },
                  {
                      title: "Clear Flag",
                      enum: [12],
                  },
              ];

        const qcTypeList = referenceToAnyOf({ list: qcTypes });
        const qcPercentList = range(1, 101);
        const flagTypeList = referenceToAnyOf({
            list: isLocked ? invoiceFlags.filter((item) => item.val === "540") : invoiceFlags,
        });

        const targetStatusList = listToAnyOf({
            list: targetStatuses,
            map: (item) => ({
                title: item.status,
                enum: [item.workflowNumber],
            }),
        });

        return {
            type: "object",
            required: ["step", "wF_Step_TypeID", "term"],
            properties: {
                step: {
                    type: "string",
                    title: "Item Step",
                },
                ...(!isMobile && {
                    constraintRule: {
                        type: "string",
                        title: "Constraint Rule",
                        default: "_current_stored_value_",
                        anyOf: [
                            {
                                type: "string",
                                title: "Current Stored Value",
                                enum: ["_current_stored_value_"],
                            },
                            {
                                type: "string",
                                title: "completeToHere()",
                                enum: ["completeToHere()"],
                            },
                        ],
                    },
                }),
                wF_Step_TypeID: {
                    type: "integer",
                    title: "Step Type",
                    anyOf: stepTypeList,
                },
                ...(!isMobile && {
                    autoCompleteRule: {
                        type: "string",
                        title: "Autocomplete Rule",
                        default: "_current_stored_value_",
                        anyOf: [
                            {
                                type: "string",
                                title: "Current Stored Value",
                                enum: ["_current_stored_value_"],
                            },
                            {
                                type: "string",
                                title: "True",
                                enum: ["true"],
                            },
                        ],
                    },
                }),
                term: {
                    type: "integer",
                    title: "Term (days)",
                    default: 0,
                    enum: range(-1, 366),
                },
                ...(isMobile && {
                    constraintRule: {
                        type: "string",
                        title: "Constraint Rule",
                        default: "_current_stored_value_",
                        anyOf: [
                            {
                                type: "string",
                                title: "Current Stored Value",
                                enum: ["_current_stored_value_"],
                            },
                            {
                                type: "string",
                                title: "completeToHere()",
                                enum: ["completeToHere()"],
                            },
                        ],
                    },
                    autoCompleteRule: {
                        type: "string",
                        title: "Autocomplete Rule",
                        default: "_current_stored_value_",
                        anyOf: [
                            {
                                type: "string",
                                title: "Current Stored Value",
                                enum: ["_current_stored_value_"],
                            },
                            {
                                type: "string",
                                title: "True",
                                enum: ["true"],
                            },
                        ],
                    },
                }),
            },
            dependencies: {
                autoCompleteRule: {
                    oneOf: [
                        {
                            properties: {
                                autoCompleteRule: {
                                    enum: ["_current_stored_value_"],
                                },
                            },
                        },
                        {
                            properties: {
                                autoCompleteRule: {
                                    enum: ["true"],
                                },
                                newStatus: {
                                    type: "string",
                                    title: "Target Status",
                                    anyOf: targetStatusList,
                                },
                            },
                        },
                    ],
                },
                wF_Step_TypeID: {
                    oneOf: [
                        {
                            properties: {
                                wF_Step_TypeID: {
                                    enum: [1],
                                },
                                emailSubject: {
                                    type: "string",
                                    title: "Email Subject Line",
                                },
                                content: {
                                    type: "string",
                                    title: "Content",
                                },
                            },
                        },
                        {
                            properties: {
                                wF_Step_TypeID: {
                                    enum: [2],
                                },
                                folderName: {
                                    type: "string",
                                    title: "Target Folder Name",
                                    default: folders.length === 0 ? DEFAULT_FOLDER : undefined,
                                    enum: foldersEnum,
                                },
                            },
                        },
                        {
                            properties: {
                                wF_Step_TypeID: {
                                    enum: [3],
                                },
                                content: {
                                    type: "string",
                                    title: "Content",
                                },
                            },
                        },
                        {
                            properties: {
                                wF_Step_TypeID: {
                                    enum: [4],
                                },
                                content: {
                                    type: "string",
                                    title: "Content",
                                },
                            },
                        },
                        {
                            properties: {
                                wF_Step_TypeID: {
                                    enum: [6],
                                },
                                qcType: {
                                    type: "integer",
                                    title: "QC Type",
                                    anyOf: qcTypeList,
                                },
                                qcPercent: {
                                    type: "integer",
                                    title: "QC Threshold %",
                                    enum: qcPercentList,
                                },
                                content: {
                                    type: "string",
                                    title: "Content",
                                },
                            },
                        },
                        {
                            properties: {
                                wF_Step_TypeID: {
                                    enum: [7],
                                },
                                appCd: {
                                    type: "integer",
                                    title: "Flag Type",
                                    anyOf: flagTypeList,
                                },
                            },
                            dependencies: {
                                appCd: {
                                    oneOf: [
                                        {
                                            properties: {
                                                appCd: {
                                                    enum: [202],
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                        {
                            required: ["proc"],
                            properties: {
                                wF_Step_TypeID: {
                                    enum: [8],
                                },
                                proc: {
                                    type: "string",
                                    title: "Procedure Code",
                                    anyOf: procedureList,
                                },
                                emailSubject: {
                                    type: "string",
                                    title: "Email Subject Line",
                                },
                                content: {
                                    type: "string",
                                    title: "Content",
                                },
                            },
                        },
                        {
                            properties: {
                                wF_Step_TypeID: {
                                    enum: [10],
                                },
                                content: {
                                    type: "string",
                                    title: "Content",
                                },
                            },
                        },
                        {
                            properties: {
                                wF_Step_TypeID: {
                                    enum: [11],
                                },
                                qcType: {
                                    type: "integer",
                                    title: "QC Type",
                                    anyOf: qcTypeList,
                                },
                                qcPercent: {
                                    type: "integer",
                                    title: "QC Threshold %",
                                    enum: qcPercentList,
                                },
                                content: {
                                    type: "string",
                                    title: "Content",
                                },
                            },
                        },
                        {
                            properties: {
                                wF_Step_TypeID: {
                                    enum: [12],
                                },
                                appCd: {
                                    type: "integer",
                                    title: "Flag Type",
                                    anyOf: flagTypeList,
                                },
                            },
                            dependencies: {
                                appCd: {
                                    oneOf: [
                                        {
                                            properties: {
                                                appCd: {
                                                    enum: [202],
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
            },
        };
    }, [folders, invoiceFlags, qcTypes, procedureList, targetStatuses, isMobile, isLocked]);

    const uiSchema = {
        "ui:rootFieldId": "adhoc-task",
        classNames: "inline-form columns-2",
        constraintRule: {
            "ui:emptyItem": true,
        },
        autoCompleteRule: {
            classNames: isMobile ? "autocomplete-rule-mobile fill-width" : "",
            "ui:emptyItem": true,
        },
        term: {
            classNames: !isMobile ? "term fill-width" : "",
        },
        content: {
            classNames: "fill-width",
            "ui:widget": isTaskContentWithHtmlEditor ? "HtmlEditorWidget" : "textarea",
            "ui:options": {
                fastTags,
                isLoadingFastTags,
            },
        },
        newStatus: {
            "ui:placeholder": isLoadingTargetStatuses ? "Loading..." : undefined,
        },
        proc: {
            "ui:placeholder": isLoadingProcedureList ? "Loading..." : "-- SELECT --",
        },
    };

    const onChange = useCallback((form) => {
        setIsTaskContentWithHtmlEditor([1, 3].includes(form.formData.wF_Step_TypeID));
    }, []);

    const onStepSubmit = useCallback(
        (formData) => {
            const additionalAttributes = [
                "emailSubject",
                "folderName",
                "qcType",
                "qcPercent",
                "appCd",
                "formpgnum",
                "proc",
                "appFieldID",
            ].reduce((result, attrName) => {
                if (formData[attrName]) {
                    result.push(`${attrName}=${formData[attrName]}`);
                }

                return result;
            }, []);

            // Remove placeholder string value to bypass dropdown not selecting empty value
            const updatedFormData = { ...formData };
            if (formData["autoCompleteRule"] === "_current_stored_value_") {
                updatedFormData.autoCompleteRule = "";
            }
            if (formData["constraintRule"] === "_current_stored_value_") {
                updatedFormData.constraintRule = "";
            }

            setStepValues({
                ...updatedFormData,
                targetEntityType: "invoice",
                targetEntityId: documentNumber,
                stepNumber: uuidv1().toUpperCase(),
                content: formData.content || "",
                customStep: "y",
                workflowstatus: 151,
                additionalAttributes,
            });
            setStep(STEP_ASSIGN_GROUPS);
        },
        [documentNumber]
    );

    const onStepCreated = () => {
        onClose();
        refreshInvoiceDocumentTab({ documentNumber });
        refreshGrid({ dataGridId });
    };

    const onGroupAssignmentsSubmit = (groups) => {
        setSubmitting(true);

        dispatch(
            createResource({
                resourceName: "utilitiesContractsWorkflowStep",
                path: {
                    utilityNumber,
                    contractNumber,
                    wfNumber,
                },
                body: {
                    groupAssignments: groups.map((g) => g.groupNumber),
                    ...stepValues,
                },
                onSuccess: onStepCreated,
                onError: () => {
                    setSubmitting(false);
                },
            })
        );
    };

    const onTaskFormStepSubmitButtonClicked = () => {
        submitByRef(formRef);
    };

    const onBack = () => {
        const rules = {};
        if (stepValues.autoCompleteRule === "") {
            rules.autoCompleteRule = "_current_stored_value_";
        }
        if (stepValues.constraintRule === "") {
            rules.constraintRule = "_current_stored_value_";
        }

        setStepValues({
            ...stepValues,
            ...rules,
        });
        setStep(STEP_TASK_FORM);
    };
    const formRef = useRef();

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    // Remove CK Editor from the DOM when isTaskContentWithHtmlEditor is changed to false
    useEffect(() => {
        if (!isTaskContentWithHtmlEditor) {
            const editorElement = document.querySelector(".sidenav-content .cke");
            if (editorElement) {
                editorElement.remove();
                formRef.current.setFormData({
                    ...formRef.current.state.formData,
                    content: "",
                });
            }
        }
    }, [isTaskContentWithHtmlEditor]);

    return (
        <SideNavContent>
            <SideNavHeader
                title={step !== STEP_ASSIGN_GROUPS ? "Add Ad-hoc Task" : "Add Ad-hoc Task > Assign Groups"}
                leadBlockIcon="plus"
                smallHeader
                onClose={onClose}
            />
            {step === STEP_TASK_FORM && (
                <>
                    <SideNavBody className="flex-one-in-column">
                        <JsonSchemaForm
                            formRef={formRef}
                            schema={getSchema()}
                            uiSchema={uiSchema}
                            initialValues={stepValues}
                            onSubmit={onStepSubmit}
                            onChange={onChange}
                            submitText={"Assign Groups"}
                            disabled={isSubmitting}
                            noReset
                            noCancel
                            noSubmit
                        />
                    </SideNavBody>
                    <SideNavFooter justifyCenter>
                        <Button primary disabled={isSubmitting} onClick={onTaskFormStepSubmitButtonClicked}>
                            Assign Groups
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </SideNavFooter>
                </>
            )}
            <UserGroupsSelector
                utilityNumber={utilityNumber}
                queryType="utility"
                groupType="Invoice Users"
                onSubmit={onGroupAssignmentsSubmit}
                submitText={isSubmitting ? "Creating..." : "Create Task"}
                icon={isSubmitting ? "" : "plus"}
                disabled={isSubmitting || isLoadingInvoiceDetails}
                onBack={onBack}
                onClose={onClose}
                hidden={step !== STEP_ASSIGN_GROUPS} // hide it, so selected groups remain when going back
            />
        </SideNavContent>
    );
});

export default AdHocTaskForm;
