import React, { memo } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";

import PowerBI from "../../../../../views/PowerBI";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";

const PortalDashboardPanel = memo(({ panel }) => {
    const applicationNumber = get(panel, "data.applicationNumber");
    const portalDashboard = useSelector((state) =>
        get(state, `resources.applicationDetails.itemsById[${applicationNumber}].portalDashboard`)
    );

    return (
        <div className="panel snapshot">
            <PanelHeaderLarge title={panel.name} />
            <div className="panel flex-column panel-report">
                {portalDashboard && <PowerBI reportID={portalDashboard} entityTypeID={3} entityNumber={applicationNumber} />}
            </div>
        </div>
    );
});

export default PortalDashboardPanel;
