import React, { memo } from "react";

import { useResource } from "../../../../../../store/resources/useResource";
import { formatJsonDateTime } from "../../../../../utils/date";

import WaitIcon from "../../../../WaitIcon";
import CalculationHeaderForm from "../CalculationHeaderForm";

const SingleAssignmentHeader = memo(
    ({ utilityNumber, programNumber, calculationType, userName, revisionDate, calculationNumber, sidePanel }) => {
        const [calculation, calculationIsLoading] = useResource({
            resourceName: "calculations",
            resourceId: calculationNumber,
        });

        if (calculationIsLoading) {
            return <WaitIcon />;
        }

        const headerValues = {
            header: {
                calculationType: calculationType.toUpperCase(),
                user: userName,
                revisionDate: formatJsonDateTime(revisionDate),
            },
            values: {
                calculationName: calculation ? calculation.calculationName : "",
                processQueue: calculation ? calculation.processQueue : "",
                targetAttrId: calculation ? calculation.targetAttrId : "0",
            },
        };

        return (
            <CalculationHeaderForm
                utilityNumber={utilityNumber}
                programNumber={programNumber}
                initialValues={headerValues}
                viewOnly
                sidePanel={sidePanel}
            />
        );
    }
);

export default SingleAssignmentHeader;
