import React from "react";
import cn from "classnames";

import CounterBox from "../CounterBox";
import IconWithLabel from "../Icons/IconWithLabel";

import "./ListHeader.scss";

export const ListHeader = ({ className, count, title, addActionTitle, onAdd, addActionDisabled }) => {
    return (
        <div className={cn(className, "flex-row align-center")}>
            <CounterBox>{count}</CounterBox>
            <span>{title}</span>
            <span className="flex-one"></span>
            <IconWithLabel icon="plus" disabled={addActionDisabled} onClick={onAdd}>
                {addActionTitle}
            </IconWithLabel>
        </div>
    );
};
