import React, { memo } from "react";

import { PinnedContractsGrid } from "../../ui/Dashboard/Panel/Widgets/PinnedContractsPanel";

import "./style.scss";

const UtilitiesAndProgramManagementBookmarkedContracts = memo(() => {
    return (
        <div className="bookmarked-contracts panel sticky-grid-list-panel">
            <div className="main-grid-wrap responsive flex-column fill-height">
                <PinnedContractsGrid />
            </div>
        </div>
    );
});

export default UtilitiesAndProgramManagementBookmarkedContracts;
