import React, { memo, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { isNil } from "lodash";

import PanelHeaderLarge from "../../../../../Headers/PanelHeaderLarge";
import { useResource, usePrograms } from "store/resources/useResource";
import DropDownInput from "components/ui/Input/DropDownInput";
import WaitIcon from "components/ui/WaitIcon";
import { WarningMessage } from "components/ui/Message";
import TabList from "components/ui/List/TabList";
import { RolesByUser } from "./RolesByUser";
import { RolesByProgram } from "./RolesByProgram";
import { RolesBulkActions } from "./RolesBulkActions";
import { getClientUsersResourceParams } from "store/configureResources";
import ViewPlaceholder from "components/ui/ViewPlaceholder";

import "./style.scss";

const SELECT_CLIENTS_MESSAGE = "Select client to manage roles";
const NO_CLIENS_MESSAGE = "Utility is not assigned to any client or insufficient permissions";
const NO_CLIENT_ROLES_MESSAGE = "Selected client has no roles defined";

const TAB = {
    USERS: "users",
    PROGRAMS: "programs",
    BULK_ACTIONS: "bulk-actions",
};

const tabs = [
    {
        id: TAB.USERS,
        title: "USERS",
    },
    {
        id: TAB.PROGRAMS,
        title: "PROGRAMS",
    },
    {
        id: TAB.BULK_ACTIONS,
        title: "BULK ACTIONS",
    },
];

const UserManagementPanel = memo(({ panel, onToggleTempPanel }) => {
    const utilityNumber = panel?.data?.utilityNumber;
    const [clientNumber, setClientNumber] = useState();
    const clientName = useSelector(
        (state) =>
            (state.resources["utilityClients"]?.itemsById[utilityNumber]?.clientList ?? []).find((i) => i.clientNumber === clientNumber)
                ?.client
    );

    const [activeTab, setActiveTab] = useState(tabs[0]);

    const onSelectTab = (tab) => {
        setActiveTab(tab);
    };

    const [clients, isLoadingClients] = useResource({
        resourceName: "utilityClients",
        key: utilityNumber,
        path: {
            utilityNumber,
        },
        forced: true,
        transform: (data) => {
            return (data?.clientList ?? []).map((client) => ({
                label: client.client,
                value: client.clientNumber,
            }));
        },
    });

    const [roles, isLoadingRoles] = useResource({
        resourceName: "clientRoles",
        key: clientNumber,
        path: {
            clientNumber,
        },
        forced: true,
        transform: (data) => {
            return (data?.clientRoles ?? []).map((item) => ({
                roleId: item.clientRoleId,
                roleName: item.roleName,
            }));
        },
    });

    const [usersList, isLoadingUsers] = useResource({
        ...getClientUsersResourceParams({ clientNumber, utilityNumber }),
        forced: true,
        transform: (data) => data?.clientUserList ?? [],
    });

    const [programsList, isLoadingPrograms] = usePrograms({
        utilityNumber,
        forced: true,
    });

    const onSelectClient = useCallback((event) => {
        setClientNumber(event.target.value);
    }, []);

    const isClientSelected = !isNil(clientNumber);
    const showNoClientsMessage = !isLoadingClients && clients.length === 0;
    const showSelectClientsMessage = !isClientSelected && clients.length > 0;

    const showNoRolesMessage = !isLoadingClients && isClientSelected && !isLoadingRoles && roles.length === 0;
    const showRolesManagement = isClientSelected && !showNoRolesMessage && !isLoadingUsers && !isLoadingPrograms;
    const isLoadingRolesInfo = isClientSelected && !showNoRolesMessage && (isLoadingRoles || isLoadingUsers || isLoadingPrograms);

    return (
        <div className="panel panel-user-management fill-height flex-column">
            <PanelHeaderLarge title={panel?.name} childTitle="Role Management" onClick={() => onToggleTempPanel()} />
            <div className="role-managemet-controls flex-row align-center">
                <TabList items={tabs} activeItem={activeTab} onClick={onSelectTab} />
                <DropDownInput
                    inline
                    label="Client"
                    placeholder={isLoadingClients ? "Loading..." : "-- SELECT --"}
                    value={clientNumber}
                    data={clients}
                    onChange={onSelectClient}
                />
            </div>
            {!showRolesManagement && (
                <ViewPlaceholder>
                    {showSelectClientsMessage && SELECT_CLIENTS_MESSAGE}
                    {showNoClientsMessage && <WarningMessage delay={100}>{NO_CLIENS_MESSAGE}</WarningMessage>}
                    {showNoRolesMessage && <WarningMessage delay={100}>{NO_CLIENT_ROLES_MESSAGE}</WarningMessage>}
                    {isLoadingRolesInfo && <WaitIcon />}
                </ViewPlaceholder>
            )}
            {showRolesManagement && activeTab.id === TAB.USERS && (
                <RolesByUser
                    clientNumber={clientNumber}
                    clientName={clientName}
                    utilityNumber={utilityNumber}
                    roles={roles}
                    programs={programsList}
                />
            )}
            {showRolesManagement && activeTab.id === TAB.PROGRAMS && (
                <RolesByProgram
                    clientNumber={clientNumber}
                    clientName={clientName}
                    utilityNumber={utilityNumber}
                    roles={roles}
                    programs={programsList}
                />
            )}
            {showRolesManagement && activeTab.id === TAB.BULK_ACTIONS && (
                <RolesBulkActions
                    clientNumber={clientNumber}
                    utilityNumber={utilityNumber}
                    roles={roles}
                    usersList={usersList}
                    programsList={programsList}
                />
            )}
        </div>
    );
});

export default UserManagementPanel;
