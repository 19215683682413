import React, { memo, useEffect } from "react";

import { programsAttributesGridColumnKeys } from "../../../../../../views/configureGrids";
import { isLockedText } from "../../../../../../views/ProgramView/utils";
import { exportDatagridToCSV } from "../../../../../../utils/CSV";

import { sideNavSize } from "../../../../../SideNav/SideNavRoot";
import useSidePanelHandlers from "../../../../../../utils/useSidePanelHandlers";

import IconWithLabel from "../../../../../Icons/IconWithLabel";
import PanelHeaderLarge from "../../../../../Headers/PanelHeaderLarge";
import AttributesGrid from "./AttributesGrid";
import AttributesForm from "./AttributesForm";

import { useSelector } from "react-redux";
import { setAttributeAutoOpen } from "store/dashboards/actions";
import { store } from "store/configureStore";

import "../../../ConfigAttributesPanel.scss";

const AttributesPanel = memo((props) => {
    const programNumber = props?.panel?.data?.programNumber;
    const gridId = `${programNumber}-attributes`;
    const addAttributeIcon = "plus";
    const isLocked = false;

    const autoAttributeOpenState = useSelector((state) => {
        return state.dashboards.attributeAutoEditOpen;
    });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "program-config-attributes-sidenav-panel",
    });

    const openAttributeForm = () => {
        handleOpenSidePanel(
            <AttributesForm onClose={handleCloseSidePanel} programNumber={programNumber} dispatch={props.dispatch} gridId={gridId} />
        );
    };

    useEffect(() => {
        if (autoAttributeOpenState && autoAttributeOpenState.autoOpen && autoAttributeOpenState.attributeNumber) {
            const attribute = {
                attributeNumber: autoAttributeOpenState.attributeNumber,
                attributeType: autoAttributeOpenState.attributeType,
                attributeValue: autoAttributeOpenState.attributeValue,
            };

            handleOpenSidePanel(
                <AttributesForm
                    attributeNumber={autoAttributeOpenState.attributeNumber}
                    attribute={attribute}
                    onClose={handleCloseSidePanel}
                    programNumber={programNumber}
                    dispatch={props.dispatch}
                    gridId={gridId}
                />
            );

            store.dispatch(
                setAttributeAutoOpen({
                    utilityNumber: "",
                    programNumber: "",
                    attributeNumber: "",
                    attributeType: "",
                    attributeValue: "",
                    autoOpen: false,
                })
            );
        }
    }, [autoAttributeOpenState, handleCloseSidePanel, handleOpenSidePanel, programNumber, gridId, props.dispatch]);

    return (
        <div className="panel panel-attributes sticky-grid-list-panel">
            <PanelHeaderLarge title={props?.panel?.name} childTitle="Attributes" onClick={() => props.onToggleTempPanel()} />
            <div className="data-grid-controls flex-row justify-end">
                <IconWithLabel
                    icon={addAttributeIcon}
                    disabled={isLocked}
                    title={isLocked ? isLockedText : undefined}
                    onClick={openAttributeForm}
                >
                    Add Attribute
                </IconWithLabel>
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: gridId,
                            fileName: "program_attributes",
                            fileNamePostfix: programNumber,
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>

            <AttributesGrid
                programNumber={programNumber}
                gridId={gridId}
                filter={`${programsAttributesGridColumnKeys.programNumber}=${programNumber}`}
            />
        </div>
    );
});

export default AttributesPanel;
