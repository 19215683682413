import React from "react";

import { getScanQueueGridId, getRowPriorityInfo, toggleHighPriority, reorderPriority, scanQueueGridColumnKeys } from "../utils";
import { availableGrids } from "../../configureGrids";

import useSidePanelHandlers from "../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../ui/SideNav/SideNavRoot";

import IconWrap from "../../../ui/Icons";
import WaitIcon from "../../../ui/WaitIcon";
import Form from "../Form";
import DataGrid from "../../../ui/DataGrid";
import ActionLabel from "../../../ui/Label/ActionLabel";
import { InfoMessage } from "../../../ui/Message";
import { useDataGrid } from "components/utils/useDataGrid";
import { mapGridRowToObject } from "components/utils/datagrid";

const Grid = ({ onRefresh }) => {
    const dataGridId = getScanQueueGridId();
    const [gridConfig, isLoadingConfig] = useDataGrid({
        dataGridId: availableGrids.scanQueue,
        dataGridInstanceId: dataGridId,
        getDataAction: {
            type: "scanQueueGridGetDataAction",
            props: {
                columnKeysMap: scanQueueGridColumnKeys,
            },
        },
    });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
    });

    const onRowAction = (action, onExpand) => {
        const { name, dataItem, group } = action;

        const data = mapGridRowToObject(scanQueueGridColumnKeys, dataItem);

        switch (name) {
            case "open-group":
                handleOpenSidePanel(<Form onClose={handleCloseSidePanel} dataItem={group} onRefresh={onRefresh} />);
                break;
            case "toggle-priority":
                toggleHighPriority({ dataItem: data });
                break;
            default:
                break;
        }
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        reorderPriority({
            oldIndex: result.source.index,
            newIndex: result.destination.index,
        });
    };

    if (isLoadingConfig) {
        return <WaitIcon />;
    }

    return (
        <DataGrid
            name={dataGridId}
            config={gridConfig}
            onRowAction={onRowAction}
            draggableRows
            onDragEnd={onDragEnd}
            columnWidth={{
                [scanQueueGridColumnKeys.queue]: 100,
            }}
            columnCellContent={{
                [scanQueueGridColumnKeys.programName]: (column, row, onExpandRow, onRowAction, rowIndex) => {
                    const priorityInfo = getRowPriorityInfo(row[scanQueueGridColumnKeys.priority]);

                    return (
                        <>
                            <IconWrap
                                className={priorityInfo.className}
                                icon={priorityInfo.icon}
                                title={priorityInfo.text}
                                iconWrapError={priorityInfo.iconWrapError}
                                iconWrapTheme={priorityInfo.iconWrapTheme}
                                onClick={() =>
                                    onRowAction({
                                        name: "toggle-priority",
                                        dataItem: row,
                                        dataIndex: rowIndex,
                                    })
                                }
                            />
                            <span>{row[column.key]}</span>
                        </>
                    );
                },
                [scanQueueGridColumnKeys.groupName]: (column, row, onExpandRow, onRowAction, rowIndex) => {
                    const groupCount = row[scanQueueGridColumnKeys.groupList]?.length ?? 0;

                    if (groupCount > 0) {
                        return row[scanQueueGridColumnKeys.groupList].map((item, index) => (
                            <React.Fragment key={index}>
                                <ActionLabel
                                    onClick={() =>
                                        onRowAction({
                                            name: "open-group",
                                            dataItem: row,
                                            dataIndex: rowIndex,
                                            group: item,
                                        })
                                    }
                                >
                                    {item.groupName}
                                </ActionLabel>
                                {index < groupCount - 1 && <span>, </span>}
                            </React.Fragment>
                        ));
                    }

                    return <InfoMessage>No Group Assigned</InfoMessage>;
                },
            }}
        />
    );
};

export default Grid;
