import React, { memo } from "react";

import { eventsGridColumnKeys } from "../../../../../views/configureGrids";
import { isApplicationLocked } from "../../../../../views/ProjectView/utils";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import { onDeleteEvent } from "./utils";
import withDataGrid from "components/ui/DataGrid/withDataGrid";

import DataGrid from "../../../../DataGrid";
import EditEvent from "./EditEvent";

const EventsGrid = memo(
    ({ gridId, dataGridConfig, programNumber, applicationNumber, onRowSelectChange, onRowSelect, isEditEventDisabled }) => {
        const isAppLocked = isApplicationLocked({ applicationNumber });
        const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({ className: "app-events-sidenav-panel" });

        const customRowActions = [
            {
                name: "update",
                icon: isAppLocked ? "eye-visibility-empty" : "edit-empty",
                title: isAppLocked ? "View" : "Edit",
                disabled: isEditEventDisabled,
            },
            {
                name: "delete",
                icon: "delete-trash-empty",
                hide: isAppLocked,
                title: "Delete Event",
                disabled: isEditEventDisabled,
            },
        ];

        const onEditEvent = ({ eventNumber }) => {
            if (eventNumber) {
                handleOpenSidePanel(
                    <EditEvent
                        programNumber={programNumber}
                        applicationNumber={applicationNumber}
                        eventNumber={eventNumber}
                        onClose={handleCloseSidePanel}
                    />
                );
            }
        };

        const onRowAction = (action) => {
            const { name, dataItem } = action;

            const eventNumber = dataItem[eventsGridColumnKeys.number];
            const eventName = dataItem[eventsGridColumnKeys.name];

            switch (name) {
                case "update":
                    document.activeElement.blur();
                    onEditEvent({ eventNumber });
                    break;

                case "delete":
                    onDeleteEvent({
                        applicationNumber,
                        eventName,
                        eventNumber,
                        onSuccess: () => onRowSelect([]),
                    });
                    break;

                default:
                    break;
            }
        };

        return (
            <DataGrid
                name={gridId}
                config={dataGridConfig}
                onRowAction={onRowAction}
                canSelectRow
                onRowSelectChange={onRowSelectChange}
                customRowActions={customRowActions}
                columnCellContent={{
                    [eventsGridColumnKeys.duration]: (column, row) => <div>{+row[column.key] || ""}</div>,
                }}
            />
        );
    }
);

export default withDataGrid("events", EventsGrid);
