import React, { useCallback, memo } from "react";
import { isEmpty } from "lodash";

import { getPanel } from "../../../../../utils/dashboard";
import { availableGrids, locationApplicationsGridColumnKeys } from "../../../../../views/configureGrids";
import { useDataGridData, useResource } from "store/resources/useResource";

import IconWithLabel from "../../../../Icons/IconWithLabel";

import "./style.scss";

const SimilarPremises = memo(({ applicationNumber, onOpenDashboardPanel }) => {
    const count = useSimilarPremisesCount({ applicationNumber });

    const onSimilarPremises = useCallback(() => {
        if (onOpenDashboardPanel) {
            async function scrollToSimilarPremises() {
                await onOpenDashboardPanel(getPanel("project-location", 2));
                if (document.getElementsByClassName("applications")[0]) {
                    document.getElementsByClassName("applications")[0].scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }
            }
            scrollToSimilarPremises();
        }
    }, [onOpenDashboardPanel]);

    if (!count) {
        return;
    }

    return (
        <div className="similar-premises">
            <IconWithLabel icon="location" onClick={onSimilarPremises}>
                {count} {count === 1 ? "Similar Premise" : "Similar Premises"}
            </IconWithLabel>
        </div>
    );
});

const useSimilarPremisesCount = ({ applicationNumber }) => {
    const [location] = useResource({
        resourceName: "applicationLocation",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
        showErrorNotification: false,
    });

    const isLocationAvailable = !isEmpty(location?.address) && !isEmpty(location?.shortZip);

    const dataGridKey = isLocationAvailable ? `${applicationNumber}-location-applications-grid` : undefined;
    const filter = `${locationApplicationsGridColumnKeys.address}=${location?.address}|${locationApplicationsGridColumnKeys.zip}=${location?.shortZip}`;

    const [gridData] = useDataGridData({
        dataGridId: availableGrids.locationApplications,
        key: dataGridKey,
        filter,
        pageSize: 1,
        forced: false,
    });

    const totalRecords = Number(gridData?.[0]?.totRecords);
    const count = totalRecords > 0 ? totalRecords - 1 : 0;

    return count;
};

export default SimilarPremises;
