import React, { useCallback } from "react";
import IconWithLabel from "components/ui/Icons/IconWithLabel";
import { PropertyListItem } from "../Property/PropertyListItem";
import { usePortalBuilderStateSetter } from "../../PortalBuilderContextProvider";

export const ConfigPanelProperty = (props) => {
    const { id, title, openIcon, panelComponent } = props;
    const setPortalBuilderState = usePortalBuilderStateSetter();
    const Panel = panelComponent;

    const onOpenPanel = useCallback(() => {
        setPortalBuilderState({
            activeConfigPanel: <Panel {...props} onClose={() => setPortalBuilderState({ activeConfigPanel: null })} />,
        });
    }, [props, setPortalBuilderState]);

    if (props.hidden) {
        return null;
    }

    return (
        <PropertyListItem className="property-list-item--config-panel">
            <div className="property-list-item-inner align-center flex-row justify-space-between">
                <IconWithLabel
                    icon={openIcon}
                    iconWithLabelRight
                    iconWithLabelTextUppercase
                    onClick={onOpenPanel}
                    data-test-id={`${id}-config-panel`}
                >
                    {title}
                </IconWithLabel>
            </div>
        </PropertyListItem>
    );
};
