import React from "react";
import { PropertyType } from "components/ui/PortalBuilder/types";
import { PropertyListItem } from "./PropertyListItem";
import { PropertyListItemTitle } from "../PropertyList/PropertyListItemTitle";
import { PropertyList } from "../PropertyList";

export const StandardPageRowsProperty = (props) => {
    const { value = [], onChange, isTop, titleSwitch, nestingLevel = 0, borderTop, borderBottom, title } = props;

    const rowsOff = props.titleSwitchValue;

    const turnOffRows = () => {
        onChange(titleSwitch?.id, !rowsOff);
    };

    const properties = [
        {
            id: "components",
            title: "Rows",
            type: PropertyType.Rows,
            isTop,
        },
    ];

    if (props.hidden) {
        return null;
    }

    return (
        <PropertyListItem className="property-list-item--prop-group" borderTop={borderTop} borderBottom={borderBottom}>
            <PropertyListItemTitle
                title={title}
                toggleTooltip={rowsOff ? "Switch ON" : "Switch OFF"}
                onToggle={turnOffRows}
                toggleValue={rowsOff}
            />
            {!rowsOff && (
                <PropertyList
                    className="with-scroll"
                    items={properties}
                    onChange={onChange}
                    config={{ components: value }}
                    nestingLevel={nestingLevel + 1}
                />
            )}
        </PropertyListItem>
    );
};
