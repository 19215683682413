import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { sortObjectsByDate } from "../../../../../utils/date";
import { refreshNotesGrid, refreshSidebarNotes } from "../NotesPanel/utils";
import { SidebarIconGroup } from "../../../../SidebarPanel/SidebarParts/SidebarIconGroup";
import { useProgramRights, useResource } from "../../../../../../store/resources/useResource";
import { systemUserRights } from "components/utils/user";
import SidebarPanel from "../../../../SidebarPanel";
import CustomList, { renderCustomFooter } from "../../../../List/CustomList";
import WaitIcon from "../../../../WaitIcon";
import NoteListItem from "./NoteListItem";
import IconWithLabel from "components/ui/Icons/IconWithLabel";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import NotesEditForm from "../NotesPanel/NotesEditForm";

import "./NotesPanelSmall.scss";

const NotesPanelSmall = memo(({ panel, onSettings, onUnpin, onExpand, onToggleSidebar }) => {
    const applicationNumber = panel && panel.data && panel.data.applicationNumber;
    const programNumber = panel && panel.data && panel.data.programNumber;
    const [programRights = []] = useProgramRights({ programNumber });

    const user = useSelector((state) => state.user);

    const canEditNotes = programRights.includes(systemUserRights.VISIONDSM_ADD_NOTES);

    const [notes, isLoading] = useResource({
        resourceName: "applicationNotes",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
        query: {
            number: 1000,
        },
        forced: true,
    });

    const orderedNotes = useMemo(() => sortObjectsByDate(notes, "dateEntered"), [notes]);

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
    });

    const onAdd = () => {
        handleOpenSidePanel(<NotesEditForm applicationNumber={applicationNumber} onSaved={onSaved} onClose={handleCloseSidePanel} />);
    };

    const onEdit = (item) => {
        // Normalize note object
        // In dataGrids note identifier is "noteId", but in notes sidepanel "noteNumber"
        const note = {
            ...item,
            noteId: item.noteNumber,
        };

        handleOpenSidePanel(
            <NotesEditForm note={note} applicationNumber={applicationNumber} onSaved={onSaved} onClose={handleCloseSidePanel} />
        );
    };

    const onSaved = () => {
        handleCloseSidePanel();
        refreshNotesGrid({ applicationNumber });
        refreshSidebarNotes({ applicationNumber });
    };

    const renderItem = (item) => {
        // Do not allow editing if note was created by another user
        const isEditDisabled = item.userNumber !== user.userNumber;
        return <NoteListItem key={item.noteNumber} note={item} isEditDisabled={!canEditNotes || isEditDisabled} onEdit={onEdit} />;
    };

    const sidebarHeaderActions = <SidebarIconGroup panel={panel} onSettings={onSettings} onUnpin={onUnpin} onExpand={onExpand} />;

    return (
        <SidebarPanel
            sidebarHeaderTitle={panel.name}
            sidebarHeaderActions={sidebarHeaderActions}
            noPadding
            noFooter
            className="notes-sidebar-panel"
        >
            <div className="add-note">
                <IconWithLabel disabled={!canEditNotes} iconWithLabel="plus" onClick={onAdd}>
                    Add Note
                </IconWithLabel>
            </div>
            <div className="notes-list-container flex-column">
                {isLoading && <WaitIcon />}
                <div className="notes-list">
                    <CustomList limit={3} items={orderedNotes} renderItem={renderItem} renderFooter={renderCustomFooter} />
                </div>
            </div>
        </SidebarPanel>
    );
});

export default NotesPanelSmall;
