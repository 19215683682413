import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCoordinates } from "components/utils/geocoding";
import { updateResource } from "store/resources/actions";
import WaitIcon from "../WaitIcon";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useMapLoader } from "components/utils/map";

const LocationMap = (props) => {
    const { applicationNumber, location, showMarker } = props;
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const { isLoaded: isMapLoaded } = useMapLoader();

    useEffect(() => {
        const updateLocation = async () => {
            if (location && !(location.latitude && location.longitude) && location.address && location.address.trim() !== "") {
                setIsLoading(true);

                try {
                    const response = await getCoordinates({
                        address: location.address + "+" + location.city + "+" + location.state + "+" + location.zip,
                        geocoder: new window.google.maps.Geocoder(),
                    });

                    const { lat, lng } = response.results[0].geometry.location;

                    location.latitude = lat();
                    location.longitude = lng();

                    dispatch(
                        updateResource({
                            resourceName: "applicationLocation",
                            path: {
                                appId: applicationNumber,
                            },
                            query: {
                                contactNumber: location.contactNumber,
                                latitude: location.latitude,
                                longitude: location.longitude,
                            },
                            onComplete: (action) => {
                                setIsLoading(false);
                            },
                        })
                    );
                } catch (err) {
                    console.error(err);
                }

                setIsLoading(false);
            }
        };
        updateLocation();
    }, [applicationNumber, location, dispatch]);

    if (isLoading || !isMapLoaded) {
        return <WaitIcon />;
    }

    if (!(location.latitude && location.longitude)) {
        return <p>No data available</p>;
    }
    return (
        <GoogleMap
            mapContainerStyle={{
                height: props.height + "px",
            }}
            zoom={14}
            center={{ lat: location.latitude, lng: location.longitude }}
        >
            {showMarker && <Marker position={{ lat: location.latitude, lng: location.longitude }} />}
        </GoogleMap>
    );
};

export default LocationMap;
