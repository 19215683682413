import React from "react";
import { programsWorkflowAuditGridColumnKeys } from "../../../../../../views/configureGrids";
import { exportDatagridToCSV } from "../../../../../../utils/CSV";
import withDataGrid from "components/ui/DataGrid/withDataGrid";
import DataGrid from "../../../../../DataGrid";
import IconWithLabel from "../../../../../Icons/IconWithLabel";

import "./style.scss";

const WorkflowAudit = ({ programNumber }) => {
    const gridId = `${programNumber}-workflow-audit`;

    return (
        <div className="program-wf__audit flex-column flex-one-in-column no-scroll">
            <div className="flex-row program-wf__controls">
                <span className="flex-one" />
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: gridId,
                            fileName: "program_workflow_history",
                            fileNamePostfix: programNumber,
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <div className="flex-row program-wf__grid-wrap flex-one-in-column no-scroll">
                <HistoryGrid gridId={gridId} filter={`${programsWorkflowAuditGridColumnKeys.programNumber}=${programNumber}`} />
            </div>
        </div>
    );
};

const HistoryGrid = withDataGrid("programsWorkflowAudit", ({ gridId, dataGridConfig }) => {
    return <DataGrid name={gridId} config={dataGridConfig} />;
});

export default WorkflowAudit;
