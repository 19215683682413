import { HeadingPropertyContext, PortalBuilderContext } from "components/ui/PortalBuilder/contexts";
import { PropertyName, PropertyType } from "components/ui/PortalBuilder/types";
import { cloneDeep, isArray, isEmpty, isNil, omitBy, set } from "lodash";
import React, { useContext, useRef } from "react";
import { PropertyList } from "../../PropertyList";
import { ColorCommonProperty } from "./ColorCommonProperty";
import { ColorIndividualProperty } from "./ColorIndividualProperty";
import { HeadingCustomPropertyName, HeadingNames, HeadingTypes } from "./types";

import "./GlobalHeadingProperties.scss";
import { PropertyListItem } from "../../Property/PropertyListItem";
import classNames from "classnames";
import sassVariables from "assets/sass/_export.module.scss";
import { DEFAULT_UTILITY_TEMPLATE_CONFIG } from "components/ui/PortalBuilder/utils";
import { usePortalBuilderState } from "components/ui/PortalBuilder/PortalBuilderContextProvider";

export const GlobalHeadingProperties = (props) => {
    const { errors } = props;
    const { onConfigChange } = useContext(PortalBuilderContext);
    const [updatedConfig] = usePortalBuilderState((state) => state.updatedConfig);

    const sectionConfig = updatedConfig?.widgets?.heading ?? DEFAULT_UTILITY_TEMPLATE_CONFIG.widgets.heading;

    const configRef = useRef();

    configRef.current = updatedConfig;
    const defaultValue = sassVariables.colorDarkGrey;

    const onPropertyChange = (id, value, extra) => {
        if (id === HeadingCustomPropertyName.HeadingColorType) {
            onHeadingColorTypeChange(value, onConfigChange, configRef.current, defaultValue);
            return;
        }

        let updatedConfigClone = cloneDeep(configRef.current);
        set(updatedConfigClone, `widgets.heading[${id}]`, value);

        if (isArray(extra)) {
            updatedConfigClone.widgets.heading = omitBy(
                extra.reduce((result, property) => {
                    return {
                        ...result,
                        [property.id]: property.value,
                    };
                }, updatedConfigClone.widgets.heading ?? {}),
                isNil
            );
        }

        onConfigChange(updatedConfigClone);
    };

    const customPropertiesValue = {
        [HeadingCustomPropertyName.HeadingColorType]: isEmpty(sectionConfig[PropertyName.HeadingColor])
            ? HeadingTypes.Individual
            : HeadingTypes.Common,
        [HeadingCustomPropertyName.HeadingColorCommon]: sectionConfig[PropertyName.HeadingColor],
        [HeadingCustomPropertyName.HeadingColorIndividual]: {
            [HeadingNames.H1]: sectionConfig[PropertyName.HeadingH1Color],
            [HeadingNames.H2]: sectionConfig[PropertyName.HeadingH2Color],
            [HeadingNames.H3]: sectionConfig[PropertyName.HeadingH3Color],
            [HeadingNames.H4]: sectionConfig[PropertyName.HeadingH4Color],
            [HeadingNames.H5]: sectionConfig[PropertyName.HeadingH5Color],
            [HeadingNames.H6]: sectionConfig[PropertyName.HeadingH6Color],
        },
    };

    const headingPropertyContextValue = {
        customPropertiesValue,
    };

    return (
        <HeadingPropertyContext.Provider value={headingPropertyContextValue}>
            <div className="flex-column fill-width with-scroll">
                <PropertyListItem className={classNames("property-list-item--prop-group property-list-item--heading")}>
                    <PropertyList
                        items={customProperties}
                        config={customPropertiesValue ?? {}}
                        onChange={onPropertyChange}
                        errors={errors}
                        isExpanded
                    />
                </PropertyListItem>
            </div>
        </HeadingPropertyContext.Provider>
    );
};

const onHeadingColorTypeChange = (propertyValue, onChange, config, defaultValue) => {
    let updatedConfigClone = cloneDeep(config);
    const headingConfig = updatedConfigClone?.widgets?.heading ?? DEFAULT_UTILITY_TEMPLATE_CONFIG.widgets.heading;

    if (propertyValue === HeadingTypes.Individual) {
        set(
            updatedConfigClone,
            `widgets.heading[${PropertyName.HeadingH1Color}]`,
            headingConfig[PropertyName.HeadingColor] ?? defaultValue
        );
        set(
            updatedConfigClone,
            `widgets.heading[${PropertyName.HeadingH2Color}]`,
            headingConfig[PropertyName.HeadingColor] ?? defaultValue
        );
        set(
            updatedConfigClone,
            `widgets.heading[${PropertyName.HeadingH3Color}]`,
            headingConfig[PropertyName.HeadingColor] ?? defaultValue
        );
        set(
            updatedConfigClone,
            `widgets.heading[${PropertyName.HeadingH4Color}]`,
            headingConfig[PropertyName.HeadingColor] ?? defaultValue
        );
        set(
            updatedConfigClone,
            `widgets.heading[${PropertyName.HeadingH5Color}]`,
            headingConfig[PropertyName.HeadingColor] ?? defaultValue
        );
        set(
            updatedConfigClone,
            `widgets.heading[${PropertyName.HeadingH6Color}]`,
            headingConfig[PropertyName.HeadingColor] ?? defaultValue
        );
        set(updatedConfigClone, `widgets.heading[${PropertyName.HeadingColor}]`, undefined);

        onChange(updatedConfigClone);
    } else {
        set(
            updatedConfigClone,
            `widgets.heading[${PropertyName.HeadingColor}]`,
            headingConfig[PropertyName.HeadingH1Color] ?? defaultValue
        );
        set(updatedConfigClone, `widgets.heading[${PropertyName.HeadingH1Color}]`, undefined);
        set(updatedConfigClone, `widgets.heading[${PropertyName.HeadingH2Color}]`, undefined);
        set(updatedConfigClone, `widgets.heading[${PropertyName.HeadingH3Color}]`, undefined);
        set(updatedConfigClone, `widgets.heading[${PropertyName.HeadingH4Color}]`, undefined);
        set(updatedConfigClone, `widgets.heading[${PropertyName.HeadingH5Color}]`, undefined);
        set(updatedConfigClone, `widgets.heading[${PropertyName.HeadingH6Color}]`, undefined);

        onChange(updatedConfigClone);
    }
};

export const customProperties = [
    {
        id: HeadingCustomPropertyName.HeadingColorType,
        title: (
            <>
                Heading <br /> text color
            </>
        ),
        type: PropertyType.SelectBox,
        borderTop: false,
        borderBottom: false,
        items: [
            {
                text: "Common",
                tooltip: "The same text color for all headings",
                value: HeadingTypes.Common,
            },
            {
                text: "Individual",
                tooltip: "Individual color can be set for every heading",
                value: HeadingTypes.Individual,
            },
        ],
        noPostfix: true,
    },
    {
        id: HeadingCustomPropertyName.HeadingColorCommon,
        type: PropertyType.CustomComponent,
        component: ColorCommonProperty,
        borderTop: true,
        borderBottom: false,
        noPostfix: true,
    },
    {
        id: HeadingCustomPropertyName.HeadingColorIndividual,
        type: PropertyType.CustomComponent,
        component: ColorIndividualProperty,
        noPostfix: true,
    },
];

export const HEADING_PROPERTY = {
    id: "group-heading",
    title: "Heading",
    type: PropertyType.Heading,
    propertiesGroup: [
        {
            id: PropertyName.HeadingColor,
        },
        {
            id: PropertyName.HeadingH1Color,
        },
        {
            id: PropertyName.HeadingH2Color,
        },
        {
            id: PropertyName.HeadingH3Color,
        },
        {
            id: PropertyName.HeadingH4Color,
        },
        {
            id: PropertyName.HeadingH5Color,
        },
        {
            id: PropertyName.HeadingH6Color,
        },
    ],
};
