import React, { useState, memo, useContext } from "react";
import { toast } from "react-toastify";

import { useDispatch } from "react-redux";

import UserEditForm from "./UserDetails/UserEditForm";
import UsersManagement from "./UsersManagement";
import RolesManagement from "./RolesManagement";
import MimicUserModal from "./MimicUserModal";
import UserAudit from "./UserAudit";
import AssignExternalAppsForm from "./AssignExternalApps/AssignExternalAppsForm";

import { usersGridColumnKeys } from "../../views/configureGrids";
import { mapGridDataToObjects, mapGridRowToObject } from "components/utils/datagrid";

import { modalOpen, modalClose } from "../../../store/modal/actions";
import { getResource } from "../../../store/resources/actions";
import { sideNavOpen, sideNavClose } from "../../../store/sideNav/actions";
import { sideNavMode, getViewSideNavKey, sideNavPosition, sideNavKey } from "../../ui/SideNav/SideNavRoot";
import { WindowContext } from "../../ui/Windows/Window";

import "./style.scss";

const SystemManagementUsers = memo(() => {
    const dispatch = useDispatch();

    const [management, setManagement] = useState({});
    const panelTitle = "Manage Users";

    const { viewName, isSplitView } = useContext(WindowContext);
    const userEditSideNavId = isSplitView ? getViewSideNavKey({ viewName, position: sideNavPosition.right }) : sideNavKey.globalRight;

    const changeManagement = (management) => {
        const closeModal = () => dispatch(modalClose());

        const goToRolesManagement = (userNumber) => {
            closeModal();
            setManagement({ ...management, type: "manageRoles" });
        };

        const handleMimicSuccess = () => {
            toast.success("User permissions copied!");
            management.users.forEach((u) => {
                dispatch(
                    getResource({
                        resourceName: "userRoles",
                        key: u[usersGridColumnKeys.userNumber],
                        path: {
                            userNumber: u[usersGridColumnKeys.userNumber],
                        },
                    })
                );
            });

            closeModal();
        };

        if (management.type === "bulkAssignment" || management.type === "mimicUser") {
            let title =
                management.type === "mimicUser"
                    ? `Mimic User "${management.users[0][usersGridColumnKeys.name]}"`
                    : `Assign ${management.users.length} Users to similar Roles`;
            let modalIcon = management.type === "mimicUser" ? "double-person" : ``;

            dispatch(
                modalOpen({
                    modalType: "MODAL",
                    modalProps: {
                        title,
                        modalIcon,
                        overlayClassName: "modal-styled",
                        className: "mimic-user modal-sm",
                        children: (
                            <MimicUserModal
                                onCancel={closeModal}
                                onMimicSuccess={handleMimicSuccess}
                                onAssignManually={goToRolesManagement}
                                users={mapGridDataToObjects(usersGridColumnKeys, management.users)}
                            />
                        ),
                        noFooter: true,
                    },
                })
            );

            return;
        }

        setManagement(management);
    };

    const backToManagement = () => setManagement({});

    const renderPage = () => {
        if (management.type === "update") {
            const onClose = () => {
                dispatch(sideNavClose({ id: userEditSideNavId }));
                setManagement({});
            };

            const goToManagement = (dataItem) => {
                onClose();
                setManagement({
                    type: "manageRoles",
                    users: [dataItem],
                    clientNumber: dataItem[usersGridColumnKeys.clientNumber],
                });
            };

            dispatch(
                sideNavOpen({
                    id: userEditSideNavId,
                    props: {
                        mode: sideNavMode.over,
                        backdrop: true,
                        children: (
                            <UserEditForm
                                userNumber={management.user[usersGridColumnKeys.userNumber]}
                                onClose={onClose}
                                goToManagement={goToManagement}
                                isNew={management.isNew ? true : false}
                                panelTitle={panelTitle}
                            />
                        ),
                    },
                })
            );
        }
        if (management.type === "assignExternalApps") {
            const onClose = () => {
                dispatch(sideNavClose({ id: userEditSideNavId }));
                setManagement({});
            };
            dispatch(
                sideNavOpen({
                    id: userEditSideNavId,
                    props: {
                        mode: sideNavMode.over,
                        backdrop: true,
                        children: (
                            <AssignExternalAppsForm onClose={onClose} targetUserNumber={management.user[usersGridColumnKeys.userNumber]} />
                        ),
                    },
                })
            );
        }
    };

    return (
        <div className="flex-column fill-height no-scroll">
            {renderPage()}
            <UsersManagement
                hidden={management.type === "manageRoles" || management.type === "auditUser"}
                setManagement={changeManagement}
            />
            {management?.users?.[0] && management.type === "manageRoles" && (
                <RolesManagement
                    hidden={management.type !== "manageRoles"}
                    user={mapGridRowToObject(usersGridColumnKeys, management?.users?.[0])}
                    onClose={backToManagement}
                />
            )}
            {management?.users?.[0] && management.type === "auditUser" && (
                <UserAudit
                    hidden={management.type !== "auditUser"}
                    user={mapGridRowToObject(usersGridColumnKeys, management?.users?.[0])}
                    onClose={backToManagement}
                />
            )}
        </div>
    );
});

export default SystemManagementUsers;
