import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, uniq } from "lodash";

import { toArray } from "../../components/utils/array";
import { camelToTitle, arrayToText } from "../../components/utils/string";
import { createResource } from "../resources/actions";
import { store } from "../configureStore";
import { openNewApplication } from "../../components/utils/window";
import { getCisCustomerDetailsResourceParams } from "store/configureResources";
import { parseCisDetails } from "../../components/ui/CIS/utils";
import { openSearchResult } from "./actions";
import { searchTableTitle } from "../../components/ui/GlobalSearch/utils";

export const defaultPageNumber = 1;
export const defaultPageSize = 15;
export const defaultQuickSearchPageSize = 5;

export const GlobalSearchKey = "global-search";
export const GlobalSearchAllResultsKey = "global-search-all-results";

export const criteriaType = {
    table: "table",
    field: "field",
    text: "text",
};

export const entityType = {
    application: "APPLICATION",
    invoice: "INVOICE",
    customer: "CUSTOMER",
};

export const CIS_KEY_PREFIX = "cis-";
export const CIS_SEARCH_CRITERIA = "CIS Search";
export const CIS_RESULT_KEYS = ["NAME", "ACCOUNT #", "PHONE", "PHONE #", "ADDRESS", "PREMISEID", "METERID", "SIC_CODE"];

export const CIS_UTILITY_SUB_CRITERIA = {
    title: "Utility",
    key: CIS_KEY_PREFIX + "utility",
    type: criteriaType.field,
};

export const DEFAULT_MAIN_CRITERIA = {
    title: "Everywhere",
    key: "main-criteria-everywhere",
    type: criteriaType.table,
};

export const DEFAULT_SUB_CRITERIA = {
    title: "Everywhere",
    key: "sub-criteria-everywhere",
    type: criteriaType.field,
};

/**
 * Get instance id for secondary search.
 *
 * @param {string} instanceId - global search instance id
 * @returns {string}
 */
export const getSecondarySearchInstanceId = (instanceId) => {
    return `${instanceId}-1`;
};

/**
 * Get primary instance id from given secondary search instance id.
 *
 * @param {string} instanceId - secondary search instance id
 * @returns {string}
 */
export const getPrimarySearchInstanceId = (instanceId) => {
    return instanceId.split("-")[0];
};

/**
 * Chek if instance id is for secondary search.
 *
 * @param {string} instanceId - global search instance id
 * @returns {string}
 */
export const isSecondarySearchInstanceId = (instanceId) => {
    return (instanceId ?? "").indexOf("-") > 0;
};

export const hasCriteriaForSearch = ({ criteriaList }) => {
    return criteriaList.length > 0;
};

export const hasValuesForSearch = ({ instanceId, criteriaValues }) => {
    const storeState = store.getState();
    const criteriaKeys = Object.keys(criteriaValues || {});
    const { utilityKey, utilityNumber } = storeState.globalSearch.selectedUtility[instanceId] ?? {};

    // Check if this is cis search
    if (criteriaKeys.some((key) => key.startsWith(CIS_KEY_PREFIX))) {
        const cisSearchConfig = storeState.resources.cisSearchCriteria.itemsById[utilityNumber];

        if (!cisSearchConfig) {
            return false;
        }

        const cisSearchCriteria = getCriteriaFromCisSearchConfig(cisSearchConfig);
        const selectedSubCriteria = storeState.globalSearch.selectedSubCriteria[instanceId] ?? [];

        // Is Search with grouped criteria
        if (selectedSubCriteria.some((item) => item.isGroup)) {
            // Check if all required fields in group are filled
            return selectedSubCriteria
                .filter((i) => i.isGroup)
                .flatMap((i) => i.relatedFields ?? [])
                .filter((field) => field.isRequired)
                .every((field) => criteriaValues[field.key]?.length > 0);
        } else {
            const mandatoryFields = cisSearchCriteria.filter((item) => item.isMandatory);

            // Can search if at least one mandatory field and related required fields are filled.
            if (mandatoryFields.length > 0) {
                const filledMandatoryFieds = mandatoryFields
                    .filter((field) => criteriaKeys.includes(field.key))
                    .filter((field) => criteriaValues[field.key]?.length > 0);

                return (
                    filledMandatoryFieds.length > 0 &&
                    filledMandatoryFieds
                        .flatMap((field) => field.relatedFields)
                        .filter((field) => field.isRequired)
                        .every((field) => criteriaValues[field.key]?.length > 0)
                );
            }
        }
    }

    const programKey = storeState.globalSearch.selectedProgram[instanceId]?.programKey;

    // Can search if at least one value is filled and it is not program or utility from main criteria
    return criteriaKeys
        .filter((key) => ![utilityKey, programKey].includes(key)) // Do not check utility and program criteria
        .filter((key) => !key.endsWith("-title")) // Do not check keys for criteria title
        .some((key) => criteriaValues[key]?.length > 0);
};

export const hasSubCriteria = ({ mainCriteria }) => {
    return (
        mainCriteria &&
        mainCriteria.key !== DEFAULT_MAIN_CRITERIA.key &&
        !["utility", "program"].includes(mainCriteria?.title?.toLowerCase())
    );
};

/**
 * Determine if more search criteria can be added.
 *
 * @param {{ instanceId: string }} - global search instance id
 * @returns boolean
 */
export const useCanAddSearchCriteria = ({ instanceId }) => {
    const isSearchInputExpanded = useSelector((state) => state.globalSearch.isSearchInputExpanded[instanceId]) ?? true;
    const searchResponse = useSelector((state) => state.resources.search.itemsById[instanceId]);
    const criteriaList = useSelector((state) => state.globalSearch.criteriaList[instanceId]) ?? [];

    const isAnySearchResult = toArray(get(searchResponse, "RETURN.TABLE", [])).length > 0;

    return criteriaList.length > 0 && !isCisSearch({ criteriaList }) && isAnySearchResult && !isSearchInputExpanded;
};

export const getMainSearchCriteria = ({ searchOptions }) => {
    const tables = get(searchOptions, "SEARCH.TABLE", []);

    return [DEFAULT_MAIN_CRITERIA].concat(
        tables.map((item) => ({
            title: item.TITLE,
            key: item.KEY,
            type: criteriaType.table,
        }))
    );
};

export const getSubSearchCriteria = ({ mainCriteria, searchOptions }) => {
    if (["utility", "program"].includes(mainCriteria?.title?.toLowerCase())) {
        return [DEFAULT_SUB_CRITERIA];
    }

    const tables = get(searchOptions, "SEARCH.TABLE", []);

    return [DEFAULT_SUB_CRITERIA].concat(
        tables
            .filter((t) => t.KEY === mainCriteria?.key)
            .reduce((acc, next) => (acc = toArray(next.FIELD)), [])
            .map((item) => ({
                title: item.TITLE,
                key: item.KEY,
                type: criteriaType.field,
            }))
    );
};

/*
 * Create search filter.
 *
 * To filter by Utility/Program the first search filter needs to have SEARCH_TABLE as
 * DB072060-B6BB-4902-87B3-14AAF51C7189(utility) or
 * E311925F-36A6-45C6-A764-1588005DAA4F(Program),
 * the associated UtilityNumber/ProgramNumber as the SEARCH_VALUE, and EXACT_MATCH = 1.
 * The subsequent search filters will then be whatever the user has manually entered as usual.
 */
export const getSearchFilter = ({ criteriaList, pageNumber = 0, pageSize = 0 }) => {
    // Search filter structure ( not for CIS Search )
    // <SEARCH>
    //     <SEARCH_FILTER>
    //         <SEARCH_TABLE></SEARCH_TABLE> --Optional GUID from Lookup_Search_Table
    //         <SEARCH_FIELD></SEARCH_FIELD> -- Optional GUID from Lookup_Search_Field
    //         <SEARCH_VALUE></SEARCH_VALUE> --Actual word to search on
    //         <EXACT_MATCH></EXACT_MATCH> --1 = true everything else is False. IF not true we append a % to search value
    //     </SEARCH_FILTER>
    //     <SEARCH_FILTER>
    //             …
    //     </SEARCH_FILTER>
    // </SEARCH>

    if (criteriaList.length === 0) {
        return null;
    }

    let allResultsConfig = {};
    let additionalCriteriaAdded = false;

    if (pageNumber > 0 && pageSize > 0) {
        allResultsConfig = {
            SEARCH_RETURN_ALL: {
                PAGE_NUMBER: pageNumber,
                RECORDS_PER_PAGE: pageSize,
            },
        };
    }

    // Add additional criteria for utility and program specific search.
    const updatedCriteriaList = criteriaList.reduce((result, item) => {
        // First check if program is selected if not then utility.
        const mainCriteriaValue = item.mainCriteriaValue?.programNumber ?? item.mainCriteriaValue?.utilityNumber ?? null;

        const mainCriteriaKey = item.mainCriteriaValue?.programKey ?? item.mainCriteriaValue?.utilityKey ?? null;

        if (mainCriteriaValue && !additionalCriteriaAdded) {
            additionalCriteriaAdded = true;

            result.push({
                table: mainCriteriaKey,
                field: null,
                value: mainCriteriaValue,
                exactMatch: 1,
            });
        }

        result.push({
            table: item.table,
            field: item.field,
            value: item.value,
            exactMatch: 0,
        });

        return result;
    }, []);

    return JSON.stringify({
        ...allResultsConfig,
        SEARCH: updatedCriteriaList.map((item) => ({
            SEARCH_FILTER: {
                SEARCH_TABLE: item.table ?? null,
                SEARCH_FIELD: item.field ?? null,
                SEARCH_VALUE: item.value ?? null,
                EXACT_MATCH: item.exactMatch ?? 0,
            },
        })),
    });
};

export const getCisSearchValues = ({ criteriaList }) => {
    const getKey = (criteria) => {
        return (criteria.field || "").substr(CIS_KEY_PREFIX.length);
    };

    return criteriaList
        .filter((criteria) => criteria.field !== CIS_UTILITY_SUB_CRITERIA.key)
        .map((criteria) => {
            const key = getKey(criteria);

            return {
                columnName: key,
                columnValue: getCisFilterValue({ criteriaList, key }),
            };
        });
};

export const getCriteriaFromCisSearchConfig = ({ searchFields } = {}) => {
    let criteria = [];

    const getName = (item) => item.split(":")[0];
    const getTitle = (item) => camelToTitle(getName(item));
    const getAttribute = (item) => item.split(":")[1];

    const processField = ({ item }) => {
        const parts = item.split(",");
        const isGroup = getAttribute(parts[0]) === "group";

        const field = {
            title: getTitle(parts[0]),
            key: `${CIS_KEY_PREFIX}${getName(parts[0])}`,
            type: criteriaType.field,
            isGroup,
            isMandatory: isGroup ? true : getAttribute(parts[0]) !== "optional",
            isRequired: isGroup ? false : getAttribute(parts[0]) === "required",
            relatedFields:
                parts.length > 1
                    ? parts.slice(1).map((i) => ({
                          title: getTitle(i),
                          key: `${CIS_KEY_PREFIX}${getName(i)}`,
                          type: criteriaType.field,
                          isMandatory: false,
                          isRequired: getAttribute(i) === "required",
                          relatedFields: [],
                      }))
                    : [],
        };

        criteria.push(field);
    };

    (searchFields || []).forEach((item) => processField({ item }));

    criteria
        .filter((field) => !field.isGroup)
        .flatMap((field) => field.relatedFields)
        .forEach((field) => {
            if (!criteria.find((i) => i.key === field.key)) {
                criteria.push(field);
            }
        });

    return criteria;
};

export const getCisFilterValue = ({ criteriaList, key }) => {
    return criteriaList.filter((i) => i.field?.startsWith(`${CIS_KEY_PREFIX}${key}`)).map((c) => c.value)[0];
};

export const getCisUtilityNumber = ({ criteriaList }) => {
    const [criteria] = criteriaList;
    return criteria.mainCriteriaValue.utilityNumber;
};

export const getUtilityNumber = ({ searchResult }) => {
    return toArray(searchResult.FIELD)
        .filter((i) => i.TITLE === "Utility Number")
        .map((i) => i.VALUE)[0];
};

export const getCustomerAccountNumber = ({ searchResult }) => {
    return toArray(searchResult.FIELD)
        .filter((i) => i.TITLE === "Customer Acct #")
        .map((i) => i.VALUE)[0];
};

/**
 * Extract highlight words from criteria list
 *
 * @param {{ criteriaList }} param criteria list for search
 * @returns {string[]} words to highlight in search results
 */
export const getHighlightWords = ({ criteriaList }) => {
    return criteriaList.map((c) => c.value).flatMap((value) => (value ?? "").split(" "));
};

export const getCisSearchHighlightWords = ({ criteriaList }) => {
    return getCisSearchValues({ criteriaList }).map((i) => i.columnValue);
};

export const getMainCriteriaTitle = ({ mainCriteriaTitle, utilityName, programName }) => {
    if (programName) {
        return (
            <>
                <strong>{mainCriteriaTitle}</strong> under <strong>Program "{programName}"</strong>
            </>
        );
    }

    if (utilityName) {
        return (
            <>
                <strong>{mainCriteriaTitle}</strong> under <strong>Utility "{utilityName}"</strong>
            </>
        );
    }

    return <strong>{mainCriteriaTitle}</strong>;
};

export const getSearchCriteriaText = ({ criteriaList }) => {
    if (criteriaList.length === 0) {
        return null;
    }

    const words = criteriaList.map((c) => c.value);
    const wordsText = words.join(" ");
    let tables = uniq(criteriaList.map((c) => c.mainCriteriaTitle));
    const index = tables.indexOf("");
    if (index !== -1) {
        tables[index] = "Everywhere";
    }
    // for utility and program criteria
    const getProgramText = (programCriteria, index) => {
        const programText =
            programCriteria.children[0].props.children + programCriteria.children[1] + programCriteria.children[2].props.children.join("");
        tables[index] = programText;
    };
    for (var i = 0; i < tables.length; i++) {
        if (tables[i] && tables[i].props && tables[i].props.children.length === 3) {
            getProgramText(tables[i].props, i);
        }
    }
    // for additional criteria
    for (var j = 0; j < tables.length; j++) {
        if (tables[j] && tables[j].props && tables[j].props.children) {
            tables[j] = tables[j].props.children;
        }
    }

    let tablesText =
        tables && tables.some((t) => t.props === undefined) ? arrayToText(tables) : arrayToText(uniq(tables.map((t) => t.props.children)));

    if (isCisSearch({ criteriaList })) {
        return tablesText;
    }

    return (
        <>
            "{wordsText}" in {tablesText}
        </>
    );
};

export const isCisSearch = ({ criteriaList }) => {
    return criteriaList.some((i) => i.field?.startsWith(CIS_KEY_PREFIX));
};

export const isCisSearchCriteria = ({ criteria }) => {
    return criteria?.title === CIS_SEARCH_CRITERIA;
};

export const cisCriteriaListToSearchFilter = ({ criteriaList, searchOptions, pageNumber = undefined, pageSize = undefined }) => {
    const mainSearchCriteriaList = getMainSearchCriteria({ searchOptions });
    const utilityCriteria = mainSearchCriteriaList.find((criteria) => criteria.title === searchTableTitle.UTILITY);

    const utilityKey = utilityCriteria?.key;
    const utilityNumber = getCisUtilityNumber({ criteriaList });
    const searchList = getCisSearchValues({ criteriaList });

    const updatedCriteriaList = [
        {
            mainCriteriaValue: {
                utilityNumber,
                utilityKey,
            },
            value: searchList.map((i) => i.columnValue).join(" "),
        },
    ];

    return getSearchFilter({
        criteriaList: updatedCriteriaList,
        pageNumber,
        pageSize,
    });
};

export const transformCisSearchResult = ({ data }) => {
    const totalResultCount = data.resultCount;
    const resultCount = data.columnValues?.length ?? 0;
    const rows = data.columnValues || [];

    const formatValue = (value) => {
        const result = (value || "").trim();

        return result.length === 0 ? "-" : result;
    };

    return {
        RETURN: {
            TOTAL_RESULT_COUNT: totalResultCount,
            TABLE: {
                TITLE: CIS_SEARCH_CRITERIA,
                RESULT_COUNT: resultCount,
                ROW: rows.map((item) => ({
                    ENTITYID: item[0],
                    ENTITY_TYPE: entityType.customer,
                    FIELD: CIS_RESULT_KEYS.map((key) => key.toUpperCase())
                        .map((key) => {
                            const index = data.columnNames.findIndex((i) => i.toUpperCase() === key);
                            if (index > -1) {
                                return {
                                    TITLE: key,
                                    VALUE: formatValue(item[index]),
                                };
                            }

                            return null;
                        })
                        .filter((item) => item),
                })),
            },
        },
    };
};

export const createApplication = ({ utilityNumber, accountNumber, customerID }) => {
    store.dispatch(
        createResource({
            ...getCisCustomerDetailsResourceParams({
                utilityNumber,
                accountNumber,
                customerID,
            }),
            onSuccess: (action) => {
                const cis = parseCisDetails(action.data);

                const customerDetails = {
                    ...cis,
                    firstName: cis.firstname,
                    lastName: cis.lastname,
                };

                openNewApplication({
                    utilityNumber,
                    customerDetails,
                    //reset: true, ticket V50-8029
                });
            },
        })
    );
};

/**
 * Use this to enable auto open search result if there is only one result returned from search.
 *
 * @param {Object} { isSearching, totalResults, searchResults, onCloseSearch }
 * @returns {Object} useEffect
 */
export const useOpenSearchResult = ({ isSearching, totalResults, searchResults, onCloseSearch }) => {
    const dispatch = useDispatch();

    return useEffect(() => {
        if (!isSearching && totalResults === 1) {
            const row = searchResults.map((table) => toArray(table.ROW)[0])[0];

            // Open result
            if (row && row.ENTITY_TYPE !== entityType.customer) {
                dispatch(
                    openSearchResult({
                        result: row,
                    })
                );

                // Close global search panel
                onCloseSearch && onCloseSearch();
            }
        }
    }, [totalResults, searchResults, isSearching, onCloseSearch, dispatch]);
};

/**
 * Calculate first and last page numbers for paging info.
 *
 * @param {{ pageNumber: number, pageSize: number, totalResults: number }} params Paging params
 * @returns {{ firstRecord: number, lastRecord: number }} paging info
 */
export const getPagingItems = ({ pageNumber, pageSize, totalResults }) => {
    const firstRecord = (pageNumber - 1) * pageSize + 1;
    let lastRecord = pageNumber * pageSize;
    lastRecord = lastRecord > totalResults ? totalResults : lastRecord;

    return { firstRecord, lastRecord };
};
