import { extractRefSchema } from "json-rules-engine-simplified/lib/utils";
import predicate from "predicate";
import { isBoolean, isDate, isNil, isNumber } from "lodash";
import {
    datePartFromJsonDate,
    dateStringToJsonDate,
    dateToJson,
    getDateValueForInput,
    isValidDateFormatFromInput,
} from "components/utils/date";

export const isDevelopment = () => {
    return process.env.NODE_ENV !== "production";
};

export const toArray = (field) => {
    if (isNil(field)) {
        return [];
    }

    if (Array.isArray(field)) {
        return field;
    } else {
        return [field];
    }
};

export const toError = (message) => {
    if (isDevelopment()) {
        throw new ReferenceError(message);
    } else {
        console.error(message);
    }
};

/**
 * Find relevant schema for the field
 * @returns { field: "string", schema: "object" } relevant field and schema
 */
export const findRelSchemaAndField = (field, schema) => {
    let separator = field.indexOf(".");
    if (separator === -1) {
        return { field, schema };
    }

    let parentField = field.substr(0, separator);
    let refSchema = extractRefSchema(parentField, schema);
    if (refSchema) {
        return findRelSchemaAndField(field.substr(separator + 1), refSchema);
    }

    toError(`Failed to retrieve ${refSchema} from schema`);
    return { field, schema };
};

export function findRelUiSchema(field, uiSchema) {
    let separator = field.indexOf(".");
    if (separator === -1) {
        return uiSchema;
    }

    let parentField = field.substr(0, separator);
    let refUiSchema = uiSchema[parentField];
    if (!refUiSchema) {
        return uiSchema;
    } else {
        return findRelUiSchema(field.substr(separator + 1), refUiSchema);
    }
}

export const initRulesRunnerRules = () => {
    const initialEqualPredicate = predicate.equal;

    // Create rule "equal"
    predicate.equal = predicate.curry((fieldVal, ruleVal) => {
        if (predicate.arr(fieldVal)) {
            // handle numeric values
            if (fieldVal.length > 0 && isNumber(fieldVal[0])) {
                return predicate.includes(fieldVal, Number(ruleVal));
            }

            return predicate.includes(fieldVal, ruleVal);
        }

        if (isNumber(fieldVal)) {
            return initialEqualPredicate(fieldVal, Number(ruleVal));
        }

        if (isBoolean(fieldVal)) {
            return initialEqualPredicate(String(fieldVal), ruleVal);
        }

        if (isValidDateFormatFromInput(fieldVal)) {
            const dateValue = getDateValueForInput({ value: fieldVal, isFormatDate: true });
            if (isDate(dateValue)) {
                const ruleDate = ruleVal === "today" ? new Date() : getDateValueForInput({ value: ruleVal, isFormatDate: true });
                return initialEqualPredicate(
                    datePartFromJsonDate(dateStringToJsonDate(fieldVal)),
                    datePartFromJsonDate(dateToJson(ruleDate))
                );
            }
        }

        return initialEqualPredicate(fieldVal, ruleVal);
    });

    // Create rule "notEqual"
    predicate.notEqual = predicate.curry((fieldVal, ruleVal) => {
        if (predicate.arr(fieldVal)) {
            return !predicate.includes(fieldVal, ruleVal);
        }

        return !predicate.equal(fieldVal, ruleVal);
    });

    // Create rule "empty"
    predicate.empty = (fieldVal) => {
        if (predicate.not.exists(fieldVal)) {
            return true;
        }

        if (predicate.arr(fieldVal) || predicate.str(fieldVal)) {
            return !fieldVal.length;
        }

        if (predicate.number(fieldVal)) {
            return false;
        }

        if (predicate.obj(fieldVal)) {
            for (let prop in fieldVal) {
                if (predicate.has(fieldVal, prop)) {
                    return false;
                }
            }

            return true;
        }

        if (isBoolean(fieldVal)) {
            return !fieldVal;
        }

        return false;
    };

    // Create rule "less"
    predicate.less = predicate.curry(function (fieldVal, ruleVal) {
        if (isNumber(fieldVal)) {
            return fieldVal < Number(ruleVal);
        }

        if (isValidDateFormatFromInput(fieldVal)) {
            const dateValue = getDateValueForInput({ value: fieldVal, isFormatDate: true });
            if (isDate(dateValue)) {
                const ruleDate = ruleVal === "today" ? new Date() : getDateValueForInput({ value: ruleVal, isFormatDate: true });
                return datePartFromJsonDate(dateStringToJsonDate(fieldVal)) < datePartFromJsonDate(dateToJson(ruleDate));
            }
        }

        return fieldVal < ruleVal;
    });

    // Create rule "greater"
    predicate.greater = predicate.curry(function (fieldVal, ruleVal) {
        if (isNumber(fieldVal)) {
            return fieldVal > Number(ruleVal);
        }

        if (isValidDateFormatFromInput(fieldVal)) {
            const dateValue = getDateValueForInput({ value: fieldVal, isFormatDate: true });
            if (isDate(dateValue)) {
                const ruleDate = ruleVal === "today" ? new Date() : getDateValueForInput({ value: ruleVal, isFormatDate: true });
                return datePartFromJsonDate(dateStringToJsonDate(fieldVal)) > datePartFromJsonDate(dateToJson(ruleDate));
            }
        }

        return fieldVal > ruleVal;
    });

    // Create rule "lessEq"
    predicate.lessEq = predicate.curry(function (fieldVal, ruleVal) {
        return predicate.equal(fieldVal, ruleVal) || predicate.less(fieldVal, ruleVal);
    });

    // Create rule "greaterEq"
    predicate.greaterEq = predicate.curry(function (fieldVal, ruleVal) {
        return predicate.equal(fieldVal, ruleVal) || predicate.greater(fieldVal, ruleVal);
    });
};
