import React, { memo, useCallback, useId } from "react";
import { useSelector, useDispatch } from "react-redux";
import { confirmApplicationStatusChange } from "./utils";

import WaitIcon from "../../ui/WaitIcon";
import IconWrap from "../../ui/Icons";
import ActionLabelWithDropdown from "../../ui/Label/ActionLabelWithDropdown";
import { formatJsonDateTime } from "../../utils/date";
import ScrollControls from "../../ui/ScrollControls";
import SimilarPremises from "../../ui/Dashboard/Panel/Project/ContactsPanel/SimilarPremises";
import { setActiveDashboardTool } from "../../../store/dashboards/actions";
import { windowContainerTypes } from "../../utils/window";
import { systemUserRights } from "../../utils/user";
import { reapplySelectedStatusText } from "../../ui/Dashboard/Panel/Project/utils";
import { useProgramRights } from "store/resources/useResource";

const ProjectSubInfoLine = memo(({ applicationNumber, statuses, isLoadingStatuses }) => {
    const targetId = useId();
    const dispatch = useDispatch();
    const application = useSelector((state) => state.resources.applicationDetails?.itemsById[applicationNumber]);

    const programNumber = useSelector((state) => state.resources.applicationDetails?.itemsById[applicationNumber]).programNumber;
    const [programRights = []] = useProgramRights({ programNumber });

    const isMobile = useSelector((state) => state.window?.isMobile);
    const isTablet = useSelector((state) => state.window?.isTablet);
    const isSplitView = useSelector((state) => state.window[windowContainerTypes.Root].views.find((view) => view.active)?.isSplitView);

    const onChangeStatus = useCallback(
        (status) => {
            confirmApplicationStatusChange({
                application,
                status,
                applicationNumber,
            });
        },
        [applicationNumber, application]
    );

    const isLocked = application.isLocked;
    const canChangeStatus = !isLocked && statuses.length > 1 && programRights.includes(systemUserRights.VISIONDSM_MANAGE_APPLICATIONS);
    const canReapplyStatus = !isLocked && programRights.includes(systemUserRights.VISIONDSM_MANAGE_APPLICATIONS);

    const handleOpenSimilarPremises = (panel) => {
        dispatch(
            setActiveDashboardTool({
                entityNumber: applicationNumber,
                tool: panel.type,
            })
        );
    };

    const handleReapplyChangeStatus = useCallback(() => {
        if (application && application.status && canReapplyStatus) {
            const status = {
                status: application.status,
                workflowNumber: application.wfNumber,
            };
            confirmApplicationStatusChange({
                application,
                status,
                applicationNumber,
            });
        }
    }, [application, applicationNumber, canReapplyStatus]);

    if (!application) {
        return null;
    }

    return (
        <div className="sub-info-line sub-info">
            <div className="main-grid-wrap responsive">
                <div className="sub-info-wrapper">
                    <div id={targetId} className="sub-info-items">
                        {(isMobile || isTablet || isSplitView) && (
                            <div className="sub-info-item similar-premises">
                                <SimilarPremises
                                    applicationNumber={applicationNumber}
                                    onOpenDashboardPanel={(panel) => handleOpenSimilarPremises(panel)}
                                />
                            </div>
                        )}
                        <div className="sub-info-item status-not-desktop">
                            <span>Status:</span>
                            {isLoadingStatuses && <WaitIcon />}
                            {!isLoadingStatuses && !canChangeStatus && application.status}
                            {!isLoadingStatuses && canChangeStatus && (
                                <ActionLabelWithDropdown
                                    iconWithLabel="shevron-small-down-expand-more"
                                    mobileHeader="Change Application Status to"
                                    label={application.status}
                                    items={statuses}
                                    onClick={(status) => onChangeStatus(status)}
                                    zIndexOverride="23"
                                    popperEnabled
                                />
                            )}
                            {!isLoadingStatuses && canReapplyStatus && (
                                <IconWrap
                                    iconWrapClickable
                                    iconWrap="repeat"
                                    title={reapplySelectedStatusText}
                                    onClick={handleReapplyChangeStatus}
                                ></IconWrap>
                            )}
                        </div>
                        <div className="sub-info-item">
                            <span>Status Updated:</span>
                            {`${formatJsonDateTime(application.dateStatusUpdated, "-")}`}
                        </div>
                        <div className="sub-info-item">
                            <span>Last Edited:</span>
                            {`${formatJsonDateTime(application.dateEdited, "-")}`}
                        </div>
                        <div className="sub-info-item">
                            <span>Received:</span>
                            {`${formatJsonDateTime(application.dateCreated, "-")}`}
                        </div>
                        <div className="sub-info-item">
                            <span>Entry Point:</span>
                            {application.entryFlag}
                        </div>
                        <div className="sub-info-item">
                            <span>Entered By:</span>
                            {application.enteredBy || "-"}
                        </div>
                    </div>
                    <ScrollControls targetId={targetId} />
                </div>
            </div>
        </div>
    );
});

export default ProjectSubInfoLine;
