import React from "react";
import { PropertyListItem } from "./PropertyListItem";
import { SwitchProperty } from "./SwitchProperty";
import { TextProperty } from "./TextProperty";
import { ValidationRule } from "../../types";
import { isEmpty } from "lodash";

export const HeaderSignOutProperty = (props) => {
    const { value, onChange, errors = {} } = props;

    const containsActiveError = !isEmpty(errors.headerSignOutLinkText);

    return (
        <PropertyListItem borderBottom={false}>
            <SwitchProperty
                title={"Show sign out link"}
                id={"showHeaderSignOutLink"}
                value={value.showHeaderSignOutLink ?? false}
                onChange={(id, value) => onChange(id, value)}
                borderBottom={false}
                fullWidth
            />
            {value.showHeaderSignOutLink && (
                <div className="property-list--level-2">
                    <TextProperty
                        errors={errors.headerSignOutLinkText}
                        id="headerSignOutLinkText"
                        value={value.headerSignOutLinkText}
                        fullWidth={true}
                        defaultValue="Sign Out"
                        borderTop={false}
                        borderBottom={false}
                        onChange={(id, value) => onChange(id, value)}
                        listContainsActiveError={containsActiveError}
                        title="Link text"
                        validationRules={[ValidationRule.Required]}
                    />
                </div>
            )}
        </PropertyListItem>
    );
};
