import React, { memo, useCallback, useContext, useMemo, useRef } from "react";
import { cloneDeep, isNil, set } from "lodash";
import { PropertyList } from "../PropertyList";
import { PortalBuilderContext } from "components/ui/PortalBuilder/contexts";
import { getContentPageErrors } from "components/ui/PortalBuilder/utils/validation";
import {
    contactUsThankYouPageProperties,
    getPageIndexByLink,
    getPageProperties,
    isPageOverridden,
    resetProgramLevelPage,
} from "../../utils/page";
import { PageEditorInfo } from "./PageEditorInfo";
import { HeaderNavigation, PageConfiguration, PortalTemplateConfiguration, ProgramTemplateConfiguration, PropertyName } from "../../types";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";
import { useErrorContext } from "../../PortalBuilderErrorContextProvider";

const PageEditor = memo(({ onChange }: PageEditorProps) => {
    const { onActivePageChange, template } = useContext(PortalBuilderContext);
    const { errors = [] } = useErrorContext()!;

    const [config] = usePortalBuilderState((state) => state.updatedConfig);
    const [activePageUrl] = usePortalBuilderState((state) => state.activePageUrl);
    const [isProgramPortalBuilder] = usePortalBuilderState((state) => state.isProgramPortalBuilder);
    const [activeError] = usePortalBuilderState((state) => state.activeError);

    const pageIndex = useMemo(() => getPageIndexByLink(activePageUrl, config), [activePageUrl, config]);
    const allPages = config?.content?.pages ?? [];
    const page = allPages[pageIndex];

    // Strangely without using the ref the config is not always up to date in `onConfigChange` callback.
    const configRef = useRef<PortalTemplateConfiguration>(config);
    configRef.current = config;

    const activeSectionErrors = getContentPageErrors(errors, pageIndex);

    const isInactiveStartPage =
        page?.link === "/" &&
        !!(config as ProgramTemplateConfiguration).settings.programStartPage &&
        (config as ProgramTemplateConfiguration).settings.programStartPage !== "/";

    const showPageInfo = isProgramPortalBuilder && (page?.isShared || isInactiveStartPage);

    const containsActiveError = pageIndex === activeError?.pageIndex && !activeError?.rowIndex && !activeError?.columnIndex;

    const onConfigChange = useCallback(
        (id: string, value: any, additionalValues = []) => {
            const updatedConfig = cloneDeep(configRef.current);

            // Update page properties
            let updatedPage: PageConfiguration = [{ id, value }, ...additionalValues].reduce((result, { id, value }) => {
                return {
                    ...result,
                    [id]: value,
                };
            }, updatedConfig?.content?.pages[pageIndex] ?? {});
            const externalLink = updatedPage.linkType === "external";

            // Update order for all pages if page is removed from header navigation
            if ((!updatedPage.headerNavigation || updatedPage.headerNavigation === HeaderNavigation.NEVER) && !isNil(updatedPage.order)) {
                const removedOrder = updatedPage.order;

                // Clear page order.
                updatedPage.order = undefined;

                // Decrease other page order.
                updatedConfig.content?.pages.forEach((p, index) => {
                    if (!isNil(p.order) && p.order > removedOrder && index !== pageIndex) {
                        p.order--;
                    }
                });
            }

            // Set page order with next available number if page order is empty and it is added to header navigation
            if (updatedPage.headerNavigation && updatedPage.headerNavigation !== HeaderNavigation.NEVER && isNil(updatedPage.order)) {
                const order = (updatedConfig?.content?.pages ?? []).map((p) => p.order ?? 0);
                updatedPage.order = Math.max(...order) + 1;
            }

            // Make sure that external link is always empty after switching and internal starts with slash
            if (id === "linkType") {
                if (updatedPage._isHomePage && externalLink && updatedPage.link === "/") {
                    updatedPage.link = "";
                }
                if (updatedPage._isHomePage && !externalLink && updatedPage.link !== "/") {
                    updatedPage.link = "/";
                }
            }

            set(updatedConfig, `content.pages[${pageIndex}]`, updatedPage);

            // Update order for other pages if order property was changed
            if (id === PropertyName.Order) {
                const prevOrder = configRef.current?.content?.pages[pageIndex]?.order ?? 0;
                const nextOrder = updatedPage.order ?? 0;
                const isOrderIncreased = nextOrder > prevOrder;

                if (isOrderIncreased) {
                    // Decrease order for other pages where order is bigger than prevOrder and is smaller or equal than nextOrder.
                    updatedConfig.content?.pages.forEach((p, index) => {
                        if (!isNil(p.order) && p.order > prevOrder && p.order <= nextOrder && index !== pageIndex) {
                            p.order--;
                        }
                    });
                } else {
                    // Increase order for other pages where order is smaller than prevOrder and equal or higher than nextOrder
                    updatedConfig.content?.pages.forEach((p, index) => {
                        if (!isNil(p.order) && p.order < prevOrder && p.order >= nextOrder && index !== pageIndex) {
                            p.order++;
                        }
                    });
                }
            }

            onChange(updatedConfig);
            if (id === "link") {
                onActivePageChange(value);
            }
        },
        [onChange, pageIndex, onActivePageChange]
    );

    if (!page) {
        return null;
    }

    const pageProperties = getPageProperties(page, allPages, isProgramPortalBuilder);
    const isOverridden = isPageOverridden(page, isProgramPortalBuilder, template?.utilityTemplateConfiguration, pageProperties);

    // Reset program level page properties to utility level page properties.
    const onPageReset = () => {
        if (isProgramPortalBuilder) {
            const updatedPage = resetProgramLevelPage(page, template?.utilityTemplateConfiguration);

            if (updatedPage) {
                const updatedConfig = cloneDeep(configRef.current);
                set(updatedConfig, `content.pages[${pageIndex}]`, updatedPage);
                onChange(updatedConfig);
            }
        }
    };

    return (
        <div className="portal-builder-page-editor with-scroll">
            {showPageInfo && (
                <PageEditorInfo isOverridden={isOverridden} isInactiveStartPage={isInactiveStartPage} onPageReset={onPageReset} />
            )}
            {(!page["_activeTab"] || page["_activeTab"]?.id === "mainPage") && (
                <PropertyList
                    className="with-scroll"
                    errors={activeSectionErrors}
                    config={page}
                    items={pageProperties}
                    onChange={onConfigChange}
                    containsActiveError={containsActiveError}
                />
            )}
            {page.link === "/contact-us" && page["_activeTab"]?.id === "thankYou" && (
                <PropertyList
                    className="with-scroll"
                    errors={activeSectionErrors}
                    config={page}
                    items={contactUsThankYouPageProperties}
                    onChange={onConfigChange}
                    containsActiveError={containsActiveError}
                />
            )}
        </div>
    );
});

interface PageEditorProps {
    /** Configuration change callback */
    onChange: (config: PortalTemplateConfiguration) => void;
}

export default PageEditor;
