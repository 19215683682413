import React, { memo } from "react";
import ClassNames from "classnames";

import IconWrap from "../Icons";
import StatusMsg from "../StatusMsg";
import Dropzone from "react-dropzone";
import { fromEvent } from "file-selector";

import "./style.scss";
import { MAX_FILE_SIZE } from "components/utils/files";

const UploadFile = memo(({ className, disabled, multiple, files, removeFile, accept, id, onDrop }) => {
    const classNames = ClassNames("upload-file-area", className, {
        "upload-file-single": !multiple,
        "upload-file-multiple": multiple,
        "file-uploaded": files && files.length > 0,
        "file-upload-disabled": disabled,
    });

    return (
        <Dropzone
            maxSize={MAX_FILE_SIZE}
            className={classNames}
            disabled={disabled || (!multiple && files && files.length > 0)}
            multiple={multiple}
            getDataTransferItems={(e) => fromEvent(e)}
            accept={accept}
            id={id}
            onDrop={onDrop}
        >
            <div className="dropzone-text">
                <IconWrap iconWrap="upload"></IconWrap>
                <span>
                    <span className="single-upload-text">Drop a file to attach, or</span>
                    <span className="multiple-upload-text">Drop files to attach, or</span>
                    <span className="upload-link">browse</span>
                </span>
            </div>
            {files && files.length > 0 && (
                <ul className="dropzone-file-list">
                    {files.map((file, i) => {
                        const { name, size } = file;
                        return (
                            <li key={i}>
                                <StatusMsg msgStandard msgIcon="attachment" msgText={`${name} (${size} bytes)`}></StatusMsg>
                                <IconWrap
                                    iconWrapClickable
                                    iconWrap="clear-close"
                                    title="Remove"
                                    onClick={(e) => removeFile(e, file)}
                                ></IconWrap>
                            </li>
                        );
                    })}
                </ul>
            )}
        </Dropzone>
    );
});

export default UploadFile;
