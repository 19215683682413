import React from "react";
import { connect } from "react-redux";

import { construct } from "../../../../../../../store/dataGrid/actions";
import { availableGrids, programsAuditsGridColumnKeys } from "../../../../../../views/configureGrids";
import { searchAttrToFilter } from "../../../../../../utils/datagrid";
import { exportDatagridToCSV } from "../../../../../../utils/CSV";

import PanelHeaderLarge from "../../../../../Headers/PanelHeaderLarge";
import WaitIcon from "../../../../../WaitIcon";
import DataGrid from "../../../../../DataGrid";
import IconWithLabel from "../../../../../../ui/Icons/IconWithLabel";

class AuditsPanel extends React.Component {
    constructor(props) {
        super(props);

        const { programNumber } = this.props;

        this.gridId = `${programNumber}-audits`;
    }

    componentDidMount() {
        const { programNumber, dispatch } = this.props;

        dispatch(
            construct({
                dataGridId: availableGrids.programsAudits,
                dataGridInstanceId: this.gridId,
                filter: searchAttrToFilter(`${programsAuditsGridColumnKeys.programNumber}=${programNumber}`),
                data: true,
            })
        );
    }

    render() {
        const { panel, dataGrid, onToggleTempPanel, programNumber } = this.props;

        return (
            <div className="panel panel-audits sticky-grid-list-panel">
                <PanelHeaderLarge title={panel?.name} childTitle="Audits" onClick={() => onToggleTempPanel()} />
                <div className="data-grid-controls flex-row justify-end">
                    <IconWithLabel
                        withHandMadeIcon
                        onClick={() =>
                            exportDatagridToCSV({
                                dataGridId: this.gridId,
                                fileName: "program_history",
                                fileNamePostfix: programNumber,
                            })
                        }
                    >
                        Export CSV
                    </IconWithLabel>
                </div>
                {!dataGrid[this.gridId] && <WaitIcon />}
                {dataGrid[this.gridId] && <DataGrid name={this.gridId} config={dataGrid[this.gridId]} customRowActions={[]} />}
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const { dataGrid } = state;
    const programNumber = ownProps.panel && ownProps.panel.data && ownProps.panel.data.programNumber;

    return {
        programNumber,
        dataGrid,
    };
}

export default connect(mapStateToProps)(AuditsPanel);
