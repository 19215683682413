import React, { memo, useState, useRef } from "react";

import FieldGroup from "../FieldGroup";
import FieldWrap from "../FieldWrap";
import IconWrap from "../Icons";
import DropdownList from "../DropdownList";
import DropdownListItem from "../DropdownList/DropdownListItem";
import { IconWithDropdownContext } from "./IconWithDropdownContext";
import useOnKey from "../../utils/useOnKey";
import useOnClickOutside from "../../utils/useOnClickOutside";

const IconWithDropdown = memo((props) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const wrapRef = useRef();

    const icon = isDropdownOpen ? props.iconActive ?? props.icon : props.icon;

    const close = () => {
        if (isDropdownOpen) {
            setIsDropdownOpen(false);
        }
    };

    const onIconClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const onItemClick = (item) => {
        item.onClick();
        close();
    };

    useOnKey("Escape", close);
    useOnClickOutside(wrapRef, close);

    return (
        <FieldGroup className="icon-with-dropdown" inline fieldGroupRef={wrapRef}>
            <FieldWrap iconDropdown>
                <IconWrap
                    iconWrapBig={props.iconWrapBig}
                    iconWrapWhite={props.iconWrapWhite}
                    iconWrapClickable
                    iconWrapDropdown={props.iconWrapDropdown}
                    iconWrapActive={isDropdownOpen}
                    iconWrap={icon}
                    onClick={onIconClick}
                    title={props.title}
                    disabled={props.disabled}
                ></IconWrap>
                <DropdownList
                    alone
                    mobileHeader={props.mobileHeader}
                    dropdownFixedWidth={props.dropdownFixedWidth}
                    withTitle={props.withTitle}
                    visible={isDropdownOpen}
                    dropdownRight={props.dropdownRight}
                    onClose={close}
                >
                    {props.items.map((item, i) => (
                        <DropdownListItem key={i} selected={item.selected} icon={item.icon} onClick={() => onItemClick(item)}>
                            {item.text}
                        </DropdownListItem>
                    ))}

                    <IconWithDropdownContext.Provider value={{ close: close }}>{props.children}</IconWithDropdownContext.Provider>
                </DropdownList>
            </FieldWrap>
        </FieldGroup>
    );
});

export default IconWithDropdown;
