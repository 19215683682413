import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isNil } from "lodash";

import ProgramFilter from "./ProgramFilter";
import AddNewItemPanel from "../../ui/AddNewItemPanel";
import IconWrap from "../../ui/Icons";
import Button from "../../ui/Button";
import WaitIcon from "../../ui/WaitIcon";

import { setWorkqueueDataSourceFilterExpanded, dataSourceChanged } from "../../../store/workcenter/actions";
import { useResource } from "../../../store/resources/useResource";
import ProgramFiltersGrid from "./ProgramFiltersGrid";
import {
    getDataSourceProgramFilter,
    defaultProgramFilter,
    getStatusesFromProgramFilters,
    onFilterShare,
    onFilterCreatePrompt,
    onFilterUpdatePrompt,
    onFilterRemovePrompt,
} from "./utils";
import { setWorkqueueGridFilter, setWorkqueueGrid } from "../../../store/workcenter/actions";
import InlinePanelHeader from "./../../ui/Dashboard/Panel/InlinePanelHeader";
import { WorkCenterContext } from ".";

const ProgramFilters = ({ activeDataSource }) => {
    const { instanceId } = useContext(WorkCenterContext);

    const isNewFilter = activeDataSource.datasourceNumber === "0";

    const dispatch = useDispatch();
    const [dataSources, isLoadingDataSources] = useResource({
        resourceName: "workcenter",
        resourceId: isNewFilter ? null : activeDataSource.datasourceNumber,
    });

    const filterFormRefs = useRef([]);

    const [programFilters, setProgramFilters] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);

    const isMobile = useSelector((state) => state.window?.isMobile);
    const dataSourceFilterExpanded = useSelector((state) => state.workcenter[instanceId].dataSourceFilterExpanded);

    useEffect(() => {
        if (!isLoadingDataSources) {
            const filters = getDataSourceProgramFilter(dataSources);

            setProgramFilters(filters);

            filterFormRefs.current = filters.map((f) => React.createRef());
        }
    }, [isLoadingDataSources, dataSources]);

    useEffect(() => {
        if (programFilters && programFilters.length === 0) {
            setIsEditMode(true);
        }
    }, [programFilters]);

    useEffect(() => {
        if (!isEditMode && isNewFilter) {
            setIsEditMode(true);
        }
    }, [isEditMode, isNewFilter, activeDataSource, programFilters]);

    const onFilterAdd = () => {
        setProgramFilters(
            programFilters.concat({
                ...defaultProgramFilter,
            })
        );

        filterFormRefs.current.push(React.createRef());
    };

    const onFilterChange = (filter, index) => {
        setProgramFilters(
            programFilters.map((f, i) => {
                if (i === index) {
                    return {
                        ...filter,
                    };
                }

                return {
                    ...f,
                };
            })
        );
    };

    const removeGrid = () => {
        dispatch(setWorkqueueGrid({ instanceId, workqueueGrid: null }));
        dispatch(setWorkqueueGridFilter({ instanceId, workqueueGridFilter: null }));
    };

    const onFilterRemove = (filter, index) => {
        setProgramFilters(programFilters.filter((f, i) => i !== index));

        filterFormRefs.current = filterFormRefs.current.filter((f, i) => i !== index);

        removeGrid();
    };

    const onFilterSaved = () => {
        setIsEditMode(false);

        removeGrid();
    };

    const onCancel = () => {
        setIsEditMode(false);

        // reset to original filters
        const filters = getDataSourceProgramFilter(dataSources);

        setProgramFilters(filters);
        filterFormRefs.current = filters.map((f) => React.createRef());
    };

    const onFilterCreateClose = () => {
        setProgramFilters([]);
        filterFormRefs.current = [];
        dispatch(dataSourceChanged({ instanceId, dataSource: undefined }));
    };

    const onFilterClose = useCallback(() => {
        dispatch(
            setWorkqueueDataSourceFilterExpanded({
                instanceId,
                dataSourceFilterExpanded: !dataSourceFilterExpanded,
            })
        );
    }, [instanceId, dataSourceFilterExpanded, dispatch]);

    if (isLoadingDataSources || isNil(programFilters)) {
        return <WaitIcon />;
    }

    const isSaveDisabled = getStatusesFromProgramFilters(programFilters).length === 0;

    return (
        <div
            className={"program-filters fill-height flex-column" + (isNewFilter ? " add-new-filter" : "") + (isEditMode ? " is-edit" : "")}
        >
            {!isNewFilter && (
                <div className={"filters-grid__lead-block flex-row align-center justify-center" + (isEditMode ? " edit" : "")}>
                    {isEditMode ? (
                        <>
                            <IconWrap icon="edit-empty" />
                            <span>Edit Filter</span> "{activeDataSource.datasourceName}"
                            <IconWrap icon="clear-close" title="Cancel Filter Edit" onClick={onCancel} />
                        </>
                    ) : (
                        <>
                            <span>Filter</span> "{activeDataSource.datasourceName}"
                            <IconWrap
                                title="Hide Filter Details"
                                icon={isMobile ? "clear-close" : "shevron-in-circle-left-filled"}
                                onClick={onFilterClose}
                            />
                        </>
                    )}
                </div>
            )}
            {!isEditMode && (
                <ProgramFiltersGrid
                    programFilters={programFilters}
                    isShared={activeDataSource.isShared}
                    onEdit={() => setIsEditMode(true)}
                    onShare={() => onFilterShare({ activeDataSource, programFilters })}
                    onDelete={() => onFilterRemovePrompt({ instanceId, activeDataSource })}
                />
            )}
            {isEditMode && (
                <>
                    {isNewFilter && <InlinePanelHeader titleIcon="plus" title="Add New Filter" onClose={onFilterCreateClose} />}
                    <div className="program-filters-list flex-one-in-column">
                        {programFilters.map((filter, index) => (
                            <ProgramFilter
                                key={index}
                                formRef={filterFormRefs.current[index]}
                                values={filter}
                                onChange={(filter) => onFilterChange(filter, index)}
                                onRemove={programFilters.length > 1 ? (filter) => onFilterRemove(filter, index) : undefined}
                            />
                        ))}
                        <AddNewItemPanel text="Add Program to Filter" onClick={onFilterAdd}></AddNewItemPanel>
                    </div>
                    <div className="filter-footer flex-row">
                        {isNewFilter ? (
                            <div className="action-buttons flex-row fill-width">
                                <Button
                                    disabled={isSaveDisabled}
                                    primary
                                    onClick={() =>
                                        onFilterCreatePrompt({
                                            instanceId,
                                            programFilters,
                                            filterFormRefs,
                                        })
                                    }
                                >
                                    Create filter
                                </Button>
                                <Button standard onClick={onFilterCreateClose}>
                                    Cancel
                                </Button>
                            </div>
                        ) : (
                            <>
                                <Button
                                    primary
                                    disabled={isSaveDisabled}
                                    onClick={() =>
                                        onFilterUpdatePrompt({
                                            instanceId,
                                            activeDataSource,
                                            programFilters,
                                            filterFormRefs,
                                            onComplete: onFilterSaved,
                                        })
                                    }
                                >
                                    Save
                                </Button>
                                <Button standard onClick={onCancel}>
                                    Cancel
                                </Button>
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default ProgramFilters;
