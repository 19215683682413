import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { useBudgetLinePrograms } from "../../../../../../store/resources/useResource";
import { onRefreshAssignmentsList } from "../utils";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import { ListHeader } from "../../../../List/ListHeader";
import { systemUserRights } from "components/utils/user";
import { useUtilityRights } from "../../../../../../store/resources/useResource";

import WaitIcon from "../../../../WaitIcon";
import CustomList from "../../../../List/CustomList";
import TileItem from "../../../../TilesWrap/TileItem";
import IconWrap from "../../../../Icons";
import NothingFoundBlock from "../../../../NothingFoundBlock";
import IconWithLabel from "../../../../Icons/IconWithLabel";
import Form from "./Form";

const List = memo(({ utilityNumber, contractNumber, budgetLineNumber, onOpen, onDelete }) => {
    const dispatch = useDispatch();

    const [utilityRights, isLoadingRights] = useUtilityRights({ utilityNumber });

    const canAssign =
        utilityRights.includes(systemUserRights.VISIONDSM_ADD_BUDGET) || utilityRights.includes(systemUserRights.VISIONDSM_MANAGE_BUDGET);

    const [rows, isLoading] = useBudgetLinePrograms({
        utilityNumber,
        contractNumber,
        budgetLineNumber,
    });

    const assignedRows = rows.filter((r) => r.assigned.toLowerCase() === "yes");

    const onRefresh = useCallback(() => {
        onRefreshAssignmentsList({
            utilityNumber,
            contractNumber,
            budgetLineNumber,
            dispatch,
        });
    }, [utilityNumber, contractNumber, budgetLineNumber, dispatch]);

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "budget-program-assignments-sidenav-panel",
    });

    const handleAddAssignedPrograms = useCallback(() => {
        handleOpenSidePanel(
            <Form
                utilityNumber={utilityNumber}
                contractNumber={contractNumber}
                budgetLineNumber={budgetLineNumber}
                gridRefresh={onRefresh}
                onClose={handleCloseSidePanel}
            />
        );
    }, [utilityNumber, budgetLineNumber, contractNumber, onRefresh, handleOpenSidePanel, handleCloseSidePanel]);

    const renderItem = (item, index) => {
        return (
            <TileItem key={index} similarTiles title={item.programFriendlyName} onClick={() => onOpen(item)}>
                <div className="tile-action-icons flex-column">
                    <IconWrap
                        title="Remove Assignment"
                        icon="hub-filled remove"
                        iconWrapCombined
                        onClick={(e) => {
                            e.stopPropagation();
                            onDelete(item, rows);
                        }}
                        disabled={!canAssign}
                    />
                    <IconWrap icon="open-new-window" title="Manage Program" onClick={() => onOpen(item)} />
                </div>
            </TileItem>
        );
    };

    return (
        <div className="budget-program-assignments__list flex-one-in-column no-scroll">
            {isLoading || isLoadingRights ? (
                <WaitIcon />
            ) : (
                <div className="budget-program-assignments__programs-list-wrap flex-column fill-height no-scroll">
                    {assignedRows.length > 0 ? (
                        <>
                            <ListHeader
                                className="budget-program-assignments__title"
                                count={assignedRows.length}
                                title="Assigned Programs"
                                onAdd={handleAddAssignedPrograms}
                                addActionTitle="Add Assigned Programs"
                                addActionDisabled={!canAssign}
                            />
                            <CustomList
                                items={assignedRows}
                                searchFilters={{
                                    programFriendlyName: "",
                                }}
                                searchPlaceholder="Search programs"
                                renderItem={renderItem}
                            />
                        </>
                    ) : (
                        <NothingFoundBlock nothingFoundBlockMargin icon="programs" title="No Program Assignments">
                            <IconWithLabel icon="plus" onClick={() => handleAddAssignedPrograms()}>
                                Add Assigned Programs
                            </IconWithLabel>
                        </NothingFoundBlock>
                    )}
                </div>
            )}
        </div>
    );
});

export default List;
