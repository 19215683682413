import React from "react";

import { store } from "../../../store/configureStore";
import { modalClose, modalOpen } from "../../../store/modal/actions";

import CIS from ".";
import CisSearchGrid from "./CisSearchGrid";
import ApplicationContactStoredCisDetails from "./ApplicationContactStoredCisDetails";

export const onApplicationContactStoredCIS = ({ applicationNumber, contactNumber }) => {
    store.dispatch(
        modalOpen({
            modalType: "MODAL",
            modalProps: {
                title: "CIS Stored Customer Details",
                overlayClassName: "modal-styled",
                className: "cis-lookup-modal modal-sm",
                modalIcon: "library-empty",
                withScroll: true,
                children: <ApplicationContactStoredCisDetails applicationNumber={applicationNumber} contactNumber={contactNumber} />,
            },
        })
    );
};

export const onCisVerify = ({ utilityNumber, contact, searchType }) => {
    const close = () => {
        store.dispatch(modalClose());
    };

    store.dispatch(
        modalOpen({
            modalType: "MODAL",
            modalProps: {
                title: "Premise Account Information via CIS Lookup",
                overlayClassName: "modal-styled",
                className: "cis-lookup-modal modal-sm",
                modalIcon: "library-empty",
                withScroll: true,
                children: <CIS utilityNumber={utilityNumber} contact={contact} searchType={searchType} onClose={close} />,
            },
        })
    );
};

export const onCisLookup = ({ utilityNumber, contact, onSelect, newAppCreation }) => {
    const close = () => {
        store.dispatch(modalClose());
    };

    store.dispatch(
        modalOpen({
            modalType: "MODAL",
            modalProps: {
                title: "Search CIS",
                overlayClassName: "modal-styled",
                className: "cis-modal modal-with-grid modal-lg",
                modalIcon: "search",
                children: (
                    <CisSearchGrid
                        utilityNumber={utilityNumber}
                        contact={contact}
                        onSelect={onSelect}
                        onClose={close}
                        newAppCreation={newAppCreation}
                    />
                ),
            },
        })
    );
};
