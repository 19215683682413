import React, { useContext, useRef } from "react";
import { PortalBuilderContext } from "components/ui/PortalBuilder/contexts";
import { PropertyList } from "../../PropertyList";
import { set, cloneDeep } from "lodash";
import { PortalTemplateConfiguration, PropertyValue } from "components/ui/PortalBuilder/types";
import { LIST_GLOBAL_PROPERTIES } from "components/ui/PortalBuilder/utils";
import { PropertyListItem } from "../../Property/PropertyListItem";
import { usePortalBuilderState } from "components/ui/PortalBuilder/PortalBuilderContextProvider";

import "./GlobalListProperties.scss";

export const GlobalListProperties = () => {
    const { onConfigChange } = useContext(PortalBuilderContext);
    const [updatedConfig] = usePortalBuilderState((state) => state.updatedConfig);

    const sectionConfig = updatedConfig?.widgets?.list;

    const configRef = useRef<PortalTemplateConfiguration>(updatedConfig);
    configRef.current = updatedConfig;

    const onPropertyChange = (id: string, value: PropertyValue) => {
        let updatedConfigClone = cloneDeep(configRef.current);
        set(updatedConfigClone, `widgets.list[${id}]`, value);
        onConfigChange(updatedConfigClone);
    };

    return (
        <div className="flex-column fill-width with-scroll">
            <PropertyListItem className="list-properties property-list-item--prop-group">
                {/* @ts-ignore */}
                <PropertyList
                    items={LIST_GLOBAL_PROPERTIES}
                    config={sectionConfig}
                    nestingLevel={1}
                    onChange={onPropertyChange}
                    isExpanded
                />
            </PropertyListItem>
        </div>
    );
};
