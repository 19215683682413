import React, { useCallback } from "react";
import ClassNames from "classnames";

import IconWrap from "../Icons";
import useFocusClasses from "../../utils/useFocusClasses";
import useEnterKey from "../../utils/useEnterKey";

import "./style.scss";

const TagButton = (props) => {
    const isTabable = !props.readonly && Boolean(props.onFullItemClick);
    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled: !isTabable,
    });
    const [onEnter] = useEnterKey({
        disabled: !isTabable,
        onClick: props.onFullItemClick,
    });

    const className = ClassNames("btn btn-rounded btn-tag", props.tagWithIcon, props.className, {
        active: props.active,
        "btn-view": props.readonly,
        "btn-tag-disabled": props.disabled,
        "edit-type": props.edit,
        "btn-tag-hidden": props.hidden,
        "btn-tag-not-removable": props.notRemovable,
        "with-icon": props.tagWithIcon,
        "without-icon": props.tagWithoutIcon,
        selectable: props.tagSelectable,
        "btn-tag-add": props.addTag,
        "btn-tag-multiline": props.multiline,
        "btn-tag-clickable": props.onFullItemClick,
    });

    const onIconClick = useCallback(
        (event) => {
            event.stopPropagation();
            props.onClick && props.onClick(event);
        },
        [props]
    );

    return (
        <div
            ref={props.elementRef}
            className={className}
            onClick={props.onFullItemClick}
            title={props.tagTitle}
            tabIndex={isTabable ? "0" : "-1"}
            onFocus={onFocusClassesFocus}
            onBlur={onFocusClassesBlur}
            onKeyDown={onEnter}
        >
            {props.children} {props.text}
            {props.edit && (
                <IconWrap
                    title={props.title}
                    iconWrapClickable
                    iconWrap={props.notRemovable ? "shevron-circle-left-empty" : "clear-circle"}
                    onClick={onIconClick}
                ></IconWrap>
            )}
        </div>
    );
};

export default TagButton;
