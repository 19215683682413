import { useResource } from "store/resources/useResource";
import { getResource } from "store/resources/actions";

import { Localize } from "components/utils/text";
import { listToAnyOf } from "components/utils/form";
import { dashboardTypes, getDashboardNameByType } from "components/utils/dashboard";

export const USER_TYPES = {
    2: "Client User",
    1: "Internal User",
};

export const DEFAULT_STYLE = {
    theblues: "Normal",
    clean: "Clean",
    desert: "Desert",
    dusk: "Dusk",
    earth: "Earth",
    itix: "iTix",
    night: "Night",
    valentine: "Valentine",
};

export const PASSWORD_GROUPS = {
    1: "Default Password Group",
    2: "Scana Password Group",
};

export const USER_STATUS = {
    active: "Active",
    disabled: "Disabled",
    expired: "Expired",
    suspended: "Suspended",
};

const userTypeOptions = Object.values(USER_TYPES).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

export const defaultSchema = (editSchema, companies, clients, serverGroups, ssoGroups) => {
    const statusFieldSchema = editSchema
        ? {
              status: {
                  type: "string",
                  title: "Status",
                  enum: Object.values(USER_STATUS),
              },
          }
        : {};

    const required = (editSchema ? ["status"] : []).concat([
        "clientNumber",
        "userType",
        "firstName",
        "lastName",
        "company",
        "email",
        "passwordRuleId",
    ]);

    return {
        type: "object",
        required,
        properties: {
            ...(editSchema
                ? {
                      userName: {
                          type: "string",
                          title: "User Name",
                      },
                  }
                : {}),
            clientNumber: {
                type: "string",
                title: "Client",
                anyOf: listToAnyOf({
                    list: clients,
                    map: (client) => ({
                        title: client.client,
                        enum: [client.clientNumber],
                    }),
                }),
            },
            userType: {
                type: "string",
                title: "User type",
                enum: userTypeOptions,
            },
            firstName: {
                type: "string",
                title: "First Name",
            },
            lastName: {
                type: "string",
                title: "Last Name",
            },
            company: {
                type: "string",
                title: "Company",
                anyOf: listToAnyOf({
                    list: companies,
                    map: (company) => ({
                        title: company.display,
                        enum: [company.display],
                    }),
                }),
            },
            address1: {
                type: "string",
                title: "Address",
            },
            address2: {
                type: "string",
                title: "Address (continued)",
            },
            city: {
                type: "string",
                title: "City",
            },
            state: {
                type: "string",
                title: Localize.STATE,
            },
            zip: {
                type: "string",
                title: "Postal Code",
            },
            phone: {
                type: "string",
                title: "Phone",
            },
            cell: {
                type: "string",
                title: "Cell",
            },
            email: {
                type: "string",
                title: "Email",
            },
            passwordRuleId: {
                type: "string",
                title: "Password group",
                enum: Object.values(PASSWORD_GROUPS),
            },
            reportServerGroup: {
                type: "number",
                title: "Report Server group",
                anyOf: listToAnyOf({
                    list: serverGroups,
                    map: (group) => ({ title: group.name, enum: [group.id] }),
                }),
            },
            ssoGroupId: {
                type: "integer",
                title: "SSO Group",
                anyOf: listToAnyOf({
                    list: ssoGroups,
                    map: (group) => ({ title: group.groupName, enum: [group.ssoGroupId] }),
                    emptyItem: true,
                }),
            },
            ssoUserId: {
                type: "string",
                title: "SSO User ID",
            },
            ...statusFieldSchema,
        },
    };
};

export const defaultUiSchema = (editSchema) => {
    const statusFieldUiSchema = editSchema
        ? {
              status: {
                  "ui:widget": "select",
              },
          }
        : {};

    return {
        "ui:rootFieldId": "userName",
        ...(editSchema
            ? {
                  userName: {
                      "ui:widget": "text",
                      "ui:disabled": editSchema,
                  },
              }
            : {}),
        clientNumber: {
            "ui:widget": "select",
        },
        userType: {
            "ui:widget": "select",
        },
        firstName: {
            "ui:widget": "text",
        },
        lastName: {
            "ui:widget": "text",
        },
        company: {
            "ui:widget": "select",
        },
        address1: {
            "ui:widget": "text",
        },
        address2: {
            "ui:widget": "text",
        },
        city: {
            "ui:widget": "text",
        },
        state: {
            "ui:widget": "state",
        },
        zip: {
            "ui:widget": "text",
        },
        phone: {
            "ui:widget": "text",
        },
        cell: {
            "ui:widget": "text",
        },
        email: {
            "ui:widget": "text",
        },
        passwordRuleId: {
            "ui:widget": "select",
        },
        reportServerGroup: {
            "ui:widget": "select",
            "ui:placeholder": "None",
            "ui:emptyItem": "None",
        },
        ssoGroupId: {
            "ui:widget": "select",
            "ui:placeholder": "-- SELECT --",
        },
        ssoUserId: {
            "ui:widget": "text",
        },
        ...statusFieldUiSchema,
    };
};

export const dashboardTypesList = [
    {
        id: dashboardTypes.LANDING_PAGE,
        label: getDashboardNameByType(dashboardTypes.LANDING_PAGE),
    },
    {
        id: dashboardTypes.UTILITY_PAGE,
        label: getDashboardNameByType(dashboardTypes.UTILITY_PAGE),
    },
    {
        id: dashboardTypes.PROGRAM_PAGE,
        label: getDashboardNameByType(dashboardTypes.PROGRAM_PAGE),
    },
    {
        id: dashboardTypes.PROJECT_PAGE,
        label: getDashboardNameByType(dashboardTypes.PROJECT_PAGE),
    },
];

export const getKeyByValue = (object, value) => Object.keys(object).find((key) => object[key] === value);

export const useUser = (userNumber) => {
    return useResource({
        resourceName: "users",
        resourceId: userNumber,
        forced: true,
    });
};

export const refreshUser = (userNumber) => (dispatch) => {
    dispatch(
        getResource({
            resourceName: "users",
            resourceId: userNumber,
            path: {
                userNumber,
            },
        })
    );
};
