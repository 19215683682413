import { useResource } from "store/resources/useResource";
import useProgramFormNumber from "./useProgramFormNumber";

const useFormPages = ({ entityId }) => {
    const [formNumber, isLoadingForm] = useProgramFormNumber({ entityId });
    const key = formNumber ? `${entityId}-${formNumber}` : undefined;

    const [pages = [], isLoadingPages] = useResource({
        resourceName: "programFormPages",
        key,
        path: {
            programNumber: entityId,
            formNumber,
        },
        forced: true,
        transform: (data) => {
            return data ?? [];
        },
    });

    return [pages, isLoadingForm || isLoadingPages];
};

export default useFormPages;
