import React, { useCallback, useRef } from "react";
import Color from "color";
import { isNil, debounce, uniq, isString } from "lodash";

import IconWrap from "components/ui/Icons";
import Separator from "components/ui/Separator";
import TextLabel from "components/ui/Label/TextLabel";
import CloseCircle from "components/ui/Icons/CloseCircle";
import { SectionInfo } from "../SectionInfo";
import StatusMsg from "components/ui/StatusMsg";
import { camelToTitle } from "components/utils/string";
import ClassNames from "classnames";
import { DEFAULT_UTILITY_TEMPLATE_CONFIG } from "../../utils";
import { openInfoModal } from "components/ui/Modal/utils";
import { reduce, isObjectLike } from "lodash";
import {
    ButtonBackgroundCustomPropertyName,
    ButtonBorderCustomPropertyName,
    GhostButtonTextCustomPropertyName,
} from "../Widgets/GlobalButtonProperties/types";
import { PropertyName } from "../../types";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";

import "./Colors.scss";

export const Colors = (props) => {
    const { onChange, onSelect, noRemoval, selectedColor, colors } = props;

    const [config] = usePortalBuilderState((state) => state.updatedConfig);

    const configRef = useRef();
    configRef.current = config;

    const onColorChange = useCallback(
        (id) => (event, isNew) => {
            debouncedOnChange(id, event.target.value, configRef.current, colors, onChange, isNew);
        },
        [onChange, colors]
    );

    const onColorRemove = (id) => {
        let colors = { ...(config?.colors ?? {}) };
        delete colors[id];
        onChange({
            ...(config ?? {}),
            colors: {
                ...colors,
            },
        });
    };

    return (
        <div className="portal-builder-properties-colors flex-column fill-width no-scroll flex-one-in-column">
            <SectionInfo>
                <StatusMsg
                    msgIcon="info-empty"
                    msgText={
                        noRemoval
                            ? "Select from the already set global colors or add more."
                            : "Define Portal global colors which will be used to style pages and elements."
                    }
                />
                <Separator line marginBottom={0} marginTop={15} />
            </SectionInfo>
            <ColorList
                config={config}
                onRemove={onColorRemove}
                onChange={onColorChange}
                noRemoval={noRemoval}
                onSelect={onSelect}
                selectedColor={selectedColor}
                colors={colors}
            />
        </div>
    );
};

const ColorList = (props) => {
    const defaultColorKeys = Object.keys(DEFAULT_UTILITY_TEMPLATE_CONFIG.colors);
    const colors = props?.colors ?? props?.config.colors ?? {};
    const colorKeys = Object.keys(colors);
    const newlyAddedColorArray = props.config._addedColors ?? [];
    const colorInUse = getAllColorsInUse(props?.config);
    const [isProgramPortalBuilder] = usePortalBuilderState((state) => state.isProgramPortalBuilder);

    const defaultItems = defaultColorKeys.map((key) => ({
        id: key,
        title: camelToTitle(key),
        color: colors[key],
    }));

    const createColorItem = (key) => {
        return {
            id: key,
            title: getColorTitle(key),
            color: colors[key],
            noRemoval: newlyAddedColorArray.includes(key) ? false : props.noRemoval,
        };
    };

    const items = isProgramPortalBuilder
        ? colorKeys.map(createColorItem)
        : defaultItems.concat(colorKeys.filter((c) => !defaultColorKeys.includes(c)).map(createColorItem));

    const onAdd = () => {
        // Use different color IDs for program and utility portal to not conflict with each other.
        const newColorId = getNewColorId(colorKeys, isProgramPortalBuilder ? "custom-color-p" : "custom-color-");
        props?.onChange(newColorId)({ target: { value: null } }, true);
    };

    return (
        <div className="portal-builder-properties-color-list flex-row flex-wrap flex-one-in-column with-scroll align-content-start">
            {items.map((item, index) => (
                <ColorListItem
                    key={index}
                    {...item}
                    noRemoval={item.noRemoval}
                    isHighlighted={item.id === props.selectedColor && props.noRemoval}
                    onRemove={props.onRemove}
                    onChange={props.onChange}
                    onSelect={props.onSelect}
                    isInUse={colorInUse.includes(item.id)}
                />
            ))}
            <IconWrap className="portal-builder-new-item align-center justify-center" title="Add more colors" onClick={onAdd} icon="plus" />
        </div>
    );
};

const ColorListItem = (props) => {
    const { id, title, color, onSelect, noRemoval, isHighlighted, isInUse } = props;
    const isNew = id?.startsWith("custom");
    const colorStyle = color ? { backgroundColor: color } : undefined;

    const handleRemove = () => {
        if (isInUse) {
            openInfoModal({
                title: `Remove Color ${color}`,
                modalIcon: "cancel-clear-circle-highlight-off-filled",
                text: "Not possible to remove the color because it is in use to style Portal elements.",
                buttonText: "Got it!",
                noOverlayIcon: true,
            });
        } else {
            props.onRemove(id);
        }
    };
    const showColorSelector = isNil(onSelect) || isNil(color);

    const onColorSelect = (evt) => {
        if (!showColorSelector) {
            evt.stopPropagation();
            onSelect(id);
        }
    };

    const colorTextStyle = Color(color).isLight() ? { color: "#333" } : { color: "#fff" };

    return (
        <div
            className={ClassNames("portal-builder-properties-color-list-item flex-column align-center", {
                highlighted: isHighlighted,
            })}
        >
            {isNew && !noRemoval && <CloseCircle title="Remove" onClick={handleRemove} />}
            <div
                className="portal-builder-properties-color-list-item-preview flex-column align-center justify-center"
                style={colorStyle}
                onClick={onColorSelect}
            >
                <span style={colorTextStyle} className="color-text">
                    {color ?? (
                        <span className="color-text--click-to-set">
                            click <br></br> to set
                        </span>
                    )}
                </span>
                {showColorSelector && (
                    <input className="portal-builder-color-picker" type="color" defaultValue={color ?? ""} onBlur={props.onChange(id)} />
                )}
            </div>
            {title && <TextLabel>{title}</TextLabel>}
        </div>
    );
};

const debouncedOnChange = debounce((id, color, config, colors, onChange, isNew) => {
    const addedColors = config._addedColors ?? [];

    if (isNew) {
        addedColors.push(id);
    }

    onChange({
        ...(config ?? {}),
        colors: {
            ...(config?.colors ?? {}),
            [id]: color,
        },
        _addedColors: addedColors,
    });
}, 500);

const getNewColorId = (colorKeys, idPrefix = "custom-color-") => {
    const availableIndex = colorKeys.reduce((result, key) => {
        if (key.startsWith(idPrefix)) {
            // Remove the prefix and get the index
            const index = Number(key.replace(idPrefix, ""));
            if (index >= result) {
                result = index + 1;
            }
        }

        return result;
    }, 1);

    return idPrefix + availableIndex;
};

const getColorTitle = (colorId) => {
    const titleColorId = camelToTitle(colorId);

    if (titleColorId.startsWith("Custom")) {
        return "Color #" + titleColorId.split("-").pop().toUpperCase();
    } else {
        return titleColorId.split("-").join(" ");
    }
};

const getAllColorsInUse = (config) => {
    const keys = [
        "defaultColor",
        "mouseOverColor",
        "focusColor",
        ...Object.values(ButtonBackgroundCustomPropertyName),
        ...Object.values(ButtonBorderCustomPropertyName),
        ...Object.values(GhostButtonTextCustomPropertyName),
        ...Object.values(PropertyName),
    ];

    const adjustedKeys = uniq(keys.map((k) => k.toLowerCase()).filter((k) => k.includes("color")));
    return reduce(
        config,
        function (result, value, key) {
            if (adjustedKeys.includes(isString(key) ? key.toLowerCase() : key)) {
                result.push(value);
            } else if (isObjectLike(value)) {
                return result.concat(getAllColorsInUse(value));
            }

            return result;
        },
        []
    );
};
