import React, { useState, memo } from "react";

import { auditType } from "../../utils/auditType";

import Radio from "../Input/Radio";

import "./style.scss";

export const useSelector = (initialType = auditType.all) => {
    const [type, setType] = useState(initialType);

    const changeType = (newType) => () => setType(newType);

    return [type, changeType];
};

export default memo(({ items, type, onChange }) => {
    return (
        <div className="audit-types flex-row">
            <span>Show:</span>
            {items.map((item) => (
                <Radio key={item.type} label={item.label} checked={type === item.type} onChange={onChange(item.type)} />
            ))}
        </div>
    );
});
