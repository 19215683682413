import React, { useMemo, memo } from "react";

import { pickInitialValues, listToAnyOf } from "../../../../../../utils/form";
import { useProgramCategoriesForDropdown } from "components/utils/program";
import { SelectBudgetLineWidget } from "../../../../../../../../src/components/ui/Form/JsonSchema/widgets/SelectBudgetLineWidget";

import JsonSchemaForm from "../../../../../Form/JsonSchema/JsonSchemaForm";
import { QuantityFriendlyNameWidget } from "./QuantityFriendlyNameWidget";
import { isChildProgram } from "components/views/ProgramView/utils";

const ItemForm = memo(({ formRef, programNumber, dataItem, isSubmitting, onSubmit }) => {
    const [categories = [], isLoadingCategories] = useProgramCategoriesForDropdown({
        resourceName: "programCatalogCategories",
        programNumber,
        initialItem: {
            categoryName: dataItem?.categoryName,
            categoryNumber: dataItem?.categoryNumber,
        },
    });

    const isLocked = isChildProgram({ programNumber });

    const initialValues = useMemo(() => {
        return dataItem ? pickInitialValues(dataItem) : {};
    }, [dataItem]);

    const schema = useMemo(
        () => ({
            type: "object",
            required: ["name", "statusID", "categoryNumber"],
            properties: {
                name: {
                    type: "string",
                    title: "Name",
                },
                categoryNumber: {
                    type: "string",
                    title: "Category",
                    anyOf: listToAnyOf({
                        list: categories,
                        map: (item) => ({
                            title: item.categoryName,
                            enum: [item.categoryNumber],
                        }),
                    }),
                },
                statusID: {
                    type: "integer",
                    title: "Status",
                    anyOf: [
                        {
                            title: "Active",
                            enum: [212],
                        },
                        {
                            title: "Disabled",
                            enum: [213],
                        },
                    ],
                },
                admMeasureFilter: {
                    type: "string",
                    title: "Adm Measure Filter",
                },
                quantityFriendlyName: {
                    type: "string",
                },
                quantity: {
                    type: "integer",
                },
                budgetLineNumber: {
                    type: "string",
                    title: "Budget Line",
                },
            },
        }),
        [categories]
    );

    const uiSchema = useMemo(
        () => ({
            name: {
                "ui:widget": "textarea",
            },
            categoryNumber: {
                "ui:placeholder": isLoadingCategories ? "Loading..." : "Select Category",
            },
            statusID: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                },
            },
            admMeasureFilter: {
                "ui:widget": "textarea",
            },
            budgetLineNumber: {
                "ui:widget": (props) => <SelectBudgetLineWidget formRef={formRef} programNumber={programNumber} {...props} />,
            },
            quantity: {
                "ui:displayLabel": false,
            },
            quantityFriendlyName: {
                "ui:displayLabel": false,
                "ui:widget": (props) => <QuantityFriendlyNameWidget {...props} />,
            },
        }),
        [isLoadingCategories, formRef, programNumber]
    );

    return (
        <JsonSchemaForm
            formRef={formRef}
            schema={schema}
            uiSchema={uiSchema}
            initialValues={initialValues}
            disabled={isSubmitting || isLocked}
            onSubmit={onSubmit}
            noActions
        />
    );
});

export default ItemForm;
