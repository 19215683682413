import React, { memo, useCallback, useState, useMemo } from "react";
import cn from "classnames";

import { formatJsonDateTime, dateIsSoon, dateIsOverdue, dateIsNA } from "../../../../../utils/date";
import { changeTaskOwnership, isWorkflowTaskDependent, refreshWorkflow, isAutomated } from "../../../../TaskWorkflow/utils";
// import { isAutomated, isWorkflowTaskDependent } from "../../utils";
import { isAdhocTask } from "../../../../../utils/workflow";

import WaitIcon from "../../../../WaitIcon";
import IconWrap from "../../../../Icons";
import IconWithLabel from "../../../../Icons/IconWithLabel";
import useUnmounted from "../../../../../utils/useUnmounted";
import StatusMark from "../../../../StatusMark";
import TaskDateAndTerm from "../../../../TaskWorkflow/components/Task/TaskDateAndTerm";
import { stripHtml } from "../../../../../utils/string";

const WorkflowStep = memo(({ item, applicationNumber, owned, onOpenTask, programNumber, isTakeTaskDisabled, isAppLocked }) => {
    const isStepTypeDuplicate = item.stepType === item.step;

    const [updatingStep, setUpdatingStep] = useState(false);
    const unmounted = useUnmounted();

    const onChangeTaskOwnership = useCallback(
        (event) => {
            event.stopPropagation();

            setUpdatingStep(true);

            changeTaskOwnership({
                step: item,
                resourceParams: {
                    resourceName: "applicationWorkflowTaskOwnership",
                    path: {
                        appId: applicationNumber,
                    },
                },
                onSuccess: () => {
                    refreshWorkflow({
                        resourceParams: {
                            resourceName: "applicationWorkflow",
                            key: applicationNumber,
                            path: {
                                appId: applicationNumber,
                            },
                        },
                    });
                },
                onComplete: () => {
                    if (!unmounted.current) {
                        setUpdatingStep(false);
                    }
                },
            });
        },
        [item, applicationNumber, unmounted]
    );

    const onOpen = useCallback(
        (event) => {
            event.stopPropagation();
            onOpenTask(item);
        },
        [item, onOpenTask]
    );

    const flagTitle = useMemo(
        (userOwner) => {
            if (isAppLocked) {
                return "";
            }

            if (isWorkflowTaskDependent(item)) {
                return "Dependent Task";
            }

            return item.userOwner ? "Un-take Task" : "Take Task";
        },
        [isAppLocked, item]
    );

    return (
        <div
            className={cn("workflow-step", {
                "workflow-step-dependent": isWorkflowTaskDependent(item),
            })}
        >
            <div className="status-marks flex-row align-center">
                <div className="flex-row flex-one">
                    {!isAdhocTask(item) && !isAutomated(item) && !isWorkflowTaskDependent(item) && (
                        <StatusMark statusMarkSmall statusMarkTheme>
                            Task
                        </StatusMark>
                    )}
                    {isAdhocTask(item) && (
                        <StatusMark statusMarkSmall statusMarkThemeBase>
                            Ad-hoc Task
                        </StatusMark>
                    )}
                    {isAutomated(item) && (
                        <StatusMark statusMarkSmall statusMarkThemeDark>
                            Automated
                        </StatusMark>
                    )}
                    {isWorkflowTaskDependent(item) && (
                        <StatusMark statusMarkSmall statusMarkDisabled>
                            Dependent
                        </StatusMark>
                    )}
                </div>
                <IconWithLabel className="see-more" icon="eye-visibility-empty" onClick={onOpen} disabled={isTakeTaskDisabled}>
                    see more
                </IconWithLabel>
            </div>
            <div className="step-title flex-one">
                {!isStepTypeDuplicate && <div className="step-type">{item.stepType}</div>}
                <div className="step-name">{stripHtml(item.step)}</div>
            </div>
            <div className="task-dates flex-row align-center justify-space-between">
                <div className="flex-one">
                    <TaskDateAndTerm task={item} />
                </div>
                <div
                    className={cn("step-date flex-column align-end", {
                        "due-date-soon": dateIsSoon(item.dateDue) && !dateIsNA(item.term),
                        "due-date-overdue": dateIsOverdue(item.dateDue) && !dateIsNA(item.term),
                        "due-date-na": dateIsNA(item.term),
                    })}
                >
                    <div className={dateIsNA(item.term) ? "flex-column align-end" : "flex-row align-center"}>
                        <div className="col-label">Due Date:</div>
                        {dateIsNA(item.term) ? (
                            <div className="col-content">not applicable</div>
                        ) : (
                            <>
                                {dateIsSoon(item.dateDue) && (
                                    <StatusMark statusMarkWarning statusMarkSmall>
                                        Soon
                                    </StatusMark>
                                )}
                                {dateIsOverdue(item.dateDue) && (
                                    <StatusMark statusMarkError statusMarkSmall>
                                        Overdue
                                    </StatusMark>
                                )}
                            </>
                        )}
                    </div>
                    {!dateIsNA(item.term) && <div className="col-content step-date__value">{formatJsonDateTime(item.dateDue) || "-"}</div>}
                </div>
            </div>
            <div className="assignment-block flex-row align-center justify-space-between">
                <Assignment userOwner={item.userOwner} taskGroup={item.taskGroup} owned={owned} />
                {updatingStep && <WaitIcon />}
                {!updatingStep && (
                    <IconWrap
                        className="own-step"
                        icon={item.userOwner ? "flag-filled" : "flag-empty"}
                        title={flagTitle}
                        disabled={isTakeTaskDisabled || isWorkflowTaskDependent(item)}
                        onClick={onChangeTaskOwnership}
                    />
                )}
            </div>
        </div>
    );
});

const Assignment = memo(({ userOwner, taskGroup, owned }) => {
    const label = userOwner ? "Owned By:" : "Assigned To:";
    const value = userOwner ? (owned ? `${userOwner} (Me)` : userOwner) : taskGroup || "-";

    return (
        <div className="step-assignment flex-row">
            <div className="col-label">{label}</div>
            <div className="col-content">{value}</div>
        </div>
    );
});

export default WorkflowStep;
