import { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

const Portal = ({ className = undefined, container = undefined, children }) => {
    const portalElementRef = useRef(document.createElement("div"));

    if (className) {
        portalElementRef.current.className = className;
    }

    const containerElement = container ?? document.body;

    useEffect(() => {
        const element = portalElementRef.current;

        element && containerElement.appendChild(element);

        return () => {
            element?.remove();
        };
    }, [containerElement]);

    return ReactDOM.createPortal(children, portalElementRef.current);
};

export default Portal;
