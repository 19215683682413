import React, { useRef, createContext, useContext, useCallback, useSyncExternalStore } from "react";

// source: https://github.com/jherr/fast-react-context/blob/main/fast-context-generic/src/createFastContext.tsx
export default function createFastContext<Store>(initialState: Store) {
    function useStoreData(): {
        get: () => Store;
        set: (value: Partial<Store>) => void;
        subscribe: (callback: () => void) => () => void;
    } {
        const store = useRef(initialState);
        const subscribers = useRef(new Set<() => void>());

        const get = useCallback(() => store.current, []);

        const set = useCallback((value: Partial<Store>) => {
            store.current = { ...store.current, ...value };
            subscribers.current.forEach((callback) => callback());
        }, []);

        const subscribe = useCallback((callback: () => void) => {
            subscribers.current.add(callback);
            return () => subscribers.current.delete(callback);
        }, []);

        return {
            get,
            set,
            subscribe,
        };
    }

    type UseStoreDataReturnType = ReturnType<typeof useStoreData>;

    const StoreContext = createContext<UseStoreDataReturnType | null>(null);

    function Provider({ children }: Readonly<{ children: React.ReactNode }>) {
        return <StoreContext.Provider value={useStoreData()}>{children}</StoreContext.Provider>;
    }

    function useStore<SelectorOutput>(selector: (store: Store) => SelectorOutput): [SelectorOutput, (value: Partial<Store>) => void] {
        const store = useContext(StoreContext);
        if (!store) {
            throw new Error("Store not found");
        }

        const state = useSyncExternalStore(
            store.subscribe,
            () => selector(store.get()),
            () => selector(initialState)
        );

        return [state, store.set];
    }

    function useStoreSetter(): (value: Partial<Store>) => void {
        const store = useContext(StoreContext);
        if (!store) {
            throw new Error("Store not found");
        }

        return store.set;
    }

    return {
        Provider,
        useStore,
        useStoreSetter,
    };
}
