import React, { useCallback, memo } from "react";
import { useDispatch } from "react-redux";
import { isNil } from "lodash";

import NavBar from "./../../ui/NavBar";
import Windows from "../../ui/Windows";
import { windowContainerTypes } from "../../utils/window";
import { windowAdd } from "../../../store/window/actions";
import { hasAnyOfPermissions, hasApplicationAccess, systemUserRights, UserApplications } from "../../utils/user";

const menuItems = [
    {
        text: "Dashboard",
        view: {
            containerName: windowContainerTypes.Home,
            name: "Dashboard",
            title: "Dashboard",
            component: "HomeDashboardView",
            showTab: false,
            showHeader: false,
        },
    },
    {
        text: "Application Processing",
        view: {
            containerName: windowContainerTypes.Home,
            name: "ApplicationProcessing",
            title: "Application Processing",
            component: "ApplicationProcessing",
            showTab: false,
            showHeader: false,
        },
        userRights: [
            systemUserRights.VISIONDSM_ADD_APPLICATION,
            systemUserRights.VISIONDSM_MANAGE_APPLICATIONS,
            systemUserRights.VISIONDSM_VIEW_APPLICATION,
            systemUserRights.VISIONDSM_ONLY_ASSIGNED_APPS,
            systemUserRights.VISIONDSM_MANAGE_SCANGROUPS,
            systemUserRights.VISIONDSM_MANAGE_DOCUMENT_QUEUE,
        ],
    },
    {
        text: "Utility and Program Management",
        view: {
            containerName: windowContainerTypes.Home,
            name: "UtilitiesAndProgramManagement",
            title: "Utility and Program Management",
            component: "UtilitiesAndProgramManagement",
            showTab: false,
            showHeader: false,
        },
        userRights: [
            systemUserRights.VISIONDSM_ADD_CONTRACT,
            systemUserRights.VISIONDSM_ADD_INVOICE,
            systemUserRights.VISIONDSM_ADD_PROGRAM,
            systemUserRights.VISIONDSM_ADD_UTILITY,
            systemUserRights.VISIONDSM_MANAGE_BUDGET,
            systemUserRights.VISIONDSM_MANAGE_PROGRAM,
            systemUserRights.VISIONDSM_MANAGE_UTILITY,
            systemUserRights.VISIONDSM_VIEW_BUDGET,
            systemUserRights.VISIONDSM_VIEW_INVOICE,
            systemUserRights.VISIONDSM_VIEW_PROGRAM,
            systemUserRights.VISIONDSM_VIEW_UTILITY,
        ],
    },
    {
        text: "VisionCRM",
        view: {
            containerName: windowContainerTypes.Home,
            name: "CRM",
            title: "VisionCRM",
            component: "CRM",
            showTab: false,
            showHeader: false,
        },
        userRights: [systemUserRights.VISIONDSM_CRM_ACCESS],
    },
    {
        text: "TRMulator",
        view: {
            containerName: windowContainerTypes.Root,
            name: "TRMulator",
            component: "TRMulatorView",
            header: "TRMulator",
            close: true,
        },
        userApplication: UserApplications.TRMulator,
    },
    {
        text: "System",
        view: {
            containerName: windowContainerTypes.Home,
            name: "SystemManagement",
            title: "System",
            component: "SystemManagement",
            showTab: false,
            showHeader: false,
        },
        userRights: [
            systemUserRights.VISIONDSM_MANAGE_USER_RIGHTS,
            systemUserRights.VISIONDSM_MANAGE_USERGROUPS,
            systemUserRights.VISIONDSM_MANAGE_SYSTEM,
        ],
    },
];

const HomeView = memo(() => {
    const dispatch = useDispatch();

    const initialViews = [menuItems[0].view];
    const filteredMenuItems = menuItems.filter(canShowMenuItem);

    const onMenuItemClick = useCallback(
        (menuItem) => {
            dispatch(
                windowAdd({
                    ...menuItem.view,
                    activate: true,
                })
            );
        },
        [dispatch]
    );

    return (
        <div className="home flex-column flex-one no-scroll">
            <NavBar items={filteredMenuItems} onClick={onMenuItemClick} />
            <div className="flex flex-one with-scroll">
                <Windows containerName={windowContainerTypes.Home} initialViews={initialViews} persist={true} sidebars={false} />
            </div>
        </div>
    );
});

const canShowMenuItem = (menuItem) => {
    let hasAccessToApp = true;

    if (menuItem.userApplication) {
        hasAccessToApp = hasApplicationAccess(menuItem.userApplication);
    }

    return hasAccessToApp && (isNil(menuItem.userRights) || hasAnyOfPermissions(menuItem.userRights));
};

export default HomeView;
