import React, { useRef, memo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual, isNil } from "lodash";
import cn from "classnames";

import { getData } from "../../../../../../store/dataGrid/actions";
import { isApplicationLocked } from "../../../../../views/ProjectView/utils";
import { submitByRef, submitResource } from "../../../../../utils/form";

import Button from "../../../../Button";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavContent from "../../../../SideNav/SideNavContent";
import EquipmentDetails from "./EquipmentDetails";
import { refreshSidebarEquipment } from "./utils";

const UpdateEquipmentPanel = memo(({ equipmentId, applicationNumber, programNumber, onClose, sidePanel, onRowSelectClear }) => {
    const dispatch = useDispatch();
    const formRef = useRef();
    const [isSubmitting, setSubmitting] = useState(false);
    const isMobile = useSelector((state) => state.window?.isMobile);

    const isAppLocked = isApplicationLocked({ applicationNumber });
    const title = "Edit Equipment";
    const leadBlockIcon = "edit-empty";
    const cancelButtonText = isAppLocked ? "Close" : "Cancel";

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const equipment = useSelector((state) => state.resources.equipment.itemsById[equipmentId]?.equipment);
    const [showApprovedEquipmentSearch, setShowApprovedEquipmentSearch] = useState(false);
    const [approvedEquipmentDetails, setApprovedEquipmentDetails] = useState();
    const attributesFormInitialValuesRef = useRef();

    const onSubmit = (data) => {
        const resourceParams = {
            resourceName: "equipment",
            path: {
                appId: applicationNumber,
            },
        };

        const body = {
            ...data,
            appID: applicationNumber,
        };

        submitResource({
            resourceParams,
            resourceId: equipmentId,
            body,
            onRefresh: () => {
                dispatch(
                    getData({
                        dataGridId: `${applicationNumber}-equipment-grid`,
                    })
                );

                refreshSidebarEquipment({ applicationNumber });
            },
            onSuccess: () => {
                sidePanel.close();
                onRowSelectClear();
            },
            setSubmitting,
        });
    };

    const handleSubmit = () => {
        submitByRef(formRef);
    };

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    const onChange = useCallback(
        (formData) => {
            if (equipment) {
                if (!attributesFormInitialValuesRef.current && !isNil(formData)) {
                    attributesFormInitialValuesRef.current = formData;
                }

                const quantity = isNil(equipment.quantity) ? undefined : Number(equipment.quantity);
                const initialValues = {
                    ...attributesFormInitialValuesRef.current,
                    quantity,
                };

                setIsSubmitDisabled(isSubmitting || isEqual(initialValues, formData));
            }
        },
        [isSubmitting, equipment]
    );

    const handleClickBack = useCallback(() => {
        // Go back from approved equipment details
        if (approvedEquipmentDetails) {
            setApprovedEquipmentDetails(null);
        }
        // Go back from approved equipment search
        else if (showApprovedEquipmentSearch) {
            setShowApprovedEquipmentSearch(false);
        }
    }, [showApprovedEquipmentSearch, approvedEquipmentDetails]);

    const showFooter = !(isMobile && showApprovedEquipmentSearch && !approvedEquipmentDetails);
    const showHeader = isMobile && !approvedEquipmentDetails;

    return (
        <SideNavContent className="update-equipment-panel">
            {showHeader && <SideNavHeader title={title} leadBlockIcon={leadBlockIcon} smallHeader onClose={onClose} />}
            <SideNavBody noPadding>
                <EquipmentDetails
                    formRef={formRef}
                    programNumber={programNumber}
                    applicationNumber={applicationNumber}
                    equipmentId={equipmentId}
                    hideButtons
                    headerLeftAlignedTitle={title}
                    headerLeftAlignedIcon={leadBlockIcon}
                    onChange={onChange}
                    onSubmit={onSubmit}
                    onClose={onClose}
                    showApprovedEquipmentSearch={showApprovedEquipmentSearch}
                    approvedEquipmentDetails={approvedEquipmentDetails}
                    onShowApprovedEquipmentSearch={(data) => setShowApprovedEquipmentSearch(data)}
                    onShowApprovedEquipmentDetails={(data) => setApprovedEquipmentDetails(data)}
                    submitButtonText={"Save"}
                    cancelButtonText={cancelButtonText}
                />
            </SideNavBody>
            {showFooter && (
                <SideNavFooter setPrimaryButton={isMobile} justifyCenter={!isAppLocked} justifyEnd={isAppLocked}>
                    {showApprovedEquipmentSearch && (
                        <Button icon="shevron-small-left" onClick={handleClickBack}>
                            Back
                        </Button>
                    )}
                    <div
                        className={cn("flex-row flex-one-in-row", {
                            "justify-end": showApprovedEquipmentSearch,
                            "justify-center": !showApprovedEquipmentSearch,
                        })}
                    >
                        {approvedEquipmentDetails && (
                            <Button primary icon="plus" onClick={approvedEquipmentDetails.onAdd}>
                                Apply Approved Equipment
                            </Button>
                        )}
                        {!isAppLocked && !showApprovedEquipmentSearch && (
                            <Button primary onClick={handleSubmit} disabled={isSubmitDisabled}>
                                Save
                            </Button>
                        )}
                        {!(isMobile && approvedEquipmentDetails) && <Button onClick={onClose}>{cancelButtonText}</Button>}
                    </div>
                </SideNavFooter>
            )}
        </SideNavContent>
    );
});

export default UpdateEquipmentPanel;
