import React, { useState, memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import SearchArea from "./SearchArea";
import IconWrap from "../Icons";
import { clearAllSearchCriteria } from "../../../store/globalSearch/actions";
import { GlobalSearchKey } from "../../../store/globalSearch/utils";

import "./style.scss";

export const GlobalSearchContext = React.createContext();

const GlobalSearch = memo(({ iconBackground, iconSize }) => {
    const [isActive, setIsActive] = useState(false);

    const dispatch = useDispatch();

    const onSearchIconClick = useCallback(() => {
        setIsActive((isActive) => {
            if (isActive) {
                dispatch(
                    clearAllSearchCriteria({
                        instanceId: GlobalSearchKey,
                    })
                );
            }

            return !isActive;
        });
    }, [dispatch]);

    const onCloseSearch = useCallback(() => {
        dispatch(
            clearAllSearchCriteria({
                instanceId: GlobalSearchKey,
            })
        );

        setIsActive(false);
    }, [dispatch]);

    return (
        <GlobalSearchContext.Provider value={{ onCloseSearch }}>
            <div className={cn("global-search", { active: isActive })}>
                <div
                    className={cn("global-search__container", {
                        active: isActive,
                    })}
                >
                    {isActive && (
                        <>
                            <SearchArea instanceId={GlobalSearchKey} />
                            <IconWrap icon="clear-close" title="Close" iconWrapBig onClick={onSearchIconClick} />
                        </>
                    )}
                </div>
                <IconWrap
                    icon="search"
                    title="Search"
                    iconWrapWhite={iconBackground === "white"}
                    iconWrapTransparent={iconBackground === "transparent"}
                    iconWrapBig={iconSize === "big"}
                    iconWrapMedium={iconSize === "medium"}
                    onClick={onSearchIconClick}
                />
                <div className="semi-transparent-background" onClick={onSearchIconClick}></div>
            </div>
        </GlobalSearchContext.Provider>
    );
});

export default GlobalSearch;
