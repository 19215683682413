import React, { memo, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import IconWithLabel from "../../../Icons/IconWithLabel";
import TagButton from "../../../Button/TagButton";
import { modalOpen, modalClose } from "../../../../../store/modal/actions";
import BudgetLinesSelect from "../../shared/Program/BudgetLinesSelect";
import { getBudgetLineFullName } from "components/ui/Dashboard/Panel/Contract/BudgetLookups/utils";
import { useProgramCatalogBudgetLines } from "../../../../../store/resources/useResource";
import WaitIcon from "../../../../../../src/components/ui/WaitIcon";

import "./SelectBudgetLineWidget.scss";
import { isChildProgram } from "components/views/ProgramView/utils";

export const SelectBudgetLineWidget = memo(({ readonly, value, onChange, programNumber }) => {
    const dispatch = useDispatch();

    const [budgetLines = [], isLoadingBudgetLines] = useProgramCatalogBudgetLines({ programNumber });

    const budgetLinesData = budgetLines.map((i) => ({
        title:
            i.contractDescription +
            ": " +
            getBudgetLineFullName({
                budgetLines: budgetLines,
                budgetLineNumber: i.budgetLineNumber,
            }),
        value: i.budgetLineNumber,
    }));

    const selectedTitle = useMemo(() => budgetLinesData.find((i) => i.value === value)?.title, [budgetLinesData, value]);
    const isLocked = isChildProgram({ programNumber });

    const onSelectBudgetLine = useCallback(() => {
        const onSelect = (value) => {
            onChange(value);
            dispatch(modalClose());
        };

        dispatch(
            modalOpen({
                modalType: "MODAL",
                modalProps: {
                    title: "Select Budget Line",
                    overlayClassName: "modal-styled",
                    modalIcon: "touch-empty",
                    className: "budget-lines-select-modal modal-md",
                    children: (
                        <BudgetLinesSelect
                            data={budgetLinesData}
                            programNumber={programNumber}
                            onCancel={() => dispatch(modalClose())}
                            onSelect={onSelect}
                        />
                    ),
                    noFooter: true,
                    withScroll: false,
                },
            })
        );
    }, [onChange, dispatch, programNumber, budgetLinesData]);

    return (
        <>
            {value ? (
                <TagButton
                    multiline
                    edit={!readonly}
                    disabled={isLocked}
                    readonly={readonly}
                    title={"Remove Budget Line"}
                    onClick={() => onChange(undefined)}
                >
                    {isEmpty(selectedTitle) ? "Select Budget Line" : selectedTitle}
                </TagButton>
            ) : (
                <>
                    {isLoadingBudgetLines ? (
                        <WaitIcon />
                    ) : (
                        <div className="select-budget-lines-actions">
                            <IconWithLabel
                                icon="touch-empty"
                                disabled={readonly || isLocked}
                                iconWithLabelRight
                                onClick={onSelectBudgetLine}
                            >
                                Select Budget Line
                            </IconWithLabel>
                        </div>
                    )}
                </>
            )}
        </>
    );
});
