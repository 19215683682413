import React, { useCallback, useState, memo } from "react";
import { useDispatch } from "react-redux";

import { getData } from "../../../../../../store/dataGrid/actions";
import { uploadLookupItems, downloadLookupItems } from "../../../../../utils/CSV";

import Controls from "./Controls";
import Grid from "./Grid";
import LookupTableForm from "./LookupTableForm";
import LookupTableView from "../LookupTableView";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot";

const LookupsTab = memo(({ utilityNumber, onOpenPanel, onClosePanel, programNumber }) => {
    const dispatch = useDispatch();

    const [selectedLookupTable, setSelectedLookupTable] = useState(false);
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "calculation-sidenav-panel",
    });

    const toggleAddForm = useCallback(() => {
        handleOpenSidePanel(<LookupTableForm programNumber={programNumber} utilityNumber={utilityNumber} onClose={handleCloseSidePanel} />);
    }, [handleCloseSidePanel, handleOpenSidePanel, utilityNumber, programNumber]);

    const handleShowLookupList = useCallback(
        (lookupTable) => {
            setSelectedLookupTable(lookupTable);

            onOpenPanel(
                <LookupTableView
                    programNumber={programNumber}
                    utilityNumber={utilityNumber}
                    lookupTableNumber={lookupTable.lookupTableNumber}
                    lookupTableName={lookupTable.lookupTableName}
                    onClose={onClosePanel}
                />
            );
        },
        [utilityNumber, onOpenPanel, onClosePanel, programNumber]
    );

    const onUploadLookupItems = useCallback(
        (lookupTable) => {
            const { lookupTableNumber } = lookupTable;

            uploadLookupItems({ lookupTableNumber }).then(() => {
                if (selectedLookupTable.lookupTableNumber === lookupTable.lookupTableNumber) {
                    dispatch(
                        getData({
                            dataGridId: `${utilityNumber}-${selectedLookupTable.lookupTableNumber}-lookup-items`,
                        })
                    );
                } else {
                    handleShowLookupList(lookupTable);
                }
            });
        },
        [utilityNumber, handleShowLookupList, selectedLookupTable.lookupTableNumber, dispatch]
    );

    const onDownloadLookupItemsByTableNumber = useCallback(
        (lookupTable) => {
            const { lookupTableNumber } = lookupTable;

            downloadLookupItems({ utilityNumber, lookupTableNumber });
        },
        [utilityNumber]
    );

    return (
        <div className="lookups-calculations-tab tab-list-tab-content flex-column fill-height">
            <Controls programNumber={programNumber} onAdd={toggleAddForm} />

            <Grid
                programNumber={programNumber}
                utilityNumber={utilityNumber}
                onShowLookupList={handleShowLookupList}
                onUploadLookupItems={onUploadLookupItems}
                onDownloadLookupItems={onDownloadLookupItemsByTableNumber}
            />
        </div>
    );
});

export default LookupsTab;
