import React, { memo, useCallback, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DragDropContext } from "react-beautiful-dnd";

import { addAttribute, reorderAttributes, removeAttribute } from "store/attributes/actions";
import { uploadAttributes } from "./utils";
import { elementReady } from "components/utils/dom";

import NothingFoundBlock from "../NothingFoundBlock";
import IconWithLabel from "../Icons/IconWithLabel";
import { isLockedText } from "../../views/ProgramView/utils";
import Controls from "../Dashboard/Panel/Controls";
import AttributesFormList from "./AttributesFormList";
import TextInput from "../Input/TextInput";
import "./style.scss";

const AttributesForm = memo(
    ({
        programNumber,
        attributesKey,
        availableAttributesResourceName,
        availableAttributesResourceKeys,
        isLocked,
        isSubmitting,
        attributeMaxLength,
        attributeFormRef,
        onSelectCalculation,
    }) => {
        const scrollRef = useRef();

        const dispatch = useDispatch();
        const attributes = useSelector((state) => state.attributes[attributesKey]) ?? [];
        function filterByValue(array, value) {
            return array.filter((attr) => {
                return (attr[availableAttributesResourceKeys.name] ?? "").replace("_", " ").toLowerCase().indexOf(value.toLowerCase()) > -1;
            });
        }
        const isAttributeError = useSelector((state) => (state.attributes[attributesKey] ?? []).some((a) => a._error));

        const hasFriendlyName = availableAttributesResourceName !== "programEventAttributes";
        const [attributeFilter, setAttributeFilter] = useState("");
        const filteredAttributes = attributeFilter.length > 0 ? filterByValue(attributes, attributeFilter) : attributes;
        const onAddAttribute = () => {
            setAttributeFilter("");
            dispatch(
                addAttribute({
                    key: attributesKey,
                    onSuccess: (attribute) => {
                        elementReady("#" + CSS.escape(attribute._id)).then(() => {
                            if (scrollRef.current) {
                                scrollRef.current.scrollTo({
                                    top: scrollRef.current.scrollHeight,
                                    behavior: "smooth",
                                });
                            }
                        });
                    },
                })
            );
        };

        const onRemoveAttribute = useCallback(
            (item) => {
                dispatch(
                    removeAttribute({
                        key: attributesKey,
                        attributeId: item._id,
                    })
                );
            },
            [attributesKey, dispatch]
        );

        const onUploadAttributes = () => {
            const numberKey = availableAttributesResourceKeys.number;
            const descriptionKey = availableAttributesResourceKeys.description;

            uploadAttributes({
                programNumber,
                attributes,
                attributesKey,
                numberKey,
                descriptionKey,
                availableAttributesResourceName,
            });
        };

        function onDragEnd(result) {
            if (!result.destination) {
                return;
            }

            if (result.destination.index === result.source.index) {
                return;
            }

            dispatch(
                reorderAttributes({
                    key: attributesKey,
                    sourceIndex: result.source.index,
                    destinationIndex: result.destination.index,
                })
            );
        }

        const attributeControls = [
            {
                position: "left",
                title: "Import Attributes from CSV",
                icon: "upload",
                disabled: isLocked,
                tooltip: isLocked ? isLockedText : undefined,
                onClick: onUploadAttributes,
            },
        ];

        return (
            <div className="attributes-form flex-column fill-height">
                {attributes && attributes.length > 0 ? (
                    <div className="fill-height flex-column">
                        {attributes && (
                            <div className="attributes-actions flex-row align-center">
                                <Controls items={attributeControls} />
                            </div>
                        )}
                        <div className="attributes-form-items flex-column flex-one-in-column">
                            <div className="attributes-form-items-columns flex-row align-end">
                                <TextInput
                                    icon="search"
                                    iconRight
                                    inline
                                    value={attributeFilter}
                                    labelInside="Attribute Name"
                                    placeholder="Filter Attribute Name"
                                    onChange={(event) => setAttributeFilter(event.target.value)}
                                />
                                {hasFriendlyName && <div>Friendly Name</div>}
                                <div className="required">Validation Type</div>
                                <div>Default Value</div>
                                <div className="flex-row checkboxes align-end">
                                    <div className="flex-one">
                                        Validation
                                        <br />
                                        Required
                                    </div>
                                    <div>
                                        Show
                                        <br />
                                        All
                                    </div>
                                    <div>
                                        Edit
                                        <br />
                                        All
                                    </div>
                                </div>
                            </div>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <AttributesFormList
                                    programNumber={programNumber}
                                    attributeFormRef={attributeFormRef}
                                    attributesKey={attributesKey}
                                    availableAttributesResourceName={availableAttributesResourceName}
                                    availableAttributesResourceKeys={availableAttributesResourceKeys}
                                    isLocked={isLocked}
                                    isSubmitting={isSubmitting}
                                    attributeMaxLength={attributeMaxLength}
                                    attributes={filteredAttributes}
                                    onRemoveAttribute={onRemoveAttribute}
                                    scrollRef={scrollRef}
                                    onSelectCalculation={onSelectCalculation}
                                    onAddAttribute={onAddAttribute}
                                    isAttributeErrorFullList={isAttributeError}
                                />
                            </DragDropContext>
                        </div>
                    </div>
                ) : (
                    <NothingFoundBlock nothingFoundBlockNoBorder title="No attributes">
                        <IconWithLabel
                            icon="plus"
                            disabled={isLocked}
                            title={isLocked ? undefined : "Add Attribute"}
                            onClick={onAddAttribute}
                        >
                            Add Attribute
                        </IconWithLabel>
                        <span>or</span>
                        <IconWithLabel
                            icon="upload"
                            disabled={isLocked}
                            title={isLocked ? undefined : "Import Attributes from CSV"}
                            onClick={onUploadAttributes}
                        >
                            Import Attributes from CSV
                        </IconWithLabel>
                    </NothingFoundBlock>
                )}
            </div>
        );
    }
);

export default AttributesForm;
