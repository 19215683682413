import React from "react";
import { isNil } from "lodash";
import { store } from "../../../../store/configureStore";
import { modalOpen } from "../../../../store/modal/actions";
import { deleteDashboard } from "../../../../store/dashboards/actions";
import Form from "./Form";

export const AVAILABLE_DASHBOARDS_KEY = "dashboards-available";

const TEXT_PANEL_KEY = "text";
const PBI_STANDARD_REPORT_PANEL_KEY = "report";
const PBI_PAGINATED_REPORT_PANEL_KEY = "paginated-report";

export const PBI_REPORT_TYPE = {
    STANDARD: "Standard",
    PAGINATED: "Paginated",
};

const baseUiSchema = {
    type: {
        "ui:placeholder": "Select widget type",
    },
};

const getBaseSchema = () => {
    const availablePanelTypes = getMenuItemsList();

    return {
        type: "object",
        required: ["type"],
        properties: {
            type: {
                type: "string",
                title: "Type",
                ...(availablePanelTypes.length > 0
                    ? {
                          anyOf: availablePanelTypes.map((i) => ({
                              title: i.menuItem,
                              enum: [i.itemKey],
                          })),
                      }
                    : {}),
            },
            name: {
                type: "string",
                title: "Title",
            },
        },
    };
};

export const getPanelSchema = (panelType) => {
    let schema = getBaseSchema();

    switch (panelType) {
        case PBI_STANDARD_REPORT_PANEL_KEY:
            schema = getReportPanelSchema(panelType);
            break;

        case PBI_PAGINATED_REPORT_PANEL_KEY:
            schema = getReportPanelSchema(panelType);
            break;

        case TEXT_PANEL_KEY:
            schema = getTextPanelSchema();
            break;

        default:
            break;
    }

    return schema;
};

export const getPanelUiSchema = (panelType) => {
    let uiSchema = baseUiSchema;

    switch (panelType) {
        case PBI_STANDARD_REPORT_PANEL_KEY:
            uiSchema = getReportPanelUiSchema(panelType);
            break;

        case PBI_PAGINATED_REPORT_PANEL_KEY:
            uiSchema = getReportPanelUiSchema(panelType);
            break;

        case TEXT_PANEL_KEY:
            uiSchema = getTextPanelUiSchema();
            break;

        default:
            break;
    }

    return uiSchema;
};

export const getReportType = (itemKey) => {
    const dashboardsAvailable =
        store.getState().resources.dashboardsAvailable.itemsById[AVAILABLE_DASHBOARDS_KEY]?.availableDashboardList ?? [];

    return dashboardsAvailable
        .filter((i) => i.itemKey === PBI_STANDARD_REPORT_PANEL_KEY || i.itemKey === PBI_PAGINATED_REPORT_PANEL_KEY)
        ?.flatMap((i) => i.childList)
        ?.find((i) => i.itemKey === itemKey)?.dashboardType === "Paginated Report"
        ? PBI_REPORT_TYPE.PAGINATED
        : PBI_REPORT_TYPE.STANDARD;
};

export const getReportPanelSchema = (panelType) => {
    const baseSchema = getBaseSchema();
    const availableReports = getMenuItemChildList(panelType);

    const required = (baseSchema.required || []).concat(["reportId"]);

    return {
        ...baseSchema,
        required,
        properties: {
            ...baseSchema.properties,
            reportId: {
                type: "string",
                title: "Report",
                ...(availableReports.length > 0
                    ? {
                          anyOf: availableReports.map((i) => ({
                              title: i.menuItem,
                              enum: [i.itemKey],
                          })),
                      }
                    : {}),
            },
            entityType: {
                type: "integer",
                title: "Entity Type",
                anyOf: [
                    {
                        title: "None",
                        enum: [0],
                    },
                    {
                        title: "Utility",
                        enum: [1],
                    },
                    {
                        title: "Program",
                        enum: [2],
                    },
                    {
                        title: "Application",
                        enum: [3],
                    },
                ],
            },
            entityNumber: {
                type: "string",
                title: "Entity Number",
            },
        },
    };
};

export const getReportPanelUiSchema = (panelType) => {
    const availableReports = getMenuItemChildList(panelType);

    return {
        ...baseUiSchema,
        reportId: {
            "ui:placeholder": availableReports.length > 0 ? "Select Report" : "No Reports",
            "ui:disabled": availableReports.length === 0,
        },
        entityType: {
            "ui:placeholder": "Select Entity Type",
        },
    };
};

export const getTextPanelSchema = () => {
    const baseSchema = getBaseSchema();

    return {
        ...baseSchema,
        properties: {
            ...baseSchema.properties,
            text: {
                type: "string",
                title: "Text",
            },
        },
    };
};

export const getTextPanelUiSchema = () => {
    return {
        ...baseUiSchema,
        text: {
            "ui:widget": "HtmlEditorWidget",
        },
    };
};

export const getMenuItemsList = () => {
    const dashboardsAvailable =
        store.getState().resources.dashboardsAvailable.itemsById[AVAILABLE_DASHBOARDS_KEY]?.availableDashboardList ?? [];

    return dashboardsAvailable.map((i) => ({
        menuItem: i.menuItem,
        itemKey: i.itemKey,
    }));
};

export const getMenuItemChildList = (itemKey) => {
    const dashboardsAvailable =
        store.getState().resources.dashboardsAvailable.itemsById[AVAILABLE_DASHBOARDS_KEY]?.availableDashboardList ?? [];

    return dashboardsAvailable.filter((i) => i.itemKey === itemKey).flatMap((i) => i.childList);
};

export const openDeleteDashboardDialog = (dashboard, setIsLocked) => {
    const message = (
        <>
            <p>
                This action will permanently delete dashboard <b>{dashboard.name}</b>. Once completed, this action cannot be undone.
            </p>
            <p>Are you sure you want to do this?</p>
        </>
    );

    store.dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: "Delete Dashboard",
                modalIcon: "delete-trash-empty",
                overlayClassName: "modal-styled",
                className: "delete-dashboard-confirmation-modal modal-sm",
                footerContentCenter: true,
                content: message,
                onConfirm: () => {
                    store.dispatch(
                        deleteDashboard({
                            dashboard,
                        })
                    );
                    setIsLocked(false);
                },
                onClose: () => setIsLocked(false),
            },
        })
    );
};

export const getDashboardFormComponent = ({ dashboardType, dashboard, setIsLocked, onClose }) => {
    const title = isNil(dashboard) ? "Add Dashboard" : "Edit Dashboard";

    return (
        <Form
            dashboardType={dashboardType}
            dashboard={dashboard}
            onClose={() => {
                setIsLocked(false);
                onClose();
            }}
            title={title}
        />
    );
};

export const createLandingPageSubMenuItem = (dashboard, availablePanels) => {
    return {
        id: dashboard.id,
        text: dashboard.name,
        component: "ResponsiveGridDashboard",
        icon: "dashboard-view-dashboard-empty",
        props: {
            dashboard,
            dashboardData: {
                dashboardId: dashboard.id,
            },
            availablePanels,
        },
    };
};
