import React from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { get, isNil, isEmpty, isEqual } from "lodash";
import { store } from "../../../../../../store/configureStore";
import { getData } from "../../../../../../store/dataGrid/actions";
import { modalOpen, modalClose } from "../../../../../../store/modal/actions";
import { setCalculationAssociations, setApprovedMeasureAssociations } from "../../../../../../store/associations/actions";
import { updateResource } from "../../../../../../store/resources/actions";
import { getResourceFromStore } from "store/resources/selectors";
import { getResourcePromise } from "../../../../../../store/resources/useResource";
import { uploadCSV, downloadCSV, lineToObject, showUploadCsvError } from "../../../../../utils/CSV";
import { referenceTypes } from "../../../../Reference/referenceTypes";
import { submitResource } from "../../../../../utils/form";
import { openAssignmentModal } from "../../../../AssignmentSelector/utils";
import { toast } from "react-toastify";
import { toArray } from "components/utils/array";
import {
    getAttributeFieldsFromCSV,
    validateAttributeFromCSV,
    getAttributesSubmitData,
    getAttributesImportFromCsvErrorText,
    isAttributePlaceholderLine,
    ATTRIBUTE_MAX_LENGTH,
} from "components/utils/attributes";
import { breakLongWords, isLowerCaseEqual } from "components/utils/string";
import IconWrap from "components/ui/Icons";
import { TrueFalse } from "components/utils/constants";

export const displayedProperty = {
    calculation: "calculationName",
    industryMeasure: "industryMeasureName",
};

export const getCatalogItemsGridId = ({ programNumber }) => {
    return `${programNumber}-catalog-grid`;
};

export const getCatalogItemsHistoryGridId = ({ programNumber }) => {
    return `${programNumber}-catalog-history-grid`;
};

export const getCatalogCategoriesGridId = ({ programNumber }) => {
    return `${programNumber}-catalog-categories-grid`;
};

export const onCatalogItemsGridRefresh = ({ programNumber }) => {
    const dataGridId = getCatalogItemsGridId({ programNumber });

    store.dispatch(getData({ dataGridId }));
};

export const openLookupAssignmentModal = ({ programNumber, catalogNumber, selectedItems }) => {
    const resourceDataPath = "data";
    const title = "Approved Measure Lookup Assignment";
    const idKey = "industryMeasureNumber";
    const nameKey = "industryMeasureName";

    const resourceGetParams = {
        resourceName: "programCatalogApprovedMeasures",
        key: `${programNumber}-${catalogNumber}`,
        path: {
            programNumber,
        },
        query: {
            catalogNumber,
        },
    };

    const onSelect = (approvedMeasures) => {
        store.dispatch(
            setApprovedMeasureAssociations({
                key: getCatalogKey({ programNumber, catalogNumber }),
                approvedMeasures: approvedMeasures,
            })
        );
    };

    openAssignmentModal({
        resourceGetParams,
        resourceDataPath,
        title,
        idKey,
        nameKey,
        selectedItems,
        onSelect,
    });
};

export const openCalculationAssignmentModal = async ({
    programNumber,
    catalogNumber,
    attributes,
    selectedItems,
    hasCalcAssignment,
    withSelect = false,
}) => {
    const resourceDataPath = "data";
    const title = "Catalog Calculation Assignment";
    const modalIcon = hasCalcAssignment ? "hub-filled" : "hub-empty";
    const submitButtonText = withSelect ? "Apply" : "Submit";
    const idKey = "calculationNumber";
    const nameKey = "calculationName";

    try {
        let catalogAttributes = attributes;

        if (!catalogAttributes) {
            let catalogItem = getResourceFromStore({
                resourceName: "programCatalogs",
                resourceId: catalogNumber,
            });

            if (!catalogItem) {
                catalogItem = await getProgramCatalog({
                    programNumber,
                    catalogNumber,
                });
            }

            if (!catalogItem) {
                return;
            }

            catalogAttributes = catalogItem.attributes ?? [];
        }

        const targetAttributeIds = catalogAttributes
            .map((a) => a.productAttrStandardId)
            // Add special target attribute id 0 for QUANTITY
            .concat(0);

        const resourceGetParams = {
            resourceName: "programCatalogCalculations",
            key: `${programNumber}-${catalogNumber}`,
            path: {
                programNumber,
            },
            query: {
                catalogNumber,
            },
            transform: (data) => {
                // take only calculations related to catalog item attributes
                return (data?.calculationList ?? []).filter((i) => targetAttributeIds.includes(i.targetAttributeId));
            },
        };

        const onSelect = (calculationAssociations) => {
            if (withSelect) {
                store.dispatch(
                    setCalculationAssociations({
                        key: getCatalogKey({ programNumber, catalogNumber }),
                        calculationAssociations: calculationAssociations.map((i) => ({
                            calculationName: i.calculationName,
                            calculationNumber: i.calculationNumber,
                            catalogAttrNumber: catalogAttributes.find((a) => a.productAttrStandardId === i.targetAttributeId)?.attrNumber,
                        })),
                    })
                );

                store.dispatch(
                    getData({
                        dataGridId: getCatalogItemsGridId({ programNumber }),
                    })
                );

                return;
            }

            const calculationNumbers = calculationAssociations.map((r) => r.calculationNumber);

            store.dispatch(
                updateResource({
                    resourceName: "calculationAssignments",
                    query: {
                        actionItem: "REPLACE",
                        scope: "CATALOG",
                    },
                    body: {
                        calculationNumber: calculationNumbers,
                        catalogNumber: [catalogNumber],
                    },
                    onSuccess: () => {
                        store.dispatch(
                            getData({
                                dataGridId: getCatalogItemsGridId({
                                    programNumber,
                                }),
                            })
                        );
                    },
                })
            );
        };

        const AvailableListItem = ({ contentRef, item, nameKey, selectedItems, style, onAdd }) => {
            // Item is disabled if some other item with same target attribute is already selected.
            const disabled = selectedItems.some((i) => i.targetAttributeId === item.targetAttributeId);
            const onItemAdd = disabled ? undefined : () => onAdd(item);

            return (
                <div
                    className={cn("assignment-selector__list-item flex-row", {
                        "assignment-selector__list-item--disabled": disabled,
                    })}
                    style={style}
                    onClick={onItemAdd}
                >
                    <span ref={contentRef}>{breakLongWords(item[nameKey], 30)}</span>
                    <span className="flex-one" />
                    <IconWrap
                        disabled={disabled}
                        iconWrap="arrow-right"
                        title={disabled ? "Calculation with the same target is already associated" : "Add >"}
                        onClick={onItemAdd}
                    />
                </div>
            );
        };
        const addAllHandler = ({ filteredAvailableItems, selectedItems, setSelectedItems }) => {
            let usedTargetAttributeId = [];
            let availableItems = [];
            selectedItems.forEach((item) => {
                usedTargetAttributeId.push(item.targetAttributeId);
            });
            filteredAvailableItems.forEach((item) => {
                if (!usedTargetAttributeId.includes(item.targetAttributeId)) {
                    usedTargetAttributeId.push(item.targetAttributeId);
                    availableItems.push(item);
                }
            });
            setSelectedItems(selectedItems.concat(availableItems));
        };
        openAssignmentModal({
            resourceGetParams,
            resourceDataPath,
            title,
            modalIcon,
            submitButtonText,
            idKey,
            nameKey,
            selectedItems,
            addAllDisabled: false,
            addAllHandler,
            onSelect,
            components: {
                AvailableListItem,
            },
        });
    } catch {}
};

export const getCatalogKey = ({ programNumber, catalogNumber }) => {
    return catalogNumber || `${programNumber}-catalog-new`;
};

export const uploadCategoryAssignment = ({ programNumber }) => {
    uploadCSV().then();
};

export const uploadLookupValues = ({ programNumber }) => {
    uploadCSV().then();
};

export const uploadCatalogAttributes = ({ programNumber }) => {
    uploadCSV({ delimiter: ",", header: true }).then((result) => {
        const processCSV = async () => {
            try {
                const validationTypes = await getValidationTypes();
                const attributeTypes = await getAttributeTypes({
                    programNumber,
                    resourceName: "programCatalogAttributes",
                });

                const idKey = "CATALOGNUMBER";

                // Create key value list of all catalog items form csv
                const uploadedCatalogItems = result.lines
                    .map((line) => lineToObject({ header: result.header, line }))
                    .reduce((acc, line, index) => {
                        // Ignore empty attribute placeholder line
                        if (isAttributePlaceholderLine({ line })) {
                            return acc;
                        }

                        const lineNumber = index + 2;
                        const catalogNumber = (line[idKey] ?? "").trim();

                        const productAttrStandardNumber = (
                            attributeTypes.find((i) => isLowerCaseEqual(i.productAttrDescription, line.ATTRNAME)) ?? {}
                        ).productAttrStandardNumber;

                        // Check if attribute exists
                        if (!productAttrStandardNumber) {
                            throw Error(`Line ${lineNumber}. Invalid ATTRNAME ${line.ATTRNAME}`);
                        }

                        // Check if attribute is not duplicate in csv
                        if ((acc[catalogNumber]?.attributes ?? []).find((a) => a.productAttrStandardNumber === productAttrStandardNumber)) {
                            throw Error(`Line ${lineNumber}. Duplicate ATTRNAME ${line.ATTRNAME}`);
                        }

                        const attribute = {
                            productAttrStandardNumber,
                            ...getAttributeFieldsFromCSV(line, validationTypes),
                        };

                        validateAttributeFromCSV({
                            attribute,
                            line,
                            lineNumber,
                            attributeName: line.ATTRNAME,
                            attributeMaxLength: ATTRIBUTE_MAX_LENGTH,
                        });

                        if (!acc[catalogNumber]) {
                            acc[catalogNumber] = {
                                catalogNumber,
                                name: line.NAME,
                                attributes: [],
                            };
                        }

                        acc[catalogNumber].attributes.push(attribute);

                        return acc;
                    }, {});

                // Get all catalog items found in uploaded csv
                const existingCatalogs = await Promise.all(
                    Object.values(uploadedCatalogItems).map((item) =>
                        getProgramCatalog({
                            programNumber,
                            catalogNumber: item.catalogNumber,
                        })
                    )
                );

                // Update attributes in catalog items
                const updatedItems = existingCatalogs.map((existingCatalog) => {
                    const uploadedCatalogAttributes = uploadedCatalogItems[existingCatalog.catalogNumber]?.attributes;

                    // Copy list and set attributes as existing
                    let attributesList = toArray(existingCatalog.attributes)
                        .slice()
                        .map((i) => ({ ...i, _existing: true }));

                    uploadedCatalogAttributes.forEach((uploadedAttr) => {
                        const existingAttrIndex = attributesList.findIndex(
                            (a) => a.productAttrStandardNumber === uploadedAttr.productAttrStandardNumber
                        );

                        if (existingAttrIndex > -1) {
                            attributesList[existingAttrIndex] = {
                                ...attributesList[existingAttrIndex],
                                ...uploadedAttr,
                                _existing: true,
                            };
                        } else {
                            attributesList.push(uploadedAttr);
                        }
                    });

                    return {
                        catalog: existingCatalog,
                        attributesList,
                    };
                });

                await Promise.all(
                    updatedItems.map((item) => {
                        return new Promise(async (resolve) => {
                            const { catalog, attributesList } = item;

                            const resourceParams = {
                                resourceName: "programCatalogs",
                                path: {
                                    programNumber,
                                },
                            };

                            const resourceId = catalog.catalogNumber;

                            const body = getSubmitData({
                                resource: catalog,
                                formData: catalog,
                                attributesList,
                                isExtendedView: true,
                                approvedMeasures: catalog.approvedMeasures,
                                calculationAssociations: catalog.calculationAssociations,
                            });

                            submitResource({
                                resourceParams,
                                resourceId,
                                body,
                                onComplete: resolve,
                                noResourceRefresh: true,
                                showSuccessNotification: false,
                            });
                        });
                    })
                );

                store.dispatch(modalClose());
                toast.success("Attributes Imported");
            } catch (error) {
                const text = getAttributesImportFromCsvErrorText({ error });
                showUploadCsvError({ text });
            }
        };

        store.dispatch(
            modalOpen({
                modalType: "WAITING_MODAL",
                modalProps: {
                    title: "Uploading Catalog Attributes...",
                    modalIcon: "upload",
                },
            })
        );

        // Process CSV on next tick to not block waiting modal
        setTimeout(processCSV, 0);
    });
};

export const downloadCatalogAttributes = async ({ programNumber, dataGridId }) => {
    store.dispatch(
        modalOpen({
            modalType: "WAITING_MODAL",
            modalProps: {
                title: "Preparing to Download Catalog Attributes...",
                modalIcon: "download",
            },
        })
    );

    try {
        const validationTypes = await getValidationTypes();
        const attributeTypes = await getAttributeTypes({
            programNumber,
            resourceName: "programCatalogAttributes",
        });
        const catalogs = await getProgramCatalogs({ programNumber });

        const catalogItemsPromises = await catalogs.map(
            async (catalog) =>
                await getProgramCatalog({
                    programNumber,
                    catalogNumber: catalog.catalogNumber,
                })
        );

        let nameFilter;
        let catalogNumberFilter;
        let catalogIdFilter;
        let statusFilter;
        let quantityFilter;

        const gridFilters = store.getState().dataGrid[dataGridId].filter.filters.filter((g) => !isNil(g.name) && !isNil(g.value));

        gridFilters.forEach((g) => {
            switch (g.name) {
                case "name":
                    nameFilter = g.value.toLowerCase();
                    break;
                case "catalognumber":
                    catalogNumberFilter = g.value.toLowerCase();
                    break;
                case "catalogid":
                    catalogIdFilter = g.value.toLowerCase();
                    break;
                case "status":
                    statusFilter = g.value.toLowerCase();
                    break;
                case "quantity":
                    quantityFilter = Number(g.value);
                    break;
                default:
                    break;
            }
        });

        // Ignore catalog item not found errors.
        const catalogItems = await Promise.allSettled(catalogItemsPromises).then((results) => {
            return results.filter((item) => item.status === "fulfilled").map((item) => item.value);
        });

        let data = catalogItems
            .map((catalog) => {
                const attributes = catalog.attributes || [];

                if (attributes.length === 0) {
                    return [
                        {
                            CATALOGID: catalog.catalogID,
                            CATALOGNUMBER: catalog.catalogNumber,
                            NAME: catalog.name,
                            ATTRNAME: "",
                            DEFAULTVALUE: "",
                            ITEMORDER: "",
                            FRIENDLYNAME: "",
                            TOOLTIP: "",
                            DATADESC: "",
                            VALIDATIONTYPE: "",
                            VALIDATIONOTHER: "",
                            VALIDATIONREQ: "",
                            LOWLIMIT: "",
                            HIGHLIMIT: "",
                            SHOWALL: "",
                            EDITALL: "",
                            LOOKUPVALUES: "",
                            STATUS: "",
                            QUANTITY: 0,
                        },
                    ];
                }

                return attributes.map((a) => ({
                    CATALOGID: catalog.catalogID,
                    CATALOGNUMBER: catalog.catalogNumber,
                    NAME: catalog.name,
                    ATTRNAME: (attributeTypes.filter((i) => i.productAttrStandardNumber === a.productAttrStandardNumber)[0] || {})
                        .productAttrDescription,
                    DEFAULTVALUE: a.defaultValue,
                    ITEMORDER: a.itemOrder,
                    FRIENDLYNAME: a.friendlyName,
                    TOOLTIP: a.friendlyNameToolTip,
                    DATADESC: a.dataDescription,
                    VALIDATIONTYPE: (validationTypes.filter((i) => Number(i.val) === Number(a.validationType))[0] || {}).display,
                    VALIDATIONOTHER: a.validationOther,
                    VALIDATIONREQ: a.validationReq === TrueFalse.True ? "TRUE" : "FALSE",
                    LOWLIMIT: a.lowerLimit,
                    HIGHLIMIT: a.upperLimit,
                    SHOWALL: a.showAll,
                    EDITALL: a.editAll,
                    LOOKUPVALUES: (a.lookupValues || []).map((v) => v.lookup).join("|"),
                    STATUS: catalog.statusID === 212 ? "Active" : "Disabled",
                    QUANTITY: catalog.quantity,
                }));
            })
            .reduce((result, next) => (result = result.concat(next)), [])
            .filter(
                (a) =>
                    a.NAME.toLowerCase() === (nameFilter ?? a.NAME.toLowerCase()) &&
                    a.CATALOGNUMBER.toLowerCase() === (catalogNumberFilter ?? a.CATALOGNUMBER.toLowerCase()) &&
                    a.CATALOGID.toLowerCase() === (catalogIdFilter ?? a.CATALOGID.toLowerCase()) &&
                    a.STATUS.toLowerCase() === (statusFilter ?? a.STATUS.toLowerCase()) &&
                    a.QUANTITY === (quantityFilter ?? a.QUANTITY)
            );

        if (catalogItems.length === 0) {
            data = [
                {
                    CATALOGID: "",
                    CATALOGNUMBER: "",
                    NAME: "",
                    ATTRNAME: "",
                    DEFAULTVALUE: "",
                    ITEMORDER: "",
                    FRIENDLYNAME: "",
                    TOOLTIP: "",
                    DATADESC: "",
                    VALIDATIONTYPE: "",
                    VALIDATIONOTHER: "",
                    VALIDATIONREQ: "",
                    LOWLIMIT: "",
                    HIGHLIMIT: "",
                    SHOWALL: "",
                    EDITALL: "",
                    LOOKUPVALUES: "",
                    STATUS: "",
                    QUANTITY: 0,
                },
            ];
        }

        downloadCSV({ data, fileName: `catalog_${programNumber}` });
        store.dispatch(modalClose());
    } catch (error) {
        const text = <strong>Catalog Attributes Download failed</strong>;

        store.dispatch(
            modalOpen({
                modalType: "SIMPLE_DIALOG",
                modalProps: {
                    text: text,
                    errorModal: true,
                },
            })
        );
    }
};

export const getSubmitData = ({ resource, formData, attributesList, approvedMeasures, calculationAssociations }) => {
    const attributes = getAttributesSubmitData({
        attributeNumberKey: "productAttrStandardNumber",
        attributes: attributesList,
        resource,
    });

    const itemSettings = {
        budgetLineNumber: formData?.budgetLineNumber,
        budgetCostId: formData?.budgetCostId,
    };

    return {
        ...formData,
        quantityFriendlyName: formData.quantityFriendlyName === "Quantity" ? null : formData.quantityFriendlyName,
        ...itemSettings,
        approvedMeasures,
        calculationAssociations,
        attributes,
    };
};

export const isItemChanged = ({ resource, submitData }) => {
    let isChanged = false;

    if (isNil(resource)) {
        return true;
    }

    ["name", "categoryNumber", "statusID", "budgetLineNumber", "budgetCostId", "quantity", "admMeasureFilter"].forEach((key) => {
        if (
            (isEmpty(resource[key]) && !isEmpty(submitData[key])) ||
            (!isEmpty(resource[key]) && isEmpty(submitData[key])) ||
            (resource[key] && submitData[key] && resource[key] !== submitData[key])
        ) {
            isChanged = true;
        }
    });

    // attributes
    if (!isChanged) {
        isChanged = submitData.attributes.length > 0;
    }

    // calculationAssociations
    if (!isChanged) {
        isChanged = submitData.calculationAssociations.length !== resource.calculationAssociations.length;
    }

    if (!isChanged) {
        isChanged =
            submitData.calculationAssociations.filter(
                (ca) => resource.calculationAssociations.filter((rca) => rca.calculationNumber === ca.calculationNumber).length === 0
            ).length > 0;
    }

    // approvedMeasures
    if (!isChanged) {
        isChanged = submitData.approvedMeasures.length !== resource.approvedMeasures.length;
    }

    if (!isChanged) {
        isChanged =
            submitData.approvedMeasures.filter(
                (ca) => resource.approvedMeasures.filter((rca) => rca.industryMeasureNumber === ca.industryMeasureNumber).length === 0
            ).length > 0;
    }

    return isChanged;
};

export const getValidationTypes = async () => {
    const resourceKey = referenceTypes.equipmentValidation;

    let result = get(store.getState(), `resources.reference.itemsById[${resourceKey}]`);

    if (!result) {
        result = await getResourcePromise({
            resourceName: "reference",
            key: resourceKey,
            query: {
                type: resourceKey,
                outputType: "id",
            },
        });
    }

    return result;
};

export const getAttributeTypes = async ({ programNumber, resourceName }) => {
    let result = get(store.getState(), `resources.${resourceName}.itemsById[${programNumber}]`);

    if (!result) {
        result = await getResourcePromise({
            resourceName,
            key: programNumber,
            path: {
                programNumber,
            },
        });
    }

    return result;
};

export const getProgramCatalog = async ({ programNumber, catalogNumber }) => {
    return await getResourcePromise({
        resourceName: "programCatalogs",
        resourceId: catalogNumber,
        path: {
            programNumber,
        },
    });
};

export const getProgramCatalogs = async ({ programNumber }) => {
    return await getResourcePromise({
        resourceName: "programCatalogs",
        key: programNumber,
        path: {
            programNumber,
        },
    });
};

export const useAttributes = ({ entityKey }) => useSelector((state) => state.attributes[entityKey], isEqual) ?? [];
