import React, { useCallback, useState, memo } from "react";

import { useResource, useCalculationTestInputs } from "../../../../../store/resources/useResource";
import { formatJsonDateTime } from "../../../../utils/date";
import { isProgramCalculation } from "./utils";

import WaitIcon from "../../../WaitIcon";
import FooterActions from "./FooterActions";
import IconWithLabel from "../../../Icons/IconWithLabel";
import SideNavBody from "../../../SideNav/SideNavBody";
import SideNavContent from "../../../SideNav/SideNavContent";
import SideNavHeader from "../../../SideNav/SideNavHeader";
import SideNavFooter from "../../../SideNav/SideNavFooter";
import SideNavHeaderInfoList from "components/ui/SideNav/SideNavHeaderInfoList";
import CalculationDetails from "./CalculationDetails";
import UpdateCalculationPanel from "./UpdateCalculationPanel";
import CalculationAssociations from "./CalculationAssociations";
import CalculationRevisionsPanel from "./CalculationRevisionsPanel";

import "./CalculationDetailsForm.scss";

const CalculationDetailsForm = memo(({ utilityNumber, programNumber, rowData, onClose, sidePanel, disabled }) => {
    const [showEditPanel, setShowEditPanel] = useState(false);
    const [showRevisions, setShowRevisions] = useState(false);
    const [showAssociations, setShowAssociations] = useState(false);

    const calculationType = rowData.calculationType.toUpperCase();

    const [calculation, calculationIsLoading] = useResource({
        resourceName: "calculations",
        resourceId: rowData.calculationNumber,
        forced: true,
    });

    const [testInputs, isLoadingTestInputs] = useCalculationTestInputs({
        calculationNumber: rowData.calculationNumber,
    });

    const headerItems = [
        {
            label: "Calculation Name",
            value: rowData.calculationName,
            className: "calc-name",
        },
        {
            label: programNumber ? "Target Form Field" : "Target Attribute",
            value: rowData.target,
        },
        {
            label: "Revision Date",
            value: formatJsonDateTime(rowData.revisionDate),
        },
        {
            label: "User",
            value: rowData.userName,
        },
    ];

    const onEdit = useCallback(() => {
        setShowEditPanel(true);
    }, []);

    if (showEditPanel) {
        return (
            <UpdateCalculationPanel
                disabled={disabled}
                utilityNumber={utilityNumber}
                programNumber={programNumber}
                rowData={rowData}
                onClose={onClose}
                sidePanel={sidePanel}
            />
        );
    }

    if (showRevisions) {
        return (
            <CalculationRevisionsPanel
                disabled={disabled}
                utilityNumber={utilityNumber}
                rowData={rowData}
                onClose={() => setShowRevisions(false)}
                sidePanel={sidePanel}
            />
        );
    }

    if (showAssociations) {
        return (
            <CalculationAssociations
                disabled={disabled}
                utilityNumber={utilityNumber}
                rowData={rowData}
                onClose={() => setShowAssociations(false)}
                sidePanel={sidePanel}
            />
        );
    }

    return (
        <SideNavContent>
            <SideNavBody rowLayout noPadding className="calculation-details-panel">
                <SideNavHeader title="Calculation Details" sidenavHeaderLeftAligned leadBlockIcon="eye-visibility-empty" onClose={onClose}>
                    <div className="flex-column fill-height">
                        <SideNavHeaderInfoList items={headerItems} />
                        <div className="flex-one" />
                        <div>
                            <IconWithLabel icon="assignment" onClick={() => setShowRevisions(true)}>
                                See Revisions
                            </IconWithLabel>
                            {!isProgramCalculation({ calculationType }) && (
                                <IconWithLabel icon="hub-empty" onClick={() => setShowAssociations(true)}>
                                    See Associations
                                </IconWithLabel>
                            )}
                        </div>
                    </div>
                </SideNavHeader>
                {calculationIsLoading ? (
                    <WaitIcon />
                ) : calculation ? (
                    <CalculationDetails
                        viewOnly
                        disabled={disabled}
                        utilityNumber={utilityNumber}
                        programNumber={programNumber}
                        calculationType={calculationType}
                        targetAttrId={calculation.targetAttrId}
                        calculationStr={calculation.calculationStr}
                        calculation={{
                            calculationNumber: rowData.calculationNumber,
                            ...calculation,
                        }}
                        testInputs={testInputs}
                        isLoadingTestInputs={isLoadingTestInputs}
                    />
                ) : (
                    <div>Calculation not found</div>
                )}
            </SideNavBody>
            <SideNavFooter justifyCenter>
                <FooterActions isDetails onEdit={onEdit} onCancel={onClose} />
            </SideNavFooter>
        </SideNavContent>
    );
});

export default CalculationDetailsForm;
