import React, { useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { orderBy } from "lodash";

import { updateLandingPagePanel } from "../../../store/dashboards/actions";
import { useResource } from "../../../store/resources/useResource";
import { clearData, construct } from "../../../store/dataGrid/actions";
import { searchAttrToFilter } from "../../../components/utils/datagrid";

import DataGrid from "../../ui/DataGrid";
import Separator from "../../ui/Separator";
import { availableGrids, applicationsSummaryStatusGridColumnKeys } from "../../views/configureGrids";

import DropDownInput from "../../ui/Input/DropDownInput";
import UtilityProgramsDropDown from "../../ui/Dashboard/Panel/CalculationsPanel/UtilityProgramsDropDown";
import { WarningMessage } from "../../ui/Message/index";
import { openProgramTab } from "store/window/openTabActions";

const ApplicationsSummaryStatus = memo(({ panel }) => {
    const dashboardId = panel.data.dashboardId;
    const panelId = panel.id;
    const dataGridId = `${panelId}-applications-summary-status`;
    const { utilityNumber, programNumber } = panel;

    const dispatch = useDispatch();
    const dataGridConfig = useSelector((state) => state.dataGrid[dataGridId]);

    const rowActions = [
        {
            name: "open",
            icon: "open-new-window",
            title: "Open Program Applications",
        },
    ];

    const [utilities, isLoadingUtilities] = useResource({
        resourceName: "utilities",
        key: "utilities",
        transform: (data) => {
            return orderBy(data || [], [(item) => item.utility], ["asc"]).map((i) => ({
                label: i.utility,
                value: i.utilityNumber,
            }));
        },
    });

    useEffect(() => {
        if (programNumber) {
            dispatch(
                construct({
                    dataGridId: availableGrids.applicationsSummaryStatus,
                    dataGridInstanceId: dataGridId,
                    filter: searchAttrToFilter(`${applicationsSummaryStatusGridColumnKeys.programNumber}=${programNumber}`),
                    data: true,
                })
            );

            dispatch(
                updateLandingPagePanel({
                    dashboardId,
                    panelId,
                    panelSettings: { utilityNumber, programNumber },
                })
            );
        }
    }, [utilityNumber, programNumber, dashboardId, panelId, dataGridId, dispatch]);

    const onRowAction = (action) => {
        const { name, dataItem } = action;

        switch (name) {
            case "open":
                dispatch(
                    openProgramTab({
                        programNumber: dataItem[applicationsSummaryStatusGridColumnKeys.programNumber],
                        activePanel: "program-applications",
                        applicationStatus: dataItem[applicationsSummaryStatusGridColumnKeys.statusName],
                    })
                );

                break;
            default:
                break;
        }
    };

    const onUtilityChange = (number) => {
        dispatch(
            updateLandingPagePanel({
                dashboardId,
                panelId,
                panelSettings: {
                    utilityNumber: number,
                    programNumber: undefined,
                },
            })
        );

        dispatch(clearData({ dataGridId }));
    };

    const onProgramChange = (number) => {
        dispatch(
            updateLandingPagePanel({
                dashboardId,
                panelId,
                panelSettings: {
                    utilityNumber,
                    programNumber: number,
                },
            })
        );
    };

    const renderEmptyProgram = () => {
        return (
            <div className="application-form-warning-msg">
                <WarningMessage>No active program is available for selected utility. Please choose another utility.</WarningMessage>
            </div>
        );
    };

    return (
        <div className="panel sticky-grid-list-panel">
            <div className="flex-row">
                <div className="flex-column fill-width">
                    <DropDownInput
                        inline
                        label="Select Utility"
                        mobileHeader="Select Utility"
                        placeholder={isLoadingUtilities ? "Loading..." : "Select Utility"}
                        disabled={isLoadingUtilities}
                        data={utilities}
                        value={isLoadingUtilities ? undefined : utilityNumber}
                        onChange={(event) => onUtilityChange(event.target.value)}
                        withPopper
                    />
                </div>
                <Separator vertical />
                {utilityNumber && (
                    <div className="flex-column fill-width">
                        <UtilityProgramsDropDown
                            inline
                            label={"Select Program"}
                            mobileHeader={"Select Program"}
                            placeholder={"Select Program"}
                            utilityNumber={utilityNumber}
                            program={programNumber}
                            onChange={onProgramChange}
                            renderWhenEmpty={renderEmptyProgram()}
                            withPopper
                        />
                    </div>
                )}
            </div>
            <Separator />
            {programNumber && dataGridConfig && (
                <DataGrid name={dataGridId} config={dataGridConfig} customRowActions={rowActions} onRowAction={onRowAction} />
            )}
        </div>
    );
});

export default ApplicationsSummaryStatus;
