import React from "react";
import cn from "classnames";

import "./SideNavContent.scss";

const SideNavContent = ({ className, children, rowLayout }: SideNavContentProps) => {
    return (
        <div
            className={cn("sidenav-content", className, {
                "flex-row": rowLayout,
            })}
        >
            {children}
        </div>
    );
};

export interface SideNavContentProps {
    className?: string;
    children?: React.ReactNode;
    rowLayout?: boolean;
}

export default SideNavContent;
