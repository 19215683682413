import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import Dashboard from "../../ui/Dashboard";
import { dashboardTypes, utilityInvoiceDashboard } from "../../utils/dashboard";
import Header from "./Header";
import { windowSetClassName } from "../../../store/window/actions";
import { windowContainerTypes } from "../../utils/window";
import { useInvoiceDocumentLocked } from "./utils";
import { useInvoiceDocument } from "../../../store/resources/useResource";
import WaitIcon from "../../ui/WaitIcon";

const UtilityInvoiceView = (props) => {
    const { documentNumber } = props;

    const dispatch = useDispatch();
    const [invoice, isLoading] = useInvoiceDocument({
        documentNumber,
        forced: true,
    });
    const isLocked = useInvoiceDocumentLocked({ documentNumber });

    const dashboardData = useMemo(() => {
        if (invoice) {
            return {
                utilityNumber: invoice.utilityNumber,
                contractNumber: invoice.contractNumber,
                documentNumber: invoice.invoiceDocumentNumber,
                dashboardKey: documentNumber,
            };
        }

        return {
            dashboardKey: documentNumber,
        };
    }, [invoice, documentNumber]);

    useEffect(() => {
        dispatch(
            windowSetClassName({
                containerName: windowContainerTypes.Root,
                name: "I: #" + documentNumber,
                className: isLocked ? "entity-locked" : undefined,
            })
        );
    }, [documentNumber, isLocked, dispatch]);

    return (
        <div
            className={cn("utility-invoice-view flex-column fill-height no-scroll", {
                "utility-invoice-view--locked": isLocked,
            })}
        >
            {!invoice && isLoading && (
                <div className="flex-center">
                    <WaitIcon />
                </div>
            )}
            {invoice && !isLoading && (
                <Dashboard
                    key={documentNumber}
                    type={dashboardTypes.UTILITY_INVOICE}
                    activeDashboardId={utilityInvoiceDashboard.dashboardNumber}
                    dashboardData={dashboardData}
                    header={<Header {...props} {...dashboardData} />}
                />
            )}
        </div>
    );
};

export default UtilityInvoiceView;
