import { store } from "../../../../../../store/configureStore";
import { getData } from "../../../../../../store/dataGrid/actions";
import { getResource } from "../../../../../../store/resources/actions";

export const getNotesGridId = ({ applicationNumber }) => {
    return `${applicationNumber}-notes-grid`;
};

export const refreshNotesGrid = ({ applicationNumber }) => {
    const dataGridId = getNotesGridId({ applicationNumber });

    store.dispatch(getData({ dataGridId }));
};

export const refreshSidebarNotes = ({ applicationNumber }) => {
    store.dispatch(
        getResource({
            resourceName: "applicationNotes",
            key: applicationNumber,
            path: {
                appId: applicationNumber,
            },
            query: {
                number: 1000,
            },
        })
    );
};
