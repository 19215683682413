import React, { memo, useCallback } from "react";
import cn from "classnames";
import TextLabel from "components/ui/Label/TextLabel";
import SelectBox from "components/ui/SelectBox";
import { PortalBuilderPropertyProps } from "../../types";
import { useDefaultValue } from "../../utils";
import { PropertyListItem } from "../Property/PropertyListItem";

export const NotificationTypeProperty = memo((props: TypeSelectorPropertyProps) => {
    const { id, title, items, value, defaultValue, onChange } = props;

    useDefaultValue(id, value, defaultValue, onChange);

    const onSelect = useCallback(
        (value: any) => {
            onChange(id, value);
        },
        [id, onChange]
    );

    return (
        <PropertyListItem className={cn("property-list-item--type-selector")}>
            <div className="property-list-item-inner flex-column justify-space-between">
                <TextLabel>{title}</TextLabel>
                <div className="flex-row type-list">
                    {items.map((i, index) => {
                        return (
                            <SelectBox
                                className={cn(`${i.value} type-icon`)}
                                key={index}
                                selectBoxTitle={i.tooltip}
                                icon={i.icon}
                                selectBoxSelected={i.value === value}
                                onClick={() => onSelect(i.value)}
                            />
                        );
                    })}
                </div>
            </div>
        </PropertyListItem>
    );
});

interface TypeSelectorPropertyProps extends PortalBuilderPropertyProps {
    items: any[];
}
