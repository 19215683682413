import React, { useCallback, memo } from "react";
import { useDispatch } from "react-redux";
import { get } from "lodash";

import { getData } from "store/dataGrid/actions";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import Controls from "./Controls";
import Form from "./Form";
import Grid from "./Grid";

import "./style.scss";

const AttributesPanel = memo(({ panel }) => {
    const dispatch = useDispatch();

    const utilityNumber = get(panel, "data.utilityNumber");
    const contractNumber = get(panel, "data.contractNumber");
    const dataGridId = `${contractNumber}-contract-attributes-grid`;

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "contract-attributes-sidenav-panel",
    });

    const onRefresh = useCallback(() => {
        dispatch(getData({ dataGridId }));
    }, [dataGridId, dispatch]);

    const handleCreate = useCallback(() => {
        handleOpenSidePanel(
            <Form utilityNumber={utilityNumber} contractNumber={contractNumber} gridRefresh={onRefresh} onClose={handleCloseSidePanel} />
        );
    }, [utilityNumber, contractNumber, onRefresh, handleOpenSidePanel, handleCloseSidePanel]);

    return (
        <div className="panel contract-attributes flex-column fill-height">
            <PanelHeaderLarge title={panel.name} />
            <Controls contractNumber={contractNumber} dataGridId={dataGridId} onAdd={handleCreate} />
            <Grid dataGridId={dataGridId} utilityNumber={utilityNumber} contractNumber={contractNumber} onRefresh={onRefresh} />
        </div>
    );
});

export default AttributesPanel;
