import React, { useCallback } from "react";
import { get } from "lodash";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import Grid from "./Grid";
import Form from "./Form";
import { refreshInvoiceNotesGrid } from "../../../../../../store/dataGrid/refreshGrid";

import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";

const NotesPanel = ({ panel }) => {
    const utilityNumber = get(panel, "data.utilityNumber");
    const contractNumber = get(panel, "data.contractNumber");
    const documentNumber = get(panel, "data.documentNumber");

    const onRefresh = useCallback(() => {
        refreshInvoiceNotesGrid({ documentNumber });
    }, [documentNumber]);

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "invoice-notes-sidenav-panel",
    });

    const handleCreate = useCallback(() => {
        handleOpenSidePanel(
            <Form
                utilityNumber={utilityNumber}
                contractNumber={contractNumber}
                documentNumber={documentNumber}
                gridRefresh={onRefresh}
                onClose={handleCloseSidePanel}
            />
        );
    }, [utilityNumber, contractNumber, documentNumber, onRefresh, handleOpenSidePanel, handleCloseSidePanel]);

    return (
        <div className="panel invoice-notes flex-column fill-height">
            <PanelHeaderLarge title={panel.name} />
            <Grid
                utilityNumber={utilityNumber}
                contractNumber={contractNumber}
                documentNumber={documentNumber}
                onRefresh={onRefresh}
                onAdd={handleCreate}
            />
        </div>
    );
};

export default NotesPanel;
