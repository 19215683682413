import React, { useContext, useCallback, memo } from "react";

import Button from "../../../../../ui/Button";
import { DocumentQueueGridContext } from "../../../context/DocumentQueueGridContext";
import { BatchCheckbox } from "./BatchCheckbox";

import "./style.scss";

export const Controls = memo(
    ({ batchSize, isSelectionDisabled, isBatchFilled, expandedProgramsActions, onChangeBatchSize, onChangeCommonBatch }) => {
        const { isBatchProcessing, selectedProgramDocumentNumbers, createBatch } = useContext(DocumentQueueGridContext);

        const handleClickSentToPrint = useCallback(() => {
            if (!isSelectionDisabled && selectedProgramDocumentNumbers.length) {
                createBatch();
                expandedProgramsActions.clear();
            }
        }, [selectedProgramDocumentNumbers, createBatch, isSelectionDisabled, expandedProgramsActions]);

        return (
            <div className="application-document-queue__dq-grid-controls flex-row align-center">
                <BatchCheckbox
                    batchSize={batchSize}
                    isSelectionDisabled={isSelectionDisabled}
                    isBatchFilled={isBatchFilled}
                    onChangeBatchSize={onChangeBatchSize}
                    onChangeCommonBatch={onChangeCommonBatch}
                />
                <div
                    className={
                        "application-document-queue__dq-grid-controls-actions-group flex-row flex-one align-center" +
                        (selectedProgramDocumentNumbers.length > 0 ? "" : " hidden")
                    }
                >
                    <div className="application-document-queue__dq-grid-controls-selected-count flex-one">
                        {selectedProgramDocumentNumbers.length
                            ? `${selectedProgramDocumentNumbers.length} ${
                                  selectedProgramDocumentNumbers.length === 1 ? "Document" : "Documents"
                              } selected`
                            : "No Documents selected"}
                    </div>
                    <Button
                        primary
                        icon="printer-empty"
                        disabled={isSelectionDisabled || !selectedProgramDocumentNumbers.length || isBatchProcessing}
                        onClick={handleClickSentToPrint}
                    >
                        {isBatchProcessing ? "Processing..." : "Send to Printer"}
                    </Button>
                </div>
            </div>
        );
    }
);
