import React, { useContext } from "react";
import { PaddingPropertyContext } from "components/ui/PortalBuilder/contexts";
import { PaddingCustomPropertyName, PaddingSideName, PaddingSideProps, PaddingSides, PaddingSideBySideValue } from "./types";
import { PortalBuilderPropertyProps, PropertyName } from "components/ui/PortalBuilder/types";
import TextLabel from "components/ui/Label/TextLabel";
import { PaddingBox } from "./PaddingBox";
import { PropertyListItem } from "../PropertyListItem";
import { PADDING_SIZES } from "./AllSidesProperty";

export const SideBySideProperty = (props: SizeSideBySidePropertyProps) => {
    const { value, borderBottom, onChange } = props;
    const { customPropertiesValue } = useContext(PaddingPropertyContext);

    const onSelect = (id: PropertyName, paddingSide: SideBySidePadding) => (size: string) => {
        if (size === value[paddingSide]) {
            onChange(id, "0rem");
        } else {
            onChange(id, size);
        }
    };

    if (customPropertiesValue[PaddingCustomPropertyName.PaddingType] !== PaddingSides.SideBySide) {
        return null;
    }

    return (
        <PropertyListItem
            className="property-list-item--padding-size property-list-item--padding-side-by-side flex-column"
            borderBottom={borderBottom}
        >
            <PaddingSide
                side={PaddingSideName.Top}
                selectedValue={value[PaddingSideName.Top]}
                onSelect={onSelect(PropertyName.PaddingTop, PaddingSideName.Top)}
            />
            <PaddingSide
                side={PaddingSideName.Right}
                selectedValue={value[PaddingSideName.Right]}
                onSelect={onSelect(PropertyName.PaddingRight, PaddingSideName.Right)}
            />
            <PaddingSide
                side={PaddingSideName.Bottom}
                selectedValue={value[PaddingSideName.Bottom]}
                onSelect={onSelect(PropertyName.PaddingBottom, PaddingSideName.Bottom)}
            />
            <PaddingSide
                side={PaddingSideName.Left}
                selectedValue={value[PaddingSideName.Left]}
                onSelect={onSelect(PropertyName.PaddingLeft, PaddingSideName.Left)}
            />
        </PropertyListItem>
    );
};

const PaddingSide = (props: PaddingSideProps) => {
    const { side, selectedValue: selectedSize, onSelect } = props;

    return (
        <div className="padding-side flex-row fill-size align-center">
            <TextLabel>{side}</TextLabel>
            <div className="padding-boxes flex-one-in-row flex-row">
                {PADDING_SIZES.map((size, index) => (
                    <PaddingBox
                        text={size.text}
                        tooltip={size.tooltip}
                        key={index}
                        isSelected={selectedSize === size.value}
                        onClick={() => onSelect(size.value)}
                    />
                ))}
            </div>
        </div>
    );
};

interface SizeSideBySidePropertyProps extends Omit<PortalBuilderPropertyProps, "value"> {
    value: PaddingSideBySideValue;
}

type SideBySidePadding = Exclude<PaddingSideName, PaddingSideName.AllSides>;
