import React, { useContext, useRef } from "react";
import { PortalBuilderContext } from "components/ui/PortalBuilder/contexts";
import { PropertyList } from "../../PropertyList";
import { set, cloneDeep } from "lodash";
import { PortalTemplateConfiguration, PropertyValue } from "components/ui/PortalBuilder/types";
import { LINK_GLOBAL_PROPERTIES } from "components/ui/PortalBuilder/utils";
import { PropertyListItemTitle } from "../../PropertyList/PropertyListItemTitle";
import { PropertyListItem } from "../../Property/PropertyListItem";
import { usePortalBuilderState } from "components/ui/PortalBuilder/PortalBuilderContextProvider";

import "./GlobalLinkProperties.scss";

export const GlobalLinkProperties = () => {
    const { onConfigChange } = useContext(PortalBuilderContext);
    const [updatedConfig] = usePortalBuilderState((state) => state.updatedConfig);

    const sectionConfig = updatedConfig?.widgets?.link;

    const configRef = useRef<PortalTemplateConfiguration>(updatedConfig);
    configRef.current = updatedConfig;

    const onPropertyChange = (id: string, value: PropertyValue) => {
        let updatedConfigClone = cloneDeep(configRef.current);
        set(updatedConfigClone, `widgets.link[${id}]`, value);
        onConfigChange(updatedConfigClone);
    };

    return (
        <div className="flex-column fill-width with-scroll">
            <PropertyListItem className="link-color-properties property-list-item--prop-group">
                {/* @ts-ignore */}
                <PropertyListItemTitle
                    title={
                        <>
                            Link <br /> Text Color
                        </>
                    }
                />
                <PropertyList
                    items={LINK_GLOBAL_PROPERTIES}
                    config={sectionConfig}
                    nestingLevel={2}
                    onChange={onPropertyChange}
                    isExpanded
                />
            </PropertyListItem>
        </div>
    );
};
