import React, { useState, memo, useRef, useCallback, useEffect } from "react";
import { pickBy } from "lodash";

import { useResource, useBudgetLookups } from "../../../../../../store/resources/useResource";
import { getInvoiceItemFormSchema, getInvoiceItemFormUiSchema } from "../utils";
import { submitResource, submitByRef } from "../../../../../utils/form";
import { useInvoiceDocumentLocked } from "../../../../../views/UtilityInvoiceView/utils";

import { ErrorMessage } from "../../../../Message";
import WaitIcon from "../../../../WaitIcon";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import GridDetailsFooterActions from "../../../../DataGrid/GridDetailsFooterActions";
import Button from "../../../../Button";

import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";

const Form = memo((props) => {
    const { utilityNumber, contractNumber, documentNumber, dataItem, onClose, gridRefresh, sidePanel } = props;

    const isNew = dataItem === undefined;

    const resourceId = isNew ? null : dataItem.invoiceNumber;

    const title = isNew ? "Add Invoice Item" : "Edit Invoice Item";

    const icon = isNew ? "plus" : "edit-empty";

    const [isSubmitting, setSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const isLocked = useInvoiceDocumentLocked({ documentNumber });

    const [resource, isLoading] = useResource({
        resourceName: "utilitiesContractsInvoiceDocumentsInvoices",
        resourceId,
        path: {
            utilityNumber,
            contractNumber,
            documentNumber,
        },
        forced: true,
        onError: (action) => {
            setError(action.message);
        },
    });

    const formRef = useRef();

    const handleSave = useCallback(() => {
        submitByRef(formRef);
    }, []);

    const [budgetLookups = [], isLoadingBudgetLookups] = useBudgetLookups({
        utilityNumber,
        contractNumber,
    });

    const schema = getInvoiceItemFormSchema({
        withCostCode: isNew,
        costCodeRequired: isNew && budgetLookups.length > 0,
    });
    const uiSchema = getInvoiceItemFormUiSchema({
        withCostCode: isNew,
        budgetLookups,
    });

    const initialValues = isNew ? {} : pickBy(resource);

    const submitText = isSubmitting ? "Saving..." : "Save";

    const onSubmit = (formData) => {
        const resourceParams = {
            resourceName: "utilitiesContractsInvoiceDocumentsInvoices",
            path: {
                utilityNumber,
                contractNumber,
                documentNumber,
            },
        };

        const body = {
            ...formData,
            ...(isNew && formData.blItemList ? { blItemList: formData.blItemList.split("|") } : {}),
        };

        submitResource({
            resourceParams,
            resourceId,
            body,
            onRefresh: gridRefresh,
            onSuccess: sidePanel.close,
            setSubmitting,
        });
    };

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    if (isLoading || isLoadingBudgetLookups) {
        return <WaitIcon />;
    }

    const otherActions =
        isNew || isLocked ? null : (
            <>
                <span className="flex-one" />
                <GridDetailsFooterActions {...props} />
            </>
        );

    return (
        <SideNavContent>
            <SideNavHeader title={title} leadBlockIcon={icon} smallHeader onClose={onClose} />
            <SideNavBody className="flex-one-in-column">
                {error ? (
                    <div className="with-padding">
                        <ErrorMessage>{error}</ErrorMessage>
                    </div>
                ) : (
                    <JsonSchemaForm
                        formRef={formRef}
                        schema={schema}
                        uiSchema={uiSchema}
                        initialValues={initialValues}
                        disabled={isSubmitting}
                        readOnly={isLocked}
                        onSubmit={onSubmit}
                        onCancel={onClose}
                        submitText={submitText}
                        noReset
                        otherActions={otherActions}
                        noActions
                    />
                )}
            </SideNavBody>
            {!error && (
                <SideNavFooter setPrimaryButton>
                    <Button primary onClick={handleSave} disabled={isSubmitting}>
                        {submitText}
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </SideNavFooter>
            )}
        </SideNavContent>
    );
});

export default Form;
