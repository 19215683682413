import React from "react";
import RawHtml from "components/ui/RawHtml";

import "./NotePreview.scss";

const NotePreview = ({ children }) => {
    if (!children) return null;

    const parser = new DOMParser();
    const parsed = parser.parseFromString(children, "text/html");
    let links = parsed.querySelectorAll("a");
    links.forEach((link) => link.setAttribute("target", "_blank"));
    children = parsed.body.innerHTML;
    return <RawHtml className="note-preview">{children}</RawHtml>;
};

export default NotePreview;
