import React, { memo, useMemo, useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { isNil } from "lodash";
import JsonSchemaForm from "../Form/JsonSchema/JsonSchemaForm";
import { anyOfStatuses, pickPageStatusPropertyValue } from "../../utils/form";
import { getSelectedElement, getSelectedElementUiParams, getFormBuilder } from "../../../store/formBuilder/selectors";
import { onPagePropertiesChange, onPagePropertiesValidate } from "./utils";
import { ApplicationFormPagesContext } from "components/views/ApplicationFormPages";
import usePortalWizardSteps from "./utils/hooks/usePortalWizardSteps";
import { RunAppStatusInWizard, TrueFalse, YesNo } from "components/utils/constants";

const PageProperties = memo(({ formRef, instanceId, disabled, onChange }) => {
    const { programNumber, formNumber } = useContext(ApplicationFormPagesContext);

    const formBuilder = useSelector((state) => getFormBuilder({ instanceId, state: state.formBuilder }));
    const selectedElementId = formBuilder.selectedElementId;

    const [portalWizardSteps, isLoadingPortalWizardSteps] = usePortalWizardSteps({ programNumber });

    const element = useMemo(() => {
        return getSelectedElement(formBuilder);
    }, [formBuilder]);

    const elementUiParams = useMemo(() => {
        return getSelectedElementUiParams(formBuilder);
    }, [formBuilder]);

    const onPropertiesChange = useCallback(
        (props) => {
            onPagePropertiesChange({
                instanceId,
                selectedElementId,
                programNumber,
                formNumber,
                ...props,
            });
            onChange && onChange({ instanceId, selectedElementId, ...props });
        },
        [instanceId, selectedElementId, programNumber, formNumber, onChange]
    );

    const schema = {
        type: "object",
        required: ["title", "status", "runAppStatusInWizard"],
        properties: {
            title: {
                type: "string",
                title: "Title",
            },
            description: {
                type: "string",
                title: "Description",
            },
            defaultPage: {
                type: "boolean",
                title: "Default Page",
            },
            denyLimitedAccess: {
                type: "boolean",
                title: "Deny Limited Access",
            },
            allowEditAppForm: {
                type: "boolean",
                title: "Allow Edit of App Form",
            },
            showFormPageOnApp: {
                type: "boolean",
                title: "Show Form Page on App",
            },
            disqualificationPage: {
                type: "boolean",
                title: "Is Disqualification Page",
            },
            status: {
                type: "integer",
                title: "Status",
                anyOf: anyOfStatuses,
            },
            runAppStatusInWizard: {
                type: "string",
                title: "Run App Status in Wizard",
                anyOf: [
                    {
                        title: "Do not run",
                        enum: [RunAppStatusInWizard.None],
                    },
                    {
                        title: "Entry Status",
                        enum: [RunAppStatusInWizard.Entry],
                    },
                    {
                        title: "Received Status",
                        enum: [RunAppStatusInWizard.Received],
                    },
                ],
            },
            ...(!isLoadingPortalWizardSteps &&
                portalWizardSteps && {
                    portalWizardStep: {
                        type: "string",
                        title: "Portal Wizard Step",
                        anyOf: portalWizardSteps,
                    },
                }),
            pageNumber: {
                type: "string",
                title: "Page Number",
            },
        },
    };

    const uiSchema = {
        title: {
            "ui:widget": "textarea",
        },
        description: {
            "ui:widget": "html",
        },
        status: {
            "ui:widget": "radio",
        },
        runAppStatusInWizard: {
            "ui:widget": "radio",
        },
        portalWizardStep: {
            "ui:placeholder": "None",
            "ui:emptyItem": true,
        },
        pageNumber: {
            "ui:widget": "readonlyvalue",
            "ui:disabled": "true",
        },
    };

    const initialValues = {
        title: element["title"],
        description: element["description"],
        defaultPage: Number(elementUiParams[`af:defaultPage`]) === TrueFalse.True,
        status: pickPageStatusPropertyValue(elementUiParams[`af:status`]),
        denyLimitedAccess: elementUiParams[`af:denyLimitedAccess`] === YesNo.Yes,
        allowEditAppForm: elementUiParams[`af:allowEditAppForm`] === YesNo.Yes,
        disqualificationPage: elementUiParams[`af:disqualificationPage`] === YesNo.Yes,
        runAppStatusInWizard:
            elementUiParams[`af:runAppEntryStatusInWizard`] === YesNo.Yes
                ? RunAppStatusInWizard.Entry
                : elementUiParams[`af:runAppReceivedStatusInWizard`] === YesNo.Yes
                ? RunAppStatusInWizard.Received
                : RunAppStatusInWizard.None,
        showFormPageOnApp: elementUiParams[`af:showFormPageOnApp`] === YesNo.Yes,
        pageNumber: elementUiParams["af:pageNumber"],
        portalWizardStep: elementUiParams["af:portalWizardStep"],
    };

    return (
        <div className="element-properties fill-width with-scroll">
            <JsonSchemaForm
                formRef={formRef}
                schema={schema}
                uiSchema={uiSchema}
                initialValues={initialValues}
                validate={onPagePropertiesValidate}
                disabled={disabled}
                onChange={onPropertiesChange}
                noActions
                liveValidate={!isNil(formBuilder.validationErrors[elementUiParams["af:pageNumber"]])}
                debounce
            />
        </div>
    );
});

export default PageProperties;
