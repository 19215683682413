import React, { memo, useMemo } from "react";
import CalculationDetailsForm from "../../../CalculationsPanel/CalculationDetailsForm";
import WaitIcon from "components/ui/WaitIcon";
import { useDataGridData } from "store/resources/useResource";
import { availableGrids, utilitiesCalculationsGridColumnKeys } from "components/views/configureGrids";
import { mapGridRowToObject } from "components/utils/datagrid";
import { ErrorMessage } from "components/ui/Message";

const Calculation = memo(({ utilityNumber, programNumber, calculationNumber, onClose }) => {
    const [gridData = [], isLoadingGrid] = useDataGridData({
        dataGridId: availableGrids.utilitiesCalculations,
        key: `${utilityNumber}-${calculationNumber}-grid-row`,
        filter: `${utilitiesCalculationsGridColumnKeys.calculationNumber}=${calculationNumber}`,
        pageSize: 1,
    });

    const rowData = useMemo(() => {
        if (gridData.length > 0) {
            return mapGridRowToObject(utilitiesCalculationsGridColumnKeys, gridData[0]);
        }

        return null;
    }, [gridData]);

    const sidePanel = useMemo(
        () => ({
            close: onClose,
            success: onClose,
            setForm: () => {},
        }),
        [onClose]
    );

    if (isLoadingGrid) {
        return <WaitIcon />;
    }

    if (rowData === null) {
        return <ErrorMessage>Calculation not found</ErrorMessage>;
    }

    return (
        <CalculationDetailsForm
            utilityNumber={utilityNumber}
            programNumber={programNumber}
            rowData={rowData}
            onClose={onClose}
            sidePanel={sidePanel}
        />
    );
});

export default Calculation;
