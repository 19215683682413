import { useLayoutEffect } from "react";
import ResizeObserver from "resize-observer-polyfill";
import { getScrollParent } from "./scroll";

export const isHover = (element) => {
    return element.parentElement && element.parentElement.querySelector(":hover") === element;
};

/**
 * Is element in viewport.
 *
 * @param {object} element
 * @returns true/false
 */
export const isInViewport = (element, offset = 0) => {
    var rect = element.getBoundingClientRect();

    return (
        rect.top >= 0 + offset &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) - offset &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

/**
 * Check if element is visible.
 * @param {Element} element - The element to check.
 */
export const isVisible = (element) => {
    if (!element) {
        return false;
    }

    if (element.offsetParent === null) {
        return false;
    }

    if (element.style.display === "none") {
        return false;
    }

    if (element.style.visibility === "hidden") {
        return false;
    }

    if (element.style.opacity === "0") {
        return false;
    }

    return true;
};

/**
 * Waits for an element satisfying selector to exist, then resolves promise with the element.
 * Source: https://gist.github.com/jwilson8767/db379026efcbd932f64382db4b02853e
 *
 * @param selector
 * @returns {Promise}
 */
export const elementReady = (selector, parentEl) => {
    parentEl = parentEl || document;
    return new Promise((resolve, reject) => {
        const el = parentEl.querySelector(selector);

        if (el) {
            resolve(el);
        }

        new MutationObserver((mutationRecords, observer) => {
            // Query for elements matching the specified selector
            Array.from(parentEl.querySelectorAll(selector)).forEach((element) => {
                resolve(element);
                //Once we have resolved we don't need the observer anymore.
                observer.disconnect();
            });
        }).observe(document.documentElement, {
            childList: true,
            subtree: true,
        });
    });
};

/**
 * IE11 compatible custom event dispatch.
 * https://stackoverflow.com/a/49071358
 *
 * @param {object} element
 * @param {string} eventType
 */
export const dispatchEvent = (element, eventType) => {
    var event;
    if (typeof Event === "function") {
        event = new Event(eventType);
    } else {
        event = document.createEvent("Event");
        event.initEvent(eventType, true, true);
    }

    element.dispatchEvent(event);
};

export const useAutoMaxHeight = ({ elementRef, offsetHeight, enabled = true }) => {
    useLayoutEffect(() => {
        const parent = elementRef && enabled ? getScrollParent(elementRef) : null;

        const onResize = () => {
            if (elementRef && parent) {
                elementRef.style.maxHeight = `${parent.clientHeight - (offsetHeight || 0)}px`;
            }
        };

        const observer = new ResizeObserver(() => {
            onResize();
        });

        if (parent) {
            onResize();
            window.addEventListener("resize", onResize);
            observer.observe(parent);
        }

        if (elementRef && !enabled) {
            elementRef.style.maxHeight = "";
        }

        return () => {
            window.removeEventListener("resize", onResize);
            observer.disconnect();
        };
    }, [elementRef, offsetHeight, enabled]);
};

export const getDropdownZIndex = ({ element, zIndexOffset = 1, zIndexOverride }) => {
    if (zIndexOverride) {
        return zIndexOverride;
    }

    let zIndex = zIndexOffset;

    // If in modal then use modal element z-index otherwise determine z-index from direct parents;
    let currentElement = element?.closest(".modal") ?? element;
    let parents = [];

    while (currentElement && parents.length < 30) {
        let z = window.getComputedStyle(currentElement).getPropertyValue("z-index");

        if (isNaN(z)) {
            parents.unshift(currentElement);
            currentElement = currentElement.parentElement;
        } else {
            zIndex = Number(z) + zIndexOffset;
            break;
        }
    }

    return zIndex;
};

export const getDropdownContainer = ({ element }) => {
    let container =
        element?.closest(".dashboard-panels-container") ??
        element?.closest(".sidenav") ??
        element?.closest(".window-body-header") ??
        element?.closest(".header-view-wrapper");

    return container ?? document.body;
};
