import React, { useRef, useCallback, useState, memo, useEffect } from "react";
import { useDispatch } from "react-redux";

import { submitByRef } from "../../../../../../utils/form";
import { getData } from "../../../../../../../store/dataGrid/actions";
import { createResource, updateResource } from "../../../../../../../store/resources/actions";
import { decodeHtml } from "../../../../../../utils/string";
import { mapGridRowToObject } from "../../../../../../utils/datagrid";
import { utilitiesFastTagsGridColumnKeys } from "../../../../../../views/configureGrids";

import JsonSchemaForm from "../../../../../Form/JsonSchema/JsonSchemaForm";
import Button from "../../../../../Button";
import SideNavHeader from "../../../../../SideNav/SideNavHeader";
import SideNavBody from "../../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../../SideNav/SideNavFooter";
import SideNavContent from "../../../../../SideNav/SideNavContent";

import "./FastTagForm.scss";

const FastTagForm = memo(({ utilityNumber, gridId, dataItem, tagForImage, imageTagValue, onClose, sidePanel }) => {
    const dispatch = useDispatch();

    const formRef = useRef();
    const isNew = dataItem == null;

    const [isSubmitting, setIsSubmitting] = useState(false);

    let initialValues = dataItem ? mapGridRowToObject(utilitiesFastTagsGridColumnKeys, dataItem) : tagForImage ? imageTagValue : {};

    if (initialValues && initialValues.tagValue) {
        initialValues.tagValue = decodeHtml(initialValues.tagValue);
    }

    const schema = {
        type: "object",
        required: ["tagName", "tagValue"],
        properties: {
            tagName: {
                type: "string",
                title: "Name",
            },
            tagValue: {
                type: "string",
                title: "Value",
            },
        },
    };

    const uiSchema = {
        tagValue: {
            classNames: "fill-width",
            "ui:widget": "textarea",
        },
    };

    const handleSave = useCallback(() => {
        submitByRef(formRef);
    }, []);

    const onSuccess = useCallback(() => {
        sidePanel.close();

        dispatch(
            getData({
                dataGridId: gridId,
            })
        );
    }, [gridId, sidePanel, dispatch]);

    const handleSubmit = useCallback(
        (formData) => {
            setIsSubmitting(true);

            if (isNew) {
                dispatch(
                    createResource({
                        resourceName: "fasttags",
                        body: {
                            ...formData,
                            entityNumber: utilityNumber,
                            entityType: "utility",
                        },
                        onSuccess,
                        onComplete: () => {
                            setIsSubmitting(false);
                        },
                    })
                );
            } else {
                dispatch(
                    updateResource({
                        resourceName: "fasttags",
                        resourceId: formData.number,
                        path: {
                            fasttagNumber: formData.number,
                        },
                        body: {
                            ...formData,
                        },
                        onSuccess,
                        onComplete: () => {
                            setIsSubmitting(false);
                        },
                    })
                );
            }
        },
        [isNew, utilityNumber, onSuccess, dispatch]
    );

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    return (
        <SideNavContent className="fast-tag-form-sidenav">
            <SideNavHeader
                title={isNew ? "Add FastTag" : "Edit FastTag"}
                leadBlockIcon={isNew ? "plus" : "edit-empty"}
                smallHeader
                onClose={onClose}
            />
            <SideNavBody>
                <JsonSchemaForm
                    formRef={formRef}
                    schema={schema}
                    uiSchema={uiSchema}
                    initialValues={initialValues}
                    isSubmitting={isSubmitting}
                    onSubmit={handleSubmit}
                    noSubmit
                    noReset
                    noClose
                />
            </SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button primary onClick={handleSave} disabled={isSubmitting}>
                    Save
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default FastTagForm;
