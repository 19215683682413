import React, { memo, useCallback, useState } from "react";
import { get } from "lodash";

import { exportDatagridToCSV } from "../../../../../utils/CSV";
import { programsApplicationsGridColumnKeys } from "../../../../../views/configureGrids";
import { openNewApplication } from "../../../../../utils/window";
import { hasAnyOfPermissions, systemUserRights } from "components/utils/user";

import Controls from "../../Controls";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import ApplicationsGrid from "../../../../DataGrid/ApplicationsGrid";

import { useDispatch } from "react-redux";
import { modalOpen } from "store/modal/actions";
import useMultiPageRowSelect from "store/dataGrid/useMultiPageRowSelect";

import ApplicationCustomerTransferForm from "./ApplicationCustomerTransferForm";
import { useUtilityRights } from "store/resources/useResource";
import WaitIcon from "components/ui/WaitIcon";

import "../style.scss";

const ApplicationsPanel = memo(({ panel }) => {
    const customerNumber = get(panel, "data.customerNumber");
    const utilityNumber = get(panel, "data.utilityNumber");
    const dataGridId = `${customerNumber}-applications`;
    const addNewApplicationDisabled = !hasAnyOfPermissions([systemUserRights.VISIONDSM_ADD_APPLICATION]);

    const dispatch = useDispatch();
    const [selectedRows, setSelectedRows] = useState([]);
    const [utilityRights, isLoadingRights] = useUtilityRights({ utilityNumber });

    const canTransferApplications = utilityRights.includes(systemUserRights.VISIONDSM_TRANSFER_APPLICATION);

    const onRowSelect = (rows) => {
        setSelectedRows(rows);
    };

    const [onRowSelectChange, onPageChanged, onRowSelectClear] = useMultiPageRowSelect({
        dataGridId,
        onRowSelect,
    });

    const onSuccessfulTransfer = () => {
        setSelectedRows([]);
    };

    const onTransfer = useCallback(() => {
        dispatch(
            modalOpen({
                modalType: "MODAL",
                modalProps: {
                    title: "Transfer Applications to Customer",
                    modalIcon: "import-export-compare",
                    overlayClassName: "modal-styled",
                    className: "modal-sm",
                    children: (
                        <ApplicationCustomerTransferForm
                            utilityNumber={utilityNumber}
                            applications={selectedRows}
                            customerNumber={customerNumber}
                            onSuccessfulTransfer={onSuccessfulTransfer}
                        />
                    ),
                    noFooter: true,
                },
            })
        );
    }, [selectedRows, dispatch, utilityNumber, customerNumber]);

    const controlItems = [
        {
            position: "right",
            title: "Transfer Application",
            icon: "import-export-compare",
            onClick: () => onTransfer({ selectedRows }),
            disabled: selectedRows < 1,
            hide: !canTransferApplications,
        },
        {
            position: "right",
            title: "New Application",
            icon: "plus",
            onClick: () => openNewApplication({ customerNumber }),
            disabled: addNewApplicationDisabled,
        },
        {
            position: "right",
            title: "Export CSV",
            icon: "custom-export-csv",
            onClick: () =>
                exportDatagridToCSV({
                    dataGridId,
                    fileName: "customer_applications",
                    fileNamePostfix: customerNumber,
                }),
        },
    ];

    if (isLoadingRights) {
        return <WaitIcon />;
    }

    return (
        <div className="panel customer-applications sticky-grid-list-panel">
            <PanelHeaderLarge title={panel.name} />
            <Controls items={controlItems} />
            <ApplicationsGrid
                onRowSelectClear={canTransferApplications && onRowSelectClear}
                onPageChanged={onPageChanged}
                onRowSelectChange={canTransferApplications && onRowSelectChange}
                gridId={dataGridId}
                canSelectRow
                filter={`${programsApplicationsGridColumnKeys.customerNumber}=${customerNumber}`}
            />
        </div>
    );
});

export default ApplicationsPanel;
