import React from "react";
import { store } from "../../../store/configureStore";
import { modalClose, modalOpen } from "../../../store/modal/actions";
import CustomerSearchGrid from "./CustomerSearchGrid";

export const onCustomerLookup = ({ utilityNumber, onSelect, customers, disableClose }) => {
    const close = () => {
        !disableClose && store.dispatch(modalClose());
    };

    store.dispatch(
        modalOpen({
            modalType: "MODAL",
            modalProps: {
                title: "Select Customer",
                overlayClassName: "modal-styled",
                className: "cis-modal modal-with-grid modal-lg",
                modalIcon: "touch-empty",
                children: <CustomerSearchGrid utilityNumber={utilityNumber} onSelect={onSelect} onClose={close} customers={customers} />,
            },
        })
    );
};

export const getCustomerGridId = ({ utilityNumber }) => {
    return `${utilityNumber}-customer-lookup`;
};
