import React, { useContext } from "react";
import { BorderPropertyContext } from "components/ui/PortalBuilder/contexts";
import { BorderCustomPropertyName, BorderSideName, BorderSideProps, BorderSides, BorderWidthSideBySideValue } from "./types";
import { PortalBuilderPropertyProps, PropertyName } from "components/ui/PortalBuilder/types";
import TextLabel from "components/ui/Label/TextLabel";
import { BorderBox } from "./BorderBox";
import { DefaultBorderStyle, emptySideBySideStyleProperties } from ".";
import { PropertyListItem } from "../PropertyListItem";

export const WidthSideBySideProperty = (props: WidthSideBySidePropertyProps) => {
    const { value, onChange } = props;
    const { customPropertiesValue } = useContext(BorderPropertyContext);

    const onSelect = (id: PropertyName, borderSide: SideBySideBorder) => (width: number) => {
        const extraProperties = {
            ...emptySideBySideStyleProperties.map((i) => ({ ...i, value: DefaultBorderStyle })),
        };

        // Deselect the value if same width is selected
        if (width === value[borderSide]) {
            onChange(id, `${0}px`, extraProperties);
        } else {
            onChange(id, `${width}px`, extraProperties);
        }
    };

    if (customPropertiesValue[BorderCustomPropertyName.BorderWidthType] !== BorderSides.SideBySide) {
        return null;
    }

    return (
        <PropertyListItem
            className="property-list-item--border-width property-list-item--border-width-side-by-side flex-column"
            borderTop={false}
            borderBottom={false}
        >
            <BorderSide
                side={BorderSideName.Top}
                selectedValue={value[BorderSideName.Top]}
                onSelect={onSelect(PropertyName.BorderTopWidth, BorderSideName.Top)}
            />
            <BorderSide
                side={BorderSideName.Right}
                selectedValue={value[BorderSideName.Right]}
                onSelect={onSelect(PropertyName.BorderRightWidth, BorderSideName.Right)}
            />
            <BorderSide
                side={BorderSideName.Bottom}
                selectedValue={value[BorderSideName.Bottom]}
                onSelect={onSelect(PropertyName.BorderBottomWidth, BorderSideName.Bottom)}
            />
            <BorderSide
                side={BorderSideName.Left}
                selectedValue={value[BorderSideName.Left]}
                onSelect={onSelect(PropertyName.BorderLeftWidth, BorderSideName.Left)}
            />
        </PropertyListItem>
    );
};

const BorderSide = (props: BorderSideProps) => {
    const { side, selectedValue: selectedWidth, onSelect } = props;

    return (
        <div className="border-side flex-row fill-width align-center">
            <TextLabel>{side}</TextLabel>
            <div className="border-boxes flex-one-in-row flex-row">
                {Array.from(Array(5).keys()).map((index) => (
                    <BorderBox
                        key={index}
                        width={index + 1}
                        side={side}
                        isSelected={selectedWidth === index + 1}
                        onClick={() => onSelect(index + 1)}
                    />
                ))}
            </div>
        </div>
    );
};

interface WidthSideBySidePropertyProps extends Omit<PortalBuilderPropertyProps, "value"> {
    value: BorderWidthSideBySideValue;
}

type SideBySideBorder = Exclude<BorderSideName, BorderSideName.AllSides>;
