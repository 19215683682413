import React, { useCallback, memo, useEffect } from "react";
import { useDispatch } from "react-redux";

import { setExpandedWorkflowTask } from "../../../../../../store/projects/actions";

import AddEquipment from "../../../../Dashboard/Panel/Project/EquipmentPanel/AddEquipment";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";

const AddEquipmentTask = memo(({ applicationNumber, programNumber, task, onSuccess, viewOnly }) => {
    const dispatch = useDispatch();

    const handleClose = useCallback(() => {
        dispatch(setExpandedWorkflowTask(applicationNumber));
    }, [applicationNumber, dispatch]);

    const handleSuccess = useCallback(() => {
        onSuccess();
    }, [onSuccess]);

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({ className: "add-equipment-sidenav-panel" });

    const handleAddEqipmentCancel = useCallback(() => {
        handleCloseSidePanel();
        handleClose();
    }, [handleCloseSidePanel, handleClose]);

    useEffect(() => {
        handleOpenSidePanel(
            <AddEquipment
                inline
                applicationNumber={applicationNumber}
                programNumber={programNumber}
                onClose={handleAddEqipmentCancel}
                onSuccess={() => {
                    handleSuccess();
                    handleCloseSidePanel();
                }}
            />,
            {
                onClose: handleClose,
            }
        );
    }, [applicationNumber, programNumber, handleAddEqipmentCancel, handleCloseSidePanel, handleOpenSidePanel, handleSuccess, handleClose]);

    return null;
});

export default AddEquipmentTask;
