import React from "react";
import cn from "classnames";
import StatusMsg from "../StatusMsg";
import { MessageProps } from "./index";

const InfoMessage = ({ spaceAround, inline, marginTop, children, msgCentered }: MessageProps) => {
    return (
        <StatusMsg
            className={cn("info-message", {
                "warning-message--margin": spaceAround,
                "warning-message--inline": inline,
                "warning-message--margin-top": marginTop,
            })}
            msgVisible
            msgFieldStatus
            msgInfo
            msgText={children}
            msgCentered={msgCentered}
        />
    );
};

export default InfoMessage;
