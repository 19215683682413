import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";

import {
    DEFAULT_SUB_CRITERIA,
    DEFAULT_MAIN_CRITERIA,
    isCisSearchCriteria,
    CIS_KEY_PREFIX,
    hasValuesForSearch,
    getMainCriteriaTitle,
} from "../../../store/globalSearch/utils";
import { setCriteriaValue, clickSearch } from "../../../store/globalSearch/actions";

import Input from "../Input";
import debouncedTextInput from "../../utils/debouncedTextInput";
import Button from "../Button";
import { useSearchOptions } from "store/resources/useResource";

import "./SearchInputSection.scss";

const DebouncedInput = debouncedTextInput(Input);

const SearchInputSection = memo(({ instanceId }) => {
    const dispatch = useDispatch();
    const criteriaValues = useSelector((state) => state.globalSearch.criteriaValues[instanceId]);
    const canSearch = hasValuesForSearch({ instanceId, criteriaValues });
    const [, isLoadingOptions] = useSearchOptions();

    const onClick = useCallback(() => {
        dispatch(clickSearch({ instanceId }));
    }, [instanceId, dispatch]);

    if (isLoadingOptions) {
        return null;
    }

    return (
        <div className="global-search__input flex-row fill-width flex-no-wrap align-end">
            <SearchKeywords instanceId={instanceId} />
            <Button icon="search" disabled={!canSearch} primary square onClick={onClick} />
        </div>
    );
});

const SearchKeywords = memo(({ instanceId }) => {
    const dispatch = useDispatch();

    const criteriaValuesFromStore = useSelector((state) => state.globalSearch.criteriaValues[instanceId]);
    const criteriaValues = useMemo(() => criteriaValuesFromStore ?? {}, [criteriaValuesFromStore]);

    const mainCriteriaFromStore = useSelector((state) => state.globalSearch.selectedMainCriteria[instanceId]);
    const mainCriteria = useMemo(() => mainCriteriaFromStore ?? {}, [mainCriteriaFromStore]);

    const subCriteriaFromStore = useSelector((state) => state.globalSearch.selectedSubCriteria[instanceId]);
    const subCriteria = useMemo(() => subCriteriaFromStore ?? [], [subCriteriaFromStore]);

    const selectedUtility = useSelector((state) => state.globalSearch.selectedUtility[instanceId]);
    const { utilityName } = useMemo(() => selectedUtility ?? {}, [selectedUtility]);

    const selectedProgram = useSelector((state) => state.globalSearch.selectedProgram[instanceId]);
    const { programName } = useMemo(() => selectedProgram ?? {}, [selectedProgram]);

    const keywordClass = "global-search__input-keyword flex-column flex-one fill-height";
    const placeholder = "Type keyword";

    useEffect(() => {
        const timeout = setTimeout(() => {
            const inputFields = document.querySelectorAll(".global-search__input-text");

            if (inputFields.length > 0) {
                const focusIndex = inputFields[0].classList.contains("global-search__input-group") ? 0 : inputFields.length - 1;
                inputFields[focusIndex].focus();
            }
        }, 200);

        return () => {
            clearTimeout(timeout);
        };
    }, [mainCriteria, subCriteria]);

    const onChange = useCallback(
        (criteria) => (event) => {
            dispatch(
                setCriteriaValue({
                    instanceId,
                    criteriaKey: criteria.key,
                    value: event.target.value,
                })
            );
        },
        [instanceId, dispatch]
    );

    const onKeyPress = useCallback(
        (criteria) => (event) => {
            if (event.key === "Enter") {
                dispatch(
                    setCriteriaValue({
                        instanceId,
                        criteriaKey: criteria.key,
                        value: event.target.value,
                    })
                );

                dispatch(clickSearch({ instanceId }));
            }
        },
        [instanceId, dispatch]
    );

    if (isCisSearchCriteria({ criteria: mainCriteria })) {
        return <CisSearchKeywords instanceId={instanceId} onChange={onChange} onKeyPress={onKeyPress} />;
    }

    if (subCriteria.length < 2) {
        const mainCriteriaTitle = getMainCriteriaTitle({
            utilityName,
            programName,
            mainCriteriaTitle: mainCriteria.title,
        });

        const title =
            subCriteria.length === 0 || mainCriteria.key === DEFAULT_MAIN_CRITERIA.key ? (
                <>Keyword to search {mainCriteriaTitle}</>
            ) : (
                <>
                    Keyword to search <strong>{subCriteria[0].title}</strong> in {mainCriteriaTitle}
                </>
            );

        return (
            <div className={keywordClass}>
                <div className="global-search__section-title">{title}</div>
                <DebouncedInput
                    type="text"
                    placeholder={placeholder}
                    className="global-search__input-text"
                    value={criteriaValues[subCriteria[0]?.key] ?? ""}
                    disabled={subCriteria.length === 0}
                    onChange={onChange(subCriteria[0])}
                    onKeyDown={onKeyPress(subCriteria[0])}
                />
            </div>
        );
    }

    return subCriteria.map((item, key) => (
        <div key={key} className={keywordClass}>
            <div className="global-search__section-title">
                <strong>{item.title}</strong> keyword:
            </div>
            <DebouncedInput
                type="text"
                placeholder={placeholder}
                className="global-search__input-text"
                value={criteriaValues[item.key] ?? ""}
                onChange={onChange(item)}
                onKeyDown={onKeyPress(item)}
            />
        </div>
    ));
});

const CisSearchKeywords = memo(({ instanceId, onChange, onKeyPress }) => {
    const subCriteria = useSelector((state) => state.globalSearch.selectedSubCriteria[instanceId]) ?? [];
    const criteriaValues = useSelector((state) => state.globalSearch.criteriaValues[instanceId]) ?? {};

    const keywordClass = "global-search__input-keyword flex-column flex-one fill-height";
    const placeholder = "Type keyword";

    let subCriteriaList = subCriteria.filter((i) => i.key !== CIS_KEY_PREFIX + "utility").filter((i) => i.key !== DEFAULT_SUB_CRITERIA.key);

    const isGroup = subCriteriaList[0]?.isGroup;

    if (isGroup) {
        subCriteriaList = subCriteriaList[0].relatedFields;
    }

    if (subCriteriaList.length < 1) {
        return (
            <div className={keywordClass}>
                <div className="global-search__section-title">Keyword:</div>
                <DebouncedInput
                    type="text"
                    placeholder={placeholder}
                    className="global-search__input-text"
                    disabled={subCriteriaList.length === 0}
                />
            </div>
        );
    }

    return subCriteriaList.map((item, key) => (
        <div key={key} className={keywordClass}>
            <div className={"global-search__section-title " + (item.isRequired ? "required" : undefined)}>
                <strong>{item.title}</strong> keyword:
            </div>
            <DebouncedInput
                type="text"
                placeholder={placeholder}
                className={cn("global-search__input-text", {
                    "global-search__input-group": isGroup,
                })}
                value={criteriaValues[item.key] ?? ""}
                onChange={onChange(item)}
                onKeyDown={onKeyPress(item)}
            />
        </div>
    ));
});

export default SearchInputSection;
