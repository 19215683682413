import React, { memo } from "react";

import IconWithLabel from "../../../../Icons/IconWithLabel";
import AuditTypesSelector from "../../../../AuditTypesSelector";

const Controls = memo(({ type, types, onChange, onClickExportCSV }) => {
    return (
        <div className="grid-controls flex-row align-center">
            <AuditTypesSelector items={types} type={type} onChange={onChange} />
            <span className="flex-one" />
            <IconWithLabel withHandMadeIcon onClick={onClickExportCSV}>
                Export CSV
            </IconWithLabel>
        </div>
    );
});

export default Controls;
