import { isDevEnvironment } from "./constants";

export const log = (...args) => {
    if (window?.__vdsm?.log) {
        console.log(...args);
    }
};

export const logWarning = (...args) => {
    if (isDevEnvironment) {
        console.warn(...args);
    }
};

export const logError = (...args) => {
    if (isDevEnvironment) {
        console.error(...args);
    }
};
