import React from "react";
import { PropertyType } from "../../types";
import { RowsProperty } from "./RowsProperty";
import { SelectPanelProperty } from "./SelectPanelProperty";
import { PropertiesGroup } from "./PropertiesGroup";
import { SelectProperty } from "./SelectProperty";
import { SwitchProperty } from "./SwitchProperty";
import { TextProperty } from "./TextProperty";
import { NumberProperty } from "./NumberProperty";
import { ConfigPanelProperty } from "./ConfigPanelProperty";
import { InputRangeProperty } from "./InputRangeProperty";
import { TextareaProperty } from "./TextareaProperty";
import { AutoCustomProperty } from "./AutoCustomProperty";
import { HtmlProperty } from "./HtmlProperty";
import { BackgroundProperty } from "./BackgroundProperty";
import { IconSelectorProperty } from "./IconSelector";
import { AnalyticsProperty } from "./AnalyticsProperty";
import { GoogleAnalyticsLink } from "../Settings/GoogleAnalyticsLink";
import { GoogleTagLink } from "../Settings/GoogleTagLink";
import { SelectBoxProperty } from "./SelectBoxProperty";
import { NotificationProperty } from "./NotificationProperty";
import { NotificationTypeProperty } from "./NotificationTypeProperty";
import { LeadImageParentProperty } from "./LeadImageParentProperty";
import { BorderProperty } from "./BorderProperty";
import { PaddingProperty } from "./PaddingProperty";
import { MarginProperty } from "./MarginProperty";
import { ShadowProperty } from "./ShadowProperty";
import { BorderRadiusProperty } from "./BorderRadiusProperty";
import { LeadImageContentProperty } from "./LeadImageContentProperty";
import { TileSelectorProperty } from "./TileSelectorProperty";
import { WidgetProperty } from "./WidgetProperty";
import FileSelectProperty from "./FileSelectProperty";

import "./PropertyListItem.scss";
import { LabelSwitchProperty } from "./LabelSwitchProperty";
import { NestedProperties } from "./NestedProperties";
import { StandardPageRowsProperty } from "./StandardPageRowsProperty";
import { AccordionProperty } from "./AccordionProperty";
import { CarouselProperty } from "./CarouselProperty";

export const Property = (props) => {
    const { type } = props;
    let PropertyComponent = null;

    switch (type) {
        case PropertyType.Text:
            PropertyComponent = TextProperty;
            break;
        case PropertyType.Number:
            PropertyComponent = NumberProperty;
            break;
        case PropertyType.Select:
            PropertyComponent = SelectProperty;
            break;
        case PropertyType.IconSelector:
            PropertyComponent = IconSelectorProperty;
            break;
        case PropertyType.SelectPanel:
            PropertyComponent = SelectPanelProperty;
            break;
        case PropertyType.ConfigPanel:
            PropertyComponent = ConfigPanelProperty;
            break;
        case PropertyType.Switch:
            PropertyComponent = SwitchProperty;
            break;
        case PropertyType.LabelSwitch:
            PropertyComponent = LabelSwitchProperty;
            break;
        case PropertyType.InputRange:
            PropertyComponent = InputRangeProperty;
            break;
        case PropertyType.Rows:
            PropertyComponent = RowsProperty;
            break;
        case PropertyType.StandardPageRows:
            PropertyComponent = StandardPageRowsProperty;
            break;
        case PropertyType.Widget:
            PropertyComponent = WidgetProperty;
            break;
        case PropertyType.Background:
            PropertyComponent = BackgroundProperty;
            break;
        case PropertyType.LeadImageContent:
            PropertyComponent = LeadImageContentProperty;
            break;
        case PropertyType.TileSelector:
            PropertyComponent = TileSelectorProperty;
            break;
        case PropertyType.Shadow:
            PropertyComponent = ShadowProperty;
            break;
        case PropertyType.LeadImageParent:
            PropertyComponent = LeadImageParentProperty;
            break;
        case PropertyType.Border:
            PropertyComponent = BorderProperty;
            break;
        case PropertyType.Padding:
            PropertyComponent = PaddingProperty;
            break;
        case PropertyType.Margin:
            PropertyComponent = MarginProperty;
            break;
        case PropertyType.BorderRadius:
            PropertyComponent = BorderRadiusProperty;
            break;
        case PropertyType.GroupAlignment:
        case PropertyType.GroupSizing:
        case PropertyType.GroupSpacing:
        case PropertyType.GroupVisibility:
        case PropertyType.GroupLink:
        case PropertyType.Group:
            PropertyComponent = PropertiesGroup;
            break;
        case PropertyType.Textarea:
            PropertyComponent = TextareaProperty;
            break;
        case PropertyType.AutoCustom:
            PropertyComponent = AutoCustomProperty;
            break;
        case PropertyType.Html:
            PropertyComponent = HtmlProperty;
            break;
        case PropertyType.Analytics:
            PropertyComponent = AnalyticsProperty;
            break;
        case PropertyType.AnalyticsLink:
            PropertyComponent = GoogleAnalyticsLink;
            break;
        case PropertyType.GoogleTagLink:
            PropertyComponent = GoogleTagLink;
            break;
        case PropertyType.SelectBox:
            PropertyComponent = SelectBoxProperty;
            break;
        case PropertyType.Notification:
            PropertyComponent = NotificationProperty;
            break;
        case PropertyType.NotificationType:
            PropertyComponent = NotificationTypeProperty;
            break;
        case PropertyType.FileSelect:
            PropertyComponent = FileSelectProperty;
            break;
        case PropertyType.CustomComponent:
            PropertyComponent = props.component;
            break;
        case PropertyType.NestedProperties:
            PropertyComponent = NestedProperties;
            break;
        case PropertyType.Accordion:
            PropertyComponent = AccordionProperty;
            break;
        case PropertyType.Carousel:
            PropertyComponent = CarouselProperty;
            break;
        case PropertyType.Hidden:
            PropertyComponent = () => null;
            break;
        default:
            PropertyComponent = TextProperty;
            break;
    }

    return <PropertyComponent {...props} />;
};
