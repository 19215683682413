import React, { useCallback, memo, useContext } from "react";

import { catalogGridColumnKeys } from "../configureGrids";
import { openUtilityTab } from "../../utils/window";
import { openProgramTab } from "store/window/openTabActions";
import { isYes } from "../../utils/string";
import useSidePanelHandlers from "../../utils/useSidePanelHandlers";
import { openCalculationAssignmentModal, onCatalogItemsGridRefresh } from "../../ui/Dashboard/Panel/Program/CatalogPanel/utils";
import { sideNavKey } from "components/ui/SideNav/SideNavRoot";

import { WindowContext } from "../../ui/Windows/Window";

import ActionLabel from "../../ui/Label/ActionLabel";
import DataGrid from "../../ui/DataGrid";
import withDataGrid from "../../ui/DataGrid/withDataGrid";
import Form from "../../ui/Dashboard/Panel/Program/CatalogPanel/Form";
import { useDispatch } from "react-redux";

const Grid = memo(({ gridId, dataGridConfig }) => {
    const dispatch = useDispatch();

    const rowActions = [
        {
            name: "edit",
            icon: "edit-empty",
            title: "Edit",
        },
        {
            name: "calc-assignment",
            icon: (row) => (isYes(row[catalogGridColumnKeys.calculations]) ? "hub-filled" : "hub-empty"),
            title: "Edit Catalog Calculation Assignment",
        },
    ];

    const { isSplitView } = useContext(WindowContext);
    const key = isSplitView ? undefined : sideNavKey.globalRight;

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        sideNavKey: key,
    });

    const handleOpenForm = useCallback(
        ({ dataItem }) => {
            const utilityNumber = dataItem[catalogGridColumnKeys.utilityNumber];
            const programNumber = dataItem[catalogGridColumnKeys.programNumber];
            const catalogNumber = dataItem[catalogGridColumnKeys.catalogNumber];
            const catalogId = dataItem[catalogGridColumnKeys.catalogId];

            handleOpenSidePanel(
                <Form
                    utilityNumber={utilityNumber}
                    programNumber={programNumber}
                    catalogNumber={catalogNumber}
                    catalogID={catalogId}
                    gridRefresh={() => onCatalogItemsGridRefresh({ programNumber })}
                    onClose={handleCloseSidePanel}
                />,
                {
                    className: "catalog-sidenav-panel",
                }
            );
        },
        [handleOpenSidePanel, handleCloseSidePanel]
    );

    const handleRowAction = useCallback(
        (action) => {
            const { name, dataItem } = action;

            switch (name) {
                case "edit":
                    handleOpenForm({ dataItem });
                    break;

                case "calc-assignment":
                    openCalculationAssignmentModal({
                        utilityNumber: dataItem[catalogGridColumnKeys.utilityNumber],
                        programNumber: dataItem[catalogGridColumnKeys.programNumber],
                        catalogNumber: dataItem[catalogGridColumnKeys.catalogNumber],
                        hasCalcAssignment: isYes(dataItem[catalogGridColumnKeys.calculations]),
                    });
                    break;

                default:
                    break;
            }
        },
        [handleOpenForm]
    );

    const handleUtilityOpen = useCallback((utilityNumber) => {
        openUtilityTab({ utilityNumber });
    }, []);

    const handleProgramOpen = useCallback(
        (programNumber) => {
            dispatch(openProgramTab({ programNumber }));
        },
        [dispatch]
    );

    return (
        <DataGrid
            name={gridId}
            config={dataGridConfig}
            customRowActions={rowActions}
            onRowAction={handleRowAction}
            columnCellContent={{
                [catalogGridColumnKeys.utilityNumber]: (column, row) => {
                    return <ActionLabel onClick={() => handleUtilityOpen(row[column.key])}>{row[column.key]}</ActionLabel>;
                },
                [catalogGridColumnKeys.utilityName]: (column, row) => {
                    return (
                        <ActionLabel onClick={() => handleUtilityOpen(row[catalogGridColumnKeys.utilityNumber])}>
                            {row[column.key]}
                        </ActionLabel>
                    );
                },
                [catalogGridColumnKeys.programNumber]: (column, row) => {
                    return <ActionLabel onClick={() => handleProgramOpen(row[column.key])}>{row[column.key]}</ActionLabel>;
                },
                [catalogGridColumnKeys.programName]: (column, row) => {
                    return (
                        <ActionLabel onClick={() => handleProgramOpen(row[catalogGridColumnKeys.programNumber])}>
                            {row[column.key]}
                        </ActionLabel>
                    );
                },
                [catalogGridColumnKeys.name]: (column, row) => {
                    return <ActionLabel onClick={() => handleOpenForm({ dataItem: row })}>{row[column.key]}</ActionLabel>;
                },
            }}
        />
    );
});

export default withDataGrid("catalog", Grid);
