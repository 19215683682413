import React from "react";
import cn from "classnames";
import { connect } from "react-redux";
import ReactModal from "react-modal";
import IconWrap from "../Icons";

import "./style.scss";
import { modalClose } from "../../../store/modal/actions";
import Button from "../Button";

export const SimpleDialog = ({
    text,
    title,
    modalIcon,
    buttonText,
    noOverlayIcon,
    dispatch,
    overlayClassName,
    errorModal,
    textAlignLeft,
    onClose = () => {},
}) => (
    <ReactModal
        isOpen
        overlayClassName={
            noOverlayIcon ? cn("", overlayClassName) : cn("modal-styled modal-simple", { "modal-error": errorModal }, overlayClassName)
        }
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => dispatch(modalClose())}
        className="modal modal-sm"
    >
        <div className="modal-content">
            {noOverlayIcon ? "" : <IconWrap icon={errorModal ? "report-empty" : "info-empty"} />}
            {title ? (
                <div className={cn("modal-header")}>
                    {modalIcon && <IconWrap icon={modalIcon} />}
                    {title}
                    <IconWrap
                        icon="clear-close"
                        title="Close"
                        onClick={() => {
                            dispatch(modalClose());
                            onClose();
                        }}
                    />
                </div>
            ) : (
                <div className="modal-header">
                    <IconWrap
                        icon="clear-close"
                        title="Close"
                        onClick={() => {
                            dispatch(modalClose());
                            onClose();
                        }}
                    />
                </div>
            )}
            {text && (
                <div className={"modal-body" + (textAlignLeft ? " left-aligned-text" : "")}>
                    <div className="flex-column">{text}</div>
                </div>
            )}
            {buttonText && (
                <div className={"modal-footer no-shrink center"}>
                    <Button
                        primary
                        className="modal-close"
                        onClick={() => {
                            dispatch(modalClose());
                            onClose();
                        }}
                    >
                        {buttonText ?? "Close"}
                    </Button>
                </div>
            )}
        </div>
    </ReactModal>
);

export default connect()(SimpleDialog);
