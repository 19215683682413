import React from "react";
import SelectWidget from "./SelectWidget";
import { useResource } from "store/resources/useResource";

const ContractorLookupListWidget = (props) => {
    const { applicationNumber } = props.formContext ?? {};

    const [list = [], isLoading] = useResource({
        resourceName: "applicationContractors",
        key: applicationNumber,
        path: {
            applicationNumber,
        },
    });

    const options = {
        ...(props.options || {}),
        enumOptions: list.map((item) => ({
            label: item.display,
            value: item.value,
        })),
    };

    const placeholderValue = isLoading ? "Loading..." : props.placeholder || options.placeholder || "-- SELECT --";

    return <SelectWidget {...props} options={options} placeholder={placeholderValue} />;
};

export default ContractorLookupListWidget;
