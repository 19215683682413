import React, { useCallback, memo } from "react";

import withDataGrid from "../../../../DataGrid/withDataGrid";
import { programsCalculationsGridColumnKeys } from "../../../../../views/configureGrids";
import { mapGridRowToObject } from "../../../../../utils/datagrid";
import { programCalculationsGridCustomRowActions, calculationTypes } from "../utils";

import DataGrid from "../../../../DataGrid";
import ActionLabel from "../../../../Label/ActionLabel";
import UpdateCalculationPanel from "../UpdateCalculationPanel";
import CalculationDetailsForm from "../CalculationDetailsForm";
import CalculationRevisionsPanel from "../CalculationRevisionsPanel";
import { isChildProgram } from "components/views/ProgramView/utils";

const Grid = memo(({ gridId, dataGridConfig, utilityNumber, programNumber, onOpenPanel, onClosePanel }) => {
    const disabled = isChildProgram({ programNumber });

    const onRowAction = useCallback(
        (action, onExpand) => {
            const { name, dataItem } = action;

            const rowData = {
                ...mapGridRowToObject(programsCalculationsGridColumnKeys, dataItem),
                calculationType: calculationTypes.program,
            };

            switch (name) {
                case "details":
                    onOpenPanel(
                        <CalculationDetailsForm
                            disabled={disabled}
                            utilityNumber={utilityNumber}
                            programNumber={programNumber}
                            rowData={rowData}
                            onClose={onClosePanel}
                        />
                    );
                    break;

                case "update":
                    onOpenPanel(
                        <UpdateCalculationPanel
                            disabled={disabled}
                            utilityNumber={utilityNumber}
                            programNumber={programNumber}
                            rowData={rowData}
                            onClose={onClosePanel}
                        />
                    );
                    break;

                case "revisions":
                    onOpenPanel(
                        <CalculationRevisionsPanel
                            disabled={disabled}
                            utilityNumber={utilityNumber}
                            programNumber={programNumber}
                            rowData={rowData}
                            onClose={onClosePanel}
                        />
                    );
                    break;

                default:
                    break;
            }
        },
        [utilityNumber, programNumber, onOpenPanel, onClosePanel, disabled]
    );

    if (disabled) {
        programCalculationsGridCustomRowActions[0].title = "View Calculation";
        programCalculationsGridCustomRowActions[0].icon = "eye-visibility-empty";
    }

    return (
        <DataGrid
            name={gridId}
            config={dataGridConfig}
            onRowAction={onRowAction}
            customRowActions={programCalculationsGridCustomRowActions}
            columnCellContent={{
                [programsCalculationsGridColumnKeys.calculationName]: (column, row, onExpandRow, onRowAction) => {
                    return (
                        <ActionLabel
                            onClick={() =>
                                onRowAction({
                                    name: "details",
                                    dataItem: row,
                                })
                            }
                        >
                            {row[column.key]}
                        </ActionLabel>
                    );
                },
            }}
        />
    );
});

export default withDataGrid("programsCalculations", Grid);
