import React from "react";
import ClassNames from "classnames";

import "./style.scss";

const StatusTag = (props) => {
    const className = ClassNames("status-tag", props.statusTagIcon, props.className, {
        "status-error circle-exclamation-filled": props.statusTagError,
        "status-success check-circle-filled": props.statusTagSuccess,
        "with-icon": props.statusTagIcon,
    });

    return <div className={className}>{props.children}</div>;
};

export default StatusTag;
