import { get, isString } from "lodash";
import { ElementTypes, getParentSchemasById, formIdToSchemaId } from "../../components/utils/form";
import { initialState } from "./reducer";
import { store } from "../configureStore";
import { toArray } from "../../components/utils/array";

export const getPageCount = (schema) => {
    let result = 0;
    if (schema && Object.keys(schema).length) {
        result = 1;
    }

    return result;
};

export const getSectionCount = (schema) => {
    return getPropertyCount(schema);
};

export const getSectionList = (schema) => {
    return getPropertyList(schema);
};

export const getSectionChildCount = (state) => {
    const schema = getActiveSection(state);
    return getPropertyCount(schema);
};

export const getFieldCount = (state) => {
    const activeColumn = getActiveColumn(state);
    const activeRow = getActiveRow(state);

    return activeColumn ? getPropertyCount(activeColumn) : getPropertyCount(activeRow);
};

export const getPropertyCount = (schema) => {
    let result = 0;
    if (schema && schema.properties && Object.keys(schema.properties).length) {
        result = Object.keys(schema.properties).length;
    }

    return result;
};

export const getPropertyList = (schema) => {
    let result = [];
    if (schema && schema.properties && Object.keys(schema.properties).length) {
        result = Object.keys(schema.properties);
    }

    return result;
};

export const getActiveSectionId = (state) => {
    let result = null;

    if (state.selectedElementId) {
        let parts = state.selectedElementId.split("_");

        while (parts.length > 1) {
            const elementId = parts.join("_");
            const elementSchemas = getElementSchemasById({
                elementId,
                schema: state.schema,
                uiSchema: state.uiSchema,
            });

            if (elementSchemas.uiSchema && elementSchemas.uiSchema["ui:elementType"] === ElementTypes.SECTION) {
                result = parts.pop();
                break;
            }

            parts.pop();
        }
    }

    return result;
};

export const getActiveColumnId = (state) => {
    let result = null;

    if (state.selectedElementId) {
        const parts = state.selectedElementId.split("_");

        while (parts.length > 1) {
            const elementId = parts.join("_");
            const elementSchemas = getElementSchemasById({
                elementId,
                schema: state.schema,
                uiSchema: state.uiSchema,
            });

            if (elementSchemas.uiSchema && elementSchemas.uiSchema["ui:elementType"] === ElementTypes.COLUMN) {
                result = parts.pop();
                break;
            }

            parts.pop();
        }
    }

    return result;
};

export const getActiveRowId = (state) => {
    let result = null;

    if (state.selectedElementId) {
        const parts = state.selectedElementId.split("_");

        while (parts.length > 1) {
            const elementId = parts.join("_");
            const elementSchemas = getElementSchemasById({
                elementId,
                schema: state.schema,
                uiSchema: state.uiSchema,
            });

            if (elementSchemas.uiSchema && elementSchemas.uiSchema["ui:elementType"] === ElementTypes.ROW) {
                result = parts.pop();
                break;
            }

            parts.pop();
        }
    }

    return result;
};

export const getActiveFieldId = (state) => {
    let result = null;

    if (state.selectedElementId) {
        const elementSchemas = getElementSchemasById({
            elementId: state.selectedElementId,
            schema: state.schema,
            uiSchema: state.uiSchema,
        });

        if (elementSchemas.uiSchema && elementSchemas.uiSchema["ui:elementType"] === ElementTypes.FIELD) {
            result = state.selectedElementId.split("_").pop();
        }
    }

    return result;
};

export const getActiveSection = (state) => {
    let result = null;
    if (state.schema.properties) {
        result = state.schema.properties[getActiveSectionId(state)];
    }

    return result;
};

export const getActiveColumn = (state) => {
    let result = null;
    const section = getActiveSection(state);
    if (section) {
        result = section.properties[getActiveColumnId(state)];
    }

    return result;
};

export const getActiveRow = (state) => {
    let result = null;
    const section = getActiveSection(state);
    if (section) {
        result = section.properties[getActiveRowId(state)];
    }

    return result;
};

export const getActiveField = (state) => {
    let result = null;
    const column = getActiveColumn(state);
    if (column) {
        result = column.properties[getActiveFieldId(state)];
    }

    return result;
};

export const getActiveUiField = (state) => {
    let result = null;
    if (state.selectedElementId) {
        const path = formIdToSchemaId(state.selectedElementId);
        result = path.length > 0 ? get(state.uiSchema, path) : state.uiSchema;
    }

    return result;
};

export const getElementSchemasById = ({ elementId, schema, uiSchema }) => {
    let result = {
        schema: null,
        uiSchema: null,
    };

    if (elementId) {
        const pathParts = elementId.split("_").slice(1);

        if (pathParts.length === 0) {
            result.schema = {
                ...schema,
            };

            result.uiSchema = {
                ...uiSchema,
            };
        } else {
            const path = `properties.${pathParts.join(".properties.")}`;
            const uiPath = pathParts.join(".");

            result.schema = {
                ...get(schema, path),
            };

            result.uiSchema = {
                ...get(uiSchema, uiPath),
            };
        }
    }

    return result;
};

export const getSelectedElement = (state) => {
    let element = {};
    if (state.selectedElementId) {
        const elementSchemaId = formIdToSchemaId(state.selectedElementId);
        if (elementSchemaId !== "") {
            const path = `properties.${elementSchemaId.split(".").join(".properties.")}`;
            element = get(state.schema, path, {});
        } else {
            element = state.schema;
        }
    }

    return element;
};

export const getSelectedElementType = (state) => {
    let elementType = ElementTypes.UNKNOWN;
    if (state.selectedElementId) {
        const uiField = getActiveUiField(state) || {};
        elementType = uiField["ui:elementType"] ?? ElementTypes.UNKNOWN;
    }

    return elementType;
};

export const getSelectedElementNumber = (state) => {
    if (state.selectedElementId) {
        const uiSchema = getActiveUiField(state) || {};
        return getElementNumber({ uiSchema });
    }

    return null;
};

export const getActiveSectionNumber = (state) => {
    return getElementNumber(state.uiSchema[getActiveSectionId(state)]);
};

export const getActiveColumnNumber = (state) => {
    return getElementNumber(state.uiSchema[getActiveSectionId(state)][getActiveColumnId(state)]);
};

export const getSelectedElementUiParams = (state) => {
    let uiParams = {};
    if (state.selectedElementId) {
        const uiField = getActiveUiField(state) || {};
        if (uiField) {
            uiParams = {
                ...uiField,
            };
        }

        let schema = state.schema;
        let uiSchema = state.uiSchema;

        const elementId = formIdToSchemaId(state.selectedElementId);
        const parentSchemas = getParentSchemasById({
            elementId,
            schema,
            uiSchema,
        });
        const uiParent = parentSchemas.uiSchema;

        if (uiParent) {
            uiParams["order"] = uiParent["ui:order"] || [];
        }

        const parentPath = `properties.${state.selectedElementId.split("_").slice(1).slice(0, -1).join(".properties.")}`;
        const parent = get(state.schema, parentPath, {});
        uiParams["required"] = (parent["required"] || []).slice();
    }

    // Reset legacy widget types to readonlyvalue
    if (["rawhtml"].includes(uiParams["ui:widget"])) {
        uiParams["ui:widget"] = "readonlyvalue";
    }

    if (!uiParams["order"]) {
        uiParams["order"] = [];
    }

    if (!uiParams["required"]) {
        uiParams["required"] = [];
    }

    return uiParams;
};

export const isPage = (element) => {
    return element.uiSchema && isString(element.uiSchema["ui:elementType"]) && element.uiSchema["ui:elementType"] === ElementTypes.PAGE;
};

export const isSection = (element) => {
    return element.uiSchema && isString(element.uiSchema["ui:elementType"]) && element.uiSchema["ui:elementType"] === ElementTypes.SECTION;
};

export const isColumn = (element) => {
    return element.uiSchema && isString(element.uiSchema["ui:elementType"]) && element.uiSchema["ui:elementType"] === ElementTypes.COLUMN;
};

export const isField = (element) => {
    return element.uiSchema && isString(element.uiSchema["ui:elementType"]) && element.uiSchema["ui:elementType"] === ElementTypes.FIELD;
};

export const isElementFixedWidth = (elementUiSchema) => {
    let result = true;

    if (elementUiSchema && elementUiSchema["classNames"]) {
        result = elementUiSchema["classNames"].split(" ").filter((c) => c === "fill-width").length === 0;
    }

    return result;
};

export const getElementType = (element) => {
    if (element.uiSchema && isString(element.uiSchema["ui:elementType"])) {
        return element.uiSchema["ui:elementType"];
    }

    return ElementTypes.UNKNOWN;
};

export const getElementUiSchema = ({ uiSchema, elementSchemaId }) => {
    return get(uiSchema, elementSchemaId);
};

export const getElementNumber = (element) => {
    if (element.uiSchema) {
        return (
            element.uiSchema["af:fieldNumber"] ??
            element.uiSchema["af:columnNumber"] ??
            element.uiSchema["af:sectionNumber"] ??
            element.uiSchema["af:pageNumber"]
        );
    }

    return null;
};

export const isPropertiesValid = (errorSchema) => {
    if (!errorSchema) {
        return true;
    }

    const getErrorLists = (obj) => Object.keys(obj || {}).map((key) => toArray(obj[key]["__errors"]));

    const errorLists = [...getErrorLists(errorSchema), ...getErrorLists(errorSchema?.validation)];

    return errorLists.filter((errors) => errors.length > 0).length === 0;
};

export const getFormBuilder = ({ instanceId, state, defaultValue = initialState }) => {
    const storeState = state || store.getState().formBuilder;

    return storeState[instanceId] || defaultValue;
};

export const getFormPage = ({ pageNumber }) => {
    const state = store.getState();
    return get(state, `resources.programFormPages.itemsById[${pageNumber}]`);
};

export const isAddColumnDisabled = (formBuilder) => getSectionChildCount(formBuilder) > 1;
