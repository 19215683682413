import React, { useCallback, memo } from "react";

import { mapGridRowToObject, SortType } from "../../utils/datagrid";
import { getContractorsGridId } from "./actions";
import { availableGrids } from "../../views/configureGrids";
import { useDataGrid } from "components/utils/useDataGrid";
import { Localize } from "components/utils/text";

import DataGrid from "../DataGrid";
import WaitIcon from "../WaitIcon";
import Form from "./Form";

import "./style.scss";

const contractorsGridColumnKeys = {
    contactNumber: "c_6935977f-5c71-41be-8b0d-060ccae75aeb",
    firstName: "c_414b2c78-a6c0-42a5-9062-c6a3e38fe484",
    lastName: "c_9ec7ed6d-78ab-4608-bef0-0afac879927f",
    company: "c_74851a16-b0c3-4c1b-9177-300b8d7e9d8d",
    address: "c_236498ee-f16d-409e-b37e-26e43730344d",
    addressCont: "c_22438882-e074-4bce-b864-92e23390652a",
    city: "c_20efeefa-1784-478c-b0f0-6e1e7b6123c0",
    state: "c_969e7eac-5b2d-4160-bc8b-8a5ec72fb2d6",
    zip: "c_0d5b8c2c-3bb1-4806-a8a8-35281e806fdd",
    phone: "c_f73ca8dd-e937-4f53-a772-295671d29874",
    email: "c_d4eb49ee-704c-4973-bb0e-a9338c0ddb49",
    technologies: "c_3d3f85da-fe64-45f7-aefd-72f5729edb61",
    services: "c_08b929cc-7f0f-4727-beca-730830adb0dd",
    contactTitle: "c_42106499-a37b-4009-9e2f-a6aa43878013",
    origId: "41D1B0DE-C1F4-42D7-91F4-6D0FDB3FBE53",
};

const rowActions = [
    {
        name: "view",
        icon: "eye-visibility-empty",
        title: "View Contractor Information",
    },
    {
        name: "select",
        icon: "plus",
        title: "Select This Contractor",
    },
];

const Grid = memo(({ programNumber, applicationNumber, onSelect, onClose }) => {
    const dataGridId = getContractorsGridId({
        programNumber,
        applicationNumber,
    });

    const [gridConfig, isLoadingConfig] = useDataGrid({
        dataGridId: availableGrids.contractors,
        dataGridInstanceId: dataGridId,
        serverSidePaging: true,
        isServerSideFiltering: true,
        sorting: SortType.SERVER,
        getDataAction: {
            type: "contractorsGridGetDataAction",
            props: {
                columnKeysMap: contractorsGridColumnKeys,
                programNumber,
                applicationNumber,
            },
        },
    });

    const onRowAction = useCallback(
        (action, onExpand) => {
            const { name, dataItem } = action;

            const data = dataItem[contractorsGridColumnKeys.contactNumber]
                ? mapGridRowToObject(contractorsGridColumnKeys, dataItem)
                : dataItem;

            switch (name) {
                case "view":
                    onExpand((props) => <Form {...props} dataItem={data} actions={rowActions.slice(1)} onRowAction={onRowAction} />);
                    break;

                case "select":
                    onSelect(data);
                    onClose();
                    break;

                default:
                    break;
            }
        },
        [onSelect, onClose]
    );

    if (isLoadingConfig) {
        return <WaitIcon />;
    }

    return (
        <DataGrid
            name={dataGridId}
            config={gridConfig}
            onRowAction={onRowAction}
            customRowActions={rowActions}
            columnHeaderContent={{
                [contractorsGridColumnKeys.state]: (column) => {
                    return Localize.STATE;
                },
            }}
        />
    );
});

export default Grid;
