import React, { useContext } from "react";
import { BorderPropertyContext } from "components/ui/PortalBuilder/contexts";
import { BorderColorSideBySideValue, BorderCustomPropertyName, BorderSideName, BorderSides } from "./types";
import { PortalBuilderPropertyProps, PropertyName } from "components/ui/PortalBuilder/types";
import { SelectPanelProperty } from "../SelectPanelProperty";
import { ColorSelectIcon } from "../ColorSelect/ColorSelectIcon";
import ColorSelectPanel from "../ColorSelect/ColorSelectPanel";
import { PropertyListItem } from "../PropertyListItem";

export const ColorSideBySideProperty = (props: ColorSideBySidePropertyProps) => {
    const { value } = props;
    const { customPropertiesValue } = useContext(BorderPropertyContext);

    if (customPropertiesValue[BorderCustomPropertyName.BorderColorType] !== BorderSides.SideBySide) {
        return null;
    }

    return (
        <PropertyListItem className="property-list-item--border-color flex-column" borderBottom={false}>
            <SelectPanelProperty
                {...props}
                id={PropertyName.BorderTopColor}
                value={value[BorderSideName.Top]}
                title={BorderSideName.Top}
                borderTop={true}
                selectComponent={ColorSelectIcon}
                panelComponent={ColorSelectPanel}
                selectionTitle={"Border"}
            />
            <SelectPanelProperty
                {...props}
                id={PropertyName.BorderRightColor}
                value={value[BorderSideName.Right]}
                title={BorderSideName.Right}
                selectComponent={ColorSelectIcon}
                panelComponent={ColorSelectPanel}
                selectionTitle={"Border"}
            />
            <SelectPanelProperty
                {...props}
                id={PropertyName.BorderBottomColor}
                value={value[BorderSideName.Bottom]}
                title={BorderSideName.Bottom}
                selectComponent={ColorSelectIcon}
                panelComponent={ColorSelectPanel}
                selectionTitle={"Border"}
            />
            <SelectPanelProperty
                {...props}
                id={PropertyName.BorderLeftColor}
                value={value[BorderSideName.Left]}
                title={BorderSideName.Left}
                borderBottom={false}
                selectComponent={ColorSelectIcon}
                panelComponent={ColorSelectPanel}
                selectionTitle={"Border"}
            />
        </PropertyListItem>
    );
};

interface ColorSideBySidePropertyProps extends Omit<PortalBuilderPropertyProps, "value"> {
    value: BorderColorSideBySideValue;
}
