import { ToolbarTypes } from "./../Form/JsonSchema/widgets/HtmlEditorWidget/toolbars";
import { PortalBuilderSection } from "./Properties/Section";

export enum WidgetType {
    BUTTON = "Button",
    HEADER_NAVIGATION_WIDGET = "HeaderNavigationWidget",
    HEADING = "Heading",
    IMAGE = "Image",
    NAVIGATION_WIDGET = "NavigationWidget",
    PARAGRAPH = "Paragraph",
    TEXT_WIDGET = "TextWidget",
    SECTION_PLACEHOLDER = "SectionPlaceholder",
    ROW = "Row",
    COL = "Col",
    HTML = "Html",
    LEAD_IMAGE = "LeadImage",
    CONTENT_WRAPPER = "ContentWrapper",
    INPUT_FIELD = "InputField",
    LINK = "LinkWidget",
    LIST = "List",
    FIELD_GROUP = "FieldGroup",
    ICON_AND_TEXT = "IconAndText",
    IMAGE_AND_LINK = "ImageAndLink",
    IMAGE_AND_TEXT = "ImageAndText",
    SOCIAL_LINKS = "SocialMedia",
    TITLE_AND_BUTTON = "TextWithButton",
    CARD_CONTENT = "CardContent",
    ACCORDION = "AccordionWidget",
    ACCORDION_ITEM = "AccordionItem",
    POWER_BI_PUBLIC_REPORT = "PowerBiPublicReport",
    CAROUSEL_ITEM = "CarouselItem",
    CAROUSEL = "CarouselWidget",
}

export enum DeviceTypes {
    DESKTOP = "DESKTOP",
    TABLET = "TABLET",
    MOBILE = "MOBILE",
}

export enum PageAccessType {
    PUBLIC = "PUBLIC",
    AUTHENTICATED = "AUTHENTICATED",
}

export enum HeaderNavigation {
    ALWAYS = "ALWAYS",
    ANONYMOUS = "ANONYMOUS",
    AUTHENTICATED = "AUTHENTICATED",
    NEVER = "NEVER",
}

export enum LinkType {
    INTERNAL = "internal",
    EXTERNAL = "external",
}

export enum LayoutType {
    Layout1 = "layout1",
    Layout2 = "layout2",
    Layout3 = "layout3",
    Layout4 = "layout4",
    Layout5 = "layout5",
    Layout6 = "layout6",
    Layout7 = "layout7",
    Layout8 = "layout8",
    Layout9 = "layout9",
    Layout10 = "layout10",
    Layout11 = "layout11",
}

export enum AlignYPosition {
    Top = "top",
    Center = "center",
    Bottom = "bottom",
}

export enum AlignXPosition {
    Left = "left",
    Center = "center",
    Right = "right",
}

export enum TextColor {
    Light = "light",
    Dark = "dark",
}

export enum PropertyType {
    Text = "text",
    Number = "number",
    Select = "select",
    IconSelector = "icon-selector",
    Switch = "switch",
    LabelSwitch = "label-switch",
    Textarea = "textarea",
    Html = "html",
    SelectPanel = "select-panel",
    ConfigPanel = "config-panel",
    InputRange = "input-range",
    Rows = "rows",
    Widgets = "widgets",
    Widget = "widget",
    GroupAlignment = "group-alignment",
    Background = "background",
    LeadImageContent = "lead-image-content",
    TileSelector = "tile-selector",
    Border = "border",
    Padding = "padding",
    Margin = "margin",
    BorderRadius = "border-radius",
    Group = "group",
    GroupSizing = "group-sizing",
    Shadow = "shadow",
    GroupSpacing = "group-spacing",
    Heading = "heading",
    StandardPageRows = "standard-page-rows",

    GroupVisibility = "group-visibility",
    GroupLink = "group-link",
    AutoCustom = "auto-custom",
    Analytics = "analytics",
    AnalyticsLink = "analytics-link",
    GoogleTagLink = "google-tag-link",
    SelectBox = "select-box",
    Notification = "notification",
    NotificationType = "notification-type",
    LeadImageParent = "lead-image-parent",
    FileSelect = "file-select",
    CustomComponent = "custom-component",
    NestedProperties = "nested-properties",
    Accordion = "accordion",
    Carousel = "carousel",
    Hidden = "hidden",
}

export enum PortalTemplateConfigurationSectionKey {
    HEADER = "header",
    FOOTER = "footer",
    COLORS = "colors",
    SETTINGS = "settings",
    TEMPLATE_INFO = "templateInfo",
    CONTENT = "content",
    WIDGETS = "widgets",
}

export enum PropertyName {
    BorderStyle = "borderStyle",
    BorderWidth = "borderWidth",
    BorderColor = "borderColor",
    BorderColorOpacity = "borderColorOpacity",

    BorderTopStyle = "borderTopStyle",
    BorderRightStyle = "borderRightStyle",
    BorderBottomStyle = "borderBottomStyle",
    BorderLeftStyle = "borderLeftStyle",

    BorderTopWidth = "borderTopWidth",
    BorderRightWidth = "borderRightWidth",
    BorderBottomWidth = "borderBottomWidth",
    BorderLeftWidth = "borderLeftWidth",

    BorderTopColor = "borderTopColor",
    BorderRightColor = "borderRightColor",
    BorderBottomColor = "borderBottomColor",
    BorderLeftColor = "borderLeftColor",

    Padding = "padding",

    PaddingTop = "paddingTop",
    PaddingRight = "paddingRight",
    PaddingBottom = "paddingBottom",
    PaddingLeft = "paddingLeft",

    Margin = "margin",

    MarginTop = "marginTop",
    MarginRight = "marginRight",
    MarginBottom = "marginBottom",
    MarginLeft = "marginLeft",

    MaxWidth = "maxWidth",

    BorderRadius = "borderRadius",
    BorderTopLeftRadius = "borderTopLeftRadius",
    BorderTopRightRadius = "borderTopRightRadius",
    BorderBottomRightRadius = "borderBottomRightRadius",
    BorderBottomLeftRadius = "borderBottomLeftRadius",

    HeadingH1Color = "headingH1Color",
    HeadingH2Color = "headingH2Color",
    HeadingH3Color = "headingH3Color",
    HeadingH4Color = "headingH4Color",
    HeadingH5Color = "headingH5Color",
    HeadingH6Color = "headingH6Color",

    Link = "link",
    Order = "order",
    ShowOnDesktop = "showOnDesktop",
    ShowOnTablet = "showOnTablet",
    ShowOnMobile = "showOnMobile",

    HoverTextColor = "hoverTextColor",
    TextColor = "textColor",
    BackgroundColor = "backgroundColor",
    HeadingColor = "headingColor",
    FontSize = "fontSize",
    FontFamily = "fontFamily",
    LineHeight = "lineHeight",
    ShadowType = "shadowType",
    ShadowSize = "shadowSize",
    ShadowColor = "shadowColor",
    ShadowColorOpacity = "shadowColorOpacity",

    Separators = "separators",
    SameWidths = "sameWidths",
    Underline = "underline",
    Version = "version",
}

export enum TypographyFontSize {
    Small = "0.875rem",
    Normal = "1rem",
    Large = "1.25rem",
}

export enum LayoutWidth {
    FullScreen = "100%",
    XXL = "1320px",
}

export enum TypographyLineHeight {
    Small = "1.25",
    Normal = "1.5",
    Large = "2",
}

export enum ShadowBoxSize {
    XS = "0 0 0.25rem",
    SM = "0 0 0.5rem",
    MD = "0 0 1rem",
    LG = "0 0 2rem",
    XL = "0 0 3rem",
}

export enum PaddingBoxSize {
    XS = "0.25rem",
    SM = "0.5rem",
    MD = "1rem",
    LG = "1.5rem",
    XL = "2rem",
    XXL = "3rem",
}

export enum MarginBoxSize {
    XS = "0.25rem",
    SM = "0.5rem",
    MD = "1rem",
    LG = "1.5rem",
    XL = "2rem",
    XXL = "3rem",
}

export enum ColorType {
    LinearGradient = "linearGradient",
    Solid = "solid",
}

export enum PropertySize {
    XS = "xs",
    SM = "sm",
    MD = "md",
    LG = "lg",
    XL = "xl",
    XXL = "xxl",
}

export interface PaddingProperties {
    padding?: string;
    paddingTop?: string;
    paddingRight?: string;
    paddingBottom?: string;
    paddingLeft?: string;
}

export interface MarginProperties {
    margin?: string;
    marginTop?: string;
    marginRight?: string;
    marginBottom?: string;
    marginLeft?: string;
}

export interface BackgroundProperties {
    backgroundOff?: boolean;
    backgroundWidth?: string;
    colorType?: ColorType;

    // Solid color props
    backgroundColor?: string;
    backgroundColorOpacity?: string;

    // Gradient color props
    firstGradientValue?: number;
    firstGradientColor?: string;
    secondGradientValue?: number;
    secondGradientColor?: string;
    gradientDirection?: string;

    // Image props
    backgroundImage?: string;
    backgroundRepeat?: string;
    backgroundPosition?: string;
    backgroundSize?: string;
}

/**
 * Validator options for template validation
 *
 * @export
 * @interface ValidationOptions
 */
export interface ValidationOptions {
    /** Is validation on save */
    isSaving?: boolean;
    /** Use debounce */
    debounce?: boolean;
}

export interface ValidationError {
    id: string;
    message: string;
    pageIndex?: number | null;
    rowIndex?: number | null;
    columnIndex?: number | null;
    section?: PortalBuilderSection;
    isLeadImageError?: boolean;
}

export interface AccessibilityValidationMessage extends ValidationError {
    description?: string;
    impact: ValidationMessageImpact;
    nodes: ValidationMessageNode[];
    validator: Validator;
    originalMessage?: any;
    url: string;
}

export interface AccessibilityValidationMessageForExport {
    IMPACT: ValidationMessageImpact;
    ID: string;
    URL: string;
    MESSAGE: string;
    ELEMENTS: string;
    VALIDATOR: Validator;
}

export enum ValidationRule {
    Required = "required",
    MinValue = "min-value",
    MaxValue = "max-value",
    PageUrlUnique = "page-url-unique",
    PageNameUnique = "page-name-unique",
    ExternalInternalLeadImageButton = "external-internal-lead-image-button",
    ExternalInternalPageLink = "external-internal-page-link",
    ExternalInternalWidgetsLink = "external-internal-widgets-link",
    ExternalInternalCookiesLink = "external-internal-cookies-link",
}

export enum Validator {
    AxeCore = "axe-core",
    Htmlcs = "htmlcs",
}

export enum ValidationMessageImpact {
    Error = "error",
    Warning = "warning",
    Notice = "notice",
    Unknown = "unknown",
}

export enum FontFamily {
    FiraSans = "Fira Sans",
    Jost = "Jost",
    Lato = "Lato",
    Montserrat = "Montserrat",
    OpenSans = "Open Sans",
    OverPass = "Overpass",
    Raleway = "Raleway",
    Roboto = "Roboto",
    SourceSansPro = "Source Sans Pro",
    WorkSans = "Work Sans",
    Arial = "Arial",
    Verdana = "Verdana",
    Tahoma = "Tahoma",
    TimesNewRoman = "Times New Roman",
    Figtree = "Figtree",
}

export interface ValidationMessageNode {
    selector?: string;
    innerHtml: string;
}

export type UtilityPortalTemplate = {};
export interface PortalBuilderCtx {
    colors: any;
    isConfigChanged: boolean;
    template: any;
    templateInfo: any;
    onActivePageChange: Function;
    onConfigChange: Function;
    onConfigSave: Function;
    onConfigRevert: Function;
    onTemplateInfoChange: Function;
}

export interface RevisionData {
    selectedDate: Date | null | undefined;
    skip: number;
    selectedRevision: string | null;
    selectedUsername: string | null;
}

export interface Revision {
    portaltemplatenumber: string;
    revisiondate: string;
    name: string;
    portaltemplaterevisionnumber: string;
    entityid: string;
    entitytype: string;
    username: string;
}

export type PortalTemplateConfiguration = {
    settings: TemplateSettingsConfiguration;
    colors: {
        primary?: string;
        secondary?: string;
        fill?: string;
        accent?: string;
        text?: string;
    };
    header: {
        isEnabled: boolean;
        components: PortalWidgetConfiguration[];
    };
    footer: {
        isEnabled: boolean;
        components: PortalWidgetConfiguration[];
    };
    content?: {
        pages: PageConfiguration[];
    };
    widgets?: {
        contentWrapper: any;
        button: any;
        heading: any;
        list?: {
            margin: string;
        };
        link?: {
            defaultColor: string;
            mouseOverColor: string;
            focusColor: string;
        };
    };
    _addedColors?: any;
};

export type ProgramTemplateConfiguration = {
    settings: {
        enableProgramNotification: boolean;
        programStartPage?: string;
    };
    content: {
        pages: PageConfiguration[];
    };
};

export interface TemplateCookiesConfiguration {
    enableCookiesBanner: boolean;
    enableCookiesBannerLink: boolean;
    cookiesBannerText?: string;
    /** Type of the link: "internal" or "external" */
    cookiesPageLink?: string;
    cookiesBannerLinkUrl?: string;
    cookiesBannerLinkOpenInNewTab?: boolean;
    cookiesBannerPosition?: AlignYPosition;
    cookiesBannerOverlay?: boolean;
}

export interface TemplateSettingsConfiguration extends TemplateCookiesConfiguration {
    enableGoogleAnalytics: boolean;
    enableGoogleTags: boolean;

    enableNotification: boolean;
    notificationText: string;
    notificationCloseIcon: boolean;

    enableProgramNotification: boolean;
    programNotificationText: string;
    programNotificationCloseIcon: boolean;

    siteTitleTemplate: string;

    /** Portal content max-width */
    maxWidth: string;

    /** Global typography settings */
    textColor: string;
    fontSize: TypographyFontSize;
    fontFamily: FontFamily;
    lineHeight: TypographyLineHeight;
}

export interface PageConfiguration extends PaddingProperties, MarginProperties, BackgroundProperties, StandardPageEditProperties {
    title?: string;
    link?: string;
    linkType?: LinkType;
    /**
     * For ordering pages in navigation widgets
     */
    order?: number;
    custom?: boolean;
    isShared?: boolean;
    components?: PortalWidgetConfiguration[];
    pageAccess?: PageAccessType;
    headerNavigation?: HeaderNavigation;
    openInNewTab?: boolean;
    leadImage?: any;
    internalPageSelect?: boolean;
    props?: ContactUsPageProps;

    // View application page
    headerBackgroundColor?: BackgroundProperties;
    headerTextColor?: TextColor;

    _isHomePage?: boolean;
    _activeTab?: PageTab;
}

export interface StandardPageEditProperties {
    bottomRowsOff?: boolean;
    topRowsOff?: boolean;
}

export interface PageTab {
    id: string;
    title: string;
}

export interface ContactUsPageProps {
    contactUsFormOn?: boolean;
    contactUsTextOn?: boolean;
    contactUsText?: string;
    thankYouTitle?: string;
    thankYouContent?: string;
}

export interface Row {
    errors?: ValidationError[];
    index?: number;
    title: string;
    value: RowConfiguration;
}

export interface RowConfiguration {
    height: string;
    "background-property": any;
    border: string;
    "group-spacing": any;
    layout: string;
    components: any[];
    hidden?: boolean;
}

export interface ColumnConfiguration {
    backgroundColor: string;
    border: string;
    components: any[];
    height: string;
    padding: string;
    width: string;
}

export type PortalTemplateInfo = {
    name?: string;
    notes?: string;
};

export type RowLayoutConfiguration = {
    columns: RowLayoutColumnConfiguration[];
};

export type RowLayoutColumnConfiguration = {
    span: number;
    portalProps: {
        xs: number;
        md: number;
    };
};

export interface WidgetProperties {
    id: string;
    title: string;
    type: string;
}

export interface PortalWidgetConfiguration {
    type: string;
    props?: any;
}

export type PropertyChangeFunc = (id: string, value: any, extraProperties?: { id: PropertyName | string; value: any }[]) => void;
// export type PropertyDisabledFunc = (context: any) => boolean;
export type PropertyValue = any;

export interface PortalBuilderProperty {
    className?: string;
    title?: string;
    type?: PropertyType | string;
    id: string;
    value?: PropertyValue;
    defaultValue?: PropertyValue;
    /** Value to use when clearing the property. For example use "0" when switch off padding */
    emptyValue?: PropertyValue;
    validationRules?: ValidationRule[];
    hidden?: boolean;
    disabled?: boolean;
    description?: (id: string, context: any) => string;
    errors?: string[];
    titleSwitch?: PortalBuilderProperty;
    borderBottom?: boolean;
    borderTop?: boolean;
    fullWidth?: boolean;
    maxLength?: number;
    minValue?: number;
    maxValue?: number;
    items?: any[];
    propertiesGroup?: PortalBuilderProperty[];
    nestingLevel?: number;
    component?: any;
    parentValue?: PropertyValue;
    tab?: PageTab;
    selectableWidth?: boolean;
    withGradient?: boolean;
    onlyTopAndBottom?: boolean;
    isTop?: boolean;
    hideContent?: boolean;
    hideDescription?: boolean;
    selectImageTitle?: string;
    viewTitle?: string;
}

export interface PortalBuilderTextProperty extends PortalBuilderProperty {
    fullWidth?: boolean;
    maxLength?: number;
}

export interface PortalBuilderPropertyProps extends PortalBuilderProperty {
    onChange: PropertyChangeFunc;
    listContainsActiveError?: boolean;
}
export interface PortalBuilderHtmlPropertyProps extends PortalBuilderPropertyProps {
    toolbar: ToolbarTypes;
    useTemplateColors: boolean;
}

export type PortalBuilderTextPropertyProps = PortalBuilderTextProperty & PortalBuilderPropertyProps;

export type PortalBuilderProperties = PortalBuilderProperty[] | PortalBuilderTextProperty[];

export interface ValidatePagesParams {
    pages: PageConfiguration[];
    config: PortalTemplateConfiguration;
    utilityConfig?: PortalTemplateConfiguration;
    isProgramPortalBuilder?: boolean;
    options?: ValidationOptions;
}

export interface ValidatePageParams {
    page: PageConfiguration;
    pageId: string;
    config: PortalTemplateConfiguration;
    pageIndex: number;
    utilityConfig?: PortalTemplateConfiguration;
    isProgramPortalBuilder?: boolean;
    options?: ValidationOptions;
}

export interface ValidateRowsParams {
    rows: RowConfiguration[];
    pageId: string;
    pageIndex?: number;
    section: PortalBuilderSection;
}

export interface ValidateRowParams {
    row: RowConfiguration;
    rowPath: string;
    pageIndex?: number;
    rowIndex: number;
    section: PortalBuilderSection;
}

export interface ValidateColumnsParams {
    columns: ColumnConfiguration[];
    rowPath: string;
    pageIndex?: number;
    rowIndex: number;
    section: PortalBuilderSection;
}

export interface ValidateColumnParams {
    column: ColumnConfiguration;
    colPath: string;
    pageIndex?: number;
    rowIndex: number;
    columnIndex: number;
    section: PortalBuilderSection;
}

export interface ValidatePropertyParams {
    id: string;
    validationRule?: ValidationRule;
    value?: PropertyValue | any;
    config?: PortalTemplateConfiguration;
    pageIndex?: number;
    rowIndex?: number;
    columnIndex?: number;
    section: PortalBuilderSection;
    utilityConfig?: PortalTemplateConfiguration;
    isLeadImageError?: boolean;
    activeTab?: PageTab;
    widget?: PortalWidgetConfiguration;
    property?: PortalBuilderProperty;
}

export enum LeadImageContentType {
    Title = "title",
    TitleButton = "titleButton",
    TitleText = "titleText",
    TitleTextButton = "titleTextButton",
}

export interface ValidateChildrenProps {
    parentId: string;
    propertiesGroup?: PortalBuilderProperty[];
    pageId: string;
    pageIndex: number;
    config: PortalTemplateConfiguration;
    utilityConfig?: PortalTemplateConfiguration;
    activeTab?: PageTab;
}

export interface PageCtx {
    page?: PageConfiguration;
}

export interface PortalBuilderProps {
    isProgramPortalBuilder: boolean;
    utilityNumber: string;
    programNumber?: string;
    portalTemplateNumber?: string;
    entityType: string;
    entityName: string;
    activeInitialView?: PortalBuilderSection;
    onPortalTemplateSaved: (template: any, onComplete?: Function) => void;
}

export interface ProgramPortalBuilderProps extends PortalBuilderProps {
    configData: ProgramPortalBuilderConfigData;
}

export interface UtilityPortalBuilderProps extends PortalBuilderProps {
    template?: UtilityPortalBuilderConfigData;
}

export interface UtilityPortalConfigurationResponse {
    name: string;
    configuration?: string;
    notes?: string;
    thumbnail?: string;
    dateCreated?: string;
    userCreated?: string;
    dateLastEdited?: string;
    userLastEdited?: string;
    portalTemplateNumber?: string;
}

export interface UtilityPortalBuilderConfigData extends Omit<UtilityPortalConfigurationResponse, "configuration"> {
    configuration?: PortalTemplateConfiguration;
}

export type ProgramPortalBuilderConfigData = {
    utilityTemplateName?: string;
    utilityTemplateNumber?: string;
    utilityTemplateConfiguration?: PortalTemplateConfiguration;
    programTemplateConfiguration?: PortalTemplateConfiguration;
    notes?: string;
    userCreated?: string;
    userLastEdited?: string;
    dateEntered?: string;
    dateEdited?: string;
};

export type SelectedUtilityTemplate = {
    utilityTemplateNumber: string;
    utilityTemplateConfiguration: PortalTemplateConfiguration;
    utilityTemplateName: string;
};

export enum PageLink {
    CustomerHome = "/customer-home",
    ApplicationView = "/submitted-application",
}
