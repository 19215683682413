import React from "react";
import "./style.scss";

const TilesWrap = (props) => {
    const justifyPosition = props.justify ?? "center";

    return (
        <div className={`tiles-wrap flex-row flex-wrap justify-${justifyPosition}`} hidden={props.hidden}>
            {props.children}
        </div>
    );
};

export default TilesWrap;
