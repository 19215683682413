import { API_POST_AUTHORIZED } from "../actionTypes";

export const BASE_URL = process.env.REACT_APP_LOGGING_API_BASE_URL;

export const reportError = (error) => async (dispatch, getState) => {
    const {
        user,
        system: { apiErrorLogging },
    } = getState();

    // Do not log API errors unless the apiErrorLogging flag is set to true in heartbeat.
    const errorName = error?.errorName ?? "";
    if (!apiErrorLogging && (errorName.startsWith("API_") || errorName.startsWith("DATA_GRID_"))) return;

    if (user && user.isActive) {
        dispatch({
            type: API_POST_AUTHORIZED,
            url: `${BASE_URL}Errors`,
            body: JSON.stringify(error),
            skipErrorReport: true,
        });
    }
};
