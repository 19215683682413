import React, { memo, useEffect } from "react";
import cn from "classnames";

import { useResource } from "store/resources/useResource";
import DropDownInput from "../../../Input/DropDownInput";

const BulkUploadProgramsDropDown = memo(
    ({
        utilityNumber,
        activeOnly = false,
        renderWhenEmpty,
        className,
        program,
        placeholder = "Not Selected",
        showLabel = true,
        resourceKey,
        onChange,
        directory,
        disabled,
        withPopper,
        label,
        required,
    }) => {
        const [programs, isLoadingPrograms] = useResource({
            resourceName: "utilityProgramsWithAttribute",
            key: resourceKey,
            path: {
                utilityNumber,
                attribute: directory,
            },
            forced: true,
            transform: (data) => {
                return ((data && data.programList && data.programList.filter((i) => (activeOnly ? i.isActive : true))) || []).map((i) => ({
                    label: i.program,
                    value: i.programNumber,
                }));
            },
        });

        useEffect(() => {
            if (programs?.length === 1) {
                onChange(programs[0].value);
            }
        }, [onChange, programs]);

        if (renderWhenEmpty && !isLoadingPrograms && (!programs || programs.length === 0)) {
            return renderWhenEmpty;
        }

        return (
            <DropDownInput
                className={cn("utility-programs-list", className)}
                label={showLabel ? label || "Program Filter" : ""}
                mobileHeader={showLabel ? label || "Program Filter" : ""}
                placeholder={isLoadingPrograms ? "Loading..." : placeholder}
                disabled={isLoadingPrograms || disabled}
                data={programs}
                value={isLoadingPrograms ? undefined : program}
                onChange={(event) => onChange(event.target.value)}
                withPopper={withPopper}
                required={required}
            />
        );
    }
);

export default BulkUploadProgramsDropDown;
