import React, { useRef, useState, memo, useEffect } from "react";
import { get, fromPairs, isEmpty, isEqual } from "lodash";

import { useSelector, useDispatch } from "react-redux";
import { sendSSOEmail } from "../../../store/userProfile/actions";
import { useResource } from "store/resources/useResource";
import { isEmailAddress } from "../../utils/form";
import { changePassword, changePasswordSsrs } from "../../utils/user";
import { CHANGE_PASSWORD_ERROR_CLEAR, CHANGE_PASSWORD_ERROR } from "../../../store/actionTypes";
import Form from "../../ui/Form";
import TextInput from "../../ui/Input/TextInput";
import { deepEquals } from "react-jsonschema-form/lib/utils";
import ButtonGroup from "components/ui/Button/ButtonGroup";
import Button from "../../ui/Button";
import WaitIcon from "../../ui/WaitIcon";
import IconWithLabel from "../../ui/Icons/IconWithLabel";
import PageLeadBlock from "../../ui/PageLeadBlock";
import JsonSchemaFormWithConditionals from "./../../ui/Form/JsonSchema/JsonSchemaFormWithConditionals";
import TabList from "../../ui/List/TabList";
import { updateEmail } from "../../../store/userProfile/actions";
import { toast } from "react-toastify";

import {
    userDataSchema,
    optionalPhoneSchema,
    optionalAddressSchema,
    optioanlOtherSchema,
    userDataUiSchema,
    optPhoneUiSchema,
    optAddressUiSchema,
    optOtherUiSchema,
} from "./UserProfileConfig";

import "./style.scss";

const ERROR_PASSWORDS_DO_NOT_MATCH = "New password should be the same as Repeat password.";
const ERROR_NEW_PASSWORD_SAME_AS_OLD = "New password can't be the same as Old password.";
const ERROR_LOGIN_FAILED = "The username/password combination you entered cannot be found";

export const UserProfileForm = memo((props) => {
    const { initialValues, isLoading, isUpdating, showAllFields, onShowAllFields, onSubmit, onReset } = props;

    const dispatch = useDispatch();

    const { userName, firstName, lastName, userNumber, address1, address2, cell, city, company, pager, phone, state, zip, sso } =
        initialValues;
    const userData = { userName, firstName, lastName, userNumber, sso };
    const phoneData = { pager, phone, cell };
    const addressData = { address1, address2, city, state, zip };
    const otherData = { company };

    const userDataRef = useRef(initialValues);
    const phoneRef = useRef(initialValues);
    const addressRef = useRef(initialValues);
    const otherRef = useRef(initialValues);

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [repeatEmail, setRepeatEmail] = useState("");
    const [emailPassword, setEmailPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [repeatEmailError, setRepeatEmailError] = useState("");
    const errorMessage = useSelector((state) => state.changePassword.message);
    const errorMessageSsrs = useSelector((state) => state.changePassword.ssrsMessage);
    const ssrsEmailMessage = useSelector((state) => state.userProfile.ssrsMessage);
    const errorMessageEmail = useSelector((state) => state.userProfile.errorMessage);
    const emailMessageUpdate = useSelector((state) => state.userProfile.message);
    const isError = (errorMessage || "").length > 0;
    const [authorizationCode, setAuthorizationCode] = useState("");
    const isOldPasswordError = errorMessage === ERROR_LOGIN_FAILED;
    const isNewPasswordError = errorMessage !== ERROR_LOGIN_FAILED && errorMessage !== ERROR_PASSWORDS_DO_NOT_MATCH && isError;
    const isRepeatPasswordError = errorMessage === ERROR_PASSWORDS_DO_NOT_MATCH;

    const [saveEnabled, setSaveEnabled] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [isSubmittingEmail, setIsSubmittingEmail] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [emailSSOSent, setEmailSSOSent] = useState(false);
    const [passwordSSOSent, setPasswordSSOSent] = useState(false);
    const tabs = [
        {
            id: "changeProfileDetails",
            title: "Change Profile Details",
        },
        {
            id: "changePassword",
            title: initialValues.sso ? "Change SSRS Password" : "Change Password",
        },
        {
            id: "changeEmail",
            title: "Change Email",
        },
    ];

    const [activeTab, setActiveTab] = useState(tabs[0]);

    useEffect(() => {
        if (errorMessageEmail !== null) {
            toast.error(errorMessageEmail);
        }
        setTimeout(() => {
            setIsSubmittingEmail(false);
        }, 500);
    }, [errorMessageEmail]);

    const getDataFromRef = (ref, mapObject) => {
        if (ref && !isEmpty(ref.current)) {
            const formData = get(ref, "current.state.formData", {});
            const values = Object.keys(mapObject).map((key) => {
                return { key: key, value: formData[key] || null };
            });

            return fromPairs(values.map((item) => [item.key, item.value]));
        } else {
            return {};
        }
    };

    const getFormDataAndErrors = () => {
        const userValues = getDataFromRef(userDataRef, userData);
        const phoneValues = getDataFromRef(phoneRef, phoneData);
        const addressValues = getDataFromRef(addressRef, addressData);
        const otherValues = getDataFromRef(otherRef, otherData);

        const userErrors = get(userDataRef, "current.state.errors", []);
        const phoneErrors = get(phoneRef, "current.state.errors", []);
        const addressErrors = get(addressRef, "current.state.errors", []);
        const otherErrors = get(otherRef, "current.state.errors", []);

        const errors = [...userErrors, ...phoneErrors, ...addressErrors, ...otherErrors];
        const formData = {
            ...initialValues,
            ...userValues,
            ...phoneValues,
            ...addressValues,
            ...otherValues,
        };
        return [errors, formData];
    };

    const handleSaveButtonState = (dataToCompare) => {
        const [errors, formData] = getFormDataAndErrors();

        if (errors.length > 0) {
            setSaveEnabled(false);
        } else {
            if (!isEqual(dataToCompare, formData)) {
                setSaveEnabled(true);
            } else {
                setSaveEnabled(false);
            }
        }
    };

    const onChange = (data) => {
        if (Object.keys(data.formData).length === Object.keys(initialValues).length) {
            handleSaveButtonState(initialValues);
        }
        const [, formData] = getFormDataAndErrors();

        if (deepEquals(formData, initialValues)) {
            setIsChanged(false);
        } else {
            setIsChanged(true);
        }
    };

    const onFormSubmit = (event) => {
        event.preventDefault();

        const [errors, formData] = getFormDataAndErrors();

        if (errors.length === 0) {
            onSubmit(formData);
            setSaveEnabled(false);
        }
    };

    const onSuccess = () => {
        setOldPassword("");
        setNewPassword("");
        setRepeatPassword("");
        setAuthorizationCode("");
    };

    const onPasswordFormSubmit = (e) => {
        e.preventDefault();

        const isNewPasswordEntered = newPassword?.length > 0;

        if (isNewPasswordEntered && newPassword !== repeatPassword) {
            dispatch({
                type: CHANGE_PASSWORD_ERROR,
                message: ERROR_PASSWORDS_DO_NOT_MATCH,
            });
        } else if (isNewPasswordEntered && newPassword === oldPassword) {
            dispatch({
                type: CHANGE_PASSWORD_ERROR,
                message: ERROR_NEW_PASSWORD_SAME_AS_OLD,
            });
        } else if (!isError && initialValues.sso) {
            setSubmitting(true);
            changePasswordSsrs(
                {
                    newPassword,
                    authorizationCode,
                },
                setSubmitting,
                onSuccess
            );
        } else if (!isError) {
            setSubmitting(true);
            changePassword(
                {
                    oldPassword,
                    newPassword,
                },
                setSubmitting,
                onSuccess
            );
        }
    };

    const onEmailFormSubmit = (e) => {
        let dataToSend;
        setIsSubmittingEmail(true);
        if (newEmail !== repeatEmail) {
            e.preventDefault();
            setRepeatEmailError("Emails do not match");
            setTimeout(() => {
                setIsSubmittingEmail(false);
            }, 500);
        } else {
            setRepeatEmailError("");
            if (initialValues.sso) {
                dataToSend = {
                    authCode: authorizationCode,
                    email: newEmail,
                };
            } else {
                dataToSend = {
                    password: emailPassword,
                    email: newEmail,
                };
            }
            e.preventDefault();
            dispatch(
                updateEmail({
                    data: dataToSend,
                })
            );
        }
    };

    const showOptionalText = () => {
        return showAllFields ? "Hide optional fields" : "Show Optional fields";
    };

    const validate = (formData, errors) => {
        if (formData.email && !isEmailAddress(formData.email)) {
            errors.email.addError("Email address must be valid email address");
        }

        return errors;
    };

    useEffect(() => {
        const validateEmail = () => {
            if (!isEmailAddress(newEmail)) {
                setEmailError("Email address must be valid email address");
            } else {
                setEmailError("");
            }
        };
        if (newEmail === "") {
        } else {
            validateEmail();
        }
        setIsSubmittingEmail(false);
    }, [newEmail]);

    const clearError = () => {
        dispatch({
            type: CHANGE_PASSWORD_ERROR_CLEAR,
        });
    };

    const isPasswordsFormValid = () => {
        let valid;
        if (!initialValues.sso) {
            valid = oldPassword?.length > 0 && newPassword?.length > 0 && repeatPassword?.length > 0;
        } else {
            valid = newPassword?.length > 0 && repeatPassword?.length > 0 && authorizationCode?.length > 0;
        }
        return valid;
    };

    const isEmailFormValid = () => {
        let valid;
        if (!initialValues.sso) {
            valid = emailPassword?.length > 0 && newEmail?.length > 0 && isEmailAddress(newEmail) && repeatEmail?.length > 0;
        } else {
            valid = newEmail?.length > 0 > 0 && authorizationCode?.length > 0 && isEmailAddress(newEmail) && repeatEmail?.length > 0;
        }
        return valid;
    };

    const onFormReset = () => {
        setSaveEnabled(false);
        onReset();
    };

    return (
        <div className="user-profile-form">
            {isLoading ? (
                <WaitIcon />
            ) : (
                <div className="fields-container flex-column no-scroll">
                    <PageLeadBlock>
                        <div>
                            Hello,{" "}
                            <span>
                                {initialValues.firstName} {initialValues.lastName}
                            </span>
                            !
                        </div>
                    </PageLeadBlock>
                    <div className="basic-fields flex-column flex-one no-scroll">
                        <TabList tabListGhost items={tabs} activeItem={activeTab} onClick={(tab) => setActiveTab(tab)} />
                        {activeTab.id === "changeProfileDetails" && (
                            <div className="profile-details flex-one-in-column with-scroll">
                                <div className="user-profile-base">
                                    <div className="user-profile-fields-block">
                                        <JsonSchemaFormWithConditionals
                                            formRef={userDataRef}
                                            formId="userData"
                                            initialValues={initialValues}
                                            schema={userDataSchema}
                                            uiSchema={userDataUiSchema}
                                            onChange={onChange}
                                            noReset
                                            noSubmit
                                            liveValidate
                                            validate={validate}
                                        />
                                    </div>
                                </div>

                                <div className={"profile-expandable-panel show-profile-optional-fields" + (showAllFields ? " opened" : "")}>
                                    <IconWithLabel
                                        iconWithLabelRight
                                        iconWithLabel={
                                            showAllFields ? "shevron-in-circle-up-filled--before" : "shevron-in-circle-down-drop-down-empty"
                                        }
                                        active={showAllFields}
                                        onClick={() => onShowAllFields(!showAllFields)}
                                    >
                                        {showOptionalText()}
                                    </IconWithLabel>
                                    {showAllFields && (
                                        <div className="user-profile-optional-fields">
                                            <div className="main-grid-wrap responsive">
                                                <div className="optional-group">
                                                    <div className="with-icon group-title">
                                                        <span>Phone</span>
                                                    </div>
                                                    <JsonSchemaFormWithConditionals
                                                        formRef={phoneRef}
                                                        formId="phone"
                                                        initialValues={initialValues}
                                                        schema={optionalPhoneSchema}
                                                        uiSchema={optPhoneUiSchema}
                                                        onChange={onChange}
                                                        noReset
                                                        noSubmit
                                                        validate={validate}
                                                    />
                                                </div>

                                                <div className="optional-group">
                                                    <div className="with-icon group-title">
                                                        <span>Address</span>
                                                    </div>
                                                    <JsonSchemaFormWithConditionals
                                                        formRef={addressRef}
                                                        formId="address"
                                                        initialValues={initialValues}
                                                        schema={optionalAddressSchema}
                                                        uiSchema={optAddressUiSchema}
                                                        onChange={onChange}
                                                        noReset
                                                        noSubmit
                                                        validate={validate}
                                                    />
                                                </div>

                                                <div className="optional-group">
                                                    <div className="with-icon group-title">
                                                        <span>Other</span>
                                                    </div>
                                                    <JsonSchemaFormWithConditionals
                                                        formRef={otherRef}
                                                        formId="other"
                                                        initialValues={initialValues}
                                                        schema={optioanlOtherSchema}
                                                        uiSchema={optOtherUiSchema}
                                                        onChange={onChange}
                                                        noReset
                                                        noSubmit
                                                        validate={validate}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <IconWithLabel
                                        className="duplicate-for-mobile"
                                        iconWithLabelRight
                                        iconWithLabel={
                                            showAllFields ? "shevron-in-circle-up-filled--before" : "shevron-in-circle-down-drop-down-empty"
                                        }
                                        active={showAllFields}
                                        onClick={() => onShowAllFields(!showAllFields)}
                                    >
                                        {showOptionalText()}
                                    </IconWithLabel>
                                </div>
                                <div className="user-profile-actions">
                                    <ButtonGroup transparent>
                                        <Button primary loading={isUpdating} disabled={!saveEnabled} onClick={onFormSubmit}>
                                            {"Save profile changes"}
                                        </Button>
                                        <Button secondary icon="undo" disabled={!isChanged} onClick={onFormReset}>
                                            Revert Changes
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                        )}
                        {activeTab.id === "changePassword" && (
                            <ChangePasswordForm
                                isSso={initialValues.sso}
                                oldPassword={oldPassword}
                                newPassword={newPassword}
                                repeatPassword={repeatPassword}
                                isOldPasswordError={isOldPasswordError}
                                isNewPasswordError={isNewPasswordError}
                                isRepeatPasswordError={isRepeatPasswordError}
                                isSubmitting={isSubmitting}
                                errorMessage={errorMessage}
                                authorizationCode={authorizationCode}
                                isPasswordsFormValid={isPasswordsFormValid}
                                clearError={clearError}
                                setOldPassword={setOldPassword}
                                setNewPassword={setNewPassword}
                                setRepeatPassword={setRepeatPassword}
                                onPasswordFormSubmit={onPasswordFormSubmit}
                                setAuthorizationCode={setAuthorizationCode}
                                errorMessageSsrs={errorMessageSsrs}
                                ssrsEmailMessage={ssrsEmailMessage}
                                isUpdating={isUpdating}
                                passwordSSOSent={passwordSSOSent}
                                setPasswordSSOSent={setPasswordSSOSent}
                            />
                        )}
                        {activeTab.id === "changeEmail" && (
                            <ChangeEmailForm
                                setNewEmail={setNewEmail}
                                newEmail={newEmail}
                                isSubmitting={isSubmittingEmail}
                                onEmailFormSubmit={onEmailFormSubmit}
                                setAuthorizationCode={setAuthorizationCode}
                                authorizationCode={authorizationCode}
                                errorMessageSsrs={errorMessageSsrs}
                                isNewPasswordError={isNewPasswordError}
                                ssrsEmailMessage={ssrsEmailMessage}
                                isSso={initialValues.sso}
                                emailPassword={emailPassword}
                                setEmailPassword={setEmailPassword}
                                isOldPasswordError={isOldPasswordError}
                                isEmailFormValid={isEmailFormValid}
                                clearError={clearError}
                                emailError={emailError}
                                isUpdating={isUpdating}
                                errorMessageEmail={errorMessageEmail}
                                setRepeatEmail={setRepeatEmail}
                                repeatEmail={repeatEmail}
                                setRepeatEmailError={setRepeatEmailError}
                                repeatEmailError={repeatEmailError}
                                setEmailError={setEmailError}
                                emailMessageUpdate={emailMessageUpdate}
                                emailSSOSent={emailSSOSent}
                                setEmailSSOSent={setEmailSSOSent}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
});

const ChangePasswordForm = memo(
    ({
        oldPassword,
        newPassword,
        repeatPassword,
        isOldPasswordError,
        isNewPasswordError,
        errorMessageSsrs,
        isRepeatPasswordError,
        isSubmitting,
        isUpdating,
        errorMessage,
        isPasswordsFormValid,
        clearError,
        setOldPassword,
        setNewPassword,
        setRepeatPassword,
        onPasswordFormSubmit,
        isSso,
        authorizationCode,
        setAuthorizationCode,
        ssrsEmailMessage,
        passwordSSOSent,
        setPasswordSSOSent,
    }) => {
        const [passwordInfo] = useResource({
            resourceName: "userPassword",
            key: "userPassword",
        });

        const dispatch = useDispatch();

        const newPasswordHelpText = isNewPasswordError ? errorMessage : passwordInfo?.description;

        const srssTextMessage = errorMessageSsrs
            ? errorMessageSsrs
            : "Please click Get Code to have the authorization code sent to your email.";

        const resetPasswordForm = () => {
            setOldPassword("");
            setNewPassword("");
            setRepeatPassword("");
            setAuthorizationCode("");
        };

        const sendEmailForSsoCode = () => {
            dispatch(sendSSOEmail());
            setPasswordSSOSent(true);
        };
        const isChanged = () => {
            return !(isEmpty(oldPassword) && isEmpty(newPassword) && isEmpty(repeatPassword));
        };

        const ssrsEmailButtonText =
            ssrsEmailMessage === "Authorization code successfully sent" && passwordSSOSent ? "Code Sent" : "Get Code";

        return (
            <div className="flex-one with-scroll">
                <Form className={"user-profile-change-password-fields"} onSubmit={onPasswordFormSubmit}>
                    {!isSso ? (
                        <TextInput
                            value={oldPassword}
                            required
                            label="Old password"
                            name="oldPassword"
                            type="password"
                            placeholder="Enter your old password"
                            error={isOldPasswordError}
                            msgText={isOldPasswordError && errorMessage}
                            onChange={(e) => {
                                clearError();
                                setOldPassword(e.target.value);
                            }}
                        />
                    ) : (
                        <TextInput
                            value={authorizationCode}
                            required
                            label="Authorization code"
                            name="ssoCode"
                            autoComplete="one-time-code"
                            placeholder="Enter your authorization code"
                            error={errorMessageSsrs}
                            msgText={srssTextMessage}
                            onChange={(e) => {
                                clearError();
                                setAuthorizationCode(e.target.value);
                            }}
                        />
                    )}
                    <TextInput
                        value={newPassword}
                        required
                        label="New password"
                        name="newPassword"
                        type="password"
                        placeholder="Enter your new password"
                        error={isNewPasswordError}
                        msgText={newPasswordHelpText}
                        onChange={(e) => {
                            clearError();
                            setNewPassword(e.target.value);
                        }}
                    />
                    <TextInput
                        value={repeatPassword}
                        required
                        label="Repeat password"
                        name="newPasswordRepeated"
                        type="password"
                        placeholder="Repeat your new password"
                        error={isRepeatPasswordError}
                        msgText={isRepeatPasswordError && errorMessage}
                        onChange={(e) => {
                            clearError();
                            setRepeatPassword(e.target.value);
                        }}
                    />
                    <div className="change-password-actions">
                        <ButtonGroup transparent>
                            {isSso && (
                                <Button
                                    disabled={
                                        (ssrsEmailMessage === "Authorization code successfully sent" && passwordSSOSent) || isUpdating
                                    }
                                    onClick={sendEmailForSsoCode}
                                >
                                    {isUpdating ? "Sending..." : ssrsEmailButtonText}
                                </Button>
                            )}
                            <Button type="submit" primary disabled={!isPasswordsFormValid() || isSubmitting}>
                                {isSubmitting ? "Saving.." : "Save"}
                            </Button>
                            <Button secondary disabled={!isChanged()} onClick={resetPasswordForm}>
                                Clear
                            </Button>
                        </ButtonGroup>
                    </div>
                </Form>
            </div>
        );
    }
);

const ChangeEmailForm = memo(
    ({
        setNewEmail,
        newEmail,
        onEmailFormSubmit,
        errorMessageSsrs,
        emailError,
        isSso,
        authorizationCode,
        setAuthorizationCode,
        ssrsEmailMessage,
        emailPassword,
        isOldPasswordError,
        setEmailPassword,
        isEmailFormValid,
        isSubmitting,
        clearError,
        validateEmail,
        isUpdating,
        errorMessageEmail,
        emailMessageUpdate,
        errorMessage,
        setRepeatEmail,
        repeatEmail,
        setRepeatEmailError,
        repeatEmailError,
        setEmailError,
        emailSSOSent,
        setEmailSSOSent,
    }) => {
        const dispatch = useDispatch();

        const srssTextMessage = errorMessageSsrs
            ? errorMessageSsrs
            : "Please click Get Code to have the authorization code sent to your email.";

        const resetPasswordForm = () => {
            setNewEmail("");
            setAuthorizationCode("");
            setEmailPassword("");
            setRepeatEmail("");
            setRepeatEmailError("");
            setEmailError("");
        };

        const sendEmailForSsoCode = () => {
            dispatch(sendSSOEmail());
            setEmailSSOSent(true);
        };
        const isChanged = () => {
            if (isSso) {
                return !isEmpty(authorizationCode) || !isEmpty(newEmail) || !isEmpty(repeatEmail);
            } else {
                return !isEmpty(emailPassword) || !isEmpty(newEmail) || !isEmpty(repeatEmail);
            }
        };

        const ssrsEmailButtonText = ssrsEmailMessage === "Authorization code successfully sent" && emailSSOSent ? "Code Sent" : "Get Code";

        let saveText;
        if (emailMessageUpdate === "Email address successfully updated" && isSubmitting) {
            saveText = "Saved";
        } else if (isSubmitting) {
            saveText = "Saving...";
        } else {
            saveText = "Save";
        }

        return (
            <div className="flex-one with-scroll">
                <Form className={"user-profile-change-password-fields"} onSubmit={onEmailFormSubmit}>
                    {!isSso ? (
                        <TextInput
                            value={emailPassword}
                            required
                            label="Password"
                            name="emailPassword"
                            type="password"
                            placeholder="Enter your password"
                            error={isOldPasswordError}
                            autoComplete="off"
                            msgText={isOldPasswordError && errorMessage}
                            onChange={(e) => {
                                clearError();
                                setEmailPassword(e.target.value);
                            }}
                        />
                    ) : (
                        <TextInput
                            value={authorizationCode}
                            required
                            label="Authorization code"
                            name="ssoCode"
                            autoComplete="one-time-code"
                            placeholder="Enter your authorization code"
                            error={errorMessageSsrs}
                            msgText={srssTextMessage}
                            onChange={(e) => {
                                clearError();
                                setAuthorizationCode(e.target.value);
                            }}
                        />
                    )}
                    <TextInput
                        value={newEmail}
                        required
                        label="New Email"
                        name="newEmail"
                        type="text"
                        placeholder="Enter your new email"
                        error={emailError}
                        msgText={emailError}
                        onChange={(e) => {
                            clearError();
                            setNewEmail(e.target.value);
                        }}
                    />
                    <TextInput
                        value={repeatEmail}
                        required
                        label="Repeat Email"
                        name="repeatEmail"
                        type="text"
                        placeholder="Repeat your new email"
                        error={repeatEmailError}
                        msgText={repeatEmailError}
                        onChange={(e) => {
                            clearError();
                            setRepeatEmail(e.target.value);
                        }}
                    />

                    <div className="change-password-actions">
                        <ButtonGroup transparent>
                            {isSso && (
                                <Button
                                    disabled={(ssrsEmailMessage === "Authorization code successfully sent" && emailSSOSent) || isUpdating}
                                    onClick={sendEmailForSsoCode}
                                >
                                    {isUpdating ? "Sending..." : ssrsEmailButtonText}
                                </Button>
                            )}
                            <Button type="submit" primary disabled={!isEmailFormValid() || isSubmitting}>
                                {saveText}
                            </Button>
                            <Button secondary disabled={!isChanged()} onClick={resetPasswordForm}>
                                Clear
                            </Button>
                        </ButtonGroup>
                    </div>
                </Form>
            </div>
        );
    }
);
export default UserProfileForm;
