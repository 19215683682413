import React, { memo, useContext } from "react";

import { logsSystemXmlProcessGridColumnKeys } from "../configureGrids";
import { exportDatagridToCSV } from "../../utils/CSV";

import IconWithLabel from "../../ui/Icons/IconWithLabel";
import DataGrid from "../../ui/DataGrid";
import withDataGrid from "../../ui/DataGrid/withDataGrid";

import LogsSystemXmlProcessDetails from "./LogsSystemXmlProcessDetails";
import { datePartFromJsonDate, dateToJson } from "components/utils/date";
import { useSelector } from "react-redux";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import { sideNavKey, sideNavSize } from "components/ui/SideNav/SideNavRoot";
import { WindowContext } from "components/ui/Windows/Window";

const DATA_GRID_ID = "logs-system-xml-process";

const LogsGrid = withDataGrid("logsSystemXmlProcess", ({ gridId, dataGridConfig, filterRequired, filter }) => {
    const ROW_ACTIONS = [
        {
            name: "details",
            title: "Details",
            icon: "eye-visibility-empty",
        },
    ];

    const { isSplitView } = useContext(WindowContext);
    const key = isSplitView ? undefined : sideNavKey.globalRight;
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.staticXXL,
        sideNavKey: key,
    });

    const onRowAction = (action) => {
        const { dataItem } = action;
        const processRunID = dataItem[logsSystemXmlProcessGridColumnKeys.processRunID];

        handleOpenSidePanel(<LogsSystemXmlProcessDetails processRunID={processRunID} onClose={handleCloseSidePanel} />);
    };

    return (
        <DataGrid
            name={gridId}
            config={dataGridConfig}
            customRowActions={ROW_ACTIONS}
            onRowAction={onRowAction}
            filterRequired={filterRequired}
        />
    );
});

const LogsSystemXmlProcess = memo(() => {
    const noFilters = useSelector((state) => !state.dataGrid[DATA_GRID_ID]?.filter?.filters?.length);
    return (
        <div className="panel log-system-xml-process tab-list-tab-content flex-one-in-column flex-column no-scroll">
            <div className="data-grid-controls">
                <span className="flex-one"></span>
                <IconWithLabel
                    disabled={noFilters}
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: DATA_GRID_ID,
                            fileName: "log_xml_processes",
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <LogsGrid
                gridId={DATA_GRID_ID}
                filter={`${logsSystemXmlProcessGridColumnKeys.startDate}=${datePartFromJsonDate(dateToJson(new Date()))}`}
                initialFilter={true}
                filterRequired={true}
            />
        </div>
    );
});

export default LogsSystemXmlProcess;
