import React, { useState, memo } from "react";

import { exportDatagridToCSV } from "../../../utils/CSV";

import IconWithLabel from "../../../ui/Icons/IconWithLabel";
import ProductAttributesGrid from "./ProductAttributesGrid";
import ProductAttributesForm from "./ProductAttributesForm";

const ProductAttributesPanel = memo((props) => {
    const gridId = "product-attributes";

    const [showAddAttribute, setShowAddAttribute] = useState(false);
    const addAttributeIcon = showAddAttribute ? "shevron-circle-down-filled" : "plus";

    const toggleAddAttribute = () => {
        setShowAddAttribute(!showAddAttribute);
    };

    return (
        <div className="tab-list-tab-content flex-one-in-column flex-column">
            <div className="data-grid-controls">
                <IconWithLabel icon={addAttributeIcon} onClick={toggleAddAttribute}>
                    Add New Attribute
                </IconWithLabel>
                <span className="flex-one"></span>
                <IconWithLabel withHandMadeIcon onClick={() => exportDatagridToCSV({ dataGridId: gridId })}>
                    Export CSV
                </IconWithLabel>
            </div>
            {showAddAttribute && (
                <div className="attributes-form">
                    <ProductAttributesForm onClose={toggleAddAttribute} dispatch={props.dispatch} gridId={gridId} />
                </div>
            )}
            <div className="panel product-attributes flex-column fill-height no-scroll">
                <ProductAttributesGrid gridId={gridId} />
            </div>
        </div>
    );
});

export default ProductAttributesPanel;
