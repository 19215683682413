import React, { memo, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";

import { deleteResource } from "../../../../../../store/resources/actions";
import { modalOpen } from "../../../../../../store/modal/actions";
import { refreshContactLists, useApplicationOtherContacts } from "./ContactsUtils";
import { useApplicationAssignedContacts, useProgramRights } from "store/resources/useResource";
import { CONTACT_TYPE } from "components/utils/constants";

import WaitIcon from "../../../../WaitIcon";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import OtherContacts from "./OtherContacts";
import AssignedContacts from "./AssignedContacts";
import { systemUserRights } from "components/utils/user";

import "./style.scss";

export const ContactsPanelContext = React.createContext();

const ContactsPanel = memo(({ panel, onExpand }) => {
    const { utilityNumber, programNumber, applicationNumber } = panel?.data;

    const dispatch = useDispatch();
    const otherContactsListRef = useRef();
    const [programRights = []] = useProgramRights({ programNumber });
    const [premiseContact, primaryContact, contractorContact, isLoadingAssignedContacts] = useApplicationAssignedContacts({
        applicationNumber,
    });
    const [otherContacts, isLoadingOtherContacts] = useApplicationOtherContacts({ applicationNumber });

    const assignedContacts = [
        {
            ContactType: CONTACT_TYPE.PRIMARY,
            Contact: primaryContact,
        },
        {
            ContactType: CONTACT_TYPE.PREMISE,
            Contact: premiseContact,
        },
        {
            ContactType: CONTACT_TYPE.CONTRACTOR,
            Contact: contractorContact,
        },
    ];

    const onDelete = useCallback(
        (contact) => {
            const text = <p>Are you sure you want to delete the contact?</p>;

            dispatch(
                modalOpen({
                    modalType: "CONFIRM",
                    modalProps: {
                        title: "Delete Contact",
                        overlayClassName: "modal-styled",
                        className: "delete-contact-confirmation-modal modal-sm",
                        modalIcon: "delete-trash-empty",
                        content: text,
                        footerContentCenter: true,
                        onConfirm: () => {
                            dispatch(
                                deleteResource({
                                    resourceName: "applicationContacts",
                                    resourceId: contact.contactnumber,
                                    path: {
                                        appId: applicationNumber,
                                    },
                                    onSuccess: () => {
                                        refreshContactLists({
                                            applicationNumber,
                                        });
                                    },
                                })
                            );
                        },
                    },
                })
            );
        },
        [applicationNumber, dispatch]
    );

    const onScrollToOtherContactsList = useCallback(() => {
        otherContactsListRef.current &&
            otherContactsListRef.current.scrollIntoView({
                behavior: "smooth",
            });
    }, []);

    const canSearchCIS = programRights.includes(systemUserRights.VISIONDSM_CIS_SEARCH);

    if (isLoadingAssignedContacts) {
        return <WaitIcon />;
    }

    return (
        <ContactsPanelContext.Provider
            value={{
                applicationNumber,
                programNumber,
                utilityNumber,
                premiseContact,
                primaryContact,
                contractorContact,
                onDelete,
                onExpand,
                onScrollToOtherContactsList,
                assignedContacts,
                canSearchCIS,
            }}
        >
            <div className="panel contacts">
                <PanelHeaderLarge title="Contacts" />
                <AssignedContacts />
                {isLoadingOtherContacts ? <WaitIcon /> : <OtherContacts listRef={otherContactsListRef} otherContacts={otherContacts} />}
            </div>
        </ContactsPanelContext.Provider>
    );
});

export default ContactsPanel;
