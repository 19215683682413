import React, { memo } from "react";
import { useSelector } from "react-redux";
import ClassNames from "classnames";

import Window from "./Window";
import WindowTabs from "./WindowTabs";

import "./style.scss";

const WindowsContainer = memo(
    ({
        containerName,
        onWindowOpen,
        onWindowClose,
        onWindowActivate,
        onWindowSplit,
        onWindowRemoveSplit,
        onWindowClearSplit,
        onWindowSync,
        onAllWindowsClose,
    }) => {
        const views = useSelector((state) => state.window[containerName])?.views || [];

        const hasSplitView = (views || []).filter((w) => w.isSplitView || w.active).length > 0;
        const layoutType = (views || []).filter((w) => w.active)[0] && (views || []).filter((w) => w.active)[0].layoutType;
        const isSplitView = (views || []).filter((w) => w.active)[0] && (views || []).filter((w) => w.active)[0].isSplitView;

        const containerClass = ClassNames("window-container flex-one-in-row", {
            "has-split-view": hasSplitView,
        });

        return (
            <div className={containerClass}>
                <div className="window-content flex-row flex-one">
                    {(views || []).map((view) => (
                        <Window
                            key={view.name}
                            containerName={containerName}
                            viewName={view.name}
                            onOpen={onWindowOpen}
                            onClose={onWindowClose}
                            onSplit={onWindowSplit}
                            onRemoveSplit={onWindowRemoveSplit}
                            onClearSplit={onWindowClearSplit}
                            layoutType={layoutType}
                        />
                    ))}
                </div>
                <WindowTabs
                    containerName={containerName}
                    layoutType={layoutType}
                    isSplitView={isSplitView}
                    onWindowActivate={onWindowActivate}
                    onWindowClose={onWindowClose}
                    onWindowSync={onWindowSync}
                    onAllWindowsClose={onAllWindowsClose}
                />
            </div>
        );
    }
);

export default WindowsContainer;
