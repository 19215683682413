import React, { memo, useEffect } from "react";
import { connect } from "react-redux";

import { applicationsBookmarksGridColumnKeys, widgetApplicationsBookmarksGridColumnKeys, availableGrids } from "../../views/configureGrids";
import { openProjectTab } from "../../utils/window";
import { updateResource } from "../../../store/resources/actions";
import { modalOpen } from "../../../store/modal/actions";
import { construct } from "../../../store/dataGrid/actions";
import { refreshApplication } from "../../../store/resources/refreshResource";
import { refreshApplicationBookmarksGrid, refreshApplicationBookmarksWidgetGrid } from "../../../store/dataGrid/refreshGrid";
import { searchAttrToFilter } from "../../utils/datagrid";
import { exportDatagridToCSV } from "../../utils/CSV";

import IconWithLabel from "../../ui/Icons/IconWithLabel";
import DataGrid from "../../ui/DataGrid";
import RawHtml from "../../ui/RawHtml";
import ActionLabel from "../../ui/Label/ActionLabel";
import WaitIcon from "../../ui/WaitIcon";

import "./style.scss";

const rowActions = [
    {
        name: "open",
        icon: "open-new-window",
        title: "Manage Application",
    },
    {
        name: "delete",
        icon: "bookmark-filled icon-wrap-combined remove",
        title: "Remove from Bookmarks",
    },
];

const ApplicationProcessingBookmarks = memo(() => {
    return (
        <div className="app-processing-bookmarks panel sticky-grid-list-panel">
            <div className="main-grid-wrap responsive flex-column fill-height">
                <ApplicationBookmarksDataGrid />
            </div>
        </div>
    );
});

const Grid = memo(({ user, dataGrid, isWidget, dispatch }) => {
    const dataGridID = isWidget ? availableGrids.widgetApplicationsBookmarks : availableGrids.applicationsBookmarks;

    const columnApplicationNumber = isWidget
        ? widgetApplicationsBookmarksGridColumnKeys.applicationNumber
        : applicationsBookmarksGridColumnKeys.applicationNumber;
    const columnProjectName = isWidget
        ? widgetApplicationsBookmarksGridColumnKeys.projectName
        : applicationsBookmarksGridColumnKeys.projectName;
    const columnProjectNumber = isWidget
        ? widgetApplicationsBookmarksGridColumnKeys.projectNumber
        : applicationsBookmarksGridColumnKeys.projectNumber;
    const columnUserNumber = isWidget
        ? widgetApplicationsBookmarksGridColumnKeys.userNumber
        : applicationsBookmarksGridColumnKeys.userNumber;

    const gridId = `${user.userNumber}${isWidget ? "-widget" : ""}-applications-bookmarks-grid`;
    const gridFilter = `${columnUserNumber}=${user.userNumber}`;
    const gridConfig = dataGrid[gridId];

    useEffect(() => {
        dispatch(
            construct({
                dataGridId: dataGridID,
                dataGridInstanceId: gridId,
                filter: searchAttrToFilter(gridFilter),
                data: true,
            })
        );
    }, [dataGridID, gridId, gridFilter, dispatch]);

    const onRowAction = (action) => {
        const { name, dataItem } = action;

        const applicationName = dataItem[columnProjectNumber] + " (" + dataItem[columnApplicationNumber] + ")";
        const applicationNumber = dataItem[columnApplicationNumber];

        switch (name) {
            case "open":
                onBookmarkOpen(applicationNumber);
                break;

            case "delete":
                onBookmarkDelete({ applicationNumber, applicationName });
                break;

            default:
                break;
        }
    };

    const onBookmarkOpen = (applicationNumber) => {
        openProjectTab({ applicationNumber });
    };

    const onBookmarkDelete = ({ applicationNumber, applicationName }) => {
        const text = "<p>Would you like to remove application <strong>" + applicationName + "</strong> from your Bookmarks?</p>";

        dispatch(
            modalOpen({
                modalType: "CONFIRM",
                modalProps: {
                    title: "Remove from Bookmarks",
                    modalIcon: "bookmark-filled icon-wrap-combined remove",
                    overlayClassName: "modal-styled",
                    className: "remove-from-bookmarks-confirmation-modal modal-sm",
                    footerContentCenter: true,
                    content: <RawHtml>{text}</RawHtml>,
                    onConfirm: () => {
                        dispatch(
                            updateResource({
                                resourceName: "applicationToolsBookmarks",
                                path: {
                                    appId: applicationNumber,
                                },
                                optimisticUpdate: {
                                    clearItem: true,
                                },
                                onSuccess: () => {
                                    refreshApplication({ applicationNumber });
                                    refreshApplicationBookmarksGrid();
                                    refreshApplicationBookmarksWidgetGrid();
                                },
                            })
                        );
                    },
                },
            })
        );
    };

    if (!gridConfig) {
        return <WaitIcon />;
    }

    return (
        <>
            <div className="data-grid-controls flex-row justify-end">
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: gridId,
                            fileName: "applications_bookmarks",
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <DataGrid
                name={gridId}
                config={gridConfig}
                customRowActions={rowActions}
                onRowAction={onRowAction}
                columnCellContent={{
                    [columnApplicationNumber]: (column, row) => (
                        <ActionLabel onClick={() => onBookmarkOpen(row[column.key])}>{row[column.key]}</ActionLabel>
                    ),
                    [columnProjectNumber]: (column, row) => {
                        if (row[columnApplicationNumber]) {
                            return (
                                <ActionLabel onClick={() => onBookmarkOpen(row[columnApplicationNumber])}>{row[column.key]}</ActionLabel>
                            );
                        }

                        return row[column.key];
                    },
                    [columnProjectName]: (column, row) => {
                        if (row[columnApplicationNumber]) {
                            return (
                                <ActionLabel onClick={() => onBookmarkOpen(row[columnApplicationNumber])}>{row[column.key]}</ActionLabel>
                            );
                        }

                        return row[column.key];
                    },
                }}
            />
        </>
    );
});

export default ApplicationProcessingBookmarks;

export const ApplicationBookmarksDataGrid = connect((state) => ({
    user: state.user,
    dataGrid: state.dataGrid,
}))(Grid);
