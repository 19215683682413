import React, { memo, useMemo } from "react";
import { usePrograms, useUtilities, useProgramWorkflowStatuses } from "../../../store/resources/useResource";

import JsonSchemaForm from "../../ui/Form/JsonSchema/JsonSchemaForm";
import IconWrap from "../../ui/Icons";
import { listToAnyOf } from "../../utils/form";

export const ProgramFilter = memo(({ formRef, values, onChange, onRemove }) => {
    const [utilities = [], isLoadingUtilities] = useUtilities({
        forced: false,
    });
    const [programs = [], isLoadingPrograms] = usePrograms({
        utilityNumber: values.utility,
    });
    const [projectStatuses = [], isLoadingProjectStatuses] = useProgramWorkflowStatuses({ programNumber: values.program });

    const schema = useMemo(
        () => ({
            type: "object",
            required: ["utility", "program", "projectStatuses"],
            properties: {
                utility: {
                    type: "string",
                    title: "Utility",
                    anyOf: listToAnyOf({
                        list: utilities,
                        map: (item) => ({
                            title: item.utility,
                            enum: [item.utilityNumber],
                        }),
                    }),
                },
                ...(values.utility
                    ? {
                          program: {
                              type: "string",
                              title: "Program",
                              anyOf: listToAnyOf({
                                  list: programs,
                                  map: (item) => ({
                                      title: item.program,
                                      enum: [item.programNumber],
                                  }),
                              }),
                          },
                      }
                    : {}),
                ...(values.program
                    ? {
                          projectStatuses: {
                              type: "array",
                              title: "Project Statuses",
                              uniqueItems: true,
                              items: {
                                  type: "string",
                                  anyOf: listToAnyOf({
                                      list: projectStatuses,
                                      map: (item) => ({
                                          title: item.status,
                                          enum: [item.workflowNumber],
                                      }),
                                      skipEmptyItem: true,
                                  }),
                              },
                          },
                      }
                    : {}),
            },
        }),
        [utilities, programs, projectStatuses, values]
    );
    const uiSchema = useMemo(
        () => ({
            classNames: "inline-form",
            utility: {
                "ui:placeholder": isLoadingUtilities ? "Loading..." : "Select utility",
            },
            ...(values.utility
                ? {
                      program: {
                          "ui:placeholder": isLoadingPrograms
                              ? "Loading..."
                              : programs.length === 0 && values.utility
                              ? "No Programs"
                              : "Select program",
                          "ui:disabled": isLoadingUtilities || isLoadingPrograms || programs.length === 0,
                      },
                  }
                : {}),
            ...(values.program
                ? {
                      projectStatuses: {
                          "ui:widget": "checkboxes",
                          "ui:shouldDisplayFilter": true,
                          "ui:enableSelectAll": true,
                          "ui:placeholder": isLoadingProjectStatuses
                              ? "Loading..."
                              : projectStatuses.length === 0 && values.program
                              ? "No statuses"
                              : "Choose statuses",
                          "ui:disabled":
                              isLoadingUtilities || isLoadingPrograms || isLoadingProjectStatuses || projectStatuses.length === 0,
                      },
                  }
                : {}),
        }),
        [isLoadingUtilities, isLoadingPrograms, isLoadingProjectStatuses, values, programs, projectStatuses]
    );

    const onFormChange = (form) => {
        const { formData } = form;

        if (formData.utility && values.utility !== formData.utility) {
            formData.program = undefined;
            formData.projectStatuses = undefined;
        }

        if (formData.program && values.program !== formData.program) {
            formData.projectStatuses = undefined;
        }

        onChange(formData);
    };

    return (
        <div className="program-filter">
            <JsonSchemaForm
                formRef={formRef}
                schema={schema}
                uiSchema={uiSchema}
                initialValues={values}
                onChange={onFormChange}
                noActions
            />
            {onRemove && <IconWrap icon="clear-close" title="Remove Program from filter" onClick={onRemove} />}
        </div>
    );
});

export default ProgramFilter;
