import React, { memo } from "react";
import { useResource } from "../../../../../../store/resources/useResource";

import WaitIcon from "../../../../WaitIcon";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";

import LocationView from "./LocationView";
import Applications from "./Applications";
import Equipment from "./Equipment";
import Participation from "./Participation";
import Portals from "./Portals";

import "./LocationPanel.scss";

const LocationPanel = memo(({ panel }) => {
    const utilityNumber = panel && panel.data && panel.data.utilityNumber;
    const applicationNumber = panel && panel.data && panel.data.applicationNumber;
    const programNumber = panel && panel.data && panel.data.programNumber;

    const [location = {}, isLoadingLocation] = useResource({
        resourceName: "applicationLocation",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
        showErrorNotification: false,
    });

    return (
        <div className="panel location">
            <PanelHeaderLarge title="Location" />
            {isLoadingLocation && <WaitIcon />}
            {!isLoadingLocation && (
                <div className="content">
                    <div className="content-item">
                        <LocationView applicationNumber={applicationNumber} location={location} />
                    </div>
                    <div className="content-item applications">
                        <Applications applicationNumber={applicationNumber} address={location.address} zip={location.shortZip} />
                    </div>
                    <div className="content-item">
                        <div className="flex-column fill-width participation">
                            <Participation
                                applicationNumber={applicationNumber}
                                address={location.address}
                                zip={location.shortZip}
                                programNumber={programNumber}
                            />
                        </div>

                        <div className="flex-column fill-width equipment-and-portals">
                            <div className="content-item">
                                <Equipment applicationNumber={applicationNumber} address={location.address} zip={location.shortZip} />
                            </div>
                            <div className="content-item portals">
                                <Portals
                                    applicationNumber={applicationNumber}
                                    utilityNumber={utilityNumber}
                                    programNumber={programNumber}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
});

export default LocationPanel;
