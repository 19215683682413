import React from "react";
import { PropertyListItemTitle } from "../PropertyList/PropertyListItemTitle";
import { PropertyList } from "../PropertyList";
import { PropertyValue } from "../../types";
import { PropertyName } from "lodash";
import { PropertyListItem } from "../Property/PropertyListItem";
import { getLeadImageErrors } from "../../utils/validation";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";
import { useErrorContext } from "../../PortalBuilderErrorContextProvider";

export const LeadImageContentProperty = (props: LeadImageContentProps) => {
    const { title, titleSwitch, propertiesGroup = [], value = [], nestingLevel, onChange, borderTop } = props;
    const off = props.titleSwitchValue ?? titleSwitch.defaultValue;
    const { errors } = useErrorContext()!;
    const [updatedConfig] = usePortalBuilderState((state) => state.updatedConfig);
    const [activeError] = usePortalBuilderState((state) => state.activeError);
    const turnOff = () => {
        onChange(titleSwitch?.id, !off);
    };

    const leadImageErrors = getLeadImageErrors(errors, updatedConfig);

    if (props.hidden) {
        return null;
    }

    return (
        <PropertyListItem className="property-list-item--prop-group property-list-item--content-type" borderTop={borderTop}>
            {/* @ts-ignore */}
            <PropertyListItemTitle title={title} toggleTooltip={off ? "Switch ON" : "Switch OFF"} onToggle={turnOff} toggleValue={off} />
            <PropertyList
                items={propertiesGroup}
                nestingLevel={nestingLevel + 1}
                config={value}
                onChange={onChange}
                isExpanded={!off}
                errors={leadImageErrors}
                containsActiveError={activeError?.isLeadImageError}
            />
        </PropertyListItem>
    );
};

interface LeadImageContentProps {
    id: string;
    title: string;
    titleSwitch: { id: string; defaultValue?: boolean };
    titleSwitchValue: boolean;
    propertiesGroup: { id: PropertyName }[];
    value: PropertyValue;
    nestingLevel: number;
    hidden: boolean;
    onChange: (val: any, s: any) => void;
    borderTop: boolean;
}
