import { useCallback } from "react";

const isFocusedClass = "focused";
const isFocusedByKeyboardClass = "focused--keyboard";

const useFocusClasses = ({ disabled } = {}) => {
    const onFocus = useCallback(
        (event) => {
            if (!disabled) {
                //TODO: Determine if this is keyboard event
                const isKeyboardEvent = false;

                event.target.classList.add(isFocusedClass);

                if (isKeyboardEvent) {
                    event.target.classList.add(isFocusedByKeyboardClass);
                }
            }
        },
        [disabled]
    );

    const onBlur = useCallback(
        (event) => {
            if (!disabled) {
                event.target.classList.remove(isFocusedClass);
                event.target.classList.remove(isFocusedByKeyboardClass);
            }
        },
        [disabled]
    );

    return [onFocus, onBlur];
};

export default useFocusClasses;
