import React, { memo, useCallback, useContext, useEffect, useState } from "react";
import { cloneDeep, isEmpty } from "lodash";

import WaitIcon from "../../../../WaitIcon";
import Checkbox from "../../../../Input/Checkbox";
import Button from "../../../../Button";
import IconWithLabel from "../../../../Icons/IconWithLabel";
import { WorkflowContext } from "../../../context/WorkflowContext";
import { useResource } from "../../../../../../store/resources/useResource";

import "./Assignments.scss";

export const Assignments = memo(({ assignments: selectedAssignments, stepNumber, settingsContainerRef }) => {
    const { onChangeWorkflowStepProperties, assignmentsGetResourceOptions, isLocked } = useContext(WorkflowContext);
    const [assignments, setAssignments] = useState([]);
    const [isAssignmentsVisible, setAssignmentsVisible] = useState(false);
    const [programWorkflowAssignments = [], programWorkflowAssignmentsLoading] = useResource(assignmentsGetResourceOptions);

    useEffect(() => {
        setAssignmentsVisible(false);
        setAssignments(selectedAssignments);
    }, [selectedAssignments]);

    useEffect(() => {
        if (isAssignmentsVisible && settingsContainerRef.current) {
            settingsContainerRef.current.scrollTop = settingsContainerRef.current.scrollHeight - settingsContainerRef.current.clientHeight;
        }
    }, [isAssignmentsVisible, settingsContainerRef]);

    const handleOpenAssignments = useCallback(() => {
        setAssignmentsVisible(true);
    }, []);

    const handleChangeAssignment = useCallback(
        (e, groupNumber, groupName) => {
            const newAssignments = cloneDeep(assignments);
            const isInSelectedAssignments = Boolean(newAssignments.find((assignment) => assignment.groupNumber === groupNumber));

            if (e.target.checked && !isInSelectedAssignments) {
                newAssignments.push({ groupNumber, groupName });
                setAssignments(newAssignments);
            }

            if (!e.target.checked && isInSelectedAssignments) {
                setAssignments(newAssignments.filter((assignment) => assignment.groupNumber !== groupNumber));
            }
        },
        [assignments]
    );

    const handleClickSave = useCallback(() => {
        setAssignmentsVisible(false);
        onChangeWorkflowStepProperties({ assignments });
    }, [assignments, onChangeWorkflowStepProperties]);

    const handleClickCancel = useCallback(() => {
        setAssignmentsVisible(false);
        setAssignments(selectedAssignments);
    }, [selectedAssignments]);

    return (
        <div className="wf__settings-step-assignments">
            <div className="wf__settings-step-assignments-title">
                <div className="wf__settings-step-assignments-title-text">Assignment</div>
                {!isEmpty(programWorkflowAssignments) && !isAssignmentsVisible && !isLocked && (
                    <IconWithLabel icon="edit-empty" onClick={handleOpenAssignments}>
                        Manage
                    </IconWithLabel>
                )}
            </div>
            {programWorkflowAssignmentsLoading && <WaitIcon />}
            {!isAssignmentsVisible && !programWorkflowAssignmentsLoading && (
                <div className="wf__settings-step-assignments-selected">
                    {isEmpty(selectedAssignments)
                        ? "No Assignments"
                        : selectedAssignments
                              .map((assignment, index) => {
                                  const programWorkflowAssignment = programWorkflowAssignments.find(
                                      (item) => item.groupNumber === assignment.groupNumber
                                  );

                                  if (!programWorkflowAssignment) return null;

                                  if (index !== selectedAssignments.length - 1) {
                                      return `${programWorkflowAssignment.groupName}, `;
                                  }

                                  return programWorkflowAssignment.groupName;
                              })
                              .join("")}
                </div>
            )}
            {isAssignmentsVisible && (
                <>
                    <div className="wf__settings-step-assignments-checkboxes">
                        <div className="wf__settings-step-assignments-checkboxes-title">User Groups</div>
                        {programWorkflowAssignments.map((assignment) => {
                            const { groupNumber, groupName } = assignment;
                            const checked = Boolean(assignments.find((assignment) => assignment.groupNumber === groupNumber));

                            return (
                                <div className="wf__settings-step-assignments-checkboxes-item" key={groupNumber}>
                                    <Checkbox
                                        label={groupName}
                                        checked={checked}
                                        onChange={(e) => handleChangeAssignment(e, groupNumber, groupName)}
                                        labelIconBig
                                    />
                                </div>
                            );
                        })}
                        <div className="wf__settings-step-assignments-checkboxes-buttons">
                            <Button primary onClick={handleClickSave}>
                                Confirm
                            </Button>
                            <Button className="wf__settings-step-assignments-checkboxes-buttons-cancel" onClick={handleClickCancel}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
});
