import React from "react";
import { ProgramSettings } from "./ProgramSettings";
import { UtilitySettings } from "./UtilitySettings";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";

export const Settings = (props) => {
    const [isProgramPortalBuilder] = usePortalBuilderState((state) => state.isProgramPortalBuilder);

    if (isProgramPortalBuilder) {
        return <ProgramSettings {...props} />;
    }

    return <UtilitySettings {...props} />;
};
