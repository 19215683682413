import React, { memo } from "react";

import IconWithLabel from "../../../../Icons/IconWithLabel";

const Controls = memo(({ onAdd, onExportCsv }) => {
    return (
        <div className="grid-controls flex-row align-center">
            <span className="flex-one" />
            <IconWithLabel icon={"plus"} onClick={onAdd}>
                Add Invoice Document
            </IconWithLabel>
            <IconWithLabel withHandMadeIcon onClick={onExportCsv}>
                Export CSV
            </IconWithLabel>
        </div>
    );
});

export default Controls;
