import React, { useState, useRef, useMemo, useCallback, memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { isString, isNil } from "lodash";

import { useUtilities } from "../../../../../store/resources/useResource";
import { useReference } from "../../../Reference/useReference";
import { referenceTypes } from "../../../Reference/referenceTypes";
import { jsonDateToDate } from "../../../../utils/date";
import { submitByRef, submitResource, pickInitialValues, isEmailAddress } from "../../../../utils/form";
import { hasPermission, systemUserRights } from "../../../../utils/user";
import { Localize } from "components/utils/text";
import { isChildProgram } from "components/views/ProgramView/utils";

import JsonSchemaForm from "../../JsonSchema/JsonSchemaForm";
import { SelectTemplateFilesWidget } from "../../JsonSchema/widgets/SelectTemplateFilesWidget";

import Button from "../../../Button";
import SideNavHeader from "../../../SideNav/SideNavHeader";
import SideNavBody from "../../../SideNav/SideNavBody";
import SideNavFooter from "../../../SideNav/SideNavFooter";
import SideNavContent from "../../../SideNav/SideNavContent";

import "./ProgramForm.scss";

const AuthenticatedProgramForm = memo((props) => {
    const user = useSelector((state) => state.user);

    if (props.isNew && !hasPermission(user, systemUserRights.VISIONDSM_COPY_PROGRAM)) {
        return null;
    }

    return <ProgramForm {...props} />;
});

const ProgramForm = memo(({ utilityNumber, programData, isProgramConfigCriteria, onSuccess, onCancel, sidePanel }) => {
    const formRef = useRef();

    const isMobile = useSelector((state) => state.window?.isMobile);

    const user = useSelector((state) => state.user);

    const isNew = isNil(programData);
    const title = isProgramConfigCriteria ? "Criteria" : isNew ? "Create New Program" : "Edit Program";
    const leadBlockIcon = isProgramConfigCriteria ? "criteria" : isNew ? "plus" : "edit-empty";
    const userNumber = user.userNumber;

    const [isSubmitting, setSubmitting] = useState(false);

    const [utilities = []] = useUtilities();
    const [webDeliveries = []] = useReference(referenceTypes.webDeliveryClass);
    const [rebateControls = []] = useReference(referenceTypes.rebateControl);
    const [rebateExports = []] = useReference(referenceTypes.rebateExport);
    const [programTypes = []] = useReference(referenceTypes.programType);
    const [fuels = []] = useReference(referenceTypes.fuel);
    const [customerTypes = []] = useReference(referenceTypes.customerType);
    const [customerClasses = []] = useReference(referenceTypes.customerClass);
    const [sectorTargets = []] = useReference(referenceTypes.sectorTargeted);
    const [programPurposes = []] = useReference(referenceTypes.programPurpose);
    const [targetGeographies = []] = useReference(referenceTypes.targetGeography);
    const [promotedActions = []] = useReference(referenceTypes.actionPromoted);
    const [financings = []] = useReference(referenceTypes.financing);

    const [customerTargets = []] = useReference(referenceTypes.customerTargeted);
    const availableCustomerTargets = useMemo(
        () =>
            customerTargets.map((i) => ({
                title: i.display,
                enum: [i.val],
            })),
        [customerTargets]
    );

    const [resourceTargets = []] = useReference(referenceTypes.resourceTargeted);
    const availableResourceTargets = useMemo(
        () =>
            resourceTargets.map((i) => ({
                title: i.display,
                enum: [i.val],
            })),
        [resourceTargets]
    );

    const [facilityTargetAges = []] = useReference(referenceTypes.targetAgeOfFacility);
    const availableFacilityTargetAges = useMemo(
        () =>
            facilityTargetAges.map((i) => ({
                title: i.display,
                enum: [i.val],
            })),
        [facilityTargetAges]
    );

    const [programClassifications = []] = useReference(referenceTypes.programClassification);
    const availableProgramClassifications = useMemo(
        () =>
            programClassifications.map((i) => ({
                title: i.display,
                enum: [i.val],
            })),
        [programClassifications]
    );

    const [incentiveOffers = []] = useReference(referenceTypes.incentiveOffered);
    const availableIncentiveOffers = useMemo(
        () =>
            incentiveOffers.map((i) => ({
                title: i.display,
                enum: [i.val],
            })),
        [incentiveOffers]
    );

    const [rebateFulfillmentChannels = []] = useReference(referenceTypes.rebateFulfillmentChannel);
    const availableRebateFulfillmentChannels = useMemo(
        () =>
            rebateFulfillmentChannels.map((i) => ({
                title: i.display,
                enum: [i.val],
            })),
        [rebateFulfillmentChannels]
    );

    const [measureSellers = []] = useReference(referenceTypes.whoSellsMeasures);
    const availableMeasureSellers = useMemo(
        () =>
            measureSellers.map((i) => ({
                title: i.display,
                enum: [i.val],
            })),
        [measureSellers]
    );

    const [measureTypes = []] = useReference(referenceTypes.measureType);
    const availableMeasureTypes = useMemo(
        () =>
            measureTypes.map((i) => ({
                title: i.display,
                enum: [i.val],
            })),
        [measureTypes]
    );

    const [marketingLeads = []] = useReference(referenceTypes.marketingLead);
    const availableMarketingLeads = useMemo(
        () =>
            marketingLeads.map((i) => ({
                title: i.display,
                enum: [i.val],
            })),
        [marketingLeads]
    );

    const [marketingChannels = []] = useReference(referenceTypes.marketingChannelsUsed);
    const availableMarketingChannels = useMemo(
        () =>
            marketingChannels.map((i) => ({
                title: i.display,
                enum: [i.val],
            })),
        [marketingChannels]
    );

    const schema = useMemo(() => {
        const availableUtilities =
            (utilities &&
                utilityNumber &&
                utilities
                    .filter((util) => util.utilityNumber === utilityNumber)
                    .map((i) => ({
                        title: i.utility,
                        enum: [i.utilityNumber],
                    }))) ||
            (utilities &&
                utilities.map((i) => ({
                    title: i.utility,
                    enum: [i.utilityNumber],
                }))) ||
            [];

        const availableWebDeliveries = webDeliveries.map((i) => ({
            title: i.display,
            enum: [+i.val],
        }));

        const availableRebateControls = rebateControls.map((i) => ({
            title: i.display,
            enum: [+i.val],
        }));

        const availableRebateExports = rebateExports.map((i) => ({
            title: i.display,
            enum: [+i.val],
        }));

        const availableProgramTypes = programTypes.map((i) => ({
            title: i.display,
            enum: [+i.val],
        }));

        const availableFuels = fuels.map((i) => ({
            title: i.display,
            enum: [+i.val],
        }));

        const availableCustomerTypes = customerTypes.map((i) => ({
            title: i.display,
            enum: [+i.val],
        }));

        const availableCustomerClasses = customerClasses.map((i) => ({
            title: i.display,
            enum: [+i.val],
        }));

        const availableSectorTargets = sectorTargets.map((i) => ({
            title: i.display,
            enum: [i.val],
        }));

        const availableProgramPurposes = programPurposes.map((i) => ({
            title: i.display,
            enum: [i.val],
        }));

        const availableTargetGeographies = targetGeographies.map((i) => ({
            title: i.display,
            enum: [i.val],
        }));

        const availablePromotedActions = promotedActions.map((i) => ({
            title: i.display,
            enum: [i.val],
        }));

        const availableFinancings = financings.map((i) => ({
            title: i.display,
            enum: [i.val],
        }));

        const required = [
            "utilityNumber",
            "program",
            "programfriendlyname",
            "programcode",
            "startdate",
            "enddate",
            "address",
            "city",
            "state",
            "zip",
            "attention",
            "administrator",
            "returnemail",
            "webdelivery",
            "rebate_control",
            "rebate_export",
            "req_acct_number",
            "equipment_import",
            "equipment_entry",
            "programtype",
            "fuel",
            "customertype",
            "customerclass",
        ];

        if (!isNew) {
            required.push("status");
        }

        const result = {
            type: "object",
            required,
            properties: {
                utilityNumber: {
                    type: "string",
                    title: "Utility",
                    anyOf: availableUtilities,
                    default: availableUtilities.length === 1 ? availableUtilities[0].enum[0] : undefined,
                },
                program: {
                    type: "string",
                    title: "Program Name",
                },
                programfriendlyname: {
                    type: "string",
                    title: "Program Friendly Name",
                },
                programcode: {
                    type: "string",
                    title: "2 Char Program Code",
                    maxLength: 2,
                },
                startdate: {
                    type: "string",
                    title: "Start Date",
                },
                enddate: {
                    type: "string",
                    title: "End Date",
                },
                clientpm: {
                    type: "string",
                    title: "Client PM",
                },
                aegpm: {
                    type: "string",
                    title: "AEG PM",
                },
                clientname: {
                    type: "string",
                    title: "AEG CLIENT",
                },
                aegba: {
                    type: "string",
                    title: "AEG BA",
                },
                address: {
                    type: "string",
                    title: "Mailing Address",
                },
                address_cont: {
                    type: "string",
                    title: "Address Line 2",
                },
                city: {
                    type: "string",
                    title: "Mailing City",
                },
                state: {
                    type: "string",
                    title: `Mailing ${Localize.STATE}`,
                },
                zip: {
                    type: "string",
                    title: "Mailing Postal Code",
                    maxLength: 10,
                },
                attention: {
                    type: "string",
                    title: "Mailing Attention",
                },
                administrator: {
                    type: "string",
                    title: "Administrator",
                },
                adminemail: {
                    type: "string",
                    title: "Admin Email",
                },
                returnemail: {
                    type: "string",
                    title: "Return Email",
                },
                header: {
                    type: "string",
                    title: "Website Header Path",
                },
                footer: {
                    type: "string",
                    title: "Website Footer Path",
                },
                webdelivery: {
                    type: "integer",
                    title: "Web Delivery Class",
                    anyOf: availableWebDeliveries,
                },
                rebate_control: {
                    type: "integer",
                    title: "Rebate Control",
                    anyOf: availableRebateControls,
                },
                rebate_export: {
                    type: "integer",
                    title: "Rebate Export",
                    anyOf: availableRebateExports,
                },
                req_acct_number: {
                    type: "string",
                    title: "Req Acct #",
                    anyOf: [
                        {
                            title: "Yes",
                            enum: ["y"],
                        },
                        {
                            title: "No",
                            enum: ["n"],
                        },
                        {
                            title: "Hide",
                            enum: ["h"],
                        },
                    ],
                },
                equipment_import: {
                    type: "integer",
                    title: "Allow Equip Import",
                    anyOf: [
                        {
                            title: "No",
                            enum: [191],
                        },
                        {
                            title: "Yes",
                            enum: [190],
                        },
                    ],
                },
                equipment_entry: {
                    type: "integer",
                    title: "Allow Cust Equip",
                    anyOf: [
                        {
                            title: "No",
                            enum: [191],
                        },
                        {
                            title: "Yes",
                            enum: [190],
                        },
                    ],
                },
                account_classification_list: {
                    type: "string",
                    title: "Account Classifications",
                },
                customer_lookup_table: {
                    type: "string",
                    title: "Customer Lookup Table",
                },
                onetimeconfig_start: {
                    type: "string",
                    title: "OTC Start Date",
                },
                onetimeconfig_end: {
                    type: "string",
                    title: "OTC End Date",
                },
                trackingcode: {
                    type: "string",
                    title: "Google Tracking Code",
                },
                trackingtagmgr: {
                    type: "string",
                    title: "Google Tag Manager",
                },
                status: {
                    type: "string",
                    title: "Status",
                    anyOf: [
                        {
                            title: "Active",
                            enum: ["active"],
                        },
                        {
                            title: "Disabled",
                            enum: ["disabled"],
                        },
                    ],
                },
                programtype: {
                    type: "integer",
                    title: "Program Type",
                    anyOf: availableProgramTypes,
                },
                fuel: {
                    type: "integer",
                    title: "Fuel",
                    anyOf: availableFuels,
                },
                customertype: {
                    type: "integer",
                    title: "Customer Type",
                    anyOf: availableCustomerTypes,
                },
                customerclass: {
                    type: "integer",
                    title: "Customer Class",
                    anyOf: availableCustomerClasses,
                },
                sector_Targeted: {
                    type: "string",
                    title: "Sector Targeted",
                    anyOf: availableSectorTargets,
                },
                customer_Targeted: {
                    type: "array",
                    title: "Customer Targeted",
                    uniqueItems: true,
                    items: {
                        type: "string",
                        anyOf: availableCustomerTargets,
                    },
                },
                resource_Targeted: {
                    type: "array",
                    title: "Resource Targeted",
                    uniqueItems: true,
                    items: {
                        type: "string",
                        anyOf: availableResourceTargets,
                    },
                },
                program_Purpose: {
                    type: "string",
                    title: "Program Purpose",
                    anyOf: availableProgramPurposes,
                },
                facility_target_age: {
                    type: "array",
                    title: "Target Age of Facility",
                    uniqueItems: true,
                    items: {
                        type: "string",
                        anyOf: availableFacilityTargetAges,
                    },
                },
                target_Geography: {
                    type: "string",
                    title: "Target Geography",
                    anyOf: availableTargetGeographies,
                },
                program_classification: {
                    type: "array",
                    title: "Program Classification",
                    uniqueItems: true,
                    items: {
                        type: "string",
                        anyOf: availableProgramClassifications,
                    },
                },
                action_Promoted: {
                    type: "string",
                    title: "Action Promoted",
                    anyOf: availablePromotedActions,
                },
                incentive_offered: {
                    type: "array",
                    title: "Incentive Offered",
                    uniqueItems: true,
                    items: {
                        type: "string",
                        anyOf: availableIncentiveOffers,
                    },
                },
                rebate_Fulfillment_Channel: {
                    type: "array",
                    title: "Rebate Fulfillment Channel",
                    uniqueItems: true,
                    items: {
                        type: "string",
                        anyOf: availableRebateFulfillmentChannels,
                    },
                },
                who_Sells_Measures: {
                    type: "array",
                    title: "Who Sells Measures",
                    uniqueItems: true,
                    items: {
                        type: "string",
                        anyOf: availableMeasureSellers,
                    },
                },
                financing: {
                    type: "string",
                    title: "Financing",
                    anyOf: availableFinancings,
                },
                measure_type: {
                    type: "array",
                    title: "Measure Type",
                    uniqueItems: true,
                    items: {
                        type: "string",
                        anyOf: availableMeasureTypes,
                    },
                },
                energy_savings_validation_required: {
                    type: "string",
                    title: "Energy Savings Validation Requried",
                    anyOf: [
                        {
                            title: "No",
                            enum: ["191"],
                        },
                        {
                            title: "Yes",
                            enum: ["190"],
                        },
                    ],
                },
                marketing_lead: {
                    type: "array",
                    title: "Marketing Lead",
                    uniqueItems: true,
                    items: {
                        type: "string",
                        anyOf: availableMarketingLeads,
                    },
                },
                marketing_channels_used: {
                    type: "array",
                    title: "Marketing Channels Used",
                    uniqueItems: true,
                    items: {
                        type: "string",
                        anyOf: availableMarketingChannels,
                    },
                },
            },
        };

        if (isNew) {
            delete result.properties.status;
            delete result.properties.customer_lookup_table;
        }

        return result;
    }, [
        utilities,
        utilityNumber,
        webDeliveries,
        rebateControls,
        rebateExports,
        programTypes,
        fuels,
        customerTypes,
        customerClasses,
        availableCustomerTargets,
        financings,
        programPurposes,
        promotedActions,
        sectorTargets,
        targetGeographies,
        availableResourceTargets,
        availableFacilityTargetAges,
        availableIncentiveOffers,
        availableProgramClassifications,
        availableMeasureSellers,
        availableRebateFulfillmentChannels,
        availableMeasureTypes,
        availableMarketingLeads,
        availableMarketingChannels,
        isNew,
    ]);

    const uiSchema = useMemo(
        () => ({
            classNames: isProgramConfigCriteria || isMobile ? "" : "inline-form columns-4",
            utilityNumber: {
                "ui:options": {
                    placeholder: "-- SELECT --",
                },
            },
            program: {
                classNames: "program",
            },
            programcode: {
                classNames: "program-code",
            },
            startdate: {
                "ui:widget": "date",
                "ui:options": {
                    overlap: true,
                },
            },
            enddate: {
                "ui:widget": "date",
                "ui:options": {
                    overlap: true,
                },
            },
            clientpm: {
                placeholder: "Client PM",
                classNames: "client-pm",
            },
            aegpm: {
                placeholder: "AEG PM",
                classNames: "aeg-pm",
            },
            clientname: {
                placeholder: "AEG CLIENT",
                classNames: "aeg-client",
            },
            state: {
                "ui:widget": "state",
            },
            header: {
                "ui:widget": (props) => <SelectTemplateFilesWidget formRef={formRef} utilityNumber={utilityNumber} {...props} />,
                "ui:disabled": isNew,
            },
            footer: {
                "ui:widget": (props) => <SelectTemplateFilesWidget formRef={formRef} utilityNumber={utilityNumber} {...props} />,
                "ui:disabled": isNew,
            },
            webdelivery: {
                "ui:options": {
                    placeholder: "-- SELECT --",
                },
            },
            rebate_control: {
                "ui:options": {
                    placeholder: "-- SELECT --",
                },
            },
            rebate_export: {
                "ui:options": {
                    placeholder: "-- SELECT --",
                },
            },
            req_acct_number: {
                "ui:options": {
                    placeholder: "-- SELECT --",
                },
            },
            equipment_import: {
                "ui:options": {
                    placeholder: "-- SELECT --",
                },
            },
            equipment_entry: {
                "ui:options": {
                    placeholder: "-- SELECT --",
                },
            },
            onetimeconfig_start: {
                "ui:widget": "date",
                "ui:options": {
                    overlap: true,
                },
            },
            onetimeconfig_end: {
                "ui:widget": "date",
                "ui:options": {
                    overlap: true,
                },
            },
            status: {
                "ui:options": {
                    placeholder: "-- SELECT --",
                },
            },
            programtype: {
                classNames: "program-type",
                "ui:options": {
                    placeholder: "-- SELECT --",
                },
            },
            fuel: {
                "ui:options": {
                    placeholder: "-- SELECT --",
                },
            },
            customertype: {
                "ui:options": {
                    placeholder: "-- SELECT --",
                },
            },
            customerclass: {
                "ui:options": {
                    placeholder: "-- SELECT --",
                },
            },
            sector_Targeted: {
                "ui:options": {
                    placeholder: "-- SELECT --",
                },
            },
            customer_Targeted: {
                "ui:widget": "checkboxes",
            },
            resource_Targeted: {
                "ui:widget": "checkboxes",
            },
            program_Purpose: {
                "ui:options": {
                    placeholder: "-- SELECT --",
                },
            },
            facility_target_age: {
                "ui:widget": "checkboxes",
            },
            target_Geography: {
                "ui:options": {
                    placeholder: "-- SELECT --",
                },
            },
            program_classification: {
                "ui:widget": "checkboxes",
            },
            action_Promoted: {
                "ui:options": {
                    placeholder: "-- SELECT --",
                },
            },
            incentive_offered: {
                "ui:widget": "checkboxes",
            },
            rebate_Fulfillment_Channel: {
                "ui:widget": "checkboxes",
            },
            who_Sells_Measures: {
                "ui:widget": "checkboxes",
            },
            financing: {
                "ui:options": {
                    placeholder: "-- SELECT --",
                },
            },
            measure_type: {
                "ui:widget": "checkboxes",
            },
            energy_savings_validation_required: {
                "ui:options": {
                    placeholder: "-- SELECT --",
                },
            },
            marketing_lead: {
                "ui:widget": "checkboxes",
            },
            marketing_channels_used: {
                "ui:widget": "checkboxes",
            },
        }),
        [utilityNumber, isProgramConfigCriteria, isMobile, isNew]
    );

    const onSubmit = useCallback(
        (formData) => {
            const resourceId = isNew ? null : programData.programNumber;

            const resourceParams = {
                resourceName: "programs",
            };

            const data = Object.keys(formData)
                .map((key) => ({
                    [key]: Array.isArray(formData[key]) ? formData[key].join(",") : formData[key],
                }))
                .reduce((result, next) => (result = { ...result, ...next }), {});

            const body = {
                userNumber,
                ...data,
            };

            submitResource({
                resourceParams,
                resourceId,
                body,
                onSuccess: onSuccess,
                setSubmitting,
            });
        },
        [userNumber, programData, isNew, onSuccess]
    );

    const initialValues = useMemo(() => {
        const checkboxGroupFields = {
            customer_Targeted: availableCustomerTargets,
            resource_Targeted: availableResourceTargets,
            facility_target_age: availableFacilityTargetAges,
            incentive_offered: availableIncentiveOffers,
            program_classification: availableProgramClassifications,
            who_Sells_Measures: availableMeasureSellers,
            rebate_Fulfillment_Channel: availableRebateFulfillmentChannels,
            measure_type: availableMeasureTypes,
            marketing_lead: availableMarketingLeads,
            marketing_channels_used: availableMarketingChannels,
        };

        let data = pickInitialValues(programData || {});

        Object.keys(checkboxGroupFields).forEach((key) => {
            data[key] = isString(data[key]) ? data[key].split(",") : data[key];

            // take only values present in available values list
            if (checkboxGroupFields[key].length) {
                data[key] = (data[key] || []).filter((v) => checkboxGroupFields[key].some((i) => i.enum[0] === v));
            }
        });

        return data;
    }, [
        programData,
        availableCustomerTargets,
        availableResourceTargets,
        availableFacilityTargetAges,
        availableIncentiveOffers,
        availableProgramClassifications,
        availableMeasureSellers,
        availableRebateFulfillmentChannels,
        availableMeasureTypes,
        availableMarketingLeads,
        availableMarketingChannels,
    ]);

    const validate = useCallback((formData, errors) => {
        // RegEx validation for Email addresses
        if (formData.adminemail && !isEmailAddress(formData.adminemail)) {
            errors.adminemail.addError("Email address must be valid email address");
        }

        if (formData.returnemail && !isEmailAddress(formData.returnemail)) {
            errors.returnemail.addError("Email address must be valid email address");
        }

        if (jsonDateToDate(formData.startdate) >= jsonDateToDate(formData.enddate)) {
            errors.startdate.addError("Start Date must be smaller than End Date");
        }

        if (jsonDateToDate(formData.onetimeconfig_start) >= jsonDateToDate(formData.onetimeconfig_end)) {
            errors.onetimeconfig_start.addError("OTC Start Date must be smaller than OTC End Date");
        }

        return errors;
    }, []);

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    const isLocked = isNew ? false : isChildProgram({ programNumber: programData.programNumber });

    return (
        <SideNavContent className="add-new-program">
            <SideNavHeader title={title} leadBlockIcon={leadBlockIcon} smallHeader onClose={onCancel} />
            <SideNavBody className="flex-one-in-column">
                <JsonSchemaForm
                    formRef={formRef}
                    schema={schema}
                    uiSchema={uiSchema}
                    disabled={isLocked}
                    initialValues={initialValues}
                    validate={validate}
                    onSubmit={onSubmit}
                    noReset
                    noSubmit
                    noCancel
                />
            </SideNavBody>
            <SideNavFooter justifyEnd={isLocked} justifyCenter={!isLocked} setPrimaryButton={isProgramConfigCriteria}>
                {!isLocked && (
                    <Button className={isMobile ? "flex-one" : ""} primary onClick={() => submitByRef(formRef)} disabled={isSubmitting}>
                        Save
                    </Button>
                )}
                <Button onClick={onCancel}>{isLocked ? "Close" : "Cancel"}</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default AuthenticatedProgramForm;
