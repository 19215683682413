import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { onDeleteBudgetLine } from "./utils";
import { openBudgetLineTab } from "../../../../../utils/window";
import { availableGrids } from "../../../../../views/configureGrids";

import WaitIcon from "../../../../WaitIcon";
import DataGrid from "../../../../DataGrid";
import Form from "./Form";
import ActionLabel from "../../../../Label/ActionLabel";
import { getBudgetLinesResourceParams } from "store/configureResources";
import { sideNavSize } from "../../../../../ui/SideNav/SideNavRoot";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import BudgetLineDetails from "./BudgetLineDetails";
import { useDataGrid } from "components/utils/useDataGrid";
import { mapGridRowToObject } from "components/utils/datagrid";

const rowActions = [
    {
        name: "edit",
        icon: "edit-empty",
        title: "Edit Budget",
    },
    {
        name: "open",
        icon: "open-new-window",
        title: "Manage Budget",
    },
    {
        name: "add",
        icon: "plus",
        title: "Add New Child Budget Line Item",
    },
    {
        name: "delete",
        icon: "delete-trash-empty",
        title: "Delete Budget Line Item",
    },
];

const budgetLinesGridColumnKeys = {
    budgetName: "c_90579392-82d5-4078-ae9e-6fe9c6ea1570",
    budgetLineID: "c_c8b5a99e-0a7d-460a-95e4-4d561119d9d9",
    budgetLineNumber: "c_573e8cf3-2193-4da9-9820-b4330507ff50",
    budgetLineCategoryName: "c_d01202bf-22fc-4a6b-9959-6d0d8eb38ae3",
    total: "c_8a6c0a98-35ac-4302-87ca-e97e5f7b1790",
    remaining: "c_4d0c662a-5a94-4f1d-8608-0f79880e17ce",
    budgetLineCategoryId: "c_b94c9c20-6dab-474e-a44d-db49e7e5f525",
    parentBudgetLineNumber: "c_b5578538-492b-412b-a885-e05a5a69f233",
    sortOrder: "c_81bfc2cf-38f3-4244-a3ff-148ffb701110",
    note: "c_dac90e30-0ca4-48e1-8aa4-0743cb8a9168",
};

const Grid = memo(({ dataGridId, utilityNumber, contractNumber, onSelect, onRefresh }) => {
    const dispatch = useDispatch();
    const [gridConfig, isLoading] = useDataGrid({
        dataGridId: availableGrids.budgetLines,
        dataGridInstanceId: dataGridId,
        getDataAction: {
            type: "resourceGetDataAction",
            props: {
                dataGridId,
                columnKeysMap: budgetLinesGridColumnKeys,
                resourceParams: getBudgetLinesResourceParams({
                    utilityNumber,
                    contractNumber,
                }),
            },
        },
        tree: {
            treeColumn: budgetLinesGridColumnKeys.budgetName,
            idColumn: budgetLinesGridColumnKeys.budgetLineNumber,
            parentIdColumn: budgetLinesGridColumnKeys.parentBudgetLineNumber,
            parentNameColumn: budgetLinesGridColumnKeys.parentBudgetName,
        },
    });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "contract-attributes-sidenav-panel",
    });

    const onRowAction = (action) => {
        const { name, dataItem } = action;

        const data = mapGridRowToObject(budgetLinesGridColumnKeys, dataItem);

        switch (name) {
            case "open":
                openBudgetLineTab({
                    utilityNumber,
                    contractNumber,
                    budgetLine: data,
                });
                break;

            case "add":
                handleOpenSidePanel(
                    <Form
                        dataItem={data}
                        utilityNumber={utilityNumber}
                        contractNumber={contractNumber}
                        gridRefresh={onRefresh}
                        addChild
                        onClose={handleCloseSidePanel}
                    />
                );
                break;

            case "delete":
                onDeleteBudgetLine({
                    utilityNumber,
                    contractNumber,
                    dataItem: data,
                    onRefresh,
                    dispatch,
                });
                break;

            case "edit":
                handleOpenSidePanel(
                    <BudgetLineDetails
                        utilityNumber={utilityNumber}
                        contractNumber={contractNumber}
                        resource={data}
                        onClose={handleCloseSidePanel}
                    />
                );
                break;

            default:
                break;
        }
    };

    if (isLoading) {
        return <WaitIcon />;
    }

    return (
        <DataGrid
            name={dataGridId}
            config={gridConfig}
            onRowAction={onRowAction}
            customRowActions={rowActions}
            columnCellContent={{
                [budgetLinesGridColumnKeys.budgetName]: (column, row) => {
                    return (
                        <ActionLabel
                            onClick={() =>
                                openBudgetLineTab({
                                    utilityNumber,
                                    contractNumber,
                                    budgetLine: mapGridRowToObject(budgetLinesGridColumnKeys, row),
                                })
                            }
                        >
                            {row[column.key]}
                        </ActionLabel>
                    );
                },
            }}
        />
    );
});

export default Grid;
