import React from "react";

import IconWithLabel from "../../../../../Icons/IconWithLabel";

const Controls = ({ onExport }) => {
    return (
        <div className="grid-controls flex-row align-center">
            <span className="flex-one" />
            <IconWithLabel withHandMadeIcon onClick={onExport}>
                Export CSV
            </IconWithLabel>
        </div>
    );
};

export default Controls;
