import React, { useState, useEffect, useMemo } from "react";
import debounce from "lodash/debounce";

export default function debouncedTextInput(WrappedComponent, params = { timeout: 300 }) {
    const DebouncedTextField = (props) => {
        const [value, setValue] = useState(props.value);

        useEffect(() => {
            setValue(props.value);
        }, [props.value]);

        const onTextChange = useMemo(() => {
            return debounce((e) => {
                props.onChange(e);
            }, params.timeout);
        }, [props]);

        const onChange = (e) => {
            setValue(e.target.value);
            onTextChange({ target: { value: e.target.value } });
        };

        return <WrappedComponent {...props} value={value} onChange={onChange} />;
    };

    return DebouncedTextField;
}
