import DataGrid from ".";
import { programsApplicationsGridColumnKeys } from "../../views/configureGrids";
import withDataGrid from "./withDataGrid";
import { openProjectTab } from "../../utils/window";
import ActionLabel from "../Label/ActionLabel";

const ApplicationsGrid = (props) => {
    const { gridId, dataGridConfig, isLocked, isLockedText, canSelectRow, onRowSelectChange, onPageChanged, onRowSelectClear } = props;

    const customRowActions = [
        {
            name: isLocked ? "view" : "edit",
            icon: "open-new-window",
            title: isLocked ? isLockedText : "Manage Application",
        },
    ];

    const onRowAction = (action) => {
        const { dataItem } = action;

        const applicationNumber = dataItem[programsApplicationsGridColumnKeys.applicationNumber];

        onApplicationOpen(applicationNumber);
    };

    const onApplicationOpen = (applicationNumber) => {
        openProjectTab({ applicationNumber });
    };

    return (
        <DataGrid
            name={gridId}
            config={dataGridConfig}
            onRowAction={onRowAction}
            customRowActions={customRowActions}
            canSelectRow={canSelectRow}
            onRowSelectChange={onRowSelectChange}
            onRowSelectClear={onRowSelectClear}
            onPageChanged={onPageChanged}
            columnCellContent={{
                [programsApplicationsGridColumnKeys.applicationNumber]: (column, row) => (
                    <ActionLabel onClick={() => onApplicationOpen(row[column.key])}>{row[column.key]}</ActionLabel>
                ),
                [programsApplicationsGridColumnKeys.projectNumber]: (column, row) => {
                    if (row[programsApplicationsGridColumnKeys.applicationNumber]) {
                        return (
                            <ActionLabel onClick={() => onApplicationOpen(row[programsApplicationsGridColumnKeys.applicationNumber])}>
                                {row[column.key]}
                            </ActionLabel>
                        );
                    }

                    return row[column.key];
                },
                [programsApplicationsGridColumnKeys.projectName]: (column, row) => {
                    if (row[programsApplicationsGridColumnKeys.applicationNumber]) {
                        return (
                            <ActionLabel onClick={() => onApplicationOpen(row[programsApplicationsGridColumnKeys.applicationNumber])}>
                                {row[column.key]}
                            </ActionLabel>
                        );
                    }

                    return row[column.key];
                },
            }}
        />
    );
};

export default withDataGrid("programsApplications", ApplicationsGrid);
