import React, { useEffect, useState, memo, useMemo } from "react";
import cn from "classnames";
import { isEqual } from "lodash";
import usePagination from "../Pagination/usePagination";
import Input from "../../Input";
import TextInput from "../../Input/TextInput";
import Pagination from "../Pagination";
import GridListPaging from "../GridList/GridListPaging";

import "./style.scss";

const CustomList = memo((props) => {
    const {
        headers = [],
        items = [],

        renderHeaders,
        renderItem,
        renderSearch,
        renderFooter,

        searchPlaceholder,
        searchFilters,
        filterByHeaders,

        limit,

        className,
    } = props;

    const defaultFilters = useMemo(() => {
        let defaultFilters = searchFilters;

        if (filterByHeaders) {
            defaultFilters = {};
            Object.keys(headers).forEach((key) => {
                defaultFilters[key] = "";
            });
        }

        return defaultFilters;
    }, [filterByHeaders, headers, searchFilters]);

    const [filters, setFilters] = useState(defaultFilters);
    const [filteredList, setFilteredList] = useState(items);

    useEffect(() => {
        setFilteredList((prev) => {
            let filteredList = items;

            if (filters) {
                filteredList = items.filter((item) => {
                    return Object.entries(filters).every(([key, value]) => {
                        const itemValue = (item[key] || "").toString().toLowerCase();
                        const filterValue = value.toLowerCase();

                        return itemValue.includes(filterValue);
                    });
                });
            }

            return isEqual(prev, filteredList) ? prev : filteredList;
        });
    }, [items, filters]);

    const updateFilter = (key) => (event) => {
        setFilters({
            ...filters,
            [key]: event.target.value,
        });
    };

    const renderHeadersRow = () => {
        if (renderHeaders) {
            return renderHeaders(headers);
        }

        return Object.keys(headers).map((key) => (
            <div key={`header-${key}`} className="header">
                {headers[key]}
            </div>
        ));
    };

    const renderItemsRow = (item, i) => {
        if (renderItem) {
            return <React.Fragment key={i}>{renderItem(item, i)}</React.Fragment>;
        }

        return (
            <div key={i} className="list-item-row">
                {Object.keys(headers).map((key) => (
                    <div key={`value-${key}`} className="item-value">
                        {item[key]}
                    </div>
                ))}
            </div>
        );
    };

    const renderSearchRow = () => {
        if (renderSearch) {
            return renderSearch(filters, updateFilter);
        }

        const filtersKeys = Object.keys(filters);

        if (filtersKeys.length === 1) {
            return Object.keys(filters).map((key) => (
                <TextInput
                    inputTableFilter
                    key={`filter-${key}`}
                    placeholder={searchPlaceholder}
                    icon="search"
                    iconRight
                    value={filters[key]}
                    onChange={updateFilter(key)}
                />
            ));
        }

        return (
            <div className="list-search-headers-row">
                {filtersKeys.map((key) => (
                    <Input inputTableFilter key={`filter-${key}`} placeholder="" value={filters[key]} onChange={updateFilter(key)} />
                ))}
            </div>
        );
    };

    return (
        <div className={cn("custom-list flex-column", className)}>
            {headers && headers.length !== 0 && <div className="list-header-row flex-row">{renderHeadersRow()}</div>}
            {(filters || renderSearch) && <div className="list-filter flex-row">{renderSearchRow()}</div>}
            {limit ? (
                <PaginationList limit={limit} listItems={filteredList} renderItemsRow={renderItemsRow} renderFooter={renderFooter} />
            ) : (
                <div className="list-body flex-column">{filteredList.map(renderItemsRow)}</div>
            )}
        </div>
    );
});

const PaginationList = memo(({ listItems, limit, renderFooter, renderItemsRow }) => {
    const [items, { selectedPage, pageCount, onSelectPage }] = usePagination(listItems, { limit });

    const itemsTotal = listItems?.length ?? 0;
    const itemsFrom = pageCount === selectedPage ? listItems.length - items.length : selectedPage * limit + 1;
    const itemsTill = itemsFrom + items.length - 1;

    const ListPagination = (props) => (
        <Pagination pageCount={pageCount} selectedPage={selectedPage} onSelectPage={onSelectPage} {...props} />
    );

    return (
        <>
            <div className="list-body flex-column">{items.map(renderItemsRow)}</div>
            {renderFooter ? renderFooter(ListPagination, itemsFrom, itemsTill, itemsTotal, limit, onSelectPage) : <ListPagination />}
        </>
    );
});

/** Custom list footer with pagination for small windows */
export const renderCustomFooter = (Pagination, itemsFrom, itemsTill, itemsTotal, limit, onSelectPage) => {
    // Page change
    const onPageChange = ({ page }) => {
        const { skip, take } = page;
        const nextPage = skip / take;
        onSelectPage(nextPage);
    };

    return <GridListPaging skip={itemsFrom - 1} take={limit} total={itemsTotal} onPageChange={onPageChange} compact />;
};

export default CustomList;
