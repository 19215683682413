import { isNil } from "lodash";
import { toast } from "react-toastify";
import * as actionType from "../actionTypes";

const RETRY_INTERVAL = (process.env.REACT_APP_API_GET_RETRY_INTERVAL ?? "").split(",").map((i) => Number(i) * 1000);
const RETRY_COUNT = Number(process.env.REACT_APP_API_GET_RETRY_COUNT);
export const REQUEST_RETRY_EXCLUDED_HTTP_STATUS_CODES = [200, 204, 400, 405];

let retriesInProgress = 0;

export function requestRetryMiddleware() {
    return (next) => (action) => {
        switch (action.type) {
            case actionType.API_GET:
            case actionType.API_GET_AUTHORIZED:
            case actionType.API_POST:
            case actionType.API_POST_AUTHORIZED:
            case actionType.API_PUT:
            case actionType.API_PUT_AUTHORIZED:
            case actionType.API_DELETE:
            case actionType.API_DELETE_AUTHORIZED:
                markForRetry(action);
                break;
            default:
                break;
        }

        return next(action);
    };
}

export const markForRetry = (action) => {
    if (RETRY_COUNT > 0 && (isNil(action.requestRetriesLeft) || action.requestRetriesLeft > 0)) {
        action.requestRetriesLeft = isNil(action.requestRetriesLeft) ? RETRY_COUNT : action.requestRetriesLeft - 1;

        if (action.requestRetriesLeft === 0) {
            clearRequestRetryNotification(action);
        }
    }
};

export const retryRequest = (action) => async (dispatch, getState) => {
    const delay = RETRY_INTERVAL[RETRY_COUNT - action.requestRetriesLeft];

    setRequestRetryNotification(action);

    setTimeout(() => dispatch(action), delay);
};

export const setRequestRetryNotification = (action) => {
    if (!action.requestRetryNotificationActive) {
        action.requestRetryNotificationActive = true;
        retriesInProgress += 1;

        toast.info("System is busy with processing your request. Please wait…", {
            toastId: "request-retry-notification",
            autoClose: false,
        });
    }
};

export const clearRequestRetryNotification = (action) => {
    if (action.requestRetryNotificationActive) {
        action.requestRetryNotificationActive = false;

        if (retriesInProgress > 0) {
            retriesInProgress -= 1;
        }

        if (retriesInProgress === 0) {
            toast.dismiss("request-retry-notification");
        }
    }
};
