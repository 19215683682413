import React, { useState, useRef, useEffect } from "react";
import { isNil } from "lodash";

import { pickInitialValues, submitByRef } from "../../../utils/form";
import JsonSchemaForm from "../../Form/JsonSchema/JsonSchemaForm";
import Button from "../../Button";
import PanelsForm from "./PanelsForm";
import { store } from "../../../../store/configureStore";
import { saveDashboard } from "../../../../store/dashboards/actions";
import { setActiveSubMenu } from "../../../../store/subMenu/actions";
import SideNavContent from "../../SideNav/SideNavContent";
import SideNavHeader from "../../SideNav/SideNavHeader";
import SideNavBody from "../../SideNav/SideNavBody";
import SideNavFooter from "../../SideNav/SideNavFooter";
import { sideNavKey } from "../../SideNav/SideNavRoot";
import { sideNavClose } from "../../../../store/sideNav/actions";
import { useSidePanelCallbacks } from "components/utils/useSidePanelCallbacks";
import { dashboardTypes } from "components/utils/dashboard";
import { createLandingPageSubMenuItem } from "./utils";

const Form = ({ dashboardType, dashboard, onClose, title, sidePanel }) => {
    useSidePanelCallbacks({
        onClose,
    });

    const sideNavId = sideNavKey.globalRight;
    const closeSideNav = () => store.dispatch(sideNavClose({ id: sideNavId }));

    const isNew = isNil(dashboard);

    const [isSubmitting, setSubmitting] = useState(false);

    const resource = dashboard;

    const dashboardFormRef = useRef();
    const panelsRef = useRef([]);

    const initialValues = isNew
        ? {
              type: dashboardType,
              name: "My Dashboard",
              isDefault: false,
              entityNumber: null,
              columns: 3,
              show: true,
              panels: [],
          }
        : pickInitialValues(resource);

    const submitText = isSubmitting ? "Saving..." : "Save";

    const schema = {
        type: "object",
        required: ["name"],
        properties: {
            name: {
                type: "string",
                title: "Name",
            },
            show: {
                type: "boolean",
                title: "Set Visible",
            },
            isDefault: {
                type: "boolean",
                title: "Set As Default",
            },
        },
    };

    const uiSchema = {
        "ui:rootFieldId": "dashboard",
    };

    const onSubmit = () => {
        const onSaved = (dashboard) => {
            if (dashboardType === dashboardTypes.LANDING_PAGE) {
                store.dispatch(
                    setActiveSubMenu({
                        key: "Dashboard",
                        subMenu: createLandingPageSubMenuItem(dashboard),
                    })
                );
            }

            onClose();
        };

        submitByRef(dashboardFormRef, (errors, dashboardData) => {
            if (errors) {
                return;
            }

            if (panelsRef.current) {
                const panelPromises = panelsRef.current.map(
                    async (panel) =>
                        new Promise((resolve, reject) => {
                            submitByRef(panel, (errors, formData) => {
                                if (errors) {
                                    reject(errors);
                                } else {
                                    resolve(formData);
                                }
                            });
                        })
                );

                Promise.all(panelPromises).then((panels) => {
                    setSubmitting(true);

                    const updatedDashboard = {
                        ...dashboardData,
                        panels,
                    };

                    store.dispatch(
                        saveDashboard({
                            dashboard: updatedDashboard,
                            showSuccessNotification: true,
                            onSuccess: (action) =>
                                onSaved({
                                    ...updatedDashboard,
                                    id: action.data.number ?? updatedDashboard.id,
                                }),
                            onError: () => setSubmitting(false),
                        })
                    );
                });
            } else {
                setSubmitting(true);

                store.dispatch(
                    saveDashboard({
                        dashboard: dashboardData,
                        showSuccessNotification: true,
                        onSuccess: (action) => {
                            onSaved({
                                ...dashboardData,
                                id: action.data.number ?? dashboardData.id,
                            });
                        },
                        onError: () => setSubmitting(false),
                    })
                );
            }
        });
    };

    useEffect(() => {
        sidePanel.setForm([dashboardFormRef, panelsRef]);
    }, [sidePanel]);

    return (
        <SideNavContent className="dashboard-settings-form">
            <SideNavHeader className="dashboard-settings-form__header" title={title} onClose={closeSideNav}>
                <JsonSchemaForm formRef={dashboardFormRef} schema={schema} uiSchema={uiSchema} initialValues={initialValues} noActions />
            </SideNavHeader>
            <SideNavBody noPadding={true}>
                <div className="dashboard-settings-form__body flex-column flex-one-in-column">
                    <PanelsForm dashboard={dashboard} isSubmitting={isSubmitting} panelsRef={panelsRef} />
                </div>
            </SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button primary onClick={onSubmit} disabled={isSubmitting}>
                    {submitText}
                </Button>
                <Button onClick={closeSideNav}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
};

export default Form;
